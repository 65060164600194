'use strict';
angular.module('myApp.forumSearch', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/forumSearch/:query?', {
      templateUrl: 'app/forumSearch/forumSearch.html',
      controller: 'forumSearchCtrl'
    });
  }])
  .controller('forumSearchCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'baseApiUrl', 'tokenService', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, baseApiUrl, tokenService, MyService) {
    $scope.imgUrl = baseApiUrl + "/resources";
    $rootScope.welcome = false;
    $scope.userId = tokenService.getUserId();
    $scope.userRole = tokenService.getUserRole();
    $scope.userData = JSON.parse(tokenService.getUserData());
    $scope.ShowAdavace = false;
    if ($routeParams.query) {
      $scope.search = {
        text: $routeParams.query,
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        factualFrom: '',
        factualTo: '',
        fromfollowlist: '',
        order: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: ''
      };
    } else {
      $scope.search = {
        text: '',
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        factualFrom: '',
        factualTo: '',
        fromfollowlist: '',
        order: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: ''
      };
    }

    $scope.showRefineLocation = false;

    $scope.Location = ["International", "National", "My State", "My County", "My Local", "Other Specific"];
    $scope.mediaType = ['Text', 'Video', 'Stills', 'Audio'];
    $scope.dateRange = ['Today', 'In the last week', 'In the last month', 'In the last year'];
    $scope.users = ["Voters", "Advocates", "Press", "Politicians", "Authors on my follow list"];
    $scope.searchResults = {
      data: []
    };
    $scope.selectedCat = [];
    $scope.subtopics = {
      subcategories: []
    }
    $scope.disabled = [];
    $scope.allLocations = [];
    $scope.allCounties = [];
    $scope.selectedArea = [];
    $scope.rmselectedArea = [];
    $scope.myselectionarea = [];
    $scope.disabledLocation = [];
    $scope.ShowSubcatTree = false;

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    function addslashes(str) {
      return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
    }

    $scope.checkCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val)
          }
        }
      );
    }

    $scope.mainCatTree = [];

    $scope.getSubcatTree = function () {
      //MyService.ConsoleLog($scope.mainCatTree);
      if ($scope.mainCatTree.length == 0) {
        $scope.ShowSubcatTree = true;

        MyService.getAllCatTree().then(function (payload) {
            $scope.subtopics.subcategories.length = 0;
            $scope.mainCatTree = angular.copy(payload);
            var subcatdata = payload;
            $scope.ShowSubcatTree = false;

            $scope.subtopics.subcategories = subcatdata;
            var selectedCat = $scope.disabled;
            if (selectedCat.length > 0) {
              for (var s = 0; s < selectedCat.length; s++) {
                var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
              }
              //MyService.ConsoleLog($scope.selectedCat);
            }
            setTimeout(function () {
              var cols = document.getElementsByClassName('cmnUL');
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = "none";
              }
            }, 300);
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {

        //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories.length);
        if ($scope.subtopics.subcategories.length == 0) {
          $scope.subtopics.subcategories = angular.copy($scope.mainCatTree);

        } else {
          ;
          $scope.subtopics.subcategories.length = 0;

        }


        $scope.ShowSubcatTree = false;
        //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories);
        //MyService.ConsoleLog("After : " + $scope.ShowSubcatTree);
      }

    }



    $scope.AllStates = [];
    $scope.allCounties = [];
    $scope.allLocals = [];
    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];

    $scope.getAllStates = function () {
      $scope.ShowStateLoader = true;
      if ($scope.AllStates.length == 0) {
        MyService.getAllStates().then(function (payload) {
            $scope.ShowStateLoader = false;
            $scope.allLocations.length = 0;
            var subcatdata = payload;
            $scope.AllStates = angular.copy(subcatdata);
            //MyService.ConsoleLog("countires : ", subcatdata);
            $scope.allLocations = subcatdata;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {
        if (!$scope.showStates) {
          $scope.allLocations.length = 0;
          $scope.ShowStateLoader = false;
        } else {
          $scope.allLocations = angular.copy($scope.AllStates);
          $scope.ShowStateLoader = false;
        }
      }

    }


    $scope.getCounties = function (state) {
      MyService.ConsoleLog("getCounties", state);
      $scope.showLoading.push(state);
      if (!$scope.allCounties[state] || $scope.allCounties[state].length == 0) {

        MyService.getAllCounties(state).then(function (payload) {
            $scope.showLoading.length = 0;
            var sublocations = payload;
            if (!$scope.allCounties) {
              $scope.allCounties = [];
            }
            $scope.allCounties[state] = sublocations;
          },
          function (errorPayload) {
            alert('Something went wrong.');
          });


      } else {
        $scope.showLoading.length = 0;
        $scope.allCounties[state].length = 0;
      }
    }

    $scope.getLocals = function (state, county) {
      $scope.showLoadingCounty.push(county);
      if (!$scope.allLocals[state] || !$scope.allLocals[state][county] || $scope.allLocals[state][county].length == 0) {

        MyService.getAllSubCountiesLocal(state, county).then(function (payload) {
            // MyService.ConsoleLog("sublocations Locals : ", payload);

            $scope.showLoadingCounty.length = 0;
            if (!$scope.allLocals[state]) {
              $scope.allLocals[state] = [];
            }
            $scope.allLocals[state][county] = payload.places;

          },
          function (errorPayload) {
            alert('Something went wrong ');
          });


      } else {
        $scope.showLoadingCounty.length = 0;
        $scope.allLocals[state][county].length = 0;
      }
    }





    $scope.showmediaType = false;
    $scope.showDateRange = false;
    $scope.showMatchAuthor = false;
    $scope.showEssayRating = false;
    $scope.showStates = false;
    $scope.showRefine = function (e, option) {
      // MyService.ConsoleLog("opts", option);
      e.preventDefault();
      switch (option) {
        case "Interest":
          $scope.getSubcatTree();
          break;
        case "Location":
          if (!$scope.showRefineLocation)
            $scope.showRefineLocation = true;
          else
            $scope.showRefineLocation = false;

          break;
        case "AllLocation":
          if (!$scope.showStates) {
            $scope.showStates = true;

          } else {
            $scope.showStates = false;
          }
          $scope.getAllStates();
          break;
        case "Media":
          if (!$scope.showmediaType)
            $scope.showmediaType = true;
          else
            $scope.showmediaType = false;
          break;
        case "Date":
          if (!$scope.showDateRange)
            $scope.showDateRange = true;
          else
            $scope.showDateRange = false;
          break;
        case "Author":
          if (!$scope.showMatchAuthor)
            $scope.showMatchAuthor = true;
          else
            $scope.showMatchAuthor = false;
          break;
        case "EssayRated":
          if (!$scope.showEssayRating)
            $scope.showEssayRating = true;
          else
            $scope.showEssayRating = false;
          break;
        case "Default":
          break;

      }
    }


    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];
    $scope.ChangeShow = function (id) {
      $scope.showSubCat = true;

      var nclass = "MainCatLi_" + id;
      if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
        document.getElementsByClassName(nclass)[0].style.display = "block";
      } else {
        document.getElementsByClassName(nclass)[0].style.display = "none";
      }
    }


    function areEqual() {
      var len = arguments.length;
      for (var i = 1; i < len; i++) {
        if (arguments[i] == null || arguments[i] != arguments[i - 1])
          return false;
      }
      return true;
    }

    function indexOfExt(list, item) {
      var len = list.length;

      for (var i = 0; i < len; i++) {
        var keys = Object.keys(list[i]);
        var flg = true;
        var arr = [];


        for (var j = 0, s = 1; j < keys.length; j++, s++) {
          var value = list[i][keys[j]];
          if (item[keys[j]] !== value) {
            flg = false;

          }

          if (item[keys[j]] == value) {
            arr.push(flg);
          }


        }
        if (flg == true) {
          if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
            return i;
          }

        }


      }
      return -1;
    }

    $scope.findLocations = [];
    $scope.Interests = [];
    $scope.MyLocation = [];
    $scope.SelectDefaultLocation = function (val) {
      //MyService.ConsoleLog("Data : ", val);
      var idx = $scope.MyLocation.indexOf(val);
      if (idx == -1) {
        $scope.MyLocation.push(val);
      } else {
        $scope.MyLocation.splice(idx, 1);
      }
      //MyService.ConsoleLog($scope.MyLocation);
    }

    $scope.selectLocation = function (val1, val2, val3, type) {
      //MyService.ConsoleLog(val1, val2, val3, type);
      var nobj = {};
      nobj.country = "United States of America";
      if (type == "state") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
      }
      if (type == "county") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
        nobj.county = val3;
      }
      if (type == "local") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
        nobj.county = val2;
        nobj.countySubdivision = val3.countySubdivision;
        nobj.place = val3.place;
      }
      //MyService.ConsoleLog("nboj:", nobj);
      var idx = indexOfExt($scope.findLocations, nobj);
      if (idx == -1) {
        $scope.findLocations.push(nobj);
      } else {
        $scope.findLocations.splice(idx, 1);
      }
    }



    $scope.getVal = function (data) {
      //MyService.ConsoleLog($scope.Interests);
      var idx = $scope.Interests.indexOf(data._id);
      if (idx == -1) {
        $scope.Interests.push(data._id);
      } else {
        $scope.Interests.splice(idx, 1);
      }
    }

    $scope.totalResults = 0;

    function cleanArray(actual) {
      var newArray = new Array();
      for (var i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    }

    function allnumeric(inputtxt) {
      var numbers = /^[0-9]+$/;
      if (inputtxt == undefined) {
        return false;
      }
      if (inputtxt.match(numbers)) {
        return true;
      } else {
        return false;
      }
    }

    $scope.getForumList = function (query) {
      //var q = addslashes(query);
      var q = query;
      //MyService.ConsoleLog("Q:",q);
      var nrequest = apiCall.apiCall('GET', '/topics/search', {
        query: q
      });
      $http(
        nrequest
      ).then(function successCallback(responseLatest) {
        //MyService.ConsoleLog("Final Data");
        //MyService.ConsoleLog(responseLatest.data.data);
        $scope.searchResults.data.length = 0;
        if (responseLatest.data.success) {
          $scope.searchResults.data = responseLatest.data.data;
          MyService.ConsoleLog("$scope.searchResults.data", $scope.searchResults.data);
        }
      }, function errorCallBack(responseLatest) {
        alert("Error : " + responseLatest);
      });
    }

    $scope.getForumListAdvance = function (data) {
      $scope.searchResults.data.length = 0;
      if ($scope.findLocations.length > 0) {
        data.searchlocations = JSON.stringify($scope.findLocations);
      }

      if ($scope.MyLocation.length > 0) {
        data.MyLocation = JSON.stringify($scope.MyLocation);
      }
      if (data.mediaoption) {
        var moption = data.mediaoption;
        if (typeof moption == "object") {
          moption = Object.keys(moption).map(function (key) {
            return moption[key]
          });
        }
        if (typeof moption != "string") {
          var media = cleanArray(moption);
          data.mediaoption = JSON.stringify(media);
        }
      }
      data.subjectOfInterest = JSON.stringify($scope.Interests);
      var validateMatch = true,
        validateMatchTo = true,
        validateRecommend = true,
        validateTo = true;
      var msg = "";
      if (data.matchFrom != "") {
        validateMatch = allnumeric(data.matchFrom);
        if (($scope.Interests.length == 0) && validateMatch) {
          msg = "Please select atleast one subject of interest";
          validateMatch = false;
        }
      }

      if (data.matchTo != "") {
        validateMatchTo = allnumeric(data.matchTo);
        if (validateMatchTo && ($scope.Interests.length == 0)) {
          msg = "Please select atleast one subject of interest";
          validateMatchTo = false;
        }
      }
      //MyService.ConsoleLog("data : ", data);

      if (validateMatch && validateMatchTo) {
        var nrequest = apiCall.apiCall('POST', '/topics/AdvanceSearch', data);
        $http(
          nrequest
        ).then(function successCallback(responseLatest) {
          //MyService.ConsoleLog("Final Advance Data");
          //MyService.ConsoleLog(responseLatest.data.data);
          $scope.searchResults.data.length = 0;
          if (responseLatest.data.success) {
            $scope.searchResults.data = responseLatest.data.data;
          }
        }, function errorCallBack(responseLatest) {
          alert("Error : " + responseLatest);
        });
      } else {
        if (msg != "") {
          alert(msg);
        } else {
          alert("Please enter valid data");
        }

      }
    }

    $scope.ShowOrderList = false;
    $scope.showOrder = function () {
      if (!$scope.ShowOrderList) {
        $scope.ShowOrderList = true;
      } else {
        $scope.ShowOrderList = false;
      }
    }
    $scope.isreverse = false;


    $scope.sortValues = function (data) {
      var result = "";


      switch ($scope.search.order) {
        case "Most Recent":
          $scope.isreverse = true;
          result = data.createdOn;
          break;
        case "Oldest":
          $scope.isreverse = false;
          result = data.createdOn;
          break;

        case "Default":
          $scope.isreverse = true;
          result = data.createdOn;
          break;

      }


      //MyService.ConsoleLog($scope.search);
      //MyService.ConsoleLog(result);
      return result;

    }


    $scope.gotoThread = function (data, subcatid) {
      var id = '';
      if (data.parentId) {
        id = data.parentId;
      } else if (data.parent) {
        id = data.parent;
      } else {
        if (data.id) {
          id = data.id;
        } else {
          id = data._id;
        }
      }
      // MyService.ConsoleLog(data);
      var categoryId = "";
      if (data.parentCat[0] && data.parentCat[0] != "") {
        categoryId = data.parentCat[0];
      } else {
        if (data.parentCat[1] && data.parentCat[1] != "") {
          categoryId = data.parentCat[1];
        }
      }

      // $rootScope.subcategoryId = subcatid;
      //MyService.ConsoleLog('forumthread/' + categoryId + '/' + subcatid + '/' + id);


      if (categoryId != "") {
        $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + id);
      } else {
        MyService.ConsoleLog("ELSE");
        alert("Something went wrong");
      }


    }

    // $scope.gotoThread = function (data) {
    //   var id = '';
    //   if (data.parentId) {
    //     id = data.parentId;
    //   } else if (data.parent) {
    //     id = data.parent;
    //   } else {
    //     if (data.id) {
    //       id = data.id;
    //     } else {
    //       id = data._id;
    //     }
    //   }
    //   var subcats = data.subcategories;
    //   var len = subcats.length;
    //   var categoryId = subcats[len - 2];
    //   var subcatid = subcats[len - 1];
    //   //MyService.ConsoleLog('forumthread/' + categoryId + '/' + subcatid + '/' + id + '/' + data._id);
    //   $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + id + '/' + data._id);
    // }



    $scope.showadvace = function () {
      $scope.ShowAdavace = true;
      var query = $scope.search.text;
      $scope.search = {
        text: query,
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        factualFrom: '',
        factualTo: '',
        fromfollowlist: '',
        order: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: ''
      };
    }

    if ($routeParams.query) {

      $scope.getForumList($routeParams.query);
    }
  }]);
