'use strict';
angular.module('myApp.ratePolitician', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ratePolitician', {
      templateUrl: 'app/ratePolitician/ratePolitician.html',
      controller: 'ratePoliticianCtrl'
    });
  }])
  .controller('ratePoliticianCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'growl', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, growl, ngDialog) {

    $scope.User = {
      name: "",
      state: "",
      runningFor: ""
    };
    $scope.showEndorse = false;
    if (tokenService.getUserId()) {
      MyService.ConsoleLog("tokenService.getUserId()", tokenService.getUserId());
    };
    /**
     * Function called on page load and fetch state infromation from database
     */
    $scope.allState = [];
    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };

    $scope.getAllStates();

    /**
     * Search politician and users on the basis of search criteria
     */

    $scope.search = function () {
      MyService.ConsoleLog("User", $scope.User);

      MyService.searchForRate({
        name: $scope.User.name,
        state: $scope.User.state.statePostalCode,
        runningFor: $scope.User.runningFor,
        orgId: tokenService.getUserId()
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        $scope.searchResult = response.data.data;
      }, function (err) {
        growl.error(err);
      });
    };

    /**
     * Endorse Politician and users
     * show text area for note
     */
    $scope.fEndorse = false;
    $scope.endorse = function (udata, type) {
      if (type == "endorse") {
        $scope.fEndorse = true;
        $scope.chkId = udata._id;
        $scope.MyDialog = ngDialog.open({
          template: "notePop",
          scope: $scope
          // className: 'ngdialog-theme-default'
        });
      } else {
        $scope.disNote = udata.note;
        $scope.MyDialogTwo = ngDialog.open({
          template: "disNotePop",
          scope: $scope
          // className: 'ngdialog-theme-default'
        });
      }

      $scope.userData = udata;
      MyService.ConsoleLog("Endorse", $scope.fEndorse);
    };

    $scope.addInfo = function () {
      // alert("all");
      MyService.ConsoleLog("note", $scope.User.note);
      MyService.ConsoleLog("userData", $scope.userData);
      MyService.endorsePoli({
        udata: $scope.userData,
        eflag: $scope.fEndorse,
        note: $scope.User.note,
        orgId: tokenService.getUserId()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.showEndorse = true;
        $scope.User.note = "";
        $scope.search();
        $scope.MyDialog.close();
      }, function (err) {
        growl.error(err);
      });
    };

    $scope.close = function () {
      $scope.MyDialogTwo.close();
    };

  }]);
