'use strict';

angular
  .module('myApp.orgActivityDetails', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/orgActivityDetails/:type?/:orgId?', {
        templateUrl: 'app/orgActivityDetails/orgActivityDetails.html',
        controller: 'orgActivityDetailsCtrl',
      });
    },
  ])

  .controller('orgActivityDetailsCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    'baseApiUrl',
    '$q',
    '$timeout',
    'growl',
    'tokenService',
    '$anchorScroll',
    '$sce',
    'MyService',
    'ngDialog',
    function(
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      baseApiUrl,
      $q,
      $timeout,
      growl,
      tokenService,
      $anchorScroll,
      $sce,
      MyService,
      ngDialog
    ) {
      $scope.isfollowList = $routeParams.type;
      $scope.isfollow = false;
      $scope.isQus = false;
      $scope.orgId = $routeParams.orgId;

      /**
       * get  followers count and get quizzes count
       */
      $scope.getFollowersList = function() {
        MyService.getFollowersList({ orgId: $scope.orgId }).then(
          function(response) {
            MyService.ConsoleLog('response', response.data);
            $scope.isfollow = true;
            $scope.isQus = false;
            $scope.foData = response.data.followersData;
            $scope.questionsCnt = response.data.quizzesCount;
            $scope.quizzesData = response.data.quizzesData;
            $scope.quizQuesHasAns = response.data.quesHasAnsCount;
          },
          function(err) {
            MyService.ConsoleLog('error', err);
          }
        );
      };

      if ($scope.orgId && $scope.orgId != '') {
        $scope.getFollowersList();
      } else {
        if (window.localStorage.getItem('followData')) {
          $scope.isfollow = true;
          $scope.isQus = false;
          $scope.foData = JSON.parse(window.localStorage.getItem('followData'));
          MyService.ConsoleLog('<<<<< data >>>>>>>>', $scope.foData);
        } else if ($scope.isfollowList == 'followers') {
          $scope.isfollow = true;
          $scope.isQus = false;
          $scope.foData = $rootScope.followersData;
          MyService.ConsoleLog('$scope.foData', $scope.foData);
        } else {
          $scope.isQus = true;
          $scope.isfollow = false;
          $scope.quesData = $rootScope.quizzesData;
        }
      }
    },
  ]);
