'use strict';
angular.module('myApp.forumReply', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/forumReply/:categoryId/:subcatId/:forumId', {
    templateUrl: 'app/forumReply/forumReply.html',
    controller: 'forumReplyCtrl'
  });
}])
.controller('forumReplyCtrl', ['$scope','$http','$location','apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout,growl) {
	$rootScope.welcome = false;
	$scope.TopicButton = false;
	$scope.location = ['National', 'State', 'County', 'Local'];
	$scope.mainTopic = {};
	$scope.Topic = {
		title: '',
		description: '',
		location: 'National',
		restrictedTo: 'Locals Only',
	};
	$scope.restrictedOptions = ['Locals Only', 'No Restrictions'];
	
	if(!$rootScope.UserId) {
		$location.path('signin');
	}



	$scope.forumDetails = function() {
		var nrequest = apiCall.apiCall('GET', '/topics/getTopic', {topicId: $routeParams.forumId});
		$http(
		    nrequest
		  ).then(function successCallback(responseLatest) {
			$scope.Topic.title = "RE: "+responseLatest.data.data.title;
			$scope.mainTopic = responseLatest.data.data;
			
		  }, function errorCallBack(responseLatest) {
		  	
		    //console.log("Error : "+responseLatest);
		  });
	}

	$scope.SubmitTopic = function(data) {
		 if ($.fn.validateForceFully($("#topicform")) == true) {
		 	$scope.TopicButton = true;
		 	data.parentcat = $routeParams.categoryId;
		 	data.subcategories = $routeParams.subcatId;
		 	data.createdById = $rootScope.UserId;
		 	data.createdByName = $rootScope.User;
		 	data.location = $scope.mainTopic.location;
		 	data.restrictedTo = $scope.mainTopic.restrictedTo;
		 	data.parent = $routeParams.forumId;
		 	var request = apiCall.apiCall('POST', '/topics/create', data);
		 	$http(
				request
				).then(function successCallback(response) {
				
				$scope.TopicButton = true;
					if(response.data.success){
						$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.forumId);
					}
					else{
						alert("Something went wrong. Please try after sometime.");
					}
				}, function errorCallBack(response) {
				//console.log("Error: ",response);
			});

		 }
	}

	$scope.goBack = function() {
		//console.log("redirect back page");
		$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.forumId);
	}
	
if((!$routeParams.categoryId) && (!$routeParams.subcatId) && (!$routeParams.forumId)) {
		$location.path('debate');	
	} else {
		$scope.forumDetails();
	}
	


	
}]);
