'use strict';
angular.module('myApp.trackFiveMinQuizAns', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/trackFiveMinQuizAns', {
        templateUrl: 'app/trackFiveMinQuizAns/trackFiveMinQuizAns.html',
        controller: 'trackFiveMinQuizAnsCtrl'
      });
    }
  ])
  .controller('trackFiveMinQuizAnsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$routeParams',
    '$q',
    "growl",
    function ($scope, ngDialog, $rootScope, MyService, $routeParams, $q, growl) {

      $scope.User = {
        from: "",
        to: "",
      };

      $scope.showAns = true;
      $scope.showQue = true;

      /**
       * 
       * @param {*} params 
       * @description Get list of other quizzes
       */

      $scope.getOtherQuizzes = function (params) {
        MyService.getListOfOtherQuizzes(params).then(function (APIResponse) {
          MyService.ConsoleLog("APIResponse >>> ", APIResponse);
          // if (APIResponse && APIResponse.data && APIResponse.data.success) {
          $scope.otherQuizzes = APIResponse.data.data;
          // } else {
          //   growl.error('Record not found for other quizzes');
          // }
        }, function (err) {
          MyService.ConsoleLog("err", err);
        });
      };

      /**
       * 
       * @param {*} params 
       * @description Get list of register user's answers of five minute quiz.
       */

      $scope.getListOfFiveMinQuiz = function (params) {

        MyService.getListOfFiveMinQuiz(params).then(function (APIResponse) {
          MyService.ConsoleLog('APIResponse', APIResponse);
          if (APIResponse && APIResponse.data && APIResponse.data.success) {
            $scope.userAnswers = APIResponse.data.data;
          } else {
            growl.error('Record not found for register users');
          }
          $scope.getOtherQuizzes(params);
        }, function (err) {
          MyService.ConsoleLog('err', err);
        });


      };

      /**
       * @description Function to get guest users answers of five minute quiz.
       *  
       */
      $scope.searchRecords = function () {
        MyService.ConsoleLog("$scope.User", $scope.User);

        if ($scope.User && $scope.User.from && $scope.User.from != '' && $scope.User.to && $scope.User.to != '') {

          var fromDate;
          if ($scope.User.from && $scope.User.from != "") {
            var month = ("0" + ($scope.User.from.getMonth() + 1)).slice(-2);
            var dat = ("0" + $scope.User.from.getDate()).slice(-2);
            fromDate = $scope.User.from.getFullYear() + "-" + month + "-" + dat;
            MyService.ConsoleLog("fromDate >>", fromDate);
          }
          var toDate;
          if ($scope.User.to && $scope.User.to != "") {
            var smonth = ("0" + ($scope.User.to.getMonth() + 1)).slice(-2);
            var sdat = ("0" + $scope.User.to.getDate()).slice(-2);
            toDate = $scope.User.to.getFullYear() + "-" + smonth + "-" + sdat;
            MyService.ConsoleLog("toDate >>", toDate);
          }

          MyService.getGuestFiveMinAns({
            from: fromDate,
            to: toDate
          }).then(function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            if (APIResponse && APIResponse.length > 0) {
              $scope.guestAnswers = APIResponse;
              $scope.getListOfFiveMinQuiz({
                from: fromDate,
                to: toDate
              });
            } else {
              $scope.getListOfFiveMinQuiz({
                from: fromDate,
                to: toDate
              });
              growl.error('Records not found for this search');
            }

          }, function (err) {
            growl.error(err);
          });
        } else {
          MyService.ConsoleLog("Params missing");
          growl.error('Please select date range');
        }
      };



      /**
       * 
       * @param {*} ans 
       * @param {*} i 
       * @description Function is use to apply dynamic css class
       */

      $scope.getComapreClass = function (ans, i) {

        if (ans == 1)
          return 'ag-dg1';
        if (ans == 2)
          return 'ag-dg2';
        if (ans == 3)
          return 'ag-dg3';
        if (ans == 4)
          return 'ag-dg4';
        if (ans == 5)
          return 'ag-dg5';
        if (ans == 6)
          return 'ag-dg6';

        return 'ag-dg';
      };

    }
  ]);
