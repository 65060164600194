'use strict';

angular.module('myApp.boardsofelections', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/boardsofelections', {
         templateUrl: 'app/boardsofelections/boardsofelections.html',
         controller: 'boardsofelectionsCtrl' 
     });
}])
.controller('boardsofelectionsCtrl', ['$scope','$rootScope', function($scope,$rootScope) {
$rootScope.welcome = false;
}]);
