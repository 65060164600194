'use strict';
angular.module('myApp.uploadBallotMeasures', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/uploadBallotMeasures', {
      templateUrl: 'app/uploadBallotMeasures/uploadBallotMeasures.html',
      controller: 'uploadBallotMeasuresCtrl'
    });
  }]).controller('uploadBallotMeasuresCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce) {

    $scope.ballot = {
      state: ''
    };
    $scope.showLoader = false;
    $scope.errorFile = "";
    $scope.uploadBallotMeasures = function (data) {
      $scope.PoliticianButton = true;
      if (data && data.files != "") {
        //MyService.ConsoleLog(data.files.type.indexOf("office"));
        if (data.files.type.indexOf("office") > -1) {
          //MyService.ConsoleLog("data : ", data);
          var fd = new FormData();
          fd.append('file', data.files);

          var posturl = baseApiUrl + "/user/uploadBallotMeasures";
          $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            })
            .then(function (response, status, headers, config) {
              //MyService.ConsoleLog("response : ", response);
              $scope.PoliticianButton = false;
              $scope.upload.files = "";
              $scope.upload.opt = "";
              if (response.data.success) {
                //$location.path('/learnHome');
                MyService.ConsoleLog("data", response);
                $scope.errorFile = response.data.errorfile;
                $scope.PoliticianButton = false;
                //$scope.getAllPolitician();
                alert("File successfully imported.");
              } else {
                alert("Something went wrong.");
              }
            }, function (data, status, headers, config) {
              $scope.PoliticianButton = false;
              //MyService.ConsoleLog("error");
            });
        } else {
          $scope.PoliticianButton = false;
          alert("Please select xlsx file");
        }
      } else {
        $scope.PoliticianButton = false;

        alert("Please select file");
      }
    };

    /**
     * Get All state
     */
    $scope.allLocations = [];

    $scope.getAllStates = function () {
      $scope.allLocations.length = 0;
      MyService.getAllStates().then(function (data) {
        //MyService.ConsoleLog("got states");
        $scope.allLocations = data;
      }, function (err) {
        //MyService.ConsoleLog("got states error");
        //growl.error("Some");
      });
    };

    /**
     * Get ballot measures statewise
     */
    $scope.getBallotMeasuresStateWise = function (state) {
      MyService.ConsoleLog("state", state);
      $scope.showLoader = true;
      // MyService.ConsoleLog("state", state.statePostalCode);
      $scope.sdata = JSON.parse(state);
      MyService.getBallotMeausres({
        statePostalCode: $scope.sdata.statePostalCode
      }).then(function (response) {
        console.log("response", response);
        $scope.ballotData = response.data.data;
        $scope.showLoader = false;
      }, function (err) {
        console.log("Error", err);
      });
    };

    /**
     * Show pop up for the text
     */
    $scope.MyDialog1 = "";
    $scope.showHtmlViewer = function (content, ttl) {
      // console.log(content);
      $scope.displayContent = $sce.trustAsHtml(content.replace(/(\r\n|\n|\r)/g, "<br />"));
      $scope.MyDialog1 = ngDialog.open({
        template: 'ballotText',
        scope: $scope
        // className: 'ngdialog-theme-default'
      });
    };

    /**
     * Download Ballot measures
     */
    $scope.ShowData = "";
    $scope.showExportLink = true;
    $scope.exportData = function () {
      //alert($scope.StatePostCode);
      $scope.showExportLink = false;
      MyService.exportBallotMeasures({
        "state": $scope.sdata.statePostalCode
      }).then(function (payload) {
        //MyService.ConsoleLog("Success Data", payload);
        $scope.showExportLink = true;
        $window.open($scope.imgUrl + '/ballotSample.xlsx', '_blank');
      }, function (errorPayload) {
        $scope.showExportLink = true;
        //MyService.ConsoleLog("errorPayload : ", errorPayload);
        alert("Something went wrong. Please try again after sometime");
      })
    };

    /**
     * Redirect to the edit page
     */
    $scope.editBill = function (u) {
      $location.path('/editBallotMeasures/' + u._id);
    };

    /**
     * Select ballot measures
     */
    $scope.isSelected = false;
    $scope.SelectChecks = function () {
      //alert($scope.isSelected);
      if ($scope.isSelected == false) {
        $scope.isSelected = true;
        angular.forEach($scope.ballotData, function (val, key) {
          val.checked = true;
        });
      } else {
        $scope.isSelected = false;
        angular.forEach($scope.ballotData, function (val, key) {
          val.checked = false;
        });
      }
      //$scope.isSelected = true;
    };

    /**
     * Delete selected ballot measures
     */
    $scope.rmBoth = false;
    $scope.deleteSelected = function () {
      var tempArr = [];
      var len = $scope.ballotData.length;
      var j = 0;

      angular.forEach($scope.ballotData, function (val, key) {
        j++;
        if (val.checked) {
          tempArr.push(val._id);
        }
        if (j == len) {
          //MyService.ConsoleLog(tempArr);
          if (tempArr.length > 0) {
            var postparam = {
              "user_ids": tempArr.join(",")
            };
            MyService.ConsoleLog("postparam", postparam);
            MyService.removeBallotMeasures(postparam).then(function (result) {
              MyService.ConsoleLog("Results", result);
              growl.success(result.data.data);
              $scope.getBallotMeasuresStateWise(JSON.stringify($scope.sdata));
            }, function (err) {
              MyService.ConsoleLog("Error", err);
            });
          } else {
            growl.error('Please select atleast one record to delete.');
          }
        }

      });
    };
    $scope.getAllStates();
  }]);
