"use strict";
angular
  .module("myApp.sendInvitations", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/sendInvitations", {
        templateUrl: "app/sendInvitations/sendInvitations.html",
        controller: "sendInvitationsCtrl",
      });
    },
  ])
  .controller("sendInvitationsCtrl", [
    "$scope",
    "ngDialog",
    "MyService",
    "growl",
    function ($scope, ngDialog, MyService, growl) {
      $scope.loader = false;
      $scope.allState = [];
      $scope.address = {
        state: "",
        upstate: "",
        files: "",
        edate: "",
      };
      $scope.Showloader = false;
      $scope.getAllStates = function () {
        $scope.allState.length = 0;
        $scope.loader = true;
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            console.log("payload : ", payload);
            $scope.allState = payload;
            var addNwState = payload;
            var smpl = {
              state: "NA",
              statePostalCode: "NA",
            };
            addNwState.unshift(smpl);
            $scope.loader = false;
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.loader = false;
          }
        );
      };
      $scope.getAllStates();

      /**
       * @description Get the election dates record
       */

      $scope.getElectionDates = function () {
        MyService.getListOfDates().then(function (APIResponse) {
          MyService.ConsoleLog("APIResponse > ", APIResponse);
          $scope.electionDates = APIResponse.data.data.map(function (
            electionDate
          ) {
            return new Date(electionDate).toISOString().split("T")[0];
          });
        });
      };

      $scope.sendInvitations = function () {
        $scope.Showloader = true;

        MyService.ConsoleLog("$scope.address > ", $scope.address);
        const argument = {
          state: $scope.address.state.state,
          statePostalCode: $scope.address.state.statePostalCode,
          electionDate: $scope.address.eDate,
        };
        MyService.sendInvitations(argument).then(function (apiResponse) {
          MyService.ConsoleLog("apiResponse > ", apiResponse);
          if (apiResponse && apiResponse.data.success) {
            growl.success(apiResponse.data.data);
            $scope.Showloader = false;
          } else {
            growl.error("Something went wrong");
            $scope.Showloader = false;
          }
        });
      };
    },
  ]);
