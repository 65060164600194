'use strict';
angular.module('myApp.polFinance', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/polFinance/:id/:type?', {
      templateUrl: 'app/polFinance/polFinance.html',
      controller: 'polFinanceCtrl'
    });
  }])
  .controller('polFinanceCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

    $scope.userId = $routeParams.id;
    $rootScope.welcome = false;
    $scope.loginUser = tokenService.getUserId();
    $scope.politicianUser = 'false';
    //MyService.ConsoleLog($routeParams.type);
    if ($routeParams.type) {
      $scope.politicianUser = $routeParams.type;
    }

    if (tokenService.getUserData()) {
      $scope.UserData = JSON.parse(tokenService.getUserData());
    }
    //MyService.ConsoleLog($routeParams.id);
    $scope.userDetails = {};
    $scope.userProfile = {};
    $scope.statusGetData = 0;
    $scope.propublicaData = [];
    $scope.memberRole = [];
    //$scope.memberRoleCommittee = [];
    //$scope.committeeData = [];
    $scope.currentRole = 0;
    $scope.allRoles = 1;
    $scope.showSponsoredContent = false;
    $scope.sponsorData = [];
    $scope.industrySupport = [];
    $scope.atLarge = '';
    $scope.memberCrpId = '';
    $scope.personalHoldingData = [];
    $scope.fundRaisingData = [];
    $scope.topContributorData = [];
    $scope.currentContent = '';
    $scope.allroles = [];
    $scope.committeesYears = [];
    $scope.contYear = ((new Date()).getFullYear()).toString();
    $scope.supportYear = (new Date()).getFullYear();
    $scope.contYearArray = [];
    $scope.selectedYear = '0';
    $scope.selectedYearCommittee = '0';
    for (var x = 0; x < 15; x++) {
      $scope.contYearArray.push($scope.contYear - x);
    }
    MyService.ConsoleLog("@@@@ ", $scope.contYearArray);
    $scope.getData = function() {
      //MyService.ConsoleLog("politicianUser:",$scope.politicianUser);
      $scope.statusGetData = 1;
      MyService.getUserPublicProfile({ id: $routeParams.id, type: $scope.politicianUser }).then(function(response) {
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          MyService.ConsoleLog("userDetails : ", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          $scope.statusGetData = 2;
        } else {
          $scope.statusGetData = 3;
        }
      }, function(errorPayload) {
        $scope.statusGetData = 3;
      });
    }

    $scope.addtoFollow = function() {
      var data = {};
      data.userId = $scope.loginUser;
      data.pushId = $routeParams.id;
      MyService.updateUserFollowList(data).then(function(payload) {
          alert("User added to followup list successfully.");
          if ($scope.UserData) {
            $scope.UserData.followlist.push($routeParams.id);
            //MyService.ConsoleLog("udata : ", $scope.UserData);
            tokenService.saveUserData(JSON.stringify($scope.UserData));
          }
        },
        function(errorPayload) {
          alert('Something went wrong ');
        });

    }

    $scope.goBack = function() {
      window.history.back();
    }
/*
    $scope.arrangeComitteeData = function() {
      $scope.committeeData = [];
      var len1 = $scope.memberRoleCommittee.committees.length;
      var len2 = $scope.memberRoleCommittee.subcommittees.length;
      if ($scope.memberRoleCommittee) {
        for (var i = 0; i < len1; i++) {
          var itm = {
            'committeeName': $scope.memberRoleCommittee.committees[i].name,
            'committeeRank': $scope.memberRoleCommittee.committees[i].rank_in_party,
            'committeeBeginDate': $scope.memberRoleCommittee.committees[i].begin_date,
            'committeeEndDate': $scope.memberRoleCommittee.committees[i].end_date,
            'committeeCode': $scope.memberRoleCommittee.committees[i].code,
            'subcommittees': []
          }
          $scope.committeeData.push(itm);
        }

        $timeout(function() {
          for (var j = 0; j < $scope.committeeData.length; j++) {
            for (var k = 0; k < len2; k++) {
              if ($scope.committeeData[j].committeeCode == $scope.memberRoleCommittee.subcommittees[k].parent_committee_id) {

                var sub = {
                  'scommitteeName': $scope.memberRoleCommittee.subcommittees[k].name,
                  'scommitteeRank': $scope.memberRoleCommittee.subcommittees[k].rank_in_party,
                  'scommitteeBeginDate': $scope.memberRoleCommittee.subcommittees[k].begin_date,
                  'scommitteeEndDate': $scope.memberRoleCommittee.subcommittees[k].end_date,
                  'scommitteeParentId': $scope.memberRoleCommittee.subcommittees[k].parent_committee_id,
                  'scommitteeCode': $scope.memberRoleCommittee.subcommittees[k].code
                }

                $scope.committeeData[j].subcommittees.push(sub);
                //$scope.committeeData.push(itm);
              }
            }
          }
        }, 2000);
        MyService.ConsoleLog("committeeData : ", $scope.committeeData);



      }
    }
    $scope.getCommitteesYears = function() {
      MyService.ConsoleLog('getCommitteesYears');
      var len = $scope.propublicaData.roles.length;
      var yr = '';
      if (len > 0) {
        for (var i = 0; i < len; i++) {
          if ($scope.propublicaData.roles[i].committees.length > 0 || $scope.propublicaData.roles[i].subcommittees.length > 0) {
            switch ($scope.propublicaData.roles[i].congress) {
              case '116':
                yr = '2019 - 2021';
                break;
              case '115':
                yr = '2017 - 2018';
                break;
              case '114':
                yr = '2015 - 2016';
                break;
              case '113':
                yr = '2013 - 2014';
                break;
              case '112':
                yr = '2011 - 2012';
                break;
              case '111':
                yr = '2009 - 2010';
                break;
              case '110':
                yr = '2007 - 2008';
                break;
              case '109':
                yr = '2005 - 2006';
                break;
              case '108':
                yr = '2003 - 2004';
                break;
              case '107':
                yr = '2001 - 2002';
                break;
              case '106':
                yr = '1999 - 2000';
                break;
              case '105':
                yr = '1997 - 1998';
                break;
              case '104':
                yr = '1995 - 1996';
                break;
              case '103':
                yr = '1993 - 1994';
                break;
              case '102':
                yr = '1991 - 1992';
                break;
              case '101':
                yr = '1989 - 1990';
                break;
              case "default":
                break;
            }
            var itm = {
              'year': yr,
              'id': i,
              'committeesid': i,
              'congress': $scope.propublicaData.roles[i].congress
            }
            $scope.committeesYears.push(itm);
          }
        }
      }
    }
    
    */
    $scope.getProbublicaData = function() {
      MyService.ConsoleLog("In getProbublicaData");
      var data = {};
      data.id = $routeParams.id;
      data.type = $scope.politicianUser;
      MyService.ConsoleLog("data : ");
      MyService.getProbublicaData(data).then(function(payload) {
          MyService.ConsoleLog("Success : ", payload);
          if (payload) {
            $scope.propublicaData = payload.data.data;
            $scope.memberCrpId = $scope.propublicaData.crp_id;
            $scope.getIndustrySupport($scope.contYear);
            MyService.ConsoleLog(typeof($scope.propublicaData.roles));
            // if (typeof($scope.propublicaData.roles[0]) == 'string') {
            //   $scope.propublicaData.roles = JSON.parse($scope.propublicaData.roles);
            //   $scope.memberRole = $scope.propublicaData.roles[$scope.currentRole];
            // } else {
            var len = $scope.propublicaData.roles.length;
            var yr = ''
            for (var i = 0; i < len; i++) {
              switch ($scope.propublicaData.roles[i].congress) {
                case '116':
                  yr = '2019 - 2021';
                  break;
                case '115':
                  yr = '2017 - 2018';
                  break;
                case '114':
                  yr = '2015 - 2016';
                  break;
                case '113':
                  yr = '2013 - 2014';
                  break;
                case '112':
                  yr = '2011 - 2012';
                  break;
                case '111':
                  yr = '2009 - 2010';
                  break;
                case '110':
                  yr = '2007 - 2008';
                  break;
                case '109':
                  yr = '2005 - 2006';
                  break;
                case '108':
                  yr = '2003 - 2004';
                  break;
                case '107':
                  yr = '2001 - 2002';
                  break;
                case '106':
                  yr = '1999 - 2000';
                  break;
                case '105':
                  yr = '1997 - 1998';
                  break;
                case '104':
                  yr = '1995 - 1996';
                  break;
                case '103':
                  yr = '1993 - 1994';
                  break;
                case '102':
                  yr = '1991 - 1992';
                  break;
                case '101':
                  yr = '1989 - 1990';
                  break;
                case "default":
                  break;
              }
              var itm = {
                'year': yr,
                'id': i,
                'committeesid': i,
                'congress': $scope.propublicaData.roles[i].congress
              }
              $scope.allroles.push(itm);
            }
            //alert("allroles : ", $scope.allroles[0].id);
            $scope.getCommitteesYears();
            $scope.memberRole = $scope.propublicaData.roles[$scope.currentRole];
            $scope.memberRoleCommittee = $scope.propublicaData.roles[$scope.currentRole];
            //}
            $timeout(function() {
              $scope.arrangeComitteeData();
            }, 1000);

            if ($scope.memberRole.at_large) {
              if ($scope.memberRole.at_large == true) {
                $scope.atLarge = 'At large';
              } else {
                $scope.atLarge = "";
              }
            }
            $scope.allRoles = $scope.propublicaData.roles.length;
            //MyService.ConsoleLog("allRoles : ", $scope.allRoles);
            MyService.ConsoleLog("$scope.propublicaData : ", $scope.propublicaData);
          }
        },
        function(errorPayload) {
          MyService.ConsoleLog('Something went wrong : ', errorPayload);
        });
    }
/*
    $scope.getBillCosponsored = function(id) {
      MyService.ConsoleLog("In getBillCosponsored :" + id);
      $location.path("/sponsored/" + id + "/" + $scope.userId + "/" + $routeParams.type);
      /*if ($scope.showSponsoredContent == false) {
        $scope.showSponsoredContent = true;
        var data = {};
        data.id = id;
        MyService.getBillCosponsoredData(data).then(function(data) {
          MyService.ConsoleLog("getBillCosponsored success : ", data);
          $scope.sponsorData = data.data.data.results[0].bills;
        }, function(err) {
          MyService.ConsoleLog("getBillCosponsored error : ", err);
        });
      } else {
        $scope.showSponsoredContent = false;
      }*/
/*    }

    $scope.getVotingRecords = function(id) {
      $location.path("/votingRecords/" + id + "/" + $scope.userId + "/" + $routeParams.type);
      /*if ($scope.showSponsoredContent == false) {
        $scope.showSponsoredContent = true;
        var data = {};
        data.id = id;
        MyService.getVotingRecords(data).then(function(data) {
          MyService.ConsoleLog("getVotingRecords success : ", data);
          //$scope.sponsorData = data.data.data.results[0].bills;
        }, function(err) {
          MyService.ConsoleLog("getVotingRecords error : ", err);
        });
      } else {
        $scope.showSponsoredContent = false;
      }*/
 //   }

    $scope.getIndustrySupport = function(yr) {
      MyService.ConsoleLog("In getIndustrySupport : ");
      if ($scope.memberCrpId) {
        var data = {}; 
        data.crpid = $scope.memberCrpId;
        data.year = yr;
        MyService.getIndustryData(data).then(function(res) {
          MyService.ConsoleLog("res : ", res);
          $scope.industrySupport = res.data.data.industries;
          $scope.currentContent = 'industrysupport';
          var cy = (new Date()).getFullYear();
          if ($scope.industrySupport['@attributes'].cycle == cy && yr != cy) {
            $scope.msg = "There are no records for " + yr + "";
            $scope.industrySupport = [];
          } else {
            $scope.msg = "";

          }
          //$scope.supportYear = $scope.industrySupport['@attributes'].cycle - 2;
        }, function(err) {
          MyService.ConsoleLog("err : ", err);
          $scope.supportYear = (new Date()).getFullYear();
        });
      }
    }

    $scope.personalHoldings = function() {
      MyService.ConsoleLog("In personalHoldings : " + $scope.memberCrpId);
      if ($scope.memberCrpId) {
        var data = {};
        data.crpid = $scope.memberCrpId;
        MyService.getPersonalHoldings(data).then(function(res) {
          MyService.ConsoleLog("personalHoldings res : ", res);
          $scope.personalHoldingData = res.data.data;

          $scope.currentContent = 'personalHoldings';
        }, function(err) {
          MyService.ConsoleLog("err : ", err);
        });
      }
    }
    $scope.fundRaising = function() {
      MyService.ConsoleLog("In fundRaising : " + $scope.memberCrpId);
      if ($scope.memberCrpId) {
        var data = {};
        data.crpid = $scope.memberCrpId;
        MyService.getFundRaising(data).then(function(res) {
          MyService.ConsoleLog("fundRaising res : ", res);
          $scope.fundRaisingData = res.data.data.summary['@attributes'];
          $scope.currentContent = 'fundRaising';
        }, function(err) {
          MyService.ConsoleLog("err : ", err);
        });
      }
    }

    $scope.topContributor = function(yr) {
      MyService.ConsoleLog("In topContributor : " + yr);
      $scope.contYear = yr;
      if ($scope.memberCrpId) {
        var data = {};
        data.crpid = $scope.memberCrpId;
        data.year = yr;
        MyService.getTopContributor(data).then(function(res) {
          MyService.ConsoleLog("topContributor res : ", res);
          $scope.topContributorData = res.data.data.contributors;
          var cy = (new Date()).getFullYear();
          if ($scope.topContributorData['@attributes'].cycle == cy && yr != cy) {
            $scope.msg = "There are no records for " + yr + "";
            $scope.topContributorData = [];
          } else {
            $scope.msg = "";

          }
          //$scope.contYear = $scope.topContributorData['@attributes'].cycle - 2;
        }, function(err) {
          MyService.ConsoleLog("err : ", err);
          $scope.contYear = (new Date()).getFullYear();
        });
      }
    }

    if ($routeParams.id != "") {
      $scope.getData();
      $scope.getProbublicaData();
      $timeout(function() {
        $scope.topContributor($scope.contYear);
      }, 3000);
    }

    $scope.showPreviousYearRole = function(id, type) {
      MyService.ConsoleLog("current role : " + $scope.currentRole);
      MyService.ConsoleLog("allRoles : " + $scope.allRoles);
      //if ($scope.currentRole < $scope.allRoles) {
      $scope.currentRole = $scope.currentRole + 1;
      if (type == 'legislation') {
        $scope.memberRole = $scope.propublicaData.roles[id];
      } else {
        $scope.memberRoleCommittee = $scope.propublicaData.roles[id];
        $scope.arrangeComitteeData();
      }
      /*if ($scope.memberRole.at_large) {
        if ($scope.memberRole.at_large == true) {
          $scope.atLarge = 'At large';
        } else {
          $scope.atLarge = "";
        }
      }*/
      //}
    }

    $scope.matchParty = function(letter) {
      if (letter == 'D') {
        return 'Democrat';
      } else if (letter == 'I') {
        return 'Independent'
      } else if (letter == 'R') {
        return 'Republican'
      } else {
        return 'Other';
      }

    }
  }]);
