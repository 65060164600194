'use strict';
angular.module('myApp.match', ['ngRoute', 'ngDialog'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/match/:categoryId', {
    templateUrl: 'app/match/match.html',
    controller: 'matchCtrl'
  });
}])
.controller('matchCtrl', ['$scope','$http','$location','apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope) {
	//MyService.ConsoleLog("** matchCtrl ** :",$routeParams);
	
	$scope.currentIndex = 0;
	$rootScope.welcome = false;
	$scope.initializeVariables = function(){
		$scope.importance = null;
		$scope.answer = null;
		$scope.comment = '';
	};
	$scope.initializeVariables();

	
	function getQuestions(params) {
	  var request = apiCall.apiCall('GET', '/questions/list', params);
	  $http(
	    request
	  ).then(function successCallback(response) {
		//MyService.ConsoleLog("Got categories: ",response);
		if(response.data.success){
			$scope.questions = response.data.data;
			//MyService.ConsoleLog("total questions: ", $scope.questions.length);
		}
		else{
			//MyService.ConsoleLog("Something went wrong: ",response.data);
		}
	  }, function errorCallBack(response) {
	    //MyService.ConsoleLog("Error: ",response);
	  });
	}

	function getCategory(params) {
	  var request = apiCall.apiCall('GET', '/categories/list', params);
	  $http(
	    request
	  ).then(function successCallback(response) {
		//MyService.ConsoleLog("Got categories: ",response);
		if(response.data.success){
			$scope.category = response.data.data;
		}
		else{
			//MyService.ConsoleLog("Something went wrong: ",response.data);
		}
	  }, function errorCallBack(response) {
	    //MyService.ConsoleLog("Error: ",response);
	  });
	}

	function getPopularQuestions(){
		var categoryIds = $routeParams.categoryId.split("popular:")[1];

		var request = apiCall.apiCall('GET', '/answers/popular', {"categoryIds": categoryIds});
		$http(
			request
		).then(function successCallback(response) {
			//MyService.ConsoleLog("Got PopularQuestions: ",response);
			if(response.data.success){
				$scope.questions = response.data.data;
			}
			else{
				//MyService.ConsoleLog("Something went wrong: ",response.data);
			}
		}, function errorCallBack(response) {
			//MyService.ConsoleLog("Error: ",response);
		});
	}

	$scope.notAnswerableSelected = function(){
		if($scope.answer == 0){
			var message = 'Many of our Statements have a fact followed by an opinion.  Like “the sky is blue.  I hate the sky.”  If this is your problem with the question, then realize that this is just part of the question process and just respond to the second half.  If not, please explain the problem in the “add comments” section so we can fix it.  Thank you.';
			var modal = ngDialog.openConfirm({
		    	template:'<p>'+message+'</p>\
			            <div class="ngdialog-buttons">\
		                	<button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
			            </div>',
			    plain: true
			});
			
			modal.then(function fullfilled(data){
				//MyService.ConsoleLog("fullfilled: ",data);
			}, function rejected(data){
				//MyService.ConsoleLog("rejected: ",data);
			});
		}
	};

	$scope.postAnswer = function(postData){
		//MyService.ConsoleLog("postData: ",postData);

	    var request = apiCall.apiCall('POST', '/answers/create', postData);
	    $http(
	      request
	    ).then(function successCallback(response) {
	      //MyService.ConsoleLog("succss: ",response);
	      if(response.data.success){
	        alert("Answered successfully");
	        $scope.initializeVariables();
	      }
	      else{
	        alert("Error: "+response.data.error);
	      }
	    }, function errorCallback(response) {
	      $scope.error = 'Wrong Email or Password';
	    });
	    
	};

	$scope.submitAnswer = function(){
		//MyService.ConsoleLog("submitAnswer: "+$scope.answer);
		//MyService.ConsoleLog("importance: "+$scope.importance);
		//MyService.ConsoleLog("comments: ", $scope.comment);

		var token = apiCall.getToken();

		if(!token || token == ""){
			var message = "Please login first.";

			var modal = ngDialog.openConfirm({
		    	template:'<p>'+message+'</p>\
			            <div class="ngdialog-buttons">\
		                	<button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
			            </div>',
			    plain: true
			});
			
			modal.then(function fullfilled(data){
				//MyService.ConsoleLog("fullfilled: ",data);
			}, function rejected(data){
				//MyService.ConsoleLog("rejected: ",data);
			});
			
			return;
		}

		if($scope.answer == null){
			
			var message = "Please select answer first.";

			var modal = ngDialog.openConfirm({
		    	template:'<p>'+message+'</p>\
			            <div class="ngdialog-buttons">\
		                	<button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
			            </div>',
			    plain: true
			});
			
			modal.then(function fullfilled(data){
				//MyService.ConsoleLog("fullfilled: ",data);
			}, function rejected(data){
				//MyService.ConsoleLog("rejected: ",data);
			});
			
			return;
		}

		if($scope.importance == null){
			var message = "You need to choose an importance level.  Should they fight hard for this can they use it to trade for other things you care about more?";

			var modal = ngDialog.openConfirm({
		    	template:'<p>'+message+'</p>\
			            <div class="ngdialog-buttons">\
		                	<button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
			            </div>',
			    plain: true
			});
			
			modal.then(function fullfilled(data){
				//MyService.ConsoleLog("fullfilled: ",data);
			}, function rejected(data){
				//MyService.ConsoleLog("rejected: ",data);
			});

			return;
		}

		/*
		var modal = ngDialog.openConfirm({
		    template:'<p>Are you sure you want to close the parent dialog?</p>\
		              <div class="ngdialog-buttons">\
	                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
	                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
		               </div>',
		    plain: true
		});
		//MyService.ConsoleLog("modal: ",modal);
		modal.then(function fullfilled(data){
			//MyService.ConsoleLog("fullfilled: ",data);
		}, function rejected(data){
			//MyService.ConsoleLog("rejected: ",data);
		});
		*/
		var postData = {
			questionId: $scope.questions[$scope.currentIndex]._id,
			importance: $scope.importance,
			answer: $scope.answer,
			comment: $scope.comment,
			token: token
		};
		$scope.postAnswer(postData);
	};

	$scope.skipQuestion = function(){
		var skip = function(){
			var tempIndex = $scope.currentIndex+1;
			if(tempIndex < $scope.questions.length){
				$scope.initializeVariables();
				$scope.currentIndex++;
			}
		};

		if($scope.answer != null){
			var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
			var modal = ngDialog.openConfirm({
		    	template:'<p>'+message+'</p>\
			            <div class="ngdialog-buttons">\
		                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
	                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
			            </div>',
			    plain: true
			});
			
			modal.then(function fullfilled(data){
				//submit answer
				$scope.submitAnswer();
			}, function rejected(data){
				//skip the answer
				skip();
			});
		}
		else{
			skip();
		}
	};

	$scope.prevQuestion = function(){
		if($scope.currentIndex > 0){
			$scope.currentIndex--;
		}
	};

	$scope.reportQuestion = function(){
		//MyService.ConsoleLog("Report questions");
	};

	$scope.compareChanged = function(compare_with){
		//MyService.ConsoleLog("compare_with :",compare_with);
		$location.path('/compare-select/'+compare_with+"/"+$routeParams.categoryId);
	};

	var current_url = $location.url();
	//MyService.ConsoleLog("$location.url: ", current_url);

	if(current_url.indexOf("question-compare") > -1){
		$scope.state = "question-compare";
		if($routeParams.categoryId == 'all'){
			//MyService.ConsoleLog("compare all answers");	
			try{
				var user_ids = []
				angular.forEach($rootScope.selectedCompareUsers, function(val, key){
					user_ids.push(val._id);
				});
				//MyService.ConsoleLog("user_ids: ", user_ids);

				var req = apiCall.apiCall('GET', '/answers/compare', {"userIds": user_ids.join(",")});
				$http(
					req
				).then(function successCallback(response) {
					//MyService.ConsoleLog("Got categories: ",response);
					if(response.data.success){
						$scope.questions = response.data.data;
						//MyService.ConsoleLog("total questions: ", $scope.questions.length);
					}
					else{
						//MyService.ConsoleLog("Something went wrong: ",response.data);
					}
				}, function errorCallBack(response) {
					//MyService.ConsoleLog("Error: ",response);
				});
			}
			catch(e){
				//MyService.ConsoleLog("Exceptoin: ",e);
			}
		} // traverse all questions
		else{
			
			//MyService.ConsoleLog("** question-compare **");
			//MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);
			$scope.questions = $rootScope.compareAnswerList;
			getCategory({id: $routeParams.categoryId});	
		}
	}
	else{
		$scope.state = "question";
		if($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0){
			getPopularQuestions();
		}
		else{
			getQuestions({categoryId: $routeParams.categoryId});
			getCategory({id: $routeParams.categoryId});
		}
	}
}]);
