'use strict';
angular.module('myApp.localPress', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/localpress', {
    templateUrl: 'app/localPress/localPress.html',
    controller: 'localPressCtrl'
  });
}])

.controller('localPressCtrl', ['$scope','$rootScope','$http','$location','apiCall', function ($scope, $rootScope, $http, $location, apiCall) {
$rootScope.welcome = false;
}]);
