'use strict';
angular
  .module('myApp.showFollowersQuiz', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/showFollowersQuiz/:quizName?/:orgId?', {
        templateUrl: 'app/showFollowersQuiz/showFollowersQuiz.html',
        controller: 'showFollowersQuizCtrl',
      });
    },
  ])
  .controller('showFollowersQuizCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      $scope.quizName = $routeParams.quizName;
      $scope.orgId = $routeParams.orgId;


      /**
       * get Quiz questions count
       */
      $scope.getQueCount = function () {
        MyService.getQuesCount({
          quizName: $scope.quizName,
          orgId: $scope.orgId,
          curtUId: tokenService.getUserId()
        }).then(function (response) {
          MyService.ConsoleLog("Response", response);
          $scope.totalQues = response.data.data.totalQuestions;
          $scope.ansQues = response.data.data.answeredQuestions;
          $scope.orgName = response.data.data.orgName;
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      $scope.getQueCount();

      /**
       * Redirect to the quiz
       */
      $scope.getQuiz = function () {

        // $location.path('/organizationQuiz/' + $scope.quizName + "/" + $scope.orgId);
        $location.path('/followersQuiz/' + $scope.quizName + "/" + $scope.orgId);

      };
    }
  ]);
