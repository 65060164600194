'use strict';
angular
  .module('myApp.getBillVoteRecords', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/getBillVoteRecords/:id/:type?', {
        templateUrl: 'app/getBillVoteRecords/getBillVoteRecords.html',
        controller: 'getBillVoteRecordsCtrl'
      });
    }
  ])
  .controller('getBillVoteRecordsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    '$timeout',
    '$q',
    '$routeParams',
    function($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q, $routeParams) {
      $scope.userId = $routeParams.id;
      $scope.type = $routeParams.type;
      $scope.show = false;
      $scope.more = [];
      $scope.documents = [];
      $scope.currPage = 1;
      $scope.alert = false;
      $scope.userProfile = [];
      if ($rootScope.userData) {
        $scope.userProfile = $rootScope.userData;
      }
      $scope.activeSpo = true;
      $scope.activeAct = true;
      $scope.activeDoc = true;
      $scope.activeVot = true;
      $scope.showLoader = false;
      $scope.bill = [];
      $scope.voter = [];


      console.log("typw  >>", $scope.type);
      console.log("id >", $scope.userId);

      $scope.myPagingFunction = function() {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.searchData > $scope.bill.length) {
          $scope.currPage++;
          $scope.pageChanged($scope.currPage);
        }
      };

      $scope.pageChanged = function(newPage) {
        $scope.getBillVotes(newPage);
      };
      $scope.openBills = [];
      $scope.searchCandy = {
        'search': ""
      };
      $scope.searchData = 0;
      $scope.SearchByTitle = function() {
        // MyService.ConsoleLog('in searchMe', $scope.search.sdata);
        // var query = $scope.search.sdata.toUpperCase();
        var pageNumber = 1;
        var params = {
          page: pageNumber,
          max_records: 30,
          title: $scope.searchCandy.search,
          "id": $scope.userId,
          "type": $scope.type
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];

        // $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();
     

        MyService.ConsoleLog('in searchMe', $scope.currPage);

        MyService.SearchByTitle(params, getAllCanceller).then(
          function(response) {
            MyService.ConsoleLog('response >> ',response);
            $scope.bill.length = 0;
            $scope.bill = response.data;
            $scope.searchData = response.count;
            

          },
          function(error) {
            MyService.ConsoleLog('Error>>', error);
          }
        );
      };

      // $scope.searchMe = function(user) {
      //   // MyService.ConsoleLog('in searchMe', user);
      //   var query = $scope.searchCandy.search.toLowerCase(),
      //     fullname = user.title.toLowerCase();

      //   if (fullname.indexOf(query) != -1) {
      //     return true;
      //   }
      //   return false;
      // };

      $scope.getBillVotes = function(pageNumber) {
        $scope.showLoader = true;

        // $scope.votes.length = 0;
        MyService.ConsoleLog('pageNumber >', pageNumber);
        var params = {
          page: pageNumber,
          max_records: 30,
          "id": $scope.userId,
          "type": $scope.type
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];

        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();
        var calc = (pageNumber - 1) * 30;

        MyService.getBillVotes(params, getAllCanceller).then(
          function(result) {
            console.log("RESPONSE >  ", result[0]);

            $scope.bill = $scope.bill.concat(result[0].billSpoData);
            $scope.searchData = result[0].count;
            console.log("Bills >>", $scope.bill);
            $scope.show = false;
            $scope.showLoader = false;



            $scope.statusGetResPg = 2;
          },
          function(err) {
            growl.error('Something went wrong.');
            $scope.statusGetResPg = 3;
            $scope.page--;
          }
        );
      };

      $scope.getBillVotes(1);
      $scope.myPagingFunctionOne = function() {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 ) {
          $scope.currPage++;
          $scope.pageChangedOne($scope.currPage);
        }
      };


      $scope.pageChangedOne = function(newPage) {
        $scope.getVotes(newPage);
      };

      $scope.searchCandy = {
        'search': ""
      };


      $scope.getVotes = function(pageNumber) {
        $scope.showLoader = true;
        // $scope.bill.length = 0;
        MyService.ConsoleLog('pageNumber >', pageNumber);
        var params = {
          page: pageNumber,
          max_records: 30,
          "id": $scope.userId,
          "type": $scope.type
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];
        $scope.totalUsers = 0;

        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();
        var calc = (pageNumber - 1) * 30;

        MyService.getBillVotes(params, getAllCanceller).then(
          function(result) {
            console.log("RESPONSE >  ", result[0]);

            if (result[0].voterData.length > 0) {
              $scope.votes = $scope.votes.concat(result[0].voterData);
              console.log("Bills >>", $scope.bill);
              $scope.show = true;



              $scope.statusGetResPg = 2;
            } else {
              $scope.show = true;
                  $scope.showLoader = false;

              // alert('else');
              $(document).ready(function() {
                $scope.alert = true;
                setTimeout(function() {
                  $('.alert').alert('close');

                }, 2000);
              });
              $scope.statusGetResPg = 2;

            }
          },
          function(err) {
            growl.error('Something went wrong.');
            $scope.statusGetResPg = 3;
            $scope.page--;
          }
        );
      };

      $scope.getDetailsOfCandidates = function(val) {

        $scope.more[val] = true;
        $scope.documents[val] = true;


      };
      $scope.showLess = function(valOne) {
        $scope.more[valOne] = false;
        $scope.documents[valOne] = false;

      }
    }
  ]);
