'use strict';
angular.module('myApp.discussWithOthers', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/discussWithOthers/:catId?/:subCat?', {
      templateUrl: 'app/discussWithOthers/discussWithOthers.html',
      controller: 'discussWithOthersCtrl'
    });
  }])
  .controller('discussWithOthersCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'baseApiUrl', 'tokenService', 'MyService', 'ngDialog', '$filter', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, baseApiUrl, tokenService, MyService, ngDialog, $filter, growl) {

    /**
     * Get params from the route
     */
    $scope.catId = $routeParams.catId;
    $scope.subCat = $routeParams.subCat;
    $scope.showtxt = false;
    if (tokenService.getUserData()) {
      MyService.ConsoleLog("tokenservice", JSON.parse(tokenService.getUserData()));
      $scope.uAddress = JSON.parse(tokenService.getUserData());
      MyService.ConsoleLog("$aaaaa", $scope.uAddress);
      if (!$scope.uAddress.address.statePostalCode) {
        $scope.addressPopup = ngDialog.open({
          template: 'addressPopUp',
          closeByNavigation: true,
          scope: $scope
        });
      }
    }

    $scope.Location = {
      county: "",
      place: "",
      state: ""
    };

    MyService.ConsoleLog(" $scope.catId", $scope.catId);
    MyService.ConsoleLog("$scope.subCat", $scope.subCat);
    var getLevelCategoryCanceler = $q.defer();

    /**
     * Get Cat
     */
    $scope.getCat = function (params) {

      getLevelCategoryCanceler.resolve();
      getLevelCategoryCanceler = $q.defer();

      var request = apiCall.apiCall('GET', '/categories/list-layer', params);
      request.timeout = getLevelCategoryCanceler.promise;
      $http(request).then(function successCallback(response) {
        MyService.ConsoleLog("response", response);
        $scope.catData = response.data.data;
        angular.forEach($scope.catData, function (val, key) {
          if (val._id == $routeParams.subCat) {

            $scope.currBackgroundIndex = key;
            //MyService.ConsoleLog("$scope.currBackgroundIndex: ", key);
            $scope.subcat = angular.copy(val);
            MyService.ConsoleLog($scope.subcat);
          }
        });
        $scope.getTopics();
      }, function errorCallBack(err) {
        MyService.ConsoleLog("Error: ", err);
        $scope.status.levlCat = 3;
      });

    };

    /**
     * Get records of the topics
     */
    $scope.getTopics = function () {

      MyService.getRecordsOf({
        catId: $scope.catId,
        subCat: $scope.subCat,
        address: JSON.stringify($scope.uAddress.address)
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);

        $scope.tempArr = [];
        $scope.tempArr.push(response[0]);
        $scope.records = response;
      }, function (err) {
        $scope.showtxt = true;
        growl.error(err.error);
        MyService.ConsoleLog("Error", err);
      });
    };
    if (tokenService.getUserId()) {
      var param = {
        id: $routeParams.catId,
      };
      $scope.getCat(param);
    }

    $scope.setAddressNew = function (address) {

      //MyService.ConsoleLog("setAddressNew > ", address);

      // if ($scope.statusGetStates == 2 && $scope.statusGetZipdata == 2) {

      var postobj = {
        userId: tokenService.getUserId(),
        zipcode: "",
        mailingAddress: $scope.mailing_Address
      };

      var add = {};

      if (address.state) {
        add.state = address.state.state;
        add.statePostalCode = address.state.statePostalCode;
        add.stateFips = address.state.stateFips;
        //MyService.ConsoleLog("address.statePostalCode :", address.state);
      }

      if (address.place) {
        add.placeFips = address.place.placeFips;
        add.place = address.place.placeName;
      }

      if (address.county) {
        add.countyFips = address.county.countyFips;
        add.county = address.county.countyName;
      }

      if (address.subcounty) {
        add.countySubdivisionFips = address.subcounty.COUSUBFP;
        add.countySubdivision = address.subcounty.NAME;
      }

      if (address.federalCongrassionalDist) {
        add.federalCongrassionalDist = address.federalCongrassionalDist.CD115FP;
      }

      if (address.stateSenateDistrict) {
        add.stateSenateDistrict = address.stateSenateDistrict.SLDUST;
      }

      if (address.unifiedSchoolDistrictName) {
        add.schoolDistrictFips = address.unifiedSchoolDistrictName.UNSDLEA;
        add.schoolDistrict = address.unifiedSchoolDistrictName.NAME;
      }

      // if ($scope.address.voterDistrict) {
      //   address.voterDistrictFips = $scope.address.voterDistrict.number;
      //   address.voterDistrict = $scope.address.voterDistrict.name;
      // }

      if (address.stateHouseOFRepresentativeDistrict) {
        var str = address.stateHouseOFRepresentativeDistrict.NAMELSAD;
        //MyService.ConsoleLog("address.stateLegislativeDistrict.NAMELSAD >>>>", str);

        var newString = str.substr(0, str.length - 3);
        //MyService.ConsoleLog("newString >>>>", newString);

        switch (newString) {

          case "General Assembly District":
            add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("General Assembly District ", "");
            break;
          case "Assembly District":
            add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("Assembly District ", "");
            break;
          case "State House District":
            add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("State House District ", "");
            break;

          default:
            break;
        }




      }



      var finalLoc = JSON.parse($scope.loc);
      //MyService.ConsoleLog(" $scope.address: ", finalLoc);


      add.loca = {
        "type": "Point",
        "coordinates": [finalLoc.longitude, finalLoc.latitude]
      }

      // alert("got");

      //MyService.ConsoleLog(">>>>>>>>>> add >>>>>>>>>>>: ", address);
      postobj.mailingAddress = $scope.mailing_Address ? $scope.mailing_Address : "";

      postobj.address = JSON.stringify(add);
      //MyService.ConsoleLog("postobj.address: ", postobj);

      MyService.updateAddress(postobj).then(function (data) {
        growl.success("Updated successfully.");
        // $scope.setAddressNew();
        MyService.ConsoleLog("data", data);
        $scope.addressPopup.close();
        // $scope.closeThisDialog();
      }, function (err) {
        growl.error("Something went wrong.");
      });

      // }

    }

    $scope.checkLen = function (ldata, sval) {
      // //MyService.ConsoleLog("vdata : ", vdata);
      //MyService.ConsoleLog("vdata", ldata);
      if (ldata && ldata.length > 0) {
        var data = ldata.map(function (sub) {
          var returnval = "";
          switch (sval) {
            case "federalCongrassionalDist":
              returnval = sub.CD115FP;
              break;
            case "stateSenateDistrict":
              returnval = sub.SLDUST;
              break;
            case "stateHouseOFRepresentativeDistrict":
              returnval = sub.NAMELSAD;
              break;
            case "unifiedSchoolDistrictName":
              returnval = sub.NAME;
              break;
            case "place":
              returnval = sub.place;
              break;
            case "countySubdivision":
              returnval = sub.countySubdivision;
              break;
            case "county":
              returnval = sub.county;
              break;
            case "state":
              returnval = sub.state;
              break;
            case "default":
              returnval = sub.NAME;
              break;
          }
          return returnval;
        });

        var ndata = $filter("unique")(data);
        if (ndata.length > 1) {
          return "yellowBackground";
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    $scope.allcounties = [];

    $scope.getCounties = function (lbl) {
      MyService.ConsoleLog("county >", $scope.oldaddress);
      // $scope.allcounties.length = 0;
      var statePostalCode = "";
      var state = "";
      // if (lbl == "new") {
      //   state = JSON.stringify($scope.oldaddress.state);
      // } else {
      statePostalCode = $scope.oldaddress.state[0].STATEFP;
      // state = $scope.Location.state;
      // }
      // //MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
      MyService.getAllCounties(statePostalCode, state).then(
        function (payload) {
          // //MyService.ConsoleLog("sublocations Locals : ", payload);
          var allcounties = payload;
          if (lbl == "new") {
            var county = $scope.checkCounty(
              allcounties,
              $scope.oldaddress.county
            );
            $scope.allcounties = county;
            MyService.ConsoleLog("county<><", county[0]);
            MyService.ConsoleLog("$scope.Location", $scope.Location);
            $scope.Location.county = county[0];
          }
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
        }
      );
    };
    $scope.MyPlaces = [];
    $scope.getSubCounties = function (lbl) {
      // //MyService.ConsoleLog(" $scope.Location.state :",$scope.Location.state);
      // //MyService.ConsoleLog("$scope.Location.county :",$scope.Location.county);
      // var county = $scope.Location.county;

      $scope.MyPlaces.length = 0;
      var county = "";
      var statePostalCode = "";
      $scope.MyPlaces.length = 0;
      var state = "";
      var county = "";
      if (lbl == "new") {
        state = JSON.stringify($scope.oldaddress.state);
        county = JSON.stringify($scope.oldaddress.county);
      } else {
        statePostalCode = $scope.Location.state.statePostalCode;
        state = $scope.Location.state;
        county = county.county;
      }
      // //MyService.ConsoleLog(statePostalCode + "===" + county);
      MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
        function (payload) {
          // payload = JSON.parse(payload);
          $scope.MyPlaces = payload;
          // //MyService.ConsoleLog("PlaCE :", payload);
          // //MyService.ConsoleLog(payload.countySubdivision);
          var subcounties = payload.countySubdivision;
          var places = payload.places;
          // //MyService.ConsoleLog("PlaCE :", payload);
          if (lbl == "new") {
            // //MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
            var subcounty = $scope.checksubCounty(
              subcounties,
              $scope.oldaddress.countySubdivision
            );
            var places = $scope.checkPlaces(places, $scope.oldaddress.place);
            // //MyService.ConsoleLog("subcounty<><><", subcounty);
            $scope.subcounties = subcounty;
            $scope.places = places;
            // //console.log('***********', places[0]);
            // $scope.Location.countySubdivision = subcounty[0];
            $scope.Location.place = places[0];
          }
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
        }
      );
    };

    $scope.checkState = function (rdata, val) {
      //MyService.ConsoleLog("State :", rdata);
      //MyService.ConsoleLog("State val :", val);
      var sdata = [];
      var len = rdata.length;
      var j = 1;

      for (var i = 0; i < len; i++, j++) {
        var rsdata = rdata[i];
        var gdata = val.filter(function (obj) {
          if (
            rsdata.countyFips == obj.COUNTYFP &&
            rsdata.stateFips == obj.STATEFP
          ) {
            // //MyService.ConsoleLog(rdata[i]);
            return rdata[i];
          }
        });
        if (gdata.length > 0) {
          if (sdata.indexOf(rdata[i]) == -1) {
            sdata.push(rdata[i]);
          }
        }

        if (j == len) {
          // //MyService.ConsoleLog("gdata > ", sdata);
          return sdata;
        }
      }
    };

    $scope.checkCounty = function (rdata, val) {
      //MyService.ConsoleLog("County :", rdata);
      //MyService.ConsoleLog("County val :", val);
      var sdata = [];
      var len = rdata.length;
      var j = 1;

      for (var i = 0; i < len; i++, j++) {
        var rsdata = rdata[i];
        var gdata = val.filter(function (obj) {
          if (
            rsdata.countyFips == obj.COUNTYFP &&
            rsdata.stateFips == obj.STATEFP
          ) {
            // //MyService.ConsoleLog(rdata[i]);
            return rdata[i];
          }
        });
        if (gdata.length > 0) {
          if (sdata.indexOf(rdata[i]) == -1) {
            sdata.push(rdata[i]);
          }
        }

        if (j == len) {
          // //MyService.ConsoleLog("gdata > ", sdata);
          return sdata;
        }
      }
    };
    $scope.checksubCounty = function (rdata, val) {
      var sdata = [];
      var len = rdata.length;
      var j = 1;

      for (var i = 0; i < len; i++, j++) {
        var rsdata = rdata[i];
        var gdata = val.filter(function (obj) {
          if (rsdata.countySubdivision == obj.NAME) {
            // //MyService.ConsoleLog(rdata[i]);
            return rdata[i];
          }
        });
        if (gdata.length > 0) {
          if (sdata.indexOf(rdata[i]) == -1) {
            sdata.push(rdata[i]);
          }
        }

        if (j == len) {
          // //MyService.ConsoleLog("gdata > ", sdata);
          return sdata;
        }
      }
    };
    $scope.checkPlaces = function (prdata, val) {
      var sdata = [];
      var len = prdata.length;
      var j = 1;

      for (var i = 0; i < len; i++, j++) {
        var rsdata = prdata[i];
        var gdata = val.filter(function (obj) {
          // //MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
          if (rsdata.place == obj.NAME && !rsdata.countyFips) {
            // //MyService.ConsoleLog(rdata[i]);
            return prdata[i];
          }
        });
        if (gdata.length > 0) {
          if (sdata.indexOf(prdata[i]) == -1) {
            sdata.push(prdata[i]);
          }
        }

        if (j == len) {
          // //MyService.ConsoleLog("gdata > ", sdata);
          return sdata;
        }
      }
    };

    $scope.User = {
      Address: ""
    };

    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;

    var options = {
      componentRestrictions: {
        country: "us"
      },
      types: ["geocode"]
    };


    $scope.oldaddress = {
      state: "",
      county: "",
      countySubdivision: "",
      place: ""
    };
    $scope.showAddressDrop = false;
    $scope.showAddressDropDowns = function (zipcode, loc) {
      $scope.showZipLoader = true;
      // //console.log(zipcode);

      if (loc && loc != "") {

        MyService.getAllStates().then(
          function (payload) {
            $scope.allStateRes = payload;
            $scope.states = payload;
            // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);

          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );

        MyService.getOrganizedZipData({
          "candidate": true,
          loc: loc
        }).then(
          function (respo) {
            //MyService.ConsoleLog("mylog", respo);
            if (respo && respo.state) {
              // var finalData = payload.data;
              // //MyService.ConsoleLog(payload[0]);
              // //MyService.ConsoleLog(payload[0].countySubdivision);
              // //MyService.ConsoleLog(payload[0].county);
              // //MyService.ConsoleLog(payload[0].place);
              var countySubdivisions = respo.places.filter(function (obj) {
                if (obj.COUSUBFP && obj.COUSUBFP != "") {
                  // //MyService.ConsoleLog("Inside cs");
                  return obj;
                }
              });
              var places = respo.places.filter(function (obj) {
                if (obj.PLACEFP && obj.PLACEFP != "") {
                  // //MyService.ConsoleLog("obj > ", obj);
                  return obj;
                }
              });

              // //MyService.ConsoleLog("here");
              $scope.showZipLoader = false;
              $scope.showAddressDrop = true;
              // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);

              $scope.oldaddress.state = respo.state;
              $scope.oldaddress.county = respo.county;
              $scope.oldaddress.countySubdivision = countySubdivisions;
              // //MyService.ConsoleLog("places >>> ",places);
              $scope.oldaddress.place = places;
              $scope.getCounties("new");

              $scope.getSubCounties("new");

              // $scope.voterDistricts = payload;
              // //MyService.ConsoleLog(payload.congressionalDistrict);
              $scope.federalCongrassionalDist = respo.congressionalDistrict;
              //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

              $scope.stateSenateDistrict = respo.stateSenateDistrict;
              $scope.stateHouseOFRepresentativeDistricts =
                respo.stateHouseofRepresentativesDistrict;
              $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
              //$scope.$apply();
              //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
              // //MyService.ConsoleLog(payload[0].statePostalCode);
              var address = $scope.checkState($scope.allStateRes, respo.state);
              $scope.allstatesData = address;
              // //MyService.ConsoleLog("address<>", address);
              // $scope.Location.state = address[0];
              // $scope.Location.voterDistrict = $scope.voterDistricts[0];
              $scope.Location.federalCongrassionalDist =
                $scope.federalCongrassionalDist[0];
              $scope.Location.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              $scope.Location.stateHouseOFRepresentativeDistrict =
                $scope.stateHouseOFRepresentativeDistricts[0];
              $scope.Location.unifiedSchoolDistrictName =
                $scope.unifiedSchoolDistrictNames[0];
              // //MyService.ConsoleLog("address ; ", address);
            } else {
              $scope.showZipLoader = false;
              alert("Zipcode not found in our database. Please correct it");
            }
          },
          function (errorPayload) {
            $scope.showZipLoader = false;
            growl.error(errorPayload);
          }
        );

      } else {

        MyService.getAllStates().then(
          function (payload) {
            $scope.allStateRes = payload;
            $scope.states = payload;
            // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);

          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );

        MyService.getOrganizedZipData({
          "zipcode": zipcode,
          "candidate": true
        }).then(
          function (respo) {
            //MyService.ConsoleLog("mylog", respo);
            if (respo && respo.state) {
              // var finalData = payload.data;
              // //MyService.ConsoleLog(payload[0]);
              // //MyService.ConsoleLog(payload[0].countySubdivision);
              // //MyService.ConsoleLog(payload[0].county);
              // //MyService.ConsoleLog(payload[0].place);
              var countySubdivisions = respo.places.filter(function (obj) {
                if (obj.COUSUBFP && obj.COUSUBFP != "") {
                  // //MyService.ConsoleLog("Inside cs");
                  return obj;
                }
              });
              var places = respo.places.filter(function (obj) {
                if (obj.PLACEFP && obj.PLACEFP != "") {
                  // //MyService.ConsoleLog("obj > ", obj);
                  return obj;
                }
              });

              // //MyService.ConsoleLog("here");
              $scope.showZipLoader = false;
              $scope.showAddressDrop = true;
              // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);

              $scope.oldaddress.state = respo.state;
              $scope.oldaddress.county = respo.county;
              $scope.oldaddress.countySubdivision = countySubdivisions;
              // //MyService.ConsoleLog("places >>> ",places);
              $scope.oldaddress.place = places;
              $scope.getCounties("new");

              $scope.getSubCounties("new");

              // $scope.voterDistricts = payload;
              // //MyService.ConsoleLog(payload.congressionalDistrict);
              $scope.federalCongrassionalDist = respo.congressionalDistrict;
              //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

              $scope.stateSenateDistrict = respo.stateSenateDistrict;
              $scope.stateHouseOFRepresentativeDistricts =
                respo.stateHouseofRepresentativesDistrict;
              $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
              //$scope.$apply();
              //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
              // //MyService.ConsoleLog(payload[0].statePostalCode);
              var address = $scope.checkState($scope.allStateRes, respo.state);
              $scope.allstatesData = address;
              // //MyService.ConsoleLog("address<>", address);
              // $scope.Location.state = address[0];
              // $scope.Location.voterDistrict = $scope.voterDistricts[0];
              //MyService.ConsoleLog("$scope.Location.federalCongrassionalDist :", $scope.federalCongrassionalDist[0]);
              $scope.Location.federalCongrassionalDist =
                $scope.federalCongrassionalDist[0];
              $scope.Location.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              $scope.Location.stateHouseOFRepresentativeDistrict =
                $scope.stateHouseOFRepresentativeDistricts[0];
              $scope.Location.unifiedSchoolDistrictName =
                $scope.unifiedSchoolDistrictNames[0];
              // //MyService.ConsoleLog("address ; ", address);
            } else {
              $scope.showZipLoader = false;
              alert("Zipcode not found in our database. Please correct it");
            }
          },
          function (errorPayload) {
            $scope.showZipLoader = false;
            growl.error(errorPayload);
          }
        );
      }

    };

    $scope.detectAddress = function (ent) {
      var val = document.getElementById("address");
      // //console.log($scope.User.Address);
      var autocomplete = new google.maps.places.Autocomplete(val, options);

      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          var place = autocomplete.getPlace();

          var lat = place.geometry.location.lat(),
            lng = place.geometry.location.lng();

          $scope.lati = lat.toFixed(6);
          $scope.lon = lng.toFixed(6);
          // //console.log($scope.lati);
          // //console.log($scope.lon);

          $scope.mailing_Address = place.formatted_address;
          console.log("  place.address_components :", place.address_components);
          for (var i = 0; i < place.address_components.length; i++) {
            for (
              var j = 0; j < place.address_components[i].types.length; j++
            ) {
              if (
                place.address_components[i].types[j] ==
                "administrative_area_level_1"
              ) {
                $scope.statePostalCode =
                  place.address_components[i].short_name;
                // //console.log($scope.statePostalCode);
              }
              if (place.address_components[i].types[j] == "country") {
                $scope.countryname = place.address_components[i].long_name;
                // //console.log($scope.countryname);
              }
              if (
                place.address_components[i].types[j] ==
                "administrative_area_level_2"
              ) {
                $scope.county_name = place.address_components[i].long_name;
                // //console.log($scope.county_name);
              }
              if (place.address_components[i].types[j] == "locality") {
                $scope.place_name = place.address_components[i].long_name;
                // //console.log($scope.place_name);
              }
              if (place.address_components[i].types[j] == "postal_code") {
                // document.getElementById('zip').value = place.address_components[i].long_name;
                $scope.ZipCode = place.address_components[i].long_name;
                // //console.log($scope.User.ZipCode);
              }
            }
          }

          //MyService.ConsoleLog(" $scope.User.ZipCode :", $scope.ZipCode);


          $scope.loc = JSON.stringify({
            longitude: $scope.lon,
            latitude: $scope.lati
          });
          // //console.log(" long and lat :",$scope.loc );
          // $rootScope.location = new Array;

          $scope.showAddressDropDowns("", $scope.loc);
        }
      );
    };




  }]);
