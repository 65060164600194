"use strict";
angular
  .module("myApp.getVotesDetails", ["ngRoute"])

  .config([
    "$routeProvider",
    function($routeProvider) {
      $routeProvider.when("/getVotesDetails/:id?", {
        templateUrl: "app/getVotesDetails/getVotesDetails.html",
        controller: "getVotesDetailsCtrl"
      });
    }
  ])
  .controller("getVotesDetailsCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "growl", "ngDialog", "tokenService", "MyService", "$routeParams", function($scope, $http, $location, apiCall, $rootScope, growl, ngDialog, tokenService, MyService, $routeParams) {

    $scope.uId = $routeParams.id;
    MyService.ConsoleLog("$scopeId >>", $scope.uId);
    $scope.activeDoc = true;


    $scope.getDetails = function() {

      MyService.getVotesDetail({ "id": $scope.uId }).then(function(response) {
        MyService.ConsoleLog("response >>", response);
        $scope.details = response.data.data;
      }, function(err) {
        MyService.ConsoleLog("Erorr >>", err);
      })

    }
    $scope.getDetails();

  }]);
