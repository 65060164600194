"use strict";
angular
  .module("myApp.userProfile", ["ngRoute"])
  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/userProfile/", {
        templateUrl: "app/userProfile/userProfile.html",
        reloadOnSearch: false,
        controller: "userProfileCtrl", //we can name anythin
      });
    },
  ])
  .controller("userProfileCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      tokenService,
      MyService
    ) {
      switch ($rootScope.userRole) {
        case "admin":
          $scope.showProfile = true;
          break;
        case "politician":
          $scope.showPoliticianProfile = true;
          break;
        case "advocate":
          $scope.showOrganizationProfile = true;
          break;
        case "voter":
          $scope.showProfile = true;
          // $scope.showVoterProfile = true;
          break;
        default:
          console.log("Sorry, data not found");
      }
      // console.log("route params", $routeParams.type);
      // console.log("type", $rootScope.userRole);

      $scope.isShowMailingAddress = false;

      $scope.getMailingAddress = function (id) {
        MyService.ConsoleLog("is first call");

        MyService.getMailingAddress({
          userId: id,
        }).then(
          function (result) {
            MyService.ConsoleLog("Result", result);
            $scope.mainData = result.data.data;
            if (
              $scope.mainData &&
              $scope.mainData.address &&
              $scope.mainData.address.statePostalCode != ""
            ) {
              window.localStorage.removeItem("isFiveMinPage");

              $scope.isShowMailingAddress = true;
            } else {
              $scope.isShowMailingAddress = false;
            }
          },
          function (err) {
            MyService.ConsoleLog("Error >", err);
          }
        );
      };
      if (tokenService.getUserId()) {
        MyService.ConsoleLog(
          "tokenService.getUserId()",
          tokenService.getUserId()
        );
        $scope.getMailingAddress(tokenService.getUserId());
      }
      /********************************** User Name ************************************** */

      // $scope.username = angular.copy($rootScope.User);
      /****************************** End User Name******************************************/
      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
        // $scope.emailID = $scope.userData.email;
        MyService.ConsoleLog(" $scope.userData >", $scope.userData);
        $scope.userAdd = $scope.userData.address;
      }

      $scope.changeEmailID = function (email, confirmEmail) {
        MyService.ConsoleLog("emai", email);
        MyService.ConsoleLog("confirmEmail", confirmEmail);

        if (email != "" && confirmEmail && confirmEmail != "") {
          MyService.ConsoleLog("typeof", typeof email);
          var chkEmail;
          if (typeof email == "object") {
            chkEmail = email[0];
          } else {
            chkEmail = email;
          }
          if (chkEmail == confirmEmail) {
            if (email != "") {
              var message =
                "When you hit “YES”, we will send an email to your new email address. For security, you will have 30 minutes to respond and complete the change or your account will stay with the old email address. Would you like to continue?";
              MyService.ConsoleLog("$scope.userMainData", $rootScope.userRole);

              var modal = ngDialog.openConfirm({
                template:
                  "<p>" +
                  message +
                  '</p>\
                              <div class="ngdialog-buttons">\
                                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                  <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
                              </div>',
                plain: true,
              });
              modal.then(
                function fullfilled(data) {
                  //MyService.ConsoleLog("fullfilled: ", data);
                  MyService.changeEmailID({
                    email: email,
                    verificationurl: $rootScope.getVerificationUrl(),
                    unsubscribeurl: $rootScope.getEmailUnsubscribeUrl(),
                  }).then(
                    function (response) {
                      if (response.data.success) {
                        growl.success("Please check your email to verify it");
                        //tokenService.saveUserName($scope.username);
                      } else if (
                        response.data.error &&
                        typeof response.data.error == "string"
                      ) {
                        growl.error("Error: " + response.data.error);
                      } else {
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      growl.error("Something went wrong");
                    }
                  );
                },
                function rejected(data) {
                  //MyService.ConsoleLog("rejected: ", data);
                }
              );
            }
          } else {
            var message = "Email addresses don't match";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Okay</button>\
              </div>',
              plain: true,
            });
            modal.then(
              function fullfilled(data) {
                //MyService.ConsoleLog("fullfilled: ", data);
              },
              function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              }
            );
          }
        } else {
          var message = "Please enter confirm email";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Okay</button>\
              </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
        }
      };

      $scope.updateAddress = function () {
        $scope.UpdateAddressLoader = true;
        MyService.getProfile($rootScope.UserId).then(
          function (data) {
            // body...
            var address_data = data.address;
            address_data.zipcode = data.zipcode;
            data.address.flag = "userProfile";
            MyService.ConsoleLog("********* Data: ", data.address);
            ngDialog.open({
              template: "app/popups/user_localupdate.html",
              data: data.address,
              // className: 'ngdialog-theme-default'
            });
            $scope.UpdateAddressLoader = false;
          },
          function (err) {
            //MyService.ConsoleLog("********* err: ", err);
            $scope.UpdateAddressLoader = false;
          }
        );
      };

      $scope.changePassword = function (
        old_password,
        password,
        confirm_password
      ) {
        MyService.resetPassword({
          old_password: old_password,
          password: password,
          confirm_password: confirm_password,
        }).then(
          function (response) {
            if (response.data.success) {
              growl.success("Updated successfully.");
              MyService.ConsoleLog(" updated password :", response);
              // tokenService.saveUserName($scope.username);
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (err) {
            growl.error("Something went wrong");
          }
        );
      };
      $scope.allSource = [];

      $scope.getNewsSources = function () {
        $scope.allSource.length = 0;
        MyService.getNewsSource().then(
          function (payload) {
            MyService.ConsoleLog("payload", payload);
            // alert("call");
            $scope.allSource = payload;
            MyService.ConsoleLog("$scope.mainData", $scope.mainData);
            if ($scope.mainData) {
              $scope.newsChannel = $scope.mainData.myFavChannel;
            }
          },
          function (errorPayload) {
            //MyService.ConsoleLog(errorPayload);
            growl.error("News services down currently.");
          }
        );
      };

      $scope.changeNewsChannel = function (channel) {
        //alert(channel);
        MyService.saveFavChannel({
          channel: channel,
        }).then(
          function (payload) {
            //MyService.ConsoleLog(payload);
            $scope.userData.my_fav_channel = channel;
            tokenService.saveUserData(JSON.stringify($scope.userData));
            growl.success("Your favorite channel saved successfully.");
          },
          function (errorPayload) {
            growl.error("Something went wrong while saving the favorites.");
          }
        );
      };

      $scope.fivefb = {
        title: "",
        description: "",
        files: "",
      };

      $scope.getFiveFacebookPost = function (data) {
        MyService.getFiveFacebookPost().then(
          function (payload) {
            MyService.ConsoleLog(payload);
            if (payload.data.success) {
              $scope.fivefb.title = payload.data.data.fbTitle;
              $scope.fivefb.description = payload.data.data.fbDescription;
              if (payload.data.data.fbImage) {
                $scope.fbFiveImage =
                  baseApiUrl + "/resources/" + payload.data.data.fbImage;
              }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.changeFacebookPost = function (data) {
        //alert(channel);
        var fd = new FormData();
        fd.append("title", $scope.fivefb.title);
        fd.append("description", $scope.fivefb.description);
        if ($scope.fivefb.files) {
          fd.append("file", $scope.fivefb.files);
        }

        var posturl = baseApiUrl + "/user/saveFiveFacebookPost";
        $http({
          method: "POST",
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {
            "Content-Type": undefined,
          },
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd,
        }).then(
          function (data, status, headers, config) {
            //MyService.ConsoleLog("success data: ", data);
            if (data.data.success) {
              growl.success("Updated successfully.");
            } else if (data.data.error && typeof data.error == "string") {
              growl.error("Error: " + data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (data, status, headers, config) {
            //MyService.ConsoleLog("error >> ", data);
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.getNewsSources();

      /**
       * Filter new data
       */

      // $scope.search = function () {
      //   return function (u) {
      //     // MyService.ConsoleLog("u", u);
      //     if (u.name.toLowerCase() == $scope.mainData.myFavChannel) {
      //       return u;
      //     } else {
      //       return u;
      //     }
      //   };
      // };

      if ($rootScope.showProfile || $rootScope.showPoliProfile) {
        $scope.getFiveFacebookPost();
      }
    },
  ])
  .controller("MyProfileCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "growl",
    "tokenService",
    "ngDialog",
    "baseApiUrl",
    "$anchorScroll",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      growl,
      tokenService,
      ngDialog,
      baseApiUrl,
      $anchorScroll,
      MyService
    ) {
      //MyService.ConsoleLog("** MyProfileCtrl **");

      $scope.userId = tokenService.getUserId();
      $scope.UpdateAddressLoader = false;
      $scope.selectedCat = [];
      $scope.SelectedTree = [];
      $scope.disabled = [];
      $scope.Allcountries = [];
      $scope.SelectedCountry = "";
      $scope.newlocation = {
        country: "",
      };
      $scope.profile = {
        orgData: {
          description: "",
          typeOfOrg: "",
        },
      };
      $scope.subtopics = {
        subcategories: [],
      };

      $scope.Essay = {
        location: "",
        subcategories: [],
      };

      $scope.ShowAddLocation = function () {
        if (!$scope.AddLocation) {
          $scope.AddLocation = true;
        } else {
          $scope.AddLocation = false;
        }
      };

      $scope.getProfile = function () {
        MyService.ConsoleLog("getProfile : ", $scope.userId);
        // alert('called');
        MyService.getProfile($scope.userId).then(
          function (payload) {
            MyService.ConsoleLog("Got New Data: ", payload);
            $scope.userMainData = payload;
            var userdata = payload;
            $scope.profile = userdata;
            $scope.emailID = payload.email;
            $scope.username = payload.username;
            if (userdata && userdata.campaignInfo.length > 0) {
              $scope.profile.campwebUrl =
                userdata.campaignInfo[0].campaignWeburl;
              $scope.profile.campEmail = userdata.campaignInfo[0].campaignEmail;
              $scope.profile.campPhone = userdata.campaignInfo[0].campaignPhone;
              $scope.profile.campaignFburl =
                userdata.campaignInfo[0].campaignFburl;
            }
            if ($scope.userData.my_fav_channel) {
              $scope.newsChannel = $scope.userData.my_fav_channel;
            }
            MyService.ConsoleLog("typeOfOrg", userdata.typeOfOrg);
            if (userdata.typeOfOrg && userdata.typeOfOrg != "") {
              $scope.profile.typeOfOrg = userdata.typeOfOrg;
            }
            $scope.profileData = userdata.politicianInfo;
            MyService.ConsoleLog(
              "Got New ProfileData: ",
              $scope.profileData.length
            );

            $scope.getAllStates();
            //MyService.ConsoleLog("Essay : ", subcatdata);
            //MyService.ConsoleLog("address:", userdata.address);
            if (userdata.address && userdata.address.state) {
              $scope.profile.state = {
                state: userdata.address.state,
                statePostalCode: userdata.address.statePostalCode,
              };
              $scope.getCounties($scope.profile.state, "address");

              $scope.profile.county = {
                state: userdata.address.state,
                statePostalCode: userdata.address.statePostalCode,
                county: userdata.address.county,
              };
              $scope.getLocals(
                $scope.profile.state,
                $scope.profile.county,
                "address"
              );
            }

            var locations = userdata.locationOfInterest;
            MyService.ConsoleLog("locations", locations);
            var catArr = userdata.subjectOfInterest;
            //MyService.ConsoleLog(catArr[0].split(","));
            $scope.disabled = userdata.subjectOfDeepInterests;
            $scope.SelectedTree = userdata.subjectOfInterests;
            //MyService.ConsoleLog($scope.disabled);

            var nobj = {
              country: "National (USA)",
            };
            var idx = indexOfExt($scope.selectedArea, nobj);
            if (idx > -1) {
              $scope.selectedArea.splice(idx, 1);
            }
            var len = 0;
            if (locations) {
              len = locations.length;
            }

            $scope.selectedArea.length = 0;
            $scope.showSubCat = true;
            $scope.disabledLocation.length = 0;
            for (var l = 0; l < len; l++) {
              var nobj = {};
              if (locations[l].country) {
                if (locations[l].country == "National (USA)") {
                  $scope.Essay.National = "National";
                }
                if (locations[l].country == "International") {
                  $scope.Essay.Locations = "International";
                }
                nobj.country = locations[l].country;
              }
              if (locations[l].state) {
                nobj.state = locations[l].state;
                nobj.statePostalCode = locations[l].statePostalCode;
              }
              if (locations[l].county) {
                nobj.county = locations[l].county;
              }
              if (locations[l].countySubdivision) {
                nobj.countySubdivision = locations[l].countySubdivision;
              }
              if (locations[l].place) {
                nobj.place = locations[l].place;
              }
              $scope.selectedArea.push(nobj);
              $scope.disabledLocation.push(nobj);
            }

            $scope.getSubcatTree();
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.changeUsername = function (username) {
        MyService.changeUsername({
          username: username,
        }).then(
          function (response) {
            if (response.data.success) {
              growl.success("Updated successfully.");
              // MyService.ConsoleLog("username", username);
              // MyService.ConsoleLog("$scope.username", $scope.username);

              tokenService.saveUserName(username);
              window.location.reload();
              // MyService.ConsoleLog("token", tokenService.getUserName());
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (err) {
            growl.error("Something went wrong");
          }
        );
      };
      $scope.getFilter = function () {
        return function (u) {
          // MyService.ConsoleLog("Filter >>>>>>", u);
          if (
            u.incumbent != "incumbent" ||
            (u.incumbent == "Incumbent" && u.runningForOffice != "N")
          ) {
            return u;
          }
        };
      };

      // getData

      $scope.getData = function () {
        return function (u) {
          // MyService.ConsoleLog("Filter >>>>>>", u);
          if (
            u.incumbent == "incumbent" ||
            (u.incumbent == "Incumbent" && u.runningForOffice == "Y")
          ) {
            return u;
          } else if (u.incumbent == "incumbent" || u.incumbent == "Incumbent") {
            return u;
          }
        };
      };

      function suggest_country(term) {
        var q = term.toLowerCase().trim();
        var results = [];
        var len = $scope.Allcountries.length;
        // Find first 10 states that start with `term`.
        for (var i = 0; i < len && results.length < 10; i++) {
          var country = $scope.Allcountries[i];
          if (country.toLowerCase().indexOf(q) === 0)
            results.push({
              label: country,
              value: country,
            });
        }
        return results;
      }

      $scope.autocomplete_options = {
        suggest: suggest_country,
      };

      $scope.getAllStates = function () {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allLocations.length = 0;
            var subcatdata = payload;
            //MyService.ConsoleLog("Sub states : ", subcatdata);
            $scope.allLocations = subcatdata;
            //MyService.ConsoleLog($scope.profile.address);
            //MyService.ConsoleLog($scope.profile.address.state);
            if ($scope.profile.address && $scope.profile.address.state) {
              var chkdata = $scope.checkExists(
                subcatdata,
                $scope.profile.address.state
              );
              //MyService.ConsoleLog("State check item :", chkdata[0]);
              if (chkdata != "") {
                chkdata[0].country = "United States of America";
                //MyService.ConsoleLog(JSON.stringify(chkdata[0]));
                $scope.profile.state = JSON.stringify(angular.copy(chkdata[0]));
              }
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.getVal = function (data) {
        MyService.ConsoleLog("gggg", data);
        var idx = $scope.selectedCat.indexOf(data);
        if (idx > -1) {
          $scope.selectedCat.splice(idx, 1);
          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var jdx = $scope.SelectedTree.indexOf(data.parentIds);
          if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
        } else {
          $scope.selectedCat.push(data);
          var id = $scope.disabled.indexOf(data._id);
          if (id == -1) {
            $scope.disabled.push(data._id);
          }
          //MyService.ConsoleLog(data.parentIds);
          //MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
          if ($scope.SelectedTree && $scope.SelectedTree.length == 0) {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              //angular.copy(data.parentIds, $scope.SelectedTree);
              var parents = data.parentIds;
              //MyService.ConsoleLog(parents);
              $scope.SelectedTree = parents;
            }
          } else {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              //var parents = angular.copy(data.parentIds);
              var parents = data.parentIds;
              var plen = parents.length;
              for (var j = 0; j < plen; j++) {
                $scope.SelectedTree.push(parents[j]);
              }
            }
          }
        }
        //MyService.ConsoleLog($scope.SelectedTree);
      };

      $scope.selectedAll = {
        id: [],
      };
      $scope.removeVal = function (data) {
        var idx = $scope.selectedCat.indexOf(data);
        if (idx > -1) {
          $scope.selectedCat.splice(idx, 1);
          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var parents = data.parentIds;
          var plen = parents.length;
          for (var j = 0; j < plen; j++) {
            var jdx = $scope.SelectedTree.indexOf(parents[j]);
            if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
          }
        }
        $scope.selectedAll.id.length = 0;
        //MyService.ConsoleLog($scope.SelectedTree);
        var allLen = $scope.SelectedTree.length;
        var chkboxe = document.getElementsByName("checkedidcat");
        for (var i = 0; i < allLen; i++) {
          chkboxe[i].checked = true;
        }
      };

      $scope.selectConfig = {
        requiredMin: 1,
        selectAll: true,
      };

      $scope.checksubCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id == val) return rdata;
          else if (rdata.subCategories) {
            $scope.checksubCatExists(rdata.subcategories, val);
          }
        });
      };

      $scope.checkCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata;
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val);
          }
        });
      };
      $scope.SaveButton = true;
      $scope.getSubcatTree = function () {
        // console.log("subcat");
        $scope.selectedCat.length = 0;
        $scope.SaveButton = true;

        MyService.getAllCatTree({
          type: "E",
        }).then(
          function (payload) {
            $scope.subtopics.subcategories.length = 0;
            var subcatdata = payload;
            // MyService.ConsoleLog("subcatdata : ", subcatdata);
            $scope.subtopics.subcategories = subcatdata;
            var selectedCat = $scope.disabled;
            MyService.ConsoleLog("$scope.disabled", selectedCat);
            if (selectedCat && selectedCat.length > 0) {
              for (var s = 0; s < selectedCat.length; s++) {
                var checkcatdata = $scope.checkCatExists(
                  subcatdata,
                  selectedCat[s]
                );
              }
              var filterCatData = $scope.selectedCat.filter(function (
                itm,
                index
              ) {
                return (
                  index ==
                  $scope.selectedCat.findIndex(function (ele) {
                    return JSON.stringify(ele) == JSON.stringify(itm);
                  })
                );
              });
              // MyService.ConsoleLog("filtered Data", filterCatData);
              $scope.selectedCat = "";
              $scope.selectedCat = filterCatData;
              MyService.ConsoleLog("now", $scope.selectedCat);
            }
            $scope.SaveButton = false;
            setTimeout(function () {
              var cols = document.getElementsByClassName("cmnUL");
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = "none";
              }
            }, 300);
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.allLocations = [];
      $scope.allCounties = [];
      $scope.selectedArea = [];
      $scope.rmselectedArea = [];
      $scope.myselectionarea = [];
      $scope.disabledLocation = [];

      $scope.checkExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.state == val) return rdata;
        });
      };

      $scope.checkCountyExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id.county == val) return rdata._id;
        });
      };

      $scope.checkCountryExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.country == val) return rdata;
        });
      };

      $scope.checkLocalExists = function (rdata, val, val1) {
        MyService.ConsoleLog("filter :", val);
        if (rdata && rdata.length > 0) {
          return rdata.filter(function (rdata) {
            if (rdata._id.place == val && rdata._id.countySubdivision == val1)
              return rdata._id;
          });
        }
      };

      function areEqual() {
        var len = arguments.length;
        for (var i = 1; i < len; i++) {
          if (arguments[i] == null || arguments[i] != arguments[i - 1])
            return false;
        }
        return true;
      }

      function indexOfExt(list, item) {
        var len = list.length;
        for (var i = 0; i < len; i++) {
          var keys = Object.keys(list[i]);
          var flg = true;
          var arr = [];
          for (var j = 0, s = 1; j < keys.length; j++, s++) {
            var value = list[i][keys[j]];
            if (item[keys[j]] !== value) {
              flg = false;
            }
            if (item[keys[j]] == value) {
              arr.push(flg);
            }
          }
          if (flg == true) {
            if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
              return i;
            }
          }
        }
        return -1;
      }

      $scope.checkDisabled = function (data, stateId, type) {
        data.country = "United States of America";
        data.state = stateId;
        var idx = indexOfExt($scope.disabledLocation, data);
        if (idx > -1) return true;
        else return false;
      };

      $scope.getAreaVal = function (data, stateId, type) {
        if (!data.country) {
          data.country = "United States of America";
        }
        if (!data.state) {
          data.state = stateId;
        }

        switch (type) {
          case "county":
            MyService.ConsoleLog("county", data);
            var tempData = data;
            data.county = tempData.countyName;
            MyService.ConsoleLog("data", data);
            break;
          case "local":
            var onTemp = data;
            data.place = onTemp.placeName;
            break;
          default:
            MyService.ConsoleLog("default");
            break;
        }

        var idx = indexOfExt($scope.selectedArea, data);

        if (idx > -1) {
          growl.error("You have already selected the location");
        } else {
          // alert('new');
          $scope.selectedArea.push(data);
          $scope.disabledLocation.push(data);
          MyService.ConsoleLog("now link", $scope.selectedArea);
        }

        //MyService.ConsoleLog($scope.disabledLocation);
      };
      $scope.selectedLAll = {
        id: [],
      };

      $scope.removeAreaVal = function (data) {
        var idx = $scope.selectedArea.indexOf(data);

        //MyService.ConsoleLog($scope.Essay.Locations);
        if (idx > -1) {
          //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
          if ($scope.selectedArea[idx].country == "International") {
            $scope.Essay.Locations = "NO";
          }
          if ($scope.selectedArea[idx].country == "National (USA)") {
            $scope.Essay.National = "NO";
          }
          $scope.selectedArea.splice(idx, 1);
          var jdx = $scope.disabledLocation.indexOf(data);
          if (jdx > -1) {
            $scope.disabledLocation.splice(jdx, 1);
          }
        }
        $scope.selectedLAll.id.length = 0;
        //MyService.ConsoleLog($scope.selectedArea);
        var allLen = $scope.SelectedTree.length;
        var chkboxe = document.getElementsByName("checkedid");
        for (var i = 0; i < allLen; i++) {
          chkboxe[i].checked = true;
        }
      };
      $scope.allCounties = [];
      $scope.allLocals = [];
      $scope.setLocation = function (option) {
        $scope.Essay.location = option;
      };
      $scope.getLocations = function (val, type) {
        if (type == "National") {
          if (val == "National") {
            var nobj = {
              country: "National (USA)",
            };
            $scope.selectedArea.push(nobj);
            //MyService.ConsoleLog($scope.selectedArea);
          }
          if (val == "NO") {
            var nobj = {
              country: "National (USA)",
            };
            var idx = indexOfExt($scope.selectedArea, nobj);
            if (idx > -1) {
              $scope.selectedArea.splice(idx, 1);
            }
          }
        } else if (type == "International") {
          if (val == "International") {
            // $scope.selectedArea.length = 0;
            var nobj = {
              country: "International",
            };
            $scope.selectedArea.push(nobj);
          }

          if (val == "NO") {
            // $scope.selectedArea.length = 0;
            var nobj = {
              country: "International",
            };

            var idx = indexOfExt($scope.selectedArea, nobj);
            if (idx > -1) {
              $scope.selectedArea.splice(idx, 1);
            }
          }
        }
      };

      $scope.AddCountry = function () {
        if ($scope.newlocation.country && $scope.newlocation.country != "") {
          var idx = $scope.Allcountries.indexOf($scope.newlocation.country);
          if (idx > -1) {
            var nobj = {
              country: $scope.newlocation.country,
            };
            $scope.selectedArea.push(nobj);
          } else {
            alert(
              "Country does not exists in our database. Please check if you have entered correct name."
            );
          }
        } else {
          alert("Please enter country name");
        }
      };
      $scope.MyCounties = [];
      $scope.MyPlaces = [];
      $scope.getCounties = function (state, val) {
        if (val != "address") $scope.showLoading.push(state);

        if (val != "address") {
          if (
            !$scope.allCounties[state] ||
            $scope.allCounties[state].length == 0
          ) {
            MyService.getAllCounties(state).then(
              function (payload) {
                MyService.ConsoleLog("sublocations payload : ", payload);
                $scope.showLoading.length = 0;
                var sublocations = payload;
                MyService.ConsoleLog("sublocations counties : ", sublocations);
                if (!$scope.allCounties) {
                  $scope.allCounties = [];
                }
                $scope.allCounties[state] = sublocations;
              },
              function (errorPayload) {
                growl.error("Something went wrong.");
              }
            );
          } else {
            $scope.showLoading.length = 0;
            $scope.allCounties[state].length = 0;
          }
        } else if (val == "address") {
          if (typeof $scope.profile.state == "string") {
            var data = JSON.parse($scope.profile.state);
          } else {
            var data = $scope.profile.state;
          }
          var statePostalCode = data.statePostalCode;
          if (
            !data.statePostalCode ||
            data.statePostalCode == null ||
            data.statePostalCode == "null"
          ) {
            //MyService.ConsoleLog($scope.allLocations);
            //MyService.ConsoleLog(data.state);
            var chkData = $scope.checkExists($scope.allLocations, data.state);
            //MyService.ConsoleLog("chkData : ", chkData[0]);
            if (chkData[0]) {
              statePostalCode = chkData[0].statePostalCode;
            }
          }
          //MyService.ConsoleLog("statePostalCode:", statePostalCode);

          $scope.MyCounties.length = 0;
          MyService.getAllCounties(statePostalCode).then(
            function (payload) {
              //MyService.ConsoleLog("sublocations Locals : ", sublocations);
              $scope.showLoading.length = 0;
              $scope.MyCounties = payload;
              //MyService.ConsoleLog("getCounty checkdata: ", $scope.profile.address.county);
              if ($scope.profile.address.county) {
                var chkdata = $scope.checkCountyExists(
                  payload,
                  $scope.profile.address.county
                );
                //MyService.ConsoleLog("getCounty checkdata: ", chkdata);
                if (chkdata != "") {
                  $scope.profile.county = JSON.stringify(
                    angular.copy(chkdata[0]._id)
                  );
                }
              }
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );
        }
      };

      $scope.getLocals = function (state, county, val) {
        //MyService.ConsoleLog("state : " + state + ", county :" + county + ", val :" + val)
        if (val != "address") $scope.showLoadingCounty.push(county);
        if (val == "address") {
          $scope.MyPlaces.length = 0;
          if (typeof $scope.profile.state == "string") {
            var sdata = JSON.parse($scope.profile.state);
          } else {
            var sdata = $scope.profile.state;
          }
          if (typeof $scope.profile.county == "string") {
            var cdata = JSON.parse($scope.profile.county);
          } else {
            var cdata = $scope.profile.county;
          }
          MyService.getAllSubCountiesLocal(
            sdata.statePostalCode,
            cdata.county
          ).then(
            function (payload) {
              $scope.MyPlaces = payload;
              MyService.ConsoleLog("PlaCE :", $scope.MyPlaces);
              if ($scope.profile.address.place) {
                var chkdata = $scope.checkLocalExists(
                  payload,
                  $scope.profile.address.place,
                  $scope.profile.address.countySubdivision
                );
                // MyService.ConsoleLog('getLocal checkdata: ', chkdata[0]);
                if (chkdata && chkdata.length > 0) {
                  var idx = $scope.MyPlaces.indexOf(chkdata[0]);
                  //MyService.ConsoleLog(idx);
                  //MyService.ConsoleLog(angular.copy($scope.MyPlaces[idx]._id));
                  $scope.profile.places = JSON.stringify(
                    angular.copy($scope.MyPlaces[idx]._id)
                  );
                }
              }
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );
        } else {
          if (
            !$scope.allLocals[state] ||
            !$scope.allLocals[state][county] ||
            $scope.allLocals[state][county] == 0
          ) {
            MyService.getAllSubCountiesLocal(state, county).then(
              function (payload) {
                $scope.showLoadingCounty.length = 0;
                //MyService.ConsoleLog("sublocations Locals : ", sublocations);
                if (!$scope.allLocals[state]) {
                  $scope.allLocals[state] = [];
                }
                // alert('caalle');
                MyService.ConsoleLog("payload", payload.places.length);
                var plFil = payload.places.filter(function (item) {
                  // MyService.ConsoleLog('item', item);
                  var smTxt = item.placeName.substring(
                    item.placeName.length - 3
                  );
                  if (smTxt != "CDP") {
                    return item;
                  }
                });
                MyService.ConsoleLog("plFil >>", plFil.length);
                $scope.allLocals[state][county] = plFil;
              },
              function (errorPayload) {
                growl.error("Something went wrong.");
              }
            );
          } else {
            $scope.showLoadingCounty.length = 0;
            $scope.allLocals[state][county] = 0;
          }
        }
      };

      $scope.showSubCat = false;
      $scope.showLoading = [];
      $scope.showLoadingCounty = [];
      $scope.showDown = [];
      $scope.ChangeShow = function (id) {
        $scope.showSubCat = true;
        var cols = document.getElementsByClassName("cmnUL");
        var clen = cols.length;
        var nclass = "MainCatLi_" + id;
        if (
          document.getElementsByClassName(nclass)[0].style.display &&
          document.getElementsByClassName(nclass)[0].style.display == "none"
        ) {
          document.getElementsByClassName(nclass)[0].style.display = "block";
        } else {
          document.getElementsByClassName(nclass)[0].style.display = "none";
        }
      };

      $scope.checkTagsExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.pid == val) return rdata;
        });
      };

      $scope.updateProfile = function (data) {
        // MyService.ConsoleLog('Update profile:', data.orgData);
        // return
        // MyService.ConsoleLog('$scope', data.campaignInfo);
        data.userId = $scope.userId;
        data.phone = $scope.mainData.phone;
        data.publicEmail = $scope.profile.publicEmail;
        if (data.extensionNo && data.extensionNo != "undefined") {
          data.extensionNo = data.extensionNo;
        }

        MyService.ConsoleLog("Update profile:", data);
        if (data.orgData && data.orgData != "") {
          data.typeOfOrg = data.orgData.typeOfOrg;
          data.phone_no = data.orgData.phone_no;

          // alert(data.orgData.speciality);
          if (
            data.orgData.speciality == undefined &&
            data.orgData.speciality != null
          ) {
            growl.error("You can enter 100 characters only");
            // data.orgData.speciality = '';
            return;
          }
        }

        // data.type = 'advocate';
        data.type = $rootScope.userRole; //this is organizatino type
        if ($scope.SelectedTree) {
          data.tags = JSON.stringify($scope.SelectedTree);
        }
        if (data.donationLink && data.donationLink != "") {
          // MyService.ConsoleLog('data', data.donationLink.indexOf('https'));
          // MyService.ConsoleLog("data", data.donationLink.indexOf("http"));

          if (data.donationLink.indexOf("http") == -1) {
            // MyService.ConsoleLog('If');
            var smp = "http://" + data.donationLink;
            // MyService.ConsoleLog('smp', smp);
            data.donationLink = "";
            data.donationLink = smp;
            // MyService.ConsoleLog(' data.donationLink', data.donationLink);
          }
        }
        if (data.orgData && data.orgData.webUrl) {
          if (data.orgData.webUrl.indexOf("http") == -1) {
            // MyService.ConsoleLog("If");
            var smpw = "http://" + data.orgData.webUrl;
            MyService.ConsoleLog("smpw", smpw);
            data.webUrl = "";
            data.webUrl = smpw;
            MyService.ConsoleLog(" data.webUrl", data.webUrl);
          }
        }
        // MyService.ConsoleLog('Update profile:', data.orgData);

        // console.log('data.webUrl :>> ', data.webUrl);
        // console.log('data.orgData.webUrl:>> ', data.orgData.webUrl);
        // return;
        if (data.campwebUrl && data.campwebUrl) {
          if (data.campwebUrl.indexOf("http") == -1) {
            // MyService.ConsoleLog("If");
            var scamp = "http://" + data.campwebUrl;
            // MyService.ConsoleLog('smpw', smpw);
            data.campwebUrl = "";
            data.campwebUrl = scamp;
            MyService.ConsoleLog(" data.webUrl", data.campwebUrl);

            data.campaignWeburl = data.campwebUrl;
          } else {
            data.campaignWeburl = data.campwebUrl;
          }
        }

        // MyService.ConsoleLog("$scope.selectedArea", $scope.selectedArea);
        data.subject_of_deep_interest = JSON.stringify($scope.disabled);
        data.locations = JSON.stringify($scope.selectedArea);
        data.orgData = JSON.stringify(data.orgData);
        var places = null;
        var states = null;
        if (data.places) {
          places = JSON.parse(data.places);
        }
        if (data.state) {
          states = JSON.parse(data.state);
        }
        var address = null;
        if (places) {
          address = {
            countySubdivision: places.countySubdivision,
            county: places.county,
            statePostalCode: places.statePostalCode,
            place: places.place,
          };
          if (states) address.state = states.state;
        }
        // if (states && address == null) address = {
        //   state: states.state
        // };
        // data.compa
        // MyService.ConsoleLog('data : ', data);

        data.address = JSON.stringify(address);
        data.politicianInfo = JSON.stringify(data.politicianInfo);
        // MyService.ConsoleLog('data: ', data);
        data.campaignInfo = JSON.stringify(data.campaignInfo);
        // MyService.ConsoleLog("<<<<<<< new sir >>>>>>>>", JSON.parse(data.orgData));
        $scope.statusUpdateProfile = 1;
        MyService.updateProfile(data).then(
          function (payload) {
            MyService.ConsoleLog("payload: ", payload);
            var userdata = tokenService.getUserData();
            MyService.ConsoleLog("userData: ", userdata);
            data.politicianInfo = JSON.parse(data.politicianInfo);
            userdata = JSON.parse(userdata);
            // userdata.address = JSON.parse(data.address);
            (userdata.subject_of_deep_interest = $scope.disabled),
              (userdata.subject_of_interest = $scope.SelectedTree),
              (userdata.location_of_interest = $scope.selectedArea),
              tokenService.saveUserData(JSON.stringify(userdata));
            try {
              data.orgData = JSON.parse(data.orgData);
            } catch (e) {
              //MyService.ConsoleLog("Org data parse error: ", e);
            }
            $scope.statusUpdateProfile = 2;
            growl.success("Profile updated successfully.");
          },
          function (errorPayload) {
            $scope.statusUpdateProfile = 3;
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.editPoliticianOffice = function (index) {
        var officeObj = $scope.profile.politician_info[index];
        //MyService.ConsoleLog("editPoliticianOffice: ", officeObj);
        ngDialog.open({
          template: "app/popups/update_politician_office.html",
          scope: $scope,
          data: officeObj,
        });
      };

      $scope.addPoliticianOffice = function () {
        //var officeObj = $scope.profile.politician_info[index];
        //MyService.ConsoleLog("editPoliticianOffice: ", officeObj);
        ngDialog.open({
          template: "app/popups/update_politician_office.html",
          scope: $scope,
        });
      };

      $scope.updateAddress = function () {
        $scope.UpdateAddressLoader = true;
        MyService.getProfile($rootScope.UserId).then(
          function (data) {
            // body...
            var address_data = data.address;
            MyService.ConsoleLog("data", data.zipcode);

            // address_data.zipcode = data.zipcode;

            // }
            // data.address = {
            //   "loca": {
            //     "coordinates": []
            //   },
            //   "place": "Allentown",
            //   "countyFips": "",
            //   "county": "Lehigh",
            //   "countySubdivisionFips": "",
            //   "countySubdivision": "Allentown",
            //   "statePostalCode": "PA",
            //   "state": "Pennsylvania",
            //   "country": "United States",
            //   "federalCongrassionalDist": "07",
            //   "stateSenateDistrict": "016",
            //   "schoolDistrictFips": "02280",
            //   "schoolDistrict": "Allentown City School District",
            //   "legislativeDistrict": "22"
            // }

            // data.address.zipcode = "18102";
            // if (data.zipcode && data.zipcode != "") {
            //   data.address.zipcode = data.zipcode;
            // }
            try {
              MyService.ConsoleLog("********* Data: ", data.address);

              data.address.flag = "userProfile";
              MyService.ConsoleLog("********* Data: ", data.address);
            } catch (err) {}

            ngDialog.open({
              template: "app/popups/user_localupdate.html",
              data: data.address,
              // className: 'ngdialog-theme-default'
            });
            $scope.UpdateAddressLoader = false;
          },
          function (err) {
            //MyService.ConsoleLog("********* err: ", err);
            $scope.UpdateAddressLoader = false;
          }
        );
      };

      $scope.getProfile();

      MyService.getAllCountries().then(
        function (payload) {
          $scope.Allcountries = payload;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
        }
      );
      // }
      $rootScope.$on("UserProfileUpdated", function (event, data) {
        //MyService.ConsoleLog(data);
        $scope.getProfile();
      });
    },
  ]);
