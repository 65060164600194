'use strict';
angular.module('myApp.allEssay', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/allEssay', {
      templateUrl: 'app/allEssay/allEssay.html',
      controller: 'AllEssayCtrl'
    });
  }]).controller('AllEssayCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', function ($scope, $location, $rootScope, tokenService, MyService, ngDialog) {
    $scope.essay = {
      data: []
    };
    $scope.totalUsers = 0;
    $scope.usersPerPage = 25; // this should match however many results your API puts on one page

    $scope.pagination = {
      current: 1
    };
    $scope.showLoading = false;

    $scope.pageChanged = function (newPage) {
      $scope.getAllEssay(newPage);
    };

    $scope.getAllEssay = function (pageNumber) {
      // this is just an example, in reality this stuff should be in a service
      $scope.showLoading = true;
      MyService.getAllEssay({
        "pageno": pageNumber,
        "max_records": $scope.usersPerPage
      }).then(function (payload) {
        $scope.essay.data = payload.Items;
        $scope.totalUsers = payload.Count;
        //MyService.ConsoleLog(payload.Items);
        $scope.showLoading = false;
      }, function (err) {
        //MyService.ConsoleLog(err);
      });
    }

    $scope.removeEssay = function (mydata, idx) {

      var message = 'Are you sure you want to delete this Essay?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeEssay({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.getAllEssay(1);
          alert("Essay deleted successfully.");
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    if ($rootScope.showAllEssay) {
      $scope.getAllEssay(1);
    }
  }])