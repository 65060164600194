'use strict';
angular.module('myApp.backup', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/backup', {
      templateUrl: 'app/backup/backup.html',
      controller: 'AdminBackup'
    });
  }]).controller('AdminBackup', ['$scope', '$http', '$location', 'apiCall', '$rootScope', 'baseApiUrl', '$q', '$filter', 'growl', 'ngDialog', 'tokenService', function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService) {
    //MyService.ConsoleLog("* AdminBackup *");
    $scope.statusTakeBackup = 0;
    $scope.statusGetBackup = 0;

    var takeBackupOfDbCanceler = $q.defer();
    $scope.takeBackupOfDb = function () {
      //MyService.ConsoleLog(" takeBackupOfDb ");

      takeBackupOfDbCanceler.resolve();
      $scope.statusTakeBackup = 1;


      var request = apiCall.apiCall('GET', '/admin/database-backup', {
        timeout: takeBackupOfDbCanceler.promise
      });
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          $scope.statusTakeBackup = 2;
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
          $scope.statusTakeBackup = 3;
        } else {
          growl.error('Something went wrong.');
          $scope.statusTakeBackup = 3;
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
        $scope.statusTakeBackup = 3;
      });
    };

    var getBackupFilesCanceler = $q.defer();
    $scope.getBackupFiles = function () {
      //MyService.ConsoleLog(" takeBackupOfDb ");

      takeBackupOfDbCanceler.resolve();
      $scope.statusGetBackup = 1;


      var request = apiCall.apiCall('GET', '/admin/backupfile-list', {
        timeout: takeBackupOfDbCanceler.promise
      });
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          $scope.statusGetBackup = 2;
          $scope.backupFiles = response.data.data;
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
          $scope.statusGetBackup = 3;
        } else {
          growl.error('Something went wrong.');
          $scope.statusGetBackup = 3;
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
        $scope.statusGetBackup = 3;
      });
    };
    // $scope.getBackupFiles();

    $scope.downloadFile = function (file) {
      //MyService.ConsoleLog(" downloadFile ");
      /*
      var request = apiCall.apiCall('GET', '/admin/download-backup', {file_name: file});
      $http(
          request
      ).then(function successCallback(response) {
          if(response.data.success){
              //download started
          }
          else if(response.data.error && typeof response.data.error == "string"){
              growl.error('Error: '+response.data.error);
          }
          else{
              growl.error('Something went wrong.');
              $scope.statusGetBackup = 3;
          }
      }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ",response);
          $scope.statusGetBackup = 3;
      });
      */
      var url = baseApiUrl + "/admin/download-backup?token=" + tokenService.getToken() + "&file_name=" + file;
      window.open(url, '_blank');
    };
  }])