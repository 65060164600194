"use strict";
angular
  .module("myApp.addFiveMinEssayQues", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/addFiveMinEssayQues", {
        templateUrl: "app/addFiveMinEssayQues/addFiveMinEssayQues.html",
        controller: "addFiveMinEssayQuesCtrl",
      });
    },
  ])
  .controller("addFiveMinEssayQuesCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "growl",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      growl
    ) {
      $scope.quiz = {
        content: "",
      };

      $scope.isEdit = false;

      if (tokenService.getUserId()) {
        $scope.userId = tokenService.getUserId();
        $scope.userType = tokenService.getUserRole();
      }

      /**
       * @description Function is use to submit essay questions to the rest api.
       */

      $scope.addQuestion = function () {
        if ($scope.userType == "admin" && $scope.quiz.content != "") {
          var params;
          if (!$scope.isEdit) {
            params = {
              content: $scope.quiz.content,
              author: $scope.userId,
              approved: true,
              approvedBy: $scope.userId,
              isUpdate: false,
            };
          } else {
            params = {
              isUpdate: true,
              content: $scope.quiz.content,
              qId: $scope.qId,
            };
          }
          MyService.ConsoleLog("params >> ", params);
          MyService.addFiveMinEssayQuestions(params).then(
            function (APIReponse) {
              $scope.quiz = {
                content: "",
              };
              $scope.isEdit = false;
              $scope.getQuestions();
              growl.success(APIReponse, {
                ttl: 2000,
              });
            },
            function (err) {
              growl.error(err);
            }
          );
        } else {
          growl.error("Please enter question first", {
            ttl: 2000,
          });
        }
      };

      /**
       * @description Function is use to get the list of five minute essay questions
       */

      $scope.getQuestions = function () {
        MyService.getFiveMinEssayQues().then(
          function (APIReponse) {
            $scope.fiveMinQuestion = APIReponse;
            MyService.ConsoleLog(
              "$scope.fiveMinQuestion ",
              $scope.fiveMinQuestion
            );
          },
          function (err) {
            growl.error(err);
          }
        );
      };

      /**
       * @description Function is use to update the content of the question
       * @param {*} val
       * @param {*} index
       */

      $scope.editQeustion = function (val, index) {
        $scope.isEdit = true;
        $scope.quiz.content = val.content;
        $scope.qId = val._id;
      };

      $scope.removeQuestion = function (val, index) {
        var params = {
          qId: val._id,
        };
        MyService.removeEssayQuestion(params).then(function (APIReponse) {
          MyService.ConsoleLog("APIReponse >> ", APIReponse);
          $scope.getQuestions();
          growl.success(APIReponse, {
            ttl: 2000,
          });
        });
      };

      $scope.getQuestions();
    },
  ]);
