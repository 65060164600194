'use strict';
angular
  .module('myApp.questionAnsweredBy', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/question-answered-by/:user_id/:voteCandidateId?/:type?/:name?', {
        //pass user_id = me to see questions tagged to self
        templateUrl: 'app/questionAnsweredBy/questionAnsweredBy.html',
        controller: 'questionAnsByCtrl',
      });
    },
  ])
  .controller('questionAnsByCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    '$cookies',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      $cookies
    ) {
      MyService.ConsoleLog('** questionCtrl ** :', $routeParams);
      $scope.routeId = $routeParams.user_id;
      MyService.ConsoleLog('** $scope.routeId ** :', $scope.routeId);
      // $rootScope.tempQuestions = '';
      $scope.isShowVotesmart = false;
      $scope.showLoader = false;
      // $cookies.remove('tmpElectionData');
      $scope.ques = {
        searchQuestionKeyword: '',
      };
      $rootScope.welcome = false;
      $scope.traverseBackgrounds = [];
      $scope.currBackgroundIndex = 0;
      $('input[type=radio]').attr('disabled', true);
      $scope.politicianName = $routeParams.name;
      MyService.ConsoleLog('  $scope.politicianName :>>>>>>>>>', $scope.politicianName);

      $scope.status = {
        questions: 0, //1= loading, 2 = loaded, 3 = error
        category: 0,
      };
      // window.localStorage.removeItem('tempQues');
      $scope.currentIndex = 0;
      $scope.initializeVariables = function () {
        $scope.importance = null;
        $scope.answer = null;
        $scope.comment = '';
        $scope.reason = '';
      };
      $scope.initializeVariables();
      $scope.checkIfExists = function (val) {
        // console.log("questions <><><>", $scope.questions);
        // MyService.ConsoleLog("Val > ", val);
        var obj = $scope.questions[$scope.currentIndex];
        // MyService.ConsoleLog("checkIfExists >> ", obj);
        if (obj) {
          var data = obj.userAnswer;
          if (data || data[0]) {
            // MyService.ConsoleLog("data", data);
            if (data[0]) {
              // MyService.ConsoleLog('checkIfExists data>> ', data[0].answer + '==' + val);
              // MyService.ConsoleLog('checkIfExists data>> ', data[0]);
              if (data && data[0].comment) {
                $scope.comment = data[0].comment;
              } else {
                $scope.comment = data[0].answer_descriptive;
              }
              if (data[0].answer == 'No') {
                if (val == 5) return true;
              }
              if (data[0].answer == 'Yes') {
                if (val == 2) return true;
              }
              if (data[0].answer == 'Pro-choice') {
                if (val == 2) return true;
              }
              if (data[0].answer == 'Pro-life') {
                if (val == 5) return true;
              }
            } else {
              // MyService.ConsoleLog("checkIfExists data>> ", data[0]);
              $scope.comment = data.comment;
              // $scope.answer = data.answer;
              // if (data.answer == "No") {
              //   if (val == 5)
              //     return true;
              // }
              // if (data.answer == "Yes") {
              //   if (val == 2)
              if (val == data.answer) {
                return true;
              }
              // }
            }
          }
        }
        return false;
      };

      $scope.checkQuestionAnswered = function () {
        // console.log("scope.questions > ", $scope.questions);
        return $scope.questions.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          var data = $scope.checkQuestion(rdata._id);
          if (data && data.length > 0) return rdata;
        });
      };

      /**
       * show pop up with old answers
       */
      $scope.getOldAnswers = function (val) {
        MyService.ConsoleLog('dava', val.oldAnswers);
        $scope.oldAns = val.oldAnswers;
        // $scope.showPopUp.close();
        $scope.showPopUp = ngDialog.open({
          template: 'showOldAnsPopUp',
          closeByNavigation: true,
          scope: $scope,
        });
      };

      $scope.getComapreClass = function (match, i) {
        // MyService.ConsoleLog('match: ', match);
        /*if(match == 100)
          return 'ag-green';
      if(match == 0)
          return 'ag-red';
      if(match > 0 && match <100)
          return 'ag-dg'+i;*/
        if (match == 1) return 'ag-dg1';
        if (match == 2) return 'ag-dg2';
        if (match == 3) return 'ag-dg3';
        if (match == 4) return 'ag-dg4';
        if (match == 5) return 'ag-dg5';
        if (match == 6) return 'ag-dg6';

        return 'ag-dg';
      };

      $scope.checkImpExists = function (val) {
        var obj = $scope.questions[$scope.currentIndex];
        // MyService.ConsoleLog(" qobject :", obj);
        if (obj) {
          var data = obj.userAnswer;

          if (data || data[0]) {
            // MyService.ConsoleLog("Importance data>> ", data[0].Importance);
            if (data[0]) {
              if (data[0].Importance == val) {
                return true;
              }
            } else {
              if (data.importance == val) {
                return true;
              }
            }
          }
        }
        return false;
      };
      $scope.initMyAnswer = function () {
        try {
          if ($scope.questions[$scope.currentIndex].userAnswer) {
            var user_answer = ($scope.importance =
              $scope.questions[$scope.currentIndex].userAnswer[0]);
            $scope.importance = user_answer.importance;
            $scope.answer = user_answer.answer;
            $scope.comment = user_answer.comment;
          } else {
            $scope.initializeVariables();
          }
        } catch (e) {
          $scope.initializeVariables();
          //MyService.ConsoleLog("Exceptoin: ", e)
        }
      };

      $scope.getPoliData = function () {
        MyService.getPoliDetails({
          voteCandidateId: $routeParams.voteCandidateId,
          uId: $scope.routeId
        }).then(function (response) {
          MyService.ConsoleLog("Response", response);
          $scope.eleDate = response.electionDate;
          MyService.ConsoleLog("$scope.eleDate", $scope.eleDate);
          var curDate = new Date();
          if ($scope.eleDate >= curDate.toISOString().split('T')[0]) {
            $cookies.put('electionDate', $scope.eleDate);
          }
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      /**
       * Get Questions of questions of votesmart
       */
      $scope.getVotesmartData = function (qId, voteCanId) {
        $scope.showLoader = true;
        MyService.getVotesmartData({
          qId: qId,
          voteCandidateId: voteCanId
        }).then(function (response) {
          MyService.ConsoleLog("response", response);
          $scope.voteData = response.data.data;
          $scope.showLoader = false;
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      /**
       * End of
       */
      var getQuestionsCanceler = $q.defer();

      function getQuestions(params) {
        // alert('cll');
        // MyService.ConsoleLog('params', params);
        getQuestionsCanceler.resolve();
        $scope.status.questions = 1;
        params.timeout = getQuestionsCanceler.promise;
        //MyService.ConsoleLog("questionAnsByCtrl** /questions/list: ", params);
        $scope.questions = [];
        var request = apiCall.apiCall('GET', '/questions/list', params);
        $http(request).then(
          function successCallback(response) {
            // MyService.ConsoleLog("questionAnsByCtrl Got categories: ", response);
            if (response.data.success) {
              $scope.status.questions = 2;
              $scope.questions = response.data.data;
              $scope.questions.sort(function (a, b) {
                var x, y;
                x = a.categories[0].viewOrder;
                y = b.categories[0].viewOrder;

                if (x < y) {
                  return -1;
                }
                if (x > y) {
                  return 1;
                }
                return 0;
              });
              MyService.ConsoleLog("check now", $scope.questions[0]);
              $scope.isShowVotesmart = $scope.questions[0].isVotesmart;
              if ($scope.isShowVotesmart) {
                var queId, voCanId;
                queId = $scope.questions[0].userAnswer[0].question_id;
                voCanId = $scope.questions[0].userAnswer[0].candidate_id;
                $scope.getVotesmartData(queId, voCanId);
              }

              MyService.ConsoleLog("one more", $scope.isShowVotesmart);
              // window.localStorage.setItem('tempQues', JSON.stringify(response.data.data));
              // $rootScope.tempQuestions = response.data.data;
              // MyService.ConsoleLog('one $rootScope.tempQuestions', $rootScope.tempQuestions);
              MyService.ConsoleLog('total questions: ', $scope.questions);
              // MyService.ConsoleLog("total questions: ", $scope.questions[0].userAnswer[0].answer);
              $scope.getPoliData();
            } else {
              $scope.status.questions = 3;
              if (typeof response.data.error == 'string') growl.error(response.data.error);
              else growl.error('Something went wrong');
            }
          },
          function errorCallBack(response) {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      }

      // $scope.searchQuestion = function(searchKey) {
      //   // alert('call');
      //   var temp = JSON.parse(window.localStorage.getItem('tempQues'));

      //   // MyService.ConsoleLog('temp', temp);
      //   if (temp.length > 0) {
      //     $scope.questions = temp;
      //   }
      //   // var searchKey = new RegExp(val, 'i');
      //   // MyService.ConsoleLog('searchKey', searchKey);
      //   const res = $scope.questions.filter(function(obj) {
      //     // MyService.ConsoleLog('obj', obj.content.indexOf(searchKey));
      //     if (obj.content.indexOf(searchKey) > -1) {
      //       return obj.content.indexOf(searchKey);
      //     }
      //   });
      //   if (res && res.length > 0) {
      //     $scope.questions.length = 0;
      //     $scope.questions = res;
      //   } else {
      //     growl.error('Records not found for this search');
      //   }
      //   // MyService.ConsoleLog('res', res);
      //   $scope.ques.searchQuestionKeyword = '';
      // };

      // $scope.getUserProfile = function(params) {
      //   MyService.getProfile($routeParams.user_id).then(function(userData) {
      //     $scope.userData = userData;
      //     //MyService.ConsoleLog("userData: ", $scope.userData);
      //   }, function(err) {
      //     growl.error("Something went wrong while fetching user info.");
      //   });
      // }

      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;

      $scope.getCategory = function (params) {
        if (prevFetchedCategoryId != params.id) {
          //MyService.ConsoleLog("scrollTop");

          $('html, body').animate({
              scrollTop: 0,
            },
            'slow'
          );

          getCategoryCanceler.resolve();
          $scope.status.category = 1;
          params.timeout = getCategoryCanceler.promise;
          var request = apiCall.apiCall('GET', '/categories/list', params);
          $http(request).then(
            function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.category = response.data.data;
                //MyService.ConsoleLog("category data: ", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
                $scope.status.category = 3;
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
              $scope.status.category = 3;
            }
          );
        }
      };

      $scope.gotoPolitician = function () {
        $location.path('/compare-select/politician');
      };

      $scope.postAnswer = function (postData) {
        //MyService.ConsoleLog("postData: ", postData);
        try {
          var my_answer = $scope.questions[$scope.currentIndex].my_answer;
          if (
            my_answer.importance != $scope.importance ||
            my_answer.answer != $scope.answer ||
            my_answer.comment != $scope.comment
          ) {
            //MyService.ConsoleLog("Answer changed");
            postData.id = my_answer._id;
            postData.reason = $scope.reason;
          } else {
            //MyService.ConsoleLog("Answer not changed");
            return; //don't do anything
          }
        } catch (e) {
          //MyService.ConsoleLog("Exceptoin: ", e);
        }

        MyService.postAnswer(postData).then(
          function (response) {
            if (response.data.success) {
              try {
                if (postData.id) {
                  $scope.questions[$scope.currentIndex]['my_answer'] = {
                    _id: postData.id,
                    created: new Date(),
                    comment: postData.comment,
                    answer: postData.answer,
                    importance: postData.importance,
                  };
                } else {
                  $scope.questions[$scope.currentIndex]['my_answer'] = {
                    _id: response.data.data._id,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance,
                  };
                }
              } catch (e) {
                //MyService.ConsoleLog("Exceptoin: ", e);
              }
              if (postData.id) {
                growl.success('Answer updated successfully', {
                  ttl: 800,
                });
              } else {
                growl.success('Answered successfully', {
                  ttl: 800,
                });
              }
              $scope.initializeVariables();
              $scope.skipQuestion();
            } else {
              growl.error('Error: ' + response.data.error);
            }
          },
          function (err) {
            growl.error('Something went wrong.');
          }
        );
      };

      $scope.skipQuestion = function () {
        /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
      }, 1500);
      */
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.initializeVariables();
            $scope.currentIndex++;
            $scope.isShowVotesmart = $scope.questions[$scope.currentIndex].isVotesmart;
            var qId, vCanId;
            if ($scope.isShowVotesmart) {
              qId = $scope.questions[$scope.currentIndex].userAnswer[0].question_id;
              vCanId = $scope.questions[$scope.currentIndex].userAnswer[0].candidate_id;
              $scope.getVotesmartData(qId, vCanId);
            }
            MyService.ConsoleLog("<<<<<<<<  $scope.isShowVotesmart >>>>>>>>>>", $scope.isShowVotesmart);
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          //MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ",$scope.questions.length);
          if (tempIndex == $scope.questions.length) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            if (tempBackIdx < $scope.traverseBackgrounds.length) {
              $scope.currBackgroundIndex++;
              $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            } else {
              //MyService.ConsoleLog("* End of background *");
            }
          }
          // console.log("Display Q :", $("#DisplayQ").offset().top);
          $('html, body').animate({
              scrollTop: $('#DisplayQ').offset().top - 150,
            },
            'slow'
          );
        };

        skip();
      };

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
      }, 1500);
      */
        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
          $scope.isShowVotesmart = $scope.questions[$scope.currentIndex].isVotesmart;
          if ($scope.isShowVotesmart) {
            var quId = $scope.questions[$scope.currentIndex].userAnswer[0].question_id;
            var CanId = $scope.questions[$scope.currentIndex].userAnswer[0].candidate_id;
            $scope.getVotesmartData(quId, CanId);
          }
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      $scope.getInfo = function (val) {
        if (val && val.comment && val.comment != '') {
          $scope.showComment = val.comment;
          $scope.showPopUp = ngDialog.open({
            template: 'showInfoPopUp',
            closeByNavigation: true,
            scope: $scope,
          });
        }
      };

      /**
       * Show votesmart data
       */
      $scope.showVotesmartAns = function (data) {
        MyService.ConsoleLog("data", data);

        $location.path('/showVotesmartAnsQues/' + data.userAnswer[0].question_id + '/' + data.userAnswer[0].candidate_id);
      };


      var getCompareAnswersCanceler = $q.defer();

      function getCompareAnswers(params) {
        if (!params) return;
        getCompareAnswersCanceler.resolve();
        params.timeout = getCompareAnswersCanceler.promise;

        $scope.questions = [];
        $scope.status.questions = 1;

        var request = apiCall.apiCall('GET', '/answers/compare', params);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.status.questions = 2;
              angular.forEach(response.data.data, function (val, key) {
                if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
                  var check_author_exist = function (u) {
                    var len = val.answers.length;
                    for (var i = 0; i < len; i++) {
                      if (val.answers[i].author._id == u._id) {
                        return true;
                      }
                    }
                    return false;
                  };

                  angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                    if (!check_author_exist(u)) {
                      response.data.data[key].answers.push({
                        author: u,
                      });
                    }
                  });
                } else if (!val.answers) {
                  response.data.data[key].answers = [];
                  angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                    response.data.data[key].answers.push({
                      author: u,
                    });
                  });
                }
              });

              $scope.questions = response.data.data;
              // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
            } else {
              $scope.status.questions = 3;
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      }

      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);

      $scope.state = 'question-answered-by';
      if ($routeParams.user_id) {
        // getPopularQuestions();
        MyService.ConsoleLog(' type :', $routeParams.type);
        if ($routeParams.type && $routeParams.type == 'politician') {
          MyService.ConsoleLog('welcome to if');

          getQuestions({
            answered_by: $routeParams.user_id,
            voteCandidateId: $routeParams.voteCandidateId,
          });
        } else {
          MyService.ConsoleLog('welcome to Else');

          // $scope.getUserProfile();
          getQuestions({
            answered_by: $routeParams.user_id,
            voteCandidateId: $routeParams.voteCandidateId,
          });
        }
      }

      $scope.$watch('questions', function (newValue, oldValue) {
        //MyService.ConsoleLog("*** questions changed ***");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid,
            });
          }

          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("questions Index Change Ex: ", e)
        }
      });
      $scope.$watch('currentIndex', function (newValue, oldValue) {
        //MyService.ConsoleLog("*** currentIndex changed ***");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid,
            });
          }

          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });
    },
  ]);
