'use strict';
angular
  .module('myApp.poliAnswers', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/poliAnswers/:quizName?/:poliId?/:email?', {
        templateUrl: 'app/poliAnswers/poliAnswers.html',
        controller: 'poliAnswersCtrl',
      });
    },
  ])
  .controller('poliAnswersCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {
      /**
       * Clear $cookies on page load
       */
      $scope.qName = $routeParams.quizName;
      $scope.poliId = $routeParams.poliId;
      $scope.uEmail = $routeParams.email;
      $cookies.remove('showLogin');
      $cookies.remove('qName');
      $cookies.remove('poliId');
      window.localStorage.removeItem('poliQuiz');

      if (!tokenService.getUserId()) {
        // alert('one');
        $cookies.put('qName', $scope.qName);
        $cookies.put('poliId', $scope.poliId);
        $cookies.put('showLogin', 'true');
        $location.path('/signin');
      }
      $scope.traverseBackgrounds = [];
      $scope.currBackgroundIndex = 0;
      $('input[type=radio]').attr('disabled', true);
      $scope.status = {
        questions: 0, //1= loading, 2 = loaded, 3 = error
        category: 0,
      };

      /**
       * getAnswered questions of Politicians
       */
      $scope.getAnsQues = function () {

        if ($scope.uEmail && $scope.uEmail != '') {
          MyService.ConsoleLog("$scope.uEmail", $scope.uEmail);
          MyService.getFollowersAnsQues({
            email: $scope.uEmail
          }).then(function (response) {
            MyService.ConsoleLog("Response", response);
            if (response && response.data.error && response.data.error != '') {
              growl.error(response.data.error);

            }
            $scope.status.questions = 2;
            $scope.questions = response.data.data;
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        } else {
          MyService.getAnsQues({
            quizName: $scope.qName,
            poliId: $scope.poliId
          }).then(function (results) {
            MyService.ConsoleLog("Results", results);
            $scope.status.questions = 2;
            $scope.questions = results.data;
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        }

      };
      $scope.getAnsQues();

      /**
       * Go to the listing page
       */
      $scope.gotoList = function () {
        if ($scope.uEmail && $scope.uEmail != '') {
          $location.path('/quizResult');
        }
      };

      $scope.$watch('questions', function (newValue, oldValue) {
        MyService.ConsoleLog("*** questions changed ***");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid,
            });
          }

          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("questions Index Change Ex: ", e)
        }
      });
      $scope.$watch('currentIndex', function (newValue, oldValue) {
        MyService.ConsoleLog("*** currentIndex changed ***");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid,
            });
          }

          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });


      /**
       * Get Match
       */
      $scope.getMatch = function () {
        var qLen = $scope.questions.length;
        var tempArr = [];
        for (var i = 0; i < qLen; i++) {
          tempArr.push($scope.questions[i].userAnswer);
        }
        MyService.ConsoleLog("tempArr", tempArr);
        window.localStorage.setItem('poliQuiz', JSON.stringify($scope.questions));
        $location.path('/politicianMatch/' + $scope.poliId);
      };


      $scope.getComapreClass = function (match, i) {
        // MyService.ConsoleLog('match: ', match);
        /*if(match == 100)
          return 'ag-green';
      if(match == 0)
          return 'ag-red';
      if(match > 0 && match <100)
          return 'ag-dg'+i;*/
        if (match == 1) return 'ag-dg1';
        if (match == 2) return 'ag-dg2';
        if (match == 3) return 'ag-dg3';
        if (match == 4) return 'ag-dg4';
        if (match == 5) return 'ag-dg5';
        if (match == 6) return 'ag-dg6';

        return 'ag-dg';
      };

      $scope.checkImpExists = function (val) {
        if ($scope.questions && $scope.questions.length > 0) {
          var obj = $scope.questions[$scope.currentIndex];
        }
        // MyService.ConsoleLog(" qobject :", obj);
        if (obj) {
          var data = obj.userAnswer;

          if (data || data[0]) {
            // MyService.ConsoleLog("Importance data>> ", data[0].Importance);
            if (data[0]) {
              if (data[0].Importance == val) {
                return true;
              }
            } else {
              if (data.importance == val) {
                return true;
              }
            }
          }
        }
        return false;
      };
      $scope.initMyAnswer = function () {
        try {
          if ($scope.questions[$scope.currentIndex].userAnswer) {
            var user_answer = ($scope.importance =
              $scope.questions[$scope.currentIndex].userAnswer[0]);
            $scope.importance = user_answer.importance;
            $scope.answer = user_answer.answer;
            $scope.comment = user_answer.comment;
          } else {
            $scope.initializeVariables();
          }
        } catch (e) {
          $scope.initializeVariables();
          //MyService.ConsoleLog("Exceptoin: ", e)
        }
      };

      $scope.currentIndex = 0;
      $scope.initializeVariables = function () {
        $scope.importance = null;
        $scope.answer = null;
        $scope.comment = '';
        $scope.reason = '';
      };
      $scope.initializeVariables();
      $scope.checkIfExists = function (val) {
        // console.log("questions <><><>", $scope.questions);
        // MyService.ConsoleLog("Val > ", val);
        if ($scope.questions && $scope.questions.length > 0) {
          var obj = $scope.questions[$scope.currentIndex];
        }

        // MyService.ConsoleLog("checkIfExists >> ", obj);
        if (obj) {
          var data = obj.userAnswer;
          if (data || data[0]) {
            // MyService.ConsoleLog("data", data);
            if (data[0]) {
              MyService.ConsoleLog('checkIfExists data>> ', data[0].answer + '==' + val);
              $scope.comment = data[0].comment;
              if (data[0].answer == 'No') {
                if (val == 5) return true;
              }
              if (data[0].answer == 'Yes') {
                if (val == 2) return true;
              }
            } else {
              // MyService.ConsoleLog("checkIfExists data>> ", data[0].answer + "==" + val);
              $scope.comment = data.comment;
              // $scope.answer = data.answer;
              // if (data.answer == "No") {
              //   if (val == 5)
              //     return true;
              // }
              // if (data.answer == "Yes") {
              //   if (val == 2)
              if (val == data.answer) {
                return true;
              }
              // }
            }
          }
        }
        return false;
      };

      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;

      $scope.getCategory = function (params) {
        if (prevFetchedCategoryId != params.id) {
          //MyService.ConsoleLog("scrollTop");

          $('html, body').animate({
              scrollTop: 0,
            },
            'slow'
          );

          getCategoryCanceler.resolve();
          $scope.status.category = 1;
          params.timeout = getCategoryCanceler.promise;
          var request = apiCall.apiCall('GET', '/categories/list', params);
          $http(request).then(
            function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.category = response.data.data;
                //MyService.ConsoleLog("category data: ", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
                $scope.status.category = 3;
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
              $scope.status.category = 3;
            }
          );
        }
      };

      $scope.skipQuestion = function () {
        /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
      }, 1500);
      */
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.initializeVariables();
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          //MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ",$scope.questions.length);
          if (tempIndex == $scope.questions.length) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            if (tempBackIdx < $scope.traverseBackgrounds.length) {
              $scope.currBackgroundIndex++;
              $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            } else {
              //MyService.ConsoleLog("* End of background *");
            }
          }
          // console.log("Display Q :", $("#DisplayQ").offset().top);
          $('html, body').animate({
              scrollTop: $('#DisplayQ').offset().top - 150,
            },
            'slow'
          );
        };

        skip();
      };

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
      }, 1500);
      */
        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      $scope.checkQuestionAnswered = function () {
        // console.log("scope.questions > ", $scope.questions);
        return $scope.questions.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          var data = $scope.checkQuestion(rdata._id);
          if (data && data.length > 0) return rdata;
        });
      };
    },
  ]);
