"use strict";
angular
  .module("myApp.liveFeed", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/liveFeed", {
        templateUrl: "app/liveFeed/liveFeed.html",
        controller: "liveFeedCtrl",
      });
    },
  ])

  .controller("liveFeedCtrl", [
    "$scope",
    "$rootScope",
    "MyService",
    "$http",
    "tokenService",
    "baseApiUrl",
    "$sce",
    "growl",
    function (
      $scope,
      $rootScope,
      MyService,
      $http,
      tokenService,
      baseApiUrl,
      $sce,
      growl
    ) {
      $scope.feed = {
        data: [],
      };
      $scope.showLoading = false;
      $rootScope.welcome = false;
      $scope.favChannel = "associated-press";
      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
        if (
          $scope.userData.my_fav_channel &&
          $scope.userData.my_fav_channel != ""
        ) {
          $scope.favChannel = $scope.userData.my_fav_channel;
        }
      }
      MyService.ConsoleLog("baseApiUrl >> ", baseApiUrl);
      $scope.baseApiUrl = $sce.trustAsResourceUrl(
        baseApiUrl + "/user/getRSSFeeds"
      );
      $scope.title = $scope.favChannel
        .replace(new RegExp("-", "g"), " ")
        .toUpperCase();
      $scope.getAllFeeds = function () {
        $scope.feed.data.length = 0;
        $scope.showLoading = true;

        MyService.getFavNews({ favChannel: $scope.favChannel }).then(
          function (payload) {
            $scope.showLoading = false;
            MyService.ConsoleLog(payload);
            $scope.feed.data = payload;
          },
          function (errorPayload) {
            $scope.showLoading = false;
            alert("Something went wrong");
          }
        );
      };

      $scope.allSource = [];
      $scope.getNewsSources = function () {
        $scope.allSource.length = 0;
        MyService.getNewsSource().then(
          function (payload) {
            $scope.allSource = payload;
            $scope.newsChannel = $scope.favChannel;
          },
          function (errorPayload) {
            growl.error("News services down currently.");
          }
        );
      };

      $scope.changeNewsChannel = function (channel) {
        //alert(channel);
        MyService.saveFavChannel({ channel: channel }).then(
          function (payload) {
            MyService.ConsoleLog(payload);
            $scope.favChannel = channel;
            $scope.userData.my_fav_channel = channel;
            $scope.title = $scope.favChannel
              .replace(new RegExp("-", "g"), " ")
              .toUpperCase();
            tokenService.saveUserData(JSON.stringify($scope.userData));
            $scope.getAllFeeds();
            //growl.success("Your favorite channel saved successfully.");
          },
          function (errorPayload) {
            growl.error("Something went wrong while saving the favorites.");
          }
        );
      };

      $scope.getAllFeeds();
      $scope.getNewsSources();
    },
  ]);
