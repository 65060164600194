"use strict";
angular
  .module("myApp.votesmart", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/votesmart", {
        templateUrl: "app/voteSmart/votesmart.html",
        controller: "votesmartCtrl"
      });
    }
  ])
  .controller("votesmartCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    $rootScope.courage = false;
    $rootScope.incumbent = false;
    $rootScope.CityCouncil = false;
    $rootScope.withoutState = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;
    $scope.showLoader = false;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          console.log("payload : ", payload);
          $scope.allState = payload;
          var addNwState = payload;
          var smpl = {
            state: "NA",
            statePostalCode: "NA"
          };
          addNwState.unshift(smpl);
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );

    };

    // console.log($scope.selectState);
    $scope.getElections = function () {
      $scope.loader = true;
      // console.log("address.state", $scope.address.state.statePostalCode);
      // var d = new Date();
      // $scope.today = d.toISOString();
      // console.log("dates", $scope.today);
      // $scope.today = '2018-06-05 00:00:00.000Z';
      // console.log("dates", $scope.today);

      MyService.getElectionData({
        stateCode: $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (response) {
        $scope.ElectionData = response;
        $scope.loader = false;

        MyService.ConsoleLog("results:", $scope.ElectionData);
      }, function (err) {
        $scope.loader = false;
        growl.error(err.error);
      });
    };
    var d = new Date();
    $scope.today = d.toISOString().split('T')[0];
    $scope.getItems = function () {


      return function (u) {
        var len = u.stage.length;
        // MyService.ConsoleLog("params", today);
        // MyService.ConsoleLog("params length", len);

        for (var i = 0; i < len; i++) {
          MyService.ConsoleLog("params counter", u.stage[i].electionDate);


          if (u.stage[i].electionDate >= $scope.today) {
            var date = new Date(u.stage[i].electionDate);
            var fomr = date.toISOString().split('T')[0];
            u.stage[i].electionDate = fomr;
            return u;
          }


        }

      }
    };
    $scope.getAllStates();

    $scope.back = function () {
      $scope.candidates = false;


    };

    $scope.getCandidates = function (eid) {
      $scope.candidates = true;

      // console.log($scope.address.state.state);
      // console.log("welcome to get candidates :", eid);
      MyService.getVoteCandidates({
        e_id: eid
      }).then(function (result) {
        $scope.candidatesData = result;
        MyService.ConsoleLog("results :", $scope.candidatesData);
      });
    };

    /**
     * @description Function is use to insert records of presidential race.
     */
    $scope.getPrimaryElections = function () {
      MyService.ConsoleLog("this is president");
      $scope.showLoader = true;
      MyService.getPresidentData({
        stateCode: $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (APIResponse) {

        MyService.ConsoleLog("APIReponse", APIResponse);
        $scope.ElectionData = APIResponse;
        $scope.showLoader = false;
      }, function (err) {
        $scope.showLoader = false;
        MyService.ConsoleLog("Error", err);
      });
    };
  }]).controller("votesmartLocalCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {
    $scope.candidates = false;
    $rootScope.election = false;
    $rootScope.cityData = false;
    $rootScope.courage = false;
    $rootScope.incumbent = false;
    $rootScope.CityCouncil = false;
    $rootScope.withoutState = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;


    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };
    $scope.getAllStatesData = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };
    // console.log($scope.selectState);

    $scope.getLocalData = function () {
      $scope.loader = true;

      // MyService.getLocalOffices({"state":state})

      // console.log("call");
      // console.log("address.state", $scope.address.state.statePostalCode);

      MyService.getLocalOffices({
        "stateCode": $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (success) {
        $scope.CountyData = success;
        console.log("success :", $scope.CountyData);

        $scope.loader = false;

      }, function (err) {
        console.log("called errr :", err);
        growl.error("No officials found in this locality.");
        $scope.loader = false;
      });

    };

    // $scope.getItems = function () {

    //   var d = new Date();
    //   var today = d.toISOString();
    //   return function (u) {
    //     var len = u.stage.length;
    //     // MyService.ConsoleLog("params", len);
    //     // MyService.ConsoleLog("params", u);

    //     for (var i = 0; i < len; i++) {
    //       // console.log("data", u.stage[i].electionDate);

    //       return u.stage[i].electionDate >= today;

    //     }

    //   }
    // };

    $scope.getAllStatesData();

    //   $scope.back = function () {
    //     $scope.candidates = false;


    //   };

    //   $scope.getCandidates = function (eid) {
    //     $scope.candidates = true;

    //     // console.log($scope.address.state.state);
    //     // console.log("welcome to get candidates :", eid);
    //     MyService.getVoteCandidates({
    //       e_id: eid
    //     }).then(function (result) {
    //       $scope.candidatesData = result;
    //       MyService.ConsoleLog("results :", $scope.candidatesData);
    //     });
    //   };
  }]).controller("LocalCityCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    // $scope.candidates = false;
    $rootScope.election = false;
    $rootScope.local = false;
    $rootScope.courage = false;
    $rootScope.incumbent = false;
    $rootScope.CityCouncil = false;
    $rootScope.withoutState = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };
    $scope.getAllStatesData = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };
    // console.log($scope.selectState);

    $scope.getLocalCity = function () {
      $scope.loader = true;

      // MyService.getLocalOffices({"state":state})

      // console.log("call");
      // console.log("address.state", $scope.address.state.statePostalCode);

      MyService.getLocalOfficesForCity({
        "stateCode": $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (success) {
        // MyService.ConsoleLog("success :", success);
        $scope.City = success;
        $scope.loader = false;

      }, function (err) {
        console.log("called errr :", err);
        growl.error("No officials found in this locality.");
        $scope.loader = false;
      });

    };

    // $scope.getItems = function () {

    //   var d = new Date();
    //   var today = d.toISOString();
    //   return function (u) {
    //     var len = u.stage.length;
    //     // MyService.ConsoleLog("params", len);
    //     // MyService.ConsoleLog("params", u);

    //     for (var i = 0; i < len; i++) {
    //       // console.log("data", u.stage[i].electionDate);

    //       return u.stage[i].electionDate >= today;

    //     }

    //   }
    // };

    $scope.getAllStatesData();

    //   $scope.back = function () {
    //     $scope.candidates = false;


    //   };

    //   $scope.getCandidates = function (eid) {
    //     $scope.candidates = true;

    //     // console.log($scope.address.state.state);
    //     // console.log("welcome to get candidates :", eid);
    //     MyService.getVoteCandidates({
    //       e_id: eid
    //     }).then(function (result) {
    //       $scope.candidatesData = result;
    //       MyService.ConsoleLog("results :", $scope.candidatesData);
    //     });
    //   };
  }]).controller("CourageTest", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      tokenService,
      MyService
    ) {

      // $scope.candidates = false;
      $rootScope.election = false;
      $rootScope.local = false;
      $rootScope.cityData = false;
      $rootScope.CityCouncil = false;
      $rootScope.withoutState = false;
      $rootScope.catList = false;
      $rootScope.orgvList = false;
      $scope.Test = false;
      $scope.candy = false;
      $scope.Update = [];
      // $scope.allParentCats = [];
      $scope.catd = [];
      $scope.success_msg = false;
      $scope.success_msg1 = false;

      $scope.showtbl = [];
      $scope.present = false;



      // $scope.fiveMatch= {
      //   match:[]
      // };




      $scope.TstData = [];

      $scope.more = [];

      $scope.allState = [];
      $scope.address = {
        state: "",
        upstate: "",
        files: ""
      };

      $scope.fiveMatch = {
        "match": [],

      };

      $scope.cat = {
        "selected": []
      }

      // category listing

      $scope.getParentCategories = function () {
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall('GET', '/categories/list', {
          root: 1,
          questions_counter: 0,
          catTypes: "Q"
        });
        $http(
          request
        ).then(function successCallback(response) {
          console.log("wwwwww :", response);
          $scope.parentCategories = response.data.data;

          if (response.data.success) {
            $scope.selCatForQues = [{
              "selected": null,
              viewOrders: 0,
              "items": $scope.parentCategories
            }];
            $scope.questions = [];
            $scope.statusGetCats = 2;
          } else {
            growl.error("Something went wrong");
            $scope.statusGetCats = 3;
          }
        }, function errorCallBack(response) {
          growl.error("Something went wrong");
          $scope.statusGetCats = 3;
        });
      }

      $scope.checkExistsInArr = function (rdata, val) {
        return rdata.filter(
          function (rdata) {
            //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
            if (rdata._id == val._id)
              return rdata
          }
        );
      }

      $scope.addToFiveMatch = function (pa, co, ftype, idx) {

        // $scope.$apply(function() {
        // $scope.TstData[idx].QueId.push(pa._id);
        // });



        // MyService.ConsoleLog("datatatatatatatata :",  $scope.fnArr);

        MyService.UpdateCourage({
          "qId": pa._id,
          "courageQId": co._id,
          "catId": $rootScope.catid,
          "ftype": ftype
        }).then(function (udata) {

          console.log(" updated :", udata);

          if (ftype !== "remove") {

            if (udata.data.data.QueId.length > 0) {
              // alert("Success");


              // $(document).ready(function() {
              // $scope.success_msg = true;
              setTimeout(function () {
                // $(".alert").alert('close');
                $scope.$apply(function () {
                  console.log("index > ", idx);
                  console.log("Qid > ", $scope.TstData[idx].QueId);
                  console.log("paid > ", pa._id);

                  $scope.TstData[idx].QueId.push(pa._id);
                  console.log("TST :::: ", $scope.TstData[idx]);
                  // $scope.exist($scope.TstData[idx].QueId, pa._id);
                })

              }, 300);

              // });

            } else {
              alert("Failed");
              // $scope.$apply();
              // $(document).ready(function() {
              // $scope.success_msg1 = true;
              // setTimeout(function() {
              // $(".alert").alert('close');


              // }, 3000);

              // });

            }
          } else {
            setTimeout(function () {
              // $(".alert").alert('close');
              $scope.$apply(function () {
                console.log("index > ", idx);
                console.log("Qid > ", $scope.TstData[idx].QueId);
                console.log("paid > ", pa._id);

                $scope.TstData[idx].QueId.pop(pa._id);
                console.log("TST :::: ", $scope.TstData[idx]);
                // $scope.exist($scope.TstData[idx].QueId, pa._id);
              })

            }, 300);
          }


          //
          // $scope.selCatForQues=[];

          // $scope.showtbl[co]=false;
          // $scope.getCourTest();
          // $scope.getParentCategories();


        });

      }

      var selQuesCategoryCanceler = $q.defer();
      var getQuestoinsCanceler = $q.defer();

      $scope.statusSubCat = 0;
      $scope.getParentCategories();

      $scope.selQuesCategory = function (pcat, index, uid) {

        // console.log("showtable : ", $scope.showtbl);

        var keysarray = Object.keys($scope.showtbl);
        console.log("keysarray > ", keysarray);

        for (var i = 0; i < keysarray.length; i++) {
          $scope.showtbl[keysarray[i]] = false;
          // pcat[keysarray[i]] = "";

        }



        // MyService.ConsoleLog("params :", uid._id);
        // MyService.ConsoleLog("params :", pcat);


        var catp = pcat[uid._id];
        var dat = JSON.parse(catp);

        // console.log("select cat :", dat);

        $rootScope.uid = uid._id;

        // console.log("select cat :", dat.title);


        MyService.getAllFiveMatch({
          "catId": dat._id
        }).then(function (fdata) {

          // console.log("fiveMatch que :", fdata);
          $scope.tbl = fdata.data.data;
          var ucid = uid._id;
          $scope.showtbl[ucid] = true;
        });


        $rootScope.catid = dat._id;
        // console.log("cat id :", $rootScope.catid);
        selQuesCategoryCanceler.resolve();
        var cat = JSON.parse(catp);
        //MyService.ConsoleLog("tempFun index: ", index);
        //remove all elements after given index


        getQuestoinsCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();

        $scope.selCatForQues.length = index + 1;

        var params = {
          questions_counter: 0,
          parentId: cat._id,
          catTypes: "Q"
        };
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = selQuesCategoryCanceler.promise;
        $scope.statusSubCat = 1;
        $http(
            request
          )
          .then(function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  "selected": null,
                  viewOrders: 0,
                  "items": response.data.data
                });
              else if (response.data.data.length == 0) {
                // $scope.getQuestoins(cat._id);
              }
              $scope.statusSubCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.statusSubCat = 3;
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.statusSubCat = 3;
          });
      };

      $scope.exist = function (arr, start) {


        // MyService.ConsoleLog("exist :>>>> ",arr);
        // MyService.ConsoleLog("exist :>>>> ",arr.length);
        //   MyService.ConsoleLog("exist start :>>>> ",start);



        // $scope.fnArr=arr;
        // arr=$rootScope.fnArr;


        if (arr) {

          $scope.indx = arr.indexOf(start);
          // $scope.indx=uid.QueId.indexOf($scope.tbl._id);
          // console.log("index of ", $scope.indx);


          if ($scope.indx == '-1') {

            // console.log("######################### -1 ############################### ");


            return true;
          } else {
            return false;
          }

        } else {
          return true
        }

        // console.log("index of ", $scope.indx);

        // return $scope.indx

      }




      // $scope.getMatchData = function(par) {

      // $scope.getParentCategories();

      //     console.log("welcome to getMatchData  :", par.fiveMatchQue);

      //     // $scope.fiveMatch.match[par]=true;
      //     // MyService.ConsoleLog("select :", $scope.fiveMatch);

      //     // MyService.ConsoleLog("select :", $scope.fiveMatch.match[par._id]);

      //     $rootScope.upId = par._id;
      //     $rootScope.flag=$scope.fiveMatch.match[par._id];


      //     if ($scope.fiveMatch.match[par._id] == 'Yes') {

      //       $scope.Update[par._id] = true;
      //       $scope.catd[par._id] = true;

      //     } else {

      //       $scope.Update[par._id] = false;


      //     }

      //     if (par.fiveMatchQue == 'Yes') {
      //       console.log("welcome to first :", par._id);

      //       $scope.Update[par._id] = true;
      //       $scope.catd[par._id] = true;


      //     }




      //   };

      // $scope.updateFiveMatch = function(u) {

      //   MyService.ConsoleLog("welcome to save  ::",  $rootScope.catid );

      //   MyService.UpdateFiveMatchCou({ "id": u._id,"catId":$rootScope.catid,"flag":$rootScope.flag }).then(function(edata) {

      //     console.log("Updated successfully :", edata);
      //      $(document).ready(function () {
      //     $scope.success_msg = true;
      //     setTimeout(function () {
      //       $(".alert").alert('close');


      //     }, 3000);

      //   });


      //   });
      // }

      $scope.getStates = function () {
        $scope.allState.length = 0;
        $scope.loader = true;
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            // console.log("payload : ", payload);
            $scope.allState = payload;
            var smpl = {
              state: "NA",
              statePostalCode: "NA"
            };
            $scope.allState.unshift(smpl);
            $scope.loader = false;
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.loader = false;
          }
        );
      };
      // console.log($scope.selectState);

      $scope.getCourageData = function () {
        $scope.loader = true;
        $scope.success_msg = false;

        // MyService.getLocalOffices({"state":state})

        // console.log("call");
        // console.log("address.state", $scope.address.state.statePostalCode);
        $rootScope.statePostalCode = $scope.address.state.statePostalCode;

        MyService.getCourageTest({
          "stateCode": $scope.address.state.statePostalCode
        }).then(function (success) {
          $scope.courageResult = success;
          // console.log("  $scope.courageResult :",  $scope.courageResult);

          $scope.loader = false;

        });

      };



      $scope.getStates();

      // $scope.currPage = 1;
      //  // $scope.statusGetResPg = 0;


      // $scope.myPagingFunction = function() {
      //   // MyService.ConsoleLog("scoll called : ", $scope.statusGetResPg);
      //   if ($scope.statusGetResPg != 1) {
      //     $scope.currPage++;

      //   // MyService.ConsoleLog("scoll  : ",$scope.currPage);

      //      $scope.getCourTest($scope.currPage);
      //   }
      // };
      $scope.import = false;

      $scope.importCourage = function (data) {
        if (data.files != "") {
          ////MyService.ConsoleLog(data.files.type.indexOf("office"));
          // alert("This needs to be implemented");
          if (data.files.type.indexOf("office") > -1) {
            $scope.import = true;
            //MyService.ConsoleLog("data : ", data);
            var fd = new FormData();
            fd.append('file', data.files);
            fd.append('options', data.opt);
            var posturl = baseApiUrl + "/votequestions/importCourageData";
            $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            }).then(function successCallback(response) {
              //console.log("Got countires: ", response);
              if (response.data.success) {
                $scope.import = false;
                alert("File successfully imported.");
              } else {
                //alert("Something went wrong: ", response.data);
                alert("Something went wrong.");
              }
            }, function errorCallBack(response) {
              console.log("Error: ", response);

            });
          } else {
            alert("Please select xlsx file");
          }

        } else {
          alert("Please select file");
        }

      }


      $scope.exportCourage = function () {

        MyService.ExportCourageData({
          "statePostalCode": $rootScope.statePostalCode
        }).then(function (payload) {
          MyService.ConsoleLog("Success Data", payload);

          window.open($scope.imgUrl + '/courageTest.xlsx', '_blank');
        }, function (errorPayload) {

          //MyService.ConsoleLog("errorPayload : ", errorPayload);
          alert("Something went wrong. Please try again after sometime");
        })

      };

      $scope.getCourTest = function () {
        MyService.getTestData({
          "statePostalCode": $rootScope.statePostalCode
        }).then(function (respo) {
          $scope.courage = false;
          $scope.Test = true;
          $scope.success_msg = false;

          // $scope.statusGetCats = 2;
          // console.log("respo :", respo);

          $scope.TstData = respo;
          // console.log("respo :", $scope.TstData);


        }, function (err) {
          //MyService.ConsoleLog(err);
          growl.error("Something went wrong.");

        });



      }

      $scope.getQuestion = function (category) {
        // console.log("Questions : ", category);
        $scope.more[category] = true;
      }

      $scope.showLess = function (category) {
        // console.log("id for less",id);
        $scope.more[category] = false;
        $scope.showtbl[category] = false;

      }

      $scope.backto = function () {
        $scope.Test = false;
        $scope.candy = false;
        $scope.Update[$rootScope.upId] = false;
        $scope.catd[$rootScope.upId] = false;
        $scope.showtbl[$rootScope.uid] = false;
        // $scope.statusGetCats = 1;
      };


      $scope.NotFound = false;


      $scope.getTst = function (u) {
        // console.log("params :",u.votesmartCandidateId);
        // MyService.ConsoleLog('params :', u);

        $scope.name = u.name;
        $scope.party = u.party;

        MyService.getCourageForCandidate({
          "c_id": u.votesmartCandidateId
        }).then(function (resultdata) {

          // console.log("data came :", resultdata);
          if (resultdata && resultdata.length > 0) {

            $scope.testy = resultdata;
            $scope.candy = true;
            // console.log("data came :", resultdata);
            $scope.NotFound = false;


          } else {
            $scope.testy = "";
            $scope.NotFound = true;
            $scope.candy = true;

            setTimeout(function () {

              $(".alert").alert('close');

            }, 3000);

          }




        });


      }



    }
  ]).controller("votesmartIncumbentCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.election = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    $rootScope.CityCouncil = false;
    $scope.Test = false;
    // $rootScope.incumbent = false;
    $rootScope.withoutState = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };

    // console.log($scope.selectState);
    $scope.getIncumbent = function () {
      $scope.loader = true;
      // console.log("address.state", $scope.address.state.statePostalCode);
      // var d = new Date();
      // $scope.today = d.toISOString();
      // console.log("dates", $scope.today);
      // $scope.today = '2018-06-05 00:00:00.000Z';
      // console.log("dates", $scope.today);

      MyService.getIncumbentData({
        stateCode: $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (response) {
        $scope.ElectionData = response;
        $scope.loader = false;
        // console.log("results:", $scope.ElectionData);
      });
    };
    var d = new Date();
    $scope.today = d.toISOString().split('T')[0];
    $scope.getItems = function () {


      return function (u) {
        var len = u.stage.length;
        // MyService.ConsoleLog("params", today);
        // MyService.ConsoleLog("params length", len);

        for (var i = 0; i < len; i++) {
          // MyService.ConsoleLog("params counter", i);


          if (u.stage[i].electionDate >= $scope.today) {

            return u.stage[i].electionDate >= $scope.today;
          }


        }

      }
    };
    $scope.getAllStates();


    $scope.back = function () {
      $scope.candidates = false;


    };

    $scope.getCandidates = function (eid) {
      $scope.candidates = true;

      // console.log($scope.address.state.state);
      // console.log("welcome to get candidates :", eid);
      MyService.getVoteCandidates({
        e_id: eid
      }).then(function (result) {
        $scope.candidatesData = result;
        MyService.ConsoleLog("results :", $scope.candidatesData);
      });
    };
  }]).controller("votesmartCityCouncil", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.election = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    // $rootScope.CityCouncil = false;
    $scope.Test = false;
    $rootScope.withoutState = false;
    $rootScope.incumbent = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };

    // console.log($scope.selectState);
    $scope.getCityCouncil = function () {
      $scope.loader = true;
      // console.log("address.state", $scope.address.state.statePostalCode);
      // var d = new Date();
      // $scope.today = d.toISOString();
      // console.log("dates", $scope.today);
      // $scope.today = '2018-06-05 00:00:00.000Z';
      // console.log("dates", $scope.today);

      MyService.getCityCouncil({
        stateCode: $scope.address.state.statePostalCode,
        state: $scope.address.state.state
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.citysd = response;
        $scope.candidates = true;
        $scope.loader = false;
        // console.log("results:", $scope.ElectionData);
      });
    };
    var d = new Date();
    $scope.today = d.toISOString().split('T')[0];
    $scope.getItems = function () {


      return function (u) {
        var len = u.stage.length;
        // MyService.ConsoleLog("params", today);
        // MyService.ConsoleLog("params length", len);

        for (var i = 0; i < len; i++) {
          // MyService.ConsoleLog("params counter", i);


          if (u.stage[i].electionDate >= $scope.today) {

            return u.stage[i].electionDate >= $scope.today;
          }


        }

      }
    };
    $scope.getAllStates();


    $scope.back = function () {
      $scope.candidates = false;


    };

    $scope.getCandidates = function (eid) {
      $scope.candidates = true;

      // console.log($scope.address.state.state);
      // console.log("welcome to get candidates :", eid);
      MyService.getVoteCandidates({
        e_id: eid
      }).then(function (result) {
        $scope.candidatesData = result;
        MyService.ConsoleLog("results :", $scope.candidatesData);
      });
    };
  }]).controller("votesmartWithoutState", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.election = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    $rootScope.catList = false;
    $rootScope.orgvList = false;
    // $rootScope.CityCouncil = false;
    $scope.Test = false;
    // $rootScope.withoutState = false;
    $rootScope.incumbent = false;

    // console.log($scope.selectState);
    $scope.getWithoutState = function () {
      $scope.loader = true;
      // alert('called');
      // console.log("address.state", $scope.address.state.statePostalCode);
      // var d = new Date();
      // $scope.today = d.toISOString();
      // console.log("dates", $scope.today);
      // $scope.today = '2018-06-05 00:00:00.000Z';
      // console.log("dates", $scope.today);

      MyService.getWithoutState().then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.without = response.data.data;
        MyService.ConsoleLog("$scope.without ", $scope.without);
        $scope.candidates = true;
        $scope.loader = false;
        // console.log("results:", $scope.ElectionData);
      });
    };
    // $scope.getWithoutState();

    $scope.back = function () {
      $scope.candidates = false;
    };
  }]).controller("orgCatCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    $rootScope.courage = false;
    $rootScope.incumbent = false;
    $rootScope.CityCouncil = false;
    $rootScope.withoutState = false;
    $rootScope.election = false;
    $scope.showLoader = false;
    $rootScope.catList = true;
    $rootScope.orgvList = false;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          console.log("payload : ", payload);
          $scope.allState = payload;
          var addNwState = payload;
          var smpl = {
            state: "NA",
            statePostalCode: "NA"
          };
          addNwState.unshift(smpl);
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );

    };

    // console.log($scope.selectState);

    $scope.getAllStates();

    $scope.getReviewCategory = function () {
      var nwState;
      MyService.ConsoleLog(" address.state", $scope.address.state);


      $scope.loader = true;

      MyService.getReviewCategory({
        "s_id": $scope.address.state.statePostalCode
      }).then(function (result) {
        console.log("result :", result);
        growl.success(result);
        $scope.loader = false;
      }, function (err) {
        growl.error(err.error);
      });
    };
  }]).controller("orgListCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {

    $scope.candidates = false;
    $rootScope.local = false;
    $rootScope.cityData = false;
    $rootScope.courage = false;
    $rootScope.incumbent = false;
    $rootScope.CityCouncil = false;
    $rootScope.withoutState = false;
    $rootScope.election = false;
    $scope.showLoader = false;
    $rootScope.catList = false;
    $rootScope.orgvList = true;
    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          console.log("payload : ", payload);
          $scope.allState = payload;
          var addNwState = payload;
          var smpl = {
            state: "NA",
            statePostalCode: "NA"
          };
          addNwState.unshift(smpl);
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );

    };

    /**
     * @description  Add votesmart orgs into the office table
     */
    $scope.getOrgList = function () {
      $scope.loader = true;
      MyService.getOrgList({
        "s_id": $scope.address.state.statePostalCode
      }).then(function (result) {
        growl.success(result);
        console.log("result :", result);
        $scope.loader = false;
      }, function (err) {
        MyService.ConsoleLog("err", err);
        growl.error(err.error);
        $scope.loader = false;
      });
    };

    // console.log($scope.selectState);

    $scope.getAllStates();
  }]);
