'use strict';
angular
  .module('myApp.signin', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/signin', {
        templateUrl: 'app/signin/signin.html',
        controller: 'signinCtrl',
      });
    },
  ])

  .controller('signinCtrl', [
    '$scope',
    '$http',
    '$location',
    'growl',
    'tokenService',
    'baseApiUrl',
    '$rootScope',
    'MyService',
    function ($scope, $http, $location, growl, tokenService, baseApiUrl, $rootScope, MyService) {
      $scope.UserName = '';
      $scope.Password = '';
      $rootScope.welcome = false;
      $scope.showPasswordReset = false;
      $scope.showDrop = false;
      $scope.User = {
        uName: ""
      };

      $scope.AuthenticateUser = function () {
        console.log('AuthenticateUser');

        if ($.fn.validateForceFully($('#signInform')) == true) {
          /*$http.post(baseApiUrl + '/user/authenticate', {
              username: $scope.UserName,
              password: $scope.Password
          })
          .then(function (response) {
              if (response.data.success == true) {
                  //MyService.ConsoleLog("Login Data");
                  //MyService.ConsoleLog(response.data);
                  tokenService.saveToken(response.data.token);
                  tokenService.saveUserName(response.data.name);
                  tokenService.saveUserId(response.data.id);
                  tokenService.saveUserRole(response.data);
                   var userData = {
                      id: response.data.id,
                      lastLogin: response.data.lastLogin,
                      uname: $scope.UserName,
                      name: response.name
                   };
                  tokenService.saveUserData(JSON.stringify(userData));


                  $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
                  $rootScope.Auth = true;
                  $rootScope.User = tokenService.getUserName();
                  $rootScope.UserId = tokenService.getUserId();
                  $location.path('debateHome');
                  $rootScope.getCategories();
              }
              else {
                  growl.error('Invalid Username or Password. Please try again.');
              }
          }, function (response) {
              var validataionMsg = "";
              var errors = response.data.error.errors;
              for (var k in errors) {
                  if (errors.hasOwnProperty(k)) {
                      //MyService.ConsoleLog(k)
                      var obj = errors[k];
                      if (obj.message !== undefined)
                          validataionMsg += obj.message.replace('Path', '') + "<br/>";
                  }
              }
              growl.error(validataionMsg);
          });*/

          var postobj = {
            username: $scope.UserName,
            password: $scope.Password,
          };
          $rootScope.AuthenticateUser(postobj, 'signin');
          // alert('login');
        }
      };

      /**
       * This function called when input box loses focus.
       * function search users with same email address
       */
      $scope.detectEmail = function () {
        MyService.ConsoleLog("forgotEmail", $scope.forgotEmail);
        var postobj = {};
        postobj.email = $scope.forgotEmail;
        MyService.checkEmailForMultiUsers(postobj).then(function (result) {
          if (result) {
            MyService.ConsoleLog("Result", result);
            if (result.data.data.length > 1) {
              MyService.ConsoleLog("IFFFFFFFFFFFFFFFF");
              $scope.showDrop = true;
              $scope.dropData = result.data.data;
              MyService.ConsoleLog("$scope.uName", $scope.uName);
              if ($scope.uName && $scope.uName != "") {

                alert($scope.uName);
              }
            }
          }

        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      $scope.requestPasswordReset = function () {
        if ($.fn.validateForceFully($('#forgotPassForm')) == true) {
          var postobj = {
            email: $scope.forgotEmail,
            reset_url: $rootScope.getPasswordResetUrl(),
            checkName: $scope.User.uName ? $scope.User.uName.name : ""
          };
          MyService.ConsoleLog("$scope.uName", $scope.User.uName.name);

          MyService.forgotPassword(postobj).then(
            function (response) {
              MyService.ConsoleLog(response);
              if (response.data.success) {
                growl.success('An email has been sent.');
              } else {
                if (typeof response.data.error == 'string') growl.error(response.data.error);
                else growl.error('Something went wrong');
              }
            },
            function (err) {
              growl.error('Something went wrong');
            }
          );
        }
      };

      $(document).ready(function () {
        console.log('onready');
        $('#signInform').validationEngine();
        $('#forgotPassForm').validationEngine();
      });
    },
  ]);
