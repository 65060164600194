'use strict';

angular.module('myApp.showComments', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showComments/:data?', {
      templateUrl: 'app/showComments/showComments.html',
      controller: 'showCommentsCtrl'
    });
  }]).controller('showCommentsCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {
    $scope.showComment = false;
    $scope.unCommentNew = false;
    $scope.showCurUser = false;
    $scope.showHis = true;
    MyService.ConsoleLog("$root politician", $rootScope.comOfPolitician);
    MyService.ConsoleLog("$root voter", $rootScope.comOfVoter);
    if (window.localStorage.getItem('comOfPolitician')) {
      var pdata = window.localStorage.getItem('comOfPolitician');
      var opoli = JSON.parse(pdata);
      $scope.global = opoli.poliArr;
      $scope.queId = opoli.queId;
      $scope.showComment = true;

    }

    /**
     * For Voter
     */
    if (window.localStorage.getItem('comOfVoter')) {
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var data = window.localStorage.getItem('comOfVoter');
      var none = JSON.parse(data);
      $scope.global = none.compArr;
      $scope.queId = none.queId;
      $scope.showComment = true;
      MyService.ConsoleLog(" none", none);

    }

    /**For Advocates */

    if (window.localStorage.getItem('comOfAdv')) {
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var adata = window.localStorage.getItem('comOfAdv');
      var aone = JSON.parse(adata);
      $scope.global = aone.advArr;
      $scope.queId = aone.queId;
      $scope.showComment = true;
      $scope.orgName = $cookies.get('orgName');

    }

    /**
     * For Guest Users
     */
    if (window.localStorage.getItem('comOfGuest')) {
      // alert('called');
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var gdata = window.localStorage.getItem('comOfGuest');
      var gone = JSON.parse(gdata);
      $scope.unVeriCommNew = gone.guestArr;
      $scope.queId = gone.queId;
      $scope.unCommentNew = true;

    }

    if (window.localStorage.getItem('cuUser')) {
      // alert('called');
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var gdata = window.localStorage.getItem('cuUser');
      var gone = JSON.parse(gdata);
      $scope.currentUser = gone.curuser;
      MyService.ConsoleLog(" $scope.currentUser", $scope.currentUser);
      $scope.showCurUser = true;

      $scope.unCommentNew = false;
      $scope.showComment = false;

    }

    /**
     * This function filter comment data
     */
    $scope.getItems = function () {
      return function (u) {
        // MyService.ConsoleLog("params >", u);

        if (u.comment && u.comment != "") {
          return u;
        }
      };
    };

    $scope.getComment = function () {
      return function (x) {
        if (x.comment && x.comment != "") {
          return x;
        }
      };
    };

    $scope.getComapreClass = function (match, i) {
      //MyService.ConsoleLog("match: ", match);
      /*if(match == 100)
        return 'ag-green';
    if(match == 0)
        return 'ag-red';
    if(match > 0 && match <100)
        return 'ag-dg'+i;*/
      if (match >= 100) return 'ag-dg1';
      if (match >= 80) return 'ag-dg2';
      if (match >= 60) return 'ag-dg3';
      if (match >= 40) return 'ag-dg4';
      if (match >= 20) return 'ag-dg5';
      if (match >= 0) return 'ag-dg6';

      return 'ag-dg';
    };
    $scope.getComapreClassHis = function (match, i) {

      if (match >= 1) return 'ag-dg1';
      if (match >= 2) return 'ag-dg2';
      if (match >= 3) return 'ag-dg3';
      if (match >= 4) return 'ag-dg4';
      if (match >= 5) return 'ag-dg5';
      if (match >= 6) return 'ag-dg6';

      return 'ag-dg';
    };


    $scope.back = function () {
      // MyService.ConsoleLog("Called");
      $location.path('/findMatch');
    };


  }]);
