mainApp
  .directive('fileModel', [
    '$parse',
    function ($parse) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;
          var maxSize = 1000000;

          element.bind('change', function () {
            scope.$apply(function () {
              if (attrs.multiple) {
                modelSetter(scope, element[0].files);
              } else {
                modelSetter(scope, element[0].files[0]);
              }

              var fileSize = element[0].files[0].size;
              if (fileSize > maxSize) {
                scope.upload.PoliticianButton = true;
                scope.upload.maxSizeError = true;
                alert("Maximum file size allowed is 1MB.");
              }
            });
          });
        }
      };
    }
  ])
  // directive('formscroll', function formScroll($window) {
  //      return {
  //        link: function(scope, elem) {
  //               elem.bind('focus', function() {
  //                 console.log("after focus");
  //                //  var offset,
  //                // elOffset = elem.prop('offsetTop'), //.offset().top,
  //                // elHeight = elem.prop('height'), //elem.height(),
  //                // windowHeight = $window.innerHeight;
  //                // offset = elOffset - ((windowHeight - elHeight) / 2);
  //                //$window.scrollTo(0, offset);
  //                $(window).resize();
  //              });
  //        }
  //     }
  //  })

  .directive('viDropdown', [
    '$parse',
    function ($parse) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {

          element.bind('click', function () {
            $(element).toggleClass("open");
          });
        }
      };
    }
  ])
  .directive("fixedFirstColumn", [function () {
    return {
      restrict: "A",
      template: "<div class='table-responsive'><div ng-transclude></div></div>",
      transclude: true,
      link: function ($scope, $element) {
        var interval = setInterval(function () {
          var tr = $element.find("tr");

          angular.forEach(tr, function (i) {
            var columns = angular.element(i).children();

            if (columns.length < 1) {
              // Row with no columns? Ignore it.
              return;
            }

            var column0 = angular.element(columns[0]).children()[0] || columns[0];
            var column1 = columns[2];

            // Calculate heights of each <td>.
            var height0 = (column0).offsetHeight;
            var height1 = column1 ? column1.offsetHeight : 0;

            // Calculate final height.
            var height = Math.max(height0, height1);

            // Set heights of <td> and <tr>.
            columns[0].style.height = height + "px";
            i.style.height = height + "px";

            if (column1) {
              column1.style.height = height + "px";
            }

            // If <td> heights have stabilized.
            if (height0 !== 0 && height0 === height1) {
              clearInterval(interval);
            }
          });
        }, 1000);
      }
    };
  }])
  .directive('autoComplete', function ($timeout) {
    return function (scope, iElement, iAttrs) {
      iElement.autocomplete({
        source: scope[iAttrs.uiItems],
        select: function () {
          $timeout(function () {
            iElement.trigger('input');
          }, 0);
        }
      });
    };
  })
  .directive('validFile', function ($parse) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, el, attrs, ngModel) {
        var model = $parse(attrs.ngModel);
        var modelSetter = model.assign;
        var maxSize = 2000; //2000 B
        el.bind('change', function () {

          scope.$apply(function () {
            scope.ebook.maxSizeError = false;
            if (el[0].files.length > 1) {
              modelSetter(scope, el[0].files);
            } else {
              modelSetter(scope, el[0].files[0]);
            }
            var fileSize = el[0].files[0].size;
            if (fileSize > maxSize) {
              scope.ebook.maxSizeError = true;
            }
          });
        });
      }
    }
  })
  .directive('checkFileSize', function () {
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent(element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false);
          } else {
            element.attachEvent('on' + type, handler);
          }
        }

        bindEvent(elem[0], 'change', function () {
          var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".svg", ".xlsx"];
          var sFileName = this.value;
          //console.log(this.value);
          if (sFileName.length > 0) {
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
              var sCurExtension = _validFileExtensions[j];
              if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                blnValid = true;
                break;
              }
            }

            if (!blnValid) {
              alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
              this.value = "";
              return false;
            }
          }
          if (this.files[0].size > 1000000) {
            alert("Maximum file size allowed is 1MB. You are uploading file of size " + this.files[0].size);
            this.value = '';
          }

        });
      }
    }
  })
  .filter('addHttp', [function () {
    return function (url) {
      //check if is string is blank return true if blank
      if (url.indexOf("http://") != 0 && url.indexOf("https://") != 0)
        url = "http://" + url;

      return url;
    }
  }])
  .filter('isBlankString', [function () {
    return function (str) {
      //check if is string is blank return true if blank
      if (!str) {
        return true;
      } else if (str == "null" || str == "undefined") {
        return true;
      }
      var regex = /^\s*$/;
      if (regex.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }])
  .filter('getItemIndex', function () {
    //order the groupped dat with by it's key
    return function (data, id) {
      var index = -1;
      var len = data.length;
      for (var i = 0; i < len; i++) {
        if (data[i]._id == id) {
          // item = data[i];
          index = i;
          break;
        }
      }
      return index;
    };
  })
  .filter('newlines', ['$sce', function ($sce) {
    return function (text) {
      // return text.split(/\n/g);
      if (text)
        return $sce.trustAsHtml(text.replace(/(?:\r\n|\r|\n)/g, '<br />'));
      else
        return $sce.trustAsHtml(text);
    };
  }])
  .filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
      return $sce.trustAsHtml(text);
    };
  }])
  .filter('vdourl', ['$sce', function ($sce) { // My custom filter
    return function (vdurl) {
      //console.log("vdurl : ", vdurl);
      var url = vdurl;
      if (url.indexOf("youtube.com") >= 0) {
        var url = url.replace("watch?v=", "v/");
        return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
      }
      if (url.indexOf("facebook.com") >= 0) {
        return $sce.trustAsHtml('<br/><iframe src="' + url + '" width="560" height="600" frameborder="0"></iframe><br/>');
      }
      if (url.indexOf("vimeo.com") >= 0) {
        if (url.indexOf("https://vimeo.com/") >= 0) {
          var id = url.replace("https://vimeo.com/", "");
          var newurl = "https://player.vimeo.com/video/" + id;
        } else {
          var newurl = url;
        }

        return $sce.trustAsHtml('<br/><iframe src="' + newurl + '" width="500" height="281" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>');
      }
      return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
    }
  }])
  .filter('convertHTML', ['$sce', function ($sce) { // My custom filter
    return function (html) {
      var newHTML = $sce.trustAsHtml(html);
      var newDiv = document.createElement("div");
      newDiv.innerHTML = newHTML;
      //console.log("Origininal : ", newDiv);
      var tags = newDiv.getElementsByTagName("p");
      //console.log(tags[0]);
      if (tags[0]) {
        //console.log("Firs P Tag : ", $(tags[0]).html());
        return $sce.trustAsHtml($(tags[0]).html());
      } else {
        return $sce.trustAsHtml(html.substring(0, 100) + "...");
      }
    }
  }])
  .filter('urlify', function ($sce, $q, $http, $filter, baseApiUrl, MyService) { // My custom filter
    var deferred = $q.defer();
    var promise = deferred.promise;
    var ncahche = {};

    var tempWDefault = 560;
    var tempHDefault = 315;
    var tempW = 560;
    var tempH = 315;

    function getHttpData(xurl, fdata) {
      if (ncahche[xurl]) {
        return ncahche[xurl];
      }
      ncahche[xurl] = "fetching...";
      //console.log(xurl);

      // $http.jsonp(url)
      //     .success(function(response) {
      //         //console.log(response);
      //         ncahche[xurl] = $sce.trustAsHtml('<br/><a href="' + response.url + '" target="_blank"><h3>' + response.title + '</h3><div style="width:98%%; clear:both;"><img src="' + response.image + '" style="width:'+tempWDefault+'px; height:auto; max-width: 98%;" /></div><div style="width:98%; clear:both;">' + response.description + '</div></a><br/>');
      //     });
      MyService.getLinkPreview({
        "weburl": xurl
      }).then(function (response) {
        //console.log(response);
        ncahche[xurl] = $sce.trustAsHtml('<br/><a href="' + xurl + '" target="_blank"><h3>' + response.title + '</h3><div style="width:98%%; clear:both;"><img src="' + response.image + '" style="width:' + tempWDefault + 'px; height:auto; max-width: 98%;" /></div><div style="width:98%; clear:both;">' + response.description + '</div></a><br/>');

      }, function (error) {
        //console.log(error);
        ncahche[xurl] = error;

      });

    }

    function urlifyFilter(text) {
      //console.log("------------------------------");
      //console.log("text:", text);
      //console.log("width: ", tempW);
      //console.log("height: ", tempH);
      //console.log("------------------------------");

      text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
      var regex = new RegExp('<iframe.*src=(.+?) .*<\/iframe>', "g");
      var newurl = text.match(regex);
      var finaltext = "";
      if ((newurl) && (newurl.length > 0)) {
        try {
          var chk = $(text);
          tempW = chk.attr("width") || 560;
          tempH = chk.attr("height") || 315;
        } catch (e) {
          //console.log("Exception: ", e);
        }
        newurl.forEach(function (val, idx) {
          finaltext = val.match(/<iframe.*src=(.+?) .*<\/iframe>/);
          var finalurl = finaltext[1].replace(/"/g, '');
          text = text.replace(val, finalurl);
        });
      }

      var urlRegex = /(http(s?):\/\/[^\s]+)/g;
      var data = text.replace(urlRegex, function (url) {
        var rdata = url;
        if (url.indexOf("youtube.com") >= 0) {
          var url = url.replace("watch?v=", "embed/");
          //var url = url.replace("watch?v=", "embed/");
          var nurl = url.split("&");
          var durl = nurl[0];
          //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
          return '<br/><iframe sandbox="allow-forms allow-same-origin allow-scripts" width="' + tempWDefault + '" height="' + tempHDefault + '" src="' + durl + '" style="max-width:98%;" frameborder="0" allowfullscreen></iframe><br/>';
        }
        if (url.indexOf("facebook.com") >= 0) {
          return '<br/><iframe sandbox="allow-forms allow-same-origin allow-scripts" src="' + url + '" width="' + tempW + '" height="' + tempH + '" frameborder="0" style="max-width:98%;"></iframe><br/>';
        }
        if (url.indexOf("vimeo.com") >= 0) {
          if (url.indexOf("https://vimeo.com/") >= 0) {
            var id = url.replace("https://vimeo.com/", "");
            var newurl = "https://player.vimeo.com/video/" + id;
          } else {
            //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
            var newurl = url;
          }
          return '<br/><iframe sandbox="allow-forms allow-same-origin allow-scripts" src="' + newurl + '" width="' + tempWDefault + '" height="' + tempHDefault + '" style="max-width:98%;" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>';
        }
        //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
        //url = url.replace("https://", "http://");
        //var nurl = 'http://localhost:8080/ topics/getHttpUrl?url=' + url;
        //console.log("baseApiUrl::::",baseApiUrl);
        if (url.indexOf("http://") > -1) {
          var nurl = baseApiUrl + '/topics/getHttpUrl?url=' + url;
        } else {
          var nurl = url;
        }
        var fdata = $sce.trustAsHtml('<br/><iframe sandbox="allow-forms allow-same-origin allow-scripts" width="' + tempWDefault + '" height="' + tempHDefault + '" src="' + nurl + '" style="max-width:98%;" frameborder="0" allowfullscreen></iframe><br/>');
        //var para = document.createElement("DIV");
        //return getHttpData(xurl, fdata);
        return getHttpData(url, fdata);
      });
      //console.log(data);
      return $sce.trustAsHtml(data);
    }

    urlifyFilter.$stateful = true;

    return urlifyFilter;
  })
  .filter('urlifyshort', function ($sce, $q, $http, $filter, baseApiUrl, MyService) { // My custom filter
    var deferred = $q.defer();
    var promise = deferred.promise;
    var ncahche = {};

    var tempWDefault = 560;
    var tempHDefault = 315;
    var tempW = 560;
    var tempH = 315;

    function getHttpData(xurl, fdata) {
      if (ncahche[xurl]) {
        return ncahche[xurl];
      }
      ncahche[xurl] = "fetching...";
      //console.log(xurl);

      // $http.jsonp(url)
      //     .success(function(response) {
      //         //console.log(response);
      //         ncahche[xurl] = $sce.trustAsHtml('<br/><a href="' + response.url + '" target="_blank"><h3>' + response.title + '</h3><div style="width:98%%; clear:both;"><img src="' + response.image + '" style="width:'+tempWDefault+'px; height:auto; max-width: 98%;" /></div><div style="width:98%; clear:both;">' + response.description + '</div></a><br/>');
      //     });
      MyService.getLinkPreview({
        "weburl": xurl
      }).then(function (response) {
        //console.log(response);
        ncahche[xurl] = $sce.trustAsHtml('<img src="' + response.image + '" style="width:' + tempWDefault + 'px; height:auto; max-width: 30%; float:left;" />');

      }, function (error) {
        //console.log(error);
        ncahche[xurl] = error;

      });

    }

    function urlifyshortFilter(text) {
      //console.log("------------------------------");
      //console.log("text:", text);
      //console.log("width: ", tempW);
      //console.log("height: ", tempH);
      //console.log("------------------------------");

      text = text.replace(/(?:\r\n|\r|\n)/g, '');
      var regex = new RegExp('<iframe.*src=(.+?) .*<\/iframe>', "g");
      var newurl = text.match(regex);
      var finaltext = "";
      if ((newurl) && (newurl.length > 0)) {
        try {
          var chk = $(text);
          tempW = chk.attr("width") || 560;
          tempH = chk.attr("height") || 315;
        } catch (e) {
          //console.log("Exception: ", e);
        }
        newurl.forEach(function (val, idx) {
          finaltext = val.match(/<iframe.*src=(.+?) .*<\/iframe>/);
          var finalurl = finaltext[1].replace(/"/g, '');
          text = text.replace(val, finalurl);
        });
      }

      var urlRegex = /(http(s?):\/\/[^\s]+)/g;
      var data = text.replace(urlRegex, function (url) {
        var rdata = url;
        if (url.indexOf("youtube.com") >= 0) {
          var url = url.replace("watch?v=", "embed/");
          //var url = url.replace("watch?v=", "embed/");
          var nurl = url.split("&");
          var durl = nurl[0];
          //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
          return '<br/><iframe sandbox="allow-scripts allow-same-origin allow-presentation" width="' + tempWDefault + '" height="' + tempHDefault + '" src="' + durl + '" style="max-width:98%;" frameborder="0" allowfullscreen></iframe><br/>';
        }
        if (url.indexOf("facebook.com") >= 0) {
          return '<br/><iframe sandbox="allow-scripts allow-same-origin allow-presentation" src="' + url + '" width="' + tempW + '" height="' + tempH + '" frameborder="0" style="max-width:98%;"></iframe><br/>';
        }
        if (url.indexOf("vimeo.com") >= 0) {
          if (url.indexOf("https://vimeo.com/") >= 0) {
            var id = url.replace("https://vimeo.com/", "");
            var newurl = "https://player.vimeo.com/video/" + id;
          } else {
            //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
            var newurl = url;
          }
          return '<br/><iframe sandbox="allow-scripts allow-same-origin allow-presentation" src="' + newurl + '" width="' + tempWDefault + '" height="' + tempHDefault + '" style="max-width:98%;" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>';
        }
        //url = 'https://www.google.com/search?q=%'+url+"&output=embed";
        //url = url.replace("https://", "http://");
        //var nurl = 'http://localhost:8080/ topics/getHttpUrl?url=' + url;
        //console.log("baseApiUrl::::",baseApiUrl);
        if (url.indexOf("http://") > -1) {
          var nurl = baseApiUrl + '/topics/getHttpUrl?url=' + url;
        } else {
          var nurl = url;
        }
        var fdata = $sce.trustAsHtml('<iframe sandbox="allow-scripts allow-same-origin allow-presentation" width="' + tempWDefault + '" height="' + tempHDefault + '" src="' + nurl + '" style="max-width:98%;" frameborder="0" allowfullscreen></iframe>');
        //var para = document.createElement("DIV");
        //return getHttpData(xurl, fdata);
        return getHttpData(url, fdata);
      });
      /*var checkimg = data.match(/<img/);
      var checkiframe = data.match(/<iframe/);
      var imageTag = document.querySelector("img").outerHTML;
      //console.log(imageTag);
      var iframeTag = document.querySelector("iframe").outerHTML;
      var hdata = data.replace(iframeTag, '');
      hdata = data.replace(imageTag, '');
      if(checkimg) {
          var fdata = hdata.substring(0,100)+"...";
          return $sce.trustAsHtml(imageTag+'<div style="float:left;">'+fdata+'</div>');
      } else if(checkiframe) {
           var fdata = hdata.substring(0,100)+"...";
          return $sce.trustAsHtml(fdata+iframeTag);
      } else {

      }*/
      var checkimg = data.match(/<img/);
      var checkiframe = data.match(/<iframe/);
      if (checkimg) {
        var elem = data.match(/<img[^>]+>/);
        var hdata = data.replace(elem[0], '');
        if (hdata != "") {
          var fdata = hdata.substring(0, 100) + "...";
        } else {
          var fdata = '';
        }
        return $sce.trustAsHtml(elem[0] + fdata);
      } else if (checkiframe) {
        var elem = data.match(/<iframe[^>]+>/);
        if (elem) {
          var newelem = elem[0] + '</iframe>';
          var hdata = data.replace(newelem, '');
          hdata = hdata.replace('<br/><br/>', '');
          if (hdata != "") {
            var fdata = hdata.substring(0, 100) + "...";
          } else {
            var fdata = '';
          }
          return $sce.trustAsHtml(fdata + '<br/>' + newelem);
        }

      } else {
        return $sce.trustAsHtml(data.substring(0, 100) + "...");
      }
    }

    urlifyshortFilter.$stateful = true;
    return urlifyshortFilter;
  })
  .filter('escape', function () {
    return function (input) {
      if (input) {
        return window.encodeURIComponent(input);
      }
      return "";
    }
  })
  .filter('fuzzy', function () {
    return function (collection, search, csensitive) {
      var sensitive = csensitive || false;

      collection = collection;

      if (!angular.isArray(collection) || angular.isUndefined(search)) {
        return collection;
      }

      search = (sensitive) ? search : search.toLowerCase();

      return collection.filter(function (elm) {
        if (angular.isString(elm)) {
          elm = (sensitive) ? elm : elm.toLowerCase();
          return hasApproxPattern(elm, search) !== false
        }
        return (angular.isObject(elm)) ? _hasApproximateKey(elm, search) : false;
      });

      /**
       * checks if object has key{string} that match
       * to fuzzy search pattern
       * @param object
       * @param search
       * @returns {boolean}
       * @private
       */
      function hasApproxPattern(word, pattern) {
        // cheaper version of indexOf; instead of creating each
        // iteration new str.
        function indexOf(word, p, c) {
          var j = 0;
          while ((p + j) <= word.length) {
            if (word.charAt(p + j) == c) return j;
            j++;
          }
          return -1;
        }
        var p = 0;
        for (var i = 0; i <= pattern.length; i++) {
          var index = indexOf(word, p, pattern.charAt(i));
          if (index == -1) return false;
          p += index + 1;
        }
        return true
      }

      function _hasApproximateKey(object, search) {
        var properties = Object.keys(object),
          prop, flag;
        return 0 < properties.filter(function (elm) {
          prop = object[elm];

          //avoid iteration if we found some key that equal[performance]
          if (flag) return true;

          if (angular.isString(prop)) {
            prop = (sensitive) ? prop : prop.toLowerCase();
            return flag = (hasApproxPattern(prop, search) !== false);
          }

          return false;

        }).length;
      }
    }
  })
  .filter('nospace', function () {
    return function (value) {
      return (!value) ? '' : value.replace(/ /g, '');
    };
  })
  .filter('decode_newline', ['$sce', function ($sce) { // My custom filter
    return function (text) {
      ;
      text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return $sce.trustAsHtml(text);
    }
  }]);
