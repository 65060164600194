'use strict';
angular
  .module('myApp.compositeQuiz', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/compositeQuiz/:orgId?/:quizName?', {
        templateUrl: 'app/compositeQuiz/compositeQuiz.html',
        controller: 'compositeQuizCtrl',
      });
    },
  ])
  .controller('compositeQuizCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {
      $scope.view = [];
      $scope.viewTwo = [];
      $scope.selectedCat = [];
      $scope.subtopics = {
        subcategories: [],
      };
      $scope.disabled = [];
      $scope.allLocations = [];
      $scope.allCounties = [];
      $scope.selectedArea = [];
      $scope.rmselectedArea = [];
      $scope.myselectionarea = [];
      $scope.disabledLocation = [];
      $scope.mainCatTree = [];
      $scope.AllStates = [];
      $scope.allCounties = [];
      $scope.allLocals = [];
      $scope.showLoading = [];
      $scope.showLoadingCounty = [];
      $scope.showDown = [];
      $scope.showLoading = [];
      $scope.showLoadingCounty = [];
      $scope.showDown = [];
      $scope.Interests = [];
      $scope.compositeQuiz = [];
      $scope.findLocations = [];
      $scope.Interests = [];
      $scope.MyLocation = [];
      $scope.Location = ["International", "National", "My State", "My County", "My Local", "Other Specific"];


      $scope.selectConfig = {
        requiredMin: 1,
        selectAll: true,
      };

      $scope.queUp = false;
      $scope.queDown = false;
      $scope.unAnsUp = false;
      $scope.unAnsDown = false;
      $scope.isInvited = false;
      $scope.checkCount = false;
      $scope.descrised = false;
      $scope.isShowFilter = false;
      $scope.ShowSubcatTree = false;
      $scope.showRefineLocation = false;

      $scope.showSubCat = true;

      $scope.currPage = 1;
      $scope.selectedQuestions = 0;
      $scope.statusGetResPg = 0;
      $scope.loadernew = false;

      /**
       * Get orgId using route params
       */

      $scope.orgId = $routeParams.orgId;
      $scope.quizName = $routeParams.quizName;
      $scope.userId = tokenService.getUserId();

      MyService.ConsoleLog('$scope.orgId', $scope.orgId);
      // $scope.selectedQuestions = '';

      if ($scope.quizName && $scope.quizName != '') {
        $scope.isInvited = true;
      }

      window.localStorage.removeItem('queIds');
      $cookies.remove('advId');
      $cookies.remove('showBanner');
      $cookies.remove('quizName');


      /**
       * Show all the quizzes created by orgs
       */
      $scope.getShowAll = function () {

        MyService.showAllQuizzes().then(function (response) {
          MyService.ConsoleLog("response", response);
          MyService.getCatForQuiz({
            quizData: JSON.stringify(response.data.data.quizData)
          }).then(function (APIResponse) {
            MyService.ConsoleLog("API", APIResponse);
            $scope.compositeQuiz = $scope.compositeQuiz.concat(APIResponse.data.data);
            $scope.totleRecords = response.data.data.totalRecords;

            if ($scope.orgData && $scope.orgData.length > 0) {
              var lens = $scope.orgData.length;
              $scope.compositeQuiz.filter(function (itms) {
                for (var i = 0; i < lens; i++) {
                  // MyService.ConsoleLog("vvvv", itms.orgId + " == " + $scope.orgData[i].orgId + "    " + itms.quizName + "     " + $scope.orgData[i].quizName);
                  if (itms.orgId == $scope.orgData[i].orgId && itms.quizName == $scope.orgData[i].quizName) {
                    itms.isInvited = true;
                  }
                }
                return itms;
              });
            }
            $scope.loadernew = false;
            // $scope.statusGetResPg = 2;
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };


      $scope.checkIfInvited = function () {
        MyService.checkIfInvited().then(function (APIResponse) {
          MyService.ConsoleLog("APIResponse", APIResponse);
          if (APIResponse.data.success) {
            $scope.orgData = APIResponse.data.data;
            MyService.ConsoleLog("$scope.orgData", $scope.orgData);
            $scope.getShowAll();
          } else {
            var message = 'No organizations have yet invited you to take a quiz.Would you like to see their quizzes anyway ?';
            var modal = ngDialog.openConfirm({
              template: '<p>' +
                message +
                '</p>\
              <div class="ngdialog-buttons">\
                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">No</button>\
              </div>',
              plain: true,
              closeByDocument: false,
            });
            modal.then(
              function fullfilled(data) {
                // MyService.ConsoleLog("fullfilled: ", data);
                $scope.getShowAll();
              },
              function rejected(data) {

              }
            );
          }
        }, function (err) {
          MyService.ConsoleLog("err", err);
        });
      };

      /**
       * Get Quiz details for composite quiz
       */
      $scope.filterCanceler = $q.defer();
      $scope.getAllCanceller = $q.defer();

      $scope.getQuizDetails = function (pageNumber) {
        // $scope.compositeQuiz = [];
        $scope.loadernew = true;
        MyService.ConsoleLog("$scope.Interest", $scope.Interests);
        // alert('call');
        var obj = {};
        obj.orgId = $scope.orgId;
        obj.quizName = $scope.quizName;
        obj.page = pageNumber;
        obj.max_records = 30;
        $scope.statusGetResPg = 1;
        if ($scope.Interests && $scope.Interests.length > 0 || $scope.findLocations && $scope.findLocations.length > 0 || $scope.MyLocation && $scope.MyLocation.length > 0) {
          obj.interest = JSON.stringify($scope.Interests);
          obj.areaOfInterest = JSON.stringify($scope.findLocations);
          obj.MyLocation = JSON.stringify($scope.MyLocation);
          // MyService.ConsoleLog("obj", obj);
          MyService.getFilterQuizzes(obj, $scope.filterCanceler).then(function (response) {
            MyService.ConsoleLog("response", response);
            if (response && response.data.quizData && response.data.quizData.length > 0) {
              $scope.compositeQuiz = $scope.compositeQuiz.concat(response.data.quizData);
              $scope.totleRecords = response.data.totalRecords;
              $scope.statusGetResPg = 2;
            } else {
              growl.error("Quizzes not found for this topic");
            }
          }, function (err) {
            MyService.ConsoleLog("Error", err);
            $scope.statusGetResPg = 3;
            // $scope.statusGetResPg = 1;
            $scope.currPage--;
            growl.error("Quizzes not found for this search", {
              ttl: 2000
            });
            // $scope.getQuizDetails($scope.currPage);
          });

        } else {
          MyService.ConsoleLog("<<<<<<<<<<<<<<<< inside >>>>>>>>>>>>>>>>>");
          MyService.getQuizDetailsFor(obj, $scope.getAllCanceller).then(
            function (result) {
              MyService.ConsoleLog('result', result);
              // MyService.ConsoleLog("<<<<<<<<<<<< ONe more Inside");
              if (result && result.data.quizData && result.data.quizData.length > 0) {

                $scope.compositeQuiz = $scope.compositeQuiz.concat(result.data.quizData);
                $scope.totleRecords = result.data.totalRecords;
                $scope.statusGetResPg = 2;
              } else {
                // growl.error("Quizzes not found");
                $scope.checkIfInvited();

              }
            },
            function (err) {
              MyService.ConsoleLog('Error', err);
              growl.error(err);
              $scope.statusGetResPg = 3;
              $scope.currPage--;
            }
          );
        }
      };

      $scope.pageFunction = function () {
        // MyService.ConsoleLog("$scope.statusGetResPg >>>>>>", $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.totleRecords > $scope.compositeQuiz.length) {
          $scope.currPage++;
          $scope.changedPage($scope.currPage);
        }
      };

      $scope.changedPage = function (newPage) {
        if ($scope.statusGetResPg != 3) {
          $scope.getQuizDetails(newPage);

        }
      };



      $scope.toggleList = function () {
        $scope.isInvited = !$scope.isInvited;
        if ($scope.isInvited == true) {
          var tmp = $scope.compositeQuiz.filter(function (itms) {

            // MyService.ConsoleLog(typeof itms.isInvited);
            if (itms.isInvited == true) {
              return itms;
            }
            // MyService.ConsoleLog("itms", itms);

          });
          $scope.compositeQuiz = [];
          $scope.compositeQuiz = tmp;
          MyService.ConsoleLog("tmp", tmp.length)
        } else {
          $scope.compositeQuiz = [];
          $scope.getQuizDetails(1);
        }

      };

      /**
       * Filter Quiz
       */

      // $scope.getItems = function () {
      //   return function (u) {
      //     if ($scope.isInvited == true) {
      //       // alert("inside");
      //       if (u.quizName == $scope.quizName) {
      //         u.isInvited = true;
      //         return u;
      //       }
      //     } else {
      //       // u.isInvited = false;
      //       MyService.ConsoleLog("one")
      //       return u;
      //     }
      //   };
      // };

      /**
       * Select quiz
       */
      $scope.uArr = [];
      $scope.unAnsArr = [];
      $scope.select = function (oid, qName, val) {
        var obj = {};

        if ($scope.uArr.length > 0) {
          obj.orgId = oid;
          obj.quizName = qName;
          var id = $scope.uArr.findIndex(function (x) {
            MyService.ConsoleLog('x', x);
            return x.orgId == oid && x.quizName == qName;
          });
          MyService.ConsoleLog('id', id);
          if (id == -1) {
            $scope.uArr.push(obj);
            $scope.checkCount = true;
            $scope.descrised = false;
            $scope.showQuestions(obj);

            // $scope.selectedQuestions += $scope.quesCount;
          } else {
            $scope.descrised = true;
            $scope.checkCount = true;
            $scope.showQuestions(obj);
            $scope.uArr.splice(id, 1);
          }
        } else {
          obj.orgId = oid;
          obj.quizName = qName;
          $scope.checkCount = true;
          $scope.descrised = false;
          $scope.uArr.push(obj);
          $scope.showQuestions(obj);
        }

        if ($scope.unAnsArr.length > 0) {
          val.unAnsweredQuesData.filter(function (itms) {
            var ids = $scope.unAnsArr.indexOf(itms);
            MyService.ConsoleLog('val.unAnsweredQuesData', ids);
            if (ids == -1) {
              $scope.unAnsArr = $scope.unAnsArr.concat(val.unAnsweredQuesData);
            } else {
              $scope.unAnsArr.splice(ids, 1);
            }
          });
        } else {
          $scope.unAnsArr = $scope.unAnsArr.concat(val.unAnsweredQuesData);
        }
        var unAnsQIds = $scope.unAnsArr.map(function (itms) {
          if (itms && itms.queId) {
            return itms.queId;
          }
        });
        MyService.ConsoleLog("unAnsQIds", unAnsQIds.length);
        $scope.unAnsCount = unAnsQIds.filter(function (itm, ind) {
          return ind == unAnsQIds.findIndex(function (ele) {
            return JSON.stringify(ele) == JSON.stringify(itm);
          });
        });

      };

      /**
       * Select and deselect All quizzes
       */
      $scope.isSelected = false;
      var tempObj = {};
      $scope.SelectChecks = function () {
        //alert($scope.isSelected);
        $scope.unAnsArr = [];
        if ($scope.isSelected == false) {
          $scope.isSelected = true;
          // angular.forEach($scope.compositeQuiz, function(val, key) {
          $scope.uArr = $scope.compositeQuiz.filter(function (val) {
            // MyService.ConsoleLog("val", val);
            $scope.unAnsArr = $scope.unAnsArr.concat(val.unAnsweredQuesData);
            val.isSelected = true;
            tempObj.orgId = val.orgId;
            tempObj.quizName = val.quizName;
            tempObj.unAnsweredQues = val.unAnsweredQues;
            $scope.checkCount = true;
            $scope.descrised = false;
            $scope.showQuestions(tempObj);
            return tempObj;
            // $scope.uArr.push(tempObj);
          });
          MyService.ConsoleLog(' $scope.unAnsArr', $scope.unAnsArr);
          var unAnsQIds = $scope.unAnsArr.map(function (itms) {
            if (itms && itms.queId) {
              return itms.queId;
            }
          });
          MyService.ConsoleLog("unAnsQIds", unAnsQIds.length);
          $scope.unAnsCount = unAnsQIds.filter(function (itm, ind) {
            return ind == unAnsQIds.findIndex(function (ele) {
              return JSON.stringify(ele) == JSON.stringify(itm);
            });
          });
          MyService.ConsoleLog("$scope.unAnsCount", $scope.unAnsCount.length);
        } else {
          $scope.isSelected = false;
          angular.forEach($scope.compositeQuiz, function (val, key) {
            val.isSelected = false;
            $scope.uArr = [];
            $scope.selectedQuestions = 0;
            // MyService.ConsoleLog(' else', $scope.uArr);
          });
        }
      };

      /**
       * Show questions
       */
      $scope.showQuestions = function (val, show) {
        $scope.idsArr = [];
        // MyService.ConsoleLog("showQuestions val", val);
        if (show && show != '') {
          $scope.checkCount = false;
        }
        MyService.ConsoleLog('val', val);
        $scope.view = [];
        $scope.viewTwo = [];
        MyService.getSelectedQues({
          orgId: val.orgId,
          quizName: val.quizName,
        }).then(
          function (result) {
            MyService.ConsoleLog('result', result.data.data);
            // var len = result.data.data.length;
            // for (var i = 0; i < len; i++) {
            //   if ($scope.idsArr && $scope.idsArr.length > 0) {
            //     if ($scope.idsArr.indexOf(result.data.data[i].queId) == -1) {
            //       $scope.idsArr.push(result.data.data[i].queId);
            //     }
            //   } else {
            //     $scope.idsArr.push(result.data.data[i].queId);
            //   }
            // }
            // MyService.ConsoleLog('$scope.idsArr', $scope.idsArr.length);
            if (!$scope.checkCount) {
              $scope.questions = result.data.data;
              $scope.view[val.orgId] = true;
              $scope.viewTwo[val.quizName] = true;
            }
            // if ($scope.checkCount) {
            //   $scope.quesCount = result.data.data.length;
            //   if ($scope.descrised) {
            //     MyService.ConsoleLog('<<<<<<<<<<>>>>>>>>>>>>>');
            //     $scope.selectedQuestions -= $scope.quesCount;
            //   } else if ($scope.isSelected && $scope.descrised) {
            //     // alert('here');
            //     $scope.selectedQuestions -= $scope.quesCount;
            //   } else {
            //     MyService.ConsoleLog('three');
            //     $scope.selectedQuestions += $scope.quesCount;
            //   }
            // }
            MyService.getCompositeQuiz({
              obj: JSON.stringify($scope.uArr),
            }).then(
              function (response) {
                MyService.ConsoleLog('Response', response);
                if (response.success) {
                  $scope.selectedQuestions = response.data.length;
                  window.localStorage.setItem('queIds', JSON.stringify(response.data));
                }
              },
              function (err) {
                MyService.ConsoleLog('Error', err);
              }
            );
            MyService.ConsoleLog(' $scope.quesCount', $scope.quesCount);
          },
          function (err) {
            growl.error(err);
          }
        );
      };

      /**
       * Hide list of questions
       */
      $scope.showLess = function (id) {
        // console.log("id for less",id);
        $scope.view[id.orgId] = false;
        $scope.viewTwo[id.quizName] = false;
      };

      /**
       * Get Quiz of composite questions
       */

      $scope.getQuiz = function () {
        MyService.ConsoleLog("$scope.uArr", $scope.uArr);
        MyService.ConsoleLog("$scope.uArr", $scope.uArr.length);
        // return;
        if ($scope.uArr && $scope.uArr.length > 0) {
          $cookies.put('advId', $scope.uArr[0].orgId);
          $cookies.put('quizName', $scope.uArr[0].quizName);
          $cookies.put('showBanner', true);
          MyService.ConsoleLog("showBanner >>>>>>>>>", $cookies.get('showBanner'));
          // alert("isnsnsn");
          $location.path('/organizationQuiz');
        } else {
          growl.error('Please select quizzes first');
        }
      };

      /**
       * Get Quiz of unAnswered questions
       * redirectToQuestions
       */

      $scope.redirectToQuestions = function (val) {
        MyService.ConsoleLog('val', val);
        MyService.getSelectedQues({
          orgId: val.orgId,
          quizName: val.quizName,
        }).then(
          function (APIResponse) {
            MyService.ConsoleLog("res APIResponse", APIResponse)
            if (APIResponse.data.success) {
              var data = APIResponse.data.data.map(function (itms) {
                return itms.queId;
              });
              MyService.ConsoleLog('data', data);
              if (data && data.length > 0) {
                MyService.ConsoleLog("val.orgId", val.orgId);

                $cookies.put('advId', val.orgId);
                window.localStorage.setItem('queIds', JSON.stringify(data));
                $location.path('/organizationQuiz/' + val.quizName);
              }
            }

          },
          function (err) {
            MyService.ConsoleLog("erroe", err);
          });
        // return;
        // var data = val.unAnsweredQuesData.map(function (itms) {
        //   return itms.queId;
        // });
        // MyService.ConsoleLog('data', data);
        // if (data && data.length > 0) {
        //   MyService.ConsoleLog("val.orgId", val.orgId);

        //   $cookies.put('advId', val.orgId);
        //   window.localStorage.setItem('queIds', JSON.stringify(data));
        //   $location.path('/organizationQuiz/' + val.quizName);
        // }
      };

      /**
       * Sort questions number
       */

      $scope.sortQues = function () {
        var sortOrder = '';

        // MyService.ConsoleLog("called");
        if ($scope.queUp == '' || $scope.queUp == 'DESC') {
          sortOrder = 'ASC';
          // if (para == 'factual') {
          $scope.queUp = true;
          $scope.queDown = false;

          // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);

          $scope.compositeQuiz.sort(function (a, b) {
            return a.noOfQues - b.noOfQues;
          });
        } else {
          sortOrder = 'DESC';

          $scope.queUp = false;
          $scope.queDown = true;

          // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);
          $scope.compositeQuiz.sort(function (a, b) {
            return b.noOfQues - a.noOfQues;
          });
        }
      };

      $scope.unAns = function () {
        var sortOrder = '';

        // MyService.ConsoleLog("called");
        if ($scope.unAnsUp == '' || $scope.unAnsUp == 'DESC') {
          sortOrder = 'ASC';
          // if (para == 'factual') {
          $scope.unAnsUp = true;
          $scope.unAnsDown = false;

          // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);

          $scope.compositeQuiz.sort(function (a, b) {
            return a.unAnsweredQues - b.unAnsweredQues;
          });
        } else {
          sortOrder = 'DESC';

          $scope.unAnsUp = false;
          $scope.unAnsDown = true;

          // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);
          $scope.compositeQuiz.sort(function (a, b) {
            return b.unAnsweredQues - a.unAnsweredQues;
          });
        }
      };

      /** Show filter */
      $scope.showFilter = function () {
        if (!$scope.isShowFilter) {
          $scope.isShowFilter = true;
        } else {
          $scope.isShowFilter = false;
        }
        // var query = $scope.search.text;
        $scope.search = {
          subject: '',
          Locations: '',
          mediaoption: [],
          mediaType: '',
          dateBefore: '',
          dateAfter: '',
          matchFrom: '',
          matchTo: '',
          recommendFrom: '',
          recommendTo: '',
          recommendFilter: '',
          factualFilter: '',
          factualFrom: '',
          factualTo: '',
          order: '',
          recommendorder: '',
          lrecommendorder: '',
          factualorder: '',
          lfactualorder: '',
          fromfollowlist: '',
          chk: false,
        };
      };

      $scope.showStates = false;
      $scope.showAllFilter = function (e, option) {
        e.preventDefault();
        switch (option) {
          case 'Interest':
            $scope.getSubcatTree();
            break;
          case 'Location':
            if (!$scope.showRefineLocation) $scope.showRefineLocation = true;
            else $scope.showRefineLocation = false;
            break;

          case 'AllLocation':
            if (!$scope.showStates) {
              $scope.showStates = true;
            } else {
              $scope.showStates = false;
            }
            $scope.getAllStates();
            break;

          case 'Default':
            break;
        }
      };

      /** Check cat exists or not */

      $scope.checkCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata;
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val);
          }
        });
      };

      /**
       * Get  Categories */

      $scope.getSubcatTree = function () {
        MyService.ConsoleLog("calll sdnsnssn");
        if ($scope.mainCatTree.length == 0) {
          $scope.ShowSubcatTree = true;
          MyService.getQuizzesCategories().then(function (payload) {
            MyService.ConsoleLog("payload", payload);
            $scope.subtopics.subcategories.length = 0;
            $scope.mainCatTree = angular.copy(payload);
            var subcatdata = payload;
            $scope.ShowSubcatTree = false;
            $scope.subtopics.subcategories = subcatdata;
            var selectedCat = $scope.disabled;
            if (selectedCat.length > 0) {
              for (var s = 0; s < selectedCat.length; s++) {
                var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
              }
              //MyService.ConsoleLog($scope.selectedCat);
            }
            setTimeout(function () {
              var cols = document.getElementsByClassName('cmnUL');
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = 'none';
              }
            }, 300);
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        } else {
          //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories.length);
          if ($scope.subtopics.subcategories.length == 0) {
            $scope.subtopics.subcategories = angular.copy($scope.mainCatTree);
          } else {
            $scope.subtopics.subcategories.length = 0;
          }

          $scope.ShowSubcatTree = false;
          //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories);
          //MyService.ConsoleLog("After : " + $scope.ShowSubcatTree);
        }
        /*if ($scope.mainCatTree.length == 0) {
          $scope.ShowSubcatTree = true;
          MyService.getAllCatTree().then(
            function (payload) {
              $scope.subtopics.subcategories.length = 0;
              $scope.mainCatTree = angular.copy(payload);
              var subcatdata = payload;
              $scope.ShowSubcatTree = false;
              $scope.subtopics.subcategories = subcatdata;
              var selectedCat = $scope.disabled;
              if (selectedCat.length > 0) {
                for (var s = 0; s < selectedCat.length; s++) {
                  var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
                }
                //MyService.ConsoleLog($scope.selectedCat);
              }
              setTimeout(function () {
                var cols = document.getElementsByClassName('cmnUL');
                var clen = cols.length;
                for (var i = 0; i < clen; i++) {
                  cols[i].style.display = 'none';
                }
              }, 300);
            },
            function (errorPayload) {
              alert('Something went wrong ');
            }
          );
        } else {
          //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories.length);
          if ($scope.subtopics.subcategories.length == 0) {
            $scope.subtopics.subcategories = angular.copy($scope.mainCatTree);
          } else {
            $scope.subtopics.subcategories.length = 0;
          }

          $scope.ShowSubcatTree = false;
          //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories);
          //MyService.ConsoleLog("After : " + $scope.ShowSubcatTree);
        }*/
      };

      /**
       * Get All State */
      $scope.getAllStates = function () {
        $scope.ShowStateLoader = true;
        if ($scope.AllStates.length == 0) {
          MyService.getAllStates().then(
            function (payload) {
              $scope.ShowStateLoader = false;
              $scope.allLocations.length = 0;
              var subcatdata = payload;
              $scope.AllStates = angular.copy(subcatdata);
              //MyService.ConsoleLog("countires : ", subcatdata);
              $scope.allLocations = subcatdata;
            },
            function (errorPayload) {
              alert('Something went wrong ');
            }
          );
        } else {
          if (!$scope.showStates) {
            $scope.allLocations.length = 0;
            $scope.ShowStateLoader = false;
          } else {
            $scope.allLocations = angular.copy($scope.AllStates);
            $scope.ShowStateLoader = false;
          }
        }
      };

      $scope.getCounties = function (state) {
        // MyService.ConsoleLog("state", state);
        $scope.showLoading.push(state);

        if (!$scope.allCounties[state.statePostalCode] || $scope.allCounties[state.statePostalCode].length == 0) {
          MyService.getAllCounties(state.stateFips).then(
            function (payload) {
              $scope.showLoading.length = 0;
              var sublocations = payload;
              // MyService.ConsoleLog("paload", payload);
              if (!$scope.allCounties) {
                $scope.allCounties = [];
              }
              $scope.allCounties[state.statePostalCode] = payload;
              // MyService.ConsoleLog(" $scope.allCounties", $scope.allCounties);
            },
            function (errorPayload) {
              alert('Something went wrong.');
            }
          );
        } else {
          // alert('inside');
          $scope.showLoading.length = 0;
          $scope.allCounties[state.statePostalCode].length = 0;
        }
      };

      $scope.getLocals = function (state, county) {
        MyService.ConsoleLog('state', state);
        MyService.ConsoleLog('county', county);

        $scope.showLoadingCounty.push(county);
        if (
          !$scope.allLocals[state] ||
          !$scope.allLocals[state][county] ||
          $scope.allLocals[state][county].length == 0
        ) {
          MyService.getAllSubCountiesLocal(state, county).then(
            function (payload) {
              $scope.showLoadingCounty.length = 0;
              MyService.ConsoleLog('sublocations Locals : ', payload);
              if (!$scope.allLocals[state]) {
                $scope.allLocals[state] = [];
              }
              MyService.ConsoleLog('state.statePostalCode', state.statePostalCode);
              $scope.allLocals[state][county] = payload.places;
              MyService.ConsoleLog('sublocations Locals : ', $scope.allLocals[state][county]);
            },
            function (errorPayload) {
              alert('Something went wrong ');
            }
          );
        } else {
          // alert('inside');
          $scope.showLoadingCounty.length = 0;
          $scope.allLocals[state][county].length = 0;
        }
      };

      $scope.ChangeShow = function (id) {
        $scope.showSubCat = true;

        var nclass = 'MainCatLi_' + id;
        if (
          document.getElementsByClassName(nclass)[0].style.display &&
          document.getElementsByClassName(nclass)[0].style.display == 'none'
        ) {
          document.getElementsByClassName(nclass)[0].style.display = 'block';
        } else {
          document.getElementsByClassName(nclass)[0].style.display = 'none';
        }
      };

      /**
       * Get Selected val */

      $scope.getVal = function (data) {
        // MyService.ConsoleLog("data", data);
        var idx = $scope.Interests.indexOf(data._id);
        if (idx == -1) {
          $scope.Interests.push(data._id);
        } else {
          $scope.Interests.splice(idx, 1);
        }
        MyService.ConsoleLog('$scope.Interest', $scope.Interests);
      };

      /**
       * Filter Quizzes */

      $scope.filterQuizzes = function () {
        MyService.ConsoleLog("$scope.findLocations", $scope.findLocations);
        MyService.ConsoleLog("$scope.MyLocation", $scope.MyLocation);

        if ($scope.Interests && $scope.Interests.length > 0 || $scope.findLocations && $scope.findLocations.length > 0 || $scope.MyLocation && $scope.MyLocation.length > 0) {
          $scope.currPage = 0;
          $scope.compositeQuiz = [];
          $scope.statusGetResPg = 2;
          $scope.pageFunction();
          // alert('djf');
        } else {
          // alert('call');
          if ($scope.quizName && $scope.quizName != '') {
            $scope.compositeQuiz = [];
            $scope.getQuizDetails(1);
          } else {
            $scope.compositeQuiz = [];
            $scope.getShowAll();
          }
        }
      };

      /**
       * Select Default locations
       */
      $scope.SelectDefaultLocation = function (val) {
        //MyService.ConsoleLog("Data : ", val);
        var idx = $scope.MyLocation.indexOf(val);
        if (idx == -1) {
          $scope.MyLocation.push(val);
        } else {
          $scope.MyLocation.splice(idx, 1);
        }
        //MyService.ConsoleLog($scope.MyLocation);
      }

      /**
       * check vales are equals or not
       */
      function areEqual() {
        var len = arguments.length;
        for (var i = 1; i < len; i++) {
          if (arguments[i] == null || arguments[i] != arguments[i - 1])
            return false;
        }
        return true;
      }


      /**
       * @description check index is exist or not
       * @param {array of states} list
       * @param {* object of state} item
       */
      function indexOfExt(list, item) {
        var len = list.length;
        for (var i = 0; i < len; i++) {
          var keys = Object.keys(list[i]);
          var flg = true;
          var arr = [];
          for (var j = 0, s = 1; j < keys.length; j++, s++) {
            var value = list[i][keys[j]];
            if (item[keys[j]] !== value) {
              flg = false;
            }

            if (item[keys[j]] == value) {
              arr.push(flg);
            }
          }
          if (flg == true) {
            if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
              return i;
            }
          }
        }
        return -1;
      }

      /**
       * Select locations
       */

      $scope.selectLocation = function (val1, val2, val3, type) {
        // MyService.ConsoleLog(val1, val2, val3, type);
        var nobj = {};
        nobj.country = "United States of America";
        if (type == "state") {
          nobj.state = val1.state;
          nobj.statePostalCode = val1.statePostalCode;
        }
        if (type == "county") {
          nobj.state = val1.state;
          nobj.statePostalCode = val1.statePostalCode;
          nobj.county = val3;
        }
        if (type == "local") {
          nobj.state = val1.state;
          nobj.statePostalCode = val1.statePostalCode;
          nobj.county = val2;
          nobj.countySubdivision = val3.countySubdivision;
          nobj.place = val3.placeName;
        }
        MyService.ConsoleLog("nboj:", nobj);
        var idx = indexOfExt($scope.findLocations, nobj);
        if (idx == -1) {
          $scope.findLocations.push(nobj);
        } else {
          $scope.findLocations.splice(idx, 1);
        }
      }

      if ($scope.orgId && $scope.orgId != '') {
        $scope.getQuizDetails(1);

      } else {
        $scope.getQuizDetails(1);
      }
    },
  ]);
