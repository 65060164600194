"use strict";
angular
  .module("myApp.createBanner", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/createBanner", {
        templateUrl: "app/createBanner/createBanner.html",
        controller: "createBannerCtrl",
      });
    },
  ])
  .controller("createBannerCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "growl",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      growl
    ) {
      $scope.banner = {
        event: "",
        link: "",
        description: "",
        isActive: "",
      };

      $scope.addBanner = function () {
        if (
          $scope.banner.link &&
          $scope.banner.link != "" &&
          $scope.banner.event &&
          $scope.banner.event != ""
        ) {
          MyService.ConsoleLog("$scope.Banner", $scope.banner);
          MyService.addBannerInfo($scope.banner).then(function (APIResponse) {
            MyService.ConsoleLog(
              "TCL ->  ~ file: createBanner.js ~ line 54 ~ APIResponse",
              APIResponse
            );
            if (APIResponse.data.success) {
              $scope.banner = {
                event: "",
                link: "",
                description: "",
                isActive: "",
              };
              growl.success(APIResponse.data.data);
            }
          });
        } else {
          growl.error("Please select event type or Add the link");
        }
      };

      $scope.getBannerInfo = function () {
        MyService.getBannerInfo({ event: $scope.banner.event }).then(function (
          APIResponse
        ) {
          MyService.ConsoleLog(
            "TCL ->  ~ file: createBanner.js ~ line 75 ~ APIResponse",
            APIResponse
          );
          if (APIResponse.data.success) {
            $scope.banner.link = APIResponse.data.data.webLink;
            $scope.banner.description = APIResponse.data.data.description;
            if (APIResponse.data.data.isActive) {
              $scope.banner.isActive = "true";
            } else {
              $scope.banner.isActive = "false";
            }
          }
        });
      };
    },
  ]);
