'use strict';

angular.module('myApp.showGroupAnswer', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showGroupAnswer/:data?/:quizName?/:isPopular?', {
      templateUrl: 'app/showGroupAnswer/showGroupAnswer.html',
      controller: 'showGroupAnswerCtrl'
    });
  }]).controller('showGroupAnswerCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    $scope.showPoliAnswerDetails = false;
    $scope.showUserAnswerDetails = false;
    $scope.showBack = $routeParams.data;
    $scope.quizName = $routeParams.quizName;
    $scope.isPopular = $routeParams.isPopular;


    if (window.localStorage.getItem('poliAr')) {
      var pdata = window.localStorage.getItem('poliAr');
      var opoli = JSON.parse(pdata);
      $scope.globalArray = opoli.poli;
      $scope.queId = opoli.qId;
      $scope.showPoliAnswerDetails = true;
    }

    /**
     * For Voter
     */
    if (window.localStorage.getItem('voter')) {
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var data = window.localStorage.getItem('voter');
      var none = JSON.parse(data);
      $scope.globalArray = none.vot;
      $scope.queId = none.qId;
      $scope.showPoliAnswerDetails = true;

      // MyService.ConsoleLog(" $scope.unVeriComm", $scope.unVeriComm);
    }

    /**
     * For Advocates
     * */

    if (window.localStorage.getItem('adv')) {
      var adata = window.localStorage.getItem('adv');
      var aone = JSON.parse(adata);
      MyService.ConsoleLog("aone", aone);

      $scope.globalArray = aone.adv;
      $scope.queId = aone.qId;
      $scope.showPoliAnswerDetails = true;
      if ($cookies.get('orgName')) {
        $scope.orgName = $cookies.get('orgName');
      }
    }

    /**
     * For Guest Users
     */
    if (window.localStorage.getItem('unVerify')) {
      // alert('called');
      // MyService.ConsoleLog("window.localStorage.getItem('comOfVoter')", window.localStorage.getItem('comOfVoter'));
      var gdata = window.localStorage.getItem('unVerify');
      var gone = JSON.parse(gdata);
      $scope.unVeriCommNew = gone.unVeri;
      $scope.queId = gone.qId;
      $scope.showUserAnswerDetails = true;
    }

    /**
     * This function filter comment data
     */
    $scope.getItems = function () {
      return function (u) {
        // MyService.ConsoleLog("params >", u);

        if (u.comment && u.comment != "") {
          return u;
        }
      };
    };

    $scope.getComment = function () {
      return function (x) {
        if (x.comment && x.comment != "") {
          return x;
        }
      };
    };

    $scope.getComapreClass = function (match, i) {
      //MyService.ConsoleLog("match: ", match);
      /*if(match == 100)
        return 'ag-green';
    if(match == 0)
        return 'ag-red';
    if(match > 0 && match <100)
        return 'ag-dg'+i;*/
      if (match >= 100) return 'ag-dg1';
      if (match >= 80) return 'ag-dg2';
      if (match >= 60) return 'ag-dg3';
      if (match >= 40) return 'ag-dg4';
      if (match >= 20) return 'ag-dg5';
      if (match >= 0) return 'ag-dg6';

      return 'ag-dg';
    };

    $scope.back = function () {
      MyService.ConsoleLog("Called");
      if ($scope.showBack == 'true') {
        // alert('c')
        $location.path('/poliCompare/' + $scope.quizName);
      } else if ($scope.isPopular == 'true') {
        $location.path('/popularQuizMatch');

      } else {
        $location.path('/findMatch');

      }
    };


  }]);
