"use strict";

angular
  .module("myApp.fiveMatchView", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/fiveMatch/:tmpType?", {
        templateUrl: "app/fivemin_match/fivemin_match.html",
        controller: "fiveMatchCtrl",
      });
    },
  ])

  .controller("fiveMatchCtrl", [
    "$scope",
    "apiCall",
    "MyService",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$rootScope",
    "ngDialog",
    "$q",
    "$http",
    "$filter",
    "tokenService",
    "baseApiUrl",
    "$document",
    "growl",
    "$cookies",
    function (
      $scope,
      apiCall,
      MyService,
      $location,
      $anchorScroll,
      $timeout,
      $rootScope,
      ngDialog,
      $q,
      $http,
      $filter,
      tokenService,
      baseApiUrl,
      $document,
      growl,
      $cookies
    ) {
      // alert('inside');
      $rootScope.welcome = true;
      $scope.currentIndex = 0;
      $scope.questions = [];
      $scope.oldQuestions = [];
      $scope.tempans = [];
      $scope.oldIndex = 0;
      $scope.showZipCode = false;
      $scope.showNw = false;
      $scope.isShowCheckBx = false;
      $scope.UserId = tokenService.getUserId();

      $scope.quizAns = {
        shortAns: "",
        mediAns: "",
        longAns: "",
      };
      $scope.answer;
      $scope.updata = {
        comment: "",
        reason: "",
      };
      $scope.nwAns;
      // $scope.skipToRes = false;
      $("#skipMatch").attr("disabled", true);

      /**
       * @description Remove session Id
       */

      window.localStorage.removeItem("sessionId");
      window.localStorage.removeItem("queIdsN");
      window.localStorage.removeItem("extraData");
      window.localStorage.removeItem("tempUserAddress");
      window.localStorage.removeItem("poliList");
      window.localStorage.removeItem("isFiveMinPage");
      window.localStorage.removeItem("fiveMatch");
      window.localStorage.removeItem("compareResult");

      $rootScope.guestUserData = [];
      MyService.ConsoleLog(" user id :", $scope.UserId);
      if ($cookies.get("sessionId")) {
        $cookies.remove("sessionId");
      }
      $scope.fivefb = {
        title:
          "Take a fun five minute quiz to find out who to vote for in your local elections",
        description: "",
        fbFiveImage: "",
      };
      window.localStorage.removeItem("mailingAddress");

      $scope.isUser = {
        selected: "",
      };

      /**
       *
       * @description Get front runners records from locaStorage if the user role is admin.
       */
      if (tokenService.getUserRole() == "admin") {
        $scope.frontRunners = JSON.parse(
          window.localStorage.getItem("frontRunners")
        );

        MyService.ConsoleLog("here is the frontRunners", $scope.frontRunners);
      }

      /**
       * @description Function calls when admin select front runner to answer for.
       * @param {*} userId
       */
      $scope.setUserId = function () {
        MyService.ConsoleLog("$scope.isUser", $scope.isUser.selected);
      };

      $scope.getFiveFacebookPost = function (data) {
        MyService.getFiveFacebookPost().then(
          function (payload) {
            // MyService.ConsoleLog(payload)
            if (payload.data.success) {
              $scope.fivefb.title = payload.data.data.fbTitle;
              $scope.fivefb.description = payload.data.data.fbDescription;
              if (payload.data.data.fbImage) {
                $scope.fivefb.fbFiveImage =
                  baseApiUrl + "/resources/" + payload.data.data.fbImage;
              }
            }
          },
          function (errorPayload) {}
        );
      };

      $scope.getFiveFacebookPost();

      if ($scope.currentIndex == 0) {
        if (window.localStorage.getItem("fiveMinMatch")) {
          // $scope.questions = JSON.parse(window.localStorage.getItem("fiveMinMatch"));
          $rootScope.fiveMatch = JSON.parse(
            window.localStorage.getItem("fiveMinMatch")
          );

          $timeout(function () {
            $scope.$apply();
          });
        } else {
          $rootScope.fiveMatch = [];
          $rootScope.fiveMatch.length = 0;
        }
      }
      // MyService.ConsoleLog("**** questions : " ,$scope.questions);
      /*if ($scope.questions.length <= 0) {
      if (window.localStorage.getItem("fiveMinMatch")) {
        $scope.questions = JSON.parse(window.localStorage.getItem("fiveMinMatch"));
      }
    }else{
      MyService.ConsoleLog("questions present");
    }*/
      $scope.initializeVariables = function () {
        $scope.importance = null;
        $scope.answer = null;
        $scope.updata.comment = "";
        $scope.updata.reason = "";

        // $scope.comment=document.getElementById('exampleFormControlTextarea1').value;
      };
      // $scope.comment;

      $scope.saveFiveMinAnswers = function (skip) {
        // MyService.ConsoleLog("saveFiveMinAnswers : ");
        MyService.ConsoleLog(
          "saveFiveMinAnswers fiveMatch >>>: ",
          $rootScope.fiveMatch
        );
        // alert(skip);
        $scope.pstData = [];
        var len = $rootScope.fiveMatch.length;
        MyService.ConsoleLog("Len :: ", len);
        if (len > 0) {
          for (var i = 0, k = 1; i < len; i++) {
            if (skip != "") {
              $scope.answer = "";
              $scope.importance = "";
              $scope.updata.comment = "";
              // MyService.ConsoleLog("saveFiveMinAnswers i >> ", i);
              // MyService.ConsoleLog("saveFiveMinAnswers >> ", $rootScope.fiveMatch[i]);
              if ($rootScope.fiveMatch[i]) {
                // console.log("Inside i ", i);
                // console.log("answer :", $rootScope.fiveMatch[i]);
                if (
                  $rootScope.fiveMatch[i].answer &&
                  $rootScope.fiveMatch[i].answer != ""
                ) {
                  $scope.answer = $rootScope.fiveMatch[i].answer;
                  $scope.importance = $rootScope.fiveMatch[i].importance;
                  $scope.updata.comment = $rootScope.fiveMatch[i].comment;
                  var postData = {
                    questionId: $rootScope.fiveMatch[i]._id,
                    importance: $scope.importance,
                    answer: $scope.answer,
                    comment: $scope.updata.comment,
                    categories: $rootScope.fiveMatch[i]["categories"],
                    content: $rootScope.fiveMatch[i]["content"],
                    _id: $rootScope.fiveMatch[i]["_id"],
                    matchOrder: $rootScope.fiveMatch[i]["matchOrder"],
                  };
                  $rootScope.fiveMatch[i] = angular.copy(postData);
                  // console.log("fiveMatch[i] > ", $rootScope.fiveMatch[i]);
                  $scope.pstData.push(postData);
                } else {
                  var data = $scope.checkQuestion($rootScope.fiveMatch[i]._id);
                  if (data && data[0]) {
                    // MyService.ConsoleLog("getdata >>> ", data[0]);
                    if (data[0].answer != "") {
                      $scope.answer = data[0].answer;
                    }
                    if (data[0].importance != "") {
                      $scope.importance = data[0].importance;
                    }
                    if (data[0].comment != "") {
                      $scope.updata.comment = data[0].comment;
                    }
                  }
                  var postData1 = {
                    questionId: $rootScope.fiveMatch[i]._id,
                    importance: $scope.importance,
                    answer: $scope.answer,
                    comment: $scope.updata.comment,
                    categories: $rootScope.fiveMatch[i]["categories"],
                    content: $rootScope.fiveMatch[i]["content"],
                    _id: $rootScope.fiveMatch[i]["_id"],
                    matchOrder: $rootScope.fiveMatch[i]["matchOrder"],
                  };
                  $rootScope.fiveMatch[i] = angular.copy(postData1);
                  $scope.pstData.push(postData1);
                }

                // MyService.ConsoleLog("$scope.postdata :",$scope.pstData);
              }
            } else {
              var postData = $rootScope.fiveMatch[i];
              $scope.pstData.push(postData);
              // MyService.ConsoleLog("$scope.postdata :>>>>>>>>>>>>", $rootScope.fiveMatch);
            }

            // console.log("postData1 > ", $rootScope.fiveMatch[i]);
            // console.log("postData > ", postData);
            if (skip != "" && postData) {
              console.log("saveFiveMinAnswers postData >>>>>>> ", postData);

              MyService.postAnswer(postData).then(
                function (response) {
                  // MyService.ConsoleLog("saveFiveMinAnswers response >>>", response);
                  if (response.data.success) {
                    MyService.ConsoleLog("Answer updated successfully");
                    growl.success("Answer updated successfully");
                  } else {
                    MyService.ConsoleLog("Error: " + response.data.error);
                    growl.error(response.data.error);
                  }

                  // console.log(k + "===" + len);
                  if (k == len) {
                    // console.log("redirect to five compare");
                    // if (skip == "skip") {
                    $rootScope.fiveMatch = angular.copy($scope.pstData);
                    window.localStorage.setItem(
                      "fiveMatch",
                      JSON.stringify($rootScope.fiveMatch)
                    );
                    // }

                    MyService.ConsoleLog(
                      "$rootScope.fiveMatch > ******** >>>",
                      $rootScope.fiveMatch
                    );
                    // MyService.ConsoleLog("This is length>>>>>>> ::", $scope.queLen);

                    if (skip == "RediectNew") {
                      $location.path("/fiveCompare/skip_to_result");
                    } else if (skip != "skipNew") {
                      MyService.ConsoleLog("IFFFFFFFFFFFFFF");
                      $location.path("/fiveCompare");
                    }

                    // else{
                    //   MyService.ConsoleLog("ELSESSSSSSSSSSSS");

                    //   $location.path('/fiveCompare/skip_to_result');

                    // }
                  }
                  k++;
                },
                function (err) {
                  MyService.ConsoleLog("Error: err");
                }
              );
            } else {
              // console.log("POstData ");

              $scope.updata.comment = "";

              if (k == len) {
                // console.log("redirect to five compare");
                // if (skip == "skip") {
                $rootScope.fiveMatch = angular.copy($scope.pstData);
                MyService.ConsoleLog(
                  "<<<<<<< tfdd",
                  window.localStorage.getItem("isShowFiveMin")
                );
                MyService.ConsoleLog(
                  "<<<<<<< tfdd",
                  typeof window.localStorage.getItem("isShowFiveMin")
                );

                // alert("got it");
                if (window.localStorage.getItem("isShowFiveMin") != "true") {
                  window.localStorage.setItem(
                    "fiveMatch",
                    JSON.stringify($rootScope.fiveMatch)
                  );
                } else {
                  window.localStorage.removeItem("isShowFiveMin");
                }

                // }

                MyService.ConsoleLog(
                  "$rootScope.fiveMatch > ******** 88888888 >>>",
                  $rootScope.fiveMatch
                );
                // MyService.ConsoleLog("This is length>>>>>>> ::", $scope.queLen);

                if (skip == "RediectNew") {
                  $location.path("/fiveCompare/skip_to_result");
                } else if (skip != "skipNew") {
                  MyService.ConsoleLog("IFFFFFFFFFFFFFF");
                  $location.path("/fiveCompare");
                }

                // else{
                //   MyService.ConsoleLog("ELSESSSSSSSSSSSS");

                //   $location.path('/fiveCompare/skip_to_result');

                // }
              }
              k++;
            }
          }
        }
      };

      $scope.fiveMinuteMatchDone = function () {
        // MyService.ConsoleLog($rootScope.fiveMatch);
        // MyService.ConsoleLog("**** fiveMinuteMatchDone");
        // MyService.ConsoleLog("UserId : >>>>>>", $rootScope.UserId);
        $scope.showpopUp = false;
        if (!$scope.UserId || $scope.UserId == "") {
          MyService.ConsoleLog("$scope.Questions", $scope.questions);
          var qLen = $scope.questions.length;
          for (var t = 0; t < qLen; t++) {
            if (
              $scope.questions[t].my_answer &&
              $scope.questions[t].my_answer != "" &&
              $scope.questions[t].my_answer.answer != ""
            ) {
              $scope.showpopUp = true;
              $scope.showZipCode = true;
            } else {
              if (!$scope.showpopUp && t == qLen - 1) {
                var message =
                  "You need to answer at least one question to see a match comparison";
                var modal = ngDialog.openConfirm({
                  template:
                    "<p>" +
                    message +
                    '</p>\
                <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip match and see candidates</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Back to Questions</button>\
                </div>',
                  plain: true,
                  closeByDocument: false,
                });
                modal.then(
                  function fullfilled(data) {
                    // MyService.ConsoleLog("fullfilled: ", data);
                    $scope.getMatchQUestions();
                  },
                  function rejected(data) {
                    // MyService.ConsoleLog("rejected: ", data);
                    // $scope.getMatchQUestions();
                    // $location.path("/compare-select/politician");
                    //  $location.path("/fiveSaveUser");
                    const ansQue = $scope.checkQuestionAnswered();
                    if (ansQue == 0) {
                      window.localStorage.setItem(
                        "fiveMatch",
                        JSON.stringify($rootScope.fiveMatch)
                      );
                    }

                    $scope.showZipCode = true;
                  }
                );
                t = qLen + 1;
              }
            }
          }
        } else {
          // MyService.ConsoleLog("else called");
          // alert("else");
          $scope.saveFiveMinAnswers("");
        }
        //MyService.ConsoleLog($scope.questions);
      };
      $scope.initializeVariables();
      $scope.newQuestion = function () {
        MyService.ConsoleLog("newQuestion" + $scope.currentIndex);
        var tempIndex = $scope.currentIndex;
        $scope.initializeVariables();

        $scope.answer = "";
        $scope.importance = "";
        $scope.updata.comment = "";
        MyService.ConsoleLog("tempIndex<><><", tempIndex);
        // MyService.ConsoleLog("questions<><><", $scope.questions);
        MyService.ConsoleLog("length yog : ", $scope.questions.length);
        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();

          if (window.localStorage.getItem("fiveMinMatch")) {
            $scope.getFiveMinData = [];
            $scope.getFiveMinData = JSON.parse(
              window.localStorage.getItem("fiveMinMatch")
            );
            MyService.ConsoleLog("Data : ", $scope.getFiveMinData);
            for (var i = 0; i < $scope.getFiveMinData.length; i++) {
              // MyService.ConsoleLog("for i : " + i + " currentIndex : " + $scope.currentIndex);
              if (i == $scope.currentIndex) {
                // MyService.ConsoleLog("index matched", $scope.getFiveMinData[i]);
                $scope.answer = $scope.getFiveMinData[i].answer;
                $scope.importance = $scope.getFiveMinData[i].importance;
                $scope.updata.comment = $scope.getFiveMinData[i].comment;
                // MyService.ConsoleLog("answer : " + $scope.answer);
                // MyService.ConsoleLog("importance : " + $scope.importance);
                // MyService.ConsoleLog("comment : " + $scope.comment);
                $timeout(function () {
                  $scope.$apply();
                });
              }
            }
          } else {
            console.log("Else is called");
            $scope.answer = "";
            $scope.importance = "";
            $scope.updata.comment = "";
            $scope.quizAns = {
              shortAns: "",
              mediAns: "",
              longAns: "",
            };
            MyService.ConsoleLog("Myans", $scope.getFiveMinData);

            // $scope.currentIndex++;
          }
          //$scope.currentIndex++;
        } else {
          // MyService.ConsoleLog("final question : " + $scope.questions.length);
          $scope.fiveMinuteMatchDone();
        }

        /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
        $scope.$apply();
      }, 100);
      */
      };

      $scope.checkQuestion = function (val) {
        if (
          $scope.AllAnsweredQuestions &&
          $scope.AllAnsweredQuestions.length > 0
        ) {
          return $scope.AllAnsweredQuestions.filter(function (rdata) {
            // MyService.ConsoleLog(rdata.question + "==" + val);
            if (rdata.question == val) return rdata;
          });
        } else {
          if (!apiCall.getToken()) {
            return $rootScope.guestUserData.filter(function (rdata) {
              // MyService.ConsoleLog(rdata.questionId + "<==>" + val);
              if (rdata.questionId == val) return rdata;
            });
          } else {
            return $rootScope.fiveMatch.filter(function (rdata) {
              // MyService.ConsoleLog(rdata.questionId + "<==>" + val);
              if (rdata.questionId == val) return rdata;
            });
          }
        }
      };

      $scope.checkIfExists = function (val) {
        // MyService.ConsoleLog("checkIfExists >> ", $scope.currentIndex);

        var obj = $scope.questions[$scope.currentIndex];
        // MyService.ConsoleLog("checkIfExists >> ", obj);
        if (obj) {
          var data = $scope.checkQuestion(obj._id);

          if (data && data[0]) {
            // MyService.ConsoleLog("checkIfExists data>> ", data[0]);
            // $scope.comment = data[0].comment;
            if (data[0].answer == val) {
              // console.log("return True");
              $scope.dis = true;
              return true;
            }
          }
        }
        return false;
      };

      $scope.checkQuestionAnswered = function () {
        // console.log("scope.questions > ", $scope.questions);
        return $scope.questions.filter(function (rdata) {
          // MyService.ConsoleLog("== rdata >>>", rdata);
          var data = $scope.checkQuestion(rdata._id);
          // MyService.ConsoleLog("rdata.questionId  ", data);

          if (data && data.length > 0) return rdata;
        });
      };

      $scope.oneMore = function (valOne) {
        console.log("One more clicked");
        $scope.importance = valOne;
        // console.log("$scope.importance >> ",$scope.importance);
        $scope.submitAnswer();
      };

      $scope.checkImpExists = function (val) {
        // MyService.ConsoleLog("checkImpExists called :");
        var obj = $scope.questions[$scope.currentIndex];

        // $scope.importance = "";
        // console.log("scope.importance >>> ", $scope.importance);
        if (obj) {
          var data = $scope.checkQuestion(obj._id);

          if (data && data[0]) {
            // MyService.ConsoleLog("checkIfExists data>> ", data[0].importance + "==" + val);
            if (data[0].importance == val) {
              // $scope.importance = val;
              // console.log("importance <><><> ", $scope.importance);
              return true;
            }
          } else {
            if ($scope.importance == val) {
              $scope.importance = "";
            }
          }
        }
        return false;
      };

      $scope.initMyAnswer = function () {
        try {
          MyService.ConsoleLog(
            "yogesh here",
            $scope.questions[$scope.currentIndex]._id
          );
          var data = $scope.checkQuestion(
            $scope.questions[$scope.currentIndex]._id
          );
          MyService.ConsoleLog("yogesh here", data);
          if (data && data.length > 0) {
            // MyService.ConsoleLog("called init my ans", $scope.questions[$scope.currentIndex].my_answer);
            // alert('ooo')
            var my_answer = data[0];
            // MyService.ConsoleLog("Final Answers", my_answer);

            $scope.importance = my_answer.importance;
            $scope.answer = my_answer.answer;
            $scope.comment = my_answer.comment;
            MyService.ConsoleLog("Importance", $scope.importance);
            MyService.ConsoleLog(" $scope.answer", $scope.answer);
            MyService.ConsoleLog("#agreedisagree-id" + my_answer.answer);
            MyService.ConsoleLog("#importance-id" + my_answer.importance);
            $scope.quizAns.shortAns = my_answer.shortAns
              ? my_answer.shortAns
              : "";
            $scope.quizAns.mediAns = my_answer.mediumAns
              ? my_answer.mediumAns
              : "";
            $scope.quizAns.longAns = my_answer.longAns ? my_answer.longAns : "";
            $("#agreedisagree-id" + my_answer.answer).prop("checked", true);
            $("#importance-id" + my_answer.importance).prop("checked", true);
          } else {
            $scope.initializeVariables();
          }
        } catch (e) {
          $scope.initializeVariables();
          //MyService.ConsoleLog("Exceptoin: ", e)
        }
      };

      $scope.cachedQuestions = [];
      $scope.flagSkipAnsweredQuestion = false;
      $scope.skipAnswerChagne = function () {
        $scope.showNw = true;
        MyService.ConsoleLog(
          "scope.flagSkipAnsweredQuestion: ",
          $scope.flagSkipAnsweredQuestion
        );
        // MyService.ConsoleLog("$scope.questi", $scope.questions);

        var queIdsN = $scope.tempQue.filter(function (item) {
          if (!item.isEssayQue) {
            return item._id;
          }
        });

        var tempQuesNw = queIdsN.map(function (item) {
          return item._id;
        });
        // MyService.ConsoleLog("tempQuesNw >> ", tempQuesNw);

        window.localStorage.setItem("queIdsN", JSON.stringify(tempQuesNw));

        $scope.temp = $scope.questions;
        // if (!$scope.flagSkipAnsweredQuestion) {
        //   // alert("calld");
        //   // $scope.getMatchQUestions();
        //   // $scope.flagSkipAnsweredQuestion = true;

        // }
        $scope.currentIndex = 0;
        var questions = $scope.checkQuestionAnswered();
        // $rootScope.fiveMatch.length = 0;
        // if (!questions) {
        //   alert("call");
        // }

        $scope.dis = false;
        MyService.ConsoleLog("questions > ", questions.length);
        if (questions && questions.length > 0) {
          if ($scope.flagSkipAnsweredQuestion) {
            $scope.cachedQuestions.length = 0;
            $scope.cachedQuestions = angular.copy($scope.questions);
            for (var i = 0; i < questions.length; i++) {
              var idx = $scope.questions.indexOf(questions[i]);
              if (idx > -1) {
                $scope.questions.splice(idx, 1);
              }
            }
            console.log(
              "questionslength > ",
              $scope.cachedQuestions.length + "==" + questions.length
            );
            MyService.ConsoleLog(
              "$scope.cachedQuestions:",
              $scope.cachedQuestions
            );
            if ($scope.cachedQuestions.length == questions.length) {
              // console.log("questionslength Equal");
              if ($rootScope.fiveMatch.length == 0) {
                $rootScope.fiveMatch = angular.copy($scope.cachedQuestions);
                MyService.ConsoleLog(
                  " $rootScope.fiveMatch one :",
                  $rootScope.fiveMatch
                );
                var lents = $rootScope.fiveMatch.length;
                MyService.ConsoleLog(
                  "Len >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
                  lents
                );
                if (lents >= 5) {
                  // $scope.skipToRes = true;
                  $("#skipMatch").attr("disabled", false);
                }
                $scope.questions = angular.copy($scope.cachedQuestions);
                // $scope.questions = $scope.tempQue;

                $scope.questions.sort(function (x, y) {
                  if (x.matchOrder < y.matchOrder) {
                    return -1;
                  }
                  if (x.matchOrder > y.matchOrder) {
                    return 1;
                  }
                  return 0;
                });
              }
              // Newly Added
              if ($scope.cachedQuestions.length != questions.length) {
                $scope.saveFiveMinAnswers("skip");
              } else {
                // MyService.ConsoleLog("check type of > ", $scope.questions);
                var checkQueAns = $scope.checkQuestionAnswered();
                // MyService.ConsoleLog("checkQueAns > ", checkQueAns);
                if ($scope.tempQue.length == checkQueAns.length) {
                  $scope.isShowCheckBx = true;
                  var message =
                    "Congratulations, you have answered all the questions that your politicians have answered.";

                  var modal = ngDialog.openConfirm({
                    template:
                      "<p>" +
                      message +
                      '</p>\
                       <div class="ngdialog-buttons">\
                           <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">See Match-Ups</button>\
                           <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Edit Answers</button>\
                       </div>',
                    plain: true,
                  });

                  modal.then(
                    function fullfilled(data) {
                      if (data == 0) {
                        $scope.skipToMatch();
                      } else {
                        $scope.flagSkipAnsweredQuestion = false;
                        $scope.showNw = false;
                        $scope.skipAnswerChagne();
                      }
                    },
                    function rejected(data) {
                      //MyService.ConsoleLog("rejected: ", data);
                    }
                  );
                } else {
                  var finalQues = [];
                  finalQues = finalQues.concat($scope.tempQue);

                  // MyService.ConsoleLog("check type of > ", questions);

                  const cLen = questions.length;
                  for (var c = 0; c < cLen; c++) {
                    var idxc = finalQues.indexOf(questions[c]);
                    // MyService.ConsoleLog("idxc > ", idxc);
                    if (idxc > -1) {
                      finalQues.splice(idxc, 1);
                    }
                  }
                  // MyService.ConsoleLog("finalQues > ", finalQues);
                  $scope.questions = finalQues;
                }

                // $scope.questions = angular.copy($scope.cachedQuestions);
                // $scope.questions.sort(function (x, y) {
                //   if (x.matchOrder < y.matchOrder) {
                //     return -1;
                //   }
                //   if (x.matchOrder > y.matchOrder) {
                //     return 1;
                //   }
                //   return 0;
                // });
              } // Newly Added
            } else {
              // alert("esle");
              $scope.updata.comment = "";
              $rootScope.fiveMatch = angular.copy(questions);
              // $rootScope.fiveMatch.filter(function (itm) {
              //   MyService.ConsoleLog("itm nw", itm)
              //   let queLen = $scope.questions.length;
              //   for (var i = 0; i > queLen; i++) {
              //     if (itm.questionId == $scope.questions[i]._id) {
              //       MyService.ConsoleLog("itm", itm);
              //     }
              //   }
              // });
              var lent = $rootScope.fiveMatch.length;
              MyService.ConsoleLog(
                "lent @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
                lent
              );

              if (lent >= 5) {
                // $scope.skipToRes = true;
                $("#skipMatch").attr("disabled", false);
              }
              $scope.saveFiveMinAnswers("skipNew");
            }
          } else {
            // alert("esle2222");
            MyService.ConsoleLog(
              "$scope.cachedQuestions super else",
              $scope.cachedQuestions
            );

            MyService.ConsoleLog("questions super else", questions);
            MyService.ConsoleLog(
              "$scope.questions super else",
              $scope.questions
            );

            // /**
            //  * Check If
            //  */
            if ($scope.cachedQuestions && $scope.cachedQuestions.length > 0) {
              $scope.questions = angular.copy($scope.cachedQuestions);
            } else {
              if (tokenService.getUserRole() == "politician") {
                const ansCheck = $scope.checkQuestionAnswered();
                if (ansCheck.length >= 5) {
                  // $scope.skipToRes = true;
                  $("#skipMatch").attr("disabled", false);
                }
                $scope.questions.sort(function (x, y) {
                  if (x.matchOrder < y.matchOrder) {
                    return -1;
                  }
                  if (x.matchOrder > y.matchOrder) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                $scope.questions = questions;
              }
            }

            // console.log("currentIndex : ", $scope.currentIndex);
          }
        } else {
          // alert("esle333");

          if (!$scope.flagSkipAnsweredQuestion) {
            MyService.ConsoleLog("INside");
            $scope.questions = angular.copy($scope.cachedQuestions);

            var queIdsN = $scope.questions.filter(function (item) {
              if (!item.isEssayQue) {
                return item._id;
              }
            });

            var tempQuesNw = queIdsN.map(function (item) {
              return item._id;
            });
            MyService.ConsoleLog("tempQuesNw >> ", tempQuesNw);

            window.localStorage.setItem("queIdsN", JSON.stringify(tempQuesNw));

            if ($scope.questions[$scope.currentIndex]) {
              MyService.ConsoleLog("ONe MORE");
              // console.log($scope.questions[$scope.currentIndex]._id);
              var data = $scope.checkQuestion(
                $scope.questions[$scope.currentIndex]._id
              );
              console.log("data >> ", data);
              if (data && data[0]) {
                $scope.updata.comment = data[0].comment;
                $scope.importance = data[0].importance; //Added
              }
            } else {
              // $scope.flagSkipAnsweredQuestion = false;
              // alert("inside");
              MyService.ConsoleLog("$scope.temp", $scope.temp);
              $scope.questions = $scope.temp;
            }
          }
        }
      };

      // $scope.commenta=function()
      $scope.checkQuestions = function (rdata, val) {
        MyService.ConsoleLog("rdata :", rdata);
        return rdata.filter(function (rdata) {
          // MyService.ConsoleLog(rdata.question + "==" + val);
          if (rdata.questionId == val) return rdata;
        });
      };
      //  Commented by vishal on 1 st November
      // $scope.postAnswer = function(postData, val) {
      //   MyService.ConsoleLog("postData: ", postData);
      //   // MyService.ConsoleLog("Index <><><", $rootScope.fiveMatch.indexOf(postData));
      //   var ndata = $scope.checkQuestion(postData.questionId);
      //   // MyService.ConsoleLog("ndata >", ndata);
      //   // MyService.ConsoleLog($rootScope.fiveMatch);
      //   /*
      //   if (ndata.length == 0) {
      //     $rootScope.fiveMatch.push(postData);
      //     MyService.ConsoleLog("Data Pushed");
      //   }
      //   */
      //   $scope.questions[$scope.currentIndex]["my_answer"] = {
      //     _id: postData.id,
      //     created: new Date(),
      //     comment: postData.comment,
      //     answer: postData.answer,
      //     importance: postData.importance
      //   };

      //   var chkData = $scope.checkQuestions($rootScope.fiveMatch, postData.questionId);
      //   MyService.ConsoleLog("chkData<><><>", chkData);
      //   if (ndata.length == 0) {
      //     postData.categories = $scope.questions[$scope.currentIndex]["categories"];
      //     postData.content = $scope.questions[$scope.currentIndex]["content"];
      //     postData._id = $scope.questions[$scope.currentIndex]["_id"];
      //     if (chkData && chkData.length == 0) {
      //       // MyService.ConsoleLog("postData1if<><><",postData);
      //       $rootScope.fiveMatch.push(postData);
      //       MyService.ConsoleLog("FiveMatch::::", $rootScope.fiveMatch);

      //       var fiveLen = $rootScope.fiveMatch.length;

      //       MyService.ConsoleLog("fiveLen:::", fiveLen);

      //       if (fiveLen >= 5) {
      //         // $scope.skipToRes = true;
      //         $('#skipMatch').attr('disabled', false);

      //       }

      //     }
      //     // MyService.ConsoleLog("Data Pushed");
      //   } else {
      //     // console.log("chkData >> ", chkData);
      //     // console.log("chkData.length >> ", chkData.length);
      //     if (chkData && chkData.length == 0) {
      //       postData.categories = $scope.questions[$scope.currentIndex]["categories"];
      //       postData.content = $scope.questions[$scope.currentIndex]["content"];
      //       postData._id = $scope.questions[$scope.currentIndex]["_id"];
      //       MyService.ConsoleLog("postData<><><", postData);
      //       $rootScope.fiveMatch.push(postData);

      //       var fiveLen = $rootScope.fiveMatch.length;

      //       if (fiveLen >= 5) {
      //         $('#skipMatch').attr('disabled', false);
      //         // $scope.skipToRes = true;

      //       }

      //     }
      //   }
      //   // MyService.ConsoleLog($rootScope.fiveMatch);
      //   window.localStorage.setItem("fiveMatch", JSON.stringify($rootScope.fiveMatch));
      //   if (val == "submit") {
      //     $scope.currentIndex = $scope.currentIndex + 1;
      //     // MyService.ConsoleLog(window.localStorage.getItem("fiveMatch"));
      //     $scope.newQuestion();
      //   }

      // };

      $scope.saveAnswersOneByOne = function (params) {
        MyService.ConsoleLog("post params >>>>>:", params);

        if (params && params != "") {
          MyService.postAnswer(params).then(
            function (response) {
              try {
                document.getElementById("txtReason").value = "";
              } catch (err) {
                console.log("err", err);
              }

              if (response.data.success) {
                try {
                  if (params.id) {
                    $scope.questions[$scope.currentIndex]["my_answer"] = {
                      _id: postData.id,
                      created: new Date(),
                      comment: params.comment,
                      questionId: params.questionId,
                      answer: params.answer,
                      importance: params.importance,
                    };
                  } else {
                    $scope.questions[$scope.currentIndex]["my_answer"] = {
                      _id: response.data.data._id,
                      created: response.data.data.created,
                      questionId: response.data.data.question,
                      comment: response.data.data.comment,
                      answer: response.data.data.answer,
                      importance: response.data.data.importance,
                    };
                  }
                } catch (e) {
                  //MyService.ConsoleLog("Exceptoin: ", e);
                }
                if (response.data.data.question) {
                  $scope.AllAnsweredQuestions.push(response.data.data);
                }

                if (params.id) {
                  growl.success("Answer updated successfully", {
                    ttl: 5000,
                  });
                } else {
                  growl.success("Answered successfully", {
                    ttl: 5000,
                  });
                }
                $scope.currentIndex = $scope.currentIndex + 1;

                // MyService.ConsoleLog(window.localStorage.getItem("fiveMatch"));
                $scope.newQuestion();
              } else {
                growl.error("Error: " + response.data.error);
              }
            },
            function (err) {
              // growl.error("Something went wrong.");
            }
          );
        }
      };

      //  Commented by vishal on 1st November
      // $scope.submitAnswer = function() {
      //   MyService.ConsoleLog("$scope.comment >>>>", $scope.updata.comment);

      //   var data = $scope.checkQuestion($scope.questions[$scope.currentIndex]._id);
      //   if (data && data[0]) {
      //     MyService.ConsoleLog("getMatchQUestions >>> ", data[0]);
      //     if (data[0].answer != "") {
      //       $scope.answer = data[0].answer;
      //     }
      //     if (data[0].importance != "") {
      //       $scope.importance = data[0].importance;
      //     }
      //     if (data[0].comment != "") {
      //       $scope.comment = data[0].comment;
      //     }

      //   }
      //   document.getElementById("txtReason").value = '';
      //   document.getElementById("exampleFormControlTextarea1").value = '';
      //   MyService.ConsoleLog("answer :" + $scope.answer);
      //   MyService.ConsoleLog("importance :" + $scope.importance);
      //   if ($scope.answer == null || $scope.answer == '') {
      //     var message = "Please select answer first.";
      //     var modal = ngDialog.openConfirm({
      //       template: '<p>' + message + '</p>\
      //                     <div class="ngdialog-buttons">\
      //                         <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
      //                     </div>',
      //       plain: true
      //     });
      //     modal.then(function fullfilled(data) {
      //       MyService.ConsoleLog("fullfilled: ", data);
      //     }, function rejected(data) {
      //       MyService.ConsoleLog("rejected: ", data);
      //     });
      //     $("html, body").animate({
      //       scrollTop: 0
      //     }, "slow");
      //     return;
      //   }
      //   //MyService.ConsoleLog("**** ", $scope.importance);
      //   if ($scope.importance == null || $scope.importance == '') {
      //     var message = "You need to choose an importance level.  Should they fight hard for this can they use it to trade for other things you care about more?";
      //     var modal = ngDialog.openConfirm({
      //       template: '<p>' + message + '</p>\
      //                     <div class="ngdialog-buttons">\
      //                         <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
      //                     </div>',
      //       plain: true
      //     });
      //     modal.then(function fullfilled(data) {
      //       //MyService.ConsoleLog("fullfilled: ", data);
      //     }, function rejected(data) {
      //       //MyService.ConsoleLog("rejected: ", data);
      //     });
      //     $("html, body").animate({
      //       scrollTop: 0
      //     }, "slow");
      //     return;
      //   }
      //   $("html, body").animate({
      //     scrollTop: 0
      //   }, "slow");
      //   var postData = {
      //     questionId: $scope.questions[$scope.currentIndex]._id,
      //     importance: $scope.importance,
      //     answer: $scope.answer,
      //     comment: $scope.updata.comment,
      //     reason: $scope.updata.reason
      //   };
      //   $scope.postAnswer(postData, 'submit');
      // };

      //

      $scope.postAnswer = function (postData, val) {
        MyService.ConsoleLog("postData: ", postData);
        // MyService.ConsoleLog("Index <><><", $rootScope.fiveMatch.indexOf(postData));

        var ndata = $scope.checkQuestion(postData.questionId);
        MyService.ConsoleLog("ndata >", ndata);
        // MyService.ConsoleLog($rootScope.fiveMatch);

        if (ndata.length > 0) {
          var vdx = $scope.AllAnsweredQuestions.indexOf(ndata[0]);
          if (vdx > -1) {
            // console.log("VDX > ", vdx);
            $scope.AllAnsweredQuestions[vdx]["answer"] = postData.answer;
            $scope.AllAnsweredQuestions[vdx]["importance"] =
              postData.importance;
            $scope.AllAnsweredQuestions[vdx]["comment"] = postData.comment;
            // console.log("AS : ", $scope.AllAnsweredQuestions[vdx]);
          }
        }

        $scope.questions[$scope.currentIndex]["my_answer"] = {
          _id: postData._id,
          created: new Date(),
          id: postData.id,
          reason: postData.reason,
          comment: postData.comment,
          answer: postData.answer,
          importance: postData.importance,
        };

        var chkData = $scope.checkQuestions(
          $rootScope.fiveMatch,
          postData.questionId
        );
        MyService.ConsoleLog("chkData<><><>", chkData);
        $scope.dis = false;

        postData.categories =
          $scope.questions[$scope.currentIndex]["categories"];
        postData.content = $scope.questions[$scope.currentIndex]["content"];
        postData._id = $scope.questions[$scope.currentIndex]["_id"];

        if (chkData && chkData.length == 0) {
          // alert("one");
          // MyService.ConsoleLog("postData1if<><><",postData);
          $rootScope.fiveMatch.push(postData);
          // MyService.ConsoleLog("Final Five match <><><><><> ", $rootScope.fiveMatch);
          window.localStorage.setItem(
            "fiveMatch",
            JSON.stringify($rootScope.fiveMatch)
          );
          if (val == "submit" && val != "skip") {
            if ($scope.UserId && $scope.UserId != "") {
              if (postData.questionId && postData.questionId != "") {
                $scope.saveAnswersOneByOne(postData);
                postData = "";
              }
            } else {
              $scope.currentIndex = $scope.currentIndex + 1;
              MyService.ConsoleLog(
                "Else >> ",
                window.localStorage.getItem("fiveMatch")
              );
              $scope.importance = angular.copy("");
              growl.success("Answered successfully", {
                ttl: 1000,
              });
              $scope.newQuestion();
            }
          }

          var fiveLen = $rootScope.fiveMatch.length;

          MyService.ConsoleLog(
            "FiveMatch:  $scope.currentIndex ::: ",
            $scope.currentIndex
          );
          $scope.importance = angular.copy("");
          if (fiveLen >= 5) {
            // $scope.skipToRes = true;
            $("#skipMatch").attr("disabled", false);
          }
        } else {
          // alert("two");
          var idx = $rootScope.fiveMatch.indexOf(chkData[0]);
          // MyService.ConsoleLog(
          //   "currentIndex <><><> ",
          //   JSON.parse(window.localStorage.getItem("fiveMatch"))
          // );
          $rootScope.fiveMatch[idx] = postData;
          $scope.importance = angular.copy("");
          window.localStorage.setItem(
            "fiveMatch",
            JSON.stringify($rootScope.fiveMatch)
          );
          // MyService.ConsoleLog("val >>>> ", val);
          if (val == "submit" && val != "skip") {
            if ($scope.UserId && $scope.UserId != "") {
              if (
                (postData.reason && postData.reason != "") ||
                (postData.id && postData.id != "")
              ) {
                $scope.saveAnswersOneByOne(postData);
                postData = "";
              }
            } else {
              $scope.currentIndex = $scope.currentIndex + 1;
              $scope.importance = angular.copy("");
              MyService.ConsoleLog(
                "currentIndex <><><> 4",
                window.localStorage.getItem("fiveMatch")
              );
              $scope.newQuestion();
            }
          }
        }

        // MyService.ConsoleLog("Final Five match <><><><><> ", $rootScope.fiveMatch);
        // window.localStorage.setItem("fiveMatch", JSON.stringify($rootScope.fiveMatch));
        // if (val == "submit") {
        //   $scope.currentIndex = $scope.currentIndex + 1;
        //   // MyService.ConsoleLog(window.localStorage.getItem("fiveMatch"));
        //   $scope.newQuestion();
        // }
      };

      /**
       * @description Funtions is use to save guest user's answers with unique id.
       */
      var ques = [];
      $scope.guest = function (guestData) {
        //MyService.ConsoleLog("guestData: ", guestData);
        try {
          var my_answer = $scope.questions[$scope.currentIndex].my_answer;
          if (
            my_answer.importance != $scope.importance ||
            my_answer.answer != $scope.answer ||
            my_answer.comment != $scope.comment
          ) {
            //MyService.ConsoleLog("Answer changed");
            guestData.id = my_answer._id;
            guestData.reason = $scope.reason;
          } else {
            //MyService.ConsoleLog("Answer not changed");
            return; //don't do anything
          }
        } catch (e) {
          //MyService.ConsoleLog("Exceptoin: ", e);
        }

        var ndatas = $scope.checkQuestion(guestData.questionId);
        MyService.ConsoleLog("ndata >", ndatas);
        // MyService.ConsoleLog($rootScope.fiveMatch);

        if (ndatas.length > 0) {
          var vdx = $scope.AllAnsweredQuestions.indexOf(ndatas[0]);
          if (vdx > -1) {
            // console.log("VDX > ", vdx);
            $scope.AllAnsweredQuestions[vdx]["answer"] = guestData.answer;
            $scope.AllAnsweredQuestions[vdx]["importance"] =
              guestData.importance;
            $scope.AllAnsweredQuestions[vdx]["comment"] = guestData.comment;
            // console.log("AS : ", $scope.AllAnsweredQuestions[vdx]);
          }
        }

        $scope.questions[$scope.currentIndex]["my_answer"] = {
          _id: guestData.questionId,
          created: new Date(),
          id: guestData.id,
          reason: guestData.reason,
          comment: guestData.comment,
          answer: guestData.answer,
          importance: guestData.importance,
          categories: $scope.questions[$scope.currentIndex]["categories"],
          content: $scope.questions[$scope.currentIndex]["content"],
        };

        guestData.content = $scope.questions[$scope.currentIndex]["content"];
        guestData.categories =
          $scope.questions[$scope.currentIndex]["categories"];

        var chkGuestAns = $scope.checkQuestions(
          $rootScope.guestUserData,
          guestData.questionId
        );

        // guestData.categories = $scope.questions[$scope.currentIndex]["categories"];
        // guestData.content = $scope.questions[$scope.currentIndex]["content"];
        guestData._id = $scope.questions[$scope.currentIndex]["_id"];

        if (chkGuestAns && chkGuestAns.length == 0) {
          $rootScope.guestUserData.push(guestData);
          window.localStorage.setItem(
            "fiveMatch",
            JSON.stringify($rootScope.guestUserData)
          );
          MyService.ConsoleLog("guestUserData >>>", $rootScope.guestUserData);
        }
        console.log("guestData > ", guestData);

        MyService.guestAnswers(guestData).then(
          function (response) {
            if (response.data.success) {
              try {
                if (guestData.id) {
                  $scope.questions[$scope.currentIndex]["my_answer"] = {
                    _id: guestData.id,
                    created: new Date(),
                    comment: guestData.comment,
                    answer: guestData.answer,
                    importance: guestData.importance,
                  };
                  MyService.ConsoleLog("Inside IF >>", $scope.questions);
                } else {
                  $scope.questions[$scope.currentIndex]["my_answer"] = {
                    _id: guestData.questionId,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance,
                  };

                  if ($scope.questions[$scope.currentIndex].my_answer) {
                    MyService.ConsoleLog(
                      "Inside ELSE >>",
                      $scope.questions[$scope.currentIndex]
                    );
                    ques.push($scope.questions[$scope.currentIndex]);
                    if ($scope.questions.length == ques.length) {
                      // window.localStorage.removeItem('quesData');
                      MyService.ConsoleLog("set it now", ques);
                      // window.localStorage.setItem('quesData', JSON.stringify(ques));
                    }
                  }
                }
              } catch (e) {
                //MyService.ConsoleLog("Exceptoin: ", e);
              }
              if (guestData.id) {
                growl.success("Answer updated successfully", {
                  ttl: 800,
                });
              } else {
                growl.success("Answered successfully", {
                  ttl: 800,
                });
              }
              $scope.dis = false;
              $scope.currentIndex = $scope.currentIndex + 1;
              var fiveLen = $rootScope.guestUserData.length;

              $scope.importance = angular.copy("");
              if (fiveLen >= 5) {
                // $scope.skipToRes = true;
                $("#skipMatch").attr("disabled", false);
              }
              $scope.newQuestion();
            } else {
              growl.error("Error: " + response.data.error);
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );
      };

      /**
       * @description Function is to set answer object.
       */

      var guestSessionId;
      $rootScope.guestUserData = [];

      $scope.submitAnswer = function () {
        MyService.ConsoleLog("$scope.answer", $scope.answer);
        MyService.ConsoleLog("$scope.comment <>>>>", $scope.updata);
        MyService.ConsoleLog("$scope.answer <>>>>", $scope.answer);
        // return;
        var isEssayQue = $scope.questions[$scope.currentIndex].isEssayQue;
        var data = $scope.checkQuestion(
          $scope.questions[$scope.currentIndex]._id
        );
        if (!isEssayQue) {
          var token = apiCall.getToken();
          var answerId = "";
          var answerReason = "";

          if (data && data[0]) {
            if (data[0] && data[0]._id) {
              answerId = data[0]._id;
            }
            if (data[0] && data[0]._id) {
              if ($scope.updata && $scope.updata.reason) {
                answerReason = $scope.updata.reason;
              }
              if ($scope.importance == null || $scope.importance == "") {
                $scope.importance = data[0].importance; // Added
              }
              if ($scope.answer == null || $scope.answer == "") {
                $scope.answer = data[0].answer; // Added
              }
            }
          }

          MyService.ConsoleLog("answer :" + $scope.answer);
          MyService.ConsoleLog("importance :" + $scope.importance);
          if ($scope.answer == null || $scope.answer == "") {
            var message = "Please select answer first.";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
                       <div class="ngdialog-buttons">\
                           <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                       </div>',
              plain: true,
            });
            modal.then(
              function fullfilled(data) {
                MyService.ConsoleLog("fullfilled: ", data);
              },
              function rejected(data) {
                MyService.ConsoleLog("rejected: ", data);
              }
            );
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            return;
          }
          //MyService.ConsoleLog("**** ", $scope.importance);
          if ($scope.importance == null || $scope.importance == "") {
            var message =
              "You need to choose an importance level.  Should they fight hard for this can they use it to trade for other things you care about more?";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
                       <div class="ngdialog-buttons">\
                           <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                       </div>',
              plain: true,
            });
            modal.then(
              function fullfilled(data) {
                //MyService.ConsoleLog("fullfilled: ", data);
              },
              function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              }
            );
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            return;
          }

          // document.getElementById("txtReason").value = '';
          // document.getElementById("exampleFormControlTextarea1").value = '';

          if (
            tokenService &&
            tokenService.getUserRole() == "admin" &&
            $scope.isUser &&
            $scope.isUser.selected &&
            $scope.isUser.selected != ""
          ) {
            var postData = {
              questionId: $scope.questions[$scope.currentIndex]._id,
              importance: $scope.importance,
              answer: $scope.answer,
              comment: $scope.updata.comment,
              frontRunnerId: $scope.isUser.selected._id,
            };
          } else {
            var postData = {
              questionId: $scope.questions[$scope.currentIndex]._id,
              importance: $scope.importance,
              answer: $scope.answer,
              comment: $scope.updata.comment,
            };
          }

          if (answerId != "") {
            postData.id = answerId;
            MyService.ConsoleLog("answerId :", answerId);
            postData.reason = answerReason;
            // console.log($rootScope.fiveMatch[$scope.currentIndex]);
            if ($rootScope.fiveMatch[$scope.currentIndex]) {
              if (
                $rootScope.fiveMatch[$scope.currentIndex].answer !=
                  $scope.answer ||
                $rootScope.fiveMatch[$scope.currentIndex].importance !=
                  $scope.importance ||
                $rootScope.fiveMatch[$scope.currentIndex].comment ==
                  $scope.updata.comment
              ) {
                // if ($scope.updata.reason == null || $scope.updata.reason == '') {
                //   var message = "You need to add the reason for changing the answer.";
                //   var modal = ngDialog.openConfirm({
                //     template: '<p>' + message + '</p>\
                //            <div class="ngdialog-buttons">\
                //                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                //            </div>',
                //     plain: true
                //   });
                //   modal.then(function fullfilled(data) {
                //     //MyService.ConsoleLog("fullfilled: ", data);
                //   }, function rejected(data) {
                //     //MyService.ConsoleLog("rejected: ", data);
                //   });
                //   $("html, body").animate({
                //     scrollTop: 0
                //   }, "slow");
                //   return;
                // }
              }
            }
          }
          $("html, body").animate(
            {
              scrollTop: $("#DisplayQ1").offset().top - 80,
            },
            "slow"
          );
          // console.log("postData > ", postData);

          if (!token) {
            var guestObj;
            if (!guestSessionId) {
              $scope.finalSessionId = "";
              MyService.ConsoleLog("Inside", guestSessionId);

              /**
               * @description Create unique Id for each guest user.
               */
              var date = new Date();
              var sessionId = date.getTime();
              $scope.finalSessionId = sessionId;
              MyService.ConsoleLog("sessionId >>", $scope.finalSessionId);
              window.localStorage.setItem("sessionId", $scope.finalSessionId);
            }

            guestSessionId = $scope.finalSessionId;

            /**
             * @description Guest user answer object with the unique id.
             */

            if (
              $scope.questions[$scope.currentIndex].showInMatch &&
              $scope.questions[$scope.currentIndex].showInMatch == "YES"
            ) {
              guestObj = {
                questionId: $scope.questions[$scope.currentIndex]._id,
                importance: $scope.importance,
                answer: $scope.answer,
                comment: $scope.updata.comment,
                gSessionId: guestSessionId,
                quizType: "FiveMin Quiz",
                matchOrder: $scope.questions[$scope.currentIndex].matchOrder,
              };
            } else if (
              $scope.questions[$scope.currentIndex].showInMatch &&
              $scope.questions[$scope.currentIndex].showInMatch == "NO"
            ) {
              guestObj = {
                questionId: $scope.questions[$scope.currentIndex]._id,
                importance: $scope.importance,
                answer: $scope.answer,
                comment: $scope.updata.comment,
                gSessionId: guestSessionId,
                quizType: "Regular Quiz",
              };
            }
            $scope.guest(guestObj);
          } else {
            $scope.postAnswer(postData, "submit");
          }
        } else {
          var ansId = "";
          if (data[0] && data[0]._id) {
            ansId = data[0]._id;
          }

          if (
            $scope.quizAns.shortAns == "" &&
            $scope.quizAns.mediAns == "" &&
            $scope.quizAns.longAns == ""
          ) {
            var message = "You need to write at least one answer";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
                       <div class="ngdialog-buttons">\
                           <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="closeThisDialog(0)">OK</button>\
                       </div>',
              plain: true,
            });
            modal.then(
              function fullfilled(data) {
                //MyService.ConsoleLog("fullfilled: ", data);
              },
              function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              }
            );
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            return;
          } // End of question checking
          var quizParams = {
            shortAns: $scope.quizAns.shortAns,
            mediAns: $scope.quizAns.mediAns,
            longAns: $scope.quizAns.longAns,
          };
          if (
            tokenService &&
            tokenService.getUserRole() == "admin" &&
            $scope.isUser &&
            $scope.isUser.selected &&
            $scope.isUser.selected != ""
          ) {
            quizParams.frontRunnerId = $scope.isUser.selected._id;
          }

          if (ansId && ansId != "") {
            quizParams.id = ansId;
          } else {
            quizParams.questionId = $scope.questions[$scope.currentIndex]._id;
            // quizParams
          }

          MyService.ConsoleLog(" check quizParams >> ", quizParams);
          // return;
          $rootScope.fiveMatch.push(quizParams);
          // MyService.ConsoleLog("Final Five match <><><><><> ", $rootScope.fiveMatch);
          window.localStorage.setItem(
            "fiveMatch",
            JSON.stringify($rootScope.fiveMatch)
          );
          MyService.storeEssayAnswer(quizParams).then(
            function (APIResponse) {
              MyService.ConsoleLog("APIResponse >> ", APIResponse);
              if (APIResponse.data.success) {
                $scope.questions[$scope.currentIndex]["my_answer"] = {
                  _id: ansId ? ansId : APIResponse.data.data._id,
                  created: new Date(),
                  shortAns: APIResponse.data.data.shortAns,
                  mediAns: APIResponse.data.data.mediumAns,
                  longAns: APIResponse.data.data.longAns,
                  questionId: APIResponse.data.data.question,
                };
              }
              $scope.currentIndex = $scope.currentIndex + 1;
              $scope.newQuestion();
            },
            function (err) {
              MyService.ConsoleLog("Error >> ", err);
            }
          );
        } // End of isEssayQue
      };

      $rootScope.$on("question.answered", function (evt, data) {
        MyService.ConsoleLog("on question.answered: ", data);
        if ($scope.questions[$scope.currentIndex]._id == data.questionId) {
          $scope.initializeVariables();
          $scope.skipQuestion();
        }
      });

      // Quick link for show match

      $scope.skipToMatch = function () {
        if ($scope.UserId && $scope.UserId != "") {
          // alert("if");
          MyService.ConsoleLog("$scope.temoQue", $scope.isQuestions);
          MyService.ConsoleLog("welcome to quick link :", $rootScope.fiveMatch);
          MyService.ConsoleLog(
            "welcome to quick link :",
            $rootScope.fiveMatch + "     " + $scope.questions.length
          );

          const ansQ = $scope.checkQuestionAnswered();
          MyService.ConsoleLog("ansQ >> ", ansQ.length);
          MyService.ConsoleLog(
            "$scope.isQuestions.length >> ",
            $scope.isQuestions.length
          );

          if (ansQ.length != $scope.isQuestions.length) {
            if ($scope.isQuestions && $scope.isQuestions.length > 0) {
              $scope.questions.filter(function (que) {
                var newQue = {};
                if (que && que.categories && que.categories.length > 0) {
                  newQue.content = que.content;
                  newQue.matchOrder = que.matchOrder;
                  newQue.categories = que.categories;
                  newQue.answer = 0;
                  newQue.importance = 0;
                  newQue.comment = "";
                  newQue.questionId = que._id;
                  newQue.question = que._id;
                  $rootScope.fiveMatch.push(newQue);
                }
              });
              MyService.ConsoleLog(
                "$rootScope.fiveMatch ",
                $rootScope.fiveMatch.length
              );
              // return;
              window.localStorage.setItem(
                "fiveMatch",
                JSON.stringify($rootScope.fiveMatch)
              );
              // setTimeout(function () {
              $location.path("/fiveCompare/skip_to_result");
              // }, 200);
            }
          } else {
            $location.path("/fiveCompare/skip_to_result");
          }

          // if ($rootScope.fiveMatch.length != $scope.cachedQuestions) {
          //   MyService.ConsoleLog("$cope catch que", $scope.cachedQuestions);
          //   const fivLen = $rootScope.fiveMatch.length;
          //   for (var f = 0; f < fivLen; f++){
          //     $scope.cachedQuestions.filter(function (ans) {
          //       if($rootScope.fiveMatch[f].questionId !=)
          //     });
          //   }
          // }

          // return;

          // window.localStorage.setItem("fiveMatch", JSON.stringify($rootScope.fiveMatch));
          // $scope.saveFiveMinAnswers("RedirectNew");
        } else {
          // alert("else");
          $scope.showZipCode = true;
        }
        // $rootScope.fiveMatch.length=0;
      };

      $scope.getMatchQUestions = function () {
        // MyService.ConsoleLog("  $rootScope.fiveMatch :>", $rootScope.fiveMatch);
        MyService.getfivematch().then(
          function (payload) {
            MyService.ConsoleLog("getMatchQUestions", payload);
            if (payload.data.success) {
              $scope.questions = payload.data.data;
              $scope.tempQue = payload.data.data;

              $scope.isQuestions = angular.copy(payload.data.data);

              $scope.questions.sort(function (x, y) {
                if (x.matchOrder < y.matchOrder) {
                  return -1;
                }
                if (x.matchOrder > y.matchOrder) {
                  return 1;
                }
                return 0;
              });
              $scope.queLen = $scope.questions.length;

              MyService.ConsoleLog("This is length ::", $scope.queLen);

              if (
                $scope.UserId &&
                $scope.UserId != "" &&
                tokenService.getUserRole() == "politician"
              ) {
                MyService.ConsoleLog(
                  "tokenService.getUserRole()",
                  tokenService.getUserRole()
                );
              } else {
                $scope.flagSkipAnsweredQuestion = true;
              }

              var ansQuestion = $scope.checkQuestionAnswered();
              MyService.ConsoleLog(
                "ansQuestion >> ",
                ansQuestion.length + "   " + $scope.queLen
              );
              if (ansQuestion.length != $scope.queLen) {
                $scope.skipAnswerChagne();
              } else if (ansQuestion.length >= 5) {
                var ansArr = [];
                $scope.questions.filter(function (itm) {
                  var isAns = $scope.checkQuestion(itm._id);
                  // MyService.ConsoleLog("itm", itm);
                  MyService.ConsoleLog("isAns >> ", isAns);
                  if (isAns && isAns.length > 0) {
                    var smlObj = {};
                    smlObj = isAns[0];
                    smlObj.questionId = itm._id;
                    smlObj.categories = itm.categories;
                    smlObj.content = itm.content;
                    smlObj.matchOrder = itm.matchOrder;
                    ansArr.push(smlObj);
                  }
                });
                $rootScope.fiveMatch = angular.copy(ansArr);
                window.localStorage.setItem(
                  "fiveMatch",
                  JSON.stringify($rootScope.fiveMatch)
                );
                $("#skipMatch").attr("disabled", false);
                if (ansQuestion.length == $scope.queLen) {
                  $scope.skipAnswerChagne();
                }
              }
            } else {
              alert("Something went wrong. Please try after some time");
              $location.path("/welcome");
            }
          },
          function (errpayload) {
            alert("Something went wrong. Please try after some time");
            $location.path("/welcome");
          }
        );
      };

      $scope.skipQuestion = function () {
        MyService.ConsoleLog("* skipQuestion *");
        var skip = function () {
          // MyService.ConsoleLog("skip");
          var data = $scope.checkQuestion(
            $scope.questions[$scope.currentIndex]._id
          );

          if (data && data[0]) {
            MyService.ConsoleLog("getMatchQUestions >>> ", data[0]);
            if (data[0].answer && data[0].answer != "") {
              $scope.answer = data[0].answer;
            }
            if (data[0].importance && data[0].importance != "") {
              $scope.importance = data[0].importance;
            }
            if (data[0].comment && data[0].comment != "") {
              $scope.updata.comment = data[0].comment;
            }
          }
          // $scope.dis=false;
          // MyService.ConsoleLog("answer : ", $scope.answer);
          if ($scope.answer && $scope.answer != "") {
            var postData = {
              questionId: $scope.questions[$scope.currentIndex]._id,
              importance: $scope.importance,
              answer: $scope.answer,
              comment: $scope.updata.comment,
              categories: $scope.questions[$scope.currentIndex]["categories"],
              content: $scope.questions[$scope.currentIndex]["content"],
            };
            // console.log("postData >> ", postData);
            // return;
            if (
              $scope.answer &&
              $scope.answer != "" &&
              $scope.importance &&
              $scope.importance != ""
            ) {
              $scope.postAnswer(postData, "skip");
            } else {
              postData.answer = 0;
              postData.importance = 0;
              $rootScope.fiveMatch.push(postData);
            }

            //
          } else {
            var pData = {
              questionId: $scope.questions[$scope.currentIndex]._id,
              importance: 0,
              answer: 0,
              comment: $scope.updata.comment,
              categories: $scope.questions[$scope.currentIndex]["categories"],
              content: $scope.questions[$scope.currentIndex]["content"],
              matchOrder: $scope.questions[$scope.currentIndex].matchOrder,
              // postData._id = $scope.questions[$scope.currentIndex]["_id"];
            };
            $rootScope.fiveMatch.push(pData);
            if (!$scope.UserId) {
              $rootScope.guestUserData.push(pData);
              window.localStorage.setItem(
                "fiveMatch",
                JSON.stringify($rootScope.guestUserData)
              );
            } else {
              window.localStorage.setItem(
                "fiveMatch",
                JSON.stringify($rootScope.fiveMatch)
              );
            }
          }

          MyService.ConsoleLog(
            "<< $rootScope.fiveMatch >> ",
            $rootScope.fiveMatch
          );
          // console.log($("#DisplayQ1").offset());
          $("html, body").animate(
            {
              scrollTop: $("#DisplayQ1").offset().top - 80,
            },
            "slow"
          );

          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.initializeVariables();
            $scope.currentIndex++;
          } else {
            MyService.ConsoleLog("final question : " + $scope.questions.length);
            $scope.fiveMinuteMatchDone();
          }
          /*
        $timeout(function() {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
        */
        };

        MyService.ConsoleLog("skip answer<><><><<>", $scope.answer);
        if ($scope.answer != null) {
          var show_answer_modal = true;
          try {
            var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];

            /*for (var key in my_answer) {
            if(my_answer[])
          }*/
            if (
              my_answer.importance == $scope.importance &&
              my_answer.answer == $scope.answer &&
              my_answer.comment == $scope.updata.comment
            )
              show_answer_modal = false;
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin : ", e);
          }
          // MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
          if (show_answer_modal) {
            var message =
              "You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
              <div class="ngdialog-buttons">\
                  <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">Skip</button>\
                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
              </div>',
              plain: true,
              closeByDocument: false,
            });

            modal.then(
              function fullfilled(data) {
                //submit answer
                if (data == 0) {
                  skip();
                } else {
                  $scope.submitAnswer();
                }
              },
              function rejected(data) {
                //skip the answer
                skip();
              }
            );
          } else {
            skip();
          }
        } else {
          skip();
        }
      };

      // Commented by vishal on 1st November

      // $scope.prevQuestion = function() {
      //   MyService.ConsoleLog("** prevQuestion **");

      //   if ($scope.currentIndex > 0) {
      //     $("html, body").animate({
      //       scrollTop: 0
      //     }, "slow");
      //     $scope.currentIndex--;
      //     if ($scope.digDeeperMatch != true) {
      //       if (window.localStorage.getItem("fiveMinMatch")) {
      //         //MyService.ConsoleLog("here");
      //         $scope.getFiveMinData = [];
      //         $scope.getFiveMinData.length = 0;
      //         if ($scope.flagSkipAnsweredQuestion) {
      //           $scope.getFiveMinData = $scope.cachedQuestions;
      //         } else {
      //           $scope.getFiveMinData = JSON.parse(window.localStorage.getItem("fiveMinMatch"));
      //         }

      //         //MyService.ConsoleLog("Data : ", $scope.getFiveMinData);
      //         for (var i = 0; i < $scope.getFiveMinData.length; i++) {
      //           MyService.ConsoleLog("for i : " + i + " currentIndex : " + $scope.currentIndex);
      //           if (i == $scope.currentIndex) {
      //             MyService.ConsoleLog("index matched", $scope.getFiveMinData[i]);
      //             // $scope.answer = $scope.getFiveMinData[i].answer;
      //             // $scope.importance = $scope.getFiveMinData[i].importance;
      //             // $scope.comment = $scope.getFiveMinData[i].comment;
      //             // $timeout(function() {
      //             //   $scope.$apply();
      //             // });

      //           }
      //         }
      //       }
      //     }
      //     /*
      //     $timeout(function() {
      //       $location.hash('DisplayQ');
      //       $anchorScroll();
      //       $scope.$apply();
      //     }, 100);
      //     */
      //   } else if ($scope.currentIndex == 0) {
      //     if ($scope.currBackgroundIndex > 0) {
      //       $scope.currBackgroundIndex--;
      //       $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
      //     }
      //   }
      // };

      //  prevQuestions
      $scope.prevQuestion = function () {
        MyService.ConsoleLog("** prevQuestion **");

        if ($scope.currentIndex > 0) {
          $("html, body").animate(
            {
              scrollTop: $("#DisplayQ1").offset().top - 80,
            },
            "slow"
          );
          $scope.currentIndex--;
          // console.log("Current <><>,", $scope.currentIndex);
          $scope.updata.comment = "";
          // console.log("C digDeeper : ",$scope.digDeeperMatch);
          if ($scope.digDeeperMatch != true) {
            // console.log("digDeeper");
            // console.log(window.localStorage.getItem("fiveMatch"));
            if (window.localStorage.getItem("fiveMatch")) {
              // MyService.ConsoleLog("here");
              $scope.getFiveMinData = [];
              $scope.getFiveMinData.length = 0;
              if ($scope.flagSkipAnsweredQuestion) {
                // console.log("IF <> ", $scope.flagSkipAnsweredQuestion, $scope.cachedQuestions);

                $scope.getFiveMinData = $scope.cachedQuestions;
              } else {
                console.log("Else ");
                $scope.getFiveMinData = JSON.parse(
                  window.localStorage.getItem("fiveMatch")
                );
              }

              // MyService.ConsoleLog("Data : ", $scope.getFiveMinData);
              var ansLen = $scope.getFiveMinData.length;
              for (var i = 0; i < ansLen; i++) {
                MyService.ConsoleLog(
                  "for i : " + i + " currentIndex : " + $scope.currentIndex
                );

                if (i == $scope.currentIndex) {
                  MyService.ConsoleLog(
                    "index matched",
                    $scope.getFiveMinData[i]
                  );
                  $scope.answer = $scope.getFiveMinData[i].answer;
                  $scope.importance = $scope.getFiveMinData[i].importance;
                  $scope.comment = $scope.getFiveMinData[i].comment;
                  $scope.updata.comment = $scope.getFiveMinData[i].comment;
                  // $timeout(function() {
                  //   $scope.$apply();
                  // });
                }
              }
            }
          }
          /*
        $timeout(function() {
          $location.hash('DisplayQ');
          $anchorScroll();
          $scope.$apply();
        }, 100);
        */
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category =
              $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      // Zip code
      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.showZipLoader = false;
      $scope.showAddressDropDown = false;

      $scope.AllAnsweredQuestions = [];
      $scope.getAllAnsweredQuestions = function (userId) {
        MyService.getAllAnsweredQuestions({
          authorId: userId,
        }).then(
          function (payload) {
            // MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload)
            if (payload.data.success) {
              $scope.AllAnsweredQuestions = payload.data.data;
              $scope.getMatchQUestions();
            }
          },
          function (errpayload) {
            MyService.ConsoleLog(
              "getAllAnsweredQuestions errpayload >> ",
              errpayload
            );
          }
        );
      };

      $scope.getAllStates = function () {
        if ($scope.counties) {
          $scope.counties.length = 0;
        }
        if ($scope.places) {
          $scope.places.length = 0;
        }

        var request = apiCall.apiCall("GET", "/user/getAllStates", {
          country: "",
        });
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog("Got States: ", response);
            if (response.data.success) {
              $scope.states = response.data.data;
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            growl.error("Something went wrong.");
          }
        );
      };

      if ($scope.UserId && $scope.UserId != "") {
        $scope.getAllAnsweredQuestions($scope.UserId);
      } else {
        $scope.getAllStates();
        $scope.getMatchQUestions();
      }

      $scope.checkState = function (rdata, val) {
        // MyService.ConsoleLog(rdata);
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val) return rdata;
        });
      };

      $scope.checkState = function (rdata, val) {
        // MyService.ConsoleLog("State :", rdata);
        // MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog("County :", rdata);
        MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      var getCountiesCanceler = $q.defer();

      $scope.allcounties = [];
      $scope.getCounties = function (lbl) {
        // MyService.ConsoleLog("county >", $scope.Location.state);
        // $scope.allcounties.length = 0;
        var statePostalCode = "";
        var state = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
        }
        if (!statePostalCode) {
          statePostalCode = $scope.stateFips;
          MyService.ConsoleLog("StatePostal Code in IF", statePostalCode);
        }
        // MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
        MyService.getAllCounties(statePostalCode, state).then(
          function (payload) {
            // MyService.ConsoleLog("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == "new") {
              var county = $scope.checkCounty(
                allcounties,
                $scope.oldaddress.county
              );
              $scope.allcounties = county;
              // MyService.ConsoleLog("county<><", county);
              $scope.address.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        //MyService.ConsoleLog($scope.Location.state);
        //MyService.ConsoleLog($scope.Location.county);
        // var county = $scope.Location.county;

        $scope.MyPlaces.length = 0;
        var county = "";
        var statePostalCode = "";
        $scope.MyPlaces.length = 0;
        var state = "";
        var county = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }
        // MyService.ConsoleLog(statePostalCode + "===" + county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            // payload = JSON.parse(payload);
            $scope.MyPlaces = payload;
            // MyService.ConsoleLog("PlaCE :", payload);
            // MyService.ConsoleLog(payload.countySubdivision);
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // MyService.ConsoleLog("PlaCE :", payload);
            if (lbl == "new") {
              // MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // MyService.ConsoleLog("subcounty<><><", subcounty);
              // $scope.subcounties = subcounty;
              $scope.places = places;
              // console.log('***********', $scope.places);
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.address.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function (lbl) {
        // MyService.ConsoleLog($scope.oldaddress);
        var state = {},
          county = {};

        if (lbl == "cur") {
          state = $scope.User.cur_state;
          county = JSON.parse($scope.User.cur_county);
        } else if (lbl == "new") {
          state.statePostalCode = $scope.oldaddress.state;
          county.county = $scope.oldaddress.county;
        } else {
          state = JSON.parse($scope.User.sel_state);
          county = JSON.parse($scope.User.sel_county);
        }

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();

        var request = apiCall.apiCall("GET", "/user/getAllSubCountiesLocal", {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              if (response.data.data) {
                // MyService.ConsoleLog(response.data.data);
                $scope.places = response.data.data;

                if (lbl == "new") {
                  var subcounty = $scope.checksubCounty(
                    $scope.places,
                    $scope.oldaddress.countySubdivision
                  );

                  var places = $scope.checkPlaces(
                    $scope.places,
                    $scope.oldaddress.place
                  );
                  // MyService.ConsoleLog("subcounty<><><", subcounty[0])
                  $scope.address.countySubdivision = subcounty[0];
                  $scope.address.place = places[0];
                }
              }
            } else {
              growl("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl("Something went wrong.");
          }
        );
      };
      $scope.User = {
        ZipCode: "",
      };
      $scope.detectCode = function (event) {
        if (event.keyCode == 9 && event.key == "Tab") {
          $scope.showAddressDropDowns($scope.User.ZipCode);
        }
        if (event.keyCode == 13 && event.key == "Enter") {
          $scope.showAddressDropDowns($scope.User.ZipCode);
        }
      };

      $scope.checkLength = function (vdata, val) {
        MyService.ConsoleLog(" vdata :", vdata);
        // MyService.ConsoleLog(" val :",val);

        var data = vdata.map(function (sub) {
          var returnval = "";
          switch (val) {
            case "federalCongrassionalDist":
              returnval = sub.CD115FP;
              break;
            case "stateSenateDistrict":
              returnval = sub.SLDUST;
              break;
            case "stateHouseOFRepresentativeDistrict":
              returnval = sub.NAMELSAD;
              break;
            case "unifiedSchoolDistrictName":
              returnval = sub.NAME;
              break;
            case "default":
              returnval = sub.NAME;
              break;
          }
          return returnval;
        });

        var ndata = $filter("unique")(data);
        if (ndata.length > 1) {
          return "yellowBackground";
        } else {
          return "";
        }
      };

      // for autocomplete address

      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // console.log($scope.lati);
            // console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            window.localStorage.setItem(
              "mailingAddress",
              $scope.mailing_Address
            );
            // MyService.ConsoleLog("Main addd :", place.address_components);
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  // document.getElementById('zip').value = place.address_components[i].long_name;
                  if (
                    place.address_components[i].long_name &&
                    place.address_components[i].long_name != ""
                  ) {
                    $scope.User.ZipCode = place.address_components[i].long_name;
                    // console.log($scope.User.ZipCode);
                  }
                  // console.log($scope.User.ZipCode);
                }
              }
            }

            console.log("$scope.User.ZipCode :", $scope.User.ZipCode);

            var loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // console.log(loc);
            // $rootScope.location = new Array;
            $rootScope.location = {
              longitude: $scope.lon,
              latitude: $scope.lati,
            };
            $scope.showAddressDropDowns("", loc);
          }
        );
      };

      // End for autocomplete address

      $scope.mainAddress = "";

      $scope.showAddressDropDowns = function (zipcode, loc) {
        if (loc && loc != "") {
          MyService.ConsoleLog("showAddressDropDowns" + zipcode);
          MyService.ConsoleLog("MyLocation : >>>." + loc);

          $scope.showZipLoader = true;
          MyService.getOrganizedZipData({
            candidate: true,
            loc: loc,
          }).then(
            function (payload) {
              MyService.ConsoleLog(" showAddressDropDowns ", payload);
              if (payload && payload != "") {
                // var finalData = payload.data;
                // MyService.ConsoleLog(payload[0].countySubdivision);
                // MyService.ConsoleLog(payload[0].county);
                // MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = payload.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = payload.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });
                MyService.ConsoleLog(
                  " showAddressDropDowns <><><><><><><><><><><><><> "
                );

                $scope.mainAddress = payload;
                $scope.showZipLoader = false;
                $scope.showAddressDropDown = true;
                $scope.oldaddress.state = payload.state;
                $scope.oldaddress.county = payload.county;
                $scope.stateFips = payload.state[0].STATEFP;
                MyService.ConsoleLog("ALLLLLLLLLLL", $scope.stateFips);
                // MyService.ConsoleLog("countySubdivisions >> ", countySubdivisions);
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // MyService.ConsoleLog("places >>> ",places);
                $scope.countyName = payload.county;
                $scope.placeName = payload.places;
                MyService.ConsoleLog("$scope.placename", $scope.placeName);
                MyService.ConsoleLog("$scope.countyName", $scope.countyName);
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = payload.congressionalDistrict;
                MyService.ConsoleLog(
                  "@@@@@@ drop :",
                  $scope.federalCongrassionalDist
                );

                $scope.stateSenateDistrict = payload.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  payload.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames =
                  payload.unifiedSchoolDistrict;
                $scope.cityCouncilDistrict = payload.cityCouncilDistrict;
                //$scope.$apply();
                // MyService.ConsoleLog($scope.states);
                // MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState($scope.states, payload.state);
                MyService.ConsoleLog("address : ", address[0]);

                $scope.address.state = address[0];
                // $scope.address.voterDistrict = $scope.voterDistricts[0];
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.address.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.address.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.address.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                $scope.address.cityCouncilDistrict = $scope.cityCouncilDistrict
                  ? $scope.cityCouncilDistrict[0]
                  : [];
                // MyService.ConsoleLog("address ; ", address);
                MyService.ConsoleLog(
                  "<><><><><<><><>>>>>>. zip code >>>>>>>>>>>>>>>>>",
                  $scope.User.ZipCode
                );
                $timeout(function () {
                  $rootScope.tempUserZip = $scope.User.ZipCode;
                  $rootScope.tempUserAddress = $scope.address;
                  MyService.ConsoleLog(
                    " temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>",
                    $rootScope.tempUserAddress
                  );
                  window.localStorage.setItem(
                    "tempUserZip",
                    $rootScope.tempUserZip
                  );
                  window.localStorage.setItem(
                    "tempUserAddress",
                    JSON.stringify($rootScope.tempUserAddress)
                  );
                  // MyService.ConsoleLog("address : ", address);
                  // MyService.ConsoleLog("rootscope address : ", $rootScope.tempUserAddress);
                }, 100);
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }

        // else {

        //   MyService.ConsoleLog("showAddressDropDowns" + zipcode);
        //   $scope.showZipLoader = true;
        //   MyService.getOrganizedZipData({
        //     "zipcode": zipcode,
        //     "candidate": true,
        //     "loc": loc
        //   }).then(function(payload) {
        //     MyService.ConsoleLog(" showAddressDropDowns ", payload);
        //     if (payload && payload != "") {
        //       // var finalData = payload.data;
        //       // MyService.ConsoleLog(payload[0].countySubdivision);
        //       // MyService.ConsoleLog(payload[0].county);
        //       // MyService.ConsoleLog(payload[0].place);
        //       var countySubdivisions = payload.places.filter(function(obj) {
        //         if (obj.COUSUBFP && obj.COUSUBFP != "") {
        //           // MyService.ConsoleLog("Inside cs");
        //           return obj;
        //         }
        //       });
        //       var places = payload.places.filter(function(obj) {
        //         if (obj.PLACEFP && obj.PLACEFP != "") {
        //           // MyService.ConsoleLog("obj > ", obj);
        //           return obj;
        //         }
        //       });
        //       MyService.ConsoleLog(" showAddressDropDowns <><><><><><><><><><><><><> ");

        //       $scope.mainAddress = payload;
        //       $scope.showZipLoader = false;
        //       $scope.showAddressDropDown = true;
        //       $scope.oldaddress.state = payload.state;
        //       $scope.oldaddress.county = payload.county;
        //       // MyService.ConsoleLog("countySubdivisions >> ", countySubdivisions);
        //       $scope.oldaddress.countySubdivision = countySubdivisions;
        //       // MyService.ConsoleLog("places >>> ",places);
        //       $scope.oldaddress.place = places;
        //       $scope.getCounties("new");

        //       $scope.getSubCounties("new");

        //       // $scope.voterDistricts = payload;
        //       // MyService.ConsoleLog(payload.congressionalDistrict);
        //       $scope.federalCongrassionalDist = payload.congressionalDistrict;
        //       MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

        //       $scope.stateSenateDistrict = payload.stateSenateDistrict;
        //       $scope.stateHouseOFRepresentativeDistricts =
        //         payload.stateHouseofRepresentativesDistrict;
        //       $scope.unifiedSchoolDistrictNames = payload.unifiedSchoolDistrict;
        //       //$scope.$apply();
        //       // MyService.ConsoleLog($scope.states);
        //       // MyService.ConsoleLog(payload[0].statePostalCode);
        //       var address = $scope.checkState($scope.states, payload.state);
        //       MyService.ConsoleLog("address : ", address);
        //       $scope.address.state = address[0];
        //       // $scope.address.voterDistrict = $scope.voterDistricts[0];
        //       $scope.address.federalCongrassionalDist = $scope.federalCongrassionalDist[0];
        //       $scope.address.stateSenateDistrict = $scope.stateSenateDistrict[0];
        //       $scope.address.stateHouseOFRepresentativeDistrict = $scope.stateHouseOFRepresentativeDistricts[0];
        //       $scope.address.unifiedSchoolDistrictName = $scope.unifiedSchoolDistrictNames[0];
        //       // MyService.ConsoleLog("address ; ", address);
        //       MyService.ConsoleLog("<><><><><<><><>>>>>>. zip code >>>>>>>>>>>>>>>>>", $scope.User.ZipCode);
        //       $timeout(function() {
        //         $rootScope.tempUserZip = $scope.User.ZipCode;
        //         $rootScope.tempUserAddress = $scope.address;
        //         MyService.ConsoleLog(" temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>", $rootScope.tempUserAddress);
        //         window.localStorage.setItem("tempUserZip", $rootScope.tempUserZip);
        //         window.localStorage.setItem("tempUserAddress", JSON.stringify($rootScope.tempUserAddress));
        //         // MyService.ConsoleLog("address : ", address);
        //         // MyService.ConsoleLog("rootscope address : ", $rootScope.tempUserAddress);
        //       }, 100);
        //     }

        //   }, function(errorPayload) {
        //     $scope.showZipLoader = false;
        //     growl.error(errorPayload);
        //   });
        // }
      };

      $scope.saveUserAnswers = function () {
        console.log("address>>>", $scope.address);
        $rootScope.tempUserAddress = $scope.address;
        MyService.ConsoleLog(
          " temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>",
          $rootScope.tempUserAddress
        );
        window.localStorage.setItem("tempUserZip", $rootScope.tempUserZip);
        window.localStorage.setItem(
          "tempUserAddress",
          JSON.stringify($rootScope.tempUserAddress)
        );
        $location.path("/fiveSaveUser");
      };

      $scope.eraseUserAnswers = function () {
        MyService.ConsoleLog("eraseUserAnswers");
        MyService.ConsoleLog(window.localStorage.getItem("fiveMatch"));
        $location.path("/fiveCompare");
      };

      $scope.showDigDeeperLoader = false;

      $scope.goBack = function () {
        $scope.initializeVariables();
        $scope.questions = angular.copy($scope.oldQuestions);
        $scope.currentIndex = angular.copy($scope.oldIndex);
        $scope.oldQuestions.length = 0;
        $scope.oldIndex = 0;
      };

      var getCategoryCanceler = $q.defer();
      $scope.digDeeper = function () {
        var catID = $scope.questions[$scope.currentIndex].categories[0].cid;
        //  MyService.ConsoleLog("catID<><>",catID);
        // MyService.ConsoleLog($scope.questions);
        // MyService.ConsoleLog("catNewID<><><>",$scope.questions[$scope.currentIndex].categories[0].cid);
        $scope.showDigDeeperLoader = true;

        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          "slow"
        );
        getCategoryCanceler.resolve();
        getCategoryCanceler = $q.defer();

        var request = apiCall.apiCall("GET", "/categories/list", {
          id: catID,
        });
        request.timeout = getCategoryCanceler.promise;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.category = response.data.data;
              MyService.ConsoleLog("category data: ", $scope.category);
              MyService.digDeepperQuestions({
                catId: catID,
              }).then(
                function (payload) {
                  $scope.showDigDeeperLoader = false;
                  if (payload.data.success) {
                    $scope.oldQuestions = angular.copy($scope.questions);
                    $scope.oldIndex = angular.copy($scope.currentIndex);
                    $scope.questions = payload.data.data;
                    $scope.currentIndex = 0;
                    $scope.digDeeperMatch = true;
                    MyService.ConsoleLog($scope.questions);
                  } else {
                    alert("Something went wrong. Please try after some time");
                    // $location.path("/welcome");
                  }
                  // MyService.ConsoleLog(payload);
                },
                function (errorPayload) {
                  $scope.showDigDeeperLoader = false;
                  // MyService.ConsoleLog(errorPayload);
                }
              );
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.category = 3;
          }
        );
      };

      // Mellisa data start //
      $scope.findaddress = {
        address: "",
        city: "",
        state: "",
        zipcode: "",
      };
      $scope.result = {
        city: "",
      };
      $scope.showFindAddress = function () {
        var q = {
          zipcode: $scope.User.ZipCode,
        };
        var addressModal = ngDialog.open({
          template: "app/popups/mellisa_data.html",
          scope: $scope,
          data: q,
        });
        $scope.findaddress.zipcode = $scope.User.ZipCode;
      };

      $scope.finddetailsclicked = false;
      $scope.getDetails = function (records) {
        var error = "";
        if ($scope.findaddress.address == "") {
          error = "Please enter address";
        }
        if (error == "" && $scope.findaddress.city == "") {
          error = "Please enter city";
        }
        if (error == "" && $scope.findaddress.state == "") {
          error = "Please enter state";
        }
        if (error == "" && $scope.findaddress.zipcode == "") {
          error = "Please enter zipcode";
        }

        if (error == "") {
          $scope.finddetailsclicked = true;
          // MyService.ConsoleLog("=============Address==============");
          // MyService.ConsoleLog($scope.findaddress);
          // MyService.ConsoleLog("findaddress<><><>", records);
          var aurl =
            "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify";
          aurl += "?id=RyMI8jlzD6dHUApvp_3XRP**";
          aurl += "&cols=GrpCensus,GrpCensus2,GrpGeocode";
          aurl += "&a1=" + records.address;
          aurl += "&city=" + records.city;
          aurl += "&state=" + records.state;
          aurl += "&postal=" + records.zipcode;
          aurl += "&ctry=US";
          aurl += "&format=JSON";
          // var deferred = $q.defer();
          //var furl = "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify?id=RyMI8jlzD6dHUApvp_3XRP**&cols=GrpCensus,GrpCensus2,GrpGeocode&a1=" + records.address + "&city=" + records.city + "&state=" + records.state + "&postal=" + records.zipcode + "&ctry=US&format=JSON";
          // MyService.ConsoleLog("furl <><>>", furl);
          $http.get(aurl).then(
            function successCallback(response) {
              //$scope.result = "";
              $scope.finddetailsclicked = false;
              if (response.statusText == "OK") {
                var rdata = response.data.Records[0];
                if (rdata) {
                  $scope.result = rdata;
                }
              }
              // MyService.ConsoleLog("response", response);
            },
            function errorCallBack(error) {
              $scope.finddetailsclicked = false;
              // MyService.ConsoleLog(error);
            }
          );
        } else {
          alert(error);
        }
      };
      // $scope.oldAddress = "";
      $scope.checkZipAddress = function (val) {
        // $scope.oldAddress = angular.copy($scope.mainAddress);
        return $scope.mainAddress.filter(function (rdata) {
          // MyService.ConsoleLog("Space:", parseInt(val.CongressionalDistrict));
          if (
            val.CongressionalDistrict == "" ||
            val.CongressionalDistrict == " "
          ) {
            // MyService.ConsoleLog("inside<><><><><>");

            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) ==
                parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) ==
                parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) ==
                parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              // MyService.ConsoleLog("This is a match");
              return rdata;
            }
          } else {
            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) ==
                parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.federalCongrassionalDist) ==
                parseInt(val.CongressionalDistrict) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) ==
                parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) ==
                parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              // MyService.ConsoleLog("This is a match2");
              return rdata;
            }
          }
        });
      };

      $scope.setAddressNew = function () {
        // MyService.ConsoleLog($scope.result);
        var finalResult = $scope.checkZipAddress($scope.result);
        if (finalResult && finalResult.length > 0) {
          MyService.ConsoleLog("finalResult::::", finalResult);
          // $scope.mainAddress = angular.copy(finalResult);
          $scope.voterDistricts = finalResult;
          $scope.federalCongrassionalDist = finalResult;
          $scope.stateSenateDistrict = finalResult;
          $scope.stateHouseOFRepresentativeDistricts = finalResult;
          $scope.unifiedSchoolDistrictNames = finalResult;
          ngDialog.closeAll();
          // $scope.$apply();
        } else {
          alert(
            "Address not matching with our database. Please enter detail address and try again."
          );
        }
      };

      var previousSelVal = {};
      $scope.setSelectedDropdown = function (
        listData,
        setfor,
        selectedUserAdrs
      ) {
        switch (setfor) {
          case "counties":
            previousSelVal.county = $scope.address.county;
            //MyService.ConsoleLog("selCountyNumber: ",previousSelVal.county);
            $scope.address.county = null;
            if (listData) {
              $scope.counties = listData;
              $scope.counties.unshift({
                number: null,
                name: "none",
              });

              // if(!selectedUserAdrs) selectedUserAdrs.countyFips = previousSelVal.county.number;
              var selCountyNumber = null;
              try {
                $scope.address.county = $scope.counties[0];
                if (selectedUserAdrs) {
                  selCountyNumber = selectedUserAdrs.countyFips;
                }
                if (previousSelVal.county) {
                  selCountyNumber = previousSelVal.county.number;
                }

                if (selCountyNumber) {
                  //MyService.ConsoleLog("selCountyNumber: ",selCountyNumber);
                  angular.forEach($scope.counties, function (val, key) {
                    if (selCountyNumber == val.number)
                      $scope.address.county = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "subCounties":
            previousSelVal.subcounty = $scope.address.subcounty;
            $scope.address.subcounty = null;
            if (listData) {
              $scope.subCounties = listData;
              $scope.subCounties.unshift({
                number: null,
                name: "none",
              });
              var selSubCountyNo = null;
              try {
                $scope.address.subcounty = $scope.subCounties[0];
                if (selectedUserAdrs) {
                  selSubCountyNo = selectedUserAdrs.countySubdivisionFips;
                }
                if (previousSelVal.subcounty) {
                  selSubCountyNo = previousSelVal.subcounty.number;
                }
                if (selSubCountyNo) {
                  angular.forEach($scope.subCounties, function (val, key) {
                    //set selected state
                    // if(selectedUserAdrs.countySubdivisionFips == val.number)
                    if (selSubCountyNo == val.number)
                      $scope.address.subcounty = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "places":
            previousSelVal.place = $scope.address.place;
            $scope.address.place = null;
            if (listData) {
              $scope.places = listData;
              $scope.places.unshift({
                number: null,
                name: "none",
              });

              try {
                $scope.address.place = $scope.places[0];
                var selPlaceNo = null;
                if (selectedUserAdrs) {
                  selPlaceNo = selectedUserAdrs.placeFips;
                }
                if (previousSelVal.place) {
                  selPlaceNo = previousSelVal.place.number;
                }
                if (selPlaceNo) {
                  angular.forEach($scope.places, function (val, key) {
                    if (selPlaceNo == val.number) $scope.address.place = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "voterDistricts":
            if (listData) {
              previousSelVal.voterDistrict = $scope.address.voterDistrict;
              $scope.address.voterDistrict = null;
              $scope.voterDistricts = listData;
              $scope.voterDistricts.unshift({
                number: null,
                name: "none",
              });

              var voterDistrictNo = null;
              try {
                $scope.address.voterDistrict = $scope.voterDistricts[0];
                if (selectedUserAdrs) {
                  voterDistrictNo = selectedUserAdrs.voterDistrictFips;
                }
                if (previousSelVal.voterDistrict) {
                  voterDistrictNo = previousSelVal.voterDistrict.number;
                }

                if (voterDistrictNo) {
                  angular.forEach($scope.voterDistricts, function (val, key) {
                    //set selected
                    if (voterDistrictNo == val.number)
                      $scope.address.voterDistrict = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "federalCongrassionalDist":
            $scope.address.federalCongrassionalDist = null;
            if (listData) {
              $scope.federalCongrassionalDist = listData;
              $scope.federalCongrassionalDist.unshift("none");

              if (selectedUserAdrs) {
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                angular.forEach(
                  $scope.federalCongrassionalDist,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.federalCongrassionalDist == val)
                      $scope.address.federalCongrassionalDist = val;
                  }
                );
              }
            }
            break;
          case "stateSenateDistrict":
            $scope.address.stateSenateDistrict = null;
            if (listData) {
              $scope.stateSenateDistrict = listData;
              $scope.stateSenateDistrict.unshift("none");

              $scope.address.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateSenateDistrict,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.stateSenateDistrict == val)
                      $scope.address.stateSenateDistrict = val;
                  }
                );
              }
            }
            break;
          case "stateHouseOFRepresentativeDistrict":
            $scope.address.stateLegislativeDistrict = null;
            if (listData) {
              $scope.stateLegislativeDistrict = listData;
              $scope.stateLegislativeDistrict.unshift("none");
              $scope.address.stateLegislativeDistrict =
                $scope.stateLegislativeDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateLegislativeDistrict,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.legislativeDistrict == val)
                      $scope.address.stateLegislativeDistrict = val;
                  }
                );
              }
            }
            break;
          case "schoolDistricts":
            $scope.address.schoolDistrict = null;
            if (listData) {
              $scope.schoolDistricts = listData;
              $scope.schoolDistricts.unshift({
                number: null,
                name: "none",
              });
              $scope.address.schoolDistricts = $scope.schoolDistricts[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.schoolDistricts, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.schoolDistrictFips == val.number)
                    $scope.address.schoolDistrict = val;
                });
              }
            }
            break;
        }
      };

      /*$scope.onVoterDistChange = function() {
       MyService.ConsoleLog("** onVoterDistChange **");
      try {
        var postobj = {};

        $scope.statusVoterDistSearch = 1;
        postobj.zipcode = $scope.User.ZipCode;
         MyService.ConsoleLog("address<><><",$scope.address)
        if ($scope.address.voterDistrict && $scope.address.voterDistrict.voterDistrictName.toLowerCase() != "none") {
          postobj.voterDistrictName = $scope.address.voterDistrict.voterDistrictName;

           MyService.ConsoleLog("postobj: ", postobj);
          MyService.getfilterAddressData(postobj).then(function(response) {
             MyService.ConsoleLog("Got zip data: ", response);
            if (response.data.success) {
              var payload = response.data.data;

              $scope.mainAddress = payload;
              $scope.showZipLoader = false;
              $scope.showAddressDropDown = true;
              $scope.oldaddress.state = payload[0].statePostalCode;
              $scope.oldaddress.county = payload[0].county;
              $scope.oldaddress.countySubdivision = payload[0].countySubdivision;
              $scope.oldaddress.place = payload[0].place;
              $scope.getCounties("new");

              $scope.getLocals("new");

              $scope.voterDistricts = payload;
              $scope.federalCongrassionalDist = payload;
              $scope.stateSenateDistrict = payload;
              $scope.stateHouseOFRepresentativeDistricts = payload;
              $scope.unifiedSchoolDistrictNames = payload;
              //$scope.$apply();
              var address = $scope.checkState($scope.states, payload[0].statePostalCode);
              $scope.address.state = address[0];
              $scope.address.voterDistrict.length = 0;
              $scope.address.voterDistrict = $scope.voterDistricts[0];
              $scope.address.voterDistrict.unshift({
                number: null,
                voterDistrictName: "none"
              });
              $scope.address.federalCongrassionalDist = $scope.federalCongrassionalDist[0];
              $scope.address.stateSenateDistrict = $scope.stateSenateDistrict[0];
              $scope.address.stateHouseOFRepresentativeDistrict = $scope.stateHouseOFRepresentativeDistricts[0];
              $scope.address.unifiedSchoolDistrictName = $scope.unifiedSchoolDistrictNames[0];
              $scope.statusVoterDistSearch = 2;
            } else if (response.data.error && typeof response.data.error == "string") {
              growl.error('Error: ' + response.data.error);
              $scope.statusVoterDistSearch = 2;
            } else {
              growl.error('Something went wrong.');
              $scope.statusVoterDistSearch = 3;
            }
          }, function(err) {
            growl.error('Something went wrong.');
            $scope.statusVoterDistSearch = 3;
          });
        }
      } catch (e) {
        //MyService.ConsoleLog("Exception : ",e);
      }
    };*/

      // Mellisa data end //

      $scope.confirmPopup = function () {
        MyService.ConsoleLog("address : ", $scope.address);
        const ansQuestion = $scope.checkQuestionAnswered();

        if (ansQuestion == 0) {
          $scope.eraseUserAnswers();
        } else {
          var message =
            "Without your voice, your politician won’t know how to represent you. Before we show you your results, would you like to create a profile so we can save your answers and send them to your politicians anonymously?";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="compareBtn" ng-click="confirm(0)">Create and Save</button>\
                            <button type="button" class="eraseBtn" ng-click="confirm(1)">No. Erase when done</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              MyService.ConsoleLog("fullfilled: ", data);
              if (data == 0) {
                $scope.saveUserAnswers();
              } else {
                $scope.eraseUserAnswers();
              }
            },
            function rejected(data) {
              MyService.ConsoleLog("rejected: ", data);
            }
          );

          return;
        }
      };

      function keyupHandler(keyEvent) {
        MyService.ConsoleLog("key up");
        if (
          !$scope.showZipCode &&
          !$scope.questions[$scope.currentIndex].isEssayQue
        ) {
          event.preventDefault();
          if (event.keyCode === 13) {
            //MyService.ConsoleLog('Current route name: ', $location.path());
            if ($location.path() == "/fiveMatch") {
              $scope.submitAnswer();
            }
          }
        }
      }
      $scope.$watch("currentIndex", function (newValue, oldValue) {
        try {
          // MyService.ConsoleLog($scope.currentIndex);
          // MyService.ConsoleLog($scope.questions.length);

          MyService.ConsoleLog("Current currentIndex: ", $scope.currentIndex);
          // console.log("scope.importance >>> ",$scope.importance)
          // console.log($scope.questions[$scope.currentIndex]._id);
          // if ($scope.currentIndex == 0) {

          var data = $scope.checkQuestion(
            $scope.questions[$scope.currentIndex]._id
          );
          console.log("data >> ", data.length);
          if (data.length == 0) {
            $scope.dis = false;
          }

          if (data && data[0] && data[0].answer && data[0].answer != "") {
            $scope.updata.comment = data[0].comment;
            $scope.answer = data[0].answer;
            $scope.importance = data[0].importance;
          }

          $scope.initMyAnswer(); // Added
          // }

          // var obj = $scope.questions[$scope.currentIndex];

          /*
        var data = $scope.checkQuestion(obj._id);


        if (data && data[0]) {
          MyService.ConsoleLog(data[0]);
          if (data[0].answer != "") {
            $scope.answer = data[0].answer;
          }
          if (data[0].importance != "") {
            $scope.importance = data[0].importance;
          }
          if (data[0].comment != "") {
            $scope.comment = data[0].comment;
          }

        }
        */
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });

      document.addEventListener("keyup", keyupHandler);
      $scope.$on("$destroy", function () {
        MyService.ConsoleLog("event destroyed :");
        $document.unbind("keyup");
      });
    },
  ]);
