'use strict';
angular.module('myApp.ballotThread', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ballotThread/:id', {
      templateUrl: 'app/ballotThread/ballotThread.html',
      controller: 'ballotThreadCtrl'
    });
  }])
  .controller('ballotThreadCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$filter', 'MyService', 'ngDialog', 'growl', 'tokenService', '$sce', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $filter, MyService, ngDialog, growl, tokenService, $sce) {

    /**
     * Params
     */
    $scope.bId = $routeParams.id;
    MyService.ConsoleLog("$scope.bId", $scope.bId);

    $rootScope.welcome = false;
    $scope.category = {};
    $scope.subcategory = {};
    $scope.DisplayData = [];
    $scope.userType = tokenService.getUserRole();
    $scope.userId = tokenService.getUserId();
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
    }
    $scope.Sticky = {
      txtOrder: 0
    };

    if ($rootScope.ExtraTopicData) {
      if ($rootScope.ExtraTopicData.MyDiscussion) {
        $scope.DiscussionData = $rootScope.ExtraTopicData.MyDiscussion;
      }

      if ($rootScope.ExtraTopicData.ActiveData) {
        $scope.ActiveThreads = $rootScope.ExtraTopicData.ActiveData;
      }

      if ($rootScope.ExtraTopicData.HotTopics) {
        $scope.HotTopics = $rootScope.ExtraTopicData.HotTopics;
      }
    }

    if (window.localStorage.getItem("VoteWiseCategory")) {
      $rootScope.allcategories = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
      var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);

      if (idx == -1) {
        $rootScope.allcategories.push($routeParams.subcatId);
        window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
      }
    }

    $scope.checkSticky = function (val) {
      if (val == 'Y') {
        return {
          'background-color': "#fbeec0"
        };
      } else {
        return '';
      }
    };

    $scope.createNew = function () {
      $location.path('/createBallotThread/' + $scope.bId);
    };

    $scope.statusGetForumList = 0;
    $scope.getForumList = function () {
      if ($scope.userData) {
        var address = JSON.stringify($scope.userData.address);
      } else {
        var address = "";
      }
      var finalData = {
        "taggedUserId": $routeParams.id
      };
      //MyService.ConsoleLog("post Data : ", finalData);
      $scope.statusGetForumList = 1;
      var nrequest = apiCall.apiCall('POST', '/topics/getList', finalData);
      $http(
        nrequest
      ).then(function successCallback(responseLatest) {
        //MyService.ConsoleLog("Final DataL : ",responseLatest);
        //MyService.ConsoleLog(responseLatest.data.data);
        if (responseLatest.data.success) {
          $scope.DisplayData = responseLatest.data.data;
          MyService.ConsoleLog("this is new", $scope.DisplayData);
          $scope.statusGetForumList = 2;
        } else {
          $scope.statusGetForumList = 3;
          if (responseLatest.data.error && typeof responseLatest.data.error == "string") {
            growl.error(responseLatest.data.error);
          } else {
            growl.error('Something went wrong.');
          }
        }
      }, function errorCallBack(responseLatest) {
        $scope.statusGetForumList = 3;
        growl.error("Error : " + responseLatest);
      });
    }

    function getSubCategories() {
      MyService.getSubCategoryList($routeParams.subcatId).then(function (payload) {
          $scope.subcategory = payload;
        },
        function (errorPayload) {
          alert('Something went wrong. ');
        });

    }

    function getCategory() {
      MyService.getCategoryList($routeParams.categoryId).then(function (payload) {
          $scope.category = payload;
          //MyService.ConsoleLog("subcatdata : ", subcatdata);

        },
        function (errorPayload) {
          alert('Something went wrong.');
        });

    }

    $scope.gotoThread = function (id) {

      var categoryId = $routeParams.categoryId;
      var subcatid = $routeParams.subcatId;
      $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + id);
    }

    $scope.updatePostApi = function (data, options, idex) {

      var request = apiCall.apiCall('POST', '/topics/update', data);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog(response);
        if (response.data.data.nModified == 0) {
          alert("Something went wrong. Please try after sometime.");
        } else {
          $scope.getForumList();

        }

      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", JSON.stringify(response));
      });
    }

    $scope.gotoUpdate = function (item) {
      $location.path('/updateTopic/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.type + '/' + item._id);

    }


    $scope.objectKeys = function (obj) {
      return Object.keys(obj);
    }

    $scope.updatePost = function (option, id) {
      var data = {};
      if (option == "sticky") {
        data.poststicky = 1;
        data.poststickyOrder = $scope.Sticky.txtOrder[id];
        data.topicId = id;

      } else {
        data.removepostSticky = 1;
        data.topicId = id;

      }
      //MyService.ConsoleLog(data);
      $scope.updatePostApi(data);
    }


    /*if ($routeParams.categoryId) {
        getCategory();
        getSubCategories();

    }*/
    if ($routeParams.id) {
      $scope.getForumList();
    }
  }]);
