'use strict';
angular.module('myApp.reports', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
        $routeProvider.when('/reports', {
            templateUrl: 'app/reports/reports.html',
            controller: 'reportsCtrl'
        });
    }])
    .controller('reportsCtrl', ['$scope', '$rootScope', 'growl', 'ngDialog', 'tokenService', 'MyService', function($scope, $rootScope, growl, ngDialog, tokenService, MyService) {
        //MyService.ConsoleLog("** dashboardCtrl **");
        $rootScope.welcome = false;
        $scope.Report = {
            category: ''
        };
        $scope.TotalRecords = {
            data: []
        };
        $scope.items = {
            total: 0
        };
        $scope.page = 1;
        $scope.max_records = 250;
        $scope.statusGetResPg = 0;

        //AnsweredQuestion start

        $scope.categories = [];
        $scope.subcategories = [];
        $scope.getCategories = function() {
            $scope.showCatLoading = true;
            if ($rootScope.categories) {
                $scope.categories = $rootScope.categories;
                $scope.showCatLoading = false;
            } else {
                MyService.getRootCategoryList({ root: 1, questions_counter: 1, catTypes: "Q,B" }).then(function(payload) {
                    //MyService.ConsoleLog(payload);
                    $scope.categories = payload;
                    $rootScope.categories = payload;
                    $scope.showCatLoading = false;
                }, function(errpayload) {
                    MyService.ConsoleLog(errpayload);
                    $scope.showCatLoading = false;
                });
            }
            // MyService.ConsoleLog($scope.categories);
        }

        $scope.getSubcat = function() {
            if ($scope.Report.category && $scope.Report.category._id) {
                $scope.subcategories.length = 0;
                $scope.showCatLoading = true;
                MyService.getSubCategoryList($scope.Report.category._id).then(function(payload) {
                    $scope.subcategories = payload;
                    $scope.showCatLoading = false;
                }, function(errpayload) {
                    MyService.ConsoleLog(errpayload);
                    $scope.showCatLoading = false;
                });
            }
        }

        //AnsweredQuestion End

        //AreaQuestion Start
        $scope.states = [];
        $scope.counties = [];
        $scope.subcounties = [];
        $scope.getStates = function() {
            $scope.showStateLoading = true;
            MyService.getAllStates().then(function(payload) {
                $scope.showStateLoading = false;
                // MyService.ConsoleLog(payload);
                $scope.states = payload;
            }, function(errpayload) {
                $scope.showStateLoading = false;
                //MyService.ConsoleLog(errpayload);
            });
        }

        $scope.getCounty = function() {
            var statePostalCode = "";
            if ($scope.Report.state && $scope.Report.state.statePostalCode) {
                statePostalCode = $scope.Report.state.statePostalCode;
            }
            if (statePostalCode) {
                $scope.showStateLoading = true;
                $scope.counties.length = 0;
                $scope.subcounties.length = 0;
                MyService.getAllCounties(statePostalCode, $scope.Report.state.statePostalCode).then(function(payload) {
                    $scope.showStateLoading = false;
                    // MyService.ConsoleLog(payload);
                    $scope.counties = payload;
                }, function(errpayload) {
                    $scope.showStateLoading = false;
                    //MyService.ConsoleLog(errpayload);
                });
            } else {
                alert("State is not selected");
            }
        }

        $scope.getPlaces = function() {
                var county = "";
                // MyService.ConsoleLog($scope.Report.county);
                if ($scope.Report.county && $scope.Report.county._id) {
                    county = $scope.Report.county._id;
                }
                if (county) {
                    $scope.showCountyLoading = true;
                    MyService.getAllSubCountiesLocal(county.statePostalCode, county.county, county).then(function(payload) {
                        $scope.showCountyLoading = false;
                        // MyService.ConsoleLog(payload);
                        $scope.subcounties = payload;
                    }, function(errpayload) {
                        $scope.showCountyLoading = false;
                        //MyService.ConsoleLog(errpayload);
                    });
                } else {
                    alert("County is not selected");
                }
            }
            //AreaQuestion End

        $scope.showCatLoading = false;
        $scope.showAnswered = false;
        $scope.showComments = false;
        $scope.ShowCommentsSection = 1;
        $scope.showStateLoading = false;
        $scope.showCountyLoading = false;
        $scope.resetRecord = function() {
            $scope.ReportButton = false;
            $scope.showError = false;
            $scope.RunningFunction = "";
            $scope.totalPages = 0;
            $scope.page = 1;
            $scope.TotalRecords.data.length = 0;
            $scope.Report = {
                category: '',
                subcategory: '',
                state: '',
                county: '',
                place: ''
            };
        }
        $scope.showTab = function(obj) {
            // MyService.ConsoleLog(obj);
            $scope.resetRecord();
            $scope.showAnswered = false;
            $scope.showArea = false;
            $scope.showComments = false;
            $scope.showliveUsers = false;
            switch (obj) {
                case 'AnsweredQuestion':
                    $scope.showAnswered = true;
                    $scope.getCategories();
                    break;
                case 'AreaQuestion':
                    $scope.showArea = true;
                    $scope.getCategories();
                    $scope.getStates();
                    break;
                case 'CommentsQuestion':
                    $scope.showComments = true;
                    $scope.ShowCommentsSection = 1;
                    $scope.getCategories();
                    break;
                case 'liveUsers':
                    $scope.showliveUsers = true;
                    $scope.getLiveUsers();
                    break;
                default:
            }
        }

        $scope.ReportButton = false;
        $scope.showError = false;
        $scope.RunningFunction = "";
        $scope.totalPages = 0;

        $scope.getReport = function() {
             MyService.ConsoleLog($scope.Report.category);
             MyService.ConsoleLog($scope.Report.category._id);
            $scope.RunningFunction = "getReport";
            $scope.ReportButton = true;
            var catID = "";
            if ($scope.Report.category && $scope.Report.category._id) {
                catID = $scope.Report.category._id;
            }
            if ($scope.Report.subcategory && $scope.Report.subcategory._id) {
                catID = $scope.Report.subcategory._id;
            }
            MyService.GetAnsQuestionReport({ "catid": catID, "page": $scope.page, "max_records": $scope.max_records }).then(function(payload) {
                MyService.ConsoleLog("Success");
                MyService.ConsoleLog(payload);
                $scope.ReportButton = false;
                $scope.showError = true;
                if (payload.data.success) {
                    $scope.items.total = 0;
                    $scope.TotalRecords.data.length = 0;
                    $scope.TotalRecords.data = payload.data.data;

                    // }
                    // var finalCount = parseInt($scope.page) * parseInt($scope.max_records)
                    // if(parseInt(payload.data.data.totalCount) < finalCount ) {
                    //     $scope.myPagingFunction();
                    // }
                    // MyService.ConsoleLog($scope.TotalRecords.data);
                } else {
                    alert("Something went wrong");
                }
            }, function(errpayload) {
                $scope.showError = true;
                $scope.ReportButton = false;
                // MyService.ConsoleLog("errpayload");
                alert("Something went wrong");
            })
        }

        $scope.getAreaReport = function() {
            // MyService.ConsoleLog($scope.Report.category);
            // MyService.ConsoleLog($scope.Report.category._id);
            $scope.RunningFunction = "getAreaReport";
            $scope.ReportButton = true;
            var catID = "",
                obj = "";
            if ($scope.Report.state && $scope.Report.state.state) {
                obj = JSON.stringify($scope.Report.state);
            }
            if ($scope.Report.county && $scope.Report.county._id) {
                obj = JSON.stringify($scope.Report.county._id);
            }
            if ($scope.Report.place && $scope.Report.place._id) {
                obj = JSON.stringify($scope.Report.place._id);
            }
            if ($scope.Report.category && $scope.Report.category._id) {
                catID = $scope.Report.category._id;
            }
            if ($scope.Report.subcategory && $scope.Report.subcategory._id) {
                catID = $scope.Report.subcategory._id;
            }
            if ($scope.Report.state && $scope.Report.state.state) {
                MyService.GetAreaQuestionReport({ "catid": catID, "areaobj": obj, "page": $scope.page, "max_records": $scope.max_records }).then(function(payload) {
                    // MyService.ConsoleLog("Success");
                    // MyService.ConsoleLog(payload);
                    $scope.ReportButton = false;
                    $scope.showError = true;
                    if (payload.data.success) {
                        //$scope.items.total = 0;
                        if (payload.data.data.records && payload.data.data.records.length > 0) {
                            $scope.TotalRecords.data = $scope.TotalRecords.data.concat(payload.data.data.records);
                        }
                        var finalCount = parseInt($scope.page) * parseInt($scope.max_records);
                        $scope.totalPages = Math.ceil(parseInt(payload.data.data.totalCount) / parseInt($scope.max_records));
                        // MyService.ConsoleLog("finalCount::", finalCount);
                        if (finalCount < parseInt(payload.data.data.totalCount)) {
                            // MyService.ConsoleLog("Call paginate");
                            $scope.myPagingFunction();
                        }

                        // MyService.ConsoleLog($scope.TotalRecords.data);
                    } else {
                        alert("Something went wrong");
                    }
                }, function(errpayload) {
                    $scope.ReportButton = false;
                    $scope.showError = true;
                    // MyService.ConsoleLog("errpayload");
                    alert("Something went wrong");
                })
            } else {
                $scope.ReportButton = false;
                alert("Please select state");
            }
        }

        $scope.getCommentsReport = function() {
            // MyService.ConsoleLog($scope.Report.category);
            // MyService.ConsoleLog($scope.Report.category._id);
            $scope.RunningFunction = "getCommentsReport";
            $scope.ReportButton = true;
            var catID = "";
            if ($scope.Report.category && $scope.Report.category._id) {
                catID = $scope.Report.category._id;
            }
            if ($scope.Report.subcategory && $scope.Report.subcategory._id) {
                catID = $scope.Report.subcategory._id;
            }
            MyService.GetCommentsQuestionReport({ "catid": catID, "page": $scope.page, "max_records": $scope.max_records }).then(function(payload) {
                // MyService.ConsoleLog("Success");
                // MyService.ConsoleLog(payload);
                $scope.ReportButton = false;
                $scope.showError = true;
                if (payload.data.success) {
                    $scope.items.total = 0;
                    $scope.TotalRecords.data.length = 0;
                    $scope.TotalRecords.data = payload.data.data;

                    // }
                    // var finalCount = parseInt($scope.page) * parseInt($scope.max_records)
                    // if(parseInt(payload.data.data.totalCount) < finalCount ) {
                    //     $scope.myPagingFunction();
                    // }
                    // MyService.ConsoleLog($scope.TotalRecords.data);
                } else {
                    alert("Something went wrong");
                }
            }, function(errpayload) {
                $scope.showError = true;
                $scope.ReportButton = false;
                // MyService.ConsoleLog("errpayload");
                alert("Something went wrong");
            })
        }
        $scope.CommentQuestion = "";
        $scope.AllComments = [];
        $scope.getComments = function(id) {
            $scope.AllComments.length = 0;
            $scope.loading = true;
            MyService.getComments({ "id": id }).then(function(payload) {
                //MyService.ConsoleLog(payload);
                $scope.loading = false;
                if (payload.data.success) {

                    $scope.AllComments = payload.data.data;
                } else {
                    alert("Something went wrong");
                }
            }, function(errpayload) {
                $scope.loading = false;
                //MyService.ConsoleLog(errpayload);
                alert("Something went wrong");
            })
        }
        $scope.CommentQuestion = "";
        $scope.returnPage = '';
        $scope.gotoBack = function(id) {
            if ($scope.returnPage == 'Answer') {
                $scope.showArea = false;
                $scope.showComments = false;
                $scope.showAnswered = true;
            } else {
                $scope.ShowCommentsSection = id;
            }
        }
        $scope.DisplayAComments = function(data) {
            //alert("herre");
            //MyService.ConsoleLog(data);
            $scope.returnPage = 'Answer';
            $scope.CommentQuestion = data;
            // ngDialog.open({
            //     template: 'reports/comments.html',
            //     width: '80%',
            //     scope: $scope
            // });
            $scope.showAnswered = false;
            $scope.showArea = false;
            $scope.showComments = true;
            $scope.ShowCommentsSection = 2;
            $scope.getComments(data._id);
        }
        $scope.DisplayComments = function(data) {
                //alert("herre");
                //MyService.ConsoleLog(data);
                $scope.CommentQuestion = data;
                // ngDialog.open({
                //     template: 'reports/comments.html',
                //     width: '80%',
                //     scope: $scope
                // });
                $scope.ShowCommentsSection = 2;
                $scope.getComments(data._id);
            }
            // $scope.getUserReport = function() {
            //     $scope.RunningFunction = "getUserReport";
            //     MyService.GetAllUsersAnsReport({}).then(function(payload) {
            //         MyService.ConsoleLog("Success");
            //         MyService.ConsoleLog(payload);
            //         $scope.showError = true;
            //     }, function(errpayload) {
            //         $scope.ReportButton = false;
            //         MyService.ConsoleLog("errpayload");
            //         $scope.showError = true;
            //         alert("Something went wrong");
            //     })
            // }

        $scope.myPagingFunction = function() {
            if ($scope.RunningFunction != "") {
                if ($scope.page < $scope.totalPages) {
                    $scope.page++;
                    if ($scope.RunningFunction == "getAreaReport") {
                        $scope.getAreaReport();
                    }
                }
            }
        }
        $scope.userData = "";
        $scope.AllAnswers = [];
        $scope.loading = false;
        $scope.gotoUserAnswers = function(userdata) {
            MyService.ConsoleLog(userdata);
            $scope.userData = userdata.userData;
            $scope.ShowCommentsSection = 3;
            if ($scope.userData._id) {
                $scope.loading = true;
                MyService.getUserAnsQuestion({ "id": $scope.userData._id }).then(function(payload) {
                    MyService.ConsoleLog(payload);
                    if (payload.data.success) {
                        $scope.AllAnswers = payload.data.data;
                        $scope.loading = false;
                    } else {
                        $scope.loading = false;
                        alert("Something went wrong.");
                    }
                }, function(errpayload) {
                    $scope.loading = false;
                    alert("Something went wrong.");
                });
            } else {
                alert("Please select user first");
            }
        }

        $scope.ShowOrderList = false;
        $scope.showOrder = function() {
            if (!$scope.ShowOrderList) {
                $scope.ShowOrderList = true;
            } else {
                $scope.ShowOrderList = false;
            }
        }

        $scope.Importance = false;
        $scope.Agree = false;

        $scope.ShowImp = function(option) {
            switch (option) {
                case "Importance":
                    if (!$scope.Importance) {
                        $scope.Importance = true;
                    } else {
                        $scope.Importance = false;
                    }
                    break;
                case "Agree":
                    if (!$scope.Agree) {
                        $scope.Agree = true;
                    } else {
                        $scope.Agree = false;
                    }
                    break;
                case "Default":
                    $scope.Importance = false;
                    break;
            }
        }

        $scope.search = {
            importance: '',
            importancesmin: 0,
            importancesmax: 100,
            importanceimin: 0,
            importanceimax: 100,
            importancevmin: 0,
            importancevmax: 100,
            agree: '',
            strongagreemin: 0,
            strongagreemax: 100,
            agreemin: 0,
            agreemax: 100,
            sortagreemin: 0,
            sortagreemax: 100,
            sortdisagreemin: 0,
            sortdisagreemax: 100,
            disagreemin: 0,
            disagreemax: 100,
            strongdisagreemin: 0,
            strongdisagreemax: 100,
        };

        $scope.filterFn = function(item) {
            if ($scope.search.importance == '' || $scope.search.agree != '') {
                switch ($scope.search.agree) {
                    case 'Very strongly agree':
                        if (item.strongAgreeAvg && ((item.strongAgreeAvg > $scope.search.strongagreemin) && (item.strongAgreeAvg < $scope.search.strongagreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Agree':
                        if (item.AgreeAvg && ((item.AgreeAvg > $scope.search.agreemin) && (item.AgreeAvg < $scope.search.agreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Sort of agree':
                        if (item.sortAgreeAvg && ((item.sortAgreeAvg > $scope.search.sortagreemin) && (item.sortAgreeAvg < $scope.search.sortagreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Sort of disagree':
                        if (item.sortDisagreeAvg && ((item.sortDisagreeAvg > $scope.search.sortdisagreemin) && (item.sortDisagreeAvg < $scope.search.sortdisagreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Disagree':
                        if (item.DisagreeAvg && ((item.DisagreeAvg > $scope.search.disagreemin) && (item.DisagreeAvg < $scope.search.disagreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Very strongly disagree':
                        if (item.strongDisagreeAvg && ((item.strongDisagreeAvg > $scope.search.strongdisagreemin) && (item.strongDisagreeAvg < $scope.search.strongdisagreemax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    default:
                        return true;
                }
                return false; // otherwise it won't be within the results
            } else if ($scope.search.agree == '' || $scope.search.importance != '') {
                switch ($scope.search.importance) {
                    case 'Somewhat':
                        if (item.somewhatAvg && ((item.somewhatAvg > $scope.search.importancesmin) && (item.somewhatAvg < $scope.search.importancesmax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Important':
                        if (item.improtantAvg && ((item.improtantAvg > $scope.search.importanceimin) && (item.improtantAvg < $scope.search.importanceimax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    case 'Very Important':
                        if (item.veryImprotantAvg && ((item.veryImprotantAvg > $scope.search.importancevmin) && (item.veryImprotantAvg < $scope.search.importancevmax))) {
                            return true; // this will be listed in the results
                        }
                        break;
                    default:
                        return true;
                }
                return false; // otherwise it won't be within the results
            } else {
                return true; // otherwise it won't be within the results
            }
        };

        $scope.liveusersrecords = {};
        $scope.loadingExtra = false;
        $scope.getLiveUsers = function() {
            $scope.loadingExtra = true;
            MyService.getLiveUsers({}).then(function(payload) {
                $scope.loadingExtra = false;
                MyService.ConsoleLog(payload);
                $scope.liveusersrecords = payload;
            }, function(errpayload) {
                $scope.loadingExtra = false;
                alert("Something went wrong.");
                MyService.ConsoleLog(errpayload);
            });
        }


    }]);
