"use strict";
angular
  .module("myApp.incumbentPolitician", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/incumbent_politician/:user_type", {
        templateUrl: "app/incumbent_politician/incumbent_politician.html",
        controller: "incumbentPoliticianCtrl",
      });
    },
  ])
  .controller("incumbentPoliticianCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$filter",
    "MyService",
    "ngDialog",
    "growl",
    "tokenService",
    "$sce",
    "$cookies",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $filter,
      MyService,
      ngDialog,
      growl,
      tokenService,
      $sce,
      $cookies
    ) {
      // MyService.ConsoleLog("** compareSelectCtrl **", $routeParams);
      $rootScope.welcome = false;
      $scope.Address = false;
      $scope.Zip = true;
      $scope.Link = true;
      $scope.showAlert = false;
      $cookies.remove("elecDateForRate");
      if (tokenService.getUserId() && tokenService.getUserId() != "") {
        $rootScope.tempUserAddress = "";
        $rootScope.incuAdd = "";
      }

      $scope.usertype = $routeParams.user_type;
      $scope.search = {
        searchFilter: "",
      };
      $scope.searchUsers = [];
      $scope.searchUsersByType = [];
      $scope.sortype = "local";
      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.User = {
        Address: "",
      };

      // if (!tokenService.getUserId()) {
      //   alert('called');
      //   if ($rootScope.FinalData) {
      //     var samp = JSON.parse($rootScope.FinalData);
      //   }
      // }

      $scope.pop = true;

      function getCategories(params) {
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
        });
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.categories = response.data.data;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      }
      //getCategories();

      $scope.setAllSelected = function (users) {
        angular.forEach(users, function (val, key) {
          if (val.registeredUser) $scope.selectedUser.push(val);
        });
      };
      $scope.searchUsersByType = [];
      $scope.searchUsers = angular.copy($scope.users);
      $scope.displayTab = [];
      $scope.getByType = function (type) {
        // MyService.ConsoleLog("type : " + type);

        $scope.sortype = type;
        $scope.searchUsersByType.length = 0;
        var len = $scope.searchUsers.length;
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            if ($scope.searchUsers[i].voterScope) {
              // MyService.ConsoleLog("i : " + i + " ----- voterscope : ", $scope.searchUsers[i]);

              if (
                type == "local" &&
                ($scope.searchUsers[i].voterScope == "placeName" ||
                  $scope.searchUsers[i].voterScope == "countySubdivisionName" ||
                  $scope.searchUsers[i].voterScope ==
                    "unifiedSchoolDistrictName" ||
                  $scope.searchUsers[i].voterScope ==
                    "elementarySchoolDistrictName" ||
                  $scope.searchUsers[i].voterScope ==
                    "secondarySchoolDistrictName")
              ) {
                if ($scope.displayTab.indexOf("Local") == -1) {
                  $scope.displayTab.push("Local");
                }
                MyService.ConsoleLog("office >>", $scope.searchUsers[i].office);
                $scope.searchUsers[i].office =
                  $scope.searchUsers[i].office.toLowerCase();
                if (typeof $scope.searchUsers[i].party == "string") {
                  // alert('one');
                  $scope.searchUsers[i].party = $scope.searchUsers[i].party;
                } else {
                  var partyl = "";
                  $scope.searchUsers[i].party.forEach(function (
                    element,
                    index
                  ) {
                    if (element && element != "") {
                      partyl += element + ", ";
                      var n = partyl.lastIndexOf(",");
                      var a = partyl.substring(0, n);
                      // MyService.ConsoleLog("Element", party);
                      $scope.searchUsers[i].party = a;
                    }
                  });
                }
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              } else if (
                type == "county" &&
                ($scope.searchUsers[i].voterScope == "countyName" ||
                  $scope.searchUsers[i].voterScope == "county")
              ) {
                if ($scope.displayTab.indexOf("County") == -1) {
                  $scope.displayTab.push("County");
                }
                $scope.searchUsers[i].office =
                  $scope.searchUsers[i].office.toLowerCase();
                if (typeof $scope.searchUsers[i].party == "string") {
                  // alert('one');
                  $scope.searchUsers[i].party = $scope.searchUsers[i].party;
                } else {
                  var partyc = "";
                  $scope.searchUsers[i].party.forEach(function (
                    element,
                    index
                  ) {
                    if (element) {
                      partyc += element + ", ";
                      var n = partyc.lastIndexOf(",");
                      var a = partyc.substring(0, n);
                      // MyService.ConsoleLog("Element", party);
                      $scope.searchUsers[i].party = a;
                    }
                  });
                }
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              } else if (
                type == "state" &&
                ($scope.searchUsers[i].OfficeTypeId == "G" ||
                  $scope.searchUsers[i].OfficeTypeId == "S" ||
                  $scope.searchUsers[i].OfficeTypeId == "K" ||
                  $scope.searchUsers[i].OfficeTypeId == "L")
              ) {
                if ($scope.displayTab.indexOf("State") == -1) {
                  $scope.displayTab.push("State");
                }
                $scope.searchUsers[i].office =
                  $scope.searchUsers[i].office.toLowerCase();
                if (typeof $scope.searchUsers[i].party == "string") {
                  // alert('one');
                  $scope.searchUsers[i].party = $scope.searchUsers[i].party;
                } else {
                  var partys = "";
                  $scope.searchUsers[i].party.forEach(function (
                    element,
                    index
                  ) {
                    if (element) {
                      partys += element + ", ";
                      // MyService.ConsoleLog("Element", party);
                      var n = partys.lastIndexOf(",");
                      var a = partys.substring(0, n);
                      $scope.searchUsers[i].party = a;
                    }
                  });
                }
                $scope.searchUsersByType.push($scope.searchUsers[i]);
                /*
              if (
                $scope.searchUsers[i].office &&
                $scope.searchUsers[i].voterScope == "statePostalCode"
              ) {
                if (
                  $scope.searchUsers[i].office != "unitedStatesSenate" &&
                  $scope.searchUsers[i].office != "United States Senate"
                ) {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                } //else {
                //$scope.searchUsersByType.push($scope.searchUsers[i]);
                //}
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
              */
                // MyService.ConsoleLog("$scope.searchUsers[i] >>", $scope.searchUsers[i]);
              } else if (
                type == "federal" &&
                ($scope.searchUsers[i].OfficeTypeId == "P" ||
                  $scope.searchUsers[i].OfficeTypeId == "C" ||
                  $scope.searchUsers[i].OfficeTypeId == "J" ||
                  $scope.searchUsers[i].voterScope ==
                    "federalCongressionalDistrict" ||
                  ($scope.searchUsers[i].office != "" &&
                    $scope.searchUsers[i].office.toLowerCase() ==
                      "u.s. senate") ||
                  $scope.searchUsers[i].office == "president" ||
                  ($scope.searchUsers[i].office == "President" &&
                    $scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                      "All USA"))
              ) {
                if ($scope.displayTab.indexOf("Federal") == -1) {
                  $scope.displayTab.push("Federal");
                }
                $scope.searchUsers[i].office =
                  $scope.searchUsers[i].office.toLowerCase();
                // MyService.ConsoleLog("typeof $scope.searchUsers[i].party", typeof $scope.searchUsers[i].party);
                // MyService.ConsoleLog("party", $scope.searchUsers[i].party);
                if (typeof $scope.searchUsers[i].party == "string") {
                  // alert('one');
                  $scope.searchUsers[i].party = $scope.searchUsers[i].party;
                } else {
                  var party = "";
                  $scope.searchUsers[i].party.forEach(function (
                    element,
                    index
                  ) {
                    if (element) {
                      party += element + ", ";
                      // MyService.ConsoleLog("Element", party);
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);
                      $scope.searchUsers[i].party = a;
                    }
                  });
                }
                $scope.searchUsersByType.push($scope.searchUsers[i]);
                /*
              if (
                $scope.searchUsers[i].office &&
                $scope.searchUsers[i].voterScope == "statePostalCode"
              ) {
                if (
                  $scope.federalCongrassionalDist.searchUsers[i].office == "unitedStatesSenate" ||
                  $scope.searchUsers[i].office == "United States Senate"
                ) {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                } //else {
                //$scope.searchUsersByType.push($scope.searchUsers[i]);
                //}
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
              */
              } else if (
                type == "other" &&
                $scope.searchUsers[i].voterScope == "All USA" &&
                $scope.searchUsers[i].OfficeTypeId != "P" &&
                $scope.searchUsers[i].OfficeTypeId != "C" &&
                $scope.searchUsers[i].OfficeTypeId != "M" &&
                $scope.searchUsers[i].OfficeTypeId != "G"
              ) {
                MyService.ConsoleLog(
                  " $scope.searchUsers[i].office new",
                  $scope.searchUsers[i]
                );

                if ($scope.searchUsers[i].winDetails.length > 0) {
                  // MyService.ConsoleLog(" $scope.searchUsers[i].office", $scope.searchUsers[i]);
                  if (
                    $scope.searchUsers[i].winDetails[0].office != "president"
                  ) {
                    $scope.searchUsers[i].office =
                      $scope.searchUsers[i].winDetails[0].office.toLowerCase();
                  }
                } else {
                  // MyService.ConsoleLog(" $scope.searchUsers[i].office", $scope.searchUsers[i]);
                  if ($scope.searchUsers[i].office != "president") {
                    $scope.searchUsers[i].office =
                      $scope.searchUsers[i].office.toLowerCase();
                  }
                }

                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
            } else {
              // MyService.ConsoleLog("no voterScope", $scope.searchUsers[i]);

              var polInfoLen = $scope.searchUsers[i].politicianInfo.length;

              for (var j = 0; j < polInfoLen; j++) {
                // MyService.ConsoleLog("$scope.searchUsers[i].politicianInfo[j].runningForOffice", $scope.searchUsers[i].politicianInfo[j].runningForOffice);
                if (
                  $scope.searchUsers[i].politicianInfo[j].runningForOffice ==
                    "N" &&
                  $scope.searchUsers[i].politicianInfo[j].candidateStatus ==
                    "active"
                ) {
                  if (
                    $scope.searchUsers[i].running_for &&
                    $scope.searchUsers[i].running_for.levelOfGov &&
                    $scope.searchUsers[i].running_for.levelOfGov != ""
                  ) {
                    if (
                      type == "local" &&
                      ($scope.searchUsers[i].running_for.levelOfGov ==
                        "placeName" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "countySubDivisionName" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "unifiedSchoolDistrictName" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "elementarySchoolDistrictName" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "secondarySchoolDistrictName")
                    ) {
                      if ($scope.displayTab.indexOf("Local") == -1) {
                        $scope.displayTab.push("Local");
                      }
                      if ($scope.displayTab.indexOf("Federal") == -1) {
                        $scope.displayTab.push("Federal");
                      }
                      $scope.searchUsers[i].office =
                        $scope.searchUsers[i].office.toLowerCase();
                      if (typeof $scope.searchUsers[i].party == "string") {
                        // alert('one');
                        $scope.searchUsers[i].party =
                          $scope.searchUsers[i].party;
                      } else {
                        var party = "";
                        $scope.searchUsers[i].party.forEach(function (
                          element,
                          index
                        ) {
                          if (element) {
                            party += element + ", ";
                            var n = party.lastIndexOf(",");
                            var a = party.substring(0, n);
                            MyService.ConsoleLog("a", a);

                            $scope.searchUsers[i].party = a;
                          }
                        });
                      }
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    } else if (
                      type == "county" &&
                      ($scope.searchUsers[i].running_for.levelOfGov ==
                        "countyName" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "county")
                    ) {
                      if ($scope.displayTab.indexOf("County") == -1) {
                        $scope.displayTab.push("County");
                      }
                      $scope.searchUsers[i].office =
                        $scope.searchUsers[i].office.toLowerCase();
                      if (typeof $scope.searchUsers[i].party == "string") {
                        // alert('one');
                        $scope.searchUsers[i].party =
                          $scope.searchUsers[i].party;
                      } else {
                        var party = "";
                        $scope.searchUsers[i].party.forEach(function (
                          element,
                          index
                        ) {
                          if (element) {
                            party += element + ", ";
                            MyService.ConsoleLog("Element", party);
                            $scope.searchUsers[i].party = party;
                          }
                        });
                      }
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    } else if (
                      type == "state" &&
                      ($scope.searchUsers[i].electionOfficeTypeId == "G" ||
                        $scope.searchUsers[i].electionOfficeTypeId == "S" ||
                        $scope.searchUsers[i].electionOfficeTypeId == "K" ||
                        ($scope.searchUsers[i].electionOfficeTypeId == "L" &&
                          $scope.searchUsers[i].politicianInfo[0].levelOfGov !=
                            "countyName") ||
                        $scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                          "stateSenateDistrict" ||
                        ($scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                          "statePostalCode" &&
                          $scope.searchUsers[i].electionOfficeTypeId != "C")) &&
                      $scope.searchUsers[i].politicianInfo[0].officeName !=
                        "" &&
                      $scope.searchUsers[
                        i
                      ].politicianInfo[0].officeName.toLowerCase() !=
                        "u.s. senate"
                    ) {
                      MyService.ConsoleLog(
                        "VoterScope : ",
                        $scope.searchUsers[i]
                      );
                      if ($scope.displayTab.indexOf("State") == -1) {
                        $scope.displayTab.push("State");
                      }
                      $scope.searchUsers[i].office =
                        $scope.searchUsers[i].office.toLowerCase();
                      if (typeof $scope.searchUsers[i].party == "string") {
                        // alert('one');
                        $scope.searchUsers[i].party =
                          $scope.searchUsers[i].party;
                      } else {
                        var party = "";
                        $scope.searchUsers[i].party.forEach(function (
                          element,
                          index
                        ) {
                          if (element) {
                            party += element + ", ";
                            MyService.ConsoleLog("Element", party);
                            $scope.searchUsers[i].party = party;
                          }
                        });
                      }
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                      /*
                    if ($scope.searchUsers[i].office) {

                      if (($scope.searchUsers[i].running_for.levelOfGov == 'state' || $scope.searchUsers[i].running_for.levelOfGov == 'statePostalCode' || $scope.searchUsers[i].running_for.levelOfGov == 'stateSenateDistrict' || $scope.searchUsers[i].running_for.levelOfGov == 'stateHouseofRepresentativesDistrict') && $scope.searchUsers[i].office != 'unitedStatesSenate') {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      } else {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      }
                    } else {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }*/
                    } else if (
                      type == "federal" &&
                      ($scope.searchUsers[i].electionOfficeTypeId == "C" ||
                        $scope.searchUsers[i].electionOfficeTypeId == "P" ||
                        $scope.searchUsers[i].electionOfficeTypeId == "J" ||
                        $scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                          "federalCongressionalDistrict" ||
                        $scope.searchUsers[i].office == "president" ||
                        ($scope.searchUsers[i].office == "President" &&
                          $scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                            "All USA") ||
                        ($scope.searchUsers[i].office != "" &&
                          $scope.searchUsers[i].office.toLowerCase() ==
                            "u.s. senate" &&
                          $scope.searchUsers[i].office.toLowerCase() !=
                            "state senate"))
                    ) {
                      // MyService.ConsoleLog("samp.federalCongrassionalDist", $scope.checkFedDist.federalCongrassionalDist);
                      // var fed = $scope.checkFedDist.federalCongrassionalDist;
                      // var nDef = fed.charAt(1);
                      MyService.ConsoleLog("nDef", $scope.searchUsers[i]);

                      if ($scope.displayTab.indexOf("Federal") == -1) {
                        $scope.displayTab.push("Federal");
                      }
                      if (
                        $scope.searchUsers[i].politicianInfo[j]
                          .runningForOffice == "N"
                      ) {
                        // MyService.ConsoleLog("agai nananananananananananananananan", $scope.searchUsers[i]);
                        $scope.searchUsers[i].office =
                          $scope.searchUsers[i].politicianInfo[
                            j
                          ].officeName.toLowerCase();
                        if (
                          typeof $scope.searchUsers[i].politicianInfo[j]
                            .party == "string"
                        ) {
                          // alert('one');
                          $scope.searchUsers[i].party =
                            $scope.searchUsers[i].politicianInfo[j].party;
                        } else {
                          var party = "";
                          $scope.searchUsers[i].politicianInfo[j].party.forEach(
                            function (element, index) {
                              if (element) {
                                party += element + ", ";
                                MyService.ConsoleLog("Element", party);
                                $scope.searchUsers[i].party = party;
                              }
                            }
                          );
                        }
                        if (
                          $scope.searchUsers[i].running_for.levelOfGov ==
                            "state" &&
                          $scope.searchUsers[i].office == "unitedStatesSenate"
                        ) {
                          // if ($scope.searchUsers[i].running_for.federalCongressionalDistrict == fed || $scope.searchUsers[i].running_for.federalCongressionalDistrict == nDef) {
                          $scope.searchUsersByType.push($scope.searchUsers[i]);

                          // }
                        } else {
                          // $scope.searchUsersByType.push($scope.searchUsers[i]);
                          // if ($scope.searchUsers[i].running_for.federalCongressionalDistrict == fed || $scope.searchUsers[i].running_for.federalCongressionalDistrict == nDef) {
                          if (
                            $scope.searchUsers[i].running_for.officeName !=
                            "state senate"
                          ) {
                            $scope.searchUsersByType.push(
                              $scope.searchUsers[i]
                            );
                          }

                          // }
                        }
                      } else {
                        if ($scope.searchUsers[i].office) {
                          $scope.searchUsers[i].office =
                            $scope.searchUsers[i].office.toLowerCase();
                          if (typeof $scope.searchUsers[i].party == "string") {
                            // alert('one');
                            $scope.searchUsers[i].party =
                              $scope.searchUsers[i].party;
                          } else {
                            var party = "";
                            $scope.searchUsers[i].party.forEach(function (
                              element,
                              index
                            ) {
                              if (element) {
                                party += element + ", ";
                                MyService.ConsoleLog("Element", party);
                                $scope.searchUsers[i].party = party;
                              }
                            });
                          }
                          if (
                            $scope.searchUsers[i].running_for.levelOfGov ==
                              "state" &&
                            $scope.searchUsers[i].office == "unitedStatesSenate"
                          ) {
                            // if ($scope.searchUsers[i].running_for.federalCongressionalDistrict == fed || $scope.searchUsers[i].running_for.federalCongressionalDistrict == nDef) {
                            $scope.searchUsersByType.push(
                              $scope.searchUsers[i]
                            );

                            // }
                          } else {
                            // $scope.searchUsersByType.push($scope.searchUsers[i]);
                            // if ($scope.searchUsers[i].running_for.federalCongressionalDistrict == fed || $scope.searchUsers[i].running_for.federalCongressionalDistrict == nDef) {
                            $scope.searchUsersByType.push(
                              $scope.searchUsers[i]
                            );

                            // }
                          }
                        }
                      }

                      //else {
                      //   $scope.searchUsersByType.push($scope.searchUsers[i]);
                      // }
                    } else if (
                      type == "other" &&
                      ($scope.searchUsers[i].running_for.levelOfGov ==
                        "All USA" ||
                        $scope.searchUsers[i].running_for.levelOfGov ==
                          "all usa") &&
                      $scope.searchUsers[i].running_for.officeName !=
                        "President" &&
                      $scope.searchUsers[i].running_for.officeName !=
                        "president" &&
                      $scope.searchUsers[i].electionOfficeTypeId != "P"
                    ) {
                      // alert("if");
                      // MyService.ConsoleLog("$scope.searchUsers[i].running_for.officeName", $scope.searchUsers[i].running_for.officeName);

                      if ($scope.displayTab.indexOf("Other") == -1) {
                        $scope.displayTab.push("Other");
                      }
                      $scope.searchUsers[i].office =
                        $scope.searchUsers[i].office.toLowerCase();
                      MyService.ConsoleLog(
                        " $scope.searchUsers[i].office else",
                        $scope.searchUsers[i]
                      );
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }
                  } else if (
                    type == "other" &&
                    (($scope.searchUsers[i].politicianInfo[0].levelOfGov ==
                      "All USA" &&
                      $scope.searchUsers[i].politicianInfo[0].officeName !=
                        "President") ||
                      $scope.searchUsers[i].politicianInfo[0].officeName !=
                        "president")
                  ) {
                    // alert("else");
                    if ($scope.displayTab.indexOf("Other") == -1) {
                      $scope.displayTab.push("Other");
                    }
                    $scope.searchUsers[i].office =
                      $scope.searchUsers[i].office.toLowerCase();
                    $scope.searchUsersByType.push($scope.searchUsers[i]);
                  }
                }
              }
            }
            if (i == len - 1) {
              // MyService.ConsoleLog("FINAL : ", $scope.searchUsersByType);
              /*setTimeout(function() {
              MyService.ConsoleLog("apply");
              $(".main-table").clone(true).appendTo('.table-scroll').addClass('clone');
            }, 2000);*/
            }
          }
        }
      };

      $scope.getAddress = function () {
        $scope.Address = true;
        $scope.showAddressDropDown = false;
        $scope.Zip = false;
        $scope.Link = false;
      };

      // get Address from google api
      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // console.log($scope.lati);
            // console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            // console.log($scope.mailing_Address);
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  // document.getElementById('zip').value = place.address_components[i].long_name;
                  // $scope.User.ZipCode = place.address_components[i].long_name;
                  // console.log($scope.User.ZipCode);
                  // console.log($scope.User.ZipCode);
                }
              }
            }

            var loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // console.log(loc);
            // $rootScope.location = new Array;
            $rootScope.location = {
              longitude: $scope.lon,
              latitude: $scope.lati,
            };
            $scope.showAddressDropDowns("", loc);
          }
        );
      };

      function getUsers(params) {
        // MyService.ConsoleLog("get users");
        var request = apiCall.apiCall("GET", "/user/list", params);
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog("Got categories: ", response);
            if (response.data.success) {
              angular.forEach(response.data.data, function (val, key) {
                response.data.data[key].registeredUser = true;
              });
              $scope.setAllSelected(response.data.data);
              $scope.users = response.data.data;
              MyService.ConsoleLog("all user data", $scope.users);
              $scope.searchUsers = angular.copy($scope.users);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong.");
          }
        );
      }

      function getCompareAnswers(params) {
        var request = apiCall.apiCall("GET", "/answers/compare", params);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              angular.forEach(response.data.data, function (val, key) {
                if (
                  val.answers &&
                  val.answers.length != $scope.selectedUser.length
                ) {
                  var check_author_exist = function (u) {
                    var len = val.answers.length;
                    for (var i = 0; i < len; i++) {
                      if (val.answers[i].author._id == u._id) {
                        return true;
                      }
                    }
                    return false;
                  };

                  angular.forEach($scope.selectedUser, function (u, i) {
                    if (!check_author_exist(u)) {
                      response.data.data[key].answers.push({
                        author: u,
                      });
                    }
                  });
                } else if (!val.answers) {
                  response.data.data[key].answers = [];
                  angular.forEach($scope.selectedUser, function (u, i) {
                    response.data.data[key].answers.push({
                      author: u,
                    });
                  });
                }
              });

              $rootScope.compareAnswerList = response.data.data;
              //MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);
              $location.path(
                "question-compare/" + $routeParams.category_id + "/" + null
              );
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      }

      $scope.statusGetLocalPoliticians = 0;
      $scope.page = 0;
      $scope.users = [];
      $scope.Location = {
        state: "",
        FinalData: "",
      };
      $scope.showIncumbentLoader = false;

      $scope.getLocalPoliticians = function () {
        $scope.statusGetLocalPoliticians = 1;
        $scope.showIncumbentLoader = true;
        $scope.page += 1;
        var extradata = "";
        if ($rootScope.FinalData && $rootScope.FinalData != "") {
          extradata = $rootScope.FinalData;
          // alert("extra");
          if ($scope.MyDialog != "") {
            $scope.MyDialog.close();
          }
        }
        if ($rootScope.FinalData && $rootScope.FinalData !== "") {
          var fnlData = JSON.parse($rootScope.FinalData);
          $scope.checkFedDist = JSON.parse($rootScope.FinalData);
          MyService.ConsoleLog("extradata : ", fnlData);

          MyService.ConsoleLog("extradata : ", fnlData.statePostalCode);

          if (fnlData.statePostalCode !== "NC") {
            MyService.ConsoleLog("######## if ###########");

            MyService.getLocalIncumbentPoliForVoter({
              max_records: 100,
              page: $scope.page,
              extradata: extradata,
            }).then(
              function (response) {
                if (response.data.success) {
                  $scope.setAllSelected(response.data.data);
                  MyService.ConsoleLog("Dataaaa:::", response.data.data);

                  //var newData = [];
                  setTimeout(function () {
                    $scope.$apply(function () {
                      $scope.users = $scope.users.concat(response.data.data);
                      $scope.searchUsers = angular.copy($scope.users);
                      $scope.showIncumbentLoader = false;
                      $scope.getByType("local");

                      // MyService.ConsoleLog('final data : ', $rootScope.FinalData);
                      // for (var i = 0; i < $scope.searchUsers.length; i++) {
                      //   if ($rootScope.FinalData) {
                      //     if ($scope.searchUsers[i].address) {
                      //       if ($scope.searchUsers[i].address.place) {
                      //         if ($scope.searchUsers[i].address.place == $rootScope.FinalData.place) {
                      //           newData.push($scope.searchUsers[i]);
                      //         }
                      //       }
                      //     }
                      //   }
                      // }
                      //MyService.ConsoleLog(newData);
                    });
                  }, 100);

                  //$scope.users = response.data.data;
                  //MyService.ConsoleLog("users:::", JSON.stringify($scope.users));
                  $scope.statusGetLocalPoliticians = 2;
                } else {
                  $scope.page -= 1;
                  $scope.showIncumbentLoader = false;
                  $scope.statusGetLocalPoliticians = 3;
                  if (typeof response.data.error == "string")
                    growl.error(response.data.error);
                  else growl.error("Something went wrong");
                }
              },
              function (err) {
                // MyService.ConsoleLog("err : ", err);
                $scope.page -= 1;
                $scope.showIncumbentLoader = false;
                $scope.statusGetLocalPoliticians = 3;
                growl.error("Something went wrong");
              }
            );
          } else {
            $scope.showAlert = true;

            MyService.ConsoleLog("######## else ###########");
          }
        } else {
          MyService.getLocalIncumbentPoliForVoter({
            max_records: 100,
            page: $scope.page,
            extradata: extradata,
          }).then(
            function (response) {
              if (response.data.success) {
                $scope.setAllSelected(response.data.data);
                MyService.ConsoleLog("Dataaaa:::", response.data.data);
                //var newData = [];
                setTimeout(function () {
                  $scope.$apply(function () {
                    $scope.users = $scope.users.concat(response.data.data);
                    $scope.searchUsers = angular.copy($scope.users);
                    $scope.showIncumbentLoader = false;
                    $scope.getByType("local");

                    // MyService.ConsoleLog('final data : ', $rootScope.FinalData);
                    // for (var i = 0; i < $scope.searchUsers.length; i++) {
                    //   if ($rootScope.FinalData) {
                    //     if ($scope.searchUsers[i].address) {
                    //       if ($scope.searchUsers[i].address.place) {
                    //         if ($scope.searchUsers[i].address.place == $rootScope.FinalData.place) {
                    //           newData.push($scope.searchUsers[i]);
                    //         }
                    //       }
                    //     }
                    //   }
                    // }
                    //MyService.ConsoleLog(newData);
                  });
                }, 100);
                //$scope.users = response.data.data;
                //MyService.ConsoleLog("users:::", JSON.stringify($scope.users));
                $scope.statusGetLocalPoliticians = 2;
              } else {
                $scope.page -= 1;
                $scope.showIncumbentLoader = false;
                $scope.statusGetLocalPoliticians = 3;
                if (typeof response.data.error == "string")
                  growl.error(response.data.error);
                else growl.error("Something went wrong");
              }
            },
            function (err) {
              // MyService.ConsoleLog("err : ", err);
              $scope.page -= 1;
              $scope.showIncumbentLoader = false;
              $scope.statusGetLocalPoliticians = 3;
              growl.error("Something went wrong");
            }
          );
        }
      };

      $scope.allState = [];
      $scope.MyDialog = "";
      $rootScope.$on("ngDialog.opened", function (e, $dialog) {
        // MyService.ConsoleLog('ngDialog opened: ' + $dialog.attr('id'));
        if ($(window).width() <= 768) {
          $(".ngdialog").css("padding-top", "65px");
        }
      });
      $rootScope.$on("ngDialog.closed", function (e, $dialog) {
        // MyService.ConsoleLog('ngDialog closed: ' + $dialog.attr('id'));
        if ($(window).width() <= 768) {
          $(".ngdialog").css("padding-top", "160px");
        }
      });
      $scope.showHtmlViewer = function (content, ttl) {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allState = payload;
            $scope.states = payload;
            $scope.MyDialog = ngDialog.open({
              template: "guest_location",
              scope: $scope,
              // className: 'ngdialog-theme-default'
            });
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.showPopups = function () {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allState = payload;
            $scope.states = payload;
            $scope.MyDialog = ngDialog.open({
              template: "guest_location",
              scope: $scope,
              // className: 'ngdialog-theme-default'
            });
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyDialog1 = "";
      $scope.displayContent = "";
      // $scope.showHtmlViewer1 = function(content, ttl) {
      //             MyService.ConsoleLog(content);
      //             $scope.MyDialog1 = ngDialog.open({
      //                 template: content,
      //                 plain: true,
      //                 scope: $scope
      //                     // className: 'ngdialog-theme-default'
      //             });

      // };

      $scope.showHtmlViewer1 = function (content, ttl) {
        // MyService.ConsoleLog(content);
        $scope.displayContent = $sce.trustAsHtml(
          content.replace(/(\r\n|\n|\r)/g, "<br />")
        );
        $scope.MyDialog1 = ngDialog.open({
          template: "statement",
          scope: $scope,
          // className: 'ngdialog-theme-default'
        });
      };

      $scope.allcounties = [];
      $scope.getCounties = function (lbl) {
        MyService.ConsoleLog(
          " <<<<<<<<<<<< county >>>>>>>>>>>",
          $scope.Location.state,
          $scope.oldaddress.state
        );
        // $scope.allcounties.length = 0;
        var statePostalCode = "";
        var state = "";
        MyService.ConsoleLog("lbl >", lbl);
        if (lbl == "new") {
          // state = JSON.stringify($scope.oldaddress.state);
          statePostalCode = $scope.Location.state.stateFips;
        } else {
          statePostalCode = $scope.Location.state.stateFips;
          state = $scope.Location.state;
        }
        // if ($scope.Location.state && $scope.Location.state != "") {
        //   statePostalCode = $scope.Location.state.stateFips;
        // } else {
        //   // var nestate = JSON.parse($scope.Location);
        //   // MyService.ConsoleLog("nestate >>", nestate);
        // }

        MyService.ConsoleLog("$scope.stateFips", $scope.stateFips);

        MyService.getAllCounties($scope.stateFips, state).then(
          function (payload) {
            // MyService.ConsoleLog("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == "new") {
              var county = $scope.checkCounty(
                allcounties,
                $scope.oldaddress.county
              );
              $scope.allcounties = county;
              // MyService.ConsoleLog("county<><", county);
              $scope.Location.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        //MyService.ConsoleLog($scope.Location.state);
        //MyService.ConsoleLog($scope.Location.county);
        // var county = $scope.Location.county;

        $scope.MyPlaces.length = 0;
        var county = "";
        var statePostalCode = "";
        $scope.MyPlaces.length = 0;
        var state = "";
        var county = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }
        // MyService.ConsoleLog(statePostalCode + "===" + county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            // payload = JSON.parse(payload);
            $scope.MyPlaces = payload;
            // MyService.ConsoleLog("PlaCE :", payload);
            // MyService.ConsoleLog(payload.countySubdivision);
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // MyService.ConsoleLog("PlaCE :", payload);
            if (lbl == "new") {
              // MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // MyService.ConsoleLog("subcounty<><><", subcounty);
              $scope.subcounties = subcounty;
              $scope.places = places;
              // console.log('***********', $scope.places);
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.Location.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.submitLocation = function () {
        // MyService.ConsoleLog("@@@@ :", $scope.Location);
        //MyService.ConsoleLog($scope.Location.county);
        MyService.ConsoleLog($scope.Location.place);
        var finalData = new Object();
        if ($scope.Location.state && $scope.Location.state != "") {
          var nobj = new Object();
          nobj.statePostalCode = $scope.Location.state.statePostalCode;
          finalData.statePostalCode = $scope.Location.state.statePostalCode;
          finalData.state = $scope.Location.state.state;
        }
        if (
          $scope.Location.county &&
          $scope.Location.county.county &&
          $scope.Location.county.county != ""
        ) {
          $scope.Location.FinalData = JSON.stringify($scope.Location.county);
          finalData.county = $scope.Location.county.countyName;
        }
        if (
          $scope.Location.countySubdivision &&
          $scope.Location.countySubdivision.countySubdivision != ""
        ) {
          $scope.Location.FinalData = JSON.stringify(
            $scope.Location.countySubdivision
          );
          finalData.countySubdivision =
            $scope.Location.countySubdivision.countySubdivision;
        }
        if ($scope.Location.place && $scope.Location.place.place != "") {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.place = $scope.Location.place.placeName;
        }

        if (
          $scope.Location.cityCouncilDistrict &&
          $scope.Location.cityCouncilDistrict != ""
        ) {
          finalData.cityCouncilDistrictName =
            $scope.Location.cityCouncilDistrict.COUNTDIST;
        }
        /*
      if ($scope.Location.voterDistrict && $scope.Location.voterDistrict.voterDistrictName != "") {
        //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
        finalData.voterDistrict = $scope.Location.voterDistrict.voterDistrictName;
      }
      */
        if (
          $scope.Location.stateHouseOFRepresentativeDistrict &&
          $scope.Location.stateHouseOFRepresentativeDistrict.NAMELSAD != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          if (
            $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST &&
            $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST != ""
          ) {
            finalData.legislativeDistrict =
              $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST;
          } else {
            var SLDLSTarr =
              $scope.Location.stateHouseOFRepresentativeDistrict.NAMELSAD.split(
                " "
              );
            var SLDLST = SLDLSTarr[SLDLSTarr.length - 1];
            finalData.legislativeDistrict = SLDLST;
          }
        }

        if (
          $scope.Location.federalCongrassionalDist &&
          $scope.Location.federalCongrassionalDist.CD115FP
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          if (
            $scope.Location.federalCongrassionalDist.CD115FP ==
            "Congressional District (at Large)"
          ) {
            finalData.federalCongrassionalDist = "At-Large";
          } else {
            finalData.federalCongrassionalDist =
              $scope.Location.federalCongrassionalDist.CD115FP;
          }
        }
        if (
          $scope.Location.stateSenateDistrict &&
          $scope.Location.stateSenateDistrict.SLDUST != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.stateSenateDistrict =
            $scope.Location.stateSenateDistrict.SLDUST;
        }
        /*
      if ($scope.Location.stateLegislativeDistrict && $scope.Location.stateLegislativeDistrict.stateHouseOFRepresentativeDistrict != "") {
        //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
        finalData.legislativeDistrict = $scope.Location.stateLegislativeDistrict.stateHouseOFRepresentativeDistrict;
      }
      */
        if (
          $scope.Location.unifiedSchoolDistrictName &&
          $scope.Location.unifiedSchoolDistrictName.NAME != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.schoolDistrict =
            $scope.Location.unifiedSchoolDistrictName.NAME;
        }
        // MyService.ConsoleLog(finalData);

        //MyService.ConsoleLog($scope.Location.FinalData);
        $rootScope.FinalData = JSON.stringify(finalData);
        MyService.ConsoleLog("final data : ", $rootScope.FinalData);

        $scope.users.length = 0;
        $scope.page = 0;
        $scope.getLocalPoliticians();
      };
      // $rootScope.compareFinalData = "";
      //alert($routeParams.user_type);
      if ($routeParams.user_type) {
        if ($routeParams.user_type == "politician") {
          MyService.ConsoleLog(
            "inside politician",
            $rootScope.compareFinalData
          );

          MyService.ConsoleLog(" $rootScope.incuAdd", $rootScope.incuAdd);
          if (
            $rootScope.compareFinalData &&
            $rootScope.compareFinalData != ""
          ) {
            $rootScope.FinalData = $rootScope.compareFinalData;
            MyService.ConsoleLog("$rootScope.FinalData ", $rootScope.FinalData);
          } else if ($rootScope.incuAdd && $rootScope.incuAdd != "") {
            $rootScope.FinalData = JSON.stringify($rootScope.incuAdd);
          }
          if (
            !tokenService.getUserId() &&
            (!$rootScope.FinalData || $rootScope.FinalData == "")
          ) {
            $scope.showHtmlViewer();
          }

          if (tokenService.getUserId()) {
            var tempData = JSON.parse(tokenService.getUserData());
            MyService.ConsoleLog("tempData", tempData);
            if (
              (tempData.address &&
                tempData.address.statePostalCode &&
                tempData.address.statePostalCode != "") ||
              (tempData.address.state && tempData.address.state != "")
            ) {
              $rootScope.FinalData = "";
              $scope.pop = false;
              $scope.getLocalPoliticians();
            } else {
              $scope.pop = true;
              $scope.showPopups();
            }
          }

          if ($rootScope.FinalData && $rootScope.FinalData != "") {
            $scope.pop = false;
            $scope.getLocalPoliticians();
          }
        } else {
          /*
        getUsers({
          usertype: $routeParams.user_type
        });
        */
        }
      }

      $scope.selectedUser = [];
      $scope.toggleSelection = function (uid) {
        //MyService.ConsoleLog("toggleSelection: ", uid);
        var idx = $scope.selectedUser.indexOf(uid);
        // console.log("idxxxx  :",idx);
        // is currently selected
        if (idx > -1) {
          $scope.selectedUser.splice(idx, 1);
        } else {
          $scope.selectedUser.push(uid);
        }
      };

      $scope.selectedUserPrint = [];
      $scope.togglePrintSelection = function (uid) {
        //MyService.ConsoleLog("togglePrintSelection: ", uid);
        var idx = $scope.selectedUserPrint.indexOf(uid);
        // is currently selected
        if (idx > -1) {
          $scope.selectedUserPrint.splice(idx, 1);
        }
        // is newly selected
        else {
          $scope.selectedUserPrint.push(uid);
        }
      };

      $scope.compare = function () {
        //MyService.ConsoleLog("compare :", $scope.selectedUser);
        if ($scope.selectedUser.length <= 0) {
          alert("Please select user first.");
        } else {
          $rootScope.selectedCompareUsers = $scope.selectedUser;
          // $location.path("/question-compare/all/"+$routeParams.category_id);
          $location.path(
            "/question-compare/all/" + $rootScope.categories[0]._id
          );
        }
      };

      $scope.compareOfficeWise = function (users) {
        $rootScope.selectedCompareUsers = [];
        angular.forEach(users, function (val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val.registeredUser) {
            $rootScope.selectedCompareUsers.push(val);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedCompareUsers.length <= 0) {
          growl.error("None of the users are registered user.");
        } else {
          $location.path(
            "/question-compare/all/" + $rootScope.categories[0]._id
          );
        }
      };

      $scope.compareRatings = function (users) {
        MyService.ConsoleLog("users > ", users);
        $rootScope.selectedRatingUsers = [];
        angular.forEach(users, function (val, key) {
          MyService.ConsoleLog("key: ", key);
          MyService.ConsoleLog(" val: ", val);
          //if (val.registeredUser && val.isSelected && val.total_reviews > 0) {
          if (val.total_reviews > 0) {
            console.log("valpoliticianIds >> ", val.politicianIds);
            if (!val.politicianIds) {
              var pids = [];
              pids.push(val._id);
              val["politicianIds"] = pids;
              console.log("val >>> ", val);
            }
            $rootScope.selectedRatingUsers.push(val);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedRatingUsers.length <= 0) {
          growl.error("None of the users you selected have ratings.");
        } else {
          $location.path("/compareRatings");
        }
      };

      $scope.compareOfficeWiseSubject = function (users) {
        $rootScope.selectedCompareUsers = [];
        angular.forEach(users, function (val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val.total_answers && val.total_answers > 0) {
            $rootScope.selectedCompareUsers.push(val);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedCompareUsers.length <= 0) {
          growl.error("None of the users you selected answered questions.");
        } else {
          $location.path("/compare-subject/" + $routeParams.user_type);
        }
      };

      $scope.compareSubjectWise = function () {
        //MyService.ConsoleLog("compare :", $scope.selectedUser);
        if ($scope.selectedUser.length <= 0) {
          alert("Please select user first.");
        } else {
          $rootScope.selectedCompareUsers = $scope.selectedUser;
          $location.path("/compare-subject/" + $routeParams.user_type);
        }
      };

      $scope.printSelectedUsers = function () {
        //MyService.ConsoleLog("* printSelectedUsers *", $scope.selectedUserPrint);
        if ($scope.selectedUserPrint.length > 0) {
          $rootScope.printUsers = angular.copy($scope.selectedUserPrint);
          $location.path("/print-users");
        } else {
          growl.error("Please select user first.");
        }
      };

      $scope.searchOffice = function (office) {
        $scope.searchUsers = $scope.users.filter(function (rdata) {
          var idx = rdata.office
            .toLowerCase()
            .indexOf($scope.search.searchFilter.toLowerCase());
          if (idx > -1) return rdata;
        });
      };

      $scope.showZipLoader = false;
      $scope.showAddressDropDown = false;

      $scope.checkState = function (rdata, val) {
        MyService.ConsoleLog("State :", rdata);
        MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog("County :", rdata);
        MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.emptyOrNull = function (item) {
        return !(
          item._id.place === null ||
          item._id.place === "" ||
          item._id.place === " "
        );
      };

      $scope.detectCode = function (event) {
        // console.log("Zipcode", $scope.Location.ZipCode);
        // MyService.ConsoleLog(event);
        // MyService.ConsoleLog("keycode : ", event.keyCode);
        if (event.keyCode == 9 && event.key == "Tab") {
          $("#zipcodeinput").blur();
          $scope.showAddressDropDowns($scope.Location.ZipCode);
        }
        if (event.keyCode == 13 && event.key == "Enter") {
          $("#zipcodeinput").blur();
          $scope.showAddressDropDowns($scope.Location.ZipCode);
        }
      };

      $scope.showAddressDropDowns = function (zipcode, loc) {
        $scope.showZipLoader = true;
        // console.log(zipcode);
        MyService.getOrganizedZipData({
          zipcode: zipcode,
          candidate: true,
          loc: loc,
        }).then(
          function (payload) {
            MyService.ConsoleLog(" <<<<<< mylog >>>>>>>>>", payload);
            if (payload && payload.state) {
              // var finalData = payload.data;
              // MyService.ConsoleLog(payload[0]);
              // MyService.ConsoleLog(payload[0].countySubdivision);
              // MyService.ConsoleLog(payload[0].county);
              // MyService.ConsoleLog(payload[0].place);
              $scope.stateFips = payload.state[0].STATEFP;
              MyService.ConsoleLog("STATEFP", $scope.stateFips);
              var countySubdivisions = payload.places.filter(function (obj) {
                if (obj.COUSUBFP && obj.COUSUBFP != "") {
                  // MyService.ConsoleLog("Inside cs");
                  return obj;
                }
              });
              var places = payload.places.filter(function (obj) {
                if (obj.PLACEFP && obj.PLACEFP != "") {
                  // MyService.ConsoleLog("obj > ", obj);
                  return obj;
                }
              });

              // MyService.ConsoleLog("here");
              $scope.showZipLoader = false;
              $scope.showAddressDropDown = true;
              $scope.oldaddress.state = payload.state;
              $scope.oldaddress.county = payload.county;
              // MyService.ConsoleLog("countySubdivisions >> ", countySubdivisions);
              $scope.oldaddress.countySubdivision = countySubdivisions;
              // MyService.ConsoleLog("places >>> ",places);
              $scope.oldaddress.place = places;
              $scope.getCounties("new");

              $scope.getSubCounties("new");

              // $scope.voterDistricts = payload;
              // MyService.ConsoleLog(payload.congressionalDistrict);
              $scope.federalCongrassionalDist = payload.congressionalDistrict;
              MyService.ConsoleLog(
                "@@@@@@ drop :",
                $scope.federalCongrassionalDist
              );

              $scope.stateSenateDistrict = payload.stateSenateDistrict;
              $scope.stateHouseOFRepresentativeDistricts =
                payload.stateHouseofRepresentativesDistrict;
              $scope.unifiedSchoolDistrictNames = payload.unifiedSchoolDistrict;
              $scope.cityCouncilDistrict = payload.cityCouncilDistrict;
              //$scope.$apply();
              // MyService.ConsoleLog($scope.states);
              // MyService.ConsoleLog(payload[0].statePostalCode);
              var address = $scope.checkState($scope.states, payload.state);
              $scope.allstates = address;
              // MyService.ConsoleLog("address<>", address);
              $scope.Location.state = address[0];
              // $scope.Location.voterDistrict = $scope.voterDistricts[0];
              $scope.Location.federalCongrassionalDist =
                $scope.federalCongrassionalDist[0];
              $scope.Location.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              $scope.Location.stateHouseOFRepresentativeDistrict =
                $scope.stateHouseOFRepresentativeDistricts[0];
              $scope.Location.unifiedSchoolDistrictName =
                $scope.unifiedSchoolDistrictNames[0];
              $scope.Location.cityCouncilDistrict = $scope.cityCouncilDistrict
                ? $scope.cityCouncilDistrict[0]
                : [];
              // MyService.ConsoleLog("address ; ", address);
            } else {
              $scope.showZipLoader = false;
              alert("Zipcode not found in our database. Please correct it");
            }
          },
          function (errorPayload) {
            $scope.showZipLoader = false;
            growl.error(errorPayload);
          }
        );
      };

      $scope.checkLength = function (vdata, val) {
        // MyService.ConsoleLog("vdata : ", vdata);
        MyService.ConsoleLog("vdata", vdata);
        if (vdata && vdata.length > 0) {
          var data = vdata.map(function (sub) {
            var returnval = "";
            switch (val) {
              case "federalCongrassionalDist":
                returnval = sub.CD115FP;
                break;
              case "stateSenateDistrict":
                returnval = sub.SLDUST;
                break;
              case "stateHouseOFRepresentativeDistrict":
                returnval = sub.NAMELSAD;
                break;
              case "unifiedSchoolDistrictName":
                returnval = sub.NAME;
                break;
              case "place":
                returnval = sub.place;
                break;
              case "countySubdivision":
                returnval = sub.countySubdivision;
                break;
              case "county":
                returnval = sub.county;
                break;
              case "state":
                returnval = sub.state;
                break;
              case "cityCouncilDistrict":
                returnval = sub.COUNTDIST;
                break;
              case "default":
                returnval = sub.NAME;
                break;
            }
            return returnval;
          });

          var ndata = $filter("unique")(data);
          if (ndata.length > 1) {
            return "yellowBackground";
          } else {
            return "";
          }
        } else {
          return "";
        }
      };
      $scope.goToPoliticianThread = function (officename, users, userData) {
        //MyService.ConsoleLog("goToPoliticianThread: ", users, " officename: ", officename, " userData: ", userData);
        $rootScope.politicianThreadData = {
          // office: officename,
          users: users,
          selected_user: userData,
        };
        window.localStorage.setItem(
          "politicianThreadData",
          JSON.stringify($rootScope.politicianThreadData)
        );
        $location.path("/politician-thread/" + userData._id);
      };

      if ($rootScope.userRole && $rootScope.userRole.toLowerCase() == "voter") {
        MyService.getProfile($rootScope.UserId).then(
          function (data) {
            // body...
            try {
              var address_data = data.address;
              if ($filter("isBlankString")(address_data.voterDistrict)) {
                address_data.zipcode = data.zipcode;
                //MyService.ConsoleLog("Data: ",data);
                // ngDialog.open({
                //   template: "popups/user_localupdate.html",
                //   data: data.address
                //   // className: 'ngdialog-theme-default'
                // });
              } //isBlankString voterDistrict
            } catch (e) {
              //MyService.ConsoleLog("Exception: ", e);
            }
          },
          function (err) {
            //MyService.ConsoleLog("********* err: ", err);
          }
        );
      }
    },
  ]);
