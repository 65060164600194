'use strict';
angular.module('myApp.updateTopic', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/updateTopic/:categoryId/:subcatId/:type/:topicId', {
      templateUrl: 'app/updateTopic/updateTopic.html',
      controller: 'updateTopicCtrl'
    });
  }])
  .controller('updateTopicCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'MyService', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, MyService, ngDialog) {
    $scope.TopicButton = false;
    $rootScope.welcome = false;
    $scope.uData = JSON.parse(tokenService.getUserData());
    $scope.location = ['National', 'State', 'County', 'Local'];
    $scope.categoryId = $routeParams.categoryId;
    $scope.subcatId = $routeParams.subcatId;
    $scope.topicId = $routeParams.topicId;
    $scope.ShowLocationOptions = false;
    $scope.Topic = {
      title: '',
      description: '',
      location: 'National',
      restrictedTo: 'No Restrictions',
    };

    $scope.subCatArr = [];

    $scope.restrictedOptions = ['Locals Only', 'My County', 'My State', 'National', 'No Restrictions'];
    if (!$rootScope.UserId) {
      $location.path('signin');
    }

    if ((!$routeParams.categoryId) && (!$routeParams.subcatId)) {
      $location.path('debateHome');
    }

    if (!$routeParams.topicId) {
      $location.path('forumList/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.type);
    }

    $scope.mainCatIds = [];
    $scope.mainCatIds.push($routeParams.categoryId);
    if ($routeParams.categoryId && $routeParams.subcatId) {
      $scope.subCatArr.push($routeParams.categoryId);
      $scope.subCatArr.push($routeParams.subcatId);

    } else if ($routeParams.categoryId) {
      $scope.subCatArr.push($routeParams.categoryId);
    } else {
      $scope.subCatArr.push($routeParams.subcatId);
    }
    console.log('$scope.subCatArr :>> ', $scope.subCatArr);

    if (window.localStorage.getItem("VoteWiseCategory")) {
      $rootScope.allcategories = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
      var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);
      if (idx == -1) {
        $rootScope.allcategories.push($routeParams.subcatId);
        window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
      }
    }
    //MyService.ConsoleLog("Final categories : ", window.localStorage.getItem("VoteWiseCategory"));
    $scope.subtopics = {
      subcategories: []
    };

    $scope.selectedCat = [];
    $scope.SelectedTree = [];
    $scope.disabled = [];

    $scope.checkPostalExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.statePostalCode == val)
            return rdata
        }
      );
    }
    $scope.getTopicDetails = function () {
      MyService.getOneTopic({
        topicId: $routeParams.topicId
      }).then(function (payload) {
        //MyService.ConsoleLog("payload : ", payload);
        //$scope.froumthreads = payload;
        $scope.Topic = payload.data[0];
        MyService.ConsoleLog($scope.Topic);
        var newDescription = $scope.Topic.description;
        $scope.Topic.description = newDescription.replace("...(updated)", "");
        //MyService.ConsoleLog("newDescripttion : ", newDescription);


        var locations = $scope.Topic.location;
        var nobj = {
          country: "National (USA)"
        }

        var idx = indexOfExt($scope.selectedArea, nobj);
        if (idx > -1) {
          $scope.selectedArea.splice(idx, 1);
        }
        $scope.Essay = {
          National: "",
          Locations: ""
        };
        var len = locations.length;
        $scope.selectedArea.length = 0;
        $scope.showSubCat = true;
        $scope.disabledLocation.length = 0;
        for (var l = 0; l < len; l++) {
          var nobj = {};

          if (locations[l].country) {

            if (locations[l].country == "National (USA)") {
              $scope.Essay.National = "National";

            }
            if (locations[l].country == "International") {
              $scope.Essay.Locations = "International";
            }
            nobj.country = locations[l].country;
            // MyService.ConsoleLog("$scope.Essay", $scope.Essay);
          }
          if (locations[l].state) {
            nobj.state = locations[l].state;
            nobj.statePostalCode = locations[l].statePostalCode;
          } else if (locations[l].statePostalCode) {
            var chkdata = $scope.checkPostalExists($scope.allLocations, locations[l].statePostalCode);
            //MyService.ConsoleLog("chkdata : ",chkdata);
            if (chkdata) {

              nobj.state = chkdata[0].state;
              nobj.statePostalCode = chkdata[0].statePostalCode;
            }
          }
          if (locations[l].county) {
            nobj.county = locations[l].county;
          }
          if (locations[l].countySubdivision) {
            nobj.countySubdivision = locations[l].countySubdivision;
          }
          if (locations[l].place) {
            nobj.place = locations[l].place;
          }
          // MyService.ConsoleLog("nobj", nobj);
          $scope.selectedArea.push(nobj);
          $scope.disabledLocation.push(nobj);
        }
        MyService.ConsoleLog("$scope.selectedArea:", $scope.selectedArea);
        MyService.ConsoleLog("Disable:", $scope.disabledLocation);
        $scope.getSubcatTree();
      }, function (errorPayload) {
        //MyService.ConsoleLog(errorPayload);
        if (errorPayload.data) {
          alert("Error : " + errorPayload.data);
        } else {
          //MyService.ConsoleLog("Something went wrong");
        }

      });
    }
    $scope.getParentId = function (childId) {
      MyService.getParentCat({
        id: childId
      }).then(function (APIResponse) {
        MyService.ConsoleLog("APIResponse", APIResponse);
        if (APIResponse && APIResponse.data.success) {
          var cobj = new Object();

          cobj.path = "," + APIResponse.data.data.parentIds[0].pid + "," + childId + ",";
          cobj.pid = childId;
          cobj.viewOrder = 1;
          $scope.SelectedTree.push(cobj);

          // APIResponse.data.data.parentIds[0].pid;
        } else {
          cobj.pid = childId;
          cobj.viewOrder = 1;

          cobj.path = "," + childId + ",";
          $scope.SelectedTree.push(cobj);
        }
        MyService.ConsoleLog("second", $scope.SelectedTree);
      }, function (err) {
        MyService.ConsoleLog("err", err);
      });
    };

    $scope.isGeneral = false;
    $scope.getVal = function (data) {
      MyService.ConsoleLog(" getVal :", data);

      var idx = $scope.selectedCat.indexOf(data);
      if (idx > -1) {
        $scope.selectedCat.splice(idx, 1);
        var id = $scope.disabled.indexOf(data._id);
        $scope.disabled.splice(id, 1);
        var jdx = $scope.SelectedTree.indexOf(data.parentIds);
        if (jdx > -1)
          $scope.SelectedTree.splice(jdx, 1);

      } else {
        // MyService.ConsoleLog("ss")
        $scope.selectedCat.push(data);
        if (data && data.subcategories && data.subcategories.length > 0) {
          data.subcategories.filter(function (itm) {

            var inx = itm.title.indexOf('General');
            if (inx > -1) {
              MyService.ConsoleLog("itm", itm);
              $scope.mainCatIds.push(data._id);
              $scope.isGeneral = true;
              $scope.subId = itm._id;
              if (itm && itm.subcategories && itm.subcategories.length > 0) {
                $scope.superSub = itm.subcategories[0];
              }
              $scope.selectedCat.push(itm);
            }
          });
        }
        MyService.ConsoleLog("mainCatIds", $scope.mainCatIds);
        var id = $scope.disabled.indexOf(data._id);
        if (id == -1) {
          $scope.disabled.push(data._id);
        }
        MyService.ConsoleLog("paren", data.parentIds);
        MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
        if ($scope.SelectedTree.length == 0) {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            //angular.copy(data.parentIds, $scope.SelectedTree);
            var parents = data.parentIds;
            //MyService.ConsoleLog(parents);
            $scope.SelectedTree = parents;
          } else {
            MyService.ConsoleLog(" $scope.subId ", $scope.subId);
            var cobj = new Object;
            if ($scope.isGeneral == true && $scope.subId != '') {
              cobj.pid = data._id;
              cobj.viewOrder = 1;
              if ($scope.superSub) {
                cobj.path = "," + data._id + "," + $scope.subId + "," + $scope.superSub._id + ",";
              } else {
                cobj.path = "," + data._id + "," + $scope.subId + ",";
              }
              // cobj.path = "," + data._id + "," + $scope.subId + ",";
              $scope.SelectedTree.push(cobj);
            } else {
              // cobj.pid = data._id;
              // cobj.viewOrder = 1;

              // cobj.path = "," + data._id + ",";
              // $scope.SelectedTree.push(cobj);
              $scope.getParentId(data._id);
            }

            //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
          }
        } else {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            var parents = angular.copy(data.parentIds);
            //var parents = data.parentIds;
            var plen = parents.length;
            for (var j = 0; j < plen; j++) {
              var jdx = indexOfExt($scope.SelectedTree, parents[j]);
              //MyService.ConsoleLog(jdx);
              if (jdx == -1) {
                $scope.SelectedTree.push(parents[j]);
                // MyService.ConsoleLog("parents[j]",parents[j]);
                if ($scope.isGeneral == true && $scope.subId != '') {
                  var obj = {};
                  obj.pid = data._id;
                  obj.viewOrder = 1;
                  obj.path = "," + data._id + "," + $scope.subId + ",";
                  var sjdx = indexOfExt($scope.SelectedTree, obj);
                  if (sjdx == -1) {
                    MyService.ConsoleLog("Obj", obj);
                    $scope.SelectedTree.push(obj);
                  }
                }

              }
            }
          } else {

            var cobj = new Object;
            cobj.pid = data._id;
            cobj.viewOrder = 1;

            if ($scope.isGeneral == true && $scope.subId != '') {
              if ($scope.superSub) {
                cobj.path = "," + data._id + "," + $scope.subId + "," + $scope.superSub._id + ",";
              } else {
                cobj.path = "," + data._id + "," + $scope.subId + ",";
              }

              MyService.ConsoleLog("is", cobj.path);
              $scope.SelectedTree.push(cobj);
            } else {
              MyService.ConsoleLog("issssss");
              // cobj.path = "," + data._id + ",";
              $scope.getParentId(data._id);
            }

            //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
          }
        }
      }
      MyService.ConsoleLog("$scope.SelectedTree", $scope.SelectedTree);
    }


    $scope.selectedAll = {
      id: []
    };
    $scope.removeVal = function (data) {
      var idx = $scope.selectedCat.indexOf(data);
      MyService.ConsoleLog("data", data);
      MyService.ConsoleLog("idx", idx);
      MyService.ConsoleLog("$scope.selectedCat", $scope.selectedCat);
      if (idx > -1) {
        if ($scope.selectedCat[idx].title.indexOf('General') > -1) {

          $scope.selectedCat.filter(function (itms, ind) {
            if (itms._id == $scope.selectedCat[idx]._id) {
              MyService.ConsoleLog(" $scope.selectedCat[idx]", itms._id + "==" + $scope.selectedCat[idx]._id);
              MyService.ConsoleLog("yes", ind);
              // $scope.selectedCat.splice(ind, 1);
            }
          });
          var indx = $scope.selectedCat.indexOf(data);
          $scope.selectedCat.splice(indx, 1);
        } else {
          $scope.selectedCat.splice(idx, 1);
        }

        var id = $scope.disabled.indexOf(data._id);
        $scope.disabled.splice(id, 1);
        if (data && data.parentIds && data.parentIds.length > 0) {
          var parents = data.parentIds;

          var plen = parents.length;

          for (var j = 0; j < plen; j++) {
            var jdx = $scope.SelectedTree.indexOf(parents[j]);
            if (jdx > -1) {
              $scope.SelectedTree.splice(jdx, 1);
            } else {
              $scope.SelectedTree.filter(function (itms) {
                MyService.ConsoleLog("chek ind", itms.pid.indexOf(parents[j].pid));
                var idx = itms.pid.indexOf(parents[j].pid);
                if (idx > -1) {
                  $scope.SelectedTree.splice(idx, 1);
                  var id = $scope.disabled.indexOf(parents[j].pid);
                  $scope.disabled.splice(id, 1);
                  if ($scope.SelectedTree.length == 0) {
                    MyService.ConsoleLog("$scope.selectedCat", $scope.selectedCat);
                    $scope.selectedCat.filter(function (itm) {
                      var obj = new Object();
                      obj.pid = itm._id;
                      obj.path = "," + itm._id + ",";
                      obj.viewOrder = 1;
                      $scope.SelectedTree.push(obj);
                    });
                  }
                }

              });
            }
          }
        } else {
          $scope.SelectedTree.filter(function (itms, ind) {
            var paths = "," + data._id + ",";

            var isContaines = itms.path.includes(paths);
            // MyService.ConsoleLog("isContaines", typeof isContaines);
            if (isContaines == true) {
              MyService.ConsoleLog("isContaines", isContaines);
              MyService.ConsoleLog("ind", ind);
              $scope.SelectedTree.splice(ind, 1);
            }
          });
        }
      }
      var idsX = $scope.allParentIds.indexOf(data._id);
      $scope.allParentIds.splice(idsX, 1);
      // MyService.ConsoleLog("path cat", $scope.allParentIds);
      // $scope.selectedAll.id.length = 0;
      MyService.ConsoleLog("$scope.SelectedTree", $scope.SelectedTree);
      var allLen = $scope.allParentIds.length;
      MyService.ConsoleLog("allLen", allLen);
      var chkboxe = document.getElementsByName("checkedidcat");
      // MyService.ConsoleLog("$scope.selectedAll", $scope.selectedAll);

      for (var i = 0; i < allLen; i++) {

        chkboxe[i].checked = true;

      }

    }

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    function areEqual() {
      var len = arguments.length;
      for (var i = 1; i < len; i++) {
        if (arguments[i] == null || arguments[i] != arguments[i - 1])
          return false;
      }
      return true;
    }

    function indexOfExt(list, item) {
      var len = list.length;
      for (var i = 0; i < len; i++) {
        var keys = Object.keys(list[i]);
        var flg = true;
        var arr = [];
        for (var j = 0, s = 1; j < keys.length; j++, s++) {
          var value = list[i][keys[j]];
          //MyService.ConsoleLog("value :" + value + "-----Item : " + item[keys[j]]);
          //MyService.ConsoleLog(typeof value + "===" + typeof item[keys[j]]);
          if ((typeof value != "object") && (typeof item[keys[j]] != "object")) {
            if (item[keys[j]] !== value) {
              flg = false;
              //MyService.ConsoleLog("flg : ", typeof item[keys[j]], typeof value);
            }
          }
          if (item[keys[j]] == value) {
            arr.push(flg);
          } else if ((typeof value == "object") && (typeof item[keys[j]] == "object")) {
            arr.push(flg);
          }
        }
        if (flg == true) {
          //MyService.ConsoleLog(arr.length + "-----" + keys.length + "-----" + Object.keys(item).length);
          if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
            return i;
          }
        }
      }
      return -1;
    }

    $scope.checksubCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.pid) {
            var idx = indexOfExt($scope.selectedCat, val);
            if (idx == -1) {
              $scope.selectedCat.push(val);
            }
            return rdata
          }
        }
      );
    }

    $scope.checkCatExists = function (rdata, val) {

      return rdata.filter(function (rsdata) {

        if (rsdata._id == val) {
          MyService.ConsoleLog("rdata", rsdata._id + "====" + val);
          return rsdata;
          // MyService.ConsoleLog("parentIds : ", rsdata);
          // if (rdata.parentIds.length > 0) {
          //   var parents = rdata.parentIds.length;
          //   for (var j = 0; j < parents; j++) {
          //     var newd = angular.copy(rdata.parentIds[j]);
          //     //MyService.ConsoleLog("newd:", newd);
          //     //MyService.ConsoleLog("SelectedTree:", $scope.SelectedTree);
          //     var jdx = indexOfExt($scope.SelectedTree, newd);
          //     //MyService.ConsoleLog(jdx);
          //     if (jdx == -1) {
          //       $scope.SelectedTree.push(newd);
          //     }
          //   }
          // }
          // if (rdata.parentIds.length > 1) {
          //   var idx = indexOfExt($scope.selectedCat, angular.copy(rdata));
          //   if (idx == -1) {
          //     $scope.selectedCat.push(angular.copy(rdata));
          //   }
          // } else {
          //   $scope.selectedCat.push(angular.copy(rdata));
          // }

        } else if (rsdata.subcategories) {

          $scope.checkCatExists(rsdata.subcategories, val);
        }
      });
    };

    $scope.getSubcatTree = function () {
      $scope.selectedCat.length = 0;
      $scope.EssayButton = true;
      MyService.getAllCatTree({
        "type": "F"
      }).then(function (payload) {
          $scope.subtopics.subcategories.length = 0;
          var subcatdata = payload;
          MyService.ConsoleLog("subcatdata : ", subcatdata);
          $scope.disabled.length = 0;
          //$scope.disabled.push(window.localStorage.getItem("VoteWiseParentCategory"));
          $scope.disabled = $scope.Topic.parentCat;
          //MyService.ConsoleLog("subcat : ", $scope.disabled);
          $scope.subtopics.subcategories = subcatdata;

          MyService.ConsoleLog("$scope.Topic.", $scope.Topic.parentCat);
          // var pIds = [];
          // $scope.Topic.subCategories.filter(function (itms) {
          //   pIds.push(itms.pid);
          // });
          // MyService.ConsoleLog("pIds", pIds);
          var selectedCat = $scope.Topic.parentCat;
          // $scope.pCatId = $scope.Topic.parentCat[0];
          var catArr = [],
            selectCatsss = [];
          if (selectedCat && selectedCat.length > 0) {
            // for (var s = 0; s < selectedCat.length; s++) {
            // var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
            // MyService.ConsoleLog("checkcatdata", checkcatdata);
            // if (checkcatdata && checkcatdata.length > 0) {
            MyService.getSubCatUsingIds({
              ids: selectedCat
            }).then(function (APIResponse) {
                // MyService.ConsoleLog("APIResponse", APIResponse)
                if (APIResponse.data.success) {
                  $scope.multiCats = APIResponse.data.data;
                  MyService.ConsoleLog("$scope.", $scope.multiCats)
                  $scope.multiCats.filter(function (item) {
                    if (item && item.subcategories && item.subcategories.length > 0) {
                      // if (item._id != $scope.categoryId) {
                      var tmp = item.subcategories.filter(function (ele) {
                        if (ele.title.indexOf('General') > -1) {
                          $scope.mainCatIds.push(item._id);
                          catArr.push(ele);
                          var nwObjs = {};
                          nwObjs.pid = item._id;
                          nwObjs.viewOrder = 1;
                          nwObjs.path = "," + item._id + ',' + ele._id + ",";
                          selectCatsss.push(nwObjs);
                        }
                      });
                      // }

                    } else {
                      selectCatsss = selectCatsss.concat($scope.Topic.subCategories);
                      catArr.push(item);

                    }

                    // return item;
                  });
                  // catArr = catArr.concat(checkcatdata);
                  MyService.ConsoleLog("catArr", catArr);
                  var sml = catArr.filter(function (itms, ind) {
                    return ind == catArr.findIndex(function (ele) {
                      return JSON.stringify(ele) == JSON.stringify(itms);
                    });
                  });
                  $scope.allParentIds = sml.map(function (eles) {
                    return eles._id;
                  });
                  $scope.selectedCat = "";
                  $scope.selectedCat = sml;
                  MyService.ConsoleLog("this is ", $scope.selectedCat);
                  MyService.ConsoleLog("$scope.SelectedTree", $scope.SelectedTree);
                  $scope.SelectedTree = selectCatsss;
                }

              },
              function (err) {
                MyService.ConsoleLog("Err", err);

              });

            // }

            // }



          }
          $scope.EssayButton = false;
          setTimeout(function () {
            var cols = document.getElementsByClassName('cmnUL');
            var clen = cols.length;
            for (var i = 0; i < clen; i++) {
              cols[i].style.display = "none";
            }
          }, 300);
        },
        function (errorPayload) {
          alert('Something went wrong.');
        });
    }

    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];

    $scope.ChangeShow = function (id) {
      //$scope.showSubCat = true;
      //var cols = document.getElementsByClassName('cmnUL');
      //var clen = cols.length;

      var nclass = "MainCatLi_" + id;
      if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
        document.getElementsByClassName(nclass)[0].style.display = "block";
      } else {
        document.getElementsByClassName(nclass)[0].style.display = "none";
      }
    }

    $scope.AddLocation = false;
    $scope.newlocation = {
      country: ''
    };
    $scope.Allcountries = [];
    $scope.SelectedCountry = '';

    $scope.ShowAddLocation = function () {
      if (!$scope.AddLocation) {
        $scope.AddLocation = true;
      } else {
        $scope.AddLocation = false;
      }

    }

    function suggest_country(term) {
      var q = term.toLowerCase().trim();
      var results = [];
      var len = $scope.Allcountries.length;
      // Find first 10 states that start with `term`.
      for (var i = 0; i < len && results.length < 10; i++) {
        var country = $scope.Allcountries[i];
        if (country.toLowerCase().indexOf(q) === 0)
          results.push({
            label: country,
            value: country
          });
      }
      return results;
    }

    $scope.autocomplete_options = {
      suggest: suggest_country
    };

    $scope.getAllStates = function () {
      MyService.getAllStates().then(function (payload) {
          $scope.allLocations.length = 0;
          var subcatdata = payload;
          //MyService.ConsoleLog("countires : ", subcatdata);
          $scope.allLocations = subcatdata;


        },
        function (errorPayload) {
          alert('Something went wrong ');
        });

    }

    $scope.allLocations = [];
    $scope.allCounties = [];
    $scope.selectedArea = [];
    $scope.rmselectedArea = [];
    $scope.myselectionarea = [];
    $scope.disabledLocation = [];

    $scope.checkExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.state == val)
            return rdata
        }
      );
    }

    $scope.checkCountyExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.county == val)
            return rdata
        }
      );
    }

    $scope.checkCountryExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.country == val)
            return rdata
        }
      );
    }

    $scope.checkDisabled = function (data, stateId, type) {

      data.country = "United States of America";
      data.state = stateId;

      var idx = indexOfExt($scope.disabledLocation, data);
      if (idx > -1)
        return true;
      else
        return false;


    }

    $scope.getAreaVal = function (data, stateId, type) {

      if (!data.country) {
        data.country = "United States of America";
      }
      if (!data.state) {
        data.state = stateId;
      }



      var idx = indexOfExt($scope.selectedArea, data);


      if (idx > -1) {
        alert("You have already selected the location");
      } else {

        $scope.selectedArea.push(data);
        $scope.disabledLocation.push(data);

      }

      //MyService.ConsoleLog($scope.disabledLocation);
    }
    $scope.selectedLAll = {
      id: []
    };
    $scope.removeAreaVal = function (data) {
      var idx = $scope.selectedArea.indexOf(data);

      //MyService.ConsoleLog($scope.Topic.Locations);
      if (idx > -1) {
        //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
        if ($scope.selectedArea[idx].country == "International") {
          $scope.Topic.Locations = 'NO';
        }
        if ($scope.selectedArea[idx].country == "National (USA)") {
          $scope.Topic.National = 'NO';
        }
        $scope.selectedArea.splice(idx, 1);
        var jdx = $scope.disabledLocation.indexOf(data);
        if (jdx > -1) {
          $scope.disabledLocation.splice(jdx, 1);
        }
      }
      $scope.selectedLAll.id.length = 0;
      //MyService.ConsoleLog($scope.selectedArea);
      var allLen = $scope.selectedArea.length;
      var chkboxe = document.getElementsByName("checkedid");
      for (var i = 0; i < allLen; i++) {
        chkboxe[i].checked = true;

      }

    }
    $scope.allCounties = [];
    $scope.allLocals = [];
    $scope.setLocation = function (option) {
      $scope.Essay.location = option;
    }
    $scope.getLocations = function (val, type) {
      if (type == "National") {
        if (val == "National") {

          var nobj = {
            country: "National (USA)"
          }
          $scope.selectedArea.push(nobj);
          //MyService.ConsoleLog($scope.selectedArea);
        }
        if (val == "NO") {

          var nobj = {
            country: "National (USA)"
          }
          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      } else if (type == "International") {
        if (val == "International") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }
          $scope.selectedArea.push(nobj);

        }
        if (val == "NO") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }
          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      }
    }

    $scope.AddCountry = function () {
      if (($scope.newlocation.country) && ($scope.newlocation.country != '')) {
        var idx = $scope.Allcountries.indexOf($scope.newlocation.country);
        if (idx > -1) {
          var nobj = {
            country: $scope.newlocation.country
          };
          $scope.selectedArea.push(nobj);
        } else {
          alert("Country does not exists in our database. Please check if you have entered correct name.");
        }
      } else {
        alert("Please enter country name");
      }
    }

    $scope.getCounties = function (state) {
      $scope.showLoading.push(state);
      if (!$scope.allCounties[state] || $scope.allCounties[state].length == 0) {
        MyService.getAllCounties(state).then(function (payload) {
            $scope.showLoading.length = 0;
            var sublocations = payload;
            if (!$scope.allCounties) {
              $scope.allCounties = [];
            }
            $scope.allCounties[state] = sublocations;
          },
          function (errorPayload) {
            alert('Something went wrong.');
          });

      } else {
        $scope.showLoading.length = 0;
        $scope.allCounties[state].length = 0;
      }
    }

    $scope.getLocals = function (state, county) {
      $scope.showLoadingCounty.push(county);
      if (!$scope.allLocals[state] || !$scope.allLocals[state][county] || $scope.allLocals[state][county].length == 0) {
        MyService.getAllSubCountiesLocal(state, county).then(function (payload) {
            $scope.showLoadingCounty.length = 0;
            //MyService.ConsoleLog("sublocations Locals : ", sublocations);
            if (!$scope.allLocals[state]) {
              $scope.allLocals[state] = [];
            }
            $scope.allLocals[state][county] = payload;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {
        $scope.showLoadingCounty.length = 0;
        $scope.allLocals[state][county].length = 0;
      }
    }

    $scope.ShowLocation = function () {
      $scope.ShowLocationOptions = true;
      MyService.getAllCountries().then(function (payload) {
          $scope.Allcountries = payload;
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });
      $scope.getAllStates();
    }

    $scope.SubmitTopic = function (data) {
      if ($.fn.validateForceFully($("#topicform")) == true) {
        //$scope.TopicButton = true;
        data.parentcat = JSON.stringify($scope.disabled);
        var subcat = [];
        MyService.ConsoleLog("$scope.SelectedTree", $scope.SelectedTree);
        subcat = JSON.stringify($scope.SelectedTree);
        data.topicId = $routeParams.topicId;
        data.description = data.description + "...(updated)";
        data.subcategories = subcat;
        data.createdById = tokenService.getUserId();
        data.createdByName = tokenService.getUserName();
        data.createdByType = tokenService.getUserRole();
        if ($scope.selectedArea.length > 0) {
          data.location = JSON.stringify($scope.selectedArea);
        } else {
          var arr = [];
          var acountry = "";
          if ($scope.uData.address.country == null) {
            acountry = "United States of America";
          } else {
            acountry = $scope.uData.address.country;
          }
          var obj = {
            country: acountry,
            state: $scope.uData.address.state,
            statePostalCode: $scope.uData.address.statePostalCode,
            county: $scope.uData.address.county,
            countySubdivision: $scope.uData.address.countySubdivision,
            place: $scope.uData.address.place
          }
          arr.push(obj);
          data.location = JSON.stringify(arr);
        }


        MyService.ConsoleLog("updated Data", data);
        //MyService.ConsoleLog($scope.selectedArea);
        // return;
        MyService.updateTopic(data).then(function (response) {

          if (response.data.success) {
            if (response.data.data.nModified == 0) {
              alert("Something went wrong. Thread is not updated, please try after sometime.");
            } else {
              alert("Thread updated successfully.");
              $location.path('forumList/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.type);
            }
          } else {
            if (response.data.error) {
              alert(response.data.error);
              //MyService.ConsoleLog(response.data);
              if ((response.data.status) && (response.data.status == "405")) {
                var msg = "Do you want to send request to Admin to reactivate your account?.";
                MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                  MyService.sendActivatationMailtoAdmin().then(function (payload) {
                    alert("Request sent successfully to admin");
                  }, function (errorPayload) {
                    alert("Something went wrong");
                  })
                }, function (errorPayload) {
                  //MyService.ConsoleLog(errorPayload);
                });
              }
            }
          }
        }, function (errorPayload) {
          //MyService.ConsoleLog("Error: ", response);
        });
      }
    }

    $scope.goBack = function () {
      $location.path('forumList/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.type);


    }

    if ($routeParams.topicId) {
      $scope.ShowLocation();
      $scope.getTopicDetails();

      $("#topicform").validationEngine();
    }




  }]);
