'use strict';
angular
  .module('myApp.quizResult', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/quizResult', {
        templateUrl: 'app/quizResult/quizResult.html',
        controller: 'quizResultCtrl',
      });
    },
  ]).controller('quizResultCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      /**
       * Get user data
       */
      $scope.userId = tokenService.getUserId();
      MyService.ConsoleLog("tokenservice", $scope.userId);

      /**
       * Get quiz result
       */

      $scope.getResult = function () {

        MyService.getResultData({
          orgId: $scope.userId
        }).then(function (response) {
          MyService.ConsoleLog("response", response);
          $scope.list = response.data.data;
        }, function (err) {
          MyService.ConsoleLog("error", err);
        });
      };
      $scope.getResult();
    }
  ]);
