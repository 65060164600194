'use strict';
angular.module('myApp.endorseBallot', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/endorseBallot/:id?', {
      templateUrl: 'app/endorseBallot/endorseBallot.html',
      controller: 'endorseBallotCtrl'
    });
  }])
  .controller('endorseBallotCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl) {

    /**
     * Get params using $route params
     */
    $scope.bId = $routeParams.id;
    MyService.ConsoleLog("$scope.bId", $scope.bId);

    $scope.userId = tokenService.getUserId();
    $scope.userData = JSON.parse(tokenService.getUserData());
    // MyService.ConsoleLog("$scope.userData", $scope.userData);
    $scope.endorse = {
      toId: '',
      toName: '',
      fromId: $scope.userData.id,
      fromName: $scope.userData.name,
      fromType: '',
      isEndorse: false,
      note: ''
    };
    $scope.showLoader = false;

    /**
     * Get Ballot measures Details
     */
    $scope.selectedUser = {};
    /**
     * Get Details of ballot measures
     */
    $scope.getDetailsOfBallot = function () {
      MyService.getDetailsOfBallotMeasures({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);

        if (response) {
          $scope.selectedUser.value = response.title;
          $scope.endorse = {
            toId: response._id,
            toName: response.title,
            fromId: $scope.userData.id,
            fromName: $scope.userData.name,
            fromType: $scope.userType,
            isEndorse: false,
            note: ''
          };
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    $scope.submitEndorse = function () {
      var params = {
        toId: $scope.endorse.toId,
        toName: $scope.endorse.toName,
        fromId: $scope.endorse.fromId,
        fromName: $scope.endorse.fromName,
        fromType: $scope.endorse.fromType,
        isEndorse: true,
        note: $scope.endorse.note
      };
      MyService.ConsoleLog("params", params);

      MyService.endorseBallotMeasures(params).then(function (response) {
        MyService.ConsoleLog("Response", response);
        $location.path('/showBallotEndorsement/' + $scope.bId);
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Get current user details
     */
    $scope.getUserDetails = function () {
      MyService.getUserPublicProfile({
        id: $scope.userId,
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          MyService.ConsoleLog("welcome to profile", $scope.userType);

          $scope.getDetailsOfBallot();
        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    };
    $scope.getUserDetails();
  }]);
