'use strict';
angular.module('myApp.ratingsDetails', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ratingsDetails', {
      templateUrl: 'app/ratingsDetails/ratingsDetails.html',
      controller: 'ratingsDetailsCtrl'
    });
    $routeProvider.when('/ratingsDetails/:id/:uid?/:type?', {
      templateUrl: 'app/ratingsDetails/ratingsDetails.html',
      controller: 'ratingsDetailsCtrl'
    });
  }])
  .controller('ratingsDetailsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {
    $scope.reviewId = $routeParams.id;
    $scope.usid = $routeParams.uid;
    $scope.type = $routeParams.type;
    $scope.key = "fdbde8c0a9f34ec9607eb83ecd246836";
    MyService.ConsoleLog("uid :", $scope.usid);

    // $scope.flguid=false;
    // if($scope.usid && $scope.usid !==''){
    //   MyService.ConsoleLog("welcome to flag");
    //   $scope.flguid=true;
    // }

    $scope.$on('$viewContentLoaded', function () {
      $scope.getData();
    });

    $scope.politicianId = "";


    $scope.pdata = "";
    $scope.getData = function () {
      MyService.getReviewsById({
        id: $routeParams.id,
      }).then(function (response) {
        MyService.ConsoleLog("response >>>>>>>>>> :::", JSON.parse(response.data.data));
        if (response.data.success) {
          // response.data.data[0].links = JSON.parse(response.data.data[0].votewise.links.split(','));
          // var Ars = [];
          $scope.reviewDetails = JSON.parse(response.data.data);
          // Ars.push($scope.reviewDetails.votesmart);
          // $scope.reviewDetails.votesmart = Ars;
          console.log("reviewDetails : ", $scope.reviewDetails);
          if ($scope.reviewDetails.votesmart[0].links && $scope.reviewDetails.votesmart[0].links != '') {
            $scope.arrLinks = $scope.reviewDetails.votesmart[0].links.split(',');
            MyService.ConsoleLog("$scope.arrLinks", $scope.arrLinks);
          }

          if ($scope.reviewDetails.votesmart[0]) {
            $scope.politicianId = $scope.reviewDetails.votesmart[0].to ? $scope.reviewDetails.votesmart[0].to[0].uid : '';
          }
          if ($scope.reviewDetails.votesmart.length > 0 && $scope.reviewDetails.votesmart[0].candidateRating[0].candidateId) {
            MyService.getVotesmartRatings({
              "key": $scope.key,
              "id": $scope.reviewDetails.votesmart[0].candidateRating[0].candidateId,
              "sigid": $scope.reviewDetails.votesmart[0].sig.sigId
            }).then(function (payload) {
              // console.log("payload >> ", payload);
              if (payload.status == 200) {
                // console.log(payload.data.candidateRating);
                MyService.ConsoleLog("payload.data.candidateRating", payload.data.candidateRating);
                $scope.pdata = payload.data.candidateRating.rating;
                $scope.candiName = payload.data.candidateRating.candidate.firstName + " " + payload.data.candidateRating.candidate.lastName;

              }
            }, function (epayload) {
              console.log("epayload >> ", epayload);
            })
          }

        }
      }, function (errorPayload) {});
    }

  }]);
