'use strict';
angular.module('myApp.sponsored', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/sponsored/:id/:userid/:type?', {
      templateUrl: 'app/sponsored/sponsored.html',
      controller: 'sponsoredCtrl'
    });
  }])
  .controller('sponsoredCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {
    $scope.memberId = $routeParams.id;
    $scope.sponsorData = [];
    MyService.ConsoleLog("memberId : " + $scope.memberId);
    $scope.selectedIndex;
    $scope.memberName;

    $scope.getBillCosponsored = function() {
      MyService.ConsoleLog("In getBillCosponsored :");
        var data = {};
        data.id = $scope.memberId;
        MyService.getBillCosponsoredData(data).then(function(data) {
          MyService.ConsoleLog("getBillCosponsored success : ", data);
          $scope.memberName = data.data.data.results[0].name;
          $scope.sponsorData = data.data.data.results[0].bills;
        }, function(err) {
          MyService.ConsoleLog("getBillCosponsored error : ", err);
        });
    }
    $scope.backToProfile = function() {
      $location.path("/publicProfile/" + $routeParams.userid + "/"+$routeParams.type);
    }
    if ($routeParams.id != "") {
      $scope.getBillCosponsored();
    }
  }]);
