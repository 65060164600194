'use strict';
angular.module('myApp.gettingStartedOrg', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/gettingStartedOrg', {
      templateUrl: 'app/gettingStartedOrg/gettingStartedOrg.html',
      controller: 'gettingStartedOrgCtrl'
    });
  }])
  .controller('gettingStartedOrgCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'growl', '$cookies', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, growl, $cookies, ngDialog) {

    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;

    $scope.ShowPorifleLoading = false;
    $scope.getmyData = function (uid) {
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: uid,
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          $scope.verify = $scope.userProfile.verifiedUser;
          // MyService.ConsoleLog("welcome", $scope.verify);
          // console.log("$scope.userDetails", $scope.userDetails.userData.address);
          if (!$scope.userProfile.verifiedUser) {
            $scope.showCon = true;
          }
          // if ($scope.userProfile.userType == 'politician') {
          if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
            // $scope.getPopUp();
            if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
              if ($scope.userProfile.userType == 'politician') {
                $scope.showCon = true;
                $scope.showBoth = true;
              } else if (!$scope.userProfile.isEmailVerified) {
                $scope.showEm = true;
              }
            } else if (!$scope.userProfile.isEmailVerified) {
              // if ($scope.userProfile.userType != 'politician') {
              $scope.showEm = true;
              // }
            } else {
              if ($scope.userProfile.userType != 'politician') {
                $scope.showEm = true;
              }
            }
            if ($scope.userProfile.userType == 'politician') {
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            } else if ($scope.userProfile.userType == 'advocate' && $scope.userProfile.isEmailVerified != true) {
              // alert('cal');
              MyService.ConsoleLog("$scope.userProfile", $scope.userProfile.isEmailVerified);
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            }
          }
          // }


        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    };
    if ($cookies.get('showLongPopUp')) {
      var msg = $cookies.get('showLongPopUp');
      MyService.ConsoleLog("cookies >>>>>", msg);


      var modal = ngDialog.openConfirm({
        template: '<p>' +
          msg +
          '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">CLOSE</button>\
                  </div>',
        plain: true,
        closeByDocument: false,
        width: "70%"
      });
      modal.then(
        function fullfilled(data) {
          if (data == 1) {
            // MyService.ConsoleLog("yes")
            $cookies.remove('showLongPopUp');
            $scope.getmyData(tokenService.getUserId());
          } else {
            $cookies.remove('showLongPopUp');
          }
        },
        function rejected(data) {
          $scope.getmyData(tokenService.getUserId());

        }
      );
    } else {
      $scope.getmyData(tokenService.getUserId());

    }

    /**
     * Redirect
     */
    $scope.goto = function () {
      $location.path('/verifyBy');
    };

    /**
     * Close it
     */
    $scope.closeIt = function () {
      $scope.PopDialog.close();
    };

    /**
     * Send Verification mail
     */

    $scope.sendMail = function () {
      MyService.SendVerificationMail({
        verificationUrl: $rootScope.getVerificationUrl(),
        unsubscribeurl: $rootScope.getEmailUnsubscribeUrl()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        if (response.data.success) {
          growl.success("Please check your email");
        }
      }, function (err) {
        MyService.ConsoleLog("err", err);
      });
    };
  }]);
