'use strict';
angular.module('myApp.showFbMessage', ['ngRoute', 'angularPayments'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showFbMessage', {
      templateUrl: 'app/showFbMessage/showFbMessage.html',
      controller: 'showFbMessageCtrl'
    });
  }])
  .controller('showFbMessageCtrl', ['$scope', '$rootScope', 'growl', 'ngDialog', 'tokenService', 'MyService', '$location', '$window', '$filter', function ($scope, $rootScope, growl, ngDialog, tokenService, MyService, $location, $window, $filter) {


    /**
     * Next
     */

    $scope.next = function () {
      $location.path('/showHideAns');
    };

    /**
     * Cancel
     */

    $scope.cancel = function () {
      $location.path('/fiveCompare');
    };
  }]);
