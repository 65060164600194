'use strict';

angular.module('myApp.getPopularComparison', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/getPopularComparison/:type?', {
      templateUrl: 'app/getPopularComparison/getPopularComparison.html',
      controller: 'getPopularComparisonCtrl'
    });
  }]).controller('getPopularComparisonCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    $scope.loader = false;
    $scope.noDataFound = false;
    $scope.FBCompareList = [];
    $scope.politician = false;
    $scope.showvoter = false;
    $scope.showadv = false;
    $scope.shownonUser = false;

    if (tokenService.getUserId()) {
      $scope.userId = tokenService.getUserId();
      console.log('$scope.userId: ', $scope.userId);
      MyService.ConsoleLog("$cookies", $cookies.get('advId'));
    }



    $scope.compareWithAll = function (params) {
      $scope.loader = true;

      MyService.ConsoleLog("params >", params);
      // params.usersIds = JSON.stringify(params.usersIds);
      params.usersIds = [];
      params.usersIds.push($cookies.get('advId'));
      params.usersIds = JSON.stringify(params.usersIds);
      // params.questions = JSON.parse(params.questions);
      MyService.getPopularComparison(params).then(function (payload) {
          MyService.ConsoleLog("payload >", payload);
          $scope.loader = false;

          if (payload.data.data.unweightedMatch && payload.data.data.unweightedMatch != "") {
            $scope.FBunWeighted = payload.data.data.unweightedMatch;
            $scope.politician = true;

          }
          if (payload.data.data.advunWeighated && payload.data.data.advunWeighated != "") {
            $scope.advUnWeighated = payload.data.data.advunWeighated;
            $scope.showadv = true;

          }
          if (payload.data.data.finalunFbcombinedWeight && payload.data.data.finalunFbcombinedWeight != "") {
            $scope.unVerifiedWeighated = payload.data.data.finalunFbcombinedWeight;
            $scope.shownonUser = true;

          }
          if (payload.data.data.voterunWeight && payload.data.data.voterunWeight != "") {
            $scope.voterUnWeighated = payload.data.data.voterunWeight;
            $scope.showvoter = true;

          }
          $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
          $scope.fbmultiplier = payload.data.data.fbmultiplier;
          $scope.fbqlen = payload.data.data.qdata.length;
          $scope.unVerifiedFBunWeighted = payload.data.data.finalUnFacbook;
          $scope.unFbmultiplier = payload.data.data.unFbmultiplier;
          $scope.voterCombined = payload.data.data.voterCombWeight;
          $scope.voterMulti = payload.data.data.voterMulti;
          $scope.advCombined = payload.data.data.advCombWeight;
          $scope.advMulti = payload.data.data.advMulti;
          $scope.orgName = payload.data.data.orgName;
          if (payload.data.data && payload.data.data.qdata && payload.data.data.qdata.length > 0) {
            if ((JSON.stringify(payload.data.data.qdata[0].answers) != JSON.stringify({}))) {
              $scope.ansCnt = payload.data.data.qdata[0].answers ? payload.data.data.qdata[0].answers.advocate.length : '';
            }

          }

          if ((JSON.stringify($scope.advCombined) == JSON.stringify({}))) {
            var message = 'Sorry, there is no final comparison because the organization hasn’t answered any of the questions in their quiz.';
            var modal = ngDialog.openConfirm({
              template: '<p>' +
                message +
                '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">OK</button>\
            </div>',
              plain: true,
              closeByDocument: false,
            });
            modal.then(
              function fullfilled(data) {

              },
              function rejected(data) {
                // MyService.ConsoleLog("rejected: ", data);
                // $scope.getMatchQUestions();
              }
            );
          }
        },
        function (error) {
          MyService.ConsoleLog("error", error);
        });
    };

    if (window.localStorage.getItem('comData')) {
      // var ques = window.localStorage.getItem('quesData');
      $scope.compare = JSON.parse(window.localStorage.getItem('comData'));
      MyService.ConsoleLog("<<<<<<<<<<<< questions >>>>>>>>>>>", $scope.compare);
      $scope.compareWithAll($scope.compare);
    }

    $scope.finalSignIn = function () {

      $location.path('/fiveSaveUser');
    };

  }]);
