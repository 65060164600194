'use strict';

angular.module('myApp.getAReminder', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/getAReminder', {
         templateUrl: 'app/getAReminder/getAReminder.html',
         controller: 'getAReminderCtrl' 
     });
}])
.controller('getAReminderCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
