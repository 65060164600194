'use strict';
angular.module('myApp.debateHome', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/debateHome', {
      templateUrl: 'app/debateHome/debateHome.html',
      controller: 'debateHomeCtrl'
    });
  }])

  .controller('debateHomeCtrl', ['$scope', '$http', '$location', 'apiCall', 'baseApiUrl', 'MyService', 'tokenService', '$rootScope', 'ngDialog', function ($scope, $http, $location, apiCall, baseApiUrl, MyService, tokenService, $rootScope, ngDialog) {
    //MyService.ConsoleLog("DebateHome called");
    $scope.imgUrl = baseApiUrl + "/resources";
    $rootScope.welcome = false;
    if (tokenService.getUserData()) {
      $scope.allData = JSON.parse(tokenService.getUserData());
    }

    $scope.location = {
      options: '',
      isInternational: true,
      isNational: true,
      isState: true,
      isCounty: true,
      isLocal: true
    };
    $scope.categories = [];
    $scope.Location = {
      state: "",
      FinalData: ""
    };
    var obj = {};
    $scope.getCategories = function (params) {
      MyService.ConsoleLog("params : ", typeof params);
      MyService.ConsoleLog("$scope.location : ", $scope.location);

      if ($scope.location.options == "other") {
        var address = JSON.stringify($scope.Location.FinalData);
      } else if ($scope.allData) {
        var address = JSON.stringify($scope.allData.address);
      } else {
        var address = "";

      }


      MyService.getCategoryListWithTopics({
        "option": JSON.stringify(params),
        "address": address,
        // "checkOps": $scope.location
      }).then(function (payload) {
        //MyService.ConsoleLog("response recieved");
        $scope.categories = payload;
        //MyService.ConsoleLog(payload);
      }, function (err) {
        //MyService.ConsoleLog("Error: ",err);
      })
    }

    $scope.gotoForum = function (item) {
      MyService.ConsoleLog("item :", item);
      var id = item._id;
      window.localStorage.setItem("locationOption", JSON.stringify($scope.location));
      //MyService.ConsoleLog("Location : ",window.localStorage.getItem("locationOption"));
      $location.path('/forum/' + id + '/home');

    }

    $scope.getResult = function (val) {
      MyService.ConsoleLog("$loca", $scope.location.isState);
      // var tmp = ;
      $scope.getCategories($scope.location);

    }

    $scope.getCategories($scope.location);



    //Other option clicked

    $scope.allState = [];
    $scope.MyDialog = "";
    $scope.showHtmlViewer = function () {

      MyService.getAllStates().then(function (payload) {
          $scope.allState = payload;
          $scope.MyDialog = ngDialog.open({
            template: 'guest_location',
            scope: $scope
            // className: 'ngdialog-theme-default'
          });
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });



    };

    $scope.allcounties = [];
    $scope.getCounties = function () {
      //MyService.ConsoleLog($scope.Location.state);
      $scope.allcounties.length = 0;
      MyService.getAllCounties($scope.Location.state.statePostalCode, $scope.Location.state).then(function (payload) {
          //MyService.ConsoleLog("sublocations Locals : ", payload);
          $scope.allcounties = payload;
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }
    $scope.MyPlaces = [];
    $scope.getSubCounties = function () {
      //MyService.ConsoleLog($scope.Location.state);
      //MyService.ConsoleLog($scope.Location.county);
      var county = $scope.Location.county;
      $scope.MyPlaces.length = 0;
      MyService.getAllSubCountiesLocal($scope.Location.state.statePostalCode, county._id.county).then(function (payload) {
          $scope.MyPlaces = payload;
          //MyService.ConsoleLog("PlaCE :", payload);

        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }

    $scope.checkTagsExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {

          if (rdata.statePostalCode == val)
            return rdata;
        }
      );
    }

    $scope.submitLocation = function () {
      //MyService.ConsoleLog($scope.Location.state);
      //MyService.ConsoleLog($scope.Location.county);
      //MyService.ConsoleLog($scope.Location.place);

      if ($scope.Location.state && $scope.Location.state != "") {
        var nobj = new Object;
        nobj.state = $scope.Location.state.state;
        $scope.Location.FinalData = nobj;
      }

      if ($scope.Location.county && $scope.Location.county._id && $scope.Location.county._id.county != "") {
        var finalObj = $scope.Location.county._id;
        var fobj = $scope.checkTagsExists($scope.allState, $scope.Location.county._id.statePostalCode)
        finalObj.state = fobj[0].state;
        $scope.Location.FinalData = finalObj;
      }

      if ($scope.Location.place && $scope.Location.place._id && $scope.Location.place._id.place != "") {
        var finalObj = $scope.Location.place._id;
        var fobj = $scope.checkTagsExists($scope.allState, $scope.Location.place._id.statePostalCode);
        finalObj.state = fobj[0].state;
        $scope.Location.FinalData = finalObj;


      }

      //MyService.ConsoleLog($scope.Location.FinalData);
      //$rootScope.FinalData = $scope.Location.FinalData;
      $scope.MyDialog.close();
      $scope.getCategories('Local');
      //$scope.getLocalPoliticians();
    }

    //Other option clicked End




  }]);
