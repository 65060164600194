'use strict';
angular.module('myApp.theConstitution', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/theConstitution', {
    templateUrl: 'app/theConstitution/theConstitution.html',
    controller: 'theConstitutionCtrl'
  });
}])

.controller('theConstitutionCtrl', ['$scope','$rootScope', function($scope, $rootScope) {
	
    $rootScope.welcome = false;

}]);