'use strict';
angular
  .module('myApp.allCongressman', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/allCongressman', {
        templateUrl: 'app/allCongressman/allCongressman.html',
        controller: 'AllCongressmanCtrl'
      });
    }
  ])
  .controller('AllCongressmanCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    function ($scope, ngDialog, $rootScope, MyService, $http) {
      $scope.allCongressmanData = [];
      // $scope.more = false;
      $scope.selChamber = {
        chamber: ''
      };
      $scope.selDbPresent = {
        present: ''
      };
      $scope.selectCandidate = {
        selectCandy: 'user'
      };
      $scope.more = [];
      $scope.searchCongressmen = '';

      $scope.searchMe = function (user) {
        //MyService.ConsoleLog("in searchMe" ,user);
        var query = $scope.searchCongressmen.toLowerCase(),
          fullname = user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase();
        MyService.ConsoleLog('fullname >', fullname);
        // MyService.ConsoleLog('fullname >', users.name.toLowerCase());

        MyService.ConsoleLog('query >', query);
        if (fullname.indexOf(query) != -1) {
          return true;
        }
        return false;
      };
      $scope.propublicaData = [];
      $scope.matchMembers = function (u) {
        MyService.ConsoleLog("U >", u);
        // $scope.more = false;
        $scope.propublicaData = u;
        // console.log($scope.propublicaData);
        var fname = $scope.propublicaData.firstName;
        // MyService.ConsoleLog("matchMembers",fname);
        var lname = $scope.propublicaData.lastName;
        // MyService.ConsoleLog("matchMembers",lname);
        $scope.proPublicaId = $scope.propublicaData.proPublicaId;
        // MyService.ConsoleLog("matchMembers", $scope.proPublicaId );

        $scope.success_msg = false;

        // MyService.ConsoleLog("matchMembers");

        // MyService.ConsoleLog("proPublicaId :", $scope.proPublicaId);
        $scope.allCongressman = false;
        // $scope.allmatch = true;
        MyService.matchMembers({
          fname: fname,
          lname: lname,
          proId: $scope.proPublicaId,
          votesmartId: u.votesmartId
        }).then(
          function (payload) {
            MyService.ConsoleLog('Match Members data : ', payload);
            if (payload.data.data.conData && payload.data.data.conData != "") {
              $scope.allmatchData = "";
              $(document).ready(function () {
                $scope.success_msg = true;
                setTimeout(function () {
                  $('.alert').alert('close');
                  $scope.getAllCongressman();
                }, 2000);
                // $scope.allCongressman = true;
              });
            } else {
              if (payload.data.data.user[0] || payload.data.data.politicians[0]) {
                // MyService.ConsoleLog('Match Members data : ', payload);
                $scope.allmatchData = payload.data.data;
                // console.log("all data", $scope.allmatchData);
                if (!$scope.allmatchData['user'] || $scope.allmatchData['user'].length == 0) {
                  $scope.selectCandidate.selectCandy = 'politician';
                }
                $scope.alert_msg = false;
                // console.log("name", $scope.allmatchData.user[0].name);
              } else {
                $scope.alert_msg = true;
              }
            }
          },
          function (err) {
            MyService.ConsoleLog('Match Members error: ', err);
          }
        );
      };

      $scope.addMatchCandidates = function (id, type) {
        // alert(id);
        // MyService.ConsoleLog("proPublicaId :", $scope.proPublicaId);
        var pro_id = $scope.proPublicaId;
        MyService.addMatchedMembers({
          u_id: id,
          type: type,
          proPublicaId: pro_id
        }).then(
          function (payload) {
            $(document).ready(function () {
              $scope.success_msg = true;
              setTimeout(function () {
                $('.alert').alert('close');
                $scope.getAllCongressman();
              }, 2000);
              // $scope.allCongressman = true;
            });

            // $scope.allCongressman = true;
            // console.log(payload);
          },
          function (errorPayload) {
            console.log(errorPayload);
          }
        );
      };

      $scope.back = function () {
        // console.log("back");
        // $scope.show = false;

        $scope.allCongressman = true;
      };

      $scope.showLess = function (id) {
        // console.log("id for less",id);
        $scope.more[id] = false;
      };

      //get Candidates
      $scope.getDetailsOfCandidates = function (id) {
        $scope.more[id] = true;
      };

      $scope.showLoader = false;
      $scope.getAllCongressman = function () {
        $scope.allCongressmanData.length = 0;
        $scope.showLoader = true;
        $scope.allCongressman = true;
        // $scope.success_msg = false;

        MyService.getAllCongressman({}).then(
          function (payload) {
            MyService.ConsoleLog('payload<><><>', payload);
            $scope.showLoader = false;
            $scope.allCongressmanData = payload;
          },
          function (errorPayload) {
            $scope.showLoader = false;
          }
        );
      };

      $scope.findCongressmenMismatch = function () {
        MyService.findCongressmenMismatch({}).then(
          function (payload) {
            // MyService.ConsoleLog("addCongressman:::", payload);
          },
          function (errorPayload) {
            MyService.ConsoleLog(errorPayload);
            //$scope.showLoader = false;
          }
        );
      };

      $scope.currentCongressman = function () {
        // $scope.allCongressman.length = 0;
        $scope.showLoader = true;
        MyService.addCongressman({}).then(
          function (payload) {
            MyService.ConsoleLog('addCongressman:::', payload);
            $scope.getAllCongressman();
          },
          function (errorPayload) {
            alert('Something went wrong');
            $scope.showLoader = false;
          }
        );
      };

      if ($rootScope.showAllCongressman) {
        $scope.getAllCongressman();
      }
    }
  ]);
