'use strict';
angular.module('myApp.orgQuestions', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/orgQuestions/:quizName?', {
      templateUrl: 'app/orgQuestions/orgQuestions.html',
      controller: 'orgQuestionsCtrl'
    });
  }])
  .controller('orgQuestionsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, growl) {

    $scope.qName = $routeParams.quizName;
    // MyService.ConsoleLog("$scope", $scope.qName);




    /**
     * Get Selected Questions by organization
     */

    $scope.getQuestions = function () {
      MyService.getSelectedQues({
        orgId: tokenService.getUserId(),
        quizName: $scope.qName
      }).then(function (result) {
        MyService.ConsoleLog("result", result);
        $scope.questions = result.data.data;
        $scope.questions.sort(function (a, b) {
          var x, y;
          x = a.qOrder;
          y = b.qOrder;
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }, function (err) {
        growl.error(err);
      });
    };

    /**
     * Call this function on page load because we have to show questions list to the organization on page load
     */

    $scope.getQuestions();

    /**
     * start call when row started dragging.
     */

    $scope.start = function (event, ui, order, id, ind) {
      MyService.ConsoleLog("current order", order);
      MyService.ConsoleLog("current index", id);
      MyService.ConsoleLog("$index", ind);
      $scope.oldOrder = order;
      $scope.curId = id;
    };

    /**
     * End of start
     */

    /**
     * Drop function called when rows drop from on position to another
     */

    $scope.drop = function (event, ui, order, id, ind) {

      MyService.ConsoleLog("order", order);
      MyService.ConsoleLog("ind", id);
      MyService.ConsoleLog("$index", ind);

      MyService.updateOrder({
        curId: $scope.curId,
        newOrder: order,
        oldOrder: $scope.oldOrder,
        orgId: tokenService.getUserId()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.getQuestions();
      }, function (error) {
        MyService.ConsoleLog("error", error);
      });
    };

    /**
     * End of drop
     */

    /**
     * removeQue
     * Remove questions from quiz
     */
    $scope.removeQue = function (ele) {
      MyService.ConsoleLog("Ele", ele);
      MyService.removeOrgQues({
        orgId: ele.orgId,
        qId: ele._id
      }).then(function (result) {
        MyService.ConsoleLog("Result", result);
        growl.success("Removed Successfully");
        $scope.getQuestions();
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

  }]);
