"use strict";
angular
  .module("myApp.politicianThread", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/politician-thread/:userid", {
        templateUrl: "app/politician_thread/politician_thread.html",
        controller: "PoliticianThreadCtrl",
      });
    },
  ])
  .filter("refineDataPol", [
    "tokenService",
    function (tokenService) {
      return function (data, filterBy) {
        //MyService.ConsoleLog("data: ",data," filterBy: ",filterBy);
        var userData = tokenService.getUserData();
        if (userData) {
          userData = JSON.parse(userData);
        }
        if (!filterBy || filterBy == "all") return data;
        var tempArr = [];
        angular.forEach(data, function (val, key) {
          //MyService.ConsoleLog("key: ",key);
          if (filterBy == "following") {
            if (userData && userData.followlist) {
              if (userData.followlist.indexOf(val.createdBy.id) > -1)
                tempArr.push(val);
            }
          } else {
            if (val.createdBy.utype == filterBy) {
              tempArr.push(val);
            }
          }
        });
        //MyService.ConsoleLog("tempArr: ", tempArr);
        return tempArr;
      };
    },
  ])
  .controller("PoliticianThreadCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "growl",
    "$anchorScroll",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      growl,
      $anchorScroll
    ) {
      $rootScope.welcome = false;
      $scope.categoryId = $routeParams.categoryId;
      $scope.subcategoryId = $routeParams.subcatId;
      $scope.uId = $routeParams.userid;
      $scope.type = $routeParams.type;
      $scope.category = {};
      $scope.subcategory = {};
      $scope.DisplayData = [];
      $scope.userType = tokenService.getUserRole();
      $scope.userId = tokenService.getUserId();
      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
      }
      $scope.getUserProfile = function () {
        MyService.getProfile($scope.userData.id).then(
          function (userData) {
            $scope.usersData = userData;
            MyService.ConsoleLog("userData: ", $scope.userData);
          },
          function (err) {
            growl.error("Something went wrong while fetching user info.");
          }
        );
      };
      window.localStorage.setItem("isShowOldResult", true);
      $scope.filterArray = [
        "all",
        "politician",
        "voter",
        "press",
        "advocate",
        "following",
      ];
      $scope.sortArray = ["Newest first", "Since last login"];
      $scope.sortBy = "Select sort";
      $scope.Sticky = {
        txtOrder: 0,
      };

      if ($rootScope.ExtraTopicData) {
        if ($rootScope.ExtraTopicData.MyDiscussion) {
          $scope.DiscussionData = $rootScope.ExtraTopicData.MyDiscussion;
        }

        if ($rootScope.ExtraTopicData.ActiveData) {
          $scope.ActiveThreads = $rootScope.ExtraTopicData.ActiveData;
        }

        if ($rootScope.ExtraTopicData.HotTopics) {
          $scope.HotTopics = $rootScope.ExtraTopicData.HotTopics;
        }
      }

      if (window.localStorage.getItem("VoteWiseCategory")) {
        $rootScope.allcategories = JSON.parse(
          window.localStorage.getItem("VoteWiseCategory")
        );
        var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);

        if (idx == -1) {
          $rootScope.allcategories.push($routeParams.subcatId);
          window.localStorage.setItem(
            "VoteWiseCategory",
            JSON.stringify($rootScope.allcategories)
          );
        }
      }

      $scope.checkSticky = function (val) {
        if (val == "Y") {
          return {
            "background-color": "#fbeec0",
          };
        } else {
          return "";
        }
      };

      $scope.createNew = function () {
        $location.path("topic-politician/" + $scope.uId);
      };

      $scope.statusGetForumList = 0;
      $scope.getForumList = function () {
        if ($scope.userData) {
          var address = JSON.stringify($scope.userData.address);
        } else {
          var address = "";
        }
        var finalData = {
          taggedUserId: $routeParams.userid,
        };
        if ($scope.isRecent) {
          finalData.sort_by = $scope.isRecent;
        }
        //MyService.ConsoleLog("post Data : ", finalData);
        $scope.statusGetForumList = 1;
        var nrequest = apiCall.apiCall("POST", "/topics/getList", finalData);
        $http(nrequest).then(
          function successCallback(responseLatest) {
            //MyService.ConsoleLog("Final DataL : ",responseLatest);
            //MyService.ConsoleLog(responseLatest.data.data);
            if (responseLatest.data.success) {
              $scope.sortNw = "-createdOn";
              $scope.DisplayData = responseLatest.data.data;
              MyService.ConsoleLog("this is new", $scope.DisplayData);
              $scope.DisplayData.sort(function (a, b) {
                // MyService.ConsoleLog("am", a);
                // MyService.ConsoleLog("b", b);
                var asc = new Date(a.createdOn);
                var bss = new Date(b.createdOn);
                return bss - asc;
              });
              $scope.statusGetForumList = 2;
            } else {
              $scope.statusGetForumList = 3;
              if (
                responseLatest.data.error &&
                typeof responseLatest.data.error == "string"
              ) {
                growl.error(responseLatest.data.error);
              } else {
                growl.error("Something went wrong.");
              }
            }
          },
          function errorCallBack(responseLatest) {
            $scope.statusGetForumList = 3;
            growl.error("Error : " + responseLatest);
          }
        );
      };

      function getSubCategories() {
        MyService.getSubCategoryList($routeParams.subcatId).then(
          function (payload) {
            $scope.subcategory = payload;
          },
          function (errorPayload) {
            alert("Something went wrong. ");
          }
        );
      }

      function getCategory() {
        MyService.getCategoryList($routeParams.categoryId).then(
          function (payload) {
            $scope.category = payload;
            //MyService.ConsoleLog("subcatdata : ", subcatdata);
          },
          function (errorPayload) {
            alert("Something went wrong.");
          }
        );
      }

      $scope.gotoThread = function (id) {
        var categoryId = $routeParams.categoryId;
        var subcatid = $routeParams.subcatId;
        $location.path("forumthread/" + categoryId + "/" + subcatid + "/" + id);
      };
      if ($scope.userData && $scope.userData.id != "") {
        $scope.getUserProfile();
      }
      $scope.sortNw = "-createdOn";
      $scope.SortData = function () {
        MyService.ConsoleLog($scope.sortBy);
        if ($scope.sortBy == "Since last login") {
          if ($scope.usersData && $scope.usersData.lastLoginOld != "") {
            var lastOldLogIn = new Date($scope.usersData.lastLoginOld);

            var lens = $scope.DisplayData.length;
            for (var i = 0; i < lens; i++) {
              var lastLoginDate = new Date($scope.usersData.lastLogin);
              var createdOn = new Date($scope.DisplayData[i].createdOn);

              if (lastLoginDate <= createdOn) {
                $location.hash("anchorList" + $scope.DisplayData[i]._id);
                $anchorScroll();
                break;
              } else if (
                lastOldLogIn.toISOString().split("T")[0] <=
                createdOn.toISOString().split("T")[0]
              ) {
                // MyService.ConsoleLog("else", 'anchor' + $scope.DisplayData[i]._id);
                var old = $location.hash();
                MyService.ConsoleLog("old", old);
                // $location.hash('anchor' + $scope.DisplayData[i]._id);
                $anchorScroll("anchorList" + $scope.DisplayData[i]._id);
                break;
              }
            }
          }
        } else if ($scope.sortBy == "Chronological") {
          // $scope.getForumList();
          // $scope.sortNw = 'createdOn';
          $scope.DisplayData.sort(function (a, b) {
            // MyService.ConsoleLog("am", a);
            // MyService.ConsoleLog("b", b);
            var asc = new Date(a.createdOn);
            var bss = new Date(b.createdOn);
            return asc - bss;
          });
        } else {
          $scope.isRecent = "Newest first";
          $scope.DisplayData.sort(function (a, b) {
            // MyService.ConsoleLog("am", a);
            // MyService.ConsoleLog("b", b);
            var asc = new Date(a.createdOn);
            var bss = new Date(b.createdOn);
            return bss - asc;
          });
        }
        //alert("Sort Data called");
      };

      $scope.updatePostApi = function (data, options, idex) {
        var request = apiCall.apiCall("POST", "/topics/update", data);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog(response);
            if (response.data.data.nModified == 0) {
              alert("Something went wrong. Please try after sometime.");
            } else {
              $scope.getForumList();
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", JSON.stringify(response));
          }
        );
      };

      $scope.gotoUpdate = function (item) {
        $location.path(
          "/updateTopic/" +
            $routeParams.categoryId +
            "/" +
            $routeParams.subcatId +
            "/" +
            $routeParams.type +
            "/" +
            item._id
        );
      };

      $scope.objectKeys = function (obj) {
        return Object.keys(obj);
      };

      $scope.updatePost = function (option, id) {
        var data = {};
        if (option == "sticky") {
          data.poststicky = 1;
          data.poststickyOrder = $scope.Sticky.txtOrder[id];
          data.topicId = id;
        } else {
          data.removepostSticky = 1;
          data.topicId = id;
        }
        //MyService.ConsoleLog(data);
        $scope.updatePostApi(data);
      };

      /*if ($routeParams.categoryId) {
        getCategory();
        getSubCategories();

    }*/
      if ($routeParams.userid) {
        $scope.getForumList();
      }
    },
  ]);
