'use strict';
angular.module('myApp.exportOrgRatings', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/exportOrgRatings', {
      templateUrl: 'app/exportOrgRatings/exportOrgRatings.html',
      controller: 'ExportOrgRatingsCtrl'
    });
  }]).controller('ExportOrgRatingsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window) {

    $scope.loader = false;

    /**
     * @description Function is use to export records of rating organizations.
     */
    $scope.exportData = function () {
      $scope.loader = true;
      MyService.exportOrgRatingData().then(function (payload) {
        //MyService.ConsoleLog("Success Data", payload);
        $scope.loader = false;
        $window.open($scope.imgUrl + '/ratingOrganizations.xlsx', '_blank');
      }, function (errorPayload) {

        //MyService.ConsoleLog("errorPayload : ", errorPayload);
        alert("Something went wrong. Please try again after sometime");
      })
    }
  }]);
