'use strict';
angular.module('myApp.showBallotEndorsement', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showBallotEndorsement/:id?', {
      templateUrl: 'app/showBallotEndorsement/showBallotEndorsement.html',
      controller: 'showBallotEndorsementCtrl'
    });
  }])
  .controller('showBallotEndorsementCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl, ngDialog) {

    /**
     * Get params using routeparams
     */
    $scope.bId = $routeParams.id;
    MyService.ConsoleLog(" $scope.bId", $scope.bId);


    /**
     * Get Details of ballot measures
     */
    $scope.getDetailsOfBallot = function () {
      MyService.getDetailsOfBallotMeasures({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        if (response) {
          $scope.title = response.title;
          $scope.getData();
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Get Reviews of the bill
     */
    $scope.getData = function () {

      MyService.getListOfEndorsers({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("Reposne", response);
        $scope.allEndorsements = response.data.data;
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Sort name wise
     */
    $scope.sortSearch = function (val) {
      var sortOrder = "";
      MyService.ConsoleLog(val);
      if (val == 'name') {
        MyService.ConsoleLog("nameUp > ", $scope.nameUp);
        if ($scope.nameUp == "" || $scope.nameUp == "DESC") {
          sortOrder = "ASC";
          $scope.nameUp = true;
          $scope.nameDown = false;
          $scope.allEndorsements.sort(function (a, b) {
            var x = a.endorseBy[0].name.toLowerCase();
            var y = b.endorseBy[0].name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

        } else {
          sortOrder = "DESC";
          $scope.nameDown = true;
          $scope.nameUp = false;
          $scope.allEndorsements.sort(function (a, b) {
            var x = a.endorseBy[0].name.toLowerCase();
            var y = b.endorseBy[0].name.toLowerCase();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
        }
      } else {
        $scope.nameUp = false;
        $scope.nameDown = false;
      }
    };
    /**
     * Show notes
     */
    $scope.MyDialog1 = "";
    $scope.showHtmlViewer = function (content, ttl) {
      // console.log(content);
      $scope.displayContent = $sce.trustAsHtml(content.replace(/(\r\n|\n|\r)/g, "<br />"));
      $scope.MyDialog1 = ngDialog.open({
        template: 'statement',
        scope: $scope
        // className: 'ngdialog-theme-default'
      });
    };
    $scope.getDetailsOfBallot();
  }]);
