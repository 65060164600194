'use strict';
angular.module('myApp.questionSearch', ['ngRoute', 'ngDialog'])
  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/question-search', { //pass user_id = me to see questions tagged to self
      templateUrl: 'app/questionSearch/questionSearch.html',
      controller: 'QuestionSearchCtrl'
    });
  }]).controller('QuestionSearchCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', '$filter', 'tokenService', '$anchorScroll', '$timeout', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, $filter, tokenService, $anchorScroll, $timeout) {
    //MyService.ConsoleLog("** QuestionSearchCtrl ** :", $routeParams);
    $rootScope.welcome = false;
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
    }
    $scope.traverseBackgrounds = [];
    $scope.currBackgroundIndex = 0;
    $scope.status = {
      questions: 0, //1= loading, 2 = loaded, 3 = error
      category: 0
    };
    $scope.flagSkipAnsweredQuestion = true;

    $scope.currentIndex = 0;
    $scope.initializeVariables = function () {
      $scope.importance = null;
      $scope.answer = null;
      $scope.comment = '';
      $scope.reason = '';
    };
    $scope.initializeVariables();


    $scope.initMyAnswer = function () {
      try {
        if ($scope.questions[$scope.currentIndex].my_answer) {
          var my_answer = $scope.importance = $scope.questions[$scope.currentIndex].my_answer;
          $scope.importance = my_answer.importance;
          $scope.answer = my_answer.answer;
          $scope.comment = my_answer.comment;
        } else {
          $scope.initializeVariables();
        }
      } catch (e) {
        $scope.initializeVariables();
        //MyService.ConsoleLog("Exceptoin: ", e)
      }
    };

    $scope.viewOldAnswers = function (old_ans) {
      ngDialog.open({
        template: 'app/popups/view_old_answers.html',
        controller: 'OldAnswerViewerCtrl',
        data: old_ans
      });
    };

    if ($rootScope.userRole == "advocate") {
      var request = apiCall.apiCall('GET', '/user/list', {
        "usertype": "politician"
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.tagableUsers = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }
    $scope.addTags = function (tagUsers) {
      //MyService.ConsoleLog("tagUsers: ", tagUsers);
      //MyService.ConsoleLog("tagUsers: ", $scope.questions[$scope.currentIndex]._id);

      var postData = {
        id: $scope.questions[$scope.currentIndex]._id,
        user_ids: tagUsers.join(",")
      };

      var request = apiCall.apiCall('POST', '/questions/add_tag', postData);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("succss: ", response);
        if (response.data.success) {
          growl.success("Tagged successfully");
        } else {
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallback(response) {
        growl.error("Something went wrong");
      });
    };

    var getQuestionsCanceler = $q.defer();
    $scope.AllAnsweredQuestions = [];

    function getQuestions(params) {
      getQuestionsCanceler.resolve();
      $scope.status.questions = 1;
      $scope.questions = [];
      getQuestionsCanceler.resolve();
      getQuestionsCanceler = $q.defer();
      MyService.ConsoleLog("QuestionSearchCtrl 2** /questions/list: ", params);
      var request = apiCall.apiCall('GET', '/questions/list', params);
      request.timeout = getQuestionsCanceler.promise;
      $http(
        request
      ).then(function successCallback(response) {
        MyService.ConsoleLog("QuestionSearchCtrl Got categories: ", response);
        if (response.data.success) {
          $scope.status.questions = 2;
          $scope.initMyAnswer();
          MyService.ConsoleLog("data len: ", response.data.data);
          $scope.questions = response.data.data;
          if ($scope.flagSkipAnsweredQuestion) {
            angular.forEach(response.data.data, function (val, key) {
              if (val.myAnswer == null) {
                // $scope.questions.push(val);
              } else {
                $scope.AllAnsweredQuestions.push(val);
              }

            });
            $scope.skipAnswerChagne();
          }

          //MyService.ConsoleLog("total questions: ", $scope.questions.length);
          if ($scope.questions.length > 0) {
            try {
              //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
              var tempCate = $scope.questions[$scope.currentIndex].categories;
              if (angular.isArray(tempCate)) {
                $scope.getCategory({
                  id: tempCate[0].cid
                });
              } else {
                $scope.getCategory({
                  id: tempCate.cid
                });
              }
            } catch (e) {
              //MyService.ConsoleLog("category sel Ex: ", e)
            }
          }
        } else {
          $scope.status.questions = 3;
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    }

    var getCategoryCanceler = $q.defer();
    var prevFetchedCategoryId = null;

    $scope.getCategory = function (params) {
      //MyService.ConsoleLog("getCategory : ", params);
      try {
        prevFetchedCategoryId = $scope.category._id
      } catch (e) {}

      if (prevFetchedCategoryId != params.id) {
        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        getCategoryCanceler.resolve();
        getCategoryCanceler = $q.defer();
        $scope.status.category = 1;
        $scope.category = null;
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = getCategoryCanceler.promise;
        $http(
            request
          )
          .then(function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.category = response.data.data;
              //MyService.ConsoleLog("category data: ", $scope.category);
              prevFetchedCategoryId = $scope.category._id;
              $scope.status.category = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.category = 3;
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.category = 3;
          });
      }
    };

    var getLevelCategoryCanceler = $q.defer();

    function getLevelCategory(params) {
      getLevelCategoryCanceler.resolve();
      getLevelCategoryCanceler = $q.defer();
      $scope.status.levlCat = 1;
      var request = apiCall.apiCall('GET', '/categories/list-layer', params);
      request.timeout = getLevelCategoryCanceler.promise;
      $http(
          request
        )
        .then(function successCallback(response) {
          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            /*
            angular.forEach(response.data.data, function(val, key){
                if(val.total_questions > 0){
                    $scope.traverseBackgrounds.push(val);
                }
            });
            //MyService.ConsoleLog("traverseBackgrounds data: ", $scope.traverseBackgrounds);
            if($scope.traverseBackgrounds.length > 0){
                $scope.currBackgroundIndex = 0;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            }
            */
            $scope.traverseBackgrounds = response.data.data;
            if ($routeParams.selectedCatId) {
              //MyService.ConsoleLog("traverseBackgrounds: ", $scope.traverseBackgrounds);
              //MyService.ConsoleLog("$routeParams.selectedCatId: ", $routeParams.selectedCatId);
              angular.forEach($scope.traverseBackgrounds, function (val, key) {
                if (val._id == $routeParams.selectedCatId) {
                  //MyService.ConsoleLog("val._id: ", val._id, " key: ", key, " total_questions: ", val.total_questions);
                  $scope.currBackgroundIndex = key;
                  //MyService.ConsoleLog("$scope.currBackgroundIndex: ", key);
                  $scope.category = angular.copy(val);
                }
              });
            } else if ($scope.traverseBackgrounds.length > 0) {
              $scope.category = $scope.traverseBackgrounds[0];
            }
            $scope.status.levlCat = 2;
          } else {
            //MyService.ConsoleLog("Something went wrong: ", response.data);
            $scope.status.levlCat = 3;
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
          $scope.status.levlCat = 3;
        });
    }

    function getPopularQuestions() {
      //MyService.ConsoleLog("** getPopularQuestions **");
      var categoryIds = $routeParams.categoryId.split("popular:")[1];

      var request = apiCall.apiCall('GET', '/answers/popular', {
        "categoryIds": categoryIds
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.questions = response.data.data;
          if ($scope.questions.length > 0) {
            $scope.getCategory({
              id: $scope.questions[0].categories.cid
            });
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }

    var getPopularCategoriesCanceler = $q.defer();
    $scope.getPopularCategories = function (params) {
      //MyService.ConsoleLog("** getPopularQuestions **");
      getPopularCategoriesCanceler.resolve();
      params.timeout = getPopularCategoriesCanceler.promise;
      var request = apiCall.apiCall('POST', '/categories/popular-subcategories', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.traverseBackgrounds = response.data.data;
          if ($scope.traverseBackgrounds.length > 0) {
            $scope.category = $scope.traverseBackgrounds[0];
            prevFetchedCategoryId = $scope.category._id;
            // getQuestions({categoryId: $scope.category._id});
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.notAnswerableSelected = function () {
      /*
      if($scope.answer == 0){
          var message = 'Many of our Statements have an undeniable fact followed by an opinion.  Like “the sky is blue.  I hate the sky.”  If this is your problem with the question, then realize that this is just part of the question process and just respond to the second half.  If not, please explain the problem in the “add comments” section so we can fix it.  Thank you.';
          var modal = ngDialog.openConfirm({
              template:'<p>'+message+'</p>\
                      <div class="ngdialog-buttons">\
                          <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                      </div>',
              plain: true
          });

          modal.then(function fullfilled(data){
              //MyService.ConsoleLog("fullfilled: ",data);
          }, function rejected(data){
              //MyService.ConsoleLog("rejected: ",data);
          });
      }
      */
      //for report question
      var token = apiCall.getToken();

      if (!token || token == "") {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }
      var postData = {
        questionId: $scope.questions[$scope.currentIndex]._id,
        importance: 0, //$scope.importance,
        answer: $scope.answer,
        comment: $scope.comment
      };

      ngDialog.open({
        template: 'app/popups/report_question.html',
        controller: 'ReportQuesCtrl',
        data: postData
      });

    };

    $scope.postAnswer = function (postData) {
      //MyService.ConsoleLog("postData: ", postData);
      try {
        var my_answer = $scope.questions[$scope.currentIndex].my_answer;
        if (my_answer.importance != $scope.importance || my_answer.answer != $scope.answer || my_answer.comment != $scope.comment) {
          //MyService.ConsoleLog("Answer changed");
          postData.id = my_answer._id;
          postData.reason = $scope.reason;
        } else {
          //MyService.ConsoleLog("Answer not changed");
          return; //don't do anything
        }
      } catch (e) {
        //MyService.ConsoleLog("Exceptoin: ", e);
      }

      MyService.postAnswer(postData).then(function (response) {
        if (response.data.success) {
          try {
            if (postData.id) {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: postData.id,
                created: new Date(),
                comment: postData.comment,
                answer: postData.answer,
                importance: postData.importance
              };
            } else {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: response.data.data._id,
                created: response.data.data.created,
                comment: response.data.data.comment,
                answer: response.data.data.answer,
                importance: response.data.data.importance
              };
            }
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin: ", e);
          }

          if (postData.id) {
            growl.success("Answer updated successfully", {
              ttl: 800
            });
          } else {
            growl.success("Answered successfully", {
              ttl: 800
            });
          }
          $scope.initializeVariables();
          $scope.skipQuestion();
        } else {
          growl.error("Error: " + response.data.error);
        }
      }, function (err) {
        growl.error("Something went wrong.");
      });


      /*
      var request = apiCall.apiCall('POST', '/answers/create', postData);
        $http(
          request
        ).then(function successCallback(response) {
          //MyService.ConsoleLog("succss: ",response);
          if(response.data.success){
            try{
                $scope.questions[$scope.currentIndex]["my_answer"] = {
                    _id: response.data.data._id,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance
                };
            }
            catch(e){
                //MyService.ConsoleLog("Exceptoin: ",e);
            }
            growl.success("Answered successfully", {ttl: 800});
            $scope.initializeVariables();
            $scope.skipQuestion();
          }
          else{
            growl.success("Error: "+response.data.error);
          }
        }, function errorCallback(response) {
          $scope.error = 'Wrong Email or Password';
        });
        */

    };

    $rootScope.$on('question.answered', function (evt, data) {
      //MyService.ConsoleLog("on question.answered: ", data);
      if ($scope.questions[$scope.currentIndex]._id == data.questionId) {
        $scope.initializeVariables();
        $scope.skipQuestion();
      }
    });

    $scope.submitAnswer = function () {
      //MyService.ConsoleLog("submitAnswer: " + $scope.answer);
      //MyService.ConsoleLog("importance: " + $scope.importance);
      //MyService.ConsoleLog("comments: ", $scope.comment);

      var token = apiCall.getToken();

      if (!token || token == "") {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });
        return;
      }

      if ($scope.answer == null) {
        var message = "Please select answer first.";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }

      if ($scope.importance == null) {
        var message = "You need to choose an importance level.  Should they fight hard for this can they use it to trade for other things you care about more?";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }

      /*
        var modal = ngDialog.openConfirm({
            template:'<p>Are you sure you want to close the parent dialog?</p>\
                      <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                        <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                       </div>',
            plain: true
        });
        //MyService.ConsoleLog("modal: ",modal);
        modal.then(function fullfilled(data){
            //MyService.ConsoleLog("fullfilled: ",data);
        }, function rejected(data){
            //MyService.ConsoleLog("rejected: ",data);
        });
        */
      var postData = {
        questionId: $scope.questions[$scope.currentIndex]._id,
        importance: $scope.importance,
        answer: $scope.answer,
        comment: $scope.comment,
        token: token
      };
      $scope.postAnswer(postData);
    };

    $scope.skipQuestion = function () {
      /*
      $timeout(function() {
        $location.hash('DisplayQ');
        $anchorScroll();
      }, 1500);
      */
      $("html, body").animate({
        scrollTop: $("#DisplayQ").offset().top - 280
      }, "slow");
      var skip = function () {
        var tempIndex = $scope.currentIndex + 1;
        MyService.ConsoleLog("questions > ", $scope.questions);
        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();
          $scope.currentIndex++;
          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            //we are showing questions of most popular section
            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        MyService.ConsoleLog("tempIndex123 = ", tempIndex)
        MyService.ConsoleLog("questions.length ", $scope.questions.length);
        if (tempIndex == $scope.questions.length) {
          MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            $scope.currBackgroundIndex++;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            MyService.ConsoleLog("* End of background *");
            var catId = $routeParams.categoryId;
            if (!$routeParams.categoryId) {
              catId = $scope.questions[$scope.currentIndex].categories[0].cid;
            }
            //$location.path('/questionSubcategories/' + catId);
            $location.path("/questionHome");
          }
        }
      };

      if ($scope.answer != null) {
        var show_answer_modal = true;
        try {
          var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];
          /*
          for (var key in my_answer) {
            if(my_answer[])
          }
          */
          if (my_answer.importance == $scope.importance && my_answer.answer == $scope.answer && my_answer.comment == $scope.comment)
            show_answer_modal = false;

        } catch (e) {
          //MyService.ConsoleLog("Exceptoin : ", e);
        }
        //MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
        if (show_answer_modal) {
          var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                            </div>',
            plain: true,
            closeByDocument: false
          });

          modal.then(function fullfilled(data) {
            //submit answer
            $scope.submitAnswer();
          }, function rejected(data) {
            //skip the answer
            skip();
          });
        } else {
          skip();
        }
      } else {
        skip();
      }
    };
    $scope.prevQuestion = function () {
      //MyService.ConsoleLog("** prevQuestion **");
      // $timeout(function() {
      //   $location.hash('DisplayQ');
      //   $anchorScroll();
      // }, 1500);

      if ($scope.currentIndex > 0) {
        $scope.currentIndex--;
      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
        }
      }
    };

    $scope.compareChanged = function (compare_with) {
      //MyService.ConsoleLog("compare_with :", compare_with);
      var catid = $routeParams.categoryId;
      if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
        var catid = $routeParams.categoryId.split("popular:")[1];
      }

      $location.path('/compare-select/' + compare_with);
    };

    var getCompareAnswersCanceler = $q.defer();

    function getCompareAnswers(params) {
      if (!params) return;
      getCompareAnswersCanceler.resolve();
      params.timeout = getCompareAnswersCanceler.promise;

      $scope.questions = [];
      $scope.status.questions = 1;

      var request = apiCall.apiCall('GET', '/answers/compare', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.status.questions = 2;
          angular.forEach(response.data.data, function (val, key) {
            if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
              var check_author_exist = function (u) {
                var len = val.answers.length;
                for (var i = 0; i < len; i++) {
                  if (val.answers[i].author._id == u._id) {
                    return true;
                  }
                }
                return false;
              };
              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                if (!check_author_exist(u)) {
                  response.data.data[key].answers.push({
                    author: u
                  });
                }
              });
            } else if (!val.answers) {
              response.data.data[key].answers = [];
              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                response.data.data[key].answers.push({
                  author: u
                });
              });
            }
          });
          $scope.questions = response.data.data;
          // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
        } else {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.initPageData = function () {
      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);
      if (current_url.indexOf("question-search") > -1) {
        $scope.state = "question-search";

        if (!$filter('isBlankString')($rootScope.searchQuestionKeyword)) {
          var param = {
            keyword: $rootScope.searchQuestionKeyword,
          };
          getQuestions(param);
        }
      }
    };

    $scope.initPageData();


    $scope.checkQuestion = function (val) {
      if ($scope.AllAnsweredQuestions && $scope.AllAnsweredQuestions.length > 0) {
        return $scope.AllAnsweredQuestions.filter(
          function (rdata) {
            MyService.ConsoleLog(rdata._id + "==" + val);
            if (rdata._id == val)
              return rdata
          }
        );
      } else {
        if ($rootScope.fiveMatch) {
          return $rootScope.fiveMatch.filter(
            function (rdata) {
              MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
              if (rdata.questionId == val)
                return rdata
            }
          );
        }

      }

    }

    $scope.checkIfExists = function (val) {
      if ($scope.questions) {
        var obj = $scope.questions[$scope.currentIndex];
        // MyService.ConsoleLog("checkIfExists >> ", obj);
        if (obj) {
          var data = $scope.checkQuestion(obj._id);

          if (data && data[0]) {
            // MyService.ConsoleLog("checkIfExists data>> ", data[0]);
            $scope.comment = data[0].comment;
            if (data[0].answer && data[0].answer != "") {
              if (data[0].answer == val) {
                // console.log("return True");
                // $scope.comment = data[0].comment;
                return true;
              }
            } else {
              if (data[0].myAnswer && data[0].myAnswer.answer == val) {
                // console.log("return myAnswer True");
                // $scope.comment = data[0].myAnswer.comment;
                return true;
              }
            }

          }
        }
        return false;
      } else {
        return false;
      }
    }

    $scope.checkQuestionAnswered = function () {
      // console.log("scope.questions > ", $scope.questions);
      if ($scope.questions) {
        return $scope.questions.filter(
          function (rdata) {
            // MyService.ConsoleLog(rdata._id);
            var data = $scope.checkQuestion(rdata._id);
            if (data && data.length > 0)
              return rdata;
          }
        );
      } else {
        return false;
      }
    }

    $scope.checkImpExists = function (val) {
      if ($scope.questions) {
        var obj = $scope.questions[$scope.currentIndex];

        if (obj) {
          var data = $scope.checkQuestion(obj._id);

          if (data && data[0]) {
            // MyService.ConsoleLog("checkIfExists data>> ", data[0].importance + "==" + val);
            if (data[0].importance && data[0].importance != "") {
              if (data[0].importance == val) {
                // console.log("return True");
                return true;
              }
            } else {
              if (data[0].myAnswer && data[0].myAnswer.importance == val) {
                // console.log("return myAnswer True");
                return true;
              }
            }

          }
        }
        return false;
      } else {
        return false;
      }
    }


    $scope.cachedQuestions = [];

    $scope.saveFiveMinAnswers = function (skip) {
      // MyService.ConsoleLog("saveFiveMinAnswers : ");
      // MyService.ConsoleLog($rootScope.fiveMatch);
      $scope.pstData = [];
      var len = $rootScope.fiveMatch.length;
      if (len > 0) {
        for (var i = 0, k = 1; i < len; i++) {
          if (skip != "" && skip == "skip") {
            $scope.answer = 0;
            $scope.importance = 0;
            $scope.comment = "";
            // MyService.ConsoleLog("saveFiveMinAnswers >> ", $rootScope.fiveMatch[i]);
            var data = $scope.checkQuestion($rootScope.fiveMatch[i]._id);
            if (data && data[0]) {
              // MyService.ConsoleLog("getMatchQUestions >>> ", data[0]);
              if (data[0].answer != "") {
                $scope.answer = data[0].answer;
              }
              if (data[0].importance != "") {
                $scope.importance = data[0].importance;
              }
              if (data[0].comment != "") {
                $scope.comment = data[0].comment;
              }

            }
            var postData = {
              questionId: $rootScope.fiveMatch[i]._id,
              importance: $scope.importance,
              answer: $scope.answer,
              comment: $scope.comment,
              categories: $rootScope.fiveMatch[i]["categories"],
              content: $rootScope.fiveMatch[i]["content"],
              _id: $rootScope.fiveMatch[i]["_id"]
            };
            $scope.pstData.push(postData);
          } else {
            var postData = $rootScope.fiveMatch[i];
          }

          // console.log("postData1 > ", $rootScope.fiveMatch[i]);
          // console.log("postData > ", postData);
          MyService.postAnswer(postData).then(function (response) {
            // MyService.ConsoleLog("saveFiveMinAnswers response >>>", response);
            if (response.data.success) {
              MyService.ConsoleLog("Answer updated successfully");

            } else {
              MyService.ConsoleLog("Error: " + response.data.error);
            }


            // console.log(k + "===" + len);
            if (k == len) {
              // console.log("redirect to five compare");
              if (skip != "" && skip == "skip") {
                $rootScope.fiveMatch = angular.copy($scope.pstData);
                window.localStorage.setItem("fiveMatch", JSON.stringify($rootScope.fiveMatch));
              }
              // console.log("$rootScope.fiveMatch > ", $rootScope.fiveMatch);
              $location.path('/fiveCompare');
            }
            k++;
          }, function (err) {
            MyService.ConsoleLog("Error: ");
          });
        }
      }
    }

    $scope.skipAnswerChagne = function () {
      MyService.ConsoleLog("scope.flagSkipAnsweredQuestion: ", $scope.flagSkipAnsweredQuestion);
      $scope.currentIndex = 0;
      $("html, body").animate({
        scrollTop: $("#DisplayQ").offset().top - 280
      }, "slow");
      var questions = $scope.checkQuestionAnswered();
      // $rootScope.fiveMatch.length = 0;
      // console.log('questions > ', questions);
      if (questions && questions.length > 0) {
        if ($scope.flagSkipAnsweredQuestion) {
          $scope.cachedQuestions.length = 0;
          $scope.cachedQuestions = angular.copy($scope.questions);
          for (var i = 0; i < questions.length; i++) {
            var idx = $scope.questions.indexOf(questions[i]);
            if (idx > -1) {
              $scope.questions.splice(idx, 1);
            }
          }
          // console.log("questionslength > ", $scope.cachedQuestions.length + "==" + questions.length)
          if ($scope.cachedQuestions.length == questions.length) {
            // console.log("questionslength Equal");
            if ($rootScope.fiveMatch.length == 0) {
              $rootScope.fiveMatch = angular.copy($scope.cachedQuestions);
              // MyService.ConsoleLog(" $rootScope.fiveMatch one :", $rootScope.fiveMatch);
              var lents = $rootScope.fiveMatch.length;
              if (lents >= 5) {
                // $scope.skipToRes = true;
                $('#skipMatch').attr('disabled', false);
              }
              $scope.questions = angular.copy($scope.cachedQuestions);
            }
            $scope.saveFiveMinAnswers("skip");
          } else {
            $rootScope.fiveMatch = angular.copy(questions);
            var lent = $rootScope.fiveMatch.length;
            if (lent >= 5) {
              // $scope.skipToRes = true;
              $('#skipMatch').attr('disabled', false);
            }
            // $scope.saveFiveMinAnswers("skip");
            // MyService.ConsoleLog(" $rootScope.fiveMatch two >>>>>>>>> :", $rootScope.fiveMatch);
            // MyService.ConsoleLog(" $rootScope.fiveMatch two >>>>>>>>> :", lent);
          }
        } else {
          $scope.questions = angular.copy($scope.cachedQuestions);
        }
      } else {
        // console.log("Else Else ");
        if (!$scope.flagSkipAnsweredQuestion) {
          if ($scope.cachedQuestions && $scope.cachedQuestions.length > 0) {
            $scope.questions = angular.copy($scope.cachedQuestions);
          }

        }
      }
    };

    /*$scope.$watch('category', function(newValue, oldValue) {
        var current_url = $location.url();

        if(current_url.indexOf("question-tagged") > -1)
            return; //for tagged questions don't again fetch questions

        if(newValue){
            //MyService.ConsoleLog("oldValue: ", oldValue);
            //MyService.ConsoleLog("newValue: ", newValue);
            if(oldValue && oldValue._id == newValue._id) return;
            //MyService.ConsoleLog("scrollTop");
            $("html, body").animate({ scrollTop: 0 }, "slow");
            $scope.currentIndex = 0;

            if(current_url.indexOf("question-compare") > -1){
                //MyService.ConsoleLog("** getCompareAnswers **");

                var temp_uids = [];
                angular.forEach($rootScope.selectedCompareUsers, function(val, key){
                    temp_uids.push(val._id);
                });

                getCompareAnswers({"categoryIds": newValue._id, "userIds": temp_uids.join(",")}); //"compare_with": $routeParams.user_type,
            }
            else{
                getQuestions({categoryId: newValue._id});
            }
        }
    });*/
    $scope.$watch('questions', function (newValue, oldValue) {
      try {
        MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }

        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("questions Index Change Ex: ", e)
      }
    });
    $scope.$watch('currentIndex', function (newValue, oldValue) {
      try {
        //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("Current Index Change Ex: ", e)
      }
    });
    $scope.$on("$destroy", function (event) {
      $rootScope.searchQuestionKeyword = '';
    });
  }])
