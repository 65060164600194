'use strict';

angular.module('myApp.orgQuizComparison', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/orgQuizComparison/:type?/:quizName?', {
      templateUrl: 'app/orgQuizComparison/orgQuizComparison.html',
      controller: 'orgQuizComparisonCtrl'
    });
  }])

  .controller('orgQuizComparisonCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    $scope.showAlert = false;

    $scope.quizName = $routeParams.quizName;
    $scope.showcomparison = false;

    if (tokenService.getUserData()) {
      var userData = JSON.parse(tokenService.getUserData());
      // console.log("userData > ", userData);
    }
    var orgs;
    if ($cookies.get('sendEmail')) {
      var isData = JSON.parse($cookies.get('sendEmail'));
      //MyService.ConsoleLog("isData", isData);
      if ($cookies.get('orgTitle')) {
        orgs = JSON.parse($cookies.get('orgTitle'));

      }
      if (isData && isData != '') {
        $cookies.remove('sendEmail');
        var ansLink = window.location.origin + '/#!/showPoliticianAns/' + $scope.quizName;
        var filData = $rootScope.fiveMatch.filter(function (itm) {
          if (itm && itm.questionId && itm.questionId != '') {
            return itm;
          }
        });
        // alert('inside');
        MyService.sendEmailToOrg({
          quizName: $scope.quizName,
          orgEmail: isData,
          orgData: orgs,
          ansLink: ansLink,
          ansQues: filData.length
        }).then(function (result) {
          //MyService.ConsoleLog("result", result);
        }, function (err) {
          //MyService.ConsoleLog("Error", err);
        });
      }
    }

    // if (!$cookies.get('isShowPopUp')) {
    if ($cookies.get('showNotice')) {

      if ($cookies.get('orgTitle')) {
        $scope.orgData = JSON.parse($cookies.get('orgTitle'));
        $scope.orgName = $scope.orgData.title;
        MyService.ConsoleLog(" $scope.orgData", $scope.orgData);
        // alert('callled');
      } else {
        $scope.orgName = "Organization";
      }
    }
    // }

    /**
     * Get more quizzes
     */
    $scope.moreQuizzes = function () {
      $location.path('/quizzes');
    };

    /**
     * See contact info of the org
     */
    $scope.seeContact = function () {
      $location.path('/pressProfile/' + $scope.orgData._id);
    };

    /**
     * @description
     */

    $scope.seeComparison = function () {
      $location.path('/popularQuizMatch');
    };
  }]);
