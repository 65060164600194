'use strict';
angular.module('myApp.endorsePolitician', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/endorsePolitician', {
      templateUrl: 'app/endorsePolitician/endorsePolitician.html',
      controller: 'endorsePoliticianCtrl'
    });

  }]).controller('endorsePoliticianCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl) {


    /**
     * Get list of Endorse Politicians
     */
    $scope.getEndorseList = function () {
      MyService.getEndorsePoliList({
        orgId: tokenService.getUserId()
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        $scope.endorseList = response.data.data;
      }, function (error) {
        // MyService.ConsoleLog("")
        growl.error(error);
      });
    };

    $scope.getEndorseList();

    /**
     * Remove Politician from endorse table
     */

    $scope.removeEndorse = function (val) {
      // alert(val);
      MyService.removePoliFromEndorse({
        pId: val,
        orgId: tokenService.getUserId()
      }).then(function (result) {
        MyService.ConsoleLog('result', result);
        growl.success(result.data.data);
        $scope.getEndorseList();
      }, function (err) {
        growl.error(err);
      });
    };
  }]);
