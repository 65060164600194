'use strict';
angular.module('myApp.signup')

//.config(['$routeProvider', function ($routeProvider) {
//    $routeProvider.when('/verify/:id', {
//        templateUrl: 'signup/verify.html',
//        controller: 'verifyCtrl'
//    });
//}])

.controller('verifyCtrl', ['$scope', '$http', '$location', 'baseApiUrl', 'growl', '$routeParams', 'tokenService', '$rootScope', function($scope, $http, $location, baseApiUrl, growl, $routeParams, tokenService, $rootScope) {

    $scope.encrypted = $routeParams.id;
    $scope.Show = false;
    $scope.Error = false;
    $scope.userData = "";
    if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
    }
//console.log($scope.userData);
    $scope.verify = function() {
        $http.post(baseApiUrl + '/user/verify', {
            salt: $scope.encrypted
        }).then(function(res) {
            if (res.data.success == true) {
                $scope.Show = true;
                if ($scope.userData) {
                    $scope.userData.isemailverified = true;
                    tokenService.saveUserData(JSON.stringify($scope.userData));
                }

                $scope.Error = false;
            } else {
                $scope.Error = true;
            }
        });
    };

    $scope.verify();
}]);
