'use strict';
angular.module('myApp.polProfileSetUp', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/polProfileSetUp', {
      templateUrl: 'app/polProfileSetUp/polProfileSetUp.html',
      controller: 'polProfileSetUpCtrl'
    });
  }])
  .controller('polProfileSetUpCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
