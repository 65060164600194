'use strict';
angular.module('myApp.highlightedPost', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/highlightedPost/:pressId', {
      templateUrl: 'app/highlightedPost/highlightedPost.html',
      controller: 'highlightedPostCtrl'
    });
  }])
  .controller('highlightedPostCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'baseApiUrl', 'MyService', 'tokenService', 'growl', '$templateCache', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, baseApiUrl, MyService, tokenService, growl, $templateCache, ngDialog) {
    $scope.pressId = $routeParams.pressId;
    MyService.ConsoleLog(" $scope.pressId", $scope.pressId);
    $scope.showAdvocate = true;
    $scope.showfAdvocate = true;
    /**
     * Fetch Highlighted post by advocate
     */

    $scope.getHighlightedPost = function () {
      MyService.getHighlighatedPost({
        pId: $scope.pressId
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.HighlightedPost = response.data;
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };
    $scope.getHighlightedPost();
  }]);
