"use strict";

angular
  .module("myApp.fiveMinEssayAnswers", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/fiveMinEssayAnswers", {
        templateUrl: "app/fiveMinEssayAnswers/fiveMinEssayAnswers.html",
        controller: "fiveMinEssayAnswersCtrl",
      });
    },
  ])

  .controller("fiveMinEssayAnswersCtrl", [
    "$scope",
    "apiCall",
    "MyService",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$rootScope",
    "ngDialog",
    "$q",
    "$http",
    "$filter",
    "growl",
    "$routeParams",
    "baseApiUrl",
    "tokenService",
    "$cookies",
    function (
      $scope,
      apiCall,
      MyService,
      $location,
      $anchorScroll,
      $timeout,
      $rootScope,
      ngDialog,
      $q,
      $http,
      $filter,
      growl,
      $routeParams,
      baseApiUrl,
      tokenService,
      $cookies
    ) {
      MyService.ConsoleLog("show essay listing");
      window.localStorage.setItem("isShowOldResult", true);

      if (window.localStorage.getItem("essayAnswers")) {
        $scope.essayAns = JSON.parse(
          window.localStorage.getItem("essayAnswers")
        );
      }
    },
  ]);
