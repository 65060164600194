'use strict';

angular.module('myApp.welcomeView', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/welcome', {
        templateUrl: 'app/welcomeView/welcomeView.html',
        controller: 'welcomeCtrl'
    });
}])

.controller('welcomeCtrl', ['$scope', 'apiCall', '$location', '$anchorScroll', '$timeout', '$rootScope', function($scope, apiCall, $location, $anchorScroll, $timeout, $rootScope) {
    // var returningUser = apiCall.getToken();
    //
    // if (returningUser) {
    //   $location.path('/debateHome');
    // }
    //$scope.showFacebookLoging = false;
    $rootScope.welcome = true;
    //console.log("outside :", $rootScope.showFacebookLoging);
    $scope.ogTitle = "VoteWise.net - In life, you can vote with wallet, with your voice, with your actions and at the ballot box. But it's important to always vote wisely.";
  $scope.ogDescription = "VoteWise.net is a non-partisan non-profit tool aimed at repairing democracy by connecting voters, politicians and organizations without advertising dollars getting in the way.  There are different tools available to different types of users, so click on a user type below, watch the 2 minute video and start saving the world.";
  $scope.ogImage = "https://votewise.net/assets/images/VoteWiseMeta.jpg";

    $scope.ShowFB = function() {
    	//if()
    	//console.log("inside :", $rootScope.showFacebookLoging);
        $rootScope.showFacebookLoging = !$rootScope.showFacebookLoging;
        $location.hash('bottom');
            $anchorScroll();
        //$location.hash('signupform');
        //$anchorScroll();
        //if($scope.showFacebookLoging) {
        // $timeout(function() {
            
        //console.log("inside after scroll :", $rootScope.showFacebookLoging);
        // }, 1500);

        //}
    }

}]);
