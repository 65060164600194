"use strict";
angular
  .module("myApp.followingUsers", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/followingUsers", {
        templateUrl: "app/followingUsers/followingUsers.html",
        controller: "FollowingCtrl"
      });
    }
  ]).controller('FollowingCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'growl', '$cookies', 'apiCall', '$http', 'ngDialog', function ($scope, $location, $rootScope, tokenService, MyService, growl, $cookies, apiCall, $http, ngDialog) {
    //MyService.ConsoleLog("* Following *");
    $scope.loginUser = tokenService.getUserId();
    $scope.allData = JSON.parse(tokenService.getUserData());
    $scope.fData = $scope.allData.followlist;
    $scope.userId = tokenService.getUserId();
    $rootScope.tempUserAddress = $scope.allData.address;
    $scope.users = {
      data: []
    };
    $scope.showList = true;
    $scope.isExist = false;
    $cookies.remove('quizTakerId');
    window.localStorage.removeItem('quesData');
    window.localStorage.removeItem('comData');
    window.localStorage.removeItem('inviterId');
    window.localStorage.removeItem('inviterInfo');

    /**
     * @description Function is use to check if the quiz taker is exist or not
     */
    $scope.checkIfQuizTakerExist = function () {
      MyService.checkIfQuizTakerExist({
        inviterId: $scope.loginUser
      }).then(function (APIResponse) {
        MyService.ConsoleLog("APIResponse", APIResponse);
        if (APIResponse.data.success) {
          $scope.result = APIResponse.data.data;
          MyService.ConsoleLog("$scope", $scope.result);
          $scope.categoryId = $scope.result[0].selCatId;
          $scope.followersIds = $scope.result.map(function (itms) {
            if (itms && itms.quizTakerArr) {
              return itms.quizTakerArr;
            }
          });

          MyService.ConsoleLog("$scope.followersIds", $scope.followersIds);
          $scope.getFollowersList();
        } else {
          // growl.error(APIResponse.data.error);
        }

      }, function (err) {
        // growl.error(err);
        MyService.ConsoleLog("Error", err);
      });
    };


    $scope.getFollowersList = function () {
      MyService.getFollowersList({
        orgId: $scope.loginUser
      }).then(
        function (response) {
          MyService.ConsoleLog('response', response.data);
          $scope.followersCnt = response.data.followersCount;
          $scope.followersData = response.data.followersData;
        },
        function (err) {
          MyService.ConsoleLog('error', err);
        }
      );
    };




    /**
     * @description Show pop up with the url quiz
     */
    $scope.shareQuiz = function (val) {
      MyService.ConsoleLog("val", val);

      if ($scope.userId && $scope.userId != '') {

        var tempLink = $location.absUrl().split("#!");
        if (val && val.mainCatId != null && val.selCatId != '') {
          $scope.shareLink = tempLink[0] + "#!/exploreQuiz/" + val.mainCatId + "/" + val.selCatId + "/" + $scope.userId;
          var modalUrl = ngDialog.openConfirm({
            template: 'exporeQuizLink',
            scope: $scope,
            width: "60%"
          });
          modalUrl.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);

            },
            function rejected(data) {

            }
          );
        } else {
          var request = apiCall.apiCall('GET', '/categories/list', {
            id: val.selCatId
          });
          request.headers['Cache-Control'] = 'No-Store';
          $http(
            request
          ).then(function successCallback(response) {
            MyService.ConsoleLog("Got categories: ", response);
            if (response.data.success) {
              var cat = response.data.data;
              if (cat && cat != '') {
                $scope.shareLink = tempLink[0] + "#!/exploreQuiz/" + cat.parentIds[0].pid + "/" + val.selCatId + "/" + $scope.userId;

                var modalUrl = ngDialog.openConfirm({
                  template: 'exporeQuizLink',
                  scope: $scope,
                  width: "60%"
                });
                modalUrl.then(
                  function fullfilled(data) {
                    //MyService.ConsoleLog("fullfilled: ", data);

                  },
                  function rejected(data) {

                  }
                );
              }
            } else {
              //MyService.ConsoleLog("Something went wrong: ",response.data);
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ",response);
          });
        }

      }
    };

    // $scope.getFollowers = function () {
    //   $scope.tmpArr = [];
    //   $scope.showList = !$scope.showList;
    //   var tmo = $scope.followersIds.filter(function (eles) {
    //     var len = eles.length;
    //     for (var i = 0; i < len; i++) {
    //       $scope.tmpArr.push(eles[i]);
    //     }
    //   });
    //   MyService.ConsoleLog("$scope.tmpArr", $scope.tmpArr);
    //   var remvDuplicates = $scope.tmpArr.filter(function (vals, ind) {
    //     return ind == $scope.tmpArr.findIndex(function (eles) {
    //       return JSON.stringify(eles) == JSON.stringify(vals);
    //     });
    //   });
    //   MyService.ConsoleLog("remvDuplicates", remvDuplicates);
    //   MyService.getAllUsersData({
    //     "followlist": JSON.stringify(remvDuplicates)
    //   }).then(function (APIResponse) {
    //     MyService.ConsoleLog("APIResponse : ", APIResponse);
    //     $scope.followers = APIResponse;

    //   }, function (error) {
    //     //MyService.ConsoleLog(error);
    //   })
    // };

    $scope.getUserDetails = function () {
      MyService.getUserPublicProfile({
        id: $scope.loginUser,
        type: 'false'
      }).then(function (response) {
        MyService.ConsoleLog("reposnse", response);
        var data;
        if (response.data.success) {
          data = JSON.stringify(response.data.data.userData.followingUsers);
        } else {
          data = JSON.stringify($scope.fData);
        }
        MyService.getAllUsersData({
          "followlist": data
        }).then(function (payload) {
          MyService.ConsoleLog("payload : ", payload);
          $scope.users.data = payload;
          $scope.checkIfQuizTakerExist();
        }, function (error) {
          //MyService.ConsoleLog(error);
        })
      }, function (err) {
        console.log('err :', err);
      });

      //MyService.ConsoleLog(data);
    }

    $scope.unfollow = function (odata, idx) {
      var data = {};
      data.userId = $scope.loginUser;
      data.pushId = odata._id;
      data.isExist = $scope.isExist;
      MyService.removeUserFollowList(data).then(function (payload) {
          var newidx = $scope.users.data.indexOf(odata);
          if (newidx > -1) {
            $scope.users.data.splice(newidx, 1);
          }

          $scope.allData.followlist.splice(odata._id, 1);
          //MyService.ConsoleLog("udata : ", $scope.allData);
          tokenService.saveUserData(JSON.stringify($scope.allData));
          alert("User removed from followup list successfully.");
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });
    }

    /**
     * This function calls on page load
     * fetch data of user's area politician ,voters and advocates
     */

    $scope.getLocalPoliticians = function (asdata) {
      // MyService.ConsoleLog(" welcome to getLocalPoliticians ", $rootScope.queData);
      $scope.statusGetLocalPoliticians = 1;
      $scope.page += 1;
      $scope.loader = true;
      // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress);
      // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress.state)
      var extradata = new Object();
      if ($rootScope.tempUserAddress) {
        if ($rootScope.tempUserAddress.state && $rootScope.tempUserAddress.state != '') {
          extradata.statePostalCode = $rootScope.tempUserAddress.statePostalCode;
        }
        if ($rootScope.tempUserAddress.county && $rootScope.tempUserAddress.countyFips) {
          $rootScope.tempUserAddress.FinalData = JSON.stringify(
            $rootScope.tempUserAddress.countyFips
          );
          extradata.county = $rootScope.tempUserAddress.county;
        }
        if (
          $rootScope.tempUserAddress.countySubdivision &&
          $rootScope.tempUserAddress.countySubdivisionFips
        ) {
          $rootScope.tempUserAddress.FinalData = JSON.stringify(
            $rootScope.tempUserAddress.countySubdivisionFips
          );
          extradata.countySubdivision = $rootScope.tempUserAddress.countySubdivision;
        }
        if ($rootScope.tempUserAddress.place && $rootScope.tempUserAddress.placeFips) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.place = $rootScope.tempUserAddress.place;
        }
        if (
          $rootScope.tempUserAddress.voterDistrict &&
          $rootScope.tempUserAddress.voterDistrictFips
        ) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
        }
        if ($rootScope.tempUserAddress.federalCongrassionalDist) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          MyService.ConsoleLog(
            'federalCongrassionalDist  :',
            $rootScope.tempUserAddress.federalCongrassionalDist
          );
          extradata.federalCongrassionalDist =
            $rootScope.tempUserAddress.federalCongrassionalDist;
        }
        if ($rootScope.tempUserAddress.stateSenateDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.stateSenateDistrict = $rootScope.tempUserAddress.stateSenateDistrict;
        }
        if ($rootScope.tempUserAddress.legislativeDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.legislativeDistrict = $rootScope.tempUserAddress.legislativeDistrict;
        }
        if ($rootScope.tempUserAddress.schoolDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.schoolDistrict = $rootScope.tempUserAddress.schoolDistrict;
        }
      }
      MyService.ConsoleLog('extradata : >>>>>>>>>> ', JSON.stringify(extradata));
      MyService.getAllLocalPoli({
        max_records: 100,
        page: $scope.page,
        extradata: JSON.stringify(extradata),
      }).then(
        function (response) {
          if (response.data.success) {
            setTimeout(function () {
              $scope.$apply(function () {
                if (response.data.data.length > 0) {
                  MyService.getAdvocates().then(function (payload) {
                    MyService.ConsoleLog("Response >", payload);
                    var ndata = response.data.data;
                    if ($scope.inviterId) {
                      $scope.userAreaPolitician = [];
                      $scope.userAreaPolitician.push($scope.inviterId);
                    } else {
                      var wdata = ndata.concat(payload.data.data);
                      $scope.userAreaPolitician = wdata.map(function (ques) {
                        return ques._id;
                      });
                    }
                    MyService.ConsoleLog(" $scope.userAreaPolitician >", $scope.userAreaPolitician);

                    var param = JSON.stringify({
                      usersIds: $scope.userAreaPolitician,
                      cur_userId: $scope.loginUser,
                      questions: JSON.stringify(asdata)
                    });

                    // MyService.ConsoleLog("apra >", param);
                    // window.localStorage.removeItem('comData');
                    window.localStorage.setItem('comData', param);
                    $scope.loadernew = false;
                    $scope.skipToFinal = true;
                    $scope.skipToMatch = true;
                  }, function (err) {
                    MyService.ConsoleLog("Error >>", err);
                  });
                } else {
                  $scope.loader = false;
                  var message =
                    'None of your politicians have answered the questions yet. Contact them and ask them to fill it out.';
                  var modal = ngDialog.openConfirm({
                    template: '<p>' +
                      message +
                      '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                    plain: true,
                  });

                  modal.then(
                    function fullfilled(data) {
                      //MyService.ConsoleLog("fullfilled: ", data);
                      if (data == 1) {
                        $scope.postonFacebook();
                      } else {
                        if ($rootScope.userRole) {
                          $location.path('/snapshot');
                        } else {
                          $location.path('/signin');
                        }
                      }
                    },
                    function rejected(data) {
                      //MyService.ConsoleLog("rejected: ", data);
                    }
                  );
                }
              });
            }, 100);
          } else {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            if (typeof response.data.error == 'string') growl.error(response.data.error);
            else growl.error('Something went wrong');
          }
        },
        function (err) {
          $scope.page -= 1;
          $scope.statusGetLocalPoliticians = 3;
          $scope.loader = false;
          growl.error(err);
        }
      );
    };


    /**
     * @description Get Inviter details
     */

    $scope.getmyData = function (val, quesData) {
      MyService.ConsoleLog("quizTakerId", val.quizTakerId);
      $scope.showLoader = true;
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: val.quizTakerId,
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);
          $scope.poliName = $scope.userProfile.nameOnBallot;
          window.localStorage.getItem('inviterInfo', JSON.stringify($scope.userProfile));
          if (val.quizTakerArr && val.quizTakerArr.length > 1) {
            $cookies.put('quizTakerIdsArr', JSON.stringify(val.quizTakerArr));
          } else {
            $cookies.put('quizTakerId', val.quizTakerId);
          }

          $scope.userAreaPolitician = [];
          $scope.userAreaPolitician = val.quizTakerArr;
          console.log('$scope.userAreaPolitician :', $scope.userAreaPolitician);
          // return;
          var param = JSON.stringify({
            usersIds: $scope.userAreaPolitician,
            cur_userId: $scope.loginUser,
            questions: JSON.stringify(quesData)
          });

          MyService.ConsoleLog("apra >", param);
          window.localStorage.setItem('quesData', JSON.stringify(quesData));
          $location.path('/findFollowerMatch/true');

        } else {
          MyService.ConsoleLog("ELSE vishal");
          $scope.showLoader = false;
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    };

    /**
     * @description Function is use to get match of quiz taker
     */
    $scope.getMatch = function (val) {
      MyService.ConsoleLog("selId", val.selCatId);
      MyService.getQuestions({
        categoryId: val.selCatId
      }).then(function (APIResponse) {
        MyService.ConsoleLog("APIResponse", APIResponse);
        // $scope.getLocalPoliticians(APIResponse.data.data);
        if (APIResponse.data.success) {
          $scope.getmyData(val, APIResponse.data.data);
        }

      }, function (err) {
        MyService.ConsoleLog("Err", err);
      });
    };


    $scope.checkInviter = function () {
      MyService.ConsoleLog("$scope.fData", $scope.fData);
      MyService.checkInviter({
        "Ids": $scope.fData
      }).then(function (APIResponse) {
        MyService.ConsoleLog("APIResponse check", APIResponse);
        if (APIResponse.data.success) {
          $scope.isExist = true;
        } else {
          $scope.isExist = false;
        }
        $scope.getUserDetails();
      }, function (err) {
        console.log('err :', err);
      });
    };

    // if ($rootScope.showFollowing) {
    if ($scope.fData.length > 0) {
      $scope.checkInviter();
    } else {
      $scope.checkInviter();
      $scope.checkIfQuizTakerExist();
    }
    // }
  }])
