'use strict';
angular
  .module('myApp.displayGuestForPolitician', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/displayGuestForPolitician', {
        templateUrl: 'app/displayGuestForPolitician/displayGuestForPolitician.html',
        controller: 'displayGuestForPoliticianCtrl',
      });
    },
  ])
  .controller('displayGuestForPoliticianCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    '$q',
    '$timeout',
    'growl',
    'tokenService',
    'ngDialog',
    'baseApiUrl',
    '$anchorScroll',
    'MyService',
    '$window',
    function(
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      growl,
      tokenService,
      ngDialog,
      baseApiUrl,
      $anchorScroll,
      MyService,
      $window
    ) {
      $scope.showTotal = false;

      $scope.getCount = function() {
        MyService.getUsersCount().then(function(result) {
          $scope.count = result.data.data;
          MyService.ConsoleLog('users count: ', $scope.count);
          $scope.showTotal = true;

          $scope.guestIds = $scope.count.guestIds;

          MyService.ConsoleLog('$scope.GuestIs >>', $scope.guestIds);
        });
      };

      $scope.getCount();

      $rootScope.UserType = '';
      $rootScope.state = '';

      /**
       ** This function fetch guest users details
       **
       **/

      $scope.getGuestUsersAnswers = function() {
        var userAdd = tokenService.getUserId();
        MyService.ConsoleLog('userAdd >>', userAdd);

        // MyService.getGuestUsersAnswers({ "ids": $scope.guestIds, "userId": userAdd }).then(function(payload) {

        //   MyService.ConsoleLog("Payload >>", payload.data.data);
        //   var mData = JSON.stringify(payload.data.data);

        //   window.localStorage.setItem("cData", mData);
        //   $location.path('/compareGuestWith');
        // }, function(error) {

        //   MyService.ConsoleLog("error >>", error);

        // });
      };
    },
  ]);
