'use strict';
angular
  .module('myApp.showPoliAns', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/showPoliticianAns/:quizName?/:poliId?/:email?', {
        templateUrl: 'app/showPoliAns/showPoliAns.html',
        controller: 'showPoliticiansAnsCtrl',
      });
    },
  ])
  .controller('showPoliticiansAnsCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {
      /**
       * Clear $cookies on page load
       */
      $scope.qName = $routeParams.quizName;
      $scope.poliId = $routeParams.poliId;
      $scope.uEmail = $routeParams.email;
      $cookies.remove('showLogin');
      $cookies.remove('qName');
      $cookies.remove('poliId');

      if (!tokenService.getUserId()) {
        // alert('one');
        $cookies.put('qName', $scope.qName);
        $cookies.put('poliId', $scope.poliId);
        $cookies.put('showLogin', 'true');
        $location.path('/signin');
      }

      /**
       * Get details of the solved quiz
       */

      $scope.getDetails = function () {

        MyService.getDetailsofQuiz({
          quizName: $scope.qName,
          poliId: $scope.poliId
        }).then(function (response) {
          MyService.ConsoleLog("Response", response);
          $scope.poliName = response.data.poliName;
          $scope.totalQuestions = response.data.totalQues;
          $scope.answerQues = response.data.ansQuestions;
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      $scope.getDetails();

      /**
       * Show Answers of the politicians
       */

      $scope.getAnswers = function () {
        $location.path('/poliAnswers/' + $scope.qName + '/' + $scope.poliId);
      };

      /**
       * Get invited politicians info
       */
      $scope.getInfo = function () {

        MyService.getInfoOfInvited({
          orgId: tokenService.getUserId(),
          quizName: $scope.qName
        }).then(
          function (result) {
            MyService.ConsoleLog("Results", result);
            $scope.polData = result.data.data;
          },
          function (err) {
            MyService.ConsoleLog("Error", err);
          })
      };

      $scope.getInfo();

      /**
       * Redirect to the listing page
       */
      $scope.gotoList = function () {
        $location.path('/listOfFollowers/' + $scope.qName + '/' + $scope.poliId);
      };
    }
  ]);
