'use strict';
angular.module('myApp.openFecCommittee', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/openFecCommittees', {
      templateUrl: 'app/openFecCommittees/openFecCommittee.html',
      controller: 'openFECCommittee'
    });
  }])
.controller('openFECCommittee', ['$scope', 'ngDialog', '$rootScope', 'MyService', '$http', '$timeout', '$q', function ($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q) {
    $scope.openFecCandidates = [];
    $scope.showLoader = false;
    $scope.currPage = 1;
    $scope.openCandidateDtls = false;
    $scope.candidiateDtls = [];
    $scope.candidiateCommittees = [];

    /*OpenFec Calls*/
    $scope.myPagingFunction = function () {
      MyService.ConsoleLog("scoll called",$scope.statusGetResPg);
      if ($scope.statusGetResPg != 1) {
        $scope.currPage++;
        $scope.pageChanged($scope.currPage);
      }
    };


    $scope.pageChanged = function (newPage) {

      $scope.getopenFECCommittees(newPage);
    };
    $scope.committee = [];
    $scope.getopenFECCommittees = function (pageNumber) {
      console.log("page number", pageNumber);



      var params = {
        "page": pageNumber,
        max_records: 30
      };
      //MyService.ConsoleLog("params: ", params);
      // $scope.users.data = [];
      $scope.totalUsers = 0;


      $scope.statusGetResPg = 1;

      var getAllCanceller = $q.defer();
      var calc = (pageNumber - 1) * 30;

      MyService.getAllFECCommitteesData(params, getAllCanceller).then(function (payload) {
        // $scope.users.data = payload.Items;
        // console.log(payload);
        $scope.committee = $scope.committee.concat(payload.data.data);
        // console.log($scope.committee);
        // $scope.totalUsers = payload.Count;
        // MyService.ConsoleLog(payload.Items);
        $scope.statusGetResPg = 2;
      }, function (err) {
        //MyService.ConsoleLog(err);
        growl.error("Something went wrong.");
        $scope.statusGetResPg = 3;
        $scope.page--;
      });
      // } else {
      //   //MyService.ConsoleLog("break loop");
      //   $scope.page = 0;
      //   // break;
      //   return false;

      // }


      // if (!$filter("isBlankString")($scope.orgDetailKeyword) || !$filter("isBlankString")($scope.userDetailKeyword) || !$filter("isBlankString")($scope.userDetailFilter)) {
      //   if (!$filter("isBlankString")($scope.orgDetailKeyword)) {
      //     //MyService.ConsoleLog("searching by organization");
      //     $scope.statusGetResPg = 1;

      //     searchOrgByDetailCanceller = $q.defer();
      //     params.keyword = $scope.orgDetailKeyword;
      //     var calc = (pageNumber - 1) * 30;
      //     if ($scope.users.data.length >= calc) {
      //       MyService.searchUserByOrgDetail(params, searchOrgByDetailCanceller).then(function (payload) {
      //         //MyService.ConsoleLog("payload: ", payload);
      //         // $scope.users.data = payload.Items;
      //         $scope.users.data = $scope.users.data.concat(payload);
      //         //MyService.ConsoleLog("length: ", $scope.users.data.length);
      //         $scope.totalUsers = payload.Count;
      //         //MyService.ConsoleLog(payload.Items);
      //         $scope.statusGetResPg = 2;
      //       }, function (err) {
      //         //MyService.ConsoleLog(err);
      //         growl.error("Something went wrong.");
      //         $scope.statusGetResPg = 3;
      //         $scope.page--;
      //       });
      //     } else {
      //       //MyService.ConsoleLog("break loop");
      //       $scope.page = 0;
      //       // break;
      //       return false;

      //     }
      //   } else if (!$filter("isBlankString")($scope.userDetailKeyword) || !$filter("isBlankString")($scope.userDetailFilter)) {
      //     //MyService.ConsoleLog("searching by user detail");
      //     $scope.statusGetResPg = 1;

      //     searchUserByDetailCanceller = $q.defer();
      //     params.keyword = $scope.userDetailKeyword;
      //     if (!params.keyword) {
      //       params.keyword = "postcard";
      //     }
      //     params.listOption = $scope.userDetailFilter;
      //     var calc = (pageNumber - 1) * 30;
      //     if ($scope.users.data.length >= calc) {
      //       MyService.searchUserByDetail(params, searchUserByDetailCanceller).then(function (payload) {
      //         //MyService.ConsoleLog("payload: ", payload);
      //         // $scope.users.data = payload.Items;
      //         $scope.users.data = $scope.users.data.concat(payload);
      //         //MyService.ConsoleLog("length: ", $scope.users.data.length);
      //         $scope.totalUsers = payload.Count;
      //         //MyService.ConsoleLog(payload.Items);
      //         $scope.statusGetResPg = 2;
      //       }, function (err) {
      //         //MyService.ConsoleLog(err);
      //         growl.error("Something went wrong.");
      //         $scope.statusGetResPg = 3;
      //         $scope.page--;
      //       });
      //     } else {
      //       //MyService.ConsoleLog("break loop");
      //       $scope.page = 0;
      //       // break;
      //       return false;

      //     }
      //   }
      // } else {
      //   if ($scope.filterUser) {
      //     params.usertype = $scope.filterUser;
      //   }



      // MyService.getAllFECCommitteesData({
      //   "pageno": pageNumber
      // }).then(function (response) {
      //   console.log("response", response);
      //   $scope.candidiateCommittees = response.data.data;
      //   $scope.openFecTotal = $scope.candidiateCommittees.length;

      //   MyService.ConsoleLog("getopenFECCandidateCommittees ** : ", $scope.candidiateCommittees);
      // }, function (err) {
      //   MyService.ConsoleLog("error : ", err);
      // });

      // MyService.getAllFECCommittees().then(function (response) {
      //   console.log("yogesh");
      //   console.log(response);
      // }, function (err) {
      //   MyService.ConsoleLog("error : ", err);
      // });

    }
    $scope.AddCommittees = function () {

      MyService.getAllFECCommittees().then(function (response) {
        // console.log("yogesh");
        // console.log(response);
      }, function (err) {
        MyService.ConsoleLog("error : ", err);
      });
    }

    if ($rootScope.showOpenFecCommittee) {
      $scope.getopenFECCommittees($scope.currPage);
    }

  }])