'use strict';
angular.module('myApp.essay', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/essay/:categoryId/:subcatId?/:type?', {
      templateUrl: 'app/essay/essay.html',
      controller: 'essayCtrl'
    });
  }])
  .controller('essayCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'baseApiUrl', 'MyService', 'tokenService', 'growl', '$templateCache', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, baseApiUrl, MyService, tokenService, growl, $templateCache, ngDialog) {
    $scope.imgUrl = baseApiUrl + "/resources";
    $scope.categoryId = $routeParams.categoryId;
    $scope.subcategoryId = $routeParams.subcatId;
    $scope.userId = tokenService.getUserId();
    $scope.userRole = tokenService.getUserRole();
    $rootScope.welcome = false;
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
    } else {
      $scope.userData = "";
    }

    MyService.ConsoleLog("$scope.userData", $scope.userData);

    $scope.ShowAdavace = false;
    if ($routeParams.query) {
      $scope.search = {
        text: $routeParams.query,
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        recommendFilter: '',
        factualFilter: '',
        factualFrom: '',
        factualTo: '',
        fromfollowlist: '',
        order: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: '',
        chk: false

      };
    } else {
      $scope.search = {
        text: '',
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        recommendFilter: '',
        factualFilter: '',
        factualFrom: '',
        factualTo: '',
        fromfollowlist: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: '',
        order: '',
        chk: false

      };
    }

    $scope.showRefineLocation = false;

    $scope.Location = ["International", "National", "My State", "My County", "My Local", "Other Specific"];
    $scope.mediaType = ['Text', 'Video', 'Stills', 'Audio'];
    $scope.dateRange = ['Today', 'In the last week', 'In the last month', 'In the last year'];
    $scope.users = ["Voters", "Organizations", "Press", "Politicians", "Authors on my follow list"];
    $scope.staffResults = {
      data: []
    };
    $scope.searchResults = {
      data: []
    };
    $scope.selectedCat = [];
    $scope.subtopics = {
      subcategories: []
    }
    $scope.disabled = [];
    $scope.allLocations = [];
    $scope.allCounties = [];
    $scope.selectedArea = [];
    $scope.rmselectedArea = [];
    $scope.myselectionarea = [];
    $scope.disabledLocation = [];
    $scope.ShowSubcatTree = false;

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    $scope.getMailingAddress = function (id) {
      MyService.getMailingAddress({
        userId: id,
      }).then(
        function (result) {
          MyService.ConsoleLog('Result', result);
          $scope.mainData = result.data.data;
        },
        function (err) {
          MyService.ConsoleLog('Error >', err);
        }
      );
    };

    function getCategory(params, type) {
      MyService.getCategoryList(params).then(function (payload) {
          var subcatdata = payload;
          //MyService.ConsoleLog("subcatdata : ", subcatdata);
          if (type == "Prev") {
            $scope.previouscategory = payload;
          } else {
            $scope.category = payload;
          }
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });
    }

    $scope.getStaffEssayList = function () {
      $scope.staffResults.data.length = 0;
      var nrequest = apiCall.apiCall('GET', '/essay/staffEssayList', {
        maincatId: $routeParams.categoryId,
        subcatId: $routeParams.subcatId,
        limit: 3
      });
      $http(
        nrequest
      ).then(function successCallback(responseLatest) {
        if (responseLatest.data.success) {
          var finalData = responseLatest.data.data;
          var flen = finalData.length;
          $scope.staffResults.data = finalData;
          /*
          for (var s = 0; s < flen; s++) {
              if (finalData[s].recommended.length > 0) {
                  finalData[s].recavg = $scope.calculateAvg(finalData[s].recommended);
                  finalData[s].recavgvoters = $scope.calculateAvgType(finalData[s].recommended, 'voter');
                  finalData[s].recavgpolitician = $scope.calculateAvgType(finalData[s].recommended, 'politician');
                  finalData[s].recavgadvocate = $scope.calculateAvgType(finalData[s].recommended, 'advocate');
                  finalData[s].recavgpress = $scope.calculateAvgType(finalData[s].recommended, 'press');
              } else {
                  finalData[s].recavg = 0;
                  finalData[s].recavgvoters = 0;
                  finalData[s].recavgpolitician = 0;
                  finalData[s].recavgadvocate = 0;
                  finalData[s].recavgpress = 0;
              }
              if (finalData[s].factual.length > 0) {
                  finalData[s].factfavg = $scope.calculateAvg(finalData[s].factual);
                  finalData[s].factfavgvoters = $scope.calculateAvgType(finalData[s].factual, 'voter');
                  finalData[s].factfavgpolitician = $scope.calculateAvgType(finalData[s].factual, 'politician');
                  finalData[s].factfavgadvocate = $scope.calculateAvgType(finalData[s].factual, 'advocate');
                  finalData[s].factfavgpress = $scope.calculateAvgType(finalData[s].factual, 'press');
              } else {
                  finalData[s].factfavg = 0;
                  finalData[s].factfavgvoters = 0;
                  finalData[s].factfavgpolitician = 0;
                  finalData[s].factfavgadvocate = 0;
                  finalData[s].factfavgpress = 0;
              }
              //MyService.ConsoleLog("finalData[s] :", finalData[s]);
              $scope.staffResults.data.push(finalData[s]);
          }
          */
        }
      }, function errorCallBack(responseLatest) {
        alert("Error : " + responseLatest);
      });
    };

    /**
     * Highlight post
     */
    $scope.highlight = function (pId) {
      MyService.ConsoleLog("highlight", pId);
      MyService.ConsoleLog(" $scope.userData", $scope.userData);
      // hdjdDA
      MyService.highlightPost({
        pId: pId,
        advId: $scope.userData.id
      }).then(function (result) {
        MyService.ConsoleLog("result", result);
        growl.success(result.data);
      }, function (error) {
        growl.error(error);
      });
    };


    $scope.showArr = false;
    $scope.currentPage = 1;
    $scope.pageChangeHandler = function (num) {
      MyService.ConsoleLog('meals page changed to ' + num);
    };
    $scope.getEssayList = function () {
      $scope.searchResults.data.length = 0;
      var nrequest = apiCall.apiCall('GET', '/essay/getList', {
        maincatId: $routeParams.categoryId,
        subcatId: $routeParams.subcatId
      });
      $http(
        nrequest
      ).then(function successCallback(responseLatest) {
        if (responseLatest.data.success) {
          var finalData = responseLatest.data.data;
          var flen = finalData.length;

          for (var s = 0; s < flen; s++) {
            if (finalData[s].recommended.length > 0) {
              finalData[s].recavg = $scope.calculateAvg(finalData[s].recommended);
              finalData[s].recavgvoters = $scope.calculateAvgType(finalData[s].recommended, 'voter');
              finalData[s].recavgpolitician = $scope.calculateAvgType(finalData[s].recommended, 'politician');
              finalData[s].recavgadvocate = $scope.calculateAvgType(finalData[s].recommended, 'advocate');
              finalData[s].recavgpress = $scope.calculateAvgType(finalData[s].recommended, 'press');
            } else {
              finalData[s].recavg = 0;
              finalData[s].recavgvoters = 0;
              finalData[s].recavgpolitician = 0;
              finalData[s].recavgadvocate = 0;
              finalData[s].recavgpress = 0;
            }
            if (finalData[s].factual.length > 0) {
              finalData[s].factfavg = $scope.calculateAvg(finalData[s].factual);
              finalData[s].factfavgvoters = $scope.calculateAvgType(finalData[s].factual, 'voter');
              finalData[s].factfavgpolitician = $scope.calculateAvgType(finalData[s].factual, 'politician');
              finalData[s].factfavgadvocate = $scope.calculateAvgType(finalData[s].factual, 'advocate');
              finalData[s].factfavgpress = $scope.calculateAvgType(finalData[s].factual, 'press');
            } else {
              finalData[s].factfavg = 0;
              finalData[s].factfavgvoters = 0;
              finalData[s].factfavgpolitician = 0;
              finalData[s].factfavgadvocate = 0;
              finalData[s].factfavgpress = 0;
            }
            //MyService.ConsoleLog("finalData[s] :", finalData[s]);
            $scope.searchResults.data.push(finalData[s]);
          }
          MyService.ConsoleLog("dd", $scope.searchResults.data);
          $scope.totalResults = flen;
        }
      }, function errorCallBack(responseLatest) {
        alert("Error : " + responseLatest);
      });
    }

    $scope.checkCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val)
          }
        }
      );
    }

    $scope.mainCatTree = [];

    $scope.getSubcatTree = function () {
      MyService.ConsoleLog("main  cat", $scope.mainCatTree);
      if ($scope.mainCatTree.length == 0) {
        $scope.ShowSubcatTree = true;
        $scope.showArr = true;
        MyService.getAllCatTree().then(function (payload) {
            $scope.subtopics.subcategories.length = 0;
            $scope.mainCatTree = angular.copy(payload);
            var subcatdata = payload;
            $scope.ShowSubcatTree = false;
            $scope.subtopics.subcategories = subcatdata;
            MyService.ConsoleLog("$scope.subtopics", $scope.subtopics);
            var selectedCat = $scope.disabled;
            if (selectedCat.length > 0) {
              for (var s = 0; s < selectedCat.length; s++) {
                var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
              }
              //MyService.ConsoleLog($scope.selectedCat);
            }
            setTimeout(function () {
              var cols = document.getElementsByClassName('cmnUL');
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = "none";
              }
            }, 300);
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {
        //MyService.ConsoleLog("After : " + $scope.subtopics.subcategories.length);
        if ($scope.subtopics.subcategories.length == 0) {
          $scope.subtopics.subcategories = angular.copy($scope.mainCatTree);
        } else {

          $scope.subtopics.subcategories.length = 0;
        }


        $scope.ShowSubcatTree = false;
        MyService.ConsoleLog("After : " + $scope.subtopics.subcategories);
        MyService.ConsoleLog("After : " + $scope.ShowSubcatTree);
      }

    }



    $scope.AllStates = [];
    $scope.allCounties = [];
    $scope.allLocals = [];
    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];

    $scope.GotoEdit = function (id) {

      $location.path('/essayUpdate/' + $routeParams.categoryId + '/' + id);
    }

    $scope.removeEssay = function (mydata, idx, flag) {
      MyService.ConsoleLog(mydata);
      var message = 'Are you sure you want to delete this Essay?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        // MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeEssay({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          if (flag == "S") {
            $scope.getStaffEssayList();
          }

          if (flag == "A") {
            $scope.getEssayList();
          }

          alert("Essay deleted successfully.");
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        MyService.ConsoleLog("rejected: ", data);
      });
    }

    $scope.getAllStates = function () {
      $scope.ShowStateLoader = true;
      if ($scope.AllStates.length == 0) {
        MyService.getAllStates().then(function (payload) {
            $scope.ShowStateLoader = false;
            $scope.allLocations.length = 0;
            var subcatdata = payload;
            $scope.AllStates = angular.copy(subcatdata);
            //MyService.ConsoleLog("countires : ", subcatdata);
            $scope.allLocations = subcatdata;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {
        if (!$scope.showStates) {
          $scope.allLocations.length = 0;
          $scope.ShowStateLoader = false;
        } else {
          $scope.allLocations = angular.copy($scope.AllStates);
          $scope.ShowStateLoader = false;
        }
      }

    }

    $scope.showSts = [];
    $scope.getCounties = function (state) {
      // MyService.ConsoleLog("state", state);
      $scope.showLoading.push(state);
      $scope.showSts[state] = true;
      if (!$scope.allCounties[state] || $scope.allCounties[state].length == 0) {

        MyService.getAllCounties(state).then(function (payload) {
            $scope.showLoading.length = 0;
            var sublocations = payload;
            // MyService.ConsoleLog("paload", payload);
            if (!$scope.allCounties) {
              $scope.allCounties = [];
            }
            $scope.allCounties[state] = payload;
            // MyService.ConsoleLog(" $scope.allCounties", $scope.allCounties);
          },
          function (errorPayload) {
            alert('Something went wrong.');
          });


      } else {
        $scope.showSts[state] = false;
        $scope.showLoading.length = 0;
        $scope.allCounties[state].length = 0;
      }
    }
    $scope.showCount = [];
    $scope.getLocals = function (state, county) {
      $scope.showCount[county] = true;
      MyService.ConsoleLog("state", state);
      MyService.ConsoleLog("county", county);

      $scope.showLoadingCounty.push(county);
      if (!$scope.allLocals[state] || !$scope.allLocals[state][county] || $scope.allLocals[state][county].length == 0) {

        MyService.getAllSubCountiesLocal(state, county).then(function (payload) {
            $scope.showLoadingCounty.length = 0;
            MyService.ConsoleLog("sublocations Locals : ", payload);
            if (!$scope.allLocals[state]) {
              $scope.allLocals[state] = [];
            }
            MyService.ConsoleLog("state.statePostalCode", state.statePostalCode);
            $scope.allLocals[state][county] = payload.places;
            MyService.ConsoleLog("sublocations Locals : ", $scope.allLocals[state][county]);

          },
          function (errorPayload) {
            alert('Something went wrong ');
          });


      } else {
        $scope.showCount[county] = false;
        $scope.showLoadingCounty.length = 0;
        $scope.allLocals[state][county].length = 0;
      }
    }





    $scope.showmediaType = false;
    $scope.showDateRange = false;
    $scope.showMatchAuthor = false;
    $scope.showEssayRating = false;
    $scope.showStates = false;
    $scope.showRefine = function (e, option) {
      e.preventDefault();
      switch (option) {
        case "Interest":
          $scope.getSubcatTree();
          break;
        case "Location":
          if (!$scope.showRefineLocation)
            $scope.showRefineLocation = true;
          else
            $scope.showRefineLocation = false;

          break;
        case "AllLocation":
          if (!$scope.showStates) {
            $scope.showStates = true;

          } else {
            $scope.showStates = false;
          }
          $scope.getAllStates();
          break;
        case "Media":
          if (!$scope.showmediaType)
            $scope.showmediaType = true;
          else
            $scope.showmediaType = false;
          break;
        case "Date":
          if (!$scope.showDateRange)
            $scope.showDateRange = true;
          else
            $scope.showDateRange = false;
          break;
        case "Author":
          if (!$scope.showMatchAuthor)
            $scope.showMatchAuthor = true;
          else
            $scope.showMatchAuthor = false;
          break;
        case "EssayRated":
          if (!$scope.showEssayRating)
            $scope.showEssayRating = true;
          else
            $scope.showEssayRating = false;
          break;
        case "Default":
          break;

      }
    }


    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];
    $scope.showSubs = [];
    $scope.ChangeShow = function (id) {
      $scope.showSubCat = true;
      $scope.showSubs[id] = true;

      var nclass = "MainCatLi_" + id;
      if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
        document.getElementsByClassName(nclass)[0].style.display = "block";
      } else {
        document.getElementsByClassName(nclass)[0].style.display = "none";
      }
    }


    function areEqual() {
      var len = arguments.length;
      for (var i = 1; i < len; i++) {
        if (arguments[i] == null || arguments[i] != arguments[i - 1])
          return false;
      }
      return true;
    }

    function indexOfExt(list, item) {
      var len = list.length;
      for (var i = 0; i < len; i++) {
        var keys = Object.keys(list[i]);
        var flg = true;
        var arr = [];
        for (var j = 0, s = 1; j < keys.length; j++, s++) {
          var value = list[i][keys[j]];
          if (item[keys[j]] !== value) {
            flg = false;
          }

          if (item[keys[j]] == value) {
            arr.push(flg);
          }
        }
        if (flg == true) {
          if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
            return i;
          }
        }
      }
      return -1;
    }

    $scope.findLocations = [];
    $scope.Interests = [];
    $scope.MyLocation = [];
    $scope.SelectDefaultLocation = function (val) {
      //MyService.ConsoleLog("Data : ", val);
      var idx = $scope.MyLocation.indexOf(val);
      if (idx == -1) {
        $scope.MyLocation.push(val);
      } else {
        $scope.MyLocation.splice(idx, 1);
      }
      //MyService.ConsoleLog($scope.MyLocation);
    }

    $scope.selectLocation = function (val1, val2, val3, type) {
      //MyService.ConsoleLog(val1, val2, val3, type);
      var nobj = {};
      nobj.country = "United States of America";
      if (type == "state") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
      }
      if (type == "county") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
        nobj.county = val3;
      }
      if (type == "local") {
        nobj.state = val1.state;
        nobj.statePostalCode = val1.statePostalCode;
        nobj.county = val2;
        nobj.countySubdivision = val3.countySubdivision;
        nobj.place = val3.place;
      }
      //MyService.ConsoleLog("nboj:", nobj);
      var idx = indexOfExt($scope.findLocations, nobj);
      if (idx == -1) {
        $scope.findLocations.push(nobj);
      } else {
        $scope.findLocations.splice(idx, 1);
      }
    }



    $scope.getVal = function (data) {
      MyService.ConsoleLog("data", data);
      var idx = $scope.Interests.indexOf(data._id);
      if (idx == -1) {
        $scope.Interests.push(data._id);
      } else {
        $scope.Interests.splice(idx, 1);
      }
    }

    $scope.totalResults = 0;


    function addslashes(str) {
      return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
    }




    function cleanArray(actual) {
      var newArray = new Array();
      for (var i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    }

    function allnumeric(inputtxt) {
      var numbers = /^[0-9]+$/;
      if (inputtxt == undefined) {
        return false;
      }
      if (inputtxt.match(numbers)) {
        return true;
      } else {
        return false;
      }
    }


    $scope.calculateAvg = function (arr) {
      var reclen = arr.length;
      //MyService.ConsoleLog("Arr Len : ", reclen);
      var recsum = 0;
      for (var r = 0; r < reclen; r++) {
        if (arr[r].vote) {
          //MyService.ConsoleLog("===",arr[r].vote);
          recsum += parseInt(arr[r].vote, 10);
          if (r == reclen - 1) {
            var recavg = recsum / reclen;

            return recavg;
          }
        }

      }

      //return card.values.opt1 + card.values.opt2;
    };

    $scope.calculateAvgType = function (arr, type) {
      //MyService.ConsoleLog("calculateAvgType : ",arr);
      var reclen = arr.length;
      var recsum = 0;
      for (var r = 0; r < reclen; r++) {
        var userData = arr[r].user || arr[r][0].user;
        var userVote = arr[r].vote || arr[r][0].vote;
        if (userData.utype == type) {
          //MyService.ConsoleLog(userData.utype + "==" + type);
          recsum += parseInt(userVote, 10);

        }
        if (r == reclen - 1) {
          var recavg = recsum / reclen;

          return recavg;
        }
      }

      //return card.values.opt1 + card.values.opt2;
    };

    $scope.calculateAvgId = function (arr, followlist) {
      var reclen = arr.length;
      var recsum = 0;
      for (var r = 0; r < reclen; r++) {
        var userData = arr[r].user || arr[r][0].user;
        var userVote = arr[r].vote || arr[r][0].vote;
        var idx = followlist.indexOf(userData.id)
        if (idx > -1) {
          //MyService.ConsoleLog(arr[r].user.utype + "==" + type);
          recsum += parseInt(userVote.vote, 10);
        }

        if (r == reclen - 1) {
          var recavg = recsum / reclen;
          return recavg;
        }
      }
    }
    $scope.displayMsg = false;
    $scope.getEssayListAdvance = function (data) {
      MyService.ConsoleLog("$scope.search", $scope.search);
      $scope.displayMsg = true;
      $scope.searchResults.data.length = 0;
      if ($scope.findLocations.length > 0) {
        data.searchlocations = JSON.stringify($scope.findLocations);
      }

      if ($scope.MyLocation.length > 0) {
        data.MyLocation = JSON.stringify($scope.MyLocation);
      }
      if (data.mediaoption) {
        var moption = data.mediaoption;
        if (typeof moption == "object") {
          moption = Object.keys(moption).map(function (key) {
            return moption[key]
          });
        }
        if (typeof moption != "string") {
          var media = cleanArray(moption);
          data.mediaoption = JSON.stringify(media);
        }
      }
      if ($scope.Interests && $scope.Interests.length > 0) {
        data.subjectOfInterest = JSON.stringify($scope.Interests);
      } else {
        $scope.Interests.push($scope.categoryId);
        data.subjectOfInterest = JSON.stringify($scope.Interests);
      }

      var validateMatch = true,
        validateMatchTo = true,
        validateRecommend = true,
        validateTo = true;
      var msg = "";
      if (data.matchFrom != "") {

        validateMatch = allnumeric(data.matchFrom);
        if (($scope.Interests.length == 0) && validateMatch) {
          msg = "Please select atleast one subject of interest";
          validateMatch = false;
        }
      }

      if (data.matchTo != "") {
        validateMatchTo = allnumeric(data.matchTo);
        if (validateMatchTo && ($scope.Interests.length == 0)) {
          msg = "Please select atleast one subject of interest";
          validateMatchTo = false;
        }
      }

      if (data.recommendFrom != "") {
        validateRecommend = allnumeric(data.recommendFrom);
      }
      if (data.recommendTo != "") {
        validateTo = allnumeric(data.recommendTo);
      }
      data.cat = $scope.categoryId;
      if (validateMatch && validateMatchTo && validateRecommend && validateTo) {
        $scope.searchResults.data.length = 0;
        $scope.totalResults = 0;
        // MyService.ConsoleLog(data);
        var nrequest = apiCall.apiCall('POST', '/essay/AdvanceSearch', data);
        $http(
          nrequest
        ).then(function successCallback(responseLatest) {
          // MyService.ConsoleLog("Final Advance Data");
          // MyService.ConsoleLog(JSON.stringify(responseLatest.data));
          $scope.searchResults.data.length = 0;
          if (responseLatest.data.success) {
            var main = responseLatest.data.data.filter(function (itm, inx) {
              return inx == responseLatest.data.data.findIndex(function (eles) {
                return JSON.stringify(eles) == JSON.stringify(itm);
              });
            });
            var finalData = main;
            MyService.ConsoleLog("finalData:", finalData);
            var flen = finalData.length;
            $scope.searchResults.data.length = 0;
            //MyService.ConsoleLog("Results :", $scope.searchResults.data);

            //$templateCache.removeAll();
            for (var s = 0; s < flen; s++) {
              //MyService.ConsoleLog("actual length : ", finalData[s].recommended.length);
              // MyService.ConsoleLog("actual >>> ", finalData[s]);
              /*
              if(typeof finalData[s].recommended == "object") {
                  var narr = [];
                  narr.push(finalData[s].recommended);
                  finalData[s].recommended = narr;
              }
              if(typeof finalData[s].factual == "object") {
                  var farr = [];
                  farr.push(finalData[s].factual);
                  finalData[s].factual = farr;
              }
              */
              //  //MyService.ConsoleLog("actual length : ", finalData[s].recommended.length);
              //MyService.ConsoleLog("actual : ", finalData[s].recommended);
              if (finalData[s].recommended.length > 0) {
                finalData[s].recavg = $scope.calculateAvg(finalData[s].recommended);
                finalData[s].recavgvoters = $scope.calculateAvgType(finalData[s].recommended, 'voter');
                finalData[s].recavgpolitician = $scope.calculateAvgType(finalData[s].recommended, 'politician');
                finalData[s].recavgadvocate = $scope.calculateAvgType(finalData[s].recommended, 'advocate');
                finalData[s].recavgpress = $scope.calculateAvgType(finalData[s].recommended, 'press');
                if ($scope.userData && $scope.userData.followlist.length > 0) {
                  finalData[s].recavgfollow = $scope.calculateAvgId(finalData[s].recommended, $scope.userData.followlist);
                } else {
                  finalData[s].recavgfollow = 0;
                }

              } else {
                finalData[s].recavg = 0;
                finalData[s].recavgvoters = 0;
                finalData[s].recavgpolitician = 0;
                finalData[s].recavgadvocate = 0;
                finalData[s].recavgpress = 0;
                finalData[s].recavgfollow = 0;
              }

              if (finalData[s].factual.length > 0) {
                finalData[s].factfavg = $scope.calculateAvg(finalData[s].factual);
                finalData[s].factfavgvoters = $scope.calculateAvgType(finalData[s].factual, 'voter');
                finalData[s].factfavgpolitician = $scope.calculateAvgType(finalData[s].factual, 'politician');
                finalData[s].factfavgadvocate = $scope.calculateAvgType(finalData[s].factual, 'advocate');
                finalData[s].factfavgpress = $scope.calculateAvgType(finalData[s].factual, 'press');
                if ($scope.userData && $scope.userData.followlist.length > 0) {
                  finalData[s].factfavgfollow = $scope.calculateAvgId(finalData[s].recommended, $scope.userData.followlist);
                } else {
                  finalData[s].factfavgfollow = 0;
                }

              } else {
                finalData[s].factfavg = 0;
                finalData[s].factfavgvoters = 0;
                finalData[s].factfavgpolitician = 0;
                finalData[s].factfavgadvocate = 0;
                finalData[s].factfavgpress = 0;
                finalData[s].factfavgfollow = 0;
              }
              //MyService.ConsoleLog("finalData[s] :", finalData[s]);
              $scope.searchResults.data.push(finalData[s]);
            }
            $scope.totalResults = responseLatest.data.data.length;

            data.mediaoption = "";
            data.mediaoption = [];
            data.mediaoption.length = 0;
          } else {
            data.mediaoption = "";
            data.mediaoption = [];
            data.mediaoption.length = 0;
            $scope.searchResults.data.length = 0;
            MyService.ConsoleLog("There are no records");
          }
        }, function errorCallBack(responseLatest) {
          MyService.ConsoleLog(responseLatest);
          alert("Error : " + responseLatest);
        });
      } else {
        if (msg != "") {
          alert(msg);
        } else {
          alert("Please enter valid data");
        }

      }
    }
    $scope.ShowOrderList = false;
    $scope.showOrder = function () {
      if (!$scope.ShowOrderList) {
        $scope.ShowOrderList = true;
      } else {
        $scope.ShowOrderList = false;
      }
    }

    $scope.isreverse = false;
    $scope.showAvg = true;
    $scope.showVoters = false;
    $scope.showPolitician = false;
    $scope.showAdvocate = false;
    $scope.showPress = false;
    $scope.showFollow = false;
    $scope.showfAvg = true;
    $scope.showfVoters = false;
    $scope.showfPolitician = false;
    $scope.showfAdvocate = false;
    $scope.showfPress = false;
    $scope.showfFollow = false;
    $scope.resetAllOrder = function () {
      $scope.showAvg = false;
      $scope.showVoters = false;
      $scope.showPolitician = false;
      $scope.showAdvocate = false;
      $scope.showPress = false;
      $scope.showFollow = false;
    }
    $scope.resetAllfOrder = function () {
      $scope.showfAvg = false;
      $scope.showfVoters = false;
      $scope.showfPolitician = false;
      $scope.showfAdvocate = false;
      $scope.showfPress = false;
      $scope.showfFollow = false;
    }

    $scope.sortValues = function (data) {
      var result = "";
      $scope.resetAllOrder();
      $scope.resetAllfOrder();

      $scope.showfAvg = true;
      $scope.showAvg = true;


      switch ($scope.search.order) {
        case "Most Recent":
          $scope.isreverse = true;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.createdOn;
          break;
        case "Oldest":
          $scope.isreverse = false;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.createdOn;
          break;
        case "Highest Recommended":
          $scope.resetAllOrder();
          $scope.isreverse = true;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.recavg;
          break;
        case "Least Recommended":
          $scope.resetAllOrder();
          $scope.isreverse = false;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.recavg;
          break;
        case "Most Factual":
          $scope.resetAllfOrder();
          $scope.isreverse = true;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.factfavg;
          break;
        case "Least Factual":
          $scope.resetAllfOrder();
          $scope.isreverse = false;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          result = data.factfavg;
          break;
        case "Default":
          $scope.resetAllOrder();
          $scope.resetAllfOrder();
          $scope.isreverse = true;
          result = data.createdOn;
          $scope.showAvg = true;
          $scope.showfAvg = true;
          break;

      }

      switch ($scope.search.recommendorder) {
        case "Voters":
          $scope.resetAllOrder();
          $scope.showVoters = true;
          $scope.isreverse = true;
          result = data.recavgvoters;
          break;
        case "Politicians":
          $scope.resetAllOrder();
          $scope.showPolitician = true;
          $scope.isreverse = true;
          result = data.recavgpolitician;
          break;
        case "Organizations":
          $scope.resetAllOrder();
          $scope.showAdvocate = true;
          $scope.isreverse = true;
          result = data.recavgadvocate;
          break;
        case "Press":
          $scope.resetAllOrder();
          $scope.showPress = true;
          $scope.isreverse = true;
          result = data.recavgpress;
          break;
        case "Authors on my follow list":
          $scope.resetAllOrder();
          $scope.showFollow = true;
          $scope.isreverse = true;
          result = data.recavgfollow;
          break;
        case "Default":
          $scope.resetAllOrder();
          $scope.showAvg = true;

          break;

      }

      switch ($scope.search.lrecommendorder) {
        case "Voters":
          $scope.resetAllOrder();
          $scope.showVoters = true;
          $scope.isreverse = false;
          result = data.recavgvoters;
          break;
        case "Politicians":
          $scope.resetAllOrder();
          $scope.showPolitician = true;
          $scope.isreverse = false;
          result = data.recavgpolitician;
          break;
        case "Organizations":
          $scope.resetAllOrder();
          $scope.showAdvocate = true;
          $scope.isreverse = false;
          result = data.recavgadvocate;
          break;
        case "Press":
          $scope.resetAllOrder();
          $scope.showPress = true;
          $scope.isreverse = false;
          result = data.recavgpress;
          break;
        case "Authors on my follow list":
          $scope.resetAllOrder();
          $scope.showFollow = true;
          $scope.isreverse = false;
          result = data.recavgfollow;
          break;
        case "Default":
          $scope.resetAllOrder();
          $scope.showAvg = true;
          break;

      }

      switch ($scope.search.factualorder) {
        case "Voters":
          $scope.resetAllfOrder();
          $scope.showfVoters = true;
          $scope.isreverse = true;
          result = data.factfavgvoters;
          break;
        case "Politicians":
          $scope.resetAllfOrder();
          $scope.showfPolitician = true;
          $scope.isreverse = true;
          result = data.factfavgpolitician;
          break;
        case "Organizations":
          $scope.resetAllfOrder();
          $scope.showfAdvocate = true;
          $scope.isreverse = true;
          result = data.factfavgadvocate;
          break;
        case "Press":
          $scope.resetAllfOrder();
          $scope.showfPress = true;
          $scope.isreverse = true;
          result = data.factfavgpress;
          break;
        case "Authors on my follow list":
          $scope.resetAllOrder();
          $scope.showfFollow = true;
          $scope.isreverse = true;
          result = data.factfavgfollow;
          break;
        case "Default":
          $scope.resetAllfOrder();
          $scope.showfAvg = true;
          break;

      }

      switch ($scope.search.lfactualorder) {
        case "Voters":
          $scope.resetAllfOrder();
          $scope.showfVoters = true;
          $scope.isreverse = false;
          result = data.factfavgvoters;
          break;
        case "Politicians":
          $scope.resetAllfOrder();
          $scope.showfPolitician = true;
          $scope.isreverse = false;
          result = data.factfavgpolitician;
          break;
        case "Organizations":
          $scope.resetAllfOrder();
          $scope.showfAdvocate = true;
          $scope.isreverse = false;
          result = data.factfavgadvocate;
          break;
        case "Press":
          $scope.resetAllfOrder();
          $scope.showfPress = true;
          $scope.isreverse = false;
          result = data.factfavgpress;
          break;
        case "Authors on my follow list":
          $scope.resetAllOrder();
          $scope.showfFollow = true;
          $scope.isreverse = false;
          result = data.factfavgfollow;
          break;
        case "Default":
          $scope.resetAllfOrder();
          $scope.showfAvg = true;
          break;

      }
      //MyService.ConsoleLog($scope.search);
      //MyService.ConsoleLog(result);
      return result;

    }

    $scope.HighestRecommended = false;
    $scope.LeastRecommended = false;
    $scope.MostFactual = false;
    $scope.LeastFactual = false;
    $scope.ShowUsers = function (option) {
      switch (option) {
        case "HighestRecommended":
          if (!$scope.HighestRecommended) {
            $scope.HighestRecommended = true;
          } else {
            $scope.HighestRecommended = false;
          }
          break;
        case "LeastRecommended":
          if (!$scope.LeastRecommended) {
            $scope.LeastRecommended = true;
          } else {
            $scope.LeastRecommended = false;
          }
          break;
        case "MostFactual":
          if (!$scope.MostFactual) {
            $scope.MostFactual = true;
          } else {
            $scope.MostFactual = false;
          }
          break;
        case "LeastFactual":
          if (!$scope.LeastFactual) {
            $scope.LeastFactual = true;
          } else {
            $scope.LeastFactual = false;
          }
          break;
        case "Default":
          $scope.HighestRecommended = false;
          break;
      }
    }

    $scope.gotoThread = function (data) {
      var id = data;
      //MyService.ConsoleLog(data);
      MyService.ConsoleLog('essayDetails/' + $routeParams.categoryId + '/' + id);
      $location.path('essayDetails/' + $routeParams.categoryId + '/' + id);
    }

    $scope.GotoEdit = function (id) {
      $location.path('/essayUpdate/' + $routeParams.categoryId + '/' + id);
    }



    $scope.showadvace = function () {
      if (!$scope.ShowAdavace) {
        $scope.ShowAdavace = true;

      } else {
        $scope.ShowAdavace = false;

      }
      var query = $scope.search.text;
      $scope.search = {
        text: query,
        subject: '',
        Locations: '',
        mediaoption: [],
        mediaType: '',
        dateBefore: '',
        dateAfter: '',
        matchFrom: '',
        matchTo: '',
        recommendFrom: '',
        recommendTo: '',
        recommendFilter: '',
        factualFilter: '',
        factualFrom: '',
        factualTo: '',
        order: '',
        recommendorder: '',
        lrecommendorder: '',
        factualorder: '',
        lfactualorder: '',
        fromfollowlist: '',
        chk: false
      };
    }
    if (tokenService.getUserId()) {
      $scope.getMailingAddress(tokenService.getUserId());

    }
    if ($routeParams.categoryId) {
      getCategory($routeParams.categoryId, "Main");
      $scope.getStaffEssayList();
      $scope.getEssayList();
    }
  }]);
