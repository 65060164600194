'use strict';
angular
  .module('myApp.politicianMatch', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/politicianMatch/:poliId?', {
        templateUrl: 'app/politicianMatch/politicianMatch.html',
        controller: 'politicianMatchCtrl',
      });
    },
  ]).controller('politicianMatchCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      /**
       * Get Route param value
       */
      $scope.poliId = $routeParams.poliId;
      // $scope.

      MyService.ConsoleLog("one more sir", $scope.poliId);
      // $cookies.remove('isAlreadyAnswer');
      // $cookies.put('isAlreadyAnswer', true);
      if (window.localStorage.getItem('poliQuiz')) {
        $rootScope.resultOfAns = JSON.parse(window.localStorage.getItem('poliQuiz'));
      }

      $scope.showcomparison = false;
      $scope.noDataFound = false;


      $scope.traverseBackgrounds = [];
      $scope.currBackgroundIndex = 0;
      $scope.status = {
        questions: 0, //1=loading, 2=loaded, 3=error
        category: 0
      };

      $scope.currentIndex = 0;


      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;

      /**
       * Show Pop up
       */

      $scope.Popup = function () {

        var message = "Final comparison to you is impossible since you did not take the quiz";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                </div>',
          plain: true,
          closeByDocument: false
        });
        modal.then(function fullfilled(data) {

        }, function rejected(data) {

        });
      };


      /**
       * Get Match
       */
      $scope.getCompMatch = function () {
        // MyService.ConsoleLog(" $scope.orgId", $scope.orgId);
        // MyService.ConsoleLog('$rootScope.resultOfAns >> ', $rootScope.resultOfAns);
        MyService.getPoliMatch({
          poliId: $scope.poliId,
          poliData: JSON.stringify($rootScope.resultOfAns)
        }).then(function (result) {
          MyService.ConsoleLog("Results", result);
          // if (result && result.data.data && result.data.data.qdata) {
          $scope.questions = result.data.qdata;
          $scope.FBCompareList = result.data.qdata;

          if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
            $scope.FBunWeighted = result.data.unweightedMatch;
            $scope.fbcombinedWeight = result.data.fbcombinedWeight;
            $scope.fbmultiplier = result.data.fbmultiplier;
            $scope.answeredQuestions = result.data.answeredQuestions;
            $scope.fbqlen = result.data.qlen;
            $scope.AllUsers = result.data.AllUsers;
            $scope.poliName = result.data.poliName;
            $scope.poliInfo = result.data.poliInfo;
            // $scope.showcomparison = true;
            MyService.ConsoleLog("$scope.AllUsers ", $scope.AllUsers);
            MyService.ConsoleLog(" $scope.FBunWeighted", $scope.FBunWeighted);
          }
          // } else {
          //   // growl.error(result.data.data.poliName + ' not answered the quiz yet');
          // }

        }, function (err) {
          if (err) {
            $scope.Popup();
          }
          MyService.ConsoleLog("Error", err);
        });
      };
      $scope.getCompMatch();

      /**
       * Show match details
       */
      $scope.showDetails = function () {
        $scope.currentIndex = 0;
        $scope.showcomparison = true;
      };



      /**
       * Get Category for background
       */


      $scope.getCategory = function (params) {
        try {
          prevFetchedCategoryId = $scope.category._id
        } catch (e) {}
        if (prevFetchedCategoryId != params.id) {
          $routeParams.categoryId = params.id;
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          getCategoryCanceler.resolve();
          getCategoryCanceler = $q.defer()
          $scope.status.category = 1;

          MyService.getCategories(params, getCategoryCanceler.promise).then(function (response) {
            if (response) {
              if (response.data.success) {
                $scope.category = response.data.data;
                MyService.ConsoleLog("$scope.category", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
              } else {
                $scope.status.category = 3;
              }
            } else {
              $scope.status.category = 2;
            }
          }, function (err) {
            ////MyService.ConsoleLog("Error: ", err);
            $scope.status.category = 3;
            ////MyService.ConsoleLog("$scope.status: ", $scope.status.category);
          });
        } else {
          $timeout(function () {
            $location.hash('DisplayQ');
            $anchorScroll();
          }, 500);
        }
      };



      $scope.viewAnsComment = function (ans) {
        if (ans.comment) {
          ngDialog.open({
            template: 'app/popups/show_comment_txt.html',
            controller: 'OldAnswerViewerCtrl',
            data: {
              title: "Comment",
              content: ans.comment
            }
          });
        }
      };


      $scope.rootCatIndex = 0;
      $scope.getRootCategoryIndex = function () {
        if ($routeParams.categoryId == 'all') {
          var len = $rootScope.categories.length;
          for (var i = 0; i < len; i++) {
            if ($routeParams.parentId == $rootScope.categories[i]._id) {
              $scope.rootCatIndex = i;
              ////MyService.ConsoleLog("rootCatIndex : ", $scope.rootCatIndex);
              break;
            }
          }
        }
      };

      var getLevelCategoryCanceler = $q.defer();

      function getLevelCategory(params) {
        getLevelCategoryCanceler.resolve();
        $scope.status.levlCat = 1;
        params.timeout = getLevelCategoryCanceler.promise;
        var request = apiCall.apiCall('GET', '/categories/list-layer', params);
        $http(
            request
          )
          .then(function successCallback(response) {
            ////MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.traverseBackgrounds = response.data.data;
              if ($routeParams.selectedCatId) {
                angular.forEach(response.data.data, function (val, key) {
                  if (val._id == $routeParams.selectedCatId) {
                    $scope.currBackgroundIndex = key;
                    $scope.category = val;
                  }
                });
              } else if ($scope.traverseBackgrounds.length > 0) {
                $scope.category = $scope.traverseBackgrounds[0];
              }
              $scope.status.levlCat = 2;
            } else {
              ////MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.levlCat = 3;
            }
          }, function errorCallBack(response) {
            ////MyService.ConsoleLog("Error: ", response);
            $scope.status.levlCat = 3;
          });
      }

      $scope.skipQuestion = function () {
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories[0].cid
              });
            }
          }
          if ((tempIndex == $scope.questions.length) || $scope.questions.length == 0) {
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            if (tempBackIdx < $scope.traverseBackgrounds.length) {
              $scope.currBackgroundIndex++;
              // alert("if");
              $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            } else {
              $scope.rootCatIndex++;
              $scope.showcomparison = false;
              $scope.noDataFound = false;
              // alert("else");
              window.localStorage.setItem("RootCatIndex", $scope.rootCatIndex);
              window.localStorage.setItem("DisplayCategory", JSON.stringify($scope.category));

              //$location.path("/compare-politician-questions");

            }
          }
          //$scope.currentIndex++;
        };

        skip();
      };

      $scope.prevQuestion = function () {
        ////MyService.ConsoleLog("** prevQuestion **");
        // $timeout(function() {
        //   $location.hash('DisplayQ');
        //   $anchorScroll();
        // }, 1500);
        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            ////MyService.ConsoleLog("** end of background **");
            if ($scope.rootCatIndex > 0) {
              $scope.rootCatIndex--;
              $location.path("/question-compare-politician/all/" + $rootScope.categories[$scope.rootCatIndex]._id);
            }
          }
        }
      };

      $scope.compareChanged = function (compare_with) {
        // //MyService.ConsoleLog("compare_with :", compare_with);
        var catid = $routeParams.categoryId;
        if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
          var catid = $routeParams.categoryId.split("popular:")[1];
        }

        $location.path('/compare-select/' + compare_with);
      };

      $scope.getComapreClass = function (match, i) {

        if (match >= 1)
          return 'ag-dg1';
        if (match >= 2)
          return 'ag-dg2';
        if (match >= 3)
          return 'ag-dg3';
        if (match >= 4)
          return 'ag-dg4';
        if (match >= 5)
          return 'ag-dg5';
        if (match >= 6)
          return 'ag-dg6';

        return 'ag-dg';
      };




      var current_url = $location.url();
      ////MyService.ConsoleLog("$location.url: ", current_url);

      $scope.state = "question-compare-politician";
      if (!$rootScope.selectedCompareUsers || $rootScope.selectedCompareUsers.length <= 0) {
        //$location.path("/questionHome");

      } else {
        if ($routeParams.categoryId == 'all') {
          ////MyService.ConsoleLog("compare all answers");
          var param = {
            id: $routeParams.parentId,
            questions_counter: 1
          };
          getLevelCategory(param);
          $scope.getRootCategoryIndex();
        } // traverse all questions
        else {
          $scope.questions = $rootScope.compareAnswerList;
          // //MyService.ConsoleLog($scope.questions);
          var catid = $routeParams.categoryId;
          // //MyService.ConsoleLog(catid)
          $scope.getCategory({
            id: catid
          });
        }
      }



      $scope.retainOptions = "";
      $scope.fb = {
        options: "C"
      };

      // $scope.getFiveFacebookPost();

      $scope.$watch('category', function (newValue, oldValue) {
        //MyService.ConsoleLog("** category watch **");
        var current_url = $location.url();
        if (current_url.indexOf("question-tagged") > -1)
          return; //for tagged questions don't again fetch questions

        if (newValue) {
          if (oldValue && oldValue._id == newValue._id) return;

          if (current_url.indexOf("question-compare-politician") > -1) {
            var temp_uids = [];
            angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
              temp_uids.push(val._id);
            });

          }
        }
      });

      $scope.$watch('questions', function (newValue, oldValue) {
        //MyService.ConsoleLog("** questions watch **");
        if (!$scope.questions) {
          $scope.questions = $rootScope.fiveminquestions;
        }
        try {
          if ($scope.questions) {
            var tempCate = $scope.questions[$scope.currentIndex].categories;
            if (angular.isArray(tempCate)) {
              $scope.getCategory({
                id: tempCate[0].cid
              });
            } else {
              $scope.getCategory({
                id: tempCate.cid
              });
            }
          }
          // $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("questions Index Change Ex: ", e)
        }
      });

      $scope.$watch('currentIndex', function (newValue, oldValue) {
        //MyService.ConsoleLog("** currentIndex watch **");
        // console.log("fiveminquestions >> ", $rootScope.fiveminquestions);
        if (!$scope.questions) {
          $scope.questions = $rootScope.fiveminquestions;
        }
        try {
          // //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          if ($scope.questions) {
            var tempCate = $scope.questions[$scope.currentIndex].categories;
            if (angular.isArray(tempCate)) {
              $scope.getCategory({
                id: tempCate[0].cid
              });
            } else {
              $scope.getCategory({
                id: tempCate.cid
              });
            }
          }
          // $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });
    }
  ]);
