'use strict';
angular.module('myApp.allies', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/allies', {
      templateUrl: 'app/allies/allies.html',
      controller: 'alliesCtrl'
    });
  }])
  .controller('alliesCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
