'use strict';
angular.module('myApp.createBallotThread', ['ngRoute'])
  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/createBallotThread/:bId?', {
      templateUrl: 'app/createBallotThread/createBallotThread.html',
      controller: 'createBallotThreadCtrl'
    });
  }])
  .controller('createBallotThreadCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'MyService', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, MyService, ngDialog) {

    /**
     * Params
     */
    $scope.bId = $routeParams.bId;
    MyService.ConsoleLog(" $scope.bId", $scope.bId);

    $scope.TopicButton = false;
    $rootScope.welcome = false;
    if (tokenService.getUserData()) {
      $scope.uData = JSON.parse(tokenService.getUserData());
    }
    $scope.uId = $routeParams.bId;
    if (window.localStorage.getItem("ballotThreadData") != "") {
      $rootScope.ballotThreadData = JSON.parse(window.localStorage.getItem("ballotThreadData"));
    }

    $scope.location = ['National', 'State', 'County', 'Local'];
    $scope.ShowLocationOptions = false;
    $scope.Topic = {
      title: '',
      description: '',
      location: 'National',
      restrictedTo: 'No Restrictions',
    };
    $scope.restrictedOptions = ['Locals Only', 'My County', 'My State', 'National', 'No Restrictions'];


    var current_url = $location.url();
    MyService.ConsoleLog("asndkadakd", $rootScope.ballotThreadData);
    if (!$routeParams.categoryId && $rootScope.ballotThreadData) {
      angular.forEach($rootScope.ballotThreadData.users, function (val, key) {
        MyService.ConsoleLog("key: ", val);
        if (val._id == $rootScope.ballotThreadData.selected_user._id) {
          val.selected = true;
        }
      });
    }

    if (window.localStorage.getItem("VoteWiseCategory")) {
      $rootScope.allcategories = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
      var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);
      if (idx == -1) {
        $rootScope.allcategories.push($routeParams.subcatId);
        window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
      }
    }
    //MyService.ConsoleLog("Final categories : ", window.localStorage.getItem("VoteWiseCategory"));
    $scope.subtopics = {
      subcategories: []
    };

    $scope.selectedCat = [];
    $scope.SelectedTree = [];
    $scope.disabled = [];
    $scope.getVal = function (data) {
      MyService.ConsoleLog(" getVal :", data);
      var idx = $scope.selectedCat.indexOf(data);
      if (idx > -1) {
        $scope.selectedCat.splice(idx, 1);
        var id = $scope.disabled.indexOf(data._id);
        $scope.disabled.splice(id, 1);
        var jdx = $scope.SelectedTree.indexOf(data.parentIds);
        if (jdx > -1)
          $scope.SelectedTree.splice(jdx, 1);

      } else {
        $scope.selectedCat.push(data);
        var id = $scope.disabled.indexOf(data._id);
        if (id == -1) {
          $scope.disabled.push(data._id);
        }
        //MyService.ConsoleLog(data.parentIds);
        //MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
        if ($scope.SelectedTree.length == 0) {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            //angular.copy(data.parentIds, $scope.SelectedTree);
            var parents = data.parentIds;
            //MyService.ConsoleLog(parents);
            $scope.SelectedTree = parents;
          } else {
            var cobj = new Object;
            cobj.pid = data._id;
            cobj.viewOrder = 1;
            cobj.path = "," + data._id + ",";
            $scope.SelectedTree.push(cobj);
            //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
          }
        } else {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            var parents = angular.copy(data.parentIds);
            //var parents = data.parentIds;
            var plen = parents.length;
            for (var j = 0; j < plen; j++) {
              var jdx = indexOfExt($scope.SelectedTree, parents[j]);
              //MyService.ConsoleLog(jdx);
              if (jdx == -1) {
                $scope.SelectedTree.push(parents[j]);
              }
            }
          } else {
            var cobj = new Object;
            cobj.pid = data._id;
            cobj.viewOrder = 1;
            cobj.path = "," + data._id + ",";
            $scope.SelectedTree.push(cobj);
            //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
          }
        }
      }
      //MyService.ConsoleLog($scope.SelectedTree);
    }


    $scope.selectedAll = {
      id: []
    };
    $scope.removeVal = function (data) {
      var idx = $scope.selectedCat.indexOf(data);
      if (idx > -1) {
        $scope.selectedCat.splice(idx, 1);
        var id = $scope.disabled.indexOf(data._id);
        $scope.disabled.splice(id, 1);
        var parents = data.parentIds;
        var plen = parents.length;
        for (var j = 0; j < plen; j++) {
          var jdx = $scope.SelectedTree.indexOf(parents[j]);
          if (jdx > -1)
            $scope.SelectedTree.splice(jdx, 1);
        }
      }
      $scope.selectedAll.id.length = 0;
      //MyService.ConsoleLog($scope.SelectedTree);
      var allLen = $scope.SelectedTree.length;
      var chkboxe = document.getElementsByName("checkedidcat");
      for (var i = 0; i < allLen; i++) {
        chkboxe[i].checked = true;

      }
    }

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    function areEqual() {
      var len = arguments.length;
      for (var i = 1; i < len; i++) {
        if (arguments[i] == null || arguments[i] != arguments[i - 1])
          return false;
      }
      return true;
    }

    function indexOfExt(list, item) {
      var len = list.length;

      for (var i = 0; i < len; i++) {
        var keys = Object.keys(list[i]);
        var flg = true;
        var arr = [];
        for (var j = 0, s = 1; j < keys.length; j++, s++) {
          var value = list[i][keys[j]];
          //MyService.ConsoleLog("value :" + value + "-----Item : " + item[keys[j]]);
          //MyService.ConsoleLog(typeof value + "===" + typeof item[keys[j]]);
          if ((typeof value != "object") && (typeof item[keys[j]] != "object")) {
            if (item[keys[j]] !== value) {
              flg = false;
              //MyService.ConsoleLog("flg : ", typeof item[keys[j]], typeof value);
            }
          }
          if (item[keys[j]] == value) {
            arr.push(flg);
          } else if ((typeof value == "object") && (typeof item[keys[j]] == "object")) {
            arr.push(flg);
          }


        }
        if (flg == true) {
          //MyService.ConsoleLog(arr.length + "-----" + keys.length + "-----" + Object.keys(item).length);
          if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
            return i;
          }

        }


      }
      return -1;
    }

    $scope.checksubCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.pid) {
            var idx = indexOfExt($scope.selectedCat, val);

            if (idx == -1) {
              $scope.selectedCat.push(val);
            }
            return rdata
          }

        }
      );
    }

    $scope.checkCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id == val) {
            //MyService.ConsoleLog("parentIds : ", rdata.parentIds.length);
            if (rdata.parentIds.length > 0) {
              var parents = rdata.parentIds.length;
              for (var j = 0; j < parents; j++) {
                var newd = angular.copy(rdata.parentIds[j]);
                //MyService.ConsoleLog("newd:", newd);
                //MyService.ConsoleLog("SelectedTree:", $scope.SelectedTree);
                var jdx = indexOfExt($scope.SelectedTree, newd);
                //MyService.ConsoleLog(jdx);
                if (jdx == -1) {
                  $scope.SelectedTree.push(newd);
                }
              }
            }
            if (rdata.parentIds.length > 1) {
              var idx = indexOfExt($scope.selectedCat, angular.copy(rdata));
              if (idx == -1) {
                $scope.selectedCat.push(angular.copy(rdata));
              }
            } else {
              $scope.selectedCat.push(angular.copy(rdata));
            }
            return rdata;
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val)
          }
        }
      );
    }

    $scope.getSubcatTree = function () {
      $scope.selectedCat.length = 0;
      $scope.EssayButton = true;
      MyService.getAllCatTree({
        "type": "F"
      }).then(function (payload) {
          $scope.subtopics.subcategories.length = 0;
          var subcatdata = payload;
          //MyService.ConsoleLog("subcatdata : ", subcatdata);
          $scope.disabled.length = 0;
          //$scope.disabled.push(window.localStorage.getItem("VoteWiseParentCategory"));
          $scope.subtopics.subcategories = subcatdata;
          if ($routeParams.categoryId) {
            $scope.disabled = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
            //MyService.ConsoleLog("subcat : ", $scope.disabled);
            var selectedCat = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
            if (selectedCat.length > 0) {
              for (var s = 0; s < selectedCat.length; s++) {
                var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
              }
              //MyService.ConsoleLog($scope.selectedCat);
              //$scope.SelectedTree = angular.copy($scope.selectedCat);
            }
            $scope.EssayButton = false;
            setTimeout(function () {
              var cols = document.getElementsByClassName('cmnUL');
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = "none";
              }
            }, 300);
          } else {
            MyService.ConsoleLog("* Inside else *");
            angular.forEach($scope.subtopics.subcategories, function (val, key) {
              if (val.title && val.title.toLowerCase() == "ballot measure") {
                $scope.disabled = [val._id];
                // var checkcatdata = $scope.checkCatExists(subcatdata, val);
                $scope.selectedCat.push(val);
              }
            });
          }
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });
    }

    $scope.showSubCat = true;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];
    $scope.ChangeShow = function (id) {
      //$scope.showSubCat = true;
      //var cols = document.getElementsByClassName('cmnUL');
      //var clen = cols.length;
      var nclass = "MainCatLi_" + id;
      if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
        document.getElementsByClassName(nclass)[0].style.display = "block";
      } else {
        document.getElementsByClassName(nclass)[0].style.display = "none";
      }
    }

    $scope.AddLocation = false;
    $scope.newlocation = {
      country: ''
    };
    $scope.Allcountries = [];
    $scope.SelectedCountry = '';

    $scope.ShowAddLocation = function () {
      if (!$scope.AddLocation) {
        $scope.AddLocation = true;
      } else {
        $scope.AddLocation = false;
      }

    }

    function suggest_country(term) {
      var q = term.toLowerCase().trim();
      var results = [];
      var len = $scope.Allcountries.length;
      // Find first 10 states that start with `term`.
      for (var i = 0; i < len && results.length < 10; i++) {
        var country = $scope.Allcountries[i];
        if (country.toLowerCase().indexOf(q) === 0)
          results.push({
            label: country,
            value: country
          });
      }
      return results;
    }

    $scope.autocomplete_options = {
      suggest: suggest_country
    };

    $scope.getAllStates = function () {
      MyService.getAllStates().then(function (payload) {
          $scope.allLocations.length = 0;
          var subcatdata = payload;
          //MyService.ConsoleLog("countires : ", subcatdata);
          $scope.allLocations = subcatdata;
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });

    }
    $scope.getAllStates();

    // var fetchPoliticiansByStateCanceler = $q.defer();
    $scope.statusfetchPoliticiansByState = 0;
    $scope.fetchPoliticiansByState = function (selState, idx) {
      //MyService.ConsoleLog("selState: ", selState);
      // fetchPoliticiansByStateCanceler.resolve();
      // fetchPoliticiansByStateCanceler = $q.defer();
      // $scope.statusfetchPoliticiansByState = 1;
      // $scope.politicians = [];
      //MyService.ConsoleLog("selState.loadPolitician: ", selState.loadPolitician);
      if (selState.loadPolitician == false) {
        //MyService.ConsoleLog("* this is false *");
        $scope.allLocations[idx].politicians = [];
        return;
      }
      $scope.allLocations[idx].getPolStatus = 1;
      MyService.getPoliticianLocWise({
        statePostalCode: selState.statePostalCode
      }, null).then(function (response) {
        if (response.data.success) {
          // $scope.politicians = response.data.data;
          // $scope.statusfetchPoliticiansByState = 2;
          $scope.allLocations[idx].politicians = response.data.data;
          $scope.allLocations[idx].getPolStatus = 2;
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
          // $scope.statusfetchPoliticiansByState = 3;
          $scope.allLocations[idx].getPolStatus = 3;
        } else {
          growl.error('Something went wrong.');
          // $scope.statusfetchPoliticiansByState = 3;
          $scope.allLocations[idx].getPolStatus = 3;
        }
      }, function (err) {
        growl.error('Something went wrong.');
        // $scope.statusfetchPoliticiansByState = 3;
        $scope.allLocations[idx].getPolStatus = 3;
      });
    };
    $scope.selectUsersFromGroup = function (usres, flag) {
      angular.forEach(usres, function (val, key) {
        val.selected = flag;
      });
    };

    $scope.allLocations = [];
    $scope.allCounties = [];
    $scope.selectedArea = [];
    $scope.rmselectedArea = [];
    $scope.myselectionarea = [];
    $scope.disabledLocation = [];

    $scope.checkExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.state == val)
            return rdata
        }
      );
    }

    $scope.checkCountyExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.county == val)
            return rdata
        }
      );
    }

    $scope.checkCountryExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.country == val)
            return rdata
        }
      );
    }

    $scope.checkDisabled = function (data, stateId, type) {

      data.country = "United States of America";
      data.state = stateId;

      var idx = indexOfExt($scope.disabledLocation, data);
      if (idx > -1)
        return true;
      else
        return false;


    }

    $scope.getAreaVal = function (data, stateId, type) {

      if (!data.country) {
        data.country = "United States of America";
      }
      if (!data.state) {
        data.state = stateId;
      }



      var idx = indexOfExt($scope.selectedArea, data);


      if (idx > -1) {
        alert("You have already selected the location");
      } else {

        $scope.selectedArea.push(data);
        $scope.disabledLocation.push(data);

      }

      //MyService.ConsoleLog($scope.disabledLocation);
    }
    $scope.selectedLAll = {
      id: []
    };
    $scope.removeAreaVal = function (data) {
      var idx = $scope.selectedArea.indexOf(data);

      //MyService.ConsoleLog($scope.Topic.Locations);
      if (idx > -1) {
        //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
        if ($scope.selectedArea[idx].country == "International") {
          $scope.Topic.Locations = 'NO';
        }
        if ($scope.selectedArea[idx].country == "National (USA)") {
          $scope.Topic.National = 'NO';
        }
        $scope.selectedArea.splice(idx, 1);
        var jdx = $scope.disabledLocation.indexOf(data);
        if (jdx > -1) {
          $scope.disabledLocation.splice(jdx, 1);
        }
      }
      $scope.selectedLAll.id.length = 0;
      //MyService.ConsoleLog($scope.selectedArea);
      var allLen = $scope.selectedArea.length;
      var chkboxe = document.getElementsByName("checkedid");
      for (var i = 0; i < allLen; i++) {
        chkboxe[i].checked = true;

      }

    }
    $scope.allCounties = [];
    $scope.allLocals = [];
    $scope.setLocation = function (option) {
      $scope.Essay.location = option;
    }
    $scope.getLocations = function (val, type) {
      if (type == "National") {
        if (val == "National") {

          var nobj = {
            country: "National (USA)"
          }
          $scope.selectedArea.push(nobj);
          //MyService.ConsoleLog($scope.selectedArea);
        }
        if (val == "NO") {

          var nobj = {
            country: "National (USA)"
          }
          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      } else if (type == "International") {
        if (val == "International") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }
          $scope.selectedArea.push(nobj);

        }
        if (val == "NO") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }
          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      }
    }

    $scope.AddCountry = function () {
      if (($scope.newlocation.country) && ($scope.newlocation.country != '')) {
        var idx = $scope.Allcountries.indexOf($scope.newlocation.country);
        if (idx > -1) {
          var nobj = {
            country: $scope.newlocation.country
          };
          $scope.selectedArea.push(nobj);
        } else {
          alert("Country does not exists in our database. Please check if you have entered correct name.");
        }
      } else {
        alert("Please enter country name");
      }
    }

    $scope.getCounties = function (state) {
      console.log(state);
      $scope.showLoading.push(state);
      if (!$scope.allCounties[state] || $scope.allCounties[state].length == 0) {
        MyService.getAllCounties(state).then(function (payload) {
            $scope.showLoading.length = 0;
            var sublocations = payload;
            if (!$scope.allCounties) {
              $scope.allCounties = [];
            }
            $scope.allCounties[state] = sublocations;
          },
          function (errorPayload) {
            alert('Something went wrong.');
          });

      } else {
        $scope.showLoading.length = 0;
        $scope.allCounties[state].length = 0;
      }
    }

    $scope.getLocals = function (state, county) {
      $scope.showLoadingCounty.push(county);
      if (!$scope.allLocals[state] || !$scope.allLocals[state][county] || $scope.allLocals[state][county].length == 0) {
        MyService.getAllSubCountiesLocal(state, '', county).then(function (payload) {
            $scope.showLoadingCounty.length = 0;
            MyService.ConsoleLog("payload Locals : ", payload);
            if (!$scope.allLocals[state]) {
              $scope.allLocals[state] = [];
            }
            $scope.allLocals[state][county] = payload.places;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      } else {
        $scope.showLoadingCounty.length = 0;
        $scope.allLocals[state][county].length = 0;
      }
    }

    $scope.ShowLocation = function () {
      $scope.ShowLocationOptions = true;
      MyService.getAllCountries().then(function (payload) {
          $scope.Allcountries = payload;
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });
      $scope.getAllStates();
    }

    $scope.SubmitTopic = function (data) {
      if ($.fn.validateForceFully($("#topicform")) == true) {
        //$scope.TopicButton = true;
        MyService.ConsoleLog("first", $scope.disabled);
        data.parentcat = JSON.stringify($scope.disabled);
        if ($scope.curState == "createBallotThread" && $scope.disabled <= 0) {
          growl.error("There must be a root category to with title 'Ballot Measure'.");
          return;
        }
        var subcat = [];
        if ($scope.curState == "createBallotThread" && $scope.disabled <= 0) {
          subcat = JSON.stringify($scope.disabled);
        } else {
          subcat = JSON.stringify($scope.SelectedTree);
        }

        data.subcategories = subcat;
        data.createdById = tokenService.getUserId();
        data.createdByName = tokenService.getUserName();
        data.createdByType = tokenService.getUserRole();
        if ($scope.selectedArea.length > 0) {
          data.location = JSON.stringify($scope.selectedArea);
        } else {
          var arr = [];
          var acountry = "";
          if ($scope.uData) {
            if ($scope.uData.address.country == null) {
              acountry = "United States of America";
            } else {
              acountry = $scope.uData.address.country;
            }
            var obj = {
              country: acountry,
              state: $scope.uData.address.state,
              statePostalCode: $scope.uData.address.statePostalCode,
              county: $scope.uData.address.county,
              countySubdivision: $scope.uData.address.countySubdivision,
              place: $scope.uData.address.place
            };
            arr.push(obj);
            data.location = JSON.stringify(arr);
          }
        }


        //MyService.ConsoleLog(data);
        //MyService.ConsoleLog($scope.selectedArea);

        var tempballotids = [];
        try {
          //MyService.ConsoleLog("catch: ", $rootScope.politicianThreadData.users.length);
          angular.forEach($rootScope.ballotThreadData.users, function (pol, key) {
            //MyService.ConsoleLog("key: ", key);
            if (pol.selected) {
              tempballotids.push(pol._id);
            }
          });
        } catch (e) {
          //MyService.ConsoleLog("politicians thread exception: ", e);
        }
        if ($scope.curState == "createBallotThread" && tempballotids.length <= 0) {
          growl.error("Please select at least one politician.");
          return;
        }
        data.taggedUsers = tempballotids.join(",");
        MyService.ConsoleLog("data: ", data);


        var request = apiCall.apiCall('POST', '/topics/create', data);
        $http(
          request
        ).then(function successCallback(response) {
            MyService.ConsoleLog("Got categories: ", response);
            $scope.TopicButton = true;
            if (response.data.success) {
              if (!window.localStorage.getItem("VoteWiseParentCategory")) {
                MyService.ConsoleLog("response.data.data", response.data.data);
                MyService.ConsoleLog(response.data.data.parentCat[0]);
                if ($routeParams.categoryId && $routeParams.subcatId) {
                  $location.path("/forum/" + response.data.data.parentCat[0] + "/home");
                } else {
                  // alert('one');
                  $location.path('/ballotThread/' + $scope.uId);
                }

              } else {

                var cat = window.localStorage.getItem("VoteWiseParentCategory") || null;
                MyService.ConsoleLog(" window.localStorage.getIte", cat);
                MyService.ConsoleLog(" window.localStorage.getIte", cat);

                if (cat != 'undefined') {
                  MyService.ConsoleLog("$$$$$$$$ IF $$$$$$$$$");
                  if ($routeParams.categoryId && $routeParams.subcatId) {
                    $location.path("/forum/" + window.localStorage.getItem("VoteWiseParentCategory") + "/home");
                  } else {
                    // alert('two');
                    $location.path('/ballotThread/' + $scope.uId);
                  }
                } else {
                  MyService.ConsoleLog("$$$$$$$$ ELSE $$$$$$$$$");
                  if ($routeParams.categoryId && $routeParams.subcatId) {

                    $location.path("/forum/" + response.data.data.parentCat[0] + "/home");
                  } else {
                    // alert('three');
                  }
                }

              }

            } else {
              if (response.data.error) {
                alert(response.data.error);
                //MyService.ConsoleLog(response.data);
                if ((response.data.status) && (response.data.status == "405")) {
                  var msg = "Do you want to send request to Admin to reactivate your account?.";
                  MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                    MyService.sendActivatationMailtoAdmin().then(function (payload) {
                      alert("Request sent successfully to admin");
                    }, function (errorPayload) {
                      alert("Something went wrong");
                    })
                  }, function (errorPayload) {
                    //MyService.ConsoleLog(errorPayload);
                  });
                }
              }
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          });
      }
    };

    $scope.goBack = function () {
      $location.path('/ballotThread/' + $scope.uId);
    }

    if (!$rootScope.UserId) {
      var message = "Please login first.";

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
        plain: true
      });

      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", data);
        $location.path('signin');
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
        $location.path('signin');
      });
    } else {
      $scope.getSubcatTree();
    }
    $("#topicform").validationEngine();

  }]);
