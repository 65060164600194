'use strict';

angular
  .module('myApp.orgRating', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/orgRating/:pressId/:type?', {
        templateUrl: 'app/orgRating/orgRating.html',
        controller: 'orgRatingCtrl',
      });
    },
  ])

  .controller('orgRatingCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    'baseApiUrl',
    '$q',
    '$timeout',
    'growl',
    'tokenService',
    '$anchorScroll',
    '$sce',
    'MyService',
    'ngDialog',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      baseApiUrl,
      $q,
      $timeout,
      growl,
      tokenService,
      $anchorScroll,
      $sce,
      MyService,
      ngDialog
    ) {
      $rootScope.welcome = false;
      $scope.pressId = $routeParams.pressId;
      $scope.showUnFollow = false;
      // $scope.loginUser = tokenService.getUserId();
      $scope.dataType = $routeParams.type;
      $scope.userType = tokenService.getUserRole();

      $scope.showCon = false;
      $scope.showBoth = false;
      $scope.showEm = false;


      /**
       * Redirect
       */
      $scope.goto = function () {
        $location.path('/verifyBy');
      };

      /**
       * Close it
       */
      $scope.closeIt = function () {
        $scope.PopDialog.close();
      };
      $scope.getmyData = function () {
        $scope.ShowPorifleLoading = true;
        MyService.getUserPublicProfile({
          id: tokenService.getUserId(),
          type: 'false'
        }).then(function (response) {
          $scope.ShowPorifleLoading = false;
          if (response.data.success) {
            $scope.userDetails = response.data.data;
            // MyService.ConsoleLog("welcome", $scope.userDetails);
            $scope.userProfile = $scope.userDetails.userData;
            MyService.ConsoleLog("welcome to profile", $scope.userProfile);

            $scope.userType = $scope.userProfile.userType;
            $scope.verify = $scope.userProfile.verifiedUser;
            // MyService.ConsoleLog("welcome", $scope.verify);
            // console.log("$scope.userDetails", $scope.userDetails.userData.address);
            if (!$scope.userProfile.verifiedUser) {
              $scope.showCon = true;
            }
            if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
              // $scope.getPopUp();
              if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
                if ($scope.userProfile.userType == 'politician') {
                  $scope.showCon = true;
                  $scope.showBoth = true;
                } else if (!$scope.userProfile.isEmailVerified) {
                  $scope.showEm = true;
                }
              } else if (!$scope.userProfile.isEmailVerified) {
                // if ($scope.userProfile.userType != 'politician') {
                $scope.showEm = true;
                // }
              } else {
                if ($scope.userProfile.userType != 'politician') {
                  $scope.showEm = true;
                }
              }
              if ($scope.userProfile.userType == 'politician') {
                $scope.PopDialog = ngDialog.open({
                  template: 'popUp',
                  closeByNavigation: true,
                  scope: $scope,
                  width: '50%',
                  closeByDocument: false
                });
              } else if ($scope.userProfile.userType == 'advocate' && $scope.userProfile.isEmailVerified != true) {
                // alert('cal');
                MyService.ConsoleLog("$scope.userProfile", $scope.userProfile.isEmailVerified);
                $scope.PopDialog = ngDialog.open({
                  template: 'popUp',
                  closeByNavigation: true,
                  scope: $scope,
                  width: '50%',
                  closeByDocument: false
                });
              }
            }
            // if (!$scope.userDetails.userData.address.statePostalCode) {
            //   $scope.addressPopup = ngDialog.open({
            //     template: 'addressPopUp',
            //     closeByNavigation: true,
            //     scope: $scope
            //   });
            // };

          } else {
            MyService.ConsoleLog("ELSE vishal");
            alert("Something went wrong.");
          }
        }, function (errorPayload) {
          $scope.ShowPorifleLoading = false;
          MyService.ConsoleLog("ELSE vishal two");

          alert("Something went wrong.");
        });
      }

      if (tokenService.getUserId()) {
        $scope.getmyData();
      }

      if (tokenService.getUserData()) {
        $scope.uData = JSON.parse(tokenService.getUserData());

      }

      $scope.froumthreads = [];
      $scope.press = {};
      $scope.Sticky = {
        ShowOrder: [],
        txtOrder: [],
      };
      $scope.vote = {
        recommend: 0,
        factual: 0,
      };
      //$scope.GalleryFolder = baseApiUrl + "/resources/" + $scope.uData.uname;
      if (tokenService.getUserData()) {
        $scope.UserData = JSON.parse(tokenService.getUserData());
        MyService.ConsoleLog('$scope.UserData >', $scope.UserData);
      }
      $scope.displayComments = true;
      $scope.Reply = {};

      $scope.checkMemId = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.userData._id == $rootScope.UserId) return rdata;
        });
      };

      $scope.CheckUrl = function (url, option) {
        if (url) {
          if (url.indexOf('youtube.com') >= 0) {
            var url = url.replace('watch?v=', 'embed/');
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe width="560" height="315" src="' +
                url +
                '" frameborder="0" allowfullscreen></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
              );
          }
          if (url.indexOf('facebook.com') >= 0) {
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                url +
                '" width="560" height="315" frameborder="0"></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
              );
          }
          if (url.indexOf('vimeo.com') >= 0) {
            if (url.indexOf('https://vimeo.com/') >= 0) {
              var id = url.replace('https://vimeo.com/', '');
              var newurl = 'https://player.vimeo.com/video/' + id;
            } else {
              var newurl = url;
            }
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                newurl +
                '" width="500" height="281" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                newurl +
                '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>'
              );
          }
          if (option == 'V')
            return $sce.trustAsHtml(
              '<br/><iframe width="560" height="315" src="' +
              url +
              '" frameborder="0" allowfullscreen></iframe><br/>'
            );
          else
            return $sce.trustAsHtml(
              '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
            );
        }
      };

      $scope.chkIfVoted = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.user.id == val) return rdata;
        });
      };
      $scope.loginUser = tokenService.getUserId();
      $scope.getPressDetails = function () {
        //MyService.ConsoleLog("Get One called");
        MyService.getPress($routeParams.pressId).then(
          function (payload) {
            MyService.ConsoleLog('payLoad', payload);
            if ($scope.UserData && $scope.UserData.followlist.length > 0) {
              var fLen = $scope.UserData.followlist.length;
              for (var f = 0; f < fLen; f++) {
                // MyService.ConsoleLog("$scope.UserData.followlist[f]", $scope.UserData.followlist[f]);
                if ($scope.UserData.followlist[f] == $routeParams.pressId) {
                  $scope.showUnFollow = true;
                  // growl.success("showUnfollow");
                  // alert("showunfollow");
                }
                /*else {
              // MyService.ConsoleLog("userProfile", $scope.userProfile);
              if ($scope.UserData.followlist[f] == $scope.userProfile.officeId) {
                $scope.showUnFollow = true;

              }
            }*/
              }
            }
            if (payload && payload.orgdata) {
              $scope.pressUser = payload.userData;
              $scope.press = payload.orgdata;
              if (payload.orgdata.recommended) {
                var chkrecommend = $scope.chkIfVoted(payload.orgdata.recommended, $scope.loginUser);

                if (chkrecommend && chkrecommend[0]) {
                  $scope.vote.recommend = chkrecommend[0].vote;
                }
              }
              if (payload.orgdata.factual) {
                var chkfactual = $scope.chkIfVoted(payload.orgdata.factual, $scope.loginUser);

                if (chkfactual && chkfactual[0]) {
                  $scope.vote.factual = chkfactual[0].vote;
                }
              }
            } else {
              $scope.press = payload;
              $scope.pressUser = payload;
              MyService.ConsoleLog(' $scope.press ', $scope.press);
              if (payload.recommended) {
                var chkrecommend = $scope.chkIfVoted(payload.recommended, $scope.loginUser);

                if (chkrecommend && chkrecommend[0]) {
                  $scope.vote.recommend = chkrecommend[0].vote;
                }
              }
              if (payload.factual) {
                var chkfactual = $scope.chkIfVoted(payload.factual, $scope.loginUser);

                if (chkfactual && chkfactual[0]) {
                  $scope.vote.factual = chkfactual[0].vote;
                }
              }
            }
            MyService.getHighlighatedPost({
              pId: $scope.pressId,
            }).then(
              function (response) {
                MyService.ConsoleLog('response', response);
                $scope.HighlightedPost = response.data;
              },
              function (err) {
                MyService.ConsoleLog('Error', err);
              }
            );
          },
          function (errorPayload) {
            MyService.ConsoleLog("one", errorPayload);
            alert('Something went wrong ');
          }
        );
      };

      /**
       * Redirect to discussion page
       */
      $scope.redirectToDiscuss = function () {
        // growl.success("redirect to discussion");
        $location.path('/debateHome');
      };

      /**
       * Follow orgnization
       */
      $scope.addtoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.pressId;
        MyService.updateUserFollowList(data).then(
          function (payload) {
            alert('User added to followup list successfully.');
            if ($scope.UserData) {
              $scope.showUnFollow = true;
              $scope.UserData.followlist.push($routeParams.pressId);
              MyService.ConsoleLog('udata : ', $scope.UserData);
              tokenService.saveUserData(JSON.stringify($scope.UserData));
            }
          },
          function (errorPayload) {
            MyService.ConsoleLog("two", errorPayload);

            alert('Something went wrong ');
          }
        );
      };

      /**Unfollow organization*/

      $scope.removetoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.pressId;
        MyService.removeUserFollowList(data).then(
          function (result) {
            MyService.ConsoleLog('result', result);
            $scope.showUnFollow = false;
            var ids = $scope.UserData.followlist.indexOf($routeParams.pressId);
            MyService.ConsoleLog('ids', ids);
            if (ids > -1) {
              $scope.UserData.followlist.splice(ids, 1);
            }

            // else {
            //   var idx = $scope.UserData.followlist.indexOf($scope.userProfile.officeId);
            //   if (idx > -1) {
            //     $scope.UserData.followlist.splice(idx, 1);
            //   }
            // }
            //MyService.ConsoleLog("udata : ", $scope.UserData);
            tokenService.saveUserData(JSON.stringify($scope.UserData));
          },
          function (err) {
            MyService.ConsoleLog('error', err);
          }
        );
      };

      function getCategory() {
        MyService.getCategoryList($routeParams.categoryId).then(
          function (payload) {
            $scope.category = payload;
          },
          function (errorPayload) {
            MyService.ConsoleLog("three", errorPayload);

            alert('Something went wrong ');
          }
        );
      }

      $scope.gotoThread = function (data) {
        //MyService.ConsoleLog(data);
        var id = '';
        if (data.parentId) {
          id = data.parentId;
        } else {
          id = data.id;
        }
        $location('forumthread/' + id);
      };

      $scope.updateCommentApi = function (data, options, idex) {
        MyService.updatePressComments(data).then(
          function (payload) {
            if (payload.nModified == 0) {
              alert('You already provided your inputs on this post');
            } else {
              switch (options) {
                case 'like':
                  $scope.essayComments[idex].totalLikes = $scope.essayComments[idex].totalLikes + 1;
                  break;
                case 'dislike':
                  $scope.essayComments[idex].totalDislike =
                    $scope.essayComments[idex].totalDislike + 1;
                  break;
                default:
                  $scope.essayComments[idex].totalSpam = $scope.essayComments[idex].totalSpam + 1;
                  break;
              }
            }
          },
          function (errorPayload) {
            // alert('Something went wrong ');
            MyService.ConsoleLog("vishal", errorPayload);
            growl.error(errorPayload.error);
          }
        );
      };

      $scope.updateComments = function (options, id, idex) {
        if ($rootScope.UserId) {
          if ($scope.essayComments[idex].createdBy.id != $rootScope.UserId) {
            var commentData = {};
            commentData.commentId = id;
            commentData.userId = $rootScope.UserId;

            switch (options) {
              case 'like':
                commentData.likes = 1;
                break;
              case 'dislike':
                commentData.dislikes = 1;
                break;
              default:
                commentData.spam = 1;
                break;
            }

            $scope.updateCommentApi(commentData, options, idex);
          } else {
            alert('You cannot ' + options + ' your own comments');
          }
        } else {
          alert('Please login to perform these actions');
        }
      };
      $scope.submitSticky = function (data, id, idx) {
        if (data > 0) {
          updateForum('sticky', id, idx);
        } else {
          alert('Please enter valid order');
        }
      };

      $scope.ShowComments = false;

      $scope.redirectToComment = function () {
        var token = apiCall.getToken();
        if (!token) {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
              $location.path('signin');
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
              $location.path('signin');
            }
          );
        }
        $scope.ShowComments = true;
        $scope.displayComments = false;

        //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
      };

      $scope.goBack = function () {
        $scope.ShowComments = false;
        $scope.displayComments = true;
      };

      $scope.getComments = function () {
        MyService.getPressComments($routeParams.pressId).then(
          function (payload) {
            $scope.essayComments = payload;
            MyService.ConsoleLog("$scope.essayComments", $scope.essayComments);
          },
          function (errorPayload) {
            // alert('Something went wrong ');
            MyService.ConsoleLog("vishal two", errorPayload);

            growl.error(errorPayload.error);
          }
        );
      };

      $scope.SubmitComment = function (rec) {
        //MyService.ConsoleLog(data);
        //MyService.ConsoleLog($scope.mainThread);
        var data = {};
        if (rec.content != '') {
          $scope.TopicButton = true;
          data.createdById = $rootScope.UserId;
          data.createdByName = $rootScope.User;
          data.createdByType = tokenService.getUserRole();
          data.comments = rec.content;
          data.pressId = $routeParams.pressId;
          //MyService.ConsoleLog(data);
          MyService.submitPressComments(data).then(
            function (payload) {
              $scope.comments = {
                content: '',
              };
              $scope.ShowComments = false;
              $scope.displayComments = true;
              $scope.mainThread = {};
              //$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
              $scope.getComments();
            },
            function (errorPayload) {
              // alert('Something went wrong ');
              MyService.ConsoleLog("ommmmmm");
              $scope.ShowComments = false;
              $scope.displayComments = true;
              growl.error(errorPayload.error);

            }
          );
        } else {
          alert('Please enter comments.');
        }
      };

      $scope.PressVote = function (option) {
        var token = apiCall.getToken();
        if (!token) {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
              $location.path('signin');
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
              $location.path('signin');
            }
          );
        } else {
          var data = {};
          data._id = $routeParams.pressId;
          data.mediaType = '';
          data.option = option;
          //MyService.ConsoleLog(option);
          if (option == 'Recommended') {
            data.vote = $scope.vote.recommend;
          } else {
            data.vote = $scope.vote.factual;
          }
          data.createdById = $scope.loginUser;
          data.createdByName = tokenService.getUserName();
          data.createdByType = tokenService.getUserRole();
          MyService.updatePress(data).then(
            function (payload) {
              alert('Thank you for rating the press.');
            },
            function (errorPayload) {
              // alert('Something went wrong ');
              MyService.ConsoleLog("ommmmdsssmm");
              console.log("error", errorPayload);
              $scope.vote.recommend = "";
              $scope.vote.factual = "";
              growl.error(errorPayload.error);
            }
          );
        }
      };

      $scope.getVotesmartPressDetails = function () {
        console.log('getVotesmartPressDetails');
        MyService.getVotesmartPressDetails({
          sigid: $scope.pressId,
        }).then(
          function (payload) {
            console.log('payload1 > ', payload);
            if (payload.data.success) {
              $scope.press = payload.data.data;
            } else {
              MyService.ConsoleLog("five", payload);
              alert('Something went wrong ');
            }
          },
          function (epayload) {
            MyService.ConsoleLog("six", epayload);
            alert('Something went wrong ');
          }
        );
      };

      console.log('new', $scope.dataType);
      if ($scope.dataType && $scope.dataType == 'false') {
        $scope.getVotesmartPressDetails();
      } else {
        if ($routeParams.pressId) {
          $scope.getPressDetails();
          $scope.getComments();
        }
      }
    },
  ]);
