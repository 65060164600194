 'use strict';
 angular.module('myApp.verifyUsers', ['ngRoute'])

   .config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/verifyUsers', {
       templateUrl: 'app/verifyUsers/verifyUsers.html',
       controller: 'AdminVerifyUsers'
     });
   }])
   .controller('AdminVerifyUsers', ['$scope', '$http', '$location', 'apiCall', '$rootScope', 'baseApiUrl', '$q', '$filter', 'growl', 'ngDialog', 'MyService', function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, MyService) {
     //MyService.ConsoleLog("* AdminVerifyUsers *");

     $scope.verifySelectedUser = null;
     $scope.showDetails = true;

     $scope.verifyFilter = null;
     $scope.statusVerifyUsers = 0; //1=loading, 2 = loaded, 3 = error while loading
     $scope.page = 1;
     $scope.usersPerPage = 25;
     $scope.isShowOrg = true;

     $scope.User = {
       name: ''
     };

     //$scope.totalUsers = 0;
     $scope.verifyUsers = {
       data: []
     };
     var getVerifyUsersCanceler = $q.defer();
     $scope.statusGetResPg = 0;
     $scope.listUsers = function (page) {
       //MyService.ConsoleLog("getVerifyUsers: ", $scope.verifySelectedUser, " filter: ", $scope.verifyFilter);
       $scope.statusGetResPg = 1;
       getVerifyUsersCanceler.resolve();
       var params = {
         usertype: $scope.verifySelectedUser.toLowerCase(),
         pageno: page,
         max_records: $scope.usersPerPage,
         verified: $scope.verifyFilter,
         timeout: getVerifyUsersCanceler.promise
       };


       $scope.statusVerifyUsers = 1;
       if ($scope.User && $scope.User.name && $scope.User.name != '') {
         params.name = $scope.User.name;
         MyService.getUnVerifiedUser(params).then(function (response) {
           //  MyService.ConsoleLog("Response", response);
           $scope.statusGetResPg = 2;
           if (response.data.success) {
             //  MyService.ConsoleLog("Got users data: ", response.data.data);
             //$scope.verifyUsers = response.data.data;
             $scope.verifyUsers.data = $scope.verifyUsers.data.concat(response.data.data.data);
             $scope.totalUsers = response.data.data.data.totalRecords;
             $scope.statusVerifyUsers = 2;
           } else if (response.data.error && typeof response.data.error == "string") {
             growl.error('Error: ' + response.data.error);
             $scope.statusVerifyUsers = 3;
           } else {
             growl.error('Something went wrong.');
             $scope.statusVerifyUsers = 3;
           }
         }, function (err) {
           MyService.ConsoleLog("err", err);
         });
       } else {
         var request = apiCall.apiCall('GET', '/user/list-paginated', params);
         $http(
           request
         ).then(function successCallback(response) {
           $scope.statusGetResPg = 2;
           if (response.data.success) {
             MyService.ConsoleLog("Got users data: ", response.data.data);
             //$scope.verifyUsers = response.data.data;
             $scope.verifyUsers.data = $scope.verifyUsers.data.concat(response.data.data);
             //$scope.totalUsers = response.data.data.Count;
             $scope.statusVerifyUsers = 2;
           } else if (response.data.error && typeof response.data.error == "string") {
             growl.error('Error: ' + response.data.error);
             $scope.statusVerifyUsers = 3;
           } else {
             growl.error('Something went wrong.');
             $scope.statusVerifyUsers = 3;
           }
         }, function errorCallBack(response) {
           //MyService.ConsoleLog("Error: ", response);
           $scope.statusGetResPg = 2;
           $scope.statusVerifyUsers = 3;
         });
       }
     }

     $scope.myPagingFunction = function () {
       //MyService.ConsoleLog("scoll called");
       if ($scope.statusGetResPg != 1) {

         if ($scope.User && $scope.User.name && $scope.User.name != '') {
           if ($scope.totalUsers >= $scope.verifyUsers.data.length) {
             $scope.page++;
             $scope.listUsers($scope.page);
           }
         } else {
           if ($scope.verifySelectedUser != "") {
             $scope.page++;
             $scope.listUsers($scope.page);
           }
         }


       }
     };
     $scope.pgselectedType = "";
     $scope.getVerifyUsers = function (selectedType) {
       $scope.page = 1;
       $scope.verifyUsers.data.length = 0;
       $scope.verifySelectedUser = selectedType;

       $scope.listUsers($scope.page);
     };

     $scope.getVerifyFilterUsers = function (selectedType, selectedFilter) {
       $scope.page = 1;
       $scope.verifyUsers.data.length = 0;
       $scope.verifySelectedUser = selectedType;
       $scope.verifyFilter = selectedFilter;
       $scope.listUsers($scope.page);
     }

     $scope.verifyThisUser = function (user, index, val) {

       var postobj = {
         userid: user._id,
         verify: val
       };

       //MyService.ConsoleLog("postobj : ", postobj);

       var request = apiCall.apiCall('POST', "/user/verify-by-admin", postobj);
       $http(
         request
       ).then(function successCallback(response) {
         //  MyService.ConsoleLog("Got categories: ", response);
         if (response.data.success) {
           $scope.verifyUsers.data[index].verifieduser = val;
           growl.success("Verified Successfully");
         } else if (response.data.error && typeof response.data.error == "string") {
           growl.error('Error: ' + response.data.error);
         } else {
           growl.error('Something went wrong.');
         }
       }, function errorCallBack(response) {
         growl.error('Something went wrong.');
       });
     }

     //  $scope.getOrganizationDetail = function (user, index) {
     //    //MyService.ConsoleLog("getOrganizationDetail: ", index);
     //    var param = {
     //      id: user.officeId
     //    };
     //    //MyService.ConsoleLog("user: ", param);
     //    $scope.verifyUsers.data[index].organization = {
     //      status: 1
     //    };


     //    if (user.press == true || user.advocate == true) {

     //      MyService.getOfficeList(param).then(function (response) {
     //        //MyService.ConsoleLog("response: ", response);
     //        if (response.data.success) {
     //          $scope.verifyUsers.data[index].organization = response.data.data;
     //          $scope.verifyUsers.data[index].organization.status = 2;
     //        } else if (response.data.error && typeof response.data.error == "string") {
     //          growl.error('Error: ' + response.data.error);
     //          $scope.verifyUsers.data[index].organization.status = 3;
     //        } else {
     //          growl.error('Something went wrong.');
     //          $scope.verifyUsers.data[index].organization.status = 3;
     //        }
     //      }, function (err) {
     //        growl.error('Something went wrong.');
     //        $scope.verifyUsers.data[index].organization.status = 3;
     //      });
     //    }


     //    if (user.politician == true) {
     //      //if(user.officeId) {
     //      MyService.getOnePolitician(param).then(function (response) {
     //        //MyService.ConsoleLog("response: ", response);
     //        if (response.data.success) {
     //          $scope.verifyUsers.data[index].organization = response.data.data;
     //          $scope.verifyUsers.data[index].organization.status = 2;
     //        } else if (response.data.error && typeof response.data.error == "string") {
     //          growl.error('Error: ' + response.data.error);
     //          $scope.verifyUsers.data[index].organization.status = 3;
     //        } else {
     //          growl.error('Something went wrong.');
     //          $scope.verifyUsers.data[index].organization.status = 3;
     //        }
     //      }, function (err) {
     //        growl.error('Something went wrong.');
     //        $scope.verifyUsers.data[index].organization.status = 3;
     //      });

     //    }
     //  };

     $scope.searchUsers = function () {
       if ($scope.User && $scope.User.name && $scope.User.name != '') {
         MyService.ConsoleLog("$scope.User", $scope.User.name);
         $scope.page = 0;
         $scope.verifyUsers.data = [];
         $scope.totalUsers = [];
         $scope.myPagingFunction();
       }
     };
   }])
