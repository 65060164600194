'use strict';
angular.module('myApp.sendEmail', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/sendEmail', {
      templateUrl: 'app/sendEmail/sendEmail.html',
      controller: 'AllEmailCtrl'
    });
  }]).controller('AllEmailCtrl', ['$scope', 'ngDialog', 'MyService', function ($scope, ngDialog, MyService) {
    $scope.tinymceOptions = {
      plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools'
      ],
      toolbar: ''
    };
    $scope.Email = {
      userType: "",
      toField: "",
      subject: "",
      message: "",
      officeType: ""
    };

    $scope.usersType = ["Politicians", "Advocate", "Press", "Voters", "Voteradmin", "Forumadmin", "Custom"];
    $scope.allOfficeType = ["inOffice", "runningForOffice"];

    $scope.SendMail = function ($event) {
      // MyService.ConsoleLog($scope.Email);
      $event.stopPropagation();
      var submitForm = true;
      if ($scope.Email.userType == "") {
        alert("Please select user type");
        submitForm = false;
      }
      if (submitForm && $scope.Email.userType == "Custom" && $scope.Email.toField == "") {
        alert("To field cannot be empty");
        submitForm = false;
      }

      if (submitForm && $scope.Email.subject == "") {
        alert("Please enter Subject");
        submitForm = false;
      }

      if (submitForm && $scope.Email.message == "") {
        alert("Please enter Message");
        submitForm = false;
      }
      $scope.DisableEmail = false;
      if (submitForm) {
        $scope.DisableEmail = true;
        MyService.sendBatchMail($scope.Email).then(function (payload) {
          //MyService.ConsoleLog(payload);
          if (payload.data.success) {
            var message = "Your Message(s) are queued, will be send depending on the recipient number."
            var modal = ngDialog.openConfirm({
              template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                            </div>',
              plain: true
            });
            modal.then(function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);


            }, function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            });
            $scope.DisableEmail = false;
            $scope.Email = {
              userType: "",
              toField: "",
              subject: "",
              message: ""
            };
          } else {
            alert("Something went wrong");
            $scope.DisableEmail = false;
            $scope.Email = {
              userType: "",
              toField: "",
              subject: "",
              message: ""
            };
          }

        }, function (errorPayload) {
          //MyService.ConsoleLog(errorPayload);
          alert("Something went wrong");
          $scope.DisableEmail = false;
          $scope.Email = {
            userType: "",
            toField: "",
            subject: "",
            message: ""
          };
        })
      }

    };
  }])
