'use strict';

angular.module('myApp.compareWithGroup', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compareWithGroup/:type?', {
      templateUrl: 'app/compareWithGroup/compareWithGroup.html',
      controller: 'compareWithGroupCtrl'
    });
  }]).controller('compareWithGroupCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    $scope.loader = false;
    $scope.noDataFound = false;
    $scope.FBCompareList = [];
    $scope.politician = false;
    $scope.showvoter = false;
    $scope.showadv = false;
    $scope.shownonUser = false;
    $scope.isShowFollow = false;
    if (tokenService.getUserId()) {
      $scope.userId = tokenService.getUserId();
      console.log('$scope.userId: ', $scope.userId);
    }

    if (window.localStorage.getItem('isFindFollowers')) {
      $scope.isShowFollow = true;
    }

    if (window.localStorage.getItem('inviterInfo')) {
      $scope.inviterInfo = JSON.parse(window.localStorage.getItem('inviterInfo'));
      if ($scope.inviterInfo && $scope.inviterInfo.nameOnBallot) {
        $scope.inviterName = $scope.inviterInfo.nameOnBallot;
      } else if ($scope.inviterInfo && $scope.inviterInfo.title) {
        $scope.inviterName = $scope.inviterInfo.title;
      } else if ($scope.inviterInfo) {
        $scope.inviterName = $scope.inviterInfo.username;
      }
      $scope.inviterId = window.localStorage.getItem('inviterId');
    }

    if ($cookies.get('QuizName')) {
      console.log("$cookies.get('QuizName')", $cookies.get('QuizName'));
      $scope.quizTitle = $cookies.get('QuizName');
    }

    $scope.compareWithGroup = function (params) {
      $scope.loader = true;

      MyService.ConsoleLog("params >", params);
      params.usersIds = JSON.stringify(params.usersIds);
      // params.questions = JSON.parse(params.questions);
      MyService.getComparisonOfGroup(params).then(function (payload) {
          MyService.ConsoleLog("payload >", payload);
          $scope.loader = false;

          if (payload.data.data.unweightedMatch && payload.data.data.unweightedMatch != "") {
            $scope.FBunWeighted = payload.data.data.unweightedMatch;
            $scope.politician = true;

          }
          if (payload.data.data.advunWeighated && payload.data.data.advunWeighated != "") {
            $scope.advUnWeighated = payload.data.data.advunWeighated;
            $scope.showadv = true;

          }


          if ((!window.localStorage.getItem('inviterId')) && (!$cookies.get('quizTakerIdsArr')) && payload.data.data.finalunFbcombinedWeight && payload.data.data.finalunFbcombinedWeight != "") {
            if (!window.localStorage.getItem('inviterId')) {
              $scope.unVerifiedWeighated = payload.data.data.finalunFbcombinedWeight;
              $scope.shownonUser = true;
            } else if (!$cookies.get('quizTakerIdsArr')) {
              $scope.unVerifiedWeighated = payload.data.data.finalunFbcombinedWeight;
              $scope.shownonUser = true;
            }
          }
          if (payload.data.data.voterunWeight && payload.data.data.voterunWeight != "") {
            $scope.voterUnWeighated = payload.data.data.voterunWeight;
            $scope.showvoter = true;

          }
          $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
          $scope.fbmultiplier = payload.data.data.fbmultiplier;
          $scope.fbqlen = payload.data.data.qdata.length;
          $scope.unVerifiedFBunWeighted = payload.data.data.finalUnFacbook;
          $scope.unFbmultiplier = payload.data.data.unFbmultiplier;
          $scope.voterCombined = payload.data.data.voterCombWeight;
          $scope.voterMulti = payload.data.data.voterMulti;
          $scope.advCombined = payload.data.data.advCombWeight;
          $scope.advMulti = payload.data.data.advMulti;
          $scope.allUser = payload.data.data.AllUsers;
          MyService.ConsoleLog("check one more time what it is", window.localStorage.getItem('isFindFollowers'));
          if (!window.localStorage.getItem('isFindFollowers')) {
            window.localStorage.removeItem('inviterId');
            window.localStorage.removeItem('inviterInfo');
            $cookies.remove('quizTakerIdsArr');
          } else {
            // window.localStorage.removeItem('isFindFollowers');
          }

          if ($scope.userId && $scope.userId != '') {
            setTimeout(function () {
              var msg = "Do you want to share this quiz with others?  They will be able to take the quiz and see how you match.";

              var modal = ngDialog.openConfirm({
                template: '<p>' +
                  msg +
                  '</p>\
                      <div class="ngdialog-buttons">\
                          <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                          <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">No</button>\
                      </div>',
                plain: true,
                closeByDocument: false,
                width: "70%"
              });
              modal.then(
                function fullfilled(data) {
                  if (data == 1) {
                    $scope.getAllAnsweredQuestions();

                  } else {

                  }
                },
                function rejected(data) {

                }
              );
            }, 3000);
          }


        },
        function (error) {
          MyService.ConsoleLog("error", error);
        });
    };



    $scope.finalSignIn = function () {

      $location.path('/fiveSaveUser');
    };

    /**
     * @description Redirect to get match list
     */
    $scope.redirectTo = function (type) {
      $location.path('/showListOfMatch/' + type);
    };


    /**
     * Hide show Pop Up
     */
    $scope.FBCompareList = [];
    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;
    $scope.allQuestions = [];
    $scope.switchStatus = [];
    $scope.chooseQuestions = function () {
      //MyService.ConsoleLog($scope.oldQuestions);
      if ($scope.allQuestions.length > 0) {

        $scope.showHidePop = ngDialog.open({
          template: 'ReviewMyAnswers',
          scope: $scope,
          width: "60%"
        });
      } else {
        alert("There are no questions to display. Please go through all questions first.")
      }
    }
    //$scope.switchStatus = "Show";
    $scope.showloader = false;
    $scope.switchFilters = function (newValue, index, quest) {
      $scope.showloader = true;
      MyService.ConsoleLog("newValue::", newValue);
      MyService.ConsoleLog("oldValue::", $scope.switchStatus);
      var setVal = "N";
      if (newValue[index] == true) {
        setVal = "Y";
      }

      var idx = $scope.allQuestions.indexOf(quest);
      //MyService.ConsoleLog("setVal:", setVal);
      MyService.updateAnswers({
        "qid": quest._id,
        "show": setVal,
        "author": quest.author,
        'inviterId': $scope.inviterId ? $scope.inviterId : '',
        "quest": JSON.stringify(quest.categories)
      }).then(function (payload) {
        //MyService.ConsoleLog("payload : ", payload);
        $scope.showloader = false;
        if (payload.data.success) {
          quest.my_answer.show = setVal;
          $scope.allQuestions[idx] = quest;

        } else {
          alert("Something went wrong. Contact administrator.");
        }
      }, function (errorpayload) {
        $scope.showloader = false;
        //MyService.ConsoleLog("errorpayload : ", errorpayload);
        alert("Something went wrong. Contact administrator.");
      });
    }

    if (window.localStorage.getItem("quesData")) {
      $scope.questions = JSON.parse(window.localStorage.getItem("quesData"));
      $scope.queIds = $scope.questions.map(function (itm) {
        if (itm.questionId) {
          return itm.questionId;
        } else {
          // console.log("itm", itm);
          return itm._id;
        }
      });
      MyService.ConsoleLog("  $scope.queIds", $scope.queIds);

    }

    $scope.AllAnsweredQuestions = [];
    $scope.getAllAnsweredQuestions = function (userId) {
      MyService.getAllAnsQuesWithIds({
        "authorId": tokenService.getUserId(),
        "ques": JSON.stringify($scope.queIds)
      }).then(function (payload) {
        MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload);
        if (payload.data.success) {
          $scope.AllAnsweredQuestions = payload.data.data;
          $scope.allQuestions = $scope.AllAnsweredQuestions;
          MyService.ConsoleLog("$scope.allQuestions", $scope.allQuestions);
          var tmpS = $scope.allQuestions.filter(function (itms, ind) {
            if (itms && itms.my_answer) {
              if (itms.my_answer.show == 'Y') {
                $scope.switchStatus[ind] = true;
              } else {
                $scope.switchStatus[ind] = false;
              }
            }
          });

          MyService.ConsoleLog("$scope.switchStatus", $scope.switchStatus);
          if ($scope.allQuestions.length == 0) {
            growl.error("There are no questions to display. Please go through all questions first.")
          }
          $scope.chooseQuestions();
        }
      }, function (errpayload) {
        MyService.ConsoleLog("getAllAnsweredQuestions errpayload >> ", errpayload);
      });
    };




    /**
     * Next
     */

    $scope.next = function () {
      $scope.showHidePop.close();
      if ($scope.userId && $scope.userId != '') {
        MyService.ConsoleLog("yes");
        MyService.ConsoleLog("catId", window.localStorage.getItem('cat_id'));
        var catId, selCat_id;
        catId = window.localStorage.getItem('cat_id');
        selCat_id = window.localStorage.getItem('selCat_id');
        MyService.ConsoleLog("selCat_id", window.localStorage.getItem('selCat_id'));
        var tempLink = $location.absUrl().split("#!");
        $scope.shareLink = tempLink[0] + "#!/exploreQuiz/" + catId + "/" + selCat_id + "/" + $scope.userId;
        MyService.ConsoleLog("here we go with link", $scope.shareLink);
        ngDialog.open({
          template: 'exporeQuizLink',
          scope: $scope,
          width: "60%"
        });
      }
    };

    /**
     * Cancel
     */

    $scope.cancel = function () {
      $scope.showHidePop.close();
    };
    /**
     * @description Update quiz answers with existing flag
     */
    $scope.updateQuizAnswers = function () {
      if (window.localStorage.getItem('comData')) {
        $scope.quizData = JSON.parse(window.localStorage.getItem('comData'));
        $scope.answers = JSON.parse($scope.quizData.questions);
      }
      MyService.ConsoleLog("$scope.quizData", $scope.quizData);
      MyService.ConsoleLog("$scope.answers", $scope.answers);
      var token = apiCall.getToken();
      var ansLen = $scope.answers.length;

      for (var i = 0; i < ansLen; i++) {
        var isShare;
        if ($scope.answers[i].my_answer.show == 'Y') {
          isShare = true;
        } else {
          isShare = false;
        }
        var postData = {
          questionId: $scope.answers[i]._id,
          importance: $scope.answers[i].my_answer.importance,
          answer: $scope.answers[i].my_answer.answer,
          id: $scope.answers[i].my_answer._id,
          comment: $scope.answers[i].my_answer.comment,
          token: token ? token : "",
          isShare: isShare,
          inviterId: $scope.inviterId,
          selCatId: $scope.answers[i].categories.cid
        };
        MyService.ConsoleLog("<<<<<<<<<< postData", postData);
        MyService.postAnswer(postData).then(function (APIResponse) {
          MyService.ConsoleLog("APIResponse", APIResponse);
        }, function (err) {
          MyService.ConsoleLog("err", err);
        });
      }
      window.localStorage.removeItem('isUpdate');
      $scope.getAllAnsweredQuestions();
      $scope.compareWithGroup($scope.quizData);
    };

    if (window.localStorage.getItem('comData')) {
      // var ques = window.localStorage.getItem('quesData');
      $scope.compare = JSON.parse(window.localStorage.getItem('comData'));
      MyService.ConsoleLog("<<<<<<<<<<<< questions >>>>>>>>>>>", $scope.compare);
      if (window.localStorage.getItem('isUpdate')) {
        $scope.updateQuizAnswers();
      } else {
        $scope.compareWithGroup($scope.compare);
      }

    }


  }]);
