'use strict';
angular.module('myApp.openFecCandidates', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/openFecCandidates', {
      templateUrl: 'app/openFecCandidates/openFecCandidates.html',
      controller: 'openFECCtrl'
    });
  }]) .controller('openFECCtrl', ['$scope', 'ngDialog', '$rootScope', 'MyService', '$http', '$timeout', '$q', function ($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q) {
    $scope.openFecCandidates = [];
    $scope.showLoader = false;
    $scope.currPage = 1;
    $scope.openCandidateDtls = false;
    $scope.candidiateDtls = [];
    $scope.candidiateCommittees = [];

    /*OpenFec Calls*/
    $scope.myPagingFunction = function () {
      //MyService.ConsoleLog("scoll called");
      if ($scope.statusGetResPg != 1) {
        $scope.currPage++;
        $scope.pageChanged($scope.currPage);
      }
    };
    $scope.pageChanged = function (newPage) {
      $scope.getopenFECCandidatesData(newPage);
    };

    $scope.selDbPresent = {
      "present": ""
    };
    // Get FEC Candidates Data

    $scope.openFecCandidates = [];

    $scope.getopenFECCandidatesData = function (pageNumber) {
      console.log(pageNumber);
      var params = {
        "page": pageNumber,
        max_records: 30
      };
      //MyService.ConsoleLog("params: ", params);
      // $scope.users.data = [];



      $scope.statusGetResPg = 1;

      var getAllCanceller = $q.defer();
      var calc = (pageNumber - 1) * 30;

      MyService.getopenFECCandidates(params, getAllCanceller).then(function (success) {
        // $scope.users.data = payload.Items;
        // console.log(success);
        $scope.openFecCandidates = $scope.openFecCandidates.concat(success.data.data);
        // console.log($scope.openFecCandidates);
        // $scope.totalUsers = payload.Count;
        // MyService.ConsoleLog(payload.Items);
        $scope.statusGetResPg = 2;
      }, function (err) {
        //MyService.ConsoleLog(err);
        growl.error("Something went wrong.");
        $scope.statusGetResPg = 3;
        $scope.page--;
      });


      // //MyService.ConsoleLog("In getopenFECCandidates");
      // MyService.getopenFECCandidates({
      //   "pageno": pageNumber
      // }).then(function (success) {
      //   MyService.ConsoleLog("getopenFECCandidates : ", success);
      //   $scope.openFecCandidates = success.data.data;
      //   MyService.ConsoleLog($scope.openFecCandidates);
      //   $scope.openFecTotal = $scope.openFecCandidates.length;
      //   //$scope.currPage = success.data.data.pagination.page;
      // }, function (err) {
      //   MyService.ConsoleLog("error : ", err);
      // });
    };
    $scope.showLoader = false;
    $scope.getCurrentFECCandidates = function (pageNumber) {
      //MyService.ConsoleLog("In getopenFECCandidates");
      $scope.showLoader = true;
      MyService.getCurrentFECCandidates({
        "pageno": pageNumber
      }).then(function (returndata) {
        MyService.ConsoleLog(returndata);
        if (returndata.data.success) {
          MyService.ConsoleLog(returndata.data.data);
          var totalpages = returndata.data.data.totalPages;
          var currentPage = returndata.data.data.currentPage;
          if (currentPage < totalpages) {
            alert("Records of " + currentPage + " page out of " + totalpages + " successfully added in database");
            var nextpage = parseInt(currentPage) + 1;
            alert("Lets get " + nextpage + " page records");
            $scope.getCurrentFECCandidates(nextpage);
          }
        }

      }, function (err) {
        $scope.showLoader = false;
        MyService.ConsoleLog("error : ", err);
      });
    };
    $scope.goBack = function () {
      $scope.openCandidateDtls = false;
      $scope.candidiateDtls = [];
      $scope.candidiateCommittees = [];
    }
    $scope.getCandidateDetails = function (id) {
      $scope.openCandidateDtls = true;
      MyService.getopenFECCandidateDetails({
        "cid": id
      }).then(function (success) {
        // MyService.ConsoleLog("getCandidateDetails : ", success);
        $scope.candidiateDtls = success.data.data;
        $scope.candidiateCommittees = $scope.candidiateDtls.committees;
        // console.log('********committee candy*************');
        // console.log('committee candy', $scope.candidiateCommittees);

        //MyService.ConsoleLog("candidiateDtls @@ :",$scope.candidiateDtls);



      }, function (err) {
        MyService.ConsoleLog("error : ", err);
      });

      MyService.getopenFECCandidateCommittees({
        "cid": id
      }).then(function (success1) {
        // MyService.ConsoleLog("getopenFECCandidateCommittees ");
        $scope.candidiateCommittees = success1.data.data;
        // MyService.ConsoleLog("getopenFECCandidateCommittees ** : ", $scope.candidiateCommittees);
      }, function (err1) {
        MyService.ConsoleLog("error : ", err1);
      });
    }

    $scope.getElectionData = function () {
      MyService.ConsoleLog("In getopenFECCandidates");
      $scope.showLoader = true;
      MyService.getElectionsDate({}).then(function (success) {
        $scope.showLoader = false;
        alert("data added successfully");

      }, function (err) {
        $scope.showLoader = false;
        MyService.ConsoleLog("error : ", err);
      });
    };
    if ($rootScope.showOpenFec) {
      $scope.getopenFECCandidatesData($scope.currPage);
    }


  }])