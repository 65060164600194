"use strict";
angular
  .module("myApp.generatePostCards", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/generatePostCards", {
        templateUrl: "app/generatePostCards/generatePostCard.html",
        controller: "generatePostCardsCtrl",
      });
    },
  ])
  .controller("generatePostCardsCtrl", [
    "$scope",
    "$location",
    "$rootScope",
    "tokenService",
    "MyService",
    "ngDialog",
    "growl",
    "$filter",
    "$q",
    "$window",
    "baseApiUrl",
    function (
      $scope,
      $location,
      $rootScope,
      tokenService,
      MyService,
      ngDialog,
      growl,
      $filter,
      $q,
      $window,
      baseApiUrl
    ) {
      $scope.allState = [];
      $scope.params = {
        state: "",
        electionDate: "",
      };
      $scope.imgUrl = baseApiUrl + "/resources";
      /**
       * @description Get the list of the States
       */

      $scope.getAllStates = function () {
        $scope.allState.length = 0;
        $scope.loader = true;
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            $scope.allState = payload;
            $scope.loader = false;
            $scope.getElectionDates();
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.loader = false;
          }
        );
      };

      /**
       * @description Get the list of the elections dates
       */
      $scope.getElectionDates = function () {
        MyService.getListOfDates().then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            if (APIResponse.data.success) {
              var datesArr = [];
              var mainArr = APIResponse.data.data.map(function (item, index) {
                if (item && item != null) {
                  var date = new Date(item).toGMTString();

                  var par = {
                    _id: index,
                    eDate: date.substr(5, 12),
                    date: item,
                  };
                  datesArr.push(par);
                }
              });
              MyService.ConsoleLog("datesArr >> ", datesArr[0].eDate);
              $scope.electionDates = datesArr;

              $scope.electionDates.sort(function (a, b) {
                if (a != null && b != null) {
                  return new Date(b.date) - new Date(a.date);
                }
              });
              // $scope.quiz.eDate = $scope.electionDates[0];

              MyService.ConsoleLog("$scope.quiz >> ", $scope.quiz);
              MyService.ConsoleLog(
                "$scope.electionDates >> ",
                $scope.electionDates
              );
            }
          },
          function (err) {
            MyService.ConsoleLog("error", err);
          }
        );
      };

      $scope.generatePostCard = function () {
        console.log("$scope.params ", $scope.params);

        if (
          $scope.params &&
          $scope.params.state &&
          $scope.params.electionDate
        ) {
          MyService.generatePostCard({
            state: $scope.params.state.state,
            electionDate: $scope.params.electionDate.date,
            statePostalCode: $scope.params.state.statePostalCode,
          }).then(function (APIReponse) {
            MyService.ConsoleLog("APIResponse >> ", APIReponse);
            $scope.showExportLink = true;
            $window.open($scope.imgUrl + "/postCards.xlsx", "_blank");
          });
        } else {
          growl.error(
            "Please select state and election date to generate the postcards"
          );
        }
      };

      $scope.getAllStates();
    },
  ]);
