'use strict';
angular
  .module('myApp.viewBills', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/viewBills', {
        templateUrl: 'app/viewBills/viewBills.html',
        controller: 'viewBillsCtrl'
      });
    }
  ])
  .controller('viewBillsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    '$timeout',
    '$q',
    'tokenService',
    'growl',

    function ($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q, tokenService, growl) {

      $scope.userData = tokenService.getUserData();
      if ($scope.userData && $scope.userData != "") {
        MyService.ConsoleLog("$scope.UserData >", JSON.parse($scope.userData));
        MyService.ConsoleLog("$scope.UserData >", JSON.parse($scope.userData));
        MyService.ConsoleLog("$scope.UserData >");
      }

      $scope.currPage = 1;
      $scope.showDetail = false;
      $scope.more = [];
      $scope.documents = [];
      $scope.activeDoc = true;
      $scope.activeAct = true;

      $scope.activeVot = true;
      $scope.activeSpo = true;

      $scope.date = {
        "value": ""
      }

      $scope.myPagingFunction = function () {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.searchData > $scope.openBills.length) {
          $scope.currPage++;
          $scope.pageChanged($scope.currPage);
        }
      };

      $scope.pageChanged = function (newPage) {
        $scope.getBills(newPage);
      };
      $scope.openBills = [];
      $scope.searchCandy = {
        'search': "",
        'state': []
      };

      $scope.searchMe = function (user) {
        // MyService.ConsoleLog('in searchMe', user);
        var query = $scope.searchCandy.search.toLowerCase(),
          fullname = user.title.toLowerCase();

        if (fullname.indexOf(query) != -1) {
          return true;
        }
        return false;
      };

      $scope.searchData = 0;
      /*
      $scope.SearchByTitle = function() {
        // MyService.ConsoleLog('in searchMe', $scope.search.sdata);
        // var query = $scope.search.sdata.toUpperCase();
        if ($scope.userData && $scope.userData != "") {
          var state = JSON.parse($scope.userData);
          MyService.ConsoleLog("state >", state.address.state);
        }

        var pageNumber = 1;
        // MyService.ConsoleLog("apraaa >>",)
        // var states = [];
        var states = Object.keys($scope.searchCandy.state);
        // states.push(state.address.state);
        MyService.ConsoleLog("state > ", states);

        $scope.params = {
          page: pageNumber,
          max_records: 30,
          title: $scope.searchCandy.search,
          "state": states
        };

        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];

        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();


        MyService.ConsoleLog('in searchMe', $scope.currPage);

        MyService.SearchByTitle($scope.params, getAllCanceller).then(
          function(response) {
            MyService.ConsoleLog('response >>>>>>>>>', response);

            $scope.openBills.length = 0;
            $scope.openBills = response.data;
            $scope.searchData = response.count;

            MyService.ConsoleLog('response', $scope.openBills);

          },
          function(error) {
            MyService.ConsoleLog('Error>>', error);
            growl.error("Data not found for this search");
          }
        );
      };
*/
      // $scope.sortByDate=function(){
      //   if()

      // }


      $scope.getBills = function (pageNumber, obj, canceller) {
        MyService.ConsoleLog('pageNumber >', pageNumber);
        // $scope.states = "";
        // MyService.ConsoleLog("val >",val);
        $scope.openBills.length = 0;
        // $rootScope.para = "";
        if ($scope.userData) {
          var state = JSON.parse($scope.userData);

        }
        // MyService.ConsoleLog("state >", state.address.state);
        // $scope.state = "";

        $scope.states = Object.keys($scope.searchCandy.state);
        if ($scope.states.length == 0) {
          // alert("welcome");
          if ($scope.userData) {
            $scope.states.push(state.address.state);
          }
        }
        // if ($scope.userData) {
        //
        // }
        MyService.ConsoleLog("state > ", $scope.states);


        $scope.params = {
          page: pageNumber,
          max_records: 30,
          title: $scope.searchCandy.search,
          state: $scope.states
        };
        MyService.ConsoleLog("params: ", $scope.params);
        // $scope.users.data = [];
        $scope.totalUsers = 0;

        $scope.statusGetResPg = 1;

        $scope.getAllCanceller = $q.defer();
        var calc = (pageNumber - 1) * 30;
        MyService.ConsoleLog("Object >>", obj);
        if (pageNumber != "") {
          MyService.SearchByTitle($scope.params, $scope.getAllCanceller).then(
            function (result) {
              console.log("RESPONSE >  ", result);
              // $scope.states.length= 0;
              // $scope.TotalBills = result.data.data[0].totalCount;
              $scope.openBills = $scope.openBills.concat(result.data);
              $scope.searchData = result.count;
              MyService.ConsoleLog('$scope.open >', $scope.searchData);
              // console.log($scope.committee);
              // $scope.totalUsers = payload.Count;
              // MyService.ConsoleLog(payload.Items);
              $scope.statusGetResPg = 2;
            },
            function (err) {
              // MyService.ConsoleLog("ERORR", err);
              // MyService.ConsoleLog("ERORR", err);
              growl.error(err);
              $scope.statusGetResPg = 3;
              $scope.page--;
            }
          );
        } else {
          ////alert("else");
          MyService.SearchByTitle($rootScope.para, $scope.getAllCanceller).then(
            function (result) {
              console.log("RESPONSE >  ", result);
              // $scope.TotalBills = result.data.data[0].totalCount;
              $scope.openBills = $scope.openBills.concat(result.data);
              $scope.searchData = result.count;
              MyService.ConsoleLog('$scope.open >', $scope.searchData);
              // console.log($scope.committee);
              // $scope.totalUsers = payload.Count;
              // MyService.ConsoleLog(payload.Items);
              $scope.statusGetResPg = 2;
            },
            function (err) {
              growl.error('Something went wrong.');
              $scope.statusGetResPg = 3;
              $scope.page--;
            }
          );
        }

      };

      $scope.flag = window.localStorage.getItem('flag');

      MyService.ConsoleLog("Fla one ", $scope.flag);
      if ($scope.userData && $scope.flag == "false" || $scope.flag == "") {
        //////alert('if');
        $scope.getBills($scope.currPage);

      }

      $scope.getDetails = function (val) {
        MyService.ConsoleLog('params >', val);
        MyService.getBillDetail({
          id: val
        }).then(
          function (response) {
            MyService.ConsoleLog('got data >', response);
            $scope.details = response.data.data;
            $scope.showDetail = true;
          },
          function (err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };
      $scope.back = function () {
        $scope.showDetail = false;
      };

      $scope.getDetailsOfCandidates = function (val) {
        $scope.more[val] = true;
        $scope.documents[val] = true;


      };
      $scope.showLess = function (valOne) {
        $scope.more[valOne] = false;
        $scope.documents[valOne] = false;

      }

      /*
       *@ Function to get all states
       */


      $scope.getAllStates = function () {
        // $scope.allState.length = 0;
        // $scope.loader = true;
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            console.log("payload : ", payload);
            $scope.allState = payload;
            // $scope.loader = false;
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.loader = false;
          }
        );
      };

      $scope.getAllStates();


      $scope.flag = window.localStorage.getItem('flag');
      MyService.ConsoleLog("flag >>", $scope.flag);
      if ($scope.flag == "true") {
        // //////alert("wel");
        $scope.getBills("", $rootScope.para, $scope.getAllCanceller);
        window.localStorage.setItem('flag', false);

      } else {
        window.localStorage.setItem('flag', false);

      }
      MyService.ConsoleLog("$rootScope >>", $rootScope.para);

      $scope.getDetailPage = function (id) {
        MyService.ConsoleLog("$rootScope.para ", $rootScope.para);

        if ($rootScope.para != undefined) {
          $rootScope.para = $rootScope.para;

          //alert("if****");
        } else {
          $rootScope.para = $scope.params;


          //alert("else ****");
        }
        MyService.ConsoleLog("Root >", $rootScope.para);

        window.location.href = "#!/viewBillDetails/" + id;
      }
    }
  ]);
