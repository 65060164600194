'use strict';

angular
  .module('myApp.pressProfile', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/pressProfile/:pressId/:type?/:match?', {
        templateUrl: 'app/pressProfile/pressProfile.html',
        controller: 'pressProfileCtrl',
      });
    },
  ])

  .controller('pressProfileCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    'baseApiUrl',
    '$q',
    '$timeout',
    'growl',
    'tokenService',
    '$anchorScroll',
    '$sce',
    'MyService',
    'ngDialog',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      baseApiUrl,
      $q,
      $timeout,
      growl,
      tokenService,
      $anchorScroll,
      $sce,
      MyService,
      ngDialog
    ) {
      $rootScope.welcome = false;
      $rootScope.quizzesData = '';
      $rootScope.followersData = '';
      $scope.pressId = $routeParams.pressId;
      var tempArr = [];

      window.localStorage.removeItem('isShowSpecialMatch');
      MyService.ConsoleLog("$rootScope.selectedCompareUsers", $rootScope.selectedCompareUsers);
      // $scope.match = $routeParams.match;
      $scope.showUnFollow = false;
      // $scope.loginUser = tokenService.getUserId();
      $scope.dataType = $routeParams.type;
      MyService.ConsoleLog('$scope.cat_title', $rootScope.cat_title);
      $scope.userType = tokenService.getUserRole();
      if (tokenService.getUserData()) {
        $scope.uData = JSON.parse(tokenService.getUserData());
      }
      window.localStorage.removeItem('isShowPreviousResult');

      window.localStorage.setItem('isShowPreviousResult', true);

      // window.localStorage.removeItem('followData');
      $scope.froumthreads = [];
      $scope.press = {};
      $scope.Sticky = {
        ShowOrder: [],
        txtOrder: [],
      };
      $scope.vote = {
        recommend: 0,
        factual: 0,
      };
      //$scope.GalleryFolder = baseApiUrl + "/resources/" + $scope.uData.uname;
      if (tokenService.getUserData()) {
        $scope.UserData = JSON.parse(tokenService.getUserData());
        MyService.ConsoleLog('$scope.UserData >', $scope.UserData);
      }
      $scope.displayComments = true;
      $scope.Reply = {};

      $scope.checkMemId = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.userData._id == $rootScope.UserId) return rdata;
        });
      };

      $scope.CheckUrl = function (url, option) {
        if (url) {
          if (url.indexOf('youtube.com') >= 0) {
            var url = url.replace('watch?v=', 'embed/');
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe width="560" height="315" src="' +
                url +
                '" frameborder="0" allowfullscreen></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
              );
          }
          if (url.indexOf('facebook.com') >= 0) {
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                url +
                '" width="560" height="315" frameborder="0"></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
              );
          }
          if (url.indexOf('vimeo.com') >= 0) {
            if (url.indexOf('https://vimeo.com/') >= 0) {
              var id = url.replace('https://vimeo.com/', '');
              var newurl = 'https://player.vimeo.com/video/' + id;
            } else {
              var newurl = url;
            }
            if (option == 'V')
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                newurl +
                '" width="500" height="281" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>'
              );
            else
              return $sce.trustAsHtml(
                '<br/><iframe src="' +
                newurl +
                '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>'
              );
          }
          if (option == 'V')
            return $sce.trustAsHtml(
              '<br/><iframe width="560" height="315" src="' +
              url +
              '" frameborder="0" allowfullscreen></iframe><br/>'
            );
          else
            return $sce.trustAsHtml(
              '<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>'
            );
        }
      };

      /**
       * get the average of rating for all type of users
       */

      $scope.calculateAvg = function (arr) {
        var reclen = arr.length;
        var recsum = 0;
        for (var r = 0; r < reclen; r++) {
          recsum += parseInt(arr[r].vote, 10);
          if (r == reclen - 1) {
            if (recsum > 0) {
              var recavg = Math.round(recsum / reclen);
            } else {
              var recavg = 0;
            }
            return recavg;
          }
        }
      };

      $scope.chkIfVoted = function (rdata, val) {
        return rdata.filter(function (rdata) {
          // if (!tokenService.getUserId()) {
          //   return rdata;
          // } else {
          //   if (rdata.user.id == val) return rdata;
          // }
          return rdata;
        });
      };
      $scope.loginUser = tokenService.getUserId();
      $scope.getPressDetails = function () {
        //MyService.ConsoleLog("Get One called");
        MyService.getPress($routeParams.pressId).then(
          function (payload) {
            MyService.ConsoleLog('payload', payload);

            if ($scope.UserData && $scope.UserData.followlist.length > 0) {
              var fLen = $scope.UserData.followlist.length;
              for (var f = 0; f < fLen; f++) {
                // MyService.ConsoleLog("$scope.UserData.followlist[f]", $scope.UserData.followlist[f]);
                if ($scope.UserData.followlist[f] == $routeParams.pressId) {
                  $scope.showUnFollow = true;
                  // growl.success("showUnfollow");
                  // alert("showunfollow");
                }
                /*else {
              // MyService.ConsoleLog("userProfile", $scope.userProfile);
              if ($scope.UserData.followlist[f] == $scope.userProfile.officeId) {
                $scope.showUnFollow = true;

              }
            }*/
              }
            }
            if (payload && payload.userData) {
              $scope.pressUserId = payload.userData._id;

              tempArr.push({
                _id: $scope.pressUserId
              });
              $rootScope.selectedCompareUsers = tempArr;
              MyService.getUserPublicProfile({
                id: $scope.pressUserId,
                type: false,
              }).then(function (response) {
                MyService.ConsoleLog('vote', response.data.data);
                $scope.votewiseActivity = response.data.data;
              });
            }

            if (payload && payload.orgdata) {
              $scope.pressUser = payload.userData;
              $scope.press = payload.orgdata;
              tempArr.push({
                _id: $scope.press._id
              });
              $rootScope.selectedCompareUsers = tempArr;
              if (payload.orgdata.recommended) {
                // alert('inside');
                var chkrecommend = $scope.chkIfVoted(payload.orgdata.recommended, $scope.loginUser);
                MyService.ConsoleLog('chkrecommend', chkrecommend);
                // if (chkrecommend && chkrecommend[0]) {
                $scope.vote.recommend = $scope.calculateAvg(chkrecommend);
                // }

              }
              if (payload.orgdata.factual) {
                var chkfactual = $scope.chkIfVoted(payload.orgdata.factual, $scope.loginUser);

                // if (chkfactual && chkfactual[0]) {
                $scope.vote.factual = $scope.calculateAvg(chkfactual);
                // }
              }
            } else {
              $scope.press = payload;
              $scope.pressUser = payload;
              MyService.ConsoleLog(' $scope.press ', $scope.press);
              if (payload.recommended) {
                var chkrecommend = $scope.chkIfVoted(payload.recommended, $scope.loginUser);

                if (chkrecommend && chkrecommend[0]) {
                  $scope.vote.recommend = chkrecommend[0].vote;
                }
              }
              if (payload.factual) {
                var chkfactual = $scope.chkIfVoted(payload.factual, $scope.loginUser);

                if (chkfactual && chkfactual[0]) {
                  $scope.vote.factual = chkfactual[0].vote;
                }
              }
            }
            MyService.getHighlighatedPost({
              pId: $scope.pressId,
            }).then(
              function (response) {
                $scope.getFollowersList();
                MyService.ConsoleLog('response', response);
                $scope.HighlightedPost = response.data;
              },
              function (err) {
                MyService.ConsoleLog('Error', err);
              }
            );
          },
          function (errorPayload) {
            //MyService.ConsoleLog(errorPayload);
            alert('Something went wrong ');
          }
        );
      };

      /**
       * Redirect to discussion page
       */
      $scope.redirectToReviews = function () {
        // growl.success("redirect to discussion");
        $location.path('/orgRating/' + $scope.pressId);
      };

      /**
       * get  followers count and get quizzes count
       */
      $scope.getFollowersList = function () {
        MyService.getFollowersList({
          orgId: $scope.pressId
        }).then(
          function (response) {
            MyService.ConsoleLog('response', response.data);
            $scope.followersCnt = response.data.followersCount;
            $rootScope.followersData = response.data.followersData;
            // window.localStorage.setItem('followData', JSON.stringify(response.data.followersData));
            $scope.questionsCnt = response.data.quizzesCount;
            $rootScope.quizzesData = response.data.quizzesData;
            $scope.quizQuesHasAns = response.data.quesHasAnsCount;
            $scope.getMatch();
          },
          function (err) {
            MyService.ConsoleLog('error', err);
          }
        );
      };


      /**
       * Get Match of questions
       */

      $scope.getMatch = function () {

        MyService.getMatchForPress({
          pressId: $scope.pressId
        }).then(function (result) {
          MyService.ConsoleLog("Result", result);
          if (result && result.length > 0) {
            $scope.match = result[0].match;

          }
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      /**
       * Follow orgnization
       */
      $scope.addtoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.pressId;
        MyService.updateUserFollowList(data).then(
          function (payload) {

            $scope.getPressDetails();
            if ($scope.UserData) {
              $scope.showUnFollow = true;
              $scope.UserData.followlist.push($routeParams.pressId);
              MyService.ConsoleLog('udata : ', $scope.UserData);
              tokenService.saveUserData(JSON.stringify($scope.UserData));
            }
          },
          function (errorPayload) {
            alert('Something went wrong ');
          }
        );
      };

      $scope.getMatchSpecific = function () {

        window.localStorage.setItem('isShowSpecialMatch', true);
        $location.path('/ballotMatch/' + $scope.pressUser._id);
      };

      /**Unfollow organization*/

      $scope.removetoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.pressId;
        MyService.removeUserFollowList(data).then(
          function (result) {
            MyService.ConsoleLog('result', result);

            $scope.showUnFollow = false;
            $scope.getPressDetails();

            var ids = $scope.UserData.followlist.indexOf($routeParams.pressId);
            MyService.ConsoleLog('ids', ids);
            if (ids > -1) {
              $scope.UserData.followlist.splice(ids, 1);
            }

            // else {
            //   var idx = $scope.UserData.followlist.indexOf($scope.userProfile.officeId);
            //   if (idx > -1) {
            //     $scope.UserData.followlist.splice(idx, 1);
            //   }
            // }
            //MyService.ConsoleLog("udata : ", $scope.UserData);
            tokenService.saveUserData(JSON.stringify($scope.UserData));
          },
          function (err) {
            MyService.ConsoleLog('error', err);
          }
        );
      };

      function getCategory() {
        MyService.getCategoryList($routeParams.categoryId).then(
          function (payload) {
            $scope.category = payload;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          }
        );
      }

      // $scope.gotoThread = function (data) {
      //   //MyService.ConsoleLog(data);
      //   var id = '';
      //   if (data.parentId) {
      //     id = data.parentId;
      //   } else {
      //     id = data.id;
      //   }
      //   $location.path('/forumthread/' + id);
      // };
      $scope.gotoThread = function (data, subcatid) {
        var id = '';
        if (data.parentId) {
          id = data.parentId;
        } else if (data.parent) {
          id = data.parent;
        } else {
          if (data.id) {
            id = data.id;
          } else {
            id = data._id;
          }
        }
        // MyService.ConsoleLog(data);
        var categoryId = "";
        if (data.parentCat[0] && data.parentCat[0] != "") {
          categoryId = data.parentCat[0];
        } else {
          if (data.parentCat[1] && data.parentCat[1] != "") {
            categoryId = data.parentCat[1];
          }
        }

        // $rootScope.subcategoryId = subcatid;
        //MyService.ConsoleLog('forumthread/' + categoryId + '/' + subcatid + '/' + id);


        if (categoryId != "") {
          $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + id);
        } else {
          MyService.ConsoleLog("ELSE");
          alert("Something went wrong");
        }


      }

      $scope.updateCommentApi = function (data, options, idex) {
        MyService.updatePressComments(data).then(
          function (payload) {
            if (payload.nModified == 0) {
              alert('You already provided your inputs on this post');
            } else {
              switch (options) {
                case 'like':
                  $scope.essayComments[idex].totalLikes = $scope.essayComments[idex].totalLikes + 1;
                  break;
                case 'dislike':
                  $scope.essayComments[idex].totalDislike =
                    $scope.essayComments[idex].totalDislike + 1;
                  break;
                default:
                  $scope.essayComments[idex].totalSpam = $scope.essayComments[idex].totalSpam + 1;
                  break;
              }
            }
          },
          function (errorPayload) {
            alert('Something went wrong ');
          }
        );
      };

      $scope.updateComments = function (options, id, idex) {
        if ($rootScope.UserId) {
          if ($scope.essayComments[idex].createdBy.id != $rootScope.UserId) {
            var commentData = {};
            commentData.commentId = id;
            commentData.userId = $rootScope.UserId;

            switch (options) {
              case 'like':
                commentData.likes = 1;
                break;
              case 'dislike':
                commentData.dislikes = 1;
                break;
              default:
                commentData.spam = 1;
                break;
            }

            $scope.updateCommentApi(commentData, options, idex);
          } else {
            alert('You cannot ' + options + ' your own comments');
          }
        } else {
          alert('Please login to perform these actions');
        }
      };
      $scope.submitSticky = function (data, id, idx) {
        if (data > 0) {
          updateForum('sticky', id, idx);
        } else {
          alert('Please enter valid order');
        }
      };

      $scope.ShowComments = false;

      $scope.redirectToComment = function () {
        var token = apiCall.getToken();
        if (!token) {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
              $location.path('signin');
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
              $location.path('signin');
            }
          );
        }
        $scope.ShowComments = true;
        $scope.displayComments = false;

        //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
      };

      $scope.goBack = function () {
        $scope.ShowComments = false;
        $scope.displayComments = true;
      };

      $scope.getComments = function () {
        MyService.getPressComments($routeParams.pressId).then(
          function (payload) {
            $scope.essayComments = payload;
          },
          function (errorPayload) {
            alert('Something went wrong ');
          }
        );
      };

      $scope.SubmitComment = function (rec) {
        //MyService.ConsoleLog(data);
        //MyService.ConsoleLog($scope.mainThread);
        var data = {};
        if (rec.content != '') {
          $scope.TopicButton = true;
          data.createdById = $rootScope.UserId;
          data.createdByName = $rootScope.User;
          data.createdByType = tokenService.getUserRole();
          data.comments = rec.content;
          data.pressId = $routeParams.pressId;
          //MyService.ConsoleLog(data);
          MyService.submitPressComments(data).then(
            function (payload) {
              $scope.comments = {
                content: '',
              };
              $scope.ShowComments = false;
              $scope.displayComments = true;
              $scope.mainThread = {};
              //$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
              $scope.getComments();
            },
            function (errorPayload) {
              alert('Something went wrong ');
            }
          );
        } else {
          alert('Please enter comments.');
        }
      };

      $scope.PressVote = function (option) {
        var token = apiCall.getToken();
        if (!token) {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
              $location.path('signin');
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
              $location.path('signin');
            }
          );
        } else {
          var data = {};
          data._id = $routeParams.pressId;
          data.mediaType = '';
          data.option = option;
          //MyService.ConsoleLog(option);
          if (option == 'Recommended') {
            data.vote = $scope.vote.recommend;
          } else {
            data.vote = $scope.vote.factual;
          }
          data.createdById = $scope.loginUser;
          data.createdByName = tokenService.getUserName();
          data.createdByType = tokenService.getUserRole();
          MyService.updatePress(data).then(
            function (payload) {
              alert('Thank you for rating the press.');
            },
            function (errorPayload) {
              alert('Something went wrong ');
            }
          );
        }
      };

      $scope.getVotesmartPressDetails = function () {
        console.log('getVotesmartPressDetails');
        MyService.getVotesmartPressDetails({
          sigid: $scope.pressId,
        }).then(
          function (payload) {
            console.log('payload1 > ', payload);
            if (payload.data.success) {
              $scope.press = payload.data.data;
            } else {
              alert('Something went wrong ');
            }
          },
          function (epayload) {
            alert('Something went wrong ');
          }
        );
      };

      /**
       * @description Show pop up to select which thread to navigate
       */
      $scope.ShowLoader = false;
      $scope.LatestEssay = [];
      $scope.LatestPost = [];
      $scope.LatestThread = [];
      $scope.gotoPage = function (obj, data) {
        //MyService.ConsoleLog(data);
        switch (obj) {
          case "Essay":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: 'LatestEssayId',
              closeByNavigation: true,
              scope: $scope
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              "userId": userid
            }).then(function (payload) {
              $scope.ShowLoader = false;
              MyService.ConsoleLog("essay", payload);
              $scope.LatestEssay = payload;
            }, function (errorPayload) {
              $scope.ShowLoader = false;
              MyService.ConsoleLog("err vosjs", errorPayload);
              alert("Something went wrong.");
            });
            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "EssayComment":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: 'LatestEssayCommentId',
              closeByNavigation: true,
              scope: $scope
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              "userId": userid,
              isEssayComment: true
            }).then(function (payload) {
              if (payload && payload.length > 0) {
                var essayIds = payload.map(function (itms) {
                  if (itms && itms.essayId) {
                    return itms.essayId;
                  }
                });

                MyService.getAllEssaysList({
                  eIds: JSON.stringify(essayIds)
                }).then(function (APIResponse) {
                  MyService.ConsoleLog("APIREs", APIResponse);
                  if (APIResponse.success) {
                    $scope.ShowLoader = false;
                    MyService.ConsoleLog("essay commnent", payload);
                    $scope.LatestEssayComments = APIResponse.data;
                  }
                }, function (err) {
                  MyService.ConsoleLog("Error", err);
                });
              }

            }, function (errorPayload) {
              $scope.ShowLoader = false;
              MyService.ConsoleLog("err vosjs", errorPayload);
              alert("Something went wrong.");
            });


            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "Post":
            var userid = data._id;
            // var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //var subcatid = data.subcategories[0].pid;
            //var id = data.parent;
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            $scope.dialogClose = ngDialog.open({
              template: 'LatestPostId',
              closeByNavigation: true,
              scope: $scope
            });
            $scope.ShowLoader = true;
            MyService.getLatestPost({
              "userId": userid
            }).then(function (payload) {
              $scope.ShowLoader = false;
              //MyService.ConsoleLog(payload);
              $scope.LatestPost = payload;
            }, function (errorPayload) {
              $scope.ShowLoader = false;
              MyService.ConsoleLog("errorPayload errorPayload", errorPayload);
              alert("Something went wrong.");
            });
            break;
          case "Thread":
            //var catId = data.parentcat[0];
            //var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //     var subcatid = data.subcategories[0].pid;
            //var subcatid = data.subcategories[0].pid;
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: 'LatestThreadId',
              closeByNavigation: true,
              scope: $scope
            });
            $scope.ShowLoader = true;
            MyService.getLatestThread({
              "userId": userid
            }).then(function (payload) {
              $scope.ShowLoader = false;
              //MyService.ConsoleLog(payload);
              $scope.LatestThread = payload;
            }, function (errorPayload) {
              $scope.ShowLoader = false;
              alert('error');
              alert("Something went wrong.");
            });
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            break;
          case "topic of interest":
            MyService.ConsoleLog("data", data);
            MyService.ConsoleLog("data", typeof data);

            MyService.getMultiCategoryByIds({
              ids: data
            }).then(function (APIResponse) {
              MyService.ConsoleLog("APIResponse Its", APIResponse);
              if (APIResponse.data.success) {
                $scope.TopicsOfInterests = APIResponse.data.data;
                $scope.dialogClose = ngDialog.open({
                  template: 'TopicsOfInterest',
                  closeByNavigation: true,
                  scope: $scope
                });
              }
            }, function (err) {
              MyService.ConsoleLog("Errror", err);
            });
            break;
          case 'location of interest':
            $scope.locationOfInterest = data;
            $scope.dialogClose = ngDialog.open({
              template: 'LocationOfInterest',
              closeByNavigation: true,
              scope: $scope
            });
            break;
          default:
            break;
        }

      };

      $scope.gotoComments = function (eId, catId) {
        MyService.ConsoleLog("eid", eId);
        MyService.ConsoleLog("catId", catId);

        // MyService.getEssay(eId).then(function (APIResponse) {
        //   MyService.ConsoleLog("APIResponse", APIResponse);
        if (eId && catId) {
          $location.path('/essayDetails/' + catId + "/" + eId);
        }
      };

      $scope.gotoEssay = function (id, catId) {
        MyService.ConsoleLog("catId", catId);
        $location.path('essayDetails/' + catId + '/' + id);
      }

      $scope.gotoFThread = function (id, catId, subcatid) {
        $location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
      }

      console.log('new', $scope.dataType);
      if ($scope.dataType && $scope.dataType == 'false') {
        $scope.getVotesmartPressDetails();
      } else {
        if ($routeParams.pressId) {
          $scope.getPressDetails();
          $scope.getComments();
        }
      }
    },
  ]);
