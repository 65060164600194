'use strict';
angular.module('myApp.totalUsers', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/totalUsers', {
      templateUrl: 'app/totalUsers/totalUsers.html',
      controller: 'totalUsersCtrl'
    });
  }]).controller('totalUsersCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window) {

    $scope.showTotal = false;
    $scope.voter = false;
    $scope.politician = false;
    $scope.advocate = false;
    $scope.press = false;
    $scope.more = [];
    $scope.less = [];
    $scope.loaderOne = false;
    $scope.showLoader = false;
    $scope.newLoader = false;
    $scope.dis = [];
    $scope.disLo = [];
    $scope.disPoli = [];
    $scope.morePoli = [];
    $scope.loaderPolitician = false;

    $scope.cities = false;
    $scope.disCity = [];
    $scope.guestIds = [];
    $scope.getCount = function() {

      MyService.getUsersCount().then(function(result) {
        $scope.count = result.data.data;
        MyService.ConsoleLog("users count: ", $scope.count);
        $scope.showTotal = true;
        $scope.voter = false;
        $scope.politician = false;
        $scope.advocate = false;
        $scope.press = false;
        $scope.guestIds = $scope.count.guestIds;

        MyService.ConsoleLog("$scope.GuestIs >>", $scope.guestIds);

      });
    };


    $scope.getCount();

    $rootScope.UserType = "";
    $rootScope.state = "";


    /**
     ** This function fetch guest users details
     **
     **/

    $scope.getGuestUsersAnswers = function() {

      var userAdd = tokenService.getUserId();
      MyService.ConsoleLog("userAdd >>", userAdd);


      MyService.getGuestUsersAnswers({ "ids": $scope.guestIds, "userId": userAdd }).then(function(payload) {

        MyService.ConsoleLog("Payload >>", payload.data.data);
        var mData = JSON.stringify(payload.data.data);

        window.localStorage.setItem("cData", mData);
        $location.path('/compareGuestWith');
      }, function(error) {

        MyService.ConsoleLog("error >>", error);

      });

    };



    /**
     ** This function gets statewise data, called on page load.  
     **
     **
     ***/

    $scope.getVoterStateWise = function(val) {
      MyService.ConsoleLog(" para :>", val);
      $rootScope.UserType = val;


      MyService.getUsersCountStateWise({ "type": val }).then(function(response) {
        MyService.ConsoleLog("response :?&&&&&&& >", response);
        $scope.stateWise = response.data.data;
        $scope.voter = true;
        $scope.showTotal = false;




      });
    };



    /**
     ** Functions get data of cities
     **
     **
     ***/

    $scope.getUsersCityWise = function(county, state, stateFp, statePostalCode, obj) {
      // alert(county);
      $scope.disCity[$rootScope.county] = false;
      $scope.cities = false;
      $rootScope.state = state;
      $rootScope.county = county;
      MyService.ConsoleLog("obj >>", obj);
      $scope.showLoader = true;
      $scope.disLo[county] = true;

      MyService.ConsoleLog("$rootScope.UserType ", $rootScope.UserType);
      MyService.getUsersCountCityWise({
        "userType": $rootScope.UserType,
        "stateFips": stateFp,
        "state": state,
        "statePostalCode": statePostalCode,
        "county": obj
      }).then(function(payload) {
        // if (payload && payload != "") {
        if (payload && payload == "") growl.error("Local Data Not Found");
        $scope.disLo[county] = false;
        $scope.showLoader = false;
        $scope.cities = true;
        $scope.disCity[county] = true;
        MyService.ConsoleLog("Payload :", payload);
        $scope.cityWise = payload;
        // } else {
        //   $scope.disLo[county] = false;
        //   $scope.showLoader = false;
        //   growl.error(" Local Data Not Found");
        // }
      });
    };




    $scope.showLess = function(valOne) {
      // console.log("id for less",id);
      $scope.more[valOne] = false;

      $scope.morePoli[valOne] = false;

    }


    $scope.back = function() {
      $scope.showTotal = true;
      $scope.voter = false;
      $scope.more[$rootScope.state] = false;

    };



    /**
     ** This function called when county data not found 
     **
     **
     ***/

    $scope.getCityWise = function(state, stateFp, statePostalCode) {
      $scope.newLoader = true;

      MyService.getCity({
        "userType": $rootScope.UserType,
        "stateFips": stateFp,
        "state": state,
        "statePostalCode": statePostalCode,

      }).then(function(response) {
        if (response && response == "") growl.error("Local Data Not Found");
        $scope.newLoader = false;

        MyService.ConsoleLog("response >>>", response);
        $scope.city = response;


      }, function(error) {
        MyService.ConsoleLog("error >>", error);
      });

    }



    /**
     ** This function get records of county using statepostal code and statefips 
     **
     **
     ***/

    $scope.getUsersCountyWise = function(state, stateFp, statePostalCode, obj) {
      // alert(state + " " + stateFp);
      $scope.more[$rootScope.state] = false;
      $rootScope.state = state;
      MyService.ConsoleLog("obj >>", obj);
      $scope.loaderOne = true;
      $scope.dis[state] = true;
      $scope.cities = false;
      $scope.city = "";

      MyService.ConsoleLog("$rootScope.UserType ", $rootScope.UserType);
      MyService.getUsersCountyWise({
        "userType": $rootScope.UserType,
        "stateFips": stateFp,
        "state": state,
        "statePostalCode": statePostalCode
      }).then(function(payload) {

        // if (payload && payload != "") {
        if (payload && payload == "") growl.error("County Data Not Found");
        $scope.loaderOne = false;
        $scope.dis[state] = false;

        MyService.ConsoleLog("Payload :", payload);
        $scope.countywise = payload;
        $scope.more[state] = true;
        // } else {
        //   $scope.loaderOne = false;
        //   $scope.dis[state] = false;

        //   growl.error("County Data Not Found");

        // }

      });
    };

    /**
     ** This functions fetch state level politicians details
     **
     **
     **/

    $scope.getPolitician = function(obj) {

      MyService.ConsoleLog("obj >>>", obj);
      $scope.disPoli[obj.state] = true;
      $scope.loaderPolitician = true;
      MyService.getStateLevelPoli(obj).then(function(response) {
        MyService.ConsoleLog("Response >>", response);
        $scope.stateLevelPoli = response;
        $scope.morePoli[obj.state] = true;
        $scope.loaderPolitician = false;

      }, function(error) {
        growl.error(error);
      })

    }
  }])
