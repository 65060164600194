'use strict';
angular.module('myApp.speakOut', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/speakOut', {
      templateUrl: 'app/speakOut/speakOut.html',
      controller: 'speakOutCtrl'
    });
  }])
  .controller('speakOutCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
