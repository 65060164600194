'use strict';


angular.module('myApp.showCompetitors', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showCompetitors', {
      templateUrl: 'app/showCompetitors/showCompetitors.html',
      controller: 'showCompetitorsCtrl'
    });
  }]).controller('showCompetitorsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce) {

    MyService.ConsoleLog("this is competitors ", JSON.parse(window.localStorage.getItem('competitorsParams')));
    $scope.showVotesmartAns = true;
    $scope.showVotewiseAns = true;
    window.localStorage.setItem('isShowPreviousResult', true)
    $scope.showCompetitors = function (params) {
      params.polId = $scope.selectedPoli._id;
      MyService.getListOfCompetitors(params).then(function (APIResponse) {
        MyService.ConsoleLog('APIResponse', APIResponse);
        if (APIResponse && APIResponse.data.success) {
          $scope.competitors = APIResponse.data.data.records;
          MyService.ConsoleLog('competitors', $scope.competitors);
        } else {
          growl.error('Competitors not found');
        }

      }, function (err) {
        MyService.ConsoleLog("err >> ", err);
      });
    }

    if (window.localStorage.getItem('competitorsParams')) {
      var smlData = JSON.parse(window.localStorage.getItem('competitorsParams'));
      $scope.selectedPoli = smlData.selectedPoli;
      $scope.showCompetitors(smlData.params);
    }



  }]);
