'use strict';

angular.module('myApp.compareGuestWith', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/compareGuestWith/:type?', {
      templateUrl: 'app/compareGuestWith/compareGuestWith.html',
      controller: 'compareGuestWithCtrl'
    });
  }]).controller('compareGuestWithCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', function($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService) {


    $scope.noDataFound = false;
    $scope.FBCompareList = [];


    var data = window.localStorage.getItem('cData');
    // MyService.ConsoleLog("Data >>", data);
    try {
      $scope.AllData = JSON.parse(data);
    } catch (error) {
      MyService.ConsoleLog("Error >>", error);

    }

    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData);
    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData.AllUsers);
    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData.AllUsers.length);
    // var Arr = [];
    var len = $scope.AllData.AllUsers.length;

    // if (Array.isArray($scope.AllData.AllUsers)) {
    //   for (var i = 0; i < len; i++) {
    //     var val = $scope.AllData.AllUsers[i];
    //     Arr.push(JSON.parse(val));
    //   }
    // } else {

    //   // var udata = JSON.parse($scope.AllData.AllUsers);
    //   // MyService.ConsoleLog("Udata >>>", udata);
    // }



    // MyService.ConsoleLog("Arr. >>", Arr);

    $scope.FBunWeighted = $scope.AllData.unweightedMatch;
    $scope.fbcombinedWeight = $scope.AllData.fbcombinedWeight;
    $scope.fbmultiplier = $scope.AllData.fbmultiplier;
    $scope.answeredQuestions = $scope.AllData.answeredQuestions;
    $scope.fbqlen = $scope.AllData.qlen;
    // if (Arr.length == 0) {
    //   $scope.noDataFound = false;

    //   $scope.AllUsers = $scope.AllData.AllUsers;
    //   MyService.ConsoleLog("$scope.AllUsers >>", $scope.AllUsers);
    // } else {

    var dups = [];
    var finalArr = $scope.AllData.AllUsers.filter(function(el) {
      // If it is not a duplicate, return true
      if (dups.indexOf(el.guestId) == -1) {
        dups.push(el.guestId);
        return true;
      }

      return false;

    });

    MyService.ConsoleLog("finalArr >", finalArr);

    $scope.AllUsers = finalArr;
    // }

    $scope.finalSignIn = function() {

      $location.path('/fiveSaveUser');
    }

  }]);
