'use strict';
angular.module('myApp.allTopics', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/allTopics', {
      templateUrl: 'app/allTopics/allTopics.html',
      controller: 'AllTopicsCtrl'
    });
  }]) .controller('AllTopicsCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', function ($scope, $location, $rootScope, tokenService, MyService, ngDialog) {
    $scope.topic = {
      data: []
    };
    $scope.totalUsers = 0;
    $scope.usersPerPage = 10; // this should match however many results your API puts on one page

    $scope.pagination = {
      current: 1
    };
    $scope.showLoading = false;

    $scope.page = 1;

    $scope.pageChanged = function (newPage) {
      $scope.getAllTopics(newPage);
    };

    $scope.getAllTopics = function (pageNumber) {
      // this is just an example, in reality this stuff should be in a service
      $scope.showLoading = true;
      var params = {
        "page": pageNumber,
        "max_records": $scope.usersPerPage
      };
      //MyService.ConsoleLog("params: ", params);
      MyService.getAllTopics(params).then(function (payload) {
        $scope.topic.data = $scope.topic.data.concat(payload.Items);
        $scope.totalUsers = payload.Count;
        //MyService.ConsoleLog(payload.Items);
        $scope.showLoading = false;
      }, function (err) {
        $scope.page--;
        //MyService.ConsoleLog(err);
        $scope.showLoading = false;
      });
    }

    $scope.loadMoreTopics = function () {
      if (!$scope.showLoading) {
        $scope.page++;
        $scope.getAllTopics($scope.page)
      }
    };

    $scope.removeTopic = function (mydata, idx) {
      if (!mydata.parent) {
        var message = 'This is a parent thread. Removing it will remove all the comments added to this thread. Do you want to proceed?';
      } else {
        var message = 'Are you sure you want to delete this Topic?';
      }

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeSpamTopic({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.getAllTopics(1);
          alert("Topic deleted successfully.");
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    if ($rootScope.showAllTopic) {
      $scope.getAllTopics(1);
    }
  }])