'use strict';
angular
  .module('myApp.federalTextBills', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/federalTextBills', {
        templateUrl: 'app/federalTextBills/federalTextBills.html',
        controller: 'federalTextBillsCtrl'
      });
    }
  ])
  .controller('federalTextBillsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    '$timeout',
    '$q',
    '$routeParams',
    '$window',
    "growl",

    function($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q, $routeParams, $window, growl) {

      $scope.loader = false;
      $scope.loaderOne = false;
      $scope.currPage = 1;


      // if (!$routeParams.flag) {

      //   $window.location.href = "/federalBills";

      // }
      $scope.selType = {
        "type": "",
        "congress": "116",
        "session":""
        
      }

      $scope.myPagingFunction = function() {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.serachData > $scope.federal.length) {
          $scope.currPage++;
          $scope.pageChanged($scope.currPage);
        }
      };

      $scope.pageChanged = function(newPage) {
        $scope.getFederalText(newPage);
      };
      $scope.federal = [];

      $scope.getFederalText = function(pageNumber) {
        MyService.ConsoleLog('pageNumber >', pageNumber);
        var params = {
          page: pageNumber,
          max_records: 30
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];


        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();


        MyService.getTextBills(params, getAllCanceller).then(
          function(result) {
            console.log("RESPONSE >  ", result);
            $scope.federal = $scope.federal.concat(result.data.data.data);
            MyService.ConsoleLog('$scope.open >', $scope.federal);
            $scope.serachData = result.data.data.count;
            // console.log($scope.committee);
            // $scope.totalUsers = payload.Count;
            // MyService.ConsoleLog(payload.Items);
            $scope.statusGetResPg = 2;
          },
          function(err) {
            // growl.error('Something went wrong.');
            $scope.statusGetResPg = 3;
            $scope.page--;
          }
        );
      };
      $scope.getFederalText();


      $scope.getfederalBills = function() {
        MyService.ConsoleLog("seleted >>", $scope.selType.type);
        MyService.ConsoleLog("seleted >>", $scope.selType.congress);
        MyService.ConsoleLog("seleted >>", $scope.selType.session);


        if ($scope.selType.type && $scope.selType.type != "" && $scope.selType.congress != "" && $scope.selType.session != "" ) {
          $scope.loader = true;

          MyService.addFederalBills({ "type": $scope.selType.type, "congress": $scope.selType.congress,"session":$scope.selType.session  }).then(function(result) {
            console.log("result", result);
            $scope.loader = false;

          }, function(error) {
            console.log("error >", error);
          })
        } else {
          growl.error("Please select value from select box");
        }
      };

      // $scope.getfederalBills = function() {
      //   $scope.loaderOne = true;

      //   MyService.addFederalBills().then(function(response) {
      //     console.log("response >>>", response);
      //     $scope.loaderOne = false;

      //   })

      // }




    }
  ]);
