'use strict';
angular.module('myApp.localDebate', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/localDebate', {
    templateUrl: 'app/localDebate/localDebate.html',
    controller: 'localDebateCtrl'
  });
}])

.controller('localDebateCtrl', ['$scope','$rootScope', '$http','$location','apiCall', function ($scope, $rootScope, $http, $location, apiCall) {
$rootScope.welcome = false;
}]);
