'use strict';


angular.module('myApp.trackPoliticians', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/trackPoliticians', {
      templateUrl: 'app/trackPoliticians/trackPoliticians.html',
      controller: 'trackPoliticiansCtrl'
    });
  }]).controller('trackPoliticiansCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce) {

    $scope.User = {
      from: "",
      to: "",
      state: "",
      officeType: "",
      isContri: "",
      isAnsVotewise: false,
      isAnsVotesmart: false
    };
    $scope.showStatement = true;
    $scope.showResume = true;
    $scope.showVotesmartAns = true;
    $scope.showVotewiseAns = true;
    $scope.showCompetitor = true;
    $scope.allOfficeType = ["inOffice", "runningForOffice"];
    $scope.isUserContributed = ["Yes", "No"];
    $scope.loader = false;
    $scope.currPage = 1;

    $scope.allUSAPol = {
      "statePostalCode": "All USA",
      "state": "All USA"
    };
    $scope.allState = [];

    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.allState.unshift($scope.allUSAPol);
          $scope.loader = false;
          // var
          // alert(window.localStorage.getItem('isShowPreviousResult'))
          if (window.localStorage.getItem('isShowPreviousResult') != 'false') {
            if (window.localStorage.getItem('searchPara')) {
              // MyService.ConsoleLog(" <<<< search paramas >>> ", window.localStorage.getItem('searchPara'));
              var params = JSON.parse(window.localStorage.getItem('searchPara'));
              // MyService.ConsoleLog("params >>> ", params);
              $scope.User = {
                from: params.from ? new Date(params.from) : "",
                to: params.to ? new Date(params.to) : "",
                state: params.state ? params.state : "",
                statePostalCode: params.statePostalCode ? params.statePostalCode : "",
                officeType: params.officeType ? params.officeType : "",
                isContri: params.isContri ? params.isContri : ""
              };

              if (params.isAnsVotesmart) {
                $scope.User.isAnsVotesmart = params.isAnsVotesmart;
              }

              if (params.isAnsVotewise) {
                $scope.User.isAnsVotewise = params.isAnsVotewise;
              }

              if (params.isLoggedin) {
                $scope.User.isLoggedin = params.isLoggedin;
              }

              if (params.addedStatement) {
                $scope.User.addedStatement = params.addedStatement;
              }

              $scope.statusGetResPg = 1;
              $scope.getAllCanceller = $q.defer();
              window.localStorage.setItem('isShowPreviousResult', false)
              $scope.selectStatus(params, $scope.getAllCanceller);
            }
          }
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };



    $scope.pageFunction = function () {
      MyService.ConsoleLog("$scope.countData", $scope.countData + "   > " + $scope.mainData.length);
      if ($scope.statusGetResPg != 1 && $scope.countData > $scope.mainData.length) {
        // MyService.ConsoleLog("length");
        $scope.currPage++;
        $scope.changedPage($scope.currPage);
      }
    };

    $scope.changedPage = function (newPage) {
      $scope.searchRecords(newPage);
    };
    // $scope.getRegisterUser();
    $scope.mainData = [];
    $scope.tmpArr = [];
    /**
     * Get last activities of user
     * @params user type
     * @params Date and time
     */
    $scope.selectStatus = function (params, canceller) {
      MyService.ConsoleLog(" selectStatus params >>> ", params);
      $scope.activeUsers = true;
      $scope.activeLastLogin = true;
      $scope.showAns = true;
      $scope.resgisterCount = "";
      $scope.loader = true;
      // MyService.ConsoleLog("Date", $scope.User.from.toISOString().split("T")[0]);
      // MyService.ConsoleLog("Type", $scope.User.type);
      // MyService.ConsoleLog("State", $scope.User.state);

      MyService.trackPoliticians(params, canceller).then(function (results) {
        MyService.ConsoleLog("results", results);
        if (results.data.data.records && results.data.data.records.length > 0) {
          // MyService.ConsoleLog('$scope.resgisterCount >', results.data.data.register);
          $scope.loader = false;
          $scope.tmpArr = $scope.tmpArr.concat(results.data.data.records);
          $scope.mainData = $scope.mainData.concat(results.data.data.records);
          // MyService.ConsoleLog('$scope.resgisterCount >', $scope.tmpArr);
          $scope.countData = results.data.data.totalRecords;
          $scope.statusGetResPg = 2;
          // $scope.lastLogin = results.data.data.lastLogin;
          $scope.questionAnswered = results.data.data.questionsAnswered;
          $scope.notLoggedIn = results.data.data.notLoggedIn;
          $scope.loader = false;

        } else {
          $scope.resgisterCount = "";
          $scope.notLoggedIn = "";
          $scope.statusGetResPg = 3;
          $scope.loader = false;

          // if ($scope.User.isAnsVotewise && $scope.User.isAnsVotewise == true) {

          //   var smp = parseInt($scope.searchObj.page);
          //   smp += 1;

          //   $scope.searchObj.page = smp;
          //   $scope.selectStatus($scope.searchObj, $scope.getAllCanceller = $q.defer());
          // } else {
          growl.error("Data Not Found");
          // }

        }

        // MyService.ConsoleLog(" $scope.lastLogin", $scope.lastLogin);
        // MyService.ConsoleLog(" $scope.resgisterCount", $scope.resgisterCount)
      }, function (err) {
        $scope.loader = false;
        MyService.ConsoleLog("Error", err);
      });

    };

    /**
     * @description Search active politicians
     */
    $scope.searchRecords = function (pageNumber, val) {
      // MyService.ConsoleLog(" check >> ", $scope.User);
      window.localStorage.removeItem('searchPara');
      if (val && val != '') {
        $scope.mainData = [];
        $scope.tmpArr = [];
      }
      if ($scope.User.from && $scope.User.from != "" && $scope.User.to && $scope.User.to != "" && $scope.User.officeType && $scope.User.officeType != "" || $scope.User.state && $scope.User.state != "") {
        var fromDate;
        if ($scope.User.from && $scope.User.from != "") {
          var month = ("0" + ($scope.User.from.getMonth() + 1)).slice(-2);
          var dat = ("0" + $scope.User.from.getDate()).slice(-2);
          fromDate = $scope.User.from.getFullYear() + "-" + month + "-" + dat;
          // MyService.ConsoleLog("fromDate >>", fromDate);
        }
        var toDate;
        if ($scope.User.to && $scope.User.to != "") {
          var smonth = ("0" + ($scope.User.to.getMonth() + 1)).slice(-2);
          var sdat = ("0" + $scope.User.to.getDate()).slice(-2);
          toDate = $scope.User.to.getFullYear() + "-" + smonth + "-" + sdat;
          // MyService.ConsoleLog("toDate >>", toDate);
        }

        $scope.searchObj = {
          page: pageNumber,
          max_records: 30,
          officeType: $scope.User.officeType,
          from: fromDate,
          to: toDate,
          statePostalCode: $scope.User.state.statePostalCode ? $scope.User.state.statePostalCode : $scope.User.statePostalCode ? $scope.User.statePostalCode : '',
          state: $scope.User.state.state ? $scope.User.state.state : $scope.User.state ? $scope.User.state : '',
          isContri: $scope.User.isContri,

        };
        if ($scope.User.isContri || $scope.User.isAnsVotesmart) {
          $scope.searchObj.max_records = 100;
        }
        if ($scope.User.isAnsVotesmart) {
          $scope.searchObj.isAnsVotesmart = $scope.User.isAnsVotesmart;
        }

        if ($scope.User.isAnsVotewise) {
          $scope.searchObj.isAnsVotewise = $scope.User.isAnsVotewise;
          $scope.searchObj.max_records = 1500;
        }

        if ($scope.User.isLoggedin) {
          $scope.searchObj.isLoggedin = $scope.User.isLoggedin;
        }

        if ($scope.User.addedStatement) {
          $scope.searchObj.addedStatement = $scope.User.addedStatement;
        }


        if ($scope.User.addedResume) {
          $scope.searchObj.addedResume = $scope.User.addedResume;
        }
        // MyService.ConsoleLog(" $scope.search Obj ", $scope.searchObj);
        window.localStorage.setItem('searchPara', JSON.stringify($scope.searchObj));
        $scope.statusGetResPg = 1;

        $scope.getAllCanceller = $q.defer();
        $scope.selectStatus($scope.searchObj, $scope.getAllCanceller);
      } else {
        growl.error("Please Select at Least One Dropdown List");
      }
    };


    /**
     * 
     * @param {*} ans 
     * @param {*} i 
     * @description Function is use to apply dynamic css class
     */

    $scope.getComapreClass = function (ans, i) {

      if (ans == 1)
        return 'ag-dg1';
      if (ans == 2)
        return 'ag-dg2';
      if (ans == 3)
        return 'ag-dg3';
      if (ans == 4)
        return 'ag-dg4';
      if (ans == 5)
        return 'ag-dg5';
      if (ans == 6)
        return 'ag-dg6';

      return 'ag-dg';
    };

    $scope.getAllStates();



    $scope.showCompetitors = function (val) {
      MyService.ConsoleLog("show Competitors >> ", val);
      window.localStorage.removeItem('competitorsParams');
      // window.localStorage.removeItem('searchPara');
      MyService.ConsoleLog("$scope.searchObj >> ", $scope.searchObj);

      // window.localStorage.setItem('searchPara', JSON.stringify($scope.searchObj));
      val.officeType = $scope.User.officeType;
      var params = {
        office: val.office,
        runningForOffice: val.runningForOffice,
        statePostalCode: val.statePostalCode,
        voterScope: val.voterScope,
      }

      switch (val.voterScope) {
        case "federalCongressionalDistrict":
          params["federalCongressionalDistrict"] = val.federalCongressionalDistrict;
          var obj = {
            selectedPoli: val,
            params: params
          }
          window.localStorage.setItem('competitorsParams', JSON.stringify(obj));
          $location.path('/showCompetitors');
          break;
        case "stateHouseofRepresentativesDistrict":
          params["stateHouseofRepresentativesDistrict"] = val.stateHouseofRepresentativesDistrict;
          var obj = {
            selectedPoli: val,
            params: params
          }
          window.localStorage.setItem('competitorsParams', JSON.stringify(obj));
          $location.path('/showCompetitors');
          break;
        case "stateSenateDistrict":
          params["stateSenateDistrict"] = val.stateSenateDistrict;
          var obj = {
            selectedPoli: val,
            params: params
          }
          window.localStorage.setItem('competitorsParams', JSON.stringify(obj));
          $location.path('/showCompetitors');
          break;
        case "All USA":
          var obj = {
            selectedPoli: val,
            params: params
          }
          window.localStorage.setItem('competitorsParams', JSON.stringify(obj));
          $location.path('/showCompetitors');
          break;
        case "statePostalCode":
          var obj = {
            selectedPoli: val,
            params: params
          }
          window.localStorage.setItem('competitorsParams', JSON.stringify(obj));
          $location.path('/showCompetitors');
          break;

        default:
          console.log("default");
          break;
          // case ""
      }
    }

  }]);
