'use strict';
angular.module('myApp.gettingStarted', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/gettingstarted', {
      templateUrl: 'app/gettingStarted/gettingstarted.html',
      controller: 'gettingStartedCtrl'
    });
  }])
  .controller('gettingStartedCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'growl', '$cookies', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, growl, $cookies, ngDialog) {
    if ($cookies.get('showLongPopUp')) {
      var msg = $cookies.get('showLongPopUp');
      MyService.ConsoleLog("cookies >>>>>", msg);


      var modal = ngDialog.openConfirm({
        template: '<p>' +
          msg +
          '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">CLOSE</button>\
                  </div>',
        plain: true,
        closeByDocument: false,
        width: "70%"
      });
      modal.then(
        function fullfilled(data) {
          if (data == 1) {
            // MyService.ConsoleLog("yes")
            $cookies.remove('showLongPopUp');
          } else {
            $cookies.remove('showLongPopUp');

          }
        },
        function rejected(data) {

        }
      );
    }


  }]);
