'use strict';
angular.module('myApp.organizedTable', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/organizedTable', {
      templateUrl: 'app/organizedTable/organizedTable.html',
      controller: 'OrganizedTblCtrl'
    });
  }]).controller('OrganizedTblCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', 'uiGridConstants', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce, uiGridConstants) {
    //MyService.ConsoleLog("** UploadPoliticianCtrl **");
    $scope.allState = [];
    $scope.address = {
      "state": "",
      "upstate": "",
      "files": ""
    };
    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      MyService.getAllStates().then(function (payload) {
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
          $scope.loader = false;
        });
    }
    $scope.loader = false;
    $scope.displayLong = false;
    $scope.ziprecords = [];
    $scope.recordLength = 0;
    $scope.getOrganizedData = function () {
      var state = $scope.address.state;
      //MyService.ConsoleLog(state.statePostalCode);
      $scope.loader = true;
      $scope.displayLong = true;
      $scope.ziprecords.length = 0;
      $scope.recordLength = 0;
      MyService.getOrganizedData({
        "state": state.statePostalCode
      }).then(function (payload) {
        //MyService.ConsoleLog(payload.length);
        $scope.recordLength = payload.length;
        $scope.ziprecords = payload;
        $scope.loader = false;
        $scope.displayLong = false;
      }, function (errorPayload) {
        //MyService.ConsoleLog("error");
        //MyService.ConsoleLog(errorPayload);
        $scope.loader = false;
        $scope.displayLong = false;
      });

    }

    $scope.updateZips = function (data) {
      //MyService.ConsoleLog(data);
      if (data.files != "") {
        //MyService.ConsoleLog(data.files.type.indexOf("office"));
        //alert("This needs to be implemented");

        if (data.files.type.indexOf("office") > -1) {
          alert("This is still to be implemented.");
          $scope.PressButton = true;
          //MyService.ConsoleLog("data : ", data);
          var fd = new FormData();
          fd.append('file', data.files);
          fd.append('state', data.upsate);
          var posturl = baseApiUrl + "/user/uploadZips";
          //MyService.ConsoleLog(data.upstate.statePostalCode);
          //MyService.ConsoleLog(data.files);
          //MyService.ConsoleLog(JSON.stringify(fd));


          $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            })
            .success(function (response, status, headers, config) {
              //MyService.ConsoleLog("response : ", response);
              if (response.success) {
                //$location.path('/learnHome');
                alert("File successfully imported.");
              } else {
                alert("Something went wrong.");
              }
            })
            .error(function (data, status, headers, config) {
              //MyService.ConsoleLog("error");
              alert("Something went wrong.");
            });


        } else {
          alert("Please select xlsx file");
        }

      } else {
        alert("Please select file");
      }

    }

    if ($rootScope.showOrganized) {
      //alert("Here");
      $scope.getAllStates();
    }

  }])