angular
  .module("myApp.updateUserAddress", ["ngRoute", "ngDialog"])
  .controller("UpdateUserAddressCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$q",
    "ngDialog",
    "$rootScope",
    "growl",
    "MyService",
    "$filter",
    "tokenService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $filter,
      tokenService
    ) {
      /*
    $scope.userAddress = {
      legislativeDistrict: "104",
      voterDistrict: "250132746",
      voterDistrictFips: "2746",
      schoolDistrict: "Agawam School District",
      schoolDistrictFips: "1800",
      stateSenateDistrict: "5",
      federalCongrassionalDist: "1",
      country: null,
      state: "MASSACHUSETTS",
      statePostalCode: "MA",
      countySubdivision: "Agawam Town city",
      countySubdivisionFips: "840",
      county: "Hampden MA",
      countyFips: "25013",
      place: "Agawam Town city, MA",
      placeFips: "840",
      zipcode: "01001"
    };
    */
      $scope.address = {
        zipcode: "",
        state: "",
      };
      $scope.statusGetZipdata = 0;
      $rootScope.welcome = false;
      $scope.showFAddress = false;
      $scope.Location = new Object();
      $scope.userAddress = $scope.ngDialogData;
      MyService.ConsoleLog("test", $scope.ngDialogData);
      MyService.ConsoleLog("$scope.userAddress", $scope.userAddress);
      if (
        $scope.ngDialogData &&
        $scope.ngDialogData.zipcode &&
        $scope.ngDialogData.zipcode != ""
      ) {
        $scope.address = {
          zipcode: $scope.ngDialogData.zipcode,
          state: "",
        };
        $scope.showNwTitle = false;
      } else {
        $scope.showNwTitle = true;
        $scope.statusGetZipdata = 2;
      }

      var previousSelVal = {};
      $scope.setSelectedDropdown = function (
        listData,
        setfor,
        selectedUserAdrs
      ) {
        // //MyService.ConsoleLog("listData: ",listData);
        // //MyService.ConsoleLog("setfor: ",setfor);
        switch (setfor) {
          case "counties":
            previousSelVal.county = $scope.address.county;
            // //MyService.ConsoleLog("selCountyNumber: ",previousSelVal.county);

            $scope.address.county = null;
            if (listData) {
              $scope.counties = listData;
              // $scope.counties.unshift({
              //   number: null,
              //   name: "none"
              // });
              // if(!selectedUserAdrs) selectedUserAdrs.countyFips = previousSelVal.county.number;
              var selCountyNumber = null;
              try {
                $scope.address.county = $scope.counties[0];

                if (selectedUserAdrs) {
                  selCountyNumber = selectedUserAdrs.countyFips;
                }

                if (previousSelVal.county) {
                  selCountyNumber = previousSelVal.county.number;
                }

                if (selCountyNumber) {
                  // //MyService.ConsoleLog("selCountyNumber: ",selCountyNumber);
                  angular.forEach($scope.counties, function (val, key) {
                    if (selCountyNumber == val.COUNTYFP)
                      $scope.address.county = val;
                  });
                }
              } catch (e) {
                ////MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "subCounties":
            previousSelVal.subcounty = $scope.address.subcounty;
            $scope.address.subcounty = null;
            if (listData) {
              $scope.subCounties = listData;
              // $scope.subCounties.unshift({
              //   number: null,
              //   name: "none"
              // });
              var selSubCountyNo = null;
              try {
                $scope.address.subcounty = $scope.subCounties[0];
                if (selectedUserAdrs) {
                  selSubCountyNo = selectedUserAdrs.countySubdivisionFips;
                }
                if (previousSelVal.subcounty) {
                  selSubCountyNo = previousSelVal.subcounty.number;
                }
                if (selSubCountyNo) {
                  angular.forEach($scope.subCounties, function (val, key) {
                    //set selected state
                    // if(selectedUserAdrs.countySubdivisionFips == val.number)

                    if (selSubCountyNo == val.COUSUBFP)
                      $scope.address.subcounty = val;
                  });
                }
              } catch (e) {
                ////MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "places":
            previousSelVal.place = $scope.address.place;
            $scope.address.place = null;
            if (listData) {
              $scope.places = listData;
              // MyService.ConsoleLog("one more log", $scope.places);

              // $scope.places.unshift({
              //   number: null,
              //   name: "none"
              // });

              try {
                $scope.address.place = $scope.places[0];
                var selPlaceNo = null;
                if (selectedUserAdrs) {
                  selPlaceNo = selectedUserAdrs.placeFips;
                }
                if (previousSelVal.place) {
                  selPlaceNo = previousSelVal.place.number;
                }
                if (selPlaceNo) {
                  angular.forEach($scope.places, function (val, key) {
                    if (selPlaceNo == val.PLACEFP) $scope.address.place = val;
                    MyService.ConsoleLog(
                      "$scope.address.place",
                      $scope.address.place
                    );
                  });
                }
              } catch (e) {
                ////MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "voterDistricts":
            if (listData) {
              previousSelVal.voterDistrict = $scope.address.voterDistrict;
              $scope.address.voterDistrict = null;
              $scope.voterDistricts = listData;
              // $scope.voterDistricts.unshift({
              //   number: null,
              //   name: "none"
              // });

              var voterDistrictNo = null;
              try {
                $scope.address.voterDistrict = $scope.voterDistricts[0];
                if (selectedUserAdrs) {
                  voterDistrictNo = selectedUserAdrs.voterDistrictFips;
                }
                if (previousSelVal.voterDistrict) {
                  voterDistrictNo = previousSelVal.voterDistrict.number;
                }

                if (voterDistrictNo) {
                  angular.forEach($scope.voterDistricts, function (val, key) {
                    //set selected

                    if (voterDistrictNo == val.number)
                      $scope.address.voterDistrict = val;
                  });
                }
              } catch (e) {
                ////MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "federalCongrassionalDist":
            $scope.address.federalCongrassionalDist = null;
            if (listData) {
              $scope.federalCongrassionalDist = listData;
              // $scope.federalCongrassionalDist.unshift("none");

              if (selectedUserAdrs) {
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                angular.forEach(
                  $scope.federalCongrassionalDist,
                  function (val, key) {
                    //set selected state

                    if (
                      selectedUserAdrs.federalCongrassionalDist == val.CD115FP
                    )
                      $scope.address.federalCongrassionalDist = val;
                  }
                );
              }
            }
            break;
          case "stateSenateDistrict":
            $scope.address.stateSenateDistrict = null;
            if (listData) {
              $scope.stateSenateDistrict = listData;
              // $scope.stateSenateDistrict.unshift("none");
              $scope.address.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateSenateDistrict,
                  function (val, key) {
                    //set selected state

                    if (selectedUserAdrs.stateSenateDistrict == val.SLDUST)
                      $scope.address.stateSenateDistrict = val;
                    else if (selectedUserAdrs == val.SLDUST)
                      $scope.address.stateSenateDistrict = val;
                  }
                );
              }
            }
            break;
          case "stateHouseOFRepresentativeDistrict":
            $scope.address.stateLegislativeDistrict = null;
            // //MyService.ConsoleLog("listData>>>>", listData);
            if (listData) {
              $scope.stateLegislativeDistrict = listData;
              // $scope.stateLegislativeDistrict.unshift("none");
              // var matchval = "";
              $scope.address.stateLegislativeDistrict =
                $scope.stateLegislativeDistrict[0];
              // //MyService.ConsoleLog("selectedUserAdrs > ", selectedUserAdrs);
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateLegislativeDistrict,
                  function (val, key) {
                    //set selected state
                    // //console.log(val);

                    // //MyService.ConsoleLog("val >> ", val);
                    var val2 = val.NAMELSAD.replace(
                      "State House District ",
                      ""
                    );
                    if (selectedUserAdrs.legislativeDistrict == val2) {
                      $scope.address.stateLegislativeDistrict = val;
                    } else if (selectedUserAdrs == val2) {
                      $scope.address.stateLegislativeDistrict = val;
                    }
                  }
                );
              }
            }
            break;
          case "schoolDistricts":
            $scope.address.schoolDistrict = null;
            if (listData) {
              $scope.schoolDistricts = listData;
              // $scope.schoolDistricts.unshift({
              //   number: null,
              //   name: "none"
              // });
              $scope.address.schoolDistricts = $scope.schoolDistricts[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.schoolDistricts, function (val, key) {
                  //set selected state

                  if (selectedUserAdrs.schoolDistrictFips == val.UNSDLEA)
                    $scope.address.schoolDistrict = val;
                  else if (selectedUserAdrs == val.UNSDLEA)
                    $scope.address.schoolDistrict = val;
                });
              }
            }
            break;
        }
      };

      $scope.prevZipCode = null;
      var getZipDataCanceler = $q.defer();
      $scope.getZipData = function () {
        // //MyService.ConsoleLog("** getZipData **", " prevZipCode: ", $scope.prevZipCode, " zipcode: ", $scope.address.zipcode)
        // MyService.ConsoleLog("zipcode: ", JSON.parse(tokenService.getUserData()));

        MyService.getMailingAddress({
          userId: tokenService.getUserId(),
        }).then(
          function (result) {
            MyService.ConsoleLog("Result", result);
            $scope.mainData = result.data.data;
            if ($scope.prevZipCode != $scope.mainData.zipcode) {
              $scope.states = [];
              $scope.statusGetZipdata = 1;
              getZipDataCanceler.resolve("cancel");
              getZipDataCanceler = $q.defer();
              var param = {
                zipcode: $scope.mainData.zipcode,
                candidate: true,
              };
              // alert("two");

              MyService.getAddressData(param, getZipDataCanceler.promise).then(
                function (response) {
                  MyService.ConsoleLog("*** GOT ZIP DATA : ", response);
                  $scope.prevZipCode = $scope.mainData.zipcode;
                  $scope.address.zipcode = $scope.mainData.zipcode;
                  // //MyService.ConsoleLog("success : ", response.data.success);
                  if (response.data.success) {
                    var zipData = response.data.data;
                    if (zipData.state || zipData.place) {
                      // //MyService.ConsoleLog("zipData :: ", zipData);

                      $scope.mainAddress = zipData;

                      if (zipData.state && zipData.state.length > 0) {
                        //MyService.ConsoleLog($scope.allStates);
                        // angular.forEach(zipData.statePostalCode, function(stCode, i) {
                        angular.forEach($scope.allStates, function (val, key) {
                          angular.forEach(zipData.state, function (val1, key1) {
                            if (val.statePostalCode == val1.STUSPS) {
                              $scope.states.push(val);
                              $scope.address.state = val;
                              // //MyService.ConsoleLog("-----------------pushed--------------", val);
                            }
                          });
                        });
                        // });
                        ////MyService.ConsoleLog("$scope.states: ",$scope.states);
                        angular.forEach($scope.states, function (val, key) {
                          //set selected state
                          if (
                            $scope.userAddress.statePostalCode ==
                            val.statePostalCode
                          )
                            $scope.address.state = val;
                        });
                      }
                      // //console.log($scope.userAddress);
                      //MyService.ConsoleLog("zipData > ", zipData);
                      var countySubdivisions = zipData.places.filter(function (
                        obj
                      ) {
                        if (obj.COUSUBFP && obj.COUSUBFP != "") {
                          // //MyService.ConsoleLog("Inside cs");
                          return obj;
                        }
                      });
                      var places = zipData.places.filter(function (obj) {
                        if (obj.PLACEFP && obj.PLACEFP != "") {
                          // //MyService.ConsoleLog("obj > ", obj);
                          return obj;
                        }
                      });
                      MyService.ConsoleLog("places", places);
                      if (zipData.county)
                        $scope.setSelectedDropdown(
                          zipData.county,
                          "counties",
                          $scope.userAddress
                        );

                      if (countySubdivisions)
                        $scope.setSelectedDropdown(
                          countySubdivisions,
                          "subCounties",
                          $scope.userAddress
                        );

                      if (places)
                        $scope.setSelectedDropdown(
                          places,
                          "places",
                          $scope.userAddress
                        );

                      // if (zipData.voterDistricts) $scope.setSelectedDropdown(zipData.voterDistricts, "voterDistricts", $scope.userAddress);

                      if (zipData.congressionalDistrict)
                        $scope.setSelectedDropdown(
                          zipData.congressionalDistrict,
                          "federalCongrassionalDist",
                          $scope.userAddress
                        );

                      if (zipData.stateSenateDistrict)
                        $scope.setSelectedDropdown(
                          zipData.stateSenateDistrict,
                          "stateSenateDistrict",
                          $scope.userAddress
                        );

                      if (zipData.stateHouseofRepresentativesDistrict)
                        $scope.setSelectedDropdown(
                          zipData.stateHouseofRepresentativesDistrict,
                          "stateHouseOFRepresentativeDistrict",
                          $scope.userAddress
                        );

                      if (zipData.unifiedSchoolDistrict)
                        $scope.setSelectedDropdown(
                          zipData.unifiedSchoolDistrict,
                          "schoolDistricts",
                          $scope.userAddress
                        );
                    } else {
                      // alert("Zipcode is not found in our database, please correct it.");
                    }

                    $scope.statusGetZipdata = 2;
                  } else if (
                    response.data.error &&
                    typeof response.data.error == "string"
                  ) {
                    growl.error("Error: " + response.data.error);
                    $scope.statusGetZipdata = 2;
                  } else {
                    growl.error("Something went wrong.");
                    $scope.statusGetZipdata = 3;
                  }
                },
                function (err) {
                  if (err.status != -1) {
                    growl.error("Something went wrong.");
                    $scope.statusGetZipdata = 3;
                  } else $scope.statusGetZipdata = 2;
                }
              );
              /*$http({
                    method: "GET",
                    url: "http://localhost:8080/ziplookup/list/filter",
                    params: param,
                    timeout: getZipDataCanceler.promise
                }).then(function(response){
                    ////MyService.ConsoleLog("GOT DATA: ",response);
                });*/
            }
          },
          function (err) {
            MyService.ConsoleLog("Error >", err);
          }
        );
      };

      $scope.zipCodeChanged = function () {
        ////MyService.ConsoleLog("zipCodeChanged");
        $scope.getZipData();
      };

      $scope.onCountyChange = function () {
        ////MyService.ConsoleLog("** onCountyChange **");
        try {
          var postobj = {};

          $scope.statusCountySearch = 1;
          postobj.zipcode = $scope.address.zipcode;
          postobj.county = $scope.address.county.name;

          if ($scope.address.county.name.toLowerCase() == "none") {
            return;
          }
          // alert("three");

          MyService.getAddressData(postobj).then(
            function (response) {
              ////MyService.ConsoleLog("Got zip data: ", response);
              $scope.prevZipCode = $scope.address.zipcode;

              if (response.data.success) {
                var zipData = response.data.data;

                if (zipData.subCounties)
                  $scope.setSelectedDropdown(
                    zipData.subCounties,
                    "subCounties",
                    null
                  );

                if (zipData.places)
                  $scope.setSelectedDropdown(zipData.places, "places", null);

                if (zipData.voterDistricts)
                  $scope.setSelectedDropdown(
                    zipData.voterDistricts,
                    "voterDistricts",
                    null
                  );

                if (zipData.federalCongrassionalDist)
                  $scope.setSelectedDropdown(
                    zipData.federalCongrassionalDist,
                    "federalCongrassionalDist",
                    null
                  );

                if (zipData.stateSenateDistrict)
                  $scope.setSelectedDropdown(
                    zipData.stateSenateDistrict,
                    "stateSenateDistrict",
                    null
                  );

                if (zipData.stateHouseOFRepresentativeDistrict)
                  $scope.setSelectedDropdown(
                    zipData.stateHouseOFRepresentativeDistrict,
                    "stateHouseOFRepresentativeDistrict",
                    null
                  );

                if (zipData.schoolDistricts)
                  $scope.setSelectedDropdown(
                    zipData.schoolDistricts,
                    "schoolDistricts",
                    null
                  );

                $scope.statusCountySearch = 2;
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
                $scope.statusCountySearch = 2;
              } else {
                growl.error("Something went wrong.");
                $scope.statusCountySearch = 3;
              }
            },
            function (err) {
              growl.error("Something went wrong.");
              $scope.statusCountySearch = 3;
            }
          );
        } catch (e) {
          ////MyService.ConsoleLog("Exception : ",e);
        }
      };
      $scope.onSubCountyChange = function () {
        ////MyService.ConsoleLog("** onSubCountyChange **");
        try {
          var postobj = {};

          $scope.statusSubCountySearch = 1;
          postobj.zipcode = $scope.address.zipcode;
          postobj.county = $scope.address.county.name;
          postobj.countySubdivision = $scope.address.subcounty.name;

          if ($scope.address.subcounty.name.toLowerCase() == "none") {
            return;
          }
          // alert("four");

          MyService.getAddressData(postobj).then(
            function (response) {
              ////MyService.ConsoleLog("Got zip data: ", response);
              $scope.prevZipCode = $scope.address.zipcode;

              if (response.data.success) {
                var zipData = response.data.data;

                if (zipData.places)
                  $scope.setSelectedDropdown(zipData.places, "places", null);

                if (zipData.voterDistricts)
                  $scope.setSelectedDropdown(
                    zipData.voterDistricts,
                    "voterDistricts",
                    null
                  );

                if (zipData.federalCongrassionalDist)
                  $scope.setSelectedDropdown(
                    zipData.federalCongrassionalDist,
                    "federalCongrassionalDist",
                    null
                  );

                if (zipData.stateSenateDistrict)
                  $scope.setSelectedDropdown(
                    zipData.stateSenateDistrict,
                    "stateSenateDistrict",
                    null
                  );

                if (zipData.stateHouseOFRepresentativeDistrict)
                  $scope.setSelectedDropdown(
                    zipData.stateHouseOFRepresentativeDistrict,
                    "stateHouseOFRepresentativeDistrict",
                    null
                  );

                if (zipData.schoolDistricts)
                  $scope.setSelectedDropdown(
                    zipData.schoolDistricts,
                    "schoolDistricts",
                    null
                  );

                $scope.statusSubCountySearch = 2;
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
                $scope.statusSubCountySearch = 2;
              } else {
                growl.error("Something went wrong.");
                $scope.statusSubCountySearch = 3;
              }
            },
            function (err) {
              growl.error("Something went wrong.");
              $scope.statusSubCountySearch = 3;
            }
          );
        } catch (e) {
          ////MyService.ConsoleLog("Exception : ",e);
        }
      };
      $scope.mainAddress = "";

      $scope.onVoterDistChange = function () {
        //MyService.ConsoleLog("** onVoterDistChange **");
        try {
          var postobj = {};

          $scope.statusVoterDistSearch = 1;
          postobj.zipcode = $scope.address.zipcode;
          //MyService.ConsoleLog("a:::", $scope.address);
          if (
            $scope.address.voterDistrict &&
            $scope.address.voterDistrict.name.toLowerCase() != "none"
          ) {
            postobj.voterDistrictName = $scope.address.voterDistrict.name;

            //MyService.ConsoleLog("postobj: ", postobj);
            // alert("one");
            MyService.getAddressData(postobj).then(
              function (response) {
                // //MyService.ConsoleLog("Got zip data: ", response);
                if (response.data.success) {
                  var zipData = response.data.data;
                  $scope.mainAddress = zipData;
                  if (zipData.counties)
                    $scope.setSelectedDropdown(
                      zipData.counties,
                      "counties",
                      null
                    );

                  if (zipData.subCounties)
                    $scope.setSelectedDropdown(
                      zipData.subCounties,
                      "subCounties",
                      null
                    );

                  if (zipData.places)
                    $scope.setSelectedDropdown(zipData.places, "places", null);

                  if (zipData.voterDistricts)
                    $scope.setSelectedDropdown(
                      zipData.voterDistricts,
                      "voterDistricts",
                      {
                        voterDistrictFips: $scope.address.voterDistrict.number,
                      }
                    );

                  if (zipData.federalCongrassionalDist)
                    $scope.setSelectedDropdown(
                      zipData.federalCongrassionalDist,
                      "federalCongrassionalDist",
                      null
                    );

                  if (zipData.stateSenateDistrict)
                    $scope.setSelectedDropdown(
                      zipData.stateSenateDistrict,
                      "stateSenateDistrict",
                      null
                    );

                  if (zipData.stateHouseOFRepresentativeDistrict)
                    $scope.setSelectedDropdown(
                      zipData.stateHouseOFRepresentativeDistrict,
                      "stateHouseOFRepresentativeDistrict",
                      null
                    );

                  if (zipData.schoolDistricts)
                    $scope.setSelectedDropdown(
                      zipData.schoolDistricts,
                      "schoolDistricts",
                      null
                    );

                  $scope.statusVoterDistSearch = 2;
                } else if (
                  response.data.error &&
                  typeof response.data.error == "string"
                ) {
                  growl.error("Error: " + response.data.error);
                  $scope.statusVoterDistSearch = 2;
                } else {
                  growl.error("Something went wrong.");
                  $scope.statusVoterDistSearch = 3;
                }
              },
              function (err) {
                growl.error("Something went wrong.");
                $scope.statusVoterDistSearch = 3;
              }
            );
          }
        } catch (e) {
          ////MyService.ConsoleLog("Exception : ",e);
        }
      };

      $scope.statusGetStates = 0; //1: loading, 2: loaded, 3: failed
      var getAllStatesCanceler = $q.defer();
      $scope.getAllStates = function () {
        // //MyService.ConsoleLog("** getAllStates **");

        $scope.statusGetStates = 1;
        MyService.getAllStates().then(
          function (data) {
            // //MyService.ConsoleLog("** getAllStates **", data);
            /*{
          state: "ALABAMA",
          statePostalCode: "AL"
        }*/
            $scope.statusGetStates = 2;
            $scope.allStates = data;
            $scope.getZipData();
          },
          function (err) {
            $scope.statusGetStates = 3;
            ////MyService.ConsoleLog("got states error");
            // growl.error("Some");
          }
        );
      };

      $scope.getAllStates();

      $scope.updateAddress = function (argument) {
        MyService.ConsoleLog("argument", argument);
        if ($scope.statusGetStates == 2 && $scope.statusGetZipdata == 2) {
          var postobj = {
            userId: $rootScope.UserId,
            zipcode: $scope.mainData.zipcode,
          };

          var address = {};

          if ($scope.address.state) {
            address.state = $scope.address.state.state;
            address.statePostalCode = $scope.address.state.statePostalCode;
          }

          if ($scope.address.place) {
            address.placeFips = $scope.address.place.PLACEFP;
            address.place = $scope.address.place.placeName
              ? $scope.address.place.placeName
              : $scope.address.place.NAMELSAD;
          }

          if ($scope.address.county) {
            address.countyFips = $scope.address.county.COUNTYFP;
            address.county = $scope.address.place.countyName
              ? $scope.address.place.countyName
              : $scope.address.county.NAMELSAD;
          }

          if ($scope.address.subcounty) {
            address.countySubdivisionFips = $scope.address.subcounty.COUSUBFP;
            address.countySubdivision = $scope.address.subcounty.NAMELSAD;
          }

          if ($scope.address.federalCongrassionalDist) {
            address.federalCongrassionalDist =
              $scope.address.federalCongrassionalDist.CD115FP;
          }

          if ($scope.address.stateSenateDistrict) {
            address.stateSenateDistrict =
              $scope.address.stateSenateDistrict.SLDUST;
          }

          if ($scope.address.schoolDistrict) {
            address.schoolDistrictFips = $scope.address.schoolDistrict.UNSDLEA;
            address.schoolDistrict = $scope.address.schoolDistrict.NAME;
          }

          // if ($scope.address.voterDistrict) {
          //   address.voterDistrictFips = $scope.address.voterDistrict.number;
          //   address.voterDistrict = $scope.address.voterDistrict.name;
          // }

          if ($scope.address.stateLegislativeDistrict) {
            address.legislativeDistrict =
              $scope.address.stateLegislativeDistrict.NAMELSAD.replace(
                "State House District ",
                ""
              );
          }

          //MyService.ConsoleLog(" $scope.address: ", address);

          postobj.address = JSON.stringify(address);
          ////MyService.ConsoleLog("postobj.address: ", postobj);

          MyService.updateProfile(postobj).then(
            function (data) {
              growl.success("Updated successfully.");
              // $scope.setAddressNew();
              $scope.closeThisDialog();
            },
            function (err) {
              growl.error("Something went wrong.");
            }
          );
        }
      };

      $scope.User = {
        Address: "",
      };

      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // //console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            console.log($scope.lati);
            console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            console.log(
              "  place.address_components :",
              place.address_components
            );
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // //console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // //console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // //console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // //console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  // document.getElementById('zip').value = place.address_components[i].long_name;
                  $scope.ZipCode = place.address_components[i].long_name;
                  // //console.log($scope.User.ZipCode);
                }
              }
            }

            //MyService.ConsoleLog(" $scope.User.ZipCode :", $scope.ZipCode);

            $scope.loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // //console.log(" long and lat :",$scope.loc );
            // $rootScope.location = new Array;

            $scope.showAddressDropDowns("", $scope.loc);
          }
        );
      };

      $scope.checkLen = function (ldata, sval) {
        // //MyService.ConsoleLog("vdata : ", vdata);
        //MyService.ConsoleLog("vdata", ldata);
        if (ldata && ldata.length > 0) {
          var data = ldata.map(function (sub) {
            var returnval = "";
            switch (sval) {
              case "federalCongrassionalDist":
                returnval = sub.CD115FP;
                break;
              case "stateSenateDistrict":
                returnval = sub.SLDUST;
                break;
              case "stateHouseOFRepresentativeDistrict":
                returnval = sub.NAMELSAD;
                break;
              case "unifiedSchoolDistrictName":
                returnval = sub.NAME;
                break;
              case "place":
                returnval = sub.place;
                break;
              case "countySubdivision":
                returnval = sub.countySubdivision;
                break;
              case "county":
                returnval = sub.county;
                break;
              case "state":
                returnval = sub.state;
                break;
              case "default":
                returnval = sub.NAME;
                break;
            }
            return returnval;
          });

          var ndata = $filter("unique")(data);
          if (ndata.length > 1) {
            return "yellowBackground";
          } else {
            return "";
          }
        } else {
          return "";
        }
      };

      $scope.allcounties = [];
      $scope.getCounties = function (lbl) {
        MyService.ConsoleLog("county >", $scope.oldaddress);
        // $scope.allcounties.length = 0;
        var statePostalCode = "";
        var state = "";
        // if (lbl == "new") {
        //   state = JSON.stringify($scope.oldaddress.state);
        // } else {
        statePostalCode = $scope.oldaddress.state[0].STATEFP;
        state = $scope.Location.state;
        // }
        // //MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
        MyService.getAllCounties(statePostalCode, state).then(
          function (payload) {
            // //MyService.ConsoleLog("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == "new") {
              var county = $scope.checkCounty(
                allcounties,
                $scope.oldaddress.county
              );
              $scope.allcounties = county;
              MyService.ConsoleLog("county<><", county[0]);
              $scope.Location.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        // //MyService.ConsoleLog(" $scope.Location.state :",$scope.Location.state);
        // //MyService.ConsoleLog("$scope.Location.county :",$scope.Location.county);
        // var county = $scope.Location.county;

        $scope.MyPlaces.length = 0;
        var county = "";
        var statePostalCode = "";
        $scope.MyPlaces.length = 0;
        var state = "";
        var county = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }
        // //MyService.ConsoleLog(statePostalCode + "===" + county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            // payload = JSON.parse(payload);
            $scope.MyPlaces = payload;
            // //MyService.ConsoleLog("PlaCE :", payload);
            // //MyService.ConsoleLog(payload.countySubdivision);
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // //MyService.ConsoleLog("PlaCE :", payload);
            if (lbl == "new") {
              // //MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // //MyService.ConsoleLog("subcounty<><><", subcounty);
              $scope.subcounties = subcounty;
              $scope.places = places;
              // //console.log('***********', places[0]);
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.Location.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.checkState = function (rdata, val) {
        //MyService.ConsoleLog("State :", rdata);
        //MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog("County :", rdata);
        MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            // MyService.ConsoleLog("obj", obj);
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              MyService.ConsoleLog("rdata", rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // //MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // //MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.emptyOrNull = function (item) {
        return !(
          item._id.place === null ||
          item._id.place === "" ||
          item._id.place === " "
        );
      };
      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.showAddressDrop = false;
      $scope.showAddressDropDowns = function (zipcode, loc) {
        $scope.showZipLoader = true;
        // //console.log(zipcode);

        if (loc && loc != "") {
          MyService.getAllStates().then(
            function (payload) {
              $scope.allStateRes = payload;
              $scope.states = payload;
              // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );

          MyService.getOrganizedZipData({
            candidate: true,
            loc: loc,
          }).then(
            function (respo) {
              //MyService.ConsoleLog("mylog", respo);
              if (respo && respo.state) {
                // var finalData = payload.data;
                // //MyService.ConsoleLog(payload[0]);
                // //MyService.ConsoleLog(payload[0].countySubdivision);
                // //MyService.ConsoleLog(payload[0].county);
                // //MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = respo.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // //MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = respo.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // //MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });

                // //MyService.ConsoleLog("here");
                $scope.showZipLoader = false;
                $scope.showAddressDrop = true;
                // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);

                $scope.oldaddress.state = respo.state;
                $scope.oldaddress.county = respo.county;
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // //MyService.ConsoleLog("places >>> ",places);
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // //MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = respo.congressionalDistrict;
                //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = respo.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  respo.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
                if (respo.cityCouncilDistrict) {
                  $scope.cityCouncilDistrict = respo.cityCouncilDistrict;
                }

                //$scope.$apply();
                //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
                // //MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState(
                  $scope.allStateRes,
                  respo.state
                );
                $scope.allstatesData = address;
                // //MyService.ConsoleLog("address<>", address);
                // $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                $scope.Location.cityCouncilDistrict = $scope.cityCouncilDistrict
                  ? $scope.cityCouncilDistrict[0]
                  : [];
                // //MyService.ConsoleLog("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        } else {
          MyService.getAllStates().then(
            function (payload) {
              $scope.allStateRes = payload;
              $scope.states = payload;
              // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );

          MyService.getOrganizedZipData({
            zipcode: zipcode,
            candidate: true,
          }).then(
            function (respo) {
              //MyService.ConsoleLog("mylog", respo);
              if (respo && respo.state) {
                // var finalData = payload.data;
                // //MyService.ConsoleLog(payload[0]);
                // //MyService.ConsoleLog(payload[0].countySubdivision);
                // //MyService.ConsoleLog(payload[0].county);
                // //MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = respo.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // //MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = respo.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // //MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });

                // //MyService.ConsoleLog("here");
                $scope.showZipLoader = false;
                $scope.showAddressDrop = true;
                // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);

                $scope.oldaddress.state = respo.state;
                $scope.oldaddress.county = respo.county;
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // //MyService.ConsoleLog("places >>> ",places);
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // //MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = respo.congressionalDistrict;
                //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = respo.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  respo.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
                //$scope.$apply();
                //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
                // //MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState(
                  $scope.allStateRes,
                  respo.state
                );
                $scope.allstatesData = address;
                // //MyService.ConsoleLog("address<>", address);
                // $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                //MyService.ConsoleLog("$scope.Location.federalCongrassionalDist :", $scope.federalCongrassionalDist[0]);
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                // //MyService.ConsoleLog("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }
      };

      /*
    $scope.updateAddressNew = function() {
//MyService.ConsoleLog("Here");
//MyService.ConsoleLog($scope.result);
//MyService.ConsoleLog($scope.result.City);
      if ($scope.result.City && $scope.result.City.trim() != "") {

        var postobj = {
          userId: $rootScope.UserId,
          zipcode: $scope.findaddress.zipcode
        };

        var address = {};

        if ($scope.result.State) {
          address.statePostalCode = $scope.result.State;
        }

        if ($scope.result.PlaceName) {
          address.place = $scope.result.PlaceName;
        }

        if ($scope.result.County) {
          address.countyFips = $scope.result.CountyFIPS;
          address.county = $scope.result.CountyName;
        }

        if ($scope.result.CountySubdivisionName) {
          address.countySubdivisionFips = $scope.result.CountySubdivisionCode;
          address.countySubdivision = $scope.result.CountySubdivisionName;
        }

        if ($scope.result.CongressionalDistrict) {
          address.federalCongrassionalDist = $scope.result.CongressionalDistrict;
        }

        if ($scope.result.UnifiedSchoolDistrictName) {
          address.schoolDistrictFips = $scope.result.UnifiedSchoolDistrictCode;
          address.schoolDistrict = $scope.result.UnifiedSchoolDistrictName;
        }
        ////MyService.ConsoleLog(" $scope.address: ", address);
        postobj.address = JSON.stringify(address);
        //MyService.ConsoleLog("postobj.address: ", postobj);
        MyService.updateProfile(postobj).then(function(data) {
          //MyService.ConsoleLog(data);
          growl.error("Updated successfully.");
          $scope.closeThisDialog();
        }, function(err) {
          //MyService.ConsoleLog(err);
          growl.error("Something went wrong.");
        });
      }
    };
    */

      $scope.showFindAddress = function () {
        if (!$scope.showFAddress) {
          $scope.getZipData();
          $scope.showFAddress = true;
        } else {
          $scope.showFAddress = false;
        }
      };

      $scope.findaddress = {
        address: "",
        city: "",
        state: "",
        zipcode: "",
      };
      $scope.result = {
        city: "",
      };
      $scope.finddetailsclicked = false;
      $scope.getDetails = function (records) {
        var error = "";
        if ($scope.findaddress.address == "") {
          error = "Please enter address";
        }
        if (error == "" && $scope.findaddress.city == "") {
          error = "Please enter city";
        }
        if (error == "" && $scope.findaddress.state == "") {
          error = "Please enter state";
        }
        if (error == "" && $scope.findaddress.zipcode == "") {
          error = "Please enter zipcode";
        }

        if (error == "") {
          $scope.finddetailsclicked = true;
          // //MyService.ConsoleLog("=============Address==============");
          // //MyService.ConsoleLog($scope.findaddress);
          // //MyService.ConsoleLog("findaddress<><><>", records);
          var aurl =
            "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify";
          aurl += "?id=RyMI8jlzD6dHUApvp_3XRP**";
          aurl += "&cols=GrpCensus,GrpCensus2,GrpGeocode";
          aurl += "&a1=" + records.address;
          aurl += "&city=" + records.city;
          aurl += "&state=" + records.state;
          aurl += "&postal=" + records.zipcode;
          aurl += "&ctry=US";
          aurl += "&format=JSON";
          // var deferred = $q.defer();
          //var furl = "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify?id=RyMI8jlzD6dHUApvp_3XRP**&cols=GrpCensus,GrpCensus2,GrpGeocode&a1=" + records.address + "&city=" + records.city + "&state=" + records.state + "&postal=" + records.zipcode + "&ctry=US&format=JSON";
          // //MyService.ConsoleLog("furl <><>>", furl);
          $http.get(aurl).then(
            function successCallback(response) {
              //$scope.result = "";
              $scope.finddetailsclicked = false;
              if (response.statusText == "OK") {
                var rdata = response.data.Records[0];
                if (rdata) {
                  $scope.result = rdata;
                }
              }
              //MyService.ConsoleLog("response", response);
            },
            function errorCallBack(error) {
              $scope.finddetailsclicked = false;
              // //MyService.ConsoleLog(error);
            }
          );
        } else {
          alert(error);
        }
      };

      $scope.checkLength = function (vdata, val) {
        var data = vdata.map(function (sub) {
          var returnval = "";
          switch (val) {
            case "federalCongrassionalDist":
              returnval = sub.federalCongrassionalDist;
              break;
            case "stateSenateDistrict":
              returnval = sub.stateSenateDistrict;
              break;
            case "stateHouseOFRepresentativeDistrict":
              returnval = sub.stateHouseOFRepresentativeDistrict;
              break;
            case "unifiedSchoolDistrictName":
              returnval = sub.unifiedSchoolDistrictName;
              break;
            case "cityCouncilDistrict":
              returnval = sub.COUNTDIST;
              break;
            case "default":
              returnval = sub.federalCongrassionalDist;
              break;
          }
          return returnval;
        });

        var ndata = $filter("unique")(data);
        if (ndata.length > 1) {
          return "yellowBackground";
        } else {
          return "";
        }
      };

      function remove(array, element) {
        const index = array.indexOf(element);

        if (index !== -1) {
          array.splice(index, 1);
        }
      }

      // $scope.oldAddress = "";
      $scope.checkZipAddress = function (val) {
        // //MyService.ConsoleLog("Main<><><>", $scope.mainAddress);
        // $scope.oldAddress = angular.copy($scope.mainAddress);
        // //MyService.ConsoleLog("main<><><", $scope.mainAddress);
        // //MyService.ConsoleLog("val+==", val);

        if ($scope.mainAddress.stateHouseOFRepresentativeDistrict) {
          var stateHouseOFRepresentativeDistrict =
            $scope.mainAddress.stateHouseOFRepresentativeDistrict.filter(
              function (rdata) {
                if (parseInt(rdata) == parseInt(val.StateDistrictLower)) {
                  return rdata;
                }
              }
            );
          //MyService.ConsoleLog("stateHouseOFRepresentativeDistrict<><><>", stateHouseOFRepresentativeDistrict);
          $scope.setSelectedDropdown(
            stateHouseOFRepresentativeDistrict,
            "stateHouseOFRepresentativeDistrict",
            stateHouseOFRepresentativeDistrict[0]
          );
          // $scope.address.stateSenateDistrict = stateHouseOFRepresentativeDistrict[0];
        }
        if ($scope.mainAddress.stateSenateDistrict) {
          var stateSenateDistrict =
            $scope.mainAddress.stateSenateDistrict.filter(function (rdata) {
              if (parseInt(rdata) == parseInt(val.StateDistrictUpper)) {
                return rdata;
              }
            });
          //MyService.ConsoleLog("stateSenateDistrict<><><>", stateSenateDistrict);
          $scope.setSelectedDropdown(
            stateSenateDistrict,
            "stateSenateDistrict",
            stateSenateDistrict[0]
          );
          // $scope.address.stateLegislativeDistrict = stateSenateDistrict[0];
        }
        //MyService.ConsoleLog("voterDistricts<><><", $scope.mainAddress.voterDistricts);
        $scope.setSelectedDropdown(
          $scope.mainAddress.voterDistricts,
          "voterDistricts",
          $scope.userAddress
        );
        if ($scope.mainAddress.federalCongrassionalDist) {
          var federalCongrassionalDist =
            $scope.mainAddress.federalCongrassionalDist.filter(function (
              rdata
            ) {
              if (parseInt(rdata) == parseInt(val.CongressionalDistrict)) {
                return rdata;
              }
            });
          //MyService.ConsoleLog("federalCongrassionalDist<><><>", federalCongrassionalDist);
          $scope.setSelectedDropdown(
            federalCongrassionalDist,
            "federalCongrassionalDist",
            federalCongrassionalDist[0]
          );
        }
        if ($scope.mainAddress.schoolDistricts) {
          var schoolDistricts = $scope.mainAddress.schoolDistricts.filter(
            function (rdata) {
              if (
                parseInt(rdata.number) ==
                parseInt(val.UnifiedSchoolDistrictCode)
              ) {
                return rdata;
              }
            }
          );
          //MyService.ConsoleLog("schoolDistricts<><><>", schoolDistricts);
          if (schoolDistricts[0] && schoolDistricts[0].number) {
            $scope.setSelectedDropdown(
              schoolDistricts,
              "schoolDistricts",
              schoolDistricts[0].number
            );
          } else {
            $scope.setSelectedDropdown(
              schoolDistricts,
              "schoolDistricts",
              null
            );
          }
        }
        if ($scope.mainAddress.subCounties) {
          var subCounties = $scope.mainAddress.subCounties.filter(function (
            rdata
          ) {
            if (parseInt(rdata.number) == parseInt(val.CountySubdivisionCode)) {
              return rdata;
            }
          });
          //MyService.ConsoleLog("subCounties<><><>", subCounties);
          if (schoolDistricts[0] && schoolDistricts[0].number) {
            $scope.setSelectedDropdown(
              subCounties,
              "subCounties",
              subCounties[0].number
            );
          } else {
            $scope.setSelectedDropdown(subCounties, "subCounties", null);
          }
        }

        /*\
      if (zipData.counties) $scope.setSelectedDropdown(zipData.counties, 'counties', $scope.userAddress);

                  if (zipData.subCounties) $scope.setSelectedDropdown(zipData.subCounties, "subCounties", $scope.userAddress);

                  if (zipData.places) $scope.setSelectedDropdown(zipData.places, "places", $scope.userAddress);

                  if (zipData.voterDistricts) $scope.setSelectedDropdown(zipData.voterDistricts, "voterDistricts", $scope.userAddress);

                  if (zipData.federalCongrassionalDist) $scope.setSelectedDropdown(zipData.federalCongrassionalDist, "federalCongrassionalDist", $scope.userAddress);

                  if (zipData.stateSenateDistrict) $scope.setSelectedDropdown(zipData.stateSenateDistrict, "stateSenateDistrict", $scope.userAddress);

                  if (zipData.stateHouseOFRepresentativeDistrict) $scope.setSelectedDropdown(zipData.stateHouseOFRepresentativeDistrict, "stateHouseOFRepresentativeDistrict", $scope.userAddress);

                  if (zipData.schoolDistricts) $scope.setSelectedDropdown(zipData.schoolDistricts, "schoolDistricts", $scope.userAddress);
            return $scope.mainAddress.filter(
              function(rdata) {
                // //MyService.ConsoleLog("Space:", parseInt(val.CongressionalDistrict));
                if (val.CongressionalDistrict == "" || val.CongressionalDistrict == " ") {
                  // //MyService.ConsoleLog("inside<><><><><>");
                  if (parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) && parseInt(rdata.FIPScountySub) == parseInt(val.CountySubdivisionCode) && parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                    parseInt(val.StateDistrictLower) && parseInt(rdata.stateSenateDistrict) == parseInt(val.StateDistrictUpper) && parseInt(rdata.unifiedSchoolDistrict) == parseInt(val.UnifiedSchoolDistrictCode)) {
                    // //MyService.ConsoleLog("This is a match");
                    return rdata;
                  }
                } else {
                  if (parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) && parseInt(rdata.FIPScountySub) == parseInt(val.CountySubdivisionCode) && parseInt(rdata.federalCongrassionalDist) == parseInt(val.CongressionalDistrict) && parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                    parseInt(val.StateDistrictLower) && parseInt(rdata.stateSenateDistrict) == parseInt(val.StateDistrictUpper) && parseInt(rdata.unifiedSchoolDistrict) == parseInt(val.UnifiedSchoolDistrictCode)) {
                    // //MyService.ConsoleLog("This is a match2");
                    return rdata;
                  }

                }
              }
            );
            */
      };

      $scope.setAddressNew = function (address) {
        MyService.ConsoleLog("setAddressNew > ", address);

        if ($scope.statusGetStates == 2 && $scope.statusGetZipdata == 2) {
          var postobj = {
            userId: $rootScope.UserId,
            zipcode: "",
          };

          var add = {};

          if (address.state) {
            add.state = address.state.state;
            add.statePostalCode = address.state.statePostalCode;
            add.stateFips = address.state.stateFips;
            //MyService.ConsoleLog("address.statePostalCode :", address.state);
          }

          if (address.place) {
            add.placeFips = address.place.placeFips;
            add.place = address.place.placeName;
          }

          if (address.county) {
            add.countyFips = address.county.countyFips;
            add.county = address.county.countyName;
          }

          if (address.subcounty) {
            add.countySubdivisionFips = address.subcounty.COUSUBFP;
            add.countySubdivision = address.subcounty.NAME;
          }

          if (address.federalCongrassionalDist) {
            add.federalCongrassionalDist =
              address.federalCongrassionalDist.CD115FP;
          }

          if (address.stateSenateDistrict) {
            add.stateSenateDistrict = address.stateSenateDistrict.SLDUST;
          }

          if (address.cityCouncilDistrict) {
            add.cityCouncilDistrictName = address.cityCouncilDistrict.COUNTDIST;
          }

          if (address.unifiedSchoolDistrictName) {
            add.schoolDistrictFips = address.unifiedSchoolDistrictName.UNSDLEA;
            add.schoolDistrict = address.unifiedSchoolDistrictName.NAME;
          }

          // if ($scope.address.voterDistrict) {
          //   address.voterDistrictFips = $scope.address.voterDistrict.number;
          //   address.voterDistrict = $scope.address.voterDistrict.name;
          // }

          if (address.stateHouseOFRepresentativeDistrict) {
            var str = address.stateHouseOFRepresentativeDistrict.NAMELSAD;
            // MyService.ConsoleLog("address.stateLegislativeDistrict.NAMELSAD >>>>", str);

            var newString = str.substr(0, str.length - 3);
            MyService.ConsoleLog("newString >>>>", str.indexOf("General"));
            if (str.indexOf("General Assembly District") > -1) {
              add.legislativeDistrict = str.replace(
                "General Assembly District ",
                ""
              );
            } else if (str.indexOf("Assembly District") > -1) {
              add.legislativeDistrict = str.replace("Assembly District ", "");
            } else {
              add.legislativeDistrict = str.replace(
                "State House District ",
                ""
              );
            }

            // alert(add.legislativeDistrict);
            /*  switch (newString) {

              case "General Assembly District":
                add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("General Assembly District ", "");
                break;
              case "Assembly District":
                add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("Assembly District ", "");
                break;
              case "State House District":
                add.legislativeDistrict = address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("State House District ", "");
                break;

              default:
                break;
            }*/
          }

          var finalLoc = JSON.parse($scope.loc);
          //MyService.ConsoleLog(" $scope.address: ", finalLoc);

          add.loca = {
            type: "Point",
            coordinates: [finalLoc.longitude, finalLoc.latitude],
          };

          // alert("got");

          //MyService.ConsoleLog(">>>>>>>>>> add >>>>>>>>>>>: ", address);
          postobj.mailingAddress = $scope.mailing_Address
            ? $scope.mailing_Address
            : "";

          postobj.address = JSON.stringify(add);
          //MyService.ConsoleLog("postobj.address: ", postobj);

          MyService.updateProfile(postobj).then(
            function (data) {
              setTimeout(function () {
                growl.success("Updated successfully.");
              }, 200);

              // $scope.setAddressNew();
              try {
                MyService.ConsoleLog(
                  "$scope.userAddress.flag",
                  $scope.userAddress.flag
                );
                if ($scope.userAddress.flag && $scope.userAddress.flag != "") {
                  $location.path("/userProfile");
                } else {
                  MyService.ConsoleLog("else >> ");
                }
              } catch (err) {
                console.log("err");

                if (window.localStorage.getItem("isFiveMinPage") != "") {
                  $location.path("/fiveCompare/skip_to_result#DisplayQ");
                }
              }
              $scope.closeThisDialog();
            },
            function (err) {
              console.log("err >> ", err);
              growl.error("Something went wrong.");
            }
          );
        }
      };

      $scope.onVoterDistChange();
    },
  ]);
