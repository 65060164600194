'use strict';
angular.module('myApp.forum', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/forum/:categoryId/:type', {
      templateUrl: 'app/forum/forum.html',
      controller: 'forumCtrl'
    });
  }])
  .controller('forumCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'ngDialog', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, ngDialog, growl) {
    MyService.ConsoleLog("** forumCtrl ** :", $routeParams);
    $rootScope.welcome = false;
    $scope.categoryId = $routeParams.categoryId;
    $scope.type = $routeParams.type;
    $scope.subCategories = [];

    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;


    /**
     * Redirect
     */
    $scope.goto = function () {
      $location.path('/verifyBy');
    };

    /**
     * Close it
     */
    $scope.closeIt = function () {
      $scope.PopDialog.close();
    };
    $scope.getmyData = function () {
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: tokenService.getUserId(),
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          // MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          $scope.verify = $scope.userProfile.verifiedUser;
          // MyService.ConsoleLog("welcome", $scope.verify);
          // console.log("$scope.userDetails", $scope.userDetails.userData.address);
          if (!$scope.userProfile.verifiedUser) {
            $scope.showCon = true;
          }
          if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
            // $scope.getPopUp();
            if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
              if ($scope.userProfile.userType == 'politician') {
                $scope.showCon = true;
                $scope.showBoth = true;
              } else if (!$scope.userProfile.isEmailVerified) {
                $scope.showEm = true;
              }
            } else if (!$scope.userProfile.isEmailVerified) {
              // if ($scope.userProfile.userType != 'politician') {
              $scope.showEm = true;
              // }
            } else {
              if ($scope.userProfile.userType != 'politician') {
                $scope.showEm = true;
              }
            }
            if ($scope.userProfile.userType == 'politician') {
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            } else if ($scope.userProfile.userType == 'advocate' && $scope.userProfile.isEmailVerified != true) {
              // alert('cal');
              MyService.ConsoleLog("$scope.userProfile", $scope.userProfile.isEmailVerified);
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            }
          }
          // }


        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    }

    if (tokenService.getUserId()) {
      $scope.getmyData();
    }





    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
      if (!$scope.userData.isemailverified) {
        // $scope.getPopUp();
      }
    }
    $scope.DiscussionData = [];
    $scope.ActiveThreads = [];
    $scope.HotTopics = [];
    var token = apiCall.getToken();

    $scope.search = {
      text: ''
    };

    $scope.previouscategory = [];

    //MyService.ConsoleLog("forum page ", window.location.getItem("locationOption"));


    function getCategory(params, type) {
      // MyService.ConsoleLog("$scope.previouscatId : one ", $scope.previouscatId);
      MyService.getCategoryList(params).then(function (payload) {
          var subcatdata = payload;
          //MyService.ConsoleLog("subcatdata : ", subcatdata);
          if (type == "Prev") {
            $scope.previouscategory = payload;
          } else {
            $scope.category = payload;
          }
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });

    }

    if ($scope.type == "home") {
      $rootScope.allcategories = [];
      $rootScope.allcategories.length = 0;
      window.localStorage.removeItem("VoteWiseCategory");
      window.localStorage.setItem("VoteWiseParentCategory", $routeParams.categoryId);
      $rootScope.allcategories.push($routeParams.categoryId);
      window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
    } else {
      if (window.localStorage.getItem("VoteWiseCategory")) {
        $rootScope.allcategories = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));

        //MyService.ConsoleLog($rootScope.allcategories);
        //MyService.ConsoleLog(finallen);


        var idx = $rootScope.allcategories.indexOf($routeParams.categoryId);
        if (idx == -1) {
          var finallen = $rootScope.allcategories.length;
          if (finallen >= 1) {
            if (finallen > 1) {
              $scope.finaltype = "subcat";
            } else {
              $scope.finaltype = "home";

            }
            var chklen = finallen - 1;

            $scope.previouscatId = $rootScope.allcategories[chklen];
            //MyService.ConsoleLog("prev ID : ", $scope.previouscatId);
            getCategory($scope.previouscatId, "Prev");
          }
          $rootScope.allcategories.push($routeParams.categoryId);
          window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
        } else {

          var len = $rootScope.allcategories.length;
          var fidx = idx;
          var no = len - fidx;

          //MyService.ConsoleLog("len:" + len + "====idx:" + fidx + "====NO:" + no);
          $rootScope.allcategories.splice(fidx, no);
          //MyService.ConsoleLog($rootScope.allcategories);
          var finallen = $rootScope.allcategories.length;
          window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
          if (finallen >= 1) {
            if (finallen > 1) {
              $scope.finaltype = "subcat";
            } else {
              $scope.finaltype = "home";

            }
            var chklen = finallen - 1;
            $scope.previouscatId = $rootScope.allcategories[chklen];
            //MyService.ConsoleLog("prev ID : ", $scope.previouscatId);
            getCategory($scope.previouscatId, "Prev");
          }
        }
        //MyService.ConsoleLog("Got categories: ",$scope.previouscategory);
      }
    }



    $scope.getExtraData = function () {

      if ($scope.type != "subcat") {
        $rootScope.ExtraTopicData = [];
        MyService.extraDataUpdated($scope.userData).then(function (payload) {
            var responseLatest = payload;
            MyService.ConsoleLog("payload : ", payload);
            $rootScope.ExtraTopicData = responseLatest;
            if (responseLatest) {
              $scope.latestDataDateWise();
              if (responseLatest.MyDiscussion) {
                $scope.DiscussionData = responseLatest.MyDiscussion;
              }
              if (responseLatest.ActiveData) {
                $scope.ActiveThreads = responseLatest.ActiveData;
              }
              if (responseLatest.HotTopics) {
                $scope.HotTopics = responseLatest.HotTopics;
              }
            }
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });

      } else {
        var responseLatest = $rootScope.ExtraTopicData;
        if (responseLatest) {
          if (responseLatest.MyDiscussion) {
            $scope.DiscussionData = responseLatest.MyDiscussion;
          }
          if (responseLatest.ActiveData) {
            $scope.ActiveThreads = responseLatest.ActiveData;
          }
          if (responseLatest.HotTopics) {
            $scope.HotTopics = responseLatest.HotTopics;
          }
        }
      }
    }

    $scope.newData = {};

    $scope.latestDataDateWise = function () {

      MyService.latestDataDateWise({}).then(function (payload) {
        MyService.ConsoleLog("payload", payload);
        //alert("Here");
        $scope.newData = payload;

      }, function (errorPayload) {
        MyService.ConsoleLog("errorPayload", errorPayload);
        alert("Something went wrong");
      });
    }

    $scope.getData = function (subCategories, subcatid, sampledata) {

      if ($scope.userData) {
        var address = JSON.stringify($scope.userData.address);
      } else {
        var address = "";
      }
      // MyService.ConsoleLog("window.localStorage.getItem >>>>>>>>", JSON.parse(window.localStorage.getItem("locationOption")));
      var finalData = {
        "subcatId": subcatid,
        "option": window.localStorage.getItem("locationOption"),
        "address": address,
        "rootData": "N",
        "cateId": $scope.categoryId,
        "prevCatId": $scope.previouscatId
      };
      // MyService.ConsoleLog("post Data : ", finalData);
      MyService.getLatestTopics(finalData).then(function (payload) {
          subCategories.returndata = payload;
          $scope.subCategories.push(subCategories);
          MyService.ConsoleLog("$scope.subCategories", $scope.subCategories);
          // MyService.ConsoleLog(subCategories);
        },
        function (errorPayload) {
          //MyService.ConsoleLog(subCategories);
          subCategories.returndata = sampledata;
          $scope.subCategories.push(subCategories);
        });
    }


    /**
     * Send Verification mail
     */

    $scope.sendMail = function () {
      MyService.SendVerificationMail({
        verificationUrl: $rootScope.getVerificationUrl(),
        unsubscribeurl: $rootScope.getEmailUnsubscribeUrl()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        if (response.data.success) {
          growl.success("Please check your email");
        }
      }, function (err) {
        MyService.ConsoleLog("err", err);
      });
    };

    $scope.getRootData = function (subCategories, subcatid, sampledata) {
      //MyService.ConsoleLog("SubcatID : ", subcatid);
      window.localStorage.removeItem('previouscatId');
      window.localStorage.setItem('previouscatId', $scope.previouscatId);
      if ($scope.userData) {
        var address = JSON.stringify($scope.userData.address);
      } else {
        var address = "";
      }
      var finalData = {
        "subcatId": subcatid,
        "option": window.localStorage.getItem("locationOption"),
        "address": address,
        "rootData": "Y",
        "catId": $routeParams.categoryId,
        "prevCatId": $scope.previouscatId,
        "cateId": $scope.categoryId
      };
      //MyService.ConsoleLog("post Data getRootData : ", finalData);
      MyService.getLatestTopics(finalData).then(function (payload) {
          subCategories.returndata = payload;
          $scope.subCategories.push(subCategories);
        },
        function (errorPayload) {
          //MyService.ConsoleLog(subCategories);
          subCategories.returndata = sampledata;
          $scope.subCategories.push(subCategories);
        });
    }


    function getSubCategories(params) {

      var sampledata = {
        topic: '',
        createdby: '',
        createdOn: '',
        brainstorming: 0,
        solution: 0,
        totalThread: 0,
        totalPost: 0
      };


      MyService.getSubCategoryList($routeParams.categoryId).then(function (payload) {
          var subcatdata = payload;
          //MyService.ConsoleLog("subcatdata: ", subcatdata);
          if (subcatdata.length <= 0) {
            //send the category itself
            MyService.getCategoryList($routeParams.categoryId).then(function (payload) {
              //MyService.ConsoleLog("payload: ",payload);
              subcatdata = [payload];
              angular.forEach(subcatdata, function (value, key) {
                var subCategories = value;
                var subcatId = value._id;
                MyService.ConsoleLog("<<<<<<<value >>>>>>", value.title);
                if (value.title == "General") {
                  $scope.getRootData(subCategories, value._id, sampledata);
                } else {
                  $scope.getData(subCategories, value._id, sampledata);
                }

              });
            }, function (err) {
              //MyService.ConsoleLog("Something went wrong.");
            });
          } else {
            angular.forEach(subcatdata, function (value, key) {
              var subCategories = value;
              //$scope.getData(subCategories, value._id, sampledata);
              MyService.ConsoleLog("<<<<<<<value >>>>>>", value.title);
              if (value.title == "General") {
                $scope.getRootData(subCategories, value._id, sampledata);
              } else {
                $scope.getData(subCategories, value._id, sampledata);
              }
            });
          }

        },
        function (errorPayload) {
          alert('Something went wrong ');
        });


    }



    $scope.gotoThread = function (data, subcatid) {

      var id = '';
      if (data.parentId) {
        id = data.parentId;
      } else if (data.parent) {
        id = data.parent;
      } else {
        if (data.id) {
          id = data.id;
        } else {
          id = data._id;
        }

      }
      var categoryId = $routeParams.categoryId;
      $rootScope.subcategoryId = subcatid;
      $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + id);
    }

    $scope.searchForum = function (data) {
      $location.path('forumSearch/' + data.text);
    }

    if ($routeParams.categoryId) {

      getCategory($routeParams.categoryId, "Main");
      getSubCategories();
      //alert(token);
      if (token) {
        $scope.getExtraData();
      }
    }

    $scope.gotoFThread = function (id, catId, subcatid) {
      $location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
    }
    $scope.gotoForumTopic = function (obj) {
      if (obj.parent && obj.parent != "") {
        $scope.gotoFThread(obj.parent, obj.parentCat[0], obj.subCategories[0]);
      } else {
        $scope.gotoFThread(obj._id, obj.parentCat[0], obj.subCategories[0]);
      }
    }

    $scope.gotoForumT = function (obj) {
      MyService.ConsoleLog("obj", obj);
      var subcat = $scope.getSubCat(obj.parentCat[0]);
      MyService.ConsoleLog("subcat:::", subcat);
      if (obj.parent && obj.parent != "") {
        $scope.gotoFThread(obj.parent, obj.parentCat[0], subcat[0].subCategories[0]._id);
      } else {
        $scope.gotoFThread(obj._id, obj.parentCat[0], subcat[0].subcategories[0]._id);
      }

      //"#!/forumthread/"+obj.parentcat[0]+"/"+subcat+"/"+obj._id;
    }
  }]);
