'use strict';
angular
  .module('myApp.findFollowerMatch', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/findFollowerMatch/:flag?', {
        templateUrl: 'app/findFollowerMatch/findFollowerMatch.html',
        controller: 'findFollowerMatchCtrl',
      });
      $routeProvider.when('/findFollowerMatch/:catid', {
        templateUrl: 'app/findFollowerMatch/findFollowerMatch.html',
        controller: 'findFollowerMatchCtrl',
      });
    },
  ])
  .controller('findFollowerMatchCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    '$q',
    '$timeout',
    'tokenService',
    'MyService',
    '$anchorScroll',
    '$cookies',
    'ngDialog',
    'growl',
    '$window',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      $anchorScroll,
      $cookies,
      ngDialog,
      growl,
      $window
    ) {
      $scope.page = 1;
      $scope.maxRecordsPerPg = 10000;
      $scope.showPoliAnswerDetails = false;
      $scope.showUserAnswerDetails = false;
      $scope.noDataFound = false;
      $scope.showcomparison = false;
      $scope.showComment = false;
      $scope.unComment = false;
      $scope.showCurr = false;
      $scope.loaders = false;


      if (tokenService.getUserId()) {
        $scope.userId = tokenService.getUserId();
        console.log('$scope.userId: ', $scope.userId);
      }
      window.localStorage.removeItem('isFindFollowers');
      window.localStorage.setItem('isFindFollowers', true);
      console.log('window.localStorage.getItem :', window.localStorage.getItem('inviterId'));
      if (window.localStorage.getItem('inviterId')) {
        if (window.localStorage.getItem('inviterInfo')) {
          $scope.inviterInfo = JSON.parse(window.localStorage.getItem('inviterInfo'));
          MyService.ConsoleLog("$scope.inviterInfo", $scope.inviterInfo);
          if ($scope.inviterInfo && $scope.inviterInfo.nameOnBallot) {
            $scope.inviterName = $scope.inviterInfo.nameOnBallot;
          } else if ($scope.inviterInfo && $scope.inviterInfo.title) {
            $scope.inviterName = $scope.inviterInfo.title;
          } else if ($scope.inviterInfo) {
            $scope.inviterName = $scope.inviterInfo.username;
          }
          $scope.isShare = true;
        }
        $scope.inviterId = window.localStorage.getItem('inviterId');

      }

      if ($cookies.get('showLongPopUp')) {
        var msg = $cookies.get('showLongPopUp');

        var modal = ngDialog.openConfirm({
          template: '<p>' +
            msg +
            '</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">OK</button>\
                        <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">Cancel</button>\
                    </div>',
          plain: true,
          closeByDocument: false,
          width: "70%"
        });
        modal.then(
          function fullfilled(data) {
            if (data == 1) {
              // MyService.ConsoleLog("yes")
              $cookies.remove('showLongPopUp');
            } else {
              $cookies.remove('showLongPopUp');

            }
          },
          function rejected(data) {

          }
        );
      }

      var param = {};
      param.page = $scope.page;
      param.max_records = $scope.maxRecordsPerPg;

      if ($routeParams.flag) {
        $scope.flag = true;
        // alert('one');
      } else {
        $scope.flag = false;
        // alert('two');
      }

      if (window.localStorage.getItem('quesData')) {

        $rootScope.queData = window.localStorage.getItem('quesData');
        // MyService.ConsoleLog("main Data>>", JSON.parse($rootScope.queData));
      }
      $scope.showGrpFollow = false;
      /**
       * Remove values from localStorage on page load
       */
      window.localStorage.removeItem('comOfPolitician');
      window.localStorage.removeItem('comOfVoter');
      window.localStorage.removeItem('comOfAdv');
      window.localStorage.removeItem('comOfGuest');
      window.localStorage.removeItem('poliAr');
      window.localStorage.removeItem('voter');
      window.localStorage.removeItem('adv');
      window.localStorage.removeItem('unVerify');
      window.localStorage.removeItem('cuUser');


      if (tokenService.getUserId()) {
        // alert('sssss');
        $scope.userData = JSON.parse(tokenService.getUserData());
        MyService.ConsoleLog("$scope.userData >", $scope.userData);
        MyService.ConsoleLog("$scope.userData >", $scope.userData.address);


        // $rootScope.tempUserAddress = "";
        $rootScope.tempUserAddress = $scope.userData.address;
        console.log('$rootScope.tempUserAddress >>> ', $rootScope.tempUserAddress);
        // }

        // var nAddobj = new Object;

        // nAddobj.state = $scope.mainAddress.state.state;
        // nAddobj.stateFips = $scope.mainAddress.state.stateFips;
        // nAddobj.statePostalCode = $scope.mainAddress.state.statePostalCode;

        // nAddobj.county = $scope.mainAddress.county ? $scope.mainAddress.county.county : "";
        // nAddobj.countyFips = $scope.mainAddress.county ? $scope.mainAddress.county.countyFips : "";
        // if ($scope.mainAddress.place && $scope.mainAddress.place != "") {
        //   nAddobj.place = $scope.mainAddress.place.place;
        //   nAddobj.placeFips = $scope.mainAddress.place.placeFips;
        // }

        // nAddobj.federalCongrassionalDist = $scope.mainAddress.federalCongrassionalDist ? $scope.mainAddress.federalCongrassionalDist.CD115FP : "";

        // nAddobj.stateSenateDistrict = $scope.mainAddress.stateSenateDistrict ? $scope.mainAddress.stateSenateDistrict.SLDUST : "";

        // nAddobj.legislativeDistrict = $scope.mainAddress.stateHouseOFRepresentativeDistrict ? $scope.mainAddress.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("State House District ", "") : "";

        // nAddobj.schoolDistrict = $scope.mainAddress.unifiedSchoolDistrictName ? $scope.mainAddress.unifiedSchoolDistrictName.NAME : '';

        // nAddobj.schoolDistrictFips = $scope.mainAddress.unifiedSchoolDistrictName ? $scope.mainAddress.unifiedSchoolDistrictName.UNSDLEA : '';
        // console.log("nAddobj > ", nAddobj);
        // $rootScope.tempUserAddress = nAddobj;
      } else {
        if (window.localStorage.getItem('tempUserAddress')) {
          $rootScope.tempUserAddress = window.localStorage.getItem('tempUserAddress');

          // MyService.ConsoleLog("$rootScope.tempUserAddress >>", $rootScope.tempUserAddress);

          $scope.mainAddress = JSON.parse(angular.copy($rootScope.tempUserAddress));
          // console.log("mainAddress >>> ", $scope.mainAddress);
          var nobj = new Object();

          nobj.state = $scope.mainAddress.state.state;
          nobj.stateFips = $scope.mainAddress.state.stateFips;
          nobj.statePostalCode = $scope.mainAddress.state.statePostalCode;

          nobj.county = $scope.mainAddress.county ? $scope.mainAddress.county.county : '';
          nobj.countyFips = $scope.mainAddress.county ? $scope.mainAddress.county.countyFips : '';
          if ($scope.mainAddress.place && $scope.mainAddress.place != '') {
            nobj.place = $scope.mainAddress.place.place;
            nobj.placeFips = $scope.mainAddress.place.placeFips;
          }

          nobj.federalCongrassionalDist = $scope.mainAddress.federalCongrassionalDist ?
            $scope.mainAddress.federalCongrassionalDist.CD115FP :
            '';

          nobj.stateSenateDistrict = $scope.mainAddress.stateSenateDistrict ?
            $scope.mainAddress.stateSenateDistrict.SLDUST :
            '';

          nobj.legislativeDistrict = $scope.mainAddress.stateHouseOFRepresentativeDistrict ?
            $scope.mainAddress.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
              'State House District ',
              ''
            ) :
            '';

          nobj.schoolDistrict = $scope.mainAddress.unifiedSchoolDistrictName ?
            $scope.mainAddress.unifiedSchoolDistrictName.NAME :
            '';

          nobj.schoolDistrictFips = $scope.mainAddress.unifiedSchoolDistrictName ?
            $scope.mainAddress.unifiedSchoolDistrictName.UNSDLEA :
            '';
          // console.log("nobj > ", nobj);
          $rootScope.tempUserAddress = nobj;
          MyService.ConsoleLog(' $rootScope.tempUserAddress >', $rootScope.tempUserAddress);
        }
      }


      $scope.changeColor = function (nper, inx, cur_ans) {

        // MyService.ConsoleLog("inx", inx);
        // MyService.ConsoleLog("cur_ans", cur_ans);

        var arrOfCol = {
          "5": "#990000",
          "4": "#cc3300",
          "3": "#ff9933",
          "2": "#ffff00",
          "1": "#99cc00",
          "0": "#00d900"
        };
        var cdiff = Math.abs(inx - cur_ans);

        // console.log("arrOfCol > ", arrOfCol);
        $scope.gr = null;
        switch (true) {
          case (nper >= 100):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }

            // MyService.ConsoleLog("$scope.gr >>", arrOfCol.nper);
            break;
          case (nper >= 80):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 60):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 40):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 20):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 0):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;

          default:
            break;
        }
        // MyService.ConsoleLog(" $scope.gr", $scope.gr);
        return $scope.gr;

      };


      /** Return class of background color */

      $scope.getComapreClass = function (match, i) {
        //MyService.ConsoleLog("match: ", match);
        /*if(match == 100)
          return 'ag-green';
      if(match == 0)
          return 'ag-red';
      if(match > 0 && match <100)
          return 'ag-dg'+i;*/
        if (match >= 100) return 'ag-dg1';
        if (match >= 80) return 'ag-dg2';
        if (match >= 60) return 'ag-dg3';
        if (match >= 40) return 'ag-dg4';
        if (match >= 20) return 'ag-dg5';
        if (match >= 0) return 'ag-dg6';

        return 'ag-dg';
      };

      // $scope.getRegisterCompare = function() {
      //   MyService.ConsoleLog("$rootScope.guestMatch >", $rootScope.guestMatch);
      //   MyService.getRegisterUsersOfNation({ "quesArray": $rootScope.guestMatch }).then(function(response) {
      //     MyService.ConsoleLog("Response >>", response);
      //     $scope.currentIndex = 0;
      //     var quedata = response.data.data;

      //     var temp_uids = quedata[0].voterAnswer.map(function(item) {
      //       // console.log("item >>", item);
      //       // console.log("item >>", JSON.parse(item));

      //       if (item) {
      //         console.log("item >>", item);
      //         return item.toString();
      //       }

      //     });

      //     // temp_uids = temp_uids.filter(function(item, pos) {
      //     //   return temp_uids.indexOf(item) == pos;
      //     // });

      //     MyService.ConsoleLog("temp_uids >>", temp_uids);

      //     $scope.compareRegisterGuestUsers({
      //       "userIds": temp_uids,
      //       "guest": $rootScope.guestMatch,
      //       "userData": $scope.userId
      //     })

      //   }, function(err) {
      //     MyService.ConsoleLog("Error >>", err);
      //   });
      // }


      /**
       * This function calls on page load
       * fetch data of user's area politician ,voters and advocates
       */

      $scope.getLocalPoliticians = function (id) {
        MyService.ConsoleLog("window.localStorage.getItem('comData')", window.localStorage.getItem('comData'));
        MyService.ConsoleLog("Id", id);
        if (!id) {
          $scope.loaders = true;

        } else {
          $scope.loadernew = true;

        }

        // MyService.ConsoleLog(" welcome to getLocalPoliticians ", $rootScope.queData);
        $scope.statusGetLocalPoliticians = 1;
        $scope.page += 1;
        $scope.loader = true;
        // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress);
        // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress.state)
        var extradata = new Object();
        if ($rootScope.tempUserAddress) {
          if ($rootScope.tempUserAddress.state && $rootScope.tempUserAddress.state != '') {
            extradata.statePostalCode = $rootScope.tempUserAddress.statePostalCode;
          }
          if ($rootScope.tempUserAddress.county && $rootScope.tempUserAddress.countyFips) {
            $rootScope.tempUserAddress.FinalData = JSON.stringify(
              $rootScope.tempUserAddress.countyFips
            );
            extradata.county = $rootScope.tempUserAddress.county;
          }
          if (
            $rootScope.tempUserAddress.countySubdivision &&
            $rootScope.tempUserAddress.countySubdivisionFips
          ) {
            $rootScope.tempUserAddress.FinalData = JSON.stringify(
              $rootScope.tempUserAddress.countySubdivisionFips
            );
            extradata.countySubdivision = $rootScope.tempUserAddress.countySubdivision;
          }
          if ($rootScope.tempUserAddress.place && $rootScope.tempUserAddress.placeFips) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.place = $rootScope.tempUserAddress.place;
          }
          if (
            $rootScope.tempUserAddress.voterDistrict &&
            $rootScope.tempUserAddress.voterDistrictFips
          ) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
          }
          if ($rootScope.tempUserAddress.federalCongrassionalDist) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            MyService.ConsoleLog(
              'federalCongrassionalDist  :',
              $rootScope.tempUserAddress.federalCongrassionalDist
            );
            extradata.federalCongrassionalDist =
              $rootScope.tempUserAddress.federalCongrassionalDist;
          }
          if ($rootScope.tempUserAddress.stateSenateDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.stateSenateDistrict = $rootScope.tempUserAddress.stateSenateDistrict;
          }
          if ($rootScope.tempUserAddress.legislativeDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.legislativeDistrict = $rootScope.tempUserAddress.legislativeDistrict;
          }
          if ($rootScope.tempUserAddress.schoolDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.schoolDistrict = $rootScope.tempUserAddress.schoolDistrict;
          }
        }
        MyService.ConsoleLog('extradata : >>>>>>>>>> ', JSON.stringify(extradata));
        MyService.getAllLocalPoli({
          max_records: 100,
          page: $scope.page,
          extradata: JSON.stringify(extradata),
        }).then(
          function (response) {
            if (response.data.success) {
              setTimeout(function () {
                $scope.$apply(function () {
                  if (response.data.data.length > 0) {
                    MyService.getAdvocates().then(function (payload) {
                      MyService.ConsoleLog("Response >", payload);
                      var ndata = response.data.data;
                      var wdata = ndata.concat(payload.data.data);

                      if (window.localStorage.getItem('inviterId') || $cookies.get('quizTakerId') || $cookies.get('quizTakerIdsArr')) {
                        $scope.userAreaPolitician = [];
                        if (window.localStorage.getItem('inviterId') && !$cookies.get('quizTakerIdsArr')) {
                          $scope.userAreaPolitician.push(window.localStorage.getItem('inviterId'));
                          var param = JSON.stringify({
                            usersIds: $scope.userAreaPolitician,
                            cur_userId: $scope.userId,
                            questions: $rootScope.queData
                          });

                          // MyService.ConsoleLog("apra >", param);
                          // window.localStorage.removeItem('comData');
                          window.localStorage.setItem('comData', param);
                        } else if ($cookies.get('quizTakerIdsArr') || $cookies.get('quizTakerId')) {
                          // MyService.ConsoleLog("check now yogesh >>>>>>>>>>", JSON.parse($cookies.get('quizTakerIdsArr')));
                          if ($cookies.get('quizTakerId')) {
                            $scope.userAreaPolitician.push($cookies.get('quizTakerId'));
                          } else {
                            $scope.userAreaPolitician = JSON.parse($cookies.get('quizTakerIdsArr'));
                          }

                          if ($scope.userAreaPolitician && $scope.userAreaPolitician.length > 1) {
                            $scope.showGrpFollow = true;
                          } else {
                            $scope.showGrpFollow = false;
                          }
                          var param = JSON.stringify({
                            usersIds: $scope.userAreaPolitician,
                            cur_userId: $scope.userId,
                            questions: $rootScope.queData
                          });
                          window.localStorage.setItem('comData', param);
                        }

                      }
                      // else {
                      //   $scope.userAreaPolitician = wdata.map(function (ques) {
                      //     return ques._id;
                      //   });
                      //   var param = JSON.stringify({
                      //     usersIds: $scope.userAreaPolitician,
                      //     cur_userId: $scope.userId,
                      //     questions: $rootScope.queData
                      //   });

                      //   // MyService.ConsoleLog("apra >", param);
                      //   // window.localStorage.removeItem('comData');
                      //   window.localStorage.setItem('comData', param);
                      // }
                      MyService.ConsoleLog(" $scope.userAreaPolitician >", $scope.userAreaPolitician);

                      var indx;
                      if (id && id != "") {
                        indx = id;
                        MyService.ConsoleLog("Inds >>>>>>>>", indx);
                      } else {
                        $scope.currentIndex = 0;
                        indx = $scope.currentIndex;
                        MyService.ConsoleLog(" $scope.currentIndex >", indx);
                      }
                      // alert('called');

                      var main = JSON.parse($rootScope.queData);
                      MyService.ConsoleLog(' $scope.quData :', main[indx]);
                      if ($cookies.get('quizTakerId')) {
                        $scope.compareRegisterGuestUsers({
                          userIds: JSON.stringify($scope.userAreaPolitician),
                          guest: JSON.stringify(main[indx]),
                          userData: $scope.userId,
                          quizTakerId: $cookies.get('quizTakerId')
                        });
                      } else if ($cookies.get('quizTakerIdsArr')) {
                        $scope.compareRegisterGuestUsers({
                          userIds: JSON.stringify($scope.userAreaPolitician),
                          guest: JSON.stringify(main[indx]),
                          userData: $scope.userId,
                          quizTakerIdsArr: $cookies.get('quizTakerId')
                        });
                      }

                    }, function (err) {
                      MyService.ConsoleLog("Error >>", err);
                    });
                  } else {
                    $scope.loader = false;
                    var message =
                      'None of your politicians have answered the questions yet. Contact them and ask them to fill it out.';
                    var modal = ngDialog.openConfirm({
                      template: '<p>' +
                        message +
                        '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                      plain: true,
                    });

                    modal.then(
                      function fullfilled(data) {
                        //MyService.ConsoleLog("fullfilled: ", data);
                        if (data == 1) {
                          $scope.postonFacebook();
                        } else {
                          if ($rootScope.userRole) {
                            $location.path('/snapshot');
                          } else {
                            $location.path('/signin');
                          }
                        }
                      },
                      function rejected(data) {
                        //MyService.ConsoleLog("rejected: ", data);
                      }
                    );
                  }
                });
              }, 100);
            } else {
              $scope.page -= 1;
              $scope.statusGetLocalPoliticians = 3;
              if (typeof response.data.error == 'string') growl.error(response.data.error);
              else growl.error('Something went wrong');
            }
          },
          function (err) {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            $scope.loader = false;
            growl.error(err);
          }
        );
      };

      /**End of getLocalPoliticians */

      if (tokenService.getUserId()) {
        $scope.getLocalPoliticians();
      } else {
        MyService.getAllUsersOfNation({
          quesArray: $rootScope.guestMatch,
        }).then(
          function (response) {
            // MyService.ConsoleLog("Response >>", response);

            $scope.currentIndex = 0;
            var quedata = response.data.data;
            MyService.ConsoleLog('quedata >>', quedata);
            var temp_uids = quedata[0].voterAnswer.map(function (item) {
              // console.log("item >>", item);
              // console.log("item >>", JSON.parse(item));

              if (item) {
                // console.log("item >>", item);
                return item.toString();
              }
            });
            // MyService.ConsoleLog("temp_uids >",temp_uids);
            $scope.getCompareGuest(temp_uids);
          },
          function (err) {
            MyService.ConsoleLog('Error >>', err);
          }
        );
      }

      /**
       * This function redirects to show answers page
       */

      $scope.showAnswers = function (ans, type, qId, chAns, ind) {
        MyService.ConsoleLog("Ind", ind);
        MyService.ConsoleLog("qId", qId);

        if (!ind) {
          ind = 0;
        }
        if (chAns) {
          if (!chAns[ind + 1]) {
            // alert("This");
            growl.error("No users to see here");
          } else {
            // if (chAns != "") {
            //   alert('dahdaj');
            // }
            MyService.ConsoleLog("Type >>", type);
            MyService.ConsoleLog("qId >>", qId);
            MyService.ConsoleLog("chAns >>", chAns);

            $location.path('/showAnswers/' + type + '/' + ans + '/' + qId + '/' + chAns);
          }
        } else {
          // alert('not Found');
          switch (type) {
            case 'politician':
              growl.error('None of politicians of your area answered for this question');
              break;
            case 'voter':
              growl.error('None of voter of your area answered for this question');
              break;
            case 'advocate':
              growl.error('None of organizations of your area answered for this question');
              break;

            default:

              break;
          }
        }
      };

      /**
       * This function show the details of answered questions
       * showAnswersDetail
       */

      $scope.showAnswersDetail = function (para) {
        MyService.ConsoleLog('para >', para);

        switch (para) {
          case 'politician':

            // if ($scope.showGrpFollow) {
            //   // MyService.ConsoleLog("$scope.arrayOfCom", $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id].length);
            //   if ($scope.arrayOfCom && $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id]) {
            //     if ($scope.advComArr && $scope.advComArr[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id] = $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id].concat($scope.advComArr[$scope.questions[$scope.currentIndex]._id]);
            //     }
            //     if ($scope.voterComArr && $scope.voterComArr[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id] = $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id].concat($scope.voterComArr[$scope.questions[$scope.currentIndex]._id]);
            //     }
            //     // console.log('$scope.arrayOfCom  :', $scope.arrayOfCom);
            //     var newObjs = JSON.stringify({
            //       poli: $scope.arrayOfCom,
            //       qId: $scope.questions[$scope.currentIndex]._id
            //     });
            //     MyService.ConsoleLog("newObj", JSON.parse(newObjs));
            //     window.localStorage.setItem('poliAr', newObjs);
            //   } else if ($scope.voterComArr && $scope.voterComArr[$scope.questions[$scope.currentIndex]._id]) {
            //     if ($scope.advComArr && $scope.advComArr[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.voterComArr[$scope.questions[$scope.currentIndex]._id] = $scope.voterComArr[$scope.questions[$scope.currentIndex]._id].concat($scope.advComArr[$scope.questions[$scope.currentIndex]._id]);
            //     }

            //     if ($scope.arrayOfCom && $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.voterComArr[$scope.questions[$scope.currentIndex]._id] = $scope.voterComArr[$scope.questions[$scope.currentIndex]._id].concat($scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id]);
            //     }
            //     var vObj = JSON.stringify({
            //       vot: $scope.voterComArr,
            //       qId: $scope.questions[$scope.currentIndex]._id
            //     });
            //     window.localStorage.setItem('voter', vObj);
            //   } else if ($scope.advComArr) {

            //     if ($scope.voterComArr && $scope.voterComArr[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.advComArr[$scope.questions[$scope.currentIndex]._id] = $scope.advComArr[$scope.questions[$scope.currentIndex]._id].concat($scope.voterComArr[$scope.questions[$scope.currentIndex]._id]);
            //     }

            //     if ($scope.arrayOfCom && $scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id]) {
            //       $scope.advComArr[$scope.questions[$scope.currentIndex]._id] = $scope.advComArr[$scope.questions[$scope.currentIndex]._id].concat($scope.arrayOfCom[$scope.questions[$scope.currentIndex]._id]);
            //     }

            //     var advo = JSON.stringify({
            //       adv: $scope.advComArr,
            //       qId: $scope.questions[$scope.currentIndex]._id
            //     });
            //     window.localStorage.setItem('adv', advo);
            //   }
            // } else {
            var newObj = JSON.stringify({
              poli: $scope.arrayOfCom,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            MyService.ConsoleLog("newObj", newObj);
            window.localStorage.setItem('poliAr', newObj);
            // }
            $location.path('/followersGroupAnswer');
            // $scope.showPoliAnswerDetails = true;
            break;
          case 'unRegister':
            var unObj = JSON.stringify({
              unVeri: $scope.unVeriComm,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('unVerify', unObj);
            $location.path('/followersGroupAnswer');
            // $scope.showUserAnswerDetails = true;
            break;
          case 'voter':
            var vObj = JSON.stringify({
              vot: $scope.voterComArr,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('voter', vObj);
            $location.path('/followersGroupAnswer');
            // $scope.showPoliAnswerDetails = true;
            break;
          case 'advocate':
            var advo = JSON.stringify({
              adv: $scope.advComArr,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('adv', advo);
            $location.path('/followersGroupAnswer');
            // $scope.showPoliAnswerDetails = true;
            break;
          default:

            break;
        }

        // if (para == 'politician') {
        //   $scope.showPoliAnswerDetails = true;
        // } else {
        //   $scope.showUserAnswerDetails = true;
        // }
      };

      /**End Of showAnswersDetail */

      $scope.getCompareGuest = function (params) {
        // MyService.ConsoleLog("params >", params);
        console.log("$rootScope.guestMatch", $rootScope.guestMatch);
        MyService.compareGuest({
          comparewith: params,
          guest: $rootScope.guestMatch
        }).then(
          function (payload) {
            if (payload.data.success) {
              MyService.ConsoleLog('CompareWithAllPolitcians >> ', payload.data.data);
              $scope.emailRegistered = payload.data.data.agrriOfEmail;
              $scope.unVerified = payload.data.data.aggriOfUnVerified;
              $scope.emailImpo = payload.data.data.aggriOfEmailImportance;
              $scope.unVeriImpo = payload.data.data.aggriUnVerifiedImportance;
              // $scope.questions = payload.data.data.qdata;
              $scope.questions = JSON.parse($rootScope.queData);
              MyService.ConsoleLog('$scope.questions ONE>>>', $scope.questions);
              $scope.FBCompareList = payload.data.data.qdata;
              $scope.ansCount = payload.data.data.ansCount;
              $scope.unCount = payload.data.data.unCount;
              $scope.impCount = payload.data.data.impCount;
              $scope.unImpCount = payload.data.data.unImpCount;
              $scope.flagOfUnVerified = payload.data.data.flagOfUnVerified;
              // MyService.ConsoleLog('ansCount >', $scope.impCount);
              $scope.globalFlag = payload.data.data.checkFlag;
              $scope.arrayOfCom = payload.data.data.commArr;
              $scope.unVeriComm = payload.data.data.unVeriComm;
              MyService.ConsoleLog("Object.keys(payload.data.data.agrriOfEmail).length", Object.keys(payload.data.data.agrriOfEmail).length);
              MyService.ConsoleLog("aggriOfUnVerified", Object.keys(payload.data.data.aggriOfUnVerified).length);
              if (Object.keys(payload.data.data.agrriOfEmail).length == 0 && Object.keys(payload.data.data.aggriOfUnVerified).length == 0 && payload.data.data.agrriOfEmail.constructor == Object && payload.data.data.aggriOfUnVerified.constructor == Object) {
                // alert("called");
                var message = "'”Congratulations!!  You are the first person to take this quiz! ";
                var modal = ngDialog.openConfirm({
                  template: '<p>' +
                    message +
                    '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                  plain: true,
                });
                modal.then(
                  function fullfilled(data) {
                    //MyService.ConsoleLog("fullfilled: ", data);
                    if (data == 0) {
                      // if (tokenService.getUserRole() == 'voter') {
                      $location.path('/learnHome');
                      // } else if (tokenService.getUserRole() == 'politician') {
                      //   $location.path('/winVoters');
                      // }

                    }
                  },
                  function rejected(data) {
                    //MyService.ConsoleLog("rejected: ", data);
                  }
                );
              }
              // if () {
              //   alert("second");
              // }
              // MyService.ConsoleLog(' $scope.unVeriComm >', $scope.unVeriComm);
              // MyService.ConsoleLog('$scope.flagOfUnVerified >', $scope.flagOfUnVerified);
              // MyService.ConsoleLog(' $scope.globalFlag >', $scope.globalFlag);

              $scope.flag = true;
              if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
                $scope.FBunWeighted = payload.data.data.unweightedMatch;
                $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
                $scope.fbmultiplier = payload.data.data.fbmultiplier;
                $scope.answeredQuestions = payload.data.data.answeredQuestions;
                $scope.fbqlen = payload.data.data.qlen;
                $scope.AllUsers = payload.data.data.AllUsers;

                var mainData = JSON.stringify(payload.data.data);
                // MyService.ConsoleLog("mainData >>", mainData);
                window.localStorage.setItem('Data', mainData);
                //$scope.qData = payload.data.data.answeredQuestions;
              } else {
                var message = "'”Congratulations!!  You are the first person in your area to take this quiz! ";
                var modal = ngDialog.openConfirm({
                  template: '<p>' +
                    message +
                    '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                  plain: true,
                });
                //alert("Looks like your friends have not yet answered the questions.");
                // MyService.ConsoleLog("No politicians found, check incumbent");
                /*MyService.compareFiveMinAnswers({
              "userdata": JSON.stringify($rootScope.fiveMatch),
              "comparewith": JSON.stringify($scope.userAreaPolitician),
              type: "incumbent"
            }).then(function(payload) {
                if (payload.data.success) {
                  MyService.ConsoleLog("incumbent data :> ", payload.data.data);
                  $scope.questions = payload.data.data.qdata;
                  $scope.FBCompareList = payload.data.data.qdata;
                  if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
                    $scope.FBunWeighted = payload.data.data.unweightedMatch;
                    $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
                    $scope.fbmultiplier = payload.data.data.fbmultiplier;
                    $scope.answeredQuestions = payload.data.data.answeredQuestions;
                    $scope.fbqlen = payload.data.data.qlen;
                    $scope.AllUsers = payload.data.data.AllUsers;
                  } else {
                    // MyService.ConsoleLog("No politicians and incumbent found, check voters");
                    $scope.hideName = true;
                    // Show only popup if no politicians and incubents
                    var message = "None of your politicians have answered the questions yet. Contact them and ask them to fill it out.";
                    var modal = ngDialog.openConfirm({
                      template: '<p>' + message + '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                      \
                  </div>',
                      plain: true
                    });

                    modal.then(function fullfilled(data) {
                      //MyService.ConsoleLog("fullfilled: ", data);
                      if (data == 1) {
                        $scope.postonFacebook();
                      } else {
                        if ($rootScope.userRole) {
                          $location.path('/snapshot');
                        } else {
                          $location.path('/signin');
                        }
                      }
                    }, function rejected(data) {
                      //MyService.ConsoleLog("rejected: ", data);
                    });
                    return;
                  }
                }
              },
              function(errorpayload) {
                MyService.ConsoleLog(errorpayload);
              });*/
              }
            } else {
              $scope.showAlert = true;
              // alert("Something went wrong.");
              $scope.loaders = false;
            }
          },
          function (err) {
            MyService.ConsoleLog('Error >', err);
          }
        );
      };
      $scope.loadernew = false;

      /** This function fetch comparison results using
       ** @ Params @
       ** guest user data and politician data
       **/

      $scope.compareRegisterGuestUsers = function (params) {

        MyService.ConsoleLog('Params >>', params);

        MyService.compareRegisterGuestUsers(params).then(
          function (payload) {
            MyService.ConsoleLog('Response >>>', payload);

            if (payload.data.success) {
              $scope.loadernew = false;
              $scope.loaders = false;
              if (window.localStorage.getItem('inviterInfo')) {
                $scope.inviterData = JSON.parse(window.localStorage.getItem('inviterInfo'));
              }

              // MyService.ConsoleLog("CompareWithAllPolitcians >> ", payload.data.data.qdata);
              // if (Object.keys(payload.data.data.agrriOfEmail).length == 0 || Object.keys(payload.data.data.aggriOfVoter).length == 0 || Object.keys(payload.data.data.aggriOfAdvocate).length == 0 || Object.keys(payload.data.data.aggriOfUnVerified).length == 0) {
              $scope.emailRegistered = "";
              if ($scope.inviterData && $scope.inviterData.userType == 'politician' && (JSON.stringify(payload.data.data.agrriOfEmail) !=
                  JSON.stringify({}))) {
                $scope.emailRegistered = payload.data.data.agrriOfEmail;
              } else if ($cookies.get('quizTakerIdsArr') && (JSON.stringify(payload.data.data.agrriOfEmail) !=
                  JSON.stringify({}))) {
                $scope.emailRegistered = payload.data.data.agrriOfEmail;
              } else if (!$scope.inviterData && (JSON.stringify(payload.data.data.agrriOfEmail) !=
                  JSON.stringify({}))) {
                $scope.emailRegistered = payload.data.data.agrriOfEmail;
              }


              // $scope.unVerified = payload.data.data.aggriOfUnVerified;
              $scope.emailImpo = payload.data.data.aggriOfEmailImportance;

              $scope.questions = JSON.parse($rootScope.queData);
              MyService.ConsoleLog("JSON.parse($rootScope.queData)", JSON.parse($rootScope.queData));
              MyService.ConsoleLog('$scope.questions TWo >>>', $scope.questions);
              $scope.FBCompareList = payload.data.data.qdata;
              $scope.ansCount = payload.data.data.ansCount;

              $scope.impCount = payload.data.data.impCount;

              $scope.flagOfUnVerified = payload.data.data.flagOfUnVerified;
              // MyService.ConsoleLog('ansCount >', $scope.impCount);
              $scope.globalFlag = payload.data.data.checkFlag;
              $scope.arrayOfCom = payload.data.data.commArr;

              $scope.voter = "";
              if ($scope.inviterData && $scope.inviterData.userType == 'voter' && (JSON.stringify(payload.data.data.aggriOfVoter) !=
                  JSON.stringify({}))) {
                MyService.ConsoleLog("<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>", JSON.stringify(payload.data.data.aggriOfVoter));
                $scope.voter = payload.data.data.aggriOfVoter;
              } else if ($cookies.get('quizTakerIdsArr') && (JSON.stringify(payload.data.data.aggriOfVoter) !=
                  JSON.stringify({}))) {
                $scope.voter = payload.data.data.aggriOfVoter;
              } else if (!$scope.inviterData && JSON.stringify(payload.data.data.aggriOfVoter) != JSON.stringify({})) {
                $scope.voter = payload.data.data.aggriOfVoter;
                MyService.ConsoleLog("else", JSON.stringify(payload.data.data.aggriOfVoter));
              }
              MyService.ConsoleLog(">>>>>>>>>>>>>>>>>>>>", $scope.voter);
              $scope.voterImp = payload.data.data.aggriOfVoterImportance;
              $scope.voterAnsCount = payload.data.data.voterAnsCount;
              $scope.voterImpCount = payload.data.data.voterImpCount;
              $scope.flagOfVoter = payload.data.data.voterFlag;
              $scope.voterComArr = payload.data.data.voterComArr;
              $scope.advocate = "";
              if ($scope.inviterData && $scope.inviterData.title && (JSON.stringify(payload.data.data.aggriOfAdvocate) !=
                  JSON.stringify({}))) {
                $scope.advocate = payload.data.data.aggriOfAdvocate;
              } else if ($cookies.get('quizTakerIdsArr') && (JSON.stringify(payload.data.data.aggriOfAdvocate) !=
                  JSON.stringify({}))) {
                $scope.advocate = payload.data.data.aggriOfAdvocate;
              } else if (!$scope.inviterData && (JSON.stringify(payload.data.data.aggriOfAdvocate) !=
                  JSON.stringify({}))) {
                $scope.advocate = payload.data.data.aggriOfAdvocate;
              }

              $scope.advocateAns = payload.data.data.advocateObj;
              $scope.advocateImpAggri = payload.data.data.aggriOfAdvocateImportance;
              $scope.advocateimpObj = payload.data.data.advocateimpObj;
              $scope.advocateFlag = payload.data.data.advFlag;
              $scope.advComArr = payload.data.data.advcommArr;
              // $scope.questions = payload.data.data.qdata;
              // MyService.ConsoleLog("$scope.questions >>>", $scope.questions);
              $scope.FBCompareList = payload.data.data.qdata;
              $scope.flag = true;
              if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
                $scope.FBunWeighted = payload.data.data.unweightedMatch;
                $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
                $scope.fbmultiplier = payload.data.data.fbmultiplier;
                $scope.answeredQuestions = payload.data.data.answeredQuestions;
                $scope.fbqlen = payload.data.data.qlen;
                $scope.AllUsers = payload.data.data.AllUsers;
                var mainData = JSON.stringify(payload.data.data);
                // MyService.ConsoleLog("mainData >>", mainData);
                window.localStorage.setItem('Data', mainData);

                if (JSON.stringify($scope.emailRegistered) == JSON.stringify({}) && JSON.stringify($scope.voter) == JSON.stringify({}) && JSON.stringify($scope.advocate) == JSON.stringify({}) && JSON.stringify($scope.unVerified) == JSON.stringify({})) {
                  // alert('got')
                  var message = '”Congratulations!!  You are the first person in your area to take this quiz!  You are at the forefront of democracy!”';
                  MyService.ConsoleLog("getUserRole >", tokenService.getUserRole());
                  // var repath;

                  var modal = ngDialog.openConfirm({
                    template: '<p>' +
                      message +
                      '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                    plain: true,
                  });

                  modal.then(
                    function fullfilled(data) {
                      //MyService.ConsoleLog("fullfilled: ", data);
                      if (data == 0) {
                        if (tokenService.getUserRole() == 'voter') {
                          $location.path('/learnHome');
                        } else if (tokenService.getUserRole() == 'politician') {
                          $location.path('/winVoters');
                        }

                      }
                    },
                    function rejected(data) {
                      //MyService.ConsoleLog("rejected: ", data);
                    }
                  );
                }

              }
              //$scope.qData = payload.data.data.answeredQuestions;
              // } else {
              //   alert('got');
              // }
            } else {
              $scope.showAlert = true;
              var message = '”Congratulations!!  You are the first person in your area to take this quiz!  You are at the forefront of democracy!”';
              MyService.ConsoleLog("getUserRole >", tokenService.getUserRole());
              // var repath;

              var modal = ngDialog.openConfirm({
                template: '<p>' +
                  message +
                  '</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                        \
                    </div>',
                plain: true,
              });

              modal.then(
                function fullfilled(data) {
                  //MyService.ConsoleLog("fullfilled: ", data);
                  if (data == 0) {
                    if (tokenService.getUserRole() == 'voter') {
                      $location.path('/learnHome');
                    } else if (tokenService.getUserRole() == 'politician') {
                      $location.path('/winVoters');
                    }

                  }
                },
                function rejected(data) {
                  //MyService.ConsoleLog("rejected: ", data);
                }
              );
              // alert("Something went wrong.");
            }
          },
          function (error) {
            MyService.ConsoleLog('Error >>', error);
          }
        );
      };

      /**This function redirects to show comment page */
      $scope.viewAnsComment = function (val) {
        MyService.ConsoleLog('called', val);

        switch (val) {
          case 'email':
            $scope.global = $scope.arrayOfCom;
            if ($scope.userId) {
              var poli = JSON.stringify({
                poliArr: $scope.arrayOfCom,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfPolitician', poli);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'unRegister':
            if ($scope.userId) {
              var guest = JSON.stringify({
                guestArr: $scope.unVeriComm,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfGuest', guest);
              $location.path('/showComments');
            }
            $scope.unComment = true;
            $scope.showComment = false;
            $scope.showCurr = false;

            break;
          case 'voter':
            $scope.global = $scope.voterComArr;
            // MyService.ConsoleLog("$scope.voterComArr", $scope.voterComArr);
            if ($scope.userId) {
              var vo = JSON.stringify({
                compArr: $scope.voterComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              // MyService.ConsoleLog("vo >>", vo);
              window.localStorage.setItem('comOfVoter', vo);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'advocate':
            $scope.global = $scope.advComArr;
            if ($scope.userId) {
              var adv = JSON.stringify({
                advArr: $scope.advComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfAdv', adv);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'currentUser':
            if ($scope.userId) {
              var curUser = JSON.stringify({
                curuser: $scope.questions[$scope.currentIndex]
              });
              window.localStorage.setItem('cuUser', curUser);
              $location.path('/showComments');
            }
            $scope.showComment = false;
            $scope.showCurr = true;
            $scope.unComment = false;
            break;
          default:

            break;
        }
      };
      $scope.back = function () {
        $scope.showPoliAnswerDetails = false;
        $scope.showUserAnswerDetails = false;
        $scope.showComment = false;
        $scope.unComment = false;
        $scope.showCurr = false;

      };

      $scope.status = {
        questions: 0, //1=loading, 2=loaded, 3=error
        category: 0,
      };

      var getCompareAnswersCanceler = $q.defer();

      $scope.getCompareAnswers = function (params) {
        console.log('params: ', params);

        if (!params) return;
        getCompareAnswersCanceler.resolve();

        getCompareAnswersCanceler = $q.defer();
        $scope.questions = [];
        $scope.status.questions = 1;

        MyService.getCompareAns(params, getCompareAnswersCanceler.promise).then(
          function successCallback(response) {
            console.log('response: ', response);
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              if (response.data.data.length > 0) {
                $scope.status.questions = 2;
                angular.forEach(response.data.data, function (val, key) {
                  if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
                    var check_author_exist = function (u) {
                      var len = val.answers.length;
                      for (var i = 0; i < len; i++) {
                        if (val.answers[i].author._id == u) {
                          return true;
                        }
                      }
                      return false;
                    };

                    angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                      if (!check_author_exist(u)) {
                        response.data.data[key].answers.push({
                          author: u,
                        });
                      }
                    });
                  } else if (!val.answers) {
                    response.data.data[key].answers = [];
                    angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                      response.data.data[key].answers.push({
                        author: u,
                      });
                    });
                  }
                });

                $scope.questions = response.data.data;
                console.log('$scope.questions: ', $scope.questions);
              }
              // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
            } else {
              $scope.status.questions = 3;
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      $scope.finalSignIn = function () {

        $location.path('/fiveSaveUser');
      };

      /**
       * This function filter comment data
       */
      $scope.getItems = function () {
        return function (u) {
          MyService.ConsoleLog("params >", u);

          if (u.comment && u.comment != "") {
            return u;
          }
        };
      };

      $scope.getComment = function () {
        return function (x) {
          if (x.comment && x.comment != "") {
            return x;
          }
        };
      };

      /**
       **
       ** This function fetched matched answers for guest
       **/

      /* var getGuestCompareCanceler = $q.defer();

    $scope.getGuestCompare = function(params) {

      console.log("params: ", params);

      if (!params) return;
      getGuestCompareCanceler.resolve();

      getGuestCompareCanceler = $q.defer();
      $scope.questions = [];
      $scope.status.questions = 1;

      MyService.getGuestCompareAns(params, getGuestCompareCanceler.promise)
        .then(function successCallback(response) {
          console.log('response: ', response);
          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            if (response.data.data.length > 0) {
              $scope.status.questions = 2;
              angular.forEach(response.data.data, function(val, key) {
                if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
                  var check_author_exist = function(u) {
                    var len = val.answers.length;
                    for (var i = 0; i < len; i++) {
                      if (val.answers[i].author._id == u) {
                        return true;
                      }
                    }
                    return false;
                  };

                  angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                    if (!check_author_exist(u)) {
                      response.data.data[key].answers.push({
                        author: u
                      });
                    }
                  });
                } else if (!val.answers) {
                  response.data.data[key].answers = [];
                  angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                    response.data.data[key].answers.push({
                      author: u
                    });
                  });
                }
              });

              $scope.questions = response.data.data;
              console.log('$scope.questions: ', $scope.questions);
            }
            // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
          } else {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
        }, function errorCallBack(response) {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Error: ", response);
        });
    };
*/

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");

        $("html, body").animate({
          scrollTop: $("#DisplayQ").offset().top - 280
        }, "slow");
        if ($scope.currentIndex > 0) {
          var decr = $scope.currentIndex--;
          var one = decr - 1;
          MyService.ConsoleLog("decr", one);
          if ($scope.userId) {
            $scope.getLocalPoliticians(one);
          }
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            MyService.ConsoleLog('$scope.traverseBackgrounds >', $scope.traverseBackgrounds);
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            //MyService.ConsoleLog("** end of background **");
            if ($scope.rootCatIndex > 0) {
              $scope.rootCatIndex--;
              $location.path(
                '/question-compare/all/' + $rootScope.categories[$scope.rootCatIndex]._id
              );
            }
          }
        }
      };

      $scope.skipQuestion = function () {
        // $("html, body").animate({
        //   scrollTop: 0
        // }, "slow");
        var skip = function () {
          MyService.ConsoleLog("offset", $("#DisplayQ").offset().top);
          $("html, body").animate({
            scrollTop: $("#DisplayQ").offset().top - 180
          }, "slow");

          var tempIndex = $scope.currentIndex + 1;
          MyService.ConsoleLog(' $scope.currentIndex >>', tempIndex);
          if ($scope.userId) {
            $scope.getLocalPoliticians(tempIndex);

          }

          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          MyService.ConsoleLog(
            'tempIndex = ',
            tempIndex + '$scope.questions.length ' + $scope.questions.length
          );
          if (tempIndex == $scope.questions.length || $scope.questions.length == 0) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            MyService.ConsoleLog('$scope.traverseBackgrounds >>', $scope.traverseBackgrounds);
            if ($scope.traverseBackgrounds && $scope.traverseBackgrounds != '') {
              if (tempBackIdx < $scope.traverseBackgrounds.length) {
                $scope.currBackgroundIndex++;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
              } else {
                $scope.rootCatIndex++;
                // alert("Else");
                window.localStorage.setItem('RootCatIndex', $scope.rootCatIndex);
                window.localStorage.setItem('DisplayCategory', JSON.stringify($scope.category));
                //MyService.ConsoleLog("/politician_compare/" + $routeParams.categoryId + "/" + $routeParams.parentId);
                $location.path(
                  '/politician_compare/' + $routeParams.categoryId + '/' + $routeParams.parentId
                );
              }
            } else {
              if ($scope.userId) {
                $location.path('/compareWithGroup');

              } else {
                $location.path('/compareGuestUsers');

              }
            }
          }
        };

        skip();
      };
    },
  ]);
