'use strict';
angular.module('myApp.uploadNewPolitician', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/uploadNewPolitician', {
      templateUrl: 'app/uploadNewPolitician/uploadNewPolitician.html',
      controller: 'UploadNewPoliticianCtrl'
    });
  }])
  .controller('UploadNewPoliticianCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', 'uiGridConstants', '$filter', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce, uiGridConstants, $filter) {
    //MyService.ConsoleLog("** UploadPoliticianCtrl **");
    $scope.loginUser = tokenService.getUserId();
    //MyService.ConsoleLog(tokenService.getUserData());
    if (tokenService.getUserData()) {
      $scope.UserData = JSON.parse(tokenService.getUserData());
    }

    $scope.myFields = ["name", "email", "privateEmail", "office", "officePlace", "candidateStatus", "party", "postCard", "electionDate", "voterScope", "publicMailingAddress", "privateMailingAddress", "website", "rapidTransitDistrict", "statement", "numberOfOpenings", "incumbent", "publicZipCode", "privateZipCode", "publicPhoneNumber", "privatePhoneNumber", "stateBoardEducationDistrict", "judicialCircuitNumber", "stateFips", "statePostalCode", "countyFips", "countyName", "countySubdivisionFips", "countySubdivisionName", "placeFips", "placeName", "voterDistrict", "votingDistrictName", "federalCongressionalDistrict", "stateSenateDistrict", "stateHouseofRepresentativesDistrict", "unifiedSchoolDistrict", "unifiedSchoolDistrictName", "elementarySchoolDistrict", "elementarySchoolDistrictName", "secondarySchoolDistrict", "secondarySchoolDistrictName", "totalPop", "afact", "dataX", "dataY", "dataZ", "runningForOffice", "facebookPage", "twitterFeed", "website2", "typeOfElection", "dataOrigin"];
    $scope.gridOptions = {
      enableCellEdit: true,
      enablePaging: true,
      editableCellTemplate: '<div mass-autocomplete><input mass-autocomplete-item="grid.appScope.autocomplete_options" data-ng-model="MODEL_COL_FIELD" ng-input="COL_FIELD" ng-blur="grid.appScope.closeEditBlur(grid.getCellValue(row, col));" ng-class="itemAuto" ></div>',
      showFooter: true,
      enableColumnMenus: true,
      paginationPageSizes: [20],
      paginationPageSize: 20,
      minWidth: 150,
      data: [],
      columnDefs: []
    };

    $scope.reset = function () {
      $scope.gridOptions.data = [];
      $scope.gridOptions.columnDefs = [];
    }



    // editableCellTemplate: '<div class="mass-autocomplete"><input type="INPUT_TYPE"  data-ng-model="MODEL_COL_FIELD" name="label" mass-autocomplete-item="grid.appScope.autocomplete_options" maxlength="100" /></div>',

    $scope.myHeaderCellTemplate = '<div ng-style="{cursor: col.cursor}" ui-grid-auto-resize ng-class="{ ngSorted: !noSortVisible }">' +
      '<div class="ngHeaderText">{{col.displayName}}</div><div><select ng-model="dirty.value" ng-change="grid.appScope.changeDisplay(col,dirty.value)">';
    // for(var i=0; i<myfields.length;i++) {
    //     $scope.myHeaderCellTemplate += '<option value="'+myfields[i]+'">'+myfields[i]+'</option>';
    // }

    $scope.myHeaderCellTemplate += '<option ng-repeat="fld in grid.appScope.myFields" value="{{fld}}">{{fld}}</option>';
    $scope.myHeaderCellTemplate += '</select></div>' +
      '<div class="ngSortButtonDown" ng-show="col.showSortButtonDown()"></div>' +
      '<div class="ngSortButtonUp" ng-show="col.showSortButtonUp()"></div>' +
      '<div class="ngSortPriority">{{col.sortPriority}}</div>' +
      '</div>' +
      '<div ng-show="col.resizable" class="ngHeaderGrip"></div>';


    $scope.checkColumn = function (rdata, val) {
      return $scope.gridOptions.columnDefs.filter(
        function (rdata) {
          //MyService.ConsoleLog(rdata.field + "==" + val);
          if (rdata.field == val)
            return rdata;
        }
      );
    }

    $scope.add = function () {
      $scope.gridOptions.columnDefs.push({
        field: 'new',
        minWidth: 150,
        enableSorting: true,
        enableCellEdit: true,
        headerCellTemplate: $scope.myHeaderCellTemplate
      });
    }

    $scope.addColumn = function (t) {
      //MyService.ConsoleLog(t);
      //MyService.ConsoleLog($scope.gridOptions.columnDefs);
      var getfield = $scope.checkColumn($scope.gridOptions.columnDefs, t);
      //MyService.ConsoleLog("getfield >>>>>> ", getfield);
      if (getfield.length == 0) {
        $scope.gridOptions.columnDefs.push({
          field: t,
          minWidth: 150,
          enableSorting: true,
          enableCellEdit: true,
          headerCellTemplate: $scope.myHeaderCellTemplate
        });
      }

    }


    $scope.remove = function () {
      angular.forEach($scope.gridApi.selection.getSelectedRows(), function (data, index) {
        $scope.gridOptions.data.splice($scope.gridOptions.data.lastIndexOf(data), 1);
      });
    }

    $scope.editField = "";
    $scope.editFieldD = "";
    $scope.prevVal = "";
    $scope.address = {
      state: '',
      county: '',
      places: [],
      towns: [],
      city: [],
      CountySubdivisionName: [],
      FederalCongressionalDistrict: [],
      StateHouseofRepresentativesDistrict: [],
      UnifiedSchoolDistrictName: [],
      voterDistrictName: []
    }
    $scope.angularElement = "";
    $scope.selectedEditRow = "";
    $scope.selectedEditS = "";
    $scope.countyList = [];
    //MyService.ConsoleLog($scope.UserData.volunteers);
    if ($scope.UserData && $scope.UserData.volunteers && $scope.UserData.volunteers.length > 0) {
      $scope.countyList.length = 0;
      $scope.locations = $scope.UserData.volunteers[0].permited_location;
      //MyService.ConsoleLog($scope.locations);
      $scope.countyList = $scope.locations.map(function (pl) {
        return {
          "county": pl.county,
          "statePostalCode": pl.statePostalCode
        };
      });
      //MyService.ConsoleLog($scope.countyList);
    }

    $scope.checkColumn = function (val) {
      return $scope.countyList.filter(
        function (rdata) {
          if (rdata.county == val.county && rdata.statePostalCode == val.statePostalCode)
            return rdata;
        }
      );
    }

    $scope.checkCountyExists = function (item) {
      MyService.ConsoleLog("item:::", item);
      var idx = $scope.checkColumn(item._id);
      if (idx && idx.length > 0) {
        return item;
      } else {
        return "";
      }
    }



    $scope.gridOptions.onRegisterApi = function (gridApi) {
      //set gridApi on scope
      $scope.gridApi = gridApi;

      $scope.gridApi.colResizable.on.columnSizeChanged($scope, function (colDef, deltaChange) {
        //MyService.ConsoleLog('resized #############');
      });


      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        var msg = 'row selected ' + row.isSelected;
        //MyService.ConsoleLog(msg);
      });

      gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
        var msg = 'rows rowSelectionChangedBatch ' + rows.length;
        //MyService.ConsoleLog(msg);
      });

      //MyService.ConsoleLog(gridApi.edit);
      gridApi.edit.on.beginCellEdit($scope, function (row, s, t) {
        $scope.selectedEditRow = row;
        $scope.selectedEditS = s;
        // //t.srcElement.onkeypress = $scope.keypresscalled;
        //MyService.ConsoleLog(t.srcElement);
        //MyService.ConsoleLog(s);
        if (s.displayName) {
          $scope.editField = s.field;
          $scope.editFieldD = s.displayName;
        } else {
          $scope.editField = s.field;
          $scope.editFieldD = s.field;
        }
        $scope.prevVal = angular.copy(t.srcElement.innerHTML);
        $scope.angularElement = t.srcElement;
      });

      gridApi.edit.on.afterCellEdit($scope, function (row, s, t) {


        // $scope.gridApi.core.refresh();
        var fieldName = s.displayName ? s.displayName : s.field;

        // $scope.$apply();
        var message = "Do you want to replace all " + $scope.prevVal + " with " + t + " from " + $scope.editField + " column";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", $scope.gridApi.grid);
          if (data == 1) {
            var len = $scope.gridApi.grid.rows.length;
            //MyService.ConsoleLog("len>", len);
            for (var j = 0; j < len; j++) {
              //MyService.ConsoleLog($scope.editField);
              //MyService.ConsoleLog($scope.gridApi.grid.rows[j].entity[$scope.editField]);
              //MyService.ConsoleLog($scope.prevVal);
              if ($scope.gridApi.grid.rows[j].entity[$scope.editField] == $scope.prevVal) {
                $scope.gridApi.grid.rows[j].entity[$scope.editField] = t;
                // MyService.ConsoleLog($scope.gridApi.grid.rows[j]);
                var columns = $scope.gridApi.grid.columns;
                var colLen = $scope.gridApi.grid.columns.length;
                for (var c = 0; c < colLen; c++) {

                  if (fieldName == $scope.gridApi.grid.columns[c].displayName) {
                    MyService.ConsoleLog($scope.gridApi.grid.columns[c]);
                    MyService.ConsoleLog(fieldName)
                    switch (fieldName) {
                      case "countyName":
                        MyService.ConsoleLog($scope.address.towns + "==" + t);
                        if ($scope.address.towns.indexOf(t) > 0) {
                          $scope.gridApi.grid.columns[c].cellClass = 'white';
                          // $scope.$apply();
                        }
                        break;
                      case "countySubdivisionName":
                        MyService.ConsoleLog($scope.address.CountySubdivisionName + "==" + t);
                        if ($scope.address.CountySubdivisionName.indexOf(t) > 0) {
                          $scope.gridApi.grid.columns[c].cellClass = 'white';
                          // $scope.$apply();
                        }
                        break;
                      case "placeName":
                        MyService.ConsoleLog($scope.address.city + "==" + t);
                        if ($scope.address.city.indexOf(t) > 0) {
                          $scope.gridApi.grid.columns[c].cellClass = 'white';
                          // $scope.$apply();
                        }
                        break;
                      case "default":

                        // $scope.$apply();
                        break;

                    }
                  }
                }
                MyService.ConsoleLog($scope.gridApi.grid.rows[j]);

              }
              if (j == len - 1) {
                angular.element($scope.angularElement).removeClass('ui-grid-cell-contents-hidden');
                angular.element($scope.angularElement).addClass('ui-grid-cell-contents');
                angular.element(document.querySelector('.ac-container')).remove();

                if ($scope.editFieldD.toLowerCase() == 'voterscope') {
                  $scope.addColumn(t);
                }

                $scope.gridApi.core.refresh();
                // $scope.$apply();
              }
            }
          }
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
          angular.element($scope.angularElement).removeClass('ui-grid-cell-contents-hidden');



          angular.element($scope.angularElement).addClass('ui-grid-cell-contents');

          switch (fieldName) {
            case "countyName":
              MyService.ConsoleLog($scope.address.towns + "==" + t);
              if ($scope.address.towns.indexOf(t) > 0) {
                angular.element($scope.angularElement).removeClass('yellow');
                angular.element($scope.angularElement).addClass('white');
                // $scope.$apply();
              }
              break;
            case "countySubdivisionName":
              MyService.ConsoleLog($scope.address.CountySubdivisionName + "==" + t);
              if ($scope.address.CountySubdivisionName.indexOf(t) > 0) {
                angular.element($scope.angularElement).removeClass('yellow');
                angular.element($scope.angularElement).addClass('white');
                // $scope.$apply();
              }
              break;
            case "placeName":
              MyService.ConsoleLog($scope.address.city + "==" + t);
              if ($scope.address.city.indexOf(t) > 0) {
                angular.element($scope.angularElement).removeClass('yellow');
                angular.element($scope.angularElement).addClass('white');
                // $scope.$apply();
              }
              break;
            case "default":

              // $scope.$apply();
              break;

          }

          angular.element(document.querySelector('.ac-container')).remove();
          if ($scope.editFieldD.toLowerCase() == 'voterscope') {
            $scope.addColumn(t);
          }
          $scope.gridApi.core.refresh();
        });


        // gridApi.edit.on.afterCellEdit($scope, function(rowEntity, colDef, newValue, oldValue) {

        // colDef.cellClass = function(grid, row, col, rowRenderIndex, colRenderIndex) {
        //       if (rowEntity.id === row.entity.id && newValue !== oldValue) {
        //         return "test" ;
        //       }
        //       return "";
        //     };

        // $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);



      });
    };

    $scope.closeEdit = function (col) {
      // alert("here");
      //MyService.ConsoleLog(col);
      //var nval = col.value? col.value: col.displayName
      //MyService.ConsoleLog($scope.address.towns);
      $scope.gridApi.edit.raise.afterCellEdit($scope.selectedEditRow, $scope.selectedEditS, col.value);
      //$scope.gridApi.core.refresh();
    }

    $scope.closeEditBlur = function (col) {
      //MyService.ConsoleLog(col);
      var n = new Object;
      n.value = col;
      if (!$scope.suggestionsShown) {
        $scope.closeEdit(n);
      }

      //MyService.ConsoleLog(val);
    }

    $scope.finaldata = "";

    //Autofill fieldname
    $scope.dirty = {
      value: '',
      colval: ''
    };

    //angular.element($scope.angularElement).bind('blur', function() {
    //MyService.ConsoleLog("Blur triggered");
    //});



    $scope.party = ["Democratic", "Unknown", "Republican", "Democratic/Republican", "Independent", "Republican and Democrat", "Democratic and Republican", "Nonpartisan", "Green", "Republican/Democratic", "Independence", "Conservative", "Working Families", "Women's Equality"];

    $scope.voterScope = ["All USA", "CountyName", "PlaceName", "CountySubdivisionName", "FederalCongressionalDistrict", "StateHouseofRepresentativesDistrict", "StatePostalCode", "StateSenateDistrict", "UnifiedSchoolDistrictName"];

    function highlight(str, term) {
      var highlight_regex = new RegExp('(' + term + ')', 'gi');
      return str.replace(highlight_regex,
        '<span class="highlight">$1</span>');
    };
    $scope.suggestionsShown = false;

    function suggest_state(term) {
      var q = term.toLowerCase().trim();
      var results = [];
      var finaldata;
      $scope.suggestionsShown = false;
      var myEl = angular.element(document.querySelector('.ac-container'));
      myEl.css('top', 'inherit');
      myEl.css('left', 'inherit');
      // Find first 10 states that start with `term`.

      var editfield = $scope.editFieldD.replace(" ").toLowerCase();
      //MyService.ConsoleLog(editfield);
      switch (editfield) {
        case "voterscope":
          finaldata = $scope.voterScope;
          break;
        case "countysubdivisionname":
          finaldata = $scope.address.CountySubdivisionName;
          break;
        case "federalcongressionaldistrict":
          finaldata = $scope.address.FederalCongressionalDistrict;
          break;
        case "statehouseofrepresentativesdistrict":
          finaldata = $scope.address.StateHouseofRepresentativesDistrict;
          break;
        case "stateSenatedistrict":
          finaldata = $scope.address.StateSenateDistrict;
          break;
        case "unifiedschooldistrictname":
          finaldata = $scope.address.UnifiedSchoolDistrictName;
          break;
        case "party":
          finaldata = $scope.party;
          break;
        default:
          finaldata = $scope.address.towns;
          break;
      }
      //MyService.ConsoleLog("finaldata>>>",finaldata);
      for (var i = 0; i < finaldata.length; i++) {
        var state = finaldata[i];
        var matched = state.match(new RegExp(q, "i"));
        //MyService.ConsoleLog(state.match())
        //MyService.ConsoleLog(matched);
        //MyService.ConsoleLog(state.match(q, "i"));
        if (matched) {
          $scope.suggestionsShown = true;
          results.push({
            label: state,
            value: state
          });
        }
      }
      return results;
    }

    function suggest_state_with_highlight(term) {
      $scope.suggestionsShown = false;
      if (term.length < 1)
        return;
      var suggestions = suggest_state(term);
      suggestions.forEach(function (s) {
        // In real life you should reuse the regexp object.
        s.label = $sce.trustAsHtml(highlight(s.label, term));
      });
      return suggestions;
    };

    $scope.getAutoData = function (data, col) {
      //MyService.ConsoleLog(data);
      //MyService.ConsoleLog(col);

    };

    $scope.autocomplete_options = {
      suggest: suggest_state_with_highlight,
      on_select: $scope.closeEdit
    };


    function suggest_name(term) {
      var q = term.toLowerCase().trim();
      var results = [];
      var len = $scope.Allcountries.length;
      // Find first 10 states that start with `term`.
      for (var i = 0; i < len && results.length < 10; i++) {
        var country = $scope.Allcountries[i];
        if (country.toLowerCase().indexOf(q) === 0)
          results.push({
            label: country,
            value: country
          });
      }
      return results;
    }

    $scope.autocomplete_nameoptions = {
      suggest: suggest_name
    };


    $scope.changeDisplay = function (col, fld) {
      //MyService.ConsoleLog("<===============");
      //MyService.ConsoleLog(col);
      //MyService.ConsoleLog("===============>");
      //MyService.ConsoleLog(fld);
      col.colDef.displayName = fld;
      //MyService.ConsoleLog($scope.gridApi);
      //MyService.ConsoleLog($scope.opts);
      var idx = $scope.gridApi.grid.columns.indexOf(col);
      //MyService.ConsoleLog(fld.length);
      $scope.gridApi.grid.columns[idx].displayName = fld;

      if (fld.toLowerCase() != "voterscope") {
        var wdth = fld.length * 10;
        if (wdth < 150) {
          wdth = 150
        }
        $scope.gridApi.grid.columns[idx].width = wdth;
        $scope.gridApi.grid.columns[idx].colDef.width = wdth;
        //$scope.gridOptions.columnDefs[idx].width = fld.length * 10;
        //MyService.ConsoleLog($scope.gridOptions.columnDefs);
        $scope.gridApi.grid.columns[idx].drawnWidth = wdth;
      } else {
        $scope.gridApi.grid.columns[idx].width = 300;
        $scope.gridApi.grid.columns[idx].colDef.width = 300;
        //$scope.gridOptions.columnDefs[idx].width = fld.length * 10;
        //MyService.ConsoleLog($scope.gridOptions.columnDefs);
        $scope.gridApi.grid.columns[idx].drawnWidth = 300;
      }
      $timeout(function () {
        $scope.gridApi.core.refresh();
      });

    }

    //Autofill fieldname end
    // $scope.curstate = "";


    $scope.Show = {
      Loader: false
    };
    $scope.saveState = function () {
      //$scope.curstate = JSON.stringify($scope.gridApi.saveState.save());
      $scope.gridDirtyRows = $scope.gridOptions.data;
      $scope.promises = [];
      $scope.Show.Loader = true;
      angular.forEach($scope.gridDirtyRows, function (mvalue, key) {
        //MyService.ConsoleLog("Key>>>", key);
        $scope.subpromises = [];
        //var len = $scope.gridApi.grid.columns.length;
        var j = 0;
        angular.forEach($scope.gridApi.grid.columns, function (subval, subkey) {
          //MyService.ConsoleLog("mvalue<>",mvalue);
          var nfld = subval.field;
          //MyService.ConsoleLog("subval.field<>",mvalue[nfld]);
          //var nobj = new Object;
          if (subval.displayName != "" && subval.displayName != " ") {
            $scope.promises.push({
              fld: subval.displayName,
              fldval: mvalue[nfld],
              StatePostalCode: $scope.address.state.statePostalCode,
              CountyName: $scope.address.county._id.county,
              user: $scope.loginUser
            });
          }
        });
        //var displayColumn = $scope.checkColumn($scope.gridApi.grid.columns, value);

        // $q.all($scope.subpromises).then(function(value, key) {
        //     //MyService.ConsoleLog($scope.subpromises);

        // });

        //$scope.promises.push($scope.subpromises);
      })
      $q.all($scope.promises).then(function () {
        //MyService.ConsoleLog('there')
        //MyService.ConsoleLog($scope.promises);
        MyService.saveTempPolitician({
          data: JSON.stringify($scope.promises)
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          $scope.Show.Loader = false;
          growl.error('Current state is successfully stored in database.');
        }, function (errorPayload) {
          $scope.Show.Loader = false;
          growl.error('Something went wrong.');
        });
      });
      //MyService.ConsoleLog($scope.gridDirtyRows);
      //window.localStorage.setItem('gridState', $scope.curstate);
    };
    $scope.finalData = [];

    function savedata(obj, columns, len) {
      //MyService.ConsoleLog(obj);
      var s = 0;
      var checkempty = false;
      var filData = {};
      filData = angular.copy(obj);
      $scope.finalData.push(filData);
    }

    $scope.restoreState = function () {
      //$timeout(function() {
      $scope.Show.Loader = true;
      //MyService.ConsoleLog($scope.ShowLoader);
      MyService.restoreTempPolitician({
        "user": $scope.loginUser
      }).then(function (payload) {
        //MyService.ConsoleLog(payload);
        var actualData = payload.data;
        var fldlenth = payload.fldlenth;
        var columnsArray = payload.flds;
        var j = 0;
        $scope.finalData1 = [];
        var nobj = {};
        angular.forEach(actualData, function (subval, subkey) {
          //MyService.ConsoleLog(subval);
          nobj[subval.fld] = subval.fldval;
          j++;
          if (j % fldlenth == 0) {
            savedata(nobj, columnsArray, fldlenth);
            //j = 0;
            //nobj.length = 0;
          }
        });
        //MyService.ConsoleLog($scope.finalData);
        //$scope.gridApi.grid.columns
        $scope.gridOptions.data = $scope.finalData;
        $scope.gridOptions.columnDefs = [];
        $scope.myHeaderCellTemplate = '<div  ng-style="{cursor: col.cursor}" ui-grid-auto-resize ng-class="{ ngSorted: !noSortVisible }">' +
          '<div class="ngHeaderText">{{col.displayName}}</div><div><select ng-model="dirty.value" ng-change="grid.appScope.changeDisplay(col,dirty.value)">';
        // for(var i=0; i<myfields.length;i++) {

        //     $scope.myHeaderCellTemplate += '<option value="'+myfields[i]+'">'+myfields[i]+'</option>';
        // }
        $scope.myHeaderCellTemplate += '<option ng-repeat="fld in grid.appScope.myFields" value="{{fld}}">{{fld}}</option>';
        $scope.myHeaderCellTemplate += '</select></div>' +
          '<div class="ngSortButtonDown" ng-show="col.showSortButtonDown()"></div>' +
          '<div class="ngSortButtonUp" ng-show="col.showSortButtonUp()"></div>' +
          '<div class="ngSortPriority">{{col.sortPriority}}</div>' +
          '</div>' +
          '<div ng-show="col.resizable" class="ngHeaderGrip"></div>';
        columnsArray.forEach(function (h) {

          if (checkcityArray.indexOf(h) > -1) {
            cityIdx = checkcityArray.indexOf(h);
            //MyService.ConsoleLog("cityIdx>>", cityIdx);
            /*cellClass: function(grid, row, col, rowRenderIndex, colRenderIndex) { var cidx = $scope.address.places.indexOf(grid.getCellValue(row,col)); if (cidx > -1) { if($scope.address.places[cidx]!=grid.getCellValue(row,col)) { return 'yellow'; } } return 'green'; } */
            //MyService.ConsoleLog($scope.opts);
            //MyService.ConsoleLog($scope);
            //MyService.ConsoleLog(grid.appScope.address);
            //MyService.ConsoleLog($scope.address.towns);
            $scope.gridOptions.columnDefs.push({
              field: h,
              displayName: h,
              width: 150,
              enableSorting: false,
              enableCellEdit: true,
              headerCellTemplate: $scope.myHeaderCellTemplate,
              cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                var gVal = grid.getCellValue(row, col);
                if (gVal) {
                  //MyService.ConsoleLog(gVal);
                  var cval = gVal.split(",")[0].trim();
                  //MyService.ConsoleLog(cval);
                  var cidx = $scope.address.towns.filter(function (d) {
                    return d.indexOf(cval) !== -1;
                  });
                  if (cidx != "") {
                    return 'yellow'
                  }
                }
                return '';
              }
            });
          } else {
            $scope.gridOptions.columnDefs.push({
              field: h,
              displayName: h,
              width: 150,
              enableSorting: false,
              enableCellEdit: true,
              headerCellTemplate: $scope.myHeaderCellTemplate,
              cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                var gVal = grid.getCellValue(row, col);
                if (gVal) {
                  //MyService.ConsoleLog(gVal);
                  var cidx = $scope.address.city.filter(function (d) {
                    return gVal.indexOf(d) !== -1;
                  });
                  if (cidx != "") {
                    return 'yellow'
                  }
                }
                return '';
              }
            });
          }

          //$scope.gridOptions.columnDefs.push({ field: h, displayName: h, width: 150, enableSorting: false, enableCellEdit: true, headerCellTemplate: $scope.myHeaderCellTemplate });
        });

        $scope.Show.Loader = false;

      }, function (errorPayload) {
        $scope.Show.Loader = false;
        growl.error('Something went wrong.');
      });
      //});
    };


    $scope.removeState = function () {
      var message = "This action cannot be undone. Are you sure you want to remove the previously stored grid data?";
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        if (data == 1) {
          $scope.Show.Loader = true;
          MyService.removeStoredTempPolitician({
            "user": $scope.loginUser
          }).then(function (payload) {
            //MyService.ConsoleLog(payload);
            $scope.checkPrevState = false;
            $scope.Show.Loader = false;
            $scope.reset();
          }, function (errorPayload) {
            //MyService.ConsoleLog(errorPayload);
            $scope.Show.Loader = false;
          });
        }
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }



    $scope.getAllStates = function () {
      $scope.counties = "";
      $scope.address.state = "";
      $scope.address.county = "";
      $scope.address.places.length = 0;
      MyService.getAllStates().then(function (payload) {
          $scope.allState = payload;
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }

    $scope.getCounties = function () {
      MyService.ConsoleLog("state<><><>", $scope.address.state);
      $scope.address.places.length = 0;
      MyService.getAllCounties($scope.address.state.stateFips, $scope.address.state).then(function (payload) {
          $scope.counties = payload;
          $scope.county = payload;
          MyService.ConsoleLog("counties", $scope.counties);
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }

    $scope.switch = {
      "dStatus": []
    };

    $scope.checkboxModel = {
      "winner": ""
    };

    $scope.checkWinner = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val)
            return rdata;
        }
      );
    }

    $scope.showPreferences = function () {
      ngDialog.open({
        template: 'app/popups/setPreference.html',
        controller: "setPreference"
      });
    }

    $scope.orginialData = [];

    $scope.showHideWinners = function () {
      var val = $scope.checkboxModel.winner;
      //MyService.ConsoleLog(val);

      if (val == true) {
        $scope.orginialData = angular.copy($scope.gridOptions.data);
        var ldata = $filter('filter')($scope.gridOptions.data, "Winner");
        for (var i = 0, k = 1; i < ldata.length; i++, k++) {
          var idx = $scope.gridOptions.data.indexOf(ldata[i]);
          $scope.gridOptions.data.splice(idx, 1);
        }
        //var idx = $scope.gridOptions.data.indexOf(data);
        //$scope.gridApi.core.refresh();
      } else {
        //MyService.ConsoleLog($scope.orginialData);
        $scope.gridOptions.data = angular.copy($scope.orginialData);
        //$scope.gridApi.core.refresh();
      }
    }

    $scope.showRunningFor = function () {
      var val = $scope.checkboxModel.runningFor;
      //MyService.ConsoleLog(val);

      if (val == true) {
        $scope.orginialData = angular.copy($scope.gridOptions.data);
        var ldata = $scope.gridOptions.data.filter(function (ndata) {
          return (ndata.runningForOffice != "Y");
        });
        for (var i = 0, k = 1; i < ldata.length; i++, k++) {
          var idx = $scope.gridOptions.data.indexOf(ldata[i]);
          $scope.gridOptions.data.splice(idx, 1);
        }
        //var idx = $scope.gridOptions.data.indexOf(data);
        //$scope.gridApi.core.refresh();
      } else {
        //MyService.ConsoleLog($scope.orginialData);
        $scope.gridOptions.data = angular.copy($scope.orginialData);
        //$scope.gridApi.core.refresh();
      }
    }

    $scope.showMe = function (row, val) {
      //MyService.ConsoleLog(row);
      //MyService.ConsoleLog(val);
      var idx = $scope.gridOptions.data.indexOf(row);
      if (val != "") {
        //MyService.ConsoleLog("Row>>>", idx);
        if (val == "Loose") {
          $scope.gridOptions.data.splice(idx, 1);
        } else {
          if ($scope.checkboxModel.winner == true) {
            $scope.gridOptions.data.splice(idx, 1);
          }
          $scope.gridOptions.data[idx].incumbent = "Winner";
        }

        MyService.setWinRecords({
          "id": row._id,
          "val": val
        }).then(function (payload) {
          $timeout(function () {
            //$scope.gridApi.core.refresh();
            for (var j = 0; j < $scope.switch.dStatus.length; j++) {
              $scope.switch.dStatus[j] = "";
            }
            $scope.switch.dStatus[idx] = "";
            $scope.gridApi.core.refresh();
            //MyService.ConsoleLog("Inside TImeout");
          }, 2000);
        }, function (errorPayload) {

        });
      }
    }

    $scope.userSelectedCols = [];
    //MyService.ConsoleLog($scope.UserData);
    if ($scope.UserData && $scope.UserData.selectedColumns) {
      $scope.userSelectedCols = $scope.UserData.selectedColumns;
    } else {
      $scope.userSelectedCols = $scope.myFields;
    }
    $scope.list = {
      "selectedName": ''
    };
    $scope.selectedCols = function (temp) {
      //MyService.ConsoleLog(temp.cols);
      //MyService.ConsoleLog($scope.list);
      var allColumns = $scope.gridOptions.columnDefs.map(function (e) {
        return e.field;
      });
      //MyService.ConsoleLog(allColumns);
      for (var i = 1; i < allColumns.length; i++) {
        $scope.gridOptions.columnDefs[i].visible = false;
      }
      //MyService.ConsoleLog(temp);
      if (temp != "restore" && temp != null) {
        for (var j = 0; j < temp.cols.length; j++) {
          //MyService.ConsoleLog(temp.cols[j]);
          var pos = $scope.gridOptions.columnDefs.map(function (e) {
            return e.field;
          }).indexOf(temp.cols[j]);
          $scope.gridOptions.columnDefs[pos].visible = true;
        }
      } else {
        for (var i = 1; i < allColumns.length; i++) {
          $scope.gridOptions.columnDefs[i].visible = true;
        }
      }

      $scope.gridApi.core.refresh();
    }

    var checkcityArray = ['city', 'City', 'CITY', 'town', 'Town', 'TOWN'];

    $scope.getSubCounties = function () {
      MyService.ConsoleLog("state<><><>", $scope.address.county);
      $scope.address.towns.length = 0;
      $scope.address.city.length = 0;
      $scope.Show.Loader = true;
      MyService.getAllLocalDetails($scope.address.state.statePostalCode, $scope.address.county.county).then(function (npayload) {
        //$scope.counties = payload;
        //MyService.ConsoleLog(npayload);
        var payload = npayload.locations;
        $scope.address.places = npayload.locations;
        //$scope.address.places = npayload.locations;

        //MyService.ConsoleLog(payload);
        payload.forEach(function (d) {
          var idx = $scope.address.towns.indexOf(d._id.place);
          if (idx == -1) {
            $scope.address.towns.push(d._id.place);
            $scope.address.city.push(d._id.place.split(" ")[0]);
          }


          var idxc = $scope.address.CountySubdivisionName.indexOf(d._id.countySubdivision);
          if (idxc == -1) {
            $scope.address.CountySubdivisionName.push(d._id.countySubdivision);
          }

          var idxf = $scope.address.FederalCongressionalDistrict.indexOf(d._id.federalCongrassionalDist);
          if (idxf == -1) {
            $scope.address.FederalCongressionalDistrict.push(d._id.federalCongrassionalDist);
          }

          var idxH = $scope.address.StateHouseofRepresentativesDistrict.indexOf(d._id.stateHouseOFRepresentativeDistrict);
          if (idxH == -1) {
            $scope.address.StateHouseofRepresentativesDistrict.push(d._id.stateHouseOFRepresentativeDistrict);
          }

          var idxu = $scope.address.UnifiedSchoolDistrictName.indexOf(d._id.unifiedSchoolDistrictName);
          if (idxu == -1) {
            $scope.address.UnifiedSchoolDistrictName.push(d._id.unifiedSchoolDistrictName);

          }

          var idxd = $scope.address.voterDistrictName.indexOf(d._id.voterDistrictName);
          if (idxd == -1) {
            $scope.address.voterDistrictName.push(d._id.voterDistrictName);
          }
        });

        //MyService.ConsoleLog(npayload.politician);

        var wincellTemplate = '<select ng-model="switch.dStatus[$index]" ng-change="grid.appScope.showMe(row.entity, switch.dStatus[$index])"><option value="" selected ></option><option value="Win">Won</option><option value="Loose">Lost</option></select>';

        if (npayload.politician.length > 0) {
          var message = 'There are already records for this county. Do you want to load them?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
            plain: true
          });
          modal.then(function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", mydata);
              // alert(data);
              if (data == 1) {
                var promises = [];
                var columns = npayload.politician[0];
                var collen = columns.length;
                $scope.gridOptions.columnDefs.length = 0;
                //MyService.ConsoleLog("collen>>>", collen);
                var j = 0;
                //MyService.ConsoleLog($scope.userSelectedCols);
                angular.forEach(columns, function (value, key) {
                  //columns.forEach(function(key, value) {
                  j++;
                  //MyService.ConsoleLog("key>>>",key);
                  //var idx = $scope.userSelectedCols.indexOf(key);
                  //MyService.ConsoleLog("idx>>",idx);
                  //if (idx > -1) {

                  if (checkcityArray.indexOf(key) > -1) {
                    cityIdx = checkcityArray.indexOf(key);
                    //MyService.ConsoleLog("cityIdx>>", cityIdx);
                    /*cellClass: function(grid, row, col, rowRenderIndex, colRenderIndex) { var cidx = $scope.address.places.indexOf(grid.getCellValue(row,col)); if (cidx > -1) { if($scope.address.places[cidx]!=grid.getCellValue(row,col)) { return 'yellow'; } } return 'green'; } */
                    //MyService.ConsoleLog($scope.opts);
                    //MyService.ConsoleLog($scope);
                    //MyService.ConsoleLog(grid.appScope.address);
                    //MyService.ConsoleLog($scope.address.towns);


                    $scope.gridOptions.columnDefs.unshift({
                      field: key,
                      displayName: key,
                      width: 150,
                      enableSorting: false,
                      enableCellEdit: true,
                      cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var gVal = grid.getCellValue(row, col);
                        if (gVal) {
                          //MyService.ConsoleLog(gVal);
                          var cval = gVal.split(",")[0].trim();
                          //MyService.ConsoleLog(cval);
                          var cidx = $scope.address.towns.filter(function (d) {
                            return d.indexOf(cval) !== -1;
                          });
                          if (cidx != "") {
                            return 'yellow'
                          }
                        }
                        return '';
                      }
                    });
                  } else {
                    $scope.gridOptions.columnDefs.unshift({
                      field: key,
                      displayName: key,
                      width: 150,
                      enableSorting: false,
                      enableCellEdit: true,
                      cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var gVal = grid.getCellValue(row, col);
                        if (gVal) {
                          var cidx = $scope.address.city.filter(function (d) {
                            //MyService.ConsoleLog("d>", d);
                            if (d && d != "") {
                              MyService.ConsoleLog("gVal", gVal);
                              if (gVal) {
                                return gVal.indexOf(d) !== -1;
                              }
                            }

                          });
                          if (cidx != "") {
                            return 'yellow'
                          }
                        }
                        return '';
                      }
                    });
                  }

                  /*
                  $scope.gridOptions.columnDefs.unshift({
                    field: key,
                    displayName: key,
                    width: 150,
                    enableSorting: false,
                    enableCellEdit: true,
                    cellClass: function(grid, row, col, rowRenderIndex, colRenderIndex) {
                      var gVal = grid.getCellValue(row, col);
                      if (gVal) {
                        //MyService.ConsoleLog(gVal);
                        var cval = gVal.split(",")[0].trim();
                        //MyService.ConsoleLog(cval);
                        var cidx = $scope.address.towns.filter(function(d) {
                          return d.indexOf(cval) !== -1;
                        });
                        if (cidx != "") {
                          MyService.ConsoleLog("cval<><><>",cval);
                          MyService.ConsoleLog("cidx<><><>",cidx);
                          MyService.ConsoleLog("Twons<><><>",$scope.address.towns)
                          return 'yellow'
                        }
                      }
                      return '';
                    }
                  });
                  */
                  //}

                  promises.push(j);
                });

                $q.all(promises).then(function () {
                  //MyService.ConsoleLog("data");
                  $scope.gridOptions.columnDefs.unshift({
                    field: "WinLose",
                    displayName: "Won/Lost",
                    width: 110,
                    cellTemplate: wincellTemplate
                  });
                  $scope.gridOptions.data = npayload.politician;
                  $scope.gridOptions.enableFiltering = true;
                  //$scope.gridApi.core.refresh();
                  $scope.Show.Loader = false;
                  $scope.showSave = false;
                });
              }

              if (data == 2) {
                $scope.Show.Loader = false;
                $scope.showSave = true;
              }

              // $timeout(function() {
              // }, 2000);
              //window.localStorage.setItem()
              //MyService.ConsoleLog($scope.address.UnifiedSchoolDistrictName);
            },
            function (errorPayload) {
              $scope.Show.Loader = false;
              $scope.showSave = true;
              //growl.error('Something went wrong.');
            });
        } else {
          $scope.Show.Loader = false;
          $scope.showSave = true;
        }
      });
    }

    $scope.checkPrevState = false;




    $scope.saveRecords = function () {
      $scope.gridDirtyRows = $scope.gridOptions.data;
      MyService.ConsoleLog($scope.gridDirtyRows);
      $scope.promises = [];
      $scope.Show.Loader = true;

      angular.forEach($scope.gridDirtyRows, function (mvalue, key) {
        //MyService.ConsoleLog("Key>>>", key);
        $scope.subpromises = [];
        //var len = $scope.gridApi.grid.columns.length;
        var j = 0;
        var nobj = new Object;
        var len = $scope.gridApi.grid.columns.length;
        angular.forEach($scope.gridApi.grid.columns, function (subval, subkey) {
          //MyService.ConsoleLog("mvalue<>",mvalue);
          var nfld = subval.field;
          j++;
          //MyService.ConsoleLog("subval.field<>",mvalue[nfld]);
          //var nobj = new Object;
          if (subval.displayName != "" && subval.displayName != " ") {
            nobj[subval.displayName] = mvalue[nfld];
            //MyService.ConsoleLog(nobj);
          }
          if (j == len) {
            nobj.StatePostalCode = $scope.address.state.statePostalCode;
            nobj.CountyName = $scope.address.county.county;
            $scope.promises.push(nobj);
          }
        });
        //var displayColumn = $scope.checkColumn($scope.gridApi.grid.columns, value);

        // $q.all($scope.subpromises).then(function(value, key) {
        //     //MyService.ConsoleLog($scope.subpromises);

        // });

        //$scope.promises.push($scope.subpromises);
      })
      $q.all($scope.promises).then(function () {
        MyService.ConsoleLog($scope.promises);

        MyService.savePoliticianGrid({
          "records": JSON.stringify($scope.promises)
        }).then(function (payload) {
          MyService.ConsoleLog(payload);
          $scope.Show.Loader = false;
          $scope.reset();
          alert("Data saved successfully.");
        }, function (errorPayload) {
          //MyService.ConsoleLog(errorPayload);
          $scope.Show.Loader = false;
          alert("Error occurred. Please try again after sometime.");
        });

      });

    }


    $scope.saveSelectedRecords = function () {
      //MyService.ConsoleLog($scope.gridApi.selection.getSelectedRows());
      /*
      angular.forEach($scope.gridApi.selection.getSelectedRows(), function(data, index) {
          $scope.gridOptions.data.splice($scope.gridOptions.data.lastIndexOf(data), 1);
      });
      */
      $scope.gridDirtyRows = $scope.gridApi.selection.getSelectedRows();
      $scope.promises = [];
      $scope.Show.Loader = true;
      angular.forEach($scope.gridDirtyRows, function (mvalue, key) {
        //MyService.ConsoleLog("Key>>>", key);
        $scope.subpromises = [];
        //var len = $scope.gridApi.grid.columns.length;
        var j = 0;
        var nobj = new Object;
        var len = $scope.gridApi.grid.columns.length;


        angular.forEach($scope.gridApi.grid.columns, function (subval, subkey) {
          //MyService.ConsoleLog("mvalue<>",mvalue);h
          var nfld = subval.field;
          j++;
          //MyService.ConsoleLog("subval.field<>",mvalue[nfld]);
          //var nobj = new Object;
          if (subval.displayName != "" && subval.displayName != " ") {
            nobj[subval.displayName] = mvalue[nfld];
            //MyService.ConsoleLog(nobj);
          }
          if (j == len) {
            nobj.StatePostalCode = $scope.address.state.statePostalCode;
            nobj.CountyName = $scope.address.county.county;
            $scope.promises.push(nobj);
          }
        });
        //var displayColumn = $scope.checkColumn($scope.gridApi.grid.columns, value);
        // $q.all($scope.subpromises).then(function(value, key) {
        //     //MyService.ConsoleLog($scope.subpromises);
        // });
        //$scope.promises.push($scope.subpromises);
      })
      $q.all($scope.promises).then(function () {
        //MyService.ConsoleLog($scope.promises);
        MyService.savePoliticianGrid({
          "records": JSON.stringify($scope.promises)
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          $scope.Show.Loader = false;
          alert("Data saved successfully");
        }, function (errorPayload) {
          $scope.Show.Loader = false;
          alert("Error occurred. Please try again after sometime.");
          //MyService.ConsoleLog(errorPayload);
        });
      });


    }


    $scope.updateRecords = function () {
      $scope.Show.Loader = true;
      $scope.gridDirtyRows = JSON.stringify($scope.gridOptions.data, function (key, value) {
        if (key === "$$hashKey" || key === "__v" || value === null) {
          return undefined;
        }
        return value;
      });
      //MyService.ConsoleLog("gridDirtyRows>>>",$scope.gridDirtyRows);

      MyService.updatePoliticianGrid({
        "records": $scope.gridDirtyRows
      }).then(function (payload) {
        //MyService.ConsoleLog(payload);
        $scope.Show.Loader = false;
        alert("Data updated successfully.");
      }, function (errorPayload) {
        //MyService.ConsoleLog(errorPayload);
        $scope.Show.Loader = false;
        alert("Error occurred. Please try again after sometime.");
      });
    }

    $scope.updateSelectedRecords = function () {
      $scope.Show.Loader = true;
      $scope.gridDirtyRows = JSON.stringify($scope.gridApi.selection.getSelectedRows(), function (key, value) {
        if (key === "$$hashKey" || key === "__v" || value === null) {
          return undefined;
        }
        return value;
      });
      //MyService.ConsoleLog("gridDirtyRows>>>", $scope.gridDirtyRows);
      MyService.updatePoliticianGrid({
        "records": $scope.gridDirtyRows
      }).then(function (payload) {
        //MyService.ConsoleLog(payload);
        $scope.Show.Loader = false;
        alert("Data updated successfully.");
      }, function (errorPayload) {
        //MyService.ConsoleLog(errorPayload);
        $scope.Show.Loader = false;
        alert("Error occurred. Please try again after sometime.");
      });

    }

    $scope.removeDB = function () {
      $scope.Show.Loader = true;
      var allrecords = JSON.stringify($scope.gridApi.selection.getSelectedRows(), function (key, value) {
        if (key === "$$hashKey" || key === "__v" || value === null) {
          return undefined;
        }
        return value;
      });

      var message = 'This action will remove the record from database as well as convert the user type to voter and this cannot be reversed. Do you want to continue?';
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
          MyService.removePoliticianGrid({
            "records": allrecords
          }).then(function (payload) {
            //MyService.ConsoleLog(payload);
            angular.forEach($scope.gridApi.selection.getSelectedRows(), function (data, index) {
              $scope.gridOptions.data.splice($scope.gridOptions.data.lastIndexOf(data), 1);
            });
            $scope.Show.Loader = false;
            alert("Data removed successfully.");
          }, function (errPayload) {
            //MyService.ConsoleLog(errorPayload);
            $scope.Show.Loader = false;
            alert("Error occurred. Please try again after sometime.");
          });
        },
        function (errorPayload) {
          //MyService.ConsoleLog("Error: ", errorPayload);
        });

    }

    $scope.checkifStateExists = function () {
      MyService.checkifStateExists({
        "user": $scope.loginUser
      }).then(function (payload) {
        if (payload > 0) {
          $scope.checkPrevState = true;
        }
      }, function (errorPayload) {
        $scope.checkPrevState = false;
      });
    };

    if ($rootScope.showVUpload) {
      $scope.getAllStates();
      $scope.checkifStateExists();
    }

  }])
