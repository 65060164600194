'use strict';
angular.module('myApp.learnHome', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/learnHome', {
    templateUrl: 'app/learnHome/learnHome.html',
    controller: 'learnHomeCtrl'
  }),
  $routeProvider.when('/catsel-press-essay', {
    templateUrl: 'app/learnHome/cat_sel_press_essay.html',
    controller: 'CatSelPressEssayCtrl'
  }),
  $routeProvider.when('/wheretovote', {
    templateUrl: 'app/learnHome/WhereToVote.html'
  }),
  $routeProvider.when('/wheretogetnews', {
    templateUrl: 'app/learnHome/WhereToGetNews.html'
  }),
  $routeProvider.when('/fakenews', {
    templateUrl: 'app/learnHome/FakeNews.html'
  }),
  $routeProvider.when('/factchecking', {
    templateUrl: 'app/learnHome/FactChecking.html'
  }),
  $routeProvider.when('/politicalDictionary', {
    templateUrl: 'app/learnHome/PoliticalDictionary.html'
  }),
  $routeProvider.when('/basicsofpolitics', {
    templateUrl: 'app/learnHome/basicsofpolitics.html'
  }),
  $routeProvider.when('/aboutus', {
    templateUrl: 'app/learnHome/AboutUs.html'
  });
  $routeProvider.when('/boardsofelections', {
    templateUrl: 'app/boardsofelections/boardsofelections.html'
  });
}])

.controller('learnHomeCtrl', ['$scope','$rootScope', '$http','$location','apiCall', 'baseApiUrl', 'MyService', 'growl', function ($scope, $rootScope, $http, $location, apiCall, baseApiUrl, MyService, growl) {
//MyService.ConsoleLog("LearnHome called");
  $scope.imgUrl = baseApiUrl+"/resources";
$rootScope.welcome = false;
  $scope.statusGetEssayCat = 0;
  function getCategories(params) {
    /*var request = apiCall.apiCall('GET', '/categories/list', {root: 1});
    $http(
      request
    ).then(function successCallback(response) {
    //MyService.ConsoleLog("Got categories: ",response);
      if(response.data.success){
        $scope.categories = response.data.data;
      }
      else{
        //MyService.ConsoleLog("Something went wrong: ",response.data);
      }
    }, function errorCallBack(response) {
      //MyService.ConsoleLog("Error: ",response);
    });*/
    $scope.categories = [];
    $scope.statusGetEssayCat = 1;
    
    MyService.getCategories({root: 1, catTypes: "E"}, null).then(function(response){
      //MyService.ConsoleLog(response);
      if(response.data.success){
        $scope.categories = response.data.data;
        $scope.statusGetEssayCat = 2;
      }
      else{
        //MyService.ConsoleLog("Error: ",response.error);
        growl.error("Something went wrong: ");
        $scope.statusGetEssayCat = 3;
      }
    }, function(){
      growl.error("Something went wrong while fetching categories.");
      $scope.statusGetEssayCat = 3;
    });
  }
  getCategories();
}])
.controller('CatSelPressEssayCtrl', ['$scope','$rootScope', '$http','$location','apiCall', 'baseApiUrl', function ($scope, $rootScope, $http, $location, apiCall, baseApiUrl) {
  //MyService.ConsoleLog("CatSelPressEssayCtrl called");
  $scope.imgUrl = baseApiUrl+"/resources";
  $rootScope.welcome = false;
  function getCategories(params) {
    var request = apiCall.apiCall('GET', '/categories/list', {root: 1});
    $http(
      request
    ).then(function successCallback(response) {
    //MyService.ConsoleLog("Got categories: ",response);
    if(response.data.success){
      $scope.categories = response.data.data;
    }
    else{
      //MyService.ConsoleLog("Something went wrong: ",response.data);
    }
    }, function errorCallBack(response) {
      //MyService.ConsoleLog("Error: ",response);
    });
  }
  getCategories();
}]);

