'use strict';
angular.module('myApp.question', ['ngRoute', 'ngDialog'])
  .run(['$anchorScroll', function($anchorScroll) {
    $anchorScroll.yOffset = 150; // always scroll by 50 extra pixels
  }])
  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/question/:categoryId', {
      templateUrl: 'app/question/question.html',
      controller: 'questionCtrl'
    });
  }])
  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/question-tagged/:user_id', { //pass user_id = me to see questions tagged to self
      templateUrl: 'app/question/question.html',
      controller: 'questionCtrl'
    });
  }]) 
  .controller('questionCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', 'tokenService', '$anchorScroll', '$timeout', function($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, tokenService, $anchorScroll, $timeout) {
    //MyService.ConsoleLog("** questionCtrl ** :", $routeParams);

    MyService.ConsoleLog("** questionCtrl ** :", $scope.politicianName);

    $rootScope.welcome = false;
    $scope.userId = tokenService.getUserId();
    $scope.userRole = tokenService.getUserRole();
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
    }

    $scope.traverseBackgrounds = [];
    $scope.currBackgroundIndex = 0;
    $scope.status = {
      questions: 0, //1= loading, 2 = loaded, 3 = error
      category: 0
    };
    $scope.flagSkipAnsweredQuestion = true;


    $scope.currentIndex = 0;
    $scope.initializeVariables = function() {
      $scope.importance = null;
      $scope.answer = null;
      $scope.comment = '';
      $scope.reason = '';
    };
    $scope.initializeVariables();

    $scope.initMyAnswer = function() {
      try {
        if ($scope.questions[$scope.currentIndex].my_answer) {
          var my_answer = $scope.importance = $scope.questions[$scope.currentIndex].my_answer;
          $scope.importance = my_answer.importance;
          $scope.answer = my_answer.answer;
          $scope.comment = my_answer.comment;
        } else {
          $scope.initializeVariables();
        }
      } catch (e) {
        $scope.initializeVariables();
        //MyService.ConsoleLog("Exceptoin: ", e)
      }
    };

    $scope.viewOldAnswers = function(old_ans) {
      ngDialog.open({
        template: 'app/popups/view_old_answers.html',
        controller: 'OldAnswerViewerCtrl',
        data: old_ans
      });
    };

    if ($rootScope.userRole == "advocate") {
      var request = apiCall.apiCall('GET', '/user/list', {
        "usertype": "politician"
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.tagableUsers = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }
    $scope.addTags = function(tagUsers) {
      //MyService.ConsoleLog("tagUsers: ", tagUsers);
      //MyService.ConsoleLog("tagUsers: ", $scope.questions[$scope.currentIndex]._id);

      var postData = {
        id: $scope.questions[$scope.currentIndex]._id,
        user_ids: tagUsers.join(",")
      };

      var request = apiCall.apiCall('POST', '/questions/add_tag', postData);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("succss: ", response);
        if (response.data.success) {
          growl.success("Tagged successfully");
        } else {
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallback(response) {
        growl.error("Something went wrong");
      });
    };

    var getQuestionsCanceler = $q.defer();
    $scope.questions = [];
    $scope.fbwallDlg = "";
    $scope.oldQuestions = [];

    function getMoreQuestions(params) {
      getQuestionsCanceler.resolve();
      $scope.status.questions = 1;
      $scope.questions.length = 0;
      if ($scope.fbwallDlg != "") {
        //MyService.ConsoleLog($scope.fbwallDlg);
        ngDialog.close($scope.fbwallDlg.id);
      }
      params.timeout = getQuestionsCanceler.promise;
      MyService.ConsoleLog("questionCtrl** /questions/list: ", params);
      var request = apiCall.apiCall('GET', '/questions/list', params);

      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("questionCtrl Got categories: ", response);
        if (response.data.success) {
          $scope.status.questions = 2;
          $scope.initMyAnswer();
          MyService.ConsoleLog("data len: ", response.data.data);
          if ($scope.flagSkipAnsweredQuestion) {
            angular.forEach(response.data.data, function(val, key) {
              if (val.my_answer == null)
                $scope.questions.push(val);
            });
          } else {
            $scope.questions = response.data.data;
          }
          //$scope.oldQuestions = angular.copy($scope.questions);
          //MyService.ConsoleLog("total questions: ", $scope.questions.length);
        } else {
          $scope.status.questions = 3;
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    }
    $scope.retainOptions = "";
    $scope.fb = {
      options: "C"
    };

    $scope.postOnWall = function(message) {
      var body = message;
      var link = 'https://votewise.net/#!/question-background/' + $routeParams.categoryId + '/' + $rootScope.OldCatforFB._id;
      var picture = 'https://votewise.net:8443/resources/education-general-1484943663025.jpg';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbImage && $rootScope.OldCatforFB.fbImage != null) {
        picture = "https://votewise.net:8443/resources/" + $rootScope.OldCatforFB.fbImage;
      }

      //MyService.ConsoleLog(picture);
      //var picture = '';
      //MyService.ConsoleLog($rootScope.OldCatforFB);
      var name = 'What makes a good education?';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbTitle) {
        name = $rootScope.OldCatforFB.fbTitle;
      }
      var description = 'How do we make our education system better?  Take the quiz to learn more, tell your politicians what they should do, and compare with your friends.';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbDescription) {
        description = $rootScope.OldCatforFB.fbDescription;
      }
      FB.api('/me/feed', 'post', {
        message: body,
        link: link,
        picture: picture,
        name: name,
        description: description
      }, function(response) {
        //MyService.ConsoleLog(response);
        $scope.DisplayFPostLoader = false;
        if (!response || response.error) {
          var errmsg = "Facebook is taking too long to respond. Should we try again?"
          var modal = ngDialog.openConfirm({
            template: '<p>' + errmsg + '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
            plain: true
          });
          modal.then(function fullfilled(tdata) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (tdata == 1) {
              $scope.postOnWall(message);
            }

          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          });
        } else {

          var msg = "Message is successfully posted on facebook wall. Do you want to continue with Answering questions?";
          var modal = ngDialog.openConfirm({
            template: '<p>' + msg + '</p>\
                            <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(2)">NO</button>\
                                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                            </div>',
            plain: true
          });
          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (data == 1) {
              getMoreQuestions($scope.retainOptions);
            }
            ngDialog.close();
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            ngDialog.close();
          });
          // alert('Post ID: ' + response.id);
        }
      });
    }
    $scope.DisplayFPostLoader = false;
    $scope.postFB = function(message) {
      //alert("This needs to be implemented");
      $scope.DisplayFPostLoader = true;
      FB.getLoginStatus(function(response) {
        //MyService.ConsoleLog(response);

        if (response.status == 'unknown') {
          FB.login(function(response) {
            //statusChangeCallback(response, 'login');
            //MyService.ConsoleLog(response);
            MyService.updateFBID({
              "facebookid": response.authResponse.userID
            }).then(function(payload) {
              //MyService.ConsoleLog(payload);
              //MyService.ConsoleLog(payload.data.error);
              if (payload.data.error && payload.data.error == "FBDUPLICATE") {
                $scope.DisplayFPostLoader = false;
                alert("Facebook user doesn't match with your account.");
              } else {
                $scope.postOnWall(message);
              }

            }, function(errpayload) {
              $scope.DisplayFPostLoader = false;

              var errmsg = "Facebook is taking too long to respond. Should we try again?"
              var modal = ngDialog.openConfirm({
                template: '<p>' + errmsg + '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
                plain: true
              });
              modal.then(function fullfilled(tdata) {
                //MyService.ConsoleLog("fullfilled: ", data);
                if (tdata == 1) {
                  $scope.postOnWall(message);
                }

              }, function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              });

              // alert("Facebook is taking too long to respond. Should we try again?");
            });
          }, {
            scope: 'public_profile,email,publish_actions',
            return_scopes: true
          });
        } else {
          if ($scope.userData && $scope.userData.facebookid) {
            if (response.authResponse.userID == $scope.userData.facebookid) {
              $scope.postOnWall(message);
            } else {
              $scope.DisplayFPostLoader = false;
              alert("Facebook user doesn't match with your account.");
            }
          } else {
            $scope.postOnWall(message);
          }
        }
      });
    }

    $scope.fbFrdDlg = "";
    $scope.FBfreinds = [];
    $scope.FBfreindsFinal = [];
    $scope.DisplayFLoader = false;
    $scope.checkFBFreinds = function() {
      $scope.DisplayFLoader = true;
      FB.login(function(loginData) {
        //MyService.ConsoleLog(loginData);
        FB.api('/me/friends', function(response) {
          //MyService.ConsoleLog(response);
          $scope.FBfreindsFinal.length = 0;
          if (response && response.data) {
            var frdata = response.data;
            $scope.FBfreindsFinal = response.data;
            if (frdata.length > 0) {
              var results = frdata.map(function(frdata) {
                return frdata.id;
              });
              //MyService.ConsoleLog(results);
              var sendfrd = JSON.stringify(results);
              //MyService.ConsoleLog(sendfrd);
              MyService.checkfreindsIfanswered({
                "friends": sendfrd,
                "categoryId": $rootScope.OldCatforFB._id
              }).then(function(payload) {
                //MyService.ConsoleLog("payload:", payload);
                $scope.selection.length = 0;
                var allfriends = payload.data.data;
                $scope.FBfreinds.length = 0;
                //MyService.ConsoleLog("allfriends:::", allfriends);
                $scope.DisplayFLoader = false;
                if (allfriends.length > 0) {
                  //$scope.FBfreinds.length = 0;
                  //MyService.ConsoleLog("inside frdata >> ", frdata);
                  var fresults = $scope.FBfreindsFinal.map(function(frdata) {
                    //MyService.ConsoleLog(frdata.id);
                    //MyService.ConsoleLog(allfriends.indexOf(frdata.id));
                    if (allfriends.indexOf(frdata.id) > -1) {
                      $scope.FBfreinds.push(frdata);
                      return frdata;
                    }
                  });
                  //MyService.ConsoleLog(fresults);
                  //$scope.FBfreinds = fresults;
                  //MyService.ConsoleLog($scope.FBfreindsFinal);
                  if ($scope.fbwallDlg != "") {
                    //MyService.ConsoleLog($scope.fbwallDlg);
                    ngDialog.close($scope.fbwallDlg.id);
                  }
                  $scope.fbFrdDlg = ngDialog.open({
                    template: 'FBFreindList',
                    scope: $scope
                  });
                } else {

                  alert("none of your friends have answered yet. You should write them and ask them to answer");
                }



              }, function(errpayload) {
                $scope.DisplayFLoader = false;
                //MyService.ConsoleLog("errpayload", errpayload);
              })



            } else {
              $scope.DisplayFLoader = false;
              alert("Your freinds not yet joined VoteWise.net. Ask them to join by posting a message on Facebook Wall?");
            }
          } else {
            $scope.DisplayFLoader = false;
            //MyService.ConsoleLog('Something goes wrong', response);
          }
        }, {
          scope: 'user_friends'
        });
      }, {
        scope: 'user_friends',
        auth_type: 'rerequest'
      });
    }

    $scope.selection = [];

    // helper method to get selected fruits
    $scope.selectedFrds = function() {
      return filterFilter($scope.FBfreinds, {
        selected: true
      });
    };

    // watch fruits for changes
    // $scope.$watch('FBfreinds|filter:{selected:true}', function(nv) {
    //     $scope.selection = nv.map(function(FBfreinds) {
    //         return FBfreinds.id;
    //     });
    // }, true);

    $scope.selectedFrd = function(frd) {
      //MyService.ConsoleLog(frd);
      var idx = $scope.selection.indexOf(frd.id);
      if (idx == -1) {
        $scope.selection.push(frd.id);
      } else {
        $scope.selection.splice(idx, 1);
      }
    }


    $scope.displayMatch = function() {
      //alert("This needs to be implemented");
      FB.getLoginStatus(function(response) {
        //MyService.ConsoleLog(response);
        if (response.status == 'unknown') {
          FB.login(function(response) {
            //statusChangeCallback(response, 'login');
            //MyService.ConsoleLog(response);
            MyService.updateFBID({
              "facebookid": response.authResponse.userID
            }).then(function(payload) {
              if (payload.data.error && payload.data.error == "FBDUPLICATE") {
                $scope.DisplayFLoader = false;
                alert("Facebook user doesn't match with your account.");
              } else {
                $scope.checkFBFreinds();
              }

            }, function(errpayload) {
              $scope.DisplayFLoader = false;
              alert("Something went wrong. Please try again later.");
            });
          }, {
            scope: 'public_profile,email,publish_actions',
            return_scopes: true
          });
        } else {
          // if($scope.userData && $scope.userData.facebookid) {
          //     if(response.authResponse.userID==$scope.userData.facebookid ) {
          //         var message = "Here is some message";
          //         $scope.postOnWall(message);
          //     } else {
          //         $scope.DisplayFLoader = false;
          //        alert("Facebook user doesn't match with your account.");
          //     }
          // } else {
          //     $scope.checkFBFreinds();
          // }
          $scope.checkFBFreinds();
        }
      });

    }
    $scope.FBCompareList = [];
    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;
    $scope.ComapreWithSelected = function() {
      //MyService.ConsoleLog($scope.selection);
      //MyService.ConsoleLog("ComapreWithSelected");
      //MyService.ConsoleLog($rootScope.OldCatforFB._id);
      //MyService.ConsoleLog($scope.retainOptions);
      if ($scope.selection.length > 0) {
        var frdlist = JSON.stringify($scope.selection);
        MyService.CompareWithFreinds({
          "frds": frdlist,
          "categoryIds": $rootScope.OldCatforFB._id
        }).then(function(payload) {
          //MyService.ConsoleLog(payload);
          $scope.FBCompareList.length = 0;
          if (payload.data.success) {
            //MyService.ConsoleLog(payload.data.data);
            $scope.FBCompareList = payload.data.data.qdata;
            if ($scope.FBCompareList.length > 0) {
              ngDialog.open({
                template: 'FBFreindCompare',
                scope: $scope
              });
              $scope.FBunWeighted = payload.data.data.unweightedMatch;
              $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
              $scope.fbmultiplier = payload.data.data.fbmultiplier;
              $scope.fbqlen = payload.data.data.qlen;
            } else {
              alert("Looks like your freinds not yet answered the questions.");
              //MyService.ConsoleLog($scope.FBCompareList);
            }
          } else {
            alert("Something went wrong.");
          }
        }, function(errorpayload) {
          alert("Something went wrong.");
        })
      } else {
        alert("Please select at least one Friend to compare with");
      }
    }

    $scope.allQuestions = [];
    $scope.chooseQuestions = function() {
      //MyService.ConsoleLog($scope.oldQuestions);
      $scope.allQuestions = $scope.oldQuestions;
      if ($scope.allQuestions.length > 0) {
        ngDialog.open({
          template: 'ReviewMyAnswers',
          scope: $scope
        });
      } else {
        alert("There are no questions to display. Please go through all questions first.")
      }
    }
    //$scope.switchStatus = "Show";
    $scope.showloader = false;
    $scope.switchFilters = function(newValue, index, quest) {
      $scope.showloader = true;
      //MyService.ConsoleLog("newValue::", newValue);
      //MyService.ConsoleLog("oldValue::", $scope.switchStatus);
      var setVal = "N";
      if (newValue[index] == true) {
        setVal = "Y";
      }

      var idx = $scope.allQuestions.indexOf(quest);
      //MyService.ConsoleLog("setVal:", setVal);
      MyService.updateAnswers({
        "qid": quest._id,
        "show": setVal,
        "author": quest.author
      }).then(function(payload) {
        //MyService.ConsoleLog("payload : ", payload);
        $scope.showloader = false;
        if (payload.data.success) {
          quest.my_answer.show = setVal;
          $scope.allQuestions[idx] = quest;
          alert("Record updated successfully");
        } else {
          alert("Something went wrong. Contact administrator.");
        }
      }, function(errorpayload) {
        $scope.showloader = false;
        //MyService.ConsoleLog("errorpayload : ", errorpayload);
        alert("Something went wrong. Contact administrator.");
      });
    }


    $scope.NextOptions = function(option) {
      //MyService.ConsoleLog(option);
      switch (option) {
        case "C":
          getMoreQuestions($scope.retainOptions);
          break;
        case "P":
          //$scope.postFB();
          $scope.fbFrdDlg = ngDialog.open({
            template: 'FBComposeMessage',
            scope: $scope
          });
          break;
        case "Q":
          $scope.chooseQuestions();
          break;
        case "M":
          $scope.displayMatch();
          break;
        case "F":
          ngDialog.close($scope.fbwallDlg.id);
          $location.path('/findMatch/');
          break;
        default:
          getMoreQuestions($scope.retainOptions);
          break;
      }

    }

    $scope.ord = {
      "Option": "BW"
    };

    $scope.checkMyOrder = function(frnd) {
      //MyService.ConsoleLog($scope.ord.Option);
      switch ($scope.ord.Option) {
        case "BW":
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "W":
          return ($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "BS":
          return -($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        case "S":
          return ($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        default:
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;

      }

    }



    function getQuestions(params) {
      MyService.ConsoleLog("getQuestions params");
      MyService.ConsoleLog(params);
      //MyService.ConsoleLog($rootScope.OldCatforFB);
      $scope.oldQuestions = angular.copy($scope.questions);
      $scope.questions.length = 0;
      $scope.currentIndex = 0;
      $scope.retainOptions = params;
      //MyService.ConsoleLog($scope.retainOptions);
      //MyService.ConsoleLog($routeParams.selectedCatId);

      //MyService.ConsoleLog(params.categoryId + "======" + $routeParams.selectedCatId);
      if (params.categoryId != $routeParams.selectedCatId) {
        //MyService.ConsoleLog($scope.retainOptions);
        $scope.fbwallDlg = ngDialog.open({
          template: 'nextQuestionOptions',
          scope: $scope
        });
        //MyService.ConsoleLog($scope.fbwallDlg);
        //getMoreQuestions(params);
      } else {
        // ngDialog.open({
        //     template: 'nextQuestionOptions',
        //     scope: $scope
        // });
        getMoreQuestions(params);
      }
    }

    var getCategoryCanceler = $q.defer();
    var prevFetchedCategoryId = null;

    $scope.getCategory = function(params) {
      try {
        prevFetchedCategoryId = $scope.category._id
      } catch (e) {}

      if (prevFetchedCategoryId != params.id) {
        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        getCategoryCanceler.resolve();
        getCategoryCanceler = $q.defer();
        $scope.status.category = 1;
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = getCategoryCanceler.promise;
        $http(
            request
          )
          .then(function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.category = response.data.data;
              //MyService.ConsoleLog("category data: ", $scope.category);
              prevFetchedCategoryId = $scope.category._id;
              $scope.status.category = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.category = 3;
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.category = 3;
          });
      }
    };

    var getLevelCategoryCanceler = $q.defer();

    function getLevelCategory(params) {
      getLevelCategoryCanceler.resolve();
      getLevelCategoryCanceler = $q.defer();
      $scope.status.levlCat = 1;
      var request = apiCall.apiCall('GET', '/categories/list-layer', params);
      request.timeout = getLevelCategoryCanceler.promise;
      $http(
          request
        )
        .then(function successCallback(response) {
          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            /*
            angular.forEach(response.data.data, function(val, key){
                if(val.total_questions > 0){
                    $scope.traverseBackgrounds.push(val);       
                }
            });
            //MyService.ConsoleLog("traverseBackgrounds data: ", $scope.traverseBackgrounds);
            if($scope.traverseBackgrounds.length > 0){
                $scope.currBackgroundIndex = 0;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            }
            */
            $scope.traverseBackgrounds = response.data.data;
            if ($routeParams.selectedCatId) {
              //MyService.ConsoleLog("traverseBackgrounds: ", $scope.traverseBackgrounds);
              //MyService.ConsoleLog("$routeParams.selectedCatId: ", $routeParams.selectedCatId);
              angular.forEach($scope.traverseBackgrounds, function(val, key) {
                if (val._id == $routeParams.selectedCatId) {
                  //MyService.ConsoleLog("val._id: ", val._id, " key: ", key, " total_questions: ", val.total_questions);
                  $scope.currBackgroundIndex = key;
                  //MyService.ConsoleLog("$scope.currBackgroundIndex: ", key);
                  $scope.category = angular.copy(val);
                  MyService.ConsoleLog($scope.category);
                }
              });
            } else if ($scope.traverseBackgrounds.length > 0) {
              $scope.category = $scope.traverseBackgrounds[0];
            }
            $scope.status.levlCat = 2;
          } else {
            //MyService.ConsoleLog("Something went wrong: ", response.data);
            $scope.status.levlCat = 3;
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
          $scope.status.levlCat = 3;
        });
    }

    function getPopularQuestions() {
      //MyService.ConsoleLog("** getPopularQuestions **");
      var categoryIds = $routeParams.categoryId.split("popular:")[1];
      var request = apiCall.apiCall('GET', '/answers/popular', {
        "categoryIds": categoryIds
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.questions = response.data.data;
          if ($scope.questions.length > 0) {
            $scope.getCategory({
              id: $scope.questions[0].categories.cid
            });
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }

    var getPopularCategoriesCanceler = $q.defer();
    $scope.getPopularCategories = function(params) {
      //MyService.ConsoleLog("** getPopularQuestions **");
      getPopularCategoriesCanceler.resolve();
      params.timeout = getPopularCategoriesCanceler.promise;

      var request = apiCall.apiCall('POST', '/categories/popular-subcategories', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.traverseBackgrounds = response.data.data;
          if ($scope.traverseBackgrounds.length > 0) {
            $scope.category = $scope.traverseBackgrounds[0];
            prevFetchedCategoryId = $scope.category._id;
            // getQuestions({categoryId: $scope.category._id});
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.notAnswerableSelected = function() {
      /*
      if($scope.answer == 0){
          var message = 'Many of our Statements have an undeniable fact followed by an opinion.  Like “the sky is blue.  I hate the sky.”  If this is your problem with the question, then realize that this is just part of the question process and just respond to the second half.  If not, please explain the problem in the “add comments” section so we can fix it.  Thank you.';
          var modal = ngDialog.openConfirm({
              template:'<p>'+message+'</p>\
                      <div class="ngdialog-buttons">\
                          <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                      </div>',
              plain: true
          });
          
          modal.then(function fullfilled(data){
              //MyService.ConsoleLog("fullfilled: ",data);
          }, function rejected(data){
              //MyService.ConsoleLog("rejected: ",data);
          });
      }
      */
      //for report question
      var token = apiCall.getToken();

      if (!token || token == "") {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }
      var postData = {
        questionId: $scope.questions[$scope.currentIndex]._id,
        importance: 0, //$scope.importance,
        answer: $scope.answer,
        comment: $scope.comment
      };

      ngDialog.open({
        template: 'app/popups/report_question.html',
        controller: 'ReportQuesCtrl',
        data: postData
      });

    };

    $scope.postAnswer = function(postData) {
      //MyService.ConsoleLog("postData: ", postData);
      try {
        var my_answer = $scope.questions[$scope.currentIndex].my_answer;
        if (my_answer.importance != $scope.importance || my_answer.answer != $scope.answer || my_answer.comment != $scope.comment) {
          //MyService.ConsoleLog("Answer changed");
          postData.id = my_answer._id;
          postData.reason = $scope.reason;
        } else {
          //MyService.ConsoleLog("Answer not changed");
          return; //don't do anything
        }
      } catch (e) {
        //MyService.ConsoleLog("Exceptoin: ", e);
      }
      console.log("postData > ", postData);
      MyService.postAnswer(postData).then(function(response) {
        if (response.data.success) {
          try {
            if (postData.id) {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: postData.id,
                created: new Date(),
                comment: postData.comment,
                answer: postData.answer,
                importance: postData.importance
              };
            } else {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: response.data.data._id,
                created: response.data.data.created,
                comment: response.data.data.comment,
                answer: response.data.data.answer,
                importance: response.data.data.importance
              };
            }
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin: ", e);
          }
          if (postData.id) {
            growl.success("Answer updated successfully", {
              ttl: 800
            });
          } else {
            growl.success("Answered successfully", {
              ttl: 800
            });
          }
          $scope.initializeVariables();
          $scope.skipQuestion();
        } else {
          growl.error("Error: " + response.data.error);
        }
      }, function(err) {
        growl.error("Something went wrong.");
      });


      /*
        var request = apiCall.apiCall('POST', '/answers/create', postData);
        $http(
          request
        ).then(function successCallback(response) {
          //MyService.ConsoleLog("succss: ",response);
          if(response.data.success){
            try{
                $scope.questions[$scope.currentIndex]["my_answer"] = {
                    _id: response.data.data._id,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance
                };
            }
            catch(e){
                //MyService.ConsoleLog("Exceptoin: ",e);
            }
            growl.success("Answered successfully", {ttl: 800});
            $scope.initializeVariables();
            $scope.skipQuestion();
          }
          else{
            growl.error("Error: "+response.data.error);
          }
        }, function errorCallback(response) {
          $scope.error = 'Wrong Email or Password';
        });
        */

    };

    $rootScope.$on('question.answered', function(evt, data) {
      MyService.ConsoleLog("on question.answered: ", data);
      if ($scope.questions[$scope.currentIndex]._id == data.questionId) {
        $scope.initializeVariables();
        $scope.skipQuestion();
      }
    });

    $scope.submitAnswer = function() {
      //MyService.ConsoleLog("submitAnswer: " + $scope.answer);
      //MyService.ConsoleLog("importance: " + $scope.importance);
      //MyService.ConsoleLog("comments: ", $scope.comment);
      var token = apiCall.getToken();
      if (!token || token == "") {
        var message = "Please login first.";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });
        return;
      }

      if ($scope.answer == null) {
        var message = "Please select answer first.";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });
        return;
      }

      if ($scope.importance == null) {
        var message = "You gave your opinion, but you didn’t say how important this is to you.  Should they fight hard for this or can they use it as a bargaining chip to get other things you care about more?";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }

      var postData = {
        questionId: $scope.questions[$scope.currentIndex]._id,
        importance: $scope.importance,
        answer: $scope.answer,
        comment: $scope.comment,
        token: token
      };
      $scope.postAnswer(postData);
    };

    $scope.skipQuestion_for_skipansweredQues = function() {
      //MyService.ConsoleLog("skipQuestion: ", $scope.flagSkipAnsweredQuestion);
      var skip = function() {
        var tempIndex = $scope.currentIndex + 1;
        if ($scope.flagSkipAnsweredQuestion && tempIndex < $scope.questions.length && $scope.questions[tempIndex].my_answer) {
          $scope.currentIndex++;
          skip(); //call recursive function to move to next question
          return;
        }

        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();
          $scope.currentIndex++;
          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            //we are showing questions of most popular section
            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        //MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ",$scope.questions.length);
        if (tempIndex == $scope.questions.length) {
          //MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            $scope.currBackgroundIndex++;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            //MyService.ConsoleLog("* End of background *");
            $location.path('/questionSubcategories/' + $routeParams.categoryId);
          }
        }

        $timeout(function() {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      };

      if ($scope.answer != null) {
        var show_answer_modal = true;
        try {
          var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];
          if (my_answer.importance == $scope.importance && my_answer.answer == $scope.answer && my_answer.comment == $scope.comment)
            show_answer_modal = false;

        } catch (e) {
          //MyService.ConsoleLog("Exceptoin : ", e);
        }
        //MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
        if (show_answer_modal) {
          var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                            </div>',
            plain: true,
            closeByDocument: false
          });

          modal.then(function fullfilled(data) {
            //submit answer
            $scope.submitAnswer();
          }, function rejected(data) {
            //skip the answer
            skip();
          });
        } else {
          skip();
        }
      } else {
        skip();
      }
    };
    $scope.prevQuestion_for_skipansweredQues = function() {
      //MyService.ConsoleLog("** prevQuestion **", $scope.currentIndex, "currBackgroundIndex: ", $scope.currBackgroundIndex);
      if ($scope.currentIndex > 0) {
        //MyService.ConsoleLog("$scope.currentIndex: ", $scope.currentIndex);
        // $scope.currentIndex--;
        var tempIndex = $scope.currentIndex - 1;
        //MyService.ConsoleLog("tempIndex: ", tempIndex);
        if ($scope.flagSkipAnsweredQuestion && tempIndex > 0 && $scope.questions[tempIndex].my_answer) {
          //MyService.ConsoleLog("Inside if");
          $scope.currentIndex--;
          $scope.prevQuestion(); //call recursive function to move to next question
          return;
        } else {
          //MyService.ConsoleLog("Inside else");
          $scope.currentIndex--;
          if ($scope.currentIndex == 0 && $scope.questions[tempIndex].my_answer && $scope.flagSkipAnsweredQuestion)
            $scope.prevQuestion();
        }

      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
        }
      }
    };

    $scope.skipQuestion = function() {


      var skip = function() {
        var tempIndex = $scope.currentIndex + 1;
        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();
          $scope.currentIndex++;
          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            //we are showing questions of most popular section
            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ", $scope.questions.length);
        if (tempIndex == $scope.questions.length) {
          MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            $scope.currBackgroundIndex++;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            MyService.ConsoleLog("* End of background *");
            $location.path('/questionSubcategories/' + $routeParams.categoryId);
          }
        }

        $timeout(function() {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      };

      if ($scope.answer != null) {
        var show_answer_modal = true;
        try {
          var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];

          /*for (var key in my_answer) {
            if(my_answer[])
          }*/
          if (my_answer.importance == $scope.importance && my_answer.answer == $scope.answer && my_answer.comment == $scope.comment)
            show_answer_modal = false;

        } catch (e) {
          //MyService.ConsoleLog("Exceptoin : ", e);
        }
        //MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
        if (show_answer_modal) {
          var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                            </div>',
            plain: true,
            closeByDocument: false
          });

          modal.then(function fullfilled(data) {
            //submit answer
            $scope.submitAnswer();
          }, function rejected(data) {
            //skip the answer
            skip();
          });
        } else {
          skip();
        }
      } else {
        skip();
      }
    };
    $scope.prevQuestion = function() {
      //MyService.ConsoleLog("** prevQuestion **");

      if ($scope.currentIndex > 0) {
        $scope.currentIndex--;
        $timeout(function() {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
        }
      }
    };

    $scope.compareChanged = function(compare_with) {
      //MyService.ConsoleLog("compare_with :", compare_with);
      var catid = $routeParams.categoryId;
      if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
        var catid = $routeParams.categoryId.split("popular:")[1];
      }

      $location.path('/compare-select/' + compare_with);
    };

    var getCompareAnswersCanceler = $q.defer();

    function getCompareAnswers(params) {
      if (!params) return;
      getCompareAnswersCanceler.resolve();
      params.timeout = getCompareAnswersCanceler.promise;
      $scope.questions = [];
      $scope.status.questions = 1;
      var request = apiCall.apiCall('GET', '/answers/compare', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.status.questions = 2;
          angular.forEach(response.data.data, function(val, key) {
            if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
              var check_author_exist = function(u) {
                var len = val.answers.length;
                for (var i = 0; i < len; i++) {
                  if (val.answers[i].author._id == u._id) {
                    return true;
                  }
                }
                return false;
              };

              angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                if (!check_author_exist(u)) {
                  response.data.data[key].answers.push({
                    author: u
                  });
                }
              });

            } else if (!val.answers) {
              response.data.data[key].answers = [];

              angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                response.data.data[key].answers.push({
                  author: u
                });
              });

            }
          });

          $scope.questions = response.data.data;
          // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
        } else {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.initPageData = function() {
      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);

      if (current_url.indexOf("question-compare") > -1) {
        $scope.state = "question-compare";
        if ($routeParams.categoryId == 'all') {
          var param = {
            id: $routeParams.parentId,
            questions_counter: 1
          };
          getLevelCategory(param);
        } // traverse all questions
        else {
          //MyService.ConsoleLog("** question-compare **");
          //MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);
          $scope.questions = $rootScope.compareAnswerList;
          $scope.getCategory({
            id: $routeParams.categoryId
          });
        }
      } else if (current_url.indexOf("question-tagged") > -1) {
        $scope.state = "question-tagged";
        getQuestions({
          "tagged_to": $routeParams.user_id
        });
      } else if (current_url.indexOf("question-background") > -1) {
        $scope.state = "question-background";
        var param = {
          id: $routeParams.categoryId,
          questions_counter: 1
        };
        getLevelCategory(param);
      } else if (current_url.indexOf("question-search") > -1) {
        $scope.state = "question-background";
        var param = {
          keyword: 'test',
        };
        getQuestions(param);
      } else {
        $scope.state = "question";
        if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
          // getPopularQuestions();
          var categoryId = $routeParams.categoryId.split("popular:")[1];
          $scope.getPopularCategories({
            parentId: categoryId
          })
        } else {
          // getQuestions({categoryId: $routeParams.categoryId});
          $scope.getCategory({
            id: $routeParams.categoryId
          });
        }
      }
    };
    $scope.initPageData();
    $scope.skipAnswerChagne = function() {
      //MyService.ConsoleLog("$scope.flagSkipAnsweredQuestion: ", $scope.flagSkipAnsweredQuestion);
      $scope.currentIndex = 0;
      // $scope.initPageData();
      getQuestions({
        categoryId: $scope.category._id
      });
    };


    // $scope.matchQuestion = function() {
    //   // ngDialog.open({
    //   //   template: 'app/popups/matchQuestion.html'
    //   //   // className: 'ngdialog-theme-default'
    //   // });

    // }

    $scope.$watch('category', function(newValue, oldValue) {
      var current_url = $location.url();
      //MyService.ConsoleLog("oldValue: ", oldValue);
      MyService.ConsoleLog("newValue: ", newValue);
      $rootScope.OldCatforFB = oldValue;
      if (current_url.indexOf("question-tagged") > -1)
        return; //for tagged questions don't again fetch questions

      if (newValue) {
        //MyService.ConsoleLog("oldValue: ", oldValue);
        //MyService.ConsoleLog("newValue: ", newValue);
        if (oldValue && oldValue._id == newValue._id) return;

        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        $scope.currentIndex = 0;

        if (current_url.indexOf("question-compare") > -1) {
          //MyService.ConsoleLog("** getCompareAnswers **");

          var temp_uids = [];
          angular.forEach($rootScope.selectedCompareUsers, function(val, key) {
            temp_uids.push(val._id);
          });

          getCompareAnswers({
            "categoryIds": newValue._id,
            "userIds": temp_uids.join(",")
          }); //"compare_with": $routeParams.user_type, 
        } else {
          getQuestions({
            categoryId: newValue._id
          });
        }
      }
    });

    $scope.$watch('questions', function(newValue, oldValue) {
      try {
        MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if ($scope.questions[$scope.currentIndex] && $scope.questions[$scope.currentIndex].my_answer) {
          if ($scope.questions[$scope.currentIndex].my_answer.comment) {
            $scope.comment = $scope.questions[$scope.currentIndex].my_answer.comment;
          }

        }
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("questions Index Change Ex: ", e)
      }
    });

    $scope.$watch('currentIndex', function(newValue, oldValue) {
      try {
        //MyService.ConsoleLog($scope.currentIndex);
        //MyService.ConsoleLog($scope.questions.length);
        MyService.ConsoleLog("Current currentIndex: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("Current Index Change Ex: ", e)
      }
    });

    $scope.checkIfExists = function(val) {
      // console.log("questions <><><>", $scope.questions);
      // MyService.ConsoleLog("Val > ", val);
      var obj = $scope.questions[$scope.currentIndex];
      // MyService.ConsoleLog("checkIfExists >> ", obj);
      if (obj) {
        var data = obj.my_answer;
        // console.log("data <>", data);
        if (data) {
          // MyService.ConsoleLog("checkIfExists data>> ", data.answer + "==" + val);
          if (data.answer == val) {
            return true;
          }

        }
      }
      return false;
    }

    $scope.subNew = function(valOne) {
      $scope.importance = valOne;
      $scope.submitAnswer();
    };

    $scope.checkImpExists = function(val) {
      var obj = $scope.questions[$scope.currentIndex];
      // MyService.ConsoleLog(" qobject :", obj);
      if (obj) {
        var data = obj.my_answer;

        if (data && data) {
          // MyService.ConsoleLog("Importance data>> ", data.importance);
          if (data.importance == val) {
            return true;

          }
        }
      }
      return false;
    }


  }])
  .controller('ReportQuesCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', '$anchorScroll', '$timeout', function($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, $anchorScroll, $timeout) {
    //MyService.ConsoleLog("** ReportQuesCtrl **");
    //MyService.ConsoleLog("ngDialog data: ", $scope.ngDialogData);
    $rootScope.welcome = false;
    $scope.ngDialogData.comment = '';

    $scope.postAnswer = function() {
      var postData = {
        id: $scope.ngDialogData.questionId,
        // importance: 0,
        // answer: 0,
        comment: $scope.ngDialogData.comment
      };

      //MyService.ConsoleLog("postData: ", postData);

      var request = apiCall.apiCall('POST', '/questions/report', postData);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("succss: ", response);
        if (response.data.success) {
          $rootScope.$broadcast("question.answered", {
            "questionId": $scope.ngDialogData.questionId
          });
          $scope.closeThisDialog();
          growl.success("Reported successfully");
        } else {
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallback(response) {
        growl.success("Something went wrong.");
      });
    };
  }])
  .controller('OldAnswerViewerCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', '$timeout', function($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, $timeout) {
    //MyService.ConsoleLog("** OldAnswerViewerCtrl **");
    //MyService.ConsoleLog("ngDialog data: ", $scope.ngDialogData);
    $rootScope.welcome = false;
    $scope.QuestionAnsArray = {};
    $scope.QuestionAnsArray[1] = 'Very strongly agree';
    $scope.QuestionAnsArray[2] = 'Agree';
    $scope.QuestionAnsArray[3] = 'Sort of agree';
    $scope.QuestionAnsArray[4] = 'Sort of disagree';
    $scope.QuestionAnsArray[5] = 'Disagree';
    $scope.QuestionAnsArray[6] = 'Very strongly disagree';

    $scope.QuestionImpArray = {};
    $scope.QuestionImpArray[1] = 'Somewhat';
    $scope.QuestionImpArray[2] = 'Important';
    $scope.QuestionImpArray[3] = 'Very Important';


  }])
  .controller('matchQuestion', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService) {
    //MyService.ConsoleLog("** matchQuestion **");
    $rootScope.welcome = false;
    $scope.AllPolitician = [];
    MyService.matchQuestionwithPolitician().then(function(payload) {
      //MyService.ConsoleLog(payload);
      if (payload.data.success) {

        $scope.AllPolitician = payload.data.data.allUsers;
        $scope.FBunWeighted = payload.data.data.unweightedMatch;
        $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
        $scope.fbmultiplier = payload.data.data.fbmultiplier;
        $scope.fbqlen = payload.data.data.qlen;
      } else {
        alert("Something went wrong. Please try again later");
      }
    }, function(errpayload) {
      //MyService.ConsoleLog(errpayload);
    });
    //MyService.ConsoleLog("CommentTxtViewerCtrl: ", $scope.ngDialogData);
  }])
  .controller('CommentTxtViewerCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', function($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl) {
    //MyService.ConsoleLog("** CommentTxtViewerCtrl **");
    //MyService.ConsoleLog("CommentTxtViewerCtrl: ", $scope.ngDialogData);
    $rootScope.welcome = false;

  }]);;
