"use strict";
angular
  .module("myApp.dashboard", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/dashboard", {
        templateUrl: "app/dashboard/dashboard.html",
        controller: "dashboardCtrl",
      });
    },
  ])
  .controller("dashboardCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      tokenService,
      MyService
    ) {
      //MyService.ConsoleLog("** dashboardCtrl **");
      $rootScope.welcome = false;
      $scope.allParentCats = [];
      $scope.statusGetCats = 0;
      $scope.alert_msg = false;
      $scope.success_msg = false;
      // $scope.search = false;

      $rootScope.getParentCategories = function (idx) {
        //MyService.ConsoleLog("getParentCategories: ", idx);
        if (idx == 0) {
          $scope.allParentCats = [];
        }
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
          questions_counter: 0,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              $scope.parentCategories = response.data.data;
              /*
          $scope.selectedCategories = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForQues = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForNewCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];*/
              $scope.allParentCats[idx] = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.selCatForCatUpdate = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.selCatForNewCatUpdate = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.statusGetCats = 2;
            } else {
              //MyService.ConsoleLog(response.data.error);
              growl.error("Something went wrong");
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong");
            $scope.statusGetCats = 3;
          }
        );
      };
      $rootScope.getParentCategories(0);

      $scope.addOneMoreParent = function () {
        var idx = $scope.allParentCats.length;
        $scope.getParentCategories(idx);
      };
      $scope.removeParent = function (pindex) {
        // body...
        //MyService.ConsoleLog("removeParent pindex: ", pindex);
        // $scope.allParentCats.length = pindex;
        $scope.allParentCats.splice(pindex, 1);
      };
      $scope.hideAll = function () {
        //alert("1");
        $rootScope.showProfile = false;
        $rootScope.showPoliProfile = false;
        $rootScope.showFollowing = false;
        $scope.showAddCategories = false;
        $scope.showUpdateCategories = false;
        $scope.showAddQuestion = false;
        $rootScope.showReportQuestion = false;
        $scope.showFiveMatchQuestion = false;
        $scope.showUserVerification = false;
        $rootScope.showAllCongressman = false;
        $rootScope.showCongressbills = false;
        $rootScope.showUpload = false;
        $rootScope.showVUpload = false;
        $rootScope.showRUpload = false;
        $rootScope.showHUpload = false;
        $rootScope.showOrganized = false;
        $rootScope.showAdvocates = false;
        $rootScope.showPress = false;
        $rootScope.showTopicSpam = false;
        $rootScope.showAllUsers = false;
        $rootScope.showAllTopic = false;
        $rootScope.showAllEssay = false;
        $rootScope.showAllEmail = false;
        $rootScope.showOpenFec = false;
        $rootScope.showOpenFecCommittee = false;
        $rootScope.postCard = false;

        $rootScope.showPlacesShape = false;
        $rootScope.showAllRecommendation = false;
        $scope.showBackup = false;
      };

      // $scope.parentCategories = angular.copy($rootScope.categories);

      /*
    $rootScope.$on("EventParentCategoriesFetched", function() {
        $scope.parentCategories = angular.copy($rootScope.categories);
        $scope.selectedCategories = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
        $scope.selCatForQues = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];

        $scope.selCatForCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
        $scope.selCatForNewCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
    });
    */

      $scope.showAddCategories = false;

      $scope.cattypes = [
        {
          lbl: "Essay",
          val: "E",
        },
        {
          lbl: "For forum",
          val: "F",
        },
        {
          lbl: "For questions",
          val: "Q",
        },
      ];
      $scope.updateCatTypes = angular.copy($scope.cattypes);

      /*
    $scope.catTypeSelected = function (val, selected) {
        //MyService.ConsoleLog("* catTypeSelected *", val);
            if(!$scope.category.catType) $scope.category.catType = [];

            var idx = $scope.category.cat_type.indexOf(val);
            if(selected)
                $scope.category.catType.push(val);
            else
                $scope.category.catType.splice(idx, 1);

            //MyService.ConsoleLog("category.catType: ",$scope.category.catType);
    };
    $scope.updateCatTypeSelected = function (val, selected) {
        //MyService.ConsoleLog("* updateCatTypeSelected *", val);

            var idx = $scope.updateCat.cat_type.indexOf(val);
            if(selected)
                $scope.updateCat.cat_type.push(val);
            else
                $scope.updateCat.cat_type.splice(idx, 1);

        //MyService.ConsoleLog("updateCatTypeSelected: ",$scope.updateCat.cat_type);
    };
    */

      $scope.initVarCat = function () {
        $scope.category = {
          title: "",
          parentIds: [],
          viewOrders: [],
          description: "",
          viewOrder: 0,
          files: null,
          catType: [],
        };
        $scope.selectedCategories = [
          {
            selected: null,
            viewOrders: 0,
            items: $scope.parentCategories,
          },
        ];
        angular.forEach($scope.cattypes, function (c, i) {
          c.selected = false;
        });
      };
      $scope.initVarCat();

      $scope.addCategory = function () {
        /*
      var tempParentIds = [],
          tempViewOrders = [];
      angular.forEach($scope.selectedCategories, function(val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val) {
              // val = JSON.parse(val);
              var selectedVal = JSON.parse(val.selected);
              if (selectedVal && selectedVal._id) {
                  tempParentIds.push(selectedVal._id);
                  tempViewOrders[0] = val.viewOrders;
              }
          }
      });*/
        //MyService.ConsoleLog("allParentCats: ", $scope.allParentCats);
        var tempParentIdsAll = [],
          tempViewOrders = [];
        angular.forEach($scope.allParentCats, function (pcat, i) {
          // body...
          var tempParentIds = [];
          var len = pcat.length;
          var tViewOrder = null;
          angular.forEach(pcat, function (val, key) {
            //MyService.ConsoleLog("key: ", key, " val: ", val);
            if (val) {
              // val = JSON.parse(val);
              var selectedVal = JSON.parse(val.selected);
              if (selectedVal && selectedVal._id) {
                tempParentIds.push(selectedVal._id);
                tViewOrder = val.viewOrders;
              }
            }
          });
          //MyService.ConsoleLog("tViewOrder: ", tViewOrder);
          if (tViewOrder != null) tempViewOrders.push(tViewOrder);
          tempParentIdsAll.push(tempParentIds.join(","));
        });

        //MyService.ConsoleLog("--------------------------------");
        //MyService.ConsoleLog("tempParentIdsAll: ", tempParentIdsAll);
        //MyService.ConsoleLog("tempViewOrders: ", tempViewOrders);
        //MyService.ConsoleLog("cat_type: ", $scope.category.cat_type);
        var fd = new FormData();
        fd.append("title", $scope.category.title);
        fd.append("description", $scope.category.description);
        //MyService.ConsoleLog("category files: ", $scope.category.files);

        angular.forEach($scope.category.files, function (file) {
          //MyService.ConsoleLog("append file", file);
          fd.append("file", file);
        });

        fd.append("file", $scope.category.files);

        if (tempParentIdsAll.length > 0) {
          fd.append("parentIds", tempParentIdsAll.join(";"));
          fd.append("viewOrders", tempViewOrders.join(","));
        } else {
          fd.append("viewOrder", $scope.category.viewOrder);
        }

        var tempCatTypes = [];
        angular.forEach($scope.cattypes, function (c, i) {
          if (c.selected) {
            tempCatTypes.push(c.val);
          }
        });
        if (tempCatTypes <= 0) {
          growl.error("At least select one category type.");
          return;
        } else {
          fd.append("catType", tempCatTypes.join(","));
        }
        //MyService.ConsoleLog("formData: ",fd);
        var posturl = baseApiUrl + "/categories/create";
        $http({
          method: "POST",
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {
            "Content-Type": undefined,
            "x-access-token": tokenService.getToken(),
          },
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd,
        }).then(
          function (response, status, headers, config) {
            //MyService.ConsoleLog("success data: ", response.data);
            if (response.data.success) {
              $scope.initVarCat();
              $rootScope.getCategories();
              $rootScope.getParentCategories(0);
              growl.success("Added successfully!");
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (data, status, headers, config) {
            //MyService.ConsoleLog("error: ", data);
            growl.error("Something went wrong");
          }
        );

        $scope.showProfile = false;
        $rootScope.showProfile = false;
        $rootScope.showUpload = false;
        $rootScope.showAllCongressman = false;
        $rootScope.showCongressbills = false;
        $rootScope.showVUpload = false;
        $rootScope.showRUpload = false;
        $rootScope.showHUpload = false;
        $rootScope.showOrganized = false;
        $rootScope.showAdvocates = false;
        $rootScope.showFollowing = false;
        $rootScope.showTopicSpam = false;
        $rootScope.showAllTopic = false;
        $rootScope.showReportQuestion = false;
        $scope.showFiveMatchQuestion = false;
        $rootScope.showAllEssay = false;
        $rootScope.showPress = false;
        $rootScope.showAllEvents = false;

        //$root.showAdvocates = !$root.showAdvocates
        //MyService.ConsoleLog("addCategory", $scope.category);

        /*
      var fd = new FormData();
      fd.append("title", $scope.category.title);
      fd.append("description", $scope.category.title);
      //MyService.ConsoleLog("category files: ", $scope.category.files);
      angular.forEach($scope.category.files, function(file){
          //MyService.ConsoleLog("append file", file);
          fd.append('file', file);
      });
      fd.append('file', $scope.category.files);
      var tempParentIds = [], tempViewOrders = [];
      angular.forEach($scope.category.parentIds, function(val, key){
          tempParentIds.push(val._id);
          tempViewOrders.push(val.viewOrders);
      });
      if(tempParentIds.length > 0){
          fd.append("parentIds", tempParentIds.join(","));
          fd.append("viewOrders", tempViewOrders.join(","));
      }
      //MyService.ConsoleLog("formData: ",fd);
      var posturl = baseApiUrl+"/categories/create";
      $http({
          method: 'POST',
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {'Content-Type': undefined},
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd
      }).
      success(function (data, status, headers, config) {
          //MyService.ConsoleLog("success data: ",data);
          if(data.success){
              $scope.initVarCat();
              $rootScope.getCategories();
              alert("Added successfully!");
          }
          else{
              alert("Error: ", data.error);
          }
      }).
      error(function (data, status, headers, config) {
          //MyService.ConsoleLog("error: ",data);
          alert("failed!");
      });
       */
      };

      $scope.toggleCategorySelection = function (cat) {
        //MyService.ConsoleLog(" toggleCategorySelection ");
        var idx = $scope.category.parentIds.indexOf(cat);

        // is currently selected
        if (idx > -1) {
          $scope.category.parentIds.splice(idx, 1);
        }
        // is newly selected
        else {
          $scope.category.parentIds.push(cat);
          $scope.statusGetCats = 1;
          var request = apiCall.apiCall("GET", "/categories/list", {
            root: 1,
            questions_counter: 0,
            parentId: cat._id,
          });
          $http(request).then(
            function successCallback(response) {
              if (response.data.success) {
                //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
                // $scope.secondLevelCat = response.data.data;
                cat.subcategory = response.data.data;
                $scope.statusGetCats = 2;
              } else {
                $scope.statusGetCats = 3;
                growl.error("Something went wrong.");
              }
            },
            function errorCallBack(response) {
              $scope.statusGetCats = 3;
              growl.error("Something went wrong.");
            }
          );
        }
      };

      var toogleAddCatSelCanceler = $q.defer();
      $scope.toogleAddCatSel = function (cat, index, pindex) {
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("$scope.selectedCategories: ",$scope.selectedCategories);
        //MyService.ConsoleLog("tempFun index: ", index, "pindex: ", pindex);
        /*
      //remove all elements after given index
      $scope.selectedCategories.length = index + 1;

      toogleAddCatSelCanceler.resolve();
      var params = {
          questions_counter: 0,
          parentId: cat._id,
          timeout: toogleAddCatSelCanceler.promise
      };
      var request = apiCall.apiCall('GET', '/categories/list', params);
      $http(
          request
      ).then(function successCallback(response) {
          if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                  $scope.selectedCategories.push({ "selected": null, viewOrders: 0, "items": response.data.data });
          } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
      }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
      });
      */
        //MyService.ConsoleLog("allParentCats: ", $scope.allParentCats);
        $scope.allParentCats[pindex].length = index + 1;

        toogleAddCatSelCanceler.resolve();
        var params = {
          questions_counter: 0,
          parentId: cat._id,
          timeout: toogleAddCatSelCanceler.promise,
        };
        var request = apiCall.apiCall("GET", "/categories/list", params);
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0) {
                //MyService.ConsoleLog("allParentCats 2: ", $scope.allParentCats);
                //MyService.ConsoleLog("index: ", index, " pindex: ", pindex);
                $scope.allParentCats[pindex].push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
                //MyService.ConsoleLog("allParentCats 3: ", $scope.allParentCats);
              }
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      //--------- update category ----------------

      $scope.initUpdateCat = function () {
        $scope.updateCat = {
          id: null,
          title: "",
          parentIds: [],
          viewOrders: [],
          description: "",
          viewOrder: 0,
          files: null,
          iconImage: null,
          old_path: null,
          catType: null,
        };
        $scope.selCatForCatUpdate = [
          {
            selected: null,
            viewOrders: 0,
            items: $scope.parentCategories,
          },
        ];
        $scope.selCatForNewCatUpdate = [
          {
            selected: null,
            viewOrders: 0,
            items: $scope.parentCategories,
          },
        ];
        angular.forEach($scope.updateCatTypes, function (c, i) {
          c.selected = false;
        });
      };
      $scope.initUpdateCat();

      var toggleUpdateCatSelCanceler = $q.defer();
      $scope.toggleUpdateCatSel = function (cat, index) {
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("toggleUpdateCatSel : ", cat);
        $scope.updateCat.id = cat._id;
        $scope.updateCat.title = cat.title;
        $scope.updateCat.description = cat.description;
        if (cat.fbTitle) {
          $scope.updateCat.fbtitle = cat.fbTitle;
        }
        if (cat.fbDescription) {
          $scope.updateCat.fbdescription = cat.fbDescription;
        }
        $scope.updateCat.viewOrder = cat.order;
        $scope.selCatForCatUpdate[index].viewOrders = cat.order;
        //MyService.ConsoleLog(" viewOrders index: ", $scope.selCatForCatUpdate[index].viewOrders);

        angular.forEach($scope.updateCatTypes, function (c, i) {
          c.selected = false; //reset all selected categories
        });
        if (cat.catType) {
          angular.forEach(cat.catType, function (val, key) {
            angular.forEach($scope.updateCatTypes, function (c, i) {
              if (c.val == val) {
                c.selected = true;
              }
            });
          });
          // $scope.updateCat.cat_type = cat.cat_type;
          // angular.forEach($scope.cattypes, function(val, key){
          //  if(val.val == cat.cat_type)
          //      $scope.updateCat.cat_type = val;
          // });
        }
        $scope.updateCat.iconImage = cat.iconImage;
        if (cat.fbImage) {
          $scope.updateCat.fbimage = cat.fbImage;
        }
        //MyService.ConsoleLog("response.data: ", cat);
        try {
          $scope.updateCat.old_path = cat.parentIds._id;
        } catch (e) {
          //MyService.ConsoleLog("Exception :", old_path);
        }

        //remove all elements after given index
        $scope.selCatForCatUpdate.length = index + 1;

        toggleUpdateCatSelCanceler.resolve();
        var params = {
          questions_counter: 0,
          parentId: cat._id,
          timeout: toggleUpdateCatSelCanceler.promise,
        };
        var request = apiCall.apiCall("GET", "/categories/list", params);
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", requestsponse.data.data);
              if (response.data.data.length > 0) {
                $scope.selCatForCatUpdate.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
              }
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      var toggleUpdateNewCatSelCanceler = $q.defer();
      $scope.statusUpdateGetCats = 0;
      $scope.toggleUpdateNewCatSel = function (cat, index) {
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("$scope.selectedCategories: ",$scope.selectedCategories);
        //MyService.ConsoleLog("tempFun index: ", index);
        //remove all elements after given index
        $scope.selCatForNewCatUpdate.length = index + 1;
        toggleUpdateNewCatSelCanceler.resolve();
        toggleUpdateNewCatSelCanceler = $q.defer();
        var params = {
          questions_counter: 0,
          parentId: cat._id,
        };
        $scope.statusUpdateGetCats = 1;
        var request = apiCall.apiCall("GET", "/categories/list", params);
        request.timeout = toggleUpdateNewCatSelCanceler.promise;
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                $scope.selCatForNewCatUpdate.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
              $scope.statusUpdateGetCats = 2;
            } else {
              $scope.statusUpdateGetCats = 3;
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.statusUpdateGetCats = 3;
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.updateCategory = function () {
        //MyService.ConsoleLog("** updateCategory **");

        var tempParentIds = [],
          tempViewOrders = [];
        var parent_path_id = 0;
        var parent_path_order = 0;
        angular.forEach($scope.selCatForCatUpdate, function (val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              // tempParentIds.push(selectedVal._id);
              // tempViewOrders[0] = val.viewOrders;
              //MyService.ConsoleLog("** parentIds: ", selectedVal.parentIds);
              parent_path_id = selectedVal.parentIds._id;
              parent_path_order = val.viewOrders;
            }
          }
        });
        //MyService.ConsoleLog("parent_pathid :", parent_path_id);
        //MyService.ConsoleLog("parent_path_order :", parent_path_order);
        //MyService.ConsoleLog("tempParentIds: ",tempParentIds);
        //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);

        var fd = new FormData();
        fd.append("id", $scope.updateCat.id);
        fd.append("title", $scope.updateCat.title);
        fd.append("description", $scope.updateCat.description);
        fd.append("fbtitle", $scope.updateCat.fbtitle);
        fd.append("fbdescription", $scope.updateCat.fbdescription);

        if (parent_path_id) {
          fd.append("parent_path_id", parent_path_id);
          fd.append("parent_path_order", parent_path_order);
        }

        //MyService.ConsoleLog("category files: ", $scope.updateCat.files);

        angular.forEach($scope.updateCat.files, function (file) {
          //MyService.ConsoleLog("append file", file);
          fd.append("file", file);
        });

        fd.append("file", $scope.updateCat.files);

        fd.append("fbfile", $scope.updateCat.fbimage);

        if (tempParentIds.length > 0) {
          fd.append("parentIds", tempParentIds.join(","));
          fd.append("viewOrders", tempViewOrders.join(","));
        } else {
          fd.append("viewOrder", $scope.updateCat.viewOrder);
        }

        var tempCatTypes = [];

        angular.forEach($scope.updateCatTypes, function (c, i) {
          if (c.selected) {
            tempCatTypes.push(c.val);
          }
        });

        if (tempCatTypes <= 0) {
          growl.error("At least select one category type.");
          return;
        } else {
          fd.append("catType", tempCatTypes.join(","));
        }
        //MyService.ConsoleLog(fd);
        var posturl = baseApiUrl + "/categories/create";
        $http({
          method: "POST",
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {
            "Content-Type": undefined,
          },
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd,
        }).then(
          function (data, status, headers, config) {
            //MyService.ConsoleLog("success data: ", data);
            if (data.data.success) {
              growl.success("Updated successfully.");
              $rootScope.getCategories();
              $scope.getParentCategories();
              $scope.initUpdateCat();
            } else if (data.data.error && typeof data.error == "string") {
              growl.error("Error: " + data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (data, status, headers, config) {
            //MyService.ConsoleLog("error >> ", data);
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.removeCategory = function () {
        //MyService.ConsoleLog("-- removeCategory --");
        if (!$scope.updateCat || !$scope.updateCat.id) {
          growl.error("Select category first.");
          return;
        }

        var old_path_ids = null;
        var category_id = null;

        angular.forEach($scope.selCatForCatUpdate, function (val, key) {
          try {
            var selObj = JSON.parse(val.selected);
            if (selObj._id) {
              category_id = selObj._id;
              // selObj.parentIds = JSON.parse(selObj.parentIds);
              old_path_ids = selObj.parentIds._id;
            }
          } catch (e) {
            //MyService.ConsoleLog("Exception: ", e)
          }
        });

        var postobj = {
          id: category_id,
          old_path_ids: old_path_ids,
        };

        //MyService.ConsoleLog("postobj : ", postobj);
        var request = apiCall.apiCall("POST", "/categories/remove", postobj);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              growl.success("Removed successfully.");
              $rootScope.getCategories();
              $scope.getParentCategories();
              $scope.initUpdateCat();
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.updateParentCategory = function () {
        //MyService.ConsoleLog("-- updateParentCategory --");
        //MyService.ConsoleLog("selCatForNewCatUpdate: ", $scope.selCatForNewCatUpdate);
        var tempParentIds = [],
          tempViewOrders = [];
        angular.forEach($scope.selCatForNewCatUpdate, function (val, key) {
          try {
            var selObj = JSON.parse(val.selected);
            if (selObj._id) {
              tempParentIds.push(selObj._id);
              tempViewOrders[0] = val.viewOrders;
            }
          } catch (e) {
            //MyService.ConsoleLog("Exception: ", e)
          }
        });

        var old_path_ids = null;
        angular.forEach($scope.selCatForCatUpdate, function (val, key) {
          try {
            var selObj = JSON.parse(val.selected);
            if (selObj._id) {
              // selObj.parentIds = JSON.parse(selObj.parentIds);
              old_path_ids = selObj.parentIds._id;
            }
          } catch (e) {
            //MyService.ConsoleLog("Exception: ", e)
          }
        });

        //MyService.ConsoleLog("tempParentIds: ",tempParentIds);
        //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);
        if (!$scope.updateCat || !$scope.updateCat.id) {
          growl.error("Select category first.");
        } else {
          var postobj = {
            parentIds: tempParentIds.join(","),
            viewOrders: tempViewOrders.join(","),
            id: $scope.updateCat.id,
            old_path_ids: old_path_ids,
            viewOrder: $scope.updateCat.viewOrder,
          };
          //MyService.ConsoleLog("postobj: ", postobj);

          /*
        var message = 'Continue?';
        var modal = ngDialog.openConfirm({
            template:'<p>'+message+'</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                    </div>',
            plain: true
        });
        modal.then(function fullfilled(data){
            //MyService.ConsoleLog("fullfilled: ",data);
        }, function rejected(data){
            //MyService.ConsoleLog("rejected: ",data);
        });
        */

          var request = apiCall.apiCall(
            "POST",
            "/categories/update-parent",
            postobj
          );
          $http(request).then(
            function successCallback(response) {
              if (response.data.success) {
                growl.success("Updated successfully.");
                $rootScope.getCategories();
                $scope.getParentCategories();
                $scope.initUpdateCat();
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
              } else {
                growl.error("Something went wrong.");
              }
            },
            function errorCallBack(response) {
              growl.error("Something went wrong.");
            }
          );
        }
      };
      $scope.addCongressBills = function () {
        MyService.ConsoleLog("In addCongressBills");
        $scope.loader = true;
        MyService.addCongressBills().then(
          function (data) {
            MyService.ConsoleLog("Congress bill data : ", data);
            if (data.status == 200) {
              $scope.loader = false;
              alert("Congress Bills added");
            }
          },
          function (err) {
            MyService.ConsoleLog("Congress bill error: ", err);
            $scope.loader = false;
          }
        );
      };
    },
  ])
  .controller("AddNewParentsCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      MyService
    ) {
      //MyService.ConsoleLog("* AddNewParentsCtrl *");

      $scope.allRootCats = [];
      $rootScope.getParentCategories2 = function (idx) {
        //MyService.ConsoleLog("getParentCategories: ", idx);
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
          questions_counter: 0,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              $scope.parentCategories = response.data.data;
              $scope.allRootCats[idx] = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.selCatForQues = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.selCatForCatUpdate = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.selCatForNewCatUpdate = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };
      $rootScope.getParentCategories2(0);

      $scope.addOneMoreParent2 = function () {
        var idx = $scope.allRootCats.length;
        $scope.getParentCategories2(idx);
      };
      $scope.removeParent = function (pindex) {
        // body...
        //MyService.ConsoleLog("removeParent pindex: ", pindex);
        // $scope.allRootCats.length = pindex;
        $scope.allRootCats.splice(pindex, 1);
      };

      var toogleAddCatSelCanceler = $q.defer();
      $scope.toogleAddCatSel = function (cat, index, pindex) {
        cat = JSON.parse(cat);

        //MyService.ConsoleLog("$scope.selectedCategories: ",$scope.selectedCategories);
        //MyService.ConsoleLog("tempFun index: ", index, "pindex: ", pindex);

        //MyService.ConsoleLog("allRootCats: ", $scope.allRootCats);
        $scope.allRootCats[pindex].length = index + 1;

        toogleAddCatSelCanceler.resolve();
        var params = {
          questions_counter: 0,
          parentId: cat._id,
          timeout: toogleAddCatSelCanceler.promise,
        };
        var request = apiCall.apiCall("GET", "/categories/list", params);
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0) {
                //MyService.ConsoleLog("allRootCats 2: ", $scope.allRootCats);
                //MyService.ConsoleLog("index: ", index, " pindex: ", pindex);
                $scope.allRootCats[pindex].push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });

                //MyService.ConsoleLog("allRootCats 3: ", $scope.allRootCats);
              }
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      $scope.statusaddParents = 0;
      $scope.addParentCategory = function () {
        if (!$scope.$parent.updateCat.id) {
          growl.error("Select category first.");
          return;
        }

        var tempParentIdsAll = [],
          tempViewOrders = [];
        //MyService.ConsoleLog("allRootCats: ", $scope.allRootCats);
        angular.forEach($scope.allRootCats, function (pcat, i) {
          // body...
          var tempParentIds = [];
          var len = pcat.length;
          var tViewOrder = 0;
          angular.forEach(pcat, function (val, key) {
            //MyService.ConsoleLog("key: ", key, " val: ", val);
            if (val) {
              // val = JSON.parse(val);
              var selectedVal = JSON.parse(val.selected);
              if (selectedVal && selectedVal._id) {
                tempParentIds.push(selectedVal._id);
                tViewOrder = val.viewOrders;
              }
            }
          });
          tempParentIdsAll.push(tempParentIds.join(","));
          tempViewOrders.push(tViewOrder);
        });
        //MyService.ConsoleLog("tempParentIdsAll: ", tempParentIdsAll);
        //MyService.ConsoleLog("tempViewOrders: ", tempViewOrders);
        var postobj = {};

        if ($scope.$parent.updateCat.id) {
          postobj["id"] = $scope.$parent.updateCat.id;
        }
        if (tempParentIdsAll.length > 0) {
          postobj["parentIds"] = tempParentIdsAll.join(";");
          postobj["viewOrders"] = tempViewOrders.join(",");
        }

        //MyService.ConsoleLog("postobj: ", postobj);
        $scope.statusaddParents = 1;
        MyService.addParents(postobj).then(
          function (response) {
            if (response.data.success) {
              $scope.statusaddParents = 2;
              growl.success("Added successfully.");
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
              $scope.statusaddParents = 3;
            } else {
              growl.error("Something went wrong.");
              $scope.statusaddParents = 3;
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );

        // $scope.showProfile = false;
        // $rootScope.showProfile = false;
        // $rootScope.showUpload = false;
        // $rootScope.showAdvocates = false;
        // $rootScope.showFollowing = false;
        // $rootScope.showTopicSpam = false;
        // $rootScope.showAllTopic = false;
        // $rootScope.showAllEssay = false;
        // $rootScope.showPress = false;
      };
    },
  ])
  .controller("EditQuestionCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      MyService
    ) {
      //MyService.ConsoleLog("* EditQuestionCtrl *", $scope.ngDialogData);

      $scope.initQuesVar = function () {
        $scope.question = {
          id: null, //to edit question set this
          content: "",
          categoryIds: [],
          viewOrders: [],
        };
        if ($scope.ngDialogData) {
          try {
            MyService.ConsoleLog(
              "$scope.ngDialogData<><><><>",
              $scope.ngDialogData
            );
            $scope.question.content = $scope.ngDialogData.content;
            $scope.question.showInMatch = $scope.ngDialogData.showInMatch;
            $scope.question.matchOrder = $scope.ngDialogData.matchOrder;
            MyService.ConsoleLog("question<><><><>", $scope.question);
          } catch (e) {
            //MyService.ConsoleLog("EditQuestion Exception: ", e);
          }
          //MyService.ConsoleLog("$scope.question: ", $scope.question);
        }

        $scope.selCatForQues = [
          {
            selected: null,
            viewOrders: 0,
            items: $scope.parentCategories,
          },
        ];
        $scope.questions = [];
      };
      $scope.initQuesVar();

      $scope.matchType = ["YES", "NO"];

      $scope.statusGetCats = 0;
      $rootScope.getParentCategories = function () {
        //MyService.ConsoleLog("getParentCategories");
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
          questions_counter: 0,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              $scope.parentCategories = response.data.data;

              $scope.selCatForQues = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.statusGetCats = 2;
            } else {
              growl.error("Something went wrong");
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong");
            $scope.statusGetCats = 3;
          }
        );
      };
      $scope.getParentCategories();

      $scope.questionCatChanged = function (selcat) {
        selcat = JSON.parse(selcat);
        $scope.questionSubcategories = [];
        if (selcat._id) {
          var request = apiCall.apiCall("GET", "/categories/list", {
            parentId: selcat._id,
          });
          $http(request).then(
            function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.questionSubcategories = response.data.data;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
            }
          );
        }
      };

      $scope.queFirstLevelCat = [];
      $scope.queSecondLevelCat = [];

      $scope.addQuestion = function () {
        var tempCatIds = [],
          tempViewOrders = [];
        angular.forEach($scope.selCatForQues, function (val, key) {
          //MyService.ConsoleLog("key: ",key," val: ",val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              tempCatIds[0] = selectedVal._id; //last level cat id
              tempViewOrders[0] = val.viewOrders;
            }
          }
        });

        var postobj = {
          content: $scope.question.content,
          showInMatch: $scope.question.showInMatch,
          matchOrder: $scope.question.matchOrder,
        };

        if (tempCatIds.length > 0) {
          postobj.categoryIds = tempCatIds.join(",");
          postobj.viewOrders = tempViewOrders.join(",");
        }
        if ($scope.ngDialogData) postobj.id = $scope.ngDialogData._id;

        MyService.ConsoleLog("postobj: ", postobj);

        MyService.editQuestion(postobj).then(
          function (response) {
            if (response.data.success) {
              growl.success("Added successfully!");
              $scope.closeThisDialog({
                data: $scope.ngDialogData,
              });
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );

        /*
      var request = apiCall.apiCall('POST', '/questions/create', postobj);
      $http(request)
      .then(function successCallback(response) {
          if (response.data.success) {
              $scope.initVarCat();
              $rootScope.getCategories();
              $scope.getParentCategories();
              growl.success("Added successfully!");
          } else if (response.data.error && typeof response.data.error == "string") {
              growl.error('Error: ' + response.data.error);
          } else {
              growl.error('Something went wrong.');
          }
      }, function errorCallBack(response) {
          growl.error('Something went wrong.');
      });
      */
      };

      var selQuesCategoryCanceler = $q.defer();
      $scope.selQuesCategory = function (cat, index) {
        selQuesCategoryCanceler.resolve();
        cat = JSON.parse(cat);
        //remove all elements after given index
        selQuesCategoryCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();

        $scope.selCatForQues.length = index + 1;
        var params = {
          questions_counter: 0,
          parentId: cat._id,
        };
        var request = apiCall.apiCall("GET", "/categories/list", params);
        request.timeout = selQuesCategoryCanceler.promise;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };
    },
  ])

  /*.controller('AdminVerifyQuestions', ['$scope', '$http', '$location', 'apiCall', '$rootScope', 'baseApiUrl', '$q', '$filter', 'growl', 'ngDialog', 'MyService', function($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, MyService) {
      //MyService.ConsoleLog("* AdminVerifyQuestions *");

      $scope.verifySelectedUser = null;
      $scope.verifyFilter = null;
      $scope.statusVerifyQues = 0; //1=loading, 2 = loaded, 3 = error while loading
      $scope.max_records = 30;

      var getVerifyUsersCanceler = $q.defer();
      var prevSelFilter = null;
      $scope.page = 0;
      $scope.verifyQuestions = [];

      $scope.getVerifyQues = function() {
          //MyService.ConsoleLog("prevSelFilter = ", prevSelFilter);
          //MyService.ConsoleLog("verifyFilter = ", $scope.verifyFilter);
          if (prevSelFilter != $scope.verifyFilter) {
              prevSelFilter = $scope.verifyFilter;
              $scope.page = 0;
              $scope.verifyQuestions = [];
          }
          $scope.page += 1;
          prevSelFilter = $scope.verifyFilter;

          //MyService.ConsoleLog("getVerifyUsers: ", $scope.verifySelectedUser, " filter: ", $scope.verifyFilter);

          getVerifyUsersCanceler.resolve();
          var params = {
              approved: $scope.verifyFilter,
              page: $scope.page,
              max_records: $scope.max_records,
          };
          //MyService.ConsoleLog("params: ", params);
          $scope.statusVerifyQues = 1;

          MyService.getQuestions(params, getVerifyUsersCanceler.promise).then(function (response) {
              if (response.data.success) {
                  //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
                  $scope.verifyQuestions = $scope.verifyQuestions.concat(response.data.data);
                  $scope.statusVerifyQues = 2;
              } else if (response.data.error && typeof response.data.error == "string") {
                  growl.error('Error: ' + response.data.error);
                  $scope.statusVerifyQues = 3;
              } else {
                  growl.error('Something went wrong.');
                  $scope.statusVerifyQues = 3;
              }
          }, function (err) {
              //MyService.ConsoleLog("Get question error: ", err);
              $scope.statusVerifyQues = 3;
          });
          var request = apiCall.apiCall('GET', '/questions/list', params);
          $http(
              request
          ).then(function successCallback(response) {
              if (response.data.success) {
                  //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
                  $scope.verifyQuestions = $scope.verifyQuestions.concat(response.data.data);
                  $scope.statusVerifyQues = 2;
              } else if (response.data.error && typeof response.data.error == "string") {
                  growl.error('Error: ' + response.data.error);
                  $scope.statusVerifyQues = 3;
              } else {
                  growl.error('Something went wrong.');
                  $scope.statusVerifyQues = 3;
              }
          }, function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
              $scope.statusVerifyQues = 3;
          });
      };

      $scope.verifyThisQues = function(user, index, val) {

          var postobj = {
              id: user._id,
              approve: val
          };

          //MyService.ConsoleLog("postobj : ", postobj);

          var request = apiCall.apiCall('POST', "/questions/approve", postobj);
          $http(
              request
          ).then(function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ", response);
              if (response.data.success) {
                  $scope.verifyQuestions[index].approved = val;
              } else if (response.data.error && typeof response.data.error == "string") {
                  growl.error('Error: ' + response.data.error);
              } else {
                  growl.error('Something went wrong.');
              }
          }, function errorCallBack(response) {
              growl.error('Something went wrong.');
          });

      }

      $scope.getCategoriesOfQues = function(q, index) {
          //MyService.ConsoleLog("getCategoriesOfQues: ",q, index);
          var tempCatIds = [];
          angular.forEach(q.categories, function(val, key) {
              tempCatIds.push(val.cid);
          });
          //MyService.ConsoleLog("tempCatIds: ", tempCatIds);
          var param = {
              ids: tempCatIds.join(",")
          }

          $scope.verifyQuestions[index].fullCategoriesData = {
              status: 1
          };
          MyService.getMultiCategoryByIds(param).then(function(response) {
              if (response.data.success) {
                  $scope.verifyQuestions[index].fullCategoriesData.list = response.data.data;
                  $scope.verifyQuestions[index].fullCategoriesData.status = 2;
              } else if (response.data.error && typeof response.data.error == "string") {
                  growl.error('Error: ' + response.data.error);
                  $scope.verifyQuestions[index].fullCategoriesData.status = 3;
              } else {
                  growl.error('Something went wrong.');
                  $scope.verifyQuestions[index].fullCategoriesData.status = 3;
              }
          }, function(err) {
              $scope.verifyQuestions[index].fullCategoriesData.status = 3;
          });
      };
  }])*/

  .controller("setPreference", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "growl",
    "tokenService",
    "ngDialog",
    "baseApiUrl",
    "$anchorScroll",
    "MyService",
    "$window",
    "$sce",
    "uiGridConstants",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      growl,
      tokenService,
      ngDialog,
      baseApiUrl,
      $anchorScroll,
      MyService,
      $window,
      $sce,
      uiGridConstants
    ) {
      $scope.userSelectedCols = [];
      $scope.userSelectedColsNew = [];
      $scope.chkcol = [];
      $scope.list = {
        name: "",
        rename: "",
        selectedName: "",
      };

      $scope.tabs = [
        {
          title: "All Templates",
          url: "one.tpl.html",
        },
        {
          title: "New Template",
          url: "two.tpl.html",
        },
        {
          title: "Update Template",
          url: "three.tpl.html",
        },
      ];

      $scope.currentTab = "one.tpl.html";

      $scope.onClickTab = function (tab) {
        $scope.currentTab = tab.url;
        if (tab.url == "three.tpl.html") {
          $scope.list.selectedName = null;
        }
      };

      $scope.isActiveTab = function (tabUrl) {
        return tabUrl == $scope.currentTab;
      };

      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
        if ($scope.userData.selectedColumns) {
          $scope.userSelectedCols = $scope.userData.selectedColumns;
        }
      }

      $scope.columns = [
        "name",
        "email",
        "privateEmail",
        "office",
        "officePlace",
        "party",
        "electionDate",
        "voterScope",
        "publicMailingAddress",
        "privateMailingAddress",
        "website",
        "rapidTransitDistrict",
        "statement",
        "numberOfOpenings",
        "incumbent",
        "publicZipCode",
        "privateZipCode",
        "publicPhoneNumber",
        "privatePhoneNumber",
        "stateBoardEducationDistrict",
        "judicialCircuitNumber",
        "stateFips",
        "statePostalCode",
        "countyFips",
        "countyName",
        "countySubdivisionFips",
        "countySubdivisionName",
        "placeFips",
        "placeName",
        "voterDistrict",
        "votingDistrictName",
        "federalCongressionalDistrict",
        "stateSenateDistrict",
        "stateHouseofRepresentativesDistrict",
        "unifiedSchoolDistrict",
        "unifiedSchoolDistrictName",
        "elementarySchoolDistrict",
        "elementarySchoolDistrictName",
        "secondarySchoolDistrict",
        "secondarySchoolDistrictName",
        "totalPop",
        "afact",
        "dataX",
        "dataY",
        "dataZ",
        "runningForOffice",
        "facebookPage",
        "twitterFeed",
        "website2",
        "typeOfElection",
        "dataOrigin",
      ];

      $scope.setColumns = function (col) {
        var idx = $scope.userSelectedColsNew.indexOf(col);
        //MyService.ConsoleLog(idx);
        if (idx === -1) {
          $scope.userSelectedColsNew.push(col);
        } else {
          $scope.userSelectedColsNew.splice(idx, 1);
        }
      };

      $scope.removeSelected = function (col) {
        var idx = $scope.userSelectedColsNew.indexOf(col);
        $scope.userSelectedColsNew.splice(idx, 1);
        var vdx = $scope.columns.indexOf(col);
        $scope.chkcol[vdx] = false;
      };

      $scope.savePreferences = function () {
        //MyService.ConsoleLog($scope.userSelectedColsNew);
        var error = false;
        var errmsg = "";
        var sfoc = "";
        if ($scope.userSelectedColsNew.length == 0) {
          errmsg = "Please select atleast one column";
          error = true;
        }

        if ($scope.list.name == "") {
          errmsg = "Please enter list name";
          sfoc = "name";
          error = true;
        }

        if (error == false) {
          MyService.savePreferences({
            selectedColumns: JSON.stringify($scope.userSelectedColsNew),
            name: $scope.list.name,
          }).then(
            function (payload) {
              //MyService.ConsoleLog(payload);
              if ($scope.userData) {
                // $scope.userSelectedColsNew = payload.selectedColumns;
                $scope.userData.selectedColumns = payload.selectedColumns;
                tokenService.saveUserData(JSON.stringify($scope.userData));
              }
              alert("Preferences saved successfully.");
            },
            function (errorPayload) {
              //MyService.ConsoleLog(errorPayload);
              alert(
                "Error occured while saving Preferences, please try after sometime."
              );
            }
          );
        } else {
          if (sfoc == "name") {
            var element = $window.document.getElementById("name");
            if (element) element.focus();
          }
          alert(errmsg);
        }
      };

      $scope.removeTemplate = function (list, idx) {
        var message =
          "This action will remove the selected template. Do you want to continue?";
        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            message +
            '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
          plain: true,
        });
        modal.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", mydata);
            $scope.userSelectedCols.splice(idx, 1);
            // alert(JSON.stringify(list));
            // alert(idx);
            MyService.removePreferences({
              selectedColumns: JSON.stringify($scope.userSelectedCols),
            }).then(
              function (payload) {
                if ($scope.userData) {
                  $scope.userData.selectedColumns = $scope.userSelectedCols;
                  tokenService.saveUserData(JSON.stringify($scope.userData));
                }
                alert("Preferences removed successfully.");
                //tokenService.saveUserData(JSON.stringify($scope.userData));
              },
              function (errorPayload) {
                alert(
                  "Error occured while removing Preferences, please try after sometime."
                );
              }
            );
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          }
        );
      };
      $scope.updateSelectedCols = [];
      $scope.chkucol = [];
      $scope.oldList = "";
      $scope.selectedCols = function () {
        var list = JSON.parse(angular.toJson($scope.list.selectedName));
        $scope.oldList = angular.copy(list);
        //MyService.ConsoleLog(list);
        if (list && list.cols) {
          $scope.updateSelectedCols = angular.copy(list.cols);
          $scope.list.rename = angular.copy(list.name);
        }
      };

      $scope.setupdatedColumns = function (col) {
        var idx = $scope.updateSelectedCols.indexOf(col);
        //MyService.ConsoleLog(idx);
        if (idx === -1) {
          $scope.updateSelectedCols.push(col);
        } else {
          $scope.updateSelectedCols.splice(idx, 1);
        }
      };
      $scope.FinalIdx = "";
      $scope.checkifItemExists = function (rdata, val) {
        return rdata.filter(function (rdata, idx) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.name == val) {
            $scope.FinalIdx = idx;
            return idx;
          }
        });
      };

      $scope.removeupdatedSelected = function (col) {
        var idx = $scope.updateSelectedCols.indexOf(col);
        $scope.updateSelectedCols.splice(idx, 1);
        var vdx = $scope.columns.indexOf(col);
        $scope.chkucol[vdx] = false;
      };

      $scope.updateRecords = function () {
        var error = false;
        var errmsg = "";
        var sfoc = "";

        if ($scope.updateSelectedCols.length == 0) {
          errmsg = "Please select atleast one column";
          error = true;
        }

        if ($scope.list.rename == "") {
          errmsg = "Please enter list name";
          sfoc = "rename";
          error = true;
        }

        if (
          $scope.list.selectedName == "" ||
          $scope.list.selectedName == null
        ) {
          //MyService.ConsoleLog($scope.list.selectedName);
          sfoc = "list";
          errmsg = "Please select the list first";
          error = true;
        }

        if (error == false) {
          var list = $scope.checkifItemExists(
            $scope.userSelectedCols,
            $scope.list.selectedName.name
          );
          $scope.userSelectedCols.splice($scope.FinalIdx, 1, {
            name: $scope.list.rename,
            cols: $scope.updateSelectedCols,
          });
          var newlist = JSON.parse(angular.toJson($scope.userSelectedCols));
          //MyService.ConsoleLog(newlist);
          // alert(JSON.stringify(list));
          // alert(idx);

          MyService.removePreferences({
            selectedColumns: JSON.stringify(newlist),
          }).then(
            function (payload) {
              if ($scope.userData) {
                $scope.userData.selectedColumns = $scope.userSelectedCols;
                tokenService.saveUserData(JSON.stringify($scope.userData));
              }
              alert("Preferences updated successfully.");
              //tokenService.saveUserData(JSON.stringify($scope.userData));
            },
            function (errorPayload) {
              alert(
                "Error occured while updating Preferences, please try after sometime."
              );
            }
          );
        } else {
          //MyService.ConsoleLog($scope.list.selectedName);
          if (sfoc == "rename") {
            var element = $window.document.getElementById("rename");
            if (element) element.focus();
          }
          alert(errmsg);
        }
      };
    },
  ])

  .controller("UpdatePoliticianOfficeCtrl_Old", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      tokenService,
      MyService
    ) {
      $scope.User = {};

      //MyService.ConsoleLog("UpdatePoliticianOfficeCtrl: ", $scope.ngDialogData);

      $scope.political_office_options = {
        suggest: function (term) {
          // return $rootScope.suggest_organization(term, $scope.UserType)
          return $rootScope.suggestPoliticianOffice(term, $rootScope.userRole);
        },
        on_select: function (selected) {
          //MyService.ConsoleLog("selected: ", selected);
          // $scope.User.organization_name = selected.label;
          $scope.User.office = selected;
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
        }
        catch(e){
            $scope.User.organization = null;
        }*/
          $scope.User.office_name = current_value;
        },
      };

      $scope.org_jurisdiction_options = {
        suggest: function (term) {
          // return $rootScope.suggest_organization(term, $scope.UserType)
          return $rootScope.suggestJurisdiction(term);
        },
        on_select: function (selected) {
          //MyService.ConsoleLog("selected: ", selected);
          $scope.User.jurisdiction = selected;
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
        }
        catch(e){
            $scope.User.organization = null;
        }*/
          $scope.User.jurisdiction_name = current_value;
        },
      };

      $scope.statusGetStates = 0; //1: loading, 2: loaded, 3: failed
      var getAllStatesCanceler = $q.defer();
      $scope.getAllStates = function () {
        //MyService.ConsoleLog("** getAllStates **");
        if ($scope.counties) $scope.counties.length = 0;
        if ($scope.places) $scope.places.length = 0;
        var params = {
          country: "",
          timeout: getAllStatesCanceler.promise,
        };
        $scope.statusGetStates = 1;
        MyService.getAllStates(params).then(
          function (data) {
            //MyService.ConsoleLog("got states");
            $scope.statusGetStates = 2;
            $scope.states = data;
            if (!$scope.User.state && $scope.ngDialogData.state) {
              var len = $scope.states.length;
              for (var i = 0; i < len; i++) {
                if (
                  $scope.ngDialogData.state.toLowerCase() ==
                  $scope.states[i].state.toLowerCase()
                ) {
                  $scope.User.state = JSON.stringify($scope.states[i]);
                  //MyService.ConsoleLog("Set current state: ", $scope.User.state);
                  $scope.getCounties();
                  break;
                }
              }
            }
          },
          function (err) {
            $scope.statusGetStates = 3;
            //MyService.ConsoleLog("got states error");
            // growl.error("Some");
          }
        );
      };
      $scope.getAllStates();

      $scope.statusGetCounties = 0; //1: loading, 2: loaded, 3: failed
      var getCountiesCanceler = $q.defer();
      $scope.getCounties = function () {
        //MyService.ConsoleLog("** getCounties **");
        var state = JSON.parse($scope.User.state);

        getCountiesCanceler.resolve();
        if ($scope.places) $scope.places.length = 0;
        $scope.User.place = null;
        $scope.User.county = null;

        var param = {
          statePostalCode: state.statePostalCode,
          timeout: getCountiesCanceler.promise,
        };
        //MyService.ConsoleLog("param : ", param);
        $scope.statusGetCounties = 1;
        MyService.getAllCounties(null, param).then(
          function (data) {
            //MyService.ConsoleLog("got counties data");
            $scope.statusGetCounties = 2;
            $scope.counties = data;
            if (!$scope.User.county && $scope.ngDialogData.county) {
              var len = $scope.counties.length;
              //MyService.ConsoleLog("county data: ", $scope.counties);
              //MyService.ConsoleLog("length: ", len);
              for (var i = 0; i < len; i++) {
                //MyService.ConsoleLog("i ", i);
                //MyService.ConsoleLog("i data: ", $scope.counties[i]);
                if (
                  $scope.ngDialogData.county.toLowerCase() ==
                  $scope.counties[i]._id.county.toLowerCase()
                ) {
                  $scope.User.county = JSON.stringify($scope.counties[i]._id);
                  //MyService.ConsoleLog("Set current county: ", $scope.User.county);
                  $scope.getLocals();
                  break;
                }
              }
            }
          },
          function (err) {
            //MyService.ConsoleLog("got counties error");
            // growl.error("Some");
            $scope.statusGetCounties = 3;
          }
        );
      };

      $scope.statusGetLocals = 0; //1: loading, 2: loaded, 3: failed
      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function () {
        //MyService.ConsoleLog("** getLocals **");
        var state = null,
          county = null;

        state = JSON.parse($scope.User.state);
        county = JSON.parse($scope.User.county);

        $scope.User.place = null;

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();
        var param = {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        };
        $scope.statusGetLocals = 1;
        MyService.getAllSubCountiesLocal(null, null, param).then(
          function (data) {
            $scope.statusGetLocals = 2;
            $scope.places = data;
            if (!$scope.User.place && $scope.ngDialogData.place) {
              var len = $scope.places.length;
              for (var i = 0; i < len; i++) {
                if (
                  $scope.ngDialogData.place.toLowerCase() ==
                  $scope.places[i]._id.place.toLowerCase()
                ) {
                  $scope.User.place = JSON.stringify($scope.places[i]._id);
                  //MyService.ConsoleLog("Set current place: ", $scope.User.place);
                  break;
                }
              }
            }
          },
          function (err) {
            $scope.statusGetLocals = 3;
            //MyService.ConsoleLog("Get places error: ", err);
          }
        );
      };

      $scope.UpdateOffice = function () {
        try {
          var userData = JSON.parse(tokenService.getUserData());
          //MyService.ConsoleLog("userData: ", userData);
          var postobj = {
            userid: userData.id,
            info_id: $scope.ngDialogData._id,
            office_name: $scope.User.office_name,
            jurisdiction_name: $scope.User.jurisdiction_name,
          };

          if ($scope.User.state) {
            var state = JSON.parse($scope.User.state);
            postobj.state = state.state;
          }
          if ($scope.User.county) {
            var obj = JSON.parse($scope.User.county);
            postobj.county = obj.county;
          }
          if ($scope.User.place) {
            var obj = JSON.parse($scope.User.place);
            postobj.place = obj.place;
            postobj.county_subdivision = obj.countySubdivision;
          }

          if ($scope.User.isCurrentOffice == 1) {
            postobj.from_date = new Date($scope.User.from_date).toISOString();
            postobj.end_date = new Date($scope.User.end_date).toISOString();
          }

          //MyService.ConsoleLog("postobj: ", postobj);

          MyService.managePoliticianOffice(postobj).then(
            function (response) {
              //MyService.ConsoleLog("response: ", response);
              if (response.data.success) {
                growl.success("Updated successfully.");
                $rootScope.$broadcast("UserProfileUpdated", response.data.data);
                $scope.closeThisDialog();
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
              } else {
                growl.error("Something went wrong.");
              }
            },
            function (err) {
              growl.error("Something went wrong.");
            }
          );
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e);
        }
      };

      $scope.removeOffice = function () {
        try {
          var userData = JSON.parse(tokenService.getUserData());
          //MyService.ConsoleLog("userData: ", userData);
          var postobj = {
            userid: userData._id,
            info_id: $scope.ngDialogData._id,
          };

          /*MyService.managePoliticianOffice().then(function(response){
            if (response.success) {
                growl.success('Updated successfully.');
            } else if (response.error && typeof response.error == "string") {
                growl.error('Error: ' + data.error);
            } else {
                growl.error('Something went wrong.');
            }
        }, function(err){
            growl.error("Something went wrong.");
        });*/
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e);
        }
      };

      if ($scope.ngDialogData) {
        var obj = $scope.ngDialogData;
        $scope.User.office_name = obj.office_name;
        $scope.User.jurisdiction_name = obj.jurisdiction_name;
        if (obj.from_date) $scope.User.from_date = new Date(obj.from_date);
        if (obj.end_date) {
          $scope.User.end_date = new Date(obj.end_date);
          $scope.User.isCurrentOffice = 1;
        }
      }
    },
  ])
  .controller("UpdatePoliticianOfficeCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    "$timeout",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      tokenService,
      MyService,
      $timeout
    ) {
      $scope.User = {};

      //MyService.ConsoleLog("UpdatePoliticianOfficeCtrl: ", $scope.ngDialogData);
      $scope.ShowAdd = true;

      $scope.statusGetStates = 0; //1: loading, 2: loaded, 3: failed
      var getAllStatesCanceler = $q.defer();
      $scope.getAllStates = function () {
        //MyService.ConsoleLog("** getAllStates **");
        if ($scope.counties) $scope.counties.length = 0;
        if ($scope.places) $scope.places.length = 0;
        var params = {
          country: "",
          // timeout: getAllStatesCanceler.promise
        };
        $scope.statusGetStates = 1;
        MyService.getAllStates(params).then(
          function (data) {
            //MyService.ConsoleLog("got states");
            $scope.statusGetStates = 2;
            $scope.states = angular.copy(data);
            if (
              !$scope.User.state &&
              $scope.ngDialogData &&
              $scope.ngDialogData.state
            ) {
              var len = $scope.states.length;
              for (var i = 0; i < len; i++) {
                if (
                  $scope.ngDialogData.state.toLowerCase() ==
                  $scope.states[i].state.toLowerCase()
                ) {
                  $scope.User.state = $scope.states[i];
                  //MyService.ConsoleLog("Set current state: ", $scope.User.state);
                  // $scope.getCounties();
                  break;
                }
              }
            }
          },
          function (err) {
            $scope.statusGetStates = 3;
            //MyService.ConsoleLog("got states error");
            // growl.error("Some");
          }
        );
      };
      $scope.getAllStates();

      $scope.RegisterButton = false;
      $scope.records = [];
      $scope.States = [];
      $scope.Cities = [];
      // $scope.UserType = $routeParams.type;

      $scope.Address = {
        ZipCode: "",
        Country: "",
        State: "",
        City: "",
      };

      $scope.User = {
        name: "",
        lastname: "",
        UserName: "",
        Password: "",
        Email: "",
        ZipCode: "",
      };

      $scope.Errors = [];

      $scope.levelOfGovt = ["Federal", "State", "Local"];
      $scope.fedlevelOfGovt = [
        "President",
        "Senate",
        "House of representatives",
      ];
      $scope.statelevelOfGovt = [
        "State House of Representatives",
        "State Senate",
        "State School Board",
        "State Other",
      ];
      $scope.locallevelOfGovt = [
        "County level job",
        "Judicial position",
        "Elementary school District Position",
        "Secondary School District Positio",
        "Unified School District Position",
        "City or Township position",
      ];

      //$scope.levelOfGovt = ['national', 'state', 'judicial district', 'county', 'sub county', 'city', 'state school board district', 'local school district', 'other'];

      $scope.offices = [];
      $scope.running_offices = [];

      $scope.govLevelChanged = function (electionFor) {
        $scope.running_flagShowStateDropdown = false;
        $scope.running_flagShowOfficeDropdown = false;
        $scope.flagShowStateDropdown = false;
        $scope.flagShowOfficeDropdown = false;
        //MyService.ConsoleLog("* govLevelChanged *");
        if (electionFor == "running") {
          $scope.User.office_name = null;
          $scope.running_flagShowStateDropdown = true;
          if ($scope.ngDialogData) {
            $scope.editflagShowOfficeDropdown = true;
          }

          //$scope.onStateChange('running');
        } else {
          $scope.User.cur_office_name = null;
          $scope.flagShowStateDropdown = true;
          //$scope.onStateChange('incumbent');
        }
      };

      $scope.localSchoolDistException = function (electionFor) {
        var exceptionState = ["AK", "DC", "VA"];
        if ((electionFor = "running")) {
          if (
            $scope.User.state &&
            exceptionState.indexOf($scope.User.state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_state &&
            exceptionState.indexOf($scope.User.cur_state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        }
      };
      //$scope.SearchByZipcode = function () {
      //    $http.get(baseApiUrl + 'votewise/search/' + $scope.Address.ZipCode)
      //        .then(function (response) {
      //            $scope.States = [];
      //            $scope.Cities = [];
      //            //MyService.ConsoleLog(response.data)
      //            angular.forEach(response.data, function (item) {
      //                $scope.States.push({ Name: item.stab });
      //                $scope.Cities.push({ Name: item.placenm });
      //            });
      //            $scope.Address.Country = "USA";
      //            $scope.Address.City = $scope.Cities[0].Name;
      //            $scope.Address.State = $scope.States[0].Name;
      //        });
      //};

      $scope.showOfficeAutoComplete = function (electionFor) {
        //MyService.ConsoleLog("showOfficeAutoComplete");
        // ['national', 'state', 'judicial district', 'county', 'sub county', 'city', 'state school board district', 'local', 'school district', 'other']
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "national":
              $scope.running_offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.state && $scope.User.district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.state && $scope.User.county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.state && $scope.User.state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.state &&
                $scope.localSchoolDistException() &&
                $scope.User.county &&
                $scope.User.district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.state && $scope.User.district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "national":
              $scope.offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.cur_state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.cur_state && $scope.User.cur_district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.cur_state && $scope.User.cur_state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.cur_state &&
                $scope.localSchoolDistException() &&
                $scope.User.cur_county &&
                $scope.User.cur_district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.cur_state && $scope.User.cur_district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        }
      };
      var running_flagShowStateDropdown = false;
      var flagShowStateDropdown = false;
      $scope.showSateDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSateDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.office_name) {
            case "senate":
            case "house of representatives":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          running_flagShowStateDropdown = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.cur_office_name) {
            case "senate":
            case "house of representatives":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          flagShowStateDropdown = false;
          return false;
        }
      };

      $scope.flagShowCandidateDropdown = false;
      $scope.running_flagShowCandidateDropdown = false;

      $scope.onOfficeChange = function (electionFor) {
        if (electionFor == "running") {
          //'Federal', 'State', 'Local'
          var nObj = new Object();
          nObj.level = $scope.User.level;
          nObj.office = $scope.User.off_name;
          nObj.state = JSON.stringify($scope.User.state);
        } else if (electionFor == "incumbent") {
          var nObj = new Object();
          nObj.level = $scope.User.cur_level;
          nObj.office = $scope.User.cur_off_name;
          nObj.state = JSON.stringify($scope.User.cur_state);
        }
        //MyService.ConsoleLog(nObj);
      };
      var running_flagCongressional = false;
      var flagCongressional = false;
      $scope.showCongressionalDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCongressionalDropdown");
        if (electionFor == "running") {
          if (
            $scope.User.office_name == "house of representatives" &&
            $scope.User.state
          ) {
            running_flagCongressional = true;
            return true;
          }
          running_flagCongressional = false;
          return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_office_name == "house of representatives" &&
            $scope.User.cur_state
          ) {
            flagCongressional = true;
            return true;
          }
          flagCongressional = false;
          return false;
        }
      };
      var running_flagDist = false;
      var flagDist = false;
      $scope.showDistrictDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "judicial district":
              if ($scope.User.state) {
                running_flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          running_flagDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "judicial district":
              if ($scope.User.cur_state) {
                flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          flagDist = false;
          return false;
        }
      };
      var running_flagConty = false;
      var flagConty = false;
      $scope.showCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.state) {
                running_flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.state &&
                exceptionState.indexOf($scope.User.state.statePostalCode) == -1
              ) {
                running_flagConty = true;
                return true;
              }
              break;
          }
          running_flagConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.cur_state) {
                flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.cur_state &&
                exceptionState.indexOf($scope.User.cur_state.statePostalCode) ==
                  -1
              ) {
                flagConty = true;
                return true;
              }
              break;
          }
          flagConty = false;
          return false;
        }
      };
      var running_flagSubConty = false;
      var flagSubConty = false;
      $scope.showSubCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSubCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "sub county":
              if ($scope.User.state && $scope.User.county) {
                running_flagSubConty = true;
                return true;
              }
              break;
          }
          running_flagSubConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "sub county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagSubConty = false;
                return true;
              }
              break;
          }
          flagSubConty = false;
          return false;
        }
      };
      var running_flagLocality = false;
      var flagLocality = false;
      $scope.showLocalityDropdown = function (electionFor) {
        //MyService.ConsoleLog("showLocalityDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "city":
              if ($scope.User.state && $scope.User.county) {
                running_flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          running_flagLocality = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "city":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          flagLocality = false;
          return false;
        }
      };
      var running_flagStateSchoolDist = false;
      var flagStateSchoolDist = false;
      $scope.showStateSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showStateSchoolDistDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state school board district":
              if ($scope.User.state) {
                running_flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          running_flagStateSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state school board district":
              if ($scope.User.cur_state) {
                flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          flagStateSchoolDist = false;
          return false;
        }
      };
      var running_flagLocalSchoolDist = false;
      var flagLocalSchoolDist = false;
      $scope.showLocalSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "local school district":
              if ($scope.User.state) {
                if ($scope.User.state && $scope.localSchoolDistException()) {
                  if ($scope.User.state && $scope.User.county) {
                    running_flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  running_flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }

          running_flagLocalSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "local school district":
              if ($scope.User.cur_state) {
                if (
                  $scope.User.cur_state &&
                  $scope.localSchoolDistException()
                ) {
                  if ($scope.User.cur_state && $scope.User.cur_county) {
                    flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.cur_state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }
          flagLocalSchoolDist = false;
          return false;
        }
      };
      //var getAddressOfRunningPoliCanceler = $q.defer();
      $scope.congressionalDistricts = [];
      var getAddressOfRunningPoliCanceler = $q.defer();
      $scope.running_flagShowOfficeDropdown = false;
      $scope.flagShowOfficeDropdown = false;
      $scope.showlevelOfGovt = [];
      $scope.onStateChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateChange **", electionFor);
        //MyService.ConsoleLog("** onStateChange **", $scope.User.level);
        if (electionFor == "running") {
          //'Federal', 'State', 'Local'
          switch ($scope.User.level) {
            case "federal":
            case "Federal":
              $scope.showlevelOfGovt = angular.copy($scope.fedlevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "state":
            case "State":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "local":
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.fedlevelOfGovt);
              $scope.running_flagShowOfficeDropdown = false;
              break;
          }
          //$scope.User.off_name = $scope.showlevelOfGovt[0];
        } else if (electionFor == "incumbent") {
          $scope.showlevelOfGovt.length = 0;
          //MyService.ConsoleLog($scope.User.cur_level);
          switch ($scope.User.cur_level) {
            case "Federal":
              $scope.showlevelOfGovt = angular.copy($scope.fedlevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "State":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.fedlevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
          }
          //MyService.ConsoleLog($scope.showlevelOfGovt);
          //$scope.User.cur_off_name = $scope.showlevelOfGovt[0];
        }
      };
      $scope.onDistrictChange = function (electionFor) {
        //MyService.ConsoleLog("** onDistrictChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "judicial district":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onDistrictChange **");
            switch ($scope.User.cur_level) {
              case "judicial district":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.cur_district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        }
      };
      $scope.onCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "county":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.state && $scope.User.county) {
                  $scope.User.cur_office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.running_subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.running_localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.running_localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                $scope.User.office_name = null;
                if (!$scope.localSchoolDistException() && $scope.User.county) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.state.statePostalCode,
                        CountyName: $scope.county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.running_localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "county":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.cur_state && $scope.User.cur_county) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                //MyService.ConsoleLog("cur_county : ", $scope.User.cur_county);
                $scope.User.cur_office_name = null;
                if (
                  !$scope.localSchoolDistException() &&
                  $scope.User.cur_county
                ) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.cur_state.statePostalCode,
                        CountyName: $scope.cur_county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        }
      };
      $scope.onSubCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "sub county":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "sub county":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalityChange = function (electionFor) {
        //MyService.ConsoleLog("** onLocalityChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "city":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.locality
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    CountySubdivisionName: $scope.User.locality.label,
                    PlaceName: $scope.User.locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "city":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.cur_locality
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    CountySubdivisionName: $scope.User.cur_locality.label,
                    PlaceName: $scope.User.cur_locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onStateSchoolDistChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateSchoolDistChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "state school board district":
                if ($scope.User.state && $scope.User.state_school_dist) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    StateBoardEducationDistrict: $scope.User.state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "state school board district":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_state_school_dist
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    StateBoardEducationDistrict:
                      $scope.User.cur_state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalSchoolDistChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        }
      };

      try {
        if ($scope.ngDialogData.from_date && $scope.ngDialogData.end_date) {
          $scope.User.isInCurrentOffice = "1";
          //MyService.ConsoleLog("$sope.User.isInCurrentOffice: ", $scope.User.isInCurrentOffice);
        }

        if (
          $scope.ngDialogData.levelOfGov.toLowerCase() == "national" ||
          $scope.ngDialogData.levelOfGov.toLowerCase() == "all usa"
        )
          $scope.User.cur_level = $scope.ngDialogData.levelOfGov.toLowerCase();
        if ($scope.ngDialogData.from_date) {
          $scope.User.from_date = new Date($scope.ngDialogData.from_date);
        }
        if ($scope.ngDialogData.end_date) {
          $scope.User.end_date = new Date($scope.ngDialogData.end_date);
        }
        if ($scope.ngDialogData.office_name) {
          $scope.User.cur_office_name = $scope.ngDialogData.office_name;
        }
      } catch (e) {
        //MyService.ConsoleLog("$scope.User: ", e);
      }

      $scope.AddNewOffice = function (opt) {
        if (tokenService.getUserData()) {
          var userData = JSON.parse(tokenService.getUserData());
          var newData = new Object();
          //MyService.ConsoleLog($scope.User);
          if (opt == "incumbent") {
            newData.govLevel = $scope.User.cur_level;
            if ($scope.User.cur_state) {
              newData.state = $scope.User.cur_state.state;
              newData.statePostalCode = $scope.User.cur_state.statePostalCode;
            }
            if ($scope.User.cur_off_name != "Other") {
              newData.office_name = $scope.User.cur_off_name;
            } else {
              newData.office_name = $scope.User.cur_office_name;
              newData.office_location = $scope.User.cur_office_location;
            }
          } else {
            newData.govLevel = $scope.User.level;
            if ($scope.User.state) {
              newData.state = $scope.User.state.state;
              newData.statePostalCode = $scope.User.state.statePostalCode;
            }
            if ($scope.User.off_name != "Other") {
              newData.office_name = $scope.User.off_name;
            } else {
              newData.office_name = $scope.User.office_name;
              newData.office_location = $scope.User.office_location;
            }

            newData.from_date = $scope.User.from_date;
            newData.end_date = $scope.User.end_date;
          }
          //MyService.ConsoleLog(newData);

          var validate = true;

          if (newData.govLevel == "") {
            alert("Please select level of government.");
            validate = false;
          }

          if (newData.state == "") {
            alert("Please select State.");
            validate = false;
          }

          if (newData.office_name == "") {
            alert("Please select/enter office details.");
            validate = false;
          }

          if (
            opt == "running" &&
            (!newData.from_date || newData.from_date == "")
          ) {
            alert("Please enter from date.");
            validate = false;
          }

          if (
            opt == "running" &&
            (!newData.end_date || newData.end_date == "")
          ) {
            alert("Please enter to date.");
            validate = false;
          }
          if (validate) {
            MyService.AddNewOfficeData(newData).then(
              function (payload) {
                //MyService.ConsoleLog(payload);
                if (payload.data.success) {
                  $scope.$parent.profile.politician_info =
                    payload.data.user.politician_info;
                  ngDialog.closeAll();
                } else {
                  //MyService.ConsoleLog(payload);
                  alert("Something went wrong");
                  ngDialog.closeAll();
                }
              },
              function (errPayload) {
                //MyService.ConsoleLog(errPayload);
                alert("Something went wrong");
                ngDialog.closeAll();
              }
            );
          }
        } else {
          alert("Please login first");
          ngDialog.closeAll();
        }
      };

      $scope.UpdateOffice = function () {
        var newData = new Object();

        newData.levelOfGov = $scope.User.level;
        if ($scope.User.state) {
          newData.state = $scope.User.state.state;
          newData.statePostalCode = $scope.User.state.statePostalCode;
        }
        newData.office_name = $scope.User.off_name;
        if ($scope.User.from_date) {
          newData.from_date = $scope.User.from_date;
        }
        if ($scope.User.end_date) {
          newData.end_date = $scope.User.end_date;
        }

        //MyService.ConsoleLog(newData);

        var validate = true;

        if (newData.govLevel == "") {
          alert("Please select level of government.");
          validate = false;
        }

        if (newData.state == "") {
          alert("Please select State.");
          validate = false;
        }

        if (newData.office_name == "") {
          alert("Please select/enter office details.");
          validate = false;
        }

        if (newData.from_date && newData.from_date == "") {
          alert("Please enter from date.");
          validate = false;
        }

        if (newData.end_date && newData.end_date == "") {
          alert("Please enter to date.");
          validate = false;
        }
        if (validate) {
          var idx = $scope.$parent.profile.politician_info.indexOf(
            $scope.ngDialogData
          );
          //MyService.ConsoleLog(idx);
          $scope.$parent.profile.politician_info[idx] = newData;
          //MyService.ConsoleLog($scope.$parent.profile.politician_info);

          MyService.UpdateOfficeData({
            politician_info: JSON.stringify(
              $scope.$parent.profile.politician_info
            ),
          }).then(
            function (payload) {
              //MyService.ConsoleLog(payload);
              if (payload.data.success) {
                $scope.$parent.profile.politician_info =
                  payload.data.user.politician_info;
                ngDialog.closeAll();
              } else {
                //MyService.ConsoleLog(payload);
                ngDialog.closeAll();
              }
            },
            function (errPayload) {
              //MyService.ConsoleLog(errPayload);
              ngDialog.closeAll();
            }
          );
        }
      };

      $scope.removeOffice = function () {
        try {
          var userData = JSON.parse(tokenService.getUserData());
          //MyService.ConsoleLog("userData: ", userData);
          var postobj = {
            userid: userData._id,
            info_id: $scope.ngDialogData._id,
          };

          /*MyService.managePoliticianOffice().then(function(response){
            if (response.success) {
                growl.success('Updated successfully.');
            } else if (response.error && typeof response.error == "string") {
                growl.error('Error: ' + data.error);
            } else {
                growl.error('Something went wrong.');
            }
        }, function(err){
            growl.error("Something went wrong.");
        });*/
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e);
        }
      };

      function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

      if ($scope.ngDialogData) {
        //MyService.ConsoleLog("Edit called");
        $scope.ShowAdd = false;
        $scope.running_flagShowStateDropdown = true;
        // $scope.running_flagShowOfficeDropdown = true;
        $scope.editflagShowOfficeDropdown = true;

        $scope.User.level = toTitleCase($scope.ngDialogData.levelOfGov);
        $scope.User.off_name = toTitleCase($scope.ngDialogData.office_name);
        $scope.onStateChange("running");
        if ($scope.ngDialogData.from_date) {
          $scope.User.from_date = $scope.ngDialogData.from_date;
        }
        if ($scope.ngDialogData.end_date) {
          $scope.User.to_date = $scope.ngDialogData.end_date;
        }
        //MyService.ConsoleLog($scope.User.level + "====" + $scope.User.off_name);
        //MyService.ConsoleLog($scope.ngDialogData.levelOfGov + "====" + $scope.ngDialogData.office_name);
        /*
       = {
          level: $scope.ngDialogData.levelOfGov,
          off_name: $scope.ngDialogData.office_name
      }
      */
      } else {
        //MyService.ConsoleLog("Add Called");
        $scope.ShowAdd = true;
      }
    },
  ])
  .controller("PlacesShapeCtrl", [
    "$scope",
    "ngDialog",
    "$rootScope",
    "MyService",
    "$http",
    "$timeout",
    function ($scope, ngDialog, $rootScope, MyService, $http, $timeout) {
      $scope.gridOptions = {
        enableFiltering: true,
        showGridFooter: true,
        showColumnFooter: false,
        enableColumnMenu: true,
      };

      $scope.getPlacesData = function () {
        MyService.getPlaceShape({}).then(
          function (records) {
            // MyService.ConsoleLog(records);
            // console.log('records for place shape', records);
            var columns = [];
            if (records.data.success) {
              var fdata = records.data.data;
              angular.forEach(fdata[0], function (pd, i) {
                // MyService.ConsoleLog("Field :", i);
                if (i != "__v" && i != "_id") {
                  var nc = Object;
                  nc.field = i;
                  nc.displayName = i;
                  nc.width = 100;
                  var str = {
                    field: i,
                    width: "140",
                  };
                  columns.push(str);
                }
              });
              MyService.ConsoleLog(columns);
              $scope.gridOptions.columnDefs = columns;
              $scope.gridOptions.data = fdata;
            }
          },
          function (err) {
            $scope.showLoader = false;
            MyService.ConsoleLog("error : ", err);
          }
        );
      };

      if ($rootScope.showPlacesShape) {
        $scope.getPlacesData();
      }
    },
  ])
  .controller("recommendCtrl", [
    "$scope",
    "$location",
    "$rootScope",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    function (
      $scope,
      $location,
      $rootScope,
      growl,
      ngDialog,
      tokenService,
      MyService
    ) {
      $scope.userData = tokenService.getUserRole();
      $scope.favorite = {
        type: "",
        name: "",
        description: "",
        artist: "",
        releaseDate: "",
        imdburl: "",
        website: "",
        utype: $scope.userData,
      };
      $scope.fav = {
        data: [],
      };
      $scope.showFaouriteList = 0;
      //MyService.ConsoleLog($scope.userData);
      $scope.allType = ["Movie", "Music", "Book", "Podcasts"];

      $scope.ListMyFavorite = function () {
        $scope.showFaouriteList = 3;
        $scope.fav.data.length = 0;
        MyService.ListMyFavorite().then(
          function (payload) {
            //MyService.ConsoleLog(payload);
            $scope.showFaouriteList = 1;
            if (payload.data.success) {
              $scope.fav.data = payload.data.data;
            }
          },
          function (errorPayload) {
            $scope.showFaouriteList = 1;
            //MyService.ConsoleLog(errorPayload);
          }
        );
      };

      $scope.DisplayFavorite = function () {
        $scope.showFaouriteList = 2;
        $scope.favorite = {
          type: "",
          name: "",
          description: "",
          artist: "",
          releaseDate: "",
          imdburl: "",
          website: "",
          utype: $scope.userData,
        };
      };
      $scope.AddFavorite = function () {
        var Validation = true;
        if ($scope.favorite.type == "") {
          alert("Please select favorite type.");
          Validation = false;
        }

        if (Validation && $scope.favorite.name == "") {
          alert("Please enter name.");
          Validation = false;
        }

        if (Validation) {
          //MyService.ConsoleLog($scope.favorite);

          MyService.AddFavorite($scope.favorite).then(
            function (payload) {
              //MyService.ConsoleLog(payload);
              if (payload.data.success) {
                alert("Record added successfully");
                $scope.ListMyFavorite();
              } else {
                alert("Something went wrong.");
              }
            },
            function (errorPayload) {
              //MyService.ConsoleLog(errorPayload);
              alert("Something went wrong.");
            }
          );
        }
      };

      $scope.removeFav = function (rdata, index) {
        var message = "Are you sure you want to delete this record?";
        //MyService.ConsoleLog(rdata);
        if (rdata._id) {
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", mydata);
              MyService.RemoveFavorite({
                id: rdata._id,
              }).then(
                function (payload) {
                  if (payload.data.success) {
                    alert("Record deleted successfully");
                    $scope.ListMyFavorite();
                  } else {
                    alert("Something went wrong.");
                  }
                },
                function (errorPayload) {
                  //MyService.ConsoleLog(errorPayload);
                  alert("Something went wrong.");
                }
              );
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
        }
      };

      $scope.recommendFav = function (data, recommend) {
        var nobj = new Object();
        nobj.favId = data._id;
        nobj.recommend = recommend;
        MyService.recommendFav({
          favId: data._id,
          recommend: recommend,
        }).then(
          function (payload) {
            if (payload.data.success) {
              alert("Record updated successfully");
              $scope.ListMyFavorite();
            } else {
              alert("Something went wrong.");
            }
          },
          function (errorPayload) {
            alert("Something went wrong.");
          }
        );
      };

      $scope.goBack = function () {
        $scope.showFaouriteList = 1;
      };
      $scope.ListMyFavorite();
    },
  ])

  .directive("fileread", [
    function () {
      return {
        scope: {
          opts: "=",
          address: "=",
        },
        link: function ($scope, $elm, $attrs) {
          // MyService.ConsoleLog("$scope >>", $scope, $elm)
          $elm.on("change", function (changeEvent) {
            var reader = new FileReader();
            // console.log("reader >", reader);
            reader.onload = function (evt) {
              $scope.$apply(function () {
                var data = evt.target.result;

                var workbook = XLSX.read(data, {
                  type: "binary",
                });

                var headerNames = XLSX.utils.sheet_to_json(
                  workbook.Sheets[workbook.SheetNames[0]],
                  {
                    header: 1,
                  }
                )[0];

                var vdata = XLSX.utils.sheet_to_json(
                  workbook.Sheets[workbook.SheetNames[0]]
                );
                // console.log("vdata>>", vdata);
                $scope.opts.columnDefs = [];

                //$scope.states = ['Alabama', 'Alaska', 'California'];
                /*
               $scope.myHeaderCellTemplate = '<div class="ngHeaderSortColumn {{col.headerClass}}" ng-style="{cursor: col.cursor}" ui-grid-auto-resize ng-class="{ ngSorted: !noSortVisible }">' +
                   '<div ng-click="col.sort($event)" ng-class="\'colt\' + col.index" class="ngHeaderText">{{col.displayName}}</div><div mass-autocomplete><input ng-model="dirty.value" mass-autocomplete-item="grid.appScope.autocomplete_options"></div>' +
                   '<div class="ngSortButtonDown" ng-show="col.showSortButtonDown()"></div>' +
                   '<div class="ngSortButtonUp" ng-show="col.showSortButtonUp()"></div>' +
                   '<div class="ngSortPriority">{{col.sortPriority}}</div>' +
                   '</div>' +
                   '<div ng-show="col.resizable" class="ngHeaderGrip"></div>';

               */
                // var myfields = ["name", "email", "PrivateEmail", "office", "officePlace", "party", "electionDate", "voterScope", "public_mailing_address", "private_mailing_address", "website", "RapidTransitDistrict", "statement", "numberOfOpenings", "incumbent", "publicZipCode", "privateZipCode", "PublicPhoneNumber", "PrivatePhoneNumber", "StateBoardEducationDistrict", "JudicialCircuitNumber", "StateFIPS", "StatePostalCode", "CountyFIPS", "CountyName", "CountySubdivisionFIPS", "CountySubdivisionName", "PlaceFIPS", "PlaceName", "VoterDistrict", "VotingDistrictName", "FederalCongressionalDistrict", "StateSenateDistrict", "StateHouseofRepresentativesDistrict", "UnifiedSchoolDistrict", "UnifiedSchoolDistrictName", "ElementarySchoolDistrict", "ElementarySchoolDistrictName", "SecondarySchoolDistrict", "SecondarySchoolDistrictName", "TotalPop", "afact", "dataX", "dataY", "dataZ", "RunningForOffice", "facebookpage", "twitterfeed", "website2", "type_of_election", "data_origin"];

                function camelize(str) {
                  return str.replace(
                    /(?:^\w|[A-Z]|\b\w|\s+)/g,
                    function (match, index) {
                      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                      return index == 0
                        ? match.toLowerCase()
                        : match.toUpperCase();
                    }
                  );
                }

                $scope.myHeaderCellTemplate =
                  '<div  ng-style="{cursor: col.cursor}" ui-grid-auto-resize ng-class="{ ngSorted: !noSortVisible }">' +
                  '<div class="ngHeaderText">{{col.displayName}}</div><div><select ng-model="dirty.value" ng-change="grid.appScope.changeDisplay(col,dirty.value)">';
                // for(var i=0; i<myfields.length;i++) {

                //     $scope.myHeaderCellTemplate += '<option value="'+myfields[i]+'">'+myfields[i]+'</option>';
                // }

                var checkcityArray = [
                  "city",
                  "City",
                  "CITY",
                  "town",
                  "Town",
                  "TOWN",
                ];
                var cityIdx = "";
                $scope.myHeaderCellTemplate +=
                  '<option ng-repeat="fld in grid.appScope.myFields" value="{{fld}}">{{fld}}</option>';
                $scope.myHeaderCellTemplate +=
                  "</select></div>" +
                  '<div class="ngSortButtonDown" ng-show="col.showSortButtonDown()"></div>' +
                  '<div class="ngSortButtonUp" ng-show="col.showSortButtonUp()"></div>' +
                  '<div class="ngSortPriority">{{col.sortPriority}}</div>' +
                  "</div>" +
                  '<div ng-show="col.resizable" class="ngHeaderGrip"></div>';

                var myFields = [
                  "name",
                  "email",
                  "privateEmail",
                  "office",
                  "officePlace",
                  "candidateStatus",
                  "party",
                  "postCard",
                  "electionDate",
                  "voterScope",
                  "publicMailingAddress",
                  "privateMailingAddress",
                  "website",
                  "rapidTransitDistrict",
                  "statement",
                  "numberOfOpenings",
                  "incumbent",
                  "publicZipCode",
                  "privateZipCode",
                  "publicPhoneNumber",
                  "privatePhoneNumber",
                  "stateBoardEducationDistrict",
                  "judicialCircuitNumber",
                  "stateFips",
                  "statePostalCode",
                  "state",
                  "countyFips",
                  "countyName",
                  "countySubdivisionFips",
                  "countySubdivisionName",
                  "placeFips",
                  "placeName",
                  "voterDistrict",
                  "votingDistrictName",
                  "federalCongressionalDistrict",
                  "stateSenateDistrict",
                  "stateHouseofRepresentativesDistrict",
                  "unifiedSchoolDistrict",
                  "unifiedSchoolDistrictName",
                  "elementarySchoolDistrict",
                  "elementarySchoolDistrictName",
                  "secondarySchoolDistrict",
                  "secondarySchoolDistrictName",
                  "officeDistrictName",
                  "officeStatus",
                  "totalPop",
                  "afact",
                  "dataX",
                  "dataY",
                  "dataZ",
                  "runningForOffice",
                  "facebookPage",
                  "twitterFeed",
                  "website2",
                  "typeOfElection",
                  "dataOrigin",
                ];

                function getSelectedRecord(h) {
                  // MyService.ConsoleLog("data", h);
                  // console.log('h >', h);
                  // console.log("touched",addr);

                  var arr = myFields.map(function (elem) {
                    return elem.toLowerCase();
                  });
                  var dname = h.toLowerCase();
                  var idx = arr.indexOf(dname);
                  if (idx > -1) {
                    return myFields[idx];
                  } else {
                    return camelize(h.toLowerCase());
                  }
                }
                // console.log('headerNames', headerNames);
                headerNames.forEach(function (h) {
                  var disName = getSelectedRecord(h);
                  //MyService.ConsoleLog("disName>>>", disName);
                  var wdth = disName.length * 10;
                  if (wdth < 150) {
                    wdth = 150;
                  }
                  var checkarray = [];
                  checkarray.length = 0;
                  switch (h) {
                    case "state":
                      // console.log("state >>", $scope.address.state);
                      checkarray = angular.copy($scope.address.state.state);
                      break;
                    case "federalCongressionalDistrict":
                    case "FederalCongressionalDistrict":
                      checkarray = angular.copy(
                        $scope.address.FederalCongressionalDistrict
                      );
                      break;
                    case "stateHouseofRepresentativesDistrict":
                    case "StateHouseofRepresentativesDistrict":
                      checkarray = angular.copy(
                        $scope.address.StateHouseofRepresentativesDistrict
                      );
                      break;
                    case "default":
                      checkarray = angular.copy($scope.address.city);
                      break;
                  }
                  // console.log("checkarray<><><><>", checkarray);
                  /* $scope.opts.columnDefs.push({
                   field: h,
                   displayName: disName,
                   minWidth: wdth,
                   enableSorting: false,
                   enableCellEdit: true,
                   headerCellTemplate: $scope.myHeaderCellTemplate,
                   cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                     var gVal = grid.getCellValue(row, col);
                     if (gVal) {
                       //MyService.ConsoleLog(gVal);
                       console.log("checkarray >>", checkarray)
                       // if (checkarray && checkarray != "") {
                       var cidx = checkarray.filter(function (d) {
                         // MyService.ConsoleLog("d>", d);
                         if (d && d != "") {
                           // MyService.ConsoleLog("gVal<><><>", gVal);
                           // console.log("address>>", $scope.address.towns)
                           if ($scope.address.towns.indexOf(gVal) == -1) {
                             return gVal.indexOf(d) !== -1;
                           }
                         }

                       });
                       // }

                       // console.log("$scope.address.state >>", $scope.address.state);
                       // if (checkarray && checkarray != "") {
                       var staids = checkarray.filter(function (st) {
                         console.log("st >>", st);
                         if (st && st != "") {
                           if ($scope.address.state.state.indexOf(gVal) == -1) {
                             return gVal.indexOf(st) !== -1;
                           }
                         }
                       });
                       // }
                       console.log("stais", staids);
                       if (staids != "") {
                         return 'yellow'
                       }

                       if (cidx != "") {
                         return 'yellow'
                       }
                     }
                     return '';
                   }
                 });*/

                  if (checkcityArray.indexOf(h) > -1) {
                    cityIdx = checkcityArray.indexOf(h);
                    //MyService.ConsoleLog("cityIdx>>", cityIdx);
                    //MyService.ConsoleLog($scope.opts);
                    //MyService.ConsoleLog($scope);
                    //MyService.ConsoleLog(grid.appScope.address);
                    //MyService.ConsoleLog($scope.address.towns);

                    $scope.opts.columnDefs.push({
                      field: h,
                      displayName: disName,
                      width: wdth,
                      enableSorting: false,
                      enableCellEdit: true,
                      headerCellTemplate: $scope.myHeaderCellTemplate,
                      cellClass: function (
                        grid,
                        row,
                        col,
                        rowRenderIndex,
                        colRenderIndex
                      ) {
                        var gVal = grid.getCellValue(row, col);
                        if (gVal) {
                          //MyService.ConsoleLog(gVal);
                          var cval = gVal.split(",")[0].trim();
                          // MyService.ConsoleLog(cval);
                          // alert('vishal');
                          var cidx = $scope.address.towns.filter(function (d) {
                            return d.indexOf(cval) !== -1;
                          });
                          if (cidx != "") {
                            return "yellow";
                          }
                        }
                        return "";
                      },
                    });
                  } else {
                    var arr = [];
                    arr.push($scope.address.county);
                    // console.log("$scope.address", $scope.address);

                    $scope.opts.columnDefs.push({
                      field: h,
                      displayName: disName,
                      minWidth: wdth,
                      enableSorting: false,
                      enableCellEdit: true,
                      enableColumnMenus: true,
                      headerCellTemplate: $scope.myHeaderCellTemplate,
                      cellClass: function (
                        grid,
                        row,
                        col,
                        rowRenderIndex,
                        colRenderIndex
                      ) {
                        var gVal = grid.getCellValue(row, col);
                        if (gVal) {
                          //MyService.ConsoleLog(gVal);
                          // console.log("grid", grid);

                          // console.log("$scope.address.city >>", $scope.address.city);
                          // console.log("$scope.address >>", $scope.address);
                          // console.log('col >>', col);

                          // console.log('row >', Object.keys(row.entity));
                          // console.log('row.entity.placeName >>', row.entity.placeName);
                          var sFields = ["state", "placeName", "countyName"];
                          // var chr;
                          var cidx = $scope.address.towns.filter(function (d) {
                            // MyService.ConsoleLog("d>", d);
                            if (d && d != "") {
                              // console.log("d >>", d);
                              // if (gVal == d) {
                              // console.log('$scope.address.towns', $scope.address.towns);
                              //   var reg = new RegExp(gVal, 'gi');
                              // }

                              // 'placeName' == col.field
                              if ("placeName" == col.field) {
                                // console.log('col.field >>>', col.field);
                                // console.log($scope.address.city.indexOf(gVal));
                                if ($scope.address.towns.indexOf(gVal) == -1) {
                                  // console.log('gval', gVal);
                                  // console.log('new search', gVal.search());

                                  return gVal.indexOf(d) == -1;
                                }
                              }
                            }
                          });

                          var sidx = arr.filter(function (item) {
                            if (item && item != "") {
                              // console.log('item >', item.countyName);
                              // console.log('col >', col.field);
                              if ("countyName" == col.field) {
                                console.log(
                                  "item.countyName",
                                  item.countyName,
                                  +" - " + gVal
                                );
                                console.log(
                                  "samp separate",
                                  item.countyName.indexOf(gVal)
                                );

                                if (gVal.indexOf(item.countyName) == -1) {
                                  return gVal.indexOf(item.countyName) == -1;
                                }
                              }
                            }
                          });
                          // console.log("sids >>", sidx);

                          var stAr = [];
                          stAr.push($scope.address.state);

                          var stat = stAr.filter(function (ds) {
                            // MyService.ConsoleLog("d>", d);
                            // console.log("d", d);
                            if (ds && ds != "") {
                              if ("state" == col.field) {
                                // console.log('col.field >', col.field);
                                // console.log('ds.state.indexOf >>', ds.state.indexOf);
                                if (ds.state.indexOf(gVal) == -1) {
                                  // console.log('Inside', gVal.indexOf(ds.state), ds.state);
                                  // console.log('Inside second', gVal.indexOf(ds));
                                  // alert('got');
                                  return ds.state.indexOf(gVal) == -1;
                                }
                              }
                            }
                          });
                          // console.log("stat >>", stat);
                          // publicMailingAddress

                          // school.push(address.)
                          //

                          var pub =
                            $scope.address.UnifiedSchoolDistrictName.filter(
                              function (e) {
                                // MyService.ConsoleLog("d>", d);
                                if (e && e != "") {
                                  if (
                                    "unifiedSchoolDistrictName" == col.field
                                  ) {
                                    // console.log('col.field >>>', col.field);
                                    // console.log($scope.address.city.indexOf(gVal));
                                    if (
                                      $scope.address.UnifiedSchoolDistrictName.indexOf(
                                        gVal
                                      ) == -1
                                    ) {
                                      // console.log('gval', gVal);
                                      // console.log('new search', gVal.search());
                                      // console.log('gVal.includes(d)', gVal.includes(d), d);
                                      // console.log('search', gVal.search(e), gVal);
                                      return gVal.search(e) == -1;
                                    }
                                  }
                                }
                              }
                            );

                          if (cidx != "") {
                            return "yellow";
                          }
                          if (sidx != "") {
                            return "yellow";
                          }
                          if (stat != "") {
                            return "yellow";
                          }
                          // console.log('pub >', pub);
                          if (pub != "") {
                            return "yellow";
                          }

                          // var ind = arr.map(function (e) {
                          //   return e.state;
                          // }).indexOf(gVal);
                          // console.log("Indx >>", ind);

                          // var sidx = $scope.address.state.state.indexOf(gVal);
                        }
                        return "";
                      },
                    });
                  }

                  // $scope.opts.columnDefs.push({ field: h, displayName: h, width: 150, enableSorting: false, enableCellEdit: true, headerCellTemplate: $scope.myHeaderCellTemplate });
                });
                //MyService.ConsoleLog($scope.opts);
                //$scope.address.places

                $scope.opts.data = vdata;

                $elm.val(null);
              });
            };

            reader.readAsBinaryString(changeEvent.target.files[0]);
          });
        },
      };
    },
  ]);
