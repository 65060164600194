'use strict';
angular.module('myApp.uploadPopulationData', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/uploadPopulationData', {
      templateUrl: 'app/uploadPopulationData/uploadPopulationData.html',
      controller: 'uploadPopulationDataCtrl'
    });
  }]).controller('uploadPopulationDataCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', '$sce', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window, $sce) {



    $scope.showLoader = false;
    $scope.errorFile = "";
    $scope.uploadBallotMeasures = function (data) {
      $scope.showLoader = true;
      if (data && data.files != "") {
        MyService.ConsoleLog(data.files.type.indexOf("office"));
        if (data.files.type.indexOf("office") > -1) {
          //MyService.ConsoleLog("data : ", data);
          var fd = new FormData();
          fd.append('file', data.files);
          var posturl = baseApiUrl + "/user/uploadPopulationData";
          $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            })
            .then(function (response, status, headers, config) {
              //MyService.ConsoleLog("response : ", response);
              $scope.PoliticianButton = false;
              $scope.upload.files = "";
              $scope.upload.opt = "";
              if (response.data.success) {
                //$location.path('/learnHome');
                MyService.ConsoleLog("data", response);
                $scope.errorFile = response.data.errorfile;
                $scope.PoliticianButton = false;
                //$scope.getAllPolitician();
                $scope.showLoader = false;
                alert("File successfully imported.");
              } else {
                $scope.showLoader = false;
                alert("Something went wrong.");
              }
            }, function (data, status, headers, config) {
              $scope.PoliticianButton = false;
              //MyService.ConsoleLog("error");
            });
        } else {
          $scope.PoliticianButton = false;
          alert("Please select xlsx file");
        }
      } else {
        $scope.PoliticianButton = false;

        alert("Please select file");
      }
    };


  }]);
