"use strict";
angular
  .module("myApp.publicProfile", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/publicProfile/:id/:type?", {
        templateUrl: "app/publicProfile/publicProfile.html",
        controller: "publicProfileCtrl",
      });
    },
  ])
  .controller("publicProfileCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "growl",
    "$sce",
    "ngDialog",
    "$cookies",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      growl,
      $sce,
      ngDialog,
      $cookies
    ) {
      $scope.userId = $routeParams.id;
      // MyService.ConsoleLog("user id :", $scope.userId);
      $rootScope.welcome = false;
      $scope.loginUser = tokenService.getUserId();
      $scope.politicianUser = "false";
      window.localStorage.removeItem("isShowPreviousResult");

      window.localStorage.setItem("isShowPreviousResult", true);

      window.localStorage.setItem("isShowOldResult", true);
      // MyService.ConsoleLog("route type", $routeParams);
      if ($routeParams.type) {
        $scope.politicianUser = $routeParams.type;
      }
      window.localStorage.removeItem("isShowSpecialMatch");
      if (tokenService.getUserData()) {
        $scope.UserData = JSON.parse(tokenService.getUserData());
        // MyService.ConsoleLog("$scope.UserData >", $scope.UserData);
      }
      $scope.shobackbtn = false;
      if ($cookies.get("params")) {
        $scope.shobackbtn = true;
      }
      MyService.ConsoleLog("params id", $routeParams.id);
      $scope.userDetails = {};
      $scope.userProfile = {};
      $scope.statusGetData = 0;
      $scope.propublicaData = [];
      $scope.memberRole = [];
      $scope.memberRoleCommittee = [];
      $scope.committeeData = [];
      $scope.currentRole = 0;
      $scope.allRoles = 1;
      $scope.showSponsoredContent = false;
      $scope.sponsorData = [];
      $scope.industrySupport = [];
      $scope.atLarge = "";
      $scope.memberCrpId = "";
      $scope.personalHoldingData = [];
      $scope.fundRaisingData = [];
      $scope.topContributorData = [];
      $scope.currentContent = "";
      $scope.allroles = [];
      $scope.committeesYears = [];
      $scope.contYear = new Date().getFullYear().toString();
      $scope.supportYear = new Date().getFullYear();
      $scope.contYearArray = [];
      $scope.selectedYear = "0";
      $scope.selectedYearCommittee = "0";
      for (var x = 0; x < 15; x++) {
        $scope.contYearArray.push($scope.contYear - x);
      }
      // MyService.ConsoleLog('@@@@ ', $scope.contYearArray);
      $scope.getData = function () {
        //MyService.ConsoleLog("politicianUser:",$scope.politicianUser);
        $scope.statusGetData = 1;
        MyService.getUserPublicProfile({
          id: $routeParams.id,
          type: $scope.politicianUser,
        }).then(
          function (response) {
            MyService.ConsoleLog("response", response);

            $scope.statusGetData = 2;
            if (response.data.success) {
              $scope.userDetails = response.data.data;
              // MyService.ConsoleLog('new user details : ', response.data.data);

              $scope.userProfile = $scope.userDetails.userData;

              MyService.ConsoleLog(
                "userDetails for user : ",
                $scope.userProfile
              );
              if ($scope.UserData && $scope.UserData.followlist.length > 0) {
                var fLen = $scope.UserData.followlist.length;
                for (var f = 0; f < fLen; f++) {
                  // MyService.ConsoleLog("$scope.UserData.followlist[f]", $scope.UserData.followlist[f]);
                  if ($scope.UserData.followlist[f] == $routeParams.id) {
                    $scope.showUnFollow = true;
                    // growl.success("showUnfollow");
                    // alert("showunfollow");
                  } else {
                    // MyService.ConsoleLog("userProfile", $scope.userProfile);
                    if (
                      $scope.UserData.followlist[f] ==
                      $scope.userProfile.officeId
                    ) {
                      $scope.showUnFollow = true;
                    }
                  }
                }
              }
              if ($scope.userProfile.politicianInfo) {
                var ulen = $scope.userProfile.politicianInfo.length;
                for (var i = 0; i < ulen; i++) {
                  if (
                    $scope.userProfile.politicianInfo[i].incumbent &&
                    $scope.userProfile.politicianInfo[i].incumbent != ""
                  ) {
                    $scope.isIncumbent = $scope.userProfile.politicianInfo[
                      i
                    ].incumbent.toLowerCase();
                  } else {
                    // $scope.isIncumbent = "";
                  }
                  MyService.ConsoleLog(
                    "$scope.isIncumbent",
                    $scope.isIncumbent
                  );
                }
              }
              $rootScope.userData = $scope.userProfile;
              var edat = $scope.userProfile.electionDate;
              // MyService.ConsoleLog("EleDate >", edat);
              if (edat && edat != "") {
                $scope.eleDate = new Date(edat).toISOString().split("T")[0];
                // MyService.ConsoleLog("EleDate >", $scope.eleDate);
              }

              var today = new Date();
              $scope.curDate = today.toISOString().split("T")[0];
              $scope.curYear = today.getFullYear();
              // MyService.ConsoleLog("$scope.curYear", $scope.curYear, $scope.userProfile.electionDate);

              // MyService.ConsoleLog("curDate >", $scope.curDate);

              if (
                $scope.userProfile.politicianIds &&
                $scope.userProfile.politicianIds[0]
              ) {
                // alert("inside");
                $scope.getRatingData($scope.userProfile.politicianIds[0]);
              } else if ($scope.userProfile.votesmartCandidateId) {
                $scope.getRatingData($scope.userProfile._id);
              } else {
                $scope.getRatingData($scope.userProfile._id);
              }
            } else {
              $scope.statusGetData = 3;
            }
          },
          function (errorPayload) {
            $scope.statusGetData = 3;
          }
        );
      };

      $scope.MyDialog1 = "";
      $scope.showHtmlViewer1 = function (content, ttl) {
        // console.log(content);
        $scope.displayContent = $sce.trustAsHtml(
          content.replace(/(\r\n|\n|\r)/g, "<br />")
        );
        $scope.MyDialog1 = ngDialog.open({
          template: "statement",
          scope: $scope,
          // className: 'ngdialog-theme-default'
        });
      };

      $scope.getMatch = function () {
        MyService.ConsoleLog("This is inside", $scope.userProfile);
        window.localStorage.setItem("isShowSpecialMatch", true);
        $location.path("/ballotMatch/" + $scope.userProfile._id);
      };

      $scope.addtoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.id;
        MyService.updateUserFollowList(data).then(
          function (payload) {
            if ($scope.UserData) {
              $scope.showUnFollow = true;
              $scope.UserData.followlist.push($routeParams.id);
              //MyService.ConsoleLog("udata : ", $scope.UserData);
              tokenService.saveUserData(JSON.stringify($scope.UserData));
            }
          },
          function (errorPayload) {
            alert("Something went wrong ");
          }
        );
      };

      $scope.removetoFollow = function () {
        var data = {};
        data.userId = $scope.loginUser;
        data.pushId = $routeParams.id;
        MyService.removeUserFollowList(data).then(
          function (result) {
            MyService.ConsoleLog("result", result);
            $scope.showUnFollow = false;
            var ids = $scope.UserData.followlist.indexOf($routeParams.id);
            MyService.ConsoleLog("ids", ids);
            if (ids > -1) {
              $scope.UserData.followlist.splice(ids, 1);
            } else {
              var idx = $scope.UserData.followlist.indexOf(
                $scope.userProfile.officeId
              );
              $scope.UserData.followlist.splice(idx, 1);
            }
            //MyService.ConsoleLog("udata : ", $scope.UserData);
            tokenService.saveUserData(JSON.stringify($scope.UserData));
          },
          function (err) {
            MyService.ConsoleLog("error", err);
          }
        );
      };

      $scope.goBack = function () {
        window.history.back();
      };

      $scope.arrangeComitteeData = function () {
        $scope.committeeData = [];
        var len1 = $scope.memberRoleCommittee.committees.length;
        var len2 = $scope.memberRoleCommittee.subcommittees.length;
        if ($scope.memberRoleCommittee) {
          for (var i = 0; i < len1; i++) {
            var itm = {
              committeeName: $scope.memberRoleCommittee.committees[i].name,
              committeeRank:
                $scope.memberRoleCommittee.committees[i].rank_in_party,
              committeeBeginDate:
                $scope.memberRoleCommittee.committees[i].begin_date,
              committeeEndDate:
                $scope.memberRoleCommittee.committees[i].end_date,
              committeeCode: $scope.memberRoleCommittee.committees[i].code,
              subcommittees: [],
            };
            $scope.committeeData.push(itm);
          }

          $timeout(function () {
            for (var j = 0; j < $scope.committeeData.length; j++) {
              for (var k = 0; k < len2; k++) {
                if (
                  $scope.committeeData[j].committeeCode ==
                  $scope.memberRoleCommittee.subcommittees[k]
                    .parent_committee_id
                ) {
                  var sub = {
                    scommitteeName:
                      $scope.memberRoleCommittee.subcommittees[k].name,
                    scommitteeRank:
                      $scope.memberRoleCommittee.subcommittees[k].rank_in_party,
                    scommitteeBeginDate:
                      $scope.memberRoleCommittee.subcommittees[k].begin_date,
                    scommitteeEndDate:
                      $scope.memberRoleCommittee.subcommittees[k].end_date,
                    scommitteeParentId:
                      $scope.memberRoleCommittee.subcommittees[k]
                        .parent_committee_id,
                    scommitteeCode:
                      $scope.memberRoleCommittee.subcommittees[k].code,
                  };

                  $scope.committeeData[j].subcommittees.push(sub);
                  //$scope.committeeData.push(itm);
                }
              }
            }
          }, 2000);
          MyService.ConsoleLog("committeeData : ", $scope.committeeData);
        }
      };
      $scope.getCommitteesYears = function () {
        MyService.ConsoleLog("getCommitteesYears");
        var len = $scope.propublicaData.roles.length;
        var yr = "";
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            if (
              $scope.propublicaData.roles[i].committees.length > 0 ||
              $scope.propublicaData.roles[i].subcommittees.length > 0
            ) {
              switch ($scope.propublicaData.roles[i].congress) {
                case "116":
                  yr = "2019 - 2021";
                  break;
                case "115":
                  yr = "2017 - 2018";
                  break;
                case "114":
                  yr = "2015 - 2016";
                  break;
                case "113":
                  yr = "2013 - 2014";
                  break;
                case "112":
                  yr = "2011 - 2012";
                  break;
                case "111":
                  yr = "2009 - 2010";
                  break;
                case "110":
                  yr = "2007 - 2008";
                  break;
                case "109":
                  yr = "2005 - 2006";
                  break;
                case "108":
                  yr = "2003 - 2004";
                  break;
                case "107":
                  yr = "2001 - 2002";
                  break;
                case "106":
                  yr = "1999 - 2000";
                  break;
                case "105":
                  yr = "1997 - 1998";
                  break;
                case "104":
                  yr = "1995 - 1996";
                  break;
                case "103":
                  yr = "1993 - 1994";
                  break;
                case "102":
                  yr = "1991 - 1992";
                  break;
                case "101":
                  yr = "1989 - 1990";
                  break;
                case "default":
                  break;
              }
              var itm = {
                year: yr,
                id: i,
                committeesid: i,
                congress: $scope.propublicaData.roles[i].congress,
              };
              $scope.committeesYears.push(itm);
            }
          }
        }
      };
      $scope.getProbublicaData = function () {
        MyService.ConsoleLog("In getProbublicaData");
        var data = {};
        data.id = $routeParams.id;
        data.type = $scope.politicianUser;
        MyService.ConsoleLog("data params: ", data);
        MyService.getProbublicaData(data).then(
          function (payload) {
            // MyService.ConsoleLog('Success : ', payload);

            if (payload.data.success) {
              $scope.propublicaData = payload.data.data;
              $scope.memberCrpId = $scope.propublicaData.crp_id;
              $scope.getIndustrySupport($scope.contYear);
              // MyService.ConsoleLog(typeof $scope.propublicaData.roles);
              // if (typeof($scope.propublicaData.roles[0]) == 'string') {
              //   $scope.propublicaData.roles = JSON.parse($scope.propublicaData.roles);
              //   $scope.memberRole = $scope.propublicaData.roles[$scope.currentRole];
              // } else {
              var len = $scope.propublicaData.roles.length;
              var yr = "";
              for (var i = 0; i < len; i++) {
                switch ($scope.propublicaData.roles[i].congress) {
                  case "116":
                    yr = "2019 - 2021";
                    break;
                  case "115":
                    yr = "2017 - 2018";
                    break;
                  case "114":
                    yr = "2015 - 2016";
                    break;
                  case "113":
                    yr = "2013 - 2014";
                    break;
                  case "112":
                    yr = "2011 - 2012";
                    break;
                  case "111":
                    yr = "2009 - 2010";
                    break;
                  case "110":
                    yr = "2007 - 2008";
                    break;
                  case "109":
                    yr = "2005 - 2006";
                    break;
                  case "108":
                    yr = "2003 - 2004";
                    break;
                  case "107":
                    yr = "2001 - 2002";
                    break;
                  case "106":
                    yr = "1999 - 2000";
                    break;
                  case "105":
                    yr = "1997 - 1998";
                    break;
                  case "104":
                    yr = "1995 - 1996";
                    break;
                  case "103":
                    yr = "1993 - 1994";
                    break;
                  case "102":
                    yr = "1991 - 1992";
                    break;
                  case "101":
                    yr = "1989 - 1990";
                    break;
                  case "default":
                    break;
                }
                var itm = {
                  year: yr,
                  id: i,
                  committeesid: i,
                  congress: $scope.propublicaData.roles[i].congress,
                };
                $scope.allroles.push(itm);
              }
              //alert("allroles : ", $scope.allroles[0].id);
              $scope.getCommitteesYears();
              $scope.memberRole =
                $scope.propublicaData.roles[$scope.currentRole];
              $scope.memberRoleCommittee =
                $scope.propublicaData.roles[$scope.currentRole];
              //}
              $timeout(function () {
                $scope.arrangeComitteeData();
              }, 1000);

              if ($scope.memberRole.at_large) {
                if ($scope.memberRole.at_large == true) {
                  $scope.atLarge = "At large";
                } else {
                  $scope.atLarge = "";
                }
              }
              $scope.allRoles = $scope.propublicaData.roles.length;
              //MyService.ConsoleLog("allRoles : ", $scope.allRoles);
              MyService.ConsoleLog(
                "$scope.propublicaData : ",
                $scope.propublicaData
              );
            } else {
              // MyService.getOpenStateData(data).then(
              //   function (response) {
              //     $scope.openData = response;
              //     MyService.ConsoleLog('Response >', $scope.openData);
              //   },
              //   function (err) {
              //     MyService.ConsoleLog('Error >', err);
              //   }
              // );
            }
          },
          function (error) {
            MyService.ConsoleLog("Something went wrong : ", error);
          }
        );
      };

      $scope.getBillCosponsored = function (id) {
        MyService.ConsoleLog("In getBillCosponsored :" + id);
        $location.path(
          "/sponsored/" + id + "/" + $scope.userId + "/" + $routeParams.type
        );
        /*
        if ($scope.showSponsoredContent == false) {
        $scope.showSponsoredContent = true;
        var data = {};
        data.id = id;
        MyService.getBillCosponsoredData(data).then(function(data) {
          MyService.ConsoleLog("getBillCosponsored success : ", data);
          $scope.sponsorData = data.data.data.results[0].bills;
        }, function(err) {
          MyService.ConsoleLog("getBillCosponsored error : ", err);
        });
      } else {
        $scope.showSponsoredContent = false;
      }
      */
      };

      $scope.getVotingRecords = function (id) {
        $location.path(
          "/votingRecords/" + id + "/" + $scope.userId + "/" + $routeParams.type
        );
        /*if ($scope.showSponsoredContent == false) {
        $scope.showSponsoredContent = true;
        var data = {};
        data.id = id;
        MyService.getVotingRecords(data).then(function(data) {
          MyService.ConsoleLog("getVotingRecords success : ", data);
          //$scope.sponsorData = data.data.data.results[0].bills;
        }, function(err) {
          MyService.ConsoleLog("getVotingRecords error : ", err);
        });
      } else {
        $scope.showSponsoredContent = false;
      }*/
      };

      /**
       * @description Show Thread listing
       */
      $scope.ShowLoader = false;
      $scope.LatestEssay = [];
      $scope.LatestPost = [];
      $scope.LatestThread = [];
      $scope.gotoPage = function (obj, data) {
        //MyService.ConsoleLog(data);
        switch (obj) {
          case "Essay":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestEssayId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("essay", payload);
                $scope.LatestEssay = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("err vosjs", errorPayload);
                alert("Something went wrong.");
              }
            );
            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "EssayComment":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestEssayCommentId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              userId: userid,
              isEssayComment: true,
            }).then(
              function (payload) {
                if (payload && payload.length > 0) {
                  var essayIds = payload.map(function (itms) {
                    if (itms && itms.essayId) {
                      return itms.essayId;
                    }
                  });

                  MyService.getAllEssaysList({
                    eIds: JSON.stringify(essayIds),
                  }).then(
                    function (APIResponse) {
                      MyService.ConsoleLog("APIREs", APIResponse);
                      if (APIResponse.success) {
                        $scope.ShowLoader = false;
                        MyService.ConsoleLog("essay commnent", payload);
                        $scope.LatestEssayComments = APIResponse.data;
                      }
                    },
                    function (err) {
                      MyService.ConsoleLog("Error", err);
                    }
                  );
                }
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("err vosjs", errorPayload);
                alert("Something went wrong.");
              }
            );

            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "Post":
            var userid = data._id;
            // var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //var subcatid = data.subcategories[0].pid;
            //var id = data.parent;
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            $scope.dialogClose = ngDialog.open({
              template: "LatestPostId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestPost({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                //MyService.ConsoleLog(payload);
                $scope.LatestPost = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("errorPayload errorPayload", errorPayload);
                alert("Something went wrong.");
              }
            );
            break;
          case "Thread":
            //var catId = data.parentcat[0];
            //var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //     var subcatid = data.subcategories[0].pid;
            //var subcatid = data.subcategories[0].pid;
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestThreadId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestThread({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                //MyService.ConsoleLog(payload);
                $scope.LatestThread = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                alert("error");
                alert("Something went wrong.");
              }
            );
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            break;
          case "topic of interest":
            MyService.ConsoleLog("data", data);
            MyService.ConsoleLog("data", typeof data);

            MyService.getMultiCategoryByIds({
              ids: data,
            }).then(
              function (APIResponse) {
                MyService.ConsoleLog("APIResponse Its", APIResponse);
                if (APIResponse.data.success) {
                  $scope.TopicsOfInterests = APIResponse.data.data;
                  $scope.dialogClose = ngDialog.open({
                    template: "TopicsOfInterest",
                    closeByNavigation: true,
                    scope: $scope,
                  });
                }
              },
              function (err) {
                MyService.ConsoleLog("Errror", err);
              }
            );
            break;
          default:
            break;
        }
      };
      $scope.gotoThread = function (data, subcatid) {
        var id = "";
        if (data.parentId) {
          id = data.parentId;
        } else if (data.parent) {
          id = data.parent;
        } else {
          if (data.id) {
            id = data.id;
          } else {
            id = data._id;
          }
        }
        // MyService.ConsoleLog(data);
        var categoryId = "";
        if (data.parentCat[0] && data.parentCat[0] != "") {
          categoryId = data.parentCat[0];
        } else {
          if (data.parentCat[1] && data.parentCat[1] != "") {
            categoryId = data.parentCat[1];
          }
        }

        // $rootScope.subcategoryId = subcatid;
        //MyService.ConsoleLog('forumthread/' + categoryId + '/' + subcatid + '/' + id);

        if (categoryId != "") {
          $location.path(
            "forumthread/" + categoryId + "/" + subcatid + "/" + id
          );
        } else {
          MyService.ConsoleLog("ELSE");
          alert("Something went wrong");
        }
      };
      $scope.gotoComments = function (eId, catId) {
        MyService.ConsoleLog("eid", eId);
        MyService.ConsoleLog("catId", catId);

        // MyService.getEssay(eId).then(function (APIResponse) {
        //   MyService.ConsoleLog("APIResponse", APIResponse);
        if (eId && catId) {
          $location.path("/essayDetails/" + catId + "/" + eId);
        }
      };

      $scope.gotoEssay = function (id, catId) {
        $location.path("essayDetails/" + catId + "/" + id);
      };

      $scope.gotoFThread = function (id, catId, subcatid) {
        $location.path("forumthread/" + catId + "/" + subcatid + "/" + id);
      };

      $scope.getVoting = function () {
        $location.path(
          "/getBillVoteRecords/" + $scope.userId + "/" + $routeParams.type
        );
      };

      $scope.getIndustrySupport = function (yr) {
        MyService.ConsoleLog("In getIndustrySupport : ");
        if ($scope.memberCrpId) {
          var data = {};
          data.crpid = $scope.memberCrpId;
          data.year = yr;
          MyService.getIndustryData(data).then(
            function (res) {
              MyService.ConsoleLog("res : ", res);
              $scope.industrySupport = res.data.data.industries;
              $scope.currentContent = "industrysupport";
              var cy = new Date().getFullYear();
              if (
                $scope.industrySupport["@attributes"].cycle == cy &&
                yr != cy
              ) {
                $scope.msg = "There are no records for " + yr + "";
                $scope.industrySupport = [];
              } else {
                $scope.msg = "";
              }
              //$scope.supportYear = $scope.industrySupport['@attributes'].cycle - 2;
            },
            function (err) {
              MyService.ConsoleLog("err : ", err);
              $scope.supportYear = new Date().getFullYear();
            }
          );
        }
      };

      $scope.personalHoldings = function () {
        MyService.ConsoleLog("In personalHoldings : " + $scope.memberCrpId);
        if ($scope.memberCrpId) {
          var data = {};
          data.crpid = $scope.memberCrpId;
          MyService.getPersonalHoldings(data).then(
            function (res) {
              MyService.ConsoleLog("personalHoldings res : ", res);
              $scope.personalHoldingData = res.data.data;

              $scope.currentContent = "personalHoldings";
            },
            function (err) {
              MyService.ConsoleLog("err : ", err);
            }
          );
        }
      };
      $scope.fundRaising = function () {
        MyService.ConsoleLog("In fundRaising : " + $scope.memberCrpId);
        if ($scope.memberCrpId) {
          var data = {};
          data.crpid = $scope.memberCrpId;
          MyService.getFundRaising(data).then(
            function (res) {
              MyService.ConsoleLog("fundRaising res : ", res);
              $scope.fundRaisingData = res.data.data.summary["@attributes"];
              $scope.currentContent = "fundRaising";
            },
            function (err) {
              MyService.ConsoleLog("err : ", err);
            }
          );
        }
      };

      $scope.topContributor = function (yr) {
        MyService.ConsoleLog("In topContributor : " + yr);
        $scope.contYear = yr;
        if ($scope.memberCrpId) {
          var data = {};
          data.crpid = $scope.memberCrpId;
          data.year = yr;
          MyService.getTopContributor(data).then(
            function (res) {
              MyService.ConsoleLog("topContributor res : ", res);
              $scope.topContributorData = res.data.data.contributors;
              var cy = new Date().getFullYear();
              if (
                $scope.topContributorData["@attributes"].cycle == cy &&
                yr != cy
              ) {
                $scope.msg = "There are no records for " + yr + "";
                $scope.topContributorData = [];
              } else {
                $scope.msg = "";
              }
              //$scope.contYear = $scope.topContributorData['@attributes'].cycle - 2;
            },
            function (err) {
              MyService.ConsoleLog("err : ", err);
              $scope.contYear = new Date().getFullYear();
            }
          );
        }
      };

      /**
       * @description Get Followers count for public profile
       */
      $scope.getFollowers = function () {
        MyService.getFollowerForPublic({
          uId: $scope.userId,
        }).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse", APIResponse);
            if (APIResponse.data.success) {
              $scope.followersCnt = APIResponse.data.data.followersCount;
            }
          },
          function (err) {
            MyService.ConsoleLog("err", err);
          }
        );
      };

      $scope.getRatingData = function (politicianId) {
        MyService.ConsoleLog("In getRatingData");
        var data = {};
        data.id = politicianId;
        MyService.ConsoleLog("data params: ", data);
        MyService.getRatingData(data).then(
          function (res) {
            MyService.ConsoleLog("getRatingData result : ", res);
            if (res.data.success) {
              $scope.ratingsData = res.data.data;
              $scope.getFollowers();
            }
          },
          function (err) {
            MyService.ConsoleLog("getRatingData error > ", err);
          }
        );
      };

      if ($routeParams.id != "") {
        $scope.getData();
        $scope.getProbublicaData();

        $timeout(function () {
          $scope.topContributor($scope.contYear);
        }, 3000);
      }

      $scope.showPreviousYearRole = function (id, type) {
        MyService.ConsoleLog("current role : " + $scope.currentRole);
        MyService.ConsoleLog("allRoles : " + $scope.allRoles);
        //if ($scope.currentRole < $scope.allRoles) {
        $scope.currentRole = $scope.currentRole + 1;
        if (type == "legislation") {
          $scope.memberRole = $scope.propublicaData.roles[id];
        } else {
          $scope.memberRoleCommittee = $scope.propublicaData.roles[id];
          $scope.arrangeComitteeData();
        }
        /*if ($scope.memberRole.at_large) {
        if ($scope.memberRole.at_large == true) {
          $scope.atLarge = 'At large';
        } else {
          $scope.atLarge = "";
        }
      }*/
        //}
      };

      $scope.matchParty = function (letter) {
        if (letter == "D") {
          return "Democrat";
        } else if (letter == "I") {
          return "Independent";
        } else if (letter == "R") {
          return "Republican";
        } else {
          return "Other";
        }
      };

      /**
       * @description FUntion is use redirect to previous page
       */
      $scope.BackToPrev = function () {
        $location.path("/otherPoliticians");
      };

      // $scope.filterItems = function () {
      //   return function (u) {
      //     if (typeof u == 'string') {
      //       var tempArr = [];
      //       console.log("u", u);
      //       tempArr.push(u);
      //       return tempArr;
      //     }
      //   }
      // };
    },
  ]);
