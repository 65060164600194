'use strict';
angular.module('myApp.rateBallotMeasures', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/rateBallotMeasures/:id', {
      templateUrl: 'app/rateBallotMeasures/rateBallotMeasures.html',
      controller: 'rateBallotMeasuresCtrl'
    });
  }])
  .controller('rateBallotMeasuresCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl) {

    /**
     * Get params using Route params
     */
    $scope.bId = $routeParams.id;
    MyService.ConsoleLog(" $scope.bId", $scope.bId);
    $scope.userId = tokenService.getUserId();
    $scope.userData = JSON.parse(tokenService.getUserData());
    // MyService.ConsoleLog("$scope.userData", $scope.userData);
    $scope.review = {
      toId: '',
      toName: '',
      fromId: $scope.userData.id,
      fromName: $scope.userData.name,
      fromType: '',
      grade: {
        number: 0,
        letter: '',
        like: 'true'
      },
      text: '',
      links: []
    };
    $scope.showLoader = false;

    /**
     * Get Ballot measures Details
     */
    $scope.selectedUser = {};
    /**
     * Get Details of ballot measures
     */
    $scope.getDetailsOfBallot = function () {
      MyService.getDetailsOfBallotMeasures({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);

        if (response) {
          $scope.selectedUser.value = response.title;
          $scope.review = {
            toId: response._id,
            toName: response.title,
            fromId: $scope.userData.id,
            fromName: $scope.userData.name,
            fromType: $scope.userType,
            grade: {
              number: 0,
              letter: '',
              like: 'true'
            },
            text: '',
            links: []
          };
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Save Raviews
     */
    $scope.submitReview = function () {

      var params = {
        toId: $scope.review.toId,
        toName: $scope.review.toName,
        fromId: $scope.review.fromId,
        fromName: $scope.review.fromName,
        fromType: $scope.userType,
        number: $scope.review.grade.number,
        letter: $scope.review.grade.letter,
        like: $scope.review.grade.like,
        review: $scope.review.text,
        links: $scope.review.links
      };

      MyService.ConsoleLog("params : ", params);
      if (params.number && params.number != '') {
        MyService.rateBallotMeasures(params).then(function (response) {

          if (response.data.success == true) {

            $scope.review = {
              to: '',
              from: $scope.userId,
              referal: '',
              grade: {
                number: 0,
                letter: '',
                like: 'true'
              },
              text: '',
              links: ''
            }
            MyService.ConsoleLog("success : ", response.data.data);
            $location.path('/ballotRatings/' + params.toId);
          }
        }, function (err) {
          // MyService.ConsoleLog('err: ', err);
        });
      } else {
        growl.error("Please fill the ratings");
      }
    };

    /**
     * Get current user details
     */
    $scope.getUserDetails = function () {
      MyService.getUserPublicProfile({
        id: $scope.userId,
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          MyService.ConsoleLog("welcome to profile", $scope.userType);

          $scope.getDetailsOfBallot();
        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    };
    $scope.getUserDetails();

  }]);
