'use strict';
angular
  .module('myApp.popularQuizMatch', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/popularQuizMatch/:flag?', {
        templateUrl: 'app/popularQuizMatch/popularQuizMatch.html',
        controller: 'popularQuizMatchCtrl',
      });
    },
  ])
  .controller('popularQuizMatchCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    '$q',
    '$timeout',
    'tokenService',
    'MyService',
    '$anchorScroll',
    '$cookies',
    'ngDialog',
    'growl',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      $anchorScroll,
      $cookies,
      ngDialog,
      growl
    ) {
      $scope.page = 1;
      $scope.maxRecordsPerPg = 10000;
      $scope.showPoliAnswerDetails = false;
      $scope.showUserAnswerDetails = false;
      $scope.noDataFound = false;
      $scope.showcomparison = false;
      $scope.showComment = false;
      $scope.unComment = false;
      $scope.showCurr = false;
      $scope.showAdv = false;
      $scope.showVoter = false;
      $scope.showPolitician = false;
      $scope.showLoader = false;

      if (tokenService.getUserId()) {
        $scope.userId = tokenService.getUserId();
        console.log('$scope.userId: ', $scope.userId);
      }

      var param = {};
      param.page = $scope.page;
      param.max_records = $scope.maxRecordsPerPg;

      MyService.ConsoleLog("window.localStorage.getItem('fiveMatch');", window.localStorage.getItem('fiveMatch'));

      if (window.localStorage.getItem('fiveMatch')) {

        $rootScope.queData = window.localStorage.getItem('fiveMatch');
        MyService.ConsoleLog("main Data>>", JSON.parse($rootScope.queData));
        var tempData = JSON.parse($rootScope.queData);
        var queDatas = tempData.filter(function (itms) {
          if (itms && !itms.questionId) {
            return itms;
          }
        });
        $rootScope.queData = "";
        $rootScope.queData = JSON.stringify(queDatas);
        MyService.ConsoleLog("queDatas", queDatas);
      }

      /**
       * Remove values from localStorage on page load
       */
      window.localStorage.removeItem('comOfPolitician');
      window.localStorage.removeItem('comOfVoter');
      window.localStorage.removeItem('comOfAdv');
      window.localStorage.removeItem('comOfGuest');
      window.localStorage.removeItem('poliAr');
      window.localStorage.removeItem('voter');
      window.localStorage.removeItem('adv');
      window.localStorage.removeItem('unVerify');
      window.localStorage.removeItem('cuUser');
      $cookies.remove('orgName');

      if (tokenService.getUserId()) {
        // alert('sssss');
        $scope.userData = JSON.parse(tokenService.getUserData());
        MyService.ConsoleLog("$scope.userData >", $scope.userData);
        MyService.ConsoleLog("$scope.userData >", $scope.userData.address);


        // $rootScope.tempUserAddress = "";
        $rootScope.tempUserAddress = $scope.userData.address;
        console.log('$rootScope.tempUserAddress >>> ', $rootScope.tempUserAddress);
        // }

        // var nAddobj = new Object;

        // nAddobj.state = $scope.mainAddress.state.state;
        // nAddobj.stateFips = $scope.mainAddress.state.stateFips;
        // nAddobj.statePostalCode = $scope.mainAddress.state.statePostalCode;

        // nAddobj.county = $scope.mainAddress.county ? $scope.mainAddress.county.county : "";
        // nAddobj.countyFips = $scope.mainAddress.county ? $scope.mainAddress.county.countyFips : "";
        // if ($scope.mainAddress.place && $scope.mainAddress.place != "") {
        //   nAddobj.place = $scope.mainAddress.place.place;
        //   nAddobj.placeFips = $scope.mainAddress.place.placeFips;
        // }

        // nAddobj.federalCongrassionalDist = $scope.mainAddress.federalCongrassionalDist ? $scope.mainAddress.federalCongrassionalDist.CD115FP : "";

        // nAddobj.stateSenateDistrict = $scope.mainAddress.stateSenateDistrict ? $scope.mainAddress.stateSenateDistrict.SLDUST : "";

        // nAddobj.legislativeDistrict = $scope.mainAddress.stateHouseOFRepresentativeDistrict ? $scope.mainAddress.stateHouseOFRepresentativeDistrict.NAMELSAD.replace("State House District ", "") : "";

        // nAddobj.schoolDistrict = $scope.mainAddress.unifiedSchoolDistrictName ? $scope.mainAddress.unifiedSchoolDistrictName.NAME : '';

        // nAddobj.schoolDistrictFips = $scope.mainAddress.unifiedSchoolDistrictName ? $scope.mainAddress.unifiedSchoolDistrictName.UNSDLEA : '';
        // console.log("nAddobj > ", nAddobj);
        // $rootScope.tempUserAddress = nAddobj;
      }


      $scope.changeColor = function (nper, inx, cur_ans) {

        // MyService.ConsoleLog("inx", inx);
        // MyService.ConsoleLog("cur_ans", cur_ans);

        var arrOfCol = {
          "5": "#990000",
          "4": "#cc3300",
          "3": "#ff9933",
          "2": "#ffff00",
          "1": "#99cc00",
          "0": "#00d900"
        };
        var cdiff = Math.abs(inx - cur_ans);

        // console.log("arrOfCol > ", arrOfCol);
        $scope.gr = null;
        switch (true) {
          case (nper >= 100):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }

            // MyService.ConsoleLog("$scope.gr >>", arrOfCol.nper);
            break;
          case (nper >= 80):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 60):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 40):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 20):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 0):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;

          default:
            break;
        }
        // MyService.ConsoleLog(" $scope.gr", $scope.gr);
        return $scope.gr;

      };


      /** Return class of background color */

      $scope.getComapreClass = function (match, i) {

        if (match >= 100) return 'ag-dg1';
        if (match >= 80) return 'ag-dg2';
        if (match >= 60) return 'ag-dg3';
        if (match >= 40) return 'ag-dg4';
        if (match >= 20) return 'ag-dg5';
        if (match >= 0) return 'ag-dg6';

        return 'ag-dg';
      };



      /**
       * This function calls on page load
       * fetch data of user's area politician ,voters and advocates
       */

      $scope.getLocalPoliticians = function (id) {
        // alert('inside');
        $scope.showLoader = true;
        // MyService.ConsoleLog(" welcome to getLocalPoliticians ", $rootScope.queData);
        $scope.statusGetLocalPoliticians = 1;
        $scope.page += 1;
        $scope.loader = true;

        var extradata = new Object();
        if ($rootScope.tempUserAddress) {
          if ($rootScope.tempUserAddress.state && $rootScope.tempUserAddress.state != '') {
            extradata.statePostalCode = $rootScope.tempUserAddress.statePostalCode;
          }
          if ($rootScope.tempUserAddress.county && $rootScope.tempUserAddress.countyFips) {
            $rootScope.tempUserAddress.FinalData = JSON.stringify(
              $rootScope.tempUserAddress.countyFips
            );
            extradata.county = $rootScope.tempUserAddress.county;
          }
          if (
            $rootScope.tempUserAddress.countySubdivision &&
            $rootScope.tempUserAddress.countySubdivisionFips
          ) {
            $rootScope.tempUserAddress.FinalData = JSON.stringify(
              $rootScope.tempUserAddress.countySubdivisionFips
            );
            extradata.countySubdivision = $rootScope.tempUserAddress.countySubdivision;
          }
          if ($rootScope.tempUserAddress.place && $rootScope.tempUserAddress.placeFips) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.place = $rootScope.tempUserAddress.place;
          }
          if (
            $rootScope.tempUserAddress.voterDistrict &&
            $rootScope.tempUserAddress.voterDistrictFips
          ) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
          }
          if ($rootScope.tempUserAddress.federalCongrassionalDist) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            MyService.ConsoleLog(
              'federalCongrassionalDist  :',
              $rootScope.tempUserAddress.federalCongrassionalDist
            );
            extradata.federalCongrassionalDist =
              $rootScope.tempUserAddress.federalCongrassionalDist;
          }
          if ($rootScope.tempUserAddress.stateSenateDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.stateSenateDistrict = $rootScope.tempUserAddress.stateSenateDistrict;
          }
          if ($rootScope.tempUserAddress.legislativeDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.legislativeDistrict = $rootScope.tempUserAddress.legislativeDistrict;
          }
          if ($rootScope.tempUserAddress.schoolDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.schoolDistrict = $rootScope.tempUserAddress.schoolDistrict;
          }
        }
        MyService.ConsoleLog('extradata : >>>>>>>>>> ', JSON.stringify(extradata));
        MyService.getAllLocalPoli({
          max_records: 100,
          page: $scope.page,
          extradata: JSON.stringify(extradata),
        }).then(
          function (response) {
            if (response.data.success) {
              setTimeout(function () {
                $scope.$apply(function () {
                  if (response.data.data.length > 0) {
                    MyService.getAdvocates().then(function (payload) {
                      MyService.ConsoleLog("Response >", payload);
                      var ndata = response.data.data;
                      var wdata = ndata.concat(payload.data.data);
                      $scope.userAreaPolitician = wdata.map(function (ques) {
                        return ques._id;
                      });
                      // MyService.ConsoleLog(" $scope.userAreaPolitician >", $scope.userAreaPolitician);
                      var param = JSON.stringify({
                        usersIds: $scope.userAreaPolitician,
                        cur_userId: $scope.userId,
                        questions: $rootScope.queData
                      });

                      // MyService.ConsoleLog("apra >", param);
                      // window.localStorage.removeItem('comData');
                      window.localStorage.setItem('comData', param);
                      var indx;
                      if (id && id != "") {
                        indx = id;
                        MyService.ConsoleLog("Inds >>>>>>>>", indx);
                      } else {
                        $scope.currentIndex = 0;
                        indx = $scope.currentIndex;
                        MyService.ConsoleLog(" $scope.currentIndex >", indx);
                      }
                      // alert('called');
                      MyService.ConsoleLog("$rootScope.queData", $rootScope.queData);
                      var main = JSON.parse($rootScope.queData);
                      MyService.ConsoleLog(' $scope.quData :', main[indx]);
                      // MyService.ConsoleLog("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>", typeof $scope.userAreaPolitician)
                      // MyService.ConsoleLog("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>", $scope.userAreaPolitician)
                      $scope.userAreaPolitician = [];
                      $scope.userAreaPolitician.push($cookies.get('advId'));
                      MyService.ConsoleLog("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>", $scope.userAreaPolitician)
                      // return;
                      $scope.compareAll({
                        userIds: JSON.stringify($scope.userAreaPolitician),
                        quiz: JSON.stringify(main[indx]),
                        userData: $scope.userId
                      });
                    }, function (err) {
                      MyService.ConsoleLog("Error >>", err);
                    });
                  } else {
                    $scope.loader = false;
                    var message =
                      'None of your politicians have answered the questions yet. Contact them and ask them to fill it out.';
                    var modal = ngDialog.openConfirm({
                      template: '<p>' +
                        message +
                        '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                      plain: true,
                    });

                    modal.then(
                      function fullfilled(data) {
                        //MyService.ConsoleLog("fullfilled: ", data);
                        if (data == 1) {
                          // $scope.postonFacebook();
                        } else {
                          if ($rootScope.userRole) {
                            $location.path('/snapshot');
                          } else {
                            $location.path('/signin');
                          }
                        }
                      },
                      function rejected(data) {
                        //MyService.ConsoleLog("rejected: ", data);
                      }
                    );
                  }
                });
              }, 100);
            } else {
              $scope.page -= 1;
              $scope.statusGetLocalPoliticians = 3;
              if (typeof response.data.error == 'string') growl.error(response.data.error);
              else growl.error('Something went wrong');
            }
          },
          function (err) {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            $scope.loader = false;
            growl.error(err);
          }
        );
      };

      /**End of getLocalPoliticians */

      if (tokenService.getUserId()) {
        $scope.getLocalPoliticians();
      }



      /**
       * This function redirects to show answers page
       */

      $scope.showAnswers = function (ans, type, qId, chAns, ind) {
        MyService.ConsoleLog("Ind", ind);
        MyService.ConsoleLog("qId", qId);

        if (!ind) {
          ind = 0;
        }
        if (chAns) {
          if (!chAns[ind + 1]) {
            // alert("This");
            growl.error("No users to see here");
          } else {
            // if (chAns != "") {
            //   alert('dahdaj');
            // }
            MyService.ConsoleLog("Type >>", type);
            MyService.ConsoleLog("qId >>", qId);
            MyService.ConsoleLog("chAns >>", chAns);
            $cookies.put('orgName', $scope.orgName);
            $location.path('/showAnswers/' + type + '/' + ans + '/' + qId + '/' + chAns);
          }
        } else {
          // alert('not Found');
          switch (type) {
            case 'politician':
              growl.error('None of politicians of your area answered for this question');
              break;
            case 'voter':
              growl.error('None of voter of your area answered for this question');
              break;
            case 'advocate':
              growl.error('None of organizations of your area answered for this question');
              break;

            default:

              break;
          }
        }
      };

      /**
       * This function show the details of answered questions
       * showAnswersDetail
       */

      $scope.showAnswersDetail = function (para) {
        MyService.ConsoleLog('para >', para);

        switch (para) {
          case 'politician':
            var newObj = JSON.stringify({
              poli: $scope.arrayOfCom,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('poliAr', newObj);
            $location.path('/showGroupAnswer/' + '' + '/' + '' + '/' + true);
            // $scope.showPoliAnswerDetails = true;
            break;

          case 'voter':
            var vObj = JSON.stringify({
              vot: $scope.voterComArr,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('voter', vObj);
            $location.path('/showGroupAnswer/' + '' + '/' + '' + '/' + true);
            // $location.path('/showGroupAnswer');
            // $scope.showPoliAnswerDetails = true;
            break;
          case 'advocate':
            var advo = JSON.stringify({
              adv: $scope.advComArr,
              qId: $scope.questions[$scope.currentIndex]._id
            });
            window.localStorage.setItem('adv', advo);
            $cookies.put('orgName', $scope.orgName);
            // $location.path('/showGroupAnswer');
            $location.path('/showGroupAnswer/' + '' + '/' + '' + '/' + true);

            // $scope.showPoliAnswerDetails = true;
            break;
          default:

            break;
        }

        // if (para == 'politician') {
        //   $scope.showPoliAnswerDetails = true;
        // } else {
        //   $scope.showUserAnswerDetails = true;
        // }
      };

      /**End Of showAnswersDetail */


      /** This function fetch comparison results using
       ** @ Params @
       ** guest user data and politician data
       **/

      $scope.compareAll = function (params) {

        MyService.getPopularMatch(params).then(
          function (payload) {
            MyService.ConsoleLog('Response >>>', payload);

            if (payload.data.success) {
              // if (Object.keys($scope.advocate).length > 0 || Object.keys($scope.voter).length > 0 || Object.keys($scope.emailRegistered).length > 0) {
              $scope.questions = JSON.parse($rootScope.queData);

              if ((JSON.stringify(payload.data.data.agrriOfEmail) != JSON.stringify({})) || (JSON.stringify(payload.data.data.aggriOfAdvocate) != JSON.stringify({}))) {
                MyService.ConsoleLog(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
                $scope.emailRegistered = payload.data.data.agrriOfEmail;
                MyService.ConsoleLog("$scope.emailRegistered >", $scope.emailRegistered);


                $scope.emailImpo = payload.data.data.aggriOfEmailImportance;


                // MyService.ConsoleLog("JSON.parse($rootScope.queData)", JSON.parse($rootScope.queData));
                // MyService.ConsoleLog('$scope.questions TWo >>>', $scope.questions);
                $scope.FBCompareList = payload.data.data.qdata;
                $scope.ansCount = payload.data.data.ansCount;

                $scope.impCount = payload.data.data.impCount;


                // MyService.ConsoleLog('ansCount >', $scope.impCount);
                $scope.globalFlag = payload.data.data.checkFlag;
                $scope.arrayOfCom = payload.data.data.commArr;

                $scope.voter = payload.data.data.aggriOfVoter;
                $scope.voterImp = payload.data.data.aggriOfVoterImportance;
                $scope.voterAnsCount = payload.data.data.voterAnsCount;
                $scope.voterImpCount = payload.data.data.voterImpCount;
                $scope.flagOfVoter = payload.data.data.voterFlag;
                $scope.voterComArr = payload.data.data.voterComArr;
                $scope.advocate = payload.data.data.aggriOfAdvocate;
                $scope.advocateAns = payload.data.data.advocateObj;
                $scope.advocateImpAggri = payload.data.data.aggriOfAdvocateImportance;
                $scope.advocateimpObj = payload.data.data.advocateimpObj;
                $scope.advocateFlag = payload.data.data.advFlag;
                $scope.advComArr = payload.data.data.advcommArr;
                $scope.orgName = payload.data.data.orgName;


                if (Object.keys($scope.advocate).length > 0) {
                  $scope.showAdv = true;
                }
                if (Object.keys($scope.voter).length > 0) {
                  $scope.showVoter = true;
                }
                if (Object.keys($scope.emailRegistered).length > 0) {
                  $scope.showPolitician = true;
                }

                if (JSON.stringify(payload.data.data.aggriOfAdvocate) == JSON.stringify({})) {
                  // alert('is');

                  growl.error("The organization has not answered the questions");
                }
                $scope.showLoader = false;
              } else {
                $scope.showLoader = false;
                // growl.error("The organization has not answered the questions");
                // alert('sho');?
                var message = 'The organization has not answered this question';
                var modal = ngDialog.openConfirm({
                  template: '<p>' +
                    message +
                    '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">OK</button>\
                  </div>',
                  plain: true,
                  closeByDocument: false,
                });
                modal.then(
                  function fullfilled(data) {

                  },
                  function rejected(data) {
                    // MyService.ConsoleLog("rejected: ", data);
                    // $scope.getMatchQUestions();
                  }
                );
                MyService.ConsoleLog(">>>>>>>>>>>>>>>>>>>>>>>> elseeeeeeeeee >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
              }
            }
          },
          function (error) {
            $scope.showLoader = false;
            MyService.ConsoleLog('Error >>', error);
          }
        );
      };

      /**This function redirects to show comment page */
      $scope.viewAnsComment = function (val) {
        MyService.ConsoleLog('called', val);

        switch (val) {
          case 'email':
            $scope.global = $scope.arrayOfCom;
            if ($scope.userId) {
              var poli = JSON.stringify({
                poliArr: $scope.arrayOfCom,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfPolitician', poli);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'unRegister':
            if ($scope.userId) {
              var guest = JSON.stringify({
                guestArr: $scope.unVeriComm,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfGuest', guest);
              $location.path('/showComments');
            }
            $scope.unComment = true;
            $scope.showComment = false;
            $scope.showCurr = false;

            break;
          case 'voter':
            $scope.global = $scope.voterComArr;
            // MyService.ConsoleLog("$scope.voterComArr", $scope.voterComArr);
            if ($scope.userId) {
              var vo = JSON.stringify({
                compArr: $scope.voterComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              // MyService.ConsoleLog("vo >>", vo);
              window.localStorage.setItem('comOfVoter', vo);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'advocate':
            $scope.global = $scope.advComArr;
            if ($scope.userId) {
              var adv = JSON.stringify({
                advArr: $scope.advComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              $cookies.put('orgName', $scope.orgName);
              window.localStorage.setItem('comOfAdv', adv);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'currentUser':
            if ($scope.userId) {
              var curUser = JSON.stringify({
                curuser: $scope.questions[$scope.currentIndex]
              });
              window.localStorage.setItem('cuUser', curUser);
              $location.path('/showComments');
            }
            $scope.showComment = false;
            $scope.showCurr = true;
            $scope.unComment = false;
            break;
          default:

            break;
        }
      };
      $scope.back = function () {
        $scope.showPoliAnswerDetails = false;
        $scope.showUserAnswerDetails = false;
        $scope.showComment = false;
        $scope.unComment = false;
        $scope.showCurr = false;

      };

      $scope.status = {
        questions: 0, //1=loading, 2=loaded, 3=error
        category: 0,
      };

      var getCompareAnswersCanceler = $q.defer();


      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        $timeout(function () {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 1500);
        if ($scope.currentIndex > 0) {
          var decr = $scope.currentIndex--;
          var one = decr - 1;
          MyService.ConsoleLog("decr", one);
          if ($scope.userId) {
            $scope.getLocalPoliticians(one);
          }
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            MyService.ConsoleLog('$scope.traverseBackgrounds >', $scope.traverseBackgrounds);
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            //MyService.ConsoleLog("** end of background **");
            if ($scope.rootCatIndex > 0) {
              $scope.rootCatIndex--;
              $location.path(
                '/question-compare/all/' + $rootScope.categories[$scope.rootCatIndex]._id
              );
            }
          }
        }
      };

      $scope.skipQuestion = function () {
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          MyService.ConsoleLog(' $scope.currentIndex >>', tempIndex);
          if ($scope.userId) {
            $scope.getLocalPoliticians(tempIndex);

          }

          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          MyService.ConsoleLog(
            'tempIndex = ',
            tempIndex + '$scope.questions.length ' + $scope.questions.length
          );
          if (tempIndex == $scope.questions.length || $scope.questions.length == 0) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            MyService.ConsoleLog('$scope.traverseBackgrounds >>', $scope.traverseBackgrounds);
            if ($scope.traverseBackgrounds && $scope.traverseBackgrounds != '') {
              if (tempBackIdx < $scope.traverseBackgrounds.length) {
                $scope.currBackgroundIndex++;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
              } else {
                $scope.rootCatIndex++;
                // alert("Else");
                window.localStorage.setItem('RootCatIndex', $scope.rootCatIndex);
                window.localStorage.setItem('DisplayCategory', JSON.stringify($scope.category));
                //MyService.ConsoleLog("/politician_compare/" + $routeParams.categoryId + "/" + $routeParams.parentId);
                $location.path(
                  '/politician_compare/' + $routeParams.categoryId + '/' + $routeParams.parentId
                );
              }
            } else {
              if ($scope.userId) {
                $location.path('/getPopularComparison');

              }
            }
          }
        };

        skip();
      };
    },
  ]);
