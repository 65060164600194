'use strict';
angular.module('myApp.fiveminsignin', ['ngRoute'])
  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/fiveminsignin', {
      templateUrl: 'app/fiveminsignin/signin.html',
      controller: 'fiveminSigninCtrl'
    });
  }])

  .controller('fiveminSigninCtrl', ['$scope', '$http', '$location', 'growl', 'tokenService', 'baseApiUrl', '$rootScope', 'MyService',
    function($scope, $http, $location, growl, tokenService, baseApiUrl, $rootScope, MyService) {
      $scope.UserName = "";
      $scope.Password = "";
      $rootScope.welcome = false;
      $scope.saveFiveMinAnswers = function() {
        MyService.ConsoleLog("saveFiveMinAnswers : ");
        MyService.ConsoleLog($rootScope.fiveMatch);
        var len = $rootScope.fiveMatch.length;
        if(len > 0){
        for (var i = 0; i < len; i++) {
          MyService.ConsoleLog($rootScope.fiveMatch[i]);
          MyService.postAnswer($rootScope.fiveMatch[i]).then(function(response) {
            MyService.ConsoleLog(response);
            if (response.data.success) {
                MyService.ConsoleLog("Answer updated successfully");
              
            } else {
              MyService.ConsoleLog("Error: " + response.data.error);
            }
          }, function(err) {
            MyService.ConsoleLog("Error: ");
          });
        }
      }
      }
      $scope.initAuthorizedUser1 = function(userdata) {
        // body...
        MyService.ConsoleLog("userdata>>", userdata);
        tokenService.saveToken(userdata.token);
        tokenService.saveUserName(userdata.name);
        tokenService.saveUserId(userdata.id);
        tokenService.saveUserRole(userdata);
        var udata = {
          id: userdata.id,
          lastLogin: userdata.lastLogin,
          uname: userdata.username,
          email: userdata.email,
          followlist: userdata.followlist,
          isemailverified: userdata.isemailverified,
          subject_of_deep_interest: userdata.subject_of_deep_interest,
          subject_of_interest: userdata.subject_of_interest,
          location_of_interest: userdata.location_of_interest,
          address: userdata.address,
          selectedColumns: userdata.selectedColumns,
          facebookid: userdata.facebookid,
          volunteers: userdata.volunteers,
          name: userdata.name
        };
        $rootScope.tempUserAddress = userdata.address;
        //MyService.ConsoleLog("udata : ", udata);
        tokenService.saveUserData(JSON.stringify(udata));

        $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
        // $http.defaults.headers.common['Authorization'] = tokenService.getToken();
        $rootScope.Auth = true;
        $rootScope.User = tokenService.getUserName();
        $rootScope.UserId = tokenService.getUserId();
        //$location.path('howToVideo');
        if ($rootScope.endFiveMin == true) {
          $location.path('/snapshot');
        } else {
          $location.path('/fiveCompare');
        }
        $scope.saveFiveMinAnswers();
        $rootScope.getCategories();
      };
      $rootScope.statusAuthenticate = 0;
      $scope.AuthenticateUser1 = function(postObj) {
        $rootScope.statusAuthenticate = 1;
        $http.post(baseApiUrl + '/user/authenticate', postObj)
          .then(function(response) {
            if (response.data.success == true) {
              //MyService.ConsoleLog("Login Data");
              //MyService.ConsoleLog(response.data);
              $scope.initAuthorizedUser1(response.data);
              /*
              tokenService.saveToken(response.data.token);
              tokenService.saveUserName(response.data.name);
              tokenService.saveUserId(response.data.id);
              tokenService.saveUserRole(response.data);
              var userData = {
                  id: response.data.id,
                  lastLogin: response.data.lastLogin,
                  uname: response.data.username,
                  name: response.data.name
              };
              //MyService.ConsoleLog("username: ", userData);
              tokenService.saveUserData(JSON.stringify(userData));
              $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
              $rootScope.Auth = true;
              $rootScope.User = tokenService.getUserName();
              $rootScope.UserId = tokenService.getUserId();
              $location.path('debateHome');
              $rootScope.getCategories();
              */
              $rootScope.statusAuthenticate = 2;
            } else {
              $rootScope.statusAuthenticate = 3;
              growl.error('Invalid Username or Password. Please try again.');
            }
          }, function(response) {
            $rootScope.statusAuthenticate = 3;
            var validataionMsg = "";
            //MyService.ConsoleLog("response: ", response);
            if ((response.data) && (response.data.error)) {
              var errors = response.data.error.errors;
              for (var k in errors) {
                if (errors.hasOwnProperty(k)) {
                  //MyService.ConsoleLog(k)
                  var obj = errors[k];
                  if (obj.message !== undefined)
                    validataionMsg += obj.message.replace('Path', '') + "<br/>";
                }
              }
              growl.error(validataionMsg);
            }

          });
      };
      $scope.AuthenticateUser = function() {
        if ($.fn.validateForceFully($("#signInform")) == true) {
          /*$http.post(baseApiUrl + '/user/authenticate', {
              username: $scope.UserName,
              password: $scope.Password
          })
          .then(function (response) {
              if (response.data.success == true) {
                  //MyService.ConsoleLog("Login Data");
                  //MyService.ConsoleLog(response.data);
                  tokenService.saveToken(response.data.token);
                  tokenService.saveUserName(response.data.name);
                  tokenService.saveUserId(response.data.id);
                  tokenService.saveUserRole(response.data);
                   var userData = {
                      id: response.data.id,
                      lastLogin: response.data.lastLogin,
                      uname: $scope.UserName,
                      name: response.name
                   };
                  tokenService.saveUserData(JSON.stringify(userData));
                 
                  
                  $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
                  $rootScope.Auth = true;
                  $rootScope.User = tokenService.getUserName();
                  $rootScope.UserId = tokenService.getUserId();
                  $location.path('debateHome');
                  $rootScope.getCategories();
              }
              else {
                  growl.error('Invalid Username or Password. Please try again.');
              }
          }, function (response) {
              var validataionMsg = "";
              var errors = response.data.error.errors;
              for (var k in errors) {
                  if (errors.hasOwnProperty(k)) {
                      //MyService.ConsoleLog(k)
                      var obj = errors[k];
                      if (obj.message !== undefined)
                          validataionMsg += obj.message.replace('Path', '') + "<br/>";
                  }
              }
              growl.error(validataionMsg);
          });*/

          var postobj = {
            username: $scope.UserName,
            password: $scope.Password
          };
          $scope.AuthenticateUser1(postobj);
        }
      };

      $scope.requestPasswordReset = function() {
        if ($.fn.validateForceFully($("#forgotPassForm")) == true) {
          var postobj = {
            email: $scope.forgotEmail,
            reset_url: $rootScope.getPasswordResetUrl()
          };
          //MyService.ConsoleLog("postobj: ",postobj);

          MyService.forgotPassword(postobj)
            .then(function(response) {
              if (response.data.success) {
                growl.success("An email has been sent.");
              } else {
                if (typeof response.data.error == "string")
                  growl.error(response.data.error);
                else
                  growl.error("Something went wrong");
              }
            }, function(err) {
              growl.error("Something went wrong");
            });
        }
      };

      $("form").validationEngine();
    }
  ]);
