'use strict';

angular.module('myApp.pressComment', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/pressComment/:pressId/:pressName?', {
        templateUrl: 'app/pressComment/pressComment.html',
        controller: 'pressCommentCtrl'
    });
}])

.controller('pressCommentCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', 'baseApiUrl', '$q', '$timeout', 'growl', 'tokenService', '$anchorScroll', '$sce', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, baseApiUrl, $q, $timeout, growl, tokenService, $anchorScroll, $sce, MyService) {

    $rootScope.welcome = false;
    $scope.pressId = $routeParams.pressId;
    $scope.pressName = $routeParams.pressName;
    $scope.userType = tokenService.getUserRole();
    $scope.uData = JSON.parse(tokenService.getUserData());
    $scope.essayComments = [];
    $scope.press = {};
    $scope.Sticky = {
        ShowOrder: [],
        txtOrder: []
    };
    $scope.vote = {
        recommend: 0,
        factual: 0
    };
    //$scope.GalleryFolder = baseApiUrl + "/resources/" + $scope.uData.uname;

    $scope.displayComments = true;
    $scope.Reply = {};

    $scope.checkMemId = function(rdata, val) {
        return rdata.filter(
            function(rdata) {
                if (rdata.userData._id == $rootScope.UserId)
                    return rdata
            }
        );
    }


    $scope.CheckUrl = function(url, option) {
        if (url) {
            if (url.indexOf("youtube.com") >= 0) {
                var url = url.replace("watch?v=", "embed/");
                if (option == "V")
                    return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
                else
                    return $sce.trustAsHtml('<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
            }
            if (url.indexOf("facebook.com") >= 0) {
                if (option == "V")
                    return $sce.trustAsHtml('<br/><iframe src="' + url + '" width="560" height="315" frameborder="0"></iframe><br/>');
                else
                    return $sce.trustAsHtml('<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
            }
            if (url.indexOf("vimeo.com") >= 0) {
                if (url.indexOf("https://vimeo.com/") >= 0) {
                    var id = url.replace("https://vimeo.com/", "");
                    var newurl = "https://player.vimeo.com/video/" + id;
                } else {
                    var newurl = url;
                }
                if (option == "V")
                    return $sce.trustAsHtml('<br/><iframe src="' + newurl + '" width="500" height="281" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>');
                else
                    return $sce.trustAsHtml('<br/><iframe src="' + newurl + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>');
            }
            if (option == "V")
                return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
            else
                return $sce.trustAsHtml('<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
        }

    }



    $scope.updateCommentApi = function(data, options, idex) {
        MyService.updatePressComments(data).then(function(payload) {
            if (payload.nModified == 0) {
                alert("You already provided your inputs on this post");
            } else {
                switch (options) {
                    case 'like':
                        $scope.essayComments[idex].totalLikes = $scope.essayComments[idex].totalLikes + 1;
                        break;
                    case 'dislike':
                        $scope.essayComments[idex].totalDislike = $scope.essayComments[idex].totalDislike + 1;
                        break;
                    default:
                        $scope.essayComments[idex].totalSpam = $scope.essayComments[idex].totalSpam + 1;
                        break;
                }
            }
        }, function(errorPayload) {
            alert('Something went wrong ');
        });
    }



    $scope.updateComments = function(options, id, idex) {
        if ($rootScope.UserId) {
            if ($scope.essayComments[idex].createdBy.id != $rootScope.UserId) {
                var commentData = {};
                commentData.commentId = id;
                commentData.userId = $rootScope.UserId;

                switch (options) {
                    case 'like':
                        commentData.likes = 1;
                        break;
                    case 'dislike':
                        commentData.dislikes = 1;
                        break;
                    default:
                        commentData.spam = 1;
                        break;
                }
                
                $scope.updateCommentApi(commentData, options, idex);

            } else {
                alert("You cannot " + options + " your own comments");
            }
        } else {
            alert("Please login to perform these actions");
        }
    }
    $scope.submitSticky = function(data, id, idx) {
        if (data > 0) {
            updateForum('sticky', id, idx);
        } else {
            alert("Please enter valid order");
        }
    }

    $scope.ShowComments = false;
    
    $scope.redirectToComment = function() {
        if (!$rootScope.UserId) {
            $location.path('signin');
        }
        $scope.ShowComments = true;
        $scope.displayComments = false;
        //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
    }

    $scope.goBack = function() {
        $scope.ShowComments = false;
        $scope.displayComments = true;
    }

    $scope.getComments = function() {
        MyService.getPressComments($routeParams.pressId).then(function(payload) {
            //MyService.ConsoleLog(payload);
                if(payload) {
                    $scope.essayComments = payload;    
                }
                
            },
            function(errorPayload) {
                alert('Something went wrong ');
            });
    }

    $scope.SubmitComment = function(rec) {
        //MyService.ConsoleLog(data);
        //MyService.ConsoleLog($scope.mainThread);
        var data = {};
        if (rec.content != "") {
            $scope.TopicButton = true;
            data.createdById = $rootScope.UserId;
            data.createdByName = $rootScope.User;
            data.createdByType = tokenService.getUserRole();
            data.comments = rec.content;
            data.pressId = $routeParams.pressId;
            //MyService.ConsoleLog(data);
            MyService.submitPressComments(data).then(function(payload) {
                $scope.comments = {
                    content: ''
                };
                $scope.ShowComments = false;
                $scope.displayComments = true;
                $scope.mainThread = {};
                //$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
                $scope.getComments();
            }, function(errorPayload) {
                alert('Something went wrong ');
            });
        } else {
            alert("Please enter comments.");
        }
    }

    



    if ($routeParams.pressId) {
        $scope.getComments();
    }
}]);
