'use strict';

angular.module('myApp.donate', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/donate', {
         templateUrl: 'app/donate/donate.html',
         controller: 'donateCtrl' 
     });
}])
.controller('donateCtrl', ['$scope', '$rootScope', '$window', function($scope, $rootScope, $window) {
$rootScope.welcome = false;
$window.scrollTo(0, 0);
}]);
