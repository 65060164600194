'use strict';
angular.module('myApp.compareRatings', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compareRatings', {
      templateUrl: 'app/compareRatings/compareRatings.html',
      controller: 'compareRatingsCtrl'
    });
  }])
  .controller('compareRatingsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

    $scope.politicians = [];
    $scope.rating1 = [];
    $scope.rating2 = [];
    $scope.rating3 = [];

    $scope.reviewId1 = [];
    $scope.reviewId2 = [];
    $scope.reviewId3 = [];
    /*
    $scope.review = {
      "data1": "",
      "data2": "",
      "data3": ""
    };
    */


    if ($rootScope.selectedRatingUsers && $rootScope.selectedRatingUsers.length > 0) {
      $scope.politicians = $rootScope.selectedRatingUsers;
      var plen = $scope.politicians.length;
      for (var h = 0; h < plen; h++) {
        $scope.rating1[$scope.politicians[h].politicianIds[0]] = 0;
        $scope.reviewId1[$scope.politicians[h].politicianIds[0]] = '';
        $scope.rating2[$scope.politicians[h].politicianIds[0]] = 0;
        $scope.reviewId2[$scope.politicians[h].politicianIds[0]] = '';
        $scope.rating3[$scope.politicians[h].politicianIds[0]] = 0;
        $scope.reviewId3[$scope.politicians[h].politicianIds[0]] = '';
      }
    } else {
      $location.path("/incumbent_politician/politician");
    }

    MyService.ConsoleLog("selectedRatingUsers >", $rootScope.selectedRatingUsers);

    var userIds = $rootScope.selectedRatingUsers.map(function (key, val) {
      return key._id;
    });

    MyService.ConsoleLog("userIds > ", userIds);
    $scope.reviewData = [];
    $scope.review = [];
    MyService.getCompareReviewList({
      "userIds": JSON.stringify(userIds)
    }).then(function (response) {
        MyService.ConsoleLog("getCompareReviewList Response : ", response);
        if (response.data.success) {
          MyService.ConsoleLog("OrgData1 : ", response.data.orgdata);
          MyService.ConsoleLog("Data : ", response.data.data);
          $scope.reviewData = response.data.data;

          $scope.orgData = response.data.orgdata;

          $scope.review.data1 = $scope.orgData[0];
          $scope.setReviewer1($scope.review.data1);
          if ($scope.orgData[1]) {
            $scope.review.data2 = $scope.orgData[1];
            $scope.setReviewer2($scope.review.data2);
          }
          if ($scope.orgData[2]) {
            $scope.review.data3 = $scope.orgData[2];
            $scope.setReviewer3($scope.review.data3);
          }


          /*
          MyService.getOrgCompareAnswers().then(function (payload) {
            MyService.ConsoleLog("payload : ", payload);
            if (payload.data.success) {

              response.data.data.forEach(function (element) {
                var resultObject = payload.data.data.find(function (o) {
                  return o.author.username === element.name[0];
                });

                var t = 0;
                if (resultObject) {
                  t = (resultObject.fbcombinedWeight / resultObject.fbmultiplier);
                }
                element.matchCount = t.toFixed(2);
                $scope.reviewData.push(element);
              });

              $scope.reviewData = response.data.data;

              if ($scope.reviewData.length > 0) {
                if ($scope.reviewData[0].referal[0].name && $scope.reviewData[0].referal[0].name) {
                  $scope.review.data1 = JSON.stringify($scope.reviewData[0]);
                } else {
                  $scope.review.data1 = JSON.stringify($scope.reviewData[0]);
                }
                $scope.setReviewer1($scope.review.data1);
                if ($scope.reviewData.length > 1) {
                  if ($scope.reviewData[1].referal[0].name && $scope.reviewData[1].referal[0].name) {
                    $scope.review.data2 = JSON.stringify($scope.reviewData[1]);
                  } else {
                    $scope.review.data2 = JSON.stringify($scope.reviewData[1]);
                  }
                  $scope.setReviewer2($scope.review.data2);
                  if ($scope.reviewData.length > 2) {
                    if ($scope.reviewData[2].referal[0].name && $scope.reviewData[2].referal[0].name) {
                      $scope.review.data3 = JSON.stringify($scope.reviewData[2]);
                    } else {
                      $scope.review.data3 = JSON.stringify($scope.reviewData[2]);
                    }
                    $scope.setReviewer3($scope.review.data3);
                  }
                }
              }

            }
          }, function (errorpayload) {
            console.log("errorpayload : ", errorpayload);
          });
          */
        }
      },
      function (errcallback) {
        MyService.ConsoleLog("Error : ", errcallback);
      });

    $scope.getToArray = function (rdata, val) {
      // MyService.ConsoleLog("rdata", rdata);
      // MyService.ConsoleLog("val", val);
      return rdata.filter(
        function (rdata) {
          // MyService.ConsoleLog(rdata.referal[0].name + "==" + rdata.from[0].name + "==" + val);
          if (rdata.sig && rdata.sig.name != "") {
            if (rdata.sig.name == val || rdata.sig.name == val) {
              var len = $rootScope.selectedRatingUsers.length;
              for (var i = 0; i < len; i++) {
                // MyService.ConsoleLog("rdata", rdata);
                if ($rootScope.selectedRatingUsers[i].name == rdata.to[0].name) {
                  // MyService.ConsoleLog("$rootScope.selectedRatingUsers", $rootScope.selectedRatingUsers[i].politicianIds[0] + " " + rdata.to[0].name);
                  rdata.politicianId = $rootScope.selectedRatingUsers[i].politicianIds[0];
                }
              }
              rdata.rating = rdata.candidateRating[0].rating;
              return rdata.sig.sigId;
            }
          } else {
            if (rdata.organisation == val || rdata.organisation == val)
              return rdata.politicianId;
          }
        }
      );
    }

    $scope.setReviewer1 = function (data) {

      var l = $scope.rating1.length;
      for (var k in $scope.rating1) {
        $scope.rating1[k] = 'N/A';
      }

      var selected = $scope.review.data1;
      // MyService.ConsoleLog("setReviewer1 > selected : ", selected);
      var referal_name = selected;
      // MyService.ConsoleLog("setReviewer1 > referal_name : ", referal_name);
      var finalData = $scope.getToArray($scope.reviewData, referal_name);
      MyService.ConsoleLog("setReviewer1 > finalData : ", finalData);
      var len = finalData.length;
      for (var s = 0; s < len; s++) {
        $scope.rating1[finalData[s].politicianId] = finalData[s].rating;
        $scope.reviewId1[finalData[s].politicianId] = finalData[s].politicianId;
      }



    }

    $scope.setReviewer2 = function (data) {
      var l = $scope.rating2.length;
      for (var k in $scope.rating2) {
        $scope.rating2[k] = 'N/A';
      }
      var selected = $scope.review.data2;
      var referal_name = selected;

      var finalData = $scope.getToArray($scope.reviewData, referal_name);

      var len = finalData.length;
      for (var s = 0; s < len; s++) {
        $scope.rating2[finalData[s].politicianId] = finalData[s].rating;
        $scope.reviewId2[finalData[s].politicianId] = finalData[s].politicianId;
      }
    }

    $scope.setReviewer3 = function (data) {
      var l = $scope.rating3.length;
      for (var k in $scope.rating3) {
        $scope.rating3[k] = 'N/A';
      }
      var selected = $scope.review.data3;
      var referal_name = selected;

      var finalData = $scope.getToArray($scope.reviewData, referal_name);

      var len = finalData.length;
      for (var s = 0; s < len; s++) {
        $scope.rating3[finalData[s].politicianId] = finalData[s].rating;
        $scope.reviewId3[finalData[s].politicianId] = finalData[s].politicianId;
      }


    }

    $scope.getToCount = function (rdata, val) {
      // MyService.ConsoleLog("rdata", rdata);
      MyService.ConsoleLog("val", val);

      return rdata.filter(
        function (rdata) {
          // MyService.ConsoleLog(rdata.to[0].uid + "==" + val);
          if (rdata.politicianId && rdata.politicianId != "") {
            if (rdata.politicianId == val)
              return rdata;
          } else {
            // MyService.ConsoleLog("ELSE");
            // MyService.ConsoleLog(rdata.to[0].uid + "==" + val);
            if (rdata.to[0].uid == val) {
              return rdata;
            } else {
              return $scope.politicians.filter(function (itm) {
                // MyService.ConsoleLog(rdata.to[0].uid + "==" + itm._id);

                if (rdata.to[0].uid == itm._id) {
                  return rdata;
                }
              });
            }
          }
        }
      );
    }
    $scope.getRatingsTotal = function (data) {
      // MyService.ConsoleLog("data :", data);
      var fdata = $scope.getToCount($scope.reviewData, data);
      // MyService.ConsoleLog("fdata",fdata);
      return fdata.length;
    }

  }]);
