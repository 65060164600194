'use strict';
angular
  .module('myApp.openState', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/openState', {
        templateUrl: 'app/openState/openState.html',
        controller: 'openStateCtrl'
      });
    }
  ])
  .controller('openStateCtrl', ['$scope','ngDialog','$rootScope', 'MyService', '$http','$timeout','$q', function($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q) {
      $scope.loader = false;
      $scope.currPage = 1;

      $scope.people = true;
      $scope.selectCandidate = {
        selectCandy: 'user'
      };

      $scope.selDbPresent = {
        present: ''
      };
      $scope.success_msg = false;
      $scope.more = [];
      $scope.search = {
        sdata: ''
      };

      $scope.getOpenState = function() {
        $scope.loader = true;

        console.log('welcome');
        MyService.getOpenStatePeopleData().then(
          function(data) {
            MyService.ConsoleLog('Data >>>>>>', data);
            $scope.loader = false;
          },
          function(err) {
            console.log('Error >>', err);
          }
        );
      };

      $scope.myPagingFunction = function() {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.searchCount > $scope.open.length) {
          $scope.currPage++;
          $scope.pageChanged($scope.currPage);
        }
      };

      $scope.pageChanged = function(newPage) {
        $scope.getOpenData(newPage);
      };
      $scope.open = [];

      $scope.SearchByName = function() {
        // MyService.ConsoleLog('in searchMe', $scope.search.sdata);
        // var query = $scope.search.sdata.toUpperCase();

        MyService.ConsoleLog('in searchMe', $scope.search.sdata);

        MyService.SearchByName({ name: $scope.search.sdata }).then(
          function(response) {
            MyService.ConsoleLog('response', response);
            $scope.open=response.data;
            $scope.searchCount=response.count;
          },
          function(error) {
            MyService.ConsoleLog('Error>>', error);
          }
        );
      };

      $scope.getOpenData = function(pageNumber) {
        MyService.ConsoleLog('pageNumber >', pageNumber);
        var params = {
          page: pageNumber,
          max_records: 30
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];
       

        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();
       

        MyService.getOpenData(params, getAllCanceller).then(
          function(result) {
            console.log("RESPONSE >	",result.data.data);
            $scope.open = $scope.open.concat(result.data.data.data);
            $scope.searchCount=result.data.data.count;
            MyService.ConsoleLog('$scope.open >', $scope.open);
            // console.log($scope.committee);
            // $scope.totalUsers = payload.Count;
            // MyService.ConsoleLog(payload.Items);
            $scope.statusGetResPg = 2;
          },
          function(err) {
            growl.error('Something went wrong.');
            $scope.statusGetResPg = 3;
            $scope.page--;
          }
        );
      };

      $scope.getOpenData($scope.currPage);

      $scope.matchMembers = function(para) {
        MyService.ConsoleLog('parameters >', para);
        $scope.pdata = para;
        $scope.pId = para._id;
        MyService.matchPeople({ name: para.name }).then(
          function(result) {
            MyService.ConsoleLog('Result >', result);
            $scope.matchData = result;
            $scope.people = false;
          },
          function(err) {
            MyService.ConsoleLog('Error >', err);
          }
        );
      };

      $scope.back = function() {
        // console.log("back");
        // $scope.show = false;

        $scope.people = true;
      };

      $scope.showLess = function(id) {
        // console.log("id for less",id);
        $scope.more[id] = false;
      };

      //get Candidates
      $scope.getDetailsOfCandidates = function(id) {
        $scope.more[id] = true;
      };
      $scope.addMatchCandidates = function(uid, type) {
        MyService.ConsoleLog('params >', uid + 'type' + type);
        MyService.ConsoleLog('people Id >', $scope.pId);
        MyService.addMatchedPeople({
          id: uid,
          type: type,
          pId: $scope.pId
        }).then(
          function(response) {
            MyService.ConsoleLog('Response >', response);
            $(document).ready(function() {
              $scope.success_msg = true;
              setTimeout(function() {
                $('.alert').alert('close');
                $scope.getOpenData(1);
              }, 2000);
            });
          },
          function(err) {
            console.log('Error >', err);
          }
        );
      };
    }
  ]);
