'use strict';
angular.module('myApp.showMelisaData', ['ngRoute', 'angularPayments'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showMelisaData/:pId?', {
      templateUrl: 'app/showMelisaData/showMelisaData.html',
      controller: 'showMelisaDataCtrl'
    });
  }])
  .controller('showMelisaDataCtrl', ['$scope', '$rootScope', 'growl', 'ngDialog', 'tokenService', 'MyService', '$location', '$window', '$filter', '$http', '$routeParams', function ($scope, $rootScope, growl, ngDialog, tokenService, MyService, $location, $window, $filter, $http, $routeParams) {

    /**
     * Get Poli Id
     */
    $scope.pId = $routeParams.pId;

    $scope.getData = function () {
      MyService.getMeliSa({
        pId: $scope.pId
      }).then(function (result) {
        MyService.ConsoleLog("Resu;t", result);
        $scope.result = result;
      }, function (err) {
        MyService.ConsoleLog("Err", err);
        growl.error(err.error);
      });
    };
    $scope.getData();
  }]);
