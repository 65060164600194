'use strict';
angular.module('myApp.fiveMatchQuestions', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/fiveMatchQuestions/:type?', {
      templateUrl: 'app/fiveMatchQuestions/fiveMatchQuestions.html',
      controller: 'MatchQuestionsCtrl'
    });
  }])
  .controller('MatchQuestionsCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', '$routeParams', function($scope, $location, $rootScope, tokenService, MyService, ngDialog, $routeParams) {
    $scope.questions = [];
    $scope.getAllMatchQuestions = function() {
      $scope.questions.length = 0;
      MyService.getfivematch().then(function(payload) {
        MyService.ConsoleLog(payload);
        if (payload.data.success) {
          $scope.questions = payload.data.data;
        } else {
          alert("Something went wrong. Please try after some time");
        }
      }, function(errpayload) {
        alert("Something went wrong. Please try after some time");
      });
    }

    if ($routeParams.type && $routeParams.type != "") {
      $scope.showReportQuestion = true;
    } else {
      $scope.showFiveMatchQuestion = true;
    }

    $scope.editQeustion = function(q, index) {
      //MyService.ConsoleLog("edit question: ", q);
      // growl.error("Edit not implemented yet.");
      var editModal = ngDialog.open({
        template: 'app/popups/edit_question.html',
        controller: "EditQuestionCtrl",
        data: q
      });
    };

    $scope.removeReportedQeustion = function(q, index) {
      var message = 'Are you sure you want to remove this question from Five Minute Match Questions?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        MyService.removeMatchedQuestion({
          "id": q._id
        }).then(function(payload) {
          //MyService.ConsoleLog(payload);
          $scope.getAllMatchQuestions();
        }, function(errPayload) {
          //MyService.ConsoleLog(errPayload);
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    // if ($scope.showFiveMatchQuestion) {
    $scope.getAllMatchQuestions();
    // }
  }]).controller('ReportQuestionsCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog','$routeParams', function($scope, $location, $rootScope, tokenService, MyService, ngDialog,$routeParams) {
    $scope.questions = [];
    $scope.getAllReportQuestions = function() {
      $scope.questions.length - 0;
      MyService.getReportedQuestions().then(function(payload) {
        //MyService.ConsoleLog(payload);
        $scope.questions = payload;
      }, function(errPayload) {
        //MyService.ConsoleLog(errPayload);
      });
    }

    if ($routeParams.type && $routeParams.type != "") {
      $scope.showReportQuestion = true;
    } else {
      $scope.showFiveMatchQuestion = true;
    }

    $scope.editQeustion = function(q, index) {
      //MyService.ConsoleLog("edit question: ", q);
      // growl.error("Edit not implemented yet.");
      var editModal = ngDialog.open({
        template: 'app/popups/edit_question.html',
        controller: "EditQuestionCtrl",
        data: q
      });
    };

    $scope.removeReportedQeustion = function(q, index) {
      var message = 'Are you sure you want to remove this question from Reported Questions?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        MyService.removeReportedQuestion({
          "id": q._id
        }).then(function(payload) {
          //MyService.ConsoleLog(payload);
          $scope.getAllReportQuestions();
        }, function(errPayload) {
          //MyService.ConsoleLog(errPayload);
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    if ($rootScope.showReportQuestion) {
      $scope.getAllReportQuestions();
    }
  }])
