'use strict';

angular.module('myApp.howABillBecomesALaw', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/howABillBecomesALaw', {
         templateUrl: 'app/howABillBecomesALaw/howABillBecomesALaw.html',
         controller: 'howABillBecomesALawCtrl' 
     });
}])
.controller('howABillBecomesALawCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
