'use strict';
angular
  .module('myApp.poliCompare', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/poliCompare/:quizName?/:poliId?', {
        templateUrl: 'app/poliCompare/poliCompare.html',
        controller: 'poliCompareCtrl',
      });
    },
  ])
  .controller('poliCompareCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      /**
       * Get params from the route params
       */
      $scope.quizName = $routeParams.quizName;
      $scope.poliId = $routeParams.poliId;

      MyService.ConsoleLog("best out of", JSON.parse(window.localStorage.getItem('compareData')));


      $scope.showPoliAnswerDetails = false;
      $scope.showUserAnswerDetails = false;
      $scope.noDataFound = false;
      $scope.showcomparison = false;
      $scope.showComment = false;
      $scope.unComment = false;
      $scope.showCurr = false;
      $scope.currentIndex = 0;
      $scope.userId = tokenService.getUserId();
      $scope.traverseBackgrounds = [];
      $scope.currBackgroundIndex = 0;

      $scope.status = {
        questions: 0, //1=loading, 2=loaded, 3=error
        category: 0
      };


      /**
       * Get quizzes details
       */

      $scope.getData = function () {
        // alert('s');
        MyService.getAnsQues({
          poliId: tokenService.getUserId(),
          quizName: $scope.quizName,
          isShowAll: true
        }).then(function (response) {
          MyService.ConsoleLog("Response", response);
          $scope.quiz = response.data;
          window.localStorage.setItem('ansDatas', JSON.stringify($scope.quiz));

          var obj = {};
          obj.poliId = window.localStorage.getItem('compareData');
          obj.poliData = JSON.stringify($scope.quiz);
          obj.quizName = $scope.quizName;
          // window.localStorage.removeItem('compareData');
          // return
          $scope.getCompMatch(obj);
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      /**
       * Get Comparison of quiz
       */

      $scope.getCompMatch = function (params) {
        // MyService.ConsoleLog(" $scope.orgId", $scope.orgId);
        // MyService.ConsoleLog('$rootScope.resultOfAns >> ', $rootScope.resultOfAns);
        MyService.getSideBySide(params).then(function (result) {
          MyService.ConsoleLog("Results", result);
          $scope.questions = result.data.data;
          $scope.questions.sort(function (x, y) {
            if (x.qOrder < y.qOrder) {
              return -1;
            }
            if (x.qOrder > y.qOrder) {
              return 1;
            }
            return 0;
          });
        }, function (err) {
          if (err) {
            $scope.Popup();
          }
          MyService.ConsoleLog("Error", err);
        });
      };


      /**This function redirects to show comment page */
      $scope.viewAnsComment = function (val) {
        MyService.ConsoleLog('called', val);

        switch (val) {
          case 'email':
            $scope.global = $scope.arrayOfCom;
            if ($scope.userId) {
              var poli = JSON.stringify({
                poliArr: $scope.arrayOfCom,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfPolitician', poli);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'unRegister':
            if ($scope.userId) {
              var guest = JSON.stringify({
                guestArr: $scope.unVeriComm,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              window.localStorage.setItem('comOfGuest', guest);
              $location.path('/showComments');
            }
            $scope.unComment = true;
            $scope.showComment = false;
            $scope.showCurr = false;

            break;
          case 'voter':
            $scope.global = $scope.voterComArr;
            // MyService.ConsoleLog("$scope.voterComArr", $scope.voterComArr);
            if ($scope.userId) {
              var vo = JSON.stringify({
                compArr: $scope.voterComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              // MyService.ConsoleLog("vo >>", vo);
              window.localStorage.setItem('comOfVoter', vo);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'advocate':
            $scope.global = $scope.advComArr;
            if ($scope.userId) {
              var adv = JSON.stringify({
                advArr: $scope.advComArr,
                queId: $scope.questions[$scope.currentIndex]._id
              });
              $cookies.put('orgName', $scope.orgName);
              window.localStorage.setItem('comOfAdv', adv);
              $location.path('/showComments');
            }
            $scope.showComment = true;
            $scope.unComment = false;
            $scope.showCurr = false;

            break;
          case 'currentUser':
            if ($scope.userId) {
              var curUser = JSON.stringify({
                curuser: $scope.questions[$scope.currentIndex]
              });
              window.localStorage.setItem('cuUser', curUser);
              $location.path('/showComments');
            }
            $scope.showComment = false;
            $scope.showCurr = true;
            $scope.unComment = false;
            break;
          default:

            break;
        }
      };

      /**
       * Change color of match on the basis on answers
       */
      $scope.changeColor = function (nper, inx, cur_ans) {

        // MyService.ConsoleLog("inx", inx);
        // MyService.ConsoleLog("cur_ans", cur_ans);

        var arrOfCol = {
          "5": "#990000",
          "4": "#cc3300",
          "3": "#ff9933",
          "2": "#ffff00",
          "1": "#99cc00",
          "0": "#00d900"
        };
        var cdiff = Math.abs(inx - cur_ans);

        // console.log("arrOfCol > ", arrOfCol);
        $scope.gr = null;
        switch (true) {
          case (nper >= 100):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }

            // MyService.ConsoleLog("$scope.gr >>", arrOfCol.nper);
            break;
          case (nper >= 80):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 60):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 40):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 20):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;
          case (nper >= 0):
            if (cur_ans == inx) {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[0] + " " + nper + "%, #2b4f7f 10%)"
              };
            } else {
              $scope.gr = {
                "background": "linear-gradient(to right, " + arrOfCol[cdiff] + " " + nper + "%, #2b4f7f 10%)"
              };
            }
            break;

          default:
            break;
        }
        // MyService.ConsoleLog(" $scope.gr", $scope.gr);
        return $scope.gr;

      };


      $scope.getOldAnswers = function (val) {
        MyService.ConsoleLog('dava', val);
        $scope.oldAns = val;
        $scope.showPopUp = ngDialog.open({
          template: 'showOldAnsPopUp',
          closeByNavigation: true,
          scope: $scope,
        });
      };

      /** Return class of background color */

      $scope.getComapreClass = function (match, i) {

        if (match >= 100) return 'ag-dg1';
        if (match >= 80) return 'ag-dg2';
        if (match >= 60) return 'ag-dg3';
        if (match >= 40) return 'ag-dg4';
        if (match >= 20) return 'ag-dg5';
        if (match >= 0) return 'ag-dg6';

        return 'ag-dg';
      };

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        $("html, body").animate({
          scrollTop: $("#DisplayQ").offset().top - 280
        }, "slow");
        if ($scope.currentIndex > 0) {
          var decr = $scope.currentIndex--;
          var one = decr - 1;
          MyService.ConsoleLog("decr", one);

        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            MyService.ConsoleLog('$scope.traverseBackgrounds >', $scope.traverseBackgrounds);
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            //MyService.ConsoleLog("** end of background **");
            if ($scope.rootCatIndex > 0) {
              $scope.rootCatIndex--;
              $location.path(
                '/question-compare/all/' + $rootScope.categories[$scope.rootCatIndex]._id
              );
            }
          }
        }
      };

      $scope.skipQuestion = function () {
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          MyService.ConsoleLog(' $scope.currentIndex >>', tempIndex);
          $("html, body").animate({
            scrollTop: $("#DisplayQ").offset().top - 280
          }, "slow");
          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          MyService.ConsoleLog(
            'tempIndex = ',
            tempIndex + '$scope.questions.length ' + $scope.questions.length
          );
          if (tempIndex == $scope.questions.length || $scope.questions.length == 0) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            MyService.ConsoleLog('$scope.traverseBackgrounds >>', $scope.traverseBackgrounds);
            if ($scope.traverseBackgrounds && $scope.traverseBackgrounds != '') {
              if (tempBackIdx < $scope.traverseBackgrounds.length) {
                $scope.currBackgroundIndex++;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
              } else {
                $scope.rootCatIndex++;
                // alert("Else");
                window.localStorage.setItem('RootCatIndex', $scope.rootCatIndex);
                window.localStorage.setItem('DisplayCategory', JSON.stringify($scope.category));
                //MyService.ConsoleLog("/politician_compare/" + $routeParams.categoryId + "/" + $routeParams.parentId);
                $location.path(
                  '/politician_compare/' + $routeParams.categoryId + '/' + $routeParams.parentId
                );
              }
            } else {
              $location.path('/matchOfFollowers');
            }
          }
        };

        skip();
      };



      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;

      $scope.getCategory = function (params) {
        try {
          prevFetchedCategoryId = $scope.category._id
        } catch (e) {}

        //MyService.ConsoleLog("prevFetchedCategoryId: ", prevFetchedCategoryId);
        //MyService.ConsoleLog("params.id: ", params.id);
        if (prevFetchedCategoryId != params.id) {
          //MyService.ConsoleLog("scrollTop");
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          getCategoryCanceler.resolve();
          getCategoryCanceler = $q.defer()
          $scope.status.category = 1;
          // alert(" category called");
          MyService.getCategories(params, getCategoryCanceler.promise).then(function (response) {
            //MyService.ConsoleLog("-----------------------------");
            //MyService.ConsoleLog("response: ", response);
            //MyService.ConsoleLog("-----------------------------");
            if (response) {
              if (response.data.success) {
                $scope.category = response.data.data;
                //MyService.ConsoleLog("category data: ", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
                //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
                $scope.status.category = 3;
                //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
              }
            } else {
              $scope.status.category = 2;
              //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
            }
          }, function (err) {
            //MyService.ConsoleLog("Error: ", err);
            $scope.status.category = 3;
            //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
          });
        }
      };

      $scope.rootCatIndex = 0;
      $scope.getRootCategoryIndex = function () {
        if ($routeParams.categoryId == 'all') {
          var len = $rootScope.categories.length;
          for (var i = 0; i < len; i++) {
            if ($routeParams.parentId == $rootScope.categories[i]._id) {
              $scope.rootCatIndex = i;
              MyService.ConsoleLog("rootCatIndex : ", $scope.rootCatIndex);
              break;
            }
          }
        }
      };

      var getLevelCategoryCanceler = $q.defer();

      function getLevelCategory(params) {
        getLevelCategoryCanceler.resolve();
        $scope.status.levlCat = 1;
        params.timeout = getLevelCategoryCanceler.promise;
        var request = apiCall.apiCall('GET', '/categories/list-layer', params);
        $http(
            request
          )
          .then(function successCallback(response) {
            MyService.ConsoleLog("Got categories: ", response);
            if (response.data.success) {
              $scope.traverseBackgrounds = response.data.data;
              if ($routeParams.selectedCatId) {
                angular.forEach(response.data.data, function (val, key) {
                  if (val._id == $routeParams.selectedCatId) {
                    $scope.currBackgroundIndex = key;
                    $scope.category = val;
                  }
                });
              } else if ($scope.traverseBackgrounds.length > 0) {
                $scope.category = $scope.traverseBackgrounds[0];
              }
              $scope.status.levlCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.levlCat = 3;
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.levlCat = 3;
          });
      }


      $scope.$watch('category', function (newValue, oldValue) {
        //MyService.ConsoleLog("** category watch **");
        var current_url = $location.url();

        if (current_url.indexOf("question-tagged") > -1)
          return; //for tagged questions don't again fetch questions

        if (newValue) {
          //MyService.ConsoleLog("<<<<<<oldValue: ", oldValue);
          //MyService.ConsoleLog("<<<<<newValue: ", newValue);
          if (oldValue && oldValue._id == newValue._id) return;
          //MyService.ConsoleLog("scrollTop");
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          // $scope.currentIndex = 0;

          if (current_url.indexOf("question-compare") > -1) {
            var temp_uids = [];
            angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
              temp_uids.push(val._id);
            });
            MyService.ConsoleLog("**** getCompareAnswers ****");
            $scope.getCompareAnswers({
              "categoryIds": newValue._id,
              "userIds": temp_uids.join(",")
            }); //"compare_with": $routeParams.user_type,
          }
        }
      });

      $scope.$watch('questions', function (newValue, oldValue) {
        //MyService.ConsoleLog("** questions watch **");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid
            });
          }

          // $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("questions Index Change Ex: ", e)
        }
      });

      $scope.$watch('currentIndex', function (newValue, oldValue) {
        //MyService.ConsoleLog("** currentIndex watch **");
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            $scope.getCategory({
              id: tempCate[0].cid
            });
          } else {
            $scope.getCategory({
              id: tempCate.cid
            });
          }
          // $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });
      $scope.getData();


      $scope.getInfo = function (val) {

        if (val && val.comment && val.comment != '') {
          $scope.showComment = val.comment;
          $scope.showPopUp = ngDialog.open({
            template: 'showInfoPopUp',
            closeByNavigation: true,
            scope: $scope,
          });
        }
      };

    }
  ]);
