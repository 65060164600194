'use strict';
angular.module('myApp.allUsers', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/allUsers', {
      templateUrl: 'app/allUsers/allUsers.html',
      controller: 'AllUsersCtrl'
    });
  }])
  .controller('AllUsersCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', 'growl', '$filter', '$q', '$window', function ($scope, $location, $rootScope, tokenService, MyService, ngDialog, growl, $filter, $q, $window) {
    //MyService.ConsoleLog("* Following *");
    $scope.loginUser = tokenService.getUserId();
    $scope.allData = JSON.parse(tokenService.getUserData());
    $scope.fData = $scope.allData.followlist;
    $scope.UserFormDiv = false;
    $scope.newuser = {
      type: "forumadmin",
      name: '',
      email: '',
      username: '',
      password: '',
    };
    $scope.page = 1;

    $scope.sel = {
      state: ''
    };

    $scope.initVar = function () {
      $scope.userTypes = ["forumadmin", "voteradmin", "dataentryadmin"];
      $scope.users = {
        data: []
      };

      $scope.totalUsers = 0;
      $scope.usersPerPage = 25; // this should match however many results your API puts on one page

      $scope.pagination = {
        current: 1
      };
    };
    $scope.initVar();

    $scope.pageChanged = function (newPage) {
      $scope.getResultsPage(newPage);
    };

    $scope.notifyUser = 0;
    $scope.notifyUsersTheirPasswords = function () {
      var tempArr = [];
      angular.forEach($scope.users.data, function (val, key) {
        if (val.isSelected) {
          tempArr.push(val._id);
        }
      });

      var postparam = {
        "user_ids": tempArr.join(",")
      };
      MyService.notifyUsersPasswords(postparam, null).then(function (response) {
        if (response.data.success) {
          $scope.notifyUser = 2;
          growl.success('Send successfully.');
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
          $scope.notifyUser = 3;
        } else {
          growl.error('Something went wrong.');
          $scope.notifyUser = 3;
        }
      }, function (err) {
        growl.error("Something went wrong.");
      });
      //MyService.ConsoleLog("tempArr: ", tempArr);
    };

    $scope.selectAll = function () {
      angular.forEach($scope.users.data, function (val, key) {
        val.isSelected = true;
      });
    };

    // generate Password
    $scope.pass = "";

    // $scope.pass[] = false;
    $scope.generatePassword = function (sdata) {
      $rootScope.Id = sdata._id;
      MyService.ConsoleLog("id", sdata._id);
      MyService.ConsoleLog("data", sdata.userStatus);

      $scope.password = Math.random().toString(36).slice(-6);
      $scope.User.password = $scope.password;
      MyService.ConsoleLog("password :", $scope.password);
      if (sdata.userStatus == "Blocked") {
        var message = "Do you want to also change your email address and username";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Cancel</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
          plain: true,
          closeByDocument: false
        });
        modal.then(function fullfilled(data) {
          MyService.ConsoleLog("fullfilled: ", data);
          $scope.addressPopup = ngDialog.open({
            template: 'addressPopUp',
            closeByNavigation: true,
            scope: $scope
          });
        }, function rejected(data) {
          MyService.ConsoleLog("rejected: ", data);
          MyService.UpdatePassword({
            id: sdata._id,
            password: $scope.password,
            flag: false
          }).then(function (response) {
            // console.log(response);
            $scope.pass = sdata._id;
          });
        });
      } else {
        MyService.UpdatePassword({
          id: sdata._id,
          password: $scope.password,
          flag: false
        }).then(function (response) {
          // console.log(response);
          $scope.pass = sdata._id;
        });
      }
    };

    $scope.User = {
      email: "",
      username: "",
      password: ""
    };

    $scope.updateCredentials = function (udata) {
      MyService.ConsoleLog("udata", udata);
      MyService.ConsoleLog(" $rootScope.Id", $rootScope.Id);
      MyService.ConsoleLog("$scope.password", $scope.password);

      MyService.UpdatePassword({
        id: $rootScope.Id,
        password: $scope.User.password,
        email: $scope.User.email,
        username: $scope.User.username,
        flag: true
      }).then(function (response) {
        // console.log(response);
        if (response.data.success) {
          $scope.addressPopup.close();
          $rootScope.Id = "";
          MyService.ConsoleLog("resonse new", response.data.data);
          growl.success("Updated Successfully");
        } else {
          growl.error(response.data.error);
        }
        MyService.ConsoleLog("response", response);
        $scope.pass = udata._id;
      }, function (err) {
        growl.error(err);
      });
    };



    // Select location startsAllUsersCtrl

    $scope.selectedCat = [];
    $scope.SelectedTree = [];
    $scope.disabled = [];
    $scope.Allcountries = [];
    $scope.SelectedCountry = '';
    $scope.newlocation = {
      country: ''
    };
    $scope.profile = {
      orgData: {}
    }
    $scope.subtopics = {
      subcategories: []
    };

    $scope.Essay = {
      location: '',
      subcategories: []
    };


    $scope.selectedCat = [];
    $scope.SelectedTree = [];
    $scope.disabled = [];
    $scope.Allcountries = [];
    $scope.SelectedCountry = '';
    $scope.newlocation = {
      country: ''
    };
    $scope.profile = {
      orgData: {}
    }
    $scope.subtopics = {
      subcategories: []
    };

    $scope.Essay = {
      location: '',
      subcategories: []
    };

    $scope.ShowAddLocation = function () {
      if (!$scope.AddLocation) {
        $scope.AddLocation = true;
      } else {
        $scope.AddLocation = false;
      }

    }


    function suggest_country(term) {
      var q = term.toLowerCase().trim();
      var results = [];
      var len = $scope.Allcountries.length;
      // Find first 10 states that start with `term`.
      for (var i = 0; i < len && results.length < 10; i++) {
        var country = $scope.Allcountries[i];
        if (country.toLowerCase().indexOf(q) === 0)
          results.push({
            label: country,
            value: country
          });
      }
      return results;
    }

    $scope.autocomplete_options = {
      suggest: suggest_country
    };


    $scope.getAllStates = function () {
      MyService.getAllStates().then(function (payload) {
          $scope.allLocations.length = 0;
          var subcatdata = payload;
          //MyService.ConsoleLog("Sub states : ", subcatdata);
          $scope.allLocations = subcatdata;
          //MyService.ConsoleLog($scope.profile.address);
          //MyService.ConsoleLog($scope.profile.address.state);
          if (($scope.profile.address) && ($scope.profile.address.state)) {
            var chkdata = $scope.checkExists(subcatdata, $scope.profile.address.state);
            //MyService.ConsoleLog("State check item :", chkdata[0]);
            if (chkdata != "") {
              chkdata[0].country = "United States of America";
              //MyService.ConsoleLog(JSON.stringify(chkdata[0]));
              $scope.profile.state = JSON.stringify(angular.copy(chkdata[0]));
            }
          }
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }



    $scope.getVal = function (data) {
      //MyService.ConsoleLog(data);
      var idx = $scope.selectedCat.indexOf(data);
      if (idx > -1) {
        $scope.selectedCat.splice(idx, 1);
        var id = $scope.disabled.indexOf(data._id);
        $scope.disabled.splice(id, 1);
        var jdx = $scope.SelectedTree.indexOf(data.parentIds);
        if (jdx > -1)
          $scope.SelectedTree.splice(jdx, 1);

      } else {
        $scope.selectedCat.push(data);
        var id = $scope.disabled.indexOf(data._id);
        if (id == -1) {
          $scope.disabled.push(data._id);

        }
        //MyService.ConsoleLog(data.parentIds);
        //MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
        if ($scope.SelectedTree.length == 0) {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            //angular.copy(data.parentIds, $scope.SelectedTree);
            var parents = data.parentIds;
            //MyService.ConsoleLog(parents);
            $scope.SelectedTree = parents;
          }
        } else {
          if (data.parentIds.length > 0) {
            //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
            //var parents = angular.copy(data.parentIds);
            var parents = data.parentIds;
            var plen = parents.length;
            for (var j = 0; j < plen; j++) {
              $scope.SelectedTree.push(parents[j]);
            }
          }
        }
      }
      //MyService.ConsoleLog($scope.SelectedTree);
    }


    $scope.selectedAll = {
      id: []
    };
    $scope.removeVal = function (data) {
      //MyService.ConsoleLog("EntrieData<><", data);
      var idx = $scope.selectedCat.indexOf(data);
      if (idx > -1) {
        $scope.selectedCat.splice(idx, 1);
        //MyService.ConsoleLog("removeDataId:", data._id);
        //MyService.ConsoleLog("Before:::", $scope.disabled);
        var vid = $scope.disabled.indexOf(data._id);
        //MyService.ConsoleLog("removeId:", vid);


        $scope.disabled.splice(vid, 1);
        //MyService.ConsoleLog("After:::", $scope.disabled);

        var parents = data.parentIds;
        var plen = parents.length;
        for (var j = 0; j < plen; j++) {
          var jdx = $scope.SelectedTree.indexOf(parents[j]);
          if (jdx > -1)
            $scope.SelectedTree.splice(jdx, 1);
        }
      }
      $scope.selectedAll.id.length = 0;
      //MyService.ConsoleLog($scope.SelectedTree);
      var allLen = $scope.SelectedTree.length;
      var chkboxe = document.getElementsByName("checkedidcat");
      for (var i = 0; i < allLen; i++) {
        chkboxe[i].checked = true;

      }
    }

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    $scope.checksubCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id == val)
            return rdata
          else if (rdata.subCategories) {
            $scope.checksubCatExists(rdata.subcategories, val)
          }
        }
      );
    }

    $scope.checkCatExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val)
          }
        }
      );
    }
    $scope.SaveButton = true;
    $scope.getSubcatTree = function () {
      $scope.selectedCat.length = 0;
      $scope.SaveButton = true;

      MyService.getAllCatTree({
        "type": ""
      }).then(function (payload) {
          $scope.subtopics.subcategories.length = 0;
          var subcatdata = payload;
          //MyService.ConsoleLog("subcatdata : ", subcatdata);
          $scope.subtopics.subcategories = subcatdata;
          var selectedCat = $scope.disabled;
          if (selectedCat && selectedCat.length > 0) {
            for (var s = 0; s < selectedCat.length; s++) {
              var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
            }
            //MyService.ConsoleLog($scope.selectedCat);
          }
          $scope.SaveButton = false;
          setTimeout(function () {
            var cols = document.getElementsByClassName('cmnUL');
            var clen = cols.length;
            for (var i = 0; i < clen; i++) {
              cols[i].style.display = "none";
            }
          }, 300);
        },
        function (errorPayload) {
          console.log('errorPayload :>> ', errorPayload);
          // growl.error('Something went wrong.');
        });
    }


    $scope.selectedAll = {
      id: []
    };

    $scope.selectConfig = {
      requiredMin: 1,
      selectAll: true
    };

    $scope.allLocations = [];
    $scope.allCounties = [];
    $scope.selectedArea = [];
    $scope.rmselectedArea = [];
    $scope.myselectionarea = [];
    $scope.disabledLocation = [];

    $scope.checkExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.state == val)
            return rdata
        }
      );
    }

    $scope.checkCountyExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id.county == val)
            return rdata._id
        }
      );
    }

    $scope.checkCountryExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.country == val)
            return rdata
        }
      );
    }

    $scope.checkLocalExists = function (rdata, val, val1) {
      return rdata.filter(
        function (rdata) {
          if ((rdata._id.place == val) && (rdata._id.countySubdivision == val1))
            return rdata._id
        }
      );
    }

    function areEqual() {
      var len = arguments.length;
      for (var i = 1; i < len; i++) {
        if (arguments[i] == null || arguments[i] != arguments[i - 1])
          return false;
      }
      return true;
    }

    function indexOfExt(list, item) {
      var len = list.length;

      for (var i = 0; i < len; i++) {
        var keys = Object.keys(list[i]);
        var flg = true;
        var arr = [];


        for (var j = 0, s = 1; j < keys.length; j++, s++) {
          var value = list[i][keys[j]];
          if (item[keys[j]] !== value) {
            flg = false;

          }

          if (item[keys[j]] == value) {
            arr.push(flg);
          }


        }
        if (flg == true) {
          if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
            return i;
          }
        }


      }
      return -1;
    }

    $scope.checkDisabled = function (data, stateId, type) {
      data.country = "United States of America";
      data.state = stateId;
      var idx = indexOfExt($scope.disabledLocation, data);
      if (idx > -1)
        return true;
      else
        return false;
    }

    $scope.getAreaVal = function (data, stateId, type) {

      if (!data.country) {
        data.country = "United States of America";
      }
      if (!data.state) {
        data.state = stateId;
      }



      var idx = indexOfExt($scope.selectedArea, data);


      if (idx > -1) {
        growl.error("You have already selected the location");
      } else {

        $scope.selectedArea.push(data);
        $scope.disabledLocation.push(data);

      }
      //MyService.ConsoleLog($scope.selectedArea);
      //MyService.ConsoleLog($scope.disabledLocation);
    }
    $scope.selectedLAll = {
      id: []
    };
    $scope.removeAreaVal = function (data) {
      var idx = $scope.selectedArea.indexOf(data);
      //MyService.ConsoleLog(data);
      //MyService.ConsoleLog($scope.selectedArea);
      //MyService.ConsoleLog(idx);
      //MyService.ConsoleLog($scope.Essay.Locations);
      if (idx > -1) {
        //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
        if ($scope.selectedArea[idx].country == "International") {
          $scope.Essay.Locations = 'NO';
        }
        if ($scope.selectedArea[idx].country == "National (USA)") {
          $scope.Essay.National = 'NO';
        }
        $scope.selectedArea.splice(idx, 1);
        $scope.disabledLocation.splice(idx, 1);

      }
      //MyService.ConsoleLog($scope.disabledLocation);
      // var jdx = $scope.disabledLocation.indexOf(data);
      //MyService.ConsoleLog(jdx);
      //   if (jdx > -1) {
      //     $scope.disabledLocation.splice(jdx, 1);
      //   }
      $scope.selectedLAll.id.length = 0;
      //MyService.ConsoleLog($scope.selectedArea);
      var allLen = $scope.SelectedTree.length;
      var chkboxe = document.getElementsByName("checkedid");
      for (var i = 0; i < allLen; i++) {
        chkboxe[i].checked = true;

      }

    }
    $scope.allCounties = [];
    $scope.allLocals = [];
    $scope.setLocation = function (option) {
      $scope.Essay.location = option;
    }
    $scope.getLocations = function (val, type) {
      if (type == "National") {
        if (val == "National") {
          var nobj = {
            country: "National (USA)"
          }
          $scope.selectedArea.push(nobj);
          //MyService.ConsoleLog($scope.selectedArea);
        }
        if (val == "NO") {
          var nobj = {
            country: "National (USA)"
          }
          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      } else if (type == "International") {

        if (val == "International") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }
          $scope.selectedArea.push(nobj);
        }

        if (val == "NO") {
          // $scope.selectedArea.length = 0;
          var nobj = {
            country: "International"
          }

          var idx = indexOfExt($scope.selectedArea, nobj);
          if (idx > -1) {
            $scope.selectedArea.splice(idx, 1);
          }
        }
      }
    }


    $scope.MyCounties = [];
    $scope.MyPlaces = [];
    $scope.getCounties = function (state, val) {
      if (val != "address")
        $scope.showLoading.push(state);
      if (val != "address") {
        if ((!$scope.allCounties[state]) || ($scope.allCounties[state].length == 0)) {
          MyService.getAllCounties(state).then(function (payload) {
              //MyService.ConsoleLog("sublocations Locals : ", sublocations);
              $scope.showLoading.length = 0;
              var sublocations = payload;
              //MyService.ConsoleLog("sublocations counties : ", sublocations);
              if (!$scope.allCounties) {
                $scope.allCounties = [];
              }
              $scope.allCounties[state] = sublocations;
            },
            function (errorPayload) {
              growl.error('Something went wrong.');
            });
        } else {
          $scope.showLoading.length = 0;
          $scope.allCounties[state].length = 0;
        }
      } else if (val == "address") {

        if (typeof $scope.profile.state == "string") {
          var data = JSON.parse($scope.profile.state);
        } else {
          var data = $scope.profile.state;
        }
        var statePostalCode = data.statePostalCode;
        if (!data.statePostalCode || data.statePostalCode == null || data.statePostalCode == "null") {
          //MyService.ConsoleLog($scope.allLocations);
          //MyService.ConsoleLog(data.state);
          var chkData = $scope.checkExists($scope.allLocations, data.state);
          //MyService.ConsoleLog("chkData : ", chkData[0]);
          if (chkData[0]) {
            statePostalCode = chkData[0].statePostalCode;
          }
        }
        //MyService.ConsoleLog("statePostalCode:", statePostalCode);

        $scope.MyCounties.length = 0;
        MyService.getAllCounties(statePostalCode).then(function (payload) {
            //MyService.ConsoleLog("sublocations Locals : ", sublocations);
            $scope.showLoading.length = 0;
            $scope.MyCounties = payload;
            //MyService.ConsoleLog("getCounty checkdata: ", $scope.profile.address.county);
            if ($scope.profile.address.county) {
              var chkdata = $scope.checkCountyExists(payload, $scope.profile.address.county);
              //MyService.ConsoleLog("getCounty checkdata: ", chkdata);
              if (chkdata != "") {

                $scope.profile.county = JSON.stringify(angular.copy(chkdata[0]._id));
              }
            }
          },
          function (errorPayload) {
            growl.error('Something went wrong.');
          });

      }
    }

    $scope.getLocals = function (state, county, val) {
      //MyService.ConsoleLog("state : " + state + ", county :" + county + ", val :" + val)
      if (val != 'address')
        $scope.showLoadingCounty.push(county);
      if (val == "address") {
        $scope.MyPlaces.length = 0;
        if (typeof $scope.profile.state == "string") {
          var sdata = JSON.parse($scope.profile.state);
        } else {
          var sdata = $scope.profile.state;
        }
        if (typeof $scope.profile.county == "string") {
          var cdata = JSON.parse($scope.profile.county);
        } else {
          var cdata = $scope.profile.county;
        }
        MyService.getAllSubCountiesLocal(sdata.statePostalCode, cdata.county).then(function (payload) {
            $scope.MyPlaces = payload;
            //MyService.ConsoleLog("PlaCE :", $scope.profile.address.place);
            if ($scope.profile.address.place) {

              var chkdata = $scope.checkLocalExists(payload, $scope.profile.address.place, $scope.profile.address.countySubdivision);
              //MyService.ConsoleLog("getLocal checkdata: ", chkdata[0]);
              if (chkdata != "") {
                var idx = $scope.MyPlaces.indexOf(chkdata[0]);
                //MyService.ConsoleLog(idx);
                //MyService.ConsoleLog(angular.copy($scope.MyPlaces[idx]._id));
                $scope.profile.places = JSON.stringify(angular.copy($scope.MyPlaces[idx]._id));
              }
            }
          },
          function (errorPayload) {
            growl.error('Something went wrong.');
          });

      } else {
        if ((!$scope.allLocals[state] || !$scope.allLocals[state][county]) || ($scope.allLocals[state][county] == 0)) {

          MyService.getAllSubCountiesLocal(state, county).then(function (payload) {
              $scope.showLoadingCounty.length = 0;
              //MyService.ConsoleLog("sublocations Locals : ", sublocations);
              if (!$scope.allLocals[state]) {
                $scope.allLocals[state] = [];
              }
              $scope.allLocals[state][county] = payload;
            },
            function (errorPayload) {
              growl.error('Something went wrong.');
            });

        } else {
          $scope.showLoadingCounty.length = 0;
          $scope.allLocals[state][county] = 0;

        }
      }
    }

    $scope.showSubCat = false;
    $scope.showLoading = [];
    $scope.showLoadingCounty = [];
    $scope.showDown = [];
    $scope.ChangeShow = function (id) {
      $scope.showSubCat = true;
      var cols = document.getElementsByClassName('cmnUL');
      var clen = cols.length;
      var nclass = "MainCatLi_" + id;
      if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
        document.getElementsByClassName(nclass)[0].style.display = "block";
      } else {
        document.getElementsByClassName(nclass)[0].style.display = "none";
      }
    }

    $scope.checkTagsExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {

          if (rdata.pid == val)
            return rdata
        }
      );
    }

    //Select location ends
    $scope.users.data = [];
    $scope.statusGetResPg = 0;
    var searchUserByDetailCanceller = $q.defer();
    var searchOrgByDetailCanceller = $q.defer();
    var getAllCanceller = $q.defer();
    $scope.postOptions = ['Postcard', 'OfficialWebsite'];
    $scope.getResultsPage = function (pageNumber) {
      // this is just an example, in reality this stuff should be in a service
      //MyService.ConsoleLog("pageNumber");
      var params = {
        "page": pageNumber,
        max_records: 30
      };
      //MyService.ConsoleLog("params: ", params);
      // $scope.users.data = [];
      $scope.totalUsers = 0;
      //MyService.ConsoleLog($scope.userDetailFilter);
      searchOrgByDetailCanceller.resolve();
      searchUserByDetailCanceller.resolve();
      getAllCanceller.resolve();
      if (!$filter("isBlankString")($scope.orgDetailKeyword) || !$filter("isBlankString")($scope.userDetailKeyword) || !$filter("isBlankString")($scope.userDetailFilter)) {
        if (!$filter("isBlankString")($scope.orgDetailKeyword)) {
          //MyService.ConsoleLog("searching by organization");
          $scope.statusGetResPg = 1;

          searchOrgByDetailCanceller = $q.defer();
          params.keyword = $scope.orgDetailKeyword;
          var calc = (pageNumber - 1) * 30;
          if ($scope.users.data.length >= calc) {
            MyService.searchUserByOrgDetail(params, searchOrgByDetailCanceller).then(function (payload) {
              MyService.ConsoleLog("payload: ", payload);
              // $scope.users.data = payload.Items;
              $scope.users.data = $scope.users.data.concat(payload);
              //MyService.ConsoleLog("length: ", $scope.users.data.length);
              $scope.totalUsers = payload.Count;
              //MyService.ConsoleLog(payload.Items);
              $scope.statusGetResPg = 2;
            }, function (err) {
              //MyService.ConsoleLog(err);
              growl.error("Something went wrong.");
              $scope.statusGetResPg = 3;
              $scope.page--;
            });
          } else {
            //MyService.ConsoleLog("break loop");
            $scope.page = 0;
            // break;
            return false;

          }
        } else if (!$filter("isBlankString")($scope.userDetailKeyword) || !$filter("isBlankString")($scope.userDetailFilter)) {
          MyService.ConsoleLog("searching by user detail");
          $scope.statusGetResPg = 1;

          searchUserByDetailCanceller = $q.defer();
          params.keyword = $scope.userDetailKeyword;
          if (!params.keyword) {
            params.keyword = "postcard";
          }
          params.listOption = $scope.userDetailFilter;
          var calc = (pageNumber - 1) * 30;
          if ($scope.users.data.length >= calc) {
            MyService.searchUserByDetail(params, searchUserByDetailCanceller).then(function (payload) {
              MyService.ConsoleLog("payload: ", payload);
              // $scope.users.data = payload.Items;
              $scope.users.data = $scope.users.data.concat(payload);
              //MyService.ConsoleLog("length: ", $scope.users.data.length);
              $scope.totalUsers = payload.Count;
              //MyService.ConsoleLog(payload.Items);
              $scope.statusGetResPg = 2;
            }, function (err) {
              //MyService.ConsoleLog(err);
              growl.error("Something went wrong.");
              $scope.statusGetResPg = 3;
              $scope.page--;
            });
          } else {
            //MyService.ConsoleLog("break loop");
            $scope.page = 0;
            // break;
            return false;

          }
        }
      } else {
        if ($scope.filterUser) {
          params.usertype = $scope.filterUser;
        }
        $scope.statusGetResPg = 1;

        getAllCanceller = $q.defer();
        var calc = (pageNumber - 1) * 30;
        if ($scope.users.data.length >= calc) {
          MyService.getAllUsers(params, getAllCanceller).then(function (payload) {
            // $scope.users.data = payload.Items;
            $scope.users.data = $scope.users.data.concat(payload.Items);
            MyService.ConsoleLog("length: ", $scope.users.data);
            $scope.totalUsers = payload.Count;
            //MyService.ConsoleLog(payload.Items);
            $scope.statusGetResPg = 2;
          }, function (err) {
            //MyService.ConsoleLog(err);
            growl.error("Something went wrong.");
            $scope.statusGetResPg = 3;
            $scope.page--;
          });
        } else {
          //MyService.ConsoleLog("break loop");
          $scope.page = 0;
          // break;
          return false;

        }
      }
    }

    $scope.ShowUserForm = function () {
      $scope.UserFormDiv = 'New';
    }

    $scope.addNewUser = function (data) {
      if ($.fn.validateForceFully($("#AllUsersform")) == true) {

        //MyService.ConsoleLog("data: ", data);
        //MyService.ConsoleLog($scope.selectedArea);

        //MyService.ConsoleLog($scope.disabledLocation);


        if (data.subtype == "dataentryadmin") {
          data.type = "volunteer";
          data.volunteers = JSON.stringify({
            vtype: data.subtype,
            permited_location: $scope.selectedArea
          });
        }

        MyService.signUp(data).then(function (payload) {
          //MyService.ConsoleLog(payload);
          if (data.subtype == "dataentryadmin") {
            $scope.newuser = {
              type: "forumadmin",
              name: '',
              email: '',
              username: '',
              password: '',
            };
          } else {
            $scope.newuser = {
              type: "forumadmin",
              name: '',
              email: '',
              username: '',
              password: '',
            };
          }


          $scope.UserFormDiv = false;

          $scope.getResultsPage(1);

          alert("User added successfully.");

        }, function (error) {
          alert(JSON.stringify(error));
        });
      }
    }
    $scope.upuser = [];

    $scope.EditUser = function (mydata, idx) {
      // MyService.ConsoleLog("welcome yo edit");
      // MyService.ConsoleLog("edit");
      $scope.UserFormDiv = 'Edit';
      $scope.upuser.length = 0;
      $scope.upuser = mydata;
      MyService.ConsoleLog("para id :", idx);
      MyService.ConsoleLog("para", $scope.upuser);
      $scope.getSubcatTree();

      //$scope.disabledLocation = mydata.location_of_interest;
      //MyService.ConsoleLog(mydata.volunteers);
      //MyService.ConsoleLog(mydata.volunteers[0].permited_location);
      //MyService.ConsoleLog(mydata.volunteers[0].permited_location.length);
      //MyService.ConsoleLog($scope.selectedArea);
      //MyService.ConsoleLog($scope.disabledLocation);

      var locations = mydata.locationOfInterest;

      var catArr = mydata.subjectOfInterest;

      $scope.disabled = mydata.subjectOfDeepInterests;
      $scope.SelectedTree = mydata.subjectOfInterest;

      if (mydata.locationOfInterest && mydata.locationOfInterest.length > 0) {
        //MyService.ConsoleLog(mydata);
        var locData = mydata.locationOfInterest;
        //MyService.ConsoleLog("locData ", locData);
        var finalArray = [];
        var len = locations.length;
        for (var l = 0; l < len; l++) {
          var nobj = {};
          if (locations[l].country) {
            if (locations[l].country == "National (USA)") {
              $scope.Essay.National = "National";
            }
            if (locations[l].country == "International") {
              $scope.Essay.Locations = "International";
            }
            nobj.country = locations[l].country;
          }
          if (locations[l].state) {
            nobj.state = locations[l].state;
            nobj.statePostalCode = locations[l].statePostalCode;
          }
          if (locations[l].county) {
            nobj.county = locations[l].county;
          }
          if (locations[l].countySubdivision) {
            nobj.countySubdivision = locations[l].countySubdivision;
          }
          if (locations[l].place) {
            nobj.place = locations[l].place;
          }
          $scope.selectedArea.push(nobj);
          $scope.disabledLocation.push(nobj);
        }
        //MyService.ConsoleLog($scope.disabledLocation);
      }
      //MyService.ConsoleLog(idx);
    }

    $scope.closeUserEdit = function () {
      $scope.UserFormDiv = false;
    }

    $scope.UpdateUser = function (data) {
      var userId = angular.copy(data._id);
      MyService.ConsoleLog("data: ", data);
      delete data._id;
      delete data.__v;
      delete data.lastLogin;
      delete data.admin;
      delete data.politician;
      delete data.press;
      delete data.voteradmin;
      delete data.advocate;
      delete data.voter;
      delete data.$$hashKey;

      if ($scope.SelectedTree) {
        data.subjectOfInterest = $scope.SelectedTree;
      }
      //MyService.ConsoleLog("Final:", $scope.disabled);
      if ($scope.disabled) {
        data.subjectOfDeepInterests = $scope.disabled;
      }

      if ($scope.selectedArea) {
        data.locationOfInterest = $scope.selectedArea;
      }
      //MyService.ConsoleLog(data);
      var ndata = angular.toJson(data);


      //MyService.ConsoleLog($scope.selectedArea);
      //MyService.ConsoleLog($scope.disabledLocation);
      // var nobj = new Object;
      // nobj.id = data._id;
      // nobj.name = data.name;
      // nobj.email = data.email;
      // nobj.username = data.username;
      // nobj.permited_location = JSON.stringify($scope.selectedArea);
      // if (data.volunteers && data.volunteers[0].permited_location) {

      //   nobj.volunteers = JSON.stringify({
      //     vtype: data.volunteers[0].vtype,
      //     permited_location: JSON.stringify($scope.selectedArea)
      //   });
      // }
      // MyService.ConsoleLog("ndata<><><><>",ndata);

      MyService.UpdateUser({
        "id": userId,
        "alldata": ndata
      }).then(function (payload) {
        MyService.ConsoleLog(payload);
        $scope.UserFormDiv = false;
        $scope.users.data.length = 0;
        $scope.userDetailKeyword = "";
        $scope.orgDetailKeyword = "";
        $scope.getResultsPage(1);
        alert("User updated successfully.");
      }, function (error) {
        alert(JSON.stringify(error));
      });

    }

    $scope.removeUser = function (mydata, idx) {
      var message = 'Remove this user? This action cannot be undone.';
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">OK</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Cancel</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeUser({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.getResultsPage(1);
          alert("User deleted successfully.");
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    $scope.changeStatus = function (mydata, status) {
      //alert(status);
      MyService.updateUserStatus({
        "userid": mydata._id,
        "status": status
      }).then(function (payload) {
        //MyService.ConsoleLog(payload);
        //$scope.UserFormDiv = false;
        $scope.getResultsPage(1);
        alert("User status updated successfully.");
      }, function (error) {
        alert(JSON.stringify(error));
      });
    }

    $scope.filterUsers = function (selVal) {
      $scope.filterUser = selVal;
      //MyService.ConsoleLog("filterUsers: ", selVal);
      $scope.initVar();
      $scope.getResultsPage(1);
    };


    $scope.myPagingFunction = function () {
      MyService.ConsoleLog("scoll called");
      if ($scope.statusGetResPg != 1) {
        $scope.page++;
        $scope.pageChanged($scope.page);
      }
    };

    $scope.orgDetailKeyword = '';
    $scope.userDetailKeyword = '';
    $scope.userDetailTextChange = function () {
      //MyService.ConsoleLog("1", $scope.userDetailKeyword);
      $scope.orgDetailKeyword = '';
    };
    $scope.orgDetailTextChange = function () {
      //MyService.ConsoleLog("2", $scope.orgDetailKeyword);
      $scope.userDetailKeyword = '';
    };

    $scope.searchPeople = function () {
      if (!$filter("isBlankString")($scope.orgDetailKeyword) || !$filter("isBlankString")($scope.userDetailKeyword) || !$filter("isBlankString")($scope.userDetailFilter)) {
        $scope.page = 0;
        $scope.users.data = [];
        $scope.myPagingFunction();
      }
    };

    /**
     * Show melisa Data
     */
    $scope.showMelisa = function (u) {
      MyService.ConsoleLog("u", u);
      $location.path('/showMelisaData/' + u);

    };

    /**
     * Make account invisible from the site
     */
    $scope.makeInvisible = function (u, type) {
      MyService.ConsoleLog("u", u);
      MyService.makeInvisible({
        uData: JSON.stringify(u),
        type: type
      }).then(function (results) {
        MyService.ConsoleLog("Results", results);
        growl.success(results);
        $scope.searchPeople();
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    $scope.updateAddress = function (id) {
      $scope.UpdateAddressLoader = true;
      MyService.getProfile(id).then(function (data) {
        // body...
        var address_data = data.address;
        address_data.zipcode = data.zipcode;
        //MyService.ConsoleLog("********* Data: ", data);
        ngDialog.open({
          template: 'app/popups/user_localupdate.html',
          data: data.address
          // className: 'ngdialog-theme-default'
        });
        $scope.UpdateAddressLoader = false;
      }, function (err) {
        //MyService.ConsoleLog("********* err: ", err);
        $scope.UpdateAddressLoader = false;
      });
    };

    if ($rootScope.showAllUsers) {
      MyService.ConsoleLog("inside");
      $scope.newuser = {
        type: "forumadmin",
        name: '',
        email: '',
        username: '',
        password: '',
      };
      $('#AllUsersform').validationEngine();
      $scope.getResultsPage(1);
      $scope.getAllStates();
    }

    /**
     * @description Function is use to export users and politicians records
     */
    $scope.exportBoth = function () {
      if (!$filter("isBlankString")($scope.sel.state)) {
        MyService.ConsoleLog(" $scope.sel ", $scope.sel.state);
        var obj = {};
        obj.statePostalCode = $scope.sel.state.statePostalCode;
        MyService.exportBoth(obj).then(function (APIResponse) {
          MyService.ConsoleLog("APIResponse", APIResponse);
          $window.open($scope.imgUrl + '/politician.xlsx', '_blank');
        }, function (err) {
          MyService.ConsoleLog("err", err);
          growl.error(err);
        });
      } else {
        growl.error('Please select state first');
      }
    };

  }])
