'use strict';

angular.module('myApp.uploadFollowers', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/uploadFollowers/:qName?', {
      templateUrl: 'app/uploadFollowers/uploadFollowers.html',
      controller: 'uploadFollowersCtrl'
    });
  }]).controller('uploadFollowersCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', 'baseApiUrl', '$q', '$timeout', 'growl', 'tokenService', '$anchorScroll', '$sce', 'MyService', 'ngDialog', '$cookies', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, baseApiUrl, $q, $timeout, growl, tokenService, $anchorScroll, $sce, MyService, ngDialog, $cookies) {
    $scope.quizName = $routeParams.qName;
    MyService.ConsoleLog("$scope.quizName", $scope.quizName);

    $cookies.remove('isShows');

    /**
     * @Description upload csv file to the server
     * @params data
     * uploadFollowers
     */
    $scope.errorFile = "";
    $scope.uploadFollowers = function (data) {
      MyService.ConsoleLog("data", data);
      $scope.PoliticianButton = true;
      if (data && data.files != "") {
        if (data.files.type.indexOf("office") > -1) {
          MyService.ConsoleLog("data : ", data);
          var quizLink = window.location.origin + '/#!/followersQuiz/' + $scope.quizName + '/' + tokenService.getUserId();
          var fd = new FormData();
          fd.append('file', data.files);
          fd.append('orgId', tokenService.getUserId());
          fd.append('quizName', $scope.quizName);
          fd.append('quizLink', quizLink);

          var posturl = baseApiUrl + "/office/uploadFollowersList";
          $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            })
            .then(function (response, status, headers, config) {
              MyService.ConsoleLog("response : ", response);
              $scope.PoliticianButton = false;
              $scope.upload.files = "";
              $scope.upload.opt = "";
              if (response.data.success) {

                MyService.ConsoleLog("data", response);
                $scope.errorFile = response.data.errorfile;
                $scope.PoliticianButton = false;

                growl.success('File uploaded successfully');
                $cookies.put('isShows', true);
                $location.path('/allQuizzes');
              } else {
                // alert("Something went wrong.");
                growl.error(response.data.error);
              }
            }, function (data, status, headers, config) {
              $scope.PoliticianButton = false;
              //MyService.ConsoleLog("error");
            });
        } else {
          $scope.PoliticianButton = false;
          // alert("Please select xlsx file");
          growl.error("Please select xlsx file");
        }
      } else {
        $scope.PoliticianButton = false;

        // alert("Please select file");
        growl.error("Please select file");
      }
    };
  }]);
