"use strict";

angular
  .module("myApp.addEssayAnswers", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/addEssayAnswers", {
        templateUrl: "app/addEssayAnswers/addEssayAnswers.html",
        controller: "addEssayAnswersCtrl",
      });
    },
  ])

  .controller("addEssayAnswersCtrl", [
    "$scope",
    "apiCall",
    "MyService",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$rootScope",
    "ngDialog",
    "$q",
    "$http",
    "$filter",
    "growl",
    "$routeParams",
    "baseApiUrl",
    "tokenService",
    "$cookies",
    function (
      $scope,
      apiCall,
      MyService,
      $location,
      $anchorScroll,
      $timeout,
      $rootScope,
      ngDialog,
      $q,
      $http,
      $filter,
      growl,
      $routeParams,
      baseApiUrl,
      tokenService,
      $cookies
    ) {
      MyService.ConsoleLog("this is new");
      $scope.currentIndex = 0;

      $scope.quiz = {
        eDate: "",
      };

      $scope.quizAns = {
        shortAns: "",
        mediAns: "",
        longAns: "",
      };

      $scope.isUser = {
        selected: "",
      };

      /**
       * @description Function is use to get the essay questions
       */

      $scope.getEssayQuestions = function () {
        MyService.getFiveMinEssayQues().then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            $scope.questions = APIResponse;
          },
          function (err) {
            MyService.ConsoleLog("err >> ", err);
          }
        );
      };

      $scope.getCandidateListing = function () {
        MyService.ConsoleLog("$scope.quiz >> ", $scope.quiz);

        MyService.getCandidateListing($scope.quiz.eDate).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            $scope.candidates = APIResponse.data.data;
            $scope.getEssayQuestions();
          },
          function (err) {
            MyService.ConsoleLog("get candi >> ", err);
          }
        );
        //
      };

      $scope.getElectionDates = function () {
        MyService.getListOfDates().then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            if (APIResponse.data.success) {
              var datesArr = [];
              var mainArr = APIResponse.data.data.map(function (item, index) {
                var date = new Date(item).toGMTString();

                var par = {
                  _id: index,
                  eDate: date.substr(5, 12),
                  date: item,
                };
                datesArr.push(par);
              });
              MyService.ConsoleLog("datesArr >> ", datesArr[0].eDate);
              $scope.electionDates = datesArr;

              $scope.electionDates.sort(function (a, b) {
                if (a != null && b != null) {
                  return new Date(b.date) - new Date(a.date);
                }
              });
              $scope.quiz.eDate = $scope.electionDates[0];
              $scope.getCandidateListing();
              MyService.ConsoleLog("$scope.quiz >> ", $scope.quiz);
              MyService.ConsoleLog(
                "$scope.electionDates >> ",
                $scope.electionDates
              );
            }
          },
          function (err) {
            MyService.ConsoleLog("error", err);
          }
        );
      };
      $scope.getElectionDates();

      $scope.getAnswerQuestion = function () {
        MyService.ConsoleLog(" $scope.isUser > ", $scope.isUser.selected);
        MyService.getEssayAns({ userId: $scope.isUser.selected._id }).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse ", APIResponse);
            if (APIResponse && APIResponse.data.success) {
              $scope.ansQue = APIResponse.data.data;
              $scope.selAns = $scope.ansQue.filter(function (ans) {
                if (ans.question == $scope.questions[$scope.currentIndex]._id) {
                  $scope.quizAns = {
                    shortAns: ans.shortAns ? ans.shortAns : "",
                    mediAns: ans.mediumAns ? ans.mediumAns : "",
                    longAns: ans.longAns ? ans.longAns : "",
                  };
                  return ans;
                }
              });
            }
          }
        );
      };

      //  prevQuestions
      $scope.prevQuestion = function () {
        MyService.ConsoleLog("** prevQuestion **");

        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category =
              $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      $scope.skipQuestion = function () {
        MyService.ConsoleLog("* skipQuestion *");
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            $scope.selAns = $scope.ansQue.filter(function (ans) {
              MyService.ConsoleLog("ans ", ans);
              if (ans.question == $scope.questions[$scope.currentIndex]._id) {
                $scope.quizAns = {
                  shortAns: ans.shortAns ? ans.shortAns : "",
                  mediAns: ans.mediumAns ? ans.mediumAns : "",
                  longAns: ans.longAns ? ans.longAns : "",
                };
                return ans;
              }
            });
          } else {
            MyService.ConsoleLog(
              "Congrats answer all the questions of candidate"
            );
          }
        };

        skip();
      };

      $scope.newQuestion = function () {
        var tempIndex = $scope.currentIndex;
        $scope.quizAns = {
          shortAns: "",
          mediAns: "",
          longAns: "",
        };

        if (tempIndex < $scope.questions.length) {
          $scope.quizAns = {
            shortAns: "",
            mediAns: "",
            longAns: "",
          };

          // if (window.localStorage.getItem("fiveMinMatch")) {
          //   $scope.getFiveMinData = [];
          //   $scope.getFiveMinData = JSON.parse(
          //     window.localStorage.getItem("fiveMinMatch")
          //   );
          //   MyService.ConsoleLog("Data : ", $scope.getFiveMinData);
          //   for (var i = 0; i < $scope.getFiveMinData.length; i++) {
          //     // MyService.ConsoleLog("for i : " + i + " currentIndex : " + $scope.currentIndex);
          //     if (i == $scope.currentIndex) {
          //       // MyService.ConsoleLog("index matched", $scope.getFiveMinData[i]);
          //       $scope.answer = $scope.getFiveMinData[i].answer;
          //       $scope.importance = $scope.getFiveMinData[i].importance;
          //       $scope.updata.comment = $scope.getFiveMinData[i].comment;
          //       // MyService.ConsoleLog("answer : " + $scope.answer);
          //       // MyService.ConsoleLog("importance : " + $scope.importance);
          //       // MyService.ConsoleLog("comment : " + $scope.comment);
          //       $timeout(function () {
          //         $scope.$apply();
          //       });
          //     }
          //   }
          // } else {
          //   console.log("Else is called");
          //   $scope.answer = "";
          //   $scope.importance = "";
          //   $scope.updata.comment = "";
          //   $scope.quizAns = {
          //     shortAns: "",
          //     mediAns: "",
          //     longAns: "",
          //   };
          //   MyService.ConsoleLog("Myans", $scope.getFiveMinData);

          //   // $scope.currentIndex++;
          // }
          //$scope.currentIndex++;
        }
      };

      $scope.submitAnswer = function () {
        if ($scope.isUser.selected && $scope.isUser.selected != "") {
          if (
            $scope.quizAns.shortAns == "" &&
            $scope.quizAns.mediAns == "" &&
            $scope.quizAns.longAns == ""
          ) {
            var message = "You need to write at least one answer";
            var modal = ngDialog.openConfirm({
              template:
                "<p>" +
                message +
                '</p>\
                       <div class="ngdialog-buttons">\
                           <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="closeThisDialog(0)">OK</button>\
                       </div>',
              plain: true,
            });
            modal.then(
              function fullfilled(data) {
                //MyService.ConsoleLog("fullfilled: ", data);
              },
              function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              }
            );
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            return;
          } // End of question checking
          var quizParams = {
            shortAns: $scope.quizAns.shortAns,
            mediAns: $scope.quizAns.mediAns,
            longAns: $scope.quizAns.longAns,
          };
          if (
            tokenService &&
            tokenService.getUserRole() == "admin" &&
            $scope.isUser &&
            $scope.isUser.selected &&
            $scope.isUser.selected != ""
          ) {
            if ($scope.ansQue && $scope.ansQue.length > 0) {
              MyService.ConsoleLog(" $scope.selAns  >> ", $scope.selAns[0]);
              quizParams.id = $scope.selAns[0]._id;
              quizParams.frontRunnerId = $scope.isUser.selected._id;
            } else {
              quizParams.frontRunnerId = $scope.isUser.selected._id;
            }
          }

          quizParams.questionId = $scope.questions[$scope.currentIndex]._id;

          MyService.ConsoleLog("quizParams >> ", quizParams);

          MyService.storeEssayAnswer(quizParams).then(
            function (APIResponse) {
              MyService.ConsoleLog("APIResponse >> ", APIResponse);
              if (APIResponse.data.success) {
                $scope.questions[$scope.currentIndex]["my_answer"] = {
                  _id: APIResponse.data.data._id,
                  created: new Date(),
                  shortAns: APIResponse.data.data.shortAns,
                  mediAns: APIResponse.data.data.mediumAns,
                  longAns: APIResponse.data.data.longAns,
                  questionId: APIResponse.data.data.question,
                };
              }

              var tempIndex = $scope.currentIndex + 1;
              if (tempIndex < $scope.questions.length) {
                MyService.ConsoleLog(" else tempIndex >> ", tempIndex);
                $scope.currentIndex = $scope.currentIndex + 1;
              } else {
                $scope.isUser.selected = "";
                $scope.currentIndex = 0;
                growl.success("Congrats you have answer all the questions");
              }
              $scope.newQuestion();
            },
            function (err) {
              MyService.ConsoleLog("Error >> ", err);
            }
          );
        } else {
          growl.warning("Please select candidate first");
        }
      };
    },
  ]);
