'use strict';
angular.module('myApp.politician_compare', ['ngRoute', 'ngDialog'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/politician_compare/:categoryId?/:parentId?', {
      templateUrl: 'app/politician_compare/politician_compare.html',
      controller: 'politician_compareCtrl'
    });
  }])

  .controller('politician_compareCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', 'tokenService', '$anchorScroll', '$timeout', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, tokenService, $anchorScroll, $timeout) {
    MyService.ConsoleLog("** politician-compare ** :", $routeParams);
    // alert("Here");
    $rootScope.welcome = false;
    $scope.FBCompareList = [];
    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;
    $scope.showAlert = false;

    $scope.checkMyOrder = function (frnd) {
      //MyService.ConsoleLog($scope.ord.Option);
      switch ($scope.ord.Option) {
        case "BW":
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "W":
          return ($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "BS":
          return -($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        case "S":
          return ($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        default:
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
      }
    }
    if (window.localStorage.getItem('fiveQueIds')) {
      $scope.FBfreinds = $rootScope.selectedCompareUsers ? $rootScope.selectedCompareUsers : JSON.parse(window.localStorage.getItem("selectedCompareUsers"));
      window.localStorage.removeItem('selectedCompareUsers');
      window.localStorage.setItem('selectedCompareUsers', JSON.stringify($scope.FBfreinds));
    } else {
      $scope.FBfreinds = JSON.parse(window.localStorage.getItem("selectedCompareUsers"));

    }


    $scope.category = JSON.parse(window.localStorage.getItem("DisplayCategory"));


    $scope.nextCat = function () {

      $location.path("compare-subject/politician");
      // $scope.rootCatIndex = window.localStorage.getItem("RootCatIndex");
      // MyService.ConsoleLog($scope.rootCatIndex);
      // if ($scope.rootCatIndex < ($rootScope.categories.length - 1)) {
      //     $location.path("/question-compare/all/" + $rootScope.categories[$scope.rootCatIndex]._id);
      // }

    }

    $scope.isObjectEmpty = function (card) {
      return Object.keys(card).length === 0;
    }

    $scope.ComapreWithSelected = function () {
      //MyService.ConsoleLog($scope.selection);
      MyService.ConsoleLog("ComapreWithSelected", $rootScope.selectedCompareUsers);
      //MyService.ConsoleLog($rootScope.OldCatforFB._id);
      //MyService.ConsoleLog(window.localStorage.getItem("selectedCompareUsers"));
      if (window.localStorage.getItem("selectedCompareUsers")) {

        var frdlistA = $scope.FBfreinds.map(function (sub) {
          return sub._id;
        });
        var frdlist = JSON.stringify(frdlistA);
        MyService.ConsoleLog(" frdlist :> ", frdlist);

        //MyService.ConsoleLog($routeParams.categoryId);
        var params;
        if (window.localStorage.getItem('fiveQueIds')) {
          params = {
            "frds": frdlist,
            "questionIds": window.localStorage.getItem('fiveQueIds')
          }
        } else {

          params = {
            "frds": frdlist,
            "categoryIds": $routeParams.categoryId
          }
        }
        // alert('compare');
        MyService.CompareWithPolitcians(params).then(function (payload) {
          //MyService.ConsoleLog(payload);

          $scope.FBCompareList.length = 0;
          if (payload.data.success) {
            MyService.ConsoleLog("compare data :", payload.data.data);
            MyService.ConsoleLog("answeredQuestions data :", payload.data.data.answeredQuestions);
            MyService.ConsoleLog("answeredQuestions len\ :", $scope.isObjectEmpty(payload.data.data.answeredQuestions));

            $scope.myObject = payload.data.data.answeredQuestions;

            if ($scope.isObjectEmpty(payload.data.data.answeredQuestions)) {

              MyService.ConsoleLog(" Welcome to if");

              $scope.showAlert = true;

              // setTimeout(function() {

              //   $scope.showAlert = true;


              // }, 3000);

            }
            $scope.FBCompareList = payload.data.data.qdata;
            if ($scope.FBCompareList.length > 0) {
              // ngDialog.open({
              //     template: 'FBFreindCompare',
              //     scope: $scope
              // });
              $scope.FBunWeighted = payload.data.data.unweightedMatch;
              $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
              $scope.fbmultiplier = payload.data.data.fbmultiplier;
              $scope.answeredQuestions = payload.data.data.answeredQuestions;
              $scope.fbqlen = payload.data.data.qlen;
            } else {
              alert("Looks like your freinds not yet answered the questions.");
              //MyService.ConsoleLog($scope.FBCompareList);
            }
          } else {
            alert("Something went wrong.");
          }
        }, function (errorpayload) {
          alert("Something went wrong.");
        })
      } else {
        alert("Please select at least one Friend to compare with");
      }
    }
    $scope.ComapreWithSelected();
  }]);
