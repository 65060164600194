'use strict';

angular.module('myApp.contactUs', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
     $routeProvider.when('/contactUs', {
         templateUrl: 'app/contactUs/contactUs.html',
         reloadOnSearch: false,
         controller: 'contactUsCtrl' 
     });
}])
.controller('contactUsCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
