'use strict';

angular.module('myApp.showListOfMatch', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showListOfMatch/:type?', {
      templateUrl: 'app/showListOfMatch/showListOfMatch.html',
      controller: 'showListOfMatchCtrl'
    });
  }]).controller('showListOfMatchCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    /**
     * @description Get Paramters using $routeParams.
     */
    $scope.uType = $routeParams.type;
    $scope.loader = false;
    MyService.ConsoleLog("$routeParams", $scope.uType);

    if ($cookies.get('QuizName')) {
      console.log("$cookies.get('QuizName')", $cookies.get('QuizName'));
      $scope.quizTitle = $cookies.get('QuizName');
    }

    /**
     * @description Get List of Individual Match
     */
    $scope.getIndividualMatchList = function (para) {
      $scope.loader = true;
      MyService.ConsoleLog("params", para);
      para.usersIds = JSON.stringify(para.usersIds);

      para.type = $scope.uType;

      MyService.getIndividualMatchList(para).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.loader = false;
        $scope.questions = response.data.data.qdata;
        $scope.FBCompareList = response.data.data.qdata;

        if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
          $scope.FBunWeighted = response.data.data.unweightedMatch;
          $scope.fbcombinedWeight = response.data.data.fbcombinedWeight;
          $scope.fbmultiplier = response.data.data.fbmultiplier;
          $scope.answeredQuestions = response.data.data.answeredQuestions;
          $scope.fbqlen = response.data.data.qlen;
          $scope.AllUsers = response.data.data.AllUsers;
          $scope.orgNames = response.data.data.orgNames;
          //$scope.qData = response.data.data.answeredQuestions;
        }

      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });

    };

    /**
     * @description Get Data from local storage and pass to the function.
     */

    if (window.localStorage.getItem('comData')) {

      $scope.parData = JSON.parse(window.localStorage.getItem('comData'));
      MyService.ConsoleLog("<<<<<<<<<<<< parData >>>>>>>>>>>", JSON.parse($scope.parData.questions));
      $scope.getIndividualMatchList($scope.parData);
    }





  }]);
