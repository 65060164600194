'use strict';

angular.module('myApp.compareGuest', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compareGuestUsers/:type?', {
      templateUrl: 'app/compareGuestUsers/compareGuestUsers.html',
      controller: 'compareGuestUsersCtrl'
    });
  }]).controller('compareGuestUsersCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService) {


    $scope.noDataFound = false;
    $scope.FBCompareList = [];


    if (tokenService.getUserId()) {
      $scope.userId = tokenService.getUserId();
      console.log('$scope.userId: ', $scope.userId);
    }

    var data = window.localStorage.getItem('Data');
    // MyService.ConsoleLog("Data >>", data);
    try {
      $scope.AllData = JSON.parse(data);
    } catch (error) {
      MyService.ConsoleLog("Error >>", error);

    }

    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData);
    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData.AllUsers);
    // MyService.ConsoleLog("$scope.AllData  >>", $scope.AllData.AllUsers.length);
    var Arr = [];
    var len = $scope.AllData.AllUsers.length;



    MyService.ConsoleLog("Arr. >>", Arr);

    $scope.FBunWeighted = $scope.AllData.unweightedMatch;
    $scope.fbcombinedWeight = $scope.AllData.fbcombinedWeight;
    // var ids = $scope.AllData.AllUsers.map(function(item) {
    //   return item._id;
    // })


    // var val = [];
    // for (var i = 0; i < len; i++) {
    //   if ($scope.AllData.fbmultiplier[ids[i]] && $scope.AllData.fbmultiplier[ids[i]] != "undefined") {
    //     val.push($scope.AllData.fbmultiplier[ids[i]]);

    //   }


    // }
    // MyService.ConsoleLog("val >", val);

    // var add = Math.max.apply(Math, val);
    // MyService.ConsoleLog("add >", add);


    $scope.fbmultiplier = $scope.AllData.fbmultiplier;
    $scope.answeredQuestions = $scope.AllData.answeredQuestions;
    $scope.fbqlen = $scope.AllData.qdata.length;
    $scope.unVerifiedFBunWeighted = $scope.AllData.finalUnFacbook;
    $scope.unVerifiedWeighated = $scope.AllData.finalunFbcombinedWeight;
    // var propValue = [];
    // for (var propName in $scope.AllData.unFbmultiplier) {
    //   if ($scope.AllData.unFbmultiplier.hasOwnProperty(propName)) {
    //     propValue.push($scope.AllData.unFbmultiplier[propName]);

    //   }
    // }

    // // MyService.ConsoleLog("$scope.unFbmultiplier >", $scope.AllData.unFbmultiplier);
    // // MyService.ConsoleLog("$scope.unFbmultiplier >", Object.keys($scope.AllData.unFbmultiplier).length);
    // // MyService.ConsoleLog("$scope.unFbmultiplier >", propValue.length);

    // var unFbMul = Math.max.apply(Math, propValue);

    // MyService.ConsoleLog("unFbMul >", unFbMul);

    $scope.unFbmultiplier = $scope.AllData.unFbmultiplier;

    // if (Arr.length == 0) {
    //   $scope.noDataFound = false;

    //   $scope.AllUsers = $scope.AllData.AllUsers;
    //   MyService.ConsoleLog("$scope.AllUsers >>", $scope.AllUsers);
    // } else {
    $scope.AllUsers = $scope.AllData.AllUsers;
    // }

    $scope.finalSignIn = function () {

      $location.path('/fiveSaveUser');
    }

  }]);
