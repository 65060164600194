'use strict';
angular.module('myApp.showMessage', ['ngRoute', 'angularPayments'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showMessage', {
      templateUrl: 'app/showMessage/showMessage.html',
      controller: 'showMessageCtrl'
    });
  }])
  .controller('showMessageCtrl', ['$scope', '$rootScope', 'growl', 'ngDialog', 'tokenService', 'MyService', '$location', '$window', '$filter', '$http', function ($scope, $rootScope, growl, ngDialog, tokenService, MyService, $location, $window, $filter, $http) {

  }]);
