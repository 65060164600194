'use strict';
angular.module('myApp.calendar', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
        $routeProvider.when('/calendar', {
            templateUrl: 'app/calendar/calendar.html',
            controller: 'calendarCtrl' //we can name anythin
        });
    }])
.config(['$routeProvider', function($routeProvider) {
        $routeProvider.when('/calendar-event', { //pass user_id = me to see questions tagged to self
            templateUrl: 'app/calendar/calendar-event.html',
            controller: 'calendarEventCtrl'
        });
    }])
    .controller('calendarCtrl', ['$scope', '$rootScope','MyService', '$location', function($scope, $rootScope, MyService, $location) {
        //this is controller for sampepage.html, we link the controller to page in config definition, check above .config
        $scope.CalendarList = [];
        $rootScope.welcome = false;
        $scope.getCalendarList = function() {
        	//MyService.ConsoleLog("getCalendarList called");
        	MyService.getCalendarList().then(function(payload) {
        		//MyService.ConsoleLog(payload);
        		$scope.CalendarList = payload;
        	}, function(errorpayload) {
        		//MyService.ConsoleLog("error");
        		//MyService.ConsoleLog(errorpayload);
        	});
        }

        $scope.getCalendarList();


        $scope.calendarEvent = function(id) {
        	window.localStorage.setItem("calendarId", id);
        	$location.path('/calendar-event');
        }

    }])
    .controller('calendarEventCtrl', ['$scope', '$rootScope', 'MyService', '$routeParams', function($scope, $rootScope, MyService, $routeParams) {
        //this is controller for sampepage.html, we link the controller to page in config definition, check above .config
        var calendarId = window.localStorage.getItem("calendarId");
        $rootScope.welcome = false;
        $scope.CalendarEventList = [];
        $scope.getCalendarEventList = function() {
        	//MyService.ConsoleLog("getCalendarList called");
        	MyService.getCalendarEventList({"calendarId": calendarId}).then(function(payload) {
        		//MyService.ConsoleLog(payload);
        		$scope.CalendarEventList = payload;
        	}, function(errorpayload) {
        		//MyService.ConsoleLog("error");
        		//MyService.ConsoleLog(errorpayload);
        	});
        }

        $scope.getCalendarEventList();
    }]);
