'use strict';
angular.module('myApp.ballotReviewDetails', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ballotReviewDetails/:id/:uid?/:type?', {
      templateUrl: 'app/ballotReviewDetails/ballotReviewDetails.html',
      controller: 'ballotReviewDetailsCtrl'
    });
  }])
  .controller('ballotReviewDetailsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

    /**
     * Get id using route params
     */
    $scope.bId = $routeParams.id;
    $scope.uId = $routeParams.uid;

    MyService.ConsoleLog("$scope.bId", $scope.bId);

    $scope.getReviewDetails = function () {
      MyService.getReviewsDetailsOf({
        bId: $scope.bId,
        uId: $scope.uId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        $scope.reviewDetails = response.data.data;
        $scope.arrLinks = $scope.reviewDetails.links.split(',');
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };
    $scope.getReviewDetails();
  }]);
