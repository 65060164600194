'use strict';

angular.module('myApp.allQuizzes', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/allQuizzes', {
      templateUrl: 'app/allQuizzes/allQuizzes.html',
      controller: 'allQuizzesCtrl'
    });
  }]).controller('allQuizzesCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', 'baseApiUrl', '$q', '$timeout', 'growl', 'tokenService', '$anchorScroll', '$sce', 'MyService', 'ngDialog', '$cookies', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, baseApiUrl, $q, $timeout, growl, tokenService, $anchorScroll, $sce, MyService, ngDialog, $cookies) {

    /**
     * Get Current user id
     */
    if (tokenService.getUserId()) {
      $scope.advId = tokenService.getUserId();
      MyService.ConsoleLog("$scope.advId", $scope.advId);
    }
    window.localStorage.removeItem('isOrg');
    // $scope.isShow = $routeParams.isShowPop;

    /**
     * Get quizzes records which is created by advocate.
     */

    $scope.getQuizzesList = function () {
      MyService.getQuizList({
        advId: $scope.advId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        if (response && response.length > 0) {
          $scope.quizzes = response;

        } else {
          growl.error("You haven't created any quiz yet");
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**End of getQuizzesList */

    $scope.getQuizzesList();

    if ($cookies.get('isShows') == 'true') {
      var message = 'Congratulations you have invited your followers';
      var modal = ngDialog.openConfirm({
        template: '<p>' +
          message +
          '</p>\
        <div class="ngdialog-buttons">\
            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">OK</button>\
        </div>',
        plain: true,
        closeByDocument: false,
      });
      modal.then(
        function fullfilled(data) {
          if (data == 1) {
            MyService.ConsoleLog("Yes ");
          }
        },
        function rejected(data) {
          MyService.ConsoleLog("rejected: ", data);
          // $scope.getMatchQUestions();
        }
      );
      $cookies.remove('isShows');
    }

    /**
     * Shows the confirmation pop up
     */
    $scope.showPopUp = function (quiz) {
      var message = 'Are you sure you want to delete this quiz';
      var modal = ngDialog.openConfirm({
        template: '<p>' +
          message +
          '</p>\
        <div class="ngdialog-buttons">\
            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">No</button>\
        </div>',
        plain: true,
        closeByDocument: false,
      });
      modal.then(
        function fullfilled(data) {
          if (data == 1) {
            MyService.ConsoleLog("Yes ");
            $scope.deleteQuiz(quiz);
          }
        },
        function rejected(data) {
          MyService.ConsoleLog("rejected: ", data);
          // $scope.getMatchQUestions();
        }
      );
    };
    $scope.questions = {
      quiz: ''
    }
    /**
     * @description Show Pop with text box to edit quiz name
     */

    $scope.editName = function (item) {
      $scope.questions.quiz = item.quizName;
      $scope.quizData = item;
      $rootScope.editModal = ngDialog.open({
        template: 'viewQuiz',
        scope: $scope,
      });
    };

    /**
     * @description Update quiz name
     */
    $scope.updateName = function () {
      $rootScope.editModal.close();
      MyService.ConsoleLog("Ssss", $scope.questions.quiz);
      MyService.ConsoleLog("$scope.quizData", $scope.quizData);

      MyService.updateQuizName({
        quizName: $scope.questions.quiz,
        orgId: $scope.advId,
        oldQuizName: $scope.quizData.quizName
      }).then(function (APIResponse) {
        MyService.ConsoleLog("this is", APIResponse);
        $scope.getQuizzesList();
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };


    /**
     * Delete selected quiz
     */
    $scope.deleteQuiz = function (val) {
      MyService.ConsoleLog("val", val);
      MyService.deleteQuiz(val).then(function (result) {
        MyService.ConsoleLog("Result", result);
        growl.success(result.data.data);
        $scope.getQuizzesList();
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    $scope.takeQuiz = function (val) {
      window.localStorage.setItem('isOrg', true);
      $location.path('/followersQuiz/' + val.quizName + '/' + val.orgId);
    };
  }]);
