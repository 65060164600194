'use strict';
angular.module('myApp.addRating', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/addRating', {
      templateUrl: 'app/addRating/addRating.html',
      controller: 'addRatingCtrl'
    });
    $routeProvider.when('/addRating/:id/:isEdit?/:voteSmartId?', {
      templateUrl: 'app/addRating/addRating.html',
      controller: 'addRatingCtrl'
    });
  }])
  .controller('addRatingCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl) {
    $scope.locationIntrest = 'politician';
    $scope.selectionType = '';
    $scope.selectedUser = {};
    $scope.selectedReferal = {};
    $scope.userId = tokenService.getUserId();
    $scope.userData = JSON.parse(tokenService.getUserData());
    $scope.politiicanId = $routeParams.id;
    $scope.isEdit = $routeParams.isEdit;
    $scope.voteSmartId = $routeParams.voteSmartId;
    MyService.ConsoleLog(" $scope.voteSmartId", $scope.voteSmartId);
    $scope.review = {
      toId: '',
      toName: '',
      fromId: $scope.userData.id,
      fromName: $scope.userData.name,
      referalId: '',
      referalName: '',
      grade: {
        number: 0,
        letter: '',
        like: 'true'
      },
      text: '',
      links: []
    };
    $scope.showLoader = false;

    $scope.$on('$viewContentLoaded', function () {
      //commented by Pankaj Patil to stop the autoload for politicians when called from incumbent page
      //$scope.getUsers('politician');
      if ($routeParams.id && $routeParams.id != "") {
        $scope.getUsersFromIds();
      }
      if (tokenService.getUserRole()) {
        MyService.ConsoleLog("tokenService.getUserRole()", tokenService.getUserRole());
      }
    });

    $scope.getUsers = function (type) {
      $scope.allUsers = [];
      $scope.selectedUser.value = '';
      $scope.selectedReferal.value = '';
      $scope.review.referalId = '';
      $scope.review.referalName = '';
      $scope.showLoader = true;
      $scope.review = {
        toId: '',
        toName: '',
        fromId: $scope.userData.id,
        fromName: $scope.userData.name,
        referalId: '',
        referalName: '',
        grade: {
          number: 0,
          letter: '',
          like: 'true'
        },
        text: '',
        links: []
      }
      $scope.selectionType = type;
      var request = apiCall.apiCall('GET', '/user/list', {
        usertype: type
      });
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          // MyService.ConsoleLog(response);
          angular.forEach(response.data.data, function (val, key) {
            var fkey;
            var _id = response.data.data[key]._id;
            if ($routeParams.id && response.data.data[key]._id == $routeParams.id) {
              var fkey = '';
              if (response.data.data[key].userType == 'politician') {
                var name, officeName, state;
                name = response.data.data[key].name;
                if (response.data.data[key].politicianInfo && response.data.data[key].politicianInfo.length > 0) {
                  officeName = response.data.data[key].politicianInfo[0].officeName;
                  state = response.data.data[key].politicianInfo[0].state;
                }

                if (state && state != '') {
                  fkey = name + ' ' + state + ' ' + officeName;
                } else {
                  fkey = name + ' ' + officeName;
                }
              } else {
                var name, url;
                name = response.data.data[key].name;
                url = window.location.origin + '/#!/publicProfile/' + response.data.data[key]._id;
                fkey = name + ' ' + url;
              }
              $scope.selectedUser.value = fkey;
              $scope.review.toId = response.data.data[key]._id;
              $scope.review.toName = response.data.data[key].name;
              $scope.showLoader = false;
              document
                .getElementById("auto-referal-suggest-box")
                .getElementsByTagName("input")[0]
                .blur();
            } else {
              if ($scope.selectionType == 'politician') {
                MyService.ConsoleLog("politician Info", response.data.data[key].politicianInfo);
                var name, officeName, state;
                name = response.data.data[key].name;
                if (response.data.data[key].politicianInfo && response.data.data[key].politicianInfo.length > 0) {
                  officeName = response.data.data[key].politicianInfo[0].officeName;
                  state = response.data.data[key].politicianInfo[0].state;
                }



                if (state && state != '') {
                  fkey = name + ' ' + state + ' ' + officeName;
                } else {
                  fkey = name + ' ' + officeName;
                }
              } else {
                var name, url;
                name = response.data.data[key].name;
                url = window.location.origin + '/#!/publicProfile/' + response.data.data[key]._id;
                fkey = name + ' ' + url;
              }
              response.data.data[key].fkey = fkey
              $scope.allUsers.push(response.data.data[key]);
              $scope.showLoader = false;
            }

          });
        } else {
          growl.error("Something went wrong.");
          $scope.showLoader = false;
        }
      }, function errorCallBack(response) {
        growl.error("Something went wrong.");
        $scope.showLoader = false;
      });
    }

    $scope.getUsersFromIds = function () {
      var userIds = [];
      userIds.push($routeParams.id);
      if (!$scope.isEdit) {
        MyService.getUsersFromIds({
          "userIds": JSON.stringify(userIds)
        }).then(function (response) {
          console.log("response : ", response);
          if (response.data.success) {
            console.log("Data : ", response.data.data);
            var rdata = response.data.data;
            $scope.selectedUser.value = rdata[0].name;
            $scope.review = {
              toId: $routeParams.id,
              toName: rdata[0].name,
              fromId: $scope.userData.id,
              fromName: $scope.userData.name,
              referalId: '',
              referalName: '',
              grade: {
                number: 0,
                letter: '',
                like: 'true'
              },
              text: '',
              links: []
            };
            console.log("review >>> ", $scope.review);
          }
        });
      } else {
        MyService.getRatingsFor({
          "rateId": $routeParams.id,
          "curUId": $scope.userId,
          "voteSmartId": $scope.voteSmartId
        }).then(function (APIResponse) {
            MyService.ConsoleLog("APIResponse", APIResponse);
            if (APIResponse.data.success) {
              console.log("Data : ", APIResponse.data.data);
              var rdata = APIResponse.data.data;

              if (!$scope.voteSmartId) {
                $scope.selectedUser.value = rdata.to[0].name;
                $scope.review = {
                  toId: rdata.to[0].uid,
                  toName: rdata.to[0].name,
                  fromId: rdata.from[0].uid,
                  fromName: rdata.from[0].name,
                  referalId: '',
                  referalName: '',
                  grade: {
                    number: rdata.number,
                    letter: '',
                    like: 'true'
                  },
                  text: rdata.review,
                  links: []
                };
              } else {
                $scope.selectedUser.value = rdata.poliName;
                $scope.selectedUserId = rdata.userId;
                $scope.review = {
                  grade: {
                    number: parseInt(rdata.candidateRating.rating),
                    letter: '',
                    like: 'true'
                  },
                  text: '',
                }
              }

            }
          },
          function (err) {
            MyService.ConsoleLog("Error", err);
          });
      }
    };

    function fuzzy_suggest(term) {
      if (!term) return [];
      var fuzzySearch = "";
      fuzzySearch = new Fuse($scope.allUsers, {
        shouldSort: true,
        keys: ["fkey"],
        caseSensitive: false,
        threshold: 0.4
      });
      // MyService.ConsoleLog(fuzzySearch);
      return fuzzySearch
        .search(term)
        .slice(0, 10)
        .map(function (i) {
          var val = i.fkey;
          // MyService.ConsoleLog("val:::", val);
          return {
            value: JSON.stringify(i),
            label: $sce.trustAsHtml(val)
          };
        });
    }

    function highlight(str, term) {
      var highlight_regex = new RegExp("(" + term + ")", "gi");
      return str.replace(
        highlight_regex,
        '<span class="highlight">$1</span>'
      );
    }

    $scope.closeEdit = function (col) {
      var nval = JSON.parse(col.value);
      // MyService.ConsoleLog(nval);
      // MyService.ConsoleLog('_id: ', nval._id);
      $scope.selectedUser.value = nval.fkey;
      $scope.review.toId = nval._id;
      $scope.review.toName = nval.name;
      document
        .getElementById("auto-user-suggest-box")
        .getElementsByTagName("input")[0]
        .blur();
    }

    $scope.user_options = {
      suggest: fuzzy_suggest,
      on_select: $scope.closeEdit
    };

    $scope.reviewYourself = function () {
      $scope.selectionType = 'yourself';
      // $scope.selectedUser.value = '';
      MyService.ConsoleLog("review : ", $scope.review);
      $scope.selectedReferal.value = '';
      /*
      $scope.review = {
        fromId: $scope.userData.id,
        fromName: $scope.userData.name,
        referalId: '',
        referalName: '',
        grade: {
          number: 0,
          letter: 'a',
          like: 'true'
        },
        text: '',
        links: []
      }
      */
    }

    function referal_suggest(term) {
      if (!term) return [];
      var fuzzySearch = "";
      fuzzySearch = new Fuse($scope.allUsers, {
        shouldSort: true,
        keys: ["fkey"],
        caseSensitive: false,
        threshold: 0.4
      });
      // MyService.ConsoleLog(fuzzySearch);
      return fuzzySearch
        .search(term)
        .slice(0, 10)
        .map(function (i) {
          var val = i.fkey;
          // MyService.ConsoleLog("val:::", val);
          return {
            value: JSON.stringify(i),
            label: $sce.trustAsHtml(val)
          };
        });
    }

    $scope.closeReferal = function (col) {
      var nval = JSON.parse(col.value);
      $scope.selectedReferal.value = nval.fkey;
      $scope.review.referalId = nval._id;
      $scope.review.referalName = nval.name;
      document
        .getElementById("auto-referal-suggest-box")
        .getElementsByTagName("input")[0]
        .blur();
    }

    $scope.referal_options = {
      suggest: referal_suggest,
      on_select: $scope.closeReferal
    };

    $scope.setLike = function (grade) {
      $scope.review.grade.like = grade;
    }

    $scope.submitReview = function () {
      var referalId = null;
      var referalName = "";

      if ($scope.review.referalName == "") {
        referalName = $scope.selectedReferal.value;
        referalId = referalId;
      } else {
        referalName = $scope.review.referalId;
        referalId = $scope.review.referalName;
      }

      var params = {
        toId: $scope.review.toId,
        toName: $scope.review.toName,
        fromId: $scope.review.fromId,
        fromName: $scope.review.fromName,
        referalId: referalId,
        referalName: referalName,
        number: $scope.review.grade.number,
        letter: $scope.review.grade.letter,
        like: $scope.review.grade.like,
        review: $scope.review.text,
        links: $scope.review.links

      };

      MyService.ConsoleLog("params : ", params);
      if (params.number && params.number != '') {
        if (!$scope.isEdit) {
          MyService.addReview(params).then(function (response) {

            if (response.data.success == true) {
              $scope.selectedUser.value = '';
              $scope.selectedReferal.value = '';
              $scope.review = {
                to: '',
                from: $scope.userId,
                referal: '',
                grade: {
                  number: 0,
                  letter: '',
                  like: 'true'
                },
                text: '',
                links: ''
              }
              MyService.ConsoleLog("success : ", response.data.data);
              $location.path('/allRatings/' + params.toId + '/showmsg');
            }
          }, function (err) {
            // MyService.ConsoleLog('err: ', err);
          });
        } else {
          MyService.updateRating({
            "rateId": $routeParams.id,
            "curUId": $scope.userId,
            "voteSmartId": $scope.voteSmartId,
            "data": params
          }).then(function (APIResponse) {
            MyService.ConsoleLog("APIResponse", APIResponse);
            if (!$scope.voteSmartId)
              $location.path('/allRatings/' + params.toId + '/showmsg');
            else
              $location.path('/allRatings/' + $scope.selectedUserId + '/showmsg');

          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        }
      } else {
        growl.error("Please fill the ratings");
      }




    };
    $(document).ready(function () {
      console.log('onready');
      $('#myform').validationEngine();

    });
  }]);
