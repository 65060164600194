"use strict";
angular
  .module("myApp.uploadBarAssociations", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/uploadBarAssociations", {
        templateUrl: "app/uploadBarAssociations/uploadBarAssociations.html",
        controller: "uploadBarAssociationsCtrl",
      });
    },
  ])
  .controller("uploadBarAssociationsCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "$anchorScroll",
    "$cookies",
    "ngDialog",
    "growl",
    "$window",
    "baseApiUrl",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      $anchorScroll,
      $cookies,
      ngDialog,
      growl,
      $window,
      baseApiUrl
    ) {
      MyService.ConsoleLog("bar Associations");

      $scope.showLoader = false;
      $scope.errorFile = "";

      $scope.uploadBarAssociations = function (data) {
        MyService.ConsoleLog("data >> ", data);

        $scope.showLoader = true;
        if (data && data.files != "") {
          //MyService.ConsoleLog(data.files.type.indexOf("office"));
          if (data.files.type.indexOf("office") > -1) {
            //MyService.ConsoleLog("data : ", data);
            var fd = new FormData();
            fd.append("file", data.files);
            // fd.append('options', data.opt);
            // fd.append('type', data.mtype);
            // fd.append('etype', data.etype);
            // if (data.mtype == "WIN") {
            //   fd.append('state', data.mstate);
            // }
            var posturl = baseApiUrl + "/user/uploadBarAssociations";
            $http
              .post(posturl, fd, {
                transformRequest: angular.identity,
                headers: {
                  "Content-Type": undefined,
                },
              })
              .then(
                function (response, status, headers, config) {
                  //MyService.ConsoleLog("response : ", response);

                  $scope.upload.files = "";

                  if (response.data.success) {
                    //$location.path('/learnHome');
                    $scope.showLoader = false;

                    $scope.errorFile = response.data.errorfile;

                    //$scope.getAllPolitician();
                    alert("File successfully imported.");
                  } else {
                    $scope.showLoader = false;

                    alert("Something went wrong.");
                  }
                },
                function (data, status, headers, config) {
                  $scope.showLoader = false;

                  //MyService.ConsoleLog("error");
                }
              );
          } else {
            alert("Please select xlsx file");
          }
        } else {
          alert("Please select file");
        }
      };
    },
  ]);
