'use strict';

angular.module('myApp.showHideAns', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showHideAns', {
      templateUrl: 'app/showHideAns/showHideAns.html',
      controller: 'showHideAnsCtrl'
    });
  }])

  .controller('showHideAnsCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'tokenService', 'baseApiUrl', '$document', "growl", '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, tokenService, baseApiUrl, $document, growl, $cookies) {

    $scope.oldQuestions = [];
    $scope.allQuestions = [];

    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;

    if (window.localStorage.getItem("fiveMatch")) {
      $scope.questions = JSON.parse(window.localStorage.getItem("fiveMatch"));
      $scope.queIds = $scope.questions.map(function (itm) {
        if (itm.questionId) {
          return itm.questionId;
        } else {
          // console.log("itm", itm);
          return itm._id;
        }
      });
      MyService.ConsoleLog("  $scope.queIds", $scope.queIds);

    }

    $scope.AllAnsweredQuestions = [];
    $scope.getAllAnsweredQuestions = function (userId) {
      MyService.getAllAnsQuesWithIds({
        "authorId": tokenService.getUserId(),
        "ques": JSON.stringify($scope.queIds)
      }).then(function (payload) {
        MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload);
        if (payload.data.success) {
          $scope.AllAnsweredQuestions = payload.data.data;
          $scope.allQuestions = $scope.AllAnsweredQuestions;
          if ($scope.allQuestions.length == 0) {
            growl.error("There are no questions to display. Please go through all questions first.")
          }

        }
      }, function (errpayload) {
        MyService.ConsoleLog("getAllAnsweredQuestions errpayload >> ", errpayload);
      });
    };

    $scope.getAllAnsweredQuestions();

    /**
     * Get switch class
     */
    $scope.getSwitchClass = function (swtSts, inx) {
      MyService.ConsoleLog("swtSts", swtSts);
      MyService.ConsoleLog("inx", inx);
    };


    /**
     * Update the show status
     */
    $scope.showloader = false;
    $scope.isred = "red";
    $scope.isblue = "color:green";

    $scope.switchFilters = function (newValue, index, quest) {
      $scope.showloader = true;


      //MyService.ConsoleLog("oldValue::", $scope.switchStatus);
      var setVal = "N";
      if (newValue[index] == true) {
        setVal = "Y";
      }

      var idx = $scope.allQuestions.indexOf(quest);
      //MyService.ConsoleLog("setVal:", setVal);
      MyService.updateAnswers({
        "qid": quest._id,
        "show": setVal,
        "author": quest.author
      }).then(function (payload) {
        //MyService.ConsoleLog("payload : ", payload);
        $scope.showloader = false;
        if (payload.data.success) {
          quest.my_answer.show = setVal;
          $scope.allQuestions[idx] = quest;
          growl.success("Record updated successfully");
        } else {
          growl.error("Something went wrong. Contact administrator.");
        }
      }, function (errorpayload) {
        $scope.showloader = false;
        //MyService.ConsoleLog("errorpayload : ", errorpayload);
        growl.error("Something went wrong. Contact administrator.");
      });
    };

    /**
     * Next
     */

    $scope.next = function () {
      $location.path('/postOnFaceBook/'+window.localStorage.getItem('fbName'));
    };

    /**
     * Cancel
     */

    $scope.cancel = function () {
      $location.path('/showFbMessage');
    };

  }]);
