'use strict';
angular.module('myApp.votingRecords', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/votingRecords/:id/:userid/:type?', {
      templateUrl: 'app/votingRecords/votingRecords.html',
      controller: 'votingRecordsCtrl'
    });
  }])
  .controller('votingRecordsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {
      
    $scope.memberId = $routeParams.id;
    MyService.ConsoleLog("memberId : " + $scope.memberId);
    $scope.selectedIndex;
      
      
      
    $scope.getVotingRecords = function() {
      var data = {};
      data.id = $scope.memberId;
      $scope.votingRecords = [];
      MyService.getVotingRecords(data).then(function(data) {
        MyService.ConsoleLog("getVotingRecords success : ", data);
        //$scope.memberName = data.data.data.results[0].name;
        $scope.votingRecords = data.data.data.results[0];
      }, function(err) {
        MyService.ConsoleLog("getVotingRecords error : ", err);
      });
    }


    if ($routeParams.id != "") {
      $scope.getVotingRecords();
    }
  }]);
