'use strict';
angular.module('myApp.questionBackGround', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/question-background/:categoryId/:selectedCatId', {
      templateUrl: 'app/questionBackGround/questionBackGround.html',
      reloadOnSearch: false,
      controller: 'questionCtrl'
    });
  }]).controller('questionCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', 'tokenService', '$anchorScroll', '$timeout', '$window', '$cookies', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, tokenService, $anchorScroll, $timeout, $window, $cookies) {
    //MyService.ConsoleLog("** questionCtrl ** :", $routeParams);

    // MyService.ConsoleLog("** questionCtrl ** :", $scope.politicianName);
    $scope.showLoaderNw = false;
    $rootScope.welcome = false;
    $scope.saveForLater = false;
    $scope.userId = tokenService.getUserId();
    $scope.userRole = tokenService.getUserRole();
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
      $rootScope.tempUserAddress = $scope.userData.address;
    }

    /**
     * @description Remove session Id
     */

    window.localStorage.removeItem('sessionId');

    window.localStorage.removeItem('showSavePopUp');
    window.localStorage.removeItem("cat_id");
    window.localStorage.removeItem("selCat_id");
    if ($routeParams.categoryId && $routeParams.selectedCatId) {
      window.localStorage.setItem("cat_id", $routeParams.categoryId);
      window.localStorage.setItem("selCat_id", $routeParams.selectedCatId);
    } else if ($routeParams.categoryId) {
      window.localStorage.setItem("cat_id", $routeParams.categoryId);
    } else {
      window.localStorage.setItem("selCat_id", $routeParams.selectedCatId);
    }
    window.localStorage.removeItem('fbImg');
    window.localStorage.removeItem('fbName');
    window.localStorage.removeItem('quizLinkData');
    if (window.localStorage.getItem('isFindFollowers')) {
      window.localStorage.removeItem("inviterInfo");
      $cookies.remove("quizTakerIdsArr");
      window.localStorage.removeItem('isFindFollowers');
    }

    if (window.localStorage.getItem('inviterId')) {
      if (window.localStorage.getItem('inviterInfo')) {
        $scope.inviterInfo = JSON.parse(window.localStorage.getItem('inviterInfo'));
        MyService.ConsoleLog("$scope.inviterInfo", $scope.inviterInfo);
        if ($scope.inviterInfo && $scope.inviterInfo.nameOnBallot && $scope.inviterInfo.userType == 'politician') {
          $scope.inviterName = $scope.inviterInfo.nameOnBallot;
        } else if ($scope.inviterInfo && $scope.inviterInfo.title) {
          $scope.inviterName = $scope.inviterInfo.title;
        } else if ($scope.inviterInfo && $scope.inviterInfo.userType == 'voter') {
          $scope.inviterName = $scope.inviterInfo.username;
        }
        $scope.isShare = true;
      }
      $scope.inviterId = window.localStorage.getItem('inviterId');
    }

    $scope.updateFlag = function () {
      $scope.isShare = !$scope.isShare;
      console.log('isShare :', $scope.isShare);
    };

    $cookies.remove('QuizName');
    $scope.traverseBackgrounds = [];
    $scope.currBackgroundIndex = 0;
    $scope.status = {
      questions: 0, //1= loading, 2 = loaded, 3 = error
      category: 0
    };
    $scope.flagSkipAnsweredQuestion = true;
    $scope.skipToMatch = false;
    $scope.skipToFinal = false;
    $scope.loadernew = false;
    $scope.currentIndex = 0;

    $cookies.remove('quizLinks');
    $scope.quizLink = "https://votewise.net/#!/question-background/" + $routeParams.categoryId + "/" + $routeParams.selectedCatId;
    // MyService.ConsoleLog("$scope.quizLink", $scope.quizLink);

    /**
     * This function calls on page load
     * fetch data of user's area politician ,voters and advocates
     */

    $scope.getLocalPoliticians = function (asdata) {
      // MyService.ConsoleLog(" welcome to getLocalPoliticians ", $rootScope.queData);
      $scope.statusGetLocalPoliticians = 1;
      $scope.page += 1;
      $scope.loader = true;
      // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress);
      // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress.state)
      var extradata = new Object();
      if ($rootScope.tempUserAddress) {
        if ($rootScope.tempUserAddress.state && $rootScope.tempUserAddress.state != '') {
          extradata.statePostalCode = $rootScope.tempUserAddress.statePostalCode;
        }
        if ($rootScope.tempUserAddress.county && $rootScope.tempUserAddress.countyFips) {
          $rootScope.tempUserAddress.FinalData = JSON.stringify(
            $rootScope.tempUserAddress.countyFips
          );
          extradata.county = $rootScope.tempUserAddress.county;
        }
        if (
          $rootScope.tempUserAddress.countySubdivision &&
          $rootScope.tempUserAddress.countySubdivisionFips
        ) {
          $rootScope.tempUserAddress.FinalData = JSON.stringify(
            $rootScope.tempUserAddress.countySubdivisionFips
          );
          extradata.countySubdivision = $rootScope.tempUserAddress.countySubdivision;
        }
        if ($rootScope.tempUserAddress.place && $rootScope.tempUserAddress.placeFips) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.place = $rootScope.tempUserAddress.place;
        }
        if (
          $rootScope.tempUserAddress.voterDistrict &&
          $rootScope.tempUserAddress.voterDistrictFips
        ) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
        }
        if ($rootScope.tempUserAddress.federalCongrassionalDist) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);

          extradata.federalCongrassionalDist =
            $rootScope.tempUserAddress.federalCongrassionalDist;
        }
        if ($rootScope.tempUserAddress.stateSenateDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.stateSenateDistrict = $rootScope.tempUserAddress.stateSenateDistrict;
        }
        if ($rootScope.tempUserAddress.legislativeDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.legislativeDistrict = $rootScope.tempUserAddress.legislativeDistrict;
        }
        if ($rootScope.tempUserAddress.schoolDistrict) {
          //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
          extradata.schoolDistrict = $rootScope.tempUserAddress.schoolDistrict;
        }
      }
      // MyService.ConsoleLog('extradata : >>>>>>>>>> ', JSON.stringify(extradata));
      MyService.getAllLocalPoli({
        max_records: 100,
        page: $scope.page,
        extradata: JSON.stringify(extradata),
      }).then(
        function (response) {
          if (response.data.success) {
            setTimeout(function () {
              $scope.$apply(function () {
                if (response.data.data.length > 0) {
                  MyService.getAdvocates().then(function (payload) {
                    // MyService.ConsoleLog("Response >", payload);
                    var ndata = response.data.data;
                    if ($scope.inviterId) {
                      $scope.userAreaPolitician = [];
                      $scope.userAreaPolitician.push($scope.inviterId);
                    } else {
                      var wdata = ndata.concat(payload.data.data);
                      $scope.userAreaPolitician = wdata.map(function (ques) {
                        return ques._id;
                      });
                    }
                    // MyService.ConsoleLog(" $scope.userAreaPolitician >", $scope.userAreaPolitician);
                    var param = JSON.stringify({
                      usersIds: $scope.userAreaPolitician,
                      cur_userId: $scope.userId,
                      questions: JSON.stringify(asdata)
                    });

                    // MyService.ConsoleLog("apra >", param);
                    // window.localStorage.removeItem('comData');
                    window.localStorage.setItem('comData', param);
                    $scope.loadernew = false;
                    $scope.skipToFinal = true;
                    $scope.skipToMatch = true;
                  }, function (err) {
                    MyService.ConsoleLog("Error >>", err);
                  });
                } else {
                  $scope.loader = false;
                  var message =
                    'None of your politicians have answered the questions yet. Contact them and ask them to fill it out.';
                  var modal = ngDialog.openConfirm({
                    template: '<p>' +
                      message +
                      '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
                    plain: true,
                  });

                  modal.then(
                    function fullfilled(data) {
                      //MyService.ConsoleLog("fullfilled: ", data);
                      if (data == 1) {
                        $scope.postonFacebook();
                      } else {
                        if ($rootScope.userRole) {
                          $location.path('/snapshot');
                        } else {
                          $location.path('/signin');
                        }
                      }
                    },
                    function rejected(data) {
                      //MyService.ConsoleLog("rejected: ", data);
                    }
                  );
                }
              });
            }, 100);
          } else {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            if (typeof response.data.error == 'string') growl.error(response.data.error);
            else growl.error('Something went wrong');
          }
        },
        function (err) {
          $scope.page -= 1;
          $scope.statusGetLocalPoliticians = 3;
          $scope.loader = false;
          growl.error(err);
        }
      );
    };




    $scope.initializeVariables = function () {
      // alert("called");
      $scope.importance = null;
      $scope.answer = null;
      $scope.comment = '';
      $scope.reason = '';
    };
    $scope.initializeVariables();

    $scope.initMyAnswer = function () {

      try {
        if ($scope.questions[$scope.currentIndex].my_answer) {
          // MyService.ConsoleLog("called init my ans", $scope.questions[$scope.currentIndex].my_answer);
          var my_answer = $scope.importance = $scope.questions[$scope.currentIndex].my_answer;
          // MyService.ConsoleLog("Final Answers", my_answer);
          $scope.importance = my_answer.importance;
          $scope.answer = my_answer.answer;
          $scope.comment = my_answer.comment;
          // MyService.ConsoleLog("Importance", $scope.importance);
          // MyService.ConsoleLog(" $scope.answer", $scope.answer);
          // MyService.ConsoleLog("#agreedisagree-id" + my_answer.answer);
          // MyService.ConsoleLog("#importance-id" + my_answer.importance);
          $("#agreedisagree-id" + my_answer.answer).prop('checked', true);
          $("#importance-id" + my_answer.importance).prop('checked', true);
        } else {
          $scope.initializeVariables();
        }
      } catch (e) {
        $scope.initializeVariables();
        //MyService.ConsoleLog("Exceptoin: ", e)
      }
    };

    $scope.viewOldAnswers = function (old_ans) {
      ngDialog.open({
        template: 'app/popups/view_old_answers.html',
        controller: 'OldAnswerViewerCtrl',
        data: old_ans
      });
    };

    if ($rootScope.userRole == "advocate") {
      var request = apiCall.apiCall('GET', '/user/list', {
        "usertype": "politician"
      });
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.tagableUsers = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }
    $scope.addTags = function (tagUsers) {
      //MyService.ConsoleLog("tagUsers: ", tagUsers);
      //MyService.ConsoleLog("tagUsers: ", $scope.questions[$scope.currentIndex]._id);

      var postData = {
        id: $scope.questions[$scope.currentIndex]._id,
        user_ids: tagUsers.join(",")
      };

      var request = apiCall.apiCall('POST', '/questions/add_tag', postData);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("succss: ", response);
        if (response.data.success) {
          growl.success("Tagged successfully");
        } else {
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallback(response) {
        growl.error("Something went wrong");
      });
    };

    /**
     * Check how many questions has already answered
     */
    $scope.checkAnswerQuestions = function (quData) {
      return quData.filter(function (itm) {
        if (itm.my_answer && itm.my_answer.answer && itm.my_answer.answer != '') {
          return itm;
        }
      });
    };

    var getQuestionsCanceler = $q.defer();
    $scope.questions = [];
    $scope.fbwallDlg = "";
    $scope.oldQuestions = [];

    function getMoreQuestions(params) {
      $scope.loadernew = true;

      MyService.ConsoleLog("params >>", params);
      // alert('welcome');
      getQuestionsCanceler.resolve();
      $scope.status.questions = 1;
      $scope.questions.length = 0;
      if ($scope.fbwallDlg != "") {
        //MyService.ConsoleLog($scope.fbwallDlg);
        ngDialog.close($scope.fbwallDlg.id);
      }
      params.timeout = getQuestionsCanceler.promise;
      MyService.ConsoleLog("questionCtrl** /questions/list: ", params);
      var request = apiCall.apiCall('GET', '/questions/list', params);
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        // MyService.ConsoleLog("questionCtrl Got categories: ", response);
        if (response.data.success) {
          MyService.ConsoleLog("IF =>>>", response.data.data.length);
          if (response.data.data == "") {
            // alert('call');
            $scope.skipQuestion();
          }
          $scope.status.questions = 2;

          $scope.initMyAnswer();
          if ($scope.userId) {

            // alert("call");
            window.localStorage.removeItem('quesData');
            window.localStorage.setItem('quesData', JSON.stringify(response.data.data));
            $scope.questionsData = response.data.data;
            $scope.queIds = $scope.questionsData.map(function (itm) {
              if (itm.questionId) {
                return itm.questionId;
              } else {
                // console.log("itm", itm);
                return itm._id;
              }
            });

          }
          // window.localStorage.setItem('quesData', JSON.stringify(response.data.data));
          // MyService.ConsoleLog("data len: ", response.data.data);
          if ($scope.flagSkipAnsweredQuestion) {
            angular.forEach(response.data.data, function (val, key) {
              if (val.my_answer == null)
                $scope.questions.push(val);
            });
          } else {
            $scope.questions = response.data.data;
          }
          $scope.chkAnsQues = $scope.checkAnswerQuestions(response.data.data);
          MyService.ConsoleLog("chkAnsQues", $scope.chkAnsQues);
          if ($scope.chkAnsQues && $scope.chkAnsQues.length > 0 && $scope.chkAnsQues.length != $scope.questionsData.length) {
            $scope.saveForLater = true;
          }
          if ($scope.chkAnsQues && $scope.chkAnsQues.length == response.data.data.length) {
            if (tokenService.getUserId()) {
              $scope.getLocalPoliticians(response.data.data);
            } else {
              $scope.loadernew = false;

            }
          } else {
            $scope.loadernew = false;
          }

          //$scope.oldQuestions = angular.copy($scope.questions);
          //MyService.ConsoleLog("total questions: ", $scope.questions.length);
        } else {
          MyService.ConsoleLog("Else by vishal");
          $scope.status.questions = 3;
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    }

    /**
     * Redirect to the match
     */
    $scope.skipToMatchs = function () {
      if ($scope.inviterId) {
        window.localStorage.removeItem('isUpdate');
        window.localStorage.setItem('isUpdate', true);
      }

      $location.path('/findMatch/#DisplayQ');
    };

    /**
     * Redirect to the final match
     */

    $scope.skipToCompare = function () {
      if ($scope.inviterId) {
        window.localStorage.removeItem('isUpdate');
        window.localStorage.setItem('isUpdate', true);
      }
      $location.path('/compareWithGroup');
    };

    /**
     * @description Show action pop up.
     */
    $scope.skipToActions = function () {
      MyService.ConsoleLog("old", $rootScope.OldCatforFB)
      $scope.getLocalMatch();
    };

    $scope.createLink = function () {
      MyService.ConsoleLog("create");
      $scope.fbwallDlg.close();
      if ($scope.userId && $scope.userId != '') {
        $scope.getAllAnsweredQuestions();
      }

    };

    $scope.retainOptions = "";
    if ($scope.userId) {
      $scope.fb = {
        // options: "C"
        options: "PS"
      };
    } else {
      $scope.fb = {
        options: "R"
      };
    }


    $scope.postOnWall = function (message) {
      var body = message;
      var link = 'https://votewise.net/#!/question-background/' + $routeParams.categoryId + '/' + $rootScope.OldCatforFB._id;
      var picture = 'https://api.votewise.net/resources/education-general-1484943663025.jpg';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbImage && $rootScope.OldCatforFB.fbImage != null) {
        picture = "https://api.votewise.net/resources/" + $rootScope.OldCatforFB.fbImage;
      }

      //MyService.ConsoleLog(picture);
      //var picture = '';
      //MyService.ConsoleLog($rootScope.OldCatforFB);
      var name = 'What makes a good education?';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbTitle) {
        name = $rootScope.OldCatforFB.fbTitle;
      }
      var description = 'How do we make our education system better?  Take the quiz to learn more, tell your politicians what they should do, and compare with your friends.';
      if ($rootScope.OldCatforFB && $rootScope.OldCatforFB.fbDescription) {
        description = $rootScope.OldCatforFB.fbDescription;
      }
      FB.api('/me/feed', 'post', {
        message: body,
        link: link,
        picture: picture,
        name: name,
        description: description
      }, function (response) {
        //MyService.ConsoleLog(response);
        $scope.DisplayFPostLoader = false;
        if (!response || response.error) {
          var errmsg = "Facebook is taking too long to respond. Should we try again?"
          var modal = ngDialog.openConfirm({
            template: '<p>' + errmsg + '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
            plain: true
          });
          modal.then(function fullfilled(tdata) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (tdata == 1) {
              $scope.postOnWall(message);
            }

          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          });
        } else {

          var msg = "Message is successfully posted on facebook wall. Do you want to continue with Answering questions?";
          var modal = ngDialog.openConfirm({
            template: '<p>' + msg + '</p>\
                            <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(2)">NO</button>\
                                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                            </div>',
            plain: true
          });
          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (data == 1) {
              getMoreQuestions($scope.retainOptions);
            }
            ngDialog.close();
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            ngDialog.close();
          });
          // alert('Post ID: ' + response.id);
        }
      });
    }
    $scope.DisplayFPostLoader = false;
    $scope.postFB = function (message) {
      //alert("This needs to be implemented");
      $scope.DisplayFPostLoader = true;
      FB.getLoginStatus(function (response) {
        //MyService.ConsoleLog(response);

        if (response.status == 'unknown') {
          FB.login(function (response) {
            //statusChangeCallback(response, 'login');
            //MyService.ConsoleLog(response);
            MyService.updateFBID({
              "facebookid": response.authResponse.userID
            }).then(function (payload) {
              //MyService.ConsoleLog(payload);
              //MyService.ConsoleLog(payload.data.error);
              if (payload.data.error && payload.data.error == "FBDUPLICATE") {
                $scope.DisplayFPostLoader = false;
                alert("Facebook user doesn't match with your account.");
              } else {
                $scope.postOnWall(message);
              }

            }, function (errpayload) {
              $scope.DisplayFPostLoader = false;

              var errmsg = "Facebook is taking too long to respond. Should we try again?"
              var modal = ngDialog.openConfirm({
                template: '<p>' + errmsg + '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
                plain: true
              });
              modal.then(function fullfilled(tdata) {
                //MyService.ConsoleLog("fullfilled: ", data);
                if (tdata == 1) {
                  $scope.postOnWall(message);
                }

              }, function rejected(data) {
                //MyService.ConsoleLog("rejected: ", data);
              });

              // alert("Facebook is taking too long to respond. Should we try again?");
            });
          }, {
            scope: 'public_profile,email,publish_actions',
            return_scopes: true
          });
        } else {
          if ($scope.userData && $scope.userData.facebookid) {
            if (response.authResponse.userID == $scope.userData.facebookid) {
              $scope.postOnWall(message);
            } else {
              $scope.DisplayFPostLoader = false;
              alert("Facebook user doesn't match with your account.");
            }
          } else {
            $scope.postOnWall(message);
          }
        }
      });
    }

    $scope.fbFrdDlg = "";
    $scope.FBfreinds = [];
    $scope.FBfreindsFinal = [];
    $scope.DisplayFLoader = false;
    $scope.checkFBFreinds = function () {
      $scope.DisplayFLoader = true;
      FB.login(function (loginData) {
        //MyService.ConsoleLog(loginData);
        FB.api('/me/friends', function (response) {
          //MyService.ConsoleLog(response);
          $scope.FBfreindsFinal.length = 0;
          if (response && response.data) {
            var frdata = response.data;
            $scope.FBfreindsFinal = response.data;
            if (frdata.length > 0) {
              var results = frdata.map(function (frdata) {
                return frdata.id;
              });
              //MyService.ConsoleLog(results);
              var sendfrd = JSON.stringify(results);
              //MyService.ConsoleLog(sendfrd);
              MyService.checkfreindsIfanswered({
                "friends": sendfrd,
                "categoryId": $rootScope.OldCatforFB._id
              }).then(function (payload) {
                //MyService.ConsoleLog("payload:", payload);
                $scope.selection.length = 0;
                var allfriends = payload.data.data;
                $scope.FBfreinds.length = 0;
                //MyService.ConsoleLog("allfriends:::", allfriends);
                $scope.DisplayFLoader = false;
                if (allfriends.length > 0) {
                  //$scope.FBfreinds.length = 0;
                  //MyService.ConsoleLog("inside frdata >> ", frdata);
                  var fresults = $scope.FBfreindsFinal.map(function (frdata) {
                    //MyService.ConsoleLog(frdata.id);
                    //MyService.ConsoleLog(allfriends.indexOf(frdata.id));
                    if (allfriends.indexOf(frdata.id) > -1) {
                      $scope.FBfreinds.push(frdata);
                      return frdata;
                    }
                  });
                  //MyService.ConsoleLog(fresults);
                  //$scope.FBfreinds = fresults;
                  //MyService.ConsoleLog($scope.FBfreindsFinal);
                  if ($scope.fbwallDlg != "") {
                    //MyService.ConsoleLog($scope.fbwallDlg);
                    ngDialog.close($scope.fbwallDlg.id);
                  }
                  $scope.fbFrdDlg = ngDialog.open({
                    template: 'FBFreindList',
                    scope: $scope
                  });
                } else {

                  alert("none of your friends have answered yet. You should write them and ask them to answer");
                }



              }, function (errpayload) {
                $scope.DisplayFLoader = false;
                //MyService.ConsoleLog("errpayload", errpayload);
              })



            } else {
              $scope.DisplayFLoader = false;
              alert("Your freinds not yet joined VoteWise.net. Ask them to join by posting a message on Facebook Wall?");
            }
          } else {
            $scope.DisplayFLoader = false;
            //MyService.ConsoleLog('Something goes wrong', response);
          }
        }, {
          scope: 'user_friends'
        });
      }, {
        scope: 'user_friends',
        auth_type: 'rerequest'
      });
    }

    $scope.selection = [];

    // helper method to get selected fruits
    $scope.selectedFrds = function () {
      return filterFilter($scope.FBfreinds, {
        selected: true
      });
    };

    // watch fruits for changes
    // $scope.$watch('FBfreinds|filter:{selected:true}', function(nv) {
    //     $scope.selection = nv.map(function(FBfreinds) {
    //         return FBfreinds.id;
    //     });
    // }, true);

    $scope.selectedFrd = function (frd) {
      //MyService.ConsoleLog(frd);
      var idx = $scope.selection.indexOf(frd.id);
      if (idx == -1) {
        $scope.selection.push(frd.id);
      } else {
        $scope.selection.splice(idx, 1);
      }
    }


    $scope.displayMatch = function () {
      //alert("This needs to be implemented");
      FB.getLoginStatus(function (response) {
        //MyService.ConsoleLog(response);
        if (response.status == 'unknown') {
          FB.login(function (response) {
            //statusChangeCallback(response, 'login');
            //MyService.ConsoleLog(response);
            MyService.updateFBID({
              "facebookid": response.authResponse.userID
            }).then(function (payload) {
              if (payload.data.error && payload.data.error == "FBDUPLICATE") {
                $scope.DisplayFLoader = false;
                alert("Facebook user doesn't match with your account.");
              } else {
                $scope.checkFBFreinds();
              }

            }, function (errpayload) {
              $scope.DisplayFLoader = false;
              alert("Something went wrong. Please try again later.");
            });
          }, {
            scope: 'public_profile,email,publish_actions',
            return_scopes: true
          });
        } else {
          // if($scope.userData && $scope.userData.facebookid) {
          //     if(response.authResponse.userID==$scope.userData.facebookid ) {
          //         var message = "Here is some message";
          //         $scope.postOnWall(message);
          //     } else {
          //         $scope.DisplayFLoader = false;
          //        alert("Facebook user doesn't match with your account.");
          //     }
          // } else {
          //     $scope.checkFBFreinds();
          // }
          $scope.checkFBFreinds();
        }
      });

    }
    $scope.FBCompareList = [];
    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;
    $scope.ComapreWithSelected = function () {
      //MyService.ConsoleLog($scope.selection);
      //MyService.ConsoleLog("ComapreWithSelected");
      //MyService.ConsoleLog($rootScope.OldCatforFB._id);
      //MyService.ConsoleLog($scope.retainOptions);
      if ($scope.selection.length > 0) {
        var frdlist = JSON.stringify($scope.selection);
        MyService.CompareWithFreinds({
          "frds": frdlist,
          "categoryIds": $rootScope.OldCatforFB._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          $scope.FBCompareList.length = 0;
          if (payload.data.success) {
            //MyService.ConsoleLog(payload.data.data);
            $scope.FBCompareList = payload.data.data.qdata;
            if ($scope.FBCompareList.length > 0) {
              ngDialog.open({
                template: 'FBFreindCompare',
                scope: $scope
              });
              $scope.FBunWeighted = payload.data.data.unweightedMatch;
              $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
              $scope.fbmultiplier = payload.data.data.fbmultiplier;
              $scope.fbqlen = payload.data.data.qlen;
            } else {
              alert("Looks like your freinds not yet answered the questions.");
              //MyService.ConsoleLog($scope.FBCompareList);
            }
          } else {
            alert("Something went wrong.");
          }
        }, function (errorpayload) {
          alert("Something went wrong.");
        })
      } else {
        alert("Please select at least one Friend to compare with");
      }
    }

    $scope.allQuestions = [];
    $scope.switchStatus = [];
    $scope.chooseQuestions = function () {
      MyService.ConsoleLog("$scope.oldQuestions", $scope.oldQuestions);
      // if ($scope.oldQuestions && $scope.oldQuestions.length > 0) {
      //   $scope.allQuestions = $scope.oldQuestions;
      // }
      if ($scope.allQuestions.length > 0) {
        $scope.showHidePop = ngDialog.open({
          template: 'ReviewMyAnswers',
          scope: $scope,
          width: "60%"
        });
      } else {
        MyService.ConsoleLog("here >>>>>>>>>>>>>>>>>>>");
        alert("There are no questions to display. Please go through all questions first.")
      }
    }
    //$scope.switchStatus = "Show";
    $scope.showloader = false;
    $scope.switchFilters = function (newValue, index, quest) {
      $scope.showloader = true;
      // MyService.ConsoleLog("newValue::", newValue);
      // MyService.ConsoleLog("oldValue::", $scope.switchStatus);
      var setVal = "N";
      if (newValue[index] == true) {
        setVal = "Y";
      }

      var idx = $scope.allQuestions.indexOf(quest);
      //MyService.ConsoleLog("setVal:", setVal);
      MyService.updateAnswers({
        "qid": quest._id,
        "show": setVal,
        "author": quest.author
      }).then(function (payload) {
        //MyService.ConsoleLog("payload : ", payload);
        $scope.showloader = false;
        if (payload.data.success) {
          quest.my_answer.show = setVal;
          $scope.allQuestions[idx] = quest;
          // alert("Record updated successfully");
        } else {
          alert("Something went wrong. Contact administrator.");
        }
      }, function (errorpayload) {
        $scope.showloader = false;
        //MyService.ConsoleLog("errorpayload : ", errorpayload);
        alert("Something went wrong. Contact administrator.");
      });
    }


    $scope.NextOptions = function (option) {
      //MyService.ConsoleLog(option);
      switch (option) {
        case "R":
          $scope.fbwallDlg.close();
          $location.path('/fiveSaveUser');
          break;
        case "C":
          // MyService.ConsoleLog("one more new : ", $scope.traverseBackgrounds);
          // $scope.traverseBackgrounds.filter(function (itm, index) {
          //   if ($routeParams.selectedCatId == itm._id) {
          //     MyService.ConsoleLog("index", index);
          //     var fnInd = index + 1;
          //     var chkId = $scope.traverseBackgrounds[fnInd];
          //     if (chkId && chkId.total_questions != 0) {
          //       $scope.catInd = fnInd;
          //     } else {
          //       $scope.catInd = fnInd + 1;
          //     }

          //   }
          // });
          // var catIds = $scope.traverseBackgrounds[$scope.catInd];
          // MyService.ConsoleLog("catIds", catIds);
          // MyService.ConsoleLog("check this new one", $scope.retainOptions);
          // $scope.retainOptions = "";
          // $scope.retainOptions = {
          //   categoryId: catIds._id
          // };
          // $scope.category = catIds;
          // getMoreQuestions($scope.retainOptions);
          ngDialog.close($scope.fbwallDlg.id);
          $location.path('/questionHome');
          break;
        case "P":
          //$scope.postFB();
          // $scope.fbFrdDlg = ngDialog.open({
          //   template: 'FBComposeMessage',
          //   scope: $scope
          // });
          MyService.ConsoleLog("$rootScope.OldCatforFB.", $rootScope.OldCatforFB)
          $scope.fbwallDlg.close();
          $cookies.put('quizLinks', $scope.quizLink);
          var qData = window.localStorage.getItem('quesData');
          window.localStorage.removeItem('fiveMatch');
          window.localStorage.setItem('fiveMatch', qData);
          $location.path('/showHideAns');
          break;
        case "Q":
          $scope.chooseQuestions();
          break;
        case "M":
          $scope.displayMatch();
          break;
        case "F":
          ngDialog.close($scope.fbwallDlg.id);

          if (!apiCall.getToken()) {
            MyService.ConsoleLog('IF');

            $scope.confirmPopup();
          } else {
            MyService.ConsoleLog('ELSE');

            $location.path('/findMatch/');

          }
          break;
        case 'L':
          $scope.fbwallDlg.close();
          $location.path('/essay/' + $routeParams.categoryId);
          break;
        case 'D':
          $scope.fbwallDlg.close();
          $location.path('/discussWithOthers/' + $routeParams.categoryId + '/' + $routeParams.selectedCatId);
          break;
        case 'PS':
          $scope.createLink();
          break;
        default:
          getMoreQuestions($scope.retainOptions);
          break;
      }

    }



    $scope.confirmPopup = function () {
      MyService.ConsoleLog("address : ", $scope.address);
      var message = "Without your voice, your politicians won’t know how to represent you. Before we show you your results, would you like to create a profile so we can tell your politicians what you want them to do?  It takes 45 seconds, is free and you won't get spammed.  Politicians just need to know that you are real and live in their district.";
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="compareBtn" ng-click="confirm(0)">Register</button>\
                            <button type="button" class="eraseBtn" ng-click="confirm(1)">Skip</button>\
                        </div>',
        plain: true
      });



      modal.then(function fullfilled(data) {
        MyService.ConsoleLog("fullfilled: ", data);
        if (data == 0) {
          $location.path('/fiveSaveUser');

          // $scope.saveUserAnswers();
        } else {
          // $scope.eraseUserAnswers();
          $location.path('/findMatch/');
          // ngDialog.close();

          // var message = "Thanks for answering those questions";
          // var modal = ngDialog.openConfirm({
          //   template: '<p>' + message + '</p>\
          //               <div class="ngdialog-buttons">\
          //                   <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
          //               </div>',
          //   plain: true
          // });

        }
      }, function rejected(data) {
        MyService.ConsoleLog("rejected: ", data);
      });

      return;
    };


    $scope.ord = {
      "Option": "BW"
    };

    $scope.checkMyOrder = function (frnd) {
      //MyService.ConsoleLog($scope.ord.Option);
      switch ($scope.ord.Option) {
        case "BW":
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "W":
          return ($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "BS":
          return -($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        case "S":
          return ($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        default:
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;

      }

    }

    /**
     *
     * Get local best match of politician and organizations
     */

    $scope.getLocalMatch = function () {
      $scope.showLoaderNw = true;
      var testItm = window.localStorage.getItem('quesData');
      MyService.ConsoleLog("<<<<<< $scope.questions >>>>>>>", JSON.parse(testItm));

      MyService.getMatchOfLocalPoli({
        questions: testItm
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        if (response.data.success) {
          $scope.poliData = response.data.data.politician;
          $scope.orgData = response.data.data.advocate;
          $scope.showLoaderNw = false;
          $scope.fbwallDlg = ngDialog.open({
            template: 'nextQuestionOptions',
            scope: $scope
          });
        } else {
          $scope.showLoaderNw = false;
          $scope.skipToMatch = false;
          $scope.skipToFinal = false;
          getMoreQuestions($scope.retainOptions);
        }

      }, function (err) {
        $scope.showLoaderNw = false;
        growl.error(err);
      });
    };


    $scope.redirectTo = function (type, id) {
      $scope.fbwallDlg.close();
      if (type == 'politician') {
        $location.path('/publicProfile/' + id);

      } else {
        $location.path('/pressProfile/' + id);
      }
    };

    function getQuestions(params) {
      MyService.ConsoleLog("getQuestions params");
      MyService.ConsoleLog(params);
      //MyService.ConsoleLog($rootScope.OldCatforFB);
      $scope.oldQuestions = angular.copy($scope.questions);
      $scope.questions.length = 0;
      $scope.currentIndex = 0;
      $scope.retainOptions = params;
      MyService.ConsoleLog("$scope.questionsData", $scope.questionsData);
      //MyService.ConsoleLog($routeParams.selectedCatId);

      MyService.ConsoleLog(params.categoryId + "======" + $routeParams.selectedCatId);
      if (params.categoryId != $routeParams.selectedCatId) {
        // alert('in');
        if (tokenService.getUserId()) {
          $scope.chkAnsQues = $scope.checkAnswerQuestions($scope.questionsData);
          if ($scope.questionsData.length == $scope.chkAnsQues.length && $scope.saveForLater) {
            var catId, selCat_id;
            catId = window.localStorage.getItem('cat_id');
            selCat_id = window.localStorage.getItem('selCat_id');
            var tempLink = $location.absUrl().split("#!");
            var quizLinks = tempLink[0] + "#!/question-background/" + catId + "/" + selCat_id;
            MyService.updateRemainingQuiz({
              saveLink: quizLinks,
              userId: $scope.userId,
              quizName: window.localStorage.getItem('fbName')
            }).then(function (APIResponse) {
              MyService.ConsoleLog("APIResponse", APIResponse);
            }, function (err) {
              console.log("Error", err);
            });
          }

          $scope.getLocalMatch();

        } else {
          $scope.fbwallDlg = ngDialog.open({
            template: 'nextQuestionOptions',
            scope: $scope
          });
        }
        MyService.ConsoleLog("$scope.retainOptions nssssss", $scope.retainOptions);

        //MyService.ConsoleLog($scope.fbwallDlg);
        //getMoreQuestions(params);
      } else {
        // alert('sss');
        // ngDialog.open({
        //     template: 'nextQuestionOptions',
        //     scope: $scope
        // });
        // var obj = {};
        // obj.categoryId = $routeParams.selectedCatId;
        // MyService.ConsoleLog("params", params);
        getMoreQuestions(params);
      }
    }

    var getCategoryCanceler = $q.defer();
    var prevFetchedCategoryId = null;

    $scope.getCategory = function (params) {
      MyService.ConsoleLog("cat params", params);
      window.localStorage.removeItem("selCat_id");
      window.localStorage.setItem("selCat_id", params.id);
      try {
        prevFetchedCategoryId = $scope.category._id
      } catch (e) {}

      if (prevFetchedCategoryId != params.id) {
        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        getCategoryCanceler.resolve();
        getCategoryCanceler = $q.defer();
        $scope.status.category = 1;
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.headers['Cache-Control'] = 'No-Store';
        request.timeout = getCategoryCanceler.promise;
        $http(
            request
          )
          .then(function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              // alert('one');
              MyService.ConsoleLog("one >>>>>>>>>>>");
              // if (params.id == $routeParams.selectedCatId) {
              $scope.category = response.data.data;
              // }


              //MyService.ConsoleLog("category data: ", $scope.category);
              prevFetchedCategoryId = $scope.category._id;
              $scope.status.category = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.category = 3;
            }
          }, function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.category = 3;
          });
      }
    };

    var getLevelCategoryCanceler = $q.defer();

    function getLevelCategory(params) {
      getLevelCategoryCanceler.resolve();
      getLevelCategoryCanceler = $q.defer();
      $scope.status.levlCat = 1;
      var request = apiCall.apiCall('GET', '/categories/list-layer', params);
      request.headers['Cache-Control'] = 'No-Store';
      request.timeout = getLevelCategoryCanceler.promise;
      $http(
          request
        )
        .then(function successCallback(response) {

          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            /*
            angular.forEach(response.data.data, function(val, key){
                if(val.total_questions > 0){
                    $scope.traverseBackgrounds.push(val);
                }
            });
            //MyService.ConsoleLog("traverseBackgrounds data: ", $scope.traverseBackgrounds);
            if($scope.traverseBackgrounds.length > 0){
                $scope.currBackgroundIndex = 0;
                $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            }
            */
            $scope.traverseBackgrounds = response.data.data;
            if ($routeParams.selectedCatId) {
              //MyService.ConsoleLog("traverseBackgrounds: ", $scope.traverseBackgrounds);
              //MyService.ConsoleLog("$routeParams.selectedCatId: ", $routeParams.selectedCatId);
              angular.forEach($scope.traverseBackgrounds, function (val, key) {
                if (val._id == $routeParams.selectedCatId) {
                  //MyService.ConsoleLog("val._id: ", val._id, " key: ", key, " total_questions: ", val.total_questions);
                  $scope.currBackgroundIndex = key;
                  //MyService.ConsoleLog("$scope.currBackgroundIndex: ", key);
                  $scope.category = angular.copy(val);
                  $cookies.put('QuizName', $scope.category.title);
                  MyService.ConsoleLog("check two", $scope.category);
                  // $rootScope.fbImage =  $scope.category.fbImage;
                  window.localStorage.setItem('fbImg', $scope.category.fbImage);
                  window.localStorage.setItem('fbName', $scope.category.title);

                }
              });
            } else if ($scope.traverseBackgrounds.length > 0) {
              MyService.ConsoleLog("three >>>>>>>>>>>");
              $scope.category = $scope.traverseBackgrounds[0];
            }
            $scope.status.levlCat = 2;
          } else {
            //MyService.ConsoleLog("Something went wrong: ", response.data);
            $scope.status.levlCat = 3;
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
          $scope.status.levlCat = 3;
        });
    }

    function getPopularQuestions() {
      //MyService.ConsoleLog("** getPopularQuestions **");
      var categoryIds = $routeParams.categoryId.split("popular:")[1];
      var request = apiCall.apiCall('GET', '/answers/popular', {
        "categoryIds": categoryIds
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.questions = response.data.data;
          if ($scope.questions.length > 0) {
            $scope.getCategory({
              id: $scope.questions[0].categories.cid
            });
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }

    var getPopularCategoriesCanceler = $q.defer();
    $scope.getPopularCategories = function (params) {
      //MyService.ConsoleLog("** getPopularQuestions **");
      getPopularCategoriesCanceler.resolve();
      params.timeout = getPopularCategoriesCanceler.promise;

      var request = apiCall.apiCall('POST', '/categories/popular-subcategories', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ", response);
        if (response.data.success) {
          // response.data.data[1].categories.cid = "579b6d83d0cfc69732251494"; //for testing purpose
          $scope.traverseBackgrounds = response.data.data;
          if ($scope.traverseBackgrounds.length > 0) {
            MyService.ConsoleLog("four >>>>>>>>>>>");
            $scope.category = $scope.traverseBackgrounds[0];
            prevFetchedCategoryId = $scope.category._id;
            // getQuestions({categoryId: $scope.category._id});
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.notAnswerableSelected = function () {
      /*
      if($scope.answer == 0){
          var message = 'Many of our Statements have an undeniable fact followed by an opinion.  Like “the sky is blue.  I hate the sky.”  If this is your problem with the question, then realize that this is just part of the question process and just respond to the second half.  If not, please explain the problem in the “add comments” section so we can fix it.  Thank you.';
          var modal = ngDialog.openConfirm({
              template:'<p>'+message+'</p>\
                      <div class="ngdialog-buttons">\
                          <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                      </div>',
              plain: true
          });

          modal.then(function fullfilled(data){
              //MyService.ConsoleLog("fullfilled: ",data);
          }, function rejected(data){
              //MyService.ConsoleLog("rejected: ",data);
          });
      }
      */
      //for report question

      // commented by vishal on 18 February 2019

      var token = apiCall.getToken();

      if (!token || token == "") {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }
      var postData = {
        questionId: $scope.questions[$scope.currentIndex]._id,
        importance: 0, //$scope.importance,
        answer: $scope.answer,
        comment: $scope.comment
      };

      ngDialog.open({
        template: 'app/popups/report_question.html',
        controller: 'ReportQuesCtrl',
        data: postData
      });

    };

    /**
     * @description Funtion is use to skip qustion using submit button click
     */
    $scope.skipToNext = function () {
      var tempIndex = $scope.currentIndex + 1;
      // MyService.ConsoleLog("MyLog >>", tempIndex);
      if (tempIndex < $scope.questions.length) {
        // alert('inside');
        $scope.initializeVariables();
        $scope.currentIndex++;
        MyService.ConsoleLog("cat", $routeParams.categoryId);

        if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
          //we are showing questions of most popular section
          MyService.ConsoleLog("$routeParams.categoryId", $routeParams.categoryId);

          $scope.getCategory({
            id: $scope.questions[$scope.currentIndex].categories.cid
          });
        }
      }
      MyService.ConsoleLog("tempIndex => ", tempIndex + "$scope.questions.length " + $scope.questions.length);


      if (tempIndex == $scope.questions.length) {

        MyService.ConsoleLog("end of questions switch background");
        var tempBackIdx = $scope.currBackgroundIndex + 1;
        if (tempBackIdx < $scope.traverseBackgrounds.length) {
          MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds);
          MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds[1].title);

          // if ($scope.traverseBackgrounds[1].title == 'General') {
          //   MyService.ConsoleLog("IF");
          //   var y = 1;
          //   $scope.currBackgroundIndex = ++y;
          //   MyService.ConsoleLog("IFFFFFFFFFFF >>", $scope.currBackgroundIndex);
          // } else {
          //   MyService.ConsoleLog("ELSE");
          $scope.currBackgroundIndex++;

          // }

          MyService.ConsoleLog("$scope.$scope.currBackgroundIndex>>", $scope.currBackgroundIndex);

          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          MyService.ConsoleLog("$scope.category yogesh", $scope.category);
        } else {
          MyService.ConsoleLog("* End of background Yogesh *");

          $scope.fbwallDlg = ngDialog.open({
            template: 'nextQuestionOptions',
            scope: $scope
          });
          // $location.path('/questionSubcategories/' + $routeParams.categoryId);
        }
      } else if ($scope.questions.length == 0) {

        var tempBackIdx = $scope.currBackgroundIndex + 1;
        if (tempBackIdx < $scope.traverseBackgrounds.length) {
          MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds);
          MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds[1].title);

          // if ($scope.traverseBackgrounds[1].title == 'General') {
          //   MyService.ConsoleLog("IF");
          //   var y = 1;
          //   $scope.currBackgroundIndex = ++y;
          //   MyService.ConsoleLog("IFFFFFFFFFFF >>", $scope.currBackgroundIndex);
          // } else {
          //   MyService.ConsoleLog("ELSE");
          $scope.currBackgroundIndex++;

          // }

          MyService.ConsoleLog("$scope.$scope.currBackgroundIndex>>", $scope.currBackgroundIndex);

          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          MyService.ConsoleLog("$scope.category second five", $scope.category);
        } else {
          MyService.ConsoleLog("* End of background *");
          $location.path('/questionSubcategories/' + $routeParams.categoryId);
        }

      }
    };
    var temp = [];
    var netm = [];
    $scope.postAnswer = function (postData) {
      //MyService.ConsoleLog("postData: ", postData);
      try {
        var my_answer = $scope.questions[$scope.currentIndex].my_answer;
        MyService.ConsoleLog(" my_answer", my_answer);
        // check if the inviter is exists or not if not then check for answers values if any value chnage then we are updating that answer.
        if (!window.localStorage.getItem('inviterId')) {
          if (my_answer.importance != $scope.importance || my_answer.answer != $scope.answer || my_answer.comment != $scope.comment) {
            //MyService.ConsoleLog("Answer changed");
            postData.id = my_answer._id;
            postData.reason = $scope.reason;
          } else {
            // If value not change then we are just skip that question.
            // MyService.ConsoleLog("Answer not changed");
            $scope.skipToNext();
            return; //don't do anything
          }
        } else {
          // If inviter Id exists then we are updating answer.
          postData.id = my_answer._id;
        }

      } catch (e) {
        //MyService.ConsoleLog("Exceptoin: ", e);
      }
      console.log("postData > ", postData);
      MyService.postAnswer(postData).then(function (response) {
        if (response.data.success) {
          try {
            if (postData.id) {
              MyService.ConsoleLog("Inside If");
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: postData.id,
                created: new Date(),
                comment: postData.comment,
                answer: postData.answer,
                importance: postData.importance
              };
              netm.push($scope.questions[$scope.currentIndex]);
              if ($scope.questions.length == netm.length) {
                window.localStorage.removeItem('quesData');
                MyService.ConsoleLog("set it now netm", netm);
                window.localStorage.setItem('quesData', JSON.stringify(netm));
              } else {
                MyService.ConsoleLog("Its ELSE Need to add updated record in array");
                var testItm = window.localStorage.getItem('quesData');
                // MyService.ConsoleLog("testItm", JSON.parse(testItm));
                var tempQ = JSON.parse(testItm);

                tempQ[$scope.currentIndex]["my_answer"] = {
                  _id: postData.id,
                  created: new Date(),
                  comment: postData.comment,
                  answer: postData.answer,
                  importance: postData.importance
                };
                // MyService.ConsoleLog("$scope.currentIndex", tempQ[$scope.currentIndex]);
                // MyService.ConsoleLog("tempQ", tempQ);
                window.localStorage.removeItem('quesData');
                window.localStorage.setItem('quesData', JSON.stringify(tempQ));
              }
            } else {
              MyService.ConsoleLog("Inside ELSEEEE");

              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: response.data.data._id,
                created: response.data.data.created,
                comment: response.data.data.comment,
                answer: response.data.data.answer,
                importance: response.data.data.importance
              };
              MyService.ConsoleLog(" $scope.questions[$scope.currentIndex]", $scope.questions[$scope.currentIndex]);
              temp.push($scope.questions[$scope.currentIndex]);
              if ($scope.questions.length == temp.length) {
                window.localStorage.removeItem('quesData');
                MyService.ConsoleLog("set it now", temp);
                window.localStorage.setItem('quesData', JSON.stringify(temp));
              }
            }
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin: ", e);
          }
          if (postData.id) {
            growl.success("Answer updated successfully", {
              ttl: 800
            });
          } else {
            growl.success("Answered successfully", {
              ttl: 800
            });
          }
          $scope.initializeVariables();
          $scope.skipQuestion();
        } else {
          growl.error("Error: " + response.data.error);
        }
      }, function (err) {
        growl.error("Something went wrong.");
      });

      /*
            var request = apiCall.apiCall('POST', '/answers/create', postData);
            $http(
              request
            ).then(function successCallback(response) {
              //MyService.ConsoleLog("succss: ",response);
              if(response.data.success){
                try{
                    $scope.questions[$scope.currentIndex]["my_answer"] = {
                        _id: response.data.data._id,
                        created: response.data.data.created,
                        comment: response.data.data.comment,
                        answer: response.data.data.answer,
                        importance: response.data.data.importance
                    };
                }
                catch(e){
                    //MyService.ConsoleLog("Exceptoin: ",e);
                }
                growl.success("Answered successfully", {ttl: 800});
                $scope.initializeVariables();
                $scope.skipQuestion();
              }
              else{
                growl.error("Error: "+response.data.error);
              }
            }, function errorCallback(response) {
              $scope.error = 'Wrong Email or Password';
            });
            */

    };

    /*
     *
     * This function called when guest user want to take quiz
     *
     **/
    var ques = [];
    $scope.guest = function (postData) {
      //MyService.ConsoleLog("postData: ", postData);
      try {
        var my_answer = $scope.questions[$scope.currentIndex].my_answer;
        if (my_answer.importance != $scope.importance || my_answer.answer != $scope.answer || my_answer.comment != $scope.comment) {
          //MyService.ConsoleLog("Answer changed");
          postData.id = my_answer._id;
          postData.reason = $scope.reason;
        } else {
          //MyService.ConsoleLog("Answer not changed");
          return; //don't do anything
        }
      } catch (e) {
        //MyService.ConsoleLog("Exceptoin: ", e);
      }
      console.log("postData > ", postData);

      MyService.guestAnswers(postData).then(function (response) {
        if (response.data.success) {
          try {
            if (postData.id) {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: postData.id,
                created: new Date(),
                comment: postData.comment,
                answer: postData.answer,
                importance: postData.importance
              };
              MyService.ConsoleLog("Inside IF >>", $scope.questions);
            } else {
              $scope.questions[$scope.currentIndex]["my_answer"] = {
                _id: response.data.data._id,
                created: response.data.data.created,
                comment: response.data.data.comment,
                answer: response.data.data.answer,
                importance: response.data.data.importance
              };
              if ($scope.questions[$scope.currentIndex].my_answer) {
                MyService.ConsoleLog("Inside ELSE >>", $scope.questions[$scope.currentIndex]);
                ques.push($scope.questions[$scope.currentIndex]);
                if ($scope.questions.length == ques.length) {
                  window.localStorage.removeItem('quesData');
                  MyService.ConsoleLog("set it now", ques);
                  window.localStorage.setItem('quesData', JSON.stringify(ques));
                }
              }

            }
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin: ", e);
          }
          if (postData.id) {
            growl.success("Answer updated successfully", {
              ttl: 800
            });
          } else {
            growl.success("Answered successfully", {
              ttl: 800
            });
          }
          $scope.initializeVariables();
          $scope.skipQuestion();
        } else {
          growl.error("Error: " + response.data.error);
        }
      }, function (err) {
        growl.error("Something went wrong.");
      });


      /*
        var request = apiCall.apiCall('POST', '/answers/create', postData);
        $http(
          request
        ).then(function successCallback(response) {
          //MyService.ConsoleLog("succss: ",response);
          if(response.data.success){
            try{
                $scope.questions[$scope.currentIndex]["my_answer"] = {
                    _id: response.data.data._id,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance
                };
            }
            catch(e){
                //MyService.ConsoleLog("Exceptoin: ",e);
            }
            growl.success("Answered successfully", {ttl: 800});
            $scope.initializeVariables();
            $scope.skipQuestion();
          }
          else{
            growl.error("Error: "+response.data.error);
          }
        }, function errorCallback(response) {
          $scope.error = 'Wrong Email or Password';
        });
        */

    };


    $rootScope.$on('question.answered', function (evt, data) {
      MyService.ConsoleLog("on question.answered: ", data);
      if ($scope.questions[$scope.currentIndex]._id == data.questionId) {
        $scope.initializeVariables();
        $scope.skipQuestion();
      }
    });

    var guestArr = [];
    var guestCookies;
    // $cookies.remove('sessionId');

    // var guestOne = window.localStorage.getItem('guestMatch');
    // if (guestOne && guestOne != "") {
    //   MyService.ConsoleLog("$rootScope >>", guestOne);
    //   alert('got');
    // } else {
    $rootScope.guestMatch = [];
    $rootScope.guestMatch.length = 0;
    // }

    $scope.submitAnswer = function () {
      //MyService.ConsoleLog("submitAnswer: " + $scope.answer);
      //MyService.ConsoleLog("importance: " + $scope.importance);
      //MyService.ConsoleLog("comments: ", $scope.comment);

      // Commented by Vishal on 18 th February 2019

      var token = apiCall.getToken();

      // if (!token || token == "") {
      //   var message = "Please login first.";
      //   var modal = ngDialog.openConfirm({
      //     template: '<p>' + message + '</p>\
      //                   <div class="ngdialog-buttons">\
      //                       <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
      //                   </div>',
      //     plain: true
      //   });
      //   modal.then(function fullfilled(data) {
      //     //MyService.ConsoleLog("fullfilled: ", data);
      //   }, function rejected(data) {
      //     //MyService.ConsoleLog("rejected: ", data);
      //   });
      //   return;
      // }

      if ($scope.answer == null) {
        var message = "Please select answer first.";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });
        return;
      }

      if ($scope.importance == null) {
        var message = "You gave your opinion, but you didn’t say how important this is to you.  Should they fight hard for this or can they use it as a bargaining chip to get other things you care about more?";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

        return;
      }


      MyService.ConsoleLog("token  >>", token);
      var postData;
      if (!window.localStorage.getItem('inviterId')) {
        postData = {
          questionId: $scope.questions[$scope.currentIndex]._id,
          importance: $scope.importance,
          answer: $scope.answer,
          comment: $scope.comment,
          token: token ? token : "",
        };
      } else {
        postData = {
          questionId: $scope.questions[$scope.currentIndex]._id,
          importance: $scope.importance,
          answer: $scope.answer,
          comment: $scope.comment,
          token: token ? token : "",
          isShare: $scope.isShare,
          inviterId: $scope.inviterId,
          catId: $routeParams.categoryId,
          selCatId: $routeParams.selectedCatId
        };
      }


      if (!token) {
        MyService.ConsoleLog("Inside IF");




        // $cookies.remove('guest');

        if (!guestCookies || guestCookies == "") {
          $rootScope.finalSessionId = "";
          MyService.ConsoleLog("Inside", guestCookies);
          var date = new Date();
          var sessionId = date.getTime();
          $rootScope.finalSessionId = sessionId;
          MyService.ConsoleLog("sessionId >>", $rootScope.finalSessionId);
          $cookies.put('sessionId', $rootScope.finalSessionId);


        }
        var guessObj;
        // window.sessionStorage;
        if ($scope.inviterId) {
          guessObj = {
            questionId: $scope.questions[$scope.currentIndex]._id,
            importance: $scope.importance,
            answer: $scope.answer,
            comment: $scope.comment,
            gSessionId: $rootScope.finalSessionId,
            isShare: $scope.isShare,
            inviterId: $scope.inviterId,
            catId: $routeParams.categoryId,
            selCatId: $routeParams.selectedCatId,
            quizType: 'Regular Quiz'
          };
        } else {
          guessObj = {
            questionId: $scope.questions[$scope.currentIndex]._id,
            importance: $scope.importance,
            answer: $scope.answer,
            comment: $scope.comment,
            gSessionId: $rootScope.finalSessionId,
            quizType: 'Regular Quiz'
          };
        }
        // guestArr.push(guessObj);

        var chkData = $scope.checkQuestions($rootScope.guestMatch, guessObj.questionId);


        if (chkData && chkData.length == 0) {
          $rootScope.guestMatch.push(guessObj);
          window.localStorage.setItem('guestMatch', JSON.stringify($rootScope.guestMatch));
          MyService.ConsoleLog("$rootScope.guestMatch >>>", $rootScope.guestMatch);
        }


        guestCookies = $cookies.get('sessionId');

        // window.sessionStorage.setItem("guest", JSON.stringify(guestArr));
        // // var test = sessionStorage.guest.length;

        MyService.ConsoleLog("guestCookies >>>", guestCookies.sessionId);
        // var one = sessionStorage.getItem('guest');
        // MyService.ConsoleLog("guessObj >>>", JSON.parse(one));
        $scope.guest(guessObj);
      } else {
        MyService.ConsoleLog("Else", postData);
        $scope.postAnswer(postData);
      }
      //
    };

    $scope.checkQuestions = function (rdata, val) {
      MyService.ConsoleLog("rdata :", rdata);
      if (rdata) {
        return rdata.filter(
          function (rdata) {
            // MyService.ConsoleLog(rdata.question + "==" + val);
            if (rdata.questionId == val)
              return rdata
          }
        );
      } else {
        return rdata = "";
      }
    }
    $scope.skipQuestion_for_skipansweredQues = function () {
      //MyService.ConsoleLog("skipQuestion: ", $scope.flagSkipAnsweredQuestion);
      var skip = function () {
        var tempIndex = $scope.currentIndex + 1;
        if ($scope.flagSkipAnsweredQuestion && tempIndex < $scope.questions.length && $scope.questions[tempIndex].my_answer) {
          $scope.currentIndex++;
          skip(); //call recursive function to move to next question
          return;
        }

        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();
          $scope.currentIndex++;
          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            //we are showing questions of most popular section
            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        //MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ",$scope.questions.length);
        if (tempIndex == $scope.questions.length) {
          //MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            $scope.currBackgroundIndex++;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            MyService.ConsoleLog("one >>>>>>>>>>> Six");
          } else {
            //MyService.ConsoleLog("* End of background *");
            $location.path('/questionSubcategories/' + $routeParams.categoryId);
          }
        }

        $timeout(function () {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      };

      if ($scope.answer != null) {
        var show_answer_modal = true;
        try {
          var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];
          if (my_answer.importance == $scope.importance && my_answer.answer == $scope.answer && my_answer.comment == $scope.comment)
            show_answer_modal = false;

        } catch (e) {
          //MyService.ConsoleLog("Exceptoin : ", e);
        }
        //MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
        if (show_answer_modal) {
          var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                            </div>',
            plain: true,
            closeByDocument: false
          });

          modal.then(function fullfilled(data) {
            //submit answer
            $scope.submitAnswer();
          }, function rejected(data) {
            //skip the answer
            skip();
          });
        } else {
          skip();
        }
      } else {
        skip();
      }
    };
    $scope.prevQuestion_for_skipansweredQues = function () {
      //MyService.ConsoleLog("** prevQuestion **", $scope.currentIndex, "currBackgroundIndex: ", $scope.currBackgroundIndex);
      if ($scope.currentIndex > 0) {
        //MyService.ConsoleLog("$scope.currentIndex: ", $scope.currentIndex);
        // $scope.currentIndex--;
        var tempIndex = $scope.currentIndex - 1;
        //MyService.ConsoleLog("tempIndex: ", tempIndex);
        if ($scope.flagSkipAnsweredQuestion && tempIndex > 0 && $scope.questions[tempIndex].my_answer) {
          //MyService.ConsoleLog("Inside if");
          $scope.currentIndex--;
          $scope.prevQuestion(); //call recursive function to move to next question
          return;
        } else {
          //MyService.ConsoleLog("Inside else");
          $scope.currentIndex--;
          if ($scope.currentIndex == 0 && $scope.questions[tempIndex].my_answer && $scope.flagSkipAnsweredQuestion)
            $scope.prevQuestion();
        }

      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          MyService.ConsoleLog("seven >>>>>>>>>>>");
        }
      }
    };

    $scope.skipQuestion = function () {
      var skip = function () {
        var tempIndex = $scope.currentIndex + 1;
        MyService.ConsoleLog("MyLog >>", tempIndex);
        if (tempIndex < $scope.questions.length) {
          // alert('inside');
          $scope.initializeVariables();
          $scope.currentIndex++;
          MyService.ConsoleLog("cat", $routeParams.categoryId);

          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            //we are showing questions of most popular section
            MyService.ConsoleLog("$routeParams.categoryId", $routeParams.categoryId);

            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        MyService.ConsoleLog("tempIndex => ", tempIndex + "$scope.questions.length " + $scope.questions.length);
        if (!$scope.userId) {
          $scope.chkAnsQues = $scope.checkAnswerQuestions($scope.questions);
          MyService.ConsoleLog("chkAnsQues", $scope.chkAnsQues);
          if ($scope.chkAnsQues && $scope.chkAnsQues.length > 0 && $scope.chkAnsQues.length != $scope.questions.length) {
            $scope.saveForLater = true;
          }
        }
        if (tempIndex == $scope.questions.length) {
          MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds);
            MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds[1].title);

            // if ($scope.traverseBackgrounds[1].title == 'General') {
            //   MyService.ConsoleLog("IF");
            //   var y = 1;
            //   $scope.currBackgroundIndex = ++y;
            //   MyService.ConsoleLog("IFFFFFFFFFFF >>", $scope.currBackgroundIndex);
            // } else {
            //   MyService.ConsoleLog("ELSE");
            $scope.currBackgroundIndex++;

            // }

            MyService.ConsoleLog("$scope.$scope.currBackgroundIndex>>", $scope.currBackgroundIndex);

            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            MyService.ConsoleLog("$scope.category third", $scope.category);
          } else {
            MyService.ConsoleLog("Yogesh");
            $scope.chkAnsQues = $scope.checkAnswerQuestions($scope.questionsData);
            if ($scope.questionsData.length == $scope.chkAnsQues.length && $scope.saveForLater) {
              var catId, selCat_id;
              catId = window.localStorage.getItem('cat_id');
              selCat_id = window.localStorage.getItem('selCat_id');
              var tempLink = $location.absUrl().split("#!");
              var quizLinks = tempLink[0] + "#!/question-background/" + catId + "/" + selCat_id;
              MyService.updateRemainingQuiz({
                saveLink: quizLinks,
                userId: $scope.userId,
                quizName: window.localStorage.getItem('fbName')
              }).then(function (APIResponse) {
                MyService.ConsoleLog("APIResponse", APIResponse);
              }, function (err) {
                console.log("Error", err);
              });
            }
            $scope.fbwallDlg = ngDialog.open({
              template: 'nextQuestionOptions',
              scope: $scope
            });
            // $location.path('/questionSubcategories/' + $routeParams.categoryId);
          }
        } else if ($scope.questions.length == 0) {

          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds);
            MyService.ConsoleLog("$scope.traverseBackgrounds >>", $scope.traverseBackgrounds[1].title);

            // if ($scope.traverseBackgrounds[1].title == 'General') {
            //   MyService.ConsoleLog("IF");
            //   var y = 1;
            //   $scope.currBackgroundIndex = ++y;
            //   MyService.ConsoleLog("IFFFFFFFFFFF >>", $scope.currBackgroundIndex);
            // } else {
            //   MyService.ConsoleLog("ELSE");
            $scope.currBackgroundIndex++;

            // }

            MyService.ConsoleLog("$scope.$scope.currBackgroundIndex>>", $scope.currBackgroundIndex);

            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            MyService.ConsoleLog("$scope.category fourth", $scope.category);
          } else {
            MyService.ConsoleLog("* End of background *");
            $location.path('/questionSubcategories/' + $routeParams.categoryId);
          }

        }

        $timeout(function () {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      };
      MyService.ConsoleLog("$scope.answer", $scope.answer);
      if ($scope.answer != null) {
        var show_answer_modal = true;
        try {
          var my_answer = $scope.questions[$scope.currentIndex]["my_answer"];

          /*for (var key in my_answer) {
            if(my_answer[])
          }*/
          if (my_answer.importance == $scope.importance && my_answer.answer == $scope.answer && my_answer.comment == $scope.comment)
            show_answer_modal = false;

        } catch (e) {
          //MyService.ConsoleLog("Exceptoin : ", e);
        }
        //MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
        if (show_answer_modal) {
          var message = 'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                            <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                            </div>',
            plain: true,
            closeByDocument: false
          });

          modal.then(function fullfilled(data) {
            //submit answer
            $scope.submitAnswer();
          }, function rejected(data) {
            //skip the answer
            skip();
          });
        } else {
          skip();
        }
      } else {
        skip();
        // $scope.initializeVariables();
      }
    };
    $scope.prevQuestion = function () {
      //MyService.ConsoleLog("** prevQuestion **");

      if ($scope.currentIndex > 0) {
        $scope.currentIndex--;
        $timeout(function () {
          $location.hash('DisplayQ');
          $anchorScroll();
        }, 100);
      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          MyService.ConsoleLog("eight >>>>>>>>>>>");
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
        }
      }
    };

    $scope.compareChanged = function (compare_with) {
      //MyService.ConsoleLog("compare_with :", compare_with);
      var catid = $routeParams.categoryId;
      if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
        var catid = $routeParams.categoryId.split("popular:")[1];
      }

      $location.path('/compare-select/' + compare_with);
    };

    var getCompareAnswersCanceler = $q.defer();

    function getCompareAnswers(params) {
      if (!params) return;
      getCompareAnswersCanceler.resolve();
      params.timeout = getCompareAnswersCanceler.promise;
      $scope.questions = [];
      $scope.status.questions = 1;
      var request = apiCall.apiCall('GET', '/answers/compare', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.status.questions = 2;
          angular.forEach(response.data.data, function (val, key) {
            if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
              var check_author_exist = function (u) {
                var len = val.answers.length;
                for (var i = 0; i < len; i++) {
                  if (val.answers[i].author._id == u._id) {
                    return true;
                  }
                }
                return false;
              };

              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                if (!check_author_exist(u)) {
                  response.data.data[key].answers.push({
                    author: u
                  });
                }
              });

            } else if (!val.answers) {
              response.data.data[key].answers = [];

              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                response.data.data[key].answers.push({
                  author: u
                });
              });

            }
          });

          $scope.questions = response.data.data;
          // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
        } else {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        $scope.status.questions = 3;
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    $scope.initPageData = function () {
      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);

      if (current_url.indexOf("question-compare") > -1) {
        $scope.state = "question-compare";
        if ($routeParams.categoryId == 'all') {
          var param = {
            id: $routeParams.parentId,
            questions_counter: 1
          };
          getLevelCategory(param);
        } // traverse all questions
        else {
          //MyService.ConsoleLog("** question-compare **");
          //MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);
          $scope.questions = $rootScope.compareAnswerList;
          $scope.getCategory({
            id: $routeParams.categoryId
          });
        }
      } else if (current_url.indexOf("question-tagged") > -1) {
        $scope.state = "question-tagged";
        getQuestions({
          "tagged_to": $routeParams.user_id
        });
      } else if (current_url.indexOf("question-background") > -1) {
        $scope.state = "question-background";
        var param = {
          id: $routeParams.categoryId,
          questions_counter: 1
        };
        getLevelCategory(param);
      } else if (current_url.indexOf("question-search") > -1) {
        $scope.state = "question-background";
        var param = {
          keyword: 'test',
        };
        getQuestions(param);
      } else {
        $scope.state = "question";
        if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
          // getPopularQuestions();
          var categoryId = $routeParams.categoryId.split("popular:")[1];
          $scope.getPopularCategories({
            parentId: categoryId
          })
        } else {
          // getQuestions({categoryId: $routeParams.categoryId});
          $scope.getCategory({
            id: $routeParams.categoryId
          });
        }
      }
    };
    $scope.initPageData();
    $scope.skipAnswerChagne = function () {
      MyService.ConsoleLog("$scope.category._id ", $scope.category._id);
      $scope.currentIndex = 0;
      // alert('nn');
      // $scope.initPageData();
      getQuestions({
        categoryId: $scope.category._id
      });
    };


    $scope.checkIfExists = function (val) {
      // console.log("questions <><><>", $scope.questions);
      // MyService.ConsoleLog("Val > ", val);
      var obj = $scope.questions[$scope.currentIndex];
      // MyService.ConsoleLog("checking For calling >> ");
      if (obj) {
        var data = obj.my_answer;
        // console.log("data <>", data);
        if (data) {
          // MyService.ConsoleLog("checkIfExists data>> ", data.answer + "==" + val);
          if (data.answer == val) {
            // MyService.ConsoleLog("checkIfExists data>> ", data.answer + "==" + val);
            return true;
          }

        }
      }
      return false;
    }

    $scope.subNew = function (valOne) {
      $scope.importance = valOne;
      $scope.submitAnswer();
    };

    $scope.checkImpExists = function (sval) {
      var obj = $scope.questions[$scope.currentIndex];
      // MyService.ConsoleLog(" qobject :", obj);
      if (obj) {
        var data = obj.my_answer;
        // MyService.ConsoleLog("Data", data);
        if (data && data) {
          if (data.importance == sval) {
            // MyService.ConsoleLog("main", $scope.importance);
            // $scope.importance = data.importance;
            // MyService.ConsoleLog("Importance data>> ", sval + " AND ++++++++ " + data.importance);
            return true;
          }
        }
      }
      // MyService.ConsoleLog("false return");
      return false;
    }

    // $scope.matchQuestion = function() {
    //   // ngDialog.open({
    //   //   template: 'app/popups/matchQuestion.html'
    //   //   // className: 'ngdialog-theme-default'
    //   // });

    // }

    $scope.saveQuizForLater = function () {

      window.localStorage.setItem('showSavePopUp', true);
      var catId, selCat_id;
      catId = window.localStorage.getItem('cat_id');
      selCat_id = window.localStorage.getItem('selCat_id');
      var tempLink = $location.absUrl().split("#!");
      var quizLinks = tempLink[0] + "#!/question-background/" + catId + "/" + selCat_id;
      MyService.ConsoleLog("this is new", quizLinks);
      if ($scope.userId) {
        MyService.saveForLaterQuiz({
          saveLink: quizLinks,
          userId: $scope.userId,
          quizName: window.localStorage.getItem('fbName')
        }).then(function (APIResponse) {
          // MyService.ConsoleLog("APIResponse", APIResponse);
          $location.path('/snapshot');
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      } else {
        MyService.ConsoleLog("inside me");
        var quizLinkData = {
          saveLink: quizLinks,
          userId: $scope.userId,
          quizName: window.localStorage.getItem('fbName')
        };
        window.localStorage.setItem('quizLinkData', JSON.stringify(quizLinkData));
        $location.path('/fiveSaveUser');
      }
    };


    $scope.$watch('category', function (newValue, oldValue) {
      var current_url = $location.url();
      MyService.ConsoleLog("oldValue: ", oldValue);
      MyService.ConsoleLog("newValue: ", newValue);
      if (oldValue) {
        window.localStorage.removeItem('fbImg');
        window.localStorage.setItem('fbImg', newValue.fbImage);
        window.localStorage.setItem('fbName', oldValue.title);

      }
      $rootScope.OldCatforFB = oldValue;
      if (current_url.indexOf("question-tagged") > -1)
        return; //for tagged questions don't again fetch questions

      if (newValue) {
        //MyService.ConsoleLog("oldValue: ", oldValue);
        //MyService.ConsoleLog("newValue: ", newValue);
        if (oldValue && oldValue._id == newValue._id) return;

        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        $scope.currentIndex = 0;

        if (current_url.indexOf("question-compare") > -1) {
          //MyService.ConsoleLog("** getCompareAnswers **");

          var temp_uids = [];
          angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
            temp_uids.push(val._id);
          });

          getCompareAnswers({
            "categoryIds": newValue._id,
            "userIds": temp_uids.join(",")
          }); //"compare_with": $routeParams.user_type,
        } else {
          // alert('yo');
          // getQuestions({
          //   categoryId: $routeParams.selectedCatId
          // });
          getQuestions({
            categoryId: newValue._id
          });
        }
      }
    });

    $scope.$watch('questions', function (newValue, oldValue) {
      try {
        // MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if ($scope.questions[$scope.currentIndex] && $scope.questions[$scope.currentIndex].my_answer) {
          if ($scope.questions[$scope.currentIndex].my_answer.comment) {
            $scope.comment = $scope.questions[$scope.currentIndex].my_answer.comment;
          }

        }
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        MyService.ConsoleLog("one");
        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("questions Index Change Ex: ", e)
      }
    });

    $scope.$watch('currentIndex', function (newValue, oldValue) {
      try {
        // MyService.ConsoleLog("test", tes);
        MyService.ConsoleLog("$scope.currentIndex", $scope.currentIndex);
        MyService.ConsoleLog("$scope.questions.length", $scope.questions.length);
        // MyService.ConsoleLog("Current currentIndex: ", $scope.questions[$scope.currentIndex]);
        $scope.chkAnsQues = $scope.checkAnswerQuestions($scope.questionsData);
        if (!$scope.saveForLater && $scope.chkAnsQues && $scope.chkAnsQues.length > 0 && $scope.chkAnsQues.length != $scope.questionsData.length) {
          $scope.saveForLater = true;
        }
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        MyService.ConsoleLog("first", tempCate);
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        // MyService.ConsoleLog("two");

        $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("Current Index Change Ex: ", e)
      }
    });




    // if (window.localStorage.getItem("quesData")) {
    //   $scope.questionsData = JSON.parse(window.localStorage.getItem("quesData"));
    //   $scope.queIds = $scope.questionsData.map(function (itm) {
    //     if (itm.questionId) {
    //       return itm.questionId;
    //     } else {
    //       // console.log("itm", itm);
    //       return itm._id;
    //     }
    //   });
    //   // MyService.ConsoleLog("  $scope.queIds", $scope.queIds);

    // }

    $scope.AllAnsweredQuestions = [];
    $scope.getAllAnsweredQuestions = function (userId) {
      MyService.getAllAnsQuesWithIds({
        "authorId": tokenService.getUserId(),
        "ques": JSON.stringify($scope.queIds)
      }).then(function (payload) {
        MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload);
        if (payload.data.success) {
          $scope.AllAnsweredQuestions = payload.data.data;
          $scope.allQuestions = $scope.AllAnsweredQuestions;
          MyService.ConsoleLog("$scope.allQuestions", $scope.allQuestions);
          var tmpS = $scope.allQuestions.filter(function (itms, ind) {
            if (itms && itms.my_answer) {
              if (itms.my_answer.show == 'Y') {
                $scope.switchStatus[ind] = true;
              } else {
                $scope.switchStatus[ind] = false;
              }
            }
          });
          $scope.chooseQuestions();
          MyService.ConsoleLog("$scope.switchStatus", $scope.switchStatus);
          if ($scope.allQuestions.length == 0) {
            growl.error("There are no questions to display. Please go through all questions first.")
          }

        }
      }, function (errpayload) {
        MyService.ConsoleLog("getAllAnsweredQuestions errpayload >> ", errpayload);
      });
    };




    /**
     * Next
     */

    $scope.next = function () {
      $scope.showHidePop.close();
      if ($scope.userId && $scope.userId != '') {
        MyService.ConsoleLog("yes");
        MyService.ConsoleLog("catId", window.localStorage.getItem('cat_id'));
        var catId, selCat_id;
        catId = window.localStorage.getItem('cat_id');
        selCat_id = window.localStorage.getItem('selCat_id');
        MyService.ConsoleLog("selCat_id", window.localStorage.getItem('selCat_id'));
        var tempLink = $location.absUrl().split("#!");
        $scope.shareLink = tempLink[0] + "#!/exploreQuiz/" + catId + "/" + selCat_id + "/" + $scope.userId;
        MyService.ConsoleLog("here we go with link", $scope.shareLink);
        var modalUrl = ngDialog.openConfirm({
          template: 'exporeQuizLink',
          scope: $scope,
          width: "60%"
        });

        modalUrl.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (data == 1) {
              MyService.ConsoleLog("one");
            } else {
              // console.log('two :');
            }
          },
          function rejected(data) {
            $scope.fbwallDlg = ngDialog.open({
              template: 'nextQuestionOptions',
              scope: $scope
            });
          }
        );
      }
    };

    /**
     * Cancel
     */

    $scope.cancel = function () {
      $scope.showHidePop.close();
      $scope.fbwallDlg = ngDialog.open({
        template: 'nextQuestionOptions',
        scope: $scope
      });
    };




  }])
