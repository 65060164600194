"use strict";
angular
  .module("myApp.getRollVotes", ["ngRoute"])

  .config([
    "$routeProvider",
    function($routeProvider) {
      $routeProvider.when("/getRollVotes", {
        templateUrl: "app/getRollVotes/getRollVotes.html",
        controller: "getRollVotesCtrl"
      });
    }
  ])
  .controller("getRollVotesCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {
    $scope.loaderOne = false;
    $scope.currPage = 1;
    $scope.addVotes = function() {
      $scope.loaderOne = true;

      MyService.addRollVotes().then(function(response) {
        console.log("response >>>", response);
        $scope.loaderOne = false;

      })
    }


    $scope.myPagingFunction = function() {
      MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
      if ($scope.statusGetResPg != 1 && $scope.serachData > $scope.votesData.length) {
        $scope.currPage++;
        $scope.pageChanged($scope.currPage);
      }
    };

    $scope.pageChanged = function(newPage) {
      $scope.getVotes(newPage);
    };

    $scope.votesData = [];

    $scope.getVotes = function(pageNumber) {
      MyService.ConsoleLog('pageNumber >', pageNumber);
      var params = {
        page: pageNumber,
        max_records: 30
      };
      //MyService.ConsoleLog("params: ", params);
      // $scope.users.data = [];


      $scope.statusGetResPg = 1;

      var getAllCanceller = $q.defer();


      MyService.getVotesData(params, getAllCanceller).then(
        function(result) {
          console.log("RESPONSE >  ", result);
          // $scope.votes = $scope.votes.concat(result.data.data.data);
          $scope.votesData = $scope.votesData.concat(result.data.data.data);

          MyService.ConsoleLog('$scope.open >', $scope.votesData);
          $scope.serachData = result.data.data.count;
          MyService.ConsoleLog("serachData >>", $scope.serachData);
          // console.log($scope.committee);
          // $scope.totalUsers = payload.Count;
          // MyService.ConsoleLog(payload.Items);
          $scope.statusGetResPg = 2;
        },
        function(err) {
          // growl.error('Something went wrong.');
          $scope.statusGetResPg = 3;
          $scope.page--;
        }
      );
    };
    $scope.getVotes();



  }]);
