'use strict';
angular
  .module('myApp.answeredQuestions', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/answeredQuestions', {
        templateUrl: 'app/answeredQuestions/answeredQuestions.html',
        controller: 'answeredQuestionsCtrl',
      });
    },
  ])
  .controller('answeredQuestionsCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService
    ) {
      //MyService.ConsoleLog("** questionCtrl ** :", $routeParams);
      $rootScope.welcome = false;
      $scope.traverseBackgrounds = [];
      window.localStorage.removeItem('tempQues');

      $scope.currBackgroundIndex = 0;
      // $scope.flagSkipAnsweredQuestion = true;
      // alert("welcome");
      // $('#skipMatchOne').attr('disabled', false);
      // $scope.reason;
      // $scope.comment;
      $scope.skipMatchOne = 1;
      MyService.ConsoleLog(' $scope.skipMatchOne :', $scope.skipMatchOne);

      $scope.status = {
        questions: 0, //1= loading, 2 = loaded, 3 = error
        category: 0,
      };

      $scope.updata = {
        comment: '',
        reason: '',
      };
      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
      }

      /** 
       * 
       * @description Get front runners records from locaStorage if the user role is admin. 
       */
      if (tokenService.getUserRole() == 'admin') {

        $scope.frontRunners = JSON.parse(window.localStorage.getItem('frontRunners'));

        MyService.ConsoleLog("here is the frontRunners", $scope.frontRunners);
      }
      $scope.isUser = {
        selected: ''
      }

      /**
       * @description Function calls when admin select front runner to answer for.
       * @param {*} userId 
       */
      $scope.setUserId = function () {
        MyService.ConsoleLog("$scope.isUser", $scope.isUser.selected);
        $scope.questions = "";
        $scope.getAnsweredQuestions({
          frontRunnerId: $scope.isUser.selected._id
        });
      }


      var flagNw = 0;
      $scope.ansCheck = function (val) {
        // MyService.ConsoleLog("val >>>>>>>>>>>>", val);
        if (flagNw != 0) {
          $scope.chgAns(val);
        }
        flagNw++;
      };

      $scope.currentIndex = 0;
      $scope.initializeVariables = function () {
        // MyService.ConsoleLog("one");
        $scope.importance = null;
        $scope.answer = null;
        $scope.updata.comment = '';
        $scope.reason = '';
      };
      $scope.initializeVariables();

      $scope.initMyAnswer = function () {
        try {

          if ($scope.questions[$scope.currentIndex].my_answer) {
            MyService.ConsoleLog(
              '$scope.questions[$scope.currentIndex].my_answer :',
              $scope.questions[$scope.currentIndex].my_answer.answer
            );
            var user_answer = $scope.questions[$scope.currentIndex].my_answer;
            $scope.importance = user_answer.importance;
            $scope.answer = user_answer.answer;
            MyService.ConsoleLog("$scope.answer", $scope.answer);
            $scope.updata.comment = user_answer.comment;
            $('#agreedisagree-id' + user_answer.answer).prop('checked', true);
            $('#importance-id' + user_answer.importance).prop('checked', true);
            // console.log("$scope.answer > ",$scope.answer);
            // console.log("$scope.importance > ",$scope.importance);
          } else {
            $scope.initializeVariables();
          }
        } catch (e) {
          $scope.initializeVariables();
          //MyService.ConsoleLog("Exceptoin: ", e)
        }
      };

      var getQuestionsCanceler = $q.defer();
      /*
     function getQuestions(params) {
       getQuestionsCanceler.resolve();
       $scope.status.questions = 1;
       params.timeout = getQuestionsCanceler.promise;
       MyService.ConsoleLog("questionAnsByCtrl** /questions/list: ", params);
       $scope.questions = [];
       var request = apiCall.apiCall('GET', '/questions/list', params);
       $http(
         request
       ).then(function successCallback(response) {
         //MyService.ConsoleLog("questionAnsByCtrl Got categories: ", response);
         if (response.data.success) {
           $scope.status.questions = 2;
           $scope.questions = response.data.data;
           //MyService.ConsoleLog("total questions: ", $scope.questions);
         } else {
           $scope.status.questions = 3;
           if (typeof response.data.error == "string")
             growl.error(response.data.error);
           else {
             // growl.error("Something went wrong");
           }
         }
       }, function errorCallBack(response) {
         $scope.status.questions = 3;
         //MyService.ConsoleLog("Error: ", response);
       });
     }

     */
      $scope.getUserProfile = function () {
        MyService.getProfile($routeParams.user_id).then(
          function (userData) {
            $scope.userData = userData;
            MyService.ConsoleLog('userData: ', $scope.userData);
          },
          function (err) {
            growl.error('Something went wrong while fetching user info.');
          }
        );
      };

      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;
      $scope.categoryCalled = false;
      $scope.getCategory = function (params) {
        if (prevFetchedCategoryId != params.id) {
          //MyService.ConsoleLog("scrollTop");
          $scope.categoryCalled = true;
          $('html, body').animate({
              scrollTop: 0,
            },
            'slow'
          );

          getCategoryCanceler.resolve();
          $scope.status.category = 1;
          params.timeout = getCategoryCanceler.promise;
          var request = apiCall.apiCall('GET', '/categories/list', params);
          $http(request).then(
            function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.category = response.data.data;
                //MyService.ConsoleLog("category data: ", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
                $scope.status.category = 3;
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
              $scope.status.category = 3;
            }
          );
        } else {
          $('html, body').animate({
              scrollTop: $('#DisplayQue').offset().top - 100,
            },
            'slow'
          );

          /*
          $timeout(function() {
            MyService.ConsoleLog("called")
            $location.hash('DisplayQue');
            $anchorScroll();
          }, 100);
         */
        }
      };

      $scope.postAnswer = function (postData) {
        MyService.ConsoleLog('postData: ', postData);
        try {
          var my_answer = $scope.questions[$scope.currentIndex].my_answer;
          if (
            my_answer.importance != $scope.importance ||
            my_answer.answer != $scope.answer ||
            my_answer.comment != $scope.updata.comment
          ) {
            //MyService.ConsoleLog("Answer changed");
            // alert($scope.answer);
            postData.id = my_answer._id;
            postData.reason = $scope.updata.reason;
          } else {
            //MyService.ConsoleLog("Answer not changed");
            return; //don't do anything
          }
        } catch (e) {
          //MyService.ConsoleLog("Exceptoin: ", e);
        }
        //MyService.ConsoleLog(postData);
        MyService.ConsoleLog('postData Second: ', postData);

        MyService.postAnswer(postData).then(
          function (response) {
            document.getElementById('txtReason').value = '';
            // console.log("Result >", response.data);
            if (response.data.success) {
              try {
                if (postData.id) {
                  console.log('postData.id > ', postData);
                  $scope.questions[$scope.currentIndex]['my_answer'] = {
                    _id: postData.id,
                    created: new Date(),
                    comment: postData.comment,
                    answer: postData.answer,
                    importance: postData.importance,
                    question: postData.questionId,
                  };
                } else {
                  console.log('postData.id Else  > ', postData);
                  console.log('postData.id Else Data  > ', response.data.data);
                  $scope.questions[$scope.currentIndex]['my_answer'] = {
                    _id: response.data.data._id,
                    created: response.data.data.created,
                    comment: response.data.data.comment,
                    answer: response.data.data.answer,
                    importance: response.data.data.importance,
                  };
                }
              } catch (e) {
                //MyService.ConsoleLog("Exceptoin: ", e);
              }
              if (postData.id) {
                growl.success('Answer updated successfully', {
                  ttl: 800,
                });
              } else {
                growl.success('Answered successfully', {
                  ttl: 800,
                });
              }
              console.log('My ANs : ', $scope.questions[$scope.currentIndex].my_answer);
              $scope.initializeVariables();
              $scope.skipQuestions();
            } else {
              growl.error('Error: ' + response.data.error);
            }
          },
          function (err) {
            // growl.error("Something went wrong.");
          }
        );
      };

      $scope.chgAns = function (val) {
        console.log("$scope.answer val", val);
        $scope.answer = val;

        // $scope.checkIfExists(val);
      };
      $scope.chgImp = function (val) {
        $scope.importance = val;
        // $scope.checkImpExists(val);
      };

      $scope.submitAnswer = function () {
        // console.log($scope);
        MyService.ConsoleLog('submitAnswer: ' + $scope.answer);
        MyService.ConsoleLog('importance: ' + $scope.importance);
        MyService.ConsoleLog('comments: ', $scope.updata.comment);
        MyService.ConsoleLog('$scope.comments: ', $scope.comment);
        MyService.ConsoleLog('reason: ', $scope.updata.reason);

        var token = apiCall.getToken();

        if (!token || token == '') {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
          return;
        }

        if ($scope.answer == null) {
          var message = 'Please select answer first.';
          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );

          return;
        }

        if ($scope.importance == null) {
          var message =
            'You gave your opinion, but you didn’t say how important this is to you.  Should they fight hard for this or can they use it as a bargaining chip to get other things you care about more?';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
          return;
        }
        var postData;
        if (tokenService && tokenService.getUserRole() == 'admin' && $scope.isUser && $scope.isUser.selected && $scope.isUser.selected != '') {
          postData = {
            questionId: $scope.questions[$scope.currentIndex]._id,
            importance: $scope.importance,
            answer: $scope.answer,
            comment: $scope.updata.comment,
            reason: $scope.updata.reason,
            token: token,
            frontRunnerId: $scope.isUser.selected._id
          };
        } else {
          postData = {
            questionId: $scope.questions[$scope.currentIndex]._id,
            importance: $scope.importance,
            answer: $scope.answer,
            comment: $scope.updata.comment,
            reason: $scope.updata.reason,
            token: token,
          };
        }


        // MyService.ConsoleLog(postData);
        $scope.postAnswer(postData);
      };

      // $scope.comment;
      //   MyService.ConsoleLog("  $scope.comment :", $scope.comment);

      $scope.skipQuestions = function () {
        MyService.ConsoleLog(' questionssssssssssssssssssss called');
        $scope.answer = null;
        // $scope.categoryCalled = false;
        MyService.ConsoleLog(' $rootScope.queOneLen ', $rootScope.queOneLen);
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          MyService.ConsoleLog(' length of questions :', tempIndex);
          if (tempIndex < $scope.questions.length) {
            $scope.initializeVariables();
            $scope.currentIndex++;
            if ($routeParams.categoryId && $routeParams.categoryId.indexOf('popular:') == 0) {
              //we are showing questions of most popular section
              MyService.ConsoleLog('welcome to if');
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories.cid,
              });
            }
          }
          if (tempIndex >= 5) {
            // $scope.skipToRes = true;
            // $('#skipMatchOne').attr('disabled', false);
            $scope.skipMatchOne = 2;
          }
          MyService.ConsoleLog(
            'tempIndex = ',
            tempIndex,
            '$scope.questions.length ',
            $scope.questions.length
          );
          if (tempIndex == $scope.questions.length) {
            //MyService.ConsoleLog("end of questions switch background");
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            if (tempBackIdx < $scope.traverseBackgrounds.length) {
              $scope.currBackgroundIndex++;
              $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
              MyService.ConsoleLog(' category  id   : ', $scope.category);
            } else {
              //MyService.ConsoleLog("* End of background *");
              var message =
                'End of Questions';
              var modal = ngDialog.openConfirm({
                template: '<p>' +
                  message +
                  '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                        </div>',
                plain: true,
              });
              modal.then(
                function fullfilled(data) {
                  MyService.ConsoleLog('fullfilled: ', data);
                  // getCompareAnswers({
                  //   "compareQuestionsAll": JSON.stringify($scope.queIds),
                  //   "compareUsersAll": JSON.stringify($scope.compareUsersAll)
                  // });
                },
                function rejected(data) {
                  //MyService.ConsoleLog("rejected: ", data);
                }
              );
              return;
            }
          }
        };
        skip();
      };

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        // $timeout(function() {
        //   $location.hash('DisplayQ');
        //   $anchorScroll();
        // }, 1500);
        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
          // $scope.skipQuestions();
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      var getCompareAnswersCanceler = $q.defer();
      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);
      $scope.state = 'question-not-answered';
      $scope.status.questions = 1;
      $scope.compareQuestionsAll = [];

      $scope.getAnsweredQuestions = function (params) {
        // alert("called");
        MyService.getAnsweredQuestions(params).then(
          function (result) {
            MyService.ConsoleLog('result', result);
            MyService.ConsoleLog('matchQuestionwithPolitician :', result.data.data);
            if (result && result.data.data) {
              window.localStorage.setItem('tempQues', JSON.stringify(result.data.data));
              $scope.questions = result.data.data;

              $rootScope.ques = result.data.data;
            }
            if (result.data.error) {
              $scope.questions = JSON.parse(window.localStorage.getItem('tempQues'));
              $scope.ques.searchQuestionKeyword = '';
              growl.error(result.data.error);
            }
            $scope.questions.sort(function (a, b) {
              var x, y;
              x = a.categories[0].viewOrder;
              y = b.categories[0].viewOrder;
              if (x < y) {
                return -1;
              }
              if (x > y) {
                return 1;
              }
              return 0;
            });
            // MyService.ConsoleLog("dhADGDAD :",result.data.success);
            //MyService.ConsoleLog(payload.data.data.error);
            // $scope.flagSkipAnsweredQuestion = true;

            if ($scope.questions && $scope.questions.length > 0) {
              $scope.queIds = $scope.questions.map(function (obj) {
                if (obj != '' && obj != ' ') {
                  return obj._id;
                }
              });
            }

            // $rootScope.selectedCompareUsers=$scope.queIds;
            MyService.ConsoleLog('question ids :', $scope.queIds);
            // $scope.flagSkipAnsweredQuestion = true;
            // $scope.skipAnswerChagne();
          },
          function (err) {
            growl.error('Something went wrong');
          }
        );
      };

      $scope.getAnsweredQuestions();

      $scope.searchQuestion = function (searchKey) {
        $scope.getAnsweredQuestions({
          keyword: searchKey
        });
      };

      $scope.newSub = function (valOne) {
        $scope.importance = valOne;
        $scope.submitAnswer();
      };

      $scope.checkImpExists = function (val) {
        if ($scope.questions) {
          var obj = $scope.questions[$scope.currentIndex];
          // MyService.ConsoleLog("object :", obj);

          if (obj) {
            var data = $scope.checkQuestion(obj._id);

            if (data && data[0]) {
              // MyService.ConsoleLog("checkIfExists data>> ", data[0].importance + "==" + val);
              if (data[0].my_answer.importance == val) {
                return true;
              }
            }
          }
        }
        return false;
      };

      $scope.checkIfExists = function (val) {
        // MyService.ConsoleLog("click");
        if ($scope.questions) {
          var obj = $scope.questions[$scope.currentIndex];
          // MyService.ConsoleLog("checking For calling >> ");
          if (obj) {
            var data = obj.my_answer;
            // console.log("data <>", data);
            if (data) {
              if (data.answer == val) {
                // MyService.ConsoleLog("checkIfExists data>> ", data.answer + "==" + val);
                return true;
              }
            }
          }
        }
        return false;
      };

      $scope.checkQuestionAnswered = function () {
        // alert("called");
        // console.log("scope.questions > ", $scope.questions);
        return $scope.questions.filter(function (rdata) {
          // MyService.ConsoleLog(" questions Ids", rdata._id);
          var data = $scope.checkQuestion(rdata._id);
          if (data && data.length > 0) {
            return rdata;
          }
        });
      };

      $scope.checkQuestion = function (val) {
        // MyService.ConsoleLog("value ", val);
        var queLen = $scope.questions.length;
        // alert(queLen);
        // MyService.ConsoleLog("quelen :",queLen);
        for (var i = 0; i < queLen; i++) {
          if ($scope.questions[i].my_answer && $scope.questions[i].my_answer != '') {
            return $scope.questions.filter(function (rdata) {
              // MyService.ConsoleLog(" ANs data :", rdata);

              if (rdata.my_answer) {
                // MyService.ConsoleLog(" ANs data :", rdata.myAnswers.question);
                if (rdata.my_answer.question == val) {
                  return rdata;
                }
              }
            });
          }
        }
      };

      $scope.flagSkipAnsweredQuestion = false;
      $scope.cachedQuestions = [];
      $rootScope.queOneLen = [];
      // $rootScope.queOneLen.length=0;

      $scope.skipAnswerChagne = function () {
        MyService.ConsoleLog('scope.flagSkipAnsweredQuestion: ', $scope.flagSkipAnsweredQuestion);
        $scope.currentIndex = 0;
        var questions = $scope.checkQuestionAnswered();
        // $rootScope.fiveMatch.length = 0;
        MyService.ConsoleLog('questions >$$$ ', questions);
        MyService.ConsoleLog('questions  length ', questions.length);
        $rootScope.queOneLen = questions;
        MyService.ConsoleLog(' $rootScope.queOneLen ', $rootScope.queOneLen);

        if (questions.length >= 5) {
          // $scope.skipToRes = true;
          // $('#skipMatchOne').attr('disabled', false);
          $scope.skipMatchOne = 2;
        }

        if (questions && questions.length > 0) {
          if ($scope.flagSkipAnsweredQuestion) {
            // $scope.cachedQuestions.length = 0;
            $scope.cachedQuestions = angular.copy($scope.questions);
            for (var i = 0; i < questions.length; i++) {
              var idx = $scope.questions.indexOf(questions[i]);
              // console.log("idx : ", idx);
              if (idx > -1) {
                // console.log("idx > ", idx);
                $scope.questions.splice(idx, 1);
              }
            }
            // console.log("questionslength > ", $scope.cachedQuestions.length + "==" + $scope.questions.length)
            MyService.ConsoleLog('cachedQuestions :', $scope.cachedQuestions);
            if ($scope.cachedQuestions.length == questions.length) {
              // console.log("questionslength Equal");
              if ($scope.questions.length == 0) {
                // alert("if");
                // $scope.questions = angular.copy($scope.cachedQuestions);
                // MyService.ConsoleLog(" $rootScope.fiveMatch one :", $scope.questions);
                // var lents = $rootScope.fiveMatch.length;
                // if (lents >= 5) {
                //   // $scope.skipToRes = true;
                //   $('#skipMatch').attr('disabled', false);
                // }

                //MyService.ConsoleLog("* End of background *");
                var message =
                  'End of Questions';

                var modal = ngDialog.openConfirm({
                  template: '<p>' +
                    message +
                    '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                        </div>',
                  plain: true,
                });

                modal.then(
                  function fullfilled(data) {
                    MyService.ConsoleLog('fullfilled: ', data);
                    // getCompareAnswers({
                    //   "compareQuestionsAll": JSON.stringify($scope.queIds),
                    //   "compareUsersAll": JSON.stringify($scope.compareUsersAll)
                    // });
                  },
                  function rejected(data) {
                    //MyService.ConsoleLog("rejected: ", data);
                  }
                );
                return;

                $scope.status.questions = 2;
              }
              // $scope.saveFiveMinAnswers("skip");
            } else {
              // alert("esle");
              $rootScope.ques = angular.copy(questions);
              // $scope.questions = angular.copy(questions);
              // MyService.ConsoleLog(" questions", $rootScope.ques)
              // var lent = $rootScope.fiveMatch.length;
              // if (lent >= 5) {
              //   // $scope.skipToRes = true;
              //   $('#skipMatch').attr('disabled', false);
              // }
              // $scope.saveFiveMinAnswers("skipNew");
            }
          } else {
            // alert("if else");

            $scope.questions = angular.copy($scope.cachedQuestions);
          }
        } else {
          // alert("last else");

          if ($scope.flagSkipAnsweredQuestion) {
            if ($scope.cachedQuestions && $scope.cachedQuestions != '') {
              // alert("welcome");
              $scope.questions = angular.copy($scope.cachedQuestions);
              MyService.ConsoleLog(' $scope.questions :', $scope.questions);
            }
          }
        }
      };

      // $scope.skipAnswerChagne = function() {
      //   MyService.ConsoleLog("$scope.flagSkipAnsweredQuestion: ", $scope.flagSkipAnsweredQuestion);
      //   MyService.ConsoleLog(" $scope.category._id ", $scope.category._id);

      //   $scope.currentIndex = 0;
      //   // $scope.initPageData();
      //   getQuestions({
      //     categoryId: $scope.category._id
      //   });
      // };
      $scope.$watch('questions', function (newValue, oldValue) {
        MyService.ConsoleLog('*** questions changed ***');
        try {
          MyService.ConsoleLog('Current question: ', $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;

          if (angular.isArray(tempCate)) {
            // console.log("IF");
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            // console.log("ELSE IF");
            $scope.getCategory({
              id: tempCate.cid,
            });
          }
          //MyService.ConsoleLog($scope.questions.length + "====" + $scope.currentIndex);
          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("questions Index Change Ex: ", e)
        }
      });
      $scope.$watch('currentIndex', function (newValue, oldValue) {
        MyService.ConsoleLog('*** currentIndex changed ***');
        try {
          //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          var tempCate = $scope.questions[$scope.currentIndex].categories;
          if (angular.isArray(tempCate)) {
            console.log('currentIndex IF');
            $scope.getCategory({
              id: tempCate[0].cid,
            });
          } else {
            console.log('currentIndex ELSE IF');
            $scope.getCategory({
              id: tempCate.cid,
            });
          }
          MyService.ConsoleLog($scope.questions.length + '====' + $scope.currentIndex);
          $scope.initMyAnswer();
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });
    },
  ]);
