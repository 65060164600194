'use strict';

angular.module('myApp.registerToVote', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/registerToVote', {
         templateUrl: 'app/registerToVote/registerToVote.html',
         controller: 'registerToVoteCtrl' 
     });
}])
.controller('registerToVoteCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
