'use strict';
angular.module('myApp.whatAmILookingAt1', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/whatAmILookingAt1', {
      templateUrl: 'app/whatAmILookingAt1/whatAmILookingAt1.html',
      controller: 'whatAmILookingAt1Ctrl'
    });
  }])
  .controller('whatAmILookingAt1Ctrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
