'use strict';
angular.module('myApp.essayUpdate', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
        $routeProvider.when('/essayUpdate/:categoryId/:eid', {
            templateUrl: 'app/essayUpdate/essayUpdate.html',
            controller: 'essayUpdateCtrl'
        });
    }])
    .controller('essayUpdateCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService) {
        $scope.EssayButton = false;
        $rootScope.welcome = false;
        $scope.location = ['International', 'National', 'State', 'County', 'Local'];
        $scope.uData = JSON.parse(tokenService.getUserData());
        $scope.mediaType = ['Text', 'Video', 'Stills', 'Audio'];

        $scope.tinymceOptions = {
            plugins: [
                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                'searchreplace wordcount visualblocks visualchars code fullscreen',
                'insertdatetime media nonbreaking save table contextmenu directionality',
                'emoticons template paste textcolor colorpicker textpattern imagetools'
            ],
            toolbar: ''
        };

        $scope.subtopics = {
            subcategories: []
        };
        $scope.AddLocation = false;
        $scope.newlocation = {
            country: ''
        };
        $scope.Essay = {
            title: '',
            description: '',
            location: '',
            mediaType: 'Text',
            subcategories: []
        };

        $scope.restrictedOptions = ['Locals Only', 'No Restrictions'];

        $scope.selectedCat = [];
        $scope.SelectedTree = [];
        $scope.disabled = [];
        $scope.Allcountries = [];
        $scope.SelectedCountry = '';

        $scope.ShowAddLocation = function() {
            if (!$scope.AddLocation) {
                $scope.AddLocation = true;
            } else {
                $scope.AddLocation = false;
            }

        }

        $scope.getEssay = function() {
            MyService.getEssay($routeParams.eid).then(function(payload) {
                //MyService.ConsoleLog("Got Essay: ", response);
                $scope.getAllStates();
                var subcatdata = payload;
                //MyService.ConsoleLog("Essay : ", subcatdata);
                $scope.Essay = subcatdata;
                var locations = subcatdata.locationSpecific;
                var catArr = subcatdata.parentcat;
                //MyService.ConsoleLog(catArr[0].split(","));
                $scope.disabled = subcatdata.parentCat;
                $scope.SelectedTree = subcatdata.subCategories;
                //MyService.ConsoleLog($scope.disabled);
                var len = locations.length;
                $scope.selectedArea.length = 0;
                $scope.showSubCat = true;
                $scope.disabledLocation.length = 0;
                for (var l = 0; l < len; l++) {
                    var nobj = {};
                    if (locations[l].country) {
                        nobj.country = locations[l].country;
                    }
                    if (locations[l].state) {
                        nobj.state = locations[l].state;
                        nobj.statePostalCode = locations[l].statePostalCode;
                    }
                    if (locations[l].county) {
                        nobj.county = locations[l].county;
                    }
                    if (locations[l].countySubdivision) {
                        nobj.countySubdivision = locations[l].countySubdivision;
                    }
                    if (locations[l].place) {
                        nobj.place = locations[l].place;
                    }
                    $scope.selectedArea.push(nobj);
                    $scope.disabledLocation.push(nobj);
                }

                $scope.getSubcatTree();

            }, function errorCallBack(response) {
                //MyService.ConsoleLog("Error: ", response);
            });
        }




        function suggest_country(term) {
            var q = term.toLowerCase().trim();
            var results = [];
            var len = $scope.Allcountries.length;
            // Find first 10 states that start with `term`.
            for (var i = 0; i < len && results.length < 10; i++) {
                var country = $scope.Allcountries[i];
                if (country.toLowerCase().indexOf(q) === 0)
                    results.push({ label: country, value: country });
            }
            return results;
        }

        $scope.autocomplete_options = {
            suggest: suggest_country
        };

        $scope.getAllStates = function() {
            MyService.getAllStates().then(function(payload) {
                    $scope.allLocations.length = 0;
                    var subcatdata = payload;
                    //MyService.ConsoleLog("countires : ", subcatdata);
                    $scope.allLocations = subcatdata;


                },
                function(errorPayload) {
                    alert('Something went wrong ');
                });

        }

        $scope.getVal = function(data) {
            //MyService.ConsoleLog(data);
            var idx = $scope.selectedCat.indexOf(data);
            if (idx > -1) {
                $scope.selectedCat.splice(idx, 1);
                var id = $scope.disabled.indexOf(data._id);
                $scope.disabled.splice(id, 1);
                var jdx = $scope.SelectedTree.indexOf(data.parentIds);
                if (jdx > -1)
                    $scope.SelectedTree.splice(jdx, 1);

            } else {
                $scope.selectedCat.push(data);
                var id = $scope.disabled.indexOf(data._id);
                if (id == -1) {
                    $scope.disabled.push(data._id);

                }
                //MyService.ConsoleLog(data.parentIds);
                //MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
                if ($scope.SelectedTree.length == 0) {
                    if (data.parentIds.length > 0) {
                        //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
                        //angular.copy(data.parentIds, $scope.SelectedTree);
                        var parents = data.parentIds;
                        //MyService.ConsoleLog(parents);
                        $scope.SelectedTree = parents;
                    }
                } else {
                    if (data.parentIds.length > 0) {
                        //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
                        //var parents = angular.copy(data.parentIds);
                        var parents = data.parentIds;
                        var plen = parents.length;
                        for (var j = 0; j < plen; j++) {
                            $scope.SelectedTree.push(parents[j]);
                        }
                    }
                }
            }
            //MyService.ConsoleLog($scope.SelectedTree);
        }


        $scope.selectedAll = {
            id: []
        };
        $scope.removeVal = function(data) {
            var idx = $scope.selectedCat.indexOf(data);
            if (idx > -1) {
                $scope.selectedCat.splice(idx, 1);
                var id = $scope.disabled.indexOf(data._id);
                $scope.disabled.splice(id, 1);
                var parents = data.parentIds;
                var plen = parents.length;
                for (var j = 0; j < plen; j++) {
                    var jdx = $scope.SelectedTree.indexOf(parents[j]);
                    if (jdx > -1)
                        $scope.SelectedTree.splice(jdx, 1);
                }
            }
            $scope.selectedAll.id.length = 0;
            //MyService.ConsoleLog($scope.SelectedTree);
        }

        $scope.selectConfig = {
            requiredMin: 1,
            selectAll: true
        };

        $scope.checksubCatExists = function(rdata, val) {
            return rdata.filter(
                function(rdata) {
                    if (rdata._id == val)
                        return rdata
                    else if (rdata.subCategories) {
                        $scope.checksubCatExists(rdata.subcategories, val)
                    }
                }
            );
        }

        $scope.checkCatExists = function(rdata, val) {
            return rdata.filter(
                function(rdata) {
                    if (rdata._id == val) {
                        $scope.selectedCat.push(rdata);
                        return rdata
                    } else if (rdata.subcategories) {
                        $scope.checkCatExists(rdata.subcategories, val)
                    }
                }
            );
        }

        $scope.getSubcatTree = function() {
            $scope.selectedCat.length = 0;
            $scope.EssayButton = true;
            MyService.getAllCatTree({ "type": "E" }).then(function(payload) {
                    $scope.subtopics.subcategories.length = 0;
                    var subcatdata = payload;
                    // MyService.ConsoleLog("subcatdata : ", subcatdata);
                    $scope.subtopics.subcategories = subcatdata;
                    var selectedCat = $scope.disabled;
                    if (selectedCat.length > 0) {
                        for (var s = 0; s < selectedCat.length; s++) {
                            var checkcatdata = $scope.checkCatExists(subcatdata, selectedCat[s]);
                        }
                        //MyService.ConsoleLog($scope.selectedCat);
                    }
                    $scope.EssayButton = false;
                    setTimeout(function() {
                        var cols = document.getElementsByClassName('cmnUL');
                        var clen = cols.length;
                        for (var i = 0; i < clen; i++) {
                            cols[i].style.display = "none";
                        }
                    }, 300);
                },
                function(errorPayload) {
                    alert('Something went wrong ');
                });

        }
        $scope.allLocations = [];
        $scope.allCounties = [];
        $scope.selectedArea = [];
        $scope.rmselectedArea = [];
        $scope.myselectionarea = [];
        $scope.disabledLocation = [];

        $scope.checkExists = function(rdata, val) {
            return rdata.filter(
                function(rdata) {
                    if (rdata.state == val)
                        return rdata
                }
            );
        }

        $scope.checkCountyExists = function(rdata, val) {
            return rdata.filter(
                function(rdata) {
                    if (rdata.county == val)
                        return rdata
                }
            );
        }

        $scope.checkCountryExists = function(rdata, val) {
            return rdata.filter(
                function(rdata) {
                    if (rdata.country == val)
                        return rdata
                }
            );
        }

        function areEqual() {
            var len = arguments.length;
            for (var i = 1; i < len; i++) {
                if (arguments[i] == null || arguments[i] != arguments[i - 1])
                    return false;
            }
            return true;
        }

        function indexOfExt(list, item) {
            var len = list.length;

            for (var i = 0; i < len; i++) {
                var keys = Object.keys(list[i]);
                var flg = true;
                var arr = [];


                for (var j = 0, s = 1; j < keys.length; j++, s++) {
                    var value = list[i][keys[j]];
                    if (item[keys[j]] !== value) {
                        flg = false;

                    }

                    if (item[keys[j]] == value) {
                        arr.push(flg);
                    }


                }
                if (flg == true) {
                    if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
                        return i;
                    }

                }


            }
            return -1;
        }

        $scope.checkDisabled = function(data, stateId, type) {

            data.country = "United States of America";
            data.state = stateId;

            var idx = indexOfExt($scope.disabledLocation, data);
            if (idx > -1)
                return true;
            else
                return false;


        }

        $scope.getAreaVal = function(data, stateId, type) {

            if (!data.country) {
                data.country = "United States of America";
            }
            if (!data.state) {
                data.state = stateId;
            }



            var idx = indexOfExt($scope.selectedArea, data);


            if (idx > -1) {
                alert("You have already selected the location");
            } else {

                $scope.selectedArea.push(data);
                $scope.disabledLocation.push(data);

            }

            //MyService.ConsoleLog($scope.disabledLocation);
        }
        $scope.selectedLAll = {
            id: []
        };
        $scope.removeAreaVal = function(data) {
            var idx = $scope.selectedArea.indexOf(data);

            //MyService.ConsoleLog($scope.Essay.Locations);
            if (idx > -1) {
                //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
                if ($scope.selectedArea[idx].country == "International") {
                    $scope.Essay.Locations = 'NO';
                }
                if ($scope.selectedArea[idx].country == "National (USA)") {
                    $scope.Essay.National = 'NO';
                }
                $scope.selectedArea.splice(idx, 1);
                var jdx = $scope.disabledLocation.indexOf(data);
                if (jdx > -1) {
                    $scope.disabledLocation.splice(jdx, 1);
                }
            }
            $scope.selectedLAll.id.length = 0;
            //MyService.ConsoleLog($scope.selectedArea);

        }
        $scope.allCounties = [];
        $scope.allLocals = [];
        $scope.setLocation = function(option) {
            $scope.Essay.location = option;
        }
        $scope.getLocations = function(val, type) {
            if (type == "National") {
                if (val == "National") {

                    var nobj = {
                        country: "National (USA)"
                    }
                    $scope.selectedArea.push(nobj);
                    //MyService.ConsoleLog($scope.selectedArea);
                }
                if (val == "NO") {

                    var nobj = {
                        country: "National (USA)"
                    }
                    var idx = indexOfExt($scope.selectedArea, nobj);
                    if (idx > -1) {
                        $scope.selectedArea.splice(idx, 1);
                    }
                }
            } else if (type == "International") {
                if (val == "International") {
                    // $scope.selectedArea.length = 0;
                    var nobj = {
                        country: "International"
                    }
                    $scope.selectedArea.push(nobj);

                }
                if (val == "NO") {
                    // $scope.selectedArea.length = 0;
                    var nobj = {
                        country: "International"
                    }
                    var idx = indexOfExt($scope.selectedArea, nobj);
                    if (idx > -1) {
                        $scope.selectedArea.splice(idx, 1);
                    }
                }
            }
        }

        $scope.AddCountry = function() {
            if (($scope.newlocation.country) && ($scope.newlocation.country != '')) {
                var idx = $scope.Allcountries.indexOf($scope.newlocation.country);
                if (idx > -1) {
                    var nobj = {
                        country: $scope.newlocation.country
                    };
                    $scope.selectedArea.push(nobj);
                } else {
                    alert("Country does not exists in our database. Please check if you have entered correct name.");
                }
            } else {
                alert("Please enter country name");
            }
        }

        $scope.getCounties = function(state) {
            $scope.showLoading.push(state);
            if (!$scope.allCounties[state] || $scope.allCounties[state].length == 0) {
                MyService.getAllCounties(state).then(function(payload) {
                        $scope.showLoading.length = 0;
                        var sublocations = payload;
                        if (!$scope.allCounties) {
                            $scope.allCounties = [];
                        }
                        $scope.allCounties[state] = sublocations;
                    },
                    function(errorPayload) {
                        alert('Something went wrong.');
                    });

            } else {
                $scope.showLoading.length = 0;
                $scope.allCounties[state].length = 0;
            }
        }

        $scope.getLocals = function(state, county) {
            $scope.showLoadingCounty.push(county);
            if (!$scope.allLocals[state] || !$scope.allLocals[state][county] || $scope.allLocals[state][county].length == 0) {
                MyService.getAllSubCountiesLocal(state, county).then(function(payload) {
                        $scope.showLoadingCounty.length = 0;
                        //MyService.ConsoleLog("sublocations Locals : ", sublocations);
                        if (!$scope.allLocals[state]) {
                            $scope.allLocals[state] = [];
                        }
                        $scope.allLocals[state][county] = payload;
                    },
                    function(errorPayload) {
                        alert('Something went wrong ');
                    });
            } else {
                $scope.showLoadingCounty.length = 0;
                $scope.allLocals[state][county].length = 0;
            }
        }

        if (!$rootScope.UserId) {
            $location.path('signin');
        }

        if (window.localStorage.getItem("VoteWiseEssayCategory")) {
            $rootScope.allEssaycategories = JSON.parse(window.localStorage.getItem("VoteWiseEssayCategory"));
            var idx = $rootScope.allEssaycategories.indexOf($routeParams.subcatId);
            if (idx == -1) {
                $rootScope.allEssaycategories.push($routeParams.subcatId);
                window.localStorage.setItem("VoteWiseEssayCategory", JSON.stringify($rootScope.allEssaycategories));
            }
        }

        $scope.showSubCat = true;
        $scope.showLoading = [];
        $scope.showLoadingCounty = [];
        $scope.showDown = [];
        $scope.ChangeShow = function(id) {
            //$scope.showSubCat = true;
            //var cols = document.getElementsByClassName('cmnUL');
            //var clen = cols.length;
            var nclass = "MainCatLi_" + id;
            if ((document.getElementsByClassName(nclass)[0].style.display) && (document.getElementsByClassName(nclass)[0].style.display == "none")) {
                document.getElementsByClassName(nclass)[0].style.display = "block";
            } else {
                document.getElementsByClassName(nclass)[0].style.display = "none";
            }
            /*
            for (var i = 0, j = 1; i < clen; i++, j++) {
                if (cols[i].style.display == "none") {
                    if (j == clen) {
                        var nclass = "MainCatLi_" + id;
                        if ($scope.showDown.length > 0 && $scope.showDown[0] == id) {

                            document.getElementsByClassName(nclass)[0].style.display = "none";
                            $scope.showDown.splice(0, 1);
                            $scope.showDown.length = 0;

                        } else {
                            $scope.showDown.splice(0, 1);
                            $scope.showDown.length = 0;

                            $scope.showDown.push(id);
                            document.getElementsByClassName(nclass)[0].style.display = "block";
                        }

                        var idx = $location.hash();
                        $location.hash(id);
                        $anchorScroll();
                        $location.hash(idx);                        
                    }
                } else {
                    cols[i].style.display = 'none';
                }

            }

            */
        }


        $scope.UpdateEssay = function(data) {
            //MyService.ConsoleLog($scope.disabled);
            //MyService.ConsoleLog($scope.SelectedTree);
            if ($.fn.validateForceFully($("#topicform")) == true) {
                if (($scope.disabled.length == 0) && ($scope.SelectedTree == 0)) {
                    alert("Please select atleast one Tag");
                } else {
                    $scope.EssayButton = true;
                    if (data.mediaType == 'Stills') {
                        //MyService.ConsoleLog(data.files);
                        if (data.files) {
                            var fd = new FormData();
                            fd.append('file', data.files);
                            fd.append('essayId', $routeParams.eid);
                            fd.append("parentcat", $scope.disabled);
                            fd.append("subcategories", JSON.stringify($scope.SelectedTree));
                            fd.append("createdById", tokenService.getUserId());
                            fd.append("createdByName", tokenService.getUserName());
                            fd.append("createdByType", tokenService.getUserRole());
                            fd.append("title", data.title);
                            fd.append("description", data.description);
                            fd.append("mediaType", data.mediaType);
                            fd.append("location", data.location);
                            if ($scope.selectedArea.length > 0) {
                                fd.append("locationSpecific", JSON.stringify($scope.selectedArea));
                            }
                            var udata = $scope.uData;
                            var posturl = baseApiUrl + "/essay/update?mediaType=" + data.mediaType;

                            $http.post(posturl, fd, {
                                    transformRequest: angular.identity,
                                    headers: { 'Content-Type': undefined },
                                    params: {
                                        'folderName': udata.uname
                                    }
                                })
                                .success(function(response, status, headers, config) {
                                    $scope.EssayButton = true;
                                    //MyService.ConsoleLog("response : ", response);
                                    if (response.success) {
                                        $location.path('/essay/' + $routeParams.categoryId + '/home');
                                    } else {
                                        alert(response.data.error);
                                        if ((response.data.status) && (response.data.status == "405")) {
                                            var msg = "Do you want to send request to Admin to reactivate your account?.";
                                            MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function(payload) {
                                                MyService.sendActivatationMailtoAdmin().then(function(payload) {
                                                    alert("Request sent successfully to admin");
                                                }, function(errorPayload) {
                                                    alert("Something went wrong");
                                                })
                                            }, function(errorPayload) {
                                                //MyService.ConsoleLog(errorPayload);
                                            });
                                        }
                                    }
                                })
                                .error(function(data, status, headers, config) {
                                    //MyService.ConsoleLog("error");
                                });
                        } else {
                            alert("File is required.");
                        }


                    } else {
                        data.essayId = $routeParams.eid;
                        data.parentcat = $scope.disabled;
                        data.subcategories = JSON.stringify($scope.SelectedTree);
                        data.createdById = tokenService.getUserId();
                        data.createdByName = tokenService.getUserName();
                        data.createdByType = tokenService.getUserRole();
                        if ($scope.selectedArea.length > 0) {
                            data.locationSpecific = JSON.stringify($scope.selectedArea);
                        }
                        //MyService.ConsoleLog("data: ", data);

                        var request = apiCall.apiCall('POST', '/essay/update?mediaType=' + data.mediaType, data);
                        $http(
                            request
                        ).then(function successCallback(response) {
                            //MyService.ConsoleLog("Got Essay: ", response);
                            $scope.EssayButton = true;
                            if (response.data.success) {
                                $location.path('/essay/' + $routeParams.categoryId + '/home');
                            } else {
                               alert(response.data.error);
                                if ((response.data.status) && (response.data.status == "405")) {
                                    var msg = "Do you want to send request to Admin to reactivate your account?.";
                                    MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function(payload) {
                                        MyService.sendActivatationMailtoAdmin().then(function(payload) {
                                            alert("Request sent successfully to admin");
                                        }, function(errorPayload) {
                                            alert("Something went wrong");
                                        })
                                    }, function(errorPayload) {
                                        //MyService.ConsoleLog(errorPayload);
                                    });
                                }
                            }
                        }, function errorCallBack(response) {
                            //MyService.ConsoleLog("Error: ", response);
                        });
                    }

                }


            }

            //alert("This is not implemented yet");
        }
        $scope.MainGallery = [];
        $scope.GalleryFolder = baseApiUrl + "/resources/" + $scope.uData.uname;
        $scope.loadGallery = function() {
            var nrequest = apiCall.apiCall('GET', '/gallery/get', { id: $scope.uData.id });
            $http(
                nrequest
            ).then(function successCallback(responseLatest) {
                if (responseLatest.data.success) {
                    $scope.MainGallery = responseLatest.data.data;
                }


            }, function errorCallBack(responseLatest) {

                subCategories.returndata = sampledata;
                $scope.subCategories.push(subCategories);
            });
        }

        $scope.Showgallery = function() {
            ngDialog.open({
                template: 'newEssay/dialogGallery.html',
                width: '650px',
                scope: $scope
            });
            $scope.loadGallery();
        }
        $scope.uploadMedia = false;
        $scope.Gallery = false;
        $scope.uploads = {
            files: '',
            path: ''
        }
        $scope.ChangeMedia = function(option) {
            if (option == "G") {
                $scope.uploadMedia = false;
                $scope.Gallery = true;
            } else {
                $scope.Gallery = false;
                $scope.uploadMedia = true;
            }


        }




        $scope.uploadFile = function() {
            var fd = new FormData();
            //Take the first selected file
            var udata = $scope.uData;
            var urole = JSON.parse(tokenService.getUserData());

            $scope.userData = {
                id: udata.id,
                name: udata.name,
                uname: udata.uname,
                utype: tokenService.getUserRole()
            };
            $scope.uploads.files.fieldname = udata.uname;

            fd.append('file', $scope.uploads.files);
            fd.append("user", JSON.stringify($scope.userData));


            var posturl = baseApiUrl + "/gallery/upload";


            $http.post(posturl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined },
                params: {
                    'folderName': udata.uname
                }
            })

            .success(function(response, status, headers, config) {
                if (response.data) {
                    $scope.loadGallery();
                    $scope.ChangeMedia("G");
                }
            })

            .error(function(data, status, headers, config) {
                //MyService.ConsoleLog("error");
            });
        };

        $scope.ShowPath = false;

        $scope.ShowSelectedPath = function(filePath) {
            $scope.uploads.path = $scope.GalleryFolder + "/" + filePath;
            $scope.ShowPath = true;
        }

        $scope.goBack = function() {
            var len = $rootScope.allEssaycategories.length;
            var type = 'home';
            if (len > 2) {
                type = "subcat";
            }
            $location.path('/essay/' + $routeParams.categoryId + '/home');
            //$location.path('essay/' + $rootScope.allEssaycategories[len - 2] + '/' + $rootScope.allEssaycategories[len - 1] + "/" + type);
        }

        if (($routeParams.eid) && ($routeParams.categoryId)) {
            $scope.getEssay();
            MyService.getAllCountries().then(function(payload) {
                    $scope.Allcountries = payload;
                },
                function(errorPayload) {
                    alert('Something went wrong ');
                });
            $("form").validationEngine();
        } else {
            $location.path('essay/' + $routeParams.categoryId);
        }
    }]);
