'use strict';
angular.module('myApp.essayList', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/essayList/:categoryId/:subcatId/:type', {
    templateUrl: 'app/essayList/essayList.html',
    controller: 'essayListCtrl'
  });
}])
.controller('essayListCtrl', ['$scope','$http','$location','apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService) {
	$rootScope.welcome = false;
	$scope.categoryId = $routeParams.categoryId;
	$scope.subcategoryId = $routeParams.subcatId;
	$scope.type = $routeParams.type;
	$scope.category = {};
	$scope.subcategory = {};
	$scope.DisplayData = [];
	$scope.userType = tokenService.getUserRole();
	$scope.Sticky = {
		txtOrder: 0
	};
	
	

	if(window.localStorage.getItem("VoteWiseEssayCategory")) {
		$rootScope.allEssaycategories = JSON.parse(window.localStorage.getItem("VoteWiseEssayCategory"));
		var idx = $rootScope.allEssaycategories.indexOf($routeParams.subcatId);
		
		if(idx==-1) {
			$rootScope.allEssaycategories.push($routeParams.subcatId);
			window.localStorage.setItem("VoteWiseEssayCategory", JSON.stringify($rootScope.allEssaycategories));		
		}	
	}
	
	
	$scope.getForumList = function() {
		var nrequest = apiCall.apiCall('GET', '/essay/getList', {maincatId: $routeParams.categoryId, subcatId: $routeParams.subcatId});
		$http(
		    nrequest
		  ).then(function successCallback(responseLatest) {
		  	//MyService.ConsoleLog("Final Data");
		  	//MyService.ConsoleLog(responseLatest.data.data);
			if(responseLatest.data.success){
				$scope.DisplayData = responseLatest.data.data;
			}
		  }, function errorCallBack(responseLatest) {
		    alert("Error : "+responseLatest);
		  });
	}



	function getSubCategories() {
		var request = apiCall.apiCall('GET', '/categories/list', {id: $routeParams.subcatId});
		  $http(
		    request
		  ).then(function successCallback(response) {
			if(response.data.success){
				$scope.subcategory = response.data.data;
			}
			else{
				alert("Something went wrong: ");
			}
		  }, function errorCallBack(response) {
		    alert("Error: "+response);
		  });		
	}

	function getCategory() {
	  var request = apiCall.apiCall('GET', '/categories/list', {id: $routeParams.categoryId});
	  $http(
	    request
	  ).then(function successCallback(response) {
		//MyService.ConsoleLog("Got categories: ",response);
		if(response.data.success){
			$scope.category = response.data.data;
		}
		else{
			alert("Something went wrong: ");
		}
	  }, function errorCallBack(response) {
	    alert("Error: "+response);
	  });
	}

	$scope.gotoThread = function(id) {
		
		var categoryId = $routeParams.categoryId;
		var subcatid = $routeParams.subcatId;
		$location.path('essayDetails/'+categoryId+'/'+subcatid+'/'+id);
	}

	$scope.updatePostApi = function(data, options, idex) {
		
		var request = apiCall.apiCall('POST', '/topics/update', data);
		 	$http(
				request
				).then(function successCallback(response) {
					//MyService.ConsoleLog(response);
				 	if(response.data.data.nModified==0) {
				 		alert("Something went wrong. Please try after sometime.");
				 	} else {
				 		$scope.getForumList();
				 		
				 	}
				
				}, function errorCallBack(response) {
				//MyService.ConsoleLog("Error: ",response);
			});
	}


	$scope.objectKeys = function(obj){
	  return Object.keys(obj);
	}
	
	$scope.updatePost = function(option, id) {
		var data = {};
		if(option=="sticky") {
			data.poststicky = 1;
			data.poststickyOrder = $scope.Sticky.txtOrder[id];
			data.topicId = id;
			
		} else {
			data.removepostSticky = 1;
			data.topicId = id;

		}
		//MyService.ConsoleLog(data);
		$scope.updatePostApi(data);
	}
	

	if($routeParams.categoryId){
		getCategory();
		getSubCategories();
		$scope.getForumList();
	}

}]);
