'use strict';

angular.module('myApp.digDeeperView', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/digDeeper', {
      templateUrl: 'app/dig_deeper/dig_deeper.html',
      controller: 'digDeeperCtrl'
    });
  }])

  .controller('digDeeperCtrl', ['$scope', '$location', '$timeout', function($scope, $location, $timeout) {
    // var returningUser = apiCall.getToken();
    //
    // if (returningUser) {
    //   $location.path('/debateHome');
    // }
    //$scope.showFacebookLoging = false;

   // $scope.videoURL = "https://www.youtube.com/watch?v=HrZqeFsbQZk";
    $scope.videoID = "HrZqeFsbQZk";

    $scope.playerReady = function(event) {
        MyService.ConsoleLog(event); // Event data logged
    };
    
    $scope.playerStateChanged = function(event) {
        MyService.ConsoleLog(event); // Event data logged
        MyService.ConsoleLog(event.data);
        if(event.data == 0) {
          MyService.ConsoleLog("inside data 0");
          $location.path("/signup/voter");
          $scope.$apply();
        }
    };

     $scope.$on('youtube.player.ended', function ($event, player) {
        MyService.ConsoleLog("inside player end");
           $location.path("/signup/voter");
          // $scope.$apply();
     });

    
  }]);
