'use strict';
angular.module('myApp.printUsers', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
        $routeProvider.when('/print-users', {
            templateUrl: 'app/printUsers/printUsers.html',
            controller: 'PrintUserCtrl'
        });
}])
.controller('PrintUserCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService) {
    //MyService.ConsoleLog("* PrintUserCtrl *", $rootScope.printUsers); 
    $scope.printUsers = [];
    $rootScope.welcome = false;
    if($rootScope.printUsers && $rootScope.printUsers.length > 0){
        angular.forEach($rootScope.printUsers, function (val, key) {
            val.groupByKey = val.office+", "+val.running_for_location;
            $scope.printUsers.push(val);
        });
    }
    // obj.getUserList = function(params, canceler) {
}]);
