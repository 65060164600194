'use strict';
angular.module('myApp.whatAmILookingAt4', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/whatAmILookingAt4', {
      templateUrl: 'app/whatAmILookingAt4/whatAmILookingAt4.html',
      controller: 'whatAmILookingAt4Ctrl'
    });
  }])
  .controller('whatAmILookingAt4Ctrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
