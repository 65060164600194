'use strict';
angular.module('myApp.newsFeed', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/newsFeed', {
      templateUrl: 'app/newsFeed/newsFeed.html',
      controller: 'newsFeedCtrl'
    });
  }])

  .controller('newsFeedCtrl', ['$scope', '$rootScope', '$http', '$location', 'apiCall', 'baseApiUrl', 'tokenService', 'ngDialog', 'growl', 'MyService', function ($scope, $rootScope, $http, $location, apiCall, baseApiUrl, tokenService, ngDialog, growl, MyService) {
    $scope.imgUrl = baseApiUrl + "/resources";
    $scope.userId = tokenService.getUserId();
    $scope.userRole = tokenService.getUserRole();
    $scope.userData = JSON.parse(tokenService.getUserData());

    $rootScope.welcome = false;
    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;


    /**
     * Redirect
     */
    $scope.goto = function () {
      $location.path('/verifyBy');
    };

    /**
     * Close it
     */
    $scope.closeIt = function () {
      $scope.PopDialog.close();
    };
    $scope.getmyData = function () {
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: tokenService.getUserId(),
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          $scope.verify = $scope.userProfile.verifiedUser;
          // MyService.ConsoleLog("welcome", $scope.verify);
          // console.log("$scope.userDetails", $scope.userDetails.userData.address);
          if (!$scope.userProfile.verifiedUser) {
            $scope.showCon = true;
          }
          if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
            // $scope.getPopUp();
            if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
              $scope.showCon = true;
              $scope.showBoth = true;
            } else if (!$scope.userProfile.isEmailVerified) {
              $scope.showEm = true;
            }
            $scope.PopDialog = ngDialog.open({
              template: 'popUp',
              closeByNavigation: true,
              scope: $scope,
              width: '50%'
            });
          }


        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    }

    if (tokenService.getUserId()) {
      $scope.getmyData();
    }


  }]);
