'use strict';

angular.module('myApp.showAnswers', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/showAnswers/:type/:answers/:qId/:chAns', {
      templateUrl: 'app/showAnswers/showAnswers.html',
      controller: 'showAnswersCtrl'
    });
  }]).controller('showAnswersCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {
    MyService.ConsoleLog("Route Params >>", $routeParams.type);
    MyService.ConsoleLog("Route Params >>", $routeParams.answer);
    MyService.ConsoleLog("Route Params >>", $routeParams.chAns);
    $scope.showPoliAnswerDetails = false;
    $scope.showUserAnswerDetails = false;
    $scope.showLoader = false;

    $scope.getAnwers = function (par) {

      var pans = $routeParams.answers;
      var chAns = $routeParams.chAns;
      MyService.ConsoleLog("pans", pans);
      // if (chAns != "") {
      MyService.showUsersAnswers({
        "obj": par,
        "checkanswer": pans,
        "qId": $routeParams.qId
      }).then(function (payload) {
        $scope.quesId = $routeParams.qId;
        MyService.ConsoleLog("Payload>", payload.data.data);
        MyService.ConsoleLog("$routeParams.type>", $routeParams.type);
        $scope.showLoader = false;
        switch ($routeParams.type) {
          case 'politician':
            $scope.globalArray = payload.data.data.poliArr;
            $scope.showPoliAnswerDetails = true;
            break;
          case 'voter':
            $scope.globalArray = payload.data.data.voterArr;
            $scope.showPoliAnswerDetails = true;
            break;
          case 'advocate':
            $scope.globalArray = payload.data.data.advocateArr;
            $scope.showPoliAnswerDetails = true;
            $scope.orgName = $cookies.get('orgName');
            break;
          case 'guestUser':
            $scope.unVeriComm = payload.data.data.guestArr;
            $scope.showUserAnswerDetails = true;
            break;
          default:

            break;
        }

      }, function (error) {
        MyService.ConsoleLog("Error >", error);
      });
      // } else {

      // }
    };

    if (window.localStorage.getItem('comData')) {
      $scope.showLoader = true;
      // var ques = window.localStorage.getItem('quesData');
      $scope.compare = window.localStorage.getItem('comData');
      MyService.ConsoleLog("<<<<<<<<<<<< questions >>>>>>>>>>>", JSON.parse($scope.compare));
      var tempData;
      if (window.localStorage.getItem('compareData')) {
        tempData = JSON.parse($scope.compare);
        tempData.usersIds = [];
        tempData.usersIds = JSON.parse(window.localStorage.getItem('compareData'));
        MyService.ConsoleLog("tempData", tempData);
        $scope.getAnwers(JSON.stringify(tempData));
        window.localStorage.getItem('compareData');
      } else {
        $scope.getAnwers($scope.compare);
      }

    }

    $scope.getComapreClass = function (match, i) {
      //MyService.ConsoleLog("match: ", match);
      /*if(match == 100)
        return 'ag-green';
    if(match == 0)
        return 'ag-red';
    if(match > 0 && match <100)
        return 'ag-dg'+i;*/
      if (match >= 100) return 'ag-dg1';
      if (match >= 80) return 'ag-dg2';
      if (match >= 60) return 'ag-dg3';
      if (match >= 40) return 'ag-dg4';
      if (match >= 20) return 'ag-dg5';
      if (match >= 0) return 'ag-dg6';

      return 'ag-dg';
    };

    $scope.back = function () {
      $location.path('/findMatch');
    };

  }]);
