'use strict';
angular.module('myApp.compareSubjectWise', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compare-subject/:user_type', {
      templateUrl: 'app/compare_subject/compare_subject.html',
      controller: 'compareSubjectWiseCtrl'
    });
  }])
  .controller('compareSubjectWiseCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$filter', 'MyService', 'growl', '$q', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $filter, MyService, growl, $q) {
    //MyService.ConsoleLog("** compareSubjectWiseCtrl **", $routeParams, "$rootScope.selectedCompareUsers: ",$rootScope.selectedCompareUsers);

    $scope.usertype = $routeParams.user_type;
    $scope.categories = [];
    $rootScope.welcome = false;
    var getCategoriesCanceler = $q.defer();
    $scope.statusCat = 0;
    window.localStorage.removeItem('fiveQueIds');
    $scope.fiveMinData = [];
    $scope.showLoader = false;
    $scope.checkTotalAns = function () {
      var checkTotalAnswers = $scope.categories.filter(function (itms) {
        if (itms.total_answers != 0) {
          MyService.ConsoleLog("itms", typeof itms.total_answers);
          return itms.total_answers;
        }
      });
      MyService.ConsoleLog("checkTotalAnswers>>>>>>>>", checkTotalAnswers);
      if (checkTotalAnswers.length == 1) {
        $location.path("/compare-subcategories/" + checkTotalAnswers[0]._id);
      }
    };

    $scope.getFiveMin = function () {
      $scope.showLoader = true;
      var temp_userids = [];
      if ($rootScope.selectedCompareUsers && $rootScope.selectedCompareUsers.length > 0) {
        angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
          // body...
          temp_userids.push(val._id);
        });
      }
      var params = {};
      params.answer_count_userids = temp_userids.join(",");
      MyService.getFiveMinCount(params).then(function (APIResponse) {
        MyService.ConsoleLog("APIResponse", APIResponse);
        if (APIResponse && APIResponse.data.status) {
          $scope.queIds = APIResponse.data.data.queIds;
          $scope.allQueIds = APIResponse.data.data.allQueIds;
          $scope.showLoader = false;
          $scope.fiveMinData.push({
            title: 'Five Minute Quiz',
            imgUrl: './assets/images/5minQuizIcon.jpg',
            ansCount: APIResponse.data.data.ansCount,
            queIds: $scope.queIds
          })

          if (APIResponse.data.data.allTopicCount != '') {
            $scope.fiveMinData.push({
              title: 'All Topics',
              imgUrl: './assets/images/allTopics7.svg',
              ansCount: APIResponse.data.data.allTopicCount,
              queIds: $scope.allQueIds
            })
          }


        } else {
          $scope.showLoader = false;
        }
      }, function (err) {

        MyService.ConsoleLog("err", err);
      });

    };

    function getCategories() {
      MyService.ConsoleLog("getCategories: ***");
      getCategoriesCanceler.resolve();
      getCategoriesCanceler = $q.defer();
      var params = {
        root: 1,
        questions_counter: 1,
        catTypes: "Q,B"
      };
      var temp_userids = [];
      if ($rootScope.selectedCompareUsers && $rootScope.selectedCompareUsers.length > 0) {
        angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
          // body...
          temp_userids.push(val._id);
        });
      }
      params.answer_count_userids = temp_userids.join(",");
      MyService.ConsoleLog("params: ", params);
      $scope.statusCat = 1;
      MyService.getCatListWithQueAnsCountUserwise(params, null).then(function (response) {
        if (response.data.success) {
          $scope.categories = response.data.data;
          $scope.checkTotalAns();
          $scope.getFiveMin();
          MyService.ConsoleLog("Got categories here: ", $scope.categories);
          $scope.statusCat = 2;
        } else {
          $scope.statusCat = 3;
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function (err) {
        $scope.statusCat = 3;
        growl.error("Something went wrong.");
      });
    }
    getCategories();


    function getUsers(params) {
      var request = apiCall.apiCall('GET', '/user/list', params);
      $http(
        request
      ).then(function successCallback(response) {
        // MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.users = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    function getCompareAnswers(params) {
      console.log("params", params);
      var request = apiCall.apiCall('GET', '/answers/compare', params);
      $http(
        request
      ).then(function successCallback(response) {
        MyService.ConsoleLog("Got categories: ", response);
        // MyService.ConsoleLog(" $routeParams.category_id", $routeParams.category_id);
        if (response.data.success) {

          angular.forEach(response.data.data, function (val, key) {
            if (val.answers && val.answers.length != $scope.selectedUser.length) {
              var check_author_exist = function (u) {
                var len = val.answers.length;
                for (var i = 0; i < len; i++) {
                  if (val.answers[i].author._id == u._id) {
                    return true;
                  }
                }
                return false;
              };

              angular.forEach($scope.selectedUser, function (u, i) {
                if (!check_author_exist(u)) {
                  response.data.data[key].answers.push({
                    author: u
                  });
                }
              });

            } else if (!val.answers) {
              response.data.data[key].answers = [];
              angular.forEach($scope.selectedUser, function (u, i) {
                response.data.data[key].answers.push({
                  author: u
                });
              });
            }
          });

          $rootScope.compareAnswerList = response.data.data;
          if ($routeParams.category_id && $routeParams.category_id != '') {

            $location.path("question-compare/" + $routeParams.category_id);
          } else {
            console.log("check cat", response.data.data[0].categories[0].cid);
            $location.path("question-compare/" + response.data.data[0].categories[0].cid + "/");
          }

        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    if ($routeParams.user_type) {
      getUsers({
        usertype: $routeParams.user_type
      });
    }

    $scope.selectedUser = [];
    $scope.toggleSelection = function toggleSelection(uid) {
      //MyService.ConsoleLog("toggleSelection: ", uid);
      var idx = $scope.selectedUser.indexOf(uid);

      // is currently selected
      if (idx > -1) {
        $scope.selectedUser.splice(idx, 1);
      }

      // is newly selected
      else {
        $scope.selectedUser.push(uid);
      }
    };

    $scope.compare = function () {
      //MyService.ConsoleLog("compare :",$scope.selectedUser);
      if ($scope.selectedUser.length <= 0) {
        alert("Please select user first.");
      } else {
        var temp_uids = [];
        angular.forEach($scope.selectedUser, function (val, key) {
          temp_uids.push(val._id);
        });
        getCompareAnswers({
          "compare_with": $routeParams.user_type,
          "categoryId": $routeParams.category_id,
          "userIds": temp_uids.join(",")
        });
      }
    };

    $scope.goToCompareSubcategories = function (cat) {
      $location.path("/compare-subcategories/" + cat._id);
    };

    $scope.goToFiveCompare = function (item) {
      MyService.ConsoleLog("item >>", item);
      if (item.title == 'Five Minute Quiz') {
        window.localStorage.setItem('fiveQueIds', JSON.stringify($scope.queIds));
        var temp_userids = [];
        if ($rootScope.selectedCompareUsers && $rootScope.selectedCompareUsers.length > 0) {
          angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
            // body...
            temp_userids.push(val._id);
          });
        }
        getCompareAnswers({
          "compare_with": $routeParams.user_type,
          "questionIds": JSON.stringify($scope.queIds),
          "userIds": temp_userids.join(",")
        });
      } else if (item.title == 'All Topics') {
        window.localStorage.setItem('fiveQueIds', JSON.stringify(item.queIds));
        var temp_userids = [];
        if ($rootScope.selectedCompareUsers && $rootScope.selectedCompareUsers.length > 0) {
          angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
            // body...
            temp_userids.push(val._id);
          });
        }
        getCompareAnswers({
          "compare_with": $routeParams.user_type,
          "questionIds": JSON.stringify(item.queIds),
          "userIds": temp_userids.join(",")
        });
      }

    };
  }]);
