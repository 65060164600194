'use strict';
angular.module('myApp.whatAmILookingAt2', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/whatAmILookingAt2', {
      templateUrl: 'app/whatAmILookingAt2/whatAmILookingAt2.html',
      controller: 'whatAmILookingAt2Ctrl'
    });
  }])
  .controller('whatAmILookingAt2Ctrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
