"use strict";
angular
  .module("myApp.allRatings", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/allRatings", {
        templateUrl: "app/allRatings/allRatings.html",
        controller: "allRatingsCtrl",
      });
      $routeProvider.when("/allRatings/:id/:msg?/:type?", {
        templateUrl: "app/allRatings/allRatings.html",
        controller: "allRatingsCtrl",
      });
    },
  ])
  .controller("allRatingsCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "$cookies",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      $cookies
    ) {
      $scope.selectedPolitician = $routeParams.id;
      $scope.allReviews = [];
      $scope.ratingUp = null;
      $scope.ratingDown = null;
      $scope.nameUp = null;
      $scope.nameDown = null;
      $scope.dateUp = null;
      $scope.dateDown = null;
      $scope.matchUp = null;
      $scope.matchDown = null;
      $scope.type = false;
      $scope.ratingDateUp = null;
      $scope.ratingDateDown = null;
      $scope.showLoader = false;
      $scope.statusGetResPg = 0;
      $scope.page = 1;
      console.log("eleDate", $cookies.get("elecDateForRate"));

      /**
       * @description Get current user Id
       */
      $scope.userId = tokenService.getUserId();

      if ($scope.userId && $scope.userId != "") {
        MyService.getPress($scope.userId).then(
          function (APIResponse) {
            MyService.ConsoleLog("API", APIResponse);
            if (APIResponse && APIResponse.orgdata) {
              $scope.sigId = APIResponse.orgdata.sigId;
            }
          },
          function (err) {
            MyService.ConsoleLog("Error", err);
          }
        );
      }

      $scope.$on("$viewContentLoaded", function () {
        $scope.getData();
      });

      $scope.search = {
        name: "",
        rangeMin: 0,
        rangeMax: "",
      };
      $scope.success_msg = false;
      if ($routeParams.msg && $routeParams.msg == "true") {
        $scope.success_msg = true;
        setTimeout(function () {
          $(".alert").alert("close");
          // $scope.getAllCongressman();
        }, 2000);
      }

      if ($routeParams.type && $routeParams.type != "") {
        $scope.type = $routeParams.type;
      }

      $scope.sortDate = function () {
        var sortOrder = "";

        if ($scope.ratingDateUp == "" || $scope.ratingDateUp == "DESC") {
          sortOrder = "ASC";
          $scope.ratingDateUp = true;
          $scope.ratingDateDown = false;

          $scope.allReviews.sort(function (a, b) {
            // MyService.ConsoleLog("am", a);
            // MyService.ConsoleLog("b", b);
            var asc;
            var bss;
            // return a.timespan - b.timespan;
            if (
              a.timespan &&
              a.timespan != "" &&
              b.timespan &&
              b.timespan != ""
            ) {
              if (a.timespan.length > 4 || b.timespan.length > 4) {
                // console.log("if");
                asc = a.timespan.slice(0, 4);
                bss = b.timespan.slice(0, 4);
                MyService.ConsoleLog("ass", asc + "   " + bss);
                return asc - bss;
              } else {
                // console.log("else");
                return a.timespan - b.timespan;
              }
            }
          });
        } else {
          sortOrder = "DESC";
          $scope.ratingDateDown = true;
          $scope.ratingDateUp = false;
          var oneA, bTwo;

          $scope.allReviews.sort(function (a, b) {
            if (
              a.timespan &&
              a.timespan != "" &&
              b.timespan &&
              b.timespan != ""
            ) {
              // MyService.ConsoleLog("a", a.timespan);
              if (a.timespan.length > 4 || b.timespan.length > 4) {
                // console.log("if");
                oneA = a.timespan.slice(0, 4);
                bTwo = b.timespan.slice(0, 4);
                // MyService.ConsoleLog("ass", asc + "   " + bss);
                return bTwo - oneA;
              } else {
                // console.log("else");
                return b.timespan - a.timespan;
              }
            }
            // return b.timespan - a.timespan;
          });
        }
      };
      $scope.sortSearch = function (val) {
        var sortOrder = "";
        MyService.ConsoleLog(val);
        if (val == "name") {
          MyService.ConsoleLog("nameUp > ", $scope.nameUp);
          if ($scope.nameUp == "" || $scope.nameUp == "DESC") {
            sortOrder = "ASC";
            $scope.nameUp = true;
            $scope.nameDown = false;
            $scope.allReviews.sort(function (a, b) {
              var x, y;
              if (a && a.uType && b && b.uType) {
                if (a.uType == "advocate" && b.uType == "advocate") {
                  x = a.orgName.toLowerCase();
                  y = b.orgName.toLowerCase();
                } else {
                  x = a.pName.toLowerCase();
                  y = b.pName.toLowerCase();
                }
              } else {
                x = a.sig.name.toLowerCase();
                y = b.sig.name.toLowerCase();
              }

              if (x < y) {
                return -1;
              }
              if (x > y) {
                return 1;
              }
              return 0;
            });
          } else {
            sortOrder = "DESC";
            $scope.nameDown = true;
            $scope.nameUp = false;

            $scope.allReviews.sort(function (a, b) {
              // var x = a.sig.name.toLowerCase();
              // var y = b.sig.name.toLowerCase();
              var x, y;
              if (a && a.uType && b && b.uType) {
                if (a.uType == "advocate" && b.uType == "advocate") {
                  x = a.orgName.toLowerCase();
                  y = b.orgName.toLowerCase();
                } else {
                  x = a.pName.toLowerCase();
                  y = b.pName.toLowerCase();
                }
              } else {
                x = a.sig.name.toLowerCase();
                y = b.sig.name.toLowerCase();
              }
              if (x > y) {
                return -1;
              }
              if (x < y) {
                return 1;
              }
              return 0;
            });
          }
        } else {
          $scope.nameUp = false;
          $scope.nameDown = false;
        }
        /**
         * Sort Match
         */
        if (val == "match") {
          if ($scope.matchUp == "" || $scope.matchUp == "DESC") {
            sortOrder = "ASC";
            $scope.matchUp = true;
            $scope.matchDown = false;
            $scope.allReviews.sort(function (a, b) {
              return a.match - b.match;
            });
          } else {
            sortOrder = "DESC";
            $scope.matchDown = true;
            $scope.matchUp = false;
            $scope.allReviews.sort(function (a, b) {
              return b.match - a.match;
            });
          }
        } else {
          $scope.matchUp = false;
          $scope.matchDown = false;
        }

        if (val == "rating") {
          if ($scope.ratingUp == "" || $scope.ratingUp == "DESC") {
            sortOrder = "ASC";
            $scope.ratingUp = true;
            $scope.ratingDown = false;
            $scope.allReviews.sort(function (a, b) {
              return a.candidateRating[0].rating - b.candidateRating[0].rating;
            });
          } else {
            sortOrder = "DESC";
            $scope.ratingDown = true;
            $scope.ratingUp = false;
            $scope.allReviews.sort(function (a, b) {
              return b.candidateRating[0].rating - a.candidateRating[0].rating;
            });
          }
        } else {
          $scope.ratingUp = false;
          $scope.ratingDown = false;
        }
        if (val == "date") {
          if ($scope.dateUp == "" || $scope.dateUp == "DESC") {
            sortOrder = "ASC";
            $scope.dateUp = true;
            $scope.dateDown = false;
          } else {
            sortOrder = "DESC";
            $scope.dateDown = true;
            $scope.dateUp = false;
          }
        } else {
          $scope.dateUp = false;
          $scope.dateDown = false;
        }

        MyService.ConsoleLog("sortField : ", val);
        MyService.ConsoleLog("sortOrder : ", sortOrder);
        /*
      MyService.getReviewSearchResult({"id": $routeParams.id, "type": $scope.type, "sortField":val, "sortOrder": sortOrder}).then(function(response){
          MyService.ConsoleLog("response : ",response);
          if (response.data.success) {
          $scope.allReviews = response.data.data;
        }
      });
      */
      };

      var getAllCanceller = $q.defer();
      $scope.allReviews = [];
      $scope.tempArr = [];
      $scope.getData = function (pageNumber) {
        $scope.showLoader = true;
        $scope.statusGetData = 1;

        //getting name of politician
        MyService.getUserPublicProfile({
          id: $routeParams.id,
          type: false,
        }).then(
          function (response) {
            if (response.data.success) {
              MyService.ConsoleLog("response.data.success", response);

              $scope.username = response.data.data.userData.name;
              if (
                response.data.data.userData &&
                response.data.data.userData.politicianIds &&
                response.data.data.userData.politicianIds.length > 0
              ) {
                $scope.politicianIds =
                  response.data.data.userData.politicianIds[0];
              }

              MyService.ConsoleLog(" username : ", $scope.username);
            }
          },
          function (errorPayload) {
            $scope.statusGetData = 3;
          }
        );

        // getting user reviews
        MyService.ConsoleLog($routeParams.id + "====" + $scope.type);
        var params = {
          page: pageNumber,
          max_records: 30,
        };
        getAllCanceller.resolve();
        $scope.statusGetResPg = 1;
        getAllCanceller = $q.defer();
        params.id = $routeParams.id;
        params.type = $scope.type;
        params.curUId = $scope.userId;
        var calc = (pageNumber - 1) * 30;
        MyService.ConsoleLog("calc", calc);
        // if ($scope.allReviews.length >= calc) {
        MyService.getReviewsByUserId(params, getAllCanceller).then(
          function (response) {
            // MyService.ConsoleLog("response > ", JSON.parse(response.data.data));
            if (response.data.success) {
              try {
                $scope.showLoader = false;
                var tmpRate = JSON.parse(response.data.data);
                $scope.allReviews = $scope.allReviews.concat(tmpRate.votesmart);
                $scope.totalReviews = tmpRate.totalRecords;
                // $scope.allReviews = $scope.allReviews.concat(tmpRate);
              } catch (error) {
                $scope.showLoader = false;
                $scope.allReviews = $scope.allReviews.concat(
                  response.data.data.votesmart
                );
                $scope.totalReviews = response.data.data.totalRecords;
              }
              MyService.ConsoleLog(" $scope.allReviews : ", $scope.allReviews);
              MyService.ConsoleLog(
                "  $scope.totalReviews : ",
                $scope.totalReviews
              );

              var tempData = $scope.allReviews.filter(function (els, ind) {
                return (
                  ind ==
                  $scope.allReviews.findIndex(function (val) {
                    return JSON.stringify(val) == JSON.stringify(els);
                  })
                );
              });
              $scope.allReviews = [];
              $scope.allReviews = tempData;

              /*$scope.allReviews.sort(function (a, b) {
            var x = a.sig.name.toLowerCase();
            var y = b.sig.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });*/
              /**
               * @description Sort records and show best match first.
               */
              $scope.allReviews.sort(function (a, b) {
                return b.match - a.match;
              });
              $scope.statusGetResPg = 2;
            }
          },
          function (errorPayload) {
            $scope.statusGetData = 3;
            $scope.statusGetResPg = 3;
          }
        );
        // } else {
        //   $scope.page = 0;
        //   // break;
        //   return false;
        // }
      };

      $scope.pageChanged = function (newPage) {
        if (newPage) {
          $scope.getData(newPage);
        }
      };

      $scope.myPagingFunction = function () {
        MyService.ConsoleLog("scoll called", $scope.statusGetResPg);
        if (
          $scope.statusGetResPg != 1 &&
          $scope.totalReviews > $scope.allReviews.length
        ) {
          $scope.page++;
          $scope.pageChanged($scope.page);
        }
      };

      /**
       * @description Function use to redirect previous page.
       */

      $scope.BackToPrev = function () {
        if ($cookies.get("elecDateForRate")) {
          $location.path("/compare-select/politician");
        } else {
          $location.path("/incumbent_politician/politician");
        }
      };

      /**
       * End of BackToPrev
       */
      $scope.myPagingFunction();
    },
  ]);
