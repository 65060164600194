'use strict';
angular.module('myApp.emailunsubscribe', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/email-unsubscribe/:id', {
       templateUrl: 'app/email_unsubscribe/email_unsubscribe.html',
       controller: 'emailunsubscribeCtrl'
    });
}])

.controller('emailunsubscribeCtrl', ['$scope', '$http', '$location', 'baseApiUrl', 'growl', '$routeParams', 'tokenService', '$rootScope',
function ($scope, $http, $location, baseApiUrl, growl, $routeParams, tokenService, $rootScope) {        
    $scope.status = 0; // 1: loading, 2: loaded , 3: error, 
    $rootScope.welcome = false;
    $scope.unsubscribe_email = function () {
    	$scope.status = 1;
        $http.post(baseApiUrl + '/user/unsubscribe-email', {
            salt: $routeParams.id
        }).then(function (res) {
            if (res.data.success == true) {
                $scope.status = 2;
            }
            else {
                $scope.status = 3;
            }
        }, function(err){
        	$scope.status = 3;
        });
    };

	if($routeParams.id){
		$scope.unsubscribe_email();
	}

}]);