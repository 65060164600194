"use strict";
angular
  .module("myApp.addPolitician", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/addPolitician", {
        templateUrl: "app/addPolitician/addPolitician.html",
        controller: "addPoliticianCtrl",
      });
    },
  ])
  .controller("addPoliticianCtrl", [
    "$scope",
    "ngDialog",
    "$rootScope",
    "MyService",
    "$routeParams",
    "apiCall",
    "tokenService",
    "growl",
    "$window",
    "$sce",
    "$filter",
    "$q",
    "$http",
    "$timeout",

    function (
      $scope,
      ngDialog,
      $rootScope,
      MyService,
      $routeParams,
      apiCall,
      tokenService,
      growl,
      $window,
      $sce,
      $filter,
      $q,
      $http,
      $timeout
    ) {
      $scope.MUserType = [
        {
          name: "Politician",
          key: "politician",
        },
        {
          name: "Organization",
          key: "advocate",
        },
      ];

      $scope.RegisterButton = false;
      $rootScope.welcome = false;
      $scope.HideOrgInfo = false;
      $scope.records = [];
      $scope.States = [];
      $scope.Cities = [];
      $scope.UserType = $routeParams.type;
      $scope.Address = {
        ZipCode: "",
        Country: "",
        State: "",
        City: "",
      };

      $scope.User = {
        name: "",
        lastname: "",
        UserName: "",
        Password: "",
        Email: "",
        ZipCode: "",
        ssn: "",
        confirmOpt: "Email",
        runElectionDate: "",
        runStatus: "",
        runElecType: "",
        cur_ElectionDate: "",
        cur_status: "",
        cur_eleType: "",
        otherLevel: "",
        runOtherLevel: "",
      };

      $scope.Errors = [];

      $scope.displayDrop = false;
      $scope.displayCounty = false;
      $scope.stateSenate = false;
      $scope.stateHouse = false;
      $scope.federalCong = false;
      $scope.countyone = false;
      $scope.cityone = false;
      $scope.school = false;
      $scope.showLoaderOne = false;
      $scope.dis = false;
      $scope.showtxtRun = false;
      $scope.showtxt = false;
      // $scope.showcur_office_details = false
      $scope.showcur_office_name = false;
      $scope.showcur_office_location = false;
      $scope.showOther = false;
      $scope.show_run_Other = false;
      $scope.running_stateSenate = false;
      $scope.running_stateHouse = false;
      $scope.running_federalCong = false;
      $scope.running_countyone = false;
      $scope.running_cityone = false;
      $scope.running_school = false;
      $scope.running_showLoaderOne = false;
      $scope.running_dis = false;
      $scope.running_showLoader = false;
      $scope.LoaderTwo = false;
      $scope.shownew_office_nameDrop = false;
      $scope.showcur_office_nameDrop = false;
      $scope.flagShowStateDropdownPres = false;
      $scope.running_flagShowStateDropdownPre = false;
      $scope.shownew_office_nameOther = false;
      $scope.showcur_office_nameOther = false;
      //$scope.levelOfGovt = ['national', 'state', 'judicial district', 'county', 'sub county', 'city', 'state school board district', 'local school district', 'other'];

      $scope.levelOfGovt = [
        "Other",
        "Federal",
        "State",
        "County",
        "Local",
        "President",
      ];

      $scope.countylevelOfGovt = ["County"];
      $scope.statelevelOfGovt = [
        "State",
        "State House of Representatives District",
        "State Senate District",
        "All USA",
        "Federal Congressional District",
      ];
      $scope.schoollevelOfGovt = [
        "Elementary school District Position",
        "Secondary School District Position",
        "Unified School District Position",
        "State Board of Education District",
      ];

      $scope.judiciallevelOfGovt = ["Judicial District"];
      $scope.locallevelOfGovt = ["Place Name", "County SubDivision Name"];
      $scope.offices = [];
      $scope.running_offices = [];
      $scope.carriers = [
        {
          name: "AT&T",
          domain: "txt.att.net",
        },
        {
          name: "T-Mobile",
          domain: "tmomail.net",
        },
        {
          name: "Verizon",
          domain: "vtext.com",
        },
        {
          name: "Sprint",
          domain: "messaging.sprintpcs.com",
        },
        {
          name: "Virgin Mobile",
          domain: "vmobl.com",
        },
        {
          name: "Tracfone",
          domain: "mmst5.tracfone.com",
        },
        {
          name: "Metro PCS",
          domain: "mymetropcs.com",
        },
        {
          name: "Boost Mobile",
          domain: "myboostmobile.com",
        },
        {
          name: "Cricket",
          domain: "sms.mycricket.com",
        },
        {
          name: "Ptel",
          domain: "ptel.com",
        },
        {
          name: "Republic Wireless",
          domain: "text.republicwireless.com",
        },
        {
          name: "Suncom",
          domain: "tms.suncom.com",
        },
        {
          name: "Ting",
          domain: "message.ting.com",
        },
        {
          name: "U.S. Cellular",
          domain: "email.uscc.net",
        },
        {
          name: "Consumer Cellular",
          domain: "cingularme.com",
        },
        {
          name: "C-Spire",
          domain: "cspire1.com",
        },
        {
          name: "Page Plus",
          domain: "vtext.com",
        },
      ];

      $scope.govLevelChanged = function (electionFor) {
        $scope.running_flagShowStateDropdown = false;
        $scope.running_flagShowOfficeDropdown = false;
        // $scope.flagShowStateDropdown = false;
        $scope.flagShowOfficeDropdown = false;
        //MyService.ConsoleLog("* govLevelChanged *");
        if (electionFor == "running") {
          $scope.User.office_name = null;
          $scope.running_flagShowStateDropdown = true;
          //$scope.onStateChange('running');
          if ($scope.User.level == "Other") {
            $scope.shownew_office_nameOther = true;
            $scope.shownew_office_nameDrop = false;
            $scope.show_run_Other = true;
            $scope.running_flagShowStateDropdownPre = false;
          } else {
            $scope.shownew_office_nameOther = false;
            $scope.show_run_Other = false;
          }
          if ($scope.User.level == "President") {
            // $scope.onOfficeChange('running');
            $scope.running_flagShowStateDropdown = false;
            $scope.running_flagShowCandidateDropdown = true;
            $scope.shownew_office_nameDrop = true;
            $scope.running_flagShowStateDropdownPre = true;
          } else {
            $scope.running_flagShowStateDropdown = true;
            $scope.running_flagShowCandidateDropdown = false;
            $scope.shownew_office_nameDrop = false;
            $scope.running_flagShowStateDropdownPre = false;
          }
          $scope.running_stateSenate = false;
          $scope.running_stateHouse = false;
          $scope.running_federalCong = false;
          $scope.running_countyone = false;
          $scope.running_cityone = false;
          $scope.running_school = false;
          $scope.running_showLoader = false;
          $scope.runFederalCongrassionalDist = "";
          $scope.runUnifiedSchoolDistrictNames = "";
          $scope.runStateHouseOFRepresentativeDistricts = "";
          $scope.runStateSenateDistrict = "";
          $scope.runPlaces = "";
          $scope.runCounties = "";
          $scope.User.state = "";
          $scope.runAddress.stateSenateDistrict = "";
          $scope.runAddress.federalCongrassionalDist = "";
          $scope.runAddress.stateHouseOFRepresentativeDistrict = "";
          $scope.runAddress.unifiedSchoolDistrictName = "";

          switch ($scope.User.level) {
            case "State":
              $scope.offiArr = [
                {
                  officeName: "Governor",
                },
                {
                  officeName: "Lieutenant Governor",
                },
                {
                  officeName: "State Senator",
                },
                {
                  officeName: "State House of Representatives",
                },
                {
                  officeName: "Other",
                },
              ];
              break;
            case "Federal":
              $scope.offiArr = [
                {
                  officeName: "U.S. House of Representatives",
                },
                {
                  officeName: "U.S. Senate",
                },
              ];
              break;
            case "Local":
              $scope.offiArr = [
                {
                  officeName: "Mayor",
                },
                {
                  officeName: "City Council",
                },
                {
                  officeName: "Other",
                },
              ];
              break;

            case "County":
              $scope.offiArr = [
                {
                  officeName: "Commissioner",
                },
                {
                  officeName: "Sheriff",
                },
                {
                  officeName: "Other",
                },
              ];

              break;
            case "Other":
              $scope.offiArr = [
                {
                  officeName: "",
                },
              ];

              break;
            case "President":
              $scope.offiArr = [
                {
                  officeName: "President",
                },
              ];
              break;
          }
        } else {
          // $scope.User.cur_state = null;
          $scope.User.cur_office_name = null;

          if ($scope.User.cur_level == "Other") {
            $scope.showcur_office_nameOther = true;
            $scope.flagShowStateDropdownPre = false;
            $scope.showcur_office_nameDrop = false;
            $scope.showOther = true;
          } else {
            $scope.showcur_office_nameOther = false;
            $scope.showOther = false;
          }

          if ($scope.User.cur_level == "President") {
            // $scope.onOfficeChange('incumbent');
            $scope.flagShowStateDropdown = false;
            $scope.flagShowCandidateDropdown = true;
            $scope.showcur_office_nameDrop = true;
            $scope.flagShowStateDropdownPres = true;
          } else {
            $scope.flagShowStateDropdown = true;
            $scope.flagShowCandidateDropdown = false;
            $scope.showcur_office_nameDrop = false;
            $scope.flagShowStateDropdownPres = false;
          }
          $scope.dis = false;
          $scope.stateSenate = false;
          $scope.stateHouse = false;
          $scope.federalCong = false;
          $scope.countyone = false;
          $scope.cityone = false;
          $scope.school = false;
          $scope.federalCongrassionalDist = "";
          $scope.unifiedSchoolDistrictNames = "";
          $scope.stateHouseOFRepresentativeDistricts = "";
          $scope.stateSenateDistrict = "";
          $scope.allsubcounty = "";
          $scope.places = "";
          $scope.counties = "";
          $scope.User.cur_state = "";
          $scope.address.stateSenateDistrict = "";
          $scope.address.federalCongrassionalDist = "";
          $scope.address.stateHouseOFRepresentativeDistrict = "";

          switch ($scope.User.cur_level) {
            case "State":
              $scope.cur_offiArr = [
                {
                  officeName: "U.S. Senate",
                },
                {
                  officeName: "Lieutenant Governor",
                },
                {
                  officeName: "Governor",
                },
                {
                  officeName: "State Board of Education",
                },
                {
                  officeName: "Secretary of State",
                },
                {
                  officeName: "unifiedSchoolDistrictName",
                },
                {
                  officeName: "State House",
                },
                {
                  officeName: "State Senate",
                },
              ];
              break;
            case "Federal":
              $scope.cur_offiArr = [
                {
                  officeName: "U.S. House",
                },
                {
                  officeName: "U.S. Senate",
                },
              ];
              break;
            case "Local":
              $scope.cur_offiArr = [
                {
                  officeName: "Mayor",
                },
                {
                  officeName: "City Council",
                },
                {
                  officeName: "Other",
                },
              ];
              break;

            case "County":
              $scope.cur_offiArr = [
                {
                  officeName: "Commissioner",
                },
                {
                  officeName: "Sheriff",
                },
                {
                  officeName: "Other",
                },
              ];

              break;
            case "Other":
              $scope.cur_offiArr = [
                {
                  officeName: "",
                },
              ];

              break;
            case "President":
              $scope.cur_offiArr = [
                {
                  officeName: "President",
                },
              ];
              break;
          }
          // switch ($scope.User.cur_level) {
          //   case "Local":
          //     $scope.displayDrop = true;
          //     break;
          //   case "Other":
          //     $scope.displayDrop = true;
          //     break;
          //   case 'County':
          //     $scope.displayCounty = true;
          //     break;
          //   case 'State':
          //    $scope.displayDrop = true;
          //    break;

          //   default:
          //     MyService.ConsoleLog("default");
          //     break
          // }

          // if ($scope.User.cur_level == 'Federal') {
          //   // alert("fed");
          //   $scope.displayDrop = false;
          //   $scope.displayCounty = false;
          //   $scope.federalCongrassionalDist = "";
          //   $scope.unifiedSchoolDistrictNames = "";
          //   $scope.stateHouseOFRepresentativeDistricts = "";
          //   $scope.stateSenateDistrict = "";
          //   $scope.allsubcounty = "";
          //   $scope.places = "";
          //   $scope.counties = "";
          //   // $scope.states = "";
          //   $scope.address.state = "";
          // } else if ($scope.User.cur_level == 'County') {
          //   $scope.displayDrop = false;
          // }

          // if( $scope.User.cur_level == 'State'){

          // }

          // alert('else');
          //$scope.onStateChange('incumbent');
        }
      };

      $scope.localSchoolDistException = function (electionFor) {
        var exceptionState = ["AK", "DC", "VA"];
        if ((electionFor = "running")) {
          if (
            $scope.User.state &&
            exceptionState.indexOf($scope.User.state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_state &&
            exceptionState.indexOf($scope.User.cur_state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        }
      };

      $scope.selectStatus = function () {
        // MyService.ConsoleLog("selected >", $scope.User.runStatus);
        // MyService.ConsoleLog('User.cur_eleType >', $scope.User.runElecType);
        MyService.ConsoleLog(
          "Usercur_ElectionDate>",
          $scope.User.runElectionDate
        );
        // MyService.ConsoleLog('Usercur_ElectionDate>', $scope.User.runElectionDate);
        // var month = $scope.User.runElectionDate.getMonth() + 1;

        // var finDate = $scope.User.runElectionDate.getFullYear() + "-" + month + "-" + ;
        // MyService.ConsoleLog("$scope.User.runElectionDate ", $scope.User.runElectionDate.getDate());
        // MyService.ConsoleLog("$scope.User.runElectionDate ", $scope.User.runElectionDate.getMonth());
        // MyService.ConsoleLog("$scope.User.runElectionDate ", finDate.toString());
        // var tm = $scope.User.runElectionDate.getTime();
        // var testDate = new Date(tm);
        // MyService.ConsoleLog("testDate", testDate);
        // MyService.ConsoleLog("testDate String", testDate.toString());
      };

      $scope.showOfficeAutoComplete = function (electionFor) {
        //MyService.ConsoleLog("showOfficeAutoComplete");
        // ['national', 'state', 'judicial district', 'county', 'sub county', 'city', 'state school board district', 'local', 'school district', 'other']
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "national":
              $scope.running_offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.state && $scope.User.district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.state && $scope.User.county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.state && $scope.User.state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.state &&
                $scope.localSchoolDistException() &&
                $scope.User.county &&
                $scope.User.district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.state && $scope.User.district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "national":
              $scope.offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.cur_state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.cur_state && $scope.User.cur_district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.cur_state && $scope.User.cur_state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.cur_state &&
                $scope.localSchoolDistException() &&
                $scope.User.cur_county &&
                $scope.User.cur_district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.cur_state && $scope.User.cur_district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        }
      };
      var running_flagShowStateDropdown = false;
      var flagShowStateDropdown = false;
      $scope.showSateDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSateDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.office_name) {
            case "senate":
            case "house of representatives":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          running_flagShowStateDropdown = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.cur_office_name) {
            case "senate":
            case "house of representatives":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          flagShowStateDropdown = false;
          return false;
        }
      };

      $scope.flagShowCandidateDropdown = false;
      $scope.running_flagShowCandidateDropdown = false;

      var fuzzySearch = "";
      var autoFillCurOffice = "";
      var autoFillOffice = "";
      $scope.showLoader = false;

      $scope.onOfficeChange = function (electionFor) {
        if (electionFor == "running") {
          //'Federal', 'State', 'Local'
          // if ($scope.User.level != 'President') {
          $scope.running_showLoader = true;
          // }

          var nObj = new Object();
          nObj.level = $scope.User.level ? $scope.User.level : "";
          nObj.office = $scope.User.off_name ? $scope.User.off_name : "";
          if ($scope.User.state && $scope.User.state != "") {
            nObj.state = JSON.stringify($scope.User.state);
          }
          $scope.running_flagShowCandidateDropdown = true;
          $scope.shownew_office_name = true;

          switch ($scope.User.level) {
            case "Other":
              $scope.getRunningCounties();
              break;
            case "County":
              $scope.getRunningCounties();
              break;
            case "State":
              $scope.getRunningStateHouse();
              break;
            case "Federal":
              $scope.getRunningFederal();
              break;
            case "Local":
              $scope.getRunningCounties();
              break;

            default:
              MyService.ConsoleLog("default");
              break;
          }

          if ($scope.User.level == "Other") {
            $scope.autoOffice = [
              {
                officeName: "U.S. Senate",
              },
              {
                officeName: "Lieutenant Governor",
              },
              {
                officeName: "Governor",
              },
              {
                officeName: "State Board of Education",
              },
              {
                officeName: "Secretary of State",
              },
              {
                officeName: "unifiedSchoolDistrictName",
              },
              {
                officeName: "State House",
              },
              {
                officeName: "State Senate",
              },
              {
                officeName: "U.S. House",
              },
              {
                officeName: "U.S. Senate",
              },
              {
                officeName: "Mayor",
              },
              {
                officeName: "City Council",
              },
              {
                officeName: "Commissioner",
              },
              {
                officeName: "Sheriff",
              },
            ];

            autoFillOffice = new Fuse($scope.autoOffice, {
              shouldSort: true,
              keys: ["officeName"],
              caseSensitive: false,
              threshold: 0.4,
            });
          }
        } else if (electionFor == "incumbent") {
          // if ($scope.User.cur_level != 'President') {
          $scope.showLoader = true;
          $scope.showcur_office_name = true;

          // }

          var nObj = new Object();
          nObj.level = $scope.User.cur_level;
          nObj.office = $scope.User.cur_off_name;
          if ($scope.User.cur_state && $scope.User.cur_state != "") {
            nObj.state = JSON.stringify($scope.User.cur_state);
          }
          $scope.flagShowCandidateDropdown = true;
          // if ($scope.User.cur_level == "County") {
          //   $scope.getCounties(nObj.state);
          //   alert('office changed');
          // }

          switch ($scope.User.cur_level) {
            case "Other":
              $scope.getCounties();
              break;
            case "County":
              $scope.getCounties();
              break;
            case "State":
              $scope.getStateHouse();
              break;
            case "Federal":
              $scope.getFederal();
              break;
            case "Local":
              $scope.getCounties();
              break;

            default:
              MyService.ConsoleLog("default");
              break;
          }

          if ($scope.User.cur_level == "Other") {
            $scope.autoOfficeCur = [
              {
                officeName: "U.S. Senate",
              },
              {
                officeName: "Lieutenant Governor",
              },
              {
                officeName: "Governor",
              },
              {
                officeName: "State Board of Education",
              },
              {
                officeName: "Secretary of State",
              },
              {
                officeName: "unifiedSchoolDistrictName",
              },
              {
                officeName: "State House",
              },
              {
                officeName: "State Senate",
              },
              {
                officeName: "U.S. House",
              },
              {
                officeName: "U.S. Senate",
              },
              {
                officeName: "Mayor",
              },
              {
                officeName: "City Council",
              },
              {
                officeName: "Commissioner",
              },
              {
                officeName: "Sheriff",
              },
            ];
            $scope.showcur_office_name = true;
            $scope.showcur_office_location = true;

            autoFillCurOffice = new Fuse($scope.autoOfficeCur, {
              shouldSort: true,
              keys: ["officeName"],
              caseSensitive: false,
              threshold: 0.4,
            });
          }
        }
        MyService.ConsoleLog("nObj<><><><", nObj);
        MyService.getPoliticians(nObj).then(
          function (payload) {
            // MyService.ConsoleLog("payload<><><><><>", payload);
            // console.log(payload);
            $scope.candidates = payload;
            // $scope.showLoader = false;
            //MyService.ConsoleLog($scope.running_flagShowCandidateDropdown);

            $scope.candidates.push({
              name: "Other",
            });

            fuzzySearch = new Fuse($scope.candidates, {
              shouldSort: true,
              keys: ["name"],
              caseSensitive: false,
              threshold: 0.4,
            });
          },
          function (errPayload) {
            $scope.showLoader = false;
            //MyService.ConsoleLog("Error");
            //MyService.ConsoleLog(errPayload);
            alert(errPayload);
          }
        );
      };

      $scope.getofficeDrop = function () {
        MyService.ConsoleLog("$drop cur >>", $scope.User.cur_office_name);
        // MyService.ConsoleLog("$drop >>", $scope.User.office_name);
        if ($scope.User.cur_office_name != "") {
          if ($scope.User.cur_office_name.officeName == "Other") {
            MyService.ConsoleLog("Insde >>");

            $scope.showtxt = true;
            // MyService.ConsoleLog()
          }
        }
      };

      $scope.getofficeDropRun = function () {
        MyService.ConsoleLog(
          "$drop cur >>",
          $scope.User.office_name.officeName
        );
        // MyService.ConsoleLog("$drop >>", $scope.User.office_name);

        if ($scope.User.office_name.officeName == "Other") {
          MyService.ConsoleLog("Insde >>");
          $scope.showtxtRun = true;
          // MyService.ConsoleLog()
        }

        if (
          $scope.User.office_name.officeName == "State House of Representatives"
        ) {
          $scope.User.office_name.officeName = "";
          $scope.User.office_name.officeName = "State House";
        }

        if (
          $scope.User.office_name.officeName == "U.S. House of Representatives"
        ) {
          $scope.User.office_name.officeName = "";
          $scope.User.office_name.officeName = "U.S. House";
        }
      };

      // autocomplete

      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();
            // console.log('hello');
            // console.log("place", place);

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // console.log($scope.lati);
            // console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            console.log($scope.mailing_Address);
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  $scope.stateName = place.address_components[i].long_name;
                  // console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  document.getElementById("zip").value =
                    place.address_components[i].long_name;
                  $scope.User.ZipCode = place.address_components[i].long_name;
                  // console.log($scope.User.ZipCode);

                  // console.log($scope.User.ZipCode);
                }
              }
            }
          }
        );
      };

      /*
       * this function create 6 digit of password
       **/

      $scope.generatePassword = function () {
        $scope.password = Math.random().toString(36).slice(-8);
        MyService.ConsoleLog("password :", $scope.password);
        $scope.User.Password = $scope.password;
      };

      /*
       * this function Fetch all data of sub counties and cities
       **/

      $scope.getSubCounties = function () {
        $scope.arr = [];
        $scope.dis = false;
        $scope.stateSenate = false;
        $scope.stateHouse = false;
        $scope.federalCong = false;
        // $scope.countyone = false;
        $scope.cityone = false;
        $scope.school = false;
        $scope.federalCongrassionalDist = "";
        $scope.unifiedSchoolDistrictNames = "";
        $scope.stateHouseOFRepresentativeDistricts = "";
        $scope.stateSenateDistrict = "";
        $scope.allsubcounty = "";
        $scope.places = "";
        $scope.address.stateSenateDistrict = "";
        $scope.address.federalCongrassionalDist = "";
        $scope.address.stateHouseOFRepresentativeDistrict = "";
        $scope.address.unifiedSchoolDistrictName = "";

        MyService.ConsoleLog("$scope.state >>>", $scope.User.cur_state);
        MyService.ConsoleLog("$scope.County >>>", $scope.address.county);
        if (
          $scope.User.cur_level == "Local" ||
          $scope.User.cur_level == "Other"
        ) {
          $scope.showLoaderOne = true;
          // $scope.LoaderTwo = false;
        }

        // if($scope.User.cur_state && $scope.address.county == "" ){
        //   alert('if');
        // }else{
        //   alert('else');

        // }

        MyService.getPlacesAndSubCounty(
          $scope.User.cur_state.stateFips,
          $scope.address.county.countyFips,
          $scope.User.cur_state.state
        ).then(
          function (response) {
            MyService.ConsoleLog("response >>", response);

            var sub = response.countySubdivision.filter(function (val) {
              var cmat = val.city.substring(val.city.length - 3);
              if (cmat != "CDP") {
                return val;
              }
            });

            $scope.allsubcounty = sub;

            var plFil = response.places.filter(function (item) {
              MyService.ConsoleLog("item", item);
              var smTxt = item.city.substring(item.city.length - 3);
              if (smTxt != "CDP") {
                return item;
                MyService.ConsoleLog("smTxt >>", smTxt);
              }
            });
            MyService.ConsoleLog("plFil >>", plFil);
            $scope.finArr = $scope.allsubcounty.concat(plFil);
            MyService.ConsoleLog("$scope.arr", $scope.finArr);

            $scope.places = $scope.finArr;
            if ($scope.User.cur_level == "Local") {
              $scope.showLoaderOne = true;

              $scope.getFederal();
            }

            if ($scope.User.cur_level == "Other") {
              // $scope.showLoaderOne = true;

              $scope.getFederal();
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >>", error);
          }
        );
      };

      $scope.getRunningSubCounties = function () {
        MyService.ConsoleLog("$scope.state >>>", $scope.User.state);
        MyService.ConsoleLog("$scope.County >>>", $scope.User.state);
        $scope.running_stateSenate = false;
        $scope.running_stateHouse = false;
        $scope.running_federalCong = false;
        // $scope.running_countyone = false;
        $scope.running_cityone = false;
        $scope.running_school = false;
        $scope.running_showLoader = false;
        $scope.runFederalCongrassionalDist = "";
        $scope.runUnifiedSchoolDistrictNames = "";
        $scope.runStateHouseOFRepresentativeDistricts = "";
        $scope.runStateSenateDistrict = "";
        $scope.runPlaces = "";
        $scope.runAddress.stateSenateDistrict = "";
        $scope.runAddress.federalCongrassionalDist = "";
        $scope.runAddress.stateHouseOFRepresentativeDistrict = "";
        $scope.runAddress.unifiedSchoolDistrictName = "";
        if ($scope.User.level == "Local" || $scope.User.level == "Other") {
          $scope.running_showLoaderOne = true;
        }

        MyService.getPlacesAndSubCounty(
          $scope.User.state.stateFips,
          $scope.runAddress.county.countyFips,
          $scope.User.state.state
        ).then(
          function (response) {
            MyService.ConsoleLog("response >>", response);
            var subRun = response.countySubdivision.filter(function (val) {
              var cmatRun = val.city.substring(val.city.length - 3);
              if (cmatRun != "CDP") {
                return val;
                MyService.ConsoleLog("cmat >>", cmat);
              }
            });

            $scope.allsubcounty = subRun;

            var plFilRun = response.places.filter(function (item) {
              MyService.ConsoleLog("item", item);
              var smTxtRun = item.city.substring(item.city.length - 3);
              if (smTxtRun != "CDP") {
                return item;
                MyService.ConsoleLog("smTxt >>", smTxt);
              }
            });
            // $scope.allsubcounty = ;

            $scope.runPlaces = $scope.allsubcounty.concat(plFilRun);
            if ($scope.User.level == "Local") {
              // $scope.showLoaderOne = true;

              $scope.getRunningFederal();
            }
            if ($scope.User.level == "Other") {
              // $scope.showLoaderOne = true;

              $scope.getRunningFederal();
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >>", error);
          }
        );
      };

      /*
       * this function Fetch all data of statehouse and state senate
       **/
      $scope.getStateHouse = function () {
        MyService.ConsoleLog("State >>", $scope.User.cur_state);
        if ($scope.User.cur_level == "State") {
          $scope.stateSenateDistrict = "";
          $scope.stateHouseOFRepresentativeDistricts = "";
          $scope.stateHouse = false;
          $scope.stateSenate = false;
          $scope.address.stateSenateDistrict = "";
          $scope.address.stateHouseOFRepresentativeDistrict = "";
        }
        MyService.getStateHouseData($scope.User.cur_state).then(
          function (response) {
            MyService.ConsoleLog("Result >", response);
            $scope.stateSenateDistrict = response.allstateSenateDistrict;
            $scope.stateHouseOFRepresentativeDistricts =
              response.allstateLegislativeDistrict;
            if ($scope.User.cur_level == "State") {
              $scope.stateHouse = true;
              $scope.stateSenate = true;
              $scope.showLoader = false;
              $scope.showcur_office_nameDrop = true;
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >>", error);
          }
        );
      };

      $scope.getRunningStateHouse = function () {
        MyService.ConsoleLog("State >>", $scope.User.state);
        if ($scope.User.level == "State") {
          $scope.runStateSenateDistrict = "";
          $scope.runStateHouseOFRepresentativeDistricts = "";
          $scope.running_stateHouse = false;
          $scope.running_stateSenate = false;
          $scope.runAddress.stateSenateDistrict = "";
          $scope.runAddress.stateHouseOFRepresentativeDistrict = "";
        }

        MyService.getStateHouseData($scope.User.state).then(
          function (response) {
            MyService.ConsoleLog("Result >", response);
            $scope.runStateSenateDistrict = response.allstateSenateDistrict;
            $scope.runStateHouseOFRepresentativeDistricts =
              response.allstateLegislativeDistrict;
            if ($scope.User.level == "State") {
              $scope.running_stateHouse = true;
              $scope.running_stateSenate = true;
              $scope.running_showLoader = false;
              $scope.shownew_office_nameDrop = true;
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >>", error);
          }
        );
      };

      /*  $scope.getSubCounties = function() {
        alert("called");
        MyService.ConsoleLog("$scope.state >>>", $scope.address.state);
        MyService.ConsoleLog("$scope.County >>>", $scope.address.county);
        $scope.federalCongrassionalDist = "";
        $scope.unifiedSchoolDistrictNames = "";
        $scope.stateHouseOFRepresentativeDistricts = "";
        $scope.stateSenateDistrict = "";
        $scope.allsubcounty = "";
        $scope.places = "";

        if ($scope.address.state.stateFips != "" && $scope.address.county != "") {
          alert('not');
          MyService.getAllData({ "state": $scope.address.state.stateFips, "county": $scope.address.county.countyFips }).then(function(response) {
            MyService.ConsoleLog("response &&&&& ", response);
            // $scope.places = response;
            $scope.federalCongrassionalDist = response.allfederalCongrassionalDist;
            $scope.unifiedSchoolDistrictNames = response.allschoolDistrict;
            $scope.stateHouseOFRepresentativeDistricts = response.allstateLegislativeDistrict;
            $scope.stateSenateDistrict = response.allstateSenateDistrict;
            $scope.allsubcounty = response.allsubcounty;
            $scope.places = response.allPlaces;
            $scope.showcur_office_name = true;

          }, function(error) {
            MyService.ConsoleLog("Error >>", error);
          });
        }

      };
*/

      /***
       ** function get federal records using places and county geometry
       ***/

      $scope.getFederal = function () {
        // alert("welcome");
        MyService.ConsoleLog("$scope.address >>", $scope.User.cur_state);
        if ($scope.User.cur_level == "Federal") {
          $scope.federalCongrassionalDist = "";
          $scope.stateSenateDistrictOne = "";
          $scope.address.stateSenateDistrict = "";
          $scope.address.federalCongrassionalDist = "";
          $scope.federalCong = false;
          $scope.stateSenate = false;
          $scope.dis = false;
        }

        MyService.getAllcogressionalData($scope.User.cur_state).then(
          function (response) {
            MyService.ConsoleLog("Result >", response);
            if ($scope.User.cur_level == "Federal") {
              $scope.federalCongrassionalDist =
                response.allfederalCongrassionalDist;
              // $scope.stateSenateDistrictOne = response.allstateSenateDistrict;
              $scope.federalCong = true;
              // $scope.stateSenate = true;
              // $scope.dis = true;
              $scope.showcur_office_nameDrop = true;

              $scope.showLoader = false;
            }
            if ($scope.User.cur_level == "Local") {
              $scope.unifiedSchoolDistrictNames = response.allschoolDistrict;

              $scope.cityone = true;
              $scope.school = true;
              $scope.showLoaderOne = false;
              $scope.showcur_office_nameDrop = true;
            }

            if ($scope.User.cur_level == "Other") {
              $scope.federalCongrassionalDist =
                response.allfederalCongrassionalDist;
              $scope.stateSenateDistrict = response.allstateSenateDistrict;
              $scope.stateHouseOFRepresentativeDistricts =
                response.allstateLegislativeDistrict;
              $scope.unifiedSchoolDistrictNames = response.allschoolDistrict;
              $scope.cityone = true;
              $scope.federalCong = true;
              $scope.stateSenate = true;
              $scope.stateHouse = true;
              $scope.school = true;
              $scope.showLoaderOne = false;
              // $scope.showcur_office_nameDrop = true;
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >", error);
          }
        );
      };

      $scope.getRunningFederal = function () {
        // alert("welcome");
        MyService.ConsoleLog("$scope.address >>", $scope.User.state);
        if ($scope.User.level == "Federal") {
          $scope.runFederalCongrassionalDist = "";
          $scope.runStateSenateDistrictOne = "";
          $scope.runAddress.stateSenateDistrict = "";
          $scope.runAddress.federalCongrassionalDist = "";
          $scope.running_federalCong = false;
          $scope.running_stateSenate = false;
          $scope.running_dis = false;
        }

        MyService.getAllcogressionalData($scope.User.state).then(
          function (response) {
            MyService.ConsoleLog("Result >", response);
            if ($scope.User.level == "Federal") {
              $scope.runFederalCongrassionalDist =
                response.allfederalCongrassionalDist;
              // $scope.runStateSenateDistrictOne = response.allstateSenateDistrict;
              $scope.running_federalCong = true;
              // $scope.running_stateSenate = true;
              // $scope.running_dis = true;
              $scope.shownew_office_nameDrop = true;

              $scope.running_showLoader = false;
            }
            if ($scope.User.level == "Local") {
              $scope.runUnifiedSchoolDistrictNames = response.allschoolDistrict;

              $scope.running_cityone = true;
              $scope.running_school = true;
              $scope.running_showLoaderOne = false;
              $scope.shownew_office_nameDrop = true;
            }
            if ($scope.User.level == "Other") {
              $scope.runFederalCongrassionalDist =
                response.allfederalCongrassionalDist;
              $scope.runStateSenateDistrict = response.allstateSenateDistrict;
              $scope.runStateHouseOFRepresentativeDistricts =
                response.allstateLegislativeDistrict;
              $scope.runUnifiedSchoolDistrictNames = response.allschoolDistrict;
              $scope.running_cityone = true;
              $scope.running_federalCong = true;
              $scope.running_stateSenate = true;
              $scope.running_stateHouse = true;
              $scope.running_school = true;
              $scope.running_showLoaderOne = false;
              // $scope.shownew_office_nameDrop = true;
            }
          },
          function (error) {
            MyService.ConsoleLog("Error >", error);
          }
        );
      };
      // end of getFederal

      /***
       ** function get School district based on selected city
       ***/

      /* $scope.getSchool = function() {
         MyService.ConsoleLog("State >>", $scope.User.cur_state);
         MyService.ConsoleLog("City >>", $scope.address.place);

         if ($scope.User.cur_level == "Local" && $scope.address.place !="" ) {
           $scope.LoaderTwo = false;
           // alert('come');
         }


         MyService.getSchool($scope.address.place).then(function(response) {
           MyService.ConsoleLog("Result >", response);
           if ($scope.User.cur_level == "Local") {
             $scope.unifiedSchoolDistrictNames = response;

             // $scope.cityone = true;
             $scope.school = true;
             // $scope.showLoaderOne = false;
             $scope.LoaderTwo = false;


           }

         }, function(error) {
           MyService.ConsoleLog("Error >>", error);
         });

       }
       */
      // End of getSchool

      $scope.checkReadOnly = false;
      $scope.HideRegisterButton = false;
      $scope.onCandidateChange = function (val) {
        //$scope.User.Candidates;
        $scope.checkReadOnly = false;
        $scope.shortEmail = "";
        // alert("onCandidateChange");
        //MyService.ConsoleLog($scope.User.Candidates);
        //MyService.ConsoleLog($scope.User.NCandidates);
        if (val == "incumbent" && $scope.User.Candidates) {
          if (
            $scope.User.Candidates.name &&
            $scope.User.Candidates.name != null
          ) {
            if ($scope.User.Candidates.name != "Other") {
              $scope.User.name = $scope.User.Candidates.name;
              if (
                $scope.User.Candidates.PublicPhoneNumber &&
                $scope.User.Candidates.PublicPhoneNumber != null
              ) {
                $scope.User.phone_no = $scope.User.Candidates.PublicPhoneNumber;
              } else {
                $scope.User.phone_no = "";
              }
              if (
                $scope.User.Candidates.website &&
                $scope.User.Candidates.website != null
              ) {
                $scope.User.web_url = $scope.User.Candidates.website;
              } else {
                $scope.User.web_url = "";
              }
              if (
                $scope.User.Candidates.public_zipcode &&
                $scope.User.Candidates.public_zipcode != null
              ) {
                $scope.User.ZipCode = $scope.User.Candidates.public_zipcode;
              } else {
                $scope.User.ZipCode = "";
              }
              if (
                $scope.User.Candidates.public_mailing_address &&
                $scope.User.Candidates.public_mailing_address != null
              ) {
                $scope.User.mailing_address =
                  $scope.User.Candidates.public_mailing_address;
              } else {
                $scope.User.mailing_address = "";
              }

              if (
                $scope.User.Candidates.email &&
                $scope.User.Candidates.email != null &&
                $scope.User.Candidates.email != 0
              ) {
                $scope.HideRegisterButton = true;
                var email = $scope.User.Candidates.email
                  ? $scope.User.Candidates.email
                  : $scope.User.Candidates.privateEmail;
                // $scope.User.Email = email;
                var cemail = email;
                var firstSix = cemail.substr(0, 6);
                var lastSix = cemail.substr(cemail.length - 6);
                var xlen = cemail.length - 12;
                var xstring = "x".repeat(xlen);
                var finalstring = firstSix + xstring + lastSix;
                $scope.shortEmail = finalstring;
                /*
                var cemail = $scope.User.Candidates.email;
                var firstSix = cemail.substr(0, 6);
                var lastSix = cemail.substr(cemail.length - 6);
                var xlen = cemail.length - 12;
                var xstring = "x".repeat(xlen);
                var finalstring = firstSix + xstring + lastSix;
                $scope.HideRegisterButton = true;
                var msg = "You are already in our system with the email " + finalstring;
                MyService.GetDialogWithButtons(ngDialog, msg, "SignIn", "Please email me my password").then(function(payload) {
                  //MyService.ConsoleLog("Ans<><><>", payload);
                  $scope.User.Email = $scope.User.Candidates.email;
                  $scope.checkReadOnly = true;
                  if (payload == 1) {
                    $location.path("/signin");
                  } else {
                    var postobj = {
                      email: $scope.User.Candidates.email,
                      reset_url: $rootScope.getPasswordResetUrl()
                    };
                    //MyService.ConsoleLog("postobj: ", postobj);

                    MyService.forgotPassword(postobj)
                    .then(function(response) {
                      if (response.data.success) {
                        growl.success("An email has been sent.");
                      } else {
                        if (typeof response.data.error == "string")
                        growl.error(response.data.error);
                        else
                        growl.error("Something went wrong");
                      }
                    }, function(err) {
                      growl.error("Something went wrong");
                    });
                  }
                }, function(errorPayload) {
                  $scope.User.Email = "";
                  //MyService.ConsoleLog("errorPayload<><><>", errorPayload);
                  $scope.checkReadOnly = false;
                  if (errorPayload == 0) {
                    var postobj = {
                      email: $scope.User.Candidates.email,
                      reset_url: $rootScope.getPasswordResetUrl()
                    };
                    //MyService.ConsoleLog("postobj: ", postobj);

                    MyService.forgotPassword(postobj)
                    .then(function(response) {
                      if (response.data.success) {
                        growl.success("An email has been sent.");
                      } else {
                        if (typeof response.data.error == "string")
                        growl.error(response.data.error);
                        else
                        growl.error("Something went wrong");
                      }
                    }, function(err) {
                      growl.error("Something went wrong");
                    });
                  }
                });
                */
              } else {
                $scope.User.Email = "";
                $scope.User.phone_no = "";
                $scope.User.web_url = "";
                $scope.User.ZipCode = "";
                $scope.User.mailing_address = "";
              }
            } else {
              //If candidate is Other
              //MyService.ConsoleLog("Other selected");
            }
          } else {
            $scope.User.name = "";
            $scope.User.phone_no = "";
            $scope.User.web_url = "";
            $scope.User.ZipCode = "";
            $scope.User.mailing_address = "";
          }
        } else {
          //MyService.ConsoleLog("Else 1", $scope.User.NCandidates);
          if ($scope.User.NCandidates) {
            if (
              $scope.User.NCandidates.name &&
              $scope.User.NCandidates.name != null
            ) {
              if ($scope.User.NCandidates.name != "Other") {
                $scope.User.name = $scope.User.NCandidates.name;
                //MyService.ConsoleLog("Other than <><><><<", $scope.User.NCandidates.name);
                if (
                  $scope.User.NCandidates.PublicPhoneNumber &&
                  $scope.User.NCandidates.PublicPhoneNumber != null
                ) {
                  $scope.User.phone_no =
                    $scope.User.NCandidates.PublicPhoneNumber;
                } else {
                  $scope.User.phone_no = "";
                }
                if (
                  $scope.User.NCandidates.website &&
                  $scope.User.NCandidates.website != null
                ) {
                  $scope.User.web_url = $scope.User.NCandidates.website;
                } else {
                  $scope.User.web_url = "";
                }
                if (
                  $scope.User.NCandidates.public_zipcode &&
                  $scope.User.NCandidates.public_zipcode != null
                ) {
                  $scope.User.ZipCode = $scope.User.NCandidates.public_zipcode;
                } else {
                  $scope.User.ZipCode = "";
                }
                if (
                  $scope.User.NCandidates.public_mailing_address &&
                  $scope.User.NCandidates.public_mailing_address != null
                ) {
                  $scope.User.NCandidates =
                    $scope.User.NCandidates.public_mailing_address;
                } else {
                  $scope.User.mailing_address = "";
                }
                //MyService.ConsoleLog("NCandidates<><><>", $scope.User.NCandidates);

                if (
                  ($scope.User.NCandidates.email &&
                    $scope.User.NCandidates.email != null &&
                    $scope.User.NCandidates.email != 0) ||
                  ($scope.User.NCandidates.privateEmail &&
                    $scope.User.NCandidates.privateEmail != null &&
                    $scope.User.NCandidates.privateEmail != 0)
                ) {
                  $scope.HideNewRegisterButton = true;
                  var email = $scope.User.NCandidates.email
                    ? $scope.User.NCandidates.email
                    : $scope.User.NCandidates.privateEmail;
                  // $scope.User.Email = email;
                  var cemail = email;
                  var firstSix = cemail.substr(0, 6);
                  var lastSix = cemail.substr(cemail.length - 6);
                  var xlen = cemail.length - 12;
                  var xstring = "x".repeat(xlen);
                  var finalstring = firstSix + xstring + lastSix;
                  $scope.shortEmail = finalstring;
                  /*
                  var cemail = $scope.User.NCandidates.email;
                  var firstSix = cemail.substr(0, 6);
                  var lastSix = cemail.substr(cemail.length - 6);
                  var xlen = cemail.length - 12;
                  var xstring = "x".repeat(xlen);
                  var finalstring = firstSix + xstring + lastSix;
                  var msg = "You are already in our system with the email " + finalstring;
                  MyService.GetDialogWithButtons(ngDialog, msg, "SignIn", "Please email me my password").then(function(payload) {
                    //MyService.ConsoleLog("Ans<><><>", payload);
                    $scope.User.Email = $scope.User.NCandidates.email;
                    $scope.checkReadOnly = true;
                    if (payload == 1) {
                      $location.path("/signin");
                    } else {
                      var postobj = {
                        email: $scope.User.NCandidates.email,
                        reset_url: $rootScope.getPasswordResetUrl()
                      };
                      //MyService.ConsoleLog("postobj: ", postobj);

                      MyService.forgotPassword(postobj)
                      .then(function(response) {
                        if (response.data.success) {
                          growl.success("An email has been sent.");
                        } else {
                          if (typeof response.data.error == "string")
                          growl.error(response.data.error);
                          else
                          growl.error("Something went wrong");
                        }
                      }, function(err) {
                        growl.error("Something went wrong");
                      });
                    }
                  }, function(errorPayload) {
                    $scope.User.Email = "";
                    //MyService.ConsoleLog("errorPayload<><><>", errorPayload);
                    $scope.checkReadOnly = false;
                    if (errorPayload == 0) {
                      var postobj = {
                        email: $scope.User.NCandidates.email,
                        reset_url: $rootScope.getPasswordResetUrl()
                      };
                      //MyService.ConsoleLog("postobj: ", postobj);

                      MyService.forgotPassword(postobj)
                      .then(function(response) {
                        if (response.data.success) {
                          growl.success("An email has been sent.");
                        } else {
                          if (typeof response.data.error == "string")
                          growl.error(response.data.error);
                          else
                          growl.error("Something went wrong");
                        }
                      }, function(err) {
                        growl.error("Something went wrong");
                      });
                    }
                  });
                  */
                } else {
                  $scope.User.Email = "";
                  $scope.User.phone_no = "";
                  $scope.User.web_url = "";
                  $scope.User.ZipCode = "";
                  $scope.User.mailing_address = "";
                }
              } else {
                //If candidate is Other
                //MyService.ConsoleLog("Other selected");
              }
            } else {
              $scope.User.name = "";
              $scope.User.phone_no = "";
              $scope.User.web_url = "";
              $scope.User.ZipCode = "";
              $scope.User.mailing_address = "";
            }
          }
        }
      };

      $scope.sendPostCard = false;
      $scope.PostcardSend = false;
      $scope.generatePostcard = function () {
        var postCard = Math.random().toString(36).substr(2, 10);

        MyService.checkPostCard({
          postcard: postCard,
        }).then(
          function (payload) {
            //MyService.ConsoleLog("Payload<><><<>",payload);
            var alldata = payload.data;
            //MyService.ConsoleLog("alldata<><><>", alldata);
            var taken = alldata.taken;
            //MyService.ConsoleLog("taken<><><>", taken);
            $scope.PostcardSend = true;
            if (!taken) {
              //MyService.ConsoleLog(taken);
              $scope.User.postCard = postCard.toUpperCase();
            } else {
              //MyService.ConsoleLog(taken);
              //$scope.generatePostcard();
            }
          },
          function (errorPayload) {
            $scope.User.postCard = postCard.toUpperCase();
            //MyService.ConsoleLog("errorPayload<><><<>",errorPayload);
          }
        );
      };

      $scope.submitPostcard = function (data) {
        //MyService.ConsoleLog("confirmPostalCode<><><>", $scope.User.confirmPostalCode);
        if ($scope.User.postCard == $scope.User.confirmPostalCode) {
          $scope.verifiedUser = "true";
          //MyService.ConsoleLog($scope.verifiedUser);
          var message = "Thank you for confirming your account.";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
              <div class="ngdialog-buttons">\
              <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
              </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ",data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ",data);
            }
          );
        } else {
          $scope.verifiedUser = "true";
          var message =
            "Your postcard code doesn't match with ours. Please try again.";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
              <div class="ngdialog-buttons">\
              <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
              </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ",data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ",data);
            }
          );
        }
      };

      $scope.checkExists = function () {
        MyService.ConsoleLog("welcome to checkExists");
        // alert('called');
        if ($scope.User.cur_Candidates != "" && !$scope.User.name_on_ballot) {
          MyService.ConsoleLog("inside");
          $scope.User.name_on_ballot = $scope.User.cur_Candidates
            ? $scope.User.cur_Candidates
            : "";
        }
      };

      $scope.closeEdit = function (col) {
        // alert("here");
        // MyService.ConsoleLog("%$$$:",col);
        // MyService.ConsoleLog(" $scope.autocomplete_options => ", $scope.autocomplete_options);

        var nval = JSON.parse(col.value);
        // MyService.ConsoleLog("value for auto :",nval);
        $scope.User.Candidates = nval;
        $scope.User.cur_Candidates = nval.name;
        $scope.User.cur_office_name = nval.office;
        $scope.User.cur_office_email = nval.publicEmail;
        $scope.User.postCard = nval.postCard;
        $scope.User.cur_office_phone = nval.publicPhoneNumber;
        $scope.User.cur_office_website = nval.website;
        if ($scope.User.cur_office_name != "") {
          $scope.showcur_office_name = true;
        }
        $scope.User.cur_office_location = nval.officePlace;
        if ($scope.User.cur_office_location != "") {
          $scope.showcur_office_location = true;
        }
        $scope.User.from_date = nval.fromDate;
        $scope.User.end_date = nval.endDate;
        $scope.User.Email = nval.email ? nval.email : nval.privateEmail;
        $scope.User.name_on_ballot = nval.name;

        if (!$scope.User.Email) {
          //alert("here");
          $scope.sendPostCard = true;
        }

        //MyService.ConsoleLog("Email:::", $scope.User.Email);
        // var result = document.getElementsByClassName("ac-container");
        //MyService.ConsoleLog(result);
        // result[0].blur();
        document
          .getElementById("curCandidate")
          .getElementsByTagName("input")[0]
          .blur();
        $scope.onCandidateChange("incumbent");
        //var nval = col.value? col.value: col.displayName
      };

      function suggest_name(term) {
        var q = term.toLowerCase().trim();
        var results = [];
        var len = $scope.candidates.length;
        // Find first 10 states that start with `term`.
        for (var i = 0; i < len && results.length < 10; i++) {
          var name = $scope.candidates[i].name;
          var matched = name.match(new RegExp(q, "i"));
          //MyService.ConsoleLog(state.match())
          //MyService.ConsoleLog(matched);
          //MyService.ConsoleLog(state.match(q, "i"));
          if (matched) {
            results.push({
              label: $sce.trustAsHtml(name),
              value: JSON.stringify($scope.candidates[i]),
            });
          }
        }
        return results;
      }

      function highlight(str, term) {
        MyService.ConsoleLog("str<><><><", str);
        var highlight_regex = new RegExp("(" + term + ")", "gi");
        return str.replace(
          highlight_regex,
          '<span class="highlight">$1</span>'
        );
      }

      var prefuzzySearch = "";

      function fuzzy_suggest(term) {
        // alert('preFuzzy_suggest');
        if (!term) return [];
        if ($scope.User.cur_level == "President") {
          MyService.getPresident({
            search: term,
          }).then(
            function (results) {
              MyService.ConsoleLog("Result >", results);

              // if (results && results != "") {
              $scope.president = results;
              $scope.president.push({
                name: "Other",
              });
              prefuzzySearch = new Fuse($scope.president, {
                shouldSort: true,
                keys: ["name"],
                caseSensitive: false,
                threshold: 0.4,
              });
              MyService.ConsoleLog("prefuzzySearch >", prefuzzySearch);
              return prefuzzySearch
                .search(term)
                .slice(0, 10)
                .map(function (i) {
                  //MyService.ConsoleLog("I",i);
                  //MyService.ConsoleLog($scope.candidates);
                  //MyService.ConsoleLog(i);
                  var val = i.name;
                  // MyService.ConsoleLog("val:::", val);
                  return {
                    value: JSON.stringify(i),
                    label: $sce.trustAsHtml(
                      highlight(val + "-" + i.office, term)
                    ),
                  };
                });

              // }
            },
            function (error) {
              MyService.ConsoleLog("Error >>", error);
            }
          );
        } else {
          MyService.ConsoleLog("term >>", term);

          MyService.ConsoleLog("got", fuzzySearch);
          return fuzzySearch
            .search(term)
            .slice(0, 10)
            .map(function (i) {
              //MyService.ConsoleLog("I",i);
              //MyService.ConsoleLog($scope.candidates);
              //MyService.ConsoleLog(i);
              var val = i.name;
              MyService.ConsoleLog("val:::", val);
              return {
                value: JSON.stringify(i),
                label: $sce.trustAsHtml(highlight(val + "-" + i.office, term)),
              };
            });
        }
      }

      $scope.autocomplete_options = {
        suggest: fuzzy_suggest,
        on_select: $scope.closeEdit,
      };

      function highlightOff(str, term) {
        MyService.ConsoleLog("str<><><><", str);
        var highlight_regex = new RegExp("(" + term + ")", "gi");
        return str.replace(
          highlight_regex,
          '<span class="highlight">$1</span>'
        );
      }

      function autoFill(term) {
        // alert('preFuzzy_suggest');
        if (!term) return [];

        MyService.ConsoleLog("term >>", term);

        return autoFillOffice
          .search(term)
          .slice(0, 10)
          .map(function (i) {
            //MyService.ConsoleLog("I",i);
            //MyService.ConsoleLog($scope.candidates);
            //MyService.ConsoleLog(i);
            var val = i.officeName;
            // MyService.ConsoleLog("val:::", val);
            return {
              value: JSON.stringify(i),
              label: $sce.trustAsHtml(highlightOff(val, term)),
            };
          });
      }

      $scope.autoFill_options = {
        suggest: autoFill,
        on_select: $scope.closeAutoFill,
      };

      $scope.closeAutoFill = function (col) {
        // alert("here");

        // MyService.ConsoleLog("%$$$:",col);
        // MyService.ConsoleLog(" $scope.autocomplete_options => ", $scope.autocomplete_options);

        var nval = JSON.parse(col.value);
        MyService.ConsoleLog("nval >>", nval);
        $scope.User.office_name = nval.officeName;
        $scope.tempOffice = nval.officeName;
        MyService.ConsoleLog("Office>>", $scope.tempOffice);

        document
          .getElementById("autoOff")
          .getElementsByTagName("input")[0]
          .blur();

        // $scope.onCandidateChange("incumbent");
        //var nval = col.value? col.value: col.displayName
      };

      $scope.setOfficeCur = function () {
        MyService.ConsoleLog("insideone cur >>", $scope.tempOfficeCur);
        MyService.ConsoleLog(
          "$scope.User.cur_office_name >>",
          $scope.User.cur_office_name
        );
        if ($scope.User.cur_office_name && !$scope.tempOfficeCur) {
          $scope.User.cur_office_name = $scope.User.cur_office_name;
        } else {
          $scope.User.cur_office_name = $scope.tempOfficeCur;
        }
      };
      $scope.setOfficeRun = function () {
        MyService.ConsoleLog("insideone >>", $scope.tempOffice);
        if ($scope.User.office_name && !$scope.tempOffice) {
          $scope.User.office_name = $scope.User.office_name;
        } else {
          $scope.User.office_name = $scope.tempOffice;
        }
        // $scope.User.office_name = $scope.tempOffice;

        // MyService.ConsoleLog("$scope.User.cur_office_name >>", $scope.User.cur_office_name);
      };

      function highlightCurOff(str, term) {
        MyService.ConsoleLog("str<><><><", str);
        var highlight_regex = new RegExp("(" + term + ")", "gi");
        return str.replace(
          highlight_regex,
          '<span class="highlight">$1</span>'
        );
      }

      function autoFillCur(term) {
        // alert('preFuzzy_suggest');
        if (!term) return [];

        MyService.ConsoleLog("term >>", term);

        return autoFillCurOffice
          .search(term)
          .slice(0, 10)
          .map(function (i) {
            //MyService.ConsoleLog("I",i);
            //MyService.ConsoleLog($scope.candidates);
            //MyService.ConsoleLog(i);
            var val = i.officeName;
            // MyService.ConsoleLog("val:::", val);
            return {
              value: JSON.stringify(i),
              label: $sce.trustAsHtml(highlightCurOff(val, term)),
            };
          });
      }

      $scope.autoFillCur_options = {
        suggest: autoFillCur,
        on_select: $scope.closeAutoFillCur,
      };

      $scope.closeAutoFillCur = function (col) {
        // alert("here");

        // MyService.ConsoleLog("%$$$:",col);
        // MyService.ConsoleLog(" $scope.autocomplete_options => ", $scope.autocomplete_options);

        var nval = JSON.parse(col.value);
        MyService.ConsoleLog("nval >>", nval);
        // $scope.User.office_name = nval.officeName;
        $scope.tempOfficeCur = nval.officeName;
        // MyService.ConsoleLog("Office>>", $scope.User.office_name);
        // $scope.
        document
          .getElementById("autoFillText")
          .getElementsByTagName("input")[0]
          .blur();
        // $scope.onCandidateChange("incumbent");
        //var nval = col.value? col.value: col.displayName
      };

      // MyService.ConsoleLog(" $scope.autocomplete_options => ", $scope.autocomplete_options);

      $scope.running_flagShowCandidateDropdown = false;
      $scope.closeEditNew = function (col) {
        // alert("here");
        // MyService.ConsoleLog("got poly data :",col);
        var nval = JSON.parse(col.value);
        MyService.ConsoleLog("got poly data :", nval);
        $scope.User.NCandidates = nval;
        $scope.User.new_Candidates = nval.name;
        $scope.User.office_name = nval.office;
        $scope.User.office_email = nval.email;
        $scope.User.office_phone = nval.publicPhoneNumber;
        $scope.User.office_website = nval.website;
        if ($scope.User.cur_office_name != "") {
          $scope.showcur_office_name = true;
        }
        $scope.User.office_location = nval.officePlace;

        if ($scope.User.cur_office_location != "") {
          $scope.showcur_office_location = true;
        }

        $scope.User.from_date = nval.fromDate;
        $scope.User.end_date = nval.endDate;
        $scope.User.Email = nval.email ? nval.email : nval.privateEmail;
        $scope.User.name_on_ballot = nval.name;
        // alert($scope.User.Email);
        if (!$scope.User.Email) {
          //alert("here=", $scope.User.Email);
          $scope.sendPostCardNew = true;
        }

        //MyService.ConsoleLog("Email:::", $scope.User.Email);
        // var result = document.getElementsByClassName("ac-container");
        //MyService.ConsoleLog(result);
        // result[0].blur();

        document
          .getElementById("curCandidate")
          .getElementsByTagName("input")[0]
          .blur();
        $scope.onCandidateChange("running");
        //var nval = col.value? col.value: col.displayName
      };

      $scope.HideNewRegisterButton = false;
      $scope.shownew_office_name = false;
      $scope.checkExistsNew = function () {
        //MyService.ConsoleLog($scope.User.new_Candidates);
        MyService.ConsoleLog(
          "name_on_ballot<><><><>",
          $scope.User.name_on_ballot
        );
        if (
          $scope.User.new_Candidates != "" &&
          $scope.User.new_Candidates != $scope.User.name_on_ballot
        ) {
          //MyService.ConsoleLog("inside");
          $scope.User.name_on_ballot = $scope.User.new_Candidates;
        }

        if (!$scope.User.NCandidates) {
          //alert("NCandidates");
          $scope.shownew_office_name = true;
        }
      };

      function fuzzy_suggest_new(term) {
        if ($scope.User.level == "President") {
          MyService.getPresident({
            search: term,
          }).then(
            function (results) {
              MyService.ConsoleLog("Result >", results);

              if (results && results != "") {
                prefuzzySearch = new Fuse(results, {
                  shouldSort: true,
                  keys: ["name"],
                  caseSensitive: false,
                  threshold: 0.4,
                });
                MyService.ConsoleLog("prefuzzySearch >", prefuzzySearch);
                return prefuzzySearch
                  .search(term)
                  .slice(0, 10)
                  .map(function (i) {
                    //MyService.ConsoleLog("I",i);
                    //MyService.ConsoleLog($scope.candidates);
                    //MyService.ConsoleLog(i);
                    var val = i.name;
                    // MyService.ConsoleLog("val:::", val);
                    return {
                      value: JSON.stringify(i),
                      label: $sce.trustAsHtml(
                        highlight(val + "-" + i.office, term)
                      ),
                    };
                  });
              }
            },
            function (error) {
              MyService.ConsoleLog("Error >>", error);
            }
          );
        } else {
          if (!term) return [];
          //MyService.ConsoleLog(fuzzySearch);
          return fuzzySearch
            .search(term)
            .slice(0, 10)
            .map(function (i) {
              //MyService.ConsoleLog("I",i);
              //MyService.ConsoleLog($scope.candidates);
              //MyService.ConsoleLog(i);
              var val = i.name;
              //MyService.ConsoleLog("val:::", val);
              return {
                value: JSON.stringify(i),
                label: $sce.trustAsHtml(highlight(val + "-" + i.office, term)),
              };
            });
        }
      }

      $scope.autocomplete_new_options = {
        suggest: fuzzy_suggest_new,
        on_select: $scope.closeEditNew,
      };
      $scope.allPoliticians = [];

      function suggest_politician_name(term) {
        var results = [];
        for (var i = 0; i < $scope.allPoliticians.length; i++) {
          results.push({
            label: $sce.trustAsHtml(
              $scope.allPoliticians[i].name +
                "-" +
                $scope.allPoliticians[i].office
            ),
            value: JSON.stringify($scope.allPoliticians[i]),
          });
        }
        return results;
      }

      function suggest_politician_name_remote(term) {
        var deferred = $q.defer();
        var params = new Object();
        params.term = term;
        $scope.allPoliticians.length = 0;
        var request = apiCall.apiCall("GET", "/politician/fuzzySearch", params);
        // request.timeout = canceler;
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog("Got Politician: ", response);
            $scope.allPoliticians = response.data.data;
            /*
            if (response.data.success) {
              deferred.resolve(response.data.data);
            } else {
              deferred.reject(response.data);
            }
            */
            deferred.resolve(suggest_politician_name(term));
          },
          function errorCallBack(response) {
            deferred.reject(response);
          }
        );
        return deferred.promise;
      }

      $scope.onCandidateChangePolitician = function (val) {
        //$scope.User.Candidates;
        $scope.checkReadOnly = false;

        //MyService.ConsoleLog($scope.User.name_on_ballot);
        if ($scope.User.name_on_ballot) {
          if (
            $scope.User.name_on_ballot.name &&
            $scope.User.name_on_ballot.name != null
          ) {
            if ($scope.User.name_on_ballot.name != "Other") {
              $scope.User.name = $scope.User.name_on_ballot.name;
              if (
                $scope.User.name_on_ballot.PublicPhoneNumber &&
                $scope.User.name_on_ballot.PublicPhoneNumber != null
              ) {
                $scope.User.phone_no =
                  $scope.User.name_on_ballot.PublicPhoneNumber;
              } else {
                $scope.User.phone_no = "";
              }
              if (
                $scope.User.name_on_ballot.website &&
                $scope.User.name_on_ballot.website != null
              ) {
                $scope.User.web_url = $scope.User.name_on_ballot.website;
              } else {
                $scope.User.web_url = "";
              }
              if (
                $scope.User.name_on_ballot.public_zipcode &&
                $scope.User.name_on_ballot.public_zipcode != null
              ) {
                $scope.User.ZipCode = $scope.User.name_on_ballot.public_zipcode;
              } else {
                $scope.User.ZipCode = "";
              }
              if (
                $scope.User.name_on_ballot.public_mailing_address &&
                $scope.User.name_on_ballot.public_mailing_address != null
              ) {
                $scope.User.mailing_address =
                  $scope.User.name_on_ballot.public_mailing_address;
              } else {
                $scope.User.mailing_address = "";
              }
              /*
              if ($scope.User.name_on_ballot.email && $scope.User.name_on_ballot.email != null && $scope.User.name_on_ballot.email != 0) {
                var msg = "We already have your email with us.<br> Your Email in our db is : <br> <center>" + $scope.User.name_on_ballot.email + "<center><br> Do you want to use it for registration?"
                MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function(payload) {
                  $scope.User.Email = $scope.User.name_on_ballot.email;
                  $scope.checkReadOnly = true;
                }, function(errorPayload) {
                  $scope.User.Email = "";
                  $scope.checkReadOnly = false;
                });
              } else {
                $scope.User.Email = "";
                $scope.User.phone_no = "";
                $scope.User.web_url = "";
                $scope.User.ZipCode = "";
                $scope.User.mailing_address = "";
              }
              */
            } else {
              //If candidate is Other
              //MyService.ConsoleLog("Other selected");
            }
          } else {
            $scope.User.name = "";
            $scope.User.phone_no = "";
            $scope.User.web_url = "";
            $scope.User.ZipCode = "";
            $scope.User.mailing_address = "";
          }
        }
      };
      $scope.politicianSelected = false;
      $scope.selectedPolitician = function (col) {
        // alert("here");
        MyService.ConsoleLog("col", col);
        var nval = JSON.parse(col.value);
        //MyService.ConsoleLog(nval);
        $scope.User.name_on_ballot = nval.name;
        var result = document.getElementsByClassName("ac-container");
        //MyService.ConsoleLog(result);
        result[0].style.display = "none";
        // var wrappedResult = angular.element(result);
        //MyService.ConsoleLog(wrappedResult);
        $scope.politicianSelected = true;
        $scope.onCandidateChangePolitician();
        //var nval = col.value? col.value: col.displayName
      };

      $scope.autocomplete_politician_name = {
        suggest: suggest_politician_name_remote,
        on_select: $scope.selectedPolitician,
      };

      var running_flagCongressional = false;
      var flagCongressional = false;
      $scope.showCongressionalDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCongressionalDropdown");
        if (electionFor == "running") {
          if (
            $scope.User.office_name == "house of representatives" &&
            $scope.User.state
          ) {
            running_flagCongressional = true;
            return true;
          }
          running_flagCongressional = false;
          return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_office_name == "house of representatives" &&
            $scope.User.cur_state
          ) {
            flagCongressional = true;
            return true;
          }
          flagCongressional = false;
          return false;
        }
      };
      var running_flagDist = false;
      var flagDist = false;
      $scope.showDistrictDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "judicial district":
              if ($scope.User.state) {
                running_flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          running_flagDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "judicial district":
              if ($scope.User.cur_state) {
                flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          flagDist = false;
          return false;
        }
      };
      var running_flagConty = false;
      var flagConty = false;
      $scope.showCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.state) {
                running_flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.state &&
                exceptionState.indexOf($scope.User.state.statePostalCode) == -1
              ) {
                running_flagConty = true;
                return true;
              }
              break;
          }
          running_flagConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.cur_state) {
                flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.cur_state &&
                exceptionState.indexOf($scope.User.cur_state.statePostalCode) ==
                  -1
              ) {
                flagConty = true;
                return true;
              }
              break;
          }
          flagConty = false;
          return false;
        }
      };
      var running_flagSubConty = false;
      var flagSubConty = false;
      $scope.showSubCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSubCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "sub county":
              if ($scope.User.state && $scope.User.county) {
                running_flagSubConty = true;
                return true;
              }
              break;
          }
          running_flagSubConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "sub county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagSubConty = false;
                return true;
              }
              break;
          }
          flagSubConty = false;
          return false;
        }
      };
      var running_flagLocality = false;
      var flagLocality = false;
      $scope.showLocalityDropdown = function (electionFor) {
        //MyService.ConsoleLog("showLocalityDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "city":
              if ($scope.User.state && $scope.User.county) {
                running_flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          running_flagLocality = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "city":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          flagLocality = false;
          return false;
        }
      };
      var running_flagStateSchoolDist = false;
      var flagStateSchoolDist = false;
      $scope.showStateSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showStateSchoolDistDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state school board district":
              if ($scope.User.state) {
                running_flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          running_flagStateSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state school board district":
              if ($scope.User.cur_state) {
                flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          flagStateSchoolDist = false;
          return false;
        }
      };
      var running_flagLocalSchoolDist = false;
      var flagLocalSchoolDist = false;
      $scope.showLocalSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "local school district":
              if ($scope.User.state) {
                if ($scope.User.state && $scope.localSchoolDistException()) {
                  if ($scope.User.state && $scope.User.county) {
                    running_flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  running_flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }
          running_flagLocalSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "local school district":
              if ($scope.User.cur_state) {
                if (
                  $scope.User.cur_state &&
                  $scope.localSchoolDistException()
                ) {
                  if ($scope.User.cur_state && $scope.User.cur_county) {
                    flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.cur_state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }
          flagLocalSchoolDist = false;
          return false;
        }
      };

      $scope.congressionalDistricts = [];
      var getAddressOfRunningPoliCanceler = $q.defer();
      $scope.running_flagShowOfficeDropdown = false;
      $scope.flagShowOfficeDropdown = false;
      $scope.showlevelOfGovt = [];
      $scope.onStateChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateChange **", electionFor);
        if (electionFor == "running") {
          //'State and Federal', 'County', 'School', 'Local', 'Judicial'
          switch ($scope.User.level) {
            case "County":
              $scope.showlevelOfGovt = angular.copy($scope.countylevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "State and Federal":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              MyService.ConsoleLog(
                " $scope.showlevelof govt :",
                $scope.showlevelOfGovt
              );
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "School":
              $scope.showlevelOfGovt = angular.copy($scope.schoollevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "Judicial":
              $scope.showlevelOfGovt = angular.copy($scope.judiciallevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.running_flagShowOfficeDropdown = false;
              break;
          }
          //MyService.ConsoleLog($scope.User.level);
          /*setTimeout(function() {
            $scope.$apply(function() {
              $scope.showlevelOfGovt = $scope.showlevelOfGovt1;
              //MyService.ConsoleLog($scope.showlevelOfGovt);
              $scope.User.off_name = $scope.showlevelOfGovt[0];
            });
          }, 100);*/
          //$scope.User.off_name = $scope.showlevelOfGovt[0];

          /*
          if ($scope.User.state) {

            switch ($scope.User.level) {
              case 'state':
              if ($scope.User.state) {
                $scope.User.office_name = null;
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is = "State Postal Code", and item Q is the state selected.  Then add “other” to the end of the list
                $scope.running_offices = [];
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  voterScope: "State Postal Code",
                  return_field: "office",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_offices = response.data.data;
                      $scope.running_offices.push("other");
                      //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'judicial district':
              if ($scope.User.state) {
                $scope.User.office_name = null;
                // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "JudicialCircuitNumber",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_districts = response.data.data;
                      //MyService.ConsoleLog("--- Got district data ---: ", $scope.running_districts);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case "county":
              case "sub county":
              case "city":
              if ($scope.User.state) {
                $scope.User.office_name = null;
                // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "CountyName",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_counties = response.data.data;
                      //MyService.ConsoleLog("--- Got counties data ---: ", $scope.running_counties);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'state school board district':
              if ($scope.User.state) {
                $scope.User.office_name = null;
                // do a search through the table currently labelled “2016 Alabama” and list all item E’s where item Q is the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "StateBoardEducationDistrict",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_stateSchoolDistricts = response.data.data;
                      //MyService.ConsoleLog("--- Got district data ---: ", running_stateSchoolDistricts);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'local school district':
              $scope.User.office_name = null;
              if (!$scope.localSchoolDistException()) {
                // /if($scope.User.cur_state && $scope.localSchoolDistException())
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "CountyName",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_counties = response.data.data;
                      //MyService.ConsoleLog("--- Got counties data ---: ", $scope.running_counties);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              } else {
                //for options
                // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "UnifiedSchoolDistrictName",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      var tempArr = [];
                      angular.forEach(response.data.data, function(val, key) {
                        tempArr.push({
                          label: val,
                          type: "UnifiedSchoolDistrictName"
                        });
                      });
                      $scope.localSchoolDistricts = tempArr;
                      //MyService.ConsoleLog("--- Got localSchoolDistricts data ---: ", $scope.localSchoolDistricts);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;
            }

            if ($scope.User.office_name) {
              if ($scope.User.state) {
                getAddressOfRunningPoliCanceler.resolve();
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.state.statePostalCode,
                  return_field: "FederalCongressionalDistrict",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.running_congressionalDistricts = response.data.data;
                      //MyService.ConsoleLog("--- Got congressionalDistricts data ---");
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
            }
          } //state selected
          */
          //$scope.User.off_name = $scope.showlevelOfGovt[0];
        } else if (electionFor == "incumbent") {
          /*
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case 'state':
              if ($scope.User.cur_state) {
                $scope.User.cur_office_name = null;
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is = "State Postal Code", and item Q is the state selected.  Then add “other” to the end of the list
                $scope.offices = [];
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.cur_state.statePostalCode,
                  voterScope: "State Postal Code",
                  return_field: "office",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.offices = response.data.data;
                      $scope.offices.push("other");
                      //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'judicial district':
              if ($scope.User.cur_state) {
                $scope.User.cur_office_name = null;
                // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.cur_state.statePostalCode,
                  return_field: "JudicialCircuitNumber",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.districts = response.data.data;
                      //MyService.ConsoleLog("--- Got district data ---: ", $scope.districts);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case "county":
              case "sub county":
              case "city":
              if ($scope.User.cur_state) {
                $scope.User.cur_office_name = null;
                // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.cur_state.statePostalCode,
                  return_field: "CountyName",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.counties = response.data.data;
                      //MyService.ConsoleLog("--- Got counties data ---: ", $scope.counties);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'state school board district':
              if ($scope.User.cur_state) {
                $scope.User.cur_office_name = null;
                // do a search through the table currently labelled “2016 Alabama” and list all item E’s where item Q is the state selected.
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.cur_state.statePostalCode,
                  return_field: "StateBoardEducationDistrict",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.stateSchoolDistricts = response.data.data;
                      //MyService.ConsoleLog("--- Got district data ---: ", $scope.districts);
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
              break;

              case 'local school district':
              if ($scope.User.cur_state) {
                $scope.User.cur_office_name = null;
                if (!$scope.localSchoolDistException()) {
                  // /if($scope.User.cur_state && $scope.localSchoolDistException())
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    return_field: "CountyName",
                    timeout: getAddressOfRunningPoliCanceler.promise
                  }).then(function(response) {
                    if (response.data.success == true) {
                      $timeout(function() {
                        $scope.counties = response.data.data;
                        //MyService.ConsoleLog("--- Got counties data ---: ", $scope.counties);
                      });
                    } else if (response.data.error && typeof response.data.error == "string") {
                      growl.error('Error: ' + response.data.error);
                    } else {
                      growl.error('Something went wrong.');
                    }
                  });
                } else {
                  //for options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    return_field: "UnifiedSchoolDistrictName",
                    timeout: getAddressOfRunningPoliCanceler.promise
                  }).then(function(response) {
                    if (response.data.success == true) {
                      $timeout(function() {
                        var tempArr = [];
                        angular.forEach(response.data.data, function(val, key) {
                          tempArr.push({
                            label: val,
                            type: "UnifiedSchoolDistrictName"
                          });
                        });
                        $scope.localSchoolDistricts = tempArr;
                        //MyService.ConsoleLog("--- Got localSchoolDistricts data ---: ", $scope.localSchoolDistricts);
                      });
                    } else if (response.data.error && typeof response.data.error == "string") {
                      growl.error('Error: ' + response.data.error);
                    } else {
                      growl.error('Something went wrong.');
                    }
                  });
                }
              }
              break;
            }

            if ($scope.User.cur_office_name) {
              if ($scope.User.cur_state) {
                getAddressOfRunningPoliCanceler.resolve();
                MyService.getAddressOfRunningPoli({
                  StatePostalCode: $scope.User.cur_state.statePostalCode,
                  return_field: "FederalCongressionalDistrict",
                  timeout: getAddressOfRunningPoliCanceler.promise
                }).then(function(response) {
                  if (response.data.success == true) {
                    $timeout(function() {
                      $scope.congressionalDistricts = response.data.data;
                      //MyService.ConsoleLog("--- Got congressionalDistricts data ---");
                    });
                  } else if (response.data.error && typeof response.data.error == "string") {
                    growl.error('Error: ' + response.data.error);
                  } else {
                    growl.error('Something went wrong.');
                  }
                });
              }
            }
          } //state selected
          */
          switch ($scope.User.cur_level) {
            ////'State and Federal', 'County', 'School', 'Local', 'Judicial'
            case "County":
              $scope.showlevelOfGovt = angular.copy($scope.countylevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "State and Federal":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "School":
              $scope.showlevelOfGovt = angular.copy($scope.schoollevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "Judicial":
              $scope.showlevelOfGovt = angular.copy($scope.judiciallevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
          }
          //MyService.ConsoleLog($scope.showlevelOfGovt);
          //$scope.User.cur_off_name = $scope.showlevelOfGovt[0];
        }
      };
      $scope.onDistrictChange = function (electionFor) {
        //MyService.ConsoleLog("** onDistrictChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "judicial district":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onDistrictChange **");
            switch ($scope.User.cur_level) {
              case "judicial district":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.cur_district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        }
      };

      $scope.onCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "county":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.state && $scope.User.county) {
                  $scope.User.cur_office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.running_subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.running_localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.running_localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                $scope.User.office_name = null;
                if (!$scope.localSchoolDistException() && $scope.User.county) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.state.statePostalCode,
                        CountyName: $scope.county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.running_localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "county":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.cur_state && $scope.User.cur_county) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                //MyService.ConsoleLog("cur_county : ", $scope.User.cur_county);
                $scope.User.cur_office_name = null;
                if (
                  !$scope.localSchoolDistException() &&
                  $scope.User.cur_county
                ) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.cur_state.statePostalCode,
                        CountyName: $scope.cur_county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        }
      };
      $scope.onSubCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "sub county":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "sub county":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalityChange = function (electionFor) {
        //MyService.ConsoleLog("** onLocalityChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "city":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.locality
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    CountySubdivisionName: $scope.User.locality.label,
                    PlaceName: $scope.User.locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "city":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.cur_locality
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    CountySubdivisionName: $scope.User.cur_locality.label,
                    PlaceName: $scope.User.cur_locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onStateSchoolDistChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateSchoolDistChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "state school board district":
                if ($scope.User.state && $scope.User.state_school_dist) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    StateBoardEducationDistrict: $scope.User.state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "state school board district":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_state_school_dist
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    StateBoardEducationDistrict:
                      $scope.User.cur_state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalSchoolDistChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        }
      };

      $scope.CheckUserName = function () {
        $scope.Errors = [];
        MyService.checkUnm({
          username: $scope.User.UserName,
        }).then(
          function (response) {
            if (response.data.taken == true) {
              //$scope.Errors.push('Oops! Username ' + $scope.User.UserName + ' has already been taken.');
              growl.error(
                "Oops! Username " +
                  $scope.User.UserName +
                  " has already been taken."
              );
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.SignIn = function () {
        $http
          .post(baseApiUrl + "user/authenticate", {
            username: $scope.User.UserName,
            password: $scope.User.Password,
          })
          .then(
            function (response) {
              if (response.data.success == true) {
                tokenService.saveToken(response.data.token);
                tokenService.saveUserName(response.data.name);
                $http.defaults.headers.common["Authorization"] =
                  tokenService.getToken();
                $rootScope.Auth = true;
                $rootScope.User = tokenService.getUserName();
                $location.path("debate");
              } else {
                growl.error("Invalid Username or Password. Please try again.");
              }
            },
            function (response) {
              var validataionMsg = "";
              var errors = response.data.error.errors;
              for (var k in errors) {
                if (errors.hasOwnProperty(k)) {
                  var obj = errors[k];
                  if (obj.message !== undefined)
                    validataionMsg += obj.message.replace("Path", "") + "<br/>";
                }
              }
              growl.error(validataionMsg);
            }
          );
      };

      $scope.generatePostUserObj = function () {
        console.log("dates", $scope.User.from_date);
        if ($.fn.validateForceFully($("#addPoli")) == true) {
          $scope.Errors = [];

          var mainLocation = JSON.stringify({
            longitude: $scope.lon ? $scope.lon : $scope.longg,
            latitude: $scope.lati ? $scope.lati : $scope.latt,
          });

          MyService.ConsoleLog("latitude and longitude :", mainLocation);
          MyService.ConsoleLog("luser :", $scope.User.cur_level);
          MyService.ConsoleLog("$scope.UserType :", $scope.UserType);

          if ($scope.UserType == "advocate") {
            // alert("welcome");
            var mainAddress = JSON.stringify({
              state: $scope.state,
              statePostalCode: $scope.statePostalCode
                ? $scope.statePostalCode
                : $scope.astatePostalCode,
              place: $scope.place_name ? $scope.place_name : $scope.aplace,
              county: $scope.county_name ? $scope.county_name : $scope.acounty,
              country: $scope.countryname
                ? $scope.countryname
                : $scope.acountry,
              countySubdivision: $scope.address.countySubdivision
                ? $scope.address.countySubdivision._id.countySubdivision
                : $scope.acountySubdivision,
              federalCongrassionalDist: $scope.address.federalCongrassionalDist
                ? $scope.address.federalCongrassionalDist
                    .federalCongrassionalDist
                : "",
              stateSenateDistrict: $scope.address.stateSenateDistrict
                ? $scope.address.stateSenateDistrict.stateSenateDistrict
                : "",
              schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
                : "",
              schoolDistrict: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName
                    .unifiedSchoolDistrictName
                : "",
              voterDistrictFips: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrict
                : "",
              voterDistrict: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrictName
                : "",
              legislativeDistrict: $scope.address
                .stateHouseOFRepresentativeDistrict
                ? $scope.stateHouseOFRepresentativeDistrict
                : "",
            });
          } else {
            if ($scope.User.cur_level != "County") {
              // alert("flag");
              $scope.checkFlag = true;
            }

            var mainAddress = JSON.stringify({
              state: $scope.stateName
                ? $scope.stateName
                : $scope.User.cur_state
                ? $scope.User.cur_state.state
                : $scope.User.state.state,
              statePostalCode: $scope.address.state
                ? $scope.address.state.statePostalCode
                : $scope.statePostalCode,
              stateFips: $scope.address.state
                ? $scope.address.state.stateFips
                : "",
              place: $scope.address.place ? $scope.address.place.city : "",
              placeFips: $scope.address.place
                ? $scope.address.place.placeFips
                : "",
              county: $scope.address.county
                ? $scope.address.county.countyName
                : "",
              countyFips: $scope.address.county
                ? $scope.address.county.countyFips
                : "",
              country: $scope.countryname ? $scope.countryname : "",
              countySubdivision: $scope.address.countySubdivision
                ? $scope.address.countySubdivision.countySubdivision
                : "",
              countySubdivisionFips: $scope.address.countySubdivision
                ? $scope.address.countySubdivision.countySubdivisionFips
                : "",
              federalCongrassionalDist: $scope.address.federalCongrassionalDist
                ? $scope.address.federalCongrassionalDist
                    .congressionalDistrictFips
                : "",
              stateSenateDistrict: $scope.address.stateSenateDistrict
                ? $scope.address.stateSenateDistrict.stateSenateDistrictFips
                : "",
              schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName
                    .unifiedSchoolDistrictFips
                : "",
              schoolDistrict: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
                : "",
              voterDistrictFips: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrict
                : "",
              voterDistrict: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrictName
                : "",
              legislativeDistrict: $scope.address
                .stateHouseOFRepresentativeDistrict
                ? $scope.address.stateHouseOFRepresentativeDistrict
                    .stateSenateDistrict
                : "",
            });

            var poliAddress = JSON.stringify({
              state: $scope.User.cur_state
                ? $scope.User.cur_state.state
                : $scope.User.state.state,
              statePostalCode: $scope.User.cur_state
                ? $scope.User.cur_state.statePostalCode
                : "",
              stateFips: $scope.User.cur_state
                ? $scope.User.cur_state.stateFips
                : "",
              place: $scope.address.place ? $scope.address.place.city : "",
              placeFips: $scope.address.place
                ? $scope.address.place.placeFips
                : "",
              county: $scope.address.county
                ? $scope.address.county.countyName
                : "",
              countyFips: $scope.address.county
                ? $scope.address.county.countyFips
                : "",
              country: $scope.countryname
                ? $scope.countryname
                : $scope.acountry,
              countySubdivision: $scope.address.countySubdivision
                ? $scope.address.countySubdivision.countySubdivision
                : $scope.acountySubdivision,
              countySubdivisionFips: $scope.address.countySubdivision
                ? $scope.address.countySubdivision.countySubdivisionFips
                : "",
              federalCongrassionalDist: $scope.address.federalCongrassionalDist
                ? $scope.address.federalCongrassionalDist
                    .congressionalDistrictFips
                : "",
              stateSenateDistrict: $scope.address.stateSenateDistrict
                ? $scope.address.stateSenateDistrict.stateSenateDistrictFips
                : "",
              schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName
                    .unifiedSchoolDistrictFips
                : "",
              schoolDistrict: $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
                : "",
              voterDistrictFips: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrict
                : "",
              voterDistrict: $scope.address.voterDistrict
                ? $scope.address.voterDistrict.voterDistrictName
                : "",
              legislativeDistrict: $scope.address
                .stateHouseOFRepresentativeDistrict
                ? $scope.address.stateHouseOFRepresentativeDistrict
                    .stateHouseofRepresentativesDistrictName
                : "",
            });
            // }
            // if ($scope.User.state && $scope.User.state != "" || $scope.User.cur_state && $scope.User.cur_state != "") {
            var runningPoliInfo = JSON.stringify({
              state: $scope.User.state
                ? $scope.User.state.state
                : $scope.User.cur_state
                ? $scope.User.cur_state.state
                : "",
              statePostalCode: $scope.User.state
                ? $scope.User.state.statePostalCode
                : $scope.User.cur_state
                ? $scope.User.cur_state.statePostalCode
                : "",
              stateFips: $scope.User.state ? $scope.User.state.stateFips : "",
              place: $scope.runAddress.place
                ? $scope.runAddress.place.city
                : $scope.address.place
                ? $scope.address.place.city
                : "",
              placeFips: $scope.runAddress.place
                ? $scope.runAddress.place.placeFips
                : "",
              county: $scope.runAddress.county
                ? $scope.runAddress.county.countyName
                : $scope.address.county
                ? $scope.address.county.countyName
                : "",
              countyFips: $scope.runAddress.county
                ? $scope.runAddress.county.countyFips
                : "",
              country: $scope.countryname
                ? $scope.countryname
                : $scope.acountry,
              countySubdivision: $scope.runAddress.countySubdivision
                ? $scope.runAddress.countySubdivision.countySubdivision
                : $scope.acountySubdivision,
              countySubdivisionFips: $scope.runAddress.countySubdivision
                ? $scope.runAddress.countySubdivision.countySubdivisionFips
                : "",
              federalCongrassionalDist: $scope.runAddress
                .federalCongrassionalDist
                ? $scope.runAddress.federalCongrassionalDist
                    .congressionalDistrictFips
                : $scope.address.federalCongrassionalDist
                ? $scope.address.federalCongrassionalDist
                    .congressionalDistrictFips
                : "",
              stateSenateDistrict: $scope.runAddress.stateSenateDistrict
                ? $scope.runAddress.stateSenateDistrict.stateSenateDistrictFips
                : $scope.address.stateSenateDistrict
                ? $scope.address.stateSenateDistrict.stateSenateDistrictFips
                : "",
              schoolDistrictFips: $scope.runAddress.unifiedSchoolDistrictName
                ? $scope.runAddress.unifiedSchoolDistrictName
                    .unifiedSchoolDistrictFips
                : $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName
                    .unifiedSchoolDistrictFips
                : "",
              schoolDistrict: $scope.runAddress.unifiedSchoolDistrictName
                ? $scope.runAddress.unifiedSchoolDistrictName
                    .unifiedSchoolDistrict
                : $scope.address.unifiedSchoolDistrictName
                ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
                : "",
              voterDistrictFips: $scope.runAddress.voterDistrict
                ? $scope.runAddress.voterDistrict.voterDistrict
                : "",
              voterDistrict: $scope.runAddress.voterDistrict
                ? $scope.runAddress.voterDistrict.voterDistrictName
                : "",
              legislativeDistrict: $scope.runAddress
                .stateHouseOFRepresentativeDistrict
                ? $scope.runAddress.stateHouseOFRepresentativeDistrict
                    .stateHouseofRepresentativesDistrictName
                : $scope.address.stateHouseOFRepresentativeDistrict
                ? $scope.address.stateHouseOFRepresentativeDistrict
                    .stateHouseofRepresentativesDistrictName
                : "",
            });
          }

          // }

          // MyService.ConsoleLog("runningPoliInfo >>", runningPoliInfo);
          $scope.chndate = function () {
            MyService.ConsoleLog(
              "$scope.User.runElectionDate >",
              $scope.User.runElectionDate
            );
          };

          if (
            $scope.User.runElectionDate &&
            $scope.User.runElectionDate != ""
          ) {
            // $scope.User.runElectionDate ? $scope.User.runElectionDate : "
            // MyService.ConsoleLog("$scope.User.runElectionDate ", $scope.User.runElectionDate)
            // var eleDate = $scope.User.runElectionDate.toJSON();
            var month = (
              "0" +
              ($scope.User.runElectionDate.getMonth() + 1)
            ).slice(-2);
            var dat = ("0" + $scope.User.runElectionDate.getDate()).slice(-2);
            MyService.ConsoleLog("dat >>", dat);
            var finDate =
              $scope.User.runElectionDate.getFullYear() +
              "-" +
              month +
              "-" +
              dat;
            MyService.ConsoleLog(
              "$scope.User.runElectionDate ",
              $scope.User.runElectionDate.getDate()
            );
            MyService.ConsoleLog(
              "$scope.User.runElectionDate ",
              $scope.User.runElectionDate.getMonth()
            );
            MyService.ConsoleLog(
              "$scope.User.runElectionDate ",
              finDate.toString()
            );
          }

          var postobj = {
            username: $scope.User.UserName,
            password: $scope.User.Password,
            email: $scope.User.Email,
            zipcode: $scope.User.ZipCode,
            type: $scope.UserType,
            postCard: $scope.User.postCard,
            verifiedUser: $scope.verifiedUser,
            verificationCell: $scope.verificationCell,
            verificationCarrier: $scope.verificationCarrier,
            verificationMethod: $scope.verificationMethod,
            verificationurl: $rootScope.getVerificationUrl(),
            address: mainAddress,
            unsubscribeurl: $rootScope.getEmailUnsubscribeUrl(),
            loc: mainLocation,
            mailingAddress: $scope.mailing_Address
              ? $scope.mailing_Address
              : $scope.mailAdd,
            from_date: "",
            end_date: "",
            flagAddPoli: true,
            checkFlag: $scope.checkFlag ? $scope.checkFlag : "",
            publicEmail: $scope.User.cur_office_email
              ? $scope.User.cur_office_email
              : "",
            campaignWebSite: $scope.User.campWeb_url
              ? $scope.User.campWeb_url
              : "",
            run_electionDate: finDate ? finDate : "",
            campaignEmail: $scope.User.campEmail ? $scope.User.campEmail : "",
            cur_office_website: $scope.User.cur_office_website
              ? $scope.User.cur_office_website
              : "",
            cur_office_phone: $scope.User.cur_office_phone
              ? $scope.User.cur_office_phone
              : "",
            campPhone_no: $scope.User.campPhone_no
              ? $scope.User.campPhone_no
              : "",
            office_id: $scope.office_id ? $scope.office_id : "",
          };
          console.log("test type", $scope.UserType);
          MyService.ConsoleLog(
            " my office first >>>>>>>>>> :",
            $scope.User.cur_office_name
          );

          switch ($scope.UserType.toLowerCase()) {
            case "advocate":
              postobj.name = $scope.User.name;
              postobj.position = $scope.User.position;
              postobj.extention_no = $scope.User.extention_no;
              postobj.verification_note = $scope.User.verification_note;
              postobj.typeOfOrg = $scope.User.organization.orgType;
              postobj.empIndNo = $scope.User.organization.empIndNo;
              postobj.officeName = $scope.User.organization_name;
              postobj.officeWebUrl = $scope.User.organization.web_url;
              postobj.officePhoneNumber = $scope.User.organization.phone_no;
              break;

            case "press":
              postobj.name = $scope.User.name + " " + $scope.User.lastname;

              postobj.extention_no = $scope.User.press_extension_no;
              postobj.verification_note = $scope.User.verification_note;
              postobj.typeOfOrg = $scope.User.orgType;
              postobj.empIndNo = $scope.User.empIndNo;
              postobj.officeName = $scope.User.outlet_name;
              postobj.officeWebUrl = $scope.User.outlet_web;
              postobj.officePhoneNumber = $scope.User.outlet_phone_no;

              break;
            case "politician":
              postobj.name_on_ballot = $scope.User.name_on_ballot
                ? $scope.User.name_on_ballot
                : "";
              postobj.name = $scope.User.name;

              postobj.phone_no = $scope.User.phone_no;

              postobj.mailing_address = $scope.User.mailing_address;

              if (
                $scope.User.govtOfficialWebsite &&
                $scope.User.govtOfficialWebsite != ""
              ) {
                postobj.govtOfficialWebsite = $scope.User.govtOfficialWebsite;
              }
              if ($scope.User.Candidates && $scope.User.Candidates._id) {
                postobj.cur_office_id = $scope.User.Candidates._id;
              }
              if ($scope.User.NCandidates && $scope.User.NCandidates._id) {
                postobj.office_id = $scope.User.NCandidates._id;
              }
              if ($scope.User.isInCurrentOffice == 1) {
                try {
                  MyService.ConsoleLog(
                    " my office >>>>>>>>>> :",
                    $scope.User.cur_office_name
                  );

                  if ($scope.User.cur_office_name) {
                    if ($scope.User.cur_office_name.officeName) {
                      if ($scope.User.cur_office_name.officeName == "Other") {
                        MyService.ConsoleLog(
                          "newcur_office_name >",
                          $scope.User.newcur_office_name
                        );
                        postobj.cur_office_name =
                          $scope.User.newcur_office_name;
                      } else {
                        postobj.cur_office_name =
                          $scope.User.cur_office_name.officeName;
                        MyService.ConsoleLog("yes go it");
                      }
                    } else {
                      postobj.cur_office_name = $scope.User.cur_office_name;
                    }
                    // console.log('current office >>>>>>>>>>>>>>>', $scope.User.cur_office_name);
                  } else {
                    if ($filter("isBlankString")($scope.User.cur_office_name)) {
                      growl.error("Current Office name is not selected");
                      return null;
                    } else {
                      if ($scope.User.cur_office_name.officeName) {
                        if ($scope.User.cur_office_name.officeName == "Other") {
                          MyService.ConsoleLog(
                            "newcur_office_name two >",
                            $scope.User.newcur_office_name
                          );
                          postobj.cur_office_name =
                            $scope.User.newcur_office_name;
                        } else {
                          postobj.cur_office_name =
                            $scope.User.cur_office_name.officeName;
                          MyService.ConsoleLog("yes go it");
                        }
                      } else {
                        postobj.cur_office_name = $scope.User.cur_office_name;
                      }
                    }
                  }

                  if ($scope.User.cur_level) {
                    MyService.ConsoleLog(
                      " User.cur_level first >>>>>>> ",
                      $scope.User.cur_level
                    );

                    switch ($scope.User.cur_level) {
                      case "Federal":
                        if (
                          $scope.User.cur_office_name.officeName ==
                          "U.S. Senate"
                        ) {
                          postobj.cur_level = "statePostalCode";
                        } else if (
                          $scope.User.cur_office_name.officeName ==
                          "U.S. Senate"
                        ) {
                          postobj.cur_level = "statePostalCode";
                        } else {
                          postobj.cur_level = "federalCongressionalDistrict";
                        }
                        break;
                      case "Other":
                        if (
                          $scope.User.otherLevel &&
                          $scope.User.otherLevel != ""
                        ) {
                          MyService.ConsoleLog(
                            "User.otherLevel >",
                            $scope.User.otherLevel
                          );
                          postobj.cur_level = "All USA";
                        } else {
                          postobj.cur_level = "All USA";
                        }
                        break;
                      case "State":
                        MyService.ConsoleLog(
                          " $scope.User.cur_office_name first >>>>>>> ",
                          $scope.User.cur_office_name
                        );
                        if (
                          $scope.User.cur_office_name.officeName ==
                          "State Senate"
                        ) {
                          postobj.cur_level = "stateSenateDistrict";
                        } else {
                          postobj.cur_level = "statePostalCode";
                        }
                        MyService.ConsoleLog("check level", postobj.cur_level);
                        break;
                      case "County":
                        postobj.cur_level = "countyName";
                        break;
                      case "Local":
                        if (
                          $scope.address.unifiedSchoolDistrictName &&
                          $scope.address.unifiedSchoolDistrictName
                            .unifiedSchoolDistrict != ""
                        ) {
                          postobj.cur_level = "unifiedSchoolDistrictName";
                        } else {
                          postobj.cur_level = "placeName";
                        }
                        break;
                      case "President":
                        postobj.cur_level = "All USA";
                        break;
                      default:
                        break;
                    }
                  }

                  // if ($scope.User.cur_ElectionDate) {
                  //   postobj.cur_electionDate = $scope.User.cur_ElectionDate ? $scope.User.cur_ElectionDate : "";
                  // }

                  // if ($scope.User.cur_status) {
                  postobj.cur_candidateStatus = "active";
                  // }

                  // if ($scope.User.cur_eleType) {
                  //   postobj.cur_electionType = $scope.User.cur_eleType ? $scope.User.cur_eleType : "";
                  // }

                  /*if($filter("isBlankString")($scope.User.cur_jurisdiction_name)){
                    growl.error("Current jurisdiction name is not selected");
                    return null;
                  }*/

                  // postobj.cur_jurisdiction_name = $scope.User.cur_jurisdiction_name;
                  if ($scope.User.cur_state) {
                    var state = $scope.User.cur_state;
                    //MyService.ConsoleLog(state);
                    //MyService.ConsoleLog(state.state);
                    postobj.cur_state = state.state;
                    postobj.cur_state_postal_code = state.statePostalCode;
                  }

                  if ($scope.User.Candidates) {
                    if (
                      $scope.User.Candidates.FederalCongressionalDistrict &&
                      $scope.User.Candidates.FederalCongressionalDistrict !=
                        null
                    ) {
                      postobj.cur_federal_congrassional_dist =
                        $scope.User.Candidates.FederalCongressionalDistrict;
                    }

                    if (
                      $scope.User.Candidates.JudicialCircuitNumber &&
                      $scope.User.Candidates.JudicialCircuitNumber != null
                    ) {
                      postobj.cur_judicial_circuit_no =
                        $scope.User.Candidates.JudicialCircuitNumber;
                    }

                    if (
                      $scope.User.Candidates.CountyName &&
                      $scope.User.Candidates.CountyName != null
                    ) {
                      postobj.cur_county = $scope.User.Candidates.CountyName;
                    }

                    if (
                      $scope.User.Candidates.CountySubdivisionName &&
                      $scope.User.Candidates.CountySubdivisionName != null
                    ) {
                      postobj.cur_subcounty =
                        $scope.User.Candidates.CountySubdivisionName;
                    }

                    if (
                      $scope.User.Candidates.PlaceName &&
                      $scope.User.Candidates.PlaceName != null
                    ) {
                      postobj.cur_place = $scope.User.Candidates.PlaceName;
                    }

                    if (
                      $scope.User.Candidates.StateBoardEducationDistrict &&
                      $scope.User.Candidates.StateBoardEducationDistrict != null
                    ) {
                      postobj.cur_state_board_education_dist =
                        $scope.User.Candidates.StateBoardEducationDistrict;
                    }

                    if (
                      $scope.User.Candidates.UnifiedSchoolDistrictName &&
                      $scope.User.Candidates.UnifiedSchoolDistrictName != null
                    ) {
                      postobj.cur_unified_school_dist =
                        $scope.User.Candidates.UnifiedSchoolDistrictName;
                    }

                    if (
                      $scope.User.Candidates.ElementarySchoolDistrictName &&
                      $scope.User.Candidates.ElementarySchoolDistrictName !=
                        null
                    ) {
                      postobj.cur_elementary_school_dist =
                        $scope.User.Candidates.ElementarySchoolDistrictName;
                    }

                    if (
                      $scope.User.Candidates.SecondarySchoolDistrictName &&
                      $scope.User.Candidates.SecondarySchoolDistrictName != null
                    ) {
                      postobj.cur_secondary_school_dist =
                        $scope.User.Candidates.SecondarySchoolDistrictName;
                    }
                  }
                } catch (e) {
                  //MyService.ConsoleLog("Exceptoin: ", e);
                  $scope.RegisterButton = false;
                  growl.error("Valid Office Name is required.");
                  return null;
                }
              }

              if ($scope.User.isRunningForEle == 1) {
                if ($scope.User.RunningForOtherOffice == 1) {
                  postobj.cur_office_name = $scope.User.cur_office_name
                    .officeName
                    ? $scope.User.cur_office_name.officeName
                    : $scope.User.cur_office_name;
                  postobj.office_name = $scope.User.cur_office_name.officeName
                    ? $scope.User.cur_office_name.officeName
                    : $scope.User.cur_office_name;
                  postobj.run_candidateStatus = $scope.User.runStatus
                    ? $scope.User.runStatus
                    : "";
                  if ($scope.User.cur_level) {
                    MyService.ConsoleLog(
                      " User.cur_level second >>>>>>> ",
                      $scope.User.cur_level
                    );
                    switch ($scope.User.cur_level) {
                      case "Federal":
                        if ($scope.User.office_name == "U.S. Senate") {
                          postobj.level = "statePostalCode";
                        } else if (
                          $scope.User.office_name.officeName == "U.S. Senate"
                        ) {
                          postobj.level = "statePostalCode";
                        } else {
                          postobj.level = "federalCongressionalDistrict";
                        }
                        break;
                      case "Other":
                        if (
                          $scope.User.otherLevel &&
                          $scope.User.otherLevel != ""
                        ) {
                          MyService.ConsoleLog(
                            "User.otherLevel >",
                            $scope.User.otherLevel
                          );
                          postobj.level = "All USA";
                        } else {
                          postobj.level = "All USA";
                        }
                        break;
                      case "State":
                        MyService.ConsoleLog(
                          "$scope.User.office_name",
                          $scope.User.office_name
                        );
                        if ($scope.User.office_name == "State Senator") {
                          postobj.level = "stateSenateDistrict";
                        } else if (
                          $scope.User.office_name.officeName == "State Senate"
                        ) {
                          postobj.level = "stateSenateDistrict";
                        } else {
                          postobj.level = "statePostalCode";
                        }
                        MyService.ConsoleLog(
                          "check level second",
                          postobj.level
                        );
                        break;
                      case "County":
                        postobj.level = "countyName";
                        break;
                      case "Local":
                        if (
                          $scope.address.unifiedSchoolDistrictName &&
                          $scope.address.unifiedSchoolDistrictName
                            .unifiedSchoolDistrict != ""
                        ) {
                          postobj.level = "unifiedSchoolDistrictName";
                        } else {
                          postobj.level = "placeName";
                        }
                        break;
                      case "President":
                        postobj.level = "All USA";
                        break;
                      default:
                        break;
                    }
                    // postobj.cur_level = $scope.User.cur_level;
                  }
                } else {
                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.office
                  ) {
                    if ($scope.User.NCandidates.office) {
                      // if(!$scope.User.NCandidates.office.officeName)
                      if (
                        $scope.User.NCandidates.office.officeName == "Other"
                      ) {
                        postobj.office_name = $scope.User.newoffice_name
                          ? $scope.User.newoffice_name
                          : "";
                      } else {
                        postobj.office_name = $scope.User.NCandidates.office
                          .officeName
                          ? $scope.User.NCandidates.office.officeName
                          : $scope.User.NCandidates.office;
                      }
                    }
                  } else {
                    if ($filter("isBlankString")($scope.User.office_name)) {
                      growl.error("Office name is not selected");
                      return null;
                    } else {
                      if ($scope.User.office_name.officeName == "Other") {
                        postobj.office_name = $scope.User.newoffice_name;
                      } else {
                        postobj.office_name = $scope.User.office_name.officeName
                          ? $scope.User.office_name.officeName
                          : $scope.User.office_name;
                      }
                    }
                  }
                  if ($scope.User.level) {
                    MyService.ConsoleLog(
                      " got lvl of govt :",
                      $scope.User.level
                    );

                    switch ($scope.User.level) {
                      case "Federal":
                        if ($scope.User.office_name == "U.S. Senate") {
                          postobj.level = "statePostalCode";
                        } else if (
                          $scope.User.office_name.officeName == "U.S. Senate"
                        ) {
                          postobj.level = "statePostalCode";
                        } else {
                          postobj.level = "federalCongressionalDistrict";
                        }
                        break;
                      case "Other":
                        if ($scope.User.runOtherLevel) {
                          MyService.ConsoleLog(
                            "$scope.User.runOtherLevel >",
                            $scope.User.runOtherLevel
                          );
                          postobj.level = "All USA";
                        } else {
                          postobj.level = "All USA";
                        }
                        break;
                      case "State":
                        if (
                          $scope.User.office_name.officeName == "State Senator"
                        ) {
                          postobj.level = "stateSenateDistrict";
                        } else if (
                          $scope.User.office_name.officeName == "State Senate"
                        ) {
                          postobj.level = "stateSenateDistrict";
                        } else {
                          postobj.level = "statePostalCode";
                        }
                        break;
                      case "County":
                        postobj.level = "countyName";
                        break;
                      case "Local":
                        if (
                          $scope.runAddress.unifiedSchoolDistrictName &&
                          $scope.runAddress.unifiedSchoolDistrictName
                            .unifiedSchoolDistrict != ""
                        ) {
                          postobj.level = "unifiedSchoolDistrictName";
                        } else {
                          postobj.level = "placeName";
                        }
                        break;
                      case "President":
                        postobj.level = "All USA";
                        break;

                      default:
                        break;
                    }
                  }

                  // if ($scope.User.runElectionDate) {
                  //   postobj.run_electionDate = $scope.User.runElectionDate ? $scope.User.runElectionDate : "";
                  // }

                  if ($scope.User.runStatus) {
                    postobj.run_candidateStatus = $scope.User.runStatus
                      ? $scope.User.runStatus
                      : "";
                  }

                  if ($scope.User.runElecType) {
                    postobj.run_electionType = $scope.User.runElecType
                      ? $scope.User.runElecType
                      : "";
                  }
                }

                try {
                  if ($scope.User.state) {
                    var state = $scope.User.state;
                    postobj.state = state.state;
                    postobj.state_postal_code = state.statePostalCode;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.FederalCongressionalDistrict &&
                    $scope.User.NCandidates.FederalCongressionalDistrict != null
                  ) {
                    postobj.federal_congrassional_dist =
                      $scope.User.NCandidates.FederalCongressionalDistrict;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.JudicialCircuitNumber &&
                    $scope.User.NCandidates.JudicialCircuitNumber != null
                  ) {
                    postobj.judicial_circuit_no =
                      $scope.User.NCandidates.JudicialCircuitNumber;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.CountyName &&
                    $scope.User.NCandidates.CountyName != null
                  ) {
                    postobj.county = $scope.User.NCandidates.CountyName;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.CountySubdivisionName &&
                    $scope.User.NCandidates.CountySubdivisionName != null
                  ) {
                    postobj.subcounty =
                      $scope.User.NCandidates.CountySubdivisionName;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.PlaceName &&
                    $scope.User.NCandidates.PlaceName != null
                  ) {
                    postobj.place = $scope.User.NCandidates.PlaceName;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.StateBoardEducationDistrict &&
                    $scope.User.NCandidates.StateBoardEducationDistrict != null
                  ) {
                    postobj.state_board_education_dist =
                      $scope.User.NCandidates.StateBoardEducationDistrict;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.UnifiedSchoolDistrictName &&
                    $scope.User.NCandidates.UnifiedSchoolDistrictName != null
                  ) {
                    postobj.unified_school_dist =
                      $scope.User.NCandidates.UnifiedSchoolDistrictName;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.ElementarySchoolDistrictName &&
                    $scope.User.NCandidates.ElementarySchoolDistrictName != null
                  ) {
                    postobj.elementary_school_dist =
                      $scope.User.NCandidates.ElementarySchoolDistrictName;
                  }

                  if (
                    $scope.User.NCandidates &&
                    $scope.User.NCandidates.SecondarySchoolDistrictName &&
                    $scope.User.NCandidates.SecondarySchoolDistrictName != null
                  ) {
                    postobj.secondary_school_dist =
                      $scope.User.NCandidates.SecondarySchoolDistrictName;
                  }
                } catch (e) {
                  $scope.RegisterButton = false;
                  growl.error("Valid office details are required.");
                  return null;
                }
              }
              if ($scope.User.web_url) postobj.web_url = $scope.User.web_url;
              if ($scope.User.facebook_url)
                postobj.fabebook_url = $scope.User.facebook_url;
              break;
          }
          // MyService.ConsoleLog("postobj:::::", postobj);
          if ($scope.User.cur_office_name && $scope.User.office_name) {
            postobj.poliAddress = poliAddress;
            postobj.runningPoliInfo = runningPoliInfo;
          } else if ($scope.User.cur_office_name) {
            postobj.poliAddress = poliAddress;
          } else if ($scope.User.office_name) {
            postobj.runningPoliInfo = runningPoliInfo;
          }
          return postobj;
        } //validation
        else {
          return null;
        }
      };

      $scope.disableRegister = function (opt) {
        if (opt == "P") {
          $scope.RegisterButton = true;
        } else {
          $scope.RegisterButton = false;
        }
      };
      $scope.verificationCode = "";
      $scope.getVerificationCode = function () {
        // $scope.number_exist = false;

        var cell = $scope.User.cellnumber;
        var carrier = $scope.User.carrier;
        // MyService.ConsoleLog(cell);
        // MyService.ConsoleLog(carrier);
        // MyService.ConsoleLog(carrier.domain);

        if (!cell || cell == "") {
          alert("Please enter phone number");
          return;
          //return false;
        }

        if (!carrier || carrier == "") {
          alert("Please select your phone carrier");
          return;
          //return false;
        }

        if (cell && cell != "" && carrier && carrier != "") {
          MyService.ConsoleLog("here");
          if (carrier.domain) {
            $scope.verificationCell = cell;
            $scope.verificationCarrier = carrier.domain;
            MyService.sendVerificationCode({
              cellnumber: cell,
              carrier: carrier.domain,
            }).then(
              function (payload) {
                MyService.ConsoleLog(payload);
                if (payload.data.success) {
                  $scope.verificationCode = payload.data.data;
                } else {
                  $scope.verificationCode = "";
                  // $scope.number_exist = true;

                  $(document).ready(function () {
                    $scope.number_exist = true;
                    setTimeout(function () {
                      $(".alert").alert("close");
                    }, 5000);
                  });
                  // alert("Something went wrong please try agian");
                }
              },
              function (errPayload) {
                $scope.verificationCode = "";
                alert("Something went wrong please try agian");
              }
            );
          }
        }
      };

      $scope.verifySSN = function () {
        //MyService.ConsoleLog("SSN<><><>", $scope.User.ssn);
        var returnval = "";
        if ($scope.User.ssn == "") {
          returnval = "Please enter valid SSN";
        }
        if ($scope.User.name == "") {
          returnval = "Please enter valid name to verify with the SSN";
        }
        if (returnval == "") {
          var aurl =
            "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify";
          aurl += "?id=RyMI8jlzD6dHUApvp_3XRP**";
          aurl += "&ss=" + $scope.User.ssn;
          aurl += "&full=" + $scope.User.name;
          aurl += "&format=JSON";
          // var deferred = $q.defer();
          //var furl = "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify?id=RyMI8jlzD6dHUApvp_3XRP**&cols=GrpCensus,GrpCensus2,GrpGeocode&a1=" + records.address + "&city=" + records.city + "&state=" + records.state + "&postal=" + records.zipcode + "&ctry=US&format=JSON";
          //MyService.ConsoleLog("furl <><>>", furl);
          $http.get(aurl).then(
            function successCallback(response) {
              //$scope.result = "";
              //MyService.ConsoleLog("response<><", response);
              $scope.finddetailsclicked = false;
              if (response.statusText == "OK") {
              }
            },
            function errorCallBack(error) {
              $scope.finddetailsclicked = false;
              //MyService.ConsoleLog(error);
            }
          );
        } else {
          alert(returnval);
        }
      };

      $scope.verifiedUser = "true";
      $scope.verificationMethod = "Email";
      $scope.verificationCell = "";
      $scope.verificationCarrier = "";
      $scope.matchVerficationCode = function (val) {
        //MyService.ConsoleLog("value : ", val);
        //MyService.ConsoleLog($scope.User.verificationCode);
        //MyService.ConsoleLog(val.length);
        $scope.RegisterButton = true;
        $scope.verifiedUser = "true";
        if (val.length == 6 && $scope.verificationCode != "") {
          if ($scope.verificationCode == val) {
            $scope.RegisterButton = false;
            $scope.verifiedUser = "true";
            $scope.verificationMethod = "Cell Phone Number";
          }
        }
      };

      $scope.resetPassword = function () {
        //MyService.ConsoleLog($scope.User.Email);
        //MyService.ConsoleLog($scope.User.cur_office_email);
        var emails = [];
        if ($scope.User.Email && $scope.User.Email != "") {
          if (emails.indexOf($scope.User.Email) == -1) {
            emails.push($scope.User.Email);
          }
        }

        if (
          $scope.User.cur_office_email &&
          $scope.User.cur_office_email != ""
        ) {
          if (emails.indexOf($scope.User.cur_office_email) == -1) {
            emails.push($scope.User.cur_office_email);
          }
        }

        if (emails.length > 0) {
          //MyService.ConsoleLog("emails<><><>", emails);
          MyService.forgotPoliticianPassword({
            email: JSON.stringify(emails),
            reset_url: $rootScope.getPasswordResetUrl(),
          }).then(
            function (payload) {
              //MyService.ConsoleLog("Payload<><><><>", payload);
              if (payload.data.success) {
                alert(payload.data.data);
              } else {
                alert("Something went wrong please try again later");
              }
            },
            function (errorPayload) {
              //MyService.ConsoleLog("Payload<><><><>", errorPayload);
            }
          );
        }
      };

      $scope.CreateUser = function () {
        //MyService.ConsoleLog(" CreateUser : ", $scope.User);
        //MyService.ConsoleLog(" address : ", $scope.address);

        if ($scope.User.ZipCode != "") {
          $scope.showAddressDropDowns($scope.User.ZipCode);
        }

        var postobj = $scope.generatePostUserObj();
        console.log("welcome postobject", postobj);
        // return;
        // MyService.ConsoleLog("postobj: ", postobj);
        if (postobj) {
          // $scope.RegisterButton = true;
          //MyService.ConsoleLog("postData : ", postobj);
          MyService.signUp(postobj).then(
            function (response) {
              //MyService.ConsoleLog("response: ", response);
              $scope.RegisterButton = false;
              if (response.data.success == true) {
                var message = "";
                switch ($scope.UserType.toLowerCase()) {
                  case "politician":
                    message = "Politician added successfully.";
                    break;
                  case "advocate":
                    message = "Advocate added successfully";
                    break;
                }
                growl.info(message);
                $scope.User = {};
                $scope.addPoli.$setUntouched();
                $scope.addPoli.$setPristine();
                $scope.displayDrop = false;
                $scope.displayCounty = false;
                $scope.flagShowStateDropdown = false;
                $scope.shownew_office_name = false;
                $scope.federalCongrassionalDist = "";
                $scope.unifiedSchoolDistrictNames = "";
                $scope.stateHouseOFRepresentativeDistricts = "";
                $scope.stateSenateDistrict = "";
                $scope.allsubcounty = "";
                $scope.places = "";
                $scope.address = {};
                $scope.counties = "";
                // $scope.address.state = "";
                // $scope.address.county = "";
                // $scope.address.countySubdivision ="";
                // $scope.address.place ="";
                $scope.address.federalCongrassionalDist = "";
                $scope.address.stateSenateDistrict = "";
                $scope.address.stateHouseOFRepresentativeDistrict = "";
                $scope.address.unifiedSchoolDistrictName = "";
                // var tempParam = {
                //   username: $scope.User.UserName,
                //   password: $scope.User.Password
                // };
                // //MyService.ConsoleLog("tempParam>>",tempParam);
                // // $rootScope.AuthenticateUser(tempParam);
                // $timeout(function() {
                //   $rootScope.AuthenticateUser(tempParam, "signup");
                // }, 2000);
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
              } else {
                growl.error("Something went wrong.");
              }
            },
            function (response) {
              //MyService.ConsoleLog("Signup response: ", response);
              $scope.RegisterButton = false;
              var validataionMsg = "";
              if (
                response.data &&
                response.data.error &&
                response.data.error.errors
              ) {
                var errors = response.data.error.errors;
                for (var k in errors) {
                  if (errors.hasOwnProperty(k)) {
                    //MyService.ConsoleLog(k);
                    var obj = errors[k];
                    if (obj.message !== undefined)
                      validataionMsg +=
                        obj.message.replace("Path", "") + "<br/>";
                  }
                }
                growl.error(validataionMsg);
              }
            }
          );
        }
      };

      $scope.userTypeChanged = function (type) {
        //MyService.ConsoleLog("type : " + type);
        $scope.UserType = type;
        console.log("check type", $scope.UserType);

        $scope.User.organization_name = "";
        $scope.User.organization = null;
        //MyService.ConsoleLog("user type : ", $scope.UserType);
        $("form").validationEngine();
        //MyService.ConsoleLog("validationEngine applied");
      };

      /*
      $scope.suggest_organization = function(term) {
        var q = term.toLowerCase().trim();
        //MyService.ConsoleLog("term: ",term);
        var deferred = $q.defer();

        var postdata = {
          max_records: 5,
          keyword: term,
          type: $scope.UserType.toLowerCase()
        };

        var request = apiCall.apiCall('GET', '/office/list', postdata);
        $http(
          request
        ).then(function successCallback(response) {
          //MyService.ConsoleLog("succss: ",response);
          if(response.data.success){
            angular.forEach(response.data.data, function(val, key){
              response.data.data[key].value = val.title;
              response.data.data[key].label = val.title;
            });
            //MyService.ConsoleLog("got org dtaa: ", response.data.data);
            deferred.resolve(response.data.data);
            // growl.success("Tagged successfully");
          }
          else
          deferred.resolve([]);
          // else{
          //   if(typeof response.data.error == "string")
          //       growl.error(response.data.error);
          //   else
          //       growl.error("Something went wrong");
          // }
        }, function errorCallback(response) {
          // growl.error("Something went wrong");
          deferred.resolve([]);
        });

        return deferred.promise;
      }
      */

      function getOrganisationData(term) {
        var q = term.toLowerCase().trim();
        MyService.ConsoleLog("term: ", term);

        var deferred = $q.defer();

        var postdata = {
          max_records: 5,
          keyword: term,
          type: $scope.UserType.toLowerCase(),
        };

        var request = apiCall.apiCall("GET", "/office/list", postdata);

        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("succss: ", response);
            if (response.data.success) {
              var returnArr = [];
              angular.forEach(response.data.data, function (val, key) {
                MyService.ConsoleLog("value :", val.title);
                returnArr.push({
                  label: $sce.trustAsHtml(val.title),
                  value: val,
                });
              });
              //MyService.ConsoleLog(returnArr);
              deferred.resolve(returnArr);
              // growl.success("Tagged successfully");
            } else deferred.resolve([]);
            /*else{
              if(typeof response.data.error == "string")
              growl.error(response.data.error);
              else
              growl.error("Something went wrong");
            }*/
          },
          function errorCallback(response) {
            // growl.error("Something went wrong");
            deferred.resolve([]);
          }
        );

        return deferred.promise;
      }

      $scope.org_autocomplete_options = {
        suggest: getOrganisationData,
        on_select: function (selected) {
          MyService.ConsoleLog("selected: ", selected);
          // alert("org");
          // $scope.User.organization_name = selected.label;
          $scope.office_id = selected.value._id;
          $scope.User.organization_name = selected.value.title;
          $scope.User.outlet_name = selected.value.title;
          $scope.User.organization = selected;
          $scope.User.organization.web_url = selected.value.webUrl;
          if (selected.value.contactPerson.phoneNo) {
            $scope.User.organization.phone_no =
              selected.value.contactPerson.phoneNo;
          }

          $scope.User.name = selected.value.contactPerson.name;
          if (selected.value.contactPerson.position) {
            $scope.User.lastname = selected.value.contactPerson.position;
          }
          if (selected.value.contactPerson.position) {
            $scope.User.Email = selected.value.contactPerson.email;
          }
          $scope.User.ZipCode = selected.value.zipcode;

          if (selected.value.mailingAddress) {
            $scope.mailAdd = selected.value.mailingAddress;

            var apiKey = "AIzaSyD1oCVshO67SnASsVVvOoJiAQgl7PvOmxo";
            var addApi =
              "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              selected.value.mailingAddress +
              "&key=" +
              apiKey +
              "";
            $http.get(addApi).then(function (respo) {
              MyService.ConsoleLog(
                "api data :",
                respo.data.results[0].geometry.location
              );
              if (
                respo.data.results[0].geometry.location.lat &&
                respo.data.results[0].geometry.location.lng
              ) {
                $scope.User.Address = selected.value.mailingAddress;

                $scope.latt = respo.data.results[0].geometry.location.lat;
                $scope.longg = respo.data.results[0].geometry.location.lng;
              }
            });
          }

          if (selected.value.address) {
            $scope.acounty = selected.value.address.county;
            MyService.ConsoleLog("county :", $scope.acounty);
            $scope.acountry = selected.value.address.country;
            $scope.acountySubdivision =
              selected.value.address.countySubdivision;
            $scope.aplace = selected.value.address.place;
            $scope.astatePostalCode = selected.value.address.statePostalCode;
          }
        },
        on_detach: function (current_value) {
          MyService.ConsoleLog("on_detach: ", current_value);
          MyService.ConsoleLog("on", $scope.office_id);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
          }
          catch(e){
            $scope.User.organization = null;
          }*/
          $scope.User.organization_name = current_value;
        },
      };

      $scope.political_office_options = {
        suggest: function (term) {
          // return $rootScope.suggest_organization(term, $scope.UserType)
          return $rootScope.suggestPoliticianOffice(term, $scope.UserType);
        },
        on_select: function (selected) {
          //MyService.ConsoleLog("selected: ", selected);
          // $scope.User.organization_name = selected.label;
          $scope.User.office = selected;
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
          }
          catch(e){
            $scope.User.organization = null;
          }*/
          $scope.User.office_name = current_value;
        },
      };

      $scope.political_curroffice_options = {
        suggest: function (term) {
          return $rootScope.suggestPoliticianOffice(term, $scope.UserType);
        },
        on_select: function (selected) {
          MyService.ConsoleLog("selected: ", selected);
          $scope.User.curoffice = selected;
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
          }
          catch(e){
            $scope.User.organization = null;
          }*/
          $scope.User.cur_office_name = current_value;
        },
      };

      $scope.org_jurisdiction_options = {
        suggest: function (term) {
          // return $rootScope.suggest_organization(term, $scope.UserType)
          return $rootScope.suggestJurisdiction(term);
        },
        on_select: function (selected) {
          //MyService.ConsoleLog("selected: ", selected);
          $scope.User.jurisdiction = selected;
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
          }
          catch(e){
            $scope.User.organization = null;
          }*/
          $scope.User.jurisdiction_name = current_value;
        },
      };

      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.runAddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.getAllStates = function () {
        if ($scope.counties) $scope.counties.length = 0;
        if ($scope.places) $scope.places.length = 0;

        var request = apiCall.apiCall("GET", "/user/getAllStates", {
          country: "",
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              MyService.ConsoleLog("Got States: ", response.data.data);

              $scope.states = response.data.data;
              $scope.states.unshift({
                state: "All State",
                stateFips: "",
                statePostalCode: "All USA",
              });
              MyService.ConsoleLog(" $scope.states: ", $scope.states);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.getAllStates();

      var getCountiesCanceler = $q.defer();
      $scope.getCounties = function () {
        // alert("first");
        // MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
        // MyService.ConsoleLog("old",$scope.oldaddress);
        $scope.counties = "";
        var statePostalCode = "";

        if ($scope.User.cur_state != "") {
          statePostalCode = $scope.User.cur_state.stateFips;
        }

        // MyService.ConsoleLog("selected state", $scope.address.state);

        // MyService.ConsoleLog("lbl", lbl);

        MyService.ConsoleLog("statePostalCode", statePostalCode);

        var request = apiCall.apiCall("POST", "/user/getAllCounties", {
          statePostalCode: statePostalCode,
          timeout: getCountiesCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              MyService.ConsoleLog("response", response.data.data);
              $scope.counties = response.data.data;
              console.log("$scope.alllcounties>>", $scope.counties);
              // if (lbl == "new") {
              if ($scope.User.cur_level == "Local") {
                $scope.countyone = true;
                $scope.showLoader = false;
              }

              if ($scope.User.cur_level == "Other") {
                $scope.countyone = true;
                $scope.showLoader = false;
              }

              if ($scope.User.cur_level == "County") {
                $scope.countyone = true;
                $scope.showcur_office_nameDrop = true;
                $scope.showLoader = false;
              }

              // alert('counties');
              $scope.address.county = address.county;
              MyService.ConsoleLog(
                "$scope.address.county >",
                $scope.address.county
              );
              // }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      $scope.getRunningCounties = function () {
        // alert("first");
        // MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
        // MyService.ConsoleLog("old",$scope.oldaddress);

        var statePostalCode = "";

        if ($scope.User.state != "") {
          statePostalCode = $scope.User.state.stateFips;
        }

        // MyService.ConsoleLog("selected state", $scope.address.state);

        // MyService.ConsoleLog("lbl", lbl);

        MyService.ConsoleLog("statePostalCode", statePostalCode);

        var request = apiCall.apiCall("POST", "/user/getAllCounties", {
          statePostalCode: statePostalCode,
          timeout: getCountiesCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              MyService.ConsoleLog("response", response.data.data);
              $scope.runCounties = response.data.data;
              console.log("$scope.alllcounties>>", $scope.runCounties);
              // if (lbl == "new") {
              if ($scope.User.level == "Local") {
                $scope.running_countyone = true;
                $scope.running_showLoader = false;
              }

              if ($scope.User.level == "Other") {
                $scope.running_countyone = true;
                $scope.running_showLoader = false;
              }

              if ($scope.User.level == "County") {
                $scope.running_countyone = true;
                $scope.shownew_office_nameDrop = true;
                $scope.running_showLoader = false;
              }

              // alert('counties');

              // }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function (lbl) {
        //MyService.ConsoleLog($scope.oldaddress);
        var state = {},
          county = {};

        if (lbl == "cur") {
          state = $scope.User.cur_state;
          county = JSON.parse($scope.User.cur_county);
        } else if (lbl == "new") {
          state.statePostalCode = $scope.oldaddress.state;
          county.county = $scope.oldaddress.county;
        } else {
          state = JSON.parse($scope.User.sel_state);
          county = JSON.parse($scope.User.sel_county);
        }

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();

        var request = apiCall.apiCall("GET", "/user/getAllSubCountiesLocal", {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              if (response.data.data) {
                //MyService.ConsoleLog(response.data.data);
                $scope.places = response.data.data;

                if (lbl == "new") {
                  var subcounty = $scope.checksubCounty(
                    $scope.places,
                    $scope.oldaddress.countySubdivision
                  );

                  var places = $scope.checkPlaces(
                    $scope.places,
                    $scope.oldaddress.place
                  );
                  //MyService.ConsoleLog("subcounty<><><", subcounty[0])
                  $scope.address.countySubdivision = subcounty[0];
                  $scope.address.place = places[0];
                }
              }
            } else {
              growl("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl("Something went wrong.");
          }
        );
      };

      $scope.emptyOrNull = function (item) {
        return !(
          item.places === null ||
          item.places === "" ||
          item.places === " "
        );
      };

      $scope.$watch("User.isRunningForEle", function (newVal, oldVal) {
        //MyService.ConsoleLog("newVal: ", newVal, "oldVal: ", oldVal);
        if (newVal == 1) {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("cur_state set");
            $scope.User.sel_state = $scope.User.cur_state;
          } else {
            //MyService.ConsoleLog("cur_state not set");
          }

          if ($scope.User.cur_office_name) {
            $scope.User.office_name = $scope.User.cur_office_name;
          }
          if ($scope.User.cur_jurisdiction_name) {
            $scope.User.jurisdiction_name = $scope.User.cur_jurisdiction_name;
          }

          if ($scope.User.cur_county)
            $scope.User.sel_county = $scope.User.cur_county;
          if ($scope.User.cur_place)
            $scope.User.sel_place = $scope.User.cur_place;

          //MyService.ConsoleLog("sel_state: ", $scope.User.sel_state);
        }
      });

      function statusChangeCallback(response) {
        //MyService.ConsoleLog(response);
        if (response.status === "connected") {
          getUserInfo();
        } else if (response.status === "not_authorized") {
          growl.info("Please log " + "into this app.");
        } else {
          growl.error("Please log " + "into Facebook.");
        }
      }

      $scope.showZipLoader = false;
      $scope.showAddressDropDown = false;

      $scope.checkState = function (rdata, val) {
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val) return rdata;
        });
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog("County :", rdata);
        MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata._id.countySubdivision + "==" + val);
          if (rdata._id.countySubdivision == val) return rdata;
        });
      };
      $scope.checkPlaces = function (rdata, val) {
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata._id.place == val) return rdata;
        });
      };
      $scope.detectCode = function (event, zip) {
        //MyService.ConsoleLog(event);
        //MyService.ConsoleLog("keycode : ",event.key);
        if (event.keyCode == 9 && event.key == "Tab") {
          $scope.showAddressDropDowns(zip);
          $(this).blur();
        }
        if (event.keyCode == 13 && event.key == "Enter") {
          $scope.showAddressDropDowns(zip);
          $(this).blur();
        }
      };
      $scope.mainAddress = "";
      $scope.showAddressDropDowns = function (zipcode) {
        $scope.showZipLoader = true;
        var deferred = $q.defer();
        MyService.getOrganizedZipData({
          zipcode: zipcode,
        }).then(
          function (payload) {
            MyService.ConsoleLog(payload);
            if (payload.length > 0) {
              // var finalData = payload.data;
              //MyService.ConsoleLog(payload[0].countySubdivision);
              //MyService.ConsoleLog(payload[0].county);
              //MyService.ConsoleLog(payload[0].place);
              $scope.mainAddress = payload;
              $scope.showZipLoader = false;
              $scope.showAddressDropDown = false;
              $scope.oldaddress.state = payload[0].statePostalCode;
              $scope.oldaddress.county = payload[0].county;
              $scope.oldaddress.countySubdivision =
                payload[0].countySubdivision;
              $scope.oldaddress.place = payload[0].place;
              $scope.getCounties("new");

              $scope.getLocals("new");

              $scope.voterDistricts = payload;
              $scope.federalCongrassionalDist = payload;
              $scope.stateSenateDistrict = payload;
              $scope.stateHouseOFRepresentativeDistricts = payload;
              $scope.unifiedSchoolDistrictNames = payload;
              //$scope.$apply();
              var address = $scope.checkState(
                $scope.states,
                payload[0].statePostalCode
              );
              $scope.address.state = address[0];
              $scope.address.voterDistrict = $scope.voterDistricts[0];
              $scope.address.federalCongrassionalDist =
                $scope.federalCongrassionalDist[0];
              $scope.address.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              $scope.address.stateHouseOFRepresentativeDistrict =
                $scope.stateHouseOFRepresentativeDistricts[0];
              $scope.address.unifiedSchoolDistrictName =
                $scope.unifiedSchoolDistrictNames[0];
              deferred.resolve();
              //MyService.ConsoleLog("address ; ", address);
            }
          },
          function (errorPayload) {
            $scope.showZipLoader = false;
            growl.error(errorPayload);
            deferred.resolve();
          }
        );
        return deferred.promise;
      };

      // Mellisa data start //
      $scope.findaddress = {
        address: "",
        city: "",
        state: "",
        zipcode: "",
      };
      $scope.result = {
        city: "",
      };

      $scope.checkLength = function (vdata, val) {
        // MyService.ConsoleLog("vdata", vdata);
        if (vdata && vdata.length > 0) {
          var data = vdata.map(function (sub) {
            // MyService.ConsoleLog("SubDivision >>",sub);
            var returnval = "";
            switch (val) {
              case "federalCongrassionalDist":
                returnval = sub.congressionalDistrictFips;
                break;
              case "stateSenateDistrict":
                returnval = sub.stateSenateDistrictFips;
                break;
              case "stateHouseOFRepresentativeDistrict":
                returnval = sub.stateHouseofRepresentativesDistrict;
                break;
              case "unifiedSchoolDistrictName":
                returnval = sub.unifiedSchoolDistrict;
                break;
              case "place":
                returnval = sub.place;
                break;
              case "countySubdivision":
                returnval = sub.countySubdivision;
                break;
              case "county":
                returnval = sub.county;
                break;
              case "state":
                returnval = sub.state;
                break;
              case "default":
                returnval = sub.NAME;
                break;
            }
            return returnval;
          });

          var ndata = $filter("unique")(data);
          if (ndata.length > 1) {
            return "yellowBackground";
          } else {
            return "";
          }
        } else {
          return "";
        }
      };

      $scope.showFindAddress = function () {
        var q = {
          zipcode: $scope.User.ZipCode,
        };
        var addressModal = ngDialog.open({
          template: "app/popups/mellisa_data.html",
          scope: $scope,
          data: q,
        });
        $scope.findaddress.zipcode = $scope.User.ZipCode;
      };

      $scope.finddetailsclicked = false;
      $scope.getDetails = function (records) {
        var error = "";
        if ($scope.findaddress.address == "") {
          error = "Please enter address";
        }
        if (error == "" && $scope.findaddress.city == "") {
          error = "Please enter city";
        }
        if (error == "" && $scope.findaddress.state == "") {
          error = "Please enter state";
        }
        if (error == "" && $scope.findaddress.zipcode == "") {
          error = "Please enter zipcode";
        }

        if (error == "") {
          $scope.finddetailsclicked = true;
          //MyService.ConsoleLog("=============Address==============");
          //MyService.ConsoleLog($scope.findaddress);
          //MyService.ConsoleLog("findaddress<><><>", records);
          var aurl =
            "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify";
          aurl += "?id=RyMI8jlzD6dHUApvp_3XRP**";
          aurl += "&cols=GrpCensus,GrpCensus2,GrpGeocode";
          aurl += "&a1=" + records.address;
          aurl += "&city=" + records.city;
          aurl += "&state=" + records.state;
          aurl += "&postal=" + records.zipcode;
          aurl += "&ctry=US";
          aurl += "&format=JSON";
          // var deferred = $q.defer();
          //var furl = "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify?id=RyMI8jlzD6dHUApvp_3XRP**&cols=GrpCensus,GrpCensus2,GrpGeocode&a1=" + records.address + "&city=" + records.city + "&state=" + records.state + "&postal=" + records.zipcode + "&ctry=US&format=JSON";
          //MyService.ConsoleLog("furl <><>>", furl);
          $http.get(aurl).then(
            function successCallback(response) {
              //$scope.result = "";
              $scope.finddetailsclicked = false;
              if (response.statusText == "OK") {
                var rdata = response.data.Records[0];
                if (rdata) {
                  $scope.result = rdata;
                }
              }
              //MyService.ConsoleLog("response", response);
            },
            function errorCallBack(error) {
              $scope.finddetailsclicked = false;
              //MyService.ConsoleLog(error);
            }
          );
        } else {
          alert(error);
        }
      };
      // $scope.oldAddress = "";
      $scope.checkZipAddress = function (val) {
        // $scope.oldAddress = angular.copy($scope.mainAddress);
        return $scope.mainAddress.filter(function (rdata) {
          //MyService.ConsoleLog("Space:", parseInt(val.CongressionalDistrict));
          if (
            val.CongressionalDistrict == "" ||
            val.CongressionalDistrict == " "
          ) {
            //MyService.ConsoleLog("inside<><><><><>");

            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) ==
                parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) ==
                parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) ==
                parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              //MyService.ConsoleLog("This is a match");
              return rdata;
            }
          } else {
            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) ==
                parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.federalCongrassionalDist) ==
                parseInt(val.CongressionalDistrict) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
                parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) ==
                parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) ==
                parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              //MyService.ConsoleLog("This is a match2");
              return rdata;
            }
          }
        });
      };

      $scope.setAddressNew = function () {
        //MyService.ConsoleLog($scope.result);
        var finalResult = $scope.checkZipAddress($scope.result);
        if (finalResult && finalResult.length > 0) {
          //MyService.ConsoleLog("finalResult::::", finalResult);
          // $scope.mainAddress = angular.copy(finalResult);
          $scope.voterDistricts = finalResult;
          $scope.federalCongrassionalDist = finalResult;
          $scope.stateSenateDistrict = finalResult;
          $scope.stateHouseOFRepresentativeDistricts = finalResult;
          $scope.unifiedSchoolDistrictNames = finalResult;
          ngDialog.closeAll();
          // $scope.$apply();
        } else {
          alert(
            "Address not matching with our database. Please enter detail address and try again."
          );
        }
      };

      var previousSelVal = {};
      $scope.setSelectedDropdown = function (
        listData,
        setfor,
        selectedUserAdrs
      ) {
        switch (setfor) {
          case "counties":
            previousSelVal.county = $scope.address.county;
            //MyService.ConsoleLog("selCountyNumber: ",previousSelVal.county);
            $scope.address.county = null;
            if (listData) {
              $scope.counties = listData;
              $scope.counties.unshift({
                number: null,
                name: "none",
              });

              // if(!selectedUserAdrs) selectedUserAdrs.countyFips = previousSelVal.county.number;
              var selCountyNumber = null;
              try {
                $scope.address.county = $scope.counties[0];
                if (selectedUserAdrs) {
                  selCountyNumber = selectedUserAdrs.countyFips;
                }
                if (previousSelVal.county) {
                  selCountyNumber = previousSelVal.county.number;
                }

                if (selCountyNumber) {
                  //MyService.ConsoleLog("selCountyNumber: ",selCountyNumber);
                  angular.forEach($scope.counties, function (val, key) {
                    if (selCountyNumber == val.number)
                      $scope.address.county = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "subCounties":
            previousSelVal.subcounty = $scope.address.subcounty;
            $scope.address.subcounty = null;
            if (listData) {
              $scope.subCounties = listData;
              $scope.subCounties.unshift({
                number: null,
                name: "none",
              });
              var selSubCountyNo = null;
              try {
                $scope.address.subcounty = $scope.subCounties[0];
                if (selectedUserAdrs) {
                  selSubCountyNo = selectedUserAdrs.countySubdivisionFips;
                }
                if (previousSelVal.subcounty) {
                  selSubCountyNo = previousSelVal.subcounty.number;
                }
                if (selSubCountyNo) {
                  angular.forEach($scope.subCounties, function (val, key) {
                    //set selected state
                    // if(selectedUserAdrs.countySubdivisionFips == val.number)
                    if (selSubCountyNo == val.number)
                      $scope.address.subcounty = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "places":
            previousSelVal.place = $scope.address.place;
            $scope.address.place = null;
            if (listData) {
              $scope.places = listData;
              $scope.places.unshift({
                number: null,
                name: "none",
              });

              try {
                $scope.address.place = $scope.places[0];
                var selPlaceNo = null;
                if (selectedUserAdrs) {
                  selPlaceNo = selectedUserAdrs.placeFips;
                }
                if (previousSelVal.place) {
                  selPlaceNo = previousSelVal.place.number;
                }
                if (selPlaceNo) {
                  angular.forEach($scope.places, function (val, key) {
                    if (selPlaceNo == val.number) $scope.address.place = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "voterDistricts":
            if (listData) {
              previousSelVal.voterDistrict = $scope.address.voterDistrict;
              $scope.address.voterDistrict = null;
              $scope.voterDistricts = listData;
              $scope.voterDistricts.unshift({
                number: null,
                name: "none",
              });

              var voterDistrictNo = null;
              try {
                $scope.address.voterDistrict = $scope.voterDistricts[0];
                if (selectedUserAdrs) {
                  voterDistrictNo = selectedUserAdrs.voterDistrictFips;
                }
                if (previousSelVal.voterDistrict) {
                  voterDistrictNo = previousSelVal.voterDistrict.number;
                }

                if (voterDistrictNo) {
                  angular.forEach($scope.voterDistricts, function (val, key) {
                    //set selected
                    if (voterDistrictNo == val.number)
                      $scope.address.voterDistrict = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case "federalCongrassionalDist":
            $scope.address.federalCongrassionalDist = null;
            if (listData) {
              $scope.federalCongrassionalDist = listData;
              $scope.federalCongrassionalDist.unshift("none");

              if (selectedUserAdrs) {
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                angular.forEach(
                  $scope.federalCongrassionalDist,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.federalCongrassionalDist == val)
                      $scope.address.federalCongrassionalDist = val;
                  }
                );
              }
            }
            break;
          case "stateSenateDistrict":
            $scope.address.stateSenateDistrict = null;
            if (listData) {
              $scope.stateSenateDistrict = listData;
              $scope.stateSenateDistrict.unshift("none");

              $scope.address.stateSenateDistrict =
                $scope.stateSenateDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateSenateDistrict,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.stateSenateDistrict == val)
                      $scope.address.stateSenateDistrict = val;
                  }
                );
              }
            }
            break;
          case "stateHouseOFRepresentativeDistrict":
            $scope.address.stateLegislativeDistrict = null;
            if (listData) {
              $scope.stateLegislativeDistrict = listData;
              $scope.stateLegislativeDistrict.unshift("none");
              $scope.address.stateLegislativeDistrict =
                $scope.stateLegislativeDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach(
                  $scope.stateLegislativeDistrict,
                  function (val, key) {
                    //set selected state
                    if (selectedUserAdrs.legislativeDistrict == val)
                      $scope.address.stateLegislativeDistrict = val;
                  }
                );
              }
            }
            break;
          case "schoolDistricts":
            $scope.address.schoolDistrict = null;
            if (listData) {
              $scope.schoolDistricts = listData;
              $scope.schoolDistricts.unshift({
                number: null,
                name: "none",
              });
              $scope.address.schoolDistricts = $scope.schoolDistricts[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.schoolDistricts, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.schoolDistrictFips == val.number)
                    $scope.address.schoolDistrict = val;
                });
              }
            }
            break;
        }
      };

      if ($scope.UserType == "advocate" || $scope.UserType == "politician") {
        $(document).ready(function () {
          $("#addPoli").validationEngine();
        });
      }
    },
  ]);
