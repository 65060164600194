"use strict";
angular
  .module("myApp.snapshot", ["ngRoute", "angularPayments"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/snapshot", {
        templateUrl: "app/snapshot/snapshot.html",
        controller: "snapshotCtrl",
      });
    },
  ])
  .controller("snapshotCtrl", [
    "$scope",
    "$rootScope",
    "growl",
    "ngDialog",
    "tokenService",
    "MyService",
    "$location",
    "$window",
    "$filter",
    function (
      $scope,
      $rootScope,
      growl,
      ngDialog,
      tokenService,
      MyService,
      $location,
      $window,
      $filter
    ) {
      //MyService.ConsoleLog("** dashboardCtrl **");
      $scope.loadingExtra = false;
      $rootScope.welcome = false;
      $scope.alert_success = false;
      $scope.error_alert = false;
      $scope.alert_token = false;

      window.localStorage.removeItem("fiveMatch");
      window.localStorage.removeItem("queIdsN");

      document.title = "Votewise";

      $scope.Location = {
        county: "",
        place: "",
        state: "",
      };

      $scope.User = {
        Address: "",
      };

      $scope.showCon = false;
      $scope.showBoth = false;
      $scope.showEm = false;

      /**
       * @description Show pop up If the showSavePopUp is true
       */
      if (window.localStorage.getItem("showSavePopUp")) {
        var message =
          "Your quiz has been saved to this page.  Anytime you want to come back to this page, just click your name in the top banner.";
        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            message +
            '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                            \
                        </div>',
          plain: true,
        });

        modal.then(
          function fullfilled(data) {
            MyService.ConsoleLog("fullfilled: ", data);
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          }
        );
        window.localStorage.removeItem("showSavePopUp");
      }
      MyService.ConsoleLog(
        " check the flag value > ",
        window.localStorage.getItem("isPostCard")
      );
      MyService.ConsoleLog(
        " check the flag value > ",
        typeof window.localStorage.getItem("isPostCard")
      );

      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // //console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // //console.log($scope.lati);
            // //console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            console.log(
              "  place.address_components :",
              place.address_components
            );
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // //console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // //console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // //console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // //console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  // document.getElementById('zip').value = place.address_components[i].long_name;
                  $scope.ZipCode = place.address_components[i].long_name;
                  // //console.log($scope.User.ZipCode);
                }
              }
            }

            //MyService.ConsoleLog(" $scope.User.ZipCode :", $scope.ZipCode);

            $scope.loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // //console.log(" long and lat :",$scope.loc );
            // $rootScope.location = new Array;

            $scope.showAddressDropDowns("", $scope.loc);
          }
        );
      };

      $scope.checkLen = function (ldata, sval) {
        // //MyService.ConsoleLog("vdata : ", vdata);
        //MyService.ConsoleLog("vdata", ldata);
        if (ldata && ldata.length > 0) {
          var data = ldata.map(function (sub) {
            var returnval = "";
            switch (sval) {
              case "federalCongrassionalDist":
                returnval = sub.CD115FP;
                break;
              case "stateSenateDistrict":
                returnval = sub.SLDUST;
                break;
              case "stateHouseOFRepresentativeDistrict":
                returnval = sub.NAMELSAD;
                break;
              case "unifiedSchoolDistrictName":
                returnval = sub.NAME;
                break;
              case "place":
                returnval = sub.place;
                break;
              case "countySubdivision":
                returnval = sub.countySubdivision;
                break;
              case "county":
                returnval = sub.county;
                break;
              case "state":
                returnval = sub.state;
                break;
              case "default":
                returnval = sub.NAME;
                break;
            }
            return returnval;
          });

          var ndata = $filter("unique")(data);
          if (ndata.length > 1) {
            return "yellowBackground";
          } else {
            return "";
          }
        } else {
          return "";
        }
      };

      $scope.allcounties = [];

      $scope.getCounties = function (lbl) {
        MyService.ConsoleLog("county >", $scope.oldaddress);
        // $scope.allcounties.length = 0;
        var statePostalCode = "";
        var state = "";
        // if (lbl == "new") {
        //   state = JSON.stringify($scope.oldaddress.state);
        // } else {
        statePostalCode = $scope.oldaddress.state[0].STATEFP;
        // state = $scope.Location.state;
        // }
        // //MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
        MyService.getAllCounties(statePostalCode, state).then(
          function (payload) {
            // //MyService.ConsoleLog("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == "new") {
              var county = $scope.checkCounty(
                allcounties,
                $scope.oldaddress.county
              );
              $scope.allcounties = county;
              MyService.ConsoleLog("county<><", county[0]);
              MyService.ConsoleLog("$scope.Location", $scope.Location);
              $scope.Location.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        // //MyService.ConsoleLog(" $scope.Location.state :",$scope.Location.state);
        // //MyService.ConsoleLog("$scope.Location.county :",$scope.Location.county);
        // var county = $scope.Location.county;

        $scope.MyPlaces.length = 0;
        var county = "";
        var statePostalCode = "";
        $scope.MyPlaces.length = 0;
        var state = "";
        var county = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }
        // //MyService.ConsoleLog(statePostalCode + "===" + county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            // payload = JSON.parse(payload);
            $scope.MyPlaces = payload;
            // //MyService.ConsoleLog("PlaCE :", payload);
            // //MyService.ConsoleLog(payload.countySubdivision);
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // //MyService.ConsoleLog("PlaCE :", payload);
            if (lbl == "new") {
              // //MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // //MyService.ConsoleLog("subcounty<><><", subcounty);
              $scope.subcounties = subcounty;
              $scope.places = places;
              // //console.log('***********', places[0]);
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.Location.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.checkState = function (rdata, val) {
        //MyService.ConsoleLog("State :", rdata);
        //MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        //MyService.ConsoleLog("County :", rdata);
        //MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // //MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // //MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.showAddressDrop = false;
      $scope.showAddressDropDowns = function (zipcode, loc) {
        $scope.showZipLoader = true;
        // //console.log(zipcode);

        if (loc && loc != "") {
          MyService.getAllStates().then(
            function (payload) {
              $scope.allStateRes = payload;
              $scope.states = payload;
              // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );

          MyService.getOrganizedZipData({
            candidate: true,
            loc: loc,
          }).then(
            function (respo) {
              //MyService.ConsoleLog("mylog", respo);
              if (respo && respo.state) {
                // var finalData = payload.data;
                // //MyService.ConsoleLog(payload[0]);
                // //MyService.ConsoleLog(payload[0].countySubdivision);
                // //MyService.ConsoleLog(payload[0].county);
                // //MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = respo.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // //MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = respo.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // //MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });
                // alert('dd');
                // MyService.ConsoleLog("here", respo);
                $scope.showZipLoader = false;
                $scope.showAddressDrop = true;
                // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);
                $scope.address.county = respo.county;
                $scope.address.place = respo.places;
                // MyService.ConsoleLog(" $scope.address", $scope.address);
                $scope.oldaddress.state = respo.state;
                $scope.oldaddress.county = respo.county;
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // //MyService.ConsoleLog("places >>> ",places);
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // //MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = respo.congressionalDistrict;
                //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = respo.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  respo.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
                //$scope.$apply();
                //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
                // //MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState(
                  $scope.allStateRes,
                  respo.state
                );
                $scope.allstatesData = address;
                // //MyService.ConsoleLog("address<>", address);
                // $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                $scope.address.state = address[0];
                // $scope.address.voterDistrict = $scope.voterDistricts[0];
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.address.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.address.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.address.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                $scope.Location.state = address[0];
                MyService.ConsoleLog("address<>", address);
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                // //MyService.ConsoleLog("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        } else {
          MyService.getAllStates().then(
            function (payload) {
              $scope.allStateRes = payload;
              $scope.states = payload;
              // //MyService.ConsoleLog(" $scope.allStateRes :", $scope.allStateRes);
            },
            function (errorPayload) {
              growl.error("Something went wrong.");
            }
          );

          MyService.getOrganizedZipData({
            zipcode: zipcode,
            candidate: true,
          }).then(
            function (respo) {
              //MyService.ConsoleLog("mylog", respo);
              if (respo && respo.state) {
                // var finalData = payload.data;
                // //MyService.ConsoleLog(payload[0]);
                // //MyService.ConsoleLog(payload[0].countySubdivision);
                // //MyService.ConsoleLog(payload[0].county);
                // //MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = respo.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // //MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = respo.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // //MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });

                // //MyService.ConsoleLog("here");
                $scope.showZipLoader = false;
                $scope.showAddressDrop = true;
                // //MyService.ConsoleLog("countySubdivisions >> ", respo.state[0].NAME);

                $scope.oldaddress.state = respo.state;
                $scope.oldaddress.county = respo.county;
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // //MyService.ConsoleLog("places >>> ",places);
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // //MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = respo.congressionalDistrict;
                //MyService.ConsoleLog("@@@@@@ drop :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = respo.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  respo.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames = respo.unifiedSchoolDistrict;
                //$scope.$apply();
                //MyService.ConsoleLog("$scope.allStates :", $scope.allStates);
                // //MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState(
                  $scope.allStateRes,
                  respo.state
                );
                $scope.allstatesData = address;
                $scope.Location.state = address[0];
                MyService.ConsoleLog("address<>", address);
                // $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                //MyService.ConsoleLog("$scope.Location.federalCongrassionalDist :", $scope.federalCongrassionalDist[0]);
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                // //MyService.ConsoleLog("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }
      };

      $scope.setAddressNew = function (address) {
        MyService.ConsoleLog("setAddressNew > ", address);

        // if ($scope.statusGetStates == 2 && $scope.statusGetZipdata == 2) {

        var postobj = {
          userId: tokenService.getUserId(),
          zipcode: "",
          mailingAddress: $scope.mailing_Address,
        };

        var add = {};

        if (address.state) {
          add.state = address.state.state;
          add.statePostalCode = address.state.statePostalCode;
          add.stateFips = address.state.stateFips;
          //MyService.ConsoleLog("address.statePostalCode :", address.state);
        }

        if (address.place) {
          add.placeFips = address.place.placeFips;
          add.place = address.place.placeName;
        }

        if (address.county) {
          add.countyFips = address.county.countyFips;
          add.county = address.county.countyName;
        }

        if (address.subcounty) {
          add.countySubdivisionFips = address.subcounty.COUSUBFP;
          add.countySubdivision = address.subcounty.NAME;
        }

        if (address.federalCongrassionalDist) {
          add.federalCongrassionalDist =
            address.federalCongrassionalDist.CD115FP;
        }

        if (address.stateSenateDistrict) {
          add.stateSenateDistrict = address.stateSenateDistrict.SLDUST;
        }

        if (address.unifiedSchoolDistrictName) {
          add.schoolDistrictFips = address.unifiedSchoolDistrictName.UNSDLEA;
          add.schoolDistrict = address.unifiedSchoolDistrictName.NAME;
        }

        // if ($scope.address.voterDistrict) {
        //   address.voterDistrictFips = $scope.address.voterDistrict.number;
        //   address.voterDistrict = $scope.address.voterDistrict.name;
        // }

        if (address.stateHouseOFRepresentativeDistrict) {
          var str = address.stateHouseOFRepresentativeDistrict.NAMELSAD;
          //MyService.ConsoleLog("address.stateLegislativeDistrict.NAMELSAD >>>>", str);

          var newString = str.substr(0, str.length - 3);
          //MyService.ConsoleLog("newString >>>>", newString);

          switch (newString) {
            case "General Assembly District":
              add.legislativeDistrict =
                address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
                  "General Assembly District ",
                  ""
                );
              break;
            case "Assembly District":
              add.legislativeDistrict =
                address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
                  "Assembly District ",
                  ""
                );
              break;
            case "State House District":
              add.legislativeDistrict =
                address.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
                  "State House District ",
                  ""
                );
              break;

            default:
              break;
          }
        }

        var finalLoc = JSON.parse($scope.loc);
        //MyService.ConsoleLog(" $scope.address: ", finalLoc);

        add.loca = {
          type: "Point",
          coordinates: [finalLoc.longitude, finalLoc.latitude],
        };

        // alert("got");

        //MyService.ConsoleLog(">>>>>>>>>> add >>>>>>>>>>>: ", address);
        postobj.mailingAddress = $scope.mailing_Address
          ? $scope.mailing_Address
          : "";

        postobj.address = JSON.stringify(add);
        //MyService.ConsoleLog("postobj.address: ", postobj);

        MyService.updateAddress(postobj).then(
          function (data) {
            growl.success("Updated successfully.");
            // $scope.setAddressNew();
            MyService.ConsoleLog("data", data);
            $scope.addressPopup.close();
            // $scope.closeThisDialog();
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );

        // }
      };

      /**
       * Redirect
       */
      $scope.goto = function () {
        $location.path("/verifyBy");
      };

      /**
       * Close it
       */
      $scope.closeIt = function () {
        $scope.PopDialog.close();
      };

      /**
       * Send Verification mail
       */

      $scope.sendMail = function () {
        MyService.SendVerificationMail({
          verificationUrl: $rootScope.getVerificationUrl(),
          unsubscribeurl: $rootScope.getEmailUnsubscribeUrl(),
        }).then(
          function (response) {
            MyService.ConsoleLog("response", response);
            if (response.data.success) {
              growl.success("Please check your email");
            }
          },
          function (err) {
            MyService.ConsoleLog("err", err);
          }
        );
      };

      $scope.favChannel = "associated-press";
      if (tokenService.getUserData()) {
        $scope.userData = JSON.parse(tokenService.getUserData());
        MyService.ConsoleLog(" $scope.userData", $scope.userData);

        if (
          window.localStorage.getItem("isPostCard") &&
          window.localStorage.getItem("isPostCard") != "false" &&
          !$scope.userData.email
        ) {
          var message =
            "Your Access is limited. Kindly add your email address to access exciting features";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">Add Email</button>\
                            \
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              $location.path("/userProfile");
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
          window.localStorage.removeItem("isPostCard");
        }

        if (
          $scope.userData.my_fav_channel &&
          $scope.userData.my_fav_channel != ""
        ) {
          $scope.favChannel = $scope.userData.my_fav_channel;
        }
      }

      /**
       * @description This function get the remainig quiz details
       */
      $scope.getRemainingQuizData = function () {
        MyService.getRemainingQuizData({
          userId: tokenService.getUserId(),
        }).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse", APIResponse.remainingQuizLinks);
            $scope.inCompleteQuiz = APIResponse.remainingQuizLinks;
            $scope.completedQuiz = APIResponse.completeQuizLinks;
          },
          function (err) {
            MyService.ConsoleLog("check for error", err);
          }
        );
      };

      $scope.feedtitle = $scope.favChannel
        .replace(new RegExp("-", "g"), " ")
        .toUpperCase();
      $scope.getExtraData = function () {
        $scope.loadingExtra = true;
        MyService.getExtraData($scope.userData).then(
          function (payload) {
            var responseLatest = payload;
            //MyService.ConsoleLog("subcatdata : ", subcatdata);
            $scope.loadingExtra = false;
            $rootScope.ExtraTopicData = responseLatest;
            if (responseLatest) {
              if (responseLatest.MyDiscussion) {
                $scope.DiscussionData = responseLatest.MyDiscussion;
              }
              if (responseLatest.ActiveData) {
                $scope.ActiveThreads = responseLatest.ActiveData;
              }
              if (responseLatest.HotTopics) {
                $scope.HotTopics = responseLatest.HotTopics;
              }
            }
          },
          function (errorPayload) {
            $scope.loadingExtra = false;
            MyService.ConsoleLog("errorPayload", errorPayload);
            alert("Something went wrong");
          }
        );
        $scope.getCurrentUserAns(tokenService.getUserId());
      };

      $scope.getCurrentUserAns = function (userId) {
        MyService.getFiveMinAnswers({
          authorId: userId,
        }).then(
          function (payload) {
            MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload);
            if (payload.data.success) {
              $scope.AllAnsweredQuestions = payload.data.data;
              var queIdsN = $scope.AllAnsweredQuestions.filter(function (item) {
                if (item && item.categories && item.categories.length > 0) {
                  return item.questionId;
                }
              });

              var tempQuesNw = queIdsN.map(function (item) {
                return item.questionId;
              });
              MyService.ConsoleLog("tempQuesNw >> ", tempQuesNw);

              window.localStorage.setItem(
                "queIdsN",
                JSON.stringify(tempQuesNw)
              );
              window.localStorage.setItem(
                "fiveMatch",
                JSON.stringify($scope.AllAnsweredQuestions)
              );
              // $scope.getFiveMinQues();
            }
          },
          function (errpayload) {
            MyService.ConsoleLog(
              "getAllAnsweredQuestions errpayload >> ",
              errpayload
            );
          }
        );
        $scope.getRemainingQuizData();
      };

      $scope.gotoThread = function (data, subcatid) {
        var id = "";
        if (data.parentId) {
          id = data.parentId;
        } else if (data.parent) {
          id = data.parent;
        } else {
          if (data.id) {
            id = data.id;
          } else {
            id = data._id;
          }
        }
        // MyService.ConsoleLog(data);
        var categoryId = "";
        if (data.parentCat[0] && data.parentCat[0] != "") {
          categoryId = data.parentCat[0];
        } else {
          if (data.parentCat[1] && data.parentCat[1] != "") {
            categoryId = data.parentCat[1];
          }
        }

        // $rootScope.subcategoryId = subcatid;
        //MyService.ConsoleLog('forumthread/' + categoryId + '/' + subcatid + '/' + id);

        if (categoryId != "") {
          $location.path(
            "forumthread/" + categoryId + "/" + subcatid + "/" + id
          );
        } else {
          MyService.ConsoleLog("ELSE");
          alert("Something went wrong");
        }
      };

      $scope.gotoNewsThread = function (data, subcatid) {
        var id = data._id;
        //MyService.ConsoleLog(data);
        var categoryId = "";
        if (data.parentCat[0] && data.parentCat[0] != "") {
          categoryId = data.parentCat[0];
        } else {
          if (data.parentCat[1] && data.parentCat[1] != "") {
            categoryId = data.parentCat[1];
          }
        }

        //$rootScope.subcategoryId = subcatid;
        //MyService.ConsoleLog('pressEssayDetails/' + categoryId + '/' + id);
        if (categoryId != "") {
          $location.path("pressEssayDetails/" + categoryId + "/" + id);
        } else {
          MyService.ConsoleLog("vishal");
          alert("Something went wrong");
        }
      };

      $scope.loadingFollow = false;
      $scope.followlist = [];
      $scope.getFollowUsers = function () {
        $scope.loadingFollow = true;
        $scope.followlist.length = 0;
        MyService.ConsoleLog("followlist", $scope.userData.followlist);
        if (
          $scope.userData.followlist &&
          $scope.userData.followlist.length > 0
        ) {
          var userIds = JSON.stringify($scope.userData.followlist);
          MyService.getUsersFromIds({
            userIds: userIds,
          }).then(
            function (payload) {
              MyService.ConsoleLog("payload followers : ", payload.data.data);

              var responseLatest = payload;
              $scope.loadingFollow = false;
              // MyService.ConsoleLog("payload : ", payload.data.data);
              $scope.followlistData = payload.data.data;
            },
            function (errorPayload) {
              $scope.loadingFollow = false;
              MyService.ConsoleLog("errorPayload 3", errorPayload);
              /*   growl.error("There are no records for following users"); */
            }
          );
        } else {
          //alert("You ")
          $scope.loadingFollow = false;
        }
      };
      $scope.ShowPorifleLoading = false;
      $scope.getmyData = function () {
        $scope.ShowPorifleLoading = true;
        MyService.getUserPublicProfile({
          id: $rootScope.UserId,
          type: "false",
        }).then(
          function (response) {
            $scope.ShowPorifleLoading = false;
            if (response.data.success) {
              $scope.userDetails = response.data.data;
              MyService.ConsoleLog("welcome", $scope.userDetails);
              $scope.userProfile = $scope.userDetails.userData;
              // MyService.ConsoleLog("welcome to profile", $scope.userProfile);

              $scope.userType = $scope.userProfile.userType;
              $scope.verify = $scope.userProfile.verifiedUser;
              // MyService.ConsoleLog("welcome", $scope.verify);
              // console.log("$scope.userDetails", $scope.userDetails.userData.address);
              if (!$scope.userProfile.verifiedUser) {
                $scope.showCon = true;
              }
              // if ($scope.userProfile.userType == 'politician') {
              if (
                !$scope.userProfile.isEmailVerified ||
                !$scope.userProfile.verifiedUser
              ) {
                // $scope.getPopUp();
                if (
                  !$scope.userProfile.isEmailVerified &&
                  !$scope.userProfile.verifiedUser
                ) {
                  if ($scope.userProfile.userType == "politician") {
                    $scope.showCon = true;
                    $scope.showBoth = true;
                  } else if (!$scope.userProfile.isEmailVerified) {
                    $scope.showEm = true;
                  }
                } else if (!$scope.userProfile.isEmailVerified) {
                  // if ($scope.userProfile.userType != 'politician') {
                  $scope.showEm = true;
                  // }
                } else {
                  if ($scope.userProfile.userType != "politician") {
                    $scope.showEm = true;
                  }
                }
                if ($scope.userProfile.userType == "politician") {
                  $scope.PopDialog = ngDialog.open({
                    template: "popUp",
                    closeByNavigation: true,
                    scope: $scope,
                    width: "50%",
                    closeByDocument: false,
                  });
                } else if (
                  $scope.userProfile.userType == "advocate" &&
                  $scope.userProfile.isEmailVerified != true
                ) {
                  // alert('cal');
                  MyService.ConsoleLog(
                    "$scope.userProfile",
                    $scope.userProfile.isEmailVerified
                  );
                  $scope.PopDialog = ngDialog.open({
                    template: "popUp",
                    closeByNavigation: true,
                    scope: $scope,
                    width: "50%",
                    closeByDocument: false,
                  });
                }
              }
              // }
              /** Commented on the 28 May 2021 */
              // if (!$scope.userDetails.userData.address.statePostalCode) {
              //   $scope.addressPopup = ngDialog.open({
              //     template: "addressPopUp",
              //     closeByNavigation: true,
              //     scope: $scope,
              //   });
              // }
            } else {
              MyService.ConsoleLog("ELSE vishal");
              alert("Something went wrong.");
            }
          },
          function (errorPayload) {
            $scope.ShowPorifleLoading = false;
            MyService.ConsoleLog("ELSE vishal two");

            alert("Something went wrong.");
          }
        );
      };

      $scope.ShowLoader = false;
      $scope.LatestEssay = [];
      $scope.LatestPost = [];
      $scope.LatestThread = [];
      $scope.gotoPage = function (obj, data) {
        //MyService.ConsoleLog(data);
        switch (obj) {
          case "Essay":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestEssayId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("essay", payload);
                $scope.LatestEssay = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("err vosjs", errorPayload);
                alert("Something went wrong.");
              }
            );
            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "EssayComment":
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestEssayCommentId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestEssay({
              userId: userid,
              isEssayComment: true,
            }).then(
              function (payload) {
                if (payload && payload.length > 0) {
                  var essayIds = payload.map(function (itms) {
                    if (itms && itms.essayId) {
                      return itms.essayId;
                    }
                  });

                  MyService.getAllEssaysList({
                    eIds: JSON.stringify(essayIds),
                  }).then(
                    function (APIResponse) {
                      MyService.ConsoleLog("APIREs", APIResponse);
                      if (APIResponse.success) {
                        $scope.ShowLoader = false;
                        MyService.ConsoleLog("essay commnent", payload);
                        $scope.LatestEssayComments = APIResponse.data;
                      }
                    },
                    function (err) {
                      MyService.ConsoleLog("Error", err);
                    }
                  );
                }
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("err vosjs", errorPayload);
                alert("Something went wrong.");
              }
            );

            //$location.path('essayDetails/' + catId + '/' + id);
            break;
          case "Post":
            var userid = data._id;
            // var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //var subcatid = data.subcategories[0].pid;
            //var id = data.parent;
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            $scope.dialogClose = ngDialog.open({
              template: "LatestPostId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestPost({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                //MyService.ConsoleLog(payload);
                $scope.LatestPost = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                MyService.ConsoleLog("errorPayload errorPayload", errorPayload);
                alert("Something went wrong.");
              }
            );
            break;
          case "Thread":
            //var catId = data.parentcat[0];
            //var subcatid = ""
            // if(data.subcategories && data.subcategories.length > 0)
            //     var subcatid = data.subcategories[0].pid;
            //var subcatid = data.subcategories[0].pid;
            var userid = data._id;
            $scope.dialogClose = ngDialog.open({
              template: "LatestThreadId",
              closeByNavigation: true,
              scope: $scope,
            });
            $scope.ShowLoader = true;
            MyService.getLatestThread({
              userId: userid,
            }).then(
              function (payload) {
                $scope.ShowLoader = false;
                //MyService.ConsoleLog(payload);
                $scope.LatestThread = payload;
              },
              function (errorPayload) {
                $scope.ShowLoader = false;
                alert("error");
                alert("Something went wrong.");
              }
            );
            //MyService.ConsoleLog('forumthread/' + catId + '/' + subcatid + '/' + id);
            //$location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
            break;
          default:
            break;
        }
      };

      $scope.gotoComments = function (eId, catId) {
        MyService.ConsoleLog("eid", eId);
        MyService.ConsoleLog("catId", catId);

        // MyService.getEssay(eId).then(function (APIResponse) {
        //   MyService.ConsoleLog("APIResponse", APIResponse);
        if (eId && catId) {
          $location.path("/essayDetails/" + catId + "/" + eId);
        }
      };

      $scope.gotoEssay = function (id, catId) {
        $location.path("essayDetails/" + catId + "/" + id);
      };

      $scope.gotoFThread = function (id, catId, subcatid) {
        $location.path("forumthread/" + catId + "/" + subcatid + "/" + id);
      };

      //Select Tags start
      $scope.subtopics = {
        subcategories: [],
      };
      $scope.selectedCat = [];
      $scope.SelectedTree = [];
      $scope.disabled = [];
      $scope.getVal = function (data) {
        //MyService.ConsoleLog(data);
        var idx = $scope.selectedCat.indexOf(data);
        if (idx > -1) {
          $scope.selectedCat.splice(idx, 1);
          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var jdx = $scope.SelectedTree.indexOf(data.parentIds);
          if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
        } else {
          $scope.selectedCat.push(data);
          var id = $scope.disabled.indexOf(data._id);
          if (id == -1) {
            $scope.disabled.push(data._id);
          }
          //MyService.ConsoleLog(data.parentIds);
          //MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
          if ($scope.SelectedTree.length == 0) {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              //angular.copy(data.parentIds, $scope.SelectedTree);
              var parents = data.parentIds;
              //MyService.ConsoleLog(parents);
              $scope.SelectedTree = parents;
            }
          } else {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              //var parents = angular.copy(data.parentIds);
              var parents = data.parentIds;
              var plen = parents.length;
              for (var j = 0; j < plen; j++) {
                $scope.SelectedTree.push(parents[j]);
              }
            }
          }
        }
        //MyService.ConsoleLog($scope.SelectedTree);
      };

      $scope.selectedAll = {
        id: [],
      };

      $scope.removeVal = function (data) {
        var idx = $scope.selectedCat.indexOf(data);
        if (idx > -1) {
          $scope.selectedCat.splice(idx, 1);
          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var parents = data.parentIds;
          var plen = parents.length;
          for (var j = 0; j < plen; j++) {
            var jdx = $scope.SelectedTree.indexOf(parents[j]);
            if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
          }
        }
        $scope.selectedAll.id.length = 0;
        var allLen = $scope.SelectedTree.length;
        var chkboxe = document.getElementsByName("checkedidcat");
        for (var i = 0; i < allLen; i++) {
          chkboxe[i].checked = true;
        }
      };

      $scope.selectConfig = {
        requiredMin: 1,
        selectAll: true,
      };

      $scope.checkCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id == val) {
            $scope.selectedCat.push(rdata);
            return rdata;
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val);
          }
        });
      };

      $scope.loadDefaultTags = function () {
        $scope.selectedCat.length = 0;
        $scope.disabled.length = 0;
        var selectedCat = $scope.uData.subject_of_deep_interest;
        $scope.disabled = selectedCat;
        if (selectedCat.length > 0) {
          for (var s = 0; s < selectedCat.length; s++) {
            var checkcatdata = $scope.checkCatExists(
              $scope.subtopics.subcategories,
              selectedCat[s]
            );
          }
        }
      };

      $scope.loadDefaultLocations = function () {
        var locations = $scope.uData.location_of_interest;
        var len = locations.length;
        $scope.selectedArea.length = 0;
        $scope.showSubCat = true;
        $scope.disabledLocation.length = 0;

        for (var l = 0; l < len; l++) {
          var nobj = {};
          if (locations[l].country == "National (USA)") {
            $scope.Essay.National = "National";
          }
          if (locations[l].country) {
            nobj.country = locations[l].country;
          }
          if (locations[l].state) {
            nobj.state = locations[l].state;
            nobj.statePostalCode = locations[l].statePostalCode;
          }
          if (locations[l].county) {
            nobj.county = locations[l].county;
          }
          if (locations[l].countySubdivision) {
            nobj.countySubdivision = locations[l].countySubdivision;
          }
          if (locations[l].place) {
            nobj.place = locations[l].place;
          }
          $scope.selectedArea.push(nobj);
          $scope.disabledLocation.push(nobj);
        }
      };

      $scope.checkDisabled = function (data, stateId, type) {
        data.country = "United States of America";
        data.state = stateId;
        var idx = indexOfExt($scope.disabledLocation, data);
        if (idx > -1) return true;
        else return false;
      };

      $scope.getSubcatTree = function () {
        $scope.selectedCat.length = 0;
        MyService.getAllCatTree({
          type: "Q,F",
        }).then(
          function (payload) {
            //MyService.ConsoleLog("payload");
            //MyService.ConsoleLog(payload);
            $scope.subtopics.subcategories.length = 0;
            var subcatdata = payload;
            $scope.subtopics.subcategories = subcatdata;
            // MyService.ConsoleLog($scope.userData);
            var SelectedCats = $scope.userData.subjectOfDeepInterest;
            if (SelectedCats && SelectedCats.length > 0) {
              $scope.disabled.length = 0;
              $scope.disabled = SelectedCats;
              for (var i = 0; i < SelectedCats.length; i++) {
                var checkcatdata = $scope.checkCatExists(
                  subcatdata,
                  SelectedCats[i]
                );
              }
            }

            // if ($scope.categoryId != "") {
            //     $scope.disabled.length = 0;
            //     $scope.disabled.push($scope.categoryId);
            //     var checkcatdata = $scope.checkCatExists(subcatdata, $scope.categoryId);
            //     //MyService.ConsoleLog($scope.selectedCat);

            // }

            setTimeout(function () {
              var cols = document.getElementsByClassName("cmnUL");
              var clen = cols.length;
              for (var i = 0; i < clen; i++) {
                cols[i].style.display = "none";
              }
            }, 300);
          },
          function (errorPayload) {
            MyService.ConsoleLog("errorPayload wrong", errorPayload);
            alert("Something went wrong ");
          }
        );
      };

      $scope.showSubCat = true;
      $scope.showLoading = [];
      $scope.showLoadingCounty = [];
      $scope.showDown = [];
      $scope.ChangeShow = function (id) {
        //$scope.showSubCat = true;
        //var cols = document.getElementsByClassName('cmnUL');
        //var clen = cols.length;
        var nclass = "MainCatLi_" + id;
        if (
          document.getElementsByClassName(nclass)[0].style.display &&
          document.getElementsByClassName(nclass)[0].style.display == "none"
        ) {
          document.getElementsByClassName(nclass)[0].style.display = "block";
        } else {
          document.getElementsByClassName(nclass)[0].style.display = "none";
        }
      };

      $scope.getSubCat = function (val) {
        //MyService.ConsoleLog("Main Cat", $scope.subtopics.subcategories);
        return $scope.subtopics.subcategories.filter(function (rdata) {
          MyService.ConsoleLog("rdata", rdata._id + "  " + val);
          if (rdata._id == val) {
            //$scope.selectedCat.push(rdata);
            return rdata.subcategories[0]._id;
          }
        });
      };

      $scope.gotoForumT = function (obj) {
        MyService.ConsoleLog("obj", obj);
        var subcat = $scope.getSubCat(obj.parentCat[0]);
        MyService.ConsoleLog("subcat:::", subcat);
        if (obj.parent && obj.parent != "") {
          if (
            subcat &&
            subcat.length > 0 &&
            subcat[0].subCategories &&
            subcat[0].subCategories.length > 0
          ) {
            MyService.ConsoleLog("obj.parentCat[0]", obj.parentCat[0]);
            $scope.gotoFThread(
              obj.parent,
              obj.parentCat[0],
              subcat[0].subCategories[0]._id
            );
          } else if (
            subcat &&
            subcat.length > 0 &&
            subcat[0].subcategories &&
            subcat[0].subcategories.length > 0
          ) {
            $scope.gotoFThread(
              obj.parent,
              obj.parentCat[0],
              subcat[0].subcategories[0]._id
            );
          } else if (obj.parentCat && obj.parentCat.length > 0) {
            $scope.gotoFThread(obj.parent, obj.parentCat[0], null);
          } else {
            $scope.gotoFThread(obj.parent, obj.parentCat[0], null);
          }
        } else {
          if (
            subcat &&
            subcat.length > 0 &&
            obj.parentCat &&
            obj.parentCat.length > 0
          ) {
            $scope.gotoFThread(
              obj._id,
              obj.parentCat[0],
              subcat[0].subcategories[0]._id
            );
          } else if (
            subcat &&
            subcat.length > 0 &&
            subcat[0].subcategories &&
            subcat[0].subcategories.length > 0
          ) {
            $scope.gotoFThread(
              obj._id,
              obj.parentCat[0],
              subcat[0].subcategories[0]._id
            );
          } else if (obj.parentCat && obj.parentCat.length > 0) {
            $scope.gotoFThread(obj._id, obj.parentCat[0], null);
          } else {
            $scope.gotoFThread(obj._id, obj.parentCat[0], null);
          }
        }

        //"#!/forumthread/"+obj.parentcat[0]+"/"+subcat+"/"+obj._id;
      };

      $scope.gotoForumTopic = function (obj) {
        if (obj.parent && obj.parent != "") {
          $scope.gotoFThread(
            obj.parent,
            obj.parentCat[0],
            obj.subCategories[0]
          );
        } else {
          $scope.gotoFThread(obj._id, obj.parentCat[0], obj.subCategories[0]);
        }
      };

      $scope.saveTags = function () {
        var originalArray = [];
        var originalPArray = [];
        var CatIds = $scope.selectedCat.map(function (sub) {
          return sub._id;
        });
        var parentIds = $scope.selectedCat.map(function (sub) {
          sub.parentIds.map(function (parent) {
            if (parent.pid) {
              originalPArray.push(parent);
            }
          });
        });
        //MyService.ConsoleLog(CatIds);
        //MyService.ConsoleLog(originalPArray);
        MyService.setDeepInterest({
          catId: JSON.stringify(CatIds),
          parentId: JSON.stringify(originalPArray),
        }).then(
          function (payload) {
            if (payload.data.success) {
              $scope.userData.subject_of_deep_interest = CatIds;
              $scope.userData.subject_of_interest = originalPArray;
              tokenService.saveUserData(JSON.stringify($scope.userData));
              $scope.getNewsFeed();
            }
            //MyService.ConsoleLog(payload);
          },
          function (errorPayload) {
            //MyService.ConsoleLog(errorPayload);
          }
        );
      };
      $scope.feed = {
        data: [],
      };
      $scope.getAllFeeds = function () {
        $scope.feed.data.length = 0;
        $scope.loadingNewsExtra = true;
        MyService.getFavNews({
          favChannel: $scope.favChannel,
        }).then(
          function (payload) {
            $scope.loadingNewsExtra = false;
            //MyService.ConsoleLog(payload);
            $scope.feed.data = payload;
            $scope.getNewsFeed();
          },
          function (errorPayload) {
            $scope.loadingNewsExtra = false;
            $scope.getNewsFeed();
            //alert("Something went wrong");
          }
        );
      };

      //Select Tags End
      $scope.loadingNewsExtra = false;
      $scope.NewsPost = [];
      $scope.getNewsFeed = function () {
        if (tokenService.getUserData()) {
          $scope.userData = JSON.parse(tokenService.getUserData());
          MyService.ConsoleLog("$scope.userData", $scope.userData);
        }
        var interests = [];
        if (
          $scope.userData.subject_of_deep_interest &&
          $scope.userData.subject_of_deep_interest.length > 0
        ) {
          interests = $scope.userData.subject_of_deep_interest;
        }
        MyService.getNewsFeed({
          interest: JSON.stringify(interests),
        }).then(
          function (response) {
            $scope.loadingNewsExtra = false;
            //MyService.ConsoleLog(response);
            if (response.data.success) {
              $scope.NewsPost = response.data.data;
            } else {
              //alert("Something went wrong.");
            }
          },
          function (errorPayload) {
            $scope.loadingNewsExtra = false;
            //alert("Something went wrong.");
          }
        );
      };
      $scope.recommended = [];
      $scope.getRecommended = function () {
        $scope.recommended.length = 0;

        if (tokenService.getUserData()) {
          $scope.userData = JSON.parse(tokenService.getUserData());
          $scope.followlist = $scope.userData.followlist;
          //MyService.ConsoleLog($scope.followlist);
          if ($scope.followlist.length > 0) {
            $scope.loadingNewsExtra = true;
            MyService.getRecommended({
              followlist: JSON.stringify($scope.followlist),
            }).then(
              function (payload) {
                //MyService.ConsoleLog(payload);
                $scope.loadingNewsExtra = false;
                if (payload.data.success) {
                  $scope.recommended = payload.data.data;
                  //MyService.ConsoleLog($scope.recommended);
                }
              },
              function (errorPayload) {
                //MyService.ConsoleLog(errorPayload);
                $scope.loadingNewsExtra = false;
              }
            );
          }
        }
      };

      $scope.newData = {};

      $scope.latestDataDateWise = function () {
        MyService.latestDataDateWise({}).then(
          function (payload) {
            MyService.ConsoleLog("payload", payload);
            //alert("Here");
            $scope.newData = payload;
          },
          function (errorPayload) {
            MyService.ConsoleLog("errorPayload", errorPayload);
            alert("Something went wrong");
          }
        );
      };

      if ($scope.userData) {
        $scope.getExtraData();
        $scope.getFollowUsers();
        $scope.latestDataDateWise();
        $scope.getmyData();
        $scope.getSubcatTree();
        $scope.getAllFeeds();
        $scope.getRecommended();
      }
    },
  ]);
