'use strict';
angular.module('myApp.gridState', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/gridState', {
      templateUrl: 'app/gridState/gridState.html',
      controller: 'GridStateCtrl'
    });
  }])
  .controller('GridStateCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', '$routeParams', function($scope, $location, $rootScope, tokenService, MyService, ngDialog, $routeParams) {
    //MyService.ConsoleLog("* Following *");
    $scope.users = {
      data: []
    };
    $scope.showRUpload = false;
    $scope.showLoading = false;

    if ($routeParams.type && $routeParams.type != "") {
      $scope.showRUpload = true;
    } else {
      $scope.showHUpload = true;
    }

    $scope.getGridHistory = function() {
      // this is just an example, in reality this stuff should be in a service
      $scope.showLoading = true;
      MyService.getGridHistory().then(function(payload) {
        //MyService.ConsoleLog(payload)
        $scope.users.data = payload;
        //MyService.ConsoleLog(payload.Items);
        $scope.showLoading = false;
      }, function(err) {
        //MyService.ConsoleLog(err);
      });
    }

    $scope.removeGrid = function(mydata, idx) {
      //MyService.ConsoleLog(mydata);
      //MyService.ConsoleLog(mydata.user);
      var message = 'This action will remove the saved history of the user. Do you want to proceed?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeStoredTempPolitician({
          "user": mydata.user
        }).then(function(payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.getGridHistory();
          alert("Grid state removed successfully.");
        }, function(error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    if ($rootScope.showHUpload) {
      $scope.getGridHistory();
    }
  }])
