"use strict";

angular
  .module("myApp.fiveMatchCompare", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/fiveCompare/:type?/:quizName?", {
        templateUrl: "app/fivemincompare/fivemincompare.html",
        controller: "fiveCompareCtrl",
      });
    },
  ])

  .controller("fiveCompareCtrl", [
    "$scope",
    "apiCall",
    "MyService",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$rootScope",
    "ngDialog",
    "$q",
    "$http",
    "$filter",
    "growl",
    "$routeParams",
    "baseApiUrl",
    "tokenService",
    "$cookies",
    "$sce",
    function (
      $scope,
      apiCall,
      MyService,
      $location,
      $anchorScroll,
      $timeout,
      $rootScope,
      ngDialog,
      $q,
      $http,
      $filter,
      growl,
      $routeParams,
      baseApiUrl,
      tokenService,
      $cookies,
      $sce
    ) {
      $scope.showAlert = false;
      $scope.showBanner = false;
      $scope.isSkip = $routeParams.type;
      $scope.quizName = $routeParams.quizName;
      if (tokenService.getUserId()) {
        $scope.userId = tokenService.getUserId();
        // console.log('$scope.userId: ', $scope.userId);
      }
      $scope.selectedDate;
      window.localStorage.removeItem("showOtherDetails");
      window.localStorage.removeItem("quesData");
      window.localStorage.removeItem("comData");
      window.localStorage.removeItem("essayAnswer");
      window.localStorage.removeItem("compareAnsData");
      window.localStorage.removeItem("eleDate");
      window.localStorage.removeItem("isShowFiveMin");
      $scope.showcomparison = false;
      MyService.ConsoleLog("  $scope.isSkip :", $scope.isSkip);

      // $scope.electionDates = [
      //   {
      //     _id: 1,
      //     eDate: "May 23, 2020",
      //   },
      //   {
      //     _id: 2,
      //     eDate: "June 23, 2020",
      //   },
      // ];
      $scope.getMailingAddress = function (id) {
        MyService.ConsoleLog("is first call");

        MyService.getMailingAddress({
          userId: id,
        }).then(
          function (result) {
            MyService.ConsoleLog("Result", result);
            $scope.mainData = result.data.data;
            if ($scope.mainData && !$scope.mainData.address) {
              window.localStorage.setItem("isFiveMinPage", true);
              ngDialog.open({
                template: "app/popups/user_localupdate.html",
                data: null,
                className: "ngdialog-theme-default",
              });
            }
          },
          function (err) {
            MyService.ConsoleLog("Error >", err);
          }
        );
      };

      if (tokenService.getUserData()) {
        const usr = tokenService.getUserId();
        MyService.ConsoleLog("user > ", usr);
        $scope.getMailingAddress(usr);
      }

      if (window.localStorage.getItem("queIdsN")) {
        $scope.queIdsN = JSON.parse(window.localStorage.getItem("queIdsN"));

        MyService.ConsoleLog("$scope.queIdsN >> ", $scope.queIdsN);
      }

      $scope.getBannerInfo = function () {
        MyService.getBannerInfo({ event: "All" }).then(function (APIResponse) {
          if (APIResponse.data.success) {
            $scope.bannerInfo = APIResponse.data.data;
            MyService.ConsoleLog("$scope.bannerInfo > ", $scope.bannerInfo);
          }
        });
      };

      $scope.getBannerInfo();
      $scope.parties = ["All Parties"];
      $scope.quiz = {
        eDate: "",
        party: "",
      };

      if ($cookies.get("showLongPopUp")) {
        var msg = $cookies.get("showLongPopUp");
        MyService.ConsoleLog("cookies >>>>>", msg);
        window.localStorage.removeItem("showOtherDetails");

        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            msg +
            '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Close</button>\
                  </div>',
          plain: true,
          closeByDocument: false,
          width: "70%",
        });
        modal.then(
          function fullfilled(data) {
            if (data == 1) {
              // MyService.ConsoleLog("yes")
              $cookies.remove("showLongPopUp");
            } else {
              $cookies.remove("showLongPopUp");
            }
          },
          function rejected(data) {}
        );
      }

      // if (!$rootScope.fiveMatch) {

      $rootScope.fiveMatch = JSON.parse(
        window.localStorage.getItem("fiveMatch")
      );
      MyService.ConsoleLog("fiveMatch : >>>>>>>", $rootScope.fiveMatch);

      MyService.ConsoleLog("temp address : >>", $rootScope.tempUserAddress);

      // }
      if (tokenService.getUserData()) {
        var userData = JSON.parse(tokenService.getUserData());
        // console.log("userData > ", userData);
      }

      /**
       *
       * @description Get front runners records from locaStorage.
       */

      $scope.frontRunners = JSON.parse(
        window.localStorage.getItem("frontRunners")
      );

      if (
        !tokenService.getUserData() &&
        window.localStorage.getItem("poliList")
      ) {
        $scope.poliList = JSON.parse(window.localStorage.getItem("poliList"));
      }

      /*  var orgs;
      if ($cookies.get('sendEmail')) {
        var isData = JSON.parse($cookies.get('sendEmail'));
        MyService.ConsoleLog("isData", isData);
        if ($cookies.get('orgTitle')) {
          orgs = JSON.parse($cookies.get('orgTitle'));

        }
        if (isData && isData != '') {
          $cookies.remove('sendEmail');
          var ansLink = window.location.origin + '/#!/showPoliticianAns/' + $scope.quizName;

          MyService.sendEmailToOrg({
            quizName: $scope.quizName,
            orgEmail: isData,
            orgData: orgs,
            ansLink: ansLink
          }).then(function (result) {
            MyService.ConsoleLog("result", result);
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        }
      }
      // MyService.ConsoleLog("")
      if (!$cookies.get('isShowPopUp')) {
        if ($cookies.get('showNotice')) {
          MyService.ConsoleLog("$cookies.get('showNotice')", $cookies.get('showNotice'));
          // alert('inside');
          var title;
          if ($cookies.get('orgTitle')) {
            var orgData = JSON.parse($cookies.get('orgTitle'));
            title = orgData.title;
          } else {
            title = "Organization";
          }
          var message = "Congratulations!  You have completed the quiz. A notice has been sent to ('<b>'" + title + "'</b>') to let them know, but it wouldn’t hurt to contact them directly just in case.";
          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">See contact info</button>\
                      <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(1)">More quizzes</button>\
                      \
                  </div>',
            plain: true
          });

          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (data == 1) {
              $location.path('/quizzes');
            } else {
              if (orgData && orgData._id != '') {
                $location.path('/pressProfile/' + orgData._id);

              }
              // alert('else');
            }
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          });

        }
      } else {
        // alert("alalal");
        $scope.showcomparison = true;
        $scope.loader = false;
        $scope.state = "question-compare-politician";

      }*/

      if (!$rootScope.tempUserAddress) {
        if (tokenService.getUserData()) {
          $rootScope.tempUserAddress = userData.address;
          MyService.ConsoleLog(
            "tempUserAddress :  >>>>>>>",
            $rootScope.tempUserAddress
          );
        }
      } else {
        if (tokenService.getUserData()) {
          var userData = JSON.parse(tokenService.getUserData());
          MyService.ConsoleLog("userData > ", userData);
          // alert("iff");
          MyService.ConsoleLog(
            " ############################# IF ############################### "
          );
          if (
            $rootScope.tempUserAddress.county &&
            $rootScope.tempUserAddress.county != ""
          ) {
            if (
              $rootScope.tempUserAddress.county.county &&
              $rootScope.tempUserAddress.county.county != ""
            ) {
              // alert("Inside If");
              $scope.mainAddress = angular.copy($rootScope.tempUserAddress);
              MyService.ConsoleLog("mainAddress >>> ", $scope.mainAddress);
              var nobj = new Object();

              nobj.state = $scope.mainAddress.state.state;
              nobj.stateFips = $scope.mainAddress.state.stateFips;
              nobj.statePostalCode = $scope.mainAddress.state.statePostalCode;

              nobj.county = $scope.mainAddress.county.countyName;
              nobj.countyFips = $scope.mainAddress.county.countyFips;
              if ($scope.mainAddress.place && $scope.mainAddress.place != "") {
                nobj.place = $scope.mainAddress.place.placeName;
                nobj.placeFips = $scope.mainAddress.place.placeFips;
              }

              nobj.federalCongrassionalDist = $scope.mainAddress
                .federalCongrassionalDist
                ? $scope.mainAddress.federalCongrassionalDist.CD115FP
                : "";

              nobj.stateSenateDistrict = $scope.mainAddress.stateSenateDistrict
                ? $scope.mainAddress.stateSenateDistrict.SLDUST
                : "";

              nobj.legislativeDistrict =
                $scope.mainAddress.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
                  "State House District ",
                  ""
                );

              nobj.schoolDistrict = $scope.mainAddress.unifiedSchoolDistrictName
                ? $scope.mainAddress.unifiedSchoolDistrictName.NAME
                : "";

              nobj.schoolDistrictFips = $scope.mainAddress
                .unifiedSchoolDistrictName
                ? $scope.mainAddress.unifiedSchoolDistrictName.UNSDLEA
                : "";

              // console.log("nobj > ", nobj);
              $rootScope.tempUserAddress = nobj;
            } else {
              // alert(" Inside else");
              $rootScope.tempUserAddress = userData.address;
              MyService.ConsoleLog(
                "tempUserAddress : @@@",
                $rootScope.tempUserAddress
              );
            }
          }
        } else {
          // alert("else");
          MyService.ConsoleLog(
            " ############################# Else ############################### "
          );

          $scope.mainAddress = $rootScope.tempUserAddress
            ? angular.copy($rootScope.tempUserAddress)
            : JSON.parse(window.localStorage.getItem("extraData"));
          // console.log("mainAddress >>> ", $scope.mainAddress);
          var nobj = new Object();

          nobj.state = $scope.mainAddress.state.state;
          nobj.stateFips = $scope.mainAddress.state.stateFips;
          nobj.statePostalCode = $scope.mainAddress.state.statePostalCode;

          nobj.county = $scope.mainAddress.county
            ? $scope.mainAddress.county.countyName
            : "";
          nobj.countyFips = $scope.mainAddress.county
            ? $scope.mainAddress.county.countyFips
            : "";
          if ($scope.mainAddress.place && $scope.mainAddress.place != "") {
            nobj.place = $scope.mainAddress.place.placeName;
            nobj.placeFips = $scope.mainAddress.place.placeFips;
          }

          nobj.federalCongrassionalDist = $scope.mainAddress
            .federalCongrassionalDist
            ? $scope.mainAddress.federalCongrassionalDist.CD115FP
            : "";

          nobj.stateSenateDistrict = $scope.mainAddress.stateSenateDistrict
            ? $scope.mainAddress.stateSenateDistrict.SLDUST
            : "";
          nobj.cityCouncilDistrictName = $scope.mainAddress.cityCouncilDistrict
            ? $scope.mainAddress.cityCouncilDistrict.COUNTDIST
            : "";
          nobj.legislativeDistrict = $scope.mainAddress
            .stateHouseOFRepresentativeDistrict
            ? $scope.mainAddress.stateHouseOFRepresentativeDistrict.NAMELSAD.replace(
                "State House District ",
                ""
              )
            : "";

          nobj.schoolDistrict = $scope.mainAddress.unifiedSchoolDistrictName
            ? $scope.mainAddress.unifiedSchoolDistrictName.NAME
            : "";

          nobj.schoolDistrictFips = $scope.mainAddress.unifiedSchoolDistrictName
            ? $scope.mainAddress.unifiedSchoolDistrictName.UNSDLEA
            : "";
          // console.log("nobj > ", nobj);
          if (nobj && nobj != "") {
            $rootScope.tempUserAddress = nobj;
          }

          // console.log("$rootScope.tempUserAddress >>> ", $rootScope.tempUserAddress);
        }
      }

      if (
        !tokenService.getUserData() &&
        window.localStorage.getItem("extraData")
      ) {
        $rootScope.tempUserAddress = JSON.parse(
          window.localStorage.getItem("extraData")
        );
      }

      $rootScope.welcome = false;

      $scope.FBCompareList = [];
      $scope.AnswerArray = [];
      $scope.AnswerArray[1] = "Very Strongly Agree";
      $scope.AnswerArray[2] = "Agree";
      $scope.AnswerArray[3] = "Somewhat Agree";
      $scope.AnswerArray[4] = "Somewhat Disagree";
      $scope.AnswerArray[5] = "Disagree";
      $scope.AnswerArray[6] = "Very Strongly Disgree";
      $scope.AnswerImpArray = [];
      $scope.AnswerImpArray[1] = "Somewhat Important";
      $scope.AnswerImpArray[2] = "Important";
      $scope.AnswerImpArray[3] = "Very Important";
      $scope.Math = window.Math;
      $scope.userAreaPolitician = [];
      $scope.userAreaVoters = [];
      $scope.noDataFound = false;
      $scope.hideName = false;
      $scope.checkMyOrder = function (frnd) {
        //MyService.ConsoleLog($scope.ord.Option);
        switch ($scope.ord.Option) {
          case "BW":
            return -(
              $scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]
            );
            break;
          case "W":
            return (
              $scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]
            );
            break;
          case "BS":
            return -($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
            break;
          case "S":
            return $scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id];
            break;
          default:
            return -(
              $scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]
            );
            break;
        }
      };
      $scope.nextCat = function () {
        $scope.rootCatIndex = window.localStorage.getItem("RootCatIndex");
        if ($scope.rootCatIndex < $rootScope.categories.length - 1) {
          $location.path(
            "/question-compare/all/" +
              $rootScope.categories[$scope.rootCatIndex]._id
          );
        }
      };

      $scope.getOthersMatch = function (params) {
        $scope.loader = true;

        MyService.ConsoleLog("params >", params);
        params.usersIds = JSON.stringify(params.usersIds);
        // params.questions = JSON.parse(params.questions);
        MyService.getOthersMatch(params).then(
          function (payload) {
            MyService.ConsoleLog("payload >", payload);
            $scope.loader = false;

            if (
              payload.data.data.unweightedMatch &&
              payload.data.data.unweightedMatch != ""
            ) {
              $scope.FBunWeighted = payload.data.data.unweightedMatch;
              $scope.politician = true;
            }
            if (
              payload.data.data.advunWeighated &&
              payload.data.data.advunWeighated != ""
            ) {
              $scope.advUnWeighated = payload.data.data.advunWeighated;
              $scope.showadv = true;
            }
            if (
              payload.data.data.voterunWeight &&
              payload.data.data.voterunWeight != ""
            ) {
              $scope.voterUnWeighated = payload.data.data.voterunWeight;
              $scope.showvoter = true;
            }
            $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
            $scope.fbmultiplier = payload.data.data.fbmultiplier;
            $scope.fbqlen = payload.data.data.qdata.length;
            $scope.voterCombined = payload.data.data.voterCombWeight;
            $scope.voterMulti = payload.data.data.voterMulti;
            $scope.advCombined = payload.data.data.advCombWeight;
            $scope.advMulti = payload.data.data.advMulti;
            $scope.allUser = payload.data.data.AllUsers;
            // var tmpData = JSON.parse(window.localStorage.getItem('fiveMatch'));
            // MyService.ConsoleLog("tmpData >>>>>>>", tmpData);
            var tmpArr = $rootScope.fiveMatch.filter(function (itms) {
              itms.my_answer = {};
              itms["my_answer"].answer = itms.answer;
              itms.my_answer.comment = itms.comment;
              itms.my_answer.importance = itms.importance;
              return itms;
            });
            MyService.ConsoleLog("Check this if you can", tmpArr);
            var objParams = {
              usersIds: $scope.userAreaPolitician,
              questions: JSON.stringify(tmpArr),
              cur_userId: $scope.userId,
            };
            window.localStorage.setItem("comData", JSON.stringify(objParams));
            window.localStorage.setItem("quesData", JSON.stringify(tmpArr));
          },
          function (error) {
            MyService.ConsoleLog("error", error);
          }
        );
      };

      $scope.getGroupMatch = function () {
        $scope.statusGetLocalPoliticians = 1;
        $scope.page += 1;
        $scope.loader = true;
        // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress);
        // MyService.ConsoleLog("$rootScope.tempUserA ******* ", $rootScope.tempUserAddress.state)
        var extradata = new Object();
        if ($rootScope.tempUserAddress) {
          if (
            $rootScope.tempUserAddress.state &&
            $rootScope.tempUserAddress.state != ""
          ) {
            extradata.statePostalCode =
              $rootScope.tempUserAddress.statePostalCode;
          }
          if ($rootScope.tempUserAddress.county) {
            // $rootScope.tempUserAddress.FinalData = JSON.stringify(
            //   $rootScope.tempUserAddress.countyFips
            // );
            extradata.county = $rootScope.tempUserAddress.county;
          }
          if (
            $rootScope.tempUserAddress.countySubdivision &&
            $rootScope.tempUserAddress.countySubdivisionFips
          ) {
            $rootScope.tempUserAddress.FinalData = JSON.stringify(
              $rootScope.tempUserAddress.countySubdivisionFips
            );
            extradata.countySubdivision =
              $rootScope.tempUserAddress.countySubdivision;
          }
          if ($rootScope.tempUserAddress.place) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.place = $rootScope.tempUserAddress.place;
          }
          if (
            $rootScope.tempUserAddress.voterDistrict &&
            $rootScope.tempUserAddress.voterDistrictFips
          ) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
          }
          if ($rootScope.tempUserAddress.federalCongrassionalDist) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            MyService.ConsoleLog(
              "federalCongrassionalDist  :",
              $rootScope.tempUserAddress.federalCongrassionalDist
            );
            extradata.federalCongrassionalDist =
              $rootScope.tempUserAddress.federalCongrassionalDist;
          }
          if ($rootScope.tempUserAddress.stateSenateDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.stateSenateDistrict =
              $rootScope.tempUserAddress.stateSenateDistrict;
          }
          if ($rootScope.tempUserAddress.legislativeDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.legislativeDistrict =
              $rootScope.tempUserAddress.legislativeDistrict;
          }
          if ($rootScope.tempUserAddress.schoolDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.schoolDistrict =
              $rootScope.tempUserAddress.schoolDistrict;
          }
          if ($rootScope.tempUserAddress.cityCouncilDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.cityCouncilDistrictName =
              $rootScope.tempUserAddress.cityCouncilDistrict;
          }
        }
        MyService.ConsoleLog(
          "extradata : >>>>>>>>>> ",
          JSON.stringify(extradata)
        );
        MyService.getAllLocalPoli({
          max_records: 100,
          page: $scope.page,
          extradata: JSON.stringify(extradata),
        }).then(
          function (response) {
            if (response.data.success) {
              setTimeout(function () {
                $scope.$apply(function () {
                  if (response.data.data.length > 0) {
                    MyService.getAdvocates().then(
                      function (payload) {
                        MyService.ConsoleLog("Response >", payload);
                        var ndata = response.data.data;
                        var wdata = ndata.concat(payload.data.data);

                        $scope.userAreaPolitician = wdata.map(function (ques) {
                          return ques._id;
                        });
                        // $rootScope.fiveMatch.filter(function (itms) {
                        //   MyService.ConsoleLog("itms", itms);
                        //   var myAns = {
                        //     answer: '',
                        //     importance: '',
                        //     comment: ''
                        //   };
                        //   myAns = itms;
                        //   myAns['answer'] = itms.answer;
                        //   myAns['importance'] = itms.importance;
                        //   myAns['comment'] = itms.comment;
                        //   itms.my_answer = myAns;
                        //   return itms;
                        // });

                        MyService.ConsoleLog(
                          " $scope.userAreaPolitician >",
                          $scope.userAreaPolitician
                        );
                        // MyService.ConsoleLog("  $rootScope.fiveMatch >", $rootScope.fiveMatch);

                        $scope.getOthersMatch({
                          userIds: JSON.stringify($scope.userAreaPolitician),
                          quizData: JSON.stringify($rootScope.fiveMatch),
                          userData: $scope.userId,
                        });
                      },
                      function (err) {
                        MyService.ConsoleLog("Error >>", err);
                      }
                    );
                  } else {
                    $scope.loader = false;
                    // var message =
                    //   "No active candidates in your area have answered this set of questions yet. Contact them and ask them to fill it out.";
                    // var modal = ngDialog.openConfirm({
                    //   template:
                    //     "<p>" +
                    //     message +
                    //     '</p>\
                    //     <div class="ngdialog-buttons">\
                    //         <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                    //         \
                    //     </div>',
                    //   plain: true,
                    // });

                    // modal.then(
                    //   function fullfilled(data) {
                    //     //MyService.ConsoleLog("fullfilled: ", data);
                    //     if (data == 1) {
                    //       $scope.postonFacebook();
                    //     } else {
                    //       if ($rootScope.userRole) {
                    //         $location.path("/snapshot");
                    //       } else {
                    //         $location.path("/signin");
                    //       }
                    //     }
                    //   },
                    //   function rejected(data) {
                    //     //MyService.ConsoleLog("rejected: ", data);
                    //   }
                    // );
                  }
                });
              }, 100);
            } else {
              $scope.page -= 1;
              $scope.statusGetLocalPoliticians = 3;
              if (typeof response.data.error == "string")
                growl.error(response.data.error);
              else growl.error("Something went wrong");
            }
          },
          function (err) {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            $scope.loader = false;
            growl.error(err);
          }
        );
      };
      $scope.page = 0;
      $scope.loader = false;

      $scope.getLocalPoliticians = function (val, pVal) {
        MyService.ConsoleLog(" welcome to getLocalPoliticians ", val);
        $scope.statusGetLocalPoliticians = 1;
        $scope.page += 1;
        $scope.loader = true;
        MyService.ConsoleLog(
          "$rootScope.tempUserA ******* ",
          $rootScope.tempUserAddress
        );
        if (
          !tokenService.getUserData() &&
          $rootScope.tempUserAddress &&
          $rootScope.tempUserAddress.schoolDistrict &&
          $rootScope.tempUserAddress.schoolDistrict != ""
        ) {
          window.localStorage.setItem(
            "extraData",
            JSON.stringify($rootScope.tempUserAddress)
          );
        }
        if (!$rootScope.tempUserAddress) {
          if (
            !tokenService.getUserData() &&
            window.localStorage.getItem("extraData")
          ) {
            $rootScope.tempUserAddress = JSON.parse(
              window.localStorage.getItem("extraData")
            );
            MyService.ConsoleLog(
              "$rootScope.tempUserAddress >> ",
              $rootScope.tempUserAddress
            );
          }
        }
        var extradata = new Object();
        if ($rootScope.tempUserAddress) {
          if (
            $rootScope.tempUserAddress.state &&
            $rootScope.tempUserAddress.state != ""
          ) {
            extradata.statePostalCode =
              $rootScope.tempUserAddress.statePostalCode;
          }
          if ($rootScope.tempUserAddress.county) {
            $rootScope.tempUserAddress.FinalData = $rootScope.tempUserAddress
              .countyFips
              ? JSON.stringify($rootScope.tempUserAddress.countyFips)
              : "";
            extradata.county = $rootScope.tempUserAddress.county;
          }
          if ($rootScope.tempUserAddress.countySubdivision) {
            $rootScope.tempUserAddress.FinalData = $rootScope.tempUserAddress
              .countySubdivisionFips
              ? JSON.stringify($rootScope.tempUserAddress.countySubdivisionFips)
              : "";
            extradata.countySubdivision =
              $rootScope.tempUserAddress.countySubdivision;
          }
          if ($rootScope.tempUserAddress.place) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.place = $rootScope.tempUserAddress.place;
          }
          if (
            $rootScope.tempUserAddress.voterDistrict &&
            $rootScope.tempUserAddress.voterDistrictFips
          ) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.voterDistrict = $rootScope.tempUserAddress.voterDistrict;
          }

          if (
            $rootScope.tempUserAddress.cityCouncilDistrictName &&
            $rootScope.tempUserAddress.cityCouncilDistrictName != ""
          ) {
            extradata.cityCouncilDistrictName =
              $rootScope.tempUserAddress.cityCouncilDistrictName;
          }
          if ($rootScope.tempUserAddress.federalCongrassionalDist) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            MyService.ConsoleLog(
              "federalCongrassionalDist  :",
              $rootScope.tempUserAddress.federalCongrassionalDist
            );
            extradata.federalCongrassionalDist =
              $rootScope.tempUserAddress.federalCongrassionalDist;
          }
          if ($rootScope.tempUserAddress.stateSenateDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.stateSenateDistrict =
              $rootScope.tempUserAddress.stateSenateDistrict;
          }
          if ($rootScope.tempUserAddress.legislativeDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.legislativeDistrict =
              $rootScope.tempUserAddress.legislativeDistrict;
          }
          if ($rootScope.tempUserAddress.schoolDistrict) {
            //$rootScope.tempUserAddress.FinalData = JSON.stringify($rootScope.tempUserAddress.place._id);
            extradata.schoolDistrict =
              $rootScope.tempUserAddress.schoolDistrict;
          }
        }
        MyService.ConsoleLog("extradata : >>>>>>>>>> ", extradata);

        MyService.ConsoleLog("check add data >> ", $rootScope.tempUserAddress);

        MyService.getLocalPoliForVoterFivemin({
          max_records: 100,
          page: $scope.page,
          extradata: JSON.stringify(extradata),
          eDate: val ? val : new Date(),
          party: pVal,
        }).then(
          function (response) {
            if (response.data.success) {
              setTimeout(function () {
                $scope.$apply(function () {
                  if (response.data.data.poliRecords.length > 0) {
                    var ndata = response.data.data.poliRecords;
                    $scope.poliList = ndata;
                    window.localStorage.setItem(
                      "poliList",
                      JSON.stringify($scope.poliList)
                    );
                    if (
                      response.data.data.parties &&
                      response.data.data.parties.length > 0
                    ) {
                      var partyArr = [];

                      $scope.parties = response.data.data.parties;
                      console.log(
                        '$scope.parties.indexOf("All Parties") > ',
                        $scope.parties.indexOf("All Parties")
                      );
                      if ($scope.parties.indexOf("All Parties") == -1) {
                        $scope.parties.unshift("All Parties");
                      }
                      window.localStorage.setItem(
                        "parties",
                        JSON.stringify($scope.parties)
                      );
                      // response.data.data.parties.filter(function (party, ind) {
                      //   partyArr.push({
                      //     // _id: ind + 1,
                      //     party: party,
                      //   });
                      // });
                      // // partyArr.unshift({
                      // //   _id: 0,
                      // //   party: "All Parties",
                      // // });
                      // window.localStorage.setItem(
                      //   "parties",
                      //   JSON.stringify(partyArr)
                      // );
                      // $scope.parties = $scope.parties.concat(partyArr);
                      // $scope.parties.filter(function (item, pos) {
                      //   return $scope.parties.indexOf(item) == pos;
                      // });
                    }

                    $scope.userAreaPolitician = ndata.map(function (ques) {
                      return ques._id;
                    });
                    MyService.ConsoleLog(
                      " userAreaPolitician :",
                      $scope.userAreaPolitician
                    );
                    $scope.CompareWithAllPolitcians(val);
                  } else {
                    // alert("esdlse");
                    $scope.allCandidates = [];
                    window.localStorage.setItem("showOtherDetails", true);
                    $scope.loader = false;
                    $scope.showBanner = true;
                    // var message =
                    //   "No active candidates in your area have answered this set of questions yet. Contact them and ask them to fill it out.";
                    // var modal = ngDialog.openConfirm({
                    //   template:
                    //     "<p>" +
                    //     message +
                    //     '</p>\
                    //     <div class="ngdialog-buttons">\
                    //         <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(1)">See How You Match Your Community</button>\
                    //         <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">Learn More About Your Candidates</button>\
                    //         \
                    //     </div>',
                    //   plain: true,
                    //   width: "70%",
                    // });

                    // modal.then(
                    //   function fullfilled(data) {
                    //     //MyService.ConsoleLog("fullfilled: ", data);
                    //     if (data == 1) {
                    //       $scope.getGroupMatch();
                    //     } else {
                    //       $location.path("/compare-select/politician");
                    //     }
                    //   },
                    //   function rejected(data) {
                    //     //MyService.ConsoleLog("rejected: ", data);
                    //   }
                    // );
                  }
                });
              }, 100);
            } else {
              $scope.page -= 1;
              $scope.statusGetLocalPoliticians = 3;
              if (typeof response.data.error == "string")
                growl.error(response.data.error);
              else growl.error("Something went wrong");
            }
          },
          function (err) {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            $scope.loader = false;
            growl.error("Something went wrong");
          }
        );
      };

      $scope.changeColor = function (val) {
        $scope.gr = {
          background:
            "linear-gradient(to right,#FEFF10 ," + val + "%, #2b4f7f 10%)",
        };
        // MyService.ConsoleLog("$scope.gr", $scope.gr);
        return $scope.gr;
      };

      /**
       * @description Saved candidates to the locatstorage
       */
      $scope.savedCandi = [];
      $scope.saveCandidates = function (candi) {
        if (candi.isChecked) {
          $scope.savedCandi.push(candi);
          window.localStorage.setItem(
            "savedCandidates",
            JSON.stringify($scope.savedCandi)
          );
        } else {
          MyService.ConsoleLog(" $scope.savedCandi > ", $scope.savedCandi);
          MyService.ConsoleLog(" candi > ", candi);
          var toDel = $scope.savedCandi.indexOf(candi);
          MyService.ConsoleLog("toDel >> ", toDel);

          $scope.savedCandi.splice(toDel, 1);
          MyService.ConsoleLog(
            " $scope.savedCandi after rm > ",
            $scope.savedCandi
          );
          window.localStorage.removeItem("savedCandidates");
          window.localStorage.setItem(
            "savedCandidates",
            JSON.stringify($scope.savedCandi)
          );
        }
      };

      $scope.showSavedCandidates = function () {
        if (window.localStorage.getItem("savedCandidates")) {
          $scope.showCandidates = JSON.parse(
            window.localStorage.getItem("savedCandidates")
          );
        } else {
          growl.error("Seems no candidates are saved");
        }

        if ($scope.showCandidates && $scope.showCandidates.length > 0) {
          $scope.showPopUp = ngDialog.open({
            template: "showSavedCandi",
            scope: $scope,
            width: "60%",
          });
        } else {
          growl.error("Seems no candidates are saved");
        }
      };

      /**
       * Cancel
       */

      $scope.hidePopup = function () {
        if ($scope.showPopUp) {
          $scope.showPopUp.close();
        }

        if ($scope.isShowMatch) {
          $scope.isShowMatch.close();
        }
        if ($scope.isShowPopUp) {
          $scope.isShowPopUp.close();
        }
      };

      $scope.finish = function () {
        window.localStorage.setItem("isShowFiveMin", true);
        $location.path("/fiveMatch");
      };

      /**
       * @description Show the description of essay question answers
       */
      $scope.readStatement = function (val) {
        MyService.ConsoleLog("val >> ", val);
        window.localStorage.setItem("essayAnswers", JSON.stringify(val));
        if (val && val.length > 0) {
          $location.path("/fiveMinEssayAnswers");
        } else {
          growl.error("Candidate Haven't answered essay questions");
        }
      };

      /**
       * @description Redirect to get match list
       */
      $scope.redirectTo = function (type) {
        $location.path("/showListOfMatch/" + type);
      };

      $scope.showSelectedOffice = function (ans) {
        // MyService.ConsoleLog(
        //   "check questions >> ",
        //   $scope.questions[$scope.currentIndex]
        // );
        var isData = ans.author.politicianInfo.filter(function (polInfo) {
          if (
            polInfo.officeName &&
            polInfo.officeName != "" &&
            $scope.office == polInfo.officeName.toLowerCase()
          ) {
            // MyService.ConsoleLog(
            //   "check office name >> ",
            //   $scope.office,
            //   "polInfo.officeName",
            //   polInfo.officeName
            // );
            return polInfo;
          }
        });
        // MyService.ConsoleLog("isData >> ", isData);
        if (isData && isData.length > 0) {
          return true;
        } else {
          return false;
        }
      };

      $scope.showDetails = function (key) {
        $scope.office = key.toLowerCase();
        $scope.currentIndex = 0;
        MyService.ConsoleLog(
          "check flag",
          window.localStorage.getItem("showOtherDetails")
        );
        if (window.localStorage.getItem("showOtherDetails") == "true") {
          $location.path("/showAllUsersMatch");
        } else {
          $scope.hidePopup();
          $scope.showcomparison = true;
        }
      };

      $scope.allCandidates = [];
      $scope.filterCandidatesOfficeWise = function () {
        var cLen = $scope.AllUsers.length;
        MyService.ConsoleLog("cLen >> ", cLen);
        for (var i = 0; i < cLen; i++) {
          var udata = $scope.AllUsers[i];
          udata = JSON.parse(JSON.stringify(udata));
          if (udata.politicianInfo && udata.politicianInfo.length > 0) {
            var poliInfoLen = udata.politicianInfo.length;

            for (var j = 0; j < poliInfoLen; j++) {
              var polivar = udata.politicianInfo[j];
              if (
                polivar &&
                polivar.officeName != "" &&
                polivar.runningForOffice == "Y"
              ) {
                MyService.ConsoleLog("polivar >> ", polivar.levelOfGov);
                if (
                  (polivar.levelOfGov == "placeName" ||
                    polivar.levelOfGov == "countySubDivisionName" ||
                    polivar.levelOfGov == "unifiedSchoolDistrictName" ||
                    polivar.levelOfGov == "elementarySchoolDistrictName" ||
                    polivar.levelOfGov == "secondarySchoolDistrictName" ||
                    polivar.levelOfGov == "cityCouncilDistrictName" ||
                    polivar.levelOfGov == "countySubdivision") &&
                  polivar.candidateStatus != "inactive"
                ) {
                  if (polivar.officeName) {
                    polivar.officeName = polivar.officeName.toLowerCase();
                    udata.party = "";
                    var newOff = polivar.officeName.toLowerCase();
                    udata.office = newOff;
                    udata.candidateStatus = polivar.candidateStatus;
                  }
                  if (typeof polivar.party == "string") {
                    polivar.party = polivar.party;
                  } else {
                    var party = "";
                    polivar.party.forEach(function (element, index) {
                      if (element) {
                        party += element + ", ";
                        var n = party.lastIndexOf(",");
                        var a = party.substring(0, n);
                        udata.party = a;
                      }
                    });
                  }

                  $scope.allCandidates.push(udata);

                  // MyService.ConsoleLog(
                  //   "$scope.allCandidates >",
                  //   $scope.allCandidates
                  // );
                } else if (
                  (polivar.levelOfGov == "countyName" ||
                    polivar.levelOfGov == "county") &&
                  polivar.candidateStatus != "inactive"
                ) {
                  if (polivar.officeName) {
                    polivar.officeName = polivar.officeName.toLowerCase();
                    udata.office = polivar.officeName;
                    udata.candidateStatus = polivar.candidateStatus;
                    udata.party = polivar.party;
                  }
                  if (typeof polivar.party == "string") {
                    polivar.party = polivar.party;
                  } else {
                    var party = "";
                    polivar.party.forEach(function (element, index) {
                      if (element) {
                        party += element + ", ";
                        var n = party.lastIndexOf(",");
                        var a = party.substring(0, n);
                        udata.party = a;
                      }
                    });
                  }

                  if (typeof polivar.party == "string") {
                    // if ($scope.comp.party && $scope.comp.party != "") {
                    //   if ($scope.comp.party == udata.politicianInfo[s].party) {
                    //     $scope.allCandidates.push(udata);
                    //   }
                    // } else {
                    $scope.allCandidates.push(udata);
                    // }
                  } else {
                    $scope.allCandidates.push(udata);
                  }
                } else if (
                  (polivar.levelOfGov == "statePostalCode" ||
                    (polivar.levelOfGov == "stateSenateDistrict" &&
                      polivar.officeName != "" &&
                      polivar.officeName.toLowerCase() != "u.s. senate") ||
                    polivar.officeName != "president") &&
                  polivar.candidateStatus != "inactive"
                ) {
                  if (polivar.officeName) {
                    polivar.officeName = polivar.officeName.toLowerCase();
                    udata.office = polivar.officeName;
                    udata.candidateStatus = polivar.candidateStatus;
                    udata.party = polivar.party;
                  }
                  if (typeof polivar.party == "string") {
                    polivar.party = polivar.party;
                  } else {
                    var party = "";
                    polivar.party.forEach(function (element, index) {
                      if (element) {
                        party += element + ", ";
                        var n = party.lastIndexOf(",");
                        var a = party.substring(0, n);

                        udata.party = a;
                      }
                    });
                  }

                  $scope.allCandidates.push(udata);
                } else if (
                  polivar.levelOfGov == "federalCongressionalDistrict" ||
                  polivar.officeName == "president" ||
                  (polivar.officeName == "President" &&
                    polivar.levelOfGov == "All USA") ||
                  polivar.levelOfGov == "all USA" ||
                  (polivar.levelOfGov == "statePostalCode" &&
                    polivar.officeName != "" &&
                    polivar.officeName != "State House") ||
                  polivar.officeName != "auditor" ||
                  (polivar.officeName != "" &&
                    polivar.officeName.toLowerCase() == "u.s. senate" &&
                    polivar.candidateStatus != "inactive")
                ) {
                  if (polivar.levelOfGov != "unifiedSchoolDistrictName") {
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      if (typeof polivar.party == "string") {
                        polivar.party = polivar.party;
                      } else {
                        var party = "";
                        polivar.party.forEach(function (element, index) {
                          if (element) {
                            party += element + ", ";
                            var n = party.lastIndexOf(",");
                            var a = party.substring(0, n);
                            udata.party = a;
                          }
                        });
                      }
                      udata.office = polivar.officeName;
                      udata.candidateStatus = polivar.candidateStatus;
                    }

                    $scope.allCandidates.push(udata);
                  } else if (
                    (polivar.levelOfGov == "All USA" ||
                      polivar.levelOfGov == "all USA") &&
                    polivar.officeName != "president" &&
                    polivar.candidateStatus != "inactive"
                  ) {
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      udata.office = polivar.officeName;
                      udata.candidateStatus = polivar.candidateStatus;
                      udata.party = polivar.party;
                    }

                    $scope.allCandidates.push(udata);
                  }
                }
              }
            }
          } else {
            if (udata.voterScope) {
              if (
                udata.voterScope == "placeName" ||
                udata.voterScope == "countySubDivisionName" ||
                udata.voterScope == "unifiedSchoolDistrictName" ||
                udata.voterScope == "elementarySchoolDistrictName" ||
                udata.voterScope == "cityCouncilDistrictName" ||
                udata.voterScope == "secondarySchoolDistrictName" ||
                (udata.voterScope == "countySubdivision" &&
                  udata.voterScope != "federalCongressionalDistrict")
              ) {
                udata.office = udata.office.toLowerCase();
                if (typeof udata.party == "string") {
                  // alert('one');
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);

                      if (a != '""') {
                        udata.party = a;
                      } else {
                        udata.party = "";
                      }
                    }
                  });
                }
                if (
                  udata &&
                  udata.office &&
                  udata.office.toLowerCase() != "president"
                ) {
                  if (udata.verifiedUser != false) {
                    $scope.allCandidates.push(udata);
                  }
                }
              } else if (
                udata.voterScope == "countyName" ||
                udata.voterScope == "county"
              ) {
                udata.office = udata.office.toLowerCase();
                if (typeof udata.party == "string") {
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);
                      udata.party = a;
                    }
                  });
                }
                if (udata.verifiedUser != false) {
                  $scope.allCandidates.push(udata);
                }
              } else if (
                udata.electionOfficeTypeId == "S" ||
                udata.electionOfficeTypeId == "K" ||
                udata.electionOfficeTypeId == "L" ||
                udata.voterScope == "statePostalCode"
              ) {
                // MyService.ConsoleLog("plz check ========", udata);
                udata.office = udata.office.toLowerCase();
                if (typeof udata.party == "string") {
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);

                      udata.party = a;
                    }
                  });
                }

                if (udata.verifiedUser != false) {
                  $scope.allCandidates.push(udata);
                }
              } else if (
                type == "federal" &&
                (udata.electionOfficeTypeId == "C" ||
                  udata.electionOfficeTypeId == "J" ||
                  udata.electionOfficeTypeId == "R" ||
                  udata.voterScope == "federalCongressionalDistrict" ||
                  udata.office == "president" ||
                  (udata.office == "President" &&
                    udata.voterScope == "All USA") ||
                  udata.voterScope == "all USA" ||
                  udata.voterScope == "statePostalCode")
              ) {
                if (udata.voterScope != "unifiedSchoolDistrictName") {
                  udata.office = udata.office.toLowerCase();

                  if (typeof udata.party == "string") {
                    udata.party = udata.party;
                  } else {
                    var party = "";
                    udata.party.forEach(function (element, index) {
                      if (element) {
                        party += element + ", ";
                        var n = party.lastIndexOf(",");
                        var a = party.substring(0, n);

                        udata.party = a;
                      }
                    });
                  }

                  if (
                    (udata.office &&
                      udata.office.toLowerCase() == "u.s. house") ||
                    udata.office.toLowerCase() == "u.s. senate" ||
                    udata.office.toLowerCase() == "president"
                  ) {
                    if (udata.verifiedUser != false) {
                      if (
                        udata.isOnBallot != false &&
                        $scope.comp.election.electionDate == udata.electionDate
                      ) {
                        $scope.topCandidates.push(udata);
                      } else {
                        $scope.allCandidates.push(udata);
                      }
                    }
                  }
                }
              } else if (
                type == "other" &&
                (udata.voterScope == "All USA" || udata.voterScope == "all USA")
              ) {
                if (udata.verifiedUser != false) {
                  $scope.allCandidates.push(udata);
                }
                // }
              } else if (
                udata.office == "president" ||
                (udata.office == "President" &&
                  udata.voterScope == "All USA") ||
                udata.voterScope == "all USA"
              ) {
                if (udata.voterScope != "unifiedSchoolDistrictName") {
                  udata.office = udata.office.toLowerCase();

                  if (typeof udata.party == "string") {
                    udata.party = udata.party;
                  } else {
                    var party = "";
                    udata.party.forEach(function (element, index) {
                      if (element) {
                        party += element + ", ";
                        var n = party.lastIndexOf(",");
                        var a = party.substring(0, n);

                        udata.party = a;
                      }
                    });
                  }

                  if (
                    udata.verifiedUser != false &&
                    type != "state" &&
                    type != "local"
                  ) {
                    $scope.allCandidates.push(udata);
                  }
                }
              }
            }
          }
        }
        MyService.ConsoleLog(
          " $scope.allCandidates before filter >> ",
          $scope.allCandidates
        );
        var data = $scope.allCandidates.filter(function (item, ind) {
          return (
            ind ==
            $scope.allCandidates.findIndex(function (val) {
              return JSON.stringify(val) == JSON.stringify(item);
            })
          );
        });
        $scope.allCandidates == [];
        $scope.allCandidates = data;
        MyService.ConsoleLog(" $scope.allCandidates >> ", $scope.allCandidates);
      };

      $scope.getUrl = function (val) {
        // MyService.ConsoleLog("val >> ", val);
        if (val && val.review) {
          switch (val.review.toLowerCase()) {
            case "qualified":
            case "recommended":
              return "../assets/images/yellowCheck.svg";
            case "highly qualified":
            case "highly recommended":
              return "../assets/images/greenCheck.svg";
            case "unqualified":
            case "not recommended":
              return "../assets/images/redX.svg";

            case "declined to review":
              return "../assets/images/redExclamation.svg";
            default:
              break;
          }
        }
      };

      $scope.CompareWithAllPolitcians = function (val) {
        $timeout(function () {
          //MyService.ConsoleLog("here : ", $scope.userAreaPolitician);
          var ndata = {
            userdata: $rootScope.fiveMatch,
            comparewith: $scope.userAreaPolitician,
            type: "politician",
          };
          MyService.ConsoleLog("Compare this data <><<><>", ndata);
          if ($rootScope.fiveMatch && $rootScope.fiveMatch.length > 0) {
            MyService.compareFiveMinAnswers({
              userdata: JSON.stringify($rootScope.fiveMatch),
              comparewith: JSON.stringify($scope.userAreaPolitician),
              type: "politician",
              eDate: val ? val : new Date(),
              party: $scope.quiz.party,
              queIdsArr: window.localStorage.getItem("queIdsN")
                ? window.localStorage.getItem("queIdsN")
                : [],
            }).then(
              function (payload) {
                MyService.ConsoleLog("compareFiveMinAnswers1 success ****");
                MyService.ConsoleLog("payload check >> ", payload);
                $scope.FBCompareList = [];
                $scope.loader = false;
                if (payload.data.success) {
                  MyService.ConsoleLog(
                    "CompareWithAllPolitcians >> ",
                    payload.data.data.qdata
                  );

                  if (
                    !payload.data.data.qdata &&
                    payload.data.data.AllUsers &&
                    payload.data.data.AllUsers.length > 0
                  ) {
                    if (window.localStorage.getItem("savedCandidates")) {
                      savedCan = JSON.parse(
                        window.localStorage.getItem("savedCandidates")
                      );
                    }
                    $scope.AllUsers = payload.data.data.AllUsers.filter(
                      function (val) {
                        if (val && val._id != "") {
                          $scope.poliList.filter(function (itm) {
                            if (val._id == itm._id) {
                              val.numberOfOpenings = itm.numberOfOpenings;
                              val.dataX = itm.dataX;
                              val.dataY = itm.dataY;
                              val.total_tagged = itm.total_tagged;
                              val.total_answers = itm.total_answers;
                            }
                          });
                          return val;
                        }
                      }
                    );

                    if (savedCan && savedCan.length > 0) {
                      $scope.AllUsers.map(function (item) {
                        savedCan.filter(function (val) {
                          if (item.name == val.name) {
                            item.isChecked = true;
                          }
                        });
                        return item;
                      });
                    }

                    if (
                      payload.data.data.barRatings &&
                      payload.data.data.barRatings != ""
                    )
                      $scope.barRatingData = payload.data.data.barRatings;
                    window.localStorage.setItem(
                      "compareResult",
                      JSON.stringify(payload.data.data)
                    );

                    $scope.allCandidates = [];
                    $scope.filterCandidatesOfficeWise();
                  }

                  if (
                    payload.data.data &&
                    payload.data.data.qdata &&
                    payload.data.data.qdata.length > 0
                  ) {
                    $scope.questions = payload.data.data.qdata.sort(function (
                      x,
                      y
                    ) {
                      if (x.matchOrder < y.matchOrder) {
                        return -1;
                      }
                      if (x.matchOrder > y.matchOrder) {
                        return 1;
                      }
                      return 0;
                    });
                  }

                  window.localStorage.setItem(
                    "compareResult",
                    JSON.stringify(payload.data.data)
                  );
                  if (
                    payload.data.data.qdata &&
                    payload.data.data.qdata.length > 0
                  ) {
                    $scope.curUserAns = payload.data.data.qdata.filter(
                      function (ans) {
                        if (
                          ans &&
                          ans.my_answer &&
                          ans.my_answer.answer &&
                          ans.my_answer.answer != "" &&
                          ans.my_answer.importance &&
                          ans.my_answer.importance != ""
                        ) {
                          return ans;
                        }
                      }
                    );
                  }

                  $scope.FBCompareList = payload.data.data.qdata;

                  if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
                    $scope.FBunWeighted = payload.data.data.unweightedMatch;
                    $scope.fbcombinedWeight =
                      payload.data.data.fbcombinedWeight;
                    $scope.fbmultiplier = payload.data.data.fbmultiplier;
                    $scope.answeredQuestions =
                      payload.data.data.answeredQuestions;
                    $scope.fbqlen = payload.data.data.qlen;
                    $scope.barRatingData = payload.data.data.barRatings;
                    $scope.confidenceLevel = payload.data.data.confidenceLevel;
                    $scope.essayAnswers = payload.data.data.fiveMinEssayAns;
                    $scope.sumOfImp = payload.data.data.sumOfImp;
                    $scope.percentageOfQuizUnanswered =
                      payload.data.data.percentageOfQuizUnanswered;
                    $scope.matchPercentage = payload.data.data.matchPercentage;
                    $scope.lowestPossibleMatch =
                      payload.data.data.lowestPossibleMatch;
                    $scope.highestPossibleMatch =
                      payload.data.data.highestPossibleMatch;
                    $scope.greatestPossibleNegative =
                      payload.data.data.greatestPossibleNegative;
                    $scope.greatestPossiblePositive =
                      payload.data.data.greatestPossiblePositive;
                    $scope.noOfQuesMutuallyAns =
                      payload.data.data.noOfQuesMutuallyAns;
                    $scope.ShowAsterix = payload.data.data.isShowAsterix;

                    var allUser = payload.data.data.AllUsers;
                    var savedCan;
                    if (window.localStorage.getItem("savedCandidates")) {
                      savedCan = JSON.parse(
                        window.localStorage.getItem("savedCandidates")
                      );
                    }
                    if (savedCan && savedCan.length > 0) {
                      allUser.map(function (item) {
                        savedCan.filter(function (val) {
                          if (item.name == val.name) {
                            item.isChecked = true;
                          }
                        });
                        return item;
                      });
                    }

                    var allkeys = Object.keys($scope.answeredQuestions);

                    var usersData = [];

                    // MyService.ConsoleLog("check now ", allkeys);
                    $scope.isShowAsterix = {};
                    if ($scope.frontRunners && $scope.frontRunners.length > 0) {
                      $scope.frontRunners.filter(function (itms) {
                        if (allkeys.indexOf(itms._id) > -1) {
                          $scope.isShowAsterix[itms._id] = true;
                        }
                      });
                    }

                    allUser.filter(function (itm) {
                      if (allkeys.indexOf(itm._id) > -1) {
                        if ($scope.isShowAsterix[itm._id]) {
                          // MyService.ConsoleLog("$scope.isShowAsterix", $scope.isShowAsterix[itm._id]);
                          itm.isOnBallot = true;
                        }
                        usersData.push(itm);
                      } else {
                        MyService.ConsoleLog("else >>> ");
                        itm.isOnBallot = false;
                        usersData.push(itm);
                      }
                    });

                    $scope.AllUsers = usersData.filter(function (val) {
                      $scope.poliList.filter(function (itm) {
                        if (val._id == itm._id) {
                          val.numberOfOpenings = itm.numberOfOpenings;
                          val.dataX = itm.dataX;
                          val.dataY = itm.dataY;
                          val.total_tagged = itm.total_tagged;
                          val.total_answers = itm.total_answers;
                        }
                      });
                      return val;
                    });
                    // setTimeout(function () {
                    MyService.ConsoleLog("$usersData", $scope.AllUsers);
                    $scope.allCandidates = [];
                    $scope.filterCandidatesOfficeWise();
                    // }, 4000);

                    // //$scope.qData = payload.data.data.answeredQuestions;
                  } else {
                    //alert("Looks like your friends have not yet answered the questions.");
                    MyService.ConsoleLog(
                      "No politicians found, check incumbent"
                    );
                    MyService.compareFiveMinAnswers({
                      userdata: JSON.stringify($rootScope.fiveMatch),
                      comparewith: JSON.stringify($scope.userAreaPolitician),
                      type: "incumbent",
                    }).then(
                      function (payload) {
                        if (payload.data.success) {
                          MyService.ConsoleLog(
                            "incumbent data :> ",
                            payload.data.data
                          );
                          $scope.questions = payload.data.data.qdata;
                          $scope.FBCompareList = payload.data.data.qdata;
                          if (
                            $scope.FBCompareList &&
                            $scope.FBCompareList.length > 0
                          ) {
                            $scope.FBunWeighted =
                              payload.data.data.unweightedMatch;
                            $scope.fbcombinedWeight =
                              payload.data.data.fbcombinedWeight;
                            $scope.fbmultiplier =
                              payload.data.data.fbmultiplier;
                            $scope.answeredQuestions =
                              payload.data.data.answeredQuestions;
                            $scope.fbqlen = payload.data.data.qlen;
                            $scope.AllUsers = payload.data.data.AllUsers;
                          } else {
                            MyService.ConsoleLog(
                              "No politicians and incumbent found, check voters"
                            );
                            window.localStorage.setItem(
                              "showOtherDetails",
                              true
                            );
                            $scope.hideName = true;
                            // Show only popup if no politicians and incubents
                            //     var message =
                            //       "No active candidates in your area have answered this set of questions yet. Contact them and ask them to fill it out.";
                            //     var modal = ngDialog.openConfirm({
                            //       template:
                            //         "<p>" +
                            //         message +
                            //         '</p>\
                            // <div class="ngdialog-buttons">\
                            // <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">See How You Match Your Community</button>\
                            // <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">Learn More About Your Candidates</button>\
                            //     \
                            // </div>',
                            //       plain: true,
                            //       width: "70%",
                            //       closeByDocument: false,
                            //     });

                            // modal.then(function fullfilled(data) {
                            //   //MyService.ConsoleLog("fullfilled: ", data);
                            //   if (data == 1) {
                            //     $scope.postonFacebook();
                            //   } else {
                            //     // if ($rootScope.userRole) {
                            //     //   $location.path('/snapshot');
                            //     // } else {
                            //     //   $location.path('/signin');
                            //     // }
                            //   }
                            // }, function rejected(data) {
                            //   //MyService.ConsoleLog("rejected: ", data);
                            // });
                            // modal.then(
                            //   function fullfilled(data) {
                            //     //MyService.ConsoleLog("fullfilled: ", data);
                            //     if (data == 1) {
                            //       $scope.getGroupMatch();
                            //     } else {
                            //       $location.path("/compare-select/politician");
                            //     }
                            //   },
                            //   function rejected(data) {
                            //     //MyService.ConsoleLog("rejected: ", data);
                            //   }
                            // );
                            return;
                          }
                        }
                      },
                      function (errorpayload) {
                        MyService.ConsoleLog(errorpayload);
                      }
                    );
                  }
                } else {
                  $scope.showAlert = true;
                  // alert("Something went wrong.");
                }
              },
              function (errorpayload) {
                $scope.loader = false;
                MyService.ConsoleLog(errorpayload);
              }
            );
          } else {
            $scope.loader = false;
            // alert("Please select at least one Friend to compare with");
            growl.error("Please answer at least one question to get the match");
          }
        }, 3000);
      };

      $scope.isShowBarAsso = function (key, isShowBarLink) {
        // MyService.ConsoleLog("check key >>", key);

        // MyService.ConsoleLog("isShowBarLink >> ", isShowBarLink);
        if (isShowBarLink == "false") {
          var isBar = key.match(/judge/g);
          if (isBar && isBar != "") {
            return true;
          } else {
            return false;
          }
        } else {
          var barLink;
          $scope.allCandidates.filter(function (item) {
            if (item.office == key) {
              if (
                $scope.barRatingData &&
                $scope.barRatingData[item._id] &&
                $scope.barRatingData[item._id].length > 0
              ) {
                barLink = $scope.barRatingData[item._id][0].generalLink;
                return item;
              }
            }
          });
          // MyService.ConsoleLog("barLink >> ", barLink);
          return barLink;
        }

        // MyService.ConsoleLog("isBar >> ", isBar);
      };

      $scope.goToPoliticianThread = function (officename, users, userData) {
        //console.log("goToPoliticianThread: ", users, " officename: ", officename, " userData: ", userData);
        $rootScope.politicianThreadData = {
          // office: officename,
          users: users,
          selected_user: userData,
        };
        window.localStorage.setItem(
          "politicianThreadData",
          JSON.stringify($rootScope.politicianThreadData)
        );
        $location.path("/politician-thread/" + userData._id);
      };

      $scope.getResultDateWise = function (val) {
        $scope.showBanner = false;
        MyService.ConsoleLog("quiz", $scope.quiz);
        window.localStorage.removeItem("showOtherDetails");
        window.localStorage.removeItem("compareResult");
        window.localStorage.removeItem("isShowOldResult");
        window.localStorage.setItem("eleDate", $scope.quiz.eDate.date);
        // if (val == "date") {
        if (
          !tokenService.getUserData() &&
          window.localStorage.getItem("extraData")
        ) {
          $rootScope.tempUserAddress = JSON.parse(
            window.localStorage.getItem("extraData")
          );
        }
        $scope.getLocalPoliticians($scope.quiz.eDate.date, $scope.quiz.party);

        // } else {

        //   $scope.allCandidates.filter(function (candi) {
        //     if (candi && candi.politicianInfo && candi.politicianInfo.length > 0) {
        //         if(candi.politicianInfo.party.indexOf($scope.quiz.party))
        //       }
        //   });
        //   // return;
        //   $scope.getLocalPoliticians(
        //     $scope.quiz.eDate.date,
        //     $scope.quiz.party.party
        //   );
        // }
      };
      $scope.finalSignIn = function () {
        $rootScope.endFiveMin = true;
        $location.path("/fiveminsignin");
      };

      // ***** Match ***** //
      $scope.traverseBackgrounds = [];
      $scope.currBackgroundIndex = 0;
      $scope.status = {
        questions: 0, //1=loading, 2=loaded, 3=error
        category: 0,
      };

      $scope.currentIndex = 0;

      var getCategoryCanceler = $q.defer();
      var prevFetchedCategoryId = null;

      $scope.getCategory = function (params) {
        try {
          prevFetchedCategoryId = $scope.category._id;
        } catch (e) {}
        MyService.ConsoleLog(
          "prevFetchedCategoryId != params.id ",
          prevFetchedCategoryId + "       " + params.id
        );
        if (prevFetchedCategoryId != params.id) {
          MyService.ConsoleLog("inside check it one");
          $routeParams.categoryId = params.id;
          $("html, body").animate(
            {
              scrollTop: 0,
            },
            "slow"
          );
          // $timeout(function() {
          //   $location.hash('DisplayQ');
          //   $anchorScroll();
          // }, 1500);
          getCategoryCanceler.resolve();
          getCategoryCanceler = $q.defer();
          $scope.status.category = 1;

          MyService.ConsoleLog("inside check it >>>>. two");

          MyService.getCategories(params, getCategoryCanceler.promise).then(
            function (response) {
              if (response) {
                if (response.data.success) {
                  $scope.category = response.data.data;
                  prevFetchedCategoryId = $scope.category._id;
                  $scope.status.category = 2;
                } else {
                  $scope.status.category = 3;
                }
              } else {
                $scope.status.category = 2;
              }
            },
            function (err) {
              //MyService.ConsoleLog("Error: ", err);
              $scope.status.category = 3;
              //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
            }
          );
        } else {
          $timeout(function () {
            // $location.hash("DisplayQ");
            $anchorScroll();
          }, 500);
        }
      };

      $scope.rootCatIndex = 0;
      $scope.getRootCategoryIndex = function () {
        if ($routeParams.categoryId == "all") {
          var len = $rootScope.categories.length;
          for (var i = 0; i < len; i++) {
            if ($routeParams.parentId == $rootScope.categories[i]._id) {
              $scope.rootCatIndex = i;
              //MyService.ConsoleLog("rootCatIndex : ", $scope.rootCatIndex);
              break;
            }
          }
        }
      };

      var getLevelCategoryCanceler = $q.defer();

      function getLevelCategory(params) {
        getLevelCategoryCanceler.resolve();
        $scope.status.levlCat = 1;
        params.timeout = getLevelCategoryCanceler.promise;
        var request = apiCall.apiCall("GET", "/categories/list-layer", params);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            if (response.data.success) {
              $scope.traverseBackgrounds = response.data.data;
              if ($routeParams.selectedCatId) {
                angular.forEach(response.data.data, function (val, key) {
                  if (val._id == $routeParams.selectedCatId) {
                    $scope.currBackgroundIndex = key;
                    $scope.category = val;
                  }
                });
              } else if ($scope.traverseBackgrounds.length > 0) {
                $scope.category = $scope.traverseBackgrounds[0];
              }
              $scope.status.levlCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.levlCat = 3;
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.status.levlCat = 3;
          }
        );
      }

      $scope.skipQuestion = function () {
        var skip = function () {
          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.currentIndex++;
            if (
              $routeParams.categoryId &&
              $routeParams.categoryId.indexOf("popular:") == 0
            ) {
              $scope.getCategory({
                id: $scope.questions[$scope.currentIndex].categories[0].cid,
              });
            }
          }
          if (
            tempIndex == $scope.questions.length ||
            $scope.questions.length == 0
          ) {
            var tempBackIdx = $scope.currBackgroundIndex + 1;
            if (tempBackIdx < $scope.traverseBackgrounds.length) {
              $scope.currBackgroundIndex++;
              // alert("if");
              $scope.category =
                $scope.traverseBackgrounds[$scope.currBackgroundIndex];
            } else {
              $scope.rootCatIndex++;
              // alert("else");
              window.localStorage.setItem("RootCatIndex", $scope.rootCatIndex);
              window.localStorage.setItem(
                "DisplayCategory",
                JSON.stringify($scope.category)
              );

              //$location.path("/compare-politician-questions");
            }
          }
          MyService.ConsoleLog(
            "yoffff",
            tempIndex + "           " + $scope.questions.length
          );
          if (tempIndex == $scope.questions.length) {
            $scope.showcomparison = false;
          }
          //$scope.currentIndex++;
        };

        skip();
      };

      /**
       * show pop up with old answers
       */
      $scope.getOldAnswers = function (val) {
        MyService.ConsoleLog("dava", val);
        $scope.oldAns = val;
        $scope.showPopUp = ngDialog.open({
          template: "showOldAnsPopUp",
          closeByNavigation: true,
          scope: $scope,
        });
      };

      $scope.showMacthOnPop = function (val, isType) {
        MyService.ConsoleLog("val > ", val);
        $scope.ofcName = val.office;
        MyService.ConsoleLog("val >> ", $scope.answeredQuestions[val._id]);
        $scope.uId = val._id;
        $scope.poliName = val.name;
        if (isType == "true") {
          $scope.isShowMatch = ngDialog.open({
            template: "showMatchDetails",
            closeByNavigation: true,
            scope: $scope,
            width: "50%",
          });
        } else {
          $scope.isShowPopUp = ngDialog.open({
            template: "showMatch",
            closeByNavigation: true,
            scope: $scope,
            width: "50%",
          });
        }
      };

      $scope.getInfo = function (val) {
        if (val && val.comment && val.comment != "") {
          $scope.showComment = $sce.trustAsHtml(val.comment);
          $scope.showPopUp = ngDialog.open({
            template: "showInfoPopUp",
            closeByNavigation: true,
            scope: $scope,
          });
        }
      };

      $scope.showNotice = function () {
        $scope.showNoticePopUp = ngDialog.open({
          template: "showNoticePop",
          closeByNavigation: true,
          scope: $scope,
        });
      };

      $scope.closeNotice = function () {
        $scope.showNoticePopUp.close();
      };

      $scope.prevQuestion = function () {
        //MyService.ConsoleLog("** prevQuestion **");
        // $timeout(function() {
        //   $location.hash('DisplayQ');
        //   $anchorScroll();
        // }, 1500);
        if ($scope.currentIndex > 0) {
          $scope.currentIndex--;
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category =
              $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            //MyService.ConsoleLog("** end of background **");
            if ($scope.rootCatIndex > 0) {
              $scope.rootCatIndex--;
              $location.path(
                "/question-compare-politician/all/" +
                  $rootScope.categories[$scope.rootCatIndex]._id
              );
            }
          }
        }
      };

      $scope.compareChanged = function (compare_with) {
        // MyService.ConsoleLog("compare_with :", compare_with);
        var catid = $routeParams.categoryId;
        if (
          $routeParams.categoryId &&
          $routeParams.categoryId.indexOf("popular:") == 0
        ) {
          var catid = $routeParams.categoryId.split("popular:")[1];
        }

        $location.path("/compare-select/" + compare_with);
      };

      $scope.getComapreClass = function (match, i) {
        if (match >= 100) return "ag-dg1";
        if (match >= 80) return "ag-dg2";
        if (match >= 60) return "ag-dg3";
        if (match >= 40) return "ag-dg4";
        if (match >= 20) return "ag-dg5";
        if (match >= 0) return "ag-dg6";

        return "ag-dg";
      };

      var getCompareAnswersCanceler = $q.defer();
      $scope.getCompareAnswers = function (params) {
        MyService.ConsoleLog("getCompareAnswers");
        MyService.ConsoleLog(params);
        /*
      if (!params) return;
      getCompareAnswersCanceler.resolve();

      getCompareAnswersCanceler = $q.defer();
      $scope.questions = [];
      $scope.status.questions = 1;

      MyService.getCompareAns(params, getCompareAnswersCanceler.promise)
        .then(function successCallback(response) {
          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            $scope.status.questions = 2;
            angular.forEach(response.data.data, function(val, key) {
              if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
                var check_author_exist = function(u) {
                  var len = val.answers.length;
                  for (var i = 0; i < len; i++) {
                    if (val.answers[i].author._id == u._id) {
                      return true;
                    }
                  }
                  return false;
                };

                angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                  if (!check_author_exist(u)) {
                    response.data.data[key].answers.push({ author: u });
                  }
                });
              } else if (!val.answers) {
                response.data.data[key].answers = [];
                angular.forEach($rootScope.selectedCompareUsers, function(u, i) {
                  response.data.data[key].answers.push({ author: u });
                });
              }
            });

            $scope.questions = response.data.data;
            // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
          } else {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
        }, function errorCallBack(response) {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Error: ", response);
        });
      */
      };

      var current_url = $location.url();
      //MyService.ConsoleLog("$location.url: ", current_url);

      $scope.state = "question-compare-politician";
      if (
        !$rootScope.selectedCompareUsers ||
        $rootScope.selectedCompareUsers.length <= 0
      ) {
        //$location.path("/questionHome");
      } else {
        if ($routeParams.categoryId == "all") {
          //MyService.ConsoleLog("compare all answers");
          var param = {
            id: $routeParams.parentId,
            questions_counter: 1,
          };
          getLevelCategory(param);
          $scope.getRootCategoryIndex();
        } // traverse all questions
        else {
          $scope.questions = $rootScope.compareAnswerList;
          // MyService.ConsoleLog($scope.questions);
          var catid = $routeParams.categoryId;
          // MyService.ConsoleLog(catid)
          $scope.getCategory({
            id: catid,
          });
        }
      }

      $scope.viewAnsComment = function (ans) {
        if (ans.comment) {
          ngDialog.open({
            template: "app/popups/show_comment_txt.html",
            controller: "OldAnswerViewerCtrl",
            data: {
              title: "Comment",
              content: ans.comment,
            },
          });
        }
      };

      $scope.retainOptions = "";
      $scope.fb = {
        options: "C",
      };

      $scope.postOnWall = function (message) {
        var body = message;
        // MyService.ConsoleLog($scope.fivefb);
        var link = "https://votewise.net/#!/fiveMatch#DisplayQ";
        FB.api(
          "/me/feed",
          "post",
          {
            message: body,
            link: link,
          },
          function (response) {
            // MyService.ConsoleLog(response);
            $scope.DisplayFPostLoader = false;
            if (!response || response.error) {
              var errmsg =
                "Facebook is taking too long to respond. Should we try again?";
              var modal = ngDialog.openConfirm({
                template:
                  "<p>" +
                  errmsg +
                  '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
                plain: true,
              });
              modal.then(
                function fullfilled(tdata) {
                  //MyService.ConsoleLog("fullfilled: ", data);
                  if (tdata == 1) {
                    $scope.postOnWall(message);
                  }
                },
                function rejected(data) {
                  //MyService.ConsoleLog("rejected: ", data);
                }
              );
            } else {
              var msg = "Message is successfully posted on facebook wall.";
              var modal = ngDialog.openConfirm({
                template:
                  "<p>" +
                  msg +
                  '</p>\
                            <div class="ngdialog-buttons">\
                                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">OK</button>\
                            </div>',
                plain: true,
              });
              modal.then(
                function fullfilled(data) {
                  //MyService.ConsoleLog("fullfilled: ", data);
                  // if (data == 1) {
                  // getMoreQuestions($scope.retainOptions);
                  // }
                  ngDialog.close();
                },
                function rejected(data) {
                  //MyService.ConsoleLog("rejected: ", data);
                  ngDialog.close();
                }
              );
              // alert('Post ID: ' + response.id);
            }
          }
        );
      };
      $scope.DisplayFPostLoader = false;
      $scope.postFB = function (message) {
        $scope.DisplayFPostLoader = true;
        FB.getLoginStatus(function (response) {
          if (response.status == "unknown") {
            FB.login(
              function (response) {
                MyService.updateFBID({
                  facebookid: response.authResponse.userID,
                }).then(
                  function (payload) {
                    if (
                      payload.data.error &&
                      payload.data.error == "FBDUPLICATE"
                    ) {
                      $scope.DisplayFPostLoader = false;
                      alert("Facebook user doesn't match with your account.");
                    } else {
                      $scope.postOnWall(message);
                    }
                  },
                  function (errpayload) {
                    $scope.DisplayFPostLoader = false;
                    var errmsg =
                      "Facebook is taking too long to respond. Should we try again?";
                    var modal = ngDialog.openConfirm({
                      template:
                        "<p>" +
                        errmsg +
                        '</p>\
                            <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                </div>',
                      plain: true,
                      width: "70%",
                    });
                    modal.then(
                      function fullfilled(tdata) {
                        if (tdata == 1) {
                          $scope.postOnWall(message);
                        }
                      },
                      function rejected(data) {
                        //MyService.ConsoleLog("rejected: ", data);
                      }
                    );
                    // alert("Facebook is taking too long to respond. Should we try again?");
                  }
                );
              },
              {
                scope: "public_profile,email,publish_actions",
                return_scopes: true,
              }
            );
          } else {
            if ($scope.userData && $scope.userData.facebookid) {
              if (response.authResponse.userID == $scope.userData.facebookid) {
                $scope.postOnWall(message);
              } else {
                $scope.DisplayFPostLoader = false;
                alert("Facebook user doesn't match with your account.");
              }
            } else {
              $scope.postOnWall(message);
            }
          }
        });
      };

      $scope.postonFacebook = function () {
        // $scope.fbFrdDlg = ngDialog.open({
        //   template: 'FBComposeMessage',
        //   scope: $scope
        // });
        $location.path("/showFbMessage");
      };

      // $scope.getFiveFacebookPost();

      $scope.$watch("category", function (newValue, oldValue) {
        MyService.ConsoleLog("** category watch **");
        var current_url = $location.url();
        if (current_url.indexOf("question-tagged") > -1) return; //for tagged questions don't again fetch questions

        if (newValue) {
          if (oldValue && oldValue._id == newValue._id) return;

          if (current_url.indexOf("question-compare-politician") > -1) {
            var temp_uids = [];
            angular.forEach(
              $rootScope.selectedCompareUsers,
              function (val, key) {
                temp_uids.push(val._id);
              }
            );
          }
        }
      });

      $scope.$watch("questions", function (newValue, oldValue) {
        MyService.ConsoleLog("** questions watch **");
        if (!$scope.questions) {
          $scope.questions = $rootScope.fiveminquestions;
        }
        // MyService.ConsoleLog("$scope.questions", $scope.questions);
        try {
          if ($scope.questions) {
            var tempCate = $scope.questions[$scope.currentIndex].categories;
            if (angular.isArray(tempCate)) {
              $scope.getCategory({
                id: tempCate[0].cid,
              });
            } else {
              $scope.getCategory({
                id: tempCate.cid,
              });
            }
          }
          // $scope.initMyAnswer();
        } catch (e) {
          MyService.ConsoleLog("questions Index Change Ex: ", e);
        }
      });

      $scope.$watch("currentIndex", function (newValue, oldValue) {
        MyService.ConsoleLog("** currentIndex watch **");
        // console.log("fiveminquestions >> ", $rootScope.fiveminquestions);
        if (!$scope.questions) {
          $scope.questions = $rootScope.fiveminquestions;
        }
        try {
          // MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
          if ($scope.questions) {
            var tempCate = $scope.questions[$scope.currentIndex].categories;
            if (angular.isArray(tempCate)) {
              $scope.getCategory({
                id: tempCate[0].cid,
              });
            } else {
              $scope.getCategory({
                id: tempCate.cid,
              });
            }
          }
          // $scope.initMyAnswer();
        } catch (e) {
          MyService.ConsoleLog("Current Index Change Ex: ", e);
        }
      });

      $scope.setOldRecord = function () {
        if (window.localStorage.getItem("compareResult")) {
          var data = JSON.parse(window.localStorage.getItem("compareResult"));

          if (data && data.qdata && data.qdata.length > 0) {
            $scope.questions = data.qdata.sort(function (x, y) {
              if (x.matchOrder < y.matchOrder) {
                return -1;
              }
              if (x.matchOrder > y.matchOrder) {
                return 1;
              }
              return 0;
            });
          }

          $scope.FBCompareList = data.qdata;

          if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
            $scope.FBunWeighted = data.unweightedMatch;
            $scope.fbcombinedWeight = data.fbcombinedWeight;
            $scope.fbmultiplier = data.fbmultiplier;
            $scope.answeredQuestions = data.answeredQuestions;
            $scope.fbqlen = data.qlen;
            $scope.confidenceLevel = data.confidenceLevel;
            $scope.essayAnswers = data.fiveMinEssayAns;
            $scope.sumOfImp = data.sumOfImp;
            $scope.percentageOfQuizUnanswered = data.percentageOfQuizUnanswered;
            $scope.barRatingData = data.barRatings;
            $scope.matchPercentage = data.matchPercentage;
            $scope.lowestPossibleMatch = data.lowestPossibleMatch;
            $scope.highestPossibleMatch = data.highestPossibleMatch;
            $scope.greatestPossibleNegative = data.greatestPossibleNegative;
            $scope.greatestPossiblePositive = data.greatestPossiblePositive;
            $scope.noOfQuesMutuallyAns = data.noOfQuesMutuallyAns;
            $scope.ShowAsterix = data.isShowAsterix;
            var allUser = data.AllUsers;
            var savedCan;
            if (window.localStorage.getItem("savedCandidates")) {
              savedCan = JSON.parse(
                window.localStorage.getItem("savedCandidates")
              );
            }
            if (savedCan && savedCan.length > 0) {
              allUser.map(function (item) {
                savedCan.filter(function (val) {
                  if (item.name == val.name) {
                    item.isChecked = true;
                  }
                });
                return item;
              });
            }

            var allkeys = Object.keys($scope.answeredQuestions);

            var usersData = [];

            // MyService.ConsoleLog("check now ", allkeys);
            $scope.isShowAsterix = {};
            if ($scope.frontRunners && $scope.frontRunners.length > 0) {
              $scope.frontRunners.filter(function (itms) {
                if (allkeys.indexOf(itms._id) > -1) {
                  $scope.isShowAsterix[itms._id] = true;
                }
              });
            }

            allUser.filter(function (itm) {
              if (allkeys.indexOf(itm._id) > -1) {
                if ($scope.isShowAsterix[itm._id]) {
                  // MyService.ConsoleLog("$scope.isShowAsterix", $scope.isShowAsterix[itm._id]);
                  itm.isOnBallot = true;
                }
                usersData.push(itm);
              } else {
                MyService.ConsoleLog("else >>> ");
                itm.isOnBallot = false;
                usersData.push(itm);
              }
            });

            $scope.AllUsers = usersData;
            $scope.poliList = JSON.parse(
              window.localStorage.getItem("poliList")
            );
            $scope.AllUsers = data.AllUsers.filter(function (val) {
              if ($scope.poliList && $scope.poliList.length > 0) {
                $scope.poliList.filter(function (itm) {
                  if (val._id == itm._id) {
                    val.numberOfOpenings = itm.numberOfOpenings;
                    val.dataX = itm.dataX;
                    val.dataY = itm.dataY;
                    val.total_tagged = itm.total_tagged;
                    val.total_answers = itm.total_answers;
                  }
                });
              }

              return val;
            });
            // setTimeout(function () {
            $scope.parties = JSON.parse(window.localStorage.getItem("parties"));
            MyService.ConsoleLog("$usersData", usersData);

            $scope.allCandidates = [];
            $scope.filterCandidatesOfficeWise();
          } else {
            var savedCan;
            if (window.localStorage.getItem("savedCandidates")) {
              savedCan = JSON.parse(
                window.localStorage.getItem("savedCandidates")
              );
            }
            console.log("data >> ", data.AllUsers);
            if (data.barRatings) {
              $scope.barRatingData = data.barRatings;
            }
            $scope.poliList = JSON.parse(
              window.localStorage.getItem("poliList")
            );

            $scope.AllUsers = data.AllUsers.filter(function (val) {
              if (val && val._id != "") {
                $scope.poliList.filter(function (itm) {
                  if (val._id == itm._id) {
                    val.numberOfOpenings = itm.numberOfOpenings;
                    val.dataX = itm.dataX;
                    val.dataY = itm.dataY;
                    val.total_tagged = itm.total_tagged;
                    val.total_answers = itm.total_answers;
                  }
                });
                if (savedCan && savedCan.length > 0) {
                  savedCan.filter(function (scan) {
                    if (scan.name == val.name) {
                      val.isChecked = true;
                    }
                  });
                  return val;
                } else {
                  return val;
                }
              }
            });
            $scope.parties = JSON.parse(window.localStorage.getItem("parties"));
            $scope.allCandidates = [];
            $scope.filterCandidatesOfficeWise();
          }
        }
        // window.localStorage.removeItem("poliList");
        window.localStorage.removeItem("isShowOldResult");
      };

      $scope.checkIfDatax = function (key, val) {
        // MyService.ConsoleLog("key >> ", key);
        // MyService.ConsoleLog("val >> ", val);
        // var terms = val.filter(function (item) {
        var vlen = val.length;
        var arr;
        for (var j = 0; j < vlen; j++) {
          var item = val[j];
          if (item.politicianInfo && item.politicianInfo.length > 0) {
            var len = item.politicianInfo.length;

            for (var i = 0; i < len; i++) {
              // MyService.ConsoleLog(
              //   " item.politicianInfo[i]  >>",
              //   item.politicianInfo[i]
              // );
              if (
                item.politicianInfo[i] &&
                item.dataX &&
                item.dataX != "" &&
                item.dataX != null
              ) {
                if (item.dataX && key == item.politicianInfo[i].officeName) {
                  arr = item.dataX;
                  return arr;
                  // break;
                }
              }
            }
          }
        }

        // break;
        // });

        // MyService.ConsoleLog("terms >> ", arr);
      };

      $scope.showNumberOfOpening = function (key, val) {
        var valLen = val.length;
        var noOfOp;
        for (var i = 0; i < valLen; i++) {
          if (key == val[i].office) {
            noOfOp = val[i].numberOfOpenings;
            return noOfOp;
          }
        }
      };

      // $scope.electionDates = [];
      $scope.getElectionDates = function () {
        MyService.getListOfDates().then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            if (APIResponse.data.success) {
              var datesArr = [];
              var mainArr = APIResponse.data.data.map(function (item, index) {
                var date = new Date(item).toGMTString();

                var par = {
                  _id: index,
                  eDate: date.substr(5, 12),
                  date: item,
                };
                datesArr.push(par);
              });
              MyService.ConsoleLog("datesArr >> ", datesArr);
              $scope.electionDates = datesArr;

              // const today = new Date();
              // const closestDate = datesArr.reduce(function (a, b) {
              //   if (a != null && b != null) {
              //     const adiff = a.date - today;
              //     return adiff > 0 && adiff < b.date - today ? a : b;
              //   }
              // });

              // $scope.electionDates.sort(function (a, b) {
              //   if (a != null && b != null) {
              //     return new Date(b.date) - new Date(a.date);
              //   }
              // });
              // function sortFunction(a, b) {
              //   var dateA = new Date(a.date).getTime();
              //   var dateB = new Date(b.date).getTime();
              //   return dateA < dateB ? 1 : -1;
              // }

              // var sortedDate = datesArr.sort(sortFunction);
              MyService.ConsoleLog(
                " $scope.electionDates >> ",
                $scope.electionDates[0]
              );
              // return;
              $scope.quiz.eDate = $scope.electionDates[0];
              window.localStorage.setItem("eleDate", $scope.quiz.eDate.date);
              MyService.ConsoleLog("$scope.quiz >> ", $scope.quiz);
              MyService.ConsoleLog(
                "$scope.electionDates >> ",
                $scope.electionDates
              );
              if (window.localStorage.getItem("isShowOldResult") == "true") {
                $scope.setOldRecord();
              } else {
                $scope.getLocalPoliticians($scope.quiz.eDate.date);
              }
            } else {
              if (window.localStorage.getItem("isShowOldResult") == "true") {
                $scope.setOldRecord();
              } else {
                $scope.getLocalPoliticians($scope.quiz.eDate.date);
              }
            }
          },
          function (err) {
            MyService.ConsoleLog("error", err);
          }
        );
      };
      $scope.getElectionDates();

      $scope.showAnswerDetails = function (off) {
        MyService.ConsoleLog("check office >> ", off);
        $scope.loader = true;
        var filData = $scope.allCandidates.filter(function (val) {
          if (val.office === off) {
            return val;
          }
        });
        MyService.ConsoleLog("filData >> ", filData);
        var params = {
          candidates: filData,
          essayAnswers: $scope.essayAnswers,
        };

        window.localStorage.setItem("compareAnsData", JSON.stringify(params));
        $scope.loader = false;
        $location.path("/compareEssayAns");

        // MyService.ConsoleLog("params >> ", params);
      };

      $scope.isAnsExist = function (office, val) {
        var aData = val.filter(function (item) {
          if (item.office == office) {
            if (
              $scope.answeredQuestions &&
              $scope.answeredQuestions[item._id] &&
              $scope.answeredQuestions[item._id] != ""
            ) {
              return item;
            }
          }
        });
        if (aData && aData.length > 0) {
          return true;
        } else {
          return false;
        }
      };

      $scope.isEssayAnsExist = function (office, val) {
        var isTrue;
        var data = val.filter(function (item) {
          if (item.office == office) {
            if (
              $scope.essayAnswers &&
              $scope.essayAnswers[item._id] &&
              $scope.essayAnswers[item._id].length > 0
            ) {
              return item;
            }
          }
        });
        if (data && data.length > 0) {
          return true;
        } else {
          return false;
        }
      };
    },
  ]);
