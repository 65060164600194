'use strict';
angular.module('myApp.trackUserActivities', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/trackUserActivities', {
        templateUrl: 'app/trackUserActivities/trackUserActivities.html',
        controller: 'trackUserActivitiesCtrl'
      });
    }
  ])
  .controller('trackUserActivitiesCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$routeParams',
    '$q',
    "growl",
    function ($scope, ngDialog, $rootScope, MyService, $routeParams, $q, growl) {

      $scope.User = {
        from: "",
        to: "",
        state: "",
        type: ""
      };
      $scope.activeLastLogin = true;
      $scope.activeLogged = true;
      $scope.currPage = 0;
      /**
       * Get count of register users from last 30 Days
       */
      // $scope.getRegisterUser = function () {
      //   MyService.getRegisterUsersFrom().then(function (results) {
      //     MyService.ConsoleLog("results", results.data.data);
      //     $scope.resgisterCount = results.data.data;
      //   }, function (err) {
      //     MyService.ConsoleLog("Error", err);
      //   });
      // };
      $scope.allUSAPol = {
        "statePostalCode": "All USA",
        "state": "All USA"
      };
      $scope.allState = [];


      $scope.pageFunction = function () {
        MyService.ConsoleLog("$scope.countData", $scope.countData + "   > " + $scope.mainData.length);
        if ($scope.statusGetResPg != 1 && $scope.countData > $scope.mainData.length) {
          // MyService.ConsoleLog("length");
          $scope.currPage++;
          $scope.changedPage($scope.currPage);
        }
      };

      $scope.changedPage = function (newPage) {
        $scope.searchRecords(newPage);
      };
      // $scope.getRegisterUser();
      $scope.mainData = [];
      $scope.tmpArr = [];
      /**
       * Get last activities of user
       * @params user type
       * @params Date and time
       */
      $scope.selectStatus = function (params, canceller) {
        $scope.activeUsers = true;
        $scope.activeLastLogin = true;
        $scope.showAns = true;
        $scope.resgisterCount = "";
        // MyService.ConsoleLog("Date", $scope.User.from.toISOString().split("T")[0]);
        // MyService.ConsoleLog("Type", $scope.User.type);
        // MyService.ConsoleLog("State", $scope.User.state);

        MyService.getRegisterUsersFrom(params, canceller).then(function (results) {
          MyService.ConsoleLog("results", results);
          if (results.data.data.register && results.data.data.register.length > 0 || results.data.data.notLoggedIn && results.data.data.notLoggedIn.length > 0) {
            // MyService.ConsoleLog('$scope.resgisterCount >', results.data.data.register);
            $scope.tmpArr = $scope.tmpArr.concat(results.data.data.register);
            $scope.mainData = $scope.mainData.concat(results.data.data.register);
            // MyService.ConsoleLog('$scope.resgisterCount >', $scope.tmpArr);
            $scope.countData = results.data.data.totalRecords;
            $scope.statusGetResPg = 2;
            // $scope.lastLogin = results.data.data.lastLogin;
            $scope.questionAnswered = results.data.data.questionsAnswered;
            $scope.notLoggedIn = results.data.data.notLoggedIn;


          } else {
            $scope.resgisterCount = "";
            $scope.notLoggedIn = "";
            $scope.statusGetResPg = 3;
            growl.error("Data Not Found");
          }

          // MyService.ConsoleLog(" $scope.lastLogin", $scope.lastLogin);
          // MyService.ConsoleLog(" $scope.resgisterCount", $scope.resgisterCount)
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });

      };


      $scope.searchRecords = function (pageNumber, val) {
        window.localStorage.removeItem('searchPara');
        if (val && val != '') {
          $scope.mainData = [];
          $scope.tmpArr = [];
        }
        if ($scope.User.from && $scope.User.from != "" || $scope.User.to && $scope.User.to != "" || $scope.User.type && $scope.User.type != "" || $scope.User.state && $scope.User.state != "" || $scope.User.checkUsers && $scope.User.checkUsers != "") {
          var fromDate;
          if ($scope.User.from && $scope.User.from != "") {
            var month = ("0" + ($scope.User.from.getMonth() + 1)).slice(-2);
            var dat = ("0" + $scope.User.from.getDate()).slice(-2);
            fromDate = $scope.User.from.getFullYear() + "-" + month + "-" + dat;
            MyService.ConsoleLog("fromDate >>", fromDate);
          }
          var toDate;
          if ($scope.User.to && $scope.User.to != "") {
            var smonth = ("0" + ($scope.User.to.getMonth() + 1)).slice(-2);
            var sdat = ("0" + $scope.User.to.getDate()).slice(-2);
            toDate = $scope.User.to.getFullYear() + "-" + smonth + "-" + sdat;
            MyService.ConsoleLog("toDate >>", toDate);
          }
          var checkLast;
          if ($scope.User.checkUsers && $scope.User.checkUsers != "") {
            var xsmonth = ("0" + ($scope.User.checkUsers.getMonth() + 1)).slice(-2);
            var xsdat = ("0" + $scope.User.checkUsers.getDate()).slice(-2);
            checkLast = $scope.User.checkUsers.getFullYear() + "-" + xsmonth + "-" + xsdat;
            MyService.ConsoleLog("checkLast >>", checkLast);
          }
          $scope.searchObj = {
            page: pageNumber,
            max_records: 30,
            type: $scope.User.type,
            from: fromDate,
            to: toDate,
            statePostalCode: $scope.User.state.statePostalCode,
            checkLog: checkLast
          };
          window.localStorage.setItem('searchPara', JSON.stringify($scope.searchObj));
          $scope.statusGetResPg = 1;

          $scope.getAllCanceller = $q.defer();
          $scope.selectStatus($scope.searchObj, $scope.getAllCanceller);
        } else {
          growl.error("Please Select at Least One Dropdown List");
        }
      };

      $scope.getComapreClass = function (match, i) {

        if (match >= 1) return 'ag-dg1';
        if (match >= 2) return 'ag-dg2';
        if (match >= 3) return 'ag-dg3';
        if (match >= 4) return 'ag-dg4';
        if (match >= 5) return 'ag-dg5';
        if (match >= 6) return 'ag-dg6';

        return 'ag-dg';
      };

      $scope.getItem = function () {
        return function (u) {
          if (typeof u.email == 'string') {

            u.email = u.email;
            return u;
          } else {
            var email = '';
            u.email.forEach(function (element, index) {
              if (element) {
                email += element + ', ';
                var n = email.lastIndexOf(",");
                var a = email.substring(0, n)
                // MyService.ConsoleLog("Element", a);
                u.email = a;
              }
            });
            return u;
          }
        };
      };

      $scope.getAllStates = function () {
        $scope.allState.length = 0;
        $scope.loader = true;
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            // console.log("payload : ", payload);
            $scope.allState = payload;
            $scope.allState.unshift($scope.allUSAPol);
            $scope.loader = false;
            // var
            // alert(window.localStorage.getItem('isShowPreviousResult'))
            if (window.localStorage.getItem('isShowPreviousResult') != 'false') {
              if (window.localStorage.getItem('searchPara')) {
                var params = JSON.parse(window.localStorage.getItem('searchPara'));
                MyService.ConsoleLog("params", params);
                $scope.statusGetResPg = 1;
                $scope.getAllCanceller = $q.defer();
                window.localStorage.setItem('isShowPreviousResult', false)
                $scope.selectStatus(params, $scope.getAllCanceller);
              }
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.loader = false;
          }
        );
      };

      $scope.getAllStates();

    }
  ]);
