'use strict';
angular.module('myApp.allEndorsements', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/allEndorsements', {
      templateUrl: 'app/allEndorsements/allEndorsements.html',
      controller: 'allEndorsementsCtrl'
    });
    $routeProvider.when('/allEndorsements/:id/:msg?/:type?', {
      templateUrl: 'app/allEndorsements/allEndorsements.html',
      controller: 'allEndorsementsCtrl'
    });
  }])
  .controller('allEndorsementsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'ngDialog', '$cookies', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, ngDialog, $cookies) {

    $scope.selectedPolitician = $routeParams.id;
    $scope.allEndorsements = [];
    $scope.endorsementUp = null;
    $scope.endorsementDown = null;
    $scope.nameUp = null;
    $scope.nameDown = null;
    $scope.dateUp = null;
    $scope.dateDown = null;
    $scope.type = false;
    $scope.loader = false;
    $scope.$on('$viewContentLoaded', function () {
      $scope.getData();
    });
    $scope.userId = tokenService.getUserId();
    $scope.search = {
      name: '',
      rangeMin: 0,
      rangeMax: ''
    };
    $scope.success_msg = false;
    if ($routeParams.msg && $routeParams.msg == "true") {
      $scope.success_msg = true;
      setTimeout(function () {
        $(".alert").alert('close');
        // $scope.getAllCongressman();
      }, 2000);
    }

    if ($routeParams.type && $routeParams.type != "") {
      $scope.type = $routeParams.type;
    }
    $scope.getNotes = function (val) {
      $scope.showNote = val;
      $scope.MyDialog = ngDialog.open({
        template: 'viewNotes',
        scope: $scope
      });
    };

    MyService.ConsoleLog("tokenService.getUserId()", tokenService.getUserId());
    $scope.sortSearch = function (val) {
      var sortOrder = "";
      MyService.ConsoleLog(val);
      if (val == 'name') {
        MyService.ConsoleLog("nameUp > ", $scope.nameUp);
        if ($scope.nameUp == "" || $scope.nameUp == "DESC") {
          sortOrder = "ASC";
          $scope.nameUp = true;
          $scope.nameDown = false;
          $scope.allEndorsements.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
        } else {
          sortOrder = "DESC";
          $scope.nameDown = true;
          $scope.nameUp = false;
          $scope.allEndorsements.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
        }


      } else {
        $scope.nameUp = false;
        $scope.nameDown = false;
      }
      if (val == 'endorsement') {
        if ($scope.ratingUp == "" || $scope.ratingUp == "DESC") {
          sortOrder = "ASC";
          $scope.ratingUp = true;
          $scope.ratingDown = false;

          $scope.allEndorsements.sort(function (a, b) {
            return a.match - b.match;

          });
        } else {
          sortOrder = "DESC";
          $scope.ratingDown = true;
          $scope.ratingUp = false;

          $scope.allEndorsements.sort(function (a, b) {
            return b.match - a.match;
          });
        }
      } else {
        $scope.ratingUp = false;
        $scope.ratingDown = false;
      }
      if (val == 'date') {
        if ($scope.dateUp == "" || $scope.dateUp == "DESC") {
          sortOrder = "ASC";
          $scope.dateUp = true;
          $scope.dateDown = false;
        } else {
          sortOrder = "DESC";
          $scope.dateDown = true;
          $scope.dateUp = false;
        }
      } else {
        $scope.dateUp = false;
        $scope.dateDown = false;
      }

      MyService.ConsoleLog("sortField : ", val);
      MyService.ConsoleLog("sortOrder : ", sortOrder);
      /*
      MyService.getReviewSearchResult({"id": $routeParams.id, "type": $scope.type, "sortField":val, "sortOrder": sortOrder}).then(function(response){
          MyService.ConsoleLog("response : ",response);
          if (response.data.success) {
          $scope.allEndorsements = response.data.data;
        }
      });
      */

    }

    $scope.getData = function () {
      $scope.statusGetData = 1;
      $scope.loader = true;

      //getting name of politician
      MyService.getUserPublicProfile({
        id: $routeParams.id,
        type: $scope.type
      }).then(function (response) {
        if (response.data.success) {

          $scope.username = response.data.data.userData.name;
          MyService.ConsoleLog(" username : ", $scope.username);
        }
      }, function (errorPayload) {
        $scope.statusGetData = 3;
      });

      // getting user reviews
      MyService.ConsoleLog($routeParams.id + "====" + $scope.type);
      MyService.getEndorsementsByUserId({
        id: $routeParams.id,
        type: $scope.type,
        curId: tokenService.getUserId()
      }).then(function (response) {
        MyService.ConsoleLog("response > ", response);
        if (response.data.success) {
          $scope.allEndorsements = response.data.data;
          MyService.ConsoleLog(" $scope.allEndorsements : ", $scope.allEndorsements);
          /**
           * @description Sort records and show best match first.
           */
          $scope.allEndorsements.sort(function (a, b) {
            return b.match - a.match;
          });

          $scope.loader = false;


        }
      }, function (errorPayload) {
        $scope.statusGetData = 3;
      });
    };

    /**
     * @description Function use to redirect previous page.
     */

    $scope.BackToPrev = function () {
      if ($cookies.get('elecDateForRate')) {
        $location.path('/compare-select/politician');
      } else {
        $location.path('/incumbent_politician/politician');
      }
    };

    /**
     * End of BackToPrev
     */

  }]);
