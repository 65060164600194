"use strict";
angular
  .module("myApp.compareSelect", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/compare-select/:user_type", {
        templateUrl: "app/compare_select/compare_select.html",
        controller: "compareSelectCtrl",
      });
    },
  ])
  .controller("compareSelectCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$filter",
    "MyService",
    "ngDialog",
    "growl",
    "tokenService",
    "$sce",
    "$cookies",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $filter,
      MyService,
      ngDialog,
      growl,
      tokenService,
      $sce,
      $cookies
    ) {
      //console.log("** compareSelectCtrl **",$routeParams);
      $scope.userId = tokenService.getUserId();
      $scope.usertype = $routeParams.user_type;
      $rootScope.welcome = false;

      $scope.Address = false;
      $scope.topCandidates = [];
      $scope.Zip = true;
      $scope.Link = true;
      $scope.showAlert = false;
      // $scope.showWar = false;
      $scope.showLoader = false;
      $scope.User = {
        Address: "",
      };
      if (tokenService.getUserData()) {
        $scope.addressNw = JSON.parse(tokenService.getUserData());
        // MyService.ConsoleLog("this is add", $scope.addressNw.address);
      }

      if ($scope.userId && $scope.userId != "") {
        $rootScope.tempUserAddress = "";
        $rootScope.incuAdd = "";
      }
      $scope.comp = {
        election: {
          electionDate: "",
        },
        // "party": "Select Party"
      };
      if ($cookies.get("elecDateForRate")) {
        MyService.ConsoleLog(
          "chck for ele date",
          $cookies.get("elecDateForRate")
        );
        // $scope.comp.election.electionDate = $cookies.get('elecDateForRate');
      }

      if ($cookies.get("electionDate")) {
        MyService.ConsoleLog(
          "electionDate in co",
          $cookies.get("electionDate")
        );
        $scope.comp.election.electionDate = $cookies.get("electionDate");
        MyService.ConsoleLog("models", $scope.comp);
      }
      $scope.matchQuestion = function () {
        $location.path("/question-not-answered");
      };
      var loc = $rootScope.location;
      // console.log("location", loc);

      // console.log("longitude", loc.longitude);

      function getCategories(params) {
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
        });
        $http(request).then(
          function successCallback(response) {
            //console.log("Got categories: ",response);
            if (response.data.success) {
              $scope.categories = response.data.data;
            } else {
              //console.log("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //console.log("Error: ", response);
          }
        );
      }

      //getCategories();

      $scope.setAllSelected = function (users) {
        angular.forEach(users, function (val, key) {
          if (val.registeredUser)
            // MyService.ConsoleLog(" val.isSelected", val.isSelected);
            val.isSelected = true;
          $scope.selectedUser.push(val);
        });
      };
      var loc = [];
      var eLoc = [];

      $scope.checkParty = function (tflag, udata, type) {
        // $scope.searchUsersByType.length = 0;
        // console.log("searchUsersByType >>> ", type);

        //// MyService.ConsoleLog("Call checkParty", udata);

        if (udata.politicianInfo) {
          if (udata.politicianInfo.length > 0) {
            // MyService.ConsoleLog("IF", udata);
            var pInLen = udata.politicianInfo.length;
            //// MyService.ConsoleLog("pInLEn >", pInLen);
            // $scope.searchUsersByType.length = 0;
            for (var s = 0; s < pInLen; s++) {
              udata = JSON.parse(JSON.stringify(udata));
              var polivar = udata.politicianInfo[s];

              if (polivar.runningForOffice == "Y") {
                if ($scope.comp.election.electionDate == polivar.electionYear) {
                  // MyService.ConsoleLog("nsnsnsn", type + "   " + polivar.levelOfGov);
                  // MyService.ConsoleLog("election date", polivar.electionYear);
                  if (!tflag) {
                    // MyService.ConsoleLog("nsnsnsn", polivar.levelOfGov);
                    switch (polivar.levelOfGov) {
                      case "placeName":
                        $scope.showlocal = true;
                        $scope.sortype = "local";
                        type = "local";
                        if (!type) type = "local";
                        break;
                      case "countySubdivision":
                        $scope.sortype = "local";
                        if (!type) type = "local";
                        $scope.showlocal = true;
                        break;
                      case "unifiedSchoolDistrictName":
                        $scope.showlocal = true;
                        // $scope.sortype = "local";
                        if (!type) type = "local";
                        break;
                      case "All USA":
                      case "all USA":
                        //MyService.ConsoleLog("$scope.searchUsers[i].office >", $scope.searchUsers[i].office);
                        var ncoff = polivar.officeName;
                        if (ncoff.toLowerCase() != "president") {
                          // $scope.sortype = "other";
                          if (!type) type = "other";
                          $scope.showother = true;
                        } else {
                          // $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        }
                        break;
                      case "countyName":
                        $scope.showcounty = true;
                        $scope.sortype = "county";
                        if (!type) {
                          type = "county";
                        } else if (type == "local") {
                          type = "local";
                        }
                        break;
                      case "statePostalCode":
                        var ncoff = udata.office;
                        if (ncoff && ncoff != "") {
                          // MyService.ConsoleLog("ncoff.toLowerCase()", ncoff.toLowerCase());
                          if (ncoff.toLowerCase() != "u.s. senate") {
                            // $scope.sortype = "other";
                            $scope.showstate = true;
                            $scope.sortype = "state";
                            if (!type) type = "state";
                          } else if (ncoff.toLowerCase() != "president") {
                            // MyService.ConsoleLog("ncoff.toLowerCase() nanan", ncoff.toLowerCase());
                            $scope.sortype = "federal";
                            if (!type) type = "federal";
                            $scope.showfederal = true;
                            $scope.showother = false;
                          } else {
                            // MyService.ConsoleLog("ncoff.toLowerCase() nanan", ncoff.toLowerCase());
                            $scope.sortype = "federal";
                            if (!type) type = "federal";
                            $scope.showfederal = true;
                            $scope.showother = false;
                          }
                        } else {
                          MyService.ConsoleLog("ncoff", ncoff);
                          MyService.ConsoleLog("udata", udata);
                        }
                        break;
                      case "stateSenateDistrict":
                        var ncoff = udata.office;
                        if (ncoff && ncoff != "") {
                          if (ncoff.toLowerCase() != "president") {
                            // $scope.sortype = "other";
                            $scope.showstate = true;
                            $scope.sortype = "state";
                            if (!type) type = "state";
                          } else {
                            $scope.sortype = "federal";
                            if (!type) type = "federal";
                            $scope.showfederal = true;
                            $scope.showother = false;
                          }
                        }
                        break;
                      case "stateHouseofRepresentativesDistrict":
                        var ncoff = udata.office;
                        if (ncoff.toLowerCase() != "president") {
                          // $scope.sortype = "other";
                          $scope.showstate = true;
                          $scope.sortype = "state";
                          if (!type) type = "state";
                        } else {
                          $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        }
                        break;
                      case "federalCongressionalDistrict":
                        // $scope.sortype = "federal";
                        // console.log("type", type);
                        if (!type) type = "federal";
                        $scope.showfederal = true;
                        break;
                      default:
                        break;
                    }
                  } else {
                    // MyService.ConsoleLog("$scope.searchUsers[i].voterScope >>", $scope.searchUsers[i]);
                    // $scope.changeTab($scope.searchUsers[i].voterScope);
                    switch (polivar.levelOfGov) {
                      case "placeName":
                        // $scope.sortype = "local";
                        if (!type) type = "local";
                        $scope.showlocal = true;
                        break;
                      case "countySubdivision":
                        // $scope.sortype = "local";
                        if (!type) type = "local";
                        $scope.showlocal = true;
                        break;
                      case "unifiedSchoolDistrictName":
                        $scope.showlocal = true;
                        break;
                      case "All USA":
                      case "all USA":
                        //MyService.ConsoleLog("$scope.searchUsers[i].office >", $scope.searchUsers[i].office);
                        var ncoff = polivar.officeName;

                        if (ncoff.toLowerCase() != "president") {
                          // $scope.sortype = "other";
                          if (!type) type = "other";
                          $scope.showother = true;
                        } else {
                          // $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        }
                        break;
                      case "countyName":
                        $scope.sortype = "county";
                        if (!type) type = "county";
                        $scope.showcounty = true;
                        break;
                      case "statePostalCode":
                        // $scope.sortype = "state";
                        var ncoff = udata.office;
                        if (ncoff.toLowerCase() != "u.s. senate") {
                          // $scope.sortype = "other";
                          $scope.showstate = true;
                          $scope.sortype = "state";
                          if (!type) type = "state";
                        } else if (ncoff.toLowerCase() != "president") {
                          $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        } else {
                          $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        }
                        break;
                      case "stateSenateDistrict":
                        var ncoff = udata.office;
                        if (ncoff && ncoff != "") {
                          if (ncoff.toLowerCase() != "president") {
                            // $scope.sortype = "other";
                            $scope.showstate = true;
                            $scope.sortype = "state";
                            if (!type) type = "state";
                          } else {
                            $scope.sortype = "federal";
                            if (!type) type = "federal";
                            $scope.showfederal = true;
                            $scope.showother = false;
                          }
                        }
                        break;
                      case "stateHouseofRepresentativesDistrict":
                        var ncoff = udata.office;
                        if (ncoff.toLowerCase() != "president") {
                          // $scope.sortype = "other";
                          $scope.showstate = true;
                          $scope.sortype = "state";
                          if (!type) type = "state";
                        } else {
                          $scope.sortype = "federal";
                          if (!type) type = "federal";
                          $scope.showfederal = true;
                          $scope.showother = false;
                        }
                        break;
                      case "federalCongressionalDistrict":
                        $scope.showfederal = true;
                        if (!type) type = "federal";
                        break;
                      default:
                        break;
                    }
                  }
                  // MyService.ConsoleLog(" if type for voterscope", type);
                  // MyService.ConsoleLog(" if sort type for voterscope", $scope.sortype);
                }

                if (polivar.levelOfGov) {
                  // $scope.searchUsersByType.length = 0;

                  if (
                    type == "local" &&
                    (polivar.levelOfGov == "placeName" ||
                      polivar.levelOfGov == "countySubDivisionName" ||
                      polivar.levelOfGov == "unifiedSchoolDistrictName" ||
                      polivar.levelOfGov == "elementarySchoolDistrictName" ||
                      polivar.levelOfGov == "secondarySchoolDistrictName" ||
                      polivar.levelOfGov == "cityCouncilDistrictName" ||
                      polivar.levelOfGov == "countySubdivision") &&
                    polivar.candidateStatus != "inactive"
                  ) {
                    // if (type == 'local') {
                    //   $scope.searchUsersByType.length = 0;
                    //   $scope.sortype = "local";
                    // }
                    // MyService.ConsoleLog("local", polivar.levelOfGov);
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      udata.party = "";
                      var newOff = polivar.officeName.toLowerCase();
                      udata.office = newOff;
                      udata.candidateStatus = polivar.candidateStatus;
                      // udata.party = polivar.party;
                    }
                    if (typeof polivar.party == "string") {
                      // alert('one');
                      polivar.party = polivar.party;
                    } else {
                      var party = "";
                      polivar.party.forEach(function (element, index) {
                        if (element) {
                          party += element + ", ";
                          var n = party.lastIndexOf(",");
                          var a = party.substring(0, n);
                          // MyService.ConsoleLog("Element", a);
                          udata.party = a;
                        }
                      });
                    }

                    // loc.push(udata)
                    // $scope.searchUsersByType['local'] = loc;
                    // $scope.searchUsersByType.push(udata);
                    // if ($scope.comp.party && $scope.comp.party != "") {
                    //   if ($scope.comp.party == udata.politicianInfo[s].party) {
                    //     $scope.searchUsersByType.push(udata);

                    //   }
                    // } else {
                    //   $scope.searchUsersByType.push(udata);
                    // }
                    if (
                      polivar &&
                      polivar.officeName &&
                      polivar.officeName.toLowerCase() != "president"
                    ) {
                      if (typeof polivar.party == "string") {
                        if ($scope.comp.party && $scope.comp.party != "") {
                          if (
                            $scope.comp.party == udata.politicianInfo[s].party
                          ) {
                            $scope.searchUsersByType.push(udata);
                          }
                        } else {
                          $scope.searchUsersByType.push(udata);
                        }
                      } else {
                        if ($scope.comp.party && $scope.comp.party != "") {
                          if (
                            udata.politicianInfo[s].party.indexOf(
                              $scope.comp.party
                            ) != -1
                          ) {
                            $scope.searchUsersByType.push(udata);
                          }
                        } else {
                          $scope.searchUsersByType.push(udata);
                        }
                      }
                    }
                    // MyService.ConsoleLog("searchUsersByType local>>> ", $scope.searchUsersByType);
                  } else if (
                    type == "county" &&
                    (polivar.levelOfGov == "countyName" ||
                      polivar.levelOfGov == "county") &&
                    polivar.candidateStatus != "inactive"
                  ) {
                    // if (type == 'county') {
                    //   $scope.searchUsersByType.length = 0;
                    //   $scope.sortype = "county";
                    // }
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      udata.office = polivar.officeName;
                      udata.candidateStatus = polivar.candidateStatus;
                      udata.party = polivar.party;
                    }
                    if (typeof polivar.party == "string") {
                      // alert('one');
                      polivar.party = polivar.party;
                    } else {
                      var party = "";
                      polivar.party.forEach(function (element, index) {
                        if (element) {
                          party += element + ", ";
                          var n = party.lastIndexOf(",");
                          var a = party.substring(0, n);
                          // MyService.ConsoleLog("Element", a);
                          udata.party = a;
                        }
                      });
                    }

                    // MyService.ConsoleLog("County >>", polivar);
                    if (typeof polivar.party == "string") {
                      if ($scope.comp.party && $scope.comp.party != "") {
                        if (
                          $scope.comp.party == udata.politicianInfo[s].party
                        ) {
                          $scope.searchUsersByType.push(udata);
                        }
                      } else {
                        $scope.searchUsersByType.push(udata);
                      }
                    } else {
                      if ($scope.comp.party && $scope.comp.party != "") {
                        if (
                          udata.politicianInfo[s].party.indexOf(
                            $scope.comp.party
                          ) != -1
                        ) {
                          $scope.searchUsersByType.push(udata);
                        }
                      } else {
                        $scope.searchUsersByType.push(udata);
                      }
                    }
                  } else if (
                    type == "state" &&
                    (polivar.levelOfGov == "statePostalCode" ||
                      (polivar.levelOfGov == "stateSenateDistrict" &&
                        polivar.officeName != "" &&
                        polivar.officeName.toLowerCase() != "u.s. senate") ||
                      polivar.officeName != "president") &&
                    polivar.candidateStatus != "inactive"
                  ) {
                    // alert('in')
                    // MyService.ConsoleLog("state", polivar);
                    // if (type == 'state') {
                    //   $scope.searchUsersByType.length = 0;
                    //   $scope.sortype = "state";
                    // }
                    // MyService.ConsoleLog("VoterScope it me : ", polivar.levelOfGov);
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      udata.office = polivar.officeName;
                      udata.candidateStatus = polivar.candidateStatus;
                      udata.party = polivar.party;
                    }
                    if (typeof polivar.party == "string") {
                      // alert('one');
                      polivar.party = polivar.party;
                    } else {
                      var party = "";
                      polivar.party.forEach(function (element, index) {
                        if (element) {
                          party += element + ", ";
                          var n = party.lastIndexOf(",");
                          var a = party.substring(0, n);
                          // MyService.ConsoleLog("Element", a);
                          udata.party = a;
                        }
                      });
                    }
                    // $scope.sortype = "";

                    // $scope.sortype = "state";

                    // var ssle = $scope.searchUsersByType.length;
                    // //// MyService.ConsoleLog("cle.n ", cle);
                    // for (var t = 0; t < ssle; t++) {
                    //   //// MyService.ConsoleLog("sinsisi >", $scope.searchUsersByType[s].voterScope)
                    //   if ($scope.searchUsersByType[t].voterScope != "statePostalCode") {
                    //     $scope.searchUsersByType.splice(t, 1);
                    //   }
                    // }
                    if (
                      polivar.officeName &&
                      polivar.officeName.toLowerCase() != "u.s. house" &&
                      polivar.levelOfGov != "placeName" &&
                      polivar.levelOfGov != "All USA"
                    ) {
                      if (polivar.runningForOffice == "Y") {
                        if (typeof polivar.party == "string") {
                          if ($scope.comp.party && $scope.comp.party != "") {
                            if (
                              $scope.comp.party == udata.politicianInfo[s].party
                            ) {
                              $scope.searchUsersByType.push(udata);
                            }
                          } else {
                            $scope.searchUsersByType.push(udata);
                          }
                        } else {
                          if ($scope.comp.party && $scope.comp.party != "") {
                            if (
                              udata.politicianInfo[s].party.indexOf(
                                $scope.comp.party
                              ) != -1
                            ) {
                              $scope.searchUsersByType.push(udata);
                            }
                          } else {
                            $scope.searchUsersByType.push(udata);
                          }
                        }
                      }
                    }
                    // $scope.searchUsersByType.push(udata);
                    //// MyService.ConsoleLog("searchUsersByType state>>> ", $scope.searchUsersByType);

                    /*****************************************************************
                     * commented by vishal $scope.searchUsers.electionOfficeTypeId == 'G' ||
                     *
                     *****************************************************************/

                    /*
                  if ($scope.searchUsers[i].office) {

                    if (($scope.searchUsers[i].voterScope == 'state' || $scope.searchUsers[i].voterScope == 'statePostalCode' || $scope.searchUsers[i].voterScope == 'stateSenateDistrict' || $scope.searchUsers[i].voterScope == 'stateHouseofRepresentativesDistrict') && $scope.searchUsers[i].office != 'unitedStatesSenate') {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    } else {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }
                  } else {
                    $scope.searchUsersByType.push($scope.searchUsers[i]);
                  }
                  */
                  } else if (
                    type == "federal" &&
                    (polivar.levelOfGov == "federalCongressionalDistrict" ||
                      polivar.officeName == "president" ||
                      (polivar.officeName == "President" &&
                        polivar.levelOfGov == "All USA") ||
                      polivar.levelOfGov == "all USA" ||
                      (polivar.levelOfGov == "statePostalCode" &&
                        polivar.officeName != "" &&
                        polivar.officeName != "State House") ||
                      polivar.officeName != "auditor" ||
                      (polivar.officeName != "" &&
                        polivar.officeName.toLowerCase() == "u.s. senate" &&
                        polivar.candidateStatus != "inactive"))
                  ) {
                    // alert('come');
                    // $scope.showfederal = true;
                    // if (type == 'federal') {
                    //   $scope.searchUsersByType.length = 0;
                    //   $scope.sortype = "federal";
                    // }
                    // //MyService.ConsoleLog("unifiedSchoolDistrictName >>>", $scope.searchUsers[i].voterScope);
                    // MyService.ConsoleLog("!!!!!!!! 4 !!!!!!!!!!!!!", udata);
                    // MyService.ConsoleLog("Federal", polivar.levelOfGov);

                    // $scope.changeTab($scope.searchUsers[i]);
                    if (polivar.levelOfGov != "unifiedSchoolDistrictName") {
                      // MyService.ConsoleLog("check federal",typeof udata.isOnBallot);
                      if (polivar.officeName) {
                        polivar.officeName = polivar.officeName.toLowerCase();
                        if (typeof polivar.party == "string") {
                          // alert('one');
                          polivar.party = polivar.party;
                        } else {
                          var party = "";
                          polivar.party.forEach(function (element, index) {
                            if (element) {
                              party += element + ", ";
                              var n = party.lastIndexOf(",");
                              var a = party.substring(0, n);
                              //// MyService.ConsoleLog("Element", party);
                              udata.party = a;
                            }
                          });
                        }
                        udata.office = polivar.officeName;
                        udata.candidateStatus = polivar.candidateStatus;
                        // udata.party = polivar.party;
                      }
                      // $scope.sortype = "";
                      if (
                        (polivar.officeName &&
                          polivar.officeName.toLowerCase() == "u.s. house") ||
                        polivar.officeName.toLowerCase() == "u.s. senate" ||
                        polivar.officeName.toLowerCase() == "president"
                      ) {
                        if (typeof polivar.party == "string") {
                          if ($scope.comp.party && $scope.comp.party != "") {
                            if (
                              $scope.comp.party == udata.politicianInfo[s].party
                            ) {
                              if (
                                udata.isOnBallot != false &&
                                $scope.comp.election.electionDate ==
                                  udata.electionDate
                              ) {
                                $scope.topCandidates.push(udata);
                              } else {
                                $scope.searchUsersByType.push(udata);
                              }
                            }
                          } else {
                            if (
                              udata.isOnBallot != false &&
                              $scope.comp.election.electionDate ==
                                udata.electionDate
                            ) {
                              $scope.topCandidates.push(udata);
                            } else {
                              $scope.searchUsersByType.push(udata);
                            }
                          }
                        } else {
                          if ($scope.comp.party && $scope.comp.party != "") {
                            if (
                              udata.politicianInfo[s].party.indexOf(
                                $scope.comp.party
                              ) != -1
                            ) {
                              if (
                                udata.isOnBallot != false &&
                                $scope.comp.election.electionDate ==
                                  udata.electionDate
                              ) {
                                $scope.topCandidates.push(udata);
                              } else {
                                $scope.searchUsersByType.push(udata);
                              }
                            }
                          } else {
                            if (
                              udata.isOnBallot != false &&
                              $scope.comp.election.electionDate ==
                                udata.electionDate
                            ) {
                              $scope.topCandidates.push(udata);
                            } else {
                              $scope.searchUsersByType.push(udata);
                            }
                          }
                        }

                        // $scope.searchUsersByType.push(udata);
                        //// MyService.ConsoleLog("searchUsersByType federal>>> ", $scope.searchUsersByType);
                      }
                      //// MyService.ConsoleLog(" $scope.searchUsersByType !!!", $scope.searchUsersByType);
                    }
                    // MyService.ConsoleLog("$scope.topCandidates", $scope.topCandidates.length);
                    // else if ($scope.searchUsers[i].voterScope != 'placeName') {
                    //   $scope.searchUsers[i].office = $scope.searchUsers[i].office.toLowerCase();
                    //   $scope.searchUsersByType.push($scope.searchUsers[i]);
                    // }

                    /*
                  if ($scope.searchUsers[i].office) {
                    if ($scope.searchUsers[i].voterScope == 'state' && $scope.searchUsers[i].office == 'unitedStatesSenate') {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    } else {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }
                  } else {
                    $scope.searchUsersByType.push($scope.searchUsers[i]);
                  }
                  */
                  } else if (
                    type == "other" &&
                    (polivar.levelOfGov == "All USA" ||
                      polivar.levelOfGov == "all USA") &&
                    polivar.officeName != "president" &&
                    polivar.candidateStatus != "inactive"
                  ) {
                    // MyService.ConsoleLog("!!!!!!!! 5 !!!!!!!!!!!!!", polivar);
                    // $scope.changeTab($scope.searchUsers[i]);
                    // $scope.showother = true;
                    // $scope.showlocal = true;
                    // if (type == 'other') {
                    //   $scope.searchUsersByType.length = 0;
                    //   $scope.sortype = "other";
                    // }
                    // $scope.sortype = "other";

                    // var sle = $scope.searchUsersByType.length;
                    // for (var s = 0; s < sle; s++) {
                    //   //// MyService.ConsoleLog("sinsisi >", $scope.searchUsersByType[s].voterScope)
                    //   if ($scope.searchUsersByType[s].voterScope != "All USA") {
                    //     $scope.searchUsersByType.splice(s, 1);
                    //   }
                    // }
                    //// MyService.ConsoleLog("slen >", sle, $scope.searchUsersByType);
                    // if ($scope.searchUsers[i].voterScope == "All USA") {
                    // $scope.searchUsersByType.length = 0;
                    if (polivar.officeName) {
                      polivar.officeName = polivar.officeName.toLowerCase();
                      udata.office = polivar.officeName;
                      udata.candidateStatus = polivar.candidateStatus;
                      udata.party = polivar.party;
                    }
                    // if (udata.politicianInfo[s].runningForOffice = "Y" && udata.politicianInfo[s].incumbent != "") {
                    //   $scope.searchUsersByType.push(udata);
                    //  // MyService.ConsoleLog(" udata.office IF", udata.office);

                    // } else {
                    //// MyService.ConsoleLog(" udata.office ELSE", udata.office);
                    $scope.searchUsersByType.push(udata);
                    // }

                    // }
                  }
                } else {
                  // console.log("no voterScope");
                  if (
                    udata.running_for &&
                    udata.running_for.levelOfGov &&
                    udata.running_for.levelOfGov != ""
                  ) {
                    if (
                      type == "local" &&
                      (udata.running_for.levelOfGov == "placeName" ||
                        udata.running_for.levelOfGov ==
                          "countySubDivisionName" ||
                        udata.running_for.levelOfGov ==
                          "unifiedSchoolDistrictName" ||
                        udata.running_for.levelOfGov ==
                          "cityCouncilDistrictName" ||
                        udata.running_for.levelOfGov ==
                          "elementarySchoolDistrictName" ||
                        udata.running_for.levelOfGov ==
                          "secondarySchoolDistrictName")
                    ) {
                      udata.office = udata.office.toLowerCase();
                      $scope.searchUsersByType.push(udata);
                    } else if (
                      type == "county" &&
                      (udata.running_for.levelOfGov == "countyName" ||
                        udata.running_for.levelOfGov == "county")
                    ) {
                      udata.office = udata.office.toLowerCase();
                      $scope.searchUsersByType.push(udata);
                    } else if (
                      type == "state" &&
                      (udata.electionOfficeTypeId == "G" ||
                        udata.electionOfficeTypeId == "S" ||
                        udata.electionOfficeTypeId == "K" ||
                        udata.electionOfficeTypeId == "L" ||
                        udata.electionOfficeTypeId == "P")
                    ) {
                      // alert('new');

                      udata.office = udata.office.toLowerCase();
                      if (typeof udata.party == "string") {
                        // alert('one');
                        udata.party = udata.party;
                      } else {
                        var party = "";
                        udata.party.forEach(function (element, index) {
                          if (element) {
                            party += element + ", ";
                            var n = party.lastIndexOf(",");
                            var a = party.substring(0, n);
                            //// MyService.ConsoleLog("Element", party);
                            udata.party = a;
                          }
                        });
                      }
                      if (
                        udata.verifiedUser != false &&
                        udata.office &&
                        udata.office.toLowerCase() != "u.s. house" &&
                        udata.office.toLowerCase() != "president"
                      ) {
                        $scope.searchUsersByType.push(udata);
                      }
                      /*
                    if ($scope.searchUsers[i].office) {

                      if (($scope.searchUsers[i].running_for.levelOfGov == 'state' || $scope.searchUsers[i].running_for.levelOfGov == 'statePostalCode' || $scope.searchUsers[i].running_for.levelOfGov == 'stateSenateDistrict' || $scope.searchUsers[i].running_for.levelOfGov == 'stateHouseofRepresentativesDistrict') && $scope.searchUsers[i].office != 'unitedStatesSenate') {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      } else {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      }
                    } else {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }
                    */
                    } else if (
                      type == "federal" &&
                      (udata.electionOfficeTypeId == "P" ||
                        udata.electionOfficeTypeId == "C" ||
                        udata.electionOfficeTypeId == "J")
                    ) {
                      udata.office = udata.office.toLowerCase();
                      if (typeof udata.party == "string") {
                        // alert('one');
                        udata.party = udata.party;
                      } else {
                        var party = "";
                        udata.party.forEach(function (element, index) {
                          if (element) {
                            party += element + ", ";
                            var n = party.lastIndexOf(",");
                            var a = party.substring(0, n);
                            //// MyService.ConsoleLog("Element", party);
                            udata.party = a;
                          }
                        });
                      }

                      // $scope.searchUsersByType.push(udata);
                      if (udata && udata.isOnBallot != true) {
                        $scope.searchUsersByType.push(udata);
                      } else {
                        $scope.topCandidates.push(udata);
                      }
                      /*
                    if ($scope.searchUsers[i].office) {
                      if ($scope.searchUsers[i].running_for.levelOfGov == 'state' && $scope.searchUsers[i].office == 'unitedStatesSenate') {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      } else {
                        $scope.searchUsersByType.push($scope.searchUsers[i]);
                      }
                    } else {
                      $scope.searchUsersByType.push($scope.searchUsers[i]);
                    }
                    */
                    } // else if(type == 'other') {
                    //   $scope.searchUsersByType.push($scope.searchUsers[i]);
                    // }
                  } else if (type == "other") {
                    udata.office = udata.office.toLowerCase();
                    $scope.searchUsersByType.push(udata);
                  }
                }
              }
            }
          }
        } else {
          // MyService.ConsoleLog("in sir", udata.voterScope);
          // MyService.ConsoleLog("udata >", udata);
          if (!tflag) {
            // alert("blink");
            switch (udata.voterScope) {
              case "placeName":
                $scope.showlocal = true;

                // $scope.sortype = "local";
                if (!type) type = "local";
                break;
              case "countySubdivision":
                // $scope.sortype = "local";
                $scope.showlocal = true;

                if (!type) type = "local";
                break;
              case "unifiedSchoolDistrictName":
                $scope.showlocal = true;

                // $scope.sortype = "local";
                if (!type) type = "local";
                break;
              case "All USA":
              case "all USA":
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "president") {
                  // $scope.sortype = "other";
                  if (!type) type = "other";
                  $scope.showother = true;
                } else {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "countyName":
                $scope.showcounty = true;
                // $scope.sortype = "county";
                if (!type) type = "county";
                break;
              case "statePostalCode":
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "u.s. senate") {
                  // $scope.sortype = "other";
                  $scope.showstate = true;
                  $scope.showfederal = true;
                  $scope.sortype = "state";
                  if (!type) type = "state";
                } else if (ncoff.toLowerCase() != "president") {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                } else {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "stateHouseofRepresentativesDistrict":
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "president") {
                  // $scope.sortype = "other";
                  $scope.showstate = true;
                  $scope.sortype = "state";
                  if (!type) type = "state";
                } else {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "federalCongressionalDistrict":
                $scope.sortype = "federal";
                if (!type) type = "federal";
                $scope.showfederal = true;
                break;
              default:
                break;
            }
            //// MyService.ConsoleLog("type in", type);
          } else {
            //// MyService.ConsoleLog("$scope.searchUsers[i].voterScope >>", $scope.searchUsers[i]);
            // $scope.changeTab($scope.searchUsers[i].voterScope);
            switch (udata.voterScope) {
              case "placeName":
                // $scope.sortype = "local";
                if (!type) type = "local";
                $scope.showlocal = true;
                break;
              case "countySubdivision":
                // $scope.sortype = "local";
                if (!type) type = "local";
                $scope.showlocal = true;
                break;
              case "unifiedSchoolDistrictName":
                $scope.showlocal = true;
                if (!type) type = "local";
                break;
              case "All USA":
              case "all USA":
                //MyService.ConsoleLog("$scope.searchUsers[i].office >", $scope.searchUsers[i].office);
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "president") {
                  // $scope.sortype = "other";
                  if (!type) type = "other";
                  $scope.showother = true;
                } else {
                  // $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "countyName":
                // $scope.sortype = "county";
                if (!type) type = "county";
                $scope.showcounty = true;
                break;
              case "statePostalCode":
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "u.s. senate") {
                  // $scope.sortype = "other";
                  $scope.showstate = true;
                  $scope.showfederal = true;
                  $scope.sortype = "state";
                  if (!type) type = "state";
                } else if (ncoff.toLowerCase() != "president") {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                } else {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "stateHouseofRepresentativesDistrict":
                var ncoff = udata.office;
                if (ncoff.toLowerCase() != "president") {
                  // $scope.sortype = "other";
                  $scope.showstate = true;
                  $scope.sortype = "state";
                  if (!type) type = "state";
                } else {
                  $scope.sortype = "federal";
                  if (!type) type = "federal";
                  $scope.showfederal = true;
                  $scope.showother = false;
                }
                break;
              case "federalCongressionalDistrict":
                $scope.showfederal = true;
                if (!type) type = "federal";
                break;
              default:
                break;
            }
          }
          // $scope.searchUsersByType.length = 0;

          if (udata.voterScope) {
            // MyService.ConsoleLog(" Yogesh  >>>>>>> type for voterscope", type);
            // MyService.ConsoleLog("Yogesh =>>>>>>>>>> sort type for voterscope", $scope.sortype);

            // alert('in');
            if (
              type == "local" &&
              (udata.voterScope == "placeName" ||
                udata.voterScope == "countySubDivisionName" ||
                udata.voterScope == "unifiedSchoolDistrictName" ||
                udata.voterScope == "elementarySchoolDistrictName" ||
                udata.voterScope == "cityCouncilDistrictName" ||
                udata.voterScope == "secondarySchoolDistrictName" ||
                (udata.voterScope == "countySubdivision" &&
                  udata.voterScope != "federalCongressionalDistrict"))
            ) {
              udata.office = udata.office.toLowerCase();
              if (typeof udata.party == "string") {
                // alert('one');
                udata.party = udata.party;
              } else {
                var party = "";
                udata.party.forEach(function (element, index) {
                  if (element) {
                    party += element + ", ";
                    var n = party.lastIndexOf(",");
                    var a = party.substring(0, n);
                    // MyService.ConsoleLog("a", a);
                    if (a != '""') {
                      MyService.ConsoleLog("Element  inside", a);
                      udata.party = a;
                    } else {
                      udata.party = "";
                    }
                  }
                });
              }

              // MyService.ConsoleLog("welcome");
              if (
                udata &&
                udata.office &&
                udata.office.toLowerCase() != "president"
              ) {
                if (udata.verifiedUser != false) {
                  $scope.searchUsersByType.push(udata);
                }
              }

              // }
            } else if (
              type == "county" &&
              (udata.voterScope == "countyName" || udata.voterScope == "county")
            ) {
              udata.office = udata.office.toLowerCase();
              if (typeof udata.party == "string") {
                // alert('one');
                udata.party = udata.party;
              } else {
                var party = "";
                udata.party.forEach(function (element, index) {
                  if (element) {
                    party += element + ", ";
                    var n = party.lastIndexOf(",");
                    var a = party.substring(0, n);
                    //// MyService.ConsoleLog("Element", party);
                    udata.party = a;
                  }
                });
              }
              if (udata.verifiedUser != false) {
                $scope.searchUsersByType.push(udata);
              }

              // }
            } else if (
              type == "state" &&
              (udata.electionOfficeTypeId == "S" ||
                udata.electionOfficeTypeId == "K" ||
                udata.electionOfficeTypeId == "L" ||
                udata.voterScope == "statePostalCode")
            ) {
              // alert('mm')
              // $scope.showfederal = true;
              MyService.ConsoleLog("plz check ========", udata.voterScope);
              udata.office = udata.office.toLowerCase();
              if (typeof udata.party == "string") {
                // alert('one');
                udata.party = udata.party;
              } else {
                var party = "";
                udata.party.forEach(function (element, index) {
                  if (element) {
                    party += element + ", ";
                    var n = party.lastIndexOf(",");
                    var a = party.substring(0, n);
                    //// MyService.ConsoleLog("Element", party);
                    udata.party = a;
                  }
                });
              }
              // MyService.ConsoleLog("yogesh got it >>>>>>>>>", udata.office)
              if (
                udata.verifiedUser != false &&
                udata.office &&
                udata.office.toLowerCase() != "u.s. house" &&
                udata.office.toLowerCase() != "president"
              ) {
                $scope.searchUsersByType.push(udata);
              }
              /*****************************************************************
               * commented by vishal $scope.searchUsers.electionOfficeTypeId == 'G' ||
               *
               *****************************************************************/

              /*
            udata.voterScope == "statePostalCode" && udata.officeName != 'president'
            if ($scope.searchUsers[i].office) {

              if (($scope.searchUsers[i].voterScope == 'state' || $scope.searchUsers[i].voterScope == 'statePostalCode' || $scope.searchUsers[i].voterScope == 'stateSenateDistrict' || $scope.searchUsers[i].voterScope == 'stateHouseofRepresentativesDistrict') && $scope.searchUsers[i].office != 'unitedStatesSenate') {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
            } else {
              $scope.searchUsersByType.push($scope.searchUsers[i]);
            }
            */
            } else if (
              type == "federal" &&
              (udata.electionOfficeTypeId == "C" ||
                udata.electionOfficeTypeId == "J" ||
                udata.electionOfficeTypeId == "R" ||
                udata.voterScope == "federalCongressionalDistrict" ||
                udata.office == "president" ||
                (udata.office == "President" &&
                  udata.voterScope == "All USA") ||
                udata.voterScope == "all USA" ||
                udata.voterScope == "statePostalCode")
            ) {
              // alert('4');
              // && udata.politicianInfo[0].levelOfGov == "All USA" || udata.politicianInfo[0].levelOfGov == "all USA"
              // $scope.showfederal = true;
              // //MyService.ConsoleLog("unifiedSchoolDistrictName >>>", $scope.searchUsers[i].voterScope);
              // MyService.ConsoleLog("!!!!!!!! 4 !!!!!!!!!!!!!", udata);
              // $scope.changeTab($scope.searchUsers[i]);
              if (udata.voterScope != "unifiedSchoolDistrictName") {
                udata.office = udata.office.toLowerCase();
                // $scope.sortype = "";
                if (typeof udata.party == "string") {
                  // alert('one');
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);
                      //// MyService.ConsoleLog("Element", party);
                      udata.party = a;
                    }
                  });
                }
                // $scope.sortype = "fedesampleral";
                // var fle = $scope.searchUsersByType.length;
                // for (var f = 0; f < fle; f++) {
                //   // MySgetLocalPoliticianservice.ConsoleLog("sinsisi >", $scope.searchUsersByType[s].voterScope)
                //   if ($scope.searchUsersByType[f].voterScope != "federal" || $scope.searchUsersByType[f].voterScope != "All USA") {
                //     $scope.searchUsersByType.splice(f, 1);
                //   }
                // }
                if (
                  (udata.office &&
                    udata.office.toLowerCase() == "u.s. house") ||
                  udata.office.toLowerCase() == "u.s. senate" ||
                  udata.office.toLowerCase() == "president"
                ) {
                  if (udata.verifiedUser != false) {
                    if (
                      udata.isOnBallot != false &&
                      $scope.comp.election.electionDate == udata.electionDate
                    ) {
                      // MyService.ConsoleLog('$scope.searchUsersByType', $scope.searchUsersByType.length);
                      $scope.topCandidates.push(udata);
                    } else {
                      $scope.searchUsersByType.push(udata);
                    }
                  }
                }
              }

              // else if ($scope.searchUsers[i].voterScope != 'placeName') {
              //   $scope.searchUsers[i].office = $scope.searchUsers[i].office.toLowerCase();
              //   $scope.searchUsersByType.push($scope.searchUsers[i]);
              // }

              /*
            if ($scope.searchUsers[i].office) {
              if ($scope.searchUsers[i].voterScope == 'state' && $scope.searchUsers[i].office == 'unitedStatesSenate') {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
            } else {
              $scope.searchUsersByType.push($scope.searchUsers[i]);
            }
            */
            } else if (
              type == "other" &&
              (udata.voterScope == "All USA" || udata.voterScope == "all USA")
            ) {
              // alert('5');
              // MyService.ConsoleLog("!!!!!!!! 5 !!!!!!!!!!!!!", $scope.searchUsersByType);
              // && (udata.office != "president")
              // $scope.changeTab($scope.searchUsers[i]);
              // $scope.showother = true;
              // $scope.showlocal = true;

              // $scope.sortype = "other";
              // $scope.searchUsersByType.length = 0;
              // var sle = $scope.searchUsersByType.length;
              // for (var s = 0; s < sle; s++) {
              //   //// MyService.ConsoleLog("sinsisi >", $scope.searchUsersByType[s].voterScope)
              //   if ($scope.searchUsersByType[s].voterScope != "All USA") {
              //     $scope.searchUsersByType.splice(s, 1);
              //   }
              // }
              //// MyService.ConsoleLog("slen >", sle, $scope.searchUsersByType);
              // if ($scope.searchUsers[i].voterScope == "All USA") {
              // $scope.searchUsersByType.length = 0;
              // MyService.ConsoleLog("udata", udata);
              if (udata.verifiedUser != false) {
                $scope.searchUsersByType.push(udata);
              }
              // }
            } else if (
              udata.office == "president" ||
              (udata.office == "President" && udata.voterScope == "All USA") ||
              udata.voterScope == "all USA"
            ) {
              // MyService.ConsoleLog("check", udata.voterScope);
              // MyService.ConsoleLog(" Yogesh  >>>>>>> type for voterscope", type);
              // type = 'federal';
              if (udata.voterScope != "unifiedSchoolDistrictName") {
                udata.office = udata.office.toLowerCase();
                // $scope.sortype = "";
                if (typeof udata.party == "string") {
                  // alert('one');
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      var n = party.lastIndexOf(",");
                      var a = party.substring(0, n);
                      //// MyService.ConsoleLog("Element", party);
                      udata.party = a;
                    }
                  });
                }
                // $scope.sortype = "fedesampleral";
                // var fle = $scope.searchUsersByType.length;
                // for (var f = 0; f < fle; f++) {
                //   // MySgetLocalPoliticianservice.ConsoleLog("sinsisi >", $scope.searchUsersByType[s].voterScope)
                //   if ($scope.searchUsersByType[f].voterScope != "federal" || $scope.searchUsersByType[f].voterScope != "All USA") {
                //     $scope.searchUsersByType.splice(f, 1);
                //   }
                // }

                if (
                  udata.verifiedUser != false &&
                  type != "state" &&
                  type != "local"
                ) {
                  $scope.searchUsersByType.push(udata);
                }
              }
            }
          } else {
            // alert('no')
            if (
              udata.running_for &&
              udata.running_for.levelOfGov &&
              udata.running_for.levelOfGov != ""
            ) {
              if (
                type == "local" &&
                (udata.running_for.levelOfGov == "placeName" ||
                  udata.running_for.levelOfGov == "countySubDivisionName" ||
                  udata.running_for.levelOfGov == "unifiedSchoolDistrictName" ||
                  udata.running_for.levelOfGov == "cityCouncilDistrictName" ||
                  udata.running_for.levelOfGov ==
                    "elementarySchoolDistrictName" ||
                  udata.running_for.levelOfGov == "secondarySchoolDistrictName")
              ) {
                udata.office = udata.office.toLowerCase();
                $scope.searchUsersByType.push(udata);
              } else if (
                type == "county" &&
                (udata.running_for.levelOfGov == "countyName" ||
                  udata.running_for.levelOfGov == "county")
              ) {
                udata.office = udata.office.toLowerCase();
                $scope.searchUsersByType.push(udata);
              } else if (
                type == "state" &&
                (udata.electionOfficeTypeId == "G" ||
                  udata.electionOfficeTypeId == "S" ||
                  udata.electionOfficeTypeId == "K" ||
                  udata.electionOfficeTypeId == "L" ||
                  udata.electionOfficeTypeId == "P")
              ) {
                udata.office = udata.office.toLowerCase();

                $scope.searchUsersByType.push(udata);

                /*
              if ($scope.searchUsers[i].office) {

                if (($scope.searchUsers[i].running_for.levelOfGov == 'state' || $scope.searchUsers[i].running_for.levelOfGov == 'statePostalCode' || $scope.searchUsers[i].running_for.levelOfGov == 'stateSenateDistrict' || $scope.searchUsers[i].running_for.levelOfGov == 'stateHouseofRepresentativesDistrict') && $scope.searchUsers[i].office != 'unitedStatesSenate') {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                } else {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                }
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
              */
              } else if (
                type == "federal" &&
                (udata.electionOfficeTypeId == "P" ||
                  udata.electionOfficeTypeId == "C" ||
                  udata.electionOfficeTypeId == "J")
              ) {
                udata.office = udata.office.toLowerCase();
                if (typeof udata.party == "string") {
                  // alert('one');
                  udata.party = udata.party;
                } else {
                  var party = "";
                  udata.party.forEach(function (element, index) {
                    if (element) {
                      party += element + ", ";
                      //// MyService.ConsoleLog("Element", party);
                      udata.party = party;
                    }
                  });
                }

                $scope.searchUsersByType.push(udata);
                /*
              if ($scope.searchUsers[i].office) {
                if ($scope.searchUsers[i].running_for.levelOfGov == 'state' && $scope.searchUsers[i].office == 'unitedStatesSenate') {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                } else {
                  $scope.searchUsersByType.push($scope.searchUsers[i]);
                }
              } else {
                $scope.searchUsersByType.push($scope.searchUsers[i]);
              }
              */
              } // else if(type == 'other') {
              //   $scope.searchUsersByType.push($scope.searchUsers[i]);
              // }
            } else if (type == "other") {
              udata.office = udata.office.toLowerCase();
              $scope.searchUsersByType.push(udata);
            }
          }
        }
        // MyService.ConsoleLog("Typ End", type);
        // MyService.ConsoleLog("searchUsersByType >>> ", $scope.searchUsersByType);
        $scope.showLoader = false;

        if (type != "") {
          // $scope.sortype = "";
          $scope.searchUsersByType.filter(function (itms) {
            // MyService.ConsoleLog("itms", itms);
            if (
              $scope.comp.election.electionDate == itms.electionDate ||
              $scope.comp.election.electionDate == itms.electionYear
            ) {
              //     $scope.sortype =
              $scope.sortype = type;
              return itms;
            }
          });
          // MyService.ConsoleLog("type more", $scope.showlocal);

          // MyService.ConsoleLog("type more", type);

          // MyService.ConsoleLog("check", $scope.topCandidates.length);
        }
      };

      $scope.checkElectionDate = function () {
        var curDate = new Date();
        var smpArr = $scope.searchUsers.filter(function (itms) {
          if (itms.electionDate >= curDate.toISOString().split("T")[0]) {
            if (itms && itms.politicianInfo && itms.politicianInfo.length > 0) {
              var pLens = itms.politicianInfo.length;
              for (var j = 0; j < pLens; j++) {
                if (itms.politicianInfo[j].runningForOffice == "Y") {
                  // MyService.ConsoleLog(
                  //   "itms.politicianInfo[j].levelOfGov",
                  //   itms.politicianInfo[j].levelOfGov
                  // );
                  switch (itms.politicianInfo[j].levelOfGov) {
                    case "placeName":
                      $scope.getByType("local", "");
                      break;
                    case "countyName":
                      $scope.getByType("county", "");
                      break;
                    case "statePostalCode":
                      $scope.getByType("state", "");
                      break;
                    case "stateSenateDistrict":
                      $scope.getByType("state", "");
                      break;
                    case "federalCongressionalDistrict":
                      $scope.getByType("federal", "");
                      break;
                    case "All USA":
                    case "all USA":
                      $scope.getByType("federal", "");
                      break;
                    default:
                      break;
                  }
                } else {
                  return;
                }
              }
            } else {
              switch (itms.voterScope) {
                case "placeName":
                  $scope.getByType("local", "");
                  break;
                case "countyName":
                  $scope.getByType("county", "");
                  break;
                case "statePostalCode":
                  $scope.getByType("state", "");
                  break;
                case "stateSenateDistrict":
                  $scope.getByType("state", "");
                  break;
                case "federalCongressionalDistrict":
                  $scope.getByType("federal", "");
                  break;
                case "All USA":
                case "all USA":
                  $scope.getByType("federal", "");
                  break;
                default:
                  break;
              }
            }
          }
        });
      };

      $scope.searchUsers = [];
      $scope.searchUsersByType = [];
      $scope.sortype = "local";
      $scope.showParty = false;

      $scope.getByType = function (type, tflag) {
        $scope.showlocal = false;
        $scope.showcounty = false;
        $scope.showstate = false;
        $scope.showfederal = false;
        $scope.showother = false;
        // $cookies.remove('electionDate');
        $cookies.put("elecDateForRate", $scope.comp.election.electionDate);
        // console.log("type : " + type);
        // if (type) {
        //   $scope.sortype = type;
        //  // MyService.ConsoleLog("sortType", $scope.sortype);
        // } else {
        //   type = $scope.sortype;
        //  // MyService.ConsoleLog("type >>", type);
        // }
        $scope.searchUsersByType = [];
        $scope.topCandidates = [];
        var len = $scope.searchUsers.length;
        // MyService.ConsoleLog(" $scope.searchUsers", $scope.searchUsers);
        // if (type && type != "") {
        // alert('call');
        if (len > 0) {
          $scope.getBallotByType("local");
          for (var x = 0; x < len; x++) {
            if ($scope.searchUsers[x].typeOfElection != "Primary") {
              $scope.showParty = false;
              break;
            }
          }
          for (var i = 0; i < len; i++) {
            var today = new Date();
            // return u
            // MyService.ConsoleLog("nssssssssss", $scope.comp);
            // if ($scope.searchUsers[i].generalElection && $scope.searchUsers[i].generalElection != '') {
            //   if ($scope.searchUsers[i].generalElection >= today.toISOString().split('T')[0]) {
            //     $scope.searchUsers[i].electionDate = $scope.searchUsers[i].generalElection;
            //   }
            // }

            if (
              $scope.searchUsers[i].electionDate >=
              today.toISOString().split("T")[0]
            ) {
              if (
                $scope.comp.election.electionDate &&
                $scope.comp.election.electionDate != ""
              ) {
                if (
                  $scope.searchUsers[i].electionDate ==
                  $scope.comp.election.electionDate
                ) {
                  //MyService.ConsoleLog("tflag >>", tflag);

                  if (
                    $scope.searchUsers[i].typeOfElection == "Primary" &&
                    $scope.searchUsers[i].party
                  ) {
                    //// MyService.ConsoleLog("$scope.searchUsers", $scope.searchUsers[i].name);
                    // alert("inside");
                    $scope.showParty = true;
                    // MyService.ConsoleLog("if");
                  } else {
                    // MyService.ConsoleLog("else");
                    // $scope.showParty = false;
                  }

                  if (
                    $scope.searchUsers[i].party &&
                    $scope.searchUsers[i].party.length > 0 &&
                    $scope.comp.party &&
                    $scope.comp.party != ""
                  ) {
                    if ($scope.searchUsers[i].party == $scope.comp.party) {
                      $scope.checkParty(tflag, $scope.searchUsers[i], type);
                    }
                  } else {
                    // console.log("sample");
                    // if ($scope.comp.election.electionDate == "2020-11-03") {
                    //   type = "";
                    //   type = "federal";
                    // }

                    var setFlag;
                    // var filter_Data =
                    // console.log("start type", $scope.searchUsers[i]);

                    $scope.checkParty(tflag, $scope.searchUsers[i], type);
                  }
                }
              }
            }
            if (i == len - 1) {
              // console.log("FINAL : ", $scope.searchUsersByType);
            }
          }
        }
        // }
      };

      $scope.getAddress = function () {
        $scope.Address = true;
        $scope.showAddressDropDown = false;
        $scope.Zip = false;
        $scope.Link = false;
      };

      // get Address from google api
      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // console.log($scope.lati);
            // console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            // console.log($scope.mailing_Address);
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  // document.getElementById('zip').value = place.address_components[i].long_name;
                  // $scope.User.ZipCode = place.address_components[i].long_name;
                  // console.log($scope.User.ZipCode);
                  // console.log($scope.User.ZipCode);
                }
              }
            }

            var loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // console.log(loc);
            // $rootScope.location = new Array;
            $rootScope.location = {
              longitude: $scope.lon,
              latitude: $scope.lati,
            };
            $scope.showAddressDropDowns("", loc);
          }
        );
      };

      function getUsers(params) {
        var request = apiCall.apiCall("GET", "/user/list", params);
        $http(request).then(
          function successCallback(response) {
            // console.log("Got categories: ", response.data);
            if (response.data.success) {
              angular.forEach(response.data.data, function (val, key) {
                response.data.data[key].registeredUser = true;
                response.data.data[key].office =
                  response.data.data[key].office.toLowerCase();
              });

              $scope.setAllSelected(response.data.data);
              $scope.users = response.data.data;
              $scope.searchUsers = angular.copy($scope.users);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong.");
          }
        );
      }

      function getCompareAnswers(params) {
        var request = apiCall.apiCall("GET", "/answers/compare", params);
        $http(request).then(
          function successCallback(response) {
            //console.log("Got categories: ",response);
            if (response.data.success) {
              angular.forEach(response.data.data, function (val, key) {
                if (
                  val.answers &&
                  val.answers.length != $scope.selectedUser.length
                ) {
                  var check_author_exist = function (u) {
                    var len = val.answers.length;
                    for (var i = 0; i < len; i++) {
                      if (val.answers[i].author._id == u._id) {
                        return true;
                      }
                    }
                    return false;
                  };
                  angular.forEach($scope.selectedUser, function (u, i) {
                    if (!check_author_exist(u)) {
                      response.data.data[key].answers.push({
                        author: u,
                      });
                    }
                  });
                } else if (!val.answers) {
                  response.data.data[key].answers = [];
                  angular.forEach($scope.selectedUser, function (u, i) {
                    response.data.data[key].answers.push({
                      author: u,
                    });
                  });
                }
              });

              $rootScope.compareAnswerList = response.data.data;
              //console.log("compareAnswerList: ", $rootScope.compareAnswerList);
              $location.path(
                "question-compare/" + $routeParams.category_id + "/" + null
              );
            } else {
              //console.log("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //console.log("Error: ", response);
          }
        );
      }

      $scope.statusGetLocalPoliticians = 0;
      $scope.page = 0;
      $scope.users = [];
      $scope.Location = {
        state: "",
        FinalData: "",
      };

      $scope.getLocalPoliticians = function () {
        // //MyService.ConsoleLog(" finalData >>>>>>>>>>>",$rootScope.compareFinalData);
        $scope.showLoader = true;
        $scope.statusGetLocalPoliticians = 1;
        $scope.page += 1;
        var extradata = "";
        if ($rootScope.compareFinalData && $rootScope.compareFinalData != "") {
          extradata = $rootScope.compareFinalData;
          //alert("extra");
          if ($scope.MyDialog != "") {
            $scope.MyDialog.close();
          }
        } else {
          // MyService.ConsoleLog("else", $rootScope.tempUserAddress);
          extradata = $rootScope.tempUserAddress;
        }
        console.log("extradata<><><><> : ", extradata);
        MyService.getLocalPoliForVoter({
          max_records: 100,
          page: $scope.page,
          extradata: extradata,
        }).then(
          function (response) {
            if (response.data.success) {
              $scope.setAllSelected(response.data.data);
              $rootScope.tempUserAddress = "";
              // $rootScope.compareFinalData = "";
              $scope.getBannerInfo();
              $scope.getBallotMeasures(extradata);
              // console.log("Dataaaa:::", response.data.data);

              MyService.ConsoleLog(
                "getLocalPoliticians > : ",
                response.data.data.poliData
              );

              var filPoli = response.data.data.poliData.filter(function (
                itx,
                ind
              ) {
                return (
                  ind ==
                  response.data.data.poliData.findIndex(function (obj) {
                    return JSON.stringify(obj) == JSON.stringify(itx);
                  })
                );
              });
              MyService.ConsoleLog("filPoli >>", filPoli);
              if (filPoli && filPoli.length > 0) {
                // var tmp = filPoli.filter(function (itms) {
                //   // if (itms.isOnBallot != 'true') {
                //   //   return itms;
                //   // }
                //   MyService.ConsoleLog("itms.is",itms.isOnBallot)
                // });
                // MyService.ConsoleLog("tmp new fil;ter>>", tmp);
                setTimeout(function () {
                  $scope.$apply(function () {
                    var samp = response.data.data.dates.filter(function (
                      item,
                      index,
                      self
                    ) {
                      return (
                        index ==
                        self.findIndex(function (t) {
                          // //MyService.ConsoleLog("t", t);
                          return t.electionDate == item.electionDate;
                        })
                      );

                      // return samp;
                    });
                    //MyService.ConsoleLog("Filter >>", samp);
                    if (samp && samp.length == 0) {
                      $scope.showLoader = false;
                    }
                    // //MyService.ConsoleLog("dublicates >>", [...new Set(response.data.data.dates)]);
                    var sor = samp.sort(function (a, b) {
                      return (
                        new Date(a.electionDate) - new Date(b.electionDate)
                      );
                    });
                    // MyService.ConsoleLog("Sor >", sor);
                    $scope.eleDates = sor;
                    $scope.party = response.data.data.parties.filter(function (
                      party,
                      index
                    ) {
                      return (
                        response.data.data.parties.indexOf(party) === index
                      );
                    });
                    // MyService.ConsoleLog("$scope.party", $scope.party);

                    // var parties = [];
                    // if ($scope.party && $scope.party.length > 0) {
                    //   var parties = $scope.party.filter(function (vals) {
                    //     if (vals == 'Democratic') {
                    //       return vals;
                    //     } else if (vals == 'Republican') {
                    //       return vals;
                    //     }
                    //   });
                    //   $scope.party = '';
                    //   $scope.party = parties;
                    //   $scope.party.unshift('Select Party');
                    // } else {
                    //   parties.push('Democratic');
                    //   parties.push('Republican');
                    //   $scope.party = parties;
                    //   $scope.party.unshift('Select Party');
                    // }
                    // parties.unshift('Select Party');
                    // MyService.ConsoleLog(" parties", parties);
                    MyService.ConsoleLog(" $scope.parties >>", $scope.party);
                    MyService.ConsoleLog("electionDates >>", $scope.eleDates);

                    // MyService.ConsoleLog("$scope.comp.election", $scope.comp.election);
                    if (
                      $cookies.get("electionDate") ||
                      $cookies.get("elecDateForRate")
                    ) {
                      var sml;

                      if ($cookies.get("electionDate")) {
                        sml = {
                          electionDate: $cookies.get("electionDate"),
                        };
                      } else if ($cookies.get("elecDateForRate")) {
                        sml = {
                          electionDate: $cookies.get("elecDateForRate"),
                        };
                      }

                      $scope.eleDates.unshift(sml);
                      var eleDates = $scope.eleDates.filter(function (
                        itm,
                        ind
                      ) {
                        return (
                          ind ==
                          $scope.eleDates.findIndex(function (ele) {
                            return JSON.stringify(ele) == JSON.stringify(itm);
                          })
                        );
                      });
                      $scope.eleDates = "";
                      $scope.eleDates = eleDates;
                      MyService.ConsoleLog(" $scope.eleDates", $scope.eleDates);
                      $scope.comp.election = $scope.eleDates[0];
                      $cookies.remove("electionDate");
                      $cookies.remove("elecDateForRate");
                    } else {
                      $scope.comp.election = $scope.eleDates[0];
                    }
                    // $scope.comp.party = "Democratic";
                    $scope.users = $scope.users.concat(filPoli);
                    $scope.searchUsers = angular.copy($scope.users);
                    $scope.checkElectionDate(); //set state as default
                    // $scope.getByType('local');
                  });
                }, 100);
              } else {
                growl.error("Candidates not found");
                $scope.showLoader = false;
              }

              //$scope.users = response.data.data;
              //console.log("users:::", JSON.stringify($scope.users));
              $scope.statusGetLocalPoliticians = 2;
            } else {
              $scope.page -= 1;
              $scope.statusGetLocalPoliticians = 3;
              if (typeof response.data.error == "string")
                growl.error(response.data.error);
              else growl.error("Something went wrong");
            }
          },
          function (err) {
            $scope.page -= 1;
            $scope.statusGetLocalPoliticians = 3;
            growl.error("Something went wrong");
          }
        );
      };

      $scope.getBannerInfo = function () {
        MyService.getBannerInfo({ event: "All" }).then(function (APIResponse) {
          if (APIResponse.data.success) {
            $scope.bannerInfo = APIResponse.data.data;
            MyService.ConsoleLog("$scope.bannerInfo > ", $scope.bannerInfo);
          }
        });
      };

      $scope.getBallotMeasures = function (data) {
        var dat;
        if (data) {
          dat = data;
        } else {
          dat = $scope.addressNw.address;
        }
        MyService.ConsoleLog("data", dat);
        MyService.getBallotMeasuresData({
          address: dat,
        }).then(
          function (response) {
            MyService.ConsoleLog("Response", response);
            $scope.ballot = response.data.data;
            $scope.getBallotByType("local");
          },
          function (err) {
            // MyService.ConsoleLog("Err", err);
          }
        );
      };

      /**
       * Filter ballot measures data date wise
       */
      $scope.balloType = [];
      $scope.bType = "local";
      $scope.getBallotByType = function (type) {
        $scope.shwLocal = false;
        $scope.shwCounty = false;
        $scope.shwSbCounty = false;
        $scope.shwState = false;

        if (!type) {
          $scope.type = "local";
        } else {
          $scope.type = type;
        }
        $scope.balloType = [];
        $scope.bType = type;
        if ($scope.ballot && $scope.ballot.length > 0) {
          var bLen = $scope.ballot.length;
        }
        // alert(bLen);

        if (bLen > 0) {
          for (var i = 0; i < bLen; i++) {
            // MyService.ConsoleLog("got ttnhthth type", $scope.ballot[i].electionDate + "     " + $scope.comp.election.electionDate);
            // if()
            if (
              $scope.comp.election.electionDate == $scope.ballot[i].electionDate
            ) {
              MyService.ConsoleLog(
                "$scope.ballot[i].voterScope",
                $scope.ballot[i].voterScope
              );
              switch ($scope.ballot[i].voterScope) {
                case "placeName":
                  $scope.shwLocal = true;
                  break;
                case "countyName":
                  $scope.shwCounty = true;
                  break;
                case "countySubdivision":
                  $scope.shwSbCounty = true;
                  break;
                case "statePostalCode":
                  $scope.shwState = true;
                  break;
              }
              if (
                $scope.type == "local" &&
                $scope.ballot[i].voterScope == "placeName"
              ) {
                $scope.balloType.push($scope.ballot[i]);
              } else if (
                $scope.type == "county" &&
                $scope.ballot[i].voterScope == "countyName"
              ) {
                $scope.balloType.push($scope.ballot[i]);
              } else if (
                $scope.type == "subCounty" &&
                $scope.ballot[i].voterScope == "countySubdivision"
              ) {
                $scope.balloType.push($scope.ballot[i]);
              } else if (
                $scope.type == "state" &&
                $scope.ballot[i].voterScope == "statePostalCode"
              ) {
                // $scope.shwState = true;
                $scope.balloType.push($scope.ballot[i]);
              }
            }
          }
          // MyService.ConsoleLog(" $scope.sortType", $scope.sortType);
        }
      };

      $scope.allState = [];
      $scope.MyDialog = "";
      $scope.displayContent = "";

      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.showHtmlViewer = function (content, ttl) {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allState = payload;
            $scope.states = payload;
            $scope.MyDialog = ngDialog.open({
              template: "guest_location",
              scope: $scope,
              // className: 'ngdialog-theme-default'
            });
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      // if ($rootScope.tempUserAddress && $rootScope.tempUserAddress != "") {
      //     //MyService.ConsoleLog("Inside If ");
      //     // ngDialog.close();

      //   $scope.getLocalPoliticians();
      // };

      $scope.MyDialog1 = "";
      $scope.showHtmlViewer1 = function (content, ttl) {
        // console.log(content);
        $scope.displayContent = $sce.trustAsHtml(
          content.replace(/(\r\n|\n|\r)/g, "<br />")
        );
        $scope.MyDialog1 = ngDialog.open({
          template: "statement",
          scope: $scope,
          // className: 'ngdialog-theme-default'
        });
      };

      $scope.allcounties = [];
      $scope.getCounties = function (lbl) {
        //console.log($scope.Location.state);
        // $scope.allcounties.length = 0;
        var statePostalCode = "";
        var state = "";
        if (lbl == "new") {
          // state = JSON.stringify($scope.oldaddress.state);
          statePostalCode = $scope.Location.state.stateFips;
        } else {
          statePostalCode = $scope.Location.state.stateFips;
          state = $scope.Location.state;
        }
        //// MyService.ConsoleLog(" $scope.allstates >", $scope.allstates);
        //// MyService.ConsoleLog("$scope.Location.state >", $scope.Location);
        //// MyService.ConsoleLog("statePostalCode >>", statePostalCode);
        // if (statePostalCode == "") {
        //  // MyService.ConsoleLog(" <<<<<<<<<<<< $scope.Location.state  new >", $scope.Location.state);
        //   statePostalCode = $scope.Location.state.stateFips;

        // }
        // MyService.ConsoleLog(" $scope.stateFips second", $scope.stateFips);

        MyService.getAllCounties($scope.stateFips, state).then(
          function (payload) {
            //console.log("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == "new") {
              var county = $scope.checkCounty(
                allcounties,
                $scope.oldaddress.county
              );
              $scope.allcounties = county;
              // console.log("county<><", county);
              $scope.Location.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        //console.log($scope.Location.state);
        //console.log($scope.Location.county);
        var county = "";
        var statePostalCode = "";
        $scope.MyPlaces.length = 0;

        var state = "";
        var county = "";
        if (lbl == "new") {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }

        // MyService.getAllSubCountiesLocal($scope.Location.state.statePostalCode, county._id.county).then(function(payload) {
        //     $scope.MyPlaces = payload;
        //     //console.log("PlaCE :", payload);

        //   },
        //   function(errorPayload) {
        //     growl.error('Something went wrong.');
        //   });
        console.log("Here inside ", county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            $scope.MyPlaces = payload;
            // console.log(payload);
            // var subcounties = payload.allsubcounty
            // var places = payload.allPlaces;
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // console.log("PlaCE :", payload);
            if (lbl == "new") {
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // console.log("subcounty<><><", subcounty)
              $scope.subcounties = subcounty;
              $scope.places = places;
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.Location.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.displayPlaces = [];
      $scope.displayVoterDistrict = [];
      $scope.displayFederalDistrict = [];
      $scope.displayStateSenate = [];
      $scope.displayStateLegislative = [];
      $scope.displaySchoolDistrict = [];
      $scope.getPlacesData = function (val) {
        var rdata1 = angular.copy($scope.MyPlaces.allPlaces);
        $scope.displayPlaces.length = 0;
        var newDat = rdata1.filter(function (rdata, idx) {
          if (rdata._id.countySubdivision == val._id.countySubdivision) {
            return rdata;
          }
        });
        $scope.displayPlaces = newDat;
      };

      $scope.getVoterDistrcitData = function (val) {
        var rdata1 = angular.copy($scope.MyPlaces.allVoterDistricts);
        var compareplace = val._id.place;
        $scope.displayVoterDistrict.length = 0;
        var newDat = rdata1.filter(function (rdata, idx) {
          if (rdata._id.place == compareplace) {
            return rdata;
          }
        });
        $scope.displayVoterDistrict = newDat;
        if ($scope.displayVoterDistrict.length == 1) {
          $scope.Location.voterDistrict = $scope.displayVoterDistrict[0];
        }

        var rdata2 = angular.copy($scope.MyPlaces.allfederalCongrassionalDist);
        $scope.displayFederalDistrict.length = 0;
        var newDat1 = rdata2.filter(function (rdata21) {
          if (rdata21._id.place == compareplace) {
            return rdata21;
          }
        });
        $scope.displayFederalDistrict = newDat1;
        if ($scope.displayFederalDistrict.length == 1) {
          $scope.Location.federalCongrassionalDist =
            $scope.displayFederalDistrict[0];
        }

        var rdata3 = angular.copy($scope.MyPlaces.allstateSenateDistrict);
        $scope.displayStateSenate.length = 0;
        var newDat2 = rdata3.filter(function (rdata31) {
          if (rdata31._id.place == compareplace) {
            return rdata31;
          }
        });
        $scope.displayStateSenate = newDat2;
        if ($scope.displayStateSenate.length == 1) {
          $scope.Location.stateSenateDistrict = $scope.displayStateSenate[0];
        }

        var rdata4 = angular.copy($scope.MyPlaces.allstateLegislativeDistrict);
        $scope.displayStateLegislative.length = 0;
        var newDat3 = rdata4.filter(function (rdata41) {
          if (rdata41._id.place == compareplace) {
            return rdata41;
          }
        });
        $scope.displayStateLegislative = newDat3;
        if ($scope.displayStateLegislative.length == 1) {
          $scope.Location.stateLegislativeDistrict =
            $scope.displayStateLegislative[0];
        }
        //console.log(newDat);

        var rdata5 = angular.copy($scope.MyPlaces.allschoolDistrict);
        $scope.displaySchoolDistrict.length = 0;
        var newDat4 = rdata5.filter(function (rdata51) {
          if (rdata51._id.place == compareplace) {
            return rdata51;
          }
        });
        $scope.displaySchoolDistrict = newDat4;
        if ($scope.displaySchoolDistrict.length == 1) {
          $scope.Location.schoolDistrict = $scope.displaySchoolDistrict[0];
        }
      };

      $scope.Location = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
        voterDistrict: "",
        federalCongrassionalDist: "",
        stateSenateDistrict: "",
        stateHouseOFRepresentativeDistrict: "",
        unifiedSchoolDistrictName: "",
      };

      if ($rootScope.tempUserAddress && $rootScope.tempUserAddress != "") {
        //   $rootScope.compareFinalData = JSON.stringify($rootScope.tempUserAddress);
        // $rootScope.tempUserAddress="";
        // $scope.users.length = 0;
        // $scope.page = 0;
        $rootScope.incuAdd = $rootScope.tempUserAddress;

        $scope.getLocalPoliticians();
      }
      $scope.showPopups = function () {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allState = payload;
            $scope.states = payload;
            $scope.MyDialog = ngDialog.open({
              template: "guest_location",
              scope: $scope,
              // className: 'ngdialog-theme-default'
            });
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.submitLocation = function (loc) {
        //console.log($scope.Location.state);
        //console.log($scope.Location.county);
        //console.log($scope.Location.place);
        // console.log("location<><><>",$scope.Location);
        // console.log("location2<><><>",loc);
        var finalData = new Object();
        if ($scope.Location.state && $scope.Location.state != "") {
          var nobj = new Object();
          nobj.statePostalCode = $scope.Location.state.statePostalCode;
          finalData.statePostalCode = $scope.Location.state.statePostalCode;
          finalData.state = $scope.Location.state.state;
        }
        if (
          $scope.Location.county &&
          $scope.Location.county.county &&
          $scope.Location.county.county != ""
        ) {
          $scope.Location.FinalData = JSON.stringify($scope.Location.county);
          finalData.county = $scope.Location.county.countyName;
        }
        if (
          $scope.Location.countySubdivision &&
          $scope.Location.countySubdivision.countySubdivision != ""
        ) {
          $scope.Location.FinalData = JSON.stringify(
            $scope.Location.countySubdivision
          );
          finalData.countySubdivision =
            $scope.Location.countySubdivision.countySubdivision;
        }
        if ($scope.Location.place && $scope.Location.place.place != "") {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.place = $scope.Location.place.placeName;
        }
        /*
      if ($scope.Location.voterDistrict && $scope.Location.voterDistrict.voterDistrictName != "") {
        //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
        finalData.voterDistrict = $scope.Location.voterDistrict.voterDistrictName;
      }
      */
        if (
          $scope.Location.cityCouncilDistrict &&
          $scope.Location.cityCouncilDistrict != ""
        ) {
          finalData.cityCouncilDistrictName =
            $scope.Location.cityCouncilDistrict.COUNTDIST;
        }
        if (
          $scope.Location.stateHouseOFRepresentativeDistrict &&
          $scope.Location.stateHouseOFRepresentativeDistrict.NAMELSAD != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          if (
            $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST &&
            $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST != ""
          ) {
            finalData.legislativeDistrict =
              $scope.Location.stateHouseOFRepresentativeDistrict.SLDLST;
          } else {
            var SLDLSTarr =
              $scope.Location.stateHouseOFRepresentativeDistrict.NAMELSAD.split(
                " "
              );
            var SLDLST = SLDLSTarr[SLDLSTarr.length - 1];
            finalData.legislativeDistrict = SLDLST;
          }
        }

        if (
          $scope.Location.federalCongrassionalDist &&
          $scope.Location.federalCongrassionalDist.CD115FP
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          if ($scope.Location.federalCongrassionalDist.CD115FP == "00") {
            finalData.federalCongrassionalDist = "At-Large";
          } else {
            finalData.federalCongrassionalDist =
              $scope.Location.federalCongrassionalDist.CD115FP;
          }
        }
        if (
          $scope.Location.stateSenateDistrict &&
          $scope.Location.stateSenateDistrict.SLDUST != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.stateSenateDistrict =
            $scope.Location.stateSenateDistrict.SLDUST;
        }
        /*
      if ($scope.Location.stateLegislativeDistrict && $scope.Location.stateLegislativeDistrict.stateHouseOFRepresentativeDistrict != "") {
        //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
        finalData.legislativeDistrict = $scope.Location.stateLegislativeDistrict.stateHouseOFRepresentativeDistrict;
      }
      */
        if (
          $scope.Location.unifiedSchoolDistrictName &&
          $scope.Location.unifiedSchoolDistrictName.NAME != ""
        ) {
          //$scope.Location.FinalData = JSON.stringify($scope.Location.place._id);
          finalData.schoolDistrict =
            $scope.Location.unifiedSchoolDistrictName.NAME;
        }
        // console.log(finalData);

        // console.log($scope.Location.FinalData);
        // if (finalData && finalData != "") {
        //// MyService.ConsoleLog("IF in finalData");
        $rootScope.compareFinalData = JSON.stringify(finalData);

        $rootScope.tempUserAddress = "";
        $scope.users.length = 0;
        $scope.page = 0;
        $scope.getLocalPoliticians();
      };
      // //MyService.ConsoleLog("$rootScope.compareFinalData :",$rootScope.compareFinalData);
      $scope.pop = true;

      // $rootScope.FinalData == "";
      // alert($routeParams.user_type);
      if ($routeParams.user_type) {
        if ($routeParams.user_type == "politician") {
          // console.log("inside politician");
          // MyService.ConsoleLog("inside one");

          // if($rootScope.compareFinalData)
          console.log("temp ", $rootScope.compareFinalData);
          if (
            !tokenService.getUserId() &&
            (!$rootScope.compareFinalData || $rootScope.compareFinalData == "")
          ) {
            // if (!tokenService.getUserId()) {
            $rootScope.compareFinalData = $rootScope.FinalData;
            if (loc) {
              MyService.getAllStates().then(
                function (payload) {
                  $scope.allState = payload;
                  $scope.states = payload;
                },
                function (errorPayload) {
                  growl.error("Something went wrong.");
                }
              );
            } else {
              //MyService.ConsoleLog(" data >>", $rootScope.tempUserAddress);
              // alert("else");
              if (!$rootScope.tempUserAddress) {
                $scope.showHtmlViewer();
              }
            }
          }

          if (tokenService.getUserId()) {
            var tempData = JSON.parse(tokenService.getUserData());
            // MyService.ConsoleLog("tempData", tempData);
            if (
              (tempData.address &&
                tempData.address.statePostalCode &&
                tempData.address.statePostalCode != "") ||
              (tempData.address.state && tempData.address.state != "")
            ) {
              $rootScope.compareFinalData = "";
              $scope.pop = false;
              $scope.getLocalPoliticians();
            } else {
              $scope.pop = true;
              $scope.showPopups();
            }
          }

          if (
            $rootScope.compareFinalData &&
            $rootScope.compareFinalData != ""
          ) {
            // alert("got");
            $scope.pop = false;
            $scope.getLocalPoliticians();
          }
        } else {
          console.log("Else >> ", $rootScope.compareFinalData);
          /*
         getUsers({
           usertype: $routeParams.user_type
         });
         */
        }
      }

      $scope.selectedUser = [];
      $scope.toggleSelection = function (uid) {
        //console.log("toggleSelection: ", uid);
        var idx = $scope.selectedUser.indexOf(uid);
        // is currently selected
        if (idx > -1) {
          $scope.selectedUser.splice(idx, 1);
        } else {
          $scope.selectedUser.push(uid);
        }
      };

      $scope.selectedUserPrint = [];
      $scope.togglePrintSelection = function (uid) {
        //console.log("togglePrintSelection: ", uid);
        var idx = $scope.selectedUserPrint.indexOf(uid);
        // is currently selected
        if (idx > -1) {
          $scope.selectedUserPrint.splice(idx, 1);
        }
        // is newly selected
        else {
          $scope.selectedUserPrint.push(uid);
        }
      };

      $scope.compare = function () {
        //console.log("compare :", $scope.selectedUser);
        if ($scope.selectedUser.length <= 0) {
          alert("Please select user first.");
        } else {
          $rootScope.selectedCompareUsers = $scope.selectedUser;
          // $location.path("/question-compare/all/"+$routeParams.category_id);
          $location.path(
            "/question-compare/all/" + $rootScope.categories[0]._id
          );
        }
      };

      $scope.compareOfficeWise = function (users) {
        $rootScope.selectedCompareUsers = [];
        angular.forEach(users, function (val, key) {
          //console.log("key: ", key, " val: ", val);
          if (val.registeredUser) {
            $rootScope.selectedCompareUsers.push(val);
          }
        });
        //console.log("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedCompareUsers.length <= 0) {
          growl.error("None of the users are registered user.");
        } else {
          $location.path(
            "/question-compare/all/" + $rootScope.categories[0]._id
          );
        }
      };

      $scope.compareRatings = function (users) {
        //MyService.ConsoleLog("users > ", users);
        $rootScope.selectedRatingUsers = [];
        angular.forEach(users, function (val, key) {
          //MyService.ConsoleLog("key123: ", key);
          //MyService.ConsoleLog(" val321: ", val);
          if (val.total_reviews > 0) {
            // alert("Here");
            console.log("valpoliticianIds >> ", val.politicianIds);
            if (!val.politicianIds) {
              var pids = [];
              pids.push(val._id);
              val["politicianIds"] = pids;
              console.log("val >>> ", val);
            }
            $rootScope.selectedRatingUsers.push(val);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedRatingUsers.length <= 0) {
          growl.error("None of the users you selected have ratings.");
        } else {
          $location.path("/compareRatings");
        }
      };

      $scope.compareOfficeWiseSubject = function (users) {
        //MyService.ConsoleLog("compare-subject : >>", users);
        $rootScope.selectedCompareUsers = [];
        angular.forEach(users, function (val, key) {
          console.log("key: ", key, " val: ", val);
          if (val.total_answers > 0 && val.isSelected) {
            $rootScope.selectedCompareUsers.push(val);
            // //MyService.ConsoleLog("$rootScope.selectedCompareUsers :",$rootScope.selectedCompareUsers);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedCompareUsers.length <= 0) {
          growl.error(
            "None of the selected users have answered any questions."
          );
        } else {
          $location.path("/compare-subject/" + $routeParams.user_type);
        }
      };
      $scope.compareSubjectWise = function () {
        //console.log("compare :", $scope.selectedUser);
        if ($scope.selectedUser.length <= 0) {
          alert("Please select user first.");
        } else {
          $rootScope.selectedCompareUsers = $scope.selectedUser;
          $location.path("/compare-subject/" + $routeParams.user_type);
        }
      };

      $scope.printSelectedUsers = function () {
        //console.log("* printSelectedUsers *", $scope.selectedUserPrint);
        if ($scope.selectedUserPrint.length > 0) {
          $rootScope.printUsers = angular.copy($scope.selectedUserPrint);
          $location.path("/print-users");
        } else {
          growl.error("Please select user first.");
        }
      };

      $scope.goToPoliticianThread = function (officename, users, userData) {
        //console.log("goToPoliticianThread: ", users, " officename: ", officename, " userData: ", userData);
        $rootScope.politicianThreadData = {
          // office: officename,
          users: users,
          selected_user: userData,
        };
        window.localStorage.setItem(
          "politicianThreadData",
          JSON.stringify($rootScope.politicianThreadData)
        );
        $location.path("/politician-thread/" + userData._id);
      };

      $scope.search = {
        searchFilter: "",
      };
      $scope.filteredSearch = "";
      $scope.searchOffice = function (office) {
        // if($scope.search.searchFilter.length > 1 ) {
        //     //if($scope.searchUsers.length!=$scope.users.length) {
        //         $scope.searchUsers = angular.copy($scope.users);
        //     //}
        // }
        $scope.searchUsers = $scope.users.filter(function (rdata) {
          // console.log(rdata.office + "==" + office);
          var idx = rdata.office
            .toLowerCase()
            .indexOf($scope.search.searchFilter.toLowerCase());
          // console.log(idx);
          if (idx > -1) return rdata;
        });
        //$scope.$apply();
        // if (teamIsNew) {
        //   indexedTeams.push(player.team);
        // }
        // return teamIsNew;
      };
      $scope.userAddress = "";
      $scope.zipcode = "";
      $scope.updateAddress = function () {
        var address_data = $scope.userAddress;
        address_data.zipcode = $scope.zipcode;
        // console.log("********* Data: ", address_data);
        ngDialog.open({
          template: "app/popups/user_localupdate.html",
          data: address_data,
          // className: 'ngdialog-theme-default'
        });
      };
      $scope.string = {
        showLink: "hide",
      };

      $scope.showZipLoader = false;
      $scope.showAddressDropDown = false;

      $scope.checkState = function (rdata, val) {
        //MyService.ConsoleLog("State :", rdata);
        //MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        //MyService.ConsoleLog("County :", rdata);
        //MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // //MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // //MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // console.log(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // console.log("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // console.log(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // console.log(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // console.log("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.emptyOrNull = function (item) {
        return !(
          item._id.place === null ||
          item._id.place === "" ||
          item._id.place === " "
        );
      };

      /**
       * Get ballot thread
       */
      $scope.goToBallotThread = function (userData) {
        console.log("goToPoliticianThread: ", userData);
        var tempArr = [];
        tempArr.push(userData);
        $rootScope.ballotThread = {
          users: tempArr,
          selected_user: userData,
        };
        window.localStorage.setItem(
          "ballotThreadData",
          JSON.stringify($rootScope.ballotThread)
        );
        $location.path("/ballotThread/" + userData._id);
      };

      $scope.showAddressDropDowns = function (zipcode, loc) {
        // console.log("yogy", loc);
        if (loc) {
          // console.log("welcome to if");
          $scope.showZipLoader = true;
          MyService.getOrganizedZipData({
            zipcode: zipcode,
            candidate: true,
            loc: loc,
          }).then(
            function (payload) {
              // console.log(payload);
              // MyService.ConsoleLog(" << payload >>", payload);
              if (payload && payload.state) {
                // var finalData = payload.data;
                // console.log(payload[0]);
                // console.log(payload[0].countySubdivision);
                // console.log(payload[0].county);
                // console.log(payload[0].place);

                $scope.stateFips = payload.state[0].STATEFP;

                // MyService.ConsoleLog("$scope.stateFips >>", $scope.stateFips);

                var countySubdivisions = payload.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // console.log("Inside cs");
                    return obj;
                  }
                });

                var places = payload.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // console.log("obj > ", obj);
                    return obj;
                  }
                });

                // console.log("here");
                $scope.showZipLoader = false;
                $scope.showAddressDropDown = true;
                $scope.oldaddress.state = payload.state;
                $scope.oldaddress.county = payload.county;

                // console.log("countySubdivisions >> ", countySubdivisions);
                $scope.oldaddress.countySubdivision = countySubdivisions;
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");
                // $scope.voterDistricts = payload;
                $scope.federalCongrassionalDist = payload.congressionalDistrict;
                console.log("@@@@@@drop :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = payload.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  payload.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames =
                  payload.unifiedSchoolDistrict;
                $scope.cityCouncilDistrict = payload.cityCouncilDistrict;
                //$scope.$apply();
                // console.log($scope.states);
                // console.log(payload[0].statePostalCode);
                var address = $scope.checkState($scope.states, payload.state);
                $scope.allstates = address;
                // console.log("address<>", address);
                $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                $scope.Location.cityCouncilDistrict =
                  $scope.cityCouncilDistrict[0];
                // $scope.submitLocation('');
                // console.log("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        } else {
          // console.log("welcome to esle");
          $scope.showZipLoader = true;
          MyService.getOrganizedZipData({
            zipcode: zipcode,
            candidate: true,
          }).then(
            function (payload) {
              console.log(payload);
              if (payload && payload.state) {
                // var finalData = payload.data;
                // console.log(payload[0]);
                // console.log(payload[0].countySubdivision);
                // console.log(payload[0].county);
                // console.log(payload[0].place);

                var countySubdivisions = payload.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // console.log("Inside cs");
                    return obj;
                  }
                });

                var places = payload.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // console.log("obj > ", obj);
                    return obj;
                  }
                });

                // console.log("here");
                $scope.showZipLoader = false;
                $scope.showAddressDropDown = true;
                $scope.oldaddress.state = payload.state;
                $scope.oldaddress.county = payload.county;

                // console.log("countySubdivisions >> ", countySubdivisions);
                $scope.oldaddress.countySubdivision = countySubdivisions;
                $scope.oldaddress.place = places;
                $scope.getCounties("new");

                $scope.getSubCounties("new");
                // $scope.voterDistricts = payload;
                // console.log(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = payload.congressionalDistrict;
                // console.log("hello :", $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = payload.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  payload.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames =
                  payload.unifiedSchoolDistrict;
                //$scope.$apply();
                // console.log($scope.states);
                // console.log(payload[0].statePostalCode);
                var address = $scope.checkState($scope.states, payload.state);
                $scope.allstates = address;
                // console.log("address<>", address);
                $scope.Location.state = address[0];
                // $scope.Location.voterDistrict = $scope.voterDistricts[0];
                $scope.Location.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.Location.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.Location.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.Location.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                // console.log("address ; ", address);
              } else {
                $scope.showZipLoader = false;
                alert("Zipcode not found in our database. Please correct it");
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }
      };

      if (loc && loc != "") {
        console.log("location");
        $scope.showAddressDropDowns("", loc);
      } else {
        // console.log("else");

        $scope.detectCode = function (event) {
          // console.log(event);
          // console.log(event.keyCode);
          //MyService.ConsoleLog("callded");
          if (event.keyCode == 9 && event.key == "Tab") {
            $("#zipcodecompareinput").blur();
            $scope.showAddressDropDowns($scope.Location.ZipCode);
          }
          if (event.keyCode == 13 && event.key == "Enter") {
            $("#zipcodecompareinput").blur();
            $scope.showAddressDropDowns($scope.Location.ZipCode);
          }
        };
      }

      $scope.checkLength = function (vdata, val) {
        //MyService.ConsoleLog("vdata", vdata);
        if (vdata && vdata.length > 0) {
          var data = vdata.map(function (sub) {
            var returnval = "";
            switch (val) {
              case "federalCongrassionalDist":
                returnval = sub.CD115FP;
                break;
              case "stateSenateDistrict":
                returnval = sub.SLDUST;
                break;
              case "stateHouseOFRepresentativeDistrict":
                returnval = sub.NAMELSAD;
                break;
              case "unifiedSchoolDistrictName":
                returnval = sub.NAME;
                break;
              case "place":
                returnval = sub.place;
                break;
              case "countySubdivision":
                returnval = sub.countySubdivision;
                break;
              case "county":
                returnval = sub.county;
                break;
              case "state":
                returnval = sub.state;
                break;
              case "cityCouncilDistrict":
                returnval = sub.COUNTDIST;
                break;
              case "default":
                returnval = sub.NAME;
                break;
            }
            return returnval;
          });

          var ndata = $filter("unique")(data);
          if (ndata.length > 1) {
            return "yellowBackground";
          } else {
            return "";
          }
        } else {
          return "";
        }
      };
      $scope.user = {
        flagSkipAnsweredQuestion: "YES",
      };
      if ($rootScope.UserId) {
        MyService.getProfile($rootScope.UserId).then(
          function (data) {
            // body...

            var address_data = data.address;
            //MyService.ConsoleLog(" address_data :", address_data);
            $scope.zipcode = data.zipcode;
            // console.log(address_data.legislativeDistrict);
            if (!address_data.legislativeDistrict) {
              $scope.string.showLink = "show";
              // console.log("legislativeDistrict");
            }
            // console.log(address_data.voterDistrict);
            if (!address_data.voterDistrict) {
              $scope.string.showLink = "show";
              // console.log("voterDistrict");
            }
            // console.log(address_data.voterDistrictFips);

            // console.log(address_data.schoolDistrict);
            if (!address_data.schoolDistrict) {
              $scope.string.showLink = "show";
              // console.log("schoolDistrict");
            }

            // console.log(address_data.stateSenateDistrict);
            if (!address_data.stateSenateDistrict) {
              $scope.string.showLink = "show";
              // console.log("stateSenateDistrict");
            }
            // console.log(address_data.federalCongrassionalDist);
            if (!address_data.federalCongrassionalDist) {
              $scope.string.showLink = "show";
              // console.log("federalCongrassionalDist");
            }
            // console.log(address_data.state);
            if (!address_data.state) {
              $scope.string.showLink = "show";
              // console.log("state");
            }
            // console.log(address_data.statePostalCode);
            if (!address_data.statePostalCode) {
              $scope.string.showLink = "show";
              // console.log("statePostalCode");
            }
            // console.log(address_data.countySubdivision);
            if (!address_data.countySubdivision) {
              $scope.string.showLink = "show";
              // console.log("countySubdivision");
            }
            // console.log(address_data.county);
            if (!address_data.county) {
              $scope.string.showLink = "show";
              // console.log("county");
            }
            // console.log(address_data.place);
            if (!address_data.place) {
              $scope.string.showLink = "show";
              // console.log("place");
            }
            // console.log($scope.string.showLink);
            $scope.userAddress = address_data;
            //$scope.userAddress.zipcode = data.address.zipcode;
            // if ($filter("isBlankString")(address_data.voterDistrict)) {
            //   address_data.zipcode = data.zipcode;
            //   //console.log("Data: ",data);
            //   ngDialog.open({
            //     template: 'popups/user_localupdate.html',
            //     data: data.address
            //     // className: 'ngdialog-theme-default'
            //   });
            // } //isBlankString voterDistrict
          },
          function (err) {
            //console.log("********* err: ", err);
          }
        );
      }
    },
  ]);
