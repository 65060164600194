'use strict';
angular
  .module('myApp.federalBills', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/federalBills', {
        templateUrl: 'app/federalBills/federalBills.html',
        controller: 'federalBillsCtrlOne'
      });
    }
  ])
  .controller('federalBillsCtrlOne', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$routeParams',
    '$q',
    "growl",
    function($scope, ngDialog, $rootScope, MyService, $routeParams, $q, growl) {
      $scope.fData = [];

      $scope.userRole = $rootScope;
      $scope.currPage = 1;
      // MyService.ConsoleLog("$rootScope.userRole >>", $scope.userRole);

      $scope.loader = false;
      $scope.showOne = false;
      $scope.searchTool = false;
      $scope.moreTool = false;
      $scope.search = false;




      $scope.selType = {
        "congress": "116",
        "title": "",
        "law": true,
        "vote": true,
        "selHouse": [],
        "selSenate": [],
        "sponsor": false,
        "cosponsor": false,
        "house": "",
        "senate": "",
        "action": "",
        "com": ""
      }

      /*
       **@ function for lazzy load
       */

      // $scope.myPagingFunction = function() {
      //   // MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
      //   if ($scope.statusGetResPg != 1 && $scope.serachData > $scope.fData.length) {
      //     $scope.currPage++;
      //     $scope.pageChanged($scope.currPage);
      //   }
      // };

      // $scope.pageChanged = function(newPage) {
      //   $scope.getFederalData(newPage);
      // };








      /*
            $scope.getFederalData = function(pageNumber) {
              MyService.ConsoleLog('pageNumber >', pageNumber);
              var params = {
                page: pageNumber,
                max_records: 30
              };



              $scope.statusGetResPg = 1;

              var getAllCanceller = $q.defer();


              MyService.getFederalData(params, getAllCanceller).then(
                function(result) {
                  console.log("RESPONSE >  ", result);
                  $scope.fData = $scope.fData.concat(result.data.data.data);
                  MyService.ConsoleLog('$scope.open >', $scope.fData);
                  $scope.serachData = result.data.data.count;
                  MyService.ConsoleLog("count >", $scope.serachData);

                  // $scope.totalUsers = payload.Count;
                  // MyService.ConsoleLog(payload.Items);
                  $scope.statusGetResPg = 2;
                },
                function(err) {
                  // growl.error('Something went wrong.');
                  $scope.statusGetResPg = 3;
                  $scope.page--;
                }
              );
            };
      */



      MyService.ConsoleLog("$rootScope", $rootScope.flag);

      /*
            $scope.getFederalData();
      */

      $scope.pageFunction = function() {
        // MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        // MyService.ConsoleLog("called");
        if ($scope.statusGetResPg != 1 && $scope.countData > $scope.fedData.length) {
          // MyService.ConsoleLog("length");
          $scope.currPage++;
          $scope.changedPage($scope.currPage);
        }
      };

      $scope.changedPage = function(newPage) {
        $scope.SearchBy(newPage);
      };

      $scope.fedData = [];


      $scope.searchFun = function(params, canceller) {

        MyService.SearchBills(params, canceller).then(function(response) {
          $scope.showOne = true;
          $scope.loader = false;

          if (response.data.error) {
            growl.error(response.data.error)
          } else {
            console.log("result >>", response);
            $scope.searchTool = false;
            // $rootScope.para = "";
            // MyService.ConsoleLog("$rootScope >", $rootScope.para);

            $scope.search = true;

            $scope.fedData = $scope.fedData.concat(response.data.data.result);
            // MyService.ConsoleLog('$scope.fede >', $scope.fedData);
            $scope.countData = response.data.data.bcount;
            $scope.sim = "";
            $scope.statusGetResPg = 2;

          }

        }, function(err) {
          MyService.ConsoleLog("error >", err);
          $scope.statusGetResPg = 3;
          $scope.page--;
        })

      }

      /*
       **@ function get records of federal bills
       */

      $scope.SearchBy = function(pageNumber, val) {
        $scope.loader = true;
        $rootScope.para = "";

        // MyService.ConsoleLog("para >", val);
        if (val && val != "") {
        $scope.fedData.length = 0;

        }
        MyService.ConsoleLog("pageNumber >", pageNumber);

        // MyService.ConsoleLog("params >>", $scope.selType);
        // MyService.ConsoleLog("params >>", Object.keys($scope.selType.selHouse));
        if ($scope.selType.senate == "Any Senator" || $scope.selType.house == "Any Representative") {

          if ($scope.selType.senate == "Any Senator" && $scope.selType.house == "Any Representative") {
            $scope.selType.senate = "";
            $scope.selType.house = "";

          } else if ($scope.selType.house == "Any Representative") {
            $scope.selType.house = "";

          } else {
            $scope.selType.senate = "";

          }
        }

        if ($scope.selType.action == "Any Legislative Action") {
          $scope.selType.action = "";
        }
        $scope.sim = {};

        $scope.sim.selHouse = Object.keys($scope.selType.selHouse);
        $scope.sim.selSenate = Object.keys($scope.selType.selSenate)

        // MyService.ConsoleLog("Sim >>", $scope.sim);
        $scope.selType.com = $scope.sim;
        MyService.ConsoleLog("params >.", $scope.selType);
        $scope.searchObj = {
          page: pageNumber,
          max_records: 30,
          para: $scope.selType,
          flag: $scope.searchTool

        }


        // var obj = {
        //   page: pageNumber,
        //   max_records: 30,
        //   para: $scope.selType,
        //   flag: $scope.searchTool
        // };

        // MyService.ConsoleLog("obj>", obj);



        $scope.statusGetResPg = 1;

        $scope.getAllCanceller = $q.defer();

        // MyService.ConsoleLog("selType ", $scope.selType.title);

        if ($scope.selType.title != "") {
          //alert("if");
          // if ($scope.selType.title && $scope.selType.title.length >= 4) {
          $scope.searchFun($scope.searchObj, $scope.getAllCanceller);

          // } else {
          //   growl.error("Please enter at least 4 characters");
          // }


        } else {

          if (!$scope.searchTool) {
            //alert($scope.searchTool);
            $scope.searchFun($scope.searchObj, $scope.getAllCanceller);


          } else {
            //alert($scope.searchTool);

            if ($scope.selType.house != "" || $scope.selType.senate != "") {
              if ($scope.selType.house != "") {
                if ($scope.selType.sponsor == true || $scope.selType.cosponsor == true) {

                  if ($scope.selType.sponsor == true) {
                    $scope.searchFun($scope.searchObj, $scope.getAllCanceller);
                  } else {
                    $scope.searchFun($scope.searchObj, $scope.getAllCanceller);
                  }

                } else {
                  //alert('one');
                  growl.error("Please select atleast one checkbox  sponsor or cosponsor");
                }

              } else {
                if ($scope.selType.sponsor == true || $scope.selType.cosponsor == true) {

                  if ($scope.selType.sponsor == true) {
                    $scope.searchFun($scope.searchObj, $scope.getAllCanceller);
                  } else {
                    $scope.searchFun($scope.searchObj, $scope.getAllCanceller);
                  }

                } else {
                  //alert('two');

                  growl.error("Please select atleast one checkbox  sponsor or cosponsor");
                }

              }
            } else {
              $scope.searchFun($scope.searchObj, $scope.getAllCanceller);
            }
          }




          // $scope.searchFun(obj, getAllCanceller);


          // //alert("else");
        }




        // MyService.ConsoleLog("params >>", $scope.selType.selHouse[0] + "&&&&&&&&&&&&&&&&&&&&&&&&" + $scope.selType.selSenate[0]);


        // if ($scope.selType.title && $scope.selType.title != "" || $scope.selType.subTopics && $scope.selType.title != "" || $scope.selType.policyArea && $scope.selType.policyArea != "") {


        // } else {
        //   growl.error("Please fill the search box");
        // }
      }


      $scope.getDetailPage = function(id) {

        if ($rootScope.para != "") {
          $rootScope.para = $rootScope.para;
          // alert("else");
        } else {

          $rootScope.para = $scope.searchObj;
        }
        window.location.href = '#!/federalDetail/' + id;
      }

      /*
       **@ function calls on page load
       */

      // var fla = window.localStorage.getItem('flagOne');
      // alert(fla);

      var fone = window.localStorage.getItem('flagOne');
      MyService.ConsoleLog("f;actionType >>", fone);
      if (fone == 'true') {

        // alert("if");
        // var data = JSON.stringify($rootScope.para);
        $scope.searchFun($rootScope.para, $scope.getAllCanceller);
        console.log("Here >>> ", $rootScope.para);
        window.localStorage.setItem('flagOne', false);


      } else {
        // alert("else");

        console.log("FedData >> ", $scope.fedData);
        window.localStorage.setItem('flagOne', false);
        $scope.SearchBy();
      }



      // $scope.$on('$viewContentLoaded', function(event) {
      //   MyService.ConsoleLog("$rootScope >", $rootScope.flag);

      //   MyService.ConsoleLog("$scope.fedData >", $scope.fedData);
      //   MyService.ConsoleLog("$scope.event >", event);
      //   // alert("welcome");

      // });



      $scope.more = function() {
        $scope.searchTool = true;
        // $scope.moreTool = true;


      }

      $scope.getCommittees = function() {

        MyService.fedCommittees().then(function(response) {

          MyService.ConsoleLog("Got response >>", response);
          $scope.senate = response.Senate;
          $scope.house = response.House;
          $scope.actionType = response.actionTypes;

        }, function(err) {
          MyService.ConsoleLog("Error >", err);
        })

      }
      $scope.getCommittees();

      $scope.getCandidate = function() {
        MyService.getCandi().then(function(response) {

          MyService.ConsoleLog("response >", response);
          $scope.senateRepresent = response.senateRepresent;
          $scope.houseRepresent = response.houseRepresent;

        }, function(err) {
          MyService.ConsoleLog("Error >>", err);
        });
      }

      $scope.getCandidate();

      // $scope.getCheck = function() {
      //   MyService.ConsoleLog("Check >", $scope.selType.selHouse);
      // }

      $scope.reset = function() {
        $scope.searchTool = false;

      }

    }
  ]);
