'use strict';
angular.module('myApp.uploadPress', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/uploadPress', {
      templateUrl: 'app/uploadPress/uploadPress.html',
      controller: 'UploadPressCtrl'
    });
  }]) .controller('UploadPressCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window) {
    $scope.upload = {
      files: '',
      opt: 'Update'
    };
    $scope.showPressList = true;
    $scope.press = {
      data: []
    };
    $scope.PressButton = false;
    $scope.getAllPress = function () {
      $scope.press.data.length = 0;
      MyService.getAllOrganizations({
        type: "Press"
      }).then(function (payload) {
        $scope.press.data = payload;

      }, function (err) {
        //MyService.ConsoleLog("got states error");
        // growl.error("Some");
      });
    }

    $scope.allLocations = [];
    $scope.getAllStates = function () {
      $scope.allLocations.length = 0;
      MyService.getAllStates().then(function (data) {
        //MyService.ConsoleLog("got states");
        $scope.allLocations = data;
      }, function (err) {
        //MyService.ConsoleLog("got states error");
        // growl.error("Some");
      });
    }

    $scope.updatePress = function (data) {
      if (data.files != "") {
        //MyService.ConsoleLog(data.files.type.indexOf("office"));
        //alert("This needs to be implemented");

        if (data.files.type.indexOf("office") > -1) {

          $scope.PressButton = true;
          //MyService.ConsoleLog("data : ", data);
          var fd = new FormData();
          fd.append('file', data.files);
          fd.append('options', data.opt);
          var posturl = baseApiUrl + "/user/uploadPress";
          $http.post(posturl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            })
            .success(function (response, status, headers, config) {
              //MyService.ConsoleLog("response : ", response);
              if (response.success) {
                //$location.path('/learnHome');
                $scope.PressButton = false;
                $scope.press.data.length = 0;
                $scope.getAllPress();
                alert("File successfully imported.");
              } else {
                alert("Something went wrong.");
              }
            })
            .error(function (data, status, headers, config) {
              //MyService.ConsoleLog("error");
            });
        } else {
          alert("Please select xlsx file");
        }

      } else {
        alert("Please select file");
      }

    }

    $scope.checkState = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val)
            return rdata
        }
      );
    }
    $scope.showExportLink = true;
    $scope.exportData = function () {
      $scope.showExportLink = false;
      MyService.exportOrganizationData({
        "type": "press"
      }).then(function (payload) {
        //MyService.ConsoleLog("Success Data", payload);
        $scope.showExportLink = true;
        $window.open($scope.imgUrl + '/press.xlsx', '_blank');
      }, function (errorPayload) {
        $scope.showExportLink = true;
        //MyService.ConsoleLog("errorPayload : ", errorPayload);
        alert("Something went wrong. Please try again after sometime");
      })
    }

    $scope.MyCounties = [];
    $scope.MyPlaces = [];
    $scope.allCounties = [];
    $scope.checkCountyExists = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata._id.county == val)
            return rdata._id
        }
      );
    }

    $scope.checkLocalExists = function (rdata, val, val1) {
      return rdata.filter(
        function (rdata) {
          if ((rdata._id.place == val) && (rdata._id.countySubdivision == val1))
            return rdata._id
        }
      );
    }

    $scope.getCounties = function (state) {
      $scope.MyCounties.length = 0;
      if (typeof state == "string") {
        state = JSON.parse(state);
      }
      MyService.getAllCounties(state.statePostalCode).then(function (payload) {
          //MyService.ConsoleLog("sublocations Locals : ", payload);
          //$scope.showLoading.length = 0;
          $scope.MyCounties = payload;
          //MyService.ConsoleLog("getCounty checkdata: ", $scope.ShowData.address.county);
          if ($scope.ShowData.address.county) {
            var chkdata = $scope.checkCountyExists(payload, $scope.ShowData.address.county);
            //MyService.ConsoleLog("getCounty checkdata: ", chkdata);
            if (chkdata != "") {

              $scope.ShowData.county = JSON.stringify(angular.copy(chkdata[0]._id));
            }
          }
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }
    $scope.allLocals = [];
    $scope.getLocals = function (state, county) {
      if (typeof state == "string") {
        state = JSON.parse(state);
      }
      if (typeof county == "string") {
        county = JSON.parse(county);
      }
      MyService.getAllSubCountiesLocal(state.statePostalCode, county.county).then(function (payload) {
          $scope.MyPlaces = payload;
          //MyService.ConsoleLog("PlaCE :", $scope.ShowData.address.place);
          if ($scope.ShowData.address.place) {
            var chkdata = $scope.checkLocalExists(payload, $scope.ShowData.address.place, $scope.ShowData.address.countySubdivision);
            //MyService.ConsoleLog("getLocal checkdata: ", chkdata[0]);
            if (chkdata != "") {
              var idx = $scope.MyPlaces.indexOf(chkdata[0]);
              //MyService.ConsoleLog(idx);
              //MyService.ConsoleLog(angular.copy($scope.MyPlaces[idx]._id));
              $scope.ShowData.Locality = JSON.stringify(angular.copy($scope.MyPlaces[idx]._id));
            }
          }
        },
        function (errorPayload) {
          growl.error('Something went wrong.');
        });
    }

    $scope.dataIdx = "";
    $scope.showPress = function (data, idx) {
      //MyService.ConsoleLog(data);
      $scope.ShowData = data;
      //MyService.ConsoleLog("data : ", data);
      $scope.dataIdx = $scope.press.data.indexOf(data);
      var chkData = $scope.checkState($scope.allLocations, data.address.statePostalCode);
      //MyService.ConsoleLog("chkData : ", chkData[0]);
      $scope.ShowData.stateData = JSON.stringify(chkData[0]);
      /*
      if(data.location_of_press_interest) {
          var specialTowns = data.location_of_press_interest;
          var len = specialTowns.length;
          $scope.towns = [];
          for(var i=0; i< len; i++) {
              $scope.towns.push(specialTowns[i].place);
              if(i==len - 1) {
                  $scope.ShowData.towns = $scope.towns.join("; ");
              }
          }

      }
      */
      $scope.getCounties(data.address);
      $scope.getLocals(data.address, data.address);
      $scope.showPressList = false;
    }


    $scope.updatePressData = function (data) {

      if ($.fn.validateForceFully($("#pressform")) == true) {
        var state = JSON.parse(data.stateData);
        var county = "";
        var place = "";
        if (data.county) {
          county = JSON.parse(data.county);
        }
        if (data.Locality) {
          place = JSON.parse(data.Locality);
        }
        //data.address =
        data.state = state;
        if (data.contact_person) {
          data.contact_person = JSON.stringify(data.contact_person);
        }
        if (data.editor) {
          data.editor = JSON.stringify(data.editor);
        }
        data.county = county;
        data.place = place;
        //MyService.ConsoleLog(data);
        if (place != "") {
          var chkData = $scope.checkState($scope.allLocations, place.statePostalCode);
          //MyService.ConsoleLog("chkData : ", chkData[0]);
          if (chkData[0]) {
            data.statename = chkData[0].state;
          }
        }

        MyService.updateOfficeData(data).then(function (payload) {
          //MyService.ConsoleLog("payload : ", payload);
          data.contact_person = JSON.parse(data.contact_person);
          data.editor = JSON.parse(data.editor);
          $scope.press.data[$scope.dataIdx] = data;

          $scope.showPressList = true;

        }, function (err) {
          alert("Something went wrong");
        })
      }
    }

    $scope.registerAdvocateData = function (data) {
      if ($.fn.validateForceFully($("#pressform")) == true) {
        if ((data.contact_person.email != "") && (data.contact_person.email != null)) {
          var state = JSON.parse(data.stateData);
          var county = JSON.parse(data.county);
          var place = JSON.parse(data.Locality);
          //data.address =
          data.state = state;
          data.contact_person = JSON.stringify(data.contact_person);
          data.county = county;
          data.place = place;
          //MyService.ConsoleLog(data);
          var chkData = $scope.checkState($scope.allLocations, place.statePostalCode);
          //MyService.ConsoleLog("chkData : ", chkData[0]);
          if (chkData[0]) {
            data.statename = chkData[0].state;
          }


          MyService.registerAdvocateData(data).then(function (payload) {
            ////MyService.ConsoleLog("payload : ", payload);
            //data.contact_person = JSON.parse(data.contact_person);
            MyService.updateOfficeData(data).then(function (payload) {
              //MyService.ConsoleLog("payload : ", payload);
              data.contact_person = JSON.parse(data.contact_person);
              $scope.press.data[$scope.dataIdx] = data;
              $scope.showPressList = true;
            }, function (err) {
              alert("Something went wrong");
            })
          }, function (err) {
            alert("Something went wrong");
          })
        } else {
          alert("Please enter email ID to create user");
        }

      }
    }

    $scope.goBack = function () {
      $scope.showPressList = true;
    }

    if ($rootScope.showPress) {
      $scope.getAllPress();
      $scope.getAllStates();
      //alert("Still working on this page functionality.");
      $("#pressform").validationEngine();
    }

  }])