'use strict';
angular
  .module('myApp.listOfFollowers', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/listOfFollowers/:quizName?/:poliId?', {
        templateUrl: 'app/listOfInvitedFollowers/listOfFollowers.html',
        controller: 'listOfFollowersCtrl',
      });
    },
  ])
  .controller('listOfFollowersCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      /**
       * Get params from the route params
       */
      $scope.quizName = $routeParams.quizName;
      $scope.poliId = $routeParams.poliId;

      /**
       *  Declartion
       */
      $scope.fArr = [];

      /**
       * Remove localstorage values on page load
       */
      window.localStorage.removeItem('compareData');
      window.localStorage.removeItem('quizNameNw');
      window.localStorage.setItem('quizNameNw', $scope.quizName);


      /**
       * Get invited politicians info
       */
      $scope.getInfo = function () {

        MyService.getInfoOfInvited({
          orgId: tokenService.getUserId(),
          quizName: $scope.quizName
        }).then(
          function (result) {
            MyService.ConsoleLog("Results", result);
            $scope.polData = result.data.data;
            $scope.polData.filter(function (itms) {
              if (itms.id == $scope.poliId) {
                itms.isSelected = true;
                if ($scope.fArr.indexOf(itms.id) == -1) {
                  $scope.fArr.push(itms.id);
                }
                return itms;
              } else {
                return itms;
              }
            });
          },
          function (err) {
            MyService.ConsoleLog("Error", err);
          })
      };

      $scope.getInfo();

      /**
       * Filter Data
       */

      // $scope.getItems = function () {
      //   return function (u) {
      //     if (u.id == $scope.poliId) {

      //       u.isSelected = true;
      //       if ($scope.fArr.length == 0) {
      //         MyService.ConsoleLog("$scope.fArr fill", $scope.fArr);
      //       } else {
      //         MyService.ConsoleLog("$scope.fArr fill else", $scope.fArr);
      //       }

      //       // if ($scope.fArr.indexOf(u.id) == -1) {
      //       //   $scope.fArr.push(u.id);
      //       // }
      //       return u;
      //     } else {
      //       return u;
      //     }
      //   };
      // };

      /**
       * Select Individuals
       */
      $scope.select = function (uid, qName, name, val) {
        var obj = {};
        MyService.ConsoleLog(" $scope.fArr", $scope.fArr);
        if ($scope.fArr.length > 0) {

          if ($scope.fArr.indexOf(uid) == -1) {
            $scope.fArr.push(uid);
          } else {
            var idx = $scope.fArr.indexOf(uid);
            MyService.ConsoleLog("idx", idx);
            $scope.fArr.splice(idx, 1);
            val.isSelected = false;
          }
        } else {
          $scope.fArr.push(uid);
        }
        MyService.ConsoleLog(' $scope.farr', $scope.fArr);
        if ($scope.fArr.length == $scope.polData.length) {
          $scope.isSelected = true;
        } else {
          $scope.isSelected = false;
        }
      };

      /**
       * Select all the followers
       */

      $scope.isSelected = false;

      $scope.SelectChecks = function () {

        if ($scope.isSelected == false) {
          $scope.isSelected = true;
          angular.forEach($scope.polData, function (val, key) {
            val.isSelected = true;
            if ($scope.fArr.indexOf(val.id) == -1) {
              $scope.fArr.push(val.id);
            }
          });
          MyService.ConsoleLog('  $scope.fArr', $scope.fArr);
        } else {
          $scope.isSelected = false;
          angular.forEach($scope.polData, function (val, key) {
            val.isSelected = false;
            $scope.fArr = [];
            $scope.selectedQuestions = 0;
          });
        }
      };

      /**
       * Compare all records
       */
      $scope.compare = function () {

        if ($scope.fArr && $scope.fArr.length > 0) {
          MyService.ConsoleLog("$scope.fArr", $scope.fArr);
          window.localStorage.setItem('compareData', JSON.stringify($scope.fArr));
          $location.path('/poliCompare/' + $scope.quizName + '/' + $scope.poliId);
        } else {
          growl.error("Please select at least one politician");
        }
      };

      /**
       * Select type
       */
      $scope.selectType = function () {
        MyService.ConsoleLog("types", $scope.types);
        if ($scope.types == 'All') {
          $scope.getInfo();
        } else {
          MyService.getSeletedTypes({
            type: $scope.types,
            orgId: tokenService.getUserId(),
            quizName: $scope.quizName
          }).then(function (response) {
            MyService.ConsoleLog("Response", response);
            $scope.polData = null;
            $scope.polData = response.data.data;
          }, function (err) {
            MyService.ConsoleLog("Error", err);
          });
        }
      };
    }
  ]);
