'use strict';
angular.module('myApp.forumList', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/forumList/:categoryId/:subcatId/:type', {
      templateUrl: 'app/forumList/forumList.html',
      controller: 'forumListCtrl'
    });
  }]).filter('refineDataNw', ['tokenService', function (tokenService) {
    return function (data, filterBy) {
      //MyService.ConsoleLog("data: ",data," filterBy: ",filterBy);
      var userData = tokenService.getUserData();
      if (userData) {
        userData = JSON.parse(userData);
      }
      if (!filterBy || filterBy == 'all')
        return data;
      var tempArr = [];
      angular.forEach(data, function (val, key) {
        //MyService.ConsoleLog("key: ",key);
        if (filterBy == 'following') {
          if (userData && userData.followlist) {
            if (userData.followlist.indexOf(val.createdBy.id) > -1)
              tempArr.push(val);
          }
        } else {
          if (val.createdBy.utype == filterBy) {
            tempArr.push(val)
          }
        }
      });
      //MyService.ConsoleLog("tempArr: ", tempArr);
      return tempArr;
    };
  }])
  .controller('forumListCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', 'ngDialog', 'growl', '$anchorScroll', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, ngDialog, growl, $anchorScroll) {
    $rootScope.welcome = false;
    $scope.categoryId = $routeParams.categoryId;
    $scope.subcategoryId = $routeParams.subcatId;
    $scope.type = $routeParams.type;
    $scope.category = {};
    $scope.subcategory = {};
    $scope.DisplayData = [];
    $scope.userType = tokenService.getUserRole();
    $scope.userId = tokenService.getUserId();

    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
      MyService.ConsoleLog("$scope.userData", $scope.userData);

    }
    $scope.getUserProfile = function () {
      MyService.getProfile($scope.userData.id).then(
        function (userData) {
          $scope.usersData = userData;
          MyService.ConsoleLog('userData: ', $scope.userData);
        },
        function (err) {
          growl.error('Something went wrong while fetching user info.');
        }
      );
    };

    $scope.filterArray = ['all', 'politician', 'voter', 'press', 'advocate', 'following'];
    $scope.sortArray = ['Newest first', 'Since last login'];
    $scope.sortBy = 'Select sort';

    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;

    // alert('cc');
    /**
     * Redirect
     */
    $scope.goto = function () {
      $location.path('/verifyBy');
    };
    if ($scope.userData && $scope.userData._id != '') {
      $scope.getUserProfile();
    }

    /**
     * Close it
     */
    $scope.closeIt = function () {
      $scope.PopDialog.close();
    };
    $scope.getmyData = function () {
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: tokenService.getUserId(),
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          $scope.verify = $scope.userProfile.verifiedUser;
          // MyService.ConsoleLog("welcome", $scope.verify);
          // console.log("$scope.userDetails", $scope.userDetails.userData.address);
          if (!$scope.userProfile.verifiedUser) {
            $scope.showCon = true;
          }
          // if ($scope.userProfile.userType == 'politician') {

          if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
            // $scope.getPopUp();
            if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
              if ($scope.userProfile.userType == 'politician') {
                $scope.showCon = true;
                $scope.showBoth = true;
              } else if (!$scope.userProfile.isEmailVerified) {
                $scope.showEm = true;
              }
            } else if (!$scope.userProfile.isEmailVerified) {
              // if ($scope.userProfile.userType != 'politician') {
              $scope.showEm = true;
              // }
            } else {
              if ($scope.userProfile.userType != 'politician') {
                $scope.showEm = true;
              }
            }
            if ($scope.userProfile.userType == 'politician') {
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            } else if ($scope.userProfile.userType == 'advocate' && $scope.userProfile.isEmailVerified != true) {
              // alert('cal');
              MyService.ConsoleLog("$scope.userProfile", $scope.userProfile.isEmailVerified);
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            }
          }
          // }


        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    }

    if (tokenService.getUserId()) {
      $scope.getmyData();
    }

    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
    }
    // $scope.Sticky = {
    //   txtOrder: 0
    // };
    $scope.Sticky = {
      ShowOrder: [],
      txtOrder: []
    };
    // $scope.sortNw = '-createdOn';
    $scope.SortData = function () {
      MyService.ConsoleLog($scope.sortBy);
      if ($scope.sortBy == 'Since last login') {
        if ($scope.usersData && $scope.usersData.lastLoginOld != '') {

          var lastOldLogIn = new Date($scope.usersData.lastLoginOld);

          var lens = $scope.DisplayData.length;
          for (var i = 0; i < lens; i++) {
            var lastLoginDate = new Date($scope.usersData.lastLogin);
            var createdOn = new Date($scope.DisplayData[i].createdOn);

            if (lastLoginDate <= createdOn) {

              $location.hash('anchor' + $scope.DisplayData[i]._id);
              $anchorScroll();
              break;

            } else if (lastOldLogIn.toISOString().split('T')[0] <= createdOn.toISOString().split('T')[0]) {
              // MyService.ConsoleLog("else", 'anchor' + $scope.DisplayData[i]._id);
              var old = $location.hash();
              MyService.ConsoleLog("old", old);
              // $location.hash('anchor' + $scope.DisplayData[i]._id);
              $anchorScroll('anchor' + $scope.DisplayData[i]._id);
              break;
            }
          }
        }
      } else if ($scope.sortBy == "Chronological") {
        // $scope.getForumList();
        // $scope.sortNw = 'createdOn';
        $scope.DisplayData.sort(function (a, b) {
          // MyService.ConsoleLog("am", a);
          // MyService.ConsoleLog("b", b);
          var asc = new Date(a.createdOn);
          var bss = new Date(b.createdOn);
          return asc - bss;
        });
      } else {
        $scope.isRecent = "Newest first";
        $scope.DisplayData.sort(function (a, b) {
          // MyService.ConsoleLog("am", a);
          // MyService.ConsoleLog("b", b);
          var asc = new Date(a.createdOn);
          var bss = new Date(b.createdOn);
          return bss - asc;
        });
      }
      //alert("Sort Data called");
    };

    /**
     * Send Verification mail
     */

    $scope.sendMail = function () {
      MyService.SendVerificationMail({
        verificationUrl: $rootScope.getVerificationUrl(),
        unsubscribeurl: $rootScope.getEmailUnsubscribeUrl()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        if (response.data.success) {
          growl.success("Please check your email");
        }
      }, function (err) {
        MyService.ConsoleLog("err", err);
      });
    };

    $scope.getExtraData = function () {
      $rootScope.ExtraTopicData = [];

      var token = apiCall.getToken();
      //alert(token);
      if (token) {

        MyService.extraDataUpdated($scope.userData).then(function (payload) {
            var responseLatest = payload;
            MyService.ConsoleLog("responseLatest : ", responseLatest);
            $rootScope.ExtraTopicData = responseLatest;
            if (responseLatest) {
              $scope.latestDataDateWise();
              if (responseLatest.MyDiscussion) {
                $scope.DiscussionData = responseLatest.MyDiscussion;
              }
              if (responseLatest.ActiveData) {
                $scope.ActiveThreads = responseLatest.ActiveData;
              }
              if (responseLatest.HotTopics) {
                $scope.HotTopics = responseLatest.HotTopics;
              }
            }
          },
          function (errorPayload) {
            alert('Something went wrong ');
          });
      }
    }

    $scope.newData = {};

    $scope.latestDataDateWise = function () {

      MyService.latestDataDateWise({}).then(function (payload) {
        MyService.ConsoleLog("payload", payload);
        //alert("Here");
        $scope.newData = payload;

      }, function (errorPayload) {
        MyService.ConsoleLog("errorPayload", errorPayload);
        alert("Something went wrong");
      });
    }
    if (($rootScope.ExtraTopicData) && ($rootScope.ExtraTopicData.length > 0)) {
      if ($rootScope.ExtraTopicData.MyDiscussion) {
        $scope.DiscussionData = $rootScope.ExtraTopicData.MyDiscussion;
      }

      if ($rootScope.ExtraTopicData.ActiveData) {
        $scope.ActiveThreads = $rootScope.ExtraTopicData.ActiveData;
      }

      if ($rootScope.ExtraTopicData.HotTopics) {
        $scope.HotTopics = $rootScope.ExtraTopicData.HotTopics;
      }
    } else {
      $scope.getExtraData();
    }

    if (window.localStorage.getItem("VoteWiseCategory")) {
      $rootScope.allcategories = JSON.parse(window.localStorage.getItem("VoteWiseCategory"));
      var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);

      if (idx == -1) {
        $rootScope.allcategories.push($routeParams.subcatId);
        window.localStorage.setItem("VoteWiseCategory", JSON.stringify($rootScope.allcategories));
      }
    }

    $scope.checkSticky = function (val) {
      if (val == 'Y') {
        return {
          'background-color': "#fbeec0"
        };
      } else {
        return '';
      }
    }
    var ops, isDisWith = false;
    switch ($scope.type) {
      case 'state':
        ops = JSON.stringify({
          "options": "",
          "isInternational": false,
          "isNational": false,
          "isState": true,
          "isCounty": false,
          "isLocal": false
        });
        isDisWith = true;
        break;
      case 'country':
        ops = JSON.stringify({
          "options": "",
          "isInternational": false,
          "isNational": true,
          "isState": false,
          "isCounty": false,
          "isLocal": false
        });
        isDisWith = true;
        break;
      case 'county':
        ops = JSON.stringify({
          "options": "",
          "isInternational": false,
          "isNational": false,
          "isState": false,
          "isCounty": true,
          "isLocal": false
        });
        isDisWith = true;
        break;
      case 'place':
        ops = JSON.stringify({
          "options": "",
          "isInternational": false,
          "isNational": false,
          "isState": false,
          "isCounty": false,
          "isLocal": true
        });
        isDisWith = true;
        break;
      default:
        break;
    }

    $rootScope.subcategory = "";

    $scope.getForumList = function () {
      if ($scope.userData) {
        var address = JSON.stringify($scope.userData.address);
      } else {
        var address = "";
      }


      var finalData = {
        "prevCat": window.localStorage.getItem('previouscatId'),
        "maincatId": $routeParams.categoryId,
        "subcatId": $routeParams.subcatId,
        "option": ops ? ops : window.localStorage.getItem("locationOption"),
        "address": address,
        "rootData": "N",
        "checkIsDis": isDisWith
      };
      //MyService.ConsoleLog("category:::::::::::",$rootScope.category);
      if ($rootScope.subcategory.title == "General") {
        finalData.rootData = "Y";
      }
      if ($scope.isRecent) {
        finalData.sort_by = $scope.isRecent;
      }

      //MyService.ConsoleLog("post Data : ", finalData);
      var nrequest = apiCall.apiCall('POST', '/topics/getList', finalData);
      nrequest.headers['Cache-Control'] = 'No-Store';
      $http(
        nrequest
      ).then(function successCallback(responseLatest) {
        //MyService.ConsoleLog("Final Data");
        //MyService.ConsoleLog(responseLatest.data.data);
        if (responseLatest.data.success) {
          // $scope.sortNw = '-createdOn';
          $scope.DisplayData = responseLatest.data.data;
          MyService.ConsoleLog(" $scope.DisplayData", $scope.DisplayData);


          $scope.DisplayData.sort(function (a, b) {
            // MyService.ConsoleLog("am", a);
            // MyService.ConsoleLog("b", b);
            var asc = new Date(a.createdOn);
            var bss = new Date(b.createdOn);
            return bss - asc;
          });
        }
      }, function errorCallBack(responseLatest) {
        alert("Error : " + responseLatest);
      });
    }



    function getSubCategories() {
      MyService.getCategoryList($routeParams.subcatId).then(function (payload) {
          $scope.subcategory = payload;
          $rootScope.subcategory = payload;
          $scope.getForumList();
        },
        function (errorPayload) {
          alert('Something went wrong. ');
        });

    }

    function getCategory() {
      MyService.getCategoryList($routeParams.categoryId).then(function (payload) {
          $scope.category = payload;

        },
        function (errorPayload) {
          alert('Something went wrong.');
        });

    }

    $scope.gotoThread = function (data) {
      var categoryId = $routeParams.categoryId;
      var subcatid = $routeParams.subcatId;
      $location.path('forumthread/' + categoryId + '/' + subcatid + '/' + data);
    };

    $scope.updatePostApi = function (data, options, idex) {

      var request = apiCall.apiCall('POST', '/topics/update', data);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog(response);
        if (response.data.data.nModified == 0) {
          alert("Something went wrong. Please try after sometime.");
        } else {
          $scope.getForumList();

        }

      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", JSON.stringify(response));
      });
    }

    $scope.gotoUpdate = function (item) {
      $location.path('/updateTopic/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.type + '/' + item._id);

    }


    $scope.objectKeys = function (obj) {
      return Object.keys(obj);
    }

    $scope.updatePost = function (option, id) {
      var data = {};
      if (option == "sticky") {
        data.poststicky = 1;
        data.poststickyOrder = $scope.Sticky.txtOrder[id];
        data.topicId = id;

      } else {
        data.removepostSticky = 1;
        data.topicId = id;

      }
      //MyService.ConsoleLog(data);
      $scope.updatePostApi(data);
    }

    $scope.gotoFThread = function (id, catId, subcatid) {
      $location.path('forumthread/' + catId + '/' + subcatid + '/' + id);
    }
    $scope.gotoForumTopic = function (obj) {
      if (obj.parent && obj.parent != "") {
        $scope.gotoFThread(obj.parent, obj.parentCat[0], obj.subCategories[0]);
      } else {
        $scope.gotoFThread(obj._id, obj.parentCat[0], obj.subCategories[0]);
      }
    }

    $scope.gotoForumT = function (obj) {
      MyService.ConsoleLog("obj", obj);
      var subcat = $scope.getSubCat(obj.parentCat[0]);
      MyService.ConsoleLog("subcat:::", subcat);
      if (obj.parent && obj.parent != "") {
        $scope.gotoFThread(obj.parent, obj.parentCat[0], subcat[0].subCategories[0]._id);
      } else {
        $scope.gotoFThread(obj._id, obj.parentCat[0], subcat[0].subcategories[0]._id);
      }

      //"#!/forumthread/"+obj.parentcat[0]+"/"+subcat+"/"+obj._id;
    }


    if ($routeParams.categoryId) {
      getCategory();
      getSubCategories();
    }

  }]);
