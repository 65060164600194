"use strict";

angular
  .module("myApp.fiveMinSaveUser", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/fiveSaveUser", {
        templateUrl: "app/fiveminsaveuser/fiveminsaveuser.html",
        controller: "fiveSaveUserCtrl",
      });
    },
  ])

  .controller("fiveSaveUserCtrl", [
    "$scope",
    "apiCall",
    "MyService",
    "tokenService",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$rootScope",
    "ngDialog",
    "$q",
    "$http",
    "$filter",
    "growl",
    "baseApiUrl",
    "$sce",
    "$cookies",
    function (
      $scope,
      apiCall,
      MyService,
      tokenService,
      $location,
      $anchorScroll,
      $timeout,
      $rootScope,
      ngDialog,
      $q,
      $http,
      $filter,
      growl,
      baseApiUrl,
      $sce,
      $cookies
    ) {
      $rootScope.welcome = false;
      $scope.UserType = "voter";
      $scope.MUserType = [
        /*{
          name: 'Politician',
          key: 'politician',
        },*/
        {
          name: "Voter",
          key: "voter",
        },
        {
          name: "Organization",
          key: "advocate",
        },
      ];

      // BRING BACK   removed from above line { "name": 'Organization', "key": 'advocate' }, { "name": 'Press', "key": 'press' }
      $cookies.remove("showLongPopUp");
      $scope.showGuest = false;
      $scope.showzip = false;
      MyService.ConsoleLog("$scope.showGuest >>", $cookies.get("sessionId"));

      if ($cookies.get("sessionId")) {
        $scope.showGuest = true;
      }
      MyService.ConsoleLog("before set >> ", $rootScope.fiveMatch);
      if ($rootScope.fiveMatch && $rootScope.fiveMatch.length > 0) {
        window.localStorage.setItem(
          "fiveMatch",
          JSON.stringify($rootScope.fiveMatch)
        );
      } else {
        $rootScope.fiveMatch = JSON.parse(
          window.localStorage.getItem("fiveMatch")
        );
      }

      MyService.ConsoleLog("after set >> ", $rootScope.fiveMatch);

      if ($rootScope.tempUserAddress) {
        window.localStorage.setItem(
          "tempUserAddress",
          JSON.stringify($rootScope.tempUserAddress)
        );
      } else {
        $rootScope.tempUserAddress = JSON.parse(
          window.localStorage.getItem("tempUserAddress")
        );
      }

      MyService.ConsoleLog(
        " $rootScope.tempUserAddress :",
        $rootScope.tempUserAddress
      );

      if ($rootScope.tempUserZip) {
        MyService.ConsoleLog(
          " inside zip>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
          $rootScope.tempUserZip
        );
        window.localStorage.setItem("tempUserZip", $rootScope.tempUserZip);
      } else {
        $rootScope.tempUserZip = window.localStorage.getItem("tempUserZip");
        MyService.ConsoleLog(
          "$rootScope.tempUserZip >>",
          $rootScope.tempUserZip
        );
      }
      if ($rootScope.tempUserZip == "") {
        // alert('show');
        $scope.showzip = true;
      }

      $scope.records = [];
      $scope.States = [];
      $scope.Cities = [];

      $scope.Address = {
        ZipCode: "",
        Country: "",
        State: "",
        City: "",
      };
      $scope.User = {
        name: "",
        UserType: "voter",
        lastname: "",
        UserName: "",
        Password: "",
        ConfirmPassword: "",
        Email: "",
        ZipCode: "",
        ZipCodeOne: "",
        confirmOpt: "Email",
        runElectionDate: "",
        runStatus: "",
      };
      $scope.countyone = false;
      $scope.cityone = false;
      $scope.showLoaderOne = false;
      $scope.running_countyone = false;
      $scope.running_cityone = false;
      $scope.running_showLoaderOne = false;
      $scope.runAddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      $scope.Errors = [];

      $scope.levelOfGovt = ["Other", "Federal", "State", "County", "Local"];

      $scope.countylevelOfGovt = ["County"];
      $scope.statelevelOfGovt = [
        "State",
        "State House of Representatives District",
        "State Senate District",
        "All USA",
        "Federal Congressional District",
      ];
      $scope.schoollevelOfGovt = [
        "Elementary school District Position",
        "Secondary School District Position",
        "Unified School District Position",
        "State Board of Education District",
      ];

      $scope.judiciallevelOfGovt = ["Judicial District"];
      $scope.locallevelOfGovt = ["Place Name", "County SubDivision Name"];
      $scope.offices = [];
      $scope.running_offices = [];

      $scope.carriers = [
        {
          name: "AT&T",
          domain: "txt.att.net",
        },
        {
          name: "T-Mobile",
          domain: "tmomail.net",
        },
        {
          name: "Verizon",
          domain: "vtext.com",
        },
        {
          name: "Sprint",
          domain: "messaging.sprintpcs.com",
        },
        {
          name: "Virgin Mobile",
          domain: "vmobl.com",
        },
        {
          name: "Tracfone",
          domain: "mmst5.tracfone.com",
        },
        {
          name: "Metro PCS",
          domain: "mymetropcs.com",
        },
        {
          name: "Boost Mobile",
          domain: "myboostmobile.com",
        },
        {
          name: "Cricket",
          domain: "sms.mycricket.com",
        },
        {
          name: "Ptel",
          domain: "ptel.com",
        },
        {
          name: "Republic Wireless",
          domain: "text.republicwireless.com",
        },
        {
          name: "Suncom",
          domain: "tms.suncom.com",
        },
        {
          name: "Ting",
          domain: "message.ting.com",
        },
        {
          name: "U.S. Cellular",
          domain: "email.uscc.net",
        },
        {
          name: "Consumer Cellular",
          domain: "cingularme.com",
        },
        {
          name: "C-Spire",
          domain: "cspire1.com",
        },
        {
          name: "Page Plus",
          domain: "vtext.com",
        },
      ];

      $scope.govLevelChanged = function (electionFor) {
        $scope.running_flagShowStateDropdown = false;
        $scope.running_flagShowOfficeDropdown = false;
        $scope.flagShowStateDropdown = false;
        $scope.flagShowOfficeDropdown = false;
        MyService.ConsoleLog("* govLevelChanged *");
        if (electionFor == "running") {
          $scope.User.office_name = null;
          $scope.running_flagShowStateDropdown = true;
          //$scope.onStateChange('running');
        } else {
          $scope.User.cur_state = null;
          $scope.User.cur_office_name = null;
          $scope.flagShowStateDropdown = true;
          //$scope.onStateChange('incumbent');
        }
      };

      $scope.localSchoolDistException = function (electionFor) {
        var exceptionState = ["AK", "DC", "VA"];
        if ((electionFor = "running")) {
          if (
            $scope.User.state &&
            exceptionState.indexOf($scope.User.state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_state &&
            exceptionState.indexOf($scope.User.cur_state.statePostalCode) == -1
          ) {
            return true;
          } else return false;
        }
      };

      $scope.userTypeChanged = function (type) {
        MyService.ConsoleLog("type : " + type);
        $scope.UserType = type;
        console.log("check type", $scope.UserType);

        $scope.User.organization_name = "";
        $scope.User.organization = null;
        //MyService.ConsoleLog("user type : ", $scope.UserType);
        $("form").validationEngine();
        //MyService.ConsoleLog("validationEngine applied");
      };
      // for autocomplete address

      var options = {
        componentRestrictions: {
          country: "us",
        },
        types: ["geocode"],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById("address");
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();

            var lat = place.geometry.location.lat(),
              lng = place.geometry.location.lng();

            $scope.lati = lat.toFixed(6);
            $scope.lon = lng.toFixed(6);
            // console.log($scope.lati);
            // console.log($scope.lon);

            $scope.mailing_Address = place.formatted_address;
            MyService.ConsoleLog("Main addd :", place.address_components);
            for (var i = 0; i < place.address_components.length; i++) {
              for (
                var j = 0;
                j < place.address_components[i].types.length;
                j++
              ) {
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_1"
                ) {
                  $scope.statePostalCode =
                    place.address_components[i].short_name;
                  // console.log($scope.statePostalCode);
                }
                if (place.address_components[i].types[j] == "country") {
                  $scope.countryname = place.address_components[i].long_name;
                  // console.log($scope.countryname);
                }
                if (
                  place.address_components[i].types[j] ==
                  "administrative_area_level_2"
                ) {
                  $scope.county_name = place.address_components[i].long_name;
                  // console.log($scope.county_name);
                }
                if (place.address_components[i].types[j] == "locality") {
                  $scope.place_name = place.address_components[i].long_name;
                  // console.log($scope.place_name);
                }
                if (place.address_components[i].types[j] == "postal_code") {
                  document.getElementById("zip").value =
                    place.address_components[i].long_name;
                  if (
                    place.address_components[i].long_name &&
                    place.address_components[i].long_name != ""
                  ) {
                    $scope.User.ZipCode = place.address_components[i].long_name;
                    $scope.User.ZipCodeOne =
                      place.address_components[i].long_name;
                    // console.log($scope.User.ZipCode);
                  }
                  // console.log($scope.User.ZipCode);
                }
              }
            }

            console.log("$scope.User.ZipCode :", $scope.User.ZipCode);

            var loc = JSON.stringify({
              longitude: $scope.lon,
              latitude: $scope.lati,
            });
            // console.log(loc);
            // $rootScope.location = new Array;
            $rootScope.location = {
              longitude: $scope.lon,
              latitude: $scope.lati,
            };
            $scope.showAddressDropDowns("", loc);
          }
        );
      };
      $scope.oldaddress = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };

      $scope.address = {
        state: "",
        county: "",
        countySubdivision: "",
        place: "",
      };
      // End for autocomplete address

      $scope.mainAddress = "";

      $scope.showAddressDropDowns = function (zipcode, loc) {
        if (loc && loc != "") {
          MyService.ConsoleLog("showAddressDropDowns" + zipcode);
          MyService.ConsoleLog("MyLocation : >>>." + loc);

          $scope.showZipLoader = true;
          MyService.getOrganizedZipData({
            candidate: true,
            loc: loc,
          }).then(
            function (payload) {
              MyService.ConsoleLog(" showAddressDropDowns ", payload);
              if (payload && payload != "") {
                // var finalData = payload.data;
                // MyService.ConsoleLog(payload[0].countySubdivision);
                // MyService.ConsoleLog(payload[0].county);
                // MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = payload.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != "") {
                    // MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = payload.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != "") {
                    // MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });
                MyService.ConsoleLog(
                  " showAddressDropDowns <><><><><><><><><><><><><> "
                );

                $scope.mainAddress = payload;
                $scope.showZipLoader = false;
                $scope.showAddressDropDown = true;
                $scope.oldaddress.state = payload.state;
                $scope.oldaddress.county = payload.county;
                // MyService.ConsoleLog("countySubdivisions >> ", countySubdivisions);
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // MyService.ConsoleLog("places >>> ",places);
                $scope.oldaddress.place = places;
                // $scope.getCounties("new");

                $scope.getSubCounties("new");

                // $scope.voterDistricts = payload;
                // MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = payload.congressionalDistrict;
                MyService.ConsoleLog(
                  "@@@@@@ drop :",
                  $scope.federalCongrassionalDist
                );

                $scope.stateSenateDistrict = payload.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  payload.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames =
                  payload.unifiedSchoolDistrict;
                //$scope.$apply();
                // MyService.ConsoleLog($scope.states);
                // MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState($scope.states, payload.state);
                MyService.ConsoleLog("address : ", address);
                $scope.address.state = address[0];
                // $scope.address.voterDistrict = $scope.voterDistricts[0];
                $scope.address.federalCongrassionalDist =
                  $scope.federalCongrassionalDist[0];
                $scope.address.stateSenateDistrict =
                  $scope.stateSenateDistrict[0];
                $scope.address.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.address.unifiedSchoolDistrictName =
                  $scope.unifiedSchoolDistrictNames[0];
                // MyService.ConsoleLog("address ; ", address);
                MyService.ConsoleLog(
                  "<><><><><<><><>>>>>>. zip code >>>>>>>>>>>>>>>>>",
                  $scope.User.ZipCode
                );
                $timeout(function () {
                  $rootScope.tempUserZip = $scope.User.ZipCode;
                  $rootScope.tempUserAddress = $scope.address;
                  MyService.ConsoleLog(
                    " temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>",
                    $rootScope.tempUserAddress
                  );
                  window.localStorage.setItem(
                    "tempUserZip",
                    $rootScope.tempUserZip
                  );
                  window.localStorage.setItem(
                    "tempUserAddress",
                    JSON.stringify($rootScope.tempUserAddress)
                  );
                  // MyService.ConsoleLog("address : ", address);
                  // MyService.ConsoleLog("rootscope address : ", $rootScope.tempUserAddress);
                }, 100);
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }
      };

      $scope.checkState = function (rdata, val) {
        // MyService.ConsoleLog("State :", rdata);
        // MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog("County :", rdata);
        MyService.ConsoleLog("County val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (
              rsdata.countyFips == obj.COUNTYFP &&
              rsdata.stateFips == obj.STATEFP
            ) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      var getCountiesCanceler = $q.defer();
      var getCountiesCanceler = $q.defer();
      $scope.getCounties = function (lbl) {
        //MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
        //MyService.ConsoleLog($scope.oldaddress);
        // var state = {};
        // if (lbl == 'cur') state = $scope.User.cur_state;
        // else if (lbl == 'new') state.statePostalCode = $scope.oldaddress.state;
        // else state = JSON.parse($scope.User.sel_state);
        $scope.counties = "";
        var statePostalCode = "";

        if ($scope.User.cur_state != "") {
          statePostalCode = $scope.User.cur_state.stateFips;
        }
        //MyService.ConsoleLog("state: ", state);

        getCountiesCanceler.resolve();
        if ($scope.places) $scope.counties.length = 0;

        var request = apiCall.apiCall("POST", "/user/getAllCounties", {
          statePostalCode: statePostalCode,
          timeout: getCountiesCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              //MyService.ConsoleLog(response.data.data);
              $scope.counties = response.data.data;
              // if (lbl == 'new') {
              if ($scope.User.cur_level == "Local") {
                $scope.countyone = true;
                $scope.showLoader = false;
              }

              // if ($scope.User.cur_level == "Other") {
              //   $scope.countyone = true;
              //   $scope.showLoader = false;
              // }

              if ($scope.User.cur_level == "County") {
                $scope.countyone = true;
                $scope.showcur_office_nameDrop = true;
                $scope.showLoader = false;
              }

              // alert('counties');
              // $scope.address.county = address.county;
              var county = $scope.checkCounty(
                $scope.counties,
                $scope.oldaddress.county
              );
              //MyService.ConsoleLog("county<><", county);
              $scope.address.county = county[0];
              // }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      /*
       * this function Fetch all data of sub counties and cities
       **/

      $scope.getSubCounties = function () {
        $scope.arr = [];
        $scope.dis = false;
        $scope.stateSenate = false;
        $scope.stateHouse = false;
        $scope.federalCong = false;
        // $scope.countyone = false;
        $scope.cityone = false;
        $scope.school = false;
        $scope.federalCongrassionalDist = "";
        $scope.unifiedSchoolDistrictNames = "";
        $scope.stateHouseOFRepresentativeDistricts = "";
        $scope.stateSenateDistrict = "";
        $scope.allsubcounty = "";
        $scope.places = "";
        $scope.address.stateSenateDistrict = "";
        $scope.address.federalCongrassionalDist = "";
        $scope.address.stateHouseOFRepresentativeDistrict = "";
        $scope.address.unifiedSchoolDistrictName = "";

        MyService.ConsoleLog("$scope.state >>>", $scope.User.cur_state);
        MyService.ConsoleLog("$scope.County >>>", $scope.address.county);
        if (
          $scope.User.cur_level == "Local" ||
          $scope.User.cur_level == "Other"
        ) {
          $scope.showLoaderOne = true;
          // $scope.LoaderTwo = false;
        }
        if ($scope.User.cur_level != "County") {
          MyService.getPlacesAndSubCounty(
            $scope.User.cur_state.stateFips,
            $scope.address.county.countyFips,
            $scope.User.cur_state.state
          ).then(
            function (response) {
              MyService.ConsoleLog("response >>", response);

              var sub = response.countySubdivision.filter(function (val) {
                var cmat = val.city.substring(val.city.length - 3);
                if (cmat != "CDP") {
                  return val;
                }
              });

              $scope.allsubcounty = sub;

              var plFil = response.places.filter(function (item) {
                // MyService.ConsoleLog('item', item);
                var smTxt = item.city.substring(item.city.length - 3);
                if (smTxt != "CDP") {
                  return item;
                  MyService.ConsoleLog("smTxt >>", smTxt);
                }
              });
              MyService.ConsoleLog("plFil >>", plFil);
              $scope.finArr = $scope.allsubcounty.concat(plFil);
              MyService.ConsoleLog("$scope.arr", $scope.finArr);
              var smpl = $scope.finArr.filter(function (vals, ind) {
                return (
                  ind ==
                  $scope.finArr.findIndex(function (ele) {
                    return JSON.stringify(ele) == JSON.stringify(vals);
                  })
                );
              });
              MyService.ConsoleLog("new filtered >>", smpl);

              $scope.places = smpl;
              $scope.cityone = true;
              if ($scope.User.cur_level == "Local") {
                $scope.showLoaderOne = false;

                // $scope.getFederal();
              }

              if ($scope.User.cur_level == "Other") {
                // $scope.showLoaderOne = true;
                // $scope.getFederal();
              }
            },
            function (error) {
              MyService.ConsoleLog("Error >>", error);
            }
          );
        }
        // if($scope.User.cur_state && $scope.address.county == "" ){
        //   alert('if');
        // }else{
        //   alert('else');

        // }
      };

      /**
       * Get Counties for the running for office
       */
      $scope.getRunningCounties = function () {
        // alert("first");
        // MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
        // MyService.ConsoleLog("old",$scope.oldaddress);

        var statePostalCode = "";

        if ($scope.User.state != "") {
          statePostalCode = $scope.User.state.stateFips;
        }

        // MyService.ConsoleLog("selected state", $scope.address.state);

        // MyService.ConsoleLog("lbl", lbl);

        MyService.ConsoleLog("statePostalCode", statePostalCode);

        var request = apiCall.apiCall("POST", "/user/getAllCounties", {
          statePostalCode: statePostalCode,
          timeout: getCountiesCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              MyService.ConsoleLog("response", response.data.data);
              $scope.runCounties = response.data.data;
              console.log("$scope.alllcounties>>", $scope.runCounties);
              // if (lbl == "new") {
              if ($scope.User.level == "Local") {
                $scope.running_countyone = true;
                $scope.running_showLoader = false;
              }

              if ($scope.User.level == "Other") {
                $scope.running_countyone = true;
                $scope.running_showLoader = false;
              }

              if ($scope.User.level == "County") {
                $scope.running_countyone = true;
                $scope.shownew_office_nameDrop = true;
                $scope.running_showLoader = false;
              }

              // alert('counties');

              // }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      /**
       * Get all sub counties and cities of the running for office
       */

      $scope.getRunningSubCounties = function () {
        MyService.ConsoleLog("$scope.state >>>", $scope.User.state);
        MyService.ConsoleLog("$scope.County >>>", $scope.User.state);
        $scope.running_stateSenate = false;
        $scope.running_stateHouse = false;
        $scope.running_federalCong = false;
        // $scope.running_countyone = false;
        $scope.running_cityone = false;
        $scope.running_school = false;
        $scope.running_showLoader = false;
        $scope.runFederalCongrassionalDist = "";
        $scope.runUnifiedSchoolDistrictNames = "";
        $scope.runStateHouseOFRepresentativeDistricts = "";
        $scope.runStateSenateDistrict = "";
        $scope.runPlaces = "";
        // $scope.runAddress.stateSenateDistrict = "";
        // $scope.runAddress.federalCongrassionalDist = "";
        // $scope.runAddress.stateHouseOFRepresentativeDistrict = "";
        // $scope.runAddress.unifiedSchoolDistrictName = "";
        if ($scope.User.level == "Local" || $scope.User.level == "Other") {
          $scope.running_showLoaderOne = true;
        }
        MyService.ConsoleLog("$scope.User.cur_level", $scope.User.cur_level);
        if ($scope.User.level != "County") {
          MyService.getPlacesAndSubCounty(
            $scope.User.state.stateFips,
            $scope.runAddress.county.countyFips,
            $scope.User.state.state
          ).then(
            function (response) {
              MyService.ConsoleLog("response >>", response);
              var subRun = response.countySubdivision.filter(function (val) {
                var cmatRun = val.city.substring(val.city.length - 3);
                if (cmatRun != "CDP") {
                  return val;
                  MyService.ConsoleLog("cmat >>", cmat);
                }
              });

              $scope.allsubcounty = subRun;

              var plFilRun = response.places.filter(function (item) {
                // MyService.ConsoleLog('item', item);
                var smTxtRun = item.city.substring(item.city.length - 3);
                if (smTxtRun != "CDP") {
                  return item;
                  MyService.ConsoleLog("smTxt >>", smTxt);
                }
              });
              // $scope.allsubcounty = ;
              var temArr = $scope.allsubcounty.concat(plFilRun);
              var finls = temArr.filter(function (vals, ind) {
                return (
                  ind ==
                  temArr.findIndex(function (ele) {
                    return JSON.stringify(ele) == JSON.stringify(vals);
                  })
                );
              });
              $scope.runPlaces = finls;
              if ($scope.User.level == "Local") {
                $scope.running_showLoaderOne = false;
                $scope.running_cityone = true;

                // $scope.getRunningFederal();
              }
              if ($scope.User.level == "Other") {
                // $scope.showLoaderOne = true;
                // $scope.getRunningFederal();
              }
            },
            function (error) {
              MyService.ConsoleLog("Error >>", error);
            }
          );
        }
      };
      /*  $scope.allcounties = [];
        $scope.getCounties = function (lbl) {
          // MyService.ConsoleLog("county >", $scope.Location.state);
          $scope.allcounties.length = 0;
          var statePostalCode = '';
          var state = '';
          if (lbl == 'new') {
            state = JSON.stringify($scope.oldaddress.state);
          } else {
            statePostalCode = $scope.Location.state.statePostalCode;
            state = $scope.Location.state;
          }
          // MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
          MyService.getAllCounties(statePostalCode, state).then(
            function (payload) {
              // MyService.ConsoleLog("sublocations Locals : ", payload);
              var allcounties = payload;
              if (lbl == 'new') {
                var county = $scope.checkCounty(allcounties, $scope.oldaddress.county);
                $scope.allcounties = county;
                // MyService.ConsoleLog("county<><", county);
                $scope.address.county = county[0];
              }
            },
            function (errorPayload) {
              growl.error('Something went wrong.');
            }
          );
        };
        $scope.MyPlaces = [];
        $scope.getSubCounties = function (lbl) {
          //MyService.ConsoleLog($scope.Location.state);
          //MyService.ConsoleLog($scope.Location.county);
          // var county = $scope.Location.county;

          $scope.MyPlaces.length = 0;
          var county = '';
          var statePostalCode = '';
          $scope.MyPlaces.length = 0;
          var state = '';
          var county = '';
          if (lbl == 'new') {
            state = JSON.stringify($scope.oldaddress.state);
            county = JSON.stringify($scope.oldaddress.county);
          } else {
            statePostalCode = $scope.Location.state.statePostalCode;
            state = $scope.Location.state;
            county = county.county;
          }
          // MyService.ConsoleLog(statePostalCode + "===" + county);
          MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
            function (payload) {
              // payload = JSON.parse(payload);
              $scope.MyPlaces = payload;
              // MyService.ConsoleLog("PlaCE :", payload);
              // MyService.ConsoleLog(payload.countySubdivision);
              var subcounties = payload.countySubdivision;
              var places = payload.places;
              // MyService.ConsoleLog("PlaCE :", payload);
              if (lbl == 'new') {
                // MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
                var subcounty = $scope.checksubCounty(
                  subcounties,
                  $scope.oldaddress.countySubdivision
                );
                var places = $scope.checkPlaces(places, $scope.oldaddress.place);
                // MyService.ConsoleLog("subcounty<><><", subcounty);
                // $scope.subcounties = subcounty;
                $scope.places = places;
                // console.log('***********', $scope.places);
                // $scope.Location.countySubdivision = subcounty[0];
                $scope.address.place = places[0];
              }
            },
            function (errorPayload) {
              growl.error('Something went wrong.');
            }
          );
        }; */

      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function (lbl) {
        // MyService.ConsoleLog($scope.oldaddress);
        var state = {},
          county = {};

        if (lbl == "cur") {
          state = $scope.User.cur_state;
          county = JSON.parse($scope.User.cur_county);
        } else if (lbl == "new") {
          state.statePostalCode = $scope.oldaddress.state;
          county.county = $scope.oldaddress.county;
        } else {
          state = JSON.parse($scope.User.sel_state);
          county = JSON.parse($scope.User.sel_county);
        }

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();

        var request = apiCall.apiCall("GET", "/user/getAllSubCountiesLocal", {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              if (response.data.data) {
                // MyService.ConsoleLog(response.data.data);
                $scope.places = response.data.data;

                if (lbl == "new") {
                  var subcounty = $scope.checksubCounty(
                    $scope.places,
                    $scope.oldaddress.countySubdivision
                  );

                  var places = $scope.checkPlaces(
                    $scope.places,
                    $scope.oldaddress.place
                  );
                  // MyService.ConsoleLog("subcounty<><><", subcounty[0])
                  $scope.address.countySubdivision = subcounty[0];
                  $scope.address.place = places[0];
                }
              }
            } else {
              growl("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl("Something went wrong.");
          }
        );
      };

      $scope.showOfficeAutoComplete = function (electionFor) {
        //MyService.ConsoleLog("showOfficeAutoComplete");
        // ['national', 'state', 'judicial district', 'county', 'sub county', 'city', 'state school board district', 'local', 'school district', 'other']
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "national":
              $scope.running_offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.state && $scope.User.district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.state && $scope.User.county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.state &&
                $scope.User.county &&
                $scope.User.locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.state && $scope.User.state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.state &&
                $scope.localSchoolDistException() &&
                $scope.User.county &&
                $scope.User.district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.state && $scope.User.district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "national":
              $scope.offices = [
                "president",
                "senate",
                "house of representatives",
                "other",
              ];
              return true;
              break;
            case "state":
              if ($scope.User.cur_state) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is State Postal Code, and item Q is the state selected.  Then add “other” to the end of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "judicial district":
              if ($scope.User.cur_state && $scope.User.cur_district) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                //fetch office names for this
                //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "sub county":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_subcounty
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item U is the subcounty selected. Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "city":
              if (
                $scope.User.cur_state &&
                $scope.User.cur_county &&
                $scope.User.cur_locality
              ) {
                //fetch office names for this
                // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "state school board district":
              if ($scope.User.cur_state && $scope.User.cur_state_school_dist) {
                //fetch office names for this
                // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              }
              break;
            case "local school district":
              if (
                $scope.User.cur_state &&
                $scope.localSchoolDistException() &&
                $scope.User.cur_county &&
                $scope.User.cur_district
              ) {
                //for office names
                // search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the “County” selection and either AD’s, AF’s or  AH’s match the District Name selected.  Then add “other” to the bottom of the list.
                // $scope.offices=['other'];
                return true;
              } else if ($scope.User.cur_state && $scope.User.cur_district) {
                //for options
                //do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item AD’s match the District Name selected.  Then add “other” to the bottom of the list
                // $scope.offices=['other'];
                return true;
              }
              break;
          }
          return false;
        }
      };
      var running_flagShowStateDropdown = false;
      var flagShowStateDropdown = false;
      $scope.showSateDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSateDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.office_name) {
            case "senate":
            case "house of representatives":
              running_flagShowStateDropdown = true;
              return true;
              break;
          }

          running_flagShowStateDropdown = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state":
            case "judicial district":
            case "county":
            case "sub county":
            case "city":
            case "state school board district":
            case "local school district":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          switch ($scope.User.cur_office_name) {
            case "senate":
            case "house of representatives":
              flagShowStateDropdown = true;
              return true;
              break;
          }

          flagShowStateDropdown = false;
          return false;
        }
      };

      $scope.flagShowCandidateDropdown = false;
      $scope.running_flagShowCandidateDropdown = false;

      var fuzzySearch = "";
      $scope.showLoader = false;

      $scope.onOfficeChange = function (electionFor) {
        $scope.showLoader = true;
        if (electionFor == "running") {
          //'Federal', 'State', 'Local'
          var nObj = new Object();
          nObj.level = $scope.User.level;
          nObj.office = $scope.User.off_name;
          nObj.state = JSON.stringify($scope.User.state);
          $scope.running_flagShowCandidateDropdown = true;
          switch ($scope.User.level) {
            // case "Other":
            //   $scope.getRunningCounties();
            //   break;
            case "County":
              $scope.getRunningCounties();
              break;
            // case 'State':
            //   $scope.getRunningStateHouse();
            //   break;
            // case 'Federal':
            //   $scope.getRunningFederal();
            //   break;
            case "Local":
              $scope.getRunningCounties();
              break;

            default:
              MyService.ConsoleLog("default");
              break;
          }
        } else if (electionFor == "incumbent") {
          var nObj = new Object();
          nObj.level = $scope.User.cur_level;
          nObj.office = $scope.User.cur_off_name;
          nObj.state = JSON.stringify($scope.User.cur_state);
          $scope.flagShowCandidateDropdown = true;
          switch ($scope.User.cur_level) {
            // case "Other":
            //   $scope.getCounties();
            //   break;
            case "County":
              $scope.getCounties();
              break;
            // case 'State':
            //   $scope.getStateHouse();
            //   break;
            // case 'Federal':
            //   $scope.getFederal();
            //   break;
            case "Local":
              $scope.getCounties();
              break;

            default:
              MyService.ConsoleLog("default");
              break;
          }
        }
        // MyService.ConsoleLog("nObj<><><><", nObj);
        MyService.getPoliticians(nObj).then(
          function (payload) {
            MyService.ConsoleLog("payload<><><><><>", payload);

            $scope.candidates = payload;
            $scope.showLoader = false;
            MyService.ConsoleLog($scope.running_flagShowCandidateDropdown);
            $scope.candidates.push({
              name: "Other",
            });
            fuzzySearch = new Fuse($scope.candidates, {
              shouldSort: true,
              keys: ["name"],
              caseSensitive: false,
              threshold: 0.4,
            });
          },
          function (errPayload) {
            $scope.showLoader = false;
            //MyService.ConsoleLog("Error");
            //MyService.ConsoleLog(errPayload);
            alert(errPayload);
          }
        );
      };

      $scope.checkReadOnly = false;
      $scope.HideRegisterButton = false;
      $scope.onCandidateChange = function (val) {
        //$scope.User.Candidates;
        $scope.checkReadOnly = false;
        $scope.shortEmail = "";
        // alert("onCandidateChange");
        // MyService.ConsoleLog($scope.User.Candidates);
        // MyService.ConsoleLog($scope.User.NCandidates);
        if (val == "incumbent" && $scope.User.Candidates) {
          if (
            $scope.User.Candidates.name &&
            $scope.User.Candidates.name != null
          ) {
            if ($scope.User.Candidates.name != "Other") {
              $scope.User.name = $scope.User.Candidates.name;
              if (
                $scope.User.Candidates.PublicPhoneNumber &&
                $scope.User.Candidates.PublicPhoneNumber != null
              ) {
                $scope.User.phone_no = $scope.User.Candidates.PublicPhoneNumber;
              } else {
                $scope.User.phone_no = "";
              }
              if (
                $scope.User.Candidates.website &&
                $scope.User.Candidates.website != null
              ) {
                $scope.User.web_url = $scope.User.Candidates.website;
              } else {
                $scope.User.web_url = "";
              }
              if (
                $scope.User.Candidates.public_zipcode &&
                $scope.User.Candidates.public_zipcode != null
              ) {
                $scope.User.ZipCode = $scope.User.Candidates.public_zipcode;
              } else {
                $scope.User.ZipCode = "";
              }
              if (
                $scope.User.Candidates.public_mailing_address &&
                $scope.User.Candidates.public_mailing_address != null
              ) {
                $scope.User.mailing_address =
                  $scope.User.Candidates.public_mailing_address;
              } else {
                $scope.User.mailing_address = "";
              }

              if (
                ($scope.User.Candidates.email &&
                  $scope.User.Candidates.email != null &&
                  $scope.User.Candidates.email != 0) ||
                ($scope.User.Candidates.privateEmail &&
                  $scope.User.Candidates.privateEmail != null &&
                  $scope.User.Candidates.privateEmail != 0)
              ) {
                $scope.HideRegisterButton = true;
                var email = $scope.User.Candidates.email
                  ? $scope.User.Candidates.email
                  : $scope.User.Candidates.privateEmail;
                var cemail = email;
                var firstSix = cemail.substr(0, 6);
                var lastSix = cemail.substr(cemail.length - 6);
                var xlen = cemail.length - 12;
                var xstring = "x".repeat(xlen);
                var finalstring = firstSix + xstring + lastSix;
                $scope.shortEmail = finalstring;
                /*
              var cemail = $scope.User.Candidates.email;
              var firstSix = cemail.substr(0, 6);
              var lastSix = cemail.substr(cemail.length - 6);
              var xlen = cemail.length - 12;
              var xstring = "x".repeat(xlen);
              var finalstring = firstSix + xstring + lastSix;
              $scope.HideRegisterButton = true;
              var msg = "You are already in our system with the email " + finalstring;
              MyService.GetDialogWithButtons(ngDialog, msg, "SignIn", "Please email me my password").then(function(payload) {
                MyService.ConsoleLog("Ans<><><>", payload);
                $scope.User.Email = $scope.User.Candidates.email;
                $scope.checkReadOnly = true;
                if (payload == 1) {
                  $location.path("/signin");
                } else {
                  var postobj = {
                    email: $scope.User.Candidates.email,
                    reset_url: $rootScope.getPasswordResetUrl()
                  };
                  MyService.ConsoleLog("postobj: ", postobj);

                  MyService.forgotPassword(postobj)
                    .then(function(response) {
                      if (response.data.success) {
                        growl.success("An email has been sent.");
                      } else {
                        if (typeof response.data.error == "string")
                          growl.error(response.data.error);
                        else
                          growl.error("Something went wrong");
                      }
                    }, function(err) {
                      growl.error("Something went wrong");
                    });
                }
              }, function(errorPayload) {
                $scope.User.Email = "";
                MyService.ConsoleLog("errorPayload<><><>", errorPayload);
                $scope.checkReadOnly = false;
                if (errorPayload == 0) {
                  var postobj = {
                    email: $scope.User.Candidates.email,
                    reset_url: $rootScope.getPasswordResetUrl()
                  };
                  MyService.ConsoleLog("postobj: ", postobj);

                  MyService.forgotPassword(postobj)
                    .then(function(response) {
                      if (response.data.success) {
                        growl.success("An email has been sent.");
                      } else {
                        if (typeof response.data.error == "string")
                          growl.error(response.data.error);
                        else
                          growl.error("Something went wrong");
                      }
                    }, function(err) {
                      growl.error("Something went wrong");
                    });
                }
              });
              */
              } else {
                $scope.User.Email = "";
                $scope.User.phone_no = "";
                $scope.User.web_url = "";
                $scope.User.ZipCode = "";
                $scope.User.mailing_address = "";
              }
            } else {
              //If candidate is Other
              //MyService.ConsoleLog("Other selected");
            }
          } else {
            $scope.User.name = "";
            $scope.User.phone_no = "";
            $scope.User.web_url = "";
            $scope.User.ZipCode = "";
            $scope.User.mailing_address = "";
          }
        } else {
          MyService.ConsoleLog("Else 1", $scope.User.NCandidates);
          if ($scope.User.NCandidates) {
            if (
              $scope.User.NCandidates.name &&
              $scope.User.NCandidates.name != null
            ) {
              if ($scope.User.NCandidates.name != "Other") {
                $scope.User.name = $scope.User.NCandidates.name;
                MyService.ConsoleLog(
                  "Other than <><><><<",
                  $scope.User.NCandidates.name
                );
                if (
                  $scope.User.NCandidates.PublicPhoneNumber &&
                  $scope.User.NCandidates.PublicPhoneNumber != null
                ) {
                  $scope.User.phone_no =
                    $scope.User.NCandidates.PublicPhoneNumber;
                } else {
                  $scope.User.phone_no = "";
                }
                if (
                  $scope.User.NCandidates.website &&
                  $scope.User.NCandidates.website != null
                ) {
                  $scope.User.web_url = $scope.User.NCandidates.website;
                } else {
                  $scope.User.web_url = "";
                }
                if (
                  $scope.User.NCandidates.public_zipcode &&
                  $scope.User.NCandidates.public_zipcode != null
                ) {
                  $scope.User.ZipCode = $scope.User.NCandidates.public_zipcode;
                } else {
                  $scope.User.ZipCode = "";
                }
                if (
                  $scope.User.NCandidates.public_mailing_address &&
                  $scope.User.NCandidates.public_mailing_address != null
                ) {
                  $scope.User.NCandidates =
                    $scope.User.NCandidates.public_mailing_address;
                } else {
                  $scope.User.mailing_address = "";
                }
                MyService.ConsoleLog(
                  "NCandidates<><><>",
                  $scope.User.NCandidates
                );

                if (
                  ($scope.User.NCandidates.email &&
                    $scope.User.NCandidates.email != null &&
                    $scope.User.NCandidates.email != 0) ||
                  ($scope.User.NCandidates.privateEmail &&
                    $scope.User.NCandidates.privateEmail != null &&
                    $scope.User.NCandidates.privateEmail != 0)
                ) {
                  $scope.HideNewRegisterButton = true;
                  var email = $scope.User.NCandidates.email
                    ? $scope.User.NCandidates.email
                    : $scope.User.NCandidates.privateEmail;
                  var cemail = email;
                  var firstSix = cemail.substr(0, 6);
                  var lastSix = cemail.substr(cemail.length - 6);
                  var xlen = cemail.length - 12;
                  var xstring = "x".repeat(xlen);
                  var finalstring = firstSix + xstring + lastSix;
                  $scope.shortEmail = finalstring;
                  /*
                var cemail = $scope.User.NCandidates.email;
                var firstSix = cemail.substr(0, 6);
                var lastSix = cemail.substr(cemail.length - 6);
                var xlen = cemail.length - 12;
                var xstring = "x".repeat(xlen);
                var finalstring = firstSix + xstring + lastSix;
                var msg = "You are already in our system with the email " + finalstring;
                MyService.GetDialogWithButtons(ngDialog, msg, "SignIn", "Please email me my password").then(function(payload) {
                  MyService.ConsoleLog("Ans<><><>", payload);
                  $scope.User.Email = $scope.User.NCandidates.email;
                  $scope.checkReadOnly = true;
                  if (payload == 1) {
                    $location.path("/signin");
                  } else {
                    var postobj = {
                      email: $scope.User.NCandidates.email,
                      reset_url: $rootScope.getPasswordResetUrl()
                    };
                    MyService.ConsoleLog("postobj: ", postobj);

                    MyService.forgotPassword(postobj)
                      .then(function(response) {
                        if (response.data.success) {
                          growl.success("An email has been sent.");
                        } else {
                          if (typeof response.data.error == "string")
                            growl.error(response.data.error);
                          else
                            growl.error("Something went wrong");
                        }
                      }, function(err) {
                        growl.error("Something went wrong");
                      });
                  }
                }, function(errorPayload) {
                  $scope.User.Email = "";
                  MyService.ConsoleLog("errorPayload<><><>", errorPayload);
                  $scope.checkReadOnly = false;
                  if (errorPayload == 0) {
                    var postobj = {
                      email: $scope.User.NCandidates.email,
                      reset_url: $rootScope.getPasswordResetUrl()
                    };
                    MyService.ConsoleLog("postobj: ", postobj);

                    MyService.forgotPassword(postobj)
                      .then(function(response) {
                        if (response.data.success) {
                          growl.success("An email has been sent.");
                        } else {
                          if (typeof response.data.error == "string")
                            growl.error(response.data.error);
                          else
                            growl.error("Something went wrong");
                        }
                      }, function(err) {
                        growl.error("Something went wrong");
                      });
                  }
                });
                */
                } else {
                  $scope.User.Email = "";
                  $scope.User.phone_no = "";
                  $scope.User.web_url = "";
                  $scope.User.ZipCode = "";
                  $scope.User.mailing_address = "";
                }
              } else {
                //If candidate is Other
                //MyService.ConsoleLog("Other selected");
              }
            } else {
              $scope.User.name = "";
              $scope.User.phone_no = "";
              $scope.User.web_url = "";
              $scope.User.ZipCode = "";
              $scope.User.mailing_address = "";
            }
          }
        }
      };

      $scope.sendPostCard = false;
      $scope.PostcardSend = false;
      $scope.generatePostcard = function () {
        var postCard = Math.random().toString(36).substr(2, 10);

        MyService.checkPostCard({
          postcard: postCard,
        }).then(
          function (payload) {
            // MyService.ConsoleLog("Payload<><><<>",payload);
            var alldata = payload.data;
            // MyService.ConsoleLog("alldata<><><>", alldata);
            var taken = alldata.taken;
            // MyService.ConsoleLog("taken<><><>", taken);
            $scope.PostcardSend = true;
            if (!taken) {
              // MyService.ConsoleLog(taken);
              $scope.User.postCard = postCard.toUpperCase();
            } else {
              // MyService.ConsoleLog(taken);
              //$scope.generatePostcard();
            }
          },
          function (errorPayload) {
            $scope.User.postCard = postCard.toUpperCase();
            // MyService.ConsoleLog("errorPayload<><><<>",errorPayload);
          }
        );
      };

      $scope.submitPostcard = function (data) {
        MyService.ConsoleLog(
          "confirmPostalCode<><><>",
          $scope.User.confirmPostalCode
        );
        if ($scope.User.postCard == $scope.User.confirmPostalCode) {
          $scope.verifiedUser = "true";
          MyService.ConsoleLog($scope.verifiedUser);
          var message = "Thank you for confirming your account.";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                    </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ",data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ",data);
            }
          );
        } else {
          $scope.verifiedUser = "false";
          var message =
            "Your postcard code doesn't match with ours. Please try again.";
          var modal = ngDialog.openConfirm({
            template:
              "<p>" +
              message +
              '</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                    </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ",data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ",data);
            }
          );
        }
      };

      $scope.showcur_office_name = false;
      $scope.showcur_office_location = false;
      $scope.checkExists = function () {
        if ($scope.User.cur_Candidates != "" && !$scope.User.name_on_ballot) {
          MyService.ConsoleLog("inside");
          $scope.User.name_on_ballot = $scope.User.cur_Candidates;
          $scope.showcur_office_name = true;
          $scope.showcur_office_location = true;
        }
      };

      $scope.closeEdit = function (col) {
        // alert("here");
        // MyService.ConsoleLog(col);
        var nval = JSON.parse(col.value);
        // MyService.ConsoleLog(nval);
        $scope.User.Candidates = nval;
        $scope.User.cur_Candidates = nval.name;
        $scope.User.cur_office_name = nval.office;
        $scope.User.cur_office_email = nval.publicEmail;
        $scope.User.postCard = nval.postCard;
        $scope.User.cur_office_phone = nval.publicPhoneNumber;
        $scope.User.cur_office_website = nval.website;
        if ($scope.User.cur_office_name != "") {
          $scope.showcur_office_name = true;
        }
        $scope.User.cur_office_location = nval.officePlace;
        if ($scope.User.cur_office_location != "") {
          $scope.showcur_office_location = true;
        }
        $scope.User.from_date = nval.fromDate;
        $scope.User.end_date = nval.endDate;
        $scope.User.Email = nval.email ? nval.email : nval.privateEmail;
        $scope.User.name_on_ballot = nval.name;

        if (!$scope.User.Email) {
          //alert("here");
          $scope.sendPostCard = true;
        }

        MyService.ConsoleLog("Email:::", $scope.User.Email);
        // var result = document.getElementsByClassName("ac-container");
        // MyService.ConsoleLog(result);
        // result[0].blur();
        document
          .getElementById("curCandidate")
          .getElementsByTagName("input")[0]
          .blur();
        $scope.onCandidateChange("incumbent");
        //var nval = col.value? col.value: col.displayName
      };

      function suggest_name(term) {
        var q = term.toLowerCase().trim();
        var results = [];
        var len = $scope.candidates.length;
        // Find first 10 states that start with `term`.
        for (var i = 0; i < len && results.length < 10; i++) {
          var name = $scope.candidates[i].name;
          var matched = name.match(new RegExp(q, "i"));
          //MyService.ConsoleLog(state.match())
          //MyService.ConsoleLog(matched);
          //MyService.ConsoleLog(state.match(q, "i"));
          if (matched) {
            results.push({
              label: $sce.trustAsHtml(name),
              value: JSON.stringify($scope.candidates[i]),
            });
          }
        }
        return results;
      }

      function highlight(str, term) {
        // MyService.ConsoleLog("str<><><><",str);
        var highlight_regex = new RegExp("(" + term + ")", "gi");
        return str.replace(
          highlight_regex,
          '<span class="highlight">$1</span>'
        );
      }

      function fuzzy_suggest(term) {
        if (!term) return [];
        // MyService.ConsoleLog(fuzzySearch);
        return fuzzySearch
          .search(term)
          .slice(0, 10)
          .map(function (i) {
            // MyService.ConsoleLog("I",i);
            // MyService.ConsoleLog($scope.candidates);
            // MyService.ConsoleLog(i);
            var val = i.name;
            // MyService.ConsoleLog("val:::", val);
            return {
              value: JSON.stringify(i),
              label: $sce.trustAsHtml(highlight(val + "-" + i.office, term)),
            };
          });
      }

      $scope.autocomplete_options = {
        suggest: fuzzy_suggest,
        on_select: $scope.closeEdit,
      };

      $scope.running_flagShowCandidateDropdown = false;
      $scope.closeEditNew = function (col) {
        // alert("here");
        // MyService.ConsoleLog(col);
        var nval = JSON.parse(col.value);
        MyService.ConsoleLog(nval);
        $scope.User.NCandidates = nval;
        $scope.User.new_Candidates = nval.name;
        $scope.User.office_name = nval.office;
        $scope.User.office_email = nval.email;
        $scope.User.office_phone = nval.publicPhoneNumber;
        $scope.User.office_website = nval.website;
        if ($scope.User.cur_office_name != "") {
          $scope.showcur_office_name = true;
        }
        $scope.User.office_location = nval.officePlace;

        if ($scope.User.cur_office_location != "") {
          $scope.showcur_office_location = true;
        }

        $scope.User.from_date = nval.fromDate;
        $scope.User.end_date = nval.endDate;
        $scope.User.Email = nval.email ? nval.email : nval.privateEmail;
        $scope.User.name_on_ballot = nval.name;
        // alert($scope.User.Email);
        if (!$scope.User.Email) {
          //alert("here=", $scope.User.Email);
          $scope.sendPostCardNew = true;
        }

        // MyService.ConsoleLog("Email:::", $scope.User.Email);
        // var result = document.getElementsByClassName("ac-container");
        // MyService.ConsoleLog(result);
        // result[0].blur();

        document
          .getElementById("curCandidate")
          .getElementsByTagName("input")[0]
          .blur();
        $scope.onCandidateChange("running");
        //var nval = col.value? col.value: col.displayName
      };

      $scope.HideNewRegisterButton = false;
      $scope.shownew_office_name = false;
      $scope.checkExistsNew = function () {
        // MyService.ConsoleLog($scope.User.new_Candidates);
        // MyService.ConsoleLog("name_on_ballot<><><><>",$scope.User.name_on_ballot);
        if (
          $scope.User.new_Candidates != "" &&
          $scope.User.new_Candidates != $scope.User.name_on_ballot
        ) {
          // MyService.ConsoleLog("inside");
          $scope.User.name_on_ballot = $scope.User.new_Candidates;
        }

        if (!$scope.User.NCandidates) {
          //alert("NCandidates");
          $scope.shownew_office_name = true;
        }
      };

      function fuzzy_suggest_new(term) {
        if (!term) return [];
        // MyService.ConsoleLog(fuzzySearch);
        return fuzzySearch
          .search(term)
          .slice(0, 10)
          .map(function (i) {
            // MyService.ConsoleLog("I",i);
            // MyService.ConsoleLog($scope.candidates);
            // MyService.ConsoleLog(i);
            var val = i.name;
            // MyService.ConsoleLog("val:::", val);
            return {
              value: JSON.stringify(i),
              label: $sce.trustAsHtml(highlight(val + "-" + i.office, term)),
            };
          });
      }

      $scope.autocomplete_new_options = {
        suggest: fuzzy_suggest_new,
        on_select: $scope.closeEditNew,
      };
      $scope.allPoliticians = [];

      function suggest_politician_name(term) {
        var results = [];
        for (var i = 0; i < $scope.allPoliticians.length; i++) {
          results.push({
            label: $sce.trustAsHtml(
              $scope.allPoliticians[i].name +
                "-" +
                $scope.allPoliticians[i].office
            ),
            value: JSON.stringify($scope.allPoliticians[i]),
          });
        }
        return results;
      }

      function suggest_politician_name_remote(term) {
        var deferred = $q.defer();
        var params = new Object();
        params.term = term;
        $scope.allPoliticians.length = 0;
        var request = apiCall.apiCall("GET", "/politician/fuzzySearch", params);
        // request.timeout = canceler;
        $http(request).then(
          function successCallback(response) {
            // MyService.ConsoleLog("Got Politician: ", response);
            $scope.allPoliticians = response.data.data;
            /*
        if (response.data.success) {
            deferred.resolve(response.data.data);
        } else {
            deferred.reject(response.data);
        }
        */
            deferred.resolve(suggest_politician_name(term));
          },
          function errorCallBack(response) {
            deferred.reject(response);
          }
        );
        return deferred.promise;
      }

      $scope.onCandidateChangePolitician = function (val) {
        //$scope.User.Candidates;
        $scope.checkReadOnly = false;

        MyService.ConsoleLog($scope.User.name_on_ballot);
        if ($scope.User.name_on_ballot) {
          if (
            $scope.User.name_on_ballot.name &&
            $scope.User.name_on_ballot.name != null
          ) {
            if ($scope.User.name_on_ballot.name != "Other") {
              $scope.User.name = $scope.User.name_on_ballot.name;
              if (
                $scope.User.name_on_ballot.PublicPhoneNumber &&
                $scope.User.name_on_ballot.PublicPhoneNumber != null
              ) {
                $scope.User.phone_no =
                  $scope.User.name_on_ballot.PublicPhoneNumber;
              } else {
                $scope.User.phone_no = "";
              }
              if (
                $scope.User.name_on_ballot.website &&
                $scope.User.name_on_ballot.website != null
              ) {
                $scope.User.web_url = $scope.User.name_on_ballot.website;
              } else {
                $scope.User.web_url = "";
              }
              if (
                $scope.User.name_on_ballot.public_zipcode &&
                $scope.User.name_on_ballot.public_zipcode != null
              ) {
                $scope.User.ZipCode = $scope.User.name_on_ballot.public_zipcode;
              } else {
                $scope.User.ZipCode = "";
              }
              if (
                $scope.User.name_on_ballot.public_mailing_address &&
                $scope.User.name_on_ballot.public_mailing_address != null
              ) {
                $scope.User.mailing_address =
                  $scope.User.name_on_ballot.public_mailing_address;
              } else {
                $scope.User.mailing_address = "";
              }
              /*
            if ($scope.User.name_on_ballot.email && $scope.User.name_on_ballot.email != null && $scope.User.name_on_ballot.email != 0) {
              var msg = "We already have your email with us.<br> Your Email in our db is : <br> <center>" + $scope.User.name_on_ballot.email + "<center><br> Do you want to use it for registration?"
              MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function(payload) {
                $scope.User.Email = $scope.User.name_on_ballot.email;
                $scope.checkReadOnly = true;
              }, function(errorPayload) {
                $scope.User.Email = "";
                $scope.checkReadOnly = false;
              });
            } else {
              $scope.User.Email = "";
              $scope.User.phone_no = "";
              $scope.User.web_url = "";
              $scope.User.ZipCode = "";
              $scope.User.mailing_address = "";
            }
            */
            } else {
              //If candidate is Other
              //MyService.ConsoleLog("Other selected");
            }
          } else {
            $scope.User.name = "";
            $scope.User.phone_no = "";
            $scope.User.web_url = "";
            $scope.User.ZipCode = "";
            $scope.User.mailing_address = "";
          }
        }
      };
      $scope.politicianSelected = false;
      $scope.selectedPolitician = function (col) {
        // alert("here");
        MyService.ConsoleLog(col);
        var nval = JSON.parse(col.value);
        MyService.ConsoleLog(nval);
        $scope.User.name_on_ballot = nval.name;
        var result = document.getElementsByClassName("ac-container");
        // MyService.ConsoleLog(result);
        result[0].style.display = "none";
        // var wrappedResult = angular.element(result);
        // MyService.ConsoleLog(wrappedResult);
        $scope.politicianSelected = true;
        $scope.onCandidateChangePolitician();
        //var nval = col.value? col.value: col.displayName
      };

      $scope.autocomplete_politician_name = {
        suggest: suggest_politician_name_remote,
        on_select: $scope.selectedPolitician,
      };

      var running_flagCongressional = false;
      var flagCongressional = false;
      $scope.showCongressionalDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCongressionalDropdown");
        if (electionFor == "running") {
          if (
            $scope.User.office_name == "house of representatives" &&
            $scope.User.state
          ) {
            running_flagCongressional = true;
            return true;
          }
          running_flagCongressional = false;
          return false;
        } else if (electionFor == "incumbent") {
          if (
            $scope.User.cur_office_name == "house of representatives" &&
            $scope.User.cur_state
          ) {
            flagCongressional = true;
            return true;
          }
          flagCongressional = false;
          return false;
        }
      };
      var running_flagDist = false;
      var flagDist = false;
      $scope.showDistrictDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "judicial district":
              if ($scope.User.state) {
                running_flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          running_flagDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "judicial district":
              if ($scope.User.cur_state) {
                flagDist = true;
                return true;
              }
              // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
              break;
          }
          flagDist = false;
          return false;
        }
      };
      var running_flagConty = false;
      var flagConty = false;
      $scope.showCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.state) {
                running_flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.state &&
                exceptionState.indexOf($scope.User.state.statePostalCode) == -1
              ) {
                running_flagConty = true;
                return true;
              }
              break;
          }
          running_flagConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "county":
            case "sub county":
            case "city":
              if ($scope.User.cur_state) {
                flagConty = true;
                return true;
              }
              break;
            case "local school district":
              var exceptionState = ["AK", "DC", "VA"];
              if (
                $scope.User.cur_state &&
                exceptionState.indexOf($scope.User.cur_state.statePostalCode) ==
                  -1
              ) {
                flagConty = true;
                return true;
              }
              break;
          }
          flagConty = false;
          return false;
        }
      };
      var running_flagSubConty = false;
      var flagSubConty = false;
      $scope.showSubCountyDropdown = function (electionFor) {
        //MyService.ConsoleLog("showSubCountyDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "sub county":
              if ($scope.User.state && $scope.User.county) {
                running_flagSubConty = true;
                return true;
              }
              break;
          }
          running_flagSubConty = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "sub county":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagSubConty = false;
                return true;
              }
              break;
          }
          flagSubConty = false;
          return false;
        }
      };
      var running_flagLocality = false;
      var flagLocality = false;
      $scope.showLocalityDropdown = function (electionFor) {
        //MyService.ConsoleLog("showLocalityDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "city":
              if ($scope.User.state && $scope.User.county) {
                running_flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          running_flagLocality = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "city":
              if ($scope.User.cur_state && $scope.User.cur_county) {
                flagLocality = true;
                return true;
              }
              // for options, list all unique county subdivision names and place names within the selected county within the selected state
              break;
          }
          flagLocality = false;
          return false;
        }
      };
      var running_flagStateSchoolDist = false;
      var flagStateSchoolDist = false;
      $scope.showStateSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showStateSchoolDistDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "state school board district":
              if ($scope.User.state) {
                running_flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          running_flagStateSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "state school board district":
              if ($scope.User.cur_state) {
                flagStateSchoolDist = true;
                return true;
              }
              // For the District options,
              //list all item E’s where item Q is the state selected
              break;
          }
          flagStateSchoolDist = false;
          return false;
        }
      };
      var running_flagLocalSchoolDist = false;
      var flagLocalSchoolDist = false;
      $scope.showLocalSchoolDistDropdown = function (electionFor) {
        //MyService.ConsoleLog("showDistrictDropdown");
        if (electionFor == "running") {
          switch ($scope.User.level) {
            case "local school district":
              if ($scope.User.state) {
                if ($scope.User.state && $scope.localSchoolDistException()) {
                  if ($scope.User.state && $scope.User.county) {
                    running_flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  running_flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }
          running_flagLocalSchoolDist = false;
          return false;
        } else if (electionFor == "incumbent") {
          switch ($scope.User.cur_level) {
            case "local school district":
              if ($scope.User.cur_state) {
                if (
                  $scope.User.cur_state &&
                  $scope.localSchoolDistException()
                ) {
                  if ($scope.User.cur_state && $scope.User.cur_county) {
                    flagLocalSchoolDist = true;
                    return true;
                  }
                  // For the District options,
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                } else if ($scope.User.cur_state) {
                  // for District options
                  // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                  flagLocalSchoolDist = true;
                  return true;
                }
              }
              break;
          }
          flagLocalSchoolDist = false;
          return false;
        }
      };

      $scope.congressionalDistricts = [];
      var getAddressOfRunningPoliCanceler = $q.defer();
      $scope.running_flagShowOfficeDropdown = false;
      $scope.flagShowOfficeDropdown = false;
      $scope.showlevelOfGovt = [];
      $scope.onStateChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateChange **", electionFor);
        if (electionFor == "running") {
          //'State and Federal', 'County', 'School', 'Local', 'Judicial'
          switch ($scope.User.level) {
            case "County":
              $scope.showlevelOfGovt = angular.copy($scope.countylevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "State and Federal":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "School":
              $scope.showlevelOfGovt = angular.copy($scope.schoollevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "Judicial":
              $scope.showlevelOfGovt = angular.copy($scope.judiciallevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.running_flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.running_flagShowOfficeDropdown = false;
              break;
          }
          //MyService.ConsoleLog($scope.User.level);
          /*setTimeout(function() {
            $scope.$apply(function() {
                $scope.showlevelOfGovt = $scope.showlevelOfGovt1;
                //MyService.ConsoleLog($scope.showlevelOfGovt);
                $scope.User.off_name = $scope.showlevelOfGovt[0];
            });
        }, 100);*/
          //$scope.User.off_name = $scope.showlevelOfGovt[0];

          /*
        if ($scope.User.state) {

            switch ($scope.User.level) {
                case 'state':
                    if ($scope.User.state) {
                        $scope.User.office_name = null;
                        //fetch office names for this
                        //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is = "State Postal Code", and item Q is the state selected.  Then add “other” to the end of the list
                        $scope.running_offices = [];
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            voterScope: "State Postal Code",
                            return_field: "office",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.running_offices = response.data.data;
                                    $scope.running_offices.push("other");
                                    //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'judicial district':
                    if ($scope.User.state) {
                        $scope.User.office_name = null;
                        // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            return_field: "JudicialCircuitNumber",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.running_districts = response.data.data;
                                    //MyService.ConsoleLog("--- Got district data ---: ", $scope.running_districts);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case "county":
                case "sub county":
                case "city":
                    if ($scope.User.state) {
                        $scope.User.office_name = null;
                        // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            return_field: "CountyName",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.running_counties = response.data.data;
                                    //MyService.ConsoleLog("--- Got counties data ---: ", $scope.running_counties);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'state school board district':
                    if ($scope.User.state) {
                        $scope.User.office_name = null;
                        // do a search through the table currently labelled “2016 Alabama” and list all item E’s where item Q is the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            return_field: "StateBoardEducationDistrict",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.running_stateSchoolDistricts = response.data.data;
                                    //MyService.ConsoleLog("--- Got district data ---: ", running_stateSchoolDistricts);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'local school district':
                    $scope.User.office_name = null;
                    if (!$scope.localSchoolDistException()) {
                        // /if($scope.User.cur_state && $scope.localSchoolDistException())
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            return_field: "CountyName",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.running_counties = response.data.data;
                                    //MyService.ConsoleLog("--- Got counties data ---: ", $scope.running_counties);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    } else {
                        //for options
                        // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.state.statePostalCode,
                            return_field: "UnifiedSchoolDistrictName",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    var tempArr = [];
                                    angular.forEach(response.data.data, function(val, key) {
                                        tempArr.push({
                                            label: val,
                                            type: "UnifiedSchoolDistrictName"
                                        });
                                    });
                                    $scope.localSchoolDistricts = tempArr;
                                    //MyService.ConsoleLog("--- Got localSchoolDistricts data ---: ", $scope.localSchoolDistricts);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;
            }

            if ($scope.User.office_name) {
                if ($scope.User.state) {
                    getAddressOfRunningPoliCanceler.resolve();
                    MyService.getAddressOfRunningPoli({
                        StatePostalCode: $scope.User.state.statePostalCode,
                        return_field: "FederalCongressionalDistrict",
                        timeout: getAddressOfRunningPoliCanceler.promise
                    }).then(function(response) {
                        if (response.data.success == true) {
                            $timeout(function() {
                                $scope.running_congressionalDistricts = response.data.data;
                                //MyService.ConsoleLog("--- Got congressionalDistricts data ---");
                            });
                        } else if (response.data.error && typeof response.data.error == "string") {
                            growl.error('Error: ' + response.data.error);
                        } else {
                            growl.error('Something went wrong.');
                        }
                    });
                }
            }
        } //state selected
        */
          //$scope.User.off_name = $scope.showlevelOfGovt[0];
        } else if (electionFor == "incumbent") {
          /*
        if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
                case 'state':
                    if ($scope.User.cur_state) {
                        $scope.User.cur_office_name = null;
                        //fetch office names for this
                        //For the options, do a search through the table currently labelled “2016 Alabama” and Find all Item A’s where the Item D is = "State Postal Code", and item Q is the state selected.  Then add “other” to the end of the list
                        $scope.offices = [];
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.cur_state.statePostalCode,
                            voterScope: "State Postal Code",
                            return_field: "office",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.offices = response.data.data;
                                    $scope.offices.push("other");
                                    //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'judicial district':
                    if ($scope.User.cur_state) {
                        $scope.User.cur_office_name = null;
                        // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.cur_state.statePostalCode,
                            return_field: "JudicialCircuitNumber",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.districts = response.data.data;
                                    //MyService.ConsoleLog("--- Got district data ---: ", $scope.districts);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case "county":
                case "sub county":
                case "city":
                    if ($scope.User.cur_state) {
                        $scope.User.cur_office_name = null;
                        // For the District options, do a search through the table currently labelled “2016 Alabama” and Find all Item F’s with item Q as the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.cur_state.statePostalCode,
                            return_field: "CountyName",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.counties = response.data.data;
                                    //MyService.ConsoleLog("--- Got counties data ---: ", $scope.counties);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'state school board district':
                    if ($scope.User.cur_state) {
                        $scope.User.cur_office_name = null;
                        // do a search through the table currently labelled “2016 Alabama” and list all item E’s where item Q is the state selected.
                        MyService.getAddressOfRunningPoli({
                            StatePostalCode: $scope.User.cur_state.statePostalCode,
                            return_field: "StateBoardEducationDistrict",
                            timeout: getAddressOfRunningPoliCanceler.promise
                        }).then(function(response) {
                            if (response.data.success == true) {
                                $timeout(function() {
                                    $scope.stateSchoolDistricts = response.data.data;
                                    //MyService.ConsoleLog("--- Got district data ---: ", $scope.districts);
                                });
                            } else if (response.data.error && typeof response.data.error == "string") {
                                growl.error('Error: ' + response.data.error);
                            } else {
                                growl.error('Something went wrong.');
                            }
                        });
                    }
                    break;

                case 'local school district':
                    if ($scope.User.cur_state) {
                        $scope.User.cur_office_name = null;
                        if (!$scope.localSchoolDistException()) {
                            // /if($scope.User.cur_state && $scope.localSchoolDistException())
                            MyService.getAddressOfRunningPoli({
                                StatePostalCode: $scope.User.cur_state.statePostalCode,
                                return_field: "CountyName",
                                timeout: getAddressOfRunningPoliCanceler.promise
                            }).then(function(response) {
                                if (response.data.success == true) {
                                    $timeout(function() {
                                        $scope.counties = response.data.data;
                                        //MyService.ConsoleLog("--- Got counties data ---: ", $scope.counties);
                                    });
                                } else if (response.data.error && typeof response.data.error == "string") {
                                    growl.error('Error: ' + response.data.error);
                                } else {
                                    growl.error('Something went wrong.');
                                }
                            });
                        } else {
                            //for options
                            // do a search through the table currently labelled “2016 Alabama” and find all AD’s where the item Q is the state selected.
                            MyService.getAddressOfRunningPoli({
                                StatePostalCode: $scope.User.cur_state.statePostalCode,
                                return_field: "UnifiedSchoolDistrictName",
                                timeout: getAddressOfRunningPoliCanceler.promise
                            }).then(function(response) {
                                if (response.data.success == true) {
                                    $timeout(function() {
                                        var tempArr = [];
                                        angular.forEach(response.data.data, function(val, key) {
                                            tempArr.push({
                                                label: val,
                                                type: "UnifiedSchoolDistrictName"
                                            });
                                        });
                                        $scope.localSchoolDistricts = tempArr;
                                        //MyService.ConsoleLog("--- Got localSchoolDistricts data ---: ", $scope.localSchoolDistricts);
                                    });
                                } else if (response.data.error && typeof response.data.error == "string") {
                                    growl.error('Error: ' + response.data.error);
                                } else {
                                    growl.error('Something went wrong.');
                                }
                            });
                        }
                    }
                    break;
            }

            if ($scope.User.cur_office_name) {
                if ($scope.User.cur_state) {
                    getAddressOfRunningPoliCanceler.resolve();
                    MyService.getAddressOfRunningPoli({
                        StatePostalCode: $scope.User.cur_state.statePostalCode,
                        return_field: "FederalCongressionalDistrict",
                        timeout: getAddressOfRunningPoliCanceler.promise
                    }).then(function(response) {
                        if (response.data.success == true) {
                            $timeout(function() {
                                $scope.congressionalDistricts = response.data.data;
                                //MyService.ConsoleLog("--- Got congressionalDistricts data ---");
                            });
                        } else if (response.data.error && typeof response.data.error == "string") {
                            growl.error('Error: ' + response.data.error);
                        } else {
                            growl.error('Something went wrong.');
                        }
                    });
                }
            }
        } //state selected
        */
          switch ($scope.User.cur_level) {
            ////'State and Federal', 'County', 'School', 'Local', 'Judicial'
            case "County":
              $scope.showlevelOfGovt = angular.copy($scope.countylevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "State and Federal":
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "School":
              $scope.showlevelOfGovt = angular.copy($scope.schoollevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "Judicial":
              $scope.showlevelOfGovt = angular.copy($scope.judiciallevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            case "Local":
              $scope.showlevelOfGovt = angular.copy($scope.locallevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
            default:
              $scope.showlevelOfGovt = angular.copy($scope.statelevelOfGovt);
              $scope.flagShowOfficeDropdown = true;
              break;
          }
          //MyService.ConsoleLog($scope.showlevelOfGovt);
          //$scope.User.cur_off_name = $scope.showlevelOfGovt[0];
        }
      };
      $scope.onDistrictChange = function (electionFor) {
        //MyService.ConsoleLog("** onDistrictChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "judicial district":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onDistrictChange **");
            switch ($scope.User.cur_level) {
              case "judicial district":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item F is the district selected. Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    return_field: "office",
                    JudicialCircuitNumber: $scope.User.cur_district,
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //state selected
        }
      };
      $scope.onCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "county":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.state && $scope.User.county) {
                  $scope.User.cur_office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.running_subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.state) {
                  $scope.User.office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.User.county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.running_localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.running_localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                $scope.User.office_name = null;
                if (!$scope.localSchoolDistException() && $scope.User.county) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.state.statePostalCode,
                        CountyName: $scope.county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.state.statePostalCode,
                      CountyName: $scope.county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.running_localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "county":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  //For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "sub county":
                if ($scope.User.cur_state && $scope.User.cur_county) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    voterScope: "County Name",
                    return_field: "CountySubdivisionName",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.subcounties = response.data.data;
                        //MyService.ConsoleLog("--- Got sub counties data ---: ", $scope.subcounties);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
              case "city":
                if ($scope.User.cur_state) {
                  $scope.User.cur_office_name = null;
                  // For the options, list all unique county subdivision names and place names within the selected county within the selected state
                  var promiseArr = {
                    counties: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "CountySubdivisionName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    places: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.User.cur_county,
                      return_field: "PlaceName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(function (response) {
                    //MyService.ConsoleLog("response: ", response);
                    if (
                      response.counties.data.success &&
                      response.places.data.success
                    ) {
                      $timeout(function () {
                        var tempArr = [];
                        angular.forEach(
                          response.counties.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "county",
                            });
                          }
                        );
                        angular.forEach(
                          response.places.data.data,
                          function (val, key) {
                            tempArr.push({
                              label: val,
                              type: "place",
                            });
                          }
                        );

                        // tempArr = [{label: "tetst", type: "county"}, {label: "tetst 2", type: "place"}];

                        $scope.localities = tempArr;
                        //MyService.ConsoleLog("--- Got localities data ---: ", $scope.localities);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;

              case "local school district":
                //MyService.ConsoleLog("cur_county : ", $scope.User.cur_county);
                $scope.User.cur_office_name = null;
                if (
                  !$scope.localSchoolDistException() &&
                  $scope.User.cur_county
                ) {
                  // results of a search through the table currently labelled “2016 Alabama” and find all AD’s, AF’s  and AH’s  where item Q is the state selected and item S is the “County” selection
                  var promiseArr = {
                    UnifiedSchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "UnifiedSchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                    ElementarySchoolDistrict: MyService.getAddressOfRunningPoli(
                      {
                        StatePostalCode: $scope.User.cur_state.statePostalCode,
                        CountyName: $scope.cur_county,
                        return_field: "ElementarySchoolDistrictName",
                        timeout: getAddressOfRunningPoliCanceler.promise,
                      }
                    ),
                    SecondarySchoolDistrict: MyService.getAddressOfRunningPoli({
                      StatePostalCode: $scope.User.cur_state.statePostalCode,
                      CountyName: $scope.cur_county,
                      return_field: "SecondarySchoolDistrictName",
                      timeout: getAddressOfRunningPoliCanceler.promise,
                    }),
                  };

                  $q.all(promiseArr).then(
                    function (response) {
                      //MyService.ConsoleLog("response: ", response);
                      if (
                        response.UnifiedSchoolDistrict.data.success &&
                        response.ElementarySchoolDistrict.data.success &&
                        response.SecondarySchoolDistrict.data.success
                      ) {
                        $timeout(function () {
                          var tempArr = [];
                          angular.forEach(
                            response.UnifiedSchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "UnifiedSchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.ElementarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "ElementarySchoolDistrictName",
                              });
                            }
                          );
                          angular.forEach(
                            response.SecondarySchoolDistrict.data.data,
                            function (val, key) {
                              tempArr.push({
                                label: val,
                                type: "SecondarySchoolDistrictName",
                              });
                            }
                          );
                          //MyService.ConsoleLog("$scope.localSchoolDistricts: ", tempArr);
                          $scope.localSchoolDistricts = tempArr;
                        });
                      } else {
                        //MyService.ConsoleLog("response: ", response);
                        growl.error("Something went wrong.");
                      }
                    },
                    function (err) {
                      //MyService.ConsoleLog("err: ", err);
                      growl.error("Something went wrong.");
                    }
                  );
                }
                break;
            }
          } //state selected
        }
      };
      $scope.onSubCountyChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.level) {
              case "sub county":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onCountyChange **");
            switch ($scope.User.cur_level) {
              case "sub county":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.subcounty
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    // voterScope: "County Name",
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalityChange = function (electionFor) {
        //MyService.ConsoleLog("** onLocalityChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "city":
                if (
                  $scope.User.state &&
                  $scope.User.county &&
                  $scope.User.locality
                ) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    CountyName: $scope.User.county,
                    CountySubdivisionName: $scope.User.locality.label,
                    PlaceName: $scope.User.locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "city":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_county &&
                  $scope.User.cur_locality
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // For the options, do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item S is the county selected and U or W match the “city” selection.  Then add “other” to the bottom of the list
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    CountyName: $scope.User.cur_county,
                    CountySubdivisionName: $scope.User.cur_locality.label,
                    PlaceName: $scope.User.cur_locality.label,
                    office_for: "place", //this is special case
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onStateSchoolDistChange = function (electionFor) {
        //MyService.ConsoleLog("** onStateSchoolDistChange **");
        if (electionFor == "running") {
          if ($scope.User.state) {
            switch ($scope.User.level) {
              case "state school board district":
                if ($scope.User.state && $scope.User.state_school_dist) {
                  $scope.User.office_name = null;
                  $scope.running_offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.state.statePostalCode,
                    StateBoardEducationDistrict: $scope.User.state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.running_offices = response.data.data;
                        $scope.running_offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.running_offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            switch ($scope.User.cur_level) {
              case "state school board district":
                if (
                  $scope.User.cur_state &&
                  $scope.User.cur_state_school_dist
                ) {
                  $scope.User.cur_office_name = null;
                  $scope.offices = [];
                  //fetch office names for this
                  // do a search through the table currently labelled “2016 Alabama” and find all A’s where item Q is the state selected and item E is the “District” selection.  Then add “other” to the bottom of the list.
                  MyService.getAddressOfRunningPoli({
                    StatePostalCode: $scope.User.cur_state.statePostalCode,
                    StateBoardEducationDistrict:
                      $scope.User.cur_state_school_dist,
                    return_field: "office",
                    timeout: getAddressOfRunningPoliCanceler.promise,
                  }).then(function (response) {
                    if (response.data.success == true) {
                      $timeout(function () {
                        $scope.offices = response.data.data;
                        $scope.offices.push("other");
                        //MyService.ConsoleLog("--- Got offices data ---: ", $scope.offices);
                      });
                    } else if (
                      response.data.error &&
                      typeof response.data.error == "string"
                    ) {
                      growl.error("Error: " + response.data.error);
                    } else {
                      growl.error("Something went wrong.");
                    }
                  });
                }
                break;
            }
          } //s
        }
      };
      $scope.onLocalSchoolDistChange = function (electionFor) {
        if (electionFor == "running") {
          if ($scope.User.state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        } else if (electionFor == "incumbent") {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("** onLocalSchoolDistChange **");
            switch ($scope.User.level) {
              case "local school district":
                break;
            }
          } //s
        }
      };

      $scope.CheckUserName = function () {
        $scope.Errors = [];
        MyService.checkUnm({
          username: $scope.User.UserName,
        }).then(
          function (response) {
            if (response.data.taken == true) {
              //$scope.Errors.push('Oops! Username ' + $scope.User.UserName + ' has already been taken.');
              growl.error(
                "Oops! Username " +
                  $scope.User.UserName +
                  " has already been taken."
              );
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.SignIn = function () {
        $http
          .post(baseApiUrl + "user/authenticate", {
            username: $scope.User.UserName,
            password: $scope.User.Password,
          })
          .then(
            function (response) {
              if (response.data.success == true) {
                tokenService.saveToken(response.data.token);
                tokenService.saveUserName(response.data.name);
                $http.defaults.headers.common["Authorization"] =
                  tokenService.getToken();
                $rootScope.Auth = true;
                $rootScope.User = tokenService.getUserName();
                $location.path("debate");
              } else {
                growl.error("Invalid Username or Password. Please try again.");
              }
            },
            function (response) {
              var validataionMsg = "";
              var errors = response.data.error.errors;
              for (var k in errors) {
                if (errors.hasOwnProperty(k)) {
                  var obj = errors[k];
                  if (obj.message !== undefined)
                    validataionMsg += obj.message.replace("Path", "") + "<br/>";
                }
              }
              growl.error(validataionMsg);
            }
          );
      };

      $scope.generatePostUserObj = function () {
        //if ($.fn.validateForceFully($("#fiveminform")) == true) {

        var mainLocation = JSON.stringify($rootScope.location);

        MyService.ConsoleLog(
          " $$$$$$$$$$$$$$ My Address $$$$$$$$$$$$$$ :",
          $scope.address
        );

        $scope.Errors = [];
        /* var mainAddress = JSON.stringify({
           state: $scope.address.state ? $scope.address.state.state : '',
           statePostalCode: $scope.address.state.statePostalCode ?
             $scope.address.state.statePostalCode : $scope.statePostalCode,
           place: $scope.address.place ? $scope.address.place.place : $scope.place_name,
           // placeFips:$scope.address.place.placeFips?$scope.address.place.placeFips:"",
           country: $scope.countryname ? $scope.countryname : $scope.county_name,
           countyFips: $scope.address.county ? $scope.address.county.countyFips : '',
           county: $scope.address.county ? $scope.address.county.county : '',
           countySubdivision: $scope.address.countySubdivision ?
             $scope.address.countySubdivision.countySubdivision : '',
           federalCongrassionalDist: $scope.address.federalCongrassionalDist.CD115FP ?
             $scope.address.federalCongrassionalDist.CD115FP : '',
           stateSenateDistrict: $scope.address.stateSenateDistrict.SLDUST ?
             $scope.address.stateSenateDistrict.SLDUST : '',
           schoolDistrict: $scope.address.unifiedSchoolDistrictName ?
             $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrictName : '',
           schoolDistrictFips: $scope.address.unifiedSchoolDistrictName ?
             $scope.address.unifiedSchoolDistrictName.UNSDLEA ?
             $scope.address.unifiedSchoolDistrictName.UNSDLEA :
             '' : '',

           // voterDistrictFips: $scope.address.voterDistrict ? $scope.address.voterDistrict.voterDistrict : '',
           // voterDistrict: $scope.address.voterDistrict ? $scope.address.voterDistrict.voterDistrictName : '',
           legislativeDistrict: $scope.address.stateHouseOFRepresentativeDistrict ?
             $scope.address.stateHouseOFRepresentativeDistrict.stateHouseOFRepresentativeDistrict : '',
         });*/
        if ($scope.UserType == "advocate") {
          // alert("welcome");
          var mainAddress = JSON.stringify({
            state: $scope.state,
            statePostalCode: $scope.statePostalCode
              ? $scope.statePostalCode
              : $scope.astatePostalCode,
            place: $scope.place_name ? $scope.place_name : $scope.aplace,
            county: $scope.county_name ? $scope.county_name : $scope.acounty,
            country: $scope.countryname ? $scope.countryname : $scope.acountry,
            countySubdivision: $scope.address.countySubdivision
              ? $scope.address.countySubdivision._id.countySubdivision
              : $scope.acountySubdivision,
            federalCongrassionalDist: $scope.address.federalCongrassionalDist
              ? $scope.address.federalCongrassionalDist.CD115FP
              : "",
            stateSenateDistrict: $scope.address.stateSenateDistrict
              ? $scope.address.stateSenateDistrict.NAMELSAD
              : "",
            schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
              ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
              : "",
            schoolDistrict: $scope.address.unifiedSchoolDistrictName
              ? $scope.address.unifiedSchoolDistrictName.NAME
              : "",
            voterDistrictFips: $scope.address.voterDistrict
              ? $scope.address.voterDistrict.voterDistrict
              : "",
            voterDistrict: $scope.address.voterDistrict
              ? $scope.address.voterDistrict.voterDistrictName
              : "",
            legislativeDistrict: $scope.address
              .stateHouseOFRepresentativeDistrict
              ? $scope.address.stateHouseOFRepresentativeDistrict.NAMELSAD
              : "",
            cityCouncilDistrictName: $scope.address.cityCouncilDistrict
              ? $scope.address.cityCouncilDistrict.COUNTDIST
              : "",
          });
        } else {
          if ($scope.User.cur_level != "County") {
            // alert("flag");
            $scope.checkFlag = true;
          }
          MyService.ConsoleLog("$scope.User.cur_state", $scope.User.cur_state);
          MyService.ConsoleLog("$scope.User", $scope.User);

          var mainAddress = JSON.stringify({
            state: $scope.User.cur_state
              ? $scope.User.cur_state.state
              : $scope.address
              ? $scope.address.state.state
              : "",
            statePostalCode: $scope.address.state.statePostalCode
              ? $scope.address.state.statePostalCode
              : $scope.statePostalCode,
            stateFips: $scope.address.state
              ? $scope.address.state.stateFips
              : "",
            place: $scope.address.place ? $scope.address.place.placeName : "",
            placeFips: $scope.address.place
              ? $scope.address.place.placeFips
              : "",
            county: $scope.address.county
              ? $scope.address.county.countyName
              : "",
            countyFips: $scope.address.county
              ? $scope.address.county.countyFips
              : "",
            country: $scope.countryname ? $scope.countryname : "",
            countySubdivision: $scope.address.countySubdivision
              ? $scope.address.countySubdivision.countySubdivision
              : "",
            countySubdivisionFips: $scope.address.countySubdivision
              ? $scope.address.countySubdivision.countySubdivisionFips
              : "",
            federalCongrassionalDist: $scope.address.federalCongrassionalDist
              ? $scope.address.federalCongrassionalDist.CD115FP
              : "",
            stateSenateDistrict: $scope.address.stateSenateDistrict
              ? $scope.address.stateSenateDistrict.NAMELSAD
              : "",
            schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
              ? $scope.address.unifiedSchoolDistrictName
                  .unifiedSchoolDistrictFips
              : "",
            schoolDistrict: $scope.address.unifiedSchoolDistrictName
              ? $scope.address.unifiedSchoolDistrictName
                  .unifiedSchoolDistrictName
              : "",
            voterDistrictFips: $scope.address.voterDistrict
              ? $scope.address.voterDistrict.voterDistrict
              : "",
            voterDistrict: $scope.address.voterDistrict
              ? $scope.address.voterDistrict.voterDistrictName
              : "",
            legislativeDistrict: $scope.address
              .stateHouseOFRepresentativeDistrict
              ? $scope.address.stateHouseOFRepresentativeDistrict.NAMELSAD
              : "",
            cityCouncilDistrictName: $scope.address.cityCouncilDistrict
              ? $scope.address.cityCouncilDistrict.COUNTDIST
              : "",
          });
        }
        MyService.ConsoleLog("$scope.User.cur_state", $scope.User.cur_state);
        MyService.ConsoleLog("$scope.address.place ", $scope.address.place);
        var poliAddress = JSON.stringify({
          state: $scope.User.cur_state
            ? $scope.User.cur_state.state
            : $scope.User.state
            ? $scope.User.state.state
            : "",
          statePostalCode: $scope.User.cur_state
            ? $scope.User.cur_state.statePostalCode
            : $scope.statePostalCode,
          stateFips: $scope.address.state ? $scope.address.state.stateFips : "",
          place: $scope.address.place
            ? $scope.address.place.placeName
            : $scope.place_name,
          placeFips: $scope.address.place ? $scope.address.place.placeFips : "",
          county: $scope.address.county
            ? $scope.address.county.countyName
            : $scope.county_name,
          countyFips: $scope.address.county
            ? $scope.address.county.countyFips
            : "",
          country: $scope.countryname ? $scope.countryname : $scope.acountry,
          countySubdivision: $scope.address.countySubdivision
            ? $scope.address.countySubdivision.countySubdivision
            : $scope.acountySubdivision,
          countySubdivisionFips: $scope.address.countySubdivision
            ? $scope.address.countySubdivision.countySubdivisionFips
            : "",
          federalCongrassionalDist: $scope.address.federalCongrassionalDist
            ? $scope.address.federalCongrassionalDist.congressionalDistrictFips
            : "",
          stateSenateDistrict: $scope.address.stateSenateDistrict
            ? $scope.address.stateSenateDistrict.stateSenateDistrictFips
            : "",
          schoolDistrictFips: $scope.address.unifiedSchoolDistrictName
            ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrictFips
            : "",
          schoolDistrict: $scope.address.unifiedSchoolDistrictName
            ? $scope.address.unifiedSchoolDistrictName.unifiedSchoolDistrict
            : "",
          voterDistrictFips: $scope.address.voterDistrict
            ? $scope.address.voterDistrict.voterDistrict
            : "",
          voterDistrict: $scope.address.voterDistrict
            ? $scope.address.voterDistrict.voterDistrictName
            : "",
          legislativeDistrict: $scope.address.stateHouseOFRepresentativeDistrict
            ? $scope.address.stateHouseOFRepresentativeDistrict
                .stateHouseofRepresentativesDistrictName
            : "",
        });
        MyService.ConsoleLog("poliAddress", poliAddress);
        var runningPoliInfo = JSON.stringify({
          state: $scope.User.state
            ? $scope.User.state.state
            : $scope.User.cur_state
            ? $scope.User.cur_state.state
            : "",
          statePostalCode: $scope.User.state
            ? $scope.User.state.statePostalCode
            : $scope.statePostalCode
            ? $scope.statePostalCode
            : "",
          stateFips: $scope.User.state ? $scope.User.state.stateFips : "",
          place: $scope.runAddress.place
            ? $scope.runAddress.place.city
            : $scope.place_name
            ? $scope.place_name
            : "",
          placeFips: $scope.runAddress.place
            ? $scope.runAddress.place.placeFips
            : "",
          county: $scope.runAddress.county
            ? $scope.runAddress.county.countyName
            : $scope.county_name
            ? $scope.county_name
            : "",
          countyFips: $scope.runAddress.county
            ? $scope.runAddress.county.countyFips
            : "",
          country: $scope.countryname ? $scope.countryname : $scope.acountry,
          countySubdivision: $scope.runAddress.countySubdivision
            ? $scope.runAddress.countySubdivision.countySubdivision
            : $scope.acountySubdivision,
          countySubdivisionFips: $scope.runAddress.countySubdivision
            ? $scope.runAddress.countySubdivision.countySubdivisionFips
            : "",
          federalCongrassionalDist: $scope.runAddress.federalCongrassionalDist
            ? $scope.runAddress.federalCongrassionalDist
                .congressionalDistrictFips
            : "",
          stateSenateDistrict: $scope.runAddress.stateSenateDistrict
            ? $scope.runAddress.stateSenateDistrict.stateSenateDistrictFips
            : "",
          schoolDistrictFips: $scope.runAddress.unifiedSchoolDistrictName
            ? $scope.runAddress.unifiedSchoolDistrictName
                .unifiedSchoolDistrictFips
            : "",
          schoolDistrict: $scope.runAddress.unifiedSchoolDistrictName
            ? $scope.runAddress.unifiedSchoolDistrictName.unifiedSchoolDistrict
            : "",
          voterDistrictFips: $scope.runAddress.voterDistrict
            ? $scope.runAddress.voterDistrict.voterDistrict
            : "",
          voterDistrict: $scope.runAddress.voterDistrict
            ? $scope.runAddress.voterDistrict.voterDistrictName
            : "",
          legislativeDistrict: $scope.runAddress
            .stateHouseOFRepresentativeDistrict
            ? $scope.runAddress.stateHouseOFRepresentativeDistrict
                .stateHouseofRepresentativesDistrictName
            : "",
        });
        MyService.ConsoleLog(
          "<<<<<<<< runningPoliInfo >>>>>>>>",
          runningPoliInfo
        );
        if ($scope.User.runElectionDate && $scope.User.runElectionDate != "") {
          // $scope.User.runElectionDate ? $scope.User.runElectionDate : "
          MyService.ConsoleLog(
            "$scope.User.runElectionDate ",
            $scope.User.runElectionDate
          );
          // var eleDate = $scope.User.runElectionDate.toJSON();
          var month = (
            "0" +
            ($scope.User.runElectionDate.getMonth() + 1)
          ).slice(-2);
          var dat = ("0" + $scope.User.runElectionDate.getDate()).slice(-2);
          MyService.ConsoleLog("dat >>", dat);
          var finDate =
            $scope.User.runElectionDate.getFullYear() + "-" + month + "-" + dat;
          MyService.ConsoleLog(
            "$scope.User.runElectionDate ",
            $scope.User.runElectionDate.getDate()
          );
          MyService.ConsoleLog(
            "$scope.User.runElectionDate ",
            $scope.User.runElectionDate.getMonth()
          );
          MyService.ConsoleLog(
            "$scope.User.runElectionDate ",
            finDate.toString()
          );
        }
        var postobj = {
          username: $scope.User.UserName,
          password: $scope.User.Password,
          email: $scope.User.Email,
          zipcode: $scope.User.ZipCode
            ? $scope.User.ZipCode
            : $scope.User.ZipCodeOne,
          type: $scope.User.UserType,
          postCard: $scope.User.postCard,
          verifiedUser: $scope.verifiedUser,
          verificationCell: $scope.verificationCell,
          verificationCarrier: $scope.verificationCarrier,
          verificationMethod: $scope.verificationMethod,
          verificationurl: $rootScope.getVerificationUrl(),
          address: mainAddress,
          poliAddress: poliAddress,
          runningPoliInfo: runningPoliInfo,
          loc: mainLocation ? mainLocation : "",
          unsubscribeurl: $rootScope.getEmailUnsubscribeUrl(),
          campaignEmail: $scope.User.campaignEmail,
          campaignWebSite: $scope.User.campaignWebSite,
          campaignFburl: $scope.User.campaignFburl,
          campPhone_no: $scope.User.campPhone_no,
          run_electionDate: finDate ? finDate : "",
          mailingAddress: window.localStorage.getItem("mailingAddress"),
        };

        switch ($scope.User.UserType.toLowerCase()) {
          case "voter":
            postobj.name = $scope.User.name + " " + $scope.User.lastname;
            break;
          case "advocate":
            postobj.name = $scope.User.name + " " + $scope.User.lastname;
            postobj.position = $scope.User.position;
            postobj.extention_no = $scope.User.extention_no;
            postobj.verification_note = $scope.User.verification_note;
            postobj.typeOfOrg = $scope.User.orgType;
            postobj.empIndNo = $scope.User.empIndNo;
            postobj.officeName = $scope.User.organization_name;
            postobj.officeWebUrl = $scope.User.organization.web_url;
            postobj.officePhoneNumber = $scope.User.organization.phone_no;
            break;
          case "press":
            postobj.name = $scope.User.name + " " + $scope.User.lastname;

            postobj.extention_no = $scope.User.press_extension_no;
            postobj.verification_note = $scope.User.verification_note;
            if ($scope.User.organization) {
              MyService.ConsoleLog("one fnfnf", $scope.User.organization);
              postobj.office_id = $scope.User.organization._id
                ? $scope.User.organization._id
                : "";
            }
            postobj.typeOfOrg = $scope.User.orgType;
            postobj.empIndNo = $scope.User.empIndNo;
            postobj.officeName = $scope.User.outlet_name;
            postobj.officeWebUrl = $scope.User.outlet_web;
            postobj.officePhoneNumber = $scope.User.outlet_phone_no;

            break;
          case "politician":
            postobj.name_on_ballot = $scope.User.name_on_ballot;
            postobj.name = $scope.User.name;

            postobj.phone_no = $scope.User.phone_no ? $scope.User.phone_no : "";
            postobj.cur_office_phone = $scope.User.cur_office_phone
              ? $scope.User.cur_office_phone
              : "";
            postobj.publicEmail = $scope.User.cur_office_email
              ? $scope.User.cur_office_email
              : "";
            postobj.web_url = $scope.User.cur_office_website
              ? $scope.User.cur_office_website
              : "";
            postobj.mailing_address = $scope.User.mailing_address;

            if ($scope.User.Candidates && $scope.User.Candidates._id) {
              postobj.cur_office_id = $scope.User.Candidates._id;
            }
            if ($scope.User.NCandidates && $scope.User.NCandidates._id) {
              postobj.office_id = $scope.User.NCandidates._id
                ? $scope.User.NCandidates._id
                : "";
            }
            if ($scope.User.isInCurrentOffice == 1) {
              try {
                MyService.ConsoleLog(
                  " my office >>>>>>>>>> :",
                  $scope.User.cur_office_name
                );

                if ($scope.User.cur_office_name) {
                  postobj.cur_office_name = $scope.User.cur_office_name;
                  // console.log('current office >>>>>>>>>>>>>>>', $scope.User.cur_office_name);
                } else {
                  if ($filter("isBlankString")($scope.User.cur_office_name)) {
                    growl.error("Current Office name is not selected");
                    return null;
                  } else {
                    postobj.cur_office_name = $scope.User.cur_office_name;
                  }
                }

                if ($scope.User.cur_level) {
                  MyService.ConsoleLog(
                    " User.cur_level first >>>>>>> ",
                    $scope.User.cur_level
                  );

                  switch ($scope.User.cur_level) {
                    case "Federal":
                      postobj.cur_level = "federalCongressionalDistrict";
                      break;
                    case "Other":
                      postobj.cur_level = "All USA";
                      break;
                    case "State":
                      postobj.cur_level = "statePostalCode";
                      break;
                    case "County":
                      postobj.cur_level = "countyName";
                      break;
                    case "Local":
                      postobj.cur_level = "placeName";
                      break;
                    default:
                      break;
                  }
                }

                /*if($filter("isBlankString")($scope.User.cur_jurisdiction_name)){
                growl.error("Current jurisdiction name is not selected");
                return null;
              }*/

                // postobj.cur_jurisdiction_name = $scope.User.cur_jurisdiction_name;
                if ($scope.User.cur_state) {
                  var state = $scope.User.cur_state;
                  //MyService.ConsoleLog(state);
                  //MyService.ConsoleLog(state.state);
                  postobj.cur_state = state.state;
                  postobj.cur_state_postal_code = state.statePostalCode;
                }

                if ($scope.User.Candidates) {
                  if (
                    $scope.User.Candidates.FederalCongressionalDistrict &&
                    $scope.User.Candidates.FederalCongressionalDistrict != null
                  ) {
                    postobj.cur_federal_congrassional_dist =
                      $scope.User.Candidates.FederalCongressionalDistrict;
                  }

                  if (
                    $scope.User.Candidates.JudicialCircuitNumber &&
                    $scope.User.Candidates.JudicialCircuitNumber != null
                  ) {
                    postobj.cur_judicial_circuit_no =
                      $scope.User.Candidates.JudicialCircuitNumber;
                  }

                  if (
                    $scope.User.Candidates.CountyName &&
                    $scope.User.Candidates.CountyName != null
                  ) {
                    postobj.cur_county = $scope.User.Candidates.CountyName;
                  }

                  if (
                    $scope.User.Candidates.CountySubdivisionName &&
                    $scope.User.Candidates.CountySubdivisionName != null
                  ) {
                    postobj.cur_subcounty =
                      $scope.User.Candidates.CountySubdivisionName;
                  }

                  if (
                    $scope.User.Candidates.PlaceName &&
                    $scope.User.Candidates.PlaceName != null
                  ) {
                    postobj.cur_place = $scope.User.Candidates.PlaceName;
                  }

                  if (
                    $scope.User.Candidates.StateBoardEducationDistrict &&
                    $scope.User.Candidates.StateBoardEducationDistrict != null
                  ) {
                    postobj.cur_state_board_education_dist =
                      $scope.User.Candidates.StateBoardEducationDistrict;
                  }

                  if (
                    $scope.User.Candidates.UnifiedSchoolDistrictName &&
                    $scope.User.Candidates.UnifiedSchoolDistrictName != null
                  ) {
                    postobj.cur_unified_school_dist =
                      $scope.User.Candidates.UnifiedSchoolDistrictName;
                  }

                  if (
                    $scope.User.Candidates.ElementarySchoolDistrictName &&
                    $scope.User.Candidates.ElementarySchoolDistrictName != null
                  ) {
                    postobj.cur_elementary_school_dist =
                      $scope.User.Candidates.ElementarySchoolDistrictName;
                  }

                  if (
                    $scope.User.Candidates.SecondarySchoolDistrictName &&
                    $scope.User.Candidates.SecondarySchoolDistrictName != null
                  ) {
                    postobj.cur_secondary_school_dist =
                      $scope.User.Candidates.SecondarySchoolDistrictName;
                  }
                }
              } catch (e) {
                //MyService.ConsoleLog("Exceptoin: ", e);
                $scope.RegisterButton = false;
                growl.error("Valid Office Name is required.");
                return null;
              }
            }

            if ($scope.User.isRunningForEle == 1) {
              if ($scope.User.RunningForOtherOffice == 1) {
                postobj.cur_office_name = $scope.User.cur_office_name;
                if ($scope.User.cur_level) {
                  postobj.cur_level = $scope.User.cur_level;
                }
                postobj.run_candidateStatus = $scope.User.runStatus
                  ? $scope.User.runStatus
                  : "";
              } else {
                if ($scope.User.NCandidates && $scope.User.NCandidates.office) {
                  postobj.office_name = $scope.User.NCandidates.office;
                } else {
                  if ($filter("isBlankString")($scope.User.office_name)) {
                    growl.error("Office name is not selected");
                    return null;
                  } else {
                    postobj.office_name = $scope.User.office_name;
                  }
                }
                if ($scope.User.level) {
                  MyService.ConsoleLog(" got lvl of govt :", $scope.User.level);

                  switch ($scope.User.level) {
                    case "Federal":
                      postobj.level = "federalCongressionalDistrict";
                      break;
                    case "Other":
                      postobj.level = "All USA";
                      break;
                    case "State":
                      postobj.level = "statePostalCode";
                      break;
                    case "County":
                      postobj.level = "countyName";
                      break;
                    case "Local":
                      postobj.level = "placeName";
                      break;
                    default:
                      break;
                  }
                }
              }
              if ($scope.User.runStatus) {
                postobj.run_candidateStatus = $scope.User.runStatus
                  ? $scope.User.runStatus
                  : "";
              }
              try {
                if ($scope.User.state) {
                  var state = $scope.User.state;
                  postobj.state = state.state;
                  postobj.state_postal_code = state.statePostalCode;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.FederalCongressionalDistrict &&
                  $scope.User.NCandidates.FederalCongressionalDistrict != null
                ) {
                  postobj.federal_congrassional_dist =
                    $scope.User.NCandidates.FederalCongressionalDistrict;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.JudicialCircuitNumber &&
                  $scope.User.NCandidates.JudicialCircuitNumber != null
                ) {
                  postobj.judicial_circuit_no =
                    $scope.User.NCandidates.JudicialCircuitNumber;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.CountyName &&
                  $scope.User.NCandidates.CountyName != null
                ) {
                  postobj.county = $scope.User.NCandidates.CountyName;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.CountySubdivisionName &&
                  $scope.User.NCandidates.CountySubdivisionName != null
                ) {
                  postobj.subcounty =
                    $scope.User.NCandidates.CountySubdivisionName;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.PlaceName &&
                  $scope.User.NCandidates.PlaceName != null
                ) {
                  postobj.place = $scope.User.NCandidates.PlaceName;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.StateBoardEducationDistrict &&
                  $scope.User.NCandidates.StateBoardEducationDistrict != null
                ) {
                  postobj.state_board_education_dist =
                    $scope.User.NCandidates.StateBoardEducationDistrict;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.UnifiedSchoolDistrictName &&
                  $scope.User.NCandidates.UnifiedSchoolDistrictName != null
                ) {
                  postobj.unified_school_dist =
                    $scope.User.NCandidates.UnifiedSchoolDistrictName;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.ElementarySchoolDistrictName &&
                  $scope.User.NCandidates.ElementarySchoolDistrictName != null
                ) {
                  postobj.elementary_school_dist =
                    $scope.User.NCandidates.ElementarySchoolDistrictName;
                }

                if (
                  $scope.User.NCandidates &&
                  $scope.User.NCandidates.SecondarySchoolDistrictName &&
                  $scope.User.NCandidates.SecondarySchoolDistrictName != null
                ) {
                  postobj.secondary_school_dist =
                    $scope.User.NCandidates.SecondarySchoolDistrictName;
                }
              } catch (e) {
                $scope.RegisterButton = false;
                growl.error("Valid office details are required.");
                return null;
              }
            }
            if ($scope.User.web_url) postobj.web_url = $scope.User.web_url;
            if ($scope.User.facebook_url)
              postobj.fabebook_url = $scope.User.facebook_url;
            break;
        }

        MyService.ConsoleLog("postobj:::::", postobj);
        return postobj;
        // } //validation
        // else {
        //   return null;
        // }
      };
      $scope.disableRegister = function (opt) {
        if (opt == "P") {
          $scope.RegisterButton = true;
        } else {
          $scope.RegisterButton = false;
        }
      };
      $scope.verificationCode = "";
      $scope.getVerificationCode = function () {
        var cell = $scope.User.cellnumber;
        var carrier = $scope.User.carrier;
        // //MyService.ConsoleLog(cell);
        // //MyService.ConsoleLog(carrier);
        // //MyService.ConsoleLog(carrier.domain);

        if (!cell || cell == "") {
          alert("Please enter phone number");
          return;
          //return false;
        }

        if (!carrier || carrier == "") {
          alert("Please select your phone carrier");
          return;
          //return false;
        }

        if (cell && cell != "" && carrier && carrier != "") {
          // //MyService.ConsoleLog("here");
          if (carrier.domain) {
            $scope.verificationCell = cell;
            $scope.verificationCarrier = carrier.domain;
            MyService.sendVerificationCode({
              cellnumber: cell,
              carrier: carrier.domain,
            }).then(
              function (payload) {
                // //MyService.ConsoleLog(payload);
                if (payload.data.success) {
                  $scope.verificationCode = payload.data.data;
                  alert("Verification code sent on " + cell);
                  // //MyService.ConsoleLog("verification code : ");
                  // //MyService.ConsoleLog($scope.verificationCode);
                } else {
                  $scope.verificationCode = "";
                  if (payload.data.error) {
                    alert(payload.data.error);
                  } else {
                    alert("Something went wrong please try agian");
                  }
                }
              },
              function (errPayload) {
                $scope.verificationCode = "";
                alert("Something went wrong please try agian");
              }
            );
          }
        }
      };
      $scope.verifiedUser = "false";
      $scope.verificationMethod = "Email";
      $scope.verificationCell = "";
      $scope.verificationCarrier = "";
      $scope.matchVerficationCode = function (val) {
        MyService.ConsoleLog("value : ", val);
        ////MyService.ConsoleLog($scope.User.verificationCode);
        ////MyService.ConsoleLog(val.length);
        $scope.RegisterButton = true;
        $scope.verifiedUser = "false";
        if (val.length == 6 && $scope.verificationCode != "") {
          if ($scope.verificationCode == val) {
            $scope.RegisterButton = false;
            $scope.verifiedUser = "true";
            $scope.verificationMethod = "Cell Phone Number";
            if ($scope.User.confirmOpt == "Phone") {
              $scope.saveUser();
            }
          } else {
            alert(
              "You have entered the wrong code.  Please try again or have us send you another code."
            );
          }
        } else {
          alert(
            "You have entered the wrong code.  Please try again or have us send you another code."
          );
        }
      };
      $scope.saveUser = function () {
        var postobj = $scope.generatePostUserObj();
        MyService.ConsoleLog("postobj<><><><><>", postobj);
        if (postobj) {
          MyService.signUp(postobj).then(
            function (response) {
              //MyService.ConsoleLog("response: ", response);
              $scope.RegisterButton = false;
              if (response.data.success == true) {
                var message = "";
                switch ($scope.User.UserType.toLowerCase()) {
                  case "politician":
                    message =
                      "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Politician.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                    break;
                  case "voter":
                    message =
                      "Until you confirm your email, you can read and explore the site, but you won't be able to write responses.";
                    break;
                  case "press":
                    message =
                      "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Press.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                    break;
                  case "advocate":
                    message =
                      "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Organization.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                    break;
                }
                // growl.info(message);
                $cookies.put("showLongPopUp", message);
                var tempParam = {
                  username: $scope.User.UserName,
                  password: $scope.User.Password,
                };
                ////MyService.ConsoleLog("tempParam>>",tempParam);
                // $scope.AuthenticateUser1(tempParam);
                $timeout(function () {
                  $scope.AuthenticateUser1(tempParam);
                }, 2000);
              } else if (
                response.data.error &&
                typeof response.data.error == "string"
              ) {
                growl.error("Error: " + response.data.error);
              } else {
                growl.error("Something went wrong.");
              }
            },
            function (response) {
              //MyService.ConsoleLog("Signup response: ", response);
              $scope.RegisterButton = false;
              var validataionMsg = "";
              if (
                response.data &&
                response.data.error &&
                response.data.error.errors
              ) {
                var errors = response.data.error.errors;
                for (var k in errors) {
                  if (errors.hasOwnProperty(k)) {
                    ////MyService.ConsoleLog(k);
                    var obj = errors[k];
                    if (obj.message !== undefined)
                      validataionMsg +=
                        obj.message.replace("Path", "") + "<br/>";
                  }
                }
                growl.error(validataionMsg);
              }
            }
          );
        }
      };
      $scope.showUserConfirm = function () {
        var message =
          "Some parts of the site will not work until you check your email and confirm – or you can instantly confirm with your cell phone number.";
        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            message +
            '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">Confirm</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(1)">Skip</button>\
                        </div>',
          plain: true,
        });
        modal.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            if (data == 0) {
              $scope.verifiedUser = true;
              $scope.User.confirmOpt = "Phone";
              //$scope.saveUser();
            } else {
              $scope.verifiedUser = false;
              $scope.saveUser();
            }
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          }
        );

        return;
      };

      $scope.CreateUser = function () {
        //MyService.ConsoleLog("CreateUser");
        //MyService.ConsoleLog($rootScope.tempUserAddress);
        if (!$cookies.get("sessionId")) {
          $scope.address = $rootScope.tempUserAddress;
        }
        $scope.User.ZipCode = $rootScope.tempUserZip;
        $scope.newZipCode = $rootScope.tempUserZip;
        MyService.ConsoleLog(" CreateUser : ", $scope.newZipCode);
        //MyService.ConsoleLog(" address : ", $scope.address);

        // MyService.ConsoleLog("postobj: ", postobj);

        if ($filter("isBlankString")($scope.User.UserName)) {
          growl.error("Please enter username");
          return null;
        }
        if ($filter("isBlankString")($scope.User.Password)) {
          growl.error("Please enter password");
          return null;
        }

        if ($scope.User.Password != $scope.User.ConfirmPassword) {
          MyService.ConsoleLog(
            "check pass",
            $scope.User.Password + "    " + $scope.User.ConfirmPassword
          );
          growl.error("Password and confirm password does not match");
          return null;
        }

        if ($filter("isBlankString")($scope.User.Email)) {
          growl.error("Please enter email");
          return null;
        }
        var postobj = $scope.generatePostUserObj();
        console.log("CreateUser >>> ", postobj);
        // return;
        if (postobj) {
          // MyService.ConsoleLog("inside if post obj");
          $scope.saveUser();
        }
      };

      $scope.saveAns = function () {
        MyService.ConsoleLog("saveFiveMinAnswers : ", $rootScope.guestMatch);
        window.localStorage.removeItem("isHide");
        window.localStorage.setItem("isHide", true);

        if (!$rootScope.guestMatch) {
          $rootScope.guestMatch = $rootScope.guestUserData;
        }

        var len = $rootScope.guestMatch.length;
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            MyService.ConsoleLog($rootScope.guestMatch[i]);
            MyService.postAnswer($rootScope.guestMatch[i]).then(
              function (response) {
                MyService.ConsoleLog(response);
                if (response.data.success) {
                  MyService.ConsoleLog("Answer updated successfully");
                } else {
                  MyService.ConsoleLog("Error: " + response.data.error);
                }
              },
              function (err) {
                MyService.ConsoleLog("Error: ");
              }
            );

            MyService.deleteGuest($rootScope.guestMatch[i]).then(
              function (success) {
                MyService.ConsoleLog("SUccess >>>", success);
              },
              function (err) {
                MyService.ConsoleLog("Error >>", err);
              }
            );
          }
        }
      };
      $scope.saveFiveMinAnswers = function () {
        if ($cookies.get("sessionId")) {
          $scope.saveAns();
        } else if (window.localStorage.getItem("sessionId")) {
          $scope.saveAns();
        } else {
          MyService.ConsoleLog("saveFiveMinAnswers : ");
          MyService.ConsoleLog($rootScope.fiveMatch);
          var len = $rootScope.fiveMatch.length;
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              MyService.ConsoleLog($rootScope.fiveMatch[i]);
              MyService.postAnswer($rootScope.fiveMatch[i]).then(
                function (response) {
                  MyService.ConsoleLog(response);
                  if (response.data.success) {
                    MyService.ConsoleLog("Answer updated successfully");
                  } else {
                    MyService.ConsoleLog("Error: " + response.data.error);
                  }
                },
                function (err) {
                  MyService.ConsoleLog("Error: ");
                }
              );
            }
          }
        }
      };
      $scope.initAuthorizedUser1 = function (userdata) {
        // body...
        //MyService.ConsoleLog("userdata>>", userdata);
        tokenService.saveToken(userdata.token);
        tokenService.saveUserName(userdata.name);
        tokenService.saveUserId(userdata.id);
        tokenService.saveUserRole(userdata);
        var udata = {
          id: userdata.id,
          lastLogin: userdata.lastLogin,
          uname: userdata.username,
          email: userdata.email,
          followlist: userdata.followlist,
          isemailverified: userdata.isemailverified,
          subject_of_deep_interest: userdata.subject_of_deep_interest,
          subject_of_interest: userdata.subject_of_interest,
          location_of_interest: userdata.location_of_interest,
          address: userdata.address,
          selectedColumns: userdata.selectedColumns,
          facebookid: userdata.facebookid,
          volunteers: userdata.volunteers,
          name: userdata.name,
        };

        ////MyService.ConsoleLog("udata : ", udata);
        tokenService.saveUserData(JSON.stringify(udata));
        // $rootScope.tempUserAddress = userdata.address;

        $http.defaults.headers.common["x-access-token"] =
          tokenService.getToken();
        // $http.defaults.headers.common['Authorization'] = tokenService.getToken();
        $rootScope.Auth = true;
        $rootScope.User = tokenService.getUserName();
        $rootScope.UserId = tokenService.getUserId();

        //$location.path('howToVideo');
        if (!$cookies.get("isShowPopUp")) {
          // alert('if');
          // MyService.ConsoleLog("$cookies.get('sessionId')", $cookies.get('sessionId'));
          if (!$cookies.get("sessionId")) {
            MyService.ConsoleLog("IFFF");
            $scope.saveFiveMinAnswers();
            $location.path("/fiveCompare");
            $rootScope.getCategories();
          } else {
            MyService.ConsoleLog("ELSE");
            $scope.saveFiveMinAnswers();
            MyService.ConsoleLog(
              "Quiz Data",
              window.localStorage.getItem("quizLinkData")
            );
            if (window.localStorage.getItem("quizLinkData")) {
              var quData = JSON.parse(
                window.localStorage.getItem("quizLinkData")
              );
              MyService.saveForLaterQuiz({
                saveLink: quData.saveLink,
                userId: tokenService.getUserId(),
                quizName: quData.quizName,
              }).then(
                function (APIResponse) {
                  // MyService.ConsoleLog("APIResponse", APIResponse);
                  $location.path("/snapshot");
                },
                function (err) {
                  MyService.ConsoleLog("Error", err);
                }
              );
            } else {
              $location.path("/findMatch/true");
            }
          }
        } else {
          // alert('else');
          // var message = 'You need to answer at least one question to see a match comparison';
          // var modal = ngDialog.openConfirm({
          //   template: '<p>' +
          //     message +
          //     '</p>\
          //   <div class="ngdialog-buttons">\
          //       <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Back to Questions</button>\
          //   </div>',
          //   plain: true,
          //   closeByDocument: false,
          // });
          // modal.then(
          //   function fullfilled(data) {
          //     // MyService.ConsoleLog("fullfilled: ", data);
          //     $scope.getOrgQuiz();
          //   },
          //   function rejected(data) {
          //     // MyService.ConsoleLog("rejected: ", data);
          //     // $scope.getMatchQUestions();
          //   }
          // );
        }
      };

      $rootScope.statusAuthenticate = 0;
      $scope.AuthenticateUser1 = function (postObj) {
        $rootScope.statusAuthenticate = 1;
        $http.post(baseApiUrl + "/user/authenticate", postObj).then(
          function (response) {
            if (response.data.success == true) {
              //MyService.ConsoleLog("Login Data");
              //MyService.ConsoleLog(response.data);
              $scope.initAuthorizedUser1(response.data);
              /*
            tokenService.saveToken(response.data.token);
            tokenService.saveUserName(response.data.name);
            tokenService.saveUserId(response.data.id);
            tokenService.saveUserRole(response.data);
            var userData = {
                id: response.data.id,
                lastLogin: response.data.lastLogin,
                uname: response.data.username,
                name: response.data.name
            };
            ////MyService.ConsoleLog("username: ", userData);
            tokenService.saveUserData(JSON.stringify(userData));
            $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
            $rootScope.Auth = true;
            $rootScope.User = tokenService.getUserName();
            $rootScope.UserId = tokenService.getUserId();
            $location.path('debateHome');
            $rootScope.getCategories();
            */
              $rootScope.statusAuthenticate = 2;
            } else {
              $rootScope.statusAuthenticate = 3;
              growl.error("Invalid Username or Password. Please try again.");
            }
          },
          function (response) {
            $rootScope.statusAuthenticate = 3;
            var validataionMsg = "";
            //MyService.ConsoleLog("response: ", response);
            if (response.data && response.data.error) {
              var errors = response.data.error.errors;
              for (var k in errors) {
                if (errors.hasOwnProperty(k)) {
                  ////MyService.ConsoleLog(k)
                  var obj = errors[k];
                  if (obj.message !== undefined)
                    validataionMsg += obj.message.replace("Path", "") + "<br/>";
                }
              }
              growl.error(validataionMsg);
            }
          }
        );
      };

      $scope.ShowFB = function () {
        //if()
        //MyService.ConsoleLog("inside :", $rootScope.showFacebookLoging);
        $rootScope.showFacebookLoging = !$rootScope.showFacebookLoging;

        $location.hash("bottom");
        $anchorScroll();
        //$location.hash('signupform');
        //$anchorScroll();
        //if($scope.showFacebookLoging) {
        // $timeout(function() {

        //     //MyService.ConsoleLog("inside after scroll :", $rootScope.showFacebookLoging);
        // }, 1500);

        //}
      };

      $rootScope.LoginWithFacebook = function () {
        // if ($.fn.validateForceFully($("#signupform")) == true) {
        ////MyService.ConsoleLog("all good: ", $rootScope.NewUserData);
        $scope.address = $rootScope.tempUserAddress;
        $scope.User.ZipCode = $rootScope.tempUserZip;
        //MyService.ConsoleLog($scope.address);
        //MyService.ConsoleLog($scope.User.ZipCode);
        FB.login(
          function (response) {
            statusChangeCallback(response);
          },
          {
            scope: "public_profile,email,publish_actions",
          }
        );
        // }
      };

      function statusChangeCallback(response) {
        ////MyService.ConsoleLog(response);
        if (response.status === "connected") {
          getUserInfo();
        } else if (response.status === "not_authorized") {
          growl.info("Please log " + "into this app.");
        } else {
          growl.error("Please log " + "into Facebook.");
        }
      }

      function getUserInfo() {
        FB.api(
          "/me",
          {
            fields: "first_name,last_name,email",
          },
          function (response) {
            ////MyService.ConsoleLog("response: ", response);
            if (response != undefined && response != null && response != "") {
              $scope.CreateFBUser(response);
            }
          }
        );
      }

      $scope.CreateFBUser = function (fbResponse) {
        ////MyService.ConsoleLog("CreateFBUser: ", fbResponse);
        ////MyService.ConsoleLog("organization_name: ", $rootScope.NewUserData.organization_name);
        $rootScope.Errors = [];

        $scope.FBSignIn(fbResponse, function () {
          if (fbResponse.email && fbResponse.email != "") {
            var postobj = $scope.generatePostUserObj();

            if (postobj) {
              //reset few fields for facebook login
              postobj.name = fbResponse.first_name + " " + fbResponse.last_name;
              postobj.username = fbResponse.email.split("@")[0];
              (postobj.password = ""), (postobj.email = fbResponse.email);
              postobj.isfacebooksigin = true;
              postobj.facebookid = fbResponse.id;
              postobj.verificationMethod = "Facebook";

              //MyService.ConsoleLog("postobj: ", postobj);
              MyService.signUp(postobj).then(
                function (response) {
                  ////MyService.ConsoleLog("response: ", response);
                  $scope.RegisterButton = false;
                  if (response.data.success == true) {
                    var message = "";
                    switch ($rootScope.NewUserData.usertype.toLowerCase()) {
                      case "politician":
                        message =
                          "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Politician.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                        break;
                      case "voter":
                        message =
                          "Until you confirm your email, you can read and explore the site, but you won't be able to write responses.";
                        break;
                      case "press":
                        message =
                          "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Press.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                        break;
                      case "advocate":
                        message =
                          "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Organization.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                        break;
                    }
                    growl.success(message);
                    $scope.FBSignIn(fbResponse);
                    // $location.path('debate');
                  } else if (
                    response.data.error &&
                    typeof response.data.error == "string"
                  ) {
                    growl.error("Error: " + response.data.error);
                  } else {
                    growl.error("Something went wrong.");
                  }
                },
                function (response) {
                  var validataionMsg = "";
                  ////MyService.ConsoleLog("response: ", response)
                  var errors = response.data.error.errors;
                  for (var k in errors) {
                    if (errors.hasOwnProperty(k)) {
                      ////MyService.ConsoleLog(k)
                      var obj = errors[k];
                      if (obj.message !== undefined)
                        validataionMsg +=
                          obj.message.replace("Path", "") + "<br/>";
                    }
                  }
                  growl.error(validataionMsg);
                }
              );
            } //if postobj
          } else {
            growl.error(
              "You haven't added email in your facebook account. Please add email in your facebook account then you can register using facebook."
            );
          }
        });
      };

      $scope.FBSignIn = function (fbresponse, callback) {
        //MyService.ConsoleLog(fbresponse);
        $http
          .post(baseApiUrl + "/user/authenticateEmail", {
            email: fbresponse.email,
          })
          .then(
            function (response) {
              //MyService.ConsoleLog("response<><><>",response);
              if (response.data.success == true) {
                if (!response.data.facebookid) {
                  $http
                    .post(baseApiUrl + "/user/updateFacebookId", {
                      facebookid: fbresponse.id,
                      userId: response.data.id,
                    })
                    .then(function (userresponse) {
                      $scope.initAuthorizedUser1(response.data);
                    });
                } else {
                  $scope.initAuthorizedUser1(response.data);
                }
              } else {
                //growl.error('Invalid Username or Password. Please try again.');
                if (callback !== undefined) callback();
              }
            },
            function (response) {
              var validataionMsg = "";
              var errors = response.data.error.errors;
              for (var k in errors) {
                if (errors.hasOwnProperty(k)) {
                  ////MyService.ConsoleLog(k)
                  var obj = errors[k];
                  if (obj.message !== undefined)
                    validataionMsg += obj.message.replace("Path", "") + "<br/>";
                }
              }
              growl.error(validataionMsg);
            }
          );
      };

      function getOrganisationData(term) {
        var q = term.toLowerCase().trim();
        MyService.ConsoleLog("term: ", $scope.User.UserType.toLowerCase());

        var deferred = $q.defer();

        var postdata = {
          max_records: 5,
          keyword: term,
          type: $scope.User.UserType.toLowerCase(),
        };

        var request = apiCall.apiCall("GET", "/office/list", postdata);

        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog("succss: ", response);
            if (response.data.success) {
              var returnArr = [];
              angular.forEach(response.data.data, function (val, key) {
                MyService.ConsoleLog(val.title);

                returnArr.push({
                  label: $sce.trustAsHtml(val.title),
                  value: JSON.stringify(val),
                });
              });
              MyService.ConsoleLog(returnArr);
              deferred.resolve(returnArr);
              // growl.success("Tagged successfully");
            } else deferred.resolve([]);
            /*else{
          if(typeof response.data.error == "string")
              growl.error(response.data.error);
          else
              growl.error("Something went wrong");
        }*/
          },
          function errorCallback(response) {
            // growl.error("Something went wrong");
            deferred.resolve([]);
          }
        );

        return deferred.promise;
      }

      // $scope.checkExists = function() {
      //   if ($scope.User.organization) {
      //     $scope.User.outlet_name = $scope.User.organization.title;
      //     $scope.User.organization_name = $scope.User.organization.title;
      //   }

      // }

      $scope.org_autocomplete_options = {
        suggest: getOrganisationData,
        on_select: function (selected) {
          // MyService.ConsoleLog("selected: ", selected);
          // $scope.User.organization_name = selected.label;
          var data = JSON.parse(selected.value);
          $scope.User.organization = data;
          $scope.User.outlet_name = data.title;
          $scope.User.organization_name = data.title;
          // MyService.ConsoleLog($scope.User.organization);
        },
        on_detach: function (current_value) {
          //MyService.ConsoleLog("on_detach: ", current_value);
          /*try{
            if($scope.User.organization.label != current_value)
            $scope.User.organization = null;
        }
        catch(e){
            $scope.User.organization = null;
        }*/
          $scope.User.organization_name = current_value;
        },
      };

      $scope.getAllStates = function () {
        if ($scope.counties) $scope.counties.length = 0;
        if ($scope.places) $scope.places.length = 0;

        var request = apiCall.apiCall("GET", "/user/getAllStates", {
          country: "",
        });
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got States: ", response);
            if (response.data.success) {
              $scope.states = response.data.data;
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.getAllStates();

      var getCountiesCanceler = $q.defer();
      // $scope.getCounties = function(lbl) {
      //   //MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
      //   MyService.ConsoleLog($scope.oldaddress);
      //   var state = {};
      //   if (lbl == "cur")
      //     state = $scope.User.cur_state;
      //   else if (lbl == "new")
      //     state.statePostalCode = $scope.oldaddress.state;
      //   else
      //     state = JSON.parse($scope.User.sel_state);
      //   //MyService.ConsoleLog("state: ", state);

      //   getCountiesCanceler.resolve();
      //   if ($scope.places) $scope.counties.length = 0;

      //   var request = apiCall.apiCall('GET', '/user/getAllCounties', {
      //     "statePostalCode": state.statePostalCode,
      //     "timeout": getCountiesCanceler.promise
      //   });
      //   $http(
      //     request
      //   ).then(function successCallback(response) {
      //     if (response.data.success) {
      //       // MyService.ConsoleLog(response.data.data);
      //       $scope.counties = response.data.data;
      //       if (lbl == "new") {

      //         var county = $scope.checkCounty($scope.counties, $scope.oldaddress.county);
      //         MyService.ConsoleLog("county<><", county);
      //         $scope.address.county = county[0];
      //       }
      //     } else {
      //       growl.error("Something went wrong.");
      //     }
      //   }, function errorCallBack(response) {
      //     MyService.ConsoleLog("Error: ", response);
      //   });

      // }

      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function (lbl) {
        MyService.ConsoleLog($scope.oldaddress);
        var state = {},
          county = {};

        if (lbl == "cur") {
          state = $scope.User.cur_state;
          county = JSON.parse($scope.User.cur_county);
        } else if (lbl == "new") {
          state.statePostalCode = $scope.oldaddress.state;
          county.county = $scope.oldaddress.county;
        } else {
          state = JSON.parse($scope.User.sel_state);
          county = JSON.parse($scope.User.sel_county);
        }

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();

        var request = apiCall.apiCall("GET", "/user/getAllSubCountiesLocal", {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              if (response.data.data) {
                MyService.ConsoleLog(response.data.data);
                $scope.places = response.data.data;

                if (lbl == "new") {
                  var subcounty = $scope.checksubCounty(
                    $scope.places,
                    $scope.oldaddress.countySubdivision
                  );

                  var places = $scope.checkPlaces(
                    $scope.places,
                    $scope.oldaddress.place
                  );
                  MyService.ConsoleLog("subcounty<><><", subcounty[0]);
                  $scope.address.countySubdivision = subcounty[0];
                  $scope.address.place = places[0];
                }
              }
            } else {
              growl("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl("Something went wrong.");
          }
        );
      };

      $scope.UserLogin = function () {
        // alert($scope.User.isRunningForEle);
        $scope.flag = false;
        if ($filter("isBlankString")($scope.User.isInCurrentOffice)) {
          if ($scope.flag == false) {
            growl.error("Please answer: Are you currently in Office?");
            $scope.flag = true;
          }
        }
        if ($filter("isBlankString")($scope.User.isRunningForEle)) {
          if ($scope.flag == false) {
            growl.error(
              "Please answer: Are you running for office this coming election?"
            );
            $scope.flag = true;
          }
        }

        // if ($.fn.validateForceFully($("#signupform")) == true) {
        if ($scope.flag == false) {
          // alert("Can submit data");
          MyService.ConsoleLog($scope.User);
          var data = JSON.stringify($scope.User);
          MyService.registerLogin({
            data: data,
          }).then(
            function (payload) {
              MyService.ConsoleLog(payload.data.data);
              if (payload.data.success == true) {
                MyService.ConsoleLog(payload.data.data);
                $scope.initAuthorizedUser1(payload.data.data);
                $rootScope.statusAuthenticate = 2;
              } else {
                $rootScope.statusAuthenticate = 3;
                growl.error("Invalid Username or Password. Please try again.");
              }
            },
            function (errorPayload) {}
          );
        }
        // }
      };

      $scope.resetPassword = function () {
        // MyService.ConsoleLog($scope.User.Email);
        // MyService.ConsoleLog($scope.User.cur_office_email);
        var emails = [];
        if ($scope.User.Email && $scope.User.Email != "") {
          if (emails.indexOf($scope.User.Email) == -1) {
            emails.push($scope.User.Email);
          }
        }

        if (
          $scope.User.cur_office_email &&
          $scope.User.cur_office_email != ""
        ) {
          if (emails.indexOf($scope.User.cur_office_email) == -1) {
            emails.push($scope.User.cur_office_email);
          }
        }

        if (emails.length > 0) {
          // MyService.ConsoleLog("emails<><><>", emails);
          MyService.forgotPoliticianPassword({
            email: JSON.stringify(emails),
            reset_url: $rootScope.getPasswordResetUrl(),
          }).then(
            function (payload) {
              // MyService.ConsoleLog("Payload<><><><>", payload);
              if (payload.data.success) {
                alert(payload.data.data);
              } else {
                alert("Something went wrong please try again later");
              }
            },
            function (errorPayload) {
              MyService.ConsoleLog("Payload<><><><>", errorPayload);
            }
          );
        }
      };

      $scope.emptyOrNull = function (item) {
        return !(
          item._id.place === null ||
          item._id.place === "" ||
          item._id.place === " "
        );
      };

      $scope.$watch("User.isRunningForEle", function (newVal, oldVal) {
        //MyService.ConsoleLog("newVal: ", newVal, "oldVal: ", oldVal);
        if (newVal == 1) {
          if ($scope.User.cur_state) {
            //MyService.ConsoleLog("cur_state set");
            $scope.User.sel_state = $scope.User.cur_state;
          } else {
            //MyService.ConsoleLog("cur_state not set");
          }

          if ($scope.User.cur_office_name) {
            $scope.User.office_name = $scope.User.cur_office_name;
          }
          if ($scope.User.cur_jurisdiction_name) {
            $scope.User.jurisdiction_name = $scope.User.cur_jurisdiction_name;
          }

          if ($scope.User.cur_county)
            $scope.User.sel_county = $scope.User.cur_county;
          if ($scope.User.cur_place)
            $scope.User.sel_place = $scope.User.cur_place;

          //MyService.ConsoleLog("sel_state: ", $scope.User.sel_state);
        }
      });

      // if ($scope.UserType == "voter" || $scope.UserType == "politician") {
      //   $("#fiveminform1").validationEngine();
      // }
    },
  ]);
