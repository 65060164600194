'use strict';
angular
  .module('myApp.showPopUp', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/showPopUp', {
        templateUrl: 'app/showPopUp/showPopUp.html',
        controller: 'showPopUpCtrl',
      });
    },
  ])
  .controller('showPopUpCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {

      /**
       * Show pop up on the page load
       */

      var message = "Are you already a member of VoteWise with this email address?";
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
                <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">No</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                    \
                </div>',
        plain: true
      });

      modal.then(function fullfilled(data) {
        ////MyService.ConsoleLog("fullfilled: ", data);
        if (data == 1) {
          $location.path('/signin');
        } else {
          $location.path('/signup/voter');
        }
      }, function rejected(data) {
        ////MyService.ConsoleLog("rejected: ", data);
      });
    }

  ]);
