'use strict';
angular.module('myApp.essayDetails', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/essayDetails/:categoryId/:essayId/:location?', {
      templateUrl: 'app/essayDetails/essayDetails.html',
      controller: 'essayDetailsCtrl'
    });
  }])
  .controller('essayDetailsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', 'baseApiUrl', '$q', '$timeout', 'growl', 'tokenService', '$anchorScroll', '$sce', 'MyService', 'ngDialog', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, baseApiUrl, $q, $timeout, growl, tokenService, $anchorScroll, $sce, MyService, ngDialog) {
    //MyService.ConsoleLog("essayDetailsCtrl");
    $rootScope.welcome = false;
    $scope.categoryId = $routeParams.categoryId;
    $scope.essayId = $routeParams.essayId;
    $scope.userType = tokenService.getUserRole();


    $scope.showCon = false;
    $scope.showBoth = false;
    $scope.showEm = false;


    /**
     * Redirect
     */
    $scope.goto = function () {
      $location.path('/verifyBy');
    };

    /**
     * Close it
     */
    $scope.closeIt = function () {
      $scope.PopDialog.close();
    };
    $scope.getmyData = function () {
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: tokenService.getUserId(),
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          // MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);

          $scope.userType = $scope.userProfile.userType;
          $scope.verify = $scope.userProfile.verifiedUser;
          // MyService.ConsoleLog("welcome", $scope.verify);
          // console.log("$scope.userDetails", $scope.userDetails.userData.address);
          if (!$scope.userProfile.verifiedUser) {
            $scope.showCon = true;
          }

          if (!$scope.userProfile.isEmailVerified || !$scope.userProfile.verifiedUser) {
            // $scope.getPopUp();
            if (!$scope.userProfile.isEmailVerified && !$scope.userProfile.verifiedUser) {
              $scope.showCon = true;
              $scope.showBoth = true;
            } else if (!$scope.userProfile.isEmailVerified) {
              $scope.showEm = true;
            }

            if ($scope.userProfile.userType == 'politician') {
              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            } else if ($scope.userProfile.userType == 'advocate' && $scope.userProfile.isEmailVerified != true) {
              // alert('cal');
              MyService.ConsoleLog("$scope.userProfile", $scope.userProfile.isEmailVerified);

              $scope.PopDialog = ngDialog.open({
                template: 'popUp',
                closeByNavigation: true,
                scope: $scope,
                width: '50%',
                closeByDocument: false
              });
            }
          }


        } else {
          MyService.ConsoleLog("ELSE vishal");
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    }

    if (tokenService.getUserId()) {
      $scope.getmyData();
    }


    if (tokenService.getUserData()) {
      $scope.uData = JSON.parse(tokenService.getUserData());

    }



    $scope.froumthreads = [];
    $scope.essay = {};
    $scope.Sticky = {
      ShowOrder: [],
      txtOrder: []
    };
    $scope.vote = {
      recommend: 0,
      factual: 0
    };
    if ($scope.uData) {
      $scope.GalleryFolder = baseApiUrl + "/resources/" + $scope.uData.uname;
    }

    $scope.displayComments = true;
    $scope.Reply = {};

    $scope.checkMemId = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.userData._id == $rootScope.UserId)
            return rdata
        }
      );
    }


    $scope.CheckUrl = function (url, option) {
      if (url) {
        if (url.indexOf("youtube.com") >= 0) {
          var url = url.replace("watch?v=", "embed/");
          var nurl = url.split("&");
          var durl = nurl[0];
          if (option == "V")
            return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + durl + '" frameborder="0" allowfullscreen></iframe><br/>');
          else
            return $sce.trustAsHtml('<br/><iframe src="' + durl + '" frameborder="0" allowfullscreen></iframe><br/>');
        }

        if (url.indexOf("facebook.com") >= 0) {
          if (option == "V")
            return $sce.trustAsHtml('<br/><iframe src="' + url + '" width="560" height="315" frameborder="0"></iframe><br/>');
          else
            return $sce.trustAsHtml('<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
        }

        if (url.indexOf("vimeo.com") >= 0) {
          if (url.indexOf("https://vimeo.com/") >= 0) {
            var id = url.replace("https://vimeo.com/", "");
            var newurl = "https://player.vimeo.com/video/" + id;
          } else {
            var newurl = url;
          }
          if (option == "V")
            return $sce.trustAsHtml('<br/><iframe src="' + newurl + '" width="500" height="281" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>');
          else
            return $sce.trustAsHtml('<br/><iframe src="' + newurl + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br/>');
        }
        if (option == "V")
          return $sce.trustAsHtml('<br/><iframe width="560" height="315" src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
        else
          return $sce.trustAsHtml('<br/><iframe src="' + url + '" frameborder="0" allowfullscreen></iframe><br/>');
      }

    }

    $scope.chkIfVoted = function (rdata, val) {
      return rdata.filter(
        function (rdata) {
          if (rdata.user.id == val)
            return rdata

        }
      );
    }
    $scope.loginUser = tokenService.getUserId();
    $scope.getEssayDetails = function () {
      //MyService.ConsoleLog("Get One called");
      MyService.getEssay($routeParams.essayId).then(function (payload) {
        $scope.essay = payload;
        //MyService.ConsoleLog("payload : ", payload);
        var chkrecommend = $scope.chkIfVoted(payload.recommended, $scope.loginUser);
        if (chkrecommend && chkrecommend.length > 0) {
          $scope.vote.recommend = chkrecommend[0].vote;
        }
        var chkfactual = $scope.chkIfVoted(payload.factual, $scope.loginUser);
        if (chkfactual && chkfactual.length > 0) {
          $scope.vote.factual = chkfactual[0].vote;
        }

      }, function (errorPayload) {
        //MyService.ConsoleLog(errorPayload);
        alert('Something went wrong ');
      });

    }



    function getCategory() {
      MyService.getCategoryList($routeParams.categoryId).then(function (payload) {
        $scope.category = payload;

      }, function (errorPayload) {
        alert('Something went wrong ');
      });

    }

    $scope.gotoThread = function (data) {
      //MyService.ConsoleLog(data);
      var id = '';
      if (data.parentId) {
        id = data.parentId;
      } else {
        id = data.id;
      }
      $location('forumthread/' + id);
    }

    $scope.updateCommentApi = function (data, options, idex) {

      MyService.updateEssayComments(data).then(function (payload) {
        if (payload.nModified == 0) {
          alert("You already provided your inputs on this post");
        } else {
          switch (options) {
            case 'like':
              $scope.essayComments[idex].totalLikes = $scope.essayComments[idex].totalLikes + 1;
              break;
            case 'dislike':
              $scope.essayComments[idex].totalDislike = $scope.essayComments[idex].totalDislike + 1;
              break;
            default:
              $scope.essayComments[idex].totalSpam = $scope.essayComments[idex].totalSpam + 1;
              break;
          }
          $scope.getComments();
        }
      }, function (response) {
        if (response.error) {
          alert(response.error);
          //MyService.ConsoleLog(response);
          if ((response.status) && (response.status == "405")) {
            var msg = "Do you want to send request to Admin to reactivate your account?.";
            MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
              MyService.sendActivatationMailtoAdmin().then(function (payload) {
                alert("Request sent successfully to admin");
              }, function (errorPayload) {
                alert("Something went wrong");
              })
            }, function (errorPayload) {
              //MyService.ConsoleLog(errorPayload);
            });
          }
        }

      });

    }

    // $scope.getItems = function () {
    //   return function (u) {
    //     MyService.ConsoleLog("u", u);
    //     if (u && u.spam && u.spam.length != 3) {
    //       return u;
    //     }
    //   };
    // };

    $scope.showSpam = [];
    $scope.DisplaySpam = function (id) {
      //alert(id);
      var idx = $scope.showSpam.indexOf(id);
      if (idx == -1) {
        $scope.showSpam.push(id);
      } else {
        $scope.showSpam.splice(idx, 1);
      }
      //MyService.ConsoleLog($scope.showSpam);
    }

    $scope.spamMessage = '';
    $scope.spamData = '';
    $scope.dialogClose = "";
    $scope.sendMessage = function (data) {
      $scope.spamData = data;
      $scope.dialogClose = ngDialog.open({
        template: 'sendMessage',
        scope: $scope
      });
    };


    $scope.updateComments = function (options, id, idex) {
      var message = 'Are you sure you want make this comment as spam';
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        if ($rootScope.UserId) {
          if ($scope.essayComments[idex].createdBy.id != $rootScope.UserId) {
            var commentData = {};
            commentData.commentId = id;
            commentData.userId = $rootScope.UserId;

            switch (options) {
              case 'like':
                commentData.likes = 1;
                break;
              case 'dislike':
                commentData.dislikes = 1;
                break;
              default:
                commentData.spam = 1;
                break;

            }
            MyService.ConsoleLog("commentData", commentData);
            $scope.updateCommentApi(commentData, options, idex);
          } else {
            alert("You cannot " + options + " your own comments");
          }

        } else {
          alert("Please login to perform these actions");
        }
      }, function rejected(data) {
        MyService.ConsoleLog("rejected: ", data);
      });
    }
    $scope.submitSticky = function (data, id, idx) {
      if (data > 0) {
        updateForum('sticky', id, idx);
      } else {
        alert("Please enter valid order");
      }

    }
    $scope.ShowComments = false;
    $scope.redirectToComment = function () {
      if (!$rootScope.UserId) {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
          $location.path('signin');
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
          $location.path('signin');
        });
      }
      $scope.ShowComments = true;
      $scope.displayComments = false;
      //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);

    }


    $scope.sendMail = function (msg) {

      if ($scope.spamData.createdBy.id) {
        var msgurl = 'https://votewise.co/#!/essayDetails/' + $scope.categoryId + '/' + $scope.essayId;
        MyService.spamMail({
          "id": $scope.spamData.createdBy.id,
          "msg": msg,
          "msgurl": msgurl
        }).then(function (payload) {
          MyService.ConsoleLog(payload);
          if (payload.data.success) {
            ngDialog.close('sendMessage');
            $scope.dialogClose.close();
            alert("Message send successfully.");
          } else {
            alert(payload.data.data);
          }
        }, function (errorPayload) {
          alert("Something went wrong");
        });
      } else {
        alert("Something went wrong");
      }
      //alert("This is not implemented yet");
    }




    $scope.notSpam = function (mydata) {
      var message = 'This action will mark this comment as not spam and it will be visible again. Do you want to proceed?';
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.notSpamTopic({
          "id": mydata._id,
          isEssay: true
        }).then(function (payload) {
          $scope.getComments();
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        MyService.ConsoleLog("rejected: ", data);
      });
    }



    $scope.goBack = function () {
      $scope.ShowComments = false;
      $scope.displayComments = true;
    }
    $scope.getComments = function () {

      MyService.getEssayComments($routeParams.essayId).then(function (payload) {
          MyService.ConsoleLog("payload", payload);
          $scope.essayComments = payload;
        },
        function (errorPayload) {
          alert('Something went wrong ');
        });


    }

    $scope.SubmitComment = function (rec) {
      //MyService.ConsoleLog(data);
      //MyService.ConsoleLog($scope.mainThread);
      var data = {};
      if (rec.content != "") {
        $scope.TopicButton = true;
        data.createdById = $rootScope.UserId;
        data.createdByName = $rootScope.User;
        data.createdByType = tokenService.getUserRole();
        data.comments = rec.content;
        data.essayId = $routeParams.essayId;
        //MyService.ConsoleLog(data);


        MyService.submitEssayComments(data).then(function (payload) {
          $scope.comments = {
            content: ''
          };
          $scope.ShowComments = false;
          $scope.displayComments = true;
          $scope.mainThread = {};
          //$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
          $scope.getComments();
        }, function (response) {
          if (response.error) {
            alert(response.error);
            //MyService.ConsoleLog(response);
            if ((response.status) && (response.status == "405")) {
              var msg = "Do you want to send request to Admin to reactivate your account?.";
              MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                MyService.sendActivatationMailtoAdmin().then(function (payload) {
                  alert("Request sent successfully to admin");
                }, function (errorPayload) {
                  alert("Something went wrong");
                })
              }, function (errorPayload) {
                //MyService.ConsoleLog(errorPayload);
              });
            }
          }
        });



      } else {
        alert("Please enter comments.");
      }
    }

    $scope.EssayVote = function (option) {
      if (!$rootScope.UserId) {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true
        });

        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", data);
          $location.path('signin');
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
          $location.path('signin');
        });
      } else {
        if ($scope.essay.createdBy.id != tokenService.getUserId()) {
          var data = {};
          data.essayId = $routeParams.essayId;
          data.mediaType = '';
          data.option = option;
          //MyService.ConsoleLog(option);
          if (option == "Recommended") {
            data.vote = $scope.vote.recommend;
          } else {
            data.vote = $scope.vote.factual;
          }
          data.createdById = $scope.loginUser;
          data.createdByName = tokenService.getUserName();
          data.createdByType = tokenService.getUserRole();
          MyService.updateEssay(data).then(function (payload) {
              alert('Thank you for rating the essay.');
            },
            function (response) {
              if (response.error) {
                alert(response.error);
                //MyService.ConsoleLog(response);
                if ((response.status) && (response.status == "405")) {
                  var msg = "Do you want to send request to Admin to reactivate your account?.";
                  MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                    MyService.sendActivatationMailtoAdmin().then(function (payload) {
                      alert("Request sent successfully to admin");
                    }, function (errorPayload) {
                      alert("Something went wrong");
                    })
                  }, function (errorPayload) {
                    //MyService.ConsoleLog(errorPayload);
                  });
                }
              }
            });
        } else {
          alert("You cannot vote your own essay.");
        }
      }
    }


    $scope.editDescription = "";

    $scope.editComments = function (item) {
      MyService.ConsoleLog(item);
      $scope.editDescription = item;
      $scope.editDescription.description = item.comments.replace(" ...(updated)", "");;
      var editModal = ngDialog.open({
        template: 'templateId',
        scope: $scope
      });
    }

    $scope.removeComments = function (mydata) {
      var message = 'Are you sure you want to delete this comment?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });

      modal.then(function fullfilled(data) {

        //MyService.ConsoleLog("fullfilled: ", mydata);

        MyService.removeEssayComment({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          //$scope.getSpamTopics(1);
          $scope.getComments();

        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    $scope.UpdateReply = function (data) {
      MyService.ConsoleLog(data);
      if (data.description != "") {
        data.commentId = data._id;

        data.comments = data.description + " ...(updated)";
        MyService.updateEssayComts(data).then(function (payload) {
          MyService.ConsoleLog("payload : ", payload);
          alert("Comments updated");
          ngDialog.closeAll();
          $scope.getComments();

        }, function (errorPayload) {
          //MyService.ConsoleLog("errorPayload : ", errorPayload);
        });
      } else {
        alert("Comment should not be empty");
      }
    }



    if ($routeParams.categoryId) {
      getCategory();
      $scope.getEssayDetails();
      $scope.getComments();
      $anchorScroll();
    }

  }]);
