'use strict';
angular.module('myApp.questionSubcategories', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/questionSubcategories/:categoryId', {
      templateUrl: 'app/questionSubcategories/questionSubcategories.html',
      controller: 'questionSubcategoriesCtrl'
    });
  }])
  .controller('questionSubcategoriesCtrl', ['$scope', '$rootScope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'MyService', function ($scope, $rootScope, $http, $location, apiCall, $routeParams, $q, MyService) {
    //MyService.ConsoleLog("** questionSubcategoriesCtrl ** :",$routeParams);
    $rootScope.welcome = false;
    $scope.getAnsweredQuesCount = function (ids) {
      var request = apiCall.apiCall('POST', '/categories/count-answered-questions', {
        "ids": ids
      });
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          var total_answered_ques = response.data.data;
          //MyService.ConsoleLog("total_answered_ques: ",total_answered_ques);
          if (total_answered_ques > 0) {
            $scope.showMostPopularButton = true;
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    };

    function getSubCategories(params) {
      var request = apiCall.apiCall('GET', '/categories/list', {
        parentId: $routeParams.categoryId,
        catTypes: "Q,B"
      });
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        MyService.ConsoleLog("Got sub categories: ", response);
        if (response.data.success) {
          $scope.subCategories = response.data.data;
          var catids = [];
          angular.forEach($scope.subCategories, function (val, key) {
            if (val._id) catids.push(val._id);
          });
          if (catids.length > 0) $scope.getAnsweredQuesCount(catids.join(","));
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    function getCategory(params) {
      var request = apiCall.apiCall('GET', '/categories/list', {
        id: $routeParams.categoryId
      });
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        MyService.ConsoleLog("Got categories: ", response);
        if (response.data.success) {
          $scope.category = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    function getPopularQuestions() {
      var categoryIds = [];
      if ($routeParams.categoryId) categoryIds.push($routeParams.categoryId);

      angular.forEach($scope.subCategories, function (val, key) {
        categoryIds.push(val._id);
      });

      /*
      var request = apiCall.apiCall('GET', '/answers/popular', {"categoryIds": categoryIds.join(",")});
      $http(
      	request
      ).then(function successCallback(response) {
      	//MyService.ConsoleLog("Got PopularQuestions: ",response);
      	if(response.data.success){
      		var questions = response.data.data;
      		if(questions.length <= 0 && $scope.subCategories.length > 0){
      			$location.path('/question/'+$scope.subCategories[0]._id);
      		}
      		else{
      			$location.path('/question/popular:'+categoryIds.join(","));
      		}
      	}
      	else{
      		//MyService.ConsoleLog("Something went wrong: ",response.data);
      	}
      }, function errorCallBack(response) {
      	//MyService.ConsoleLog("Error: ",response);
      });
      */
      $location.path('/question/popular:' + $routeParams.categoryId);
    }

    $scope.popularQuestions = function () {
      getPopularQuestions();
    };

    if ($routeParams.categoryId) {
      getCategory();
      getSubCategories();
    }



    $scope.goToNextPage = function (cat) {
      var params = {
        questions_counter: 0
      };
      // params.parentId = $routeParams.categoryId+","+cat._id;
      params.parentId = cat._id;
      params.catTypes = "Q,B";
      //MyService.ConsoleLog("params: ",params);
      var request = apiCall.apiCall('GET', '/categories/list', params);
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        MyService.ConsoleLog("Response", response);
        if (response.data.success) {
          if (response.data.success && response.data.data.length > 0) {
            //there is subcategory present
            $location.path("questionSubcategories/" + params.parentId);
          } else {
            MyService.ConsoleLog("there is no subcategory", cat._id);
            // $location.path("question/"+cat._id);
            $location.path("question-background/" + $routeParams.categoryId + "/" + cat._id);
          }
        } else {
          alert("Something went wrong.");
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    };
  }]);
