'use strict';
angular.module('myApp.ratingReviews', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ratingReviews', {
      templateUrl: 'app/ratingReviews/ratingReviews.html',
      controller: 'ratingReviewsCtrl'
    });
  }])
  .controller('ratingReviewsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

    $scope.organizations = [];

    MyService.getOrgCompareAnswers().then(function (payload) {
      MyService.ConsoleLog("payload : ", payload);
      if (payload.data.success) {
        $scope.organizations = payload.data.data;
        console.log('$scope.organizations: ', $scope.organizations);
      }
    }, function (errorpayload) {
      console.log("errorpayload : ", errorpayload);
    });

  }]);
