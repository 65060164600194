'use strict';
angular
  .module('myApp.federalDetail', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/federalDetail/:ids', {
        templateUrl: 'app/federalDetails/federalDetails.html',
        controller: 'federalCtrl'
      });
    }
  ])
  .controller('federalCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$routeParams',
    'apiCall',
    'tokenService',
    'growl',
    '$window',
    '$sce',

    function($scope, ngDialog, $rootScope, MyService, $routeParams, apiCall, tokenService, growl, $window, $sce) {

      // console.log("$routeParams >", $routeParams);
      $scope.uId = $routeParams.ids;
      // console.log("idddddddddddd >", $scope.uId);
      $scope.activeDoc = true;
      $scope.activeAct = true;
      $scope.activeText = true;
      $scope.activeVot = true;
      $scope.activeVots = true;
      $scope.activeCom = true;
      $scope.activeTitles = true;

      // $rootScope.flag = true;
      $scope.activeSpo = true;
      $scope.displayComments = true;
      $scope.ShowComments = false;
      $scope.loader = false;



      $scope.getDetails = function() {
        // MyService.ConsoleLog('params >', val);
        MyService.getDetails({ id: $scope.uId }).then(
          function(response) {
            MyService.ConsoleLog("Response >", response);

            if (response.data.data && response.data.data.bill && response.data.data.votes) {
              $scope.details = response.data.data.bill;
              $scope.votesData = response.data.data.votes;

            } else {

              $scope.details = response.data.data;
            }

            // MyService.ConsoleLog('got data >', $scope.details);
            $scope.getRatingData();


            // $scope.showDetail = true;
          },
          function(err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };
      $scope.getDetails();

      /*
       ** Redirect to comment form
       */



      $scope.redirectToComment = function() {
        var token = apiCall.getToken();
        if (!token) {
          var message = "Please login first.";

          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true
          });

          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            $location.path('signin');
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            $location.path('signin');
          });
        }
        $scope.ShowComments = true;
        $scope.displayComments = false;

        //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
      }


      /*
       ** Hide comment text input
       */
      $scope.goBack = function() {
        $scope.ShowComments = false;
        $scope.displayComments = true;
      }
      $rootScope.flagOne = false;
      $scope.backTo = function() {
        $rootScope.flagOne = true;
        window.localStorage.setItem('flagOne', true);
        $window.history.back();
      }

      /*
       ** get Ratings 
       */

      $scope.rating = {
        "rate": ''
      }

      // $scope.path = "../assets/images/thumbsupone.png";
      $scope.disImg = false;

      $scope.rateLegislation = function(option) {
        var token = apiCall.getToken();
        if (!token) {
          var message = "Please login first.";

          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true
          });

          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            $location.path('signin');
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            $location.path('signin');
          });
        } else {
          var data = {};
          data.userId = tokenService.getUserId();
          data.uName = tokenService.getUserName();
          data.uType = tokenService.getUserRole();
          data.legisId = $scope.details._id;
          data.billNumber = $scope.details.billNumber;
          data.billType = $scope.details.billType;
          data.congress = $scope.details.congress;
          data.createOn = new Date();
          // if (option && option != "") {
          //   console.log("option >>", option);

          //   $scope.disImg = true;

          //   data.vote = 1;

          // }
          //MyService.ConsoleLog(option);

          data.rating = $scope.rating.rate;
          MyService.ConsoleLog("rating  >>>", data);

          MyService.saveLegisRating(data).then(function(payload) {
            // alert('Thank you for rating the Legislation.');
            // if (option && option != "") {
            // growl.success('Thank you for vote Legislation.');

            // } else {
              growl.success('Thank you for rating the Legislation.');
            // }
          }, function(errorPayload) {
            alert('Something went wrong ');
            growl.Error('Something went wrong');
          });
        }
      }



      /*
       ** Get comments and save it to tha database  
       */

      $scope.SubmitComment = function(rec) {
        //MyService.ConsoleLog(data);

        var data = {};
        if (rec.content != "") {

          data.commentedBy = tokenService.getUserId();
          data.commentedByName = tokenService.getUserName();
          data.commentedByType = tokenService.getUserRole();
          data.comments = rec.content;
          data.legisId = $scope.details._id;
          data.billNumber = $scope.details.billNumber;
          data.createOn = new Date();
          //MyService.ConsoleLog(data);
          MyService.saveLegisComment(data).then(function(payload) {
            $scope.comments = {
              content: ''
            };
            $scope.ShowComments = false;
            $scope.displayComments = true;
            $scope.getRatingData();
            growl.success("Thank you for comment")

            // $scope.getComments();
          }, function(errorPayload) {
            growl.Error("Something went wrong ");

          });
        } else {
          growl.Error("Please enter comments.");
        }
      }

      /*
       ** Fetch rating data from database  
       */

      $scope.getRatingData = function() {



        MyService.getLegisRate({ 'legisId': $scope.details._id, "uType": tokenService.getUserRole(), "billNumber": $scope.details.billNumber }).then(function(data) {
          MyService.ConsoleLog("Dayta>>>", data);

          $scope.disImg = true;

          $scope.rating.rate = data.data.rating;
          $scope.comment = data.data.comment;
        }, function(err) {
          console.log("error >>", err);
        })
      }


      $scope.checkData = function(data) {

        return $sce.trustAsHtml(data);

      }



    }
  ]);
