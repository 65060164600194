'use strict';
angular
  .module('myApp.getPopularQues', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/getPopularQues/:type?/:orgId?', {
        templateUrl: 'app/getPopularQuestions/getPopularQues.html',
        controller: 'getPopularQuesCtrl',
      });
    },
  ])
  .controller('getPopularQuesCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {
      $scope.isSelect = {
        voter: false,
        politician: false,
        organization: false,
        catId: '',
        numOfQues: '',
      };
      window.localStorage.removeItem('pQueIds');
      $cookies.remove('isShowPopUp');
      $scope.isShowPopUp = $routeParams.type;
      $cookies.put('isShowPopUp', $scope.isShowPopUp);


      /**
       * @description Find most popular questions by using user types and topics
       * @params user type,  topic, number of questions
       */
      $scope.find = function () {
        MyService.ConsoleLog('$scope.isSelected', $scope.isSelect);
        if ($scope.isSelect.voter || $scope.isSelect.politician || $scope.isSelect.organization) {
          MyService.getPopularQuestion($scope.isSelect).then(
            function (sdata) {
              MyService.ConsoleLog('sdata', sdata.data);
              if (sdata && sdata.data.length > 0) {
                window.localStorage.removeItem('queIds');
                window.localStorage.setItem('pQueIds', JSON.stringify(sdata.data));
                $location.path('/organizationQuiz');
              }

            },
            function (err) {
              growl.error(err);
            }
          );
        } else {
          growl.error('Please select at least one user type');
        }
      };

      /**Get Parent Categories */

      $scope.getParentCategoriesOne = function () {
        $rootScope.subCategory = '';
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall('GET', '/categories/list', {
          root: 1,
          questions_counter: 0,
          catTypes: 'Q',
        });
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog('data :', response);
            $scope.parentCategories = response.data.data;
            if (response.data.success) {
              $scope.selCatForQues = [{
                selected: null,
                viewOrders: 0,
                items: $scope.parentCategories,
              }, ];
              $scope.questions = [];
              $scope.statusGetCats = 2;
            } else {
              growl.error('Something went wrong');
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error('Something went wrong');
            $scope.statusGetCats = 3;
          }
        );
      };

      $scope.getParentCategoriesOne();
      var getQuestoinsCanceler = $q.defer();

      var selQuesCategoryCanceler = $q.defer();
      $scope.statusSubCat = 0;
      $scope.selQuesCategory = function (cat, index) {
        selQuesCategoryCanceler.resolve();
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("tempFun index: ", index);
        //remove all elements after given index
        // $scope.parCat = cat;
        $scope.questions = [];
        getQuestoinsCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();

        $scope.selCatForQues.length = index + 1;

        var params = {
          questions_counter: 0,
          parentId: cat._id,
          catTypes: 'Q',
        };
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = selQuesCategoryCanceler.promise;
        $scope.statusSubCat = 1;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              MyService.ConsoleLog('Got subcategory data: ', response.data.data);
              MyService.ConsoleLog('cat ', cat);

              if (response.data.data.length == 0) {
                $scope.subCategory = cat;
              }
              MyService.ConsoleLog('$rootScope.subCategory', $scope.subCategory);
              $scope.isSelect.catId = $scope.subCategory._id;

              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
              else if (response.data.data.length == 0) {}
              $scope.statusSubCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.statusSubCat = 3;
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.statusSubCat = 3;
          }
        );
      };
    },
  ]);
