"use strict";
angular
  .module("myApp.ballotMeasures", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/ballotMeasures", {
        templateUrl: "app/ballotMeasures/ballotMeasures.html",
        controller: "ballotMeasuresCtrl"
      });
    }
  ])
  .controller("ballotMeasuresCtrl", ["$scope", "$http", "$location", "apiCall", "$rootScope", "baseApiUrl", "$q", "$filter", "growl", "ngDialog", "tokenService", "MyService", function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService) {


    $scope.allState = [];
    $scope.address = {
      state: "",
      upstate: "",
      files: ""
    };
    /**
     * Load list of states
     */
    $scope.getAllStates = function () {
      $scope.allState.length = 0;
      $scope.loader = true;
      // console.log("address.state", $scope.address.state);
      MyService.getAllStates().then(
        function (payload) {
          // console.log("payload : ", payload);
          $scope.allState = payload;
          $scope.loader = false;
        },
        function (errorPayload) {
          growl.error("Something went wrong.");
          $scope.loader = false;
        }
      );
    };

    /**
     * End Of  getAllStates
     */

    /**
     * Call function on page Load
     */
    $scope.getAllStates();

    /**
     * addBallotMeasures
     * Add ballot measures by statewise
     */

    $scope.addBallotMeasures = function () {
      var stateps = $scope.address.state.statePostalCode;
      MyService.addBallotMeasuresData({
        state: stateps
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        $scope.ballotData = response.data.data;
      });
    };
    /**
     * End of addBallotMeasures
     */
  }]);
