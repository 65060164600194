"use strict";
angular
  .module("myApp.manageQue", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/manageQue", {
        templateUrl: "app/manageQuestions/manageQuestions.html",
        controller: "QuestionsCtrl",
      });
    },
  ])
  .controller("QuestionsCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$rootScope",
    "baseApiUrl",
    "$q",
    "$filter",
    "growl",
    "ngDialog",
    "MyService",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      MyService
    ) {
      //MyService.ConsoleLog(" ** QuestionsCtrl ** ");
      //------------- question --------------
      $scope.getParentCategoriesOne = function () {
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall("GET", "/categories/list", {
          root: 1,
          questions_counter: 0,
          catTypes: "Q",
        });
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog("data :", response);
            $scope.parentCategories = response.data.data;
            if (response.data.success) {
              $scope.selCatForQues = [
                {
                  selected: null,
                  viewOrders: 0,
                  items: $scope.parentCategories,
                },
              ];
              $scope.questions = [];
              $scope.statusGetCats = 2;
            } else {
              growl.error("Something went wrong");
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong");
            $scope.statusGetCats = 3;
          }
        );
      };

      $scope.getParentCategoriesOne();

      $scope.initQuesVar = function () {
        // MyService.ConsoleLog(" $scope.parentCategories :", $scope.parentCategories);
        $scope.question = {
          id: null, //to edit question set this
          content: "",
          categoryIds: [],
          viewOrders: [],
        };
        $scope.selCatForQues = [
          {
            selected: null,
            viewOrders: 0,
            items: $scope.parentCategories,
          },
        ];
        MyService.ConsoleLog("selCatForQues :", $scope.selCatForQues);
        $scope.questions = [];
      };
      $scope.initQuesVar();

      $scope.queFirstLevelCat = [];
      $scope.queSecondLevelCat = [];

      var getQuestoinsCanceler = $q.defer();
      $scope.statusGetQuestoins = 0;
      $scope.getQuestoins = function (cat_id) {
        //MyService.ConsoleLog("cat_id: ", cat_id);
        $scope.questions = [];
        getQuestoinsCanceler.resolve();
        getQuestoinsCanceler = $q.defer();
        removeQeustionCanceler.resolve();
        var params = {};

        if (cat_id) {
          params.categoryId = cat_id;
        }

        if ($rootScope.userRole != "admin") {
          params.authorId = $rootScope.UserId;
        }
        //MyService.ConsoleLog("questionVeriFilter: ", $scope.questionVeriFilter);
        if (
          $scope.questionVeriFilter != null ||
          $scope.questionVeriFilter != undefined
        ) {
          params.approved = $scope.questionVeriFilter;
        }

        if (
          $scope.question.content &&
          !$filter("isBlankString")($scope.question.content)
        ) {
          params.keyword = $scope.question.content;
        }

        //MyService.ConsoleLog("params: ", params);
        $scope.statusGetQuestoins = 1;
        MyService.getQuestions(params, getQuestoinsCanceler.promise).then(
          function (qdata) {
            //MyService.ConsoleLog("*************** questions ***************");
            //MyService.ConsoleLog("qdata: ", qdata);
            if (qdata.data.success) {
              $scope.questions = qdata.data.data;
              $scope.statusGetQuestoins = 2;
            } else {
              $scope.statusGetQuestoins = 3;
            }
          },
          function (err) {
            //MyService.ConsoleLog("Get question error: ", err);
            $scope.statusGetQuestoins = 3;
          }
        );
      };

      $scope.addQuestion = function () {
        var tempCatIds = [],
          tempViewOrders = [];
        angular.forEach($scope.selCatForQues, function (val, key) {
          //MyService.ConsoleLog("key: ",key," val: ",val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              tempCatIds[0] = selectedVal._id; //last level cat id
              tempViewOrders[0] = val.viewOrders;
            }
          }
        });
        //MyService.ConsoleLog("tempCatIds: ",tempCatIds);
        //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);

        var postobj = {
          content: $scope.question.content,
          categoryIds: tempCatIds.join(","),
          viewOrders: tempViewOrders.join(","),
        };
        //MyService.ConsoleLog("postobj: ", postobj);

        var request = apiCall.apiCall("POST", "/questions/create", postobj);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            /*if (response.data.success) {
              alert("Added successfully");
              $scope.initQuesVar();
          } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              growl.error('Error: ' + response.data.error);
          }*/
            if (response.data.success) {
              $scope.initVarCat();
              $rootScope.getCategories();
              // $scope.getParentCategories();
              growl.success("Added successfully!");
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            growl.error("Something went wrong.");
          }
        );
      };
      $scope.initVarCat = function () {
        $scope.category = {
          title: "",
          parentIds: [],
          viewOrders: [],
          description: "",
          viewOrder: 0,
          files: null,
          catType: [],
        };
      };
      var removeQeustionCanceler = $q.defer();
      $scope.removeQeustion = function (q, index) {
        //MyService.ConsoleLog("index: ", index, " Q: ", q);

        var message =
          $rootScope.userRole.toLowerCase() == "admin"
            ? "Removing question will remove all it's answers too. Do you want to continue?"
            : "This will remove question if it's not answered. Do you want to continue?";
        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            message +
            '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(1)">OK</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Cancel</button>\
            </div>',
          plain: true,
        });
        modal.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);

            var postobj = {
              id: q._id,
              timeout: removeQeustionCanceler.promise,
            };
            var request = apiCall.apiCall("POST", "/questions/remove", postobj);
            $http(request).then(
              function successCallback(response) {
                if (response.data.success) {
                  growl.success("Removed successfully.");
                  // $rootScope.getCategories();
                  // $scope.getParentCategories();
                  // $scope.initUpdateCat();
                  // $scope.questions.splice(index, 1);
                  var len = $scope.questions.length;
                  for (var i = 0; i < len; i++) {
                    if ($scope.questions[i]._id == postobj.id) {
                      $scope.questions.splice(i, 1);
                      break;
                    }
                  }
                } else if (
                  response.data.error &&
                  typeof response.data.error == "string"
                ) {
                  growl.error("Error: " + response.data.error);
                } else {
                  growl.error("Something went wrong.");
                }
              },
              function errorCallBack(response) {
                growl.error("Something went wrong.");
              }
            );
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          }
        );
      };

      $scope.editQeustion = function (q, index) {
        //MyService.ConsoleLog("edit question: ", q);
        // growl.error("Edit not implemented yet.");
        var editModal = ngDialog.open({
          template: "app/popups/edit_question.html",
          controller: "EditQuestionCtrl",
          data: q,
        });
      };

      $scope.verifyThisQues = function (user, index, val) {
        var postobj = {
          id: user._id,
          approve: val,
        };

        //MyService.ConsoleLog("postobj : ", postobj);
        $scope.questions[index].apiApproveStatus = 1;
        MyService.approveQuestion(postobj).then(
          function (response) {
            //MyService.ConsoleLog("Got categories: ", response);
            if (response.data.success) {
              $scope.questions[index].approved = val;
              $scope.questions[index].apiApproveStatus = 2;
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
              $scope.questions[index].apiApproveStatus = 3;
            } else {
              growl.error("Something went wrong.");
              $scope.questions[index].apiApproveStatus = 3;
            }
          },
          function (err) {
            growl.error("Something went wrong.");
            $scope.questions[index].apiApproveStatus = 3;
          }
        );
        /*
      var request = apiCall.apiCall('POST', "/questions/approve", postobj);
      $http(
          request
      ).then(function successCallback(response) {

      }, function errorCallBack(response) {
          growl.error('Something went wrong.');
      });
      */
      };

      var selQuesCategoryCanceler = $q.defer();
      $scope.statusSubCat = 0;
      $scope.selQuesCategory = function (cat, index) {
        selQuesCategoryCanceler.resolve();
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("tempFun index: ", index);
        //remove all elements after given index

        $scope.questions = [];
        getQuestoinsCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();

        $scope.selCatForQues.length = index + 1;
        $scope.catId = cat._id;
        var params = {
          questions_counter: 0,
          parentId: cat._id,
          catTypes: "Q",
        };
        var request = apiCall.apiCall("GET", "/categories/list", params);
        request.timeout = selQuesCategoryCanceler.promise;
        $scope.statusSubCat = 1;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
              else if (response.data.data.length == 0) {
                $scope.getQuestoins(cat._id);
              }
              $scope.statusSubCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.statusSubCat = 3;
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.statusSubCat = 3;
          }
        );
      };

      $scope.filterQuestions = function (selFilter) {
        if (selFilter == "all") $scope.questionVeriFilter = null;
        else $scope.questionVeriFilter = selFilter;

        var selCatId = null;
        angular.forEach($scope.selCatForQues, function (val, key) {
          if (val.selected) {
            //MyService.ConsoleLog("val: ", val);
            var tCat = JSON.parse(val.selected);
            selCatId = tCat._id;
          }
        });
        $scope.getQuestoins(selCatId);
      };

      /**
       * start call when row started dragging.
       */

      $scope.start = function (event, ui, order, id, ind) {
        MyService.ConsoleLog("current order", order);
        MyService.ConsoleLog("current index", id);
        MyService.ConsoleLog("$index", ind);
        $scope.oldOrder = order;
        $scope.curId = id;
      };

      /**
       * End of start
       */

      /**
       * Drop function called when rows drop from on position to another
       */

      $scope.drop = function (event, ui, order, id, ind) {
        MyService.ConsoleLog("order", order);
        MyService.ConsoleLog("ind", id);
        MyService.ConsoleLog("$index", ind);
        MyService.ConsoleLog("<<<<<<<< catId >>>>>>>>", $scope.catId);

        MyService.updateViewOrder({
          curId: $scope.curId,
          newOrder: order,
          oldOrder: $scope.oldOrder,
          catId: $scope.catId,
        }).then(
          function (response) {
            MyService.ConsoleLog("response", response);
            growl.success(response);
            setTimeout(function () {
              $scope.getQuestoins($scope.catId);
            }, 300);
          },
          function (error) {
            MyService.ConsoleLog("error", error);
          }
        );
      };

      /**
       * End of drop
       */
    },
  ]);
