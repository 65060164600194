'use strict';

angular.module('myApp.exploreQuiz', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/exploreQuiz/:catId?/:selCatId?/:inviterId?', {
      templateUrl: 'app/exploreQuiz/exploreQuiz.html',
      controller: 'exploreQuizCtrl'
    });
  }]).controller('exploreQuizCtrl', ['$scope', 'apiCall', 'MyService', '$location', '$anchorScroll', '$timeout', '$rootScope', 'ngDialog', '$q', '$http', '$filter', 'growl', '$routeParams', 'baseApiUrl', 'tokenService', '$cookies', function ($scope, apiCall, MyService, $location, $anchorScroll, $timeout, $rootScope, ngDialog, $q, $http, $filter, growl, $routeParams, baseApiUrl, tokenService, $cookies) {

    /**
     *@description Get params using $routeParams
     */
    $scope.catId = $routeParams.catId;
    $scope.selCatId = $routeParams.selCatId;
    $scope.inviterId = $routeParams.inviterId;
    MyService.ConsoleLog("catId", $scope.catId);
    MyService.ConsoleLog("selCatId", $scope.selCatId);

    $scope.showLoader = false;
    $scope.showInfo = false;
    window.localStorage.removeItem('inviterInfo');
    window.localStorage.removeItem('inviterId');
    window.localStorage.setItem('inviterId', $scope.inviterId);

    /**
     * @description Get Inviter details
     */

    $scope.getmyData = function () {
      $scope.showLoader = true;
      $scope.ShowPorifleLoading = true;
      MyService.getUserPublicProfile({
        id: $scope.inviterId,
        type: 'false'
      }).then(function (response) {
        $scope.ShowPorifleLoading = false;
        if (response.data.success) {
          $scope.userDetails = response.data.data;
          MyService.ConsoleLog("welcome", $scope.userDetails);
          $scope.userProfile = $scope.userDetails.userData;
          MyService.ConsoleLog("welcome to profile", $scope.userProfile);
          switch ($scope.userProfile.userType) {
            case "voter":
              $scope.inviterName = $scope.userProfile.username;
              window.localStorage.setItem('inviterInfo', JSON.stringify($scope.userProfile));
              break;
            case "politician":
              $scope.inviterName = $scope.userProfile.nameOnBallot;
              window.localStorage.setItem('inviterInfo', JSON.stringify($scope.userProfile));
              break;
            case "advocate":
              $scope.inviterName = $scope.userDetails.orgData.title;
              window.localStorage.setItem('inviterInfo', JSON.stringify($scope.userDetails.orgData));
              break;
            default:

              break;
          }


          $scope.showInfo = true;
          $scope.showLoader = false;
        } else {
          MyService.ConsoleLog("ELSE vishal");
          $scope.showLoader = false;
          alert("Something went wrong.");
        }
      }, function (errorPayload) {
        $scope.ShowPorifleLoading = false;
        MyService.ConsoleLog("ELSE vishal two");

        alert("Something went wrong.");
      });
    };

    /**
     * @description Redirect to the regular quiz
     */
    $scope.getQuiz = function () {
      $location.path("/question-background/" + $scope.catId + "/" + $scope.selCatId);
    };

    $scope.getmyData();
  }]);
