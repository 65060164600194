'use strict';
angular.module('myApp.questionHome', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/questionHome', {
      templateUrl: 'app/questionHome/questionHome.html',
      controller: 'questionHomeCtrl'
    });
  }])
  .controller('questionHomeCtrl', ['$scope', '$http', '$location', 'apiCall', 'MyService', '$rootScope', 'ngDialog', function ($scope, $http, $location, apiCall, MyService, $rootScope, ngDialog) {
    //MyService.ConsoleLog("** questionHomeCtrl **");
    $rootScope.welcome = false;
    $scope.showLocalMatch = true;
    //use $rootScope.categories
    window.localStorage.removeItem('isShowSpecialMatch');
    $scope.matchQuestion = function () {
      $location.path("/question-not-answered");
    }

    // $scope.getLocalPoliticians = function() {
    //   $scope.statusGetLocalPoliticians = 1;
    //   $scope.page += 1;
    //   var extradata = "";
    //   if ($rootScope.FinalData && $rootScope.FinalData != "") {
    //     extradata = $rootScope.FinalData;
    //     //alert("extra");
    //     if ($scope.MyDialog != "") {
    //       $scope.MyDialog.close();
    //     } else {
    //       $scope.showLocalMatch = false;
    //     }
    //   }
    //   MyService.getLocalIncumbentPoliForVoter({ max_records: 100, page: $scope.page, extradata: extradata }).then(function(response) {
    //     if (response.data.success) {
    //       MyService.ConsoleLog("length : " + response.data.data.length);
    //       if (response.data.data.length <= 0) {
    //         $scope.showLocalMatch = false;
    //       } else {
    //         $scope.showLocalMatch = true;
    //       }
    //       $scope.statusGetLocalPoliticians = 2;
    //     } else {
    //       $scope.page -= 1;
    //       $scope.statusGetLocalPoliticians = 3;
    //       if (typeof response.data.error == "string")
    //         growl.error(response.data.error);
    //       else
    //         growl.error("Something went wrong");
    //     }
    //   }, function(err) {
    //     $scope.page -= 1;
    //     $scope.statusGetLocalPoliticians = 3;
    //     growl.error("Something went wrong");
    //   });
    // };

    $scope.allState = [];
    $scope.MyDialog = "";
    $scope.displayContent = "";

    $scope.oldaddress = {
      state: "",
      county: "",
      countySubdivision: "",
      place: ""
    };

    $scope.address = {
      state: "",
      county: "",
      countySubdivision: "",
      place: ""
    };
    // $scope.getLocalPoliticians();
  }]);
