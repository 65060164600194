"use strict";
angular
  .module("myApp.uploadPolitician", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/uploadPolitician", {
        templateUrl: "app/uploadPoliticians/uploadPoliticians.html",
        controller: "UploadPoliticianCtrl",
      });
    },
  ])
  .controller("UploadPoliticianCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "growl",
    "tokenService",
    "ngDialog",
    "baseApiUrl",
    "$anchorScroll",
    "MyService",
    "$window",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      growl,
      tokenService,
      ngDialog,
      baseApiUrl,
      $anchorScroll,
      MyService,
      $window
    ) {
      //MyService.ConsoleLog("** UploadPoliticianCtrl **");
      $scope.imgUrl = baseApiUrl + "/resources";
      $scope.upload = {
        files: "",
        opt: "Update",
        mtype: "BEF",
        etype: "PRS",
        isEmail: "",
      };
      $scope.showPoliticianList = true;
      $scope.politicians = {
        data: [],
      };
      $scope.rate = {
        year: "",
      };
      $scope.ShowPoliticianLoader = false;
      $scope.PoliticianButton = false;
      $scope.StatePostCode = "";

      $scope.allUSAPol = {
        statePostalCode: "All USA",
        state: "All USA",
      };

      $scope.getAllPolitician = function (state) {
        $scope.ShowPoliticianLoader = true;
        $scope.politicians.data.length = 0;
        var state = JSON.parse(state);
        $scope.StatePostCode = state.statePostalCode;
        //MyService.ConsoleLog(state);
        MyService.getAllPoliticians({
          state: state.statePostalCode,
        }).then(
          function (payload) {
            // MyService.ConsoleLog("",payload);
            $scope.ShowPoliticianLoader = false;
            $scope.politicians.data = payload;
            // MyService.ConsoleLog("got states :",  $scope.politicians.data[0].statePostalCode);
          },
          function (err) {
            MyService.ConsoleLog(err);
            MyService.ConsoleLog("got states error");
            //growl.error("Some");
          }
        );
      };

      $scope.read = function (workbook) {
        /* DO SOMETHING WITH workbook HERE */
        var first_sheet_name = workbook.SheetNames[0];
        var desired_cell = workbook.Sheets[first_sheet_name];
        var finaldata = XLSX.utils.sheet_to_json(desired_cell);
        var finaldata1 = XLSX.utils.sheet_to_row_object_array(desired_cell);
        //var finaldata = XLSX.utils.sheet_to_json(desired_cell);
      };

      $scope.error = function (e) {
        /* DO SOMETHING WHEN ERROR IS THROWN */
        //MyService.ConsoleLog("Xls error called");
        //MyService.ConsoleLog(e);
      };

      $scope.allLocations = [];

      $scope.getAllStates = function () {
        $scope.allLocations.length = 0;
        MyService.getAllStates().then(
          function (data) {
            //MyService.ConsoleLog("got states");
            $scope.allLocations = data;
          },
          function (err) {
            //MyService.ConsoleLog("got states error");
            //growl.error("Some");
          }
        );
      };

      $scope.errorFile = "";
      $scope.updatePolitician = function (data) {
        MyService.ConsoleLog("data >> ", data);
        $scope.PoliticianButton = true;
        if (data.files != "") {
          //MyService.ConsoleLog(data.files.type.indexOf("office"));
          if (data.files.type.indexOf("office") > -1) {
            //MyService.ConsoleLog("data : ", data);
            if (data && data.isEmail != "") {
              var fd = new FormData();
              fd.append("file", data.files);
              fd.append("options", data.opt);
              fd.append("type", data.mtype);
              fd.append("etype", data.etype);
              fd.append("isEmail", data.isEmail);

              if (data.mtype == "WIN") {
                fd.append("state", data.mstate);
              }
              var posturl = baseApiUrl + "/user/uploadPolitician";
              $http
                .post(posturl, fd, {
                  transformRequest: angular.identity,
                  headers: {
                    "Content-Type": undefined,
                  },
                })
                .then(
                  function (response, status, headers, config) {
                    //MyService.ConsoleLog("response : ", response);
                    $scope.PoliticianButton = false;
                    $scope.upload.files = "";
                    $scope.upload.opt = "";
                    if (response.data.success) {
                      //$location.path('/learnHome');
                      $scope.politicians.data.length = 0;
                      $scope.errorFile = response.data.errorfile;
                      $scope.PoliticianButton = false;
                      //$scope.getAllPolitician();
                      alert("File successfully imported.");
                    } else {
                      alert("Something went wrong.");
                    }
                  },
                  function (data, status, headers, config) {
                    $scope.PoliticianButton = false;
                    //MyService.ConsoleLog("error");
                  }
                );
            } else {
              growl.warning("Please select email option to procced");
            }
          } else {
            alert("Please select xlsx file");
          }
        } else {
          alert("Please select file");
        }
      };

      $scope.ShowData = "";
      $scope.showExportLink = true;
      $scope.exportData = function () {
        //alert($scope.StatePostCode);

        $scope.showExportLink = false;
        MyService.exportPoliticianData({
          state: $scope.StatePostCode,
        }).then(
          function (payload) {
            //MyService.ConsoleLog("Success Data", payload);
            $scope.showExportLink = true;
            $window.open($scope.imgUrl + "/politician.xlsx", "_blank");
          },
          function (errorPayload) {
            $scope.showExportLink = true;
            //MyService.ConsoleLog("errorPayload : ", errorPayload);
            alert("Something went wrong. Please try again after sometime");
          }
        );
      };

      $scope.checkState = function (rdata, val) {
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val) return rdata;
        });
      };

      $scope.dataIdx = "";
      $scope.showPolitician = function (data, idx) {
        //MyService.ConsoleLog(data);
        $scope.ShowPoliticianLoader = true;
        $scope.ShowData = data;
        MyService.ConsoleLog("$scope.ShowData", $scope.ShowData);
        if (
          $scope.ShowData &&
          $scope.ShowData.campaignInfo &&
          $scope.ShowData.campaignInfo.length > 0
        ) {
          $scope.ShowData.campaignWeburl =
            $scope.ShowData.campaignInfo[0].campaignWeburl;
        }
        $scope.dataIdx = $scope.politicians.data.indexOf(data);
        $scope.showPoliticianList = false;
      };

      /**
       * @description Get ratings of candidates
       */
      $scope.getRatings = function (state) {
        MyService.ConsoleLog("rate.year", $scope.rate.year);
        if ($scope.rate && $scope.rate.year) {
          var nwState;
          MyService.ConsoleLog(" $scope.getStateRate", $scope.getStateRate);
          if ($scope.getStateRate) {
            nwState = $scope.getStateRate;
          } else {
            nwState = state;
          }
          console.log("nwState :", nwState);
          $scope.loader = true;
          MyService.getRatingsOfCandidate({
            s_id: nwState,
            year: $scope.rate.year,
          }).then(function (result) {
            console.log("result :", result);
            if (result && result.data && result.data.success) {
              growl.success(result.data.data);
            }
            $scope.loader = false;
          });
        } else {
          growl.error("Please Select Year First");
        }
      };

      $scope.stateName = "";
      $scope.setState = function (data) {
        //MyService.ConsoleLog("setState : ", data);
        var chkdata = $scope.checkState($scope.allLocations, data);
        if (chkdata != "") {
          //MyService.ConsoleLog("ChkData : ", chkdata);
          $scope.stateName = chkdata[0].state;
        }
      };

      $scope.goBack = function () {
        $scope.ShowData = "";
        $scope.showPoliticianList = true;
        $scope.ShowPoliticianLoader = false;
      };

      $scope.isSelected = false;
      $scope.SelectChecks = function () {
        //alert($scope.isSelected);
        if ($scope.isSelected == false) {
          $scope.isSelected = true;
          angular.forEach($scope.politicians.data, function (val, key) {
            val.checked = true;
          });
        } else {
          $scope.isSelected = false;
          angular.forEach($scope.politicians.data, function (val, key) {
            val.checked = false;
          });
        }
        //$scope.isSelected = true;
      };

      $scope.updatePoliticianData = function (data) {
        // MyService.ConsoleLog("check data", data);
        // return;
        if ($("#uploadform").validationEngine("validate") == true) {
          data = JSON.parse(JSON.stringify(data));
          var chkdata = $scope.checkState(
            $scope.allLocations,
            data.StatePostalCode
          );
          if (chkdata != "") {
            //MyService.ConsoleLog("ChkData : ", chkdata);
            data.state = chkdata[0].state;
          }
          var camp = {};
          var campArr = [];
          try {
            camp.campaignWeburl = data.campaignWeburl;
            camp.campaignEmail = data.campaignInfo[0].campaignEmail
              ? data.campaignInfo[0].campaignEmail
              : null;
            camp.campaignPhone = data.campaignInfo[0].campaignPhone
              ? data.campaignInfo[0].campaignPhone
              : null;
            camp.campaignFburl = data.campaignInfo[0].campaignFburl
              ? data.campaignInfo[0].campaignFburl
              : null;
            camp.campaignTwitterurl = data.campaignInfo[0].campaignTwitterurl
              ? data.campaignInfo[0].campaignTwitterurl
              : null;
            camp.campaignInstagramUrl = data.campaignInfo[0]
              .campaignInstagramUrl
              ? data.campaignInfo[0].campaignInstagramUrl
              : null;
            camp.campaignLinkedInUrl = data.campaignInfo[0].campaignLinkedInUrl
              ? data.campaignInfo[0].campaignLinkedInUrl
              : null;
            camp.campaignYouTubeUrl = data.campaignInfo[0].campaignYouTubeUrl
              ? data.campaignInfo[0].campaignYouTubeUrl
              : null;
            console.log("check log", camp);
            campArr.push(camp);
            data.campaignInfo = [];
            data.campaignInfo = JSON.stringify(campArr);
          } catch (err) {
            console.log("err", err);
          }
          MyService.ConsoleLog("data<><><><>", data);
          MyService.updatePoliticianData(data).then(
            function (payload) {
              //MyService.ConsoleLog("payload : ", payload);
              $scope.politicians.data[$scope.dataIdx] = data;
              $scope.showPoliticianList = true;
            },
            function (err) {
              alert("Something went wrong");
            }
          );
        }
      };

      $scope.seleectedMState = "";
      $scope.getPolitciansStateWise = function (state) {
        MyService.ConsoleLog(state);
        var tempState = JSON.parse(state);
        // MyService.ConsoleLog("state", typeof tempState);

        // MyService.ConsoleLog("state", tempState.statePostalCode);

        if (tempState && tempState.statePostalCode == "All USA") {
          $scope.getStateRate = "NA";
        } else {
          $scope.getStateRate = tempState.statePostalCode;
        }

        $scope.seleectedMState = state;
        $scope.getAllPolitician(state);
      };

      $scope.removeBoth = function (para, flag) {
        MyService.ConsoleLog("postparam<>", para);
        para.chkflag = flag;
        MyService.ConsoleLog("para", para);
        MyService.removePoliticians(para).then(
          function (response) {
            //MyService.ConsoleLog(response);
            if (response.data.success) {
              growl.success("Removed selected politicians successfully.");
              $scope.getPolitciansStateWise($scope.seleectedMState);
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
            } else {
              growl.error("Something went wrong.");
            }
          },
          function (err) {
            growl.error("Something went wrong.");
          }
        );
      };

      $scope.rmBoth = false;
      $scope.deleteSelected = function () {
        var tempArr = [];
        var len = $scope.politicians.data.length;
        var j = 0;

        angular.forEach($scope.politicians.data, function (val, key) {
          j++;
          if (val.checked) {
            tempArr.push(val._id);
          }
          if (j == len) {
            //MyService.ConsoleLog(tempArr);
            if (tempArr.length > 0) {
              var postparam = {
                user_ids: tempArr.join(","),
              };
              MyService.checkUserExist(postparam).then(
                function (result) {
                  MyService.ConsoleLog("result >>", result);
                  if (result.data.success) {
                    var message =
                      "There is a User associated with the selected politician. Do you want to delete that user also?";
                    var modal = ngDialog.openConfirm({
                      template:
                        "<p>" +
                        message +
                        '</p>\
                    <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(2)">Cancel</button>\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">No</button>\
                        <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                    </div>',
                      plain: true,
                      closeByDocument: false,
                    });

                    modal.then(
                      function fullfilled(data) {
                        MyService.ConsoleLog("data", data);
                        if (data == 1) {
                          $scope.rmBoth = true;
                          $scope.removeBoth(postparam, $scope.rmBoth);
                        } else {
                          // alert('calle');
                          $scope.rmBoth = false;
                          $scope.removeBoth(postparam, $scope.rmBoth);
                        }
                      },
                      function rejected(data) {}
                    );
                  } else {
                    // alert("User Not Exists");

                    var message = "Do you want to delete selected politician";
                    var modal = ngDialog.openConfirm({
                      template:
                        "<p>" +
                        message +
                        '</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
                        <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                    </div>',
                      plain: true,
                      closeByDocument: false,
                    });

                    modal.then(
                      function fullfilled(data) {
                        MyService.ConsoleLog("data", data);

                        // alert('calle');
                        $scope.rmBoth = false;
                        $scope.removeBoth(postparam, $scope.rmBoth);
                      },
                      function rejected(data) {}
                    );
                    //MyService.ConsoleLog("postparam<>", postparam);
                    // MyService.removePoliticians(postparam).then(function (response) {
                    //   //MyService.ConsoleLog(response);
                    //   if (response.data.success) {
                    //     growl.success('Removed selected politicians successfully.');
                    //     $scope.getPolitciansStateWise($scope.seleectedMState);
                    //   } else if (response.data.error && typeof response.data.error == "string") {
                    //     growl.error('Error: ' + response.data.error);

                    //   } else {
                    //     growl.error('Something went wrong.');
                    //   }
                    // }, function (err) {
                    //   growl.error("Something went wrong.");
                    // });
                  }
                },
                function (err) {
                  growl.error(err);
                }
              );
            } else {
              growl.error("Please select atleast one record to delete.");
            }
          }
        });
        //MyService.ConsoleLog("tempArr: ", tempArr);
      };

      $scope.registerPoliticianData = function (data) {
        //MyService.ConsoleLog("data : ", data);
        if ($.fn.validateForceFully($("#uploadform")) == true) {
          if (
            (data.privateEmail == "" || !data.privateEmail) &&
            data.publicEmail != ""
          ) {
            data.privateEmail = data.publicEmail;
          }
          // MyService.ConsoleLog("data<><><>",data);

          if (data.privateEmail != "" && data.privateEmail != null) {
            if (data.zipname != "" && data.zipname != null) {
              data = JSON.parse(JSON.stringify(data));
              var chkdata = $scope.checkState(
                $scope.allLocations,
                data.StatePostalCode
              );
              if (chkdata != "") {
                //MyService.ConsoleLog("ChkData : ", chkdata);
                data.state = chkdata[0].state;
              }
              MyService.registerPoliticianData(data).then(
                function (payload) {
                  MyService.updatePoliticianData(data).then(
                    function (payload) {
                      //MyService.ConsoleLog("payload : ", payload);
                      $scope.politicians.data[$scope.dataIdx] = data;
                      $scope.showPoliticianList = true;
                    },
                    function (err) {
                      //MyService.ConsoleLog("err : ", err);
                      alert("Something went wrong");
                    }
                  );
                },
                function (err) {
                  //MyService.ConsoleLog("err : ", err);
                  alert(err);
                }
              );
            } else {
              alert("Zipcode is required for creating the user.");
            }
          } else {
            alert("Email is required for creating the user.");
          }
        }
      };

      if ($rootScope.showUpload) {
        $scope.getAllStates();
        $("#uploadform").validationEngine();
      }
    },
  ]);
