'use strict';

angular.module('myApp.faq', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/faq', {
         templateUrl: 'app/faq/faq.html',
         controller: 'faqCtrl' 
     });
}])
.controller('faqCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
