'use strict';
angular
  .module('myApp.invitePoliticians', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/invitePoliticians/:quizName?', {
        templateUrl: 'app/invitePoliticians/invitePoliticians.html',
        controller: 'invitePoliCtrl',
      });
    },
  ])
  .controller('invitePoliCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    '$q',
    '$timeout',
    'tokenService',
    'MyService',
    'growl',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      growl
    ) {
      $scope.quizName = $routeParams.quizName;
      /**
       * User Object
       */
      $scope.User = {
        name: '',
      };
      /**
       * search politician for invite them
       */
      $scope.searchPoli = function () {
        MyService.ConsoleLog('$scope.user', $scope.User.name);
        MyService.searchPoli({
          name: $scope.User.name,
        }).then(
          function (response) {
            MyService.ConsoleLog('response', response);
            $scope.poliData = response.data.data;
          },
          function (err) {
            MyService.ConsoleLog('err', err);
          }
        );
      };

      /**
       * Select Politician
       */
      $scope.uArr = [];
      $scope.select = function (uid) {
        if ($scope.uArr.length > 0) {
          if ($scope.uArr.indexOf(uid) == -1) {
            $scope.uArr.push(uid);
          } else {
            var idx = $scope.uArr.indexOf(uid);
            MyService.ConsoleLog('idx', idx);
            $scope.uArr.splice(idx, 1);
          }
        } else {
          $scope.uArr.push(uid);
        }
        // MyService.ConsoleLog(' $scope.uArr', $scope.uArr);
      };

      /**
       * Select and deselect All Politicians
       */
      $scope.isSelected = false;

      $scope.SelectChecks = function () {
        //alert($scope.isSelected);
        if ($scope.isSelected == false) {
          $scope.isSelected = true;
          angular.forEach($scope.poliData, function (val, key) {
            val.isSelected = true;
            $scope.uArr.push(val._id);
            // MyService.ConsoleLog(' $scope.uArr inall', $scope.uArr);
          });
        } else {
          $scope.isSelected = false;
          angular.forEach($scope.poliData, function (val, key) {
            val.isSelected = false;
            $scope.uArr = [];
            // MyService.ConsoleLog(' else', $scope.uArr);
          });
        }
        //$scope.isSelected = true;
      };

      /**
       * Send Invitation to select politicians
       */
      $scope.sendInvitation = function (ele) {
        var pObj = {};
        if (ele && ele != '') {
          pObj.uId = ele;
          pObj.quizName = $scope.quizName;
          pObj.advId = tokenService.getUserId();
        } else {
          pObj.uId = $scope.uArr;
          pObj.quizName = $scope.quizName;
          pObj.advId = tokenService.getUserId();
        }
        var quizLink =
          window.location.origin + '#!/organizationQuiz/' + $scope.quizName + '/' + pObj.advId;
        pObj.quizLink = quizLink;
        MyService.ConsoleLog('pObj', quizLink);
        MyService.ConsoleLog('pObj', window.location.origin);
        // $location.path('/organizationQuiz/' + $scope.quizName + '/' + pObj.advId);
        MyService.sendQuizInvitation(pObj).then(
          function (result) {
            if (result.data.success) {
              MyService.ConsoleLog('Result', result);
              growl.success(result.data.data);
            } else {
              growl.error(result.data.error);
            }

          },
          function (err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };
    },
  ]);
