'use strict';
angular.module('myApp.geoCode', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/geoCode', {
      templateUrl: 'app/geoCode/geoCode.html',
      controller: 'geoCodeCtrl'
    });
  }])
  .controller('geoCodeCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
