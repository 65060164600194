'use strict';
angular.module('myApp.restorePolitician', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/restorePolitician/:type?', {
      templateUrl: 'app/restorePoliticians/restorePoliticians.html',
      controller: 'RemovePoliticianCtrl'
    });
  }])
  .controller('RemovePoliticianCtrl', ['$scope', '$location', '$rootScope', 'tokenService', 'MyService', 'ngDialog', '$routeParams', function($scope, $location, $rootScope, tokenService, MyService, ngDialog, $routeParams) {
    //MyService.ConsoleLog("* Following *");
    $scope.politician = {
      data: []
    };
    $scope.showHUpload = false;
    $scope.totalUsers = 0;
    $scope.usersPerPage = 25; // this should match however many results your API puts on one page

    $scope.pagination = {
      current: 1
    };
    $scope.showLoading = false;


    $scope.pageChanged = function(newPage) {
      $scope.getHistoryPolitician(newPage);
    };

    $scope.getHistoryPolitician = function(pageNumber) {
      // this is just an example, in reality this stuff should be in a service
      $scope.showLoading = true;
      MyService.getHistoryPolitician({
        "pageno": pageNumber,
        "max_records": $scope.usersPerPage
      }).then(function(payload) {
        $scope.politician.data = payload.Items;
        $scope.totalUsers = payload.Count;
        //MyService.ConsoleLog(payload.Items);
        $scope.showLoading = false;
      }, function(err) {
        //MyService.ConsoleLog(err);
      });
    }

    $scope.restorePolitician = function(mydata, idx) {
      var message = 'This action will restore the politician as well as its user account and other details if it already exists. Do you want to proceed?';

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.restorePolitician({
          "id": mydata.delId
        }).then(function(payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.getHistoryPolitician(1);
          alert("Politician restored successfully.");
        }, function(error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }

    if ($rootScope.showRUpload) {
      $scope.getHistoryPolitician(1);
    }
  }])
  