'use strict';
angular.module('myApp.compareSubcategories', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compare-subcategories/:categoryId', {
      templateUrl: 'app/compare_subcategories/compare_subcategories.html',
      controller: 'compareSubcategoriesCtrl'
    });
  }])
  .controller('compareSubcategoriesCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$filter', 'growl', 'MyService', '$q', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $filter, growl, MyService, $q) {
    //MyService.ConsoleLog("** compareSubcategoriesCtrl **",$routeParams);
    $rootScope.welcome = false;
    $scope.statusGetSubCategories = 0;
    /*
    function getSubCategories(params) {
    	$scope.statusGetSubCategories = 1;
      	var request = apiCall.apiCall('GET', '/categories/list', {"parentId": $routeParams.categoryId, "questions_counter": 1});
      	$http(
        	request
      	).then(function successCallback(response) {
    		//MyService.ConsoleLog("Got categories: ",response);
    		if(response.data.success){
    			$scope.subCategories = response.data.data;
    			$scope.statusGetSubCategories = 2;
    		}
    		else{
    			growl.error("Unable to load categories");
    			//MyService.ConsoleLog("Something went wrong: ",response.data);
    			$scope.statusGetSubCategories = 3;
    		}
      	}, function errorCallBack(response) {
        	//MyService.ConsoleLog("Error: ",response);
        	growl.error("Unable to load categories");
        	$scope.statusGetSubCategories = 3;
      	});
    }*/

    $scope.checkTotalAns = function () {
      var checkTotalAnswers = $scope.subCategories.filter(function (itms) {
        if (itms.total_answers != 0) {
          MyService.ConsoleLog("itms", typeof itms.total_answers);
          return itms.total_answers;
        }
      });
      MyService.ConsoleLog("checkTotalAnswers>>>>>>>>", checkTotalAnswers);
      if (checkTotalAnswers.length == 1) {
        $scope.compare(checkTotalAnswers[0]);
      }
    };

    var getCategoriesCanceler = $q.defer();

    function getSubCategories() {
      //MyService.ConsoleLog("getCategories: ***");
      getCategoriesCanceler.resolve();
      getCategoriesCanceler = $q.defer();
      var params = {
        root: 1,
        questions_counter: 1,
        catTypes: "Q,B",
        parentId: $routeParams.categoryId,
      };
      var temp_userids = [];
      if ($rootScope.selectedCompareUsers && $rootScope.selectedCompareUsers.length > 0) {
        angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
          // body...
          temp_userids.push(val._id);
        });
      }
      params.answer_count_userids = temp_userids.join(",");
      //MyService.ConsoleLog("params: ", params);
      $scope.statusGetSubCategories = 1;
      MyService.getCatListWithQueAnsCountUserwise(params, null).then(function (response) {
        if (response.data.success) {
          $scope.subCategories = response.data.data;
          MyService.ConsoleLog("Got subCategories: ", $scope.subCategories);
          $scope.checkTotalAns();
          $scope.statusGetSubCategories = 2;
        } else {
          $scope.statusGetSubCategories = 3;
          if (typeof response.data.error == "string")
            growl.error(response.data.error);
          else
            growl.error("Something went wrong");
        }
      }, function (err) {
        $scope.statusGetSubCategories = 3;
        growl.error("Something went wrong.");
      });
    }


    function getCategory(params) {
      var request = apiCall.apiCall('GET', '/categories/list', {
        id: $routeParams.categoryId
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.category = response.data.data;
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    function getPopularQuestions() {
      var categoryIds = [];
      if ($routeParams.categoryId) categoryIds.push($routeParams.categoryId);

      angular.forEach($scope.subCategories, function (val, key) {
        categoryIds.push(val._id);
      });

      var request = apiCall.apiCall('GET', '/answers/popular', {
        "categoryIds": categoryIds.join(",")
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got PopularQuestions: ",response);
        if (response.data.success) {
          var questions = response.data.data;
          if (questions.length <= 0 && $scope.subCategories.length > 0) {
            $location.path('/question/' + $scope.subCategories[0]._id);
          } else {
            $location.path('/question/popular:' + categoryIds.join(","));
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ",response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ",response);
      });
    }

    if ($routeParams.categoryId) {
      getCategory();
      getSubCategories();
    }

    $scope.statusGetCompareAnswers = 0;

    function getCompareAnswers(params) {
      $scope.statusGetCompareAnswers = 1;
      var request = apiCall.apiCall('GET', '/answers/compare', params);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          $scope.statusGetCompareAnswers = 2;
          angular.forEach(response.data.data, function (val, key) {
            if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {

              var check_author_exist = function (u) {
                var len = val.answers.length;
                for (var i = 0; i < len; i++) {
                  if (val.answers[i].author._id == u._id) {
                    return true;
                  }
                }
                return false;
              };

              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                if (!check_author_exist(u)) {
                  response.data.data[key].answers.push({
                    author: u
                  });
                }
              });

            } else if (!val.answers) {
              response.data.data[key].answers = [];
              angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                response.data.data[key].answers.push({
                  author: u
                });
              });
            }
          });

          $rootScope.compareAnswerList = response.data.data;
          //MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);
          $location.path("question-compare/" + params.categoryIds + "/" + $routeParams.categoryId);
        } else if (response.data.error && typeof response.data.error == "string") {
          $scope.statusGetCompareAnswers = 3;
          growl.error('Error: ' + data.error);
        } else {
          $scope.statusGetCompareAnswers = 3;
          growl.error('Something went wrong.');
        }
      }, function errorCallBack(response) {
        $scope.statusGetCompareAnswers = 3;
        growl.error('Something went wrong.');
      });
    }
    $scope.compare = function (subcat) {
      MyService.ConsoleLog("compare :", $rootScope.selectedCompareUsers);

      window.localStorage.setItem("selectedCompareUsers", JSON.stringify($rootScope.selectedCompareUsers));
      if ($rootScope.selectedCompareUsers.length <= 0) {
        growl.error('Please select user first.');
      } else if (subcat.has_subcategory) {
        $location.path("/compare-subcategories/" + subcat._id);
      } else {
        var temp_uids = [];
        angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
          temp_uids.push(val._id);
        });
        // if(subcat.total_answers && subcat.total_answers > 0){}
        $location.path("question-compare/" + subcat._id + "/" + $routeParams.categoryId);
        // getCompareAnswers({"compare_with": $routeParams.user_type, "categoryIds": subcat._id, "userIds": temp_uids.join(",")});

      }
    };
  }]);
