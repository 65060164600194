'use strict';

angular
  .module('myApp.manageOrganizationQuestions', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/manageOrganizationQuestions', {
        templateUrl: 'app/manageOrganizationQuestion/manageOrganizationQuestions.html',
        controller: 'manageOrganizationQuestionsCtrl',
      });
    },
  ])
  .controller('manageOrganizationQuestionsCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$rootScope',
    'baseApiUrl',
    '$q',
    '$timeout',
    'growl',
    'tokenService',
    '$anchorScroll',
    '$sce',
    'MyService',
    'ngDialog',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      baseApiUrl,
      $q,
      $timeout,
      growl,
      tokenService,
      $anchorScroll,
      $sce,
      MyService,
      ngDialog
    ) {
      $scope.disTxt = false;
      //------------- question --------------
      $scope.getParentCategoriesOne = function () {
        $rootScope.subCategory = '';
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall('GET', '/categories/list', {
          root: 1,
          questions_counter: 0,
          catTypes: 'Q',
        });
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog('data :', response);
            $scope.parentCategories = response.data.data;
            if (response.data.success) {
              $scope.selCatForQues = [{
                selected: null,
                viewOrders: 0,
                items: $scope.parentCategories,
              }, ];
              $scope.questions = [];
              $scope.statusGetCats = 2;
            } else {
              growl.error('Something went wrong');
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error('Something went wrong');
            $scope.statusGetCats = 3;
          }
        );
      };

      $scope.getParentCategoriesOne();
      var autoFillForQuizName = '';
      $scope.getQuizNames = function () {
        // alert('calle');
        autoFillForQuizName = '';
        MyService.getQuizNames({
          orgId: tokenService.getUserId()
        }).then(
          function (results) {
            MyService.ConsoleLog('Result>>>>>>>>', results);
            $scope.qNames = results;
            autoFillForQuizName = new Fuse(results, {
              shouldSort: true,
              caseSensitive: false,
              threshold: 0.4,
            });
            // MyService.ConsoleLog(" autoFillForQuizName", autoFillForQuizName);
          },
          function (err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };

      /**
       * Select quizNames
       */
      window.localStorage.removeItem('selectedQuizName');
      $scope.uArr = [];
      $scope.select = function (quiz) {
        MyService.ConsoleLog('quiz', quiz);
        // MyService.ConsoleLog("$scope.uArr one", $scope.uArr);
        if ($scope.uArr.length > 0) {
          // MyService.ConsoleLog('<<<<<<<$scope.uArr>>>>>>>', $scope.uArr.indexOf(quiz));


          if ($scope.uArr.indexOf(quiz) == -1) {
            $scope.uArr.push(quiz);
          } else {
            var idx = $scope.uArr.indexOf(quiz);
            MyService.ConsoleLog('idx', idx);
            $scope.uArr.splice(idx, 1);
          }
          window.localStorage.setItem('selectedQuizName', JSON.stringify($scope.uArr));
          MyService.ConsoleLog(' $scope.uArr', $scope.uArr);
        } else {
          $scope.uArr.push(quiz);
          // MyService.ConsoleLog(' $scope.uArr', $scope.uArr);
          window.localStorage.setItem('selectedQuizName', JSON.stringify($scope.uArr));
        }

        if ($scope.uArr.length > 0) {
          $scope.disTxt = true;
        } else {
          $scope.disTxt = false;
        }
      };

      function highlightCurOff(str, term) {
        MyService.ConsoleLog('str<><><><', str);
        var highlight_regex = new RegExp('(' + term + ')', 'gi');
        return str.replace(highlight_regex, '<span class="highlight">$1</span>');
      }

      /**
       * Filter quiz names
       */
      function autoFillName(term) {
        // alert('preFuzzy_suggest');
        // MyService.ConsoleLog("<<<<<<< term >>>>>>>>>", term);
        if (!term) return [];

        MyService.ConsoleLog('term >>', term);
        MyService.ConsoleLog('autoFillForQuizName', autoFillForQuizName);

        return autoFillForQuizName
          .search(term)
          .slice(0, 10)
          .map(function (i) {
            var val = $scope.qNames[i];
            return {
              value: JSON.stringify(val),
              label: $sce.trustAsHtml(highlightCurOff(val, term)),
            };
          });
      }
      /**
       * End of
       */

      $scope.closeAutoFill = function (col) {
        // alert('call');
        MyService.ConsoleLog('col', col);
        var nval = JSON.parse(col.value);
        $scope.question.quiz = nval;
        MyService.ConsoleLog('nval >>', nval);
        $scope.tempQuiz = nval;
        document.getElementById('quiz').value = $scope.tempQuiz;

        // MyService.ConsoleLog("$scope.question.quiz", $scope.question.quiz);
        document
          .getElementById('autoFillText')
          .getElementsByTagName('input')[0]
          .blur();
      };

      $scope.autoFillOptions = {
        suggest: autoFillName,
        on_select: $scope.closeAutoFill,
      };

      $scope.initQuesVar = function () {
        // MyService.ConsoleLog(" $scope.parentCategories :", $scope.parentCategories);
        $scope.question = {
          id: null, //to edit question set this
          content: '',
          categoryIds: [],
          viewOrders: [],
          quizName: '',
        };
        $scope.selCatForQues = [{
          selected: null,
          viewOrders: 0,
          items: $scope.parentCategories,
        }, ];
        $scope.getQuizNames();
        MyService.ConsoleLog('selCatForQues :', $scope.selCatForQues);
        $scope.questions = [];
      };
      $scope.initQuesVar();

      $scope.queFirstLevelCat = [];
      $scope.queSecondLevelCat = [];

      var getQuestoinsCanceler = $q.defer();
      $scope.statusGetQuestoins = 0;
      $scope.getQuestoins = function (cat_id) {
        //MyService.ConsoleLog("cat_id: ", cat_id);
        $rootScope.parCat = '';
        $scope.questions = [];
        getQuestoinsCanceler.resolve();
        getQuestoinsCanceler = $q.defer();
        removeQeustionCanceler.resolve();
        var params = {};

        if (cat_id) {
          params.categoryId = cat_id;
        }

        $rootScope.parCat = cat_id;
        // alert($rootScope.parCat);
        // if ($rootScope.userRole != "admin") {
        //   params.authorId = $rootScope.UserId;
        // }
        //MyService.ConsoleLog("questionVeriFilter: ", $scope.questionVeriFilter);
        if ($scope.questionVeriFilter != null || $scope.questionVeriFilter != undefined) {
          params.approved = $scope.questionVeriFilter;
        }

        if ($scope.question.content && !$filter('isBlankString')($scope.question.content)) {
          params.keyword = $scope.question.content;
        }

        //MyService.ConsoleLog("params: ", params);
        $scope.statusGetQuestoins = 1;
        MyService.getQuestions(params, getQuestoinsCanceler.promise).then(
          function (qdata) {
            //MyService.ConsoleLog("*************** questions ***************");
            MyService.ConsoleLog('qdata: ', qdata);
            if (qdata.data.success) {
              $scope.questions = qdata.data.data;
              $scope.statusGetQuestoins = 2;
            } else {
              $scope.statusGetQuestoins = 3;
            }
          },
          function (err) {
            //MyService.ConsoleLog("Get question error: ", err);
            $scope.statusGetQuestoins = 3;
          }
        );
      };

      $scope.addQuestion = function () {
        var tempCatIds = [],
          tempViewOrders = [];
        angular.forEach($scope.selCatForQues, function (val, key) {
          //MyService.ConsoleLog("key: ",key," val: ",val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              tempCatIds[0] = selectedVal._id; //last level cat id
              tempViewOrders[0] = val.viewOrders;
            }
          }
        });
        //MyService.ConsoleLog("tempCatIds: ",tempCatIds);
        //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);

        var postobj = {
          content: $scope.question.content,
          categoryIds: tempCatIds.join(','),
          viewOrders: tempViewOrders.join(','),
        };
        //MyService.ConsoleLog("postobj: ", postobj);

        var request = apiCall.apiCall('POST', '/questions/create', postobj);
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("Got categories: ",response);
            /*if (response.data.success) {
              alert("Added successfully");
              $scope.initQuesVar();
          } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              growl.error('Error: ' + response.data.error);
          }*/
            if (response.data.success) {
              $scope.initVarCat();
              $rootScope.getCategories();
              $scope.getParentCategories();
              growl.success('Added successfully!');
            } else if (response.data.error && typeof response.data.error == 'string') {
              growl.error('Error: ' + response.data.error);
            } else {
              growl.error('Something went wrong.');
            }
          },
          function errorCallBack(response) {
            growl.error('Something went wrong.');
          }
        );
      };
      $scope.initVarCat = function () {
        $scope.category = {
          title: '',
          parentIds: [],
          viewOrders: [],
          description: '',
          viewOrder: 0,
          files: null,
          catType: [],
        };
      };
      var removeQeustionCanceler = $q.defer();
      $scope.removeQeustion = function (q, index) {
        //MyService.ConsoleLog("index: ", index, " Q: ", q);

        var message =
          $rootScope.userRole.toLowerCase() == 'admin' ?
          "Removing question will remove all it's answers too. Do you want to continue?" :
          "This will remove question if it's not answered. Do you want to continue?";
        var modal = ngDialog.openConfirm({
          template: '<p>' +
            message +
            '</p>\
          <div class="ngdialog-buttons">\
              <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(1)">OK</button>\
              <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Cancel</button>\
          </div>',
          plain: true,
        });
        modal.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);

            var postobj = {
              id: q._id,
              timeout: removeQeustionCanceler.promise,
            };
            var request = apiCall.apiCall('POST', '/questions/remove', postobj);
            $http(request).then(
              function successCallback(response) {
                if (response.data.success) {
                  growl.success('Removed successfully.');
                  // $rootScope.getCategories();
                  // $scope.getParentCategories();
                  // $scope.initUpdateCat();
                  // $scope.questions.splice(index, 1);
                  var len = $scope.questions.length;
                  for (var i = 0; i < len; i++) {
                    if ($scope.questions[i]._id == postobj.id) {
                      $scope.questions.splice(i, 1);
                      break;
                    }
                  }
                } else if (response.data.error && typeof response.data.error == 'string') {
                  growl.error('Error: ' + response.data.error);
                } else {
                  growl.error('Something went wrong.');
                }
              },
              function errorCallBack(response) {
                growl.error('Something went wrong.');
              }
            );
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
          }
        );
      };

      $scope.editQeustion = function (q, index) {
        //MyService.ConsoleLog("edit question: ", q);
        // growl.error("Edit not implemented yet.");
        $rootScope.editModal = ngDialog.open({
          template: 'app/popups/editOrgQuestions.html',
          controller: 'AddQuestionCtrl',
          scope: $scope,
          preserveScope: true,
          data: q,
        });
      };

      $scope.verifyThisQues = function (user, index, val) {
        var postobj = {
          id: user._id,
          approve: val,
        };

        //MyService.ConsoleLog("postobj : ", postobj);
        $scope.questions[index].apiApproveStatus = 1;
        MyService.approveQuestion(postobj).then(
          function (response) {
            //MyService.ConsoleLog("Got categories: ", response);
            if (response.data.success) {
              $scope.questions[index].approved = val;
              $scope.questions[index].apiApproveStatus = 2;
            } else if (response.data.error && typeof response.data.error == 'string') {
              growl.error('Error: ' + response.data.error);
              $scope.questions[index].apiApproveStatus = 3;
            } else {
              growl.error('Something went wrong.');
              $scope.questions[index].apiApproveStatus = 3;
            }
          },
          function (err) {
            growl.error('Something went wrong.');
            $scope.questions[index].apiApproveStatus = 3;
          }
        );
        /*
      var request = apiCall.apiCall('POST', "/questions/approve", postobj);
      $http(
          request
      ).then(function successCallback(response) {

      }, function errorCallBack(response) {
          growl.error('Something went wrong.');
      });
      */
      };

      var selQuesCategoryCanceler = $q.defer();
      $scope.statusSubCat = 0;
      $scope.selQuesCategory = function (cat, index) {
        selQuesCategoryCanceler.resolve();
        cat = JSON.parse(cat);
        //MyService.ConsoleLog("tempFun index: ", index);
        //remove all elements after given index
        // $scope.parCat = cat;
        $scope.questions = [];
        getQuestoinsCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();

        $scope.selCatForQues.length = index + 1;

        var params = {
          questions_counter: 0,
          parentId: cat._id,
          catTypes: 'Q',
        };
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = selQuesCategoryCanceler.promise;
        $scope.statusSubCat = 1;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              MyService.ConsoleLog('Got subcategory data: ', response.data.data);
              MyService.ConsoleLog('cat ', cat);

              if (response.data.data.length == 0) {
                $scope.subCategory = cat;
              }
              MyService.ConsoleLog('$rootScope.subCategory', $scope.subCategory);
              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
              else if (response.data.data.length == 0) {
                $scope.getQuestoins(cat._id);
              }
              $scope.statusSubCat = 2;
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.statusSubCat = 3;
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            $scope.statusSubCat = 3;
          }
        );
      };

      $scope.filterQuestions = function (selFilter) {
        if (selFilter == 'all') $scope.questionVeriFilter = null;
        else $scope.questionVeriFilter = selFilter;

        var selCatId = null;
        angular.forEach($scope.selCatForQues, function (val, key) {
          if (val.selected) {
            //MyService.ConsoleLog("val: ", val);
            var tCat = JSON.parse(val.selected);
            selCatId = tCat._id;
          }
        });
        $scope.getQuestoins(selCatId);
      };
    },
  ])
  .controller('AddQuestionCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$rootScope',
    'baseApiUrl',
    '$q',
    '$filter',
    'growl',
    'ngDialog',
    'MyService',
    'tokenService',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $rootScope,
      baseApiUrl,
      $q,
      $filter,
      growl,
      ngDialog,
      MyService,
      tokenService
    ) {
      //MyService.ConsoleLog("* EditQuestionCtrl *", $scope.ngDialogData);
      $scope.userId = tokenService.getUserId();
      MyService.ConsoleLog('$scope.userId', $scope.userId);
      $scope.initQuesVar = function () {
        $scope.question = {
          id: null, //to edit question set this
          content: '',
          categoryIds: [],
          viewOrders: [],
          quizName: '',
        };
        if ($scope.ngDialogData) {
          try {
            MyService.ConsoleLog('$scope.ngDialogData<><><><>', $scope.ngDialogData);
            $scope.question.content = $scope.ngDialogData.content;
            $scope.question.showInMatch = $scope.ngDialogData.showInMatch;
            $scope.question.matchOrder = $scope.ngDialogData.matchOrder;
            MyService.ConsoleLog('question<><><><>', $scope.question);
          } catch (e) {
            //MyService.ConsoleLog("EditQuestion Exception: ", e);
          }
          //MyService.ConsoleLog("$scope.question: ", $scope.question);
        }

        $scope.selCatForQues = [{
          selected: null,
          viewOrders: 0,
          items: $scope.parentCategories,
        }, ];
        $scope.questions = [];
      };
      $scope.initQuesVar();

      $scope.matchType = ['YES', 'NO'];

      $scope.statusGetCats = 0;
      $rootScope.getParentCategories = function () {
        //MyService.ConsoleLog("getParentCategories");
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall('GET', '/categories/list', {
          root: 1,
          questions_counter: 0,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              $scope.parentCategories = response.data.data;

              $scope.selCatForQues = [{
                selected: null,
                viewOrders: 0,
                items: $scope.parentCategories,
              }, ];
              $scope.statusGetCats = 2;
            } else {
              growl.error('Something went wrong');
              $scope.statusGetCats = 3;
            }
          },
          function errorCallBack(response) {
            growl.error('Something went wrong');
            $scope.statusGetCats = 3;
          }
        );
      };
      $scope.getParentCategories();

      $scope.questionCatChanged = function (selcat) {
        selcat = JSON.parse(selcat);
        $scope.questionSubcategories = [];
        if (selcat._id) {
          var request = apiCall.apiCall('GET', '/categories/list', {
            parentId: selcat._id,
          });
          $http(request).then(
            function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.questionSubcategories = response.data.data;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
            }
          );
        }
      };

      $scope.queFirstLevelCat = [];
      $scope.queSecondLevelCat = [];

      $scope.addQuestion = function () {
        var tempCatIds = [],
          tempViewOrders = [];
        angular.forEach($scope.selCatForQues, function (val, key) {
          //MyService.ConsoleLog("key: ",key," val: ",val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              tempCatIds[0] = selectedVal._id; //last level cat id
              tempViewOrders[0] = val.viewOrders;
            }
          }
        });
        // var teome = document.getElementById('quiz');
        // MyService.ConsoleLog("$scope.tempQuiz", $scope.tempQuiz);
        // MyService.ConsoleLog("$scope.question", $scope.question.quiz);

        var tempQName;
        var tempArr = [];
        var isSelect = JSON.parse(window.localStorage.getItem('selectedQuizName'));
        MyService.ConsoleLog("isSelect >>>>>>>", isSelect);

        if (isSelect && isSelect.length > 0) {
          tempQName = isSelect;
        } else {
          tempArr.push($scope.question.quiz);
          tempQName = tempArr;
        }
        MyService.ConsoleLog('tempQName', tempQName);
        MyService.ConsoleLog('tempQName', typeof tempQName);

        var postobj = {
          content: $scope.question.content,
          showInMatch: $scope.question.showInMatch,
          matchOrder: $scope.question.matchOrder,
          advId: $scope.userId,
          quizName: tempQName,
        };
        postobj.categoryIds = $rootScope.parCat;
        if (tempCatIds.length > 0) {
          postobj.categoryIds = $rootScope.parCat.join(',');
          postobj.viewOrders = tempViewOrders.join(',');
        }
        if ($scope.ngDialogData) postobj.id = $scope.ngDialogData._id;
        MyService.ConsoleLog(' $rootScope.parCat', $rootScope.parCat);
        MyService.ConsoleLog('postobj: ', postobj);
        // return;
        if (postobj && postobj.quizName && postobj.quizName != '') {
          MyService.addorgQuestions(postobj).then(
            function (response) {
              MyService.ConsoleLog('response', response);
              window.localStorage.removeItem('selectedQuizName');
              $rootScope.editModal.close();
              growl.success(response.data.data);
              $scope.getQuizNames();
            },
            function (error) {
              MyService.ConsoleLog('error', error);
            }
          );
        } else {
          growl.error("Please enter the quiz name");
        }
      };

      var selQuesCategoryCanceler = $q.defer();
      $scope.selQuesCategory = function (cat, index) {
        selQuesCategoryCanceler.resolve();
        cat = JSON.parse(cat);
        //remove all elements after given index
        selQuesCategoryCanceler.resolve();
        selQuesCategoryCanceler = $q.defer();
        MyService.ConsoleLog('<><><><><><><> cat <><><><><><<><><>', cat);
        $scope.selCatForQues.length = index + 1;
        var params = {
          questions_counter: 0,
          parentId: cat._id,
        };
        var request = apiCall.apiCall('GET', '/categories/list', params);
        request.timeout = selQuesCategoryCanceler.promise;
        $http(request).then(
          function successCallback(response) {
            //MyService.ConsoleLog("************ in success callback ***************");
            if (response.data.success) {
              // MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                $scope.selCatForQues.push({
                  selected: null,
                  viewOrders: 0,
                  items: response.data.data,
                });
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };
    },
  ]);
