'use strict';

angular.module('myApp.checkRegistration', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
     $routeProvider.when('/checkRegistration', {
         templateUrl: 'app/checkRegistration/checkRegistration.html',
         controller: 'checkRegistrationCtrl' 
     });
}])
.controller('checkRegistrationCtrl', ['$scope', '$rootScope', function($scope, $rootScope) {
$rootScope.welcome = false;
}]);
