'use strict';
angular.module('myApp.addCategory', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/addCategory/:type?', {
      templateUrl: 'app/addCategory/addCategory.html',
      controller: 'addCategoryCtrl'
    });
  }]).controller('addCategoryCtrl', ['$scope', '$http', '$location', 'apiCall', '$rootScope', 'baseApiUrl', '$q', '$filter', 'growl', 'ngDialog', 'tokenService', 'MyService', '$routeParams', function ($scope, $http, $location, apiCall, $rootScope, baseApiUrl, $q, $filter, growl, ngDialog, tokenService, MyService, $routeParams) {
    //MyService.ConsoleLog("** dashboardCtrl **");
    $rootScope.welcome = false;
    $scope.allParentCats = [];
    $scope.statusGetCats = 0;
    $scope.alert_msg = false;
    $scope.success_msg = false;
    // $scope.search = false;
    MyService.ConsoleLog("$routeParams.type :", $routeParams.type);

    if ($routeParams.type && $routeParams.type != "") {
      $scope.showAddCategories = true;
    } else {
      $scope.showUpdateCategories = true;
    }

    $rootScope.getParentCategories = function (idx) {
      //MyService.ConsoleLog("getParentCategories: ", idx);
      //
      if (idx == 0) {
        $scope.allParentCats = [];
      }
      $scope.statusGetCats = 1;
      var request = apiCall.apiCall('GET', '/categories/list', {
        root: 1,
        questions_counter: 0
      });
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          $scope.parentCategories = response.data.data;
          /*
          $scope.selectedCategories = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForQues = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
          $scope.selCatForNewCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];*/
          $scope.allParentCats[idx] = [{
            "selected": null,
            viewOrders: 0,
            "items": $scope.parentCategories
          }];
          $scope.selCatForCatUpdate = [{
            "selected": null,
            viewOrders: 0,
            "items": $scope.parentCategories
          }];
          $scope.selCatForNewCatUpdate = [{
            "selected": null,
            viewOrders: 0,
            "items": $scope.parentCategories
          }];
          $scope.statusGetCats = 2;
        } else {
          //MyService.ConsoleLog(response.data.error);
          growl.error("Something went wrong");
          $scope.statusGetCats = 3;
        }
      }, function errorCallBack(response) {
        growl.error("Something went wrong");
        $scope.statusGetCats = 3;
      });
    }
    $rootScope.getParentCategories(0);

    $scope.addOneMoreParent = function () {
      var idx = $scope.allParentCats.length;
      $scope.getParentCategories(idx);
    };
    $scope.removeParent = function (pindex) {
      // body...
      //MyService.ConsoleLog("removeParent pindex: ", pindex);
      // $scope.allParentCats.length = pindex;
      $scope.allParentCats.splice(pindex, 1);
    };
    $scope.hideAll = function () {
      //alert("1");
      $rootScope.showProfile = false;
      $rootScope.showPoliProfile = false;
      $rootScope.showFollowing = false;
      $scope.showAddCategories = false;
      $scope.showUpdateCategories = false;
      $scope.showAddQuestion = false;
      $rootScope.showReportQuestion = false;
      $scope.showFiveMatchQuestion = false;
      $scope.showUserVerification = false;
      $rootScope.showAllCongressman = false;
      $rootScope.showCongressbills = false;
      $rootScope.showUpload = false;
      $rootScope.showVUpload = false;
      $rootScope.showRUpload = false;
      $rootScope.showHUpload = false;
      $rootScope.showOrganized = false;
      $rootScope.showAdvocates = false;
      $rootScope.showPress = false;
      $rootScope.showTopicSpam = false;
      $rootScope.showAllUsers = false;
      $rootScope.showAllTopic = false;
      $rootScope.showAllEssay = false;
      $rootScope.showAllEmail = false;
      $rootScope.showOpenFec = false;
      $rootScope.showOpenFecCommittee = false;

      $rootScope.showPlacesShape = false;
      $rootScope.showAllRecommendation = false;
      $scope.showBackup = false;

    }

    // $scope.parentCategories = angular.copy($rootScope.categories);


    /*
    $rootScope.$on("EventParentCategoriesFetched", function() {
        $scope.parentCategories = angular.copy($rootScope.categories);
        $scope.selectedCategories = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
        $scope.selCatForQues = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];

        $scope.selCatForCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
        $scope.selCatForNewCatUpdate = [{ "selected": null, viewOrders: 0, "items": $scope.parentCategories }];
    });
    */

    // $scope.showAddCategories = false;

    $scope.cattypes = [{
      lbl: "Essay",
      val: "E"
    }, {
      lbl: "For forum",
      val: "F"
    }, {
      lbl: "For questions",
      val: "Q"
    }, {
      lbl: "Hide Category",
      val: "H"
    }];
    $scope.updateCatTypes = angular.copy($scope.cattypes);

    /*
    $scope.catTypeSelected = function (val, selected) {
        //MyService.ConsoleLog("* catTypeSelected *", val);
            if(!$scope.category.catType) $scope.category.catType = [];

            var idx = $scope.category.cat_type.indexOf(val);
            if(selected)
                $scope.category.catType.push(val);
            else
                $scope.category.catType.splice(idx, 1);

            //MyService.ConsoleLog("category.catType: ",$scope.category.catType);
    };
    $scope.updateCatTypeSelected = function (val, selected) {
        //MyService.ConsoleLog("* updateCatTypeSelected *", val);

            var idx = $scope.updateCat.cat_type.indexOf(val);
            if(selected)
                $scope.updateCat.cat_type.push(val);
            else
                $scope.updateCat.cat_type.splice(idx, 1);

        //MyService.ConsoleLog("updateCatTypeSelected: ",$scope.updateCat.cat_type);
    };
    */

    $scope.initVarCat = function () {
      $scope.category = {
        title: '',
        parentIds: [],
        viewOrders: [],
        description: '',
        viewOrder: 0,
        files: null,
        catType: []
      };
      $scope.selectedCategories = [{
        "selected": null,
        viewOrders: 0,
        "items": $scope.parentCategories
      }];
      angular.forEach($scope.cattypes, function (c, i) {
        c.selected = false;
      });
    };
    $scope.initVarCat();

    $scope.addCategory = function () {
      /*
      var tempParentIds = [],
          tempViewOrders = [];
      angular.forEach($scope.selectedCategories, function(val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val) {
              // val = JSON.parse(val);
              var selectedVal = JSON.parse(val.selected);
              if (selectedVal && selectedVal._id) {
                  tempParentIds.push(selectedVal._id);
                  tempViewOrders[0] = val.viewOrders;
              }
          }
      });*/
      //MyService.ConsoleLog("allParentCats: ", $scope.allParentCats);
      var tempParentIdsAll = [],
        tempViewOrders = [];
      angular.forEach($scope.allParentCats, function (pcat, i) {
        // body...
        var tempParentIds = [];
        var len = pcat.length;
        var tViewOrder = null;
        angular.forEach(pcat, function (val, key) {
          //MyService.ConsoleLog("key: ", key, " val: ", val);
          if (val) {
            // val = JSON.parse(val);
            var selectedVal = JSON.parse(val.selected);
            if (selectedVal && selectedVal._id) {
              tempParentIds.push(selectedVal._id);
              tViewOrder = val.viewOrders;
            }
          }
        });
        //MyService.ConsoleLog("tViewOrder: ", tViewOrder);
        if (tViewOrder != null) tempViewOrders.push(tViewOrder);
        tempParentIdsAll.push(tempParentIds.join(","));
      });

      //MyService.ConsoleLog("--------------------------------");
      //MyService.ConsoleLog("tempParentIdsAll: ", tempParentIdsAll);
      //MyService.ConsoleLog("tempViewOrders: ", tempViewOrders);
      //MyService.ConsoleLog("cat_type: ", $scope.category.cat_type);
      var fd = new FormData();
      fd.append("title", $scope.category.title);
      fd.append("description", $scope.category.description);
      //MyService.ConsoleLog("category files: ", $scope.category.files);

      angular.forEach($scope.category.files, function (file) {
        //MyService.ConsoleLog("append file", file);
        fd.append('file', file);
      });

      fd.append('file', $scope.category.files);

      if (tempParentIdsAll.length > 0) {
        fd.append("parentIds", tempParentIdsAll.join(";"));
        fd.append("viewOrders", tempViewOrders.join(","));
      } else {
        fd.append("viewOrder", $scope.category.viewOrder);
      }


      var tempCatTypes = [];
      angular.forEach($scope.cattypes, function (c, i) {
        if (c.selected) {
          tempCatTypes.push(c.val);
        }
      });
      if (tempCatTypes <= 0) {
        growl.error("At least select one category type.");
        return;
      } else {
        fd.append("catType", tempCatTypes.join(","));
      }
      //MyService.ConsoleLog("formData: ",fd);
      var posturl = baseApiUrl + "/categories/create";
      $http({
        method: 'POST',
        url: posturl,
        // headers: { 'Content-Type': false },
        headers: {
          'Content-Type': undefined,
          'x-access-token': tokenService.getToken(),
          'Cache-Control': 'No-Store'
        },
        transformRequest: angular.identity,
        //Create an object that contains the model and files which will be transformed
        // in the above transformRequest method
        data: fd
      }).then(function (response, status, headers, config) {
        //MyService.ConsoleLog("success data: ", response.data);
        if (response.data.success) {
          $scope.initVarCat();
          $rootScope.getCategories();
          $rootScope.getParentCategories(0);
          growl.success("Added successfully!");
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
        } else {
          growl.error('Something went wrong.');
        }
      }, function (data, status, headers, config) {
        //MyService.ConsoleLog("error: ", data);
        growl.error("Something went wrong");
      });

      $scope.showProfile = false;
      $rootScope.showProfile = false;
      $rootScope.showUpload = false;
      $rootScope.showAllCongressman = false;
      $rootScope.showCongressbills = false;
      $rootScope.showVUpload = false;
      $rootScope.showRUpload = false;
      $rootScope.showHUpload = false;
      $rootScope.showOrganized = false;
      $rootScope.showAdvocates = false;
      $rootScope.showFollowing = false;
      $rootScope.showTopicSpam = false;
      $rootScope.showAllTopic = false;
      $rootScope.showReportQuestion = false;
      $scope.showFiveMatchQuestion = false;
      $rootScope.showAllEssay = false;
      $rootScope.showPress = false;
      $rootScope.showAllEvents = false;

      //$root.showAdvocates = !$root.showAdvocates
      //MyService.ConsoleLog("addCategory", $scope.category);

      /*
      var fd = new FormData();
      fd.append("title", $scope.category.title);
      fd.append("description", $scope.category.title);
      //MyService.ConsoleLog("category files: ", $scope.category.files);
      angular.forEach($scope.category.files, function(file){
          //MyService.ConsoleLog("append file", file);
          fd.append('file', file);
      });
      fd.append('file', $scope.category.files);
      var tempParentIds = [], tempViewOrders = [];
      angular.forEach($scope.category.parentIds, function(val, key){
          tempParentIds.push(val._id);
          tempViewOrders.push(val.viewOrders);
      });
      if(tempParentIds.length > 0){
          fd.append("parentIds", tempParentIds.join(","));
          fd.append("viewOrders", tempViewOrders.join(","));
      }
      //MyService.ConsoleLog("formData: ",fd);
      var posturl = baseApiUrl+"/categories/create";
      $http({
          method: 'POST',
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {'Content-Type': undefined},
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd
      }).
      success(function (data, status, headers, config) {
          //MyService.ConsoleLog("success data: ",data);
          if(data.success){
              $scope.initVarCat();
              $rootScope.getCategories();
              alert("Added successfully!");
          }
          else{
              alert("Error: ", data.error);
          }
      }).
      error(function (data, status, headers, config) {
          //MyService.ConsoleLog("error: ",data);
          alert("failed!");
      });
       */
    };

    $scope.toggleCategorySelection = function (cat) {
      //MyService.ConsoleLog(" toggleCategorySelection ");
      var idx = $scope.category.parentIds.indexOf(cat);

      // is currently selected
      if (idx > -1) {
        $scope.category.parentIds.splice(idx, 1);
      }
      // is newly selected
      else {
        $scope.category.parentIds.push(cat);
        $scope.statusGetCats = 1;
        var request = apiCall.apiCall('GET', '/categories/list', {
          root: 1,
          questions_counter: 0,
          parentId: cat._id
        });
        request.headers['Cache-Control'] = 'No-Store';
        $http(
          request
        ).then(function successCallback(response) {
          if (response.data.success) {
            //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
            // $scope.secondLevelCat = response.data.data;
            cat.subcategory = response.data.data;
            $scope.statusGetCats = 2;
          } else {
            $scope.statusGetCats = 3;
            growl.error("Something went wrong.");
          }
        }, function errorCallBack(response) {
          $scope.statusGetCats = 3;
          growl.error("Something went wrong.");
        });
      }
    };

    var toogleAddCatSelCanceler = $q.defer();
    $scope.toogleAddCatSel = function (cat, index, pindex) {
      cat = JSON.parse(cat);
      //MyService.ConsoleLog("$scope.selectedCategories: ",$scope.selectedCategories);
      //MyService.ConsoleLog("tempFun index: ", index, "pindex: ", pindex);
      /*
      //remove all elements after given index
      $scope.selectedCategories.length = index + 1;

      toogleAddCatSelCanceler.resolve();
      var params = {
          questions_counter: 0,
          parentId: cat._id,
          timeout: toogleAddCatSelCanceler.promise
      };
      var request = apiCall.apiCall('GET', '/categories/list', params);
      $http(
          request
      ).then(function successCallback(response) {
          if (response.data.success) {
              //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
              if (response.data.data.length > 0)
                  $scope.selectedCategories.push({ "selected": null, viewOrders: 0, "items": response.data.data });
          } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
      }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
      });
      */
      //MyService.ConsoleLog("allParentCats: ", $scope.allParentCats);
      $scope.allParentCats[pindex].length = index + 1;

      toogleAddCatSelCanceler.resolve();
      var params = {
        questions_counter: 0,
        parentId: cat._id,
        timeout: toogleAddCatSelCanceler.promise
      };
      var request = apiCall.apiCall('GET', '/categories/list', params);
      request.headers['Cache-Control'] = 'No-Store';
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
          if (response.data.data.length > 0) {
            //MyService.ConsoleLog("allParentCats 2: ", $scope.allParentCats);
            //MyService.ConsoleLog("index: ", index, " pindex: ", pindex);
            $scope.allParentCats[pindex].push({
              "selected": null,
              viewOrders: 0,
              "items": response.data.data
            });
            //MyService.ConsoleLog("allParentCats 3: ", $scope.allParentCats);
          }
        } else {
          //MyService.ConsoleLog("Something went wrong: ", response.data);
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
      });
    };

    //--------- update category ----------------

    $scope.initUpdateCat = function () {
      $scope.updateCat = {
        id: null,
        title: '',
        parentIds: [],
        viewOrders: [],
        description: '',
        viewOrder: 0,
        files: null,
        iconImage: null,
        old_path: null,
        catType: null
      };
      $scope.selCatForCatUpdate = [{
        "selected": null,
        viewOrders: 0,
        "items": $scope.parentCategories
      }];
      $scope.selCatForNewCatUpdate = [{
        "selected": null,
        viewOrders: 0,
        "items": $scope.parentCategories
      }];
      angular.forEach($scope.updateCatTypes, function (c, i) {
        c.selected = false;
      });
    };
    $scope.initUpdateCat();

    var toggleUpdateCatSelCanceler = $q.defer();
    $scope.toggleUpdateCatSel = function (cat, index) {
      cat = JSON.parse(cat);
      //MyService.ConsoleLog("toggleUpdateCatSel : ", cat);
      $scope.updateCat.id = cat._id;
      $scope.updateCat.title = cat.title;
      $scope.updateCat.description = cat.description;
      if (cat.fbTitle) {
        $scope.updateCat.fbtitle = cat.fbTitle;
      }
      if (cat.fbDescription) {
        $scope.updateCat.fbdescription = cat.fbDescription;
      }
      $scope.updateCat.viewOrder = cat.order;
      $scope.selCatForCatUpdate[index].viewOrders = cat.order;
      //MyService.ConsoleLog(" viewOrders index: ", $scope.selCatForCatUpdate[index].viewOrders);

      angular.forEach($scope.updateCatTypes, function (c, i) {
        c.selected = false; //reset all selected categories
      });
      if (cat.catType) {
        angular.forEach(cat.catType, function (val, key) {
          angular.forEach($scope.updateCatTypes, function (c, i) {
            if (c.val == val) {
              c.selected = true;
            }
          });
        });
        // $scope.updateCat.cat_type = cat.cat_type;
        // angular.forEach($scope.cattypes, function(val, key){
        //  if(val.val == cat.cat_type)
        //      $scope.updateCat.cat_type = val;
        // });
      }
      $scope.updateCat.iconImage = cat.iconImage;
      if (cat.fbImage) {
        $scope.updateCat.fbimage = cat.fbImage;
      }
      //MyService.ConsoleLog("response.data: ", cat);
      try {
        $scope.updateCat.old_path = cat.parentIds._id;
      } catch (e) {
        //MyService.ConsoleLog("Exception :", old_path);
      }

      //remove all elements after given index
      $scope.selCatForCatUpdate.length = index + 1;

      toggleUpdateCatSelCanceler.resolve();
      var params = {
        questions_counter: 0,
        parentId: cat._id,
        timeout: toggleUpdateCatSelCanceler.promise
      };
      var request = apiCall.apiCall('GET', '/categories/list', params);
      request.headers['Cache-Control'] = 'No-Store';
      $http(
          request
        )
        .then(function successCallback(response) {
          if (response.data.success) {
            MyService.ConsoleLog("Got subcategory data: ", response.data.data);
            if (response.data.data.length > 0) {
              $scope.selCatForCatUpdate.push({
                "selected": null,
                viewOrders: 0,
                "items": response.data.data
              });
            }
          } else {
            //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
        });
    };

    var toggleUpdateNewCatSelCanceler = $q.defer();
    $scope.statusUpdateGetCats = 0;
    $scope.toggleUpdateNewCatSel = function (cat, index) {
      cat = JSON.parse(cat);
      //MyService.ConsoleLog("$scope.selectedCategories: ",$scope.selectedCategories);
      //MyService.ConsoleLog("tempFun index: ", index);
      //remove all elements after given index
      $scope.selCatForNewCatUpdate.length = index + 1;
      toggleUpdateNewCatSelCanceler.resolve();
      toggleUpdateNewCatSelCanceler = $q.defer();
      var params = {
        questions_counter: 0,
        parentId: cat._id,
      };
      $scope.statusUpdateGetCats = 1;
      var request = apiCall.apiCall('GET', '/categories/list', params);
      request.headers['Cache-Control'] = 'No-Store';
      request.timeout = toggleUpdateNewCatSelCanceler.promise;
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          //MyService.ConsoleLog("Got subcategory data: ", response.data.data);
          if (response.data.data.length > 0)
            $scope.selCatForNewCatUpdate.push({
              "selected": null,
              viewOrders: 0,
              "items": response.data.data
            });
          $scope.statusUpdateGetCats = 2;
        } else {
          $scope.statusUpdateGetCats = 3;
          growl.error("Something went wrong.");
        }
      }, function errorCallBack(response) {
        //MyService.ConsoleLog("Error: ", response);
        $scope.statusUpdateGetCats = 3;
        growl.error("Something went wrong.");
      });
    };

    $scope.updateCategory = function () {
      //MyService.ConsoleLog("** updateCategory **");

      var tempParentIds = [],
        tempViewOrders = [];
      var parent_path_id = 0;
      var parent_path_order = 0;
      angular.forEach($scope.selCatForCatUpdate, function (val, key) {
        //MyService.ConsoleLog("key: ", key, " val: ", val);
        if (val) {
          // val = JSON.parse(val);
          var selectedVal = JSON.parse(val.selected);
          if (selectedVal && selectedVal._id) {
            // tempParentIds.push(selectedVal._id);
            // tempViewOrders[0] = val.viewOrders;
            //MyService.ConsoleLog("** parentIds: ", selectedVal.parentIds);
            parent_path_id = selectedVal.parentIds._id;
            parent_path_order = val.viewOrders;
          }
        }
      });
      //MyService.ConsoleLog("parent_pathid :", parent_path_id);
      //MyService.ConsoleLog("parent_path_order :", parent_path_order);
      //MyService.ConsoleLog("tempParentIds: ",tempParentIds);
      //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);




      var fd = new FormData();
      fd.append("id", $scope.updateCat.id);
      fd.append("title", $scope.updateCat.title);
      fd.append("description", $scope.updateCat.description);
      fd.append("fbtitle", $scope.updateCat.fbtitle);
      fd.append("fbdescription", $scope.updateCat.fbdescription);

      if (parent_path_id) {
        fd.append("parent_path_id", parent_path_id);
        fd.append("parent_path_order", parent_path_order);
      }

      //MyService.ConsoleLog("category files: ", $scope.updateCat.files);

      angular.forEach($scope.updateCat.files, function (file) {
        //MyService.ConsoleLog("append file", file);
        fd.append('file', file);
      });

      fd.append('file', $scope.updateCat.files);

      fd.append('fbfile', $scope.updateCat.fbimage);

      if (tempParentIds.length > 0) {
        fd.append("parentIds", tempParentIds.join(","));
        fd.append("viewOrders", tempViewOrders.join(","));
      } else {
        fd.append("viewOrder", $scope.updateCat.viewOrder);
      }

      var tempCatTypes = [];

      angular.forEach($scope.updateCatTypes, function (c, i) {
        if (c.selected) {
          tempCatTypes.push(c.val);
        }
      });

      if (tempCatTypes <= 0) {
        growl.error("At least select one category type.");
        return;
      } else {
        fd.append("catType", tempCatTypes.join(","));
      }
      //MyService.ConsoleLog(fd);
      var posturl = baseApiUrl + "/categories/create";
      $http({
          method: 'POST',
          url: posturl,
          // headers: { 'Content-Type': false },
          headers: {
            'Content-Type': undefined,
            'Cache-Control': 'No-Store'
          },
          transformRequest: angular.identity,
          //Create an object that contains the model and files which will be transformed
          // in the above transformRequest method
          data: fd
        })
        .then(function (data, status, headers, config) {
          //MyService.ConsoleLog("success data: ", data);
          if (data.data.success) {
            growl.success('Updated successfully.');
            $rootScope.getCategories();
            $scope.getParentCategories();
            $scope.initUpdateCat();
          } else if (data.data.error && typeof data.error == "string") {
            growl.error('Error: ' + data.error);
          } else {
            growl.error('Something went wrong.');
          }
        }, function (data, status, headers, config) {
          //MyService.ConsoleLog("error >> ", data);
          growl.error('Something went wrong.');
        });
    };

    $scope.removeCategory = function () {
      //MyService.ConsoleLog("-- removeCategory --");
      if (!$scope.updateCat || !$scope.updateCat.id) {
        growl.error("Select category first.");
        return;
      }

      var old_path_ids = null;
      var category_id = null;

      angular.forEach($scope.selCatForCatUpdate, function (val, key) {
        try {
          var selObj = JSON.parse(val.selected);
          if (selObj._id) {
            category_id = selObj._id;
            // selObj.parentIds = JSON.parse(selObj.parentIds);
            old_path_ids = selObj.parentIds._id;
          }
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e)
        }
      });

      var postobj = {
        id: category_id,
        old_path_ids: old_path_ids
      };

      //MyService.ConsoleLog("postobj : ", postobj);
      var request = apiCall.apiCall('POST', "/categories/remove", postobj);
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ",response);
        if (response.data.success) {
          growl.success('Removed successfully.');
          $rootScope.getCategories();
          $scope.getParentCategories();
          $scope.initUpdateCat();
        } else if (response.data.error && typeof response.data.error == "string") {
          growl.error('Error: ' + response.data.error);
        } else {
          growl.error('Something went wrong.');
        }
      }, function errorCallBack(response) {
        growl.error('Something went wrong.');
      });

    };

    $scope.updateParentCategory = function () {
      //MyService.ConsoleLog("-- updateParentCategory --");
      //MyService.ConsoleLog("selCatForNewCatUpdate: ", $scope.selCatForNewCatUpdate);
      var tempParentIds = [],
        tempViewOrders = [];
      angular.forEach($scope.selCatForNewCatUpdate, function (val, key) {
        try {
          var selObj = JSON.parse(val.selected);
          if (selObj._id) {
            tempParentIds.push(selObj._id);
            tempViewOrders[0] = val.viewOrders;
          }
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e)
        }
      });

      var old_path_ids = null;
      angular.forEach($scope.selCatForCatUpdate, function (val, key) {
        try {
          var selObj = JSON.parse(val.selected);
          if (selObj._id) {
            // selObj.parentIds = JSON.parse(selObj.parentIds);
            old_path_ids = selObj.parentIds._id;
          }
        } catch (e) {
          //MyService.ConsoleLog("Exception: ", e)
        }
      });

      //MyService.ConsoleLog("tempParentIds: ",tempParentIds);
      //MyService.ConsoleLog("tempViewOrders: ",tempViewOrders);
      if (!$scope.updateCat || !$scope.updateCat.id) {
        growl.error("Select category first.");
      } else {
        var postobj = {
          parentIds: tempParentIds.join(","),
          viewOrders: tempViewOrders.join(","),
          id: $scope.updateCat.id,
          old_path_ids: old_path_ids,
          viewOrder: $scope.updateCat.viewOrder
        };
        //MyService.ConsoleLog("postobj: ", postobj);

        /*
        var message = 'Continue?';
        var modal = ngDialog.openConfirm({
            template:'<p>'+message+'</p>\
                    <div class="ngdialog-buttons">\
                        <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                    </div>',
            plain: true
        });
        modal.then(function fullfilled(data){
            //MyService.ConsoleLog("fullfilled: ",data);
        }, function rejected(data){
            //MyService.ConsoleLog("rejected: ",data);
        });
        */

        var request = apiCall.apiCall('POST', "/categories/update-parent", postobj);
        $http(
          request
        ).then(function successCallback(response) {
          if (response.data.success) {
            growl.success('Updated successfully.');
            $rootScope.getCategories();
            $scope.getParentCategories();
            $scope.initUpdateCat();
          } else if (response.data.error && typeof response.data.error == "string") {
            growl.error('Error: ' + response.data.error);
          } else {
            growl.error('Something went wrong.');
          }
        }, function errorCallBack(response) {
          growl.error('Something went wrong.');
        });
      }
    };
    $scope.addCongressBills = function () {
      MyService.ConsoleLog("In addCongressBills");
      $scope.loader = true;
      MyService.addCongressBills().then(function (data) {
        MyService.ConsoleLog('Congress bill data : ', data);
        if (data.status == 200) {
          $scope.loader = false;
          alert("Congress Bills added");
        }
      }, function (err) {
        MyService.ConsoleLog("Congress bill error: ", err);
        $scope.loader = false;
      });
    };


  }])
