'use strict';
angular.module('myApp.ballotRating', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/ballotRatings/:id', {
      templateUrl: 'app/ballotRating/ballotRating.html',
      controller: 'ballotRatingCtrl'
    });
  }])
  .controller('ballotRatingCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', '$sce', 'growl', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService, $sce, growl) {

    /**
     * Get params using Route params
     */
    $scope.bId = $routeParams.id;
    MyService.ConsoleLog(" $scope.bId", $scope.bId);

    /**
     * Get Details of ballot measures
     */
    $scope.getDetailsOfBallot = function () {
      MyService.getDetailsOfBallotMeasures({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        if (response) {
          $scope.title = response.title;
          $scope.getData();
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Get Reviews of the bill
     */
    $scope.getData = function () {

      MyService.getReviewsByBallotId({
        bId: $scope.bId
      }).then(function (response) {
        MyService.ConsoleLog("reposne", response);
        $scope.allReviews = response.data.data;
      }, function (err) {
        MyService.ConsoleLog("error", err);
      });
    };

    /**
     * Sort name wise
     */
    $scope.sortSearch = function (val) {
      var sortOrder = "";
      MyService.ConsoleLog(val);
      if (val == 'name') {
        MyService.ConsoleLog("nameUp > ", $scope.nameUp);
        if ($scope.nameUp == "" || $scope.nameUp == "DESC") {
          sortOrder = "ASC";
          $scope.nameUp = true;
          $scope.nameDown = false;
          $scope.allReviews.sort(function (a, b) {
            var x = a.from[0].name.toLowerCase();
            var y = b.from[0].name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

        } else {
          sortOrder = "DESC";
          $scope.nameDown = true;
          $scope.nameUp = false;
          $scope.allReviews.sort(function (a, b) {
            var x = a.from[0].name.toLowerCase();
            var y = b.from[0].name.toLowerCase();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });

        }
      } else {
        $scope.nameUp = false;
        $scope.nameDown = false;
      }
      if (val == 'rating') {
        if ($scope.ratingUp == "" || $scope.ratingUp == "DESC") {
          sortOrder = "ASC";
          $scope.ratingUp = true;
          $scope.ratingDown = false;
          $scope.allReviews.sort(function (a, b) {
            return a.number - b.number;
          });

        } else {
          sortOrder = "DESC";
          $scope.ratingDown = true;
          $scope.ratingUp = false;
          $scope.allReviews.sort(function (a, b) {
            return b.number - a.number;
          });
        }
      } else {
        $scope.ratingUp = false;
        $scope.ratingDown = false;
      }
    };

    /**
     * Sort Date
     */
    $scope.sortDate = function () {
      var sortOrder = "";

      if ($scope.ratingDateUp == "" || $scope.ratingDateUp == "DESC") {
        sortOrder = "ASC";
        $scope.ratingDateUp = true;
        $scope.ratingDateDown = false;
        $scope.allReviews.sort(function (a, b) {
          var asc;
          var bss;
          // return a.timespan - b.timespan;
          MyService.ConsoleLog("createdBy", a.createdBy);
          if (a.createdBy.length > 4 || b.createdBy.length > 4) {
            // console.log("if");
            asc = a.createdBy.slice(0, 4);
            bss = b.createdBy.slice(0, 4);
            MyService.ConsoleLog("ass", asc + "   " + bss);
            return asc - bss;
          }
        });
      } else {
        sortOrder = "DESC";
        $scope.ratingDateDown = true;
        $scope.ratingDateUp = false;
        var oneA, bTwo;

        $scope.allReviews.sort(function (a, b) {
          if (a.createdBy.length > 4 || b.createdBy.length > 4) {
            // console.log("if");
            oneA = a.createdBy.slice(0, 4);
            bTwo = b.createdBy.slice(0, 4);
            // MyService.ConsoleLog("ass", asc + "   " + bss);
            return bTwo - oneA;
          }
        });
      }
    };

    $scope.getDetailsOfBallot();


  }]);
