"use strict";
angular
  .module("myApp.topic", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/topic/:categoryId/:subcatId", {
        templateUrl: "app/topic/topic.html",
        controller: "topicCtrl",
      });
    },
  ])
  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/topic-politician/:uId?", {
        templateUrl: "app/topic/topic.html",
        controller: "topicCtrl",
      });
    },
  ])
  .controller("topicCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "growl",
    "tokenService",
    "MyService",
    "ngDialog",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      growl,
      tokenService,
      MyService,
      ngDialog
    ) {
      $scope.TopicButton = false;
      $rootScope.welcome = false;
      if (tokenService.getUserData()) {
        $scope.uData = JSON.parse(tokenService.getUserData());
      }
      $scope.uId = $routeParams.uId;
      if (window.localStorage.getItem("politicianThreadData") != "") {
        $rootScope.politicianThreadData = JSON.parse(
          window.localStorage.getItem("politicianThreadData")
        );
      }
      $scope.isDefaultSelected = false;
      $scope.subCatArr = [];
      $scope.location = ["National", "State", "County", "Local"];
      $scope.ShowLocationOptions = false;
      $scope.isNational = "National (USA)";
      $scope.Topic = {
        title: "",
        description: "",
        location: "National",
        restrictedTo: "No Restrictions",
      };
      $scope.restrictedOptions = [
        "Locals Only",
        "My County",
        "My State",
        "National",
        "No Restrictions",
      ];

      var current_url = $location.url();
      if (current_url.indexOf("topic-politician") > -1) {
        $scope.curState = "topic-politician";
      } else {
        $scope.curState = "topic";
      }
      $scope.mainCatIds = [];
      $scope.mainCatIds.push($routeParams.categoryId);
      if ($routeParams.categoryId && $routeParams.subcatId) {
        $scope.subCatArr.push($routeParams.categoryId);
        $scope.subCatArr.push($routeParams.subcatId);
      } else if ($routeParams.categoryId) {
        $scope.subCatArr.push($routeParams.categoryId);
      } else {
        $scope.subCatArr.push($routeParams.subcatId);
      }
      console.log("$scope.subCatArr :>> ", $scope.subCatArr);

      /*
    if ((!$routeParams.categoryId) && (!$routeParams.subcatId)) {
        $location.path('debateHome');
    }
    */
      //MyService.ConsoleLog("$routeParams.categoryId: ", $routeParams.categoryId, " $rootScope.politicianThreadData: ", $rootScope.politicianThreadData);
      if (!$routeParams.categoryId && $rootScope.politicianThreadData) {
        // alert('sjs');
        angular.forEach(
          $rootScope.politicianThreadData.users,
          function (val, key) {
            //MyService.ConsoleLog("key: ", key);
            if (($scope.curState = "topic-politician")) {
              val.selected = true;
            } else {
              if (
                val._id == $rootScope.politicianThreadData.selected_user._id
              ) {
                val.selected = true;
              }
            }
          }
        );
      }

      if (window.localStorage.getItem("VoteWiseCategory")) {
        $rootScope.allcategories = JSON.parse(
          window.localStorage.getItem("VoteWiseCategory")
        );
        MyService.ConsoleLog(
          "$rootScope.allcategories",
          $rootScope.allcategories
        );
        var chklen = 0;
        $scope.previouscatId = $rootScope.allcategories[chklen];
        if ($rootScope.allcategories.length >= 2) {
          $scope.mainCatIds.push($scope.previouscatId);
        }

        MyService.ConsoleLog("$scope.previouscatId", $scope.previouscatId);
        var idx = $rootScope.allcategories.indexOf($routeParams.subcatId);
        if (idx == -1) {
          $rootScope.allcategories.push($routeParams.subcatId);
          window.localStorage.setItem(
            "VoteWiseCategory",
            JSON.stringify($rootScope.allcategories)
          );
        }
      }
      //MyService.ConsoleLog("Final categories : ", window.localStorage.getItem("VoteWiseCategory"));
      $scope.subtopics = {
        subcategories: [],
      };

      $scope.getParentId = function (childId) {
        MyService.getParentCat({
          id: childId,
        }).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse", APIResponse);
            if (APIResponse && APIResponse.data.success) {
              var cobj = new Object();

              cobj.path =
                "," +
                APIResponse.data.data.parentIds[0].pid +
                "," +
                childId +
                ",";
              cobj.pid = childId;
              cobj.viewOrder = 1;
              $scope.SelectedTree.push(cobj);

              // APIResponse.data.data.parentIds[0].pid;
            } else {
              cobj.pid = childId;
              cobj.viewOrder = 1;

              cobj.path = "," + childId + ",";
              $scope.SelectedTree.push(cobj);
            }
            MyService.ConsoleLog("second", $scope.SelectedTree);
          },
          function (err) {
            MyService.ConsoleLog("err", err);
          }
        );
      };

      $scope.selectedCat = [];
      $scope.SelectedTree = [];
      $scope.disabled = [];
      $scope.isGeneral = false;
      $scope.getVal = function (data) {
        MyService.ConsoleLog(" getVal :", data);

        var idx = $scope.selectedCat.indexOf(data);
        if (idx > -1) {
          $scope.selectedCat.splice(idx, 1);
          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var jdx = $scope.SelectedTree.indexOf(data.parentIds);
          if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
        } else {
          // MyService.ConsoleLog("ss")
          $scope.selectedCat.push(data);
          if (data && data.subcategories && data.subcategories.length > 0) {
            data.subcategories.filter(function (itm) {
              var inx = itm.title.indexOf("General");
              if (inx > -1) {
                MyService.ConsoleLog("itm", itm);
                $scope.mainCatIds.push(data._id);
                $scope.isGeneral = true;
                $scope.subId = itm._id;
                if (itm && itm.subcategories && itm.subcategories.length > 0) {
                  $scope.superSub = itm.subcategories[0];
                }
                $scope.selectedCat.push(itm);
              }
            });
          }
          MyService.ConsoleLog("mainCatIds", $scope.mainCatIds);
          var id = $scope.disabled.indexOf(data._id);
          if (id == -1) {
            $scope.disabled.push(data._id);
          }
          MyService.ConsoleLog("paren", data.parentIds);
          MyService.ConsoleLog("SelectedTree: ", $scope.SelectedTree);
          if ($scope.SelectedTree.length == 0) {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              //angular.copy(data.parentIds, $scope.SelectedTree);
              var parents = data.parentIds;
              //MyService.ConsoleLog(parents);
              $scope.SelectedTree = parents;
            } else {
              MyService.ConsoleLog(" $scope.subId ", $scope.subId);
              var cobj = new Object();
              if ($scope.isGeneral == true && $scope.subId != "") {
                cobj.pid = data._id;
                cobj.viewOrder = 1;
                if ($scope.superSub) {
                  cobj.path =
                    "," +
                    data._id +
                    "," +
                    $scope.subId +
                    "," +
                    $scope.superSub._id +
                    ",";
                } else {
                  cobj.path = "," + data._id + "," + $scope.subId + ",";
                }
                // cobj.path = "," + data._id + "," + $scope.subId + ",";
                $scope.SelectedTree.push(cobj);
              } else {
                $scope.getParentId(data._id);
                // cobj.pid = data._id;
                // cobj.viewOrder = 1;

                // cobj.path = "," + data._id + ",";
                // $scope.SelectedTree.push(cobj);
              }

              //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
            }
          } else {
            if (data.parentIds.length > 0) {
              //var parents = Object.keys(data.parentIds).map(function (key) {return data.parentIds[key]});
              var parents = angular.copy(data.parentIds);
              //var parents = data.parentIds;
              var plen = parents.length;
              for (var j = 0; j < plen; j++) {
                var jdx = indexOfExt($scope.SelectedTree, parents[j]);
                //MyService.ConsoleLog(jdx);
                if (jdx == -1) {
                  $scope.SelectedTree.push(parents[j]);
                  // MyService.ConsoleLog("parents[j]",parents[j]);
                  if ($scope.isGeneral == true && $scope.subId != "") {
                    var obj = {};
                    obj.pid = data._id;
                    obj.viewOrder = 1;
                    obj.path = "," + data._id + "," + $scope.subId + ",";
                    var sjdx = indexOfExt($scope.SelectedTree, obj);
                    if (sjdx == -1) {
                      MyService.ConsoleLog("Obj", obj);
                      $scope.SelectedTree.push(obj);
                    }
                  }
                }
              }
            } else {
              var cobj = new Object();
              cobj.pid = data._id;
              cobj.viewOrder = 1;

              if ($scope.isGeneral == true && $scope.subId != "") {
                // cobj.path = "," + data._id + "," + $scope.subId + ",";
                // MyService.ConsoleLog("is", cobj.path);
                if ($scope.superSub) {
                  cobj.path =
                    "," +
                    data._id +
                    "," +
                    $scope.subId +
                    "," +
                    $scope.superSub._id +
                    ",";
                } else {
                  cobj.path = "," + data._id + "," + $scope.subId + ",";
                }
                MyService.ConsoleLog("is", cobj.path);
                $scope.SelectedTree.push(cobj);
              } else {
                MyService.ConsoleLog("issssss");
                // cobj.path = "," + data._id + ",";
                $scope.getParentId(data._id);
              }

              //"pid":"57987dfb31411a7f224cf01e","viewOrder":6,"path":",57987dfb31411a7f224cf01e,"
            }
          }
        }
        MyService.ConsoleLog("$scope.SelectedTree", $scope.SelectedTree);
      };

      $scope.selectedAll = {
        id: [],
      };
      $scope.removeVal = function (data) {
        var idx = $scope.selectedCat.indexOf(data);
        MyService.ConsoleLog("data", data);
        MyService.ConsoleLog("idx", idx);
        if (idx > -1) {
          if ($scope.selectedCat[idx].title.indexOf("General") > -1) {
            $scope.selectedCat.filter(function (itms, ind) {
              if (itms._id == $scope.selectedCat[idx]._id) {
                MyService.ConsoleLog("yes", ind);
                // $scope.selectedCat.splice(ind, 1);
              }
            });
            var indx = $scope.selectedCat.indexOf(data);
            $scope.selectedCat.splice(indx, 1);
          } else {
            $scope.selectedCat.splice(idx, 1);
          }

          var id = $scope.disabled.indexOf(data._id);
          $scope.disabled.splice(id, 1);
          var parents = data.parentIds;

          var plen = parents.length;
          for (var j = 0; j < plen; j++) {
            var jdx = $scope.SelectedTree.indexOf(parents[j]);
            if (jdx > -1) $scope.SelectedTree.splice(jdx, 1);
          }
        }
        $scope.selectedAll.id.length = 0;
        //MyService.ConsoleLog($scope.SelectedTree);
        var allLen = $scope.SelectedTree.length;
        var chkboxe = document.getElementsByName("checkedidcat");
        for (var i = 0; i < allLen; i++) {
          chkboxe[i].checked = true;
        }
      };

      $scope.selectConfig = {
        requiredMin: 1,
        selectAll: true,
      };

      function areEqual() {
        var len = arguments.length;
        for (var i = 1; i < len; i++) {
          if (arguments[i] == null || arguments[i] != arguments[i - 1])
            return false;
        }
        return true;
      }

      function indexOfExt(list, item) {
        var len = list.length;

        for (var i = 0; i < len; i++) {
          var keys = Object.keys(list[i]);
          var flg = true;
          var arr = [];
          for (var j = 0, s = 1; j < keys.length; j++, s++) {
            var value = list[i][keys[j]];
            //MyService.ConsoleLog("value :" + value + "-----Item : " + item[keys[j]]);
            //MyService.ConsoleLog(typeof value + "===" + typeof item[keys[j]]);
            if (typeof value != "object" && typeof item[keys[j]] != "object") {
              if (item[keys[j]] !== value) {
                flg = false;
                //MyService.ConsoleLog("flg : ", typeof item[keys[j]], typeof value);
              }
            }
            if (item[keys[j]] == value) {
              arr.push(flg);
            } else if (
              typeof value == "object" &&
              typeof item[keys[j]] == "object"
            ) {
              arr.push(flg);
            }
          }
          if (flg == true) {
            //MyService.ConsoleLog(arr.length + "-----" + keys.length + "-----" + Object.keys(item).length);
            if (areEqual(arr.length, keys.length, Object.keys(item).length)) {
              return i;
            }
          }
        }
        return -1;
      }

      $scope.checksubCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.pid) {
            var idx = indexOfExt($scope.selectedCat, val);

            if (idx == -1) {
              $scope.selectedCat.push(val);
            }
            return rdata;
          }
        });
      };

      $scope.checkCatExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata._id == val) {
            //MyService.ConsoleLog("parentIds : ", rdata.parentIds.length);
            if (rdata.parentIds.length > 0) {
              var parents = rdata.parentIds.length;
              for (var j = 0; j < parents; j++) {
                var newd = angular.copy(rdata.parentIds[j]);
                //MyService.ConsoleLog("newd:", newd);
                //MyService.ConsoleLog("SelectedTree:", $scope.SelectedTree);
                var jdx = indexOfExt($scope.SelectedTree, newd);
                //MyService.ConsoleLog(jdx);
                if (jdx == -1) {
                  $scope.SelectedTree.push(newd);
                }
              }
            }
            if (rdata.parentIds.length > 1) {
              var idx = indexOfExt($scope.selectedCat, angular.copy(rdata));
              if (idx == -1) {
                $scope.selectedCat.push(angular.copy(rdata));
              }
            } else {
              $scope.selectedCat.push(angular.copy(rdata));
            }
            return rdata;
          } else if (rdata.subcategories) {
            $scope.checkCatExists(rdata.subcategories, val);
          }
        });
      };

      $scope.getSubcatTree = function () {
        $scope.selectedCat.length = 0;
        $scope.EssayButton = true;
        var types;
        if ($scope.curState == "topic-politician") {
          types = "F,H";
        } else {
          types = "F";
        }
        MyService.getAllCatTree({
          type: types,
        }).then(
          function (payload) {
            $scope.subtopics.subcategories.length = 0;
            var subcatdata = payload;
            // MyService.ConsoleLog("subcatdata : ", subcatdata);
            $scope.disabled.length = 0;
            //$scope.disabled.push(window.localStorage.getItem("VoteWiseParentCategory"));
            $scope.subtopics.subcategories = subcatdata;
            if ($routeParams.categoryId) {
              $scope.disabled = JSON.parse(
                window.localStorage.getItem("VoteWiseCategory")
              );
              //MyService.ConsoleLog("subcat : ", $scope.disabled);
              var selectedCat = JSON.parse(
                window.localStorage.getItem("VoteWiseCategory")
              );
              if (selectedCat.length > 0) {
                for (var s = 0; s < selectedCat.length; s++) {
                  var checkcatdata = $scope.checkCatExists(
                    subcatdata,
                    selectedCat[s]
                  );
                }
                //MyService.ConsoleLog($scope.selectedCat);
                //$scope.SelectedTree = angular.copy($scope.selectedCat);
              }
              $scope.EssayButton = false;
              setTimeout(function () {
                var cols = document.getElementsByClassName("cmnUL");
                var clen = cols.length;
                for (var i = 0; i < clen; i++) {
                  cols[i].style.display = "none";
                }
              }, 300);
            } else {
              MyService.ConsoleLog("* Inside else *");
              angular.forEach(
                $scope.subtopics.subcategories,
                function (val, key) {
                  if (
                    val.title &&
                    val.title.toLowerCase() == "politician review"
                  ) {
                    $scope.disabled = [val._id];
                    // var checkcatdata = $scope.checkCatExists(subcatdata, val);
                    $scope.selectedCat.push(val);
                  }
                }
              );
            }
            MyService.ConsoleLog("$scope.selectedCat", $scope.selectedCat);
            var tmp = $scope.selectedCat.filter(function (itm, ind) {
              return (
                ind ==
                $scope.selectedCat.findIndex(function (ele) {
                  return JSON.stringify(ele) == JSON.stringify(itm);
                })
              );
            });
            MyService.ConsoleLog("tmp", tmp);
            $scope.selectedCat = "";
            $scope.selectedCat = tmp;
          },
          function (errorPayload) {
            alert("Something went wrong ");
          }
        );
      };

      $scope.showSubCat = true;
      $scope.showLoading = [];
      $scope.showLoadingCounty = [];
      $scope.showDown = [];
      $scope.ChangeShow = function (id) {
        //$scope.showSubCat = true;
        //var cols = document.getElementsByClassName('cmnUL');
        //var clen = cols.length;
        var nclass = "MainCatLi_" + id;
        if (
          document.getElementsByClassName(nclass)[0].style.display &&
          document.getElementsByClassName(nclass)[0].style.display == "none"
        ) {
          document.getElementsByClassName(nclass)[0].style.display = "block";
        } else {
          document.getElementsByClassName(nclass)[0].style.display = "none";
        }
      };

      $scope.AddLocation = false;
      $scope.newlocation = {
        country: "",
      };
      $scope.Allcountries = [];
      $scope.SelectedCountry = "";

      $scope.ShowAddLocation = function () {
        if (!$scope.AddLocation) {
          $scope.AddLocation = true;
        } else {
          $scope.AddLocation = false;
        }
      };

      function suggest_country(term) {
        var q = term.toLowerCase().trim();
        var results = [];
        var len = $scope.Allcountries.length;
        // Find first 10 states that start with `term`.
        for (var i = 0; i < len && results.length < 10; i++) {
          var country = $scope.Allcountries[i];
          if (country.toLowerCase().indexOf(q) === 0)
            results.push({
              label: country,
              value: country,
            });
        }
        return results;
      }

      $scope.autocomplete_options = {
        suggest: suggest_country,
      };

      $scope.setByDefaultState = function (data) {
        data.filter(function (itms) {
          if (itms.state.indexOf($scope.uData.address.state) > -1) {
            MyService.ConsoleLog("itms", itms);

            var nobj = {
              country: "National (USA)",
            };
            $scope.nwStateFips = itms.stateFips;
            $scope.selectedArea.push(nobj);
            $scope.selectedArea.push(itms);
            $scope.disabledLocation.push(itms);
            $scope.getCounties(itms.stateFips);
          }
        });
      };

      $scope.getAllStates = function () {
        MyService.getAllStates().then(
          function (payload) {
            $scope.allLocations.length = 0;
            var subcatdata = payload;
            MyService.ConsoleLog("countires : ", subcatdata);
            $scope.allLocations = subcatdata;
            if ($scope.isDefaultSelected) {
              $scope.setByDefaultState($scope.allLocations);
            }
          },
          function (errorPayload) {
            alert("Something went wrong ");
          }
        );
      };
      $scope.getAllStates();

      // var fetchPoliticiansByStateCanceler = $q.defer();
      $scope.statusfetchPoliticiansByState = 0;
      $scope.fetchPoliticiansByState = function (selState, idx) {
        //MyService.ConsoleLog("selState: ", selState);
        // fetchPoliticiansByStateCanceler.resolve();
        // fetchPoliticiansByStateCanceler = $q.defer();
        // $scope.statusfetchPoliticiansByState = 1;
        // $scope.politicians = [];
        //MyService.ConsoleLog("selState.loadPolitician: ", selState.loadPolitician);
        if (selState.loadPolitician == false) {
          //MyService.ConsoleLog("* this is false *");
          $scope.allLocations[idx].politicians = [];
          return;
        }
        $scope.allLocations[idx].getPolStatus = 1;
        MyService.getPoliticianLocWise(
          {
            statePostalCode: selState.statePostalCode,
          },
          null
        ).then(
          function (response) {
            if (response.data.success) {
              // $scope.politicians = response.data.data;
              // $scope.statusfetchPoliticiansByState = 2;
              $scope.allLocations[idx].politicians = response.data.data;
              $scope.allLocations[idx].getPolStatus = 2;
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              growl.error("Error: " + response.data.error);
              // $scope.statusfetchPoliticiansByState = 3;
              $scope.allLocations[idx].getPolStatus = 3;
            } else {
              growl.error("Something went wrong.");
              // $scope.statusfetchPoliticiansByState = 3;
              $scope.allLocations[idx].getPolStatus = 3;
            }
          },
          function (err) {
            growl.error("Something went wrong.");
            // $scope.statusfetchPoliticiansByState = 3;
            $scope.allLocations[idx].getPolStatus = 3;
          }
        );
      };
      $scope.selectUsersFromGroup = function (usres, flag) {
        angular.forEach(usres, function (val, key) {
          val.selected = flag;
        });
      };

      $scope.allLocations = [];
      $scope.allCounties = [];
      $scope.selectedArea = [];
      $scope.rmselectedArea = [];
      $scope.myselectionarea = [];
      $scope.disabledLocation = [];

      $scope.checkExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.state == val) return rdata;
        });
      };

      $scope.checkCountyExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.county == val) return rdata;
        });
      };

      $scope.checkCountryExists = function (rdata, val) {
        return rdata.filter(function (rdata) {
          if (rdata.country == val) return rdata;
        });
      };

      $scope.checkDisabled = function (data, stateId, type) {
        data.country = "United States of America";
        data.state = stateId;

        var idx = indexOfExt($scope.disabledLocation, data);
        if (idx > -1) return true;
        else return false;
      };

      $scope.getAreaVal = function (data, stateId, type) {
        if (!data.country) {
          data.country = "United States of America";
        }
        if (!data.state) {
          data.state = stateId;
        }

        var idx = indexOfExt($scope.selectedArea, data);

        if (idx > -1) {
          alert("You have already selected the location");
        } else {
          $scope.selectedArea.push(data);
          $scope.disabledLocation.push(data);
        }

        MyService.ConsoleLog(
          "$scope.disabledLocation",
          $scope.disabledLocation
        );
        MyService.ConsoleLog("$scope.selectedArea", $scope.selectedArea);
      };
      $scope.selectedLAll = {
        id: [],
      };
      $scope.removeAreaVal = function (data) {
        var idx = $scope.selectedArea.indexOf(data);

        //MyService.ConsoleLog($scope.Topic.Locations);
        if (idx > -1) {
          //MyService.ConsoleLog("Country:",$scope.selectedArea[idx].country);
          if ($scope.selectedArea[idx].country == "International") {
            $scope.Topic.Locations = "NO";
          }
          if ($scope.selectedArea[idx].country == "National (USA)") {
            $scope.Topic.National = "NO";
          }
          $scope.selectedArea.splice(idx, 1);
          var jdx = $scope.disabledLocation.indexOf(data);
          if (jdx > -1) {
            $scope.disabledLocation.splice(jdx, 1);
          }
        }
        $scope.selectedLAll.id.length = 0;
        //MyService.ConsoleLog($scope.selectedArea);
        var allLen = $scope.selectedArea.length;
        var chkboxe = document.getElementsByName("checkedid");
        for (var i = 0; i < allLen; i++) {
          chkboxe[i].checked = true;
        }
      };
      $scope.allCounties = [];
      $scope.allLocals = [];
      $scope.setLocation = function (option) {
        $scope.Essay.location = option;
      };
      $scope.getLocations = function (val, type) {
        if (type == "National") {
          if (val == "National") {
            var nobj = {
              country: "National (USA)",
            };
            $scope.selectedArea.push(nobj);
            //MyService.ConsoleLog($scope.selectedArea);
          }
          if (val == "NO") {
            var nobj = {
              country: "National (USA)",
            };
            var idx = indexOfExt($scope.selectedArea, nobj);
            if (idx > -1) {
              $scope.selectedArea.splice(idx, 1);
            }
          }
        } else if (type == "International") {
          if (val == "International") {
            // $scope.selectedArea.length = 0;
            var nobj = {
              country: "International",
            };
            $scope.selectedArea.push(nobj);
            MyService.ConsoleLog("$scope.selectedArea", $scope.selectedArea);
          }
          if (val == "NO") {
            // $scope.selectedArea.length = 0;
            var nobj = {
              country: "International",
            };
            var idx = indexOfExt($scope.selectedArea, nobj);
            if (idx > -1) {
              $scope.selectedArea.splice(idx, 1);
            }
          }
        }
      };

      $scope.setByDefaultCounty = function (cdata) {
        // MyService
        cdata.filter(function (count) {
          if (count.countyName.indexOf($scope.uData.address.county) > -1) {
            $scope.selectedArea.push(count);
            $scope.disabledLocation.push(count);
            $scope.getLocals($scope.nwStateFips, count.countyFips);
          }
        });
      };

      $scope.AddCountry = function () {
        if ($scope.newlocation.country && $scope.newlocation.country != "") {
          var idx = $scope.Allcountries.indexOf($scope.newlocation.country);
          if (idx > -1) {
            var nobj = {
              country: $scope.newlocation.country,
            };
            $scope.selectedArea.push(nobj);
          } else {
            alert(
              "Country does not exists in our database. Please check if you have entered correct name."
            );
          }
        } else {
          alert("Please enter country name");
        }
      };

      $scope.getCounties = function (state) {
        console.log(state);
        $scope.showLoading.push(state);
        if (
          !$scope.allCounties[state] ||
          $scope.allCounties[state].length == 0
        ) {
          MyService.getAllCounties(state).then(
            function (payload) {
              $scope.showLoading.length = 0;
              var sublocations = payload;
              if (!$scope.allCounties) {
                $scope.allCounties = [];
              }
              var upCounty = sublocations.filter(function (coun) {
                coun.county = "";
                coun.county = coun.countyName;
                return coun;
              });
              $scope.allCounties[state] = upCounty;

              if ($scope.isDefaultSelected) {
                $scope.setByDefaultCounty(sublocations);
              }
            },
            function (errorPayload) {
              alert("Something went wrong.");
            }
          );
        } else {
          $scope.showLoading.length = 0;
          $scope.allCounties[state].length = 0;
        }
      };
      $scope.setByDefaultLocal = function (ldata) {
        // MyService
        ldata.filter(function (local) {
          if (local.placeName.indexOf($scope.uData.address.place) > -1) {
            $scope.selectedArea.push(local);
            $scope.disabledLocation.push(local);
          }
        });
      };

      $scope.getLocals = function (state, county) {
        $scope.showLoadingCounty.push(county);
        if (
          !$scope.allLocals[state] ||
          !$scope.allLocals[state][county] ||
          $scope.allLocals[state][county].length == 0
        ) {
          MyService.getAllSubCountiesLocal(state, "", county).then(
            function (payload) {
              $scope.showLoadingCounty.length = 0;
              MyService.ConsoleLog("payload Locals : ", payload);
              if (!$scope.allLocals[state]) {
                $scope.allLocals[state] = [];
              }
              var upPlaces = payload.places.filter(function (pla) {
                pla.place = "";
                pla.place = pla.placeName;
                return pla;
              });
              // MyService.ConsoleLog("upPlaces", upPlaces);
              $scope.allLocals[state][county] = upPlaces;
              if ($scope.isDefaultSelected) {
                $scope.setByDefaultLocal(payload.places);
              }
            },
            function (errorPayload) {
              alert("Something went wrong ");
            }
          );
        } else {
          $scope.showLoadingCounty.length = 0;
          $scope.allLocals[state][county].length = 0;
        }
      };

      $scope.ShowLocation = function () {
        $scope.ShowLocationOptions = true;
        $scope.isDefaultSelected = true;
        MyService.getAllCountries().then(
          function (payload) {
            $scope.Allcountries = payload;
            // MyService.ConsoleLog("$scope.Allcountries", $scope.Allcountries);
          },
          function (errorPayload) {
            alert("Something went wrong ");
          }
        );
        $scope.getAllStates();
      };

      $scope.SubmitTopic = function (data) {
        if ($.fn.validateForceFully($("#topicform")) == true) {
          //$scope.TopicButton = true;
          MyService.ConsoleLog("first", $scope.disabled);
          data.parentcat = JSON.stringify($scope.disabled);
          if ($scope.curState == "topic-politician" && $scope.disabled <= 0) {
            growl.error(
              "There must be a root category to with title 'Politician Review'."
            );
            return;
          }
          var subcat = [];
          if ($scope.curState == "topic-politician" && $scope.disabled <= 0) {
            subcat = JSON.stringify($scope.disabled);
          } else {
            subcat = JSON.stringify($scope.SelectedTree);
          }

          data.subcategories = subcat;
          data.createdById = tokenService.getUserId();
          data.createdByName = tokenService.getUserName();
          data.createdByType = tokenService.getUserRole();
          if ($scope.selectedArea.length > 0) {
            var obj = {};
            var smplData = $scope.selectedArea.filter(function (sel, idx) {
              if (sel.state) {
                obj.country = sel.country;
                obj.state = sel.state;
              }
              if (sel.county) {
                obj.county = sel.county;
              }
              if (sel.place) {
                obj.place = sel.place;
                obj.placeName = sel.place;

                // MyService.ConsoleLog("obj", obj);
                // MyService.ConsoleLog("idx", idx);
                $scope.selectedArea[idx] = "";
                $scope.selectedArea[idx] = obj;
                MyService.ConsoleLog("cheek plz", $scope.selectedArea[idx]);
              }

              //
            });
            data.location = JSON.stringify($scope.selectedArea);
          } else {
            var arr = [];
            var acountry = "";
            if ($scope.uData) {
              if ($scope.uData.address.country == null) {
                if ($scope.isNational) {
                  acountry = $scope.isNational;
                } else {
                  acountry = "United States of America";
                }
              } else {
                acountry = $scope.uData.address.country;
              }
              if (acountry.indexOf("National (USA)") > -1) {
                var objNw = {
                  country: acountry,
                  state: null,
                  statePostalCode: null,
                  county: null,
                  countySubdivision: null,
                  place: null,
                };

                arr.push(objNw);
                var objState = {
                  country: "United States of America",
                  state: $scope.uData.address.state,
                  statePostalCode: $scope.uData.address.statePostalCode,
                  county: null,
                  countySubdivision: null,
                  place: null,
                };
                arr.push(objState);
                var objCounty = {
                  country: "United States of America",
                  state: $scope.uData.address.state,
                  statePostalCode: $scope.uData.address.statePostalCode,
                  county: $scope.uData.address.county,
                  countySubdivision: null,
                  place: null,
                };
                arr.push(objCounty);
                var obj = {
                  country: acountry,
                  state: $scope.uData.address.state,
                  statePostalCode: $scope.uData.address.statePostalCode,
                  county: $scope.uData.address.county,
                  countySubdivision: $scope.uData.address.countySubdivision,
                  place: $scope.uData.address.place,
                };
                arr.push(obj);
              } else {
                var obj = {
                  country: acountry,
                  state: $scope.uData.address.state,
                  statePostalCode: $scope.uData.address.statePostalCode,
                  county: $scope.uData.address.county,
                  countySubdivision: $scope.uData.address.countySubdivision,
                  place: $scope.uData.address.place,
                };
                arr.push(obj);
              }

              data.location = JSON.stringify(arr);
            }
          }

          //MyService.ConsoleLog(data);
          //MyService.ConsoleLog($scope.selectedArea);

          var tempPoliticianUids = [];
          try {
            /*angular.forEach($scope.allLocations, function(state, key){
              if(state.politicians){
                  angular.forEach(state.politicians, function(pol, i){
                      if(pol.selected){
                          tempPoliticianUids.push(pol._id);
                      }
                  });
              }
          });*/
            //MyService.ConsoleLog("catch: ", $rootScope.politicianThreadData.users.length);
            angular.forEach(
              $rootScope.politicianThreadData.users,
              function (pol, key) {
                //MyService.ConsoleLog("key: ", key);
                if (pol.selected) {
                  tempPoliticianUids.push(pol._id);
                }
              }
            );
          } catch (e) {
            //MyService.ConsoleLog("politicians thread exception: ", e);
          }
          if (
            $scope.curState == "topic-politician" &&
            tempPoliticianUids.length <= 0
          ) {
            growl.error("Please select at least one politician.");
            return;
          }
          data.taggedUsers = tempPoliticianUids.join(",");
          MyService.ConsoleLog("data: ", data);
          // return;
          var request = apiCall.apiCall("POST", "/topics/create", data);
          $http(request).then(
            function successCallback(response) {
              MyService.ConsoleLog("Got categories: ", response);
              $scope.TopicButton = true;
              if (response.data.success) {
                if (!window.localStorage.getItem("VoteWiseParentCategory")) {
                  MyService.ConsoleLog(
                    "response.data.data",
                    response.data.data
                  );
                  MyService.ConsoleLog(response.data.data.parentCat[0]);
                  if ($routeParams.categoryId && $routeParams.subcatId) {
                    $location.path(
                      "/forum/" + response.data.data.parentCat[0] + "/home"
                    );
                  } else {
                    // alert('one');

                    $location.path("/politician-thread/" + $scope.uId);
                  }
                } else {
                  var cat =
                    window.localStorage.getItem("VoteWiseParentCategory") ||
                    null;
                  MyService.ConsoleLog(" window.localStorage.getIte", cat);
                  MyService.ConsoleLog(" window.localStorage.getIte", cat);

                  if (cat != "undefined") {
                    MyService.ConsoleLog("$$$$$$$$ IF $$$$$$$$$");
                    if ($routeParams.categoryId && $routeParams.subcatId) {
                      $location.path(
                        "/forum/" +
                          window.localStorage.getItem(
                            "VoteWiseParentCategory"
                          ) +
                          "/home"
                      );
                    } else {
                      // alert('two');

                      $location.path("/politician-thread/" + $scope.uId);
                    }
                  } else {
                    MyService.ConsoleLog("$$$$$$$$ ELSE $$$$$$$$$");
                    if ($routeParams.categoryId && $routeParams.subcatId) {
                      $location.path(
                        "/forum/" + response.data.data.parentCat[0] + "/home"
                      );
                    } else {
                      // alert('three');
                    }
                  }
                }
              } else {
                if (response.data.error) {
                  alert(response.data.error);
                  //MyService.ConsoleLog(response.data);
                  if (response.data.status && response.data.status == "405") {
                    var msg =
                      "Do you want to send request to Admin to reactivate your account?.";
                    MyService.GetDialogWithButtons(
                      ngDialog,
                      msg,
                      "YES",
                      "NO"
                    ).then(
                      function (payload) {
                        MyService.sendActivatationMailtoAdmin().then(
                          function (payload) {
                            alert("Request sent successfully to admin");
                          },
                          function (errorPayload) {
                            alert("Something went wrong");
                          }
                        );
                      },
                      function (errorPayload) {
                        //MyService.ConsoleLog(errorPayload);
                      }
                    );
                  }
                }
              }
            },
            function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
            }
          );
        }
      };

      $scope.goBack = function () {
        //MyService.ConsoleLog("redirect back page");
        var len = $rootScope.allcategories.length;
        var type = "home";
        //MyService.ConsoleLog("Length:", len);
        if (len > 2) {
          type = "subcat";
        }
        //MyService.ConsoleLog(type);
        //MyService.ConsoleLog($rootScope.allcategories[len - 2] + "=====" + $rootScope.allcategories[len - 1]);

        //MyService.ConsoleLog('forumList/' + $rootScope.allcategories[len - 2] + '/' + $rootScope.allcategories[len - 1] + "/" + type);
        $location.path(
          "forumList/" +
            $rootScope.allcategories[len - 2] +
            "/" +
            $rootScope.allcategories[len - 1] +
            "/" +
            type
        );
      };

      if (!$rootScope.UserId) {
        var message = "Please login first.";

        var modal = ngDialog.openConfirm({
          template:
            "<p>" +
            message +
            '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
          plain: true,
        });

        modal.then(
          function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            $location.path("signin");
          },
          function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            $location.path("signin");
          }
        );
      } else {
        $scope.getSubcatTree();
      }

      $("#topicform").validationEngine();
    },
  ]);
