'use strict';
angular.module('myApp.forgotpassword', ['ngRoute'])

.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/forgot-password/:token', {
        templateUrl: 'app/forgot-password/forgot-password.html',
        controller: 'forgotpasswordCtrl'
    });
}])

.controller('forgotpasswordCtrl', ['$scope', '$http', '$location', 'baseApiUrl', 'growl', '$routeParams', 'tokenService', '$rootScope', 'MyService', '$timeout', function ($scope, $http, $location, baseApiUrl, growl, $routeParams, tokenService, $rootScope, MyService, $timeout) {
	//MyService.ConsoleLog("* ForgotpasswordCtrl *");
$rootScope.welcome = false;
	$scope.resetPassword = function () {
        if ($.fn.validateForceFully($("#forgotPassForm")) == true) {
            var postobj = {
                password: $scope.forgotPassword,
                confirm_password: $scope.confirmPassword,
                reset_token: $routeParams.token
            };

            //MyService.ConsoleLog("postobj: ",postobj);
            
            MyService.resetPassword(postobj)
            .then(function(response){
                if(response.data.success){
                    growl.success("Password reset successfully");
                    $timeout(function(){
                		$location.path('/signin');
                	}, 1500);
                }
                else{
                    if(typeof response.data.error == "string")
                        growl.error(response.data.error);
                    else
                        growl.error("Something went wrong");
                }
            }, function(err){
                growl.error("Something went wrong");
            });
        }
    };

    $("form").validationEngine();
}]);