'use strict';
angular.module('myApp.commonPhrases', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/commonPhrases', {
        templateUrl: 'app/commonPhrases/commonPhrases.html',
        controller: 'commonPhrasesCtrl' //we can name anythin
    });
}])
.controller('commonPhrasesCtrl', ['$scope','$rootScope', function($scope, $rootScope) {
    //this is controller for sampepage.html, we link the conttoler to page in config defination, check above .config
    $scope.search = {
        text: ''
    }
    $scope.oldhtml = $('.match').html();
    $rootScope.welcome = false;
    $scope.searchTerm = function() {
        //MyService.ConsoleLog($scope.search.text)
        var oldhtml = $scope.oldhtml;
        if ($scope.search.text != "") {
            

            if ((oldhtml != "") && (oldhtml != undefined)) {
                var fOld = '<span class="match">' + oldhtml + '</span>'
                    //MyService.ConsoleLog("oldhtml:",fOld);
                    /*
    	$("#content").html($("#content").html()
        .replace(fOld, oldhtml));	
        */
                $("span.match").replaceWith(oldhtml);
            }
            $("#content").css({ "height": "550px", "overflow": "auto" })

            var searchTermRegEx = new RegExp($scope.search.text, "i");
            $("#content").html($("#content").html()
                .replace(searchTermRegEx, "<span class='match'>" + $scope.search.text + "</span>"));

            //to highlighted specific index 
            setTimeout(function() {
                //MyService.ConsoleLog("Timeout called");
                $("#content").scrollTo(".match:first");
            }, 100);
        } else {
            $("#content").removeAttr("style");
             //var oldhtml = $('.match').html();

            if ((oldhtml != "") && (oldhtml != undefined)) {
                var fOld = '<span class="match">' + oldhtml + '</span>'
                    //MyService.ConsoleLog("oldhtml:",fOld);
                    /*
    	$("#content").html($("#content").html()
        .replace(fOld, oldhtml));	
        */
                $("span.match").replaceWith(oldhtml);
            }
        }
        //$("#content").scrollTo('.match:first');
        //         $('#content').animate({
        //     scrollTop: $(".match:first").offset().top
        // }, 1000);



        //$("#elementtoScrollToID").offset().top
        //to work with index you need you var matches to know what indexes exist
        //$('.match').eq(3).addClass('highlighted');
    }


}]);
