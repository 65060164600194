'use strict';
angular.module('myApp.research', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/research', {
    templateUrl: 'app/research/research.html',
    controller: 'researchCtrl'
  }),
 
  $routeProvider.when('/boardsofelections', {
    templateUrl: 'app/boardsofelections/boardsofelections.html'
  });
}])

.controller('researchCtrl', ['$scope','$rootScope', '$http','$location','apiCall', 'baseApiUrl', 'MyService', 'growl', function ($scope, $rootScope, $http, $location, apiCall, baseApiUrl, MyService, growl) {
//MyService.ConsoleLog("LearnHome called");
  $scope.imgUrl = baseApiUrl+"/resources";
$rootScope.welcome = false;
  $scope.statusGetEssayCat = 0;
  function getCategories(params) {
    /*var request = apiCall.apiCall('GET', '/categories/list', {root: 1});
    $http(
      request
    ).then(function successCallback(response) {
    //MyService.ConsoleLog("Got categories: ",response);
      if(response.data.success){
        $scope.categories = response.data.data;
      }
      else{
        //MyService.ConsoleLog("Something went wrong: ",response.data);
      }
    }, function errorCallBack(response) {
      //MyService.ConsoleLog("Error: ",response);
    });*/
    $scope.categories = [];
    $scope.statusGetEssayCat = 1;
    
    MyService.getCategories({root: 1, catTypes: "E"}, null).then(function(response){
      //MyService.ConsoleLog(response);
      if(response.data.success){
        $scope.categories = response.data.data;
        $scope.statusGetEssayCat = 2;
      }
      else{
        //MyService.ConsoleLog("Error: ",response.error);
        growl.error("Something went wrong: ");
        $scope.statusGetEssayCat = 3;
      }
    }, function(){
      growl.error("Something went wrong while fetching categories.");
      $scope.statusGetEssayCat = 3;
    });
  }
  getCategories();
}])
.controller('CatSelPressEssayCtrl', ['$scope','$rootScope', '$http','$location','apiCall', 'baseApiUrl', function ($scope, $rootScope, $http, $location, apiCall, baseApiUrl) {
  //MyService.ConsoleLog("CatSelPressEssayCtrl called");
  $scope.imgUrl = baseApiUrl+"/resources";
  $rootScope.welcome = false;
  function getCategories(params) {
    var request = apiCall.apiCall('GET', '/categories/list', {root: 1});
    $http(
      request
    ).then(function successCallback(response) {
    //MyService.ConsoleLog("Got categories: ",response);
    if(response.data.success){
      $scope.categories = response.data.data;
    }
    else{
      //MyService.ConsoleLog("Something went wrong: ",response.data);
    }
    }, function errorCallBack(response) {
      //MyService.ConsoleLog("Error: ",response);
    });
  }
  getCategories();
}]);

