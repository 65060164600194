"use strict";

angular
  .module("myApp")
  .service("APIInterceptor", [
    "$rootScope",
    "$location",
    "$q",
    function ($rootScope, $location, $q) {
      var service = this;

      service.request = function (config) {
        config.headers = config.headers || {};
        var bwurl = config.url;

        // console.log(config.url + "======" + bwurl.indexOf("newsapi.org"));
        if (window.localStorage[tokenName] && bwurl.indexOf("newsapi") == -1) {
          //config.headers.Authorization = 'Bearer ' + $localStorage.token;
          //console.log("config>>>",config);
          //console.log(config.url);
          config.headers["x-access-token"] = window.localStorage[tokenName];
        }
        //if(bwurl.indexOf("newsapi") != -1)
        if (bwurl.indexOf("newsapi") > -1) {
          delete config.headers["x-access-token"];
        }
        if (bwurl.indexOf("melissadata") > -1) {
          delete config.headers["x-access-token"];
        }
        if (bwurl.indexOf("votesmart") > -1) {
          delete config.headers["x-access-token"];
        }

        // console.log(config.url + "======" + JSON.stringify(config.headers));
        return config;
      };
      service.response = function (response) {
        // console.log("Response", response);
        if (response && response.data) {
          if (
            response.data.error &&
            response.data.error == "TokenExpiredError"
          ) {
            var histroyPageUrl = $location.url();
            // console.log("histroyPageUrl:::",histroyPageUrl);
            window.localStorage.setItem("HistoryPage", histroyPageUrl);
            $rootScope.Logout();
          }
        }
        return response;
      };
      service.responseError = function (response) {
        if (response.status === 401 || response.status === 403) {
          // $location.path('/signin');
          var histroyPageUrl = $location.url();
          // console.log("histroyPageUrl:::",histroyPageUrl);
          window.localStorage.setItem("HistoryPage", histroyPageUrl);
          $rootScope.Logout();
        }
        return $q.reject(response);
      };
    },
  ])
  .factory("MyService", function ($http, $q, apiCall, showConsole, $rootScope) {
    var obj = {};
    obj.ConsoleLog = function (name, value) {
      if (showConsole) {
        if (value && value != "") console.log(name, value);
        else console.log(name);
      }
    };
    obj.getPayment = function (params) {
      // console.log("hello welcome", params)
      var deferred = $q.defer();

      var request = apiCall.apiCall("POST", "/user/getPayment", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllUsers = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAllUsers", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * SendVerificationMail
     */

    obj.SendVerificationMail = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/SendVerificationMail",
        params
      );
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            //var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /*OpenFEC API calls*/
    obj.getPlaceShape = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openfec/placeShape", params);
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            //var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getElectionsDate = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openfec/getElectionsDate", params);
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            //var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getopenFECCandidates = function (params, canceler) {
      var deferred = $q.defer();
      var currentYear = new Date().getFullYear();
      //params.year = currentYear;
      // console.log(params.page);
      var request = apiCall.apiCall("GET", "/openfec/getFECCandidates", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // var subcatdata = response.data.data;
            // console.log("candidites : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getCurrentFECCandidates = function (params) {
      var deferred = $q.defer();
      var currentYear = new Date().getFullYear();
      //params.year = currentYear;
      // console.log(params.pageno);
      var request = apiCall.apiCall("GET", "/openfec/openFECCandidates", {
        year: currentYear,
        pageno: params.pageno,
      });
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            //var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getopenFECCandidateDetails = function (params) {
      var deferred = $q.defer();
      var currentYear = new Date().getFullYear();
      var request = apiCall.apiCall("GET", "/openfec/openFECCandidateDetails", {
        id: params.cid,
      });
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            //var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getopenFECCandidateCommittees = function (params) {
      var deferred = $q.defer();
      var currentYear = new Date().getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/openfec/openFECCandidateCommittee",
        {
          id: params.cid,
        }
      );
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("data come");
            // var subcatdata = response.data.data;
            // console.log("countires : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllFECCommittees = function () {
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/openfec/openFECGetAllCommittee");
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            var subcatdata = response.data.data;
            // console.log("committee : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllFECCommitteesData = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/openfec/openFECGetAllCommitteeData",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.UpdatePassword = function (params) {
      // console.log("params", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updatePassword", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.searchUserByDetail = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/search", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.registerLogin = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/registerwithLogin", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * registerWithOrgs
     */
    obj.registerWithOrgs = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/registerWithOrgs", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /*
     * End of the registerWithOrgs
     */
    obj.searchUserByOrgDetail = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/search-by-org", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  search by Name

    obj.SearchByName = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openState/SearchByName", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // SearchByTitle

    obj.SearchByTitle = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openState/SearchByTitle", params);
      request.timeout = canceler;

      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //getFullText

    //   obj.getFullText = function(params) {
    //   var deferred = $q.defer();
    //   var request = apiCall.apiCall('GET', '/federalBills/getFullText', params);
    //   // request.timeout = canceler;

    //   $http(
    //     request
    //   ).then(function successCallback(response) {
    //     //console.log("Got countires: ", response);
    //     if (response.data.success) {
    //       var subcatdata = response.data.data;
    //       //console.log("countires : ", subcatdata);
    //       deferred.resolve(subcatdata);

    //     } else {
    //       //alert("Something went wrong: ", response.data);
    //       deferred.reject(response.data.error);
    //     }
    //   }, function errorCallBack(response) {
    //     //console.log("Error: ", response);
    //     deferred.reject(response);
    //   });
    //   return deferred.promise;
    // };

    // fedCommittees
    obj.fedCommittees = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/federalBills/fedCommittees");
      // request.timeout = canceler;

      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //

    obj.SearchByTitleOne = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/openState/SearchByTitleOne",
        params
      );
      request.timeout = canceler;

      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getAllEssay = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay/getAllEssay", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get Open Data

    obj.getOpenData = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/openState/openData", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  get Open State Bills

    obj.getBills = function () {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("POST", "/openState/getAllBills");
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //addFederal

    obj.addFederal = function (params) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/trackFederal/getData", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getDetails

    obj.getDetails = function (params) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/trackFederal/getFDetails", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getFederalData

    obj.getFederalData = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/federalTrack/getFederal", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //getTextBills

    obj.getTextBills = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/federalTrack/getTextBills",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get Votes Data

    // addorgQuestions

    obj.addorgQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/addorgQuestions",
        params
      );
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getVotesData = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/federalBills/getVotesData",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get open bills
    obj.getOpenBills = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/openState/getBills", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getBillDetail = function (params) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/openState/getBillDetails", params);
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // addFederalBills

    obj.addFederalBills = function (params) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/federalBills/getFederalBills",
        params
      );
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // addRollVotes

    obj.addRollVotes = function () {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/federalBills/addRollVotes");
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getVotesDetail

    obj.getVotesDetail = function (params) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/federalBills/getVoteDetail",
        params
      );
      // request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // SearchBills

    obj.SearchBills = function (params, canceler) {
      // console.log("cat_id", params.cid);
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // console.log("pagination", params.pageno);

      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/federalBills/SearchBills", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            // var subcatdata = response.data.data;
            // console.log("committee Data : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removeEssay = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/essay/removeEssay", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getSpamTopics = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/topics/getSpamTopics", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllTopics = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/topics/getAllTopics", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.notSpamTopic = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/topics/notSpamTopic", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.removeSpamTopic = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/topics/removeSpamTopic", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllPoliticians = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAllPoliticians", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removePoliticians = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/removePoliticians",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          var subcatdata = response;
          //console.log("countires : ", subcatdata);
          deferred.resolve(subcatdata);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * Get votesmart ques data
     */
    obj.getVotesmartData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/getAllVotesmartData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          var subcatdata = response;
          //console.log("countires : ", subcatdata);
          deferred.resolve(subcatdata);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Remove ballot measures
     */

    obj.removeBallotMeasures = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/elections/removeBallotMeasures",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          var subcatdata = response;
          //console.log("countires : ", subcatdata);
          deferred.resolve(subcatdata);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * Rate ballot measures
     */

    obj.rateBallotMeasures = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/reviews/rateBallotMeasures",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          var subcatdata = response;
          //console.log("countires : ", subcatdata);
          deferred.resolve(subcatdata);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // checkUserExist
    obj.checkUserExist = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/checkUserExistFor",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          var subcatdata = response;
          //console.log("countires : ", subcatdata);
          deferred.resolve(subcatdata);
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of checkUserExist
    obj.getAllOrganizations = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/office/getAllOrganizations",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.inactiveOrg = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/office/inactiveOrg", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.exportOrganizationData = function (params, canceler) {
      var deferred = $q.defer();
      //console.log(params);
      var request = apiCall.apiCall(
        "POST",
        "/office/exportOrganizations",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllOrganizationsPaginated = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/office/getAllOrganizationsPaginated",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updatePoliticianData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/updatePoliticianData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.exportPoliticianData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/exportPoliticianData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.generatePostCard = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/generatePostCard", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkMetaWithSSR = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/checkMetaWithSSR", params);
      // request.headers['Content-Type'] = 'text/html';
      $http(request).then(
        function successCallback(response) {
          console.log("Got countires: ", response);
          if (response.status == 200) {
            var subcatdata = response.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Function is use to export records of rating organizations.
     */
    obj.exportOrgRatingData = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/reviews/exportOrgRatingData");
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of exportOrgRatingData
     */

    /**
     * @description Function is use to export users and politicians records
     */
    obj.exportBoth = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/politician/exportBoth", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of exportBoth
     */

    /**
     * Get details of politicians
     */

    obj.getPoliDetails = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/getPoliDetails",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Function is use to search politician
     */
    obj.searchPoliticiansWith = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/searchPoliticiansWith",
        params
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;

            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End Of searchPoliticiansWith
     * */

    /**
     * Get details of the ballot measures
     */
    obj.getDetailsOfBallotMeasures = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getDetailsOfBallotMeasures",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Update ballot measures
     */
    obj.updateBallotMeasures = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/updateBallotMeasures",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.exportBallotMeasures = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/elections/exportBallotMeasures",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.registerPoliticianData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/autogeneratePolitician",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get Count of users

    obj.getUsersCount = function () {
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/user/userCount");
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            var subcatdata = response.data.data;
            // console.log("committee : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get user count stateWise

    // getGuestUsersAnswers

    obj.getGuestUsersAnswers = function (params) {
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getGuestUsersAnswers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            var subcatdata = response.data.data;
            // console.log("committee : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of getGuestUsersAnswers

    obj.getUsersCountStateWise = function (params) {
      // console.log("hello welcome to get all committee");
      var deferred = $q.defer();
      // var currentYear = (new Date()).getFullYear();
      var request = apiCall.apiCall("GET", "/user/userCountState", params);
      //request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response) {
            // console.log("hello welcome");

            var subcatdata = response.data.data;
            // console.log("committee : ", subcatdata);
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // Export Courage Data

    obj.ExportCourageData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/votequestions/exportCourageData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updateOfficeData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/office/updateOfficeData", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.registerAdvocateData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/autoGenerateAdvocates",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.addToMyFollow = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/office/addToMyFollow", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got addToMyFollow: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllUsersData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/getAllUsersData", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOrganizationQuesAnswers = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getOrganizationQuesAnswers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          /*
        if (response.data.success) {
        deferred.resolve(response.data.data);
        } else {
        deferred.reject(response.data);
        }
        */
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllCountries = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAllCountries", {});
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("countires : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllStates = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAllStates", params);
      $http(request).then(
        function successCallback(response) {
          // console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get votesmart Candiates
    obj.getVoteCandidates = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getCandidatesData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCityCouncilDistrict = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/getCityCouncilDistrict",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCandidatesWithParams = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/getCandidatesWithParams",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Function is use to insert records of presidential race.
     */

    obj.getPresidentData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getPresidentData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of getPresidentData
     */

    // getUsersCountCityWise
    obj.getUsersCountCityWise = function (params) {
      console.log("add-parents >>", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/getUsersCountCityWise",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get user cound county wise

    // getCity

    obj.getCity = function (params) {
      console.log("getCity >>", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getCity", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of getcity

    obj.getUsersCountyWise = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getUsersCountyWise", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of get user county wise

    // getStateLevelPoli

    obj.getStateLevelPoli = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getStateLevelPoli", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of getStateLevelPoli

    // get Open State People

    obj.getOpenStatePeopleData = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/openState/getPeople");
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getReviewCategory = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getReviewCategoryList",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description get Org list
     */
    obj.getOrgList = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/reviews/getOrgList", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // for courage Data

    obj.getCourageTest = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/getQuestions",
        params
      );
      $http(request).then(
        function successCallback(response) {
          // console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getTestData = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/getTestData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  get data for candidate

    obj.getCourageForCandidate = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/getQuestionsForCandidate",
        params
      );
      $http(request).then(
        function successCallback(response) {
          // console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // show in five match

    obj.UpdateFiveMatchCou = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/UpdateFiveMatch",
        params
      );
      $http(request).then(
        function successCallback(response) {
          // console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     *
     * @param {*}
     */
    obj.getRunningCandidateRecords = function () {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getRunningCandidateRecords");
      $http(request).then(
        function successCallback(response) {
          // console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // for localdata

    obj.getLocalOffices = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/localoffice/getCandidates",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getCandi

    obj.getCandi = function () {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/federalBills/getCandi");
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLocalOfficesForCity = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/localoffice/getCityCandidates",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getElectionData = function (params) {
      console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getElectionByYearState",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getIncumbentData = function (params) {
      console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getIncumbentByState",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCityCouncil = function (params) {
      console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/localoffice/getNewYorkCityCandidates",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got countires: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getAllCounties = function (statePostalCode, state) {
      var deferred = $q.defer();
      var params;
      if (statePostalCode == "") {
        params = state.stateFips;
      } else {
        params = {
          statePostalCode: statePostalCode,
          state: state,
        };
      }

      console.log("params", params);
      var request = apiCall.apiCall("POST", "/user/getAllCounties", params);
      $http(request).then(
        function successCallback(response) {
          console.log("Got getAllCounties: ", response);

          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations counties : ", sublocations);
              deferred.resolve(sublocations);
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllSubCountiesLocal = function (statePostalCode, county, state) {
      var deferred = $q.defer();
      var params = {};
      params.statePostalCode = statePostalCode;
      if (state) params.state = state;
      if (county) params.county = county;
      console.log("params", params);

      var request = apiCall.apiCall(
        "POST",
        "/user/getAllSubCountiesLocal",
        params
      );

      $http(request).then(
        function successCallback(response) {
          // console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    /*
     *
     * this service fetch data from the placesshape file
     * take three parameters statefifps county and state
     ***/

    obj.getPlacesAndSubCounty = function (statePostalCode, county, state) {
      var deferred = $q.defer();
      var params = {};
      params.statePostalCode = statePostalCode;
      if (state) params.state = state;
      if (county) params.county = county;
      console.log("params", params);

      var request = apiCall.apiCall(
        "POST",
        "/user/getPlacesAndSubCounty",
        params
      );

      $http(request).then(
        function successCallback(response) {
          // console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    // end of getPlacesAndSubCounty

    /*
     *
     * this service fetch data of congressional districts
     * take object of places
     ***/

    obj.getAllcogressionalData = function (params) {
      var deferred = $q.defer();

      var request = apiCall.apiCall(
        "GET",
        "/user/getAllcogressionalData",
        params
      );

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    // End of getAllcogressionalData

    // getStateHouseData

    obj.getStateHouseData = function (params) {
      var deferred = $q.defer();

      var request = apiCall.apiCall("GET", "/user/getStateHouseData", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    // getSchool
    obj.getSchool = function (params) {
      var deferred = $q.defer();

      var request = apiCall.apiCall("GET", "/user/getSchool", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    // End of getSchool

    obj.getAllPostalDetails = function (state, county, params) {
      var deferred = $q.defer();
      if (!params) params = {};
      if (state) params.statePostalCode = state;
      if (county) params.county = county;

      var request = apiCall.apiCall("GET", "/user/getAllPostalDetails", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    // get all data

    obj.getAllData = function (params) {
      var deferred = $q.defer();

      var request = apiCall.apiCall("GET", "/user/getAllAddDetails", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    };

    obj.getAllLocalDetails = function (state, county) {
      var deferred = $q.defer();
      var params = {};
      if (state) params.statePostalCode = state;
      if (county) params.county = county;
      var request = apiCall.apiCall("GET", "/user/getAllLocalDetails", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.setWinRecords = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/setWinRecords",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.savePreferences = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/savePreferences", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.removePreferences = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/removePreferences", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got sublocations: ", response);
          if (response.data.success) {
            //$scope.allCounties.length = 0;
            if (response.data.data) {
              var sublocations = response.data.data;
              //console.log("sublocations Locals : ", sublocations);
              deferred.resolve(sublocations);
              //console.log($scope.allLocals[state])
            }
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getHistoryPolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/getHistoryPolitician",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getGridHistory = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/getGridHistory",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // obj.removeGridHistory = function(params) {
    //     var deferred = $q.defer();
    //     var request = apiCall.apiCall('POST', '/politician/restorePolitician', params);
    //     $http(
    //         request
    //     ).then(function successCallback(response) {
    //         //console.log("Got subcategories: ", response);
    //         if (response.data.success) {
    //             deferred.resolve(response.data.data);
    //         } else {
    //             deferred.reject(response.data);
    //         }
    //     }, function errorCallBack(response) {
    //         deferred.reject(response);
    //     });
    //     return deferred.promise;
    // };
    obj.restorePolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/restorePolitician",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAllCatTree = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/getAllTree", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getProfile = function (userId) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/profile", {
        userId: userId,
      });
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getQuizNames
    obj.getQuizNames = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getQuizNames", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End Of getQuizNames

    /**
     * Quiz list
     */
    obj.getQuizList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getQuizList", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /** End of getQuizList */

    /**
     * Get List of categories which is used to create the category
     */
    obj.getQuizzesCategories = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getQuizzesCategories");
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of
     */

    /**
     * Add all the melisa data info into the table
     */
    obj.saveMelisaStripe = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/saveMelisaStripe", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of  saveMelisaStripe
     */

    /**
     * Get melisa Data
     */
    obj.getMeliSa = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getMeliSa", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of melisa data
     */

    /**
     * Block user
     */
    obj.makeInvisible = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/makeInvisible", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of makeInvisible
     */

    /**
     * Update the view order of the questions
     */
    obj.updateViewOrder = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/updateViewOrder",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got subcategories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of the updateViewOrder
     */

    obj.updateUserFollowList = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updateFollow", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            deferred.resolve(response.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.removeUserFollowList = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/removeFollow", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            deferred.resolve(response.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updateProfile = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updateProfile", data);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            deferred.resolve(response.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //updateAddress
    obj.updateAddress = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updateAddress", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            deferred.resolve(response.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("Error: ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // End of updateAddress
    obj.getPress = function (Id) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/office/getone", {
        officeId: Id,
      });
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Office: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /** getMatchForPress */
    obj.getMatchForPress = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/office/getMatch", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /** End Of getMatchForPress */

    obj.getEssay = function (Id) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay/getone", {
        essayId: Id,
      });
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getPressEssay = function (Id) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay-press/getone", {
        essayId: Id,
      });
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removePressEssay = function (Id) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/essay-press/removeEssay", {
        id: Id,
      });
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updateEssay = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/essay/update?mediaType=" + data.mediaType,
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     *
     * highlightPost
     */
    obj.highlightPost = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay/highlightPost", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * getHighlighatedPost
     */
    obj.getHighlighatedPost = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay/getHighlighatedPost", data);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * getFollowersList for organizations
     */

    obj.getFollowersList = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/office/getFollowersList", data);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /*
    **saves legislation rating into the table

    */

    obj.saveLegisRating = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/trackFederal/saveLegisRating",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /*
      **Save legislation comment

      */

    obj.saveLegisComment = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/trackFederal/saveLegisComment",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /*
       **get rating from database

       */

    obj.getLegisRate = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/trackFederal/getLegisRate",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * getQuizDetailsFor
     */
    obj.getQuizDetailsFor = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getQuizDetailsFor",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of getQuizDetailsFor */

    /**
     * getFilterQuizzes
     */

    obj.getFilterQuizzes = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getFilterQuizzes",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End Of getFilterQuizzes
     */

    /**
     * Send Email To the Org
     */

    obj.sendEmailToOrg = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/sendEmailToOrg", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of sendEmailToOrg
     */

    /**
     * Get details of the solved quiz
     */

    obj.getDetailsofQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getDetailsofQuiz",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of getDetailsofQuiz
     */

    /**
     * Get match with politicians
     */

    obj.getPoliMatch = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/getPoliMatch", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of get poli match
     */

    /**
     * getCompositeQuiz
     */
    obj.getCompositeQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/getCompositeQuiz",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**End of getCompositeQuiz */

    /**
     * getAnsQues
     */

    obj.getAnsQues = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getAnsQues", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End getAnsQues
     */

    /**getPopularQuestion */
    obj.getPopularQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getPopularQuestion",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /** End of getPopularQuestion */

    /**
     * Get All Essay
     */
    obj.getAllEssaysList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/essay/getAllEssaysList", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of
     */

    obj.updateEssayComts = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/comments/updateComt", data);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removeEssayComment = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/comments/removeEssayComment",
        data
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updatePressEssay = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/essay-press/update?mediaType=" + data.mediaType,
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updatePress = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/office/updateOfficeData", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Response: ", response);
          if (response.data.success) {
            var subcatdata = response.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getEssayComments = function (essayId) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/comments/getList", {
        essayId: essayId,
      });
      $http(nrequest).then(
        function successCallback(response) {
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getPressEssayComments = function (essayId) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/eassy-comments-press/getList", {
        essayId: essayId,
      });
      $http(nrequest).then(
        function successCallback(response) {
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getPressComments = function (pressId) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/presscomments/getList", {
        pressId: pressId,
      });
      nrequest.headers["Cache-Control"] = "No-Store";
      $http(nrequest).then(
        function successCallback(response) {
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updateEssayComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/comments/update", data);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updatePressEssayComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/eassy-comments-press/update",
        data
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updatePressComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/presscomments/update", data);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.submitEssayComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/comments/create", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("reply success");
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.submitPressEssayComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/eassy-comments-press/create",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("reply success");
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.submitPressComments = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/presscomments/create", data);
      $http(request).then(
        function successCallback(response) {
          //console.log("reply success");
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getCategoryList = function (categoryId) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/list", {
        id: categoryId,
      });
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getRootCategoryList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/list", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getCategoryListWithTopics = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/categories/listWithTopics",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);

          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getSubCategoryList = function (categoryId) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/list", {
        parentId: categoryId,
      });
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getExtraData = function (userData) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/topics/getExtraData", {
        userData: userData,
      });
      nrequest.headers["Cache-Control"] = "No-Store";
      $http(nrequest).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * extraDataUpdated
     */
    obj.extraDataUpdated = function (userData) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/topics/extraDataUpdated", {
        userData: userData,
      });
      $http(nrequest).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOneTopic = function (params) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("GET", "/topics/getone", params);
      nrequest.headers["Cache-Control"] = "No-Store";
      $http(nrequest).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * Get records of the topics
     *  */
    obj.getRecordsOf = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/topics/getRecordsOf", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updateTopic = function (params) {
      var deferred = $q.defer();
      var nrequest = apiCall.apiCall("POST", "/topics/update", params);
      $http(nrequest).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getLatestTopics = function (params) {
      var deferred = $q.defer();
      //console.log("params : ", params);
      var nrequest = apiCall.apiCall("POST", "/topics/latest", params);
      nrequest.headers["Cache-Control"] = "No-Store";
      $http(nrequest).then(
        function successCallback(response) {
          //console.log("response : ",response);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(responseLatest) {
          deferred.reject(responseLatest);
        }
      );
      return deferred.promise;
    };
    obj.getPoliticianOffice = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician-office/list", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
          } else {
            deferred.resolve(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getOfficeList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/office/list", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          /*if (response.data.success) {
            var subcatdata = response.data.data;
            deferred.resolve(subcatdata);
        } else {
            deferred.resolve(response.data);
        }*/
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getOnePolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/getone", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          // if (response.data.success) {
          //     var subcatdata = response.data.data;
          //     deferred.resolve(subcatdata);
          // } else {
          //     deferred.reject(response.error);
          // }
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getMultiCategoryByIds = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/categories/list-multiple",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getSubCatUsingIds = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/categories/getSubCatUsingIds",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Service is use to add or edit questions using Post Mehod
     * @param {*} data
     */

    obj.addFiveMinEssayQuestions = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/addfiveMinEssayQues",
        data
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @method Get
     * @description Service is use to get the list of five minute essay questions
     */

    obj.getFiveMinEssayQues = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getFiveMinEssayQues");
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Service is use to remove the question from the db.
     * @param {*} params
     */

    obj.removeEssayQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/removeEssayQuestion",
        params
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getQuestions = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/list", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // update courage questions

    obj.UpdateCourage = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/updateCourageQue",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getfivematch = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getfivematch", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getFiveMinAnswers = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getFiveMinAnswers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Get count of answer question for five minute quiz.
     * @param {*} params
     */
    obj.getFiveMinCount = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/getFiveMinCount", params);
      request.headers["Cache-Control"] = "No-Store";

      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get match of local politicians
     */

    obj.getMatchOfLocalPoli = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/getMatchOfLocalPoli",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getAllFiveMatch = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/votequestions/getAllfivematch",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.digDeepperQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/digDeepperQuestions",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getMatchQUestions = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/list", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.signUp = function (params) {
      console.log("vishal", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/signup", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got result from server: ", response);
          // if (response.data.success) {
          //     var subcatdata = response.data.data;
          //     deferred.resolve(subcatdata);
          // } else {
          //     deferred.resolve(response.data);
          // }
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.UpdateUser = function (params) {
      console.log("welcome to service", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/UpdateUser", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got result from server: ", response);
          // if (response.data.success) {
          //     var subcatdata = response.data.data;
          //     deferred.resolve(subcatdata);
          // } else {
          //     deferred.resolve(response.data);
          // }
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removeUser = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/remove", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          // if (response.data.success) {
          //     var subcatdata = response.data.data;
          //     deferred.resolve(subcatdata);
          // } else {
          //     deferred.resolve(response.data);
          // }
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.updateUserStatus = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updateUserStatus", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got Essay: ", response);
          if (response.data.success) {
            //var subcatdata = response.data.data;
            deferred.resolve(response.data);
          } else {
            deferred.resolve(response.data);
          }
          //deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.managePoliticianOffice = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/manage-politician-office",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getAddressData = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/ziplookup/list/filter", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getfilterAddressData = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/getfilterOrganizedZipData",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.postAnswer = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/create", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Service is use to store the essay answers into the db.
     * @param {*} params
     */
    obj.storeEssayAnswer = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/storeEssayAnswer",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // guestAnswers

    obj.guestAnswers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/guestAnswers", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    //  End of guestAnswers

    /**
     * @description Service is use to check if the quiz taker is exist or not
     */
    obj.checkIfQuizTakerExist = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/checkIfQuizTakerExist",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of checkIfQuizTakerExist
     */

    obj.getAllAnsweredQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/list", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get all ques Data
     */

    obj.getAllAnsQuesWithIds = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getAllAnsQuesWithIds",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get cat for quiz
     */

    obj.getCatForQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/getCatForQuiz", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get records of unverified users
     */

    obj.getUnVerifiedUser = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getUnVerifiedUser", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * getResultData
     */

    obj.getResultData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/getResultData", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /*
     * getFollowersAnsQues
     */

    obj.getFollowersAnsQues = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getFollowersAnsQues",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getAddressOfRunningPoli = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/address/list", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.AddNewOfficeData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/office/add", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.UpdateOfficeData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/office/update", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.forgotPassword = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/forgot-password", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * This service fetch users having same email address
     */
    obj.checkEmailForMultiUsers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/checkEmailForMultiUsers",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End Of checkEmailForMultiUsers

    // Update org quiz name

    obj.updateQuizName = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/updateQuizName", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.trackPoliticians = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/trackPoliticians", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getListOfCompetitors = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/getListOfCompetitors",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //getRegisterUSersFrom

    obj.getRegisterUsersFrom = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/getRegisterUsersFrom",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getListOfOtherQuizzes = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getListOfOtherQuizzes",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getListOfFiveMinQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getListOfFiveMinQuiz",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description  uploadPopulationData
     */

    obj.uploadPopulationData = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/uploadPopulationData");
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of getRegisterUSersFrom
    obj.getWithoutState = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/elections/getWithoutState");
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * addBallotMeasuresData
     */
    obj.addBallotMeasuresData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getBallotMeasures",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get ballot measures data statewise
     */

    obj.getBallotMeasuresData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getBallotMeasuresData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of addBallotMeasures
     */

    obj.getListOfDates = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/getListOfParties");
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // searchForRate
    obj.searchForRate = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/searchForRate", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // End of searchForRate

    /**
     * Get reviews by ballot Id
     */
    obj.getReviewsByBallotId = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getReviewsByBallotId",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get Review details
     */

    obj.getReviewsDetailsOf = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getReviewsDetailsOf",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get ballot measures data statewise
     */

    obj.getBallotMeausres = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/elections/getBallotMeausres",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * getOrgQuizQuestions
     */
    obj.getOrgQuizQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getOrgQuizQuestions",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of getOrgQuizQuestions
     */
    //  endorsePoli

    obj.endorsePoli = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/reviews/endorsePoli", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // End of endorsePoli

    /**
     * Endorse ballot measures
     */

    obj.endorseBallotMeasures = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/endorseBallotMeasures",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get list of endorsers
     */
    obj.getListOfEndorsers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getListOfEndorsers",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // Search poli for invitation
    obj.searchPoli = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/searchPoli", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // end of searchPoli

    /**
     * Send Invitations to politician for solving quiz create by advocate. */
    obj.sendQuizInvitation = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/sendQuizInvitation",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of send quiz invitations
     */

    /**
     * Get Records of all followers
     */
    obj.getIdsOfFollowers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getIdsOfFollowers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of getIdsOfFollowers
     */
    /**
     * Get info of invited politicians
     */

    obj.getInfoOfInvited = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getInfoOfInvited",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of sendQuizInvitation
     */

    /**
     * Get info of selected types
     */

    obj.getSeletedTypes = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getSeletedTypes",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * removeOrgQues
     */
    obj.removeOrgQues = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/removeOrgQues", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // End of removeOrgQues
    /**
     * Get Endorse Politician list
     */

    obj.getEndorsePoliList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getEndorsePoliList",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of getEndorsePoliList

    /**
     * Get Selected Questions By Organizations
     */

    obj.getSelectedQues = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getSelectedQues",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of

    /**
     * Update order of questions
     */

    obj.updateOrder = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/updateOrder", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of updateOrder

    /**
     * Fetch records of endorser
     */

    obj.getEndorsementsByUserId = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getEndorsementsByUserId",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * -- End of  getEndorsementsByUserId
     */

    /**
     * getFinalGroup
     */
    obj.getFinalGroup = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/getFinalGroup", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          // alert(JSON.stringify(response));
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * getFollowerMatch
     */
    obj.getFollowerMatch = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getFollowerMatch",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          // alert(JSON.stringify(response));
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of the getFollowerMatch
     */

    /**
     * Remove Politician from endorse table
     */
    obj.removePoliFromEndorse = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/removePoliFromEndorse",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // removePoliFromEndorse

    obj.forgotPoliticianPassword = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/forgotPoliticianPassword",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.resetPassword = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/reset-password", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.compareWithVotersFivemin = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/othervoters-listfivemin",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getLocalPoliForVoterFivemin = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/politicians-listfivemin",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCandidateListing = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getCandidateListing", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     *
     * GetAllLocalPoli
     */
    obj.getAllLocalPoli = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAllPoli", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**End Of getAllLocalPoli */

    // getMailingAddress
    obj.getMailingAddress = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getMailingAddress", params);
      request.headers["Cache-Control"] = "No-Store";
      // console.log('request :>> ', request);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End Of getMailingAddress

    obj.getLocalPoliForVoter = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/politicians-list", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLocalIncumbentPoliForVoter = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/incumbent-politicians-list",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.changeUsername = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/change-username", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.changeEmailID = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/change-emailid", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getUserList = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/list", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCatListWithQueAnsCountUserwise = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/categories/list-question-answer-count-userwise",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got User Data: ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCategories = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/list", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          /*
        if (response.data.success) {
            deferred.resolve(response.data.data);
        } else {
            deferred.reject(response.data);
        }
        */
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get Parent cat Id
     */
    obj.getParentCat = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/getParentCat", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          /*
        if (response.data.success) {
            deferred.resolve(response.data.data);
        } else {
            deferred.reject(response.data);
        }
        */
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCompareAns = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/compare", params);
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          /*
        if (response.data.success) {
            deferred.resolve(response.data.data);
        } else {
            deferred.reject(response.data);
        }
        */
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getSideBySide = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answer/getSideBySide", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // getGuestCompareAns

    obj.getGuestCompareAns = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getGuestCompareAns",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("Got categories: ", response);
          /*
        if (response.data.success) {
            deferred.resolve(response.data.data);
        } else {
            deferred.reject(response.data);
        }
        */
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of

    obj.sendActivatationMailtoAdmin = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/sendReactivateMail", {});
      $http(request).then(
        function successCallback(response) {
          //console.log(response);
          if (response.data.success) {
            deferred.resolve(response.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.GetDialogWithButtons = function (ngDialog, message, button1, button2) {
      var deferred = $q.defer();
      //console.log("GetDialogWithButtons called");
      var modal = ngDialog.openConfirm({
        template:
          "<p>" +
          message +
          '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">' +
          button1 +
          '</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">' +
          button2 +
          "</button>\
            </div>",
        plain: true,
      });
      modal.then(
        function fullfilled(response) {
          //console.log("fullfilled: ", mydata);
          deferred.resolve(response);
        },
        function rejected(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.addParents = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/categories/add-parents", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.editQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/update", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getEssayAns = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/getEssayAnswers", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.approveQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/approve", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkUnm = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/check/username", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkEmail = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/check/email", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkPostCard = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/check/postCard", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getPoliticianLocWise = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/user/politician-office-locationwise",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getPoliticians = function (params) {
      console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/getSearchedPolitician",
        params
      );
      $http(request).then(
        function successCallback(response) {
          console.log("Data:", response.data);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response.error);
        }
      );
      return deferred.promise;
    };

    // getPresident

    obj.getPresident = function (params) {
      console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/politician/getPresident", params);
      $http(request).then(
        function successCallback(response) {
          console.log("Data:", response.data);
          if (response.data.success) {
            deferred.resolve(response.data.data);
          } else {
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response.error);
        }
      );
      return deferred.promise;
    };

    obj.sendVerificationCode = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/sendVerificationCode",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.sendBatchMail = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/sendBatchMail", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.sendInvitations = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/sendInvitations", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // obj.sendBatchMailTo = function (params) {
    //   var deferred = $q.defer();
    //   var request = apiCall.apiCall('POST', '/user/sendBatchMailTo', params);
    //   $http(request).then(
    //     function successCallback(response) {
    //       deferred.resolve(response);
    //     },
    //     function errorCallBack(response) {
    //       deferred.reject(response);
    //     }
    //   );
    //   return deferred.promise;
    // };

    /**
     * @description Function is use to create user's of orgs
     */
    obj.createUsersOfOrg = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/createUsersOfOrg");
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.notifyUsersPasswords = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/send-password", params);
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.GetAnsQuestionReport = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/GetAnsQuestionReport",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.GetAreaQuestionReport = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/GetAreaQuestionReport",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.GetAllUsersAnsReport = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/GetAllUsersAnsReport",
        {}
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.GetCommentsQuestionReport = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/GetCommentsQuestionReport",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getComments = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/GetComments", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getUserAnsQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getUserAnsQuestion",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getUsersFromIds = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getUsersFromIds", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Funtion is use to get records of endorsement for selected politician.
     */
    obj.getEndorsementData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getEndorsementData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of
     */

    obj.getRatingsFor = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/reviews/getRatingsFor", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updateRating = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/reviews/updateRating", params);
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getUserPublicProfile = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/publicProfile", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          // console.log('User ======== response in service :', response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * @description Add ratings
     */
    obj.getRatingsOfCandidate = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getRatingsOfCandidate",
        params
      );
      $http(request).then(
        function successCallback(response) {
          // console.log('User ======== response in service :', response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.setDeepInterest = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/setDeepInterest", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getReviewSearchResult = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/reviews/getReviewSearchResult",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getNewsFeed = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/essay-press/getNewsFeed", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.AddFavorite = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/recommendation/add", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.RemoveFavorite = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/recommendation/remove", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.recommendFav = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/recommendation/recommendFav",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.ListMyFavorite = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/recommendation/listAll", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getRecommended = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/recommendation/getRecommended",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.spamMail = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/sendSpamMail", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updateFBID = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/updateFacebookId", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.matchQuestionwithPolitician = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/matchQuestionwithPolitician",
        {}
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // For Ballot match

    obj.getBallotQue = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/getBallotQuestion", {});
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //For answered Questions

    obj.getAnsweredQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getAnsweredQuestions",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.matchQuestionwithOrg = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/matchQuestionwithOrg", {});
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getFiveFacebookPost = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getFiveFacebookPost", {});
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.CompareWithFreinds = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/CompareWithFreinds",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.CompareWithPolitcians = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/CompareWithPolitcians",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.compareFiveMinAnswers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/compareFiveMinAnswers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          alert(JSON.stringify(response));
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.CompareWithAllPolitcians = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/CompareWithAllPolitcians",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.addBannerInfo = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/addBannerInfo", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getBannerInfo = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getBannerInfo", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //checkInviter

    obj.checkInviter = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/checkInviter", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  compareRegisterGuestUsers

    obj.compareRegisterGuestUsers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/compareRegisterGuestUsers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of compareRegisterGuestUsers

    /**
     * Get match of popular quiz
     */

    obj.getPopularMatch = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/getPopularMatch", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Compare with all
     */

    obj.compareWithAll = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/compareWithAll",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of compare with all
     */

    // compareGuest

    obj.compareGuest = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/answers/compareGuest", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of compareGuest

    // getAdvocates
    obj.getAdvocates = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getAdvocates");
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of getAdvocates

    // getComparisonOfGroup
    obj.getComparisonOfGroup = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getGroupComparison",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //getUserSpecificQues
    obj.getUserSpecificQues = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getUserSpecificQues",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End Of getComparisonOfGroup

    obj.getOthersMatch = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/getOthersMatch", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Get popular quiz comparison
     */
    obj.getPopularComparison = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getPopularComparison",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Show all quizzes
     */
    obj.showAllQuizzes = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/showAllQuizzes");
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkIfInvited = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/questions/checkIfInvited");
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * Delete quiz
     */
    obj.deleteQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/deleteQuiz", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // showUsersAnswers

    obj.showUsersAnswers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/getUsersAnswers", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End Of showUsersAnswers

    /**
     * getQuesCount
     */
    obj.getQuesCount = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/getQuesCount", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * End of the getQuesCount
     */

    // getAllUsersOfNation

    obj.getAllUsersOfNation = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getAllUsersOfNation",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of getAllUsersOfNation

    //  getRegisterUsersOfNation

    obj.getRegisterUsersOfNation = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getRegisterUsersOfNation",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    //  End of getRegisterUsersOfNation

    /**
     * @description Get list of politicians and org with individual match.
     */

    obj.getIndividualMatchList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/getIndividualMatchList",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    /**
     * End of getIndividualMatchList
     */

    obj.checkfreindsIfanswered = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/answers/checkfreindsIfanswered",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updateAnswers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/updateShow", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getReportedQuestions = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/questions/reportlist", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removeReportedQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/removeReportedQeustion",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removeMatchedQuestion = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/questions/removeMatchedQuestion",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLatestEssay = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/latestEssay", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLatestPost = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/latestPost", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLatestThread = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/latestThread", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.latestDataDateWise = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/latestDataDateWise", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLiveUsers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getLiveUsers", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getLinkPreview = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/check/linkpreview", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.saveTempPolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/politician/savetemp", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.restoreTempPolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/politician/restoretemp", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.checkifStateExists = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/politician/checkifStateExists",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOrganizedData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getOrganizedData", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOrganizedZipData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getOrganizedZipData", params);
      $http(request).then(
        function successCallback(response) {
          // console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // deleteGuest

    obj.deleteGuest = function (params) {
      // console.log("$$$$$$$$ params >>>>", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/answers/deleteGuest", params);
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          // console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     *
     * @param {*} params
     * @description Method to get the list of guest users five minute answers.
     * @method GET
     */

    obj.getGuestFiveMinAns = function (params) {
      // console.log("$$$$$$$$ params >>>>", params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getGuestFiveMinAns",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          // console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // End of deleteGuest

    obj.removeStoredTempPolitician = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/politician/removeStoredTempPolitician",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.savePoliticianGrid = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/savePoliticianGrid", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.updatePoliticianGrid = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/updatePoliticianGrid",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.removePoliticianGrid = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/user/removePoliticianGrid",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.saveFavChannel = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/user/saveFavChannel", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCalendarList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/calendar/listAll", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCalendarEventList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/calendar/listAllEvents", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.matchMembers = function (params) {
      // console.log(params);
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/matchMembersWithName",
        params
      );
      $http(request).then(
        function successCallback(response) {
          // console.log("topics : ", response);
          if (response.data.success) {
            // var subcatdata = response.data.data;

            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getIndustryData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/getIndustrySupportData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getPersonalHoldings = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/getPersonalHoldingsData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getFundRaising = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/getFundRaisingData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getTopContributor = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/topContributorData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getVotingRecords = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/getVotingRecordOfMember",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getBillCosponsoredData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/getBillCosponsoredData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.getProbublicaData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressman/getProbublicaData",
        data
      );
      $http(request).then(
        function successCallback(response) {
          console.log("got data");
          // console.log("topics : ", response);
          if (response) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getFollowerForPublic = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/user/getFollowerForPublic", data);
      $http(request).then(
        function successCallback(response) {
          // console.log('got data');
          // console.log('topics : ', response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getRatingData = function (data) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/reviews/getRatingData", data);
      $http(request).then(
        function successCallback(response) {
          // console.log('got data');
          // console.log('topics : ', response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOpenStateData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/openState/getOpenStateData",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    // get Billvotes
    obj.getBillVotes = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openState/getBillVotes", params);
      request.timeout = canceler;

      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    // End of get Billvotes

    // get Bills stateWise

    obj.viewBills = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/openState/viewBillsStateWise",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    //End Of get Bills stateWise

    obj.addCongressBills = function () {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/congressbills/addCongressbills");
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            deferred.resolve(response);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getAllCongressman = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressman/getCongressman",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.findCongressmenMismatch = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressman/findCongressmenMismatch",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };
    obj.addCongressman = function () {
      var deferred = $q.defer();
      var params = "";
      var request = apiCall.apiCall(
        "GET",
        "/congressman/addCongressman",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.addMatchedMembers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/congressbill/addmatchedMembers",
        params
      );
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.matchPeople = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openState/matchPeople", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.saveForLaterQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/saveForLaterQuiz",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Get remaining quiz details
     * @param {*} params
     */
    obj.getRemainingQuizData = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/getRemainingQuizData",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    /**
     * @description Update remainig quiz links array
     * @param {*} params
     */

    obj.updateRemainingQuiz = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/questions/updateRemainingQuiz",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.addMatchedPeople = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("GET", "/openState/addMatchPeople", params);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.success) {
            var subcatdata = response.data.data;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            //alert("Something went wrong: ", response.data);
            deferred.reject(response.data.error);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getNewsSource = function (params) {
      var deferred = $q.defer();
      // var proxyUrl = "https://cors-anywhere.herokuapp.com/";
      // var request = {
      //   method: 'GET',
      //   url: proxyUrl+'https://newsapi.org/v2/sources?language=en&apiKey=bb63dab0fb0044628b12fa12ce8c4809',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      //   },
      // };
      var request = apiCall.apiCall("GET", "/user/getNewsSource");
      $http(request).then(
        function successCallback(response) {
          // console.log("topics : ", response);
          if (response.data.data.status == "ok") {
            var subcatdata = response.data.data.sources;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getFavNews = function (params) {
      //console.log("https://newsapi.org/v1/articles?source=" + params.favChannel + "&sortBy=top&apiKey=bb63dab0fb0044628b12fa12ce8c4809&callback=foo");
      var deferred = $q.defer();
      // var request = {
      //   method: 'GET',
      //   url: 'https://newsapi.org/v2/articles?source=' +
      //     params.favChannel +
      //     '&sortBy=top&apiKey=bb63dab0fb0044628b12fa12ce8c4809&callback=foo',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded;',
      //   },
      // };
      var request = apiCall.apiCall("GET", "/user/getFavNewsChannel", params);

      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          if (response.data.data.status == "ok") {
            var subcatdata = response.data.data.articles;
            //console.log("topics : ", subcatdata);
            deferred.resolve(subcatdata);
          } else {
            deferred.reject(response);
          }
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.addReview = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall("POST", "/reviews/store-review", params);
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getReviewsByUserId = function (params, canceler) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/get-reviews-by-user-id",
        params
      );
      request.timeout = canceler;
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getReviewsById = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/get-reviews-by-id",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getCompareReviewList = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "POST",
        "/reviews/getCompareReviewList",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getOrgCompareAnswers = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/answers/getOrgCompareAnswers",
        params
      );
      request.headers["Cache-Control"] = "No-Store";
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getVotesmartPressDetails = function (params) {
      var deferred = $q.defer();
      var request = apiCall.apiCall(
        "GET",
        "/reviews/getVotesmartPressDetails",
        params
      );
      $http(request).then(
        function successCallback(response) {
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    obj.getVotesmartRatings = function (params) {
      var deferred = $q.defer();
      var request = {
        method: "GET",
        url:
          "https://api.votesmart.org/Rating.getCandidateRating?key=" +
          params.key +
          "&candidateId=" +
          params.id +
          "&sigId=" +
          params.sigid +
          "&o=JSON",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      };
      console.log("request > ", request);
      $http(request).then(
        function successCallback(response) {
          //console.log("topics : ", response);
          deferred.resolve(response);
        },
        function errorCallBack(response) {
          //console.log("topics : ", response);
          deferred.reject(response);
        }
      );
      return deferred.promise;
    };

    return obj;
  });
