'use strict';
angular
  .module('myApp.showVotesmartAnsQues', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/showVotesmartAnsQues/:qId/:voteCandidateId?', {
        templateUrl: 'app/showVotesmartAnsQues/showVotesmartAnsQues.html',
        controller: 'showVotesmartAnsQuesCtrl',
      });
    },
  ])
  .controller('showVotesmartAnsQuesCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout
    ) {

      /**
       * Get params using route params
       */
      $scope.qId = $routeParams.qId;
      $scope.voteCandidateId = $routeParams.voteCandidateId;
      MyService.ConsoleLog("Qid", $scope.qId);
      MyService.ConsoleLog(" $scope.voteCandidateId", $scope.voteCandidateId);


      /**
       * Get Questions
       */
      $scope.getVotesmartData = function () {

        MyService.getVotesmartData({
          qId: $scope.qId,
          voteCandidateId: $scope.voteCandidateId
        }).then(function (response) {
          MyService.ConsoleLog("response", response);
          $scope.voteData = response.data.data;
        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      /**
       * Back to
       */
      $scope.backTo = function () {
        window.history.back();
      };

      $scope.getVotesmartData();
    }
  ]);
