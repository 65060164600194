'use strict';
angular.module('myApp.politicianVideo', ['ngRoute'])
  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/politicianVideo', {
      templateUrl: 'app/politicianVideo/politicianVideo.html',
      reloadOnSearch: false,
      controller: 'politicianVideoCtrl' //we can name anythin
    });
  }])
  .controller('politicianVideoCtrl', ['$scope', '$rootScope', '$window', function($scope, $rootScope, $window) {
    //this is controller for sampepage.html, we link the conttoler to page in config defination, check above .config
    $rootScope.welcome = false;
    $window.scrollTo(0, 0);
  }]);

