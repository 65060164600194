 "use strict";
 angular
   .module("myApp.ballotMatch", ["ngRoute"])

   .config([
     "$routeProvider",
     function ($routeProvider) {
       $routeProvider.when("/ballotMatch/:uId?", {
         templateUrl: "app/ballotMatch/ballotMatch.html",
         controller: "ballotMatchCtrl"
       });
     }
   ]).controller('ballotMatchCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', '$anchorScroll', '$timeout', 'tokenService', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, $anchorScroll, $timeout, tokenService) {
     //MyService.ConsoleLog("** questionCtrl ** :", $routeParams);
     $rootScope.welcome = false;
     $scope.traverseBackgrounds = [];
     $scope.currBackgroundIndex = 0;

     if ($routeParams.uId) {
       $scope.selUserId = $routeParams.uId;
     }
     // $scope.flagSkipAnsweredQuestion = true;
     // alert("welcome");
     // $('#skipMatchOne').attr('disabled', false);
     // $scope.reason;
     // $scope.comment;
     $scope.skipMatchOne = 1;
     MyService.ConsoleLog(" $scope.skipMatchOne :", $scope.skipMatchOne);

     $scope.status = {
       questions: 0, //1= loading, 2 = loaded, 3 = error
       category: 0
     };

     window.localStorage.removeItem('specialQueAns');
     $scope.updata = {
       comment: "",
       reason: ""
     };
     if (tokenService.getUserData()) {
       $scope.userData = JSON.parse(tokenService.getUserData());
       // MyService.ConsoleLog(" $scope.userData :", $scope.userData)
     }


     $scope.currentIndex = 0;
     $scope.initializeVariables = function () {
       $scope.importance = null;
       $scope.answer = null;
       $scope.updata.comment = '';
       $scope.reason = '';
     };
     $scope.initializeVariables();

     $scope.initMyAnswer = function () {
       try {
         MyService.ConsoleLog("$scope.questions[$scope.currentIndex] :", $scope.questions[$scope.currentIndex]);
         if ($scope.questions[$scope.currentIndex].my_answer) {
           var user_answer = $scope.importance = $scope.questions[$scope.currentIndex].my_answer;
           $scope.importance = user_answer.importance;
           $scope.answer = user_answer.answer;
           $scope.updata.comment = user_answer.comment;
           $("#agreedisagree-id" + user_answer.answer).prop('checked', true);
           $("#importance-id" + user_answer.importance).prop('checked', true);


         } else {
           $scope.initializeVariables();
         }
       } catch (e) {
         $scope.initializeVariables();
         //MyService.ConsoleLog("Exceptoin: ", e)
       }
     };

     var getQuestionsCanceler = $q.defer();

     /*
     function getQuestions(params) {
       getQuestionsCanceler.resolve();
       $scope.status.questions = 1;
       params.timeout = getQuestionsCanceler.promise;
       MyService.ConsoleLog("questionAnsByCtrl** /questions/list: ", params);
       $scope.questions.length = 0;
       var request = apiCall.apiCall('GET', '/questions/list', params);
       $http(
         request
       ).then(function successCallback(response) {
         MyService.ConsoleLog("questionAnsByCtrl Got categories: ", response);
         if (response.data.success) {
           $scope.status.questions = 2;
           $scope.questions = response.data.data;
           MyService.ConsoleLog("total questions: ", $scope.questions);
           // $scope.skipAnswerChagne();
         } else {
           $scope.status.questions = 3;
           if (typeof response.data.error == "string")
             growl.error(response.data.error);
           else {
             // growl.error("Something went wrong");
           }
         }
       }, function errorCallBack(response) {
         $scope.status.questions = 3;
         //MyService.ConsoleLog("Error: ", response);
       });
     }
    */

     $scope.getUserProfile = function () {
       MyService.getProfile($routeParams.user_id).then(function (userData) {
         $scope.userData = userData;
         MyService.ConsoleLog("userData: ", $scope.userData);
       }, function (err) {
         growl.error("Something went wrong while fetching user info.");
       });
     }

     var getCategoryCanceler = $q.defer();
     var prevFetchedCategoryId = null;
     $scope.categoryCalled = false;
     $scope.getCategory = function (params) {
       MyService.ConsoleLog("scrollTop pre :", prevFetchedCategoryId);
       MyService.ConsoleLog("scrollTop Two :", params.id);


       if (prevFetchedCategoryId != params.id) {
         MyService.ConsoleLog("scrollTop");
         $scope.categoryCalled = true;
         $("html, body").animate({
           scrollTop: 0
         }, "slow");
         getCategoryCanceler.resolve();
         $scope.status.category = 1;
         params.timeout = getCategoryCanceler.promise;
         var request = apiCall.apiCall('GET', '/categories/list', params);
         $http(
             request
           )
           .then(function successCallback(response) {
             //MyService.ConsoleLog("Got categories: ",response);
             if (response.data.success) {
               $scope.category = response.data.data;
               //MyService.ConsoleLog("category data: ", $scope.category);
               prevFetchedCategoryId = $scope.category._id;
               $scope.status.category = 2;
             } else {
               //MyService.ConsoleLog("Something went wrong: ", response.data);
               $scope.status.category = 3;
             }
           }, function errorCallBack(response) {
             //MyService.ConsoleLog("Error: ", response);
             $scope.status.category = 3;
           });
       } else {

         $("html, body").animate({
           scrollTop: $("#DisplayQ1").offset().top - 80
         }, "slow");
         // $timeout(function() {
         //   MyService.ConsoleLog("called")
         //   $location.hash('DisplayQue');
         //   $anchorScroll();

         // }, 100);

       }
     };


     $scope.postAnswer = function (postData) {
       // MyService.ConsoleLog("postData: ", postData);
       try {
         var my_answer = $scope.questions[$scope.currentIndex].my_answer;
         if (my_answer.importance != $scope.importance || my_answer.answer != $scope.answer || my_answer.comment != $scope.updata.comment) {
           MyService.ConsoleLog("Answer changed :", my_answer);
           postData.id = my_answer._id;
           postData.reason = $scope.updata.reason;
         } else {
           //MyService.ConsoleLog("Answer not changed");
           return; //don't do anything
         }
       } catch (e) {
         //MyService.ConsoleLog("Exceptoin: ", e);
       }
       //MyService.ConsoleLog(postData);
       MyService.ConsoleLog("postData Second: ", postData);


       MyService.postAnswer(postData).then(function (response) {
         document.getElementById("txtReason").value = '';

         if (response.data.success) {
           // console.log("response.data > ", response.data.data);
           try {
             if (postData.id) {
               var idx = angular.copy($scope.currentIndex);
               console.log("IDX <><><><", idx);
               $scope.questions[$scope.currentIndex]["my_answer"] = {
                 _id: postData.id,
                 question: postData.questionId,
                 created: new Date(),
                 comment: postData.comment,
                 answer: postData.answer,
                 importance: postData.importance
               };
             } else {
               var idx = angular.copy($scope.currentIndex);
               console.log("eLSE IDX <><><><", idx);
               $scope.questions[$scope.currentIndex]["my_answer"] = {
                 _id: response.data.data._id,
                 question: response.data.data.question,
                 created: response.data.data.created,
                 comment: response.data.data.comment,
                 answer: response.data.data.answer,
                 importance: response.data.data.importance
               };
             }
             // $scope.questions = angular.copy($scope.questions);
             console.log("questions <><><><>", $scope.questions);
           } catch (e) {
             //MyService.ConsoleLog("Exceptoin: ", e);
           }
           if (postData.id) {
             growl.success("Answer updated successfully", {
               ttl: 800
             });
           } else {
             growl.success("Answered successfully", {
               ttl: 800
             });
           }
           $scope.initializeVariables();
           $scope.skipQuestions();
         } else {
           growl.error("Error: " + response.data.error);
         }
       }, function (err) {
         // growl.error("Something went wrong.");
       });

     };






     $scope.submitAnswer = function () {

       MyService.ConsoleLog("submitAnswer: " + $scope.answer);
       MyService.ConsoleLog("importance: " + $scope.importance);
       MyService.ConsoleLog("comments: ", $scope.updata.comment);
       // MyService.ConsoleLog("$scope.comments: ", $scope.comment);
       MyService.ConsoleLog("$scope.comments: ", $scope.updata.reason);

       //  MyService.ConsoleLog("$scope.questions[$scope.currentIndex]._id ", $scope.questions[$scope.currentIndex]);
       if ($scope.questions[$scope.currentIndex] && $scope.questions[$scope.currentIndex].my_answer && $scope.questions[$scope.currentIndex].my_answer._id != '') {
         var myAns = $scope.questions[$scope.currentIndex].my_answer;
         if ($scope.answer == null || $scope.answer == '') {
           $scope.answer = myAns.answer;
         }
         if ($scope.importance == null || $scope.importance == '') {
           $scope.importance = myAns.importance;
         }
       }

       var token = apiCall.getToken();

       if (!token || token == "") {
         var message = "Please login first.";

         var modal = ngDialog.openConfirm({
           template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
           plain: true
         });
         modal.then(function fullfilled(data) {
           //MyService.ConsoleLog("fullfilled: ", data);
         }, function rejected(data) {
           //MyService.ConsoleLog("rejected: ", data);
         });
         return;
       }

       if ($scope.answer == null) {
         var message = "Please select answer first.";
         var modal = ngDialog.openConfirm({
           template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
           plain: true
         });

         modal.then(function fullfilled(data) {
           //MyService.ConsoleLog("fullfilled: ", data);
         }, function rejected(data) {
           //MyService.ConsoleLog("rejected: ", data);
         });

         return;
       }

       if ($scope.importance == null) {
         var message = "You gave your opinion, but you didn’t say how important this is to you.  Should they fight hard for this or can they use it as a bargaining chip to get other things you care about more?";

         var modal = ngDialog.openConfirm({
           template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
           plain: true
         });

         modal.then(function fullfilled(data) {
           //MyService.ConsoleLog("fullfilled: ", data);
         }, function rejected(data) {
           //MyService.ConsoleLog("rejected: ", data);
         });
         return;
       }
       // $("html, body").animate({
       //   scrollTop: $("#DisplayQ1").offset().top - 80
       // }, "slow");

       var postData = {
         questionId: $scope.questions[$scope.currentIndex]._id,
         importance: $scope.importance,
         answer: $scope.answer,
         comment: $scope.updata.comment ? $scope.updata.comment : "",
         reason: $scope.updata.reason,
         token: token
       };
       //MyService.ConsoleLog(postData);
       $scope.postAnswer(postData);
     };

     // $scope.comment;
     //   MyService.ConsoleLog("  $scope.comment :", $scope.comment);



     $scope.skipQuestions = function () {
       MyService.ConsoleLog(" questionssssssssssssssssssss called");
       // $scope.categoryCalled = false;
       // MyService.ConsoleLog(' $rootScope.queOneLen ', $rootScope.queOneLen);




       var skip = function () {
         var tempIndex = $scope.currentIndex + 1;
         MyService.ConsoleLog(" length of questions :", tempIndex);
         if (tempIndex < $scope.questions.length) {
           $scope.initializeVariables();
           $scope.currentIndex++;
           if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
             //we are showing questions of most popular section
             MyService.ConsoleLog("welcome to if");

             $scope.getCategory({
               id: $scope.questions[$scope.currentIndex].categories.cid
             });
           }
         }
         if (tempIndex >= 5) {
           // $scope.skipToRes = true;
           // $('#skipMatchOne').attr('disabled', false);
           $scope.skipMatchOne = 2;
         }
         MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ", $scope.questions.length);
         if (tempIndex == $scope.questions.length) {
           //MyService.ConsoleLog("end of questions switch background");
           var tempBackIdx = $scope.currBackgroundIndex + 1;
           if (tempBackIdx < $scope.traverseBackgrounds.length) {
             $scope.currBackgroundIndex++;
             $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
             MyService.ConsoleLog(" category  id   : ", $scope.category);
           } else {
             //MyService.ConsoleLog("* End of background *");
             var message = "Congratulations, you have answered all the questions that your politicians have answered.";
             var modal = ngDialog.openConfirm({
               template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">See Match-ups</button>\
                        </div>',
               plain: true
             });

             modal.then(function fullfilled(data) {
               //MyService.ConsoleLog("fullfilled: ", data);
               getCompareAnswers({
                 "compareQuestionsAll": JSON.stringify($scope.queIds),
                 "compareUsersAll": JSON.stringify($scope.compareUsersAll)
               });
             }, function rejected(data) {
               //MyService.ConsoleLog("rejected: ", data);
             });
             return;

           }
         }



       };

       skip();


     };

     $scope.prevQuestion = function () {
       //MyService.ConsoleLog("** prevQuestion **");

       if ($scope.currentIndex > 0) {
         // $("html, body").animate({
         //   scrollTop: 0
         // }, "slow");
         $scope.currentIndex--;
         if ($scope.digDeeperMatch != true) {
           if (window.localStorage.getItem("fiveMinMatch")) {
             //MyService.ConsoleLog("here");
             $scope.getFiveMinData = [];
             $scope.getFiveMinData.length = 0;
             if ($scope.flagSkipAnsweredQuestion) {
               $scope.getFiveMinData = $scope.cachedQuestions;
             } else {
               $scope.getFiveMinData = JSON.parse(window.localStorage.getItem("fiveMinMatch"));
             }

             //MyService.ConsoleLog("Data : ", $scope.getFiveMinData);
             for (var i = 0; i < $scope.getFiveMinData.length; i++) {
               MyService.ConsoleLog("for i : " + i + " currentIndex : " + $scope.currentIndex);
               if (i == $scope.currentIndex) {
                 MyService.ConsoleLog("index matched", $scope.getFiveMinData[i]);
                 // $scope.answer = $scope.getFiveMinData[i].answer;
                 // $scope.importance = $scope.getFiveMinData[i].importance;
                 // $scope.comment = $scope.getFiveMinData[i].comment;
                 // $("#agreedisagree-id" + user_answer.answer).prop('checked', true);
                 // $("#importance-id" + user_answer.importance).prop('checked', true);

                 // $timeout(function() {
                 //   $scope.$apply();
                 // });

               }
             }
           }
         }


       } else if ($scope.currentIndex == 0) {
         if ($scope.currBackgroundIndex > 0) {
           $scope.currBackgroundIndex--;
           $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
         }
       }
     };

     var getCompareAnswersCanceler = $q.defer();

     function getCompareAnswers(params) {
       MyService.ConsoleLog("params >>>", params);

       if (!params) return;
       getCompareAnswersCanceler.resolve();
       params.timeout = getCompareAnswersCanceler.promise;
       $scope.questions = [];
       $scope.status.questions = 1;
       // $rootScope.selectedCompareUsers = params.compareUsersAll;
       //  var comObj = {
       //    "compareUsersAll": params.compareUsersAll,
       //    "compareQuestionsAll": params.compareQuestionsAll
       //  }
       //  MyService.ConsoleLog(" compare object :", comObj);
       //  window.localStorage.setItem("caompareAll", JSON.stringify(comObj));
       //  $location.path("/compare-politician-questions");
       //  return;
       var request = apiCall.apiCall('POST', '/answers/getCompareAnswersForPoliticians', params);
       request.headers['Cache-Control'] = 'No-Store';
       $http(
         request
       ).then(function successCallback(response) {
         MyService.ConsoleLog("Got categories:>> ", response);
         if (response.data.data.length > 0) {
           $scope.status.questions = 2;
           $scope.userIds = response.data.data.map(function (obj) {
             if (obj != "" && obj != " ") {
               return obj.answers[0].author._id;
             }
           })
           MyService.ConsoleLog("User ides", $scope.userIds.length);
           $rootScope.selectedCompareUsers = $scope.userIds;
           var comObj = {
             "compareUsersAll": JSON.stringify($scope.userIds),
             "compareQuestionsAll": JSON.stringify($scope.queIds)
           }
           MyService.ConsoleLog(" compare object :", comObj);
           window.localStorage.setItem("caompareAll", JSON.stringify(comObj));

           MyService.ConsoleLog("$rootScope.selectedCompareUsers :", $rootScope.selectedCompareUsers);
           angular.forEach(response.data.data, function (val, key) {
             if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
               var check_author_exist = function (u) {
                 var len = val.answers.length;
                 for (var i = 0; i < len; i++) {
                   if (val.answers[i].author._id == u._id) {
                     return true;
                   }
                 }
                 return false;
               };
               angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                 if (!check_author_exist(u)) {
                   response.data.data[key].answers.push({
                     author: u
                   });
                 }
               });
             } else if (!val.answers) {
               response.data.data[key].answers = [];
               angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                 response.data.data[key].answers.push({
                   author: u
                 });
               });
             }
           });

           $scope.questions = response.data.data;
           $rootScope.compareAnswerList = response.data.data;
           MyService.ConsoleLog("Questions><><><>", $scope.questions);
           MyService.ConsoleLog("Questions><><><>", params);

           // alert("params :",params);
           $rootScope.catsId = $scope.questions[0].categories[0].cid;
           MyService.ConsoleLog(" $scope.questions[0].categories[0].cid :", $scope.questions[0].categories[0].cid);
           /*
           if (params.type && params.type == "skipToResult") {

             $location.path("/compare-politician-questions");
           } else {
             // alert("else");

             $location.path("question-compare-politician/" + $scope.questions[0].categories[0].cid);
           }*/
           $location.path("/compare-politician-questions");

         } else {
           $scope.status.questions = 3;
           MyService.ConsoleLog("Something went wrong: ", response.data);
           var message = "None of your politicians have answered the questions yet. Contact them and ask them to fill it out.";

           var modal = ngDialog.openConfirm({
             template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(0)">OK</button>\
                        </div>',
             plain: true
           });

           modal.then(function fullfilled(data) {
             //MyService.ConsoleLog("fullfilled: ", data);
             if (tokenService.getUserRole() == "politician") {
               $location.path("/winVoters");
             } else if (tokenService.getUserRole() == "voter") {
               $location.path("/questionHome");

             }
           }, function rejected(data) {
             //MyService.ConsoleLog("rejected: ", data);

           });
         }
       }, function errorCallBack(response) {
         $scope.status.questions = 3;
         //MyService.ConsoleLog("Error: ", response);
       });
     };

     var current_url = $location.url();
     //MyService.ConsoleLog("$location.url: ", current_url);


     $scope.state = "question-not-answered";
     $scope.status.questions = 1;
     $scope.compareQuestionsAll = [];
     //MyService.matchQuestionwithPolitician().then(function(payload) {


     // function to get questions commented by vishal

     // MyService.matchQuestionwithPolitician().then(function(payload) {

     //     MyService.ConsoleLog("matchQuestionwithPolitician :", payload);
     //     //MyService.ConsoleLog(payload.data.success);
     //     //MyService.ConsoleLog(payload.data.data.error);
     //     if (payload.data.success) {
     //       $scope.status.questions = 2;
     //       $scope.questions = payload.data.data.unanswered;
     //       $scope.compareQuestionsAll = payload.data.data.allanswered;
     //       $scope.compareUsersAll = payload.data.data.allusers;
     //       $scope.initializeVariables();
     //       // setTimeout(function() {
     //       //   getCompareAnswers({ "compareQuestionsAll": JSON.stringify($scope.compareQuestionsAll), "compareUsersAll": JSON.stringify($scope.compareUsersAll) });
     //       // }, 6000);

     //     } else {
     //       $scope.status.questions = 3;
     //       if (typeof payload.data.data.error == "string" && payload.data.data.error == "NoQuestions") {
     //         $scope.compareQuestionsAll = payload.data.data.allanswered;
     //         $scope.compareUsersAll = payload.data.data.allusers;
     //         growl.error("You already answered all the questions answered by your politicians.");
     //         // alert("welcome to compare");
     //         setTimeout(function() {
     //           getCompareAnswers({
     //             "compareQuestionsAll": JSON.stringify($scope.compareQuestionsAll),
     //             "compareUsersAll": JSON.stringify($scope.compareUsersAll)
     //           });
     //         }, 700);

     //       } else {
     //         growl.error("Something went wrong");
     //       }
     //     }
     //   },
     //   function(errpayload) {
     //     growl.error("Something went wrong");
     //   });


     // Function to get questions for local politicians.

     $scope.getBallotMatch = function () {
       // alert("called ballotMatch");
       $scope.status.questions = 1;
       MyService.getBallotQue().then(function (result) {
         MyService.ConsoleLog("matchQuestionwithPolitician :", result.data.data);
         // MyService.ConsoleLog("dhADGDAD :",result.data.success);
         //MyService.ConsoleLog(payload.data.data.error);
         if (result.data.success) {

           $scope.status.questions = 2;
           $scope.questions = result.data.data.quizData;
           $rootScope.ques = result.data.data.quizData;
           $scope.compareUsersAll = result.data.data.userIds;
           $scope.queIds = $scope.questions.map(function (obj) {
             if (obj != "" && obj != " ") {
               return obj._id;
             }
           });


           //  MyService.ConsoleLog("question ids :", $scope.queIds);
           $scope.flagSkipAnsweredQuestion = true;
           $scope.skipAnswerChagne();

         } else {
           $scope.status.questions = 3;
           MyService.ConsoleLog("result", result.data.error);
           growl.error(result.data.error);
         }



       }, function (err) {
         growl.error("Something went wrong");
       });

     };

     $scope.getUserSpecificQues = function () {
       MyService.getUserSpecificQues({
         "uId": $scope.selUserId,
         "curUId": tokenService.getUserId()
       }).then(function (APIResponse) {
         MyService.ConsoleLog("APIResponse Its me", JSON.parse(APIResponse.data.data));
         if (APIResponse.data.success) {

           $scope.status.questions = 2;
           $scope.questions = JSON.parse(APIResponse.data.data);
           //  MyService.ConsoleLog("check Data >>", $scope.questions);
           $rootScope.ques = JSON.parse(APIResponse.data.data);
           $scope.compareUsersAll = APIResponse.data.data.userIds;
           $scope.queIds = $scope.questions.map(function (obj) {
             if (obj != "" && obj != " ") {
               return obj._id;
             }
           });


           //  MyService.ConsoleLog("question ids :", $scope.queIds);
           $scope.flagSkipAnsweredQuestion = true;
           $scope.skipAnswerChagne();

         } else {
           $scope.status.questions = 3;
           MyService.ConsoleLog("result", APIResponse.data.error);
           growl.error(APIResponse.data.error);
         }
         $scope.status.questions = 2;
       }, function (err) {
         MyService.ConsoleLog("Error", err);
       });
     };

     if (!window.localStorage.getItem('isShowSpecialMatch')) {
       $scope.getBallotMatch();
     } else {
       $scope.getUserSpecificQues();
     }




     $scope.skipToMatchOne = function () {
       // alert("welcome");

       if (!window.localStorage.getItem('isShowSpecialMatch')) {
         getCompareAnswers({
           "compareQuestionsAll": JSON.stringify($scope.queIds),
           "compareUsersAll": JSON.stringify($scope.compareUsersAll),
           "type": "skipToResult"
         });
       } else {

         var para = JSON.stringify({
           poliId: $scope.selUserId,
           poliData: JSON.stringify($rootScope.ques)
         });
         window.localStorage.setItem('specialQueAns', para);
         $location.path("/compare-politician-questions");
       }

     };

     $scope.newOne = function (valOne) {
       $scope.importance = valOne;
       $scope.submitAnswer();

     };
     $scope.checkImpExists = function (val) {

       // MyService.ConsoleLog("value :", $scope.importance + "==" + val);

       if ($scope.questions) {
         var obj = $scope.questions[$scope.currentIndex];
         // MyService.ConsoleLog("object :", obj);

         if (obj) {
           var data = $scope.checkQuestion(obj._id);

           if (data && data[0]) {
             // MyService.ConsoleLog("checkIfExists data>> ", data[0].importance + "==" + val);
             if (data[0].my_answer.importance == val) {
               return true;
             }
           }
         }
       }
       return false;
     }

     $scope.checkIfExists = function (val) {
       // MyService.ConsoleLog("answe :>>> :",$scope.answer);

       if ($scope.questions) {
         var obj = $scope.questions[$scope.currentIndex];
         // MyService.ConsoleLog("checkIfExists >> ", obj);
         if (obj) {
           var data = $scope.checkQuestion(obj._id);

           if (data && data[0]) {
             // MyService.ConsoleLog("checkIfExists data>> ", data[0]);
             // $scope.updata.comment = data[0].my_answer.comment;
             if (data[0].my_answer.answer == val) {
               // console.log("return True");
               return true;
             }
           }
         }
       }
       return false;
     }

     $scope.checkQuestionAnswered = function () {
       // alert("called");
       // console.log("scope.questions > ", $scope.questions);
       return $scope.questions.filter(
         function (rdata) {
           // MyService.ConsoleLog(" questions Ids", rdata._id);
           var data = $scope.checkQuestion(rdata._id);
           if (data && data.length > 0) {
             return rdata;
           }
         }
       );
     }

     $scope.checkQuestion = function (val) {
       // MyService.ConsoleLog("value ", val);
       var queLen = $scope.questions.length;
       // alert(queLen);
       // MyService.ConsoleLog("quelen :",queLen);
       for (var i = 0; i < queLen; i++) {
         if ($scope.questions[i].my_answer && $scope.questions[i].my_answer != "") {
           return $scope.questions.filter(
             function (rdata) {
               // MyService.ConsoleLog(" ANs data :", rdata);


               if (rdata.my_answer) {
                 // MyService.ConsoleLog(" ANs data :", rdata.myAnswers.question);
                 if (rdata.my_answer.question == val) {
                   return rdata
                 }
               }
             });

         }
       }
     }


     $scope.cachedQuestions = [];
     // $rootScope.queOneLen = [];
     // $rootScope.queOneLen.length=0;
     $scope.flagSkipAnsweredQuestion = "";
     $scope.skipAnswerChagne = function () {
       // alert("called");
       MyService.ConsoleLog("scope.flagSkipAnsweredQuestion: ", $scope.flagSkipAnsweredQuestion);
       $scope.currentIndex = 0;
       console.log("$scope.questions<><><>", $scope.questions);
       var questions = $scope.checkQuestionAnswered();
       // $rootScope.fiveMatch.length = 0;
       MyService.ConsoleLog('questions >$$$ ', questions);
       // MyService.ConsoleLog('questions  length ', questions.length);
       // $rootScope.queOneLen = questions;
       // MyService.ConsoleLog(' $rootScope.queOneLen ', $rootScope.queOneLen);

       if (questions.length >= 5) {
         // $scope.skipToRes = true;
         // $('#skipMatchOne').attr('disabled', false);
         $scope.skipMatchOne = 2;
       }

       if (questions && questions.length > 0) {
         if ($scope.flagSkipAnsweredQuestion) {
           // $scope.cachedQuestions.length = 0;
           $scope.cachedQuestions = angular.copy($scope.questions);
           for (var i = 0; i < questions.length; i++) {

             var idx = $scope.questions.indexOf(questions[i]);
             // console.log("idx : ", idx);
             if (idx > -1) {
               // console.log("idx > ", idx);
               $scope.questions.splice(idx, 1);
             }
           }
           // console.log("questionslength > ", $scope.cachedQuestions.length + "==" + $scope.questions.length)
           MyService.ConsoleLog("cachedQuestions :", $scope.cachedQuestions);
           // $scope.flagSkipAnsweredQuestion = false;
           if ($scope.cachedQuestions.length == questions.length) {
             // console.log("questionslength Equal");
             if ($scope.questions.length == 0) {
               // alert("if");
               // $scope.questions = angular.copy($scope.cachedQuestions);
               // MyService.ConsoleLog(" $rootScope.fiveMatch one :", $scope.questions);
               // var lents = $rootScope.fiveMatch.length;
               // if (lents >= 5) {
               //   // $scope.skipToRes = true;
               //   $('#skipMatch').attr('disabled', false);
               // }

               //MyService.ConsoleLog("* End of background *");
               var message = "Congratulations, you have answered all the questions that your politicians have answered.";

               var modal = ngDialog.openConfirm({
                 template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(0)">See Match-Ups</button>\
                            <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Edit Answers</button>\
                        </div>',
                 plain: true
               });

               modal.then(function fullfilled(data) {
                 //MyService.ConsoleLog("fullfilled: ", data);
                 //  getCompareAnswers({
                 //    "compareQuestionsAll": JSON.stringify($scope.queIds),
                 //    "compareUsersAll": JSON.stringify($scope.compareUsersAll)
                 //  });
                 if (data == 0) {
                   $scope.skipToMatchOne();
                 } else {
                   $scope.flagSkipAnsweredQuestion = false;
                   $scope.skipAnswerChagne();
                 }
               }, function rejected(data) {
                 //MyService.ConsoleLog("rejected: ", data);

               });
               return;


               $scope.status.questions = 2;
             }
             // $scope.saveFiveMinAnswers("skip");
           } else {
             // alert("esle");
             $rootScope.ques = angular.copy(questions);
             // $scope.questions = angular.copy(questions);
             // MyService.ConsoleLog(" questions", $rootScope.ques)
             // var lent = $rootScope.fiveMatch.length;
             // if (lent >= 5) {
             //   // $scope.skipToRes = true;
             //   $('#skipMatch').attr('disabled', false);
             // }
             // $scope.saveFiveMinAnswers("skipNew");

           }
         } else {
           // alert("if else");
           // $scope.flagSkipAnsweredQuestion = true;
           $scope.questions = angular.copy($scope.cachedQuestions);
         }
       } else {
         // alert("last else");

         // if ($scope.flagSkipAnsweredQuestion) {
         // $scope.flagSkipAnsweredQuestion = false;
         if ($scope.cachedQuestions && $scope.cachedQuestions != "") {
           // alert("welcome");
           $scope.questions = angular.copy($scope.cachedQuestions);
           MyService.ConsoleLog(" $scope.questions :", $scope.questions);

         }

       }
     };

     // $scope.skipAnswerChagne = function() {
     //   MyService.ConsoleLog("$scope.flagSkipAnsweredQuestion: ", $scope.flagSkipAnsweredQuestion);
     //   MyService.ConsoleLog(" $scope.category._id ", $scope.category._id);

     //   $scope.currentIndex = 0;
     //   // $scope.initPageData();
     //   getQuestions({
     //     categoryId: $scope.category._id
     //   });
     // };
     $scope.$watch('questions', function (newValue, oldValue) {
       //MyService.ConsoleLog("*** questions changed ***");
       try {
         MyService.ConsoleLog("Current question: >>>", $scope.questions[$scope.currentIndex]);
         var tempCate = $scope.questions[$scope.currentIndex].categories;

         if ($scope.questions[$scope.currentIndex] && $scope.questions[$scope.currentIndex].my_answer) {
           if ($scope.questions[$scope.currentIndex].my_answer.comment) {
             MyService.ConsoleLog("$scope.updata.comment :>>", $scope.questions[$scope.currentIndex].my_answer.comment);
             $scope.updata.comment = $scope.questions[$scope.currentIndex].my_answer.comment;
           }

         }
         if (angular.isArray(tempCate)) {
           // console.log("IF");
           $scope.getCategory({
             id: tempCate[0].cid
           });
         } else {
           // console.log("ELSE IF");
           $scope.getCategory({
             id: tempCate.cid
           });
         }
         //MyService.ConsoleLog($scope.questions.length + "====" + $scope.currentIndex);
         $scope.initMyAnswer();
       } catch (e) {
         //MyService.ConsoleLog("questions Index Change Ex: ", e)
       }
     });
     $scope.$watch('currentIndex', function (newValue, oldValue) {
       //MyService.ConsoleLog("*** currentIndex changed ***");
       try {
         MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
         var tempCate = $scope.questions[$scope.currentIndex].categories;
         if (angular.isArray(tempCate)) {
           console.log("currentIndex IF");
           $scope.getCategory({
             id: tempCate[0].cid
           });
         } else {
           console.log("currentIndex ELSE IF");
           $scope.getCategory({
             id: tempCate.cid
           });
         }
         //MyService.ConsoleLog($scope.questions.length + "====" + $scope.currentIndex);
         $scope.initMyAnswer();
       } catch (e) {
         //MyService.ConsoleLog("Current Index Change Ex: ", e)
       }
     });
   }])
