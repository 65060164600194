'use strict';
angular.module('myApp.placesShape', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/placesShapes', {
      templateUrl: 'app/placesShape/placesShape.html',
      controller: 'PlacesShapeCtrl'
    });
  }]) .controller('PlacesShapeCtrl', ['$scope', 'ngDialog', '$rootScope', 'MyService', '$http', '$timeout', function ($scope, ngDialog, $rootScope, MyService, $http, $timeout) {

    $scope.gridOptions = {
      enableFiltering: true,
      showGridFooter: true,
      showColumnFooter: false,
    };

    $scope.getPlacesData = function () {
      MyService.getPlaceShape({}).then(function (records) {
        // MyService.ConsoleLog(records);
        // console.log('records for place shape', records);
        var columns = [];
        if (records.data.success) {
          var fdata = records.data.data;
          angular.forEach(fdata[0], function (pd, i) {
            // MyService.ConsoleLog("Field :", i);
            if (i != "__v" && i != "_id") {
              var nc = Object;
              nc.field = i;
              nc.displayName = i;
              nc.width = 100;
              var str = {
                field: i,
                width: '140'
              };
              columns.push(str);
            }

          });
          MyService.ConsoleLog(columns);
          $scope.gridOptions.columnDefs = columns;
          $scope.gridOptions.data = fdata;
        }
      }, function (err) {
        $scope.showLoader = false;
        MyService.ConsoleLog("error : ", err);
      });
    }

    if ($rootScope.showPlacesShape) {
      $scope.getPlacesData();
    }
  }])