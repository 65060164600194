'use strict';
angular.module('myApp.forumthread', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/forumthread/:categoryId/:subcatId/:forumId/:location?', {
      templateUrl: 'app/forumthread/forumthread.html',
      controller: 'forumthreadCtrl'
    });
  }])
  .filter('refineData', ['tokenService', function (tokenService) {
    return function (data, filterBy) {
      //MyService.ConsoleLog("data: ",data," filterBy: ",filterBy);
      var userData = tokenService.getUserData();
      if (userData) {
        userData = JSON.parse(userData);
      }
      if (!filterBy || filterBy == 'all')
        return data;
      var tempArr = [];
      angular.forEach(data, function (val, key) {
        //MyService.ConsoleLog("key: ",key);
        if (filterBy == 'following') {
          if (userData && userData.followlist) {
            if (userData.followlist.indexOf(val.createdBy.id) > -1)
              tempArr.push(val);
          }
        } else {
          if (val.createdBy.utype == filterBy) {
            tempArr.push(val)
          }
        }
      });
      //MyService.ConsoleLog("tempArr: ", tempArr);
      return tempArr;
    };
  }])
  .controller('forumthreadCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', '$anchorScroll', 'ngDialog', 'MyService', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, $anchorScroll, ngDialog, MyService) {
    //MyService.ConsoleLog("** forumCtrl ** :", $routeParams);
    $rootScope.welcome = false;
    $scope.categoryId = $routeParams.categoryId;
    $scope.subcategoryId = $routeParams.subcatId;
    $scope.forumId = $routeParams.forumId;
    $scope.userType = tokenService.getUserRole();
    if (tokenService.getUserData()) {
      $scope.userData = JSON.parse(tokenService.getUserData());
      MyService.ConsoleLog("$scope.userData", $scope.userData);

    }
    $scope.getUserProfile = function () {
      MyService.getProfile($scope.userData.id).then(
        function (userData) {
          $scope.usersData = userData;
          MyService.ConsoleLog('userData: ', $scope.userData);
        },
        function (err) {
          growl.error('Something went wrong while fetching user info.');
        }
      );
    };



    $scope.filterArray = ['all', 'politician', 'voter', 'press', 'advocate', 'following'];
    $scope.sortArray = ['Newest first', 'Since last login'];
    $scope.sortBy = 'Select sort';
    $scope.statusLoading = false;
    $scope.froumthreads = [];
    $scope.mainThread = {};
    $scope.Sticky = {
      ShowOrder: [],
      txtOrder: []
    };
    $rootScope.showSpam = false;
    $scope.page = 1;
    $scope.totalRecords = 0;
    $scope.max_records = 25; // this should match however many results your API puts on one page

    $scope.pagination = {
      current: 1
    };

    $scope.displayReply = true;
    $scope.Reply = {};

    $scope.checkMemId = function (rdata, val) {
      return rdata.filter(
        function (rdata) {

          if (rdata.userData._id == $rootScope.UserId)
            return rdata
        }
      );
    }
    $scope.statusLoading = 0;

    $scope.getForumDetails = function (page) {
      $scope.statusLoading = true;
      if ($scope.userData) {
        var LastLogin = $scope.userData.lastLogin
      } else {
        var LastLogin = "";
      }
      //$scope.totalRecords = 1;
      MyService.getOneTopic({
        topicId: $routeParams.forumId,
        "page": page,
        max_records: $scope.max_records,
        sort_by: $scope.sortBy,
        last_login: LastLogin
      }).then(function (payload) {
        MyService.ConsoleLog(payload);
        $scope.froumthreads = $scope.froumthreads.concat(payload.data);
        //MyService.ConsoleLog($scope.froumthreads);
        $scope.totalRecords = payload.Count;
        if (page == 1) {
          $scope.mainThread = $scope.froumthreads[0];
        }

        $scope.statusLoading = 2;
        $timeout(function () {
          if ($routeParams.location) {
            var id = $location.hash();
            $location.hash($routeParams.location);
            $anchorScroll();
            $location.hash(id);
          }
        }, 500);
      }, function (errorPayload) {
        $scope.statusLoading = 3;
        MyService.ConsoleLog(errorPayload);
        if (errorPayload.data && errorPayload.data.error) {
          alert("Error : " + errorPayload.data.error);
        }

      });
    }

    if ($scope.userData && $scope.userData.id != '') {
      $scope.getUserProfile();
    }

    $scope.SortData = function () {
      MyService.ConsoleLog($scope.sortBy);
      if ($scope.sortBy == 'Since last login') {
        if ($scope.usersData && $scope.usersData.lastLoginOld != '') {
          var lastOldLogIn = new Date($scope.usersData.lastLoginOld);
          // MyService.ConsoleLog("lastOldLogIn", lastOldLogIn);
          var lens = $scope.froumthreads.length;
          for (var i = 0; i < lens; i++) {
            var lastLoginDate = new Date($scope.usersData.lastLogin);
            var createdOn = new Date($scope.froumthreads[i].createdOn);
            // MyService.ConsoleLog("lastOldLogIn.toISOString().split('T')[0] < createdOn.toISOString().split('T')[0]", lastOldLogIn.toISOString().split('T')[0] + "<=" + createdOn.toISOString().split('T')[0])
            if (lastLoginDate <= createdOn) {
              MyService.ConsoleLog("lastLoginDate", lastLoginDate);
              MyService.ConsoleLog("createdOn", createdOn);
              MyService.ConsoleLog("'thread-' + itms._id", 'thread-' + $scope.froumthreads[i]._id);
              $location.hash('thread-' + $scope.froumthreads[i]._id);
              $anchorScroll();
              break;
              // return itms;
            } else if (lastOldLogIn.toISOString().split('T')[0] <= createdOn.toISOString().split('T')[0]) {
              MyService.ConsoleLog("else");
              $location.hash('thread-' + $scope.froumthreads[i]._id);
              $anchorScroll();
              break;
            }
          }
        }
      } else {

        $scope.page = 0;
        $scope.froumthreads.length = 0;
        $scope.getForumDetails($scope.page);
      }
      //alert("Sort Data called");
    }

    $scope.showSpam = [];
    $scope.DisplaySpam = function (id) {
      //alert(id);
      var idx = $scope.showSpam.indexOf(id);
      if (idx == -1) {
        $scope.showSpam.push(id);
      } else {
        $scope.showSpam.splice(idx, 1);
      }
      //MyService.ConsoleLog($scope.showSpam);
    }

    function getSubCategories() {
      var request = apiCall.apiCall('GET', '/categories/list', {
        id: $routeParams.subcatId
      });
      $http(
        request
      ).then(function successCallback(response) {
        if (response.data.success) {
          $scope.subcategory = response.data.data;
        }
      }, function errorCallBack(response) {
        alert("Error: " + response);
      });
    }

    function getCategory() {
      var request = apiCall.apiCall('GET', '/categories/list', {
        id: $routeParams.categoryId
      });
      $http(
        request
      ).then(function successCallback(response) {
        //MyService.ConsoleLog("Got categories: ", response);
        if (response.data.success) {
          $scope.category = response.data.data;
        }
      }, function errorCallBack(response) {
        alert("Error: " + response);
      });
    }

    $scope.updateForumApi = function (data, options, idex) {
      MyService.updateTopic(data).then(function (response) {
        if (response.data.success) {
          if (response.data.data.nModified == 0) {
            alert("You already provided your inputs on this post");
          } else {
            switch (options) {
              case 'like':
                $scope.froumthreads[idex].likes = $scope.froumthreads[idex].likes + 1;
                break;
              case 'dislike':
                $scope.froumthreads[idex].dislikes = $scope.froumthreads[idex].dislikes + 1;
                break;
              case 'sticky':
                $routeParams.location = data.topicId;
                $scope.page = 1;
                $scope.getForumDetails($scope.page);
                break;
              case 'removeSticky':
                $routeParams.location = data.topicId;
                $scope.page = 1;
                $scope.getForumDetails($scope.page);
                break;
              default:
                $scope.froumthreads[idex].spam = $scope.froumthreads[idex].spam + 1;
                break;
            }
          }
        } else {
          if (response.data.error) {
            alert(response.data.error);
            //MyService.ConsoleLog(response.data);
            if ((response.data.status) && (response.data.status == "405")) {
              var msg = "Do you want to send request to Admin to reactivate your account?.";
              MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                MyService.sendActivatationMailtoAdmin().then(function (payload) {
                  alert("Request sent successfully to admin");
                }, function (errorPayload) {
                  alert("Something went wrong");
                })
              }, function (errorPayload) {
                //MyService.ConsoleLog(errorPayload);
              });
            }
          }
        }
      }, function (errorPayload) {
        //MyService.ConsoleLog("Error: ", response);
      });
    }



    $scope.updateForum = function (options, id, forumd) {
      var idex = $scope.froumthreads.indexOf(forumd);
      //alert(idex);
      if (options == "spam") {
        var message = "Are you sure you want to report this comment as spam or abusive?";
        var modal = ngDialog.openConfirm({
          template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
          plain: true
        });
        modal.then(function fullfilled(data) {
          //MyService.ConsoleLog("fullfilled: ", mydata);
          if ($rootScope.UserId) {
            var forumData = {};
            forumData.topicId = id;
            forumData.userId = $rootScope.UserId;
            if ((options == "sticky" || options == "removeSticky") && ($scope.userType == 'admin')) {
              if (options == "sticky") {
                forumData.sticky = 1;
                forumData.stickyOrder = $scope.Sticky.txtOrder[id];
              } else if (options == "removeSticky") {
                forumData.removeSticky = 1;
              }
              $scope.updateForumApi(forumData, options, idex);
            } else if ($scope.froumthreads[idex].createdBy.id != $rootScope.UserId) {
              switch (options) {
                case 'like':
                  forumData.likes = 1;
                  break;
                case 'dislike':
                  forumData.dislikes = 1;
                  break;
                case 'sticky':
                  forumData.sticky = 1;
                  forumData.stickyOrder = $scope.Sticky.txtOrder[id];
                  break;
                case 'removeSticky':
                  forumData.removeSticky = 1;
                  break;
                default:
                  forumData.spam = 1;
                  break;
              }
              $scope.updateForumApi(forumData, options, idex);
            } else {
              alert("You cannot " + options + " your own post");
            }
          } else {
            alert("Please login to perform these actions");
          }
        }, function rejected(data) {
          //MyService.ConsoleLog("rejected: ", data);
        });

      } else {
        if ($rootScope.UserId) {
          var forumData = {};
          forumData.topicId = id;
          forumData.userId = $rootScope.UserId;
          if ((options == "sticky" || options == "removeSticky") && ($scope.userType == 'admin')) {
            if (options == "sticky") {
              forumData.sticky = 1;
              forumData.stickyOrder = $scope.Sticky.txtOrder[id];
            } else if (options == "removeSticky") {
              forumData.removeSticky = 1;
            }
            $scope.updateForumApi(forumData, options, idex);
          } else if ($scope.froumthreads[idex].createdBy.id != $rootScope.UserId) {
            switch (options) {
              case 'like':
                forumData.likes = 1;
                break;
              case 'dislike':
                forumData.dislikes = 1;
                break;
              case 'sticky':
                forumData.sticky = 1;
                forumData.stickyOrder = $scope.Sticky.txtOrder[id];
                break;
              case 'removeSticky':
                forumData.removeSticky = 1;
                break;
              default:
                forumData.spam = 1;
                break;
            }
            $scope.updateForumApi(forumData, options, idex);
          } else {
            alert("You cannot " + options + " your own post");
          }
        } else {
          alert("Please login to perform these actions");
        }

      }
    }

    $scope.submitSticky = function (data, id, idx) {
      if (data > 0) {
        updateForum('sticky', id, idx);
      } else {
        alert("Please enter valid order");
      }
    }

    $scope.ShowReply = false;
    $scope.redirectToReply = function () {
      if (!$rootScope.UserId) {
        $location.path('signin');
      }
      $scope.ShowReply = true;
      $scope.displayReply = false;
      //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.forumId);
    }

    $scope.editDescription = "";

    $scope.editThread = function (item) {
      $scope.editDescription = item;
      $scope.editDescription.description = item.description.replace(" ...(updated)", "");;
      var editModal = ngDialog.open({
        template: 'templateId',
        scope: $scope
      });
    }

    $scope.removeThread = function (mydata) {
      if (!mydata.parent) {
        var message = 'This is a parent thread. Removing it will remove all the comments added to this thread. Do you want to proceed?';
      } else {
        var message = 'Are you sure you want to delete this comment?';
      }

      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.removeSpamTopic({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          //$scope.getSpamTopics(1);
          alert("Topic deleted successfully.");
          if (!mydata.parent) {
            $location.path("/forum/" + $routeParams.categoryId + "/home")
          } else {
            $scope.sortBy = 'Select sort';
            $scope.froumthreads.length = 0;
            $scope.page = 1;
            $scope.getForumDetails($scope.page);
          }
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        //MyService.ConsoleLog("rejected: ", data);
      });
    }


    $scope.notSpam = function (mydata) {
      var message = 'This action will mark this thread as not spam and it will be visible again. Do you want to proceed?';
      var modal = ngDialog.openConfirm({
        template: '<p>' + message + '</p>\
            <div class="ngdialog-buttons">\
                <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">YES</button>\
                <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">NO</button>\
            </div>',
        plain: true
      });
      modal.then(function fullfilled(data) {
        //MyService.ConsoleLog("fullfilled: ", mydata);
        MyService.notSpamTopic({
          "id": mydata._id
        }).then(function (payload) {
          //MyService.ConsoleLog(payload);
          //$scope.UserFormDiv = false;
          $scope.sortBy = 'Select sort';
          $scope.froumthreads.length = 0;
          $scope.page = 1;
          $scope.getForumDetails($scope.page);
          alert("Topic is updated and visible.");
        }, function (error) {
          alert(JSON.stringify(error));
        });
      }, function rejected(data) {
        MyService.ConsoleLog("rejected: ", data);
      });
    }

    $scope.spamMessage = '';
    $scope.spamData = '';
    $scope.dialogClose = "";
    $scope.sendMessage = function (data) {
      $scope.spamData = data;
      $scope.dialogClose = ngDialog.open({
        template: 'sendMessage',
        scope: $scope
      });
    };

    $scope.sendMail = function (msg) {

      if ($scope.spamData.createdBy.id) {
        var msgurl = 'https://votewise.co/#!/forumthread/' + $routeParams.categoryId + '/' + $routeParams.subcatId + '/' + $routeParams.forumId;
        MyService.spamMail({
          "id": $scope.spamData.createdBy.id,
          "msg": msg,
          "msgurl": msgurl
        }).then(function (payload) {
          MyService.ConsoleLog(payload);
          if (payload.data.success) {
            ngDialog.close('sendMessage');
            $scope.dialogClose.close();
            alert("Message send successfully.");
          } else {
            alert(payload.data.data);
          }
        }, function (errorPayload) {
          alert("Something went wrong");
        });
      } else {
        alert("Something went wrong");
      }
      //alert("This is not implemented yet");
    }

    /**
     * Send verification mail
     */

    $scope.sendVeriMail = function () {
      MyService.SendVerificationMail({
        verificationUrl: $rootScope.getVerificationUrl(),
        unsubscribeurl: $rootScope.getEmailUnsubscribeUrl()
      }).then(function (response) {
        MyService.ConsoleLog("response", response);
        if (response.data.success) {
          growl.success("Please check your email");
        }
      }, function (err) {
        MyService.ConsoleLog("err", err);
      });
    };

    $scope.UpdateReply = function (data) {
      //MyService.ConsoleLog(data);
      if (data.description != "") {
        data.topicId = data._id;
        data.parentcat = JSON.stringify(data.parentCat);
        data.location = JSON.stringify(data.location);
        data.subcategories = JSON.stringify(data.subCategories);
        data.description = data.description + " ...(updated)"
        MyService.updateTopic(data).then(function (payload) {
          //MyService.ConsoleLog("payload : ", payload);
          ngDialog.closeAll();
          alert("Comments updated");

        }, function (errorPayload) {
          //MyService.ConsoleLog("errorPayload : ", errorPayload);
        });
      } else {
        alert("Comment should not be empty");
      }
    }

    $scope.showStickyOrder = function (id) {
      $scope.Sticky.ShowOrder[id] = true;
    }

    $scope.goBack = function () {
      $scope.ShowReply = false;
      $scope.displayReply = true;
    }

    $scope.SubmitReply = function (rec) {
      //MyService.ConsoleLog(data);
      //MyService.ConsoleLog($scope.mainThread);
      var data = {};
      if (rec.description != "") {
        $scope.TopicButton = true;
        data.title = "RE: " + $scope.mainThread.title;
        data.parentcat = JSON.stringify($scope.mainThread.parentCat);
        data.subcategories = JSON.stringify($scope.mainThread.subCategories);
        data.createdById = $rootScope.UserId;
        data.createdByName = rec.screenname || $scope.userData.name || $rootScope.User;
        data.createdByType = tokenService.getUserRole();
        data.location = JSON.stringify($scope.mainThread.location);
        data.restrictedTo = $scope.mainThread.restrictedTo;
        data.parent = $routeParams.forumId;
        data.description = rec.description;
        //MyService.ConsoleLog(data);
        var request = apiCall.apiCall('POST', '/topics/create', data);

        $http(
          request
        ).then(function successCallback(response) {
          // MyService.ConsoleLog("reply success");
          $scope.TopicButton = false;
          // MyService.ConsoleLog(response);
          if (response.data.success) {
            //MyService.ConsoleLog("Check the response");
            //MyService.ConsoleLog(response.data);
            $scope.Reply = {
              description: ''
            };
            $scope.ShowReply = false;
            $scope.displayReply = true;
            $scope.mainThread = {};
            $scope.sortBy = 'Select sort';
            $scope.froumthreads.length = 0;
            //$location.path('forumthread/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.forumId);
            $scope.page = 1;
            $scope.getForumDetails($scope.page);
            //$scope.froumthreads.push(response.data.data)
          } else {
            if (response.data.error) {
              alert(response.data.error);
              //MyService.ConsoleLog(response.data);
              if ((response.data.status) && (response.data.status == "405")) {
                var msg = "Do you want to send request to Admin to reactivate your account?.";
                MyService.GetDialogWithButtons(ngDialog, msg, "YES", "NO").then(function (payload) {
                  MyService.sendActivatationMailtoAdmin().then(function (payload) {
                    alert("Request sent successfully to admin");
                  }, function (errorPayload) {
                    alert("Something went wrong");
                  })
                }, function (errorPayload) {
                  //MyService.ConsoleLog(errorPayload);
                });
              }
            }
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ",response);
        });
      }
    }

    $scope.pageChanged = function (newPage) {
      $scope.getForumDetails(newPage);
    };

    $scope.myPagingFunction = function () {
      //MyService.ConsoleLog("scoll called");
      if ($scope.statusLoading != 1) {
        //MyService.ConsoleLog($scope.froumthreads.length);
        //MyService.ConsoleLog($scope.totalRecords);
        if ($scope.froumthreads.length < $scope.totalRecords) {

          $scope.page++;
          $scope.pageChanged($scope.page);
        }

      }
    }

    if ($routeParams.categoryId) {
      getCategory();
      getSubCategories();
      $scope.getForumDetails($scope.page);
    }
  }]);
