'use strict';
angular
  .module('myApp.resetPassword', ['ngRoute'])
  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/resetPassword', {
        templateUrl: 'app/resetPassword/resetPassword.html',
        controller: 'resetPasswordCtrl',
      });
    },
  ])

  .controller('resetPasswordCtrl', [
    '$scope',
    '$http',
    '$location',
    'growl',
    'tokenService',
    'baseApiUrl',
    '$rootScope',
    'MyService',
    function ($scope, $http, $location, growl, tokenService, baseApiUrl, $rootScope, MyService) {
      $scope.UserName = '';
      $scope.Password = '';
      $rootScope.welcome = false;

      $scope.showDrop = false;
      $scope.User = {
        uName: ""
      };


      /**
       * This function called when input box loses focus.
       * function search users with same email address
       */
      $scope.detectEmail = function () {
        MyService.ConsoleLog("forgotEmail", $scope.forgotEmail);
        var postobj = {};
        postobj.email = $scope.forgotEmail;
        MyService.checkEmailForMultiUsers(postobj).then(function (result) {
          if (result) {
            MyService.ConsoleLog("Result", result);
            if (result.data.data.length > 1) {
              MyService.ConsoleLog("IFFFFFFFFFFFFFFFF");
              $scope.showDrop = true;
              $scope.dropData = result.data.data;
              MyService.ConsoleLog("$scope.uName", $scope.uName);
              if ($scope.uName && $scope.uName != "") {

                alert($scope.uName);
              }
            }
          }

        }, function (err) {
          MyService.ConsoleLog("Error", err);
        });
      };

      $scope.requestPasswordReset = function () {
        if ($.fn.validateForceFully($('#forgotPassForm')) == true) {
          var postobj = {
            email: $scope.forgotEmail,
            reset_url: $rootScope.getPasswordResetUrl(),
            checkName: $scope.User.uName ? $scope.User.uName.name : ""
          };
          MyService.ConsoleLog("$scope.uName", $scope.User.uName.name);

          MyService.forgotPassword(postobj).then(
            function (response) {
              MyService.ConsoleLog(response);
              if (response.data.success) {
                growl.success('An email has been sent.');
              } else {
                if (typeof response.data.error == 'string') growl.error(response.data.error);
                else growl.error('Something went wrong');
              }
            },
            function (err) {
              growl.error('Something went wrong');
            }
          );
        }
      };

      $(document).ready(function () {
        console.log('onready');

        $('#forgotPassForm').validationEngine();
      });
    },
  ]);
