'use strict';
angular
  .module('myApp.openStateBills', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/openStateBills', {
        templateUrl: 'app/openStateBills/openStateBills.html',
        controller: 'openStateBillsCtrl'
      });
    }
  ])
  .controller('openStateBillsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    '$timeout',
    '$q',

    function($scope, ngDialog, $rootScope, MyService, $http, $timeout, $q) {
      $scope.loader = false;
      $scope.showDetail = false;
      $scope.more = [];
      $scope.documents = [];
      $scope.activeDoc = true;
      $scope.activeAct = true;

      $scope.activeVot = true;
      $scope.activeSpo = true;

      $scope.getOpenStateBills = function() {
        $scope.loader = true;

        MyService.ConsoleLog('<<<<<<<< Welcome >>>>>>');
        MyService.getBills().then(
          function(data) {
            MyService.ConsoleLog('Data >>>>>>', data);
            $scope.loader = false;
          },
          function(err) {
            console.log('Error >>', err);
          }
        );
      };

      $scope.myPagingFunction = function() {
        MyService.ConsoleLog('scoll called', $scope.statusGetResPg);
        if ($scope.statusGetResPg != 1 && $scope.serachData > $scope.openBills.length) {
          $scope.currPage++;
          $scope.pageChanged($scope.currPage);
        }
      };

      $scope.pageChanged = function(newPage) {
        $scope.getBillsData(newPage);
      };
      $scope.openBills = [];
      $scope.searchCandy = {
        'search': ""
      };

      // $scope.searchMe = function(user) {
      //   // MyService.ConsoleLog('in searchMe', user);
      //   var query = $scope.searchCandy.search.toLowerCase(),
      //     fullname = user.title.toLowerCase();

      //   if (fullname.indexOf(query) != -1) {
      //     return true;
      //   }
      //   return false;
      // };

      $scope.SearchByTitle = function() {
        // MyService.ConsoleLog('in searchMe', $scope.search.sdata);
        // var query = $scope.search.sdata.toUpperCase();
        // $scope.currPage++;

        var pageNumber = 1;
        var params = {
          page: pageNumber,
          max_records: 30,
          title: $scope.searchCandy.search,

        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];

        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();


        MyService.ConsoleLog('in searchMe', $scope.currPage);

        MyService.SearchByTitleOne(params, getAllCanceller).then(
          function(response) {
            MyService.ConsoleLog('response', response);

            $scope.openBills.length = 0;
            $scope.openBills = response.data;
            $scope.serachData = response.count;

            MyService.ConsoleLog('response', $scope.openBills);

          },
          function(error) {
            MyService.ConsoleLog('Error>>', error);
          }
        );
      };

      $scope.getBillsData = function(pageNumber) {
        MyService.ConsoleLog('pageNumber >', pageNumber);
        var params = {
          page: pageNumber,
          max_records: 30
        };
        //MyService.ConsoleLog("params: ", params);
        // $scope.users.data = [];


        $scope.statusGetResPg = 1;

        var getAllCanceller = $q.defer();


        MyService.getOpenBills(params, getAllCanceller).then(
          function(result) {
            console.log("RESPONSE >  ", result);
            $scope.openBills = $scope.openBills.concat(result.data.data.data);
            MyService.ConsoleLog('$scope.open >', $scope.openBills);
            $scope.serachData = result.data.data.count;
            // console.log($scope.committee);
            // $scope.totalUsers = payload.Count;
            // MyService.ConsoleLog(payload.Items);
            $scope.statusGetResPg = 2;
          },
          function(err) {
            growl.error('Something went wrong.');
            $scope.statusGetResPg = 3;
            $scope.page--;
          }
        );
      };

      $scope.getDetails = function(val) {
        MyService.ConsoleLog('params >', val);
        MyService.getBillDetail({ id: val }).then(
          function(response) {
            MyService.ConsoleLog('got data >', response);
            $scope.details = response.data.data;
            $scope.showDetail = true;
          },
          function(err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };
      $scope.back = function() {
        $scope.showDetail = false;
      };

      $scope.getDetailsOfCandidates = function(val) {
        $scope.more[val] = true;
        $scope.documents[val] = true;


      };
      $scope.showLess = function(valOne) {
        $scope.more[valOne] = false;
        $scope.documents[valOne] = false;

      }

      $scope.getBillsData($scope.currPage);
    }
  ]);
