'use strict';
angular.module('myApp.voterSignup', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/votersignup', {
    templateUrl: 'app/voterSignup/voterSignup.html',
    controller: 'voterSignupCtrl'
  });
}])

.controller('voterSignupCtrl', ['$scope','$rootScope','$http','$location','apiCall', 'MyService', function ($scope, $rootScope, $http, $location, apiCall, MyService) {
  $scope.user = {};
  $rootScope.welcome = false;
  $scope.usernameMessage = '';
  $scope.passwordMessage = '';
  $scope.emailMessage = '';
  $scope.taken = true;
  $scope.counties = '';
  $scope.cities = [];
  $scope.zipClicked = "zip-not-clicked";
  var validate = apiCall.constants();

  $scope.makeErrorList = function(response){
    //MyService.ConsoleLog(response.errors);
    var errors = [];
    for (var key in response.errors) {
      if (response.errors.hasOwnProperty(key)) {
        var value = response.errors[key];
        if (value.message !== undefined) {
          errors.push(value.message);
        }
    }
    return errors;
  }
}

function validateUsername (username) {
  $scope.usernameMessage = '';
  var validated = validate.regex.username.test(username);
  if (!validated && username.length > 25 ) {
    $scope.usernameMessage = 'cannot be longer than 25 charectors and can contain (. - _ $ @ * ! )';
    return false;
  }
  if (!validated && username.length < 3 ) {
    $scope.usernameMessage = 'cannot be shorter than 3 charectors and can contain (. - _ $ @ * !)';
    return false;
  }
  if (!validated) {
    $scope.usernameMessage = 'must be between 3 and 25 charectors and can contain (. - _ $ @ * !)';
    return false;
  }
  if (validated) {
    usernameTaken(username);
      if ($scope.taken) {
        return true;
      }
      return false;
  }
  return validated;
}

function validatePassword (password) {
  $scope.passwordMessage = '';
  var validated = validate.regex.password.test(password);
  if (!validated) {
    $scope.passwordMessage = ' must be at least 8 charectors long, contain one number, one uppercase letter and one lowercase letter';
    return false;
  }
  return validated;
}

function validateEmail (email) {
  $scope.emailMessage = '';
  var validated = validate.regex.email.test(email);
  if (!validated) {
    $scope.emailMessage = 'Not a valid email address';
    return false;
  }
  if (validated) {
    emailTaken(email);
      if($scope.taken) {
        return true;
      }
      return false;
  }
  return validated;
}

function validateInputs (username, email, password) {
  var validUsername = validateUsername(username);
  var validEmail = validateEmail(email, $scope);
  var validPassword = validatePassword(password);

  if (validUsername && validEmail && validPassword) {
    return true;
  }
  return false;
}

function usernameTaken(username) {
  MyService.checkUnm({"username": username}).then(function(response){
    if (response.data.taken) {
        $scope.usernameMessage = ' is taken';
        $scope.taken = false;
        return;
    }
    $scope.taken = true;
  }, function(err){
      growl.error("Something went wrong.");
  });
}

var emailTaken = function(email){
  MyService.checkEmail({"email": email}).then(function(response){
    if (response.data.taken) {
        $scope.emailMessage = ' is taken';
        $scope.taken = false;
        return;
    }
    $scope.taken = true;
  }, function(err){
    growl.error("Something went wrong.");
  });
}

$scope.findByZip = function( user ){
  var request = apiCall.apiCall('GET', '/ziplookup/zip/'+ user.zip, undefined);
  $http(
    request
  ).then(function success(response) {
    $scope.counties = response.data.zip.county;
    $scope.cities = response.data.zip.city;
    $scope.zipClicked = 'zip-clicked';
  }, function error(response) {
    //MyService.ConsoleLog(response.data);
  })
}

  $scope.signUp = function( user ){
    $scope.errorMessages = '';
    //MyService.ConsoleLog(user);
    user.county = $scope.county;
    var validated = validateInputs (user.username, user.email, user.password);
    usernameTaken(validated);
    if (user.username && user.password && user.zip && user.email && validated) {
      var data = {
           password: user.password,
           city: user.city,
           street: user.address,
           zip: user.zip,
           username: user.username,
           email: user.email
      };
      var request = apiCall.apiCall('POST', '/user/signup', data);
     $http(
       request
     ).then(function successCallback(response) {
      $location.path('/signin');
    }, function errorCallback(response) {
       var errorList = $scope.makeErrorList(response.data.error);
       if (errorList) {
         $scope.errorMessages = String(errorList);
       }
       else {
          $scope.errorMessages = '';
       }

    });
  }
  else {
    $scope.errorMessages = 'Please fill out all fields.';
  }
 }

}]);
