'use strict';
angular.module('myApp.postOnFaceBook', ['ngRoute', 'angularPayments'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/postOnFaceBook/:fbImg', {
      templateUrl: 'app/postOnFaceBook/postOnFaceBook.html',
      controller: 'postOnFaceBookCtrl'
    });
  }])
  .controller('postOnFaceBookCtrl', ['$scope', '$rootScope', 'growl', 'ngDialog', 'tokenService', 'MyService', '$location', '$window', '$filter', '$cookies','baseApiUrl', function ($scope, $rootScope, growl, ngDialog, tokenService, MyService, $location, $window, $filter, $cookies,baseApiUrl) {

    MyService.ConsoleLog("baseApiUrl",baseApiUrl);
    // if (tokenService.getUserData())

    $scope.retainOptions = "";
    $scope.fb = {
      options: "C"
    };



    $scope.postOnWall = function (message) {
      var body = message;
      var catId = window.localStorage.getItem("cat_id");
      var subCat =window.localStorage.getItem("selCat_id");
      MyService.ConsoleLog("img",$rootScope.fbImage);
     
        var quizLink =
          window.location.origin + '#!/question-background/' + catId + '/' + subCat;
        // if ($cookies.get('quizLinks')) {
        //   // alert('if');
        //   links = $cookies.get('quizLinks');
        //   MyService.ConsoleLog("links  one", links);
        // } else {
        //   // alert('else');
        //   links = 'https://votewise.net/#!/fiveMatch#DisplayQ';
        // }
        var obj = {
          method: 'feed',
          link: quizLink
        };

        setTimeout(function(){
          FB.ui(obj, function (response) {
            MyService.ConsoleLog("response", response);
            // if (!response) {
            growl.success('Posted Successfully',{
              ttl: 800
            });
            if ($cookies.get('quizLinks')) {
              $cookies.remove('quizLinks');
            }
            // }
          });
        }, 4000);
     
    };
    $scope.DisplayFPostLoader = false;
    $scope.postFB = function (message) {

      $scope.DisplayFPostLoader = true;
      $scope.postOnWall(message);
      // FB.getLoginStatus(function (response) {
      //   if (response.status == 'unknown') {
      //     FB.login(function (response) {
      //       MyService.updateFBID({
      //         "facebookid": response.authResponse.userID
      //       }).then(function (payload) {
      //         if (payload.data.error && payload.data.error == "FBDUPLICATE") {
      //           $scope.DisplayFPostLoader = false;
      //           alert("Facebook user doesn't match with your account.");
      //         } else {
      //           $scope.postOnWall(message);
      //         }
      //       }, function (errpayload) {
      //         MyService.ConsoleLog("errpayload", errpayload);
      //         $scope.DisplayFPostLoader = false;
      //         var errmsg = "Facebook is taking too long to respond. Should we try again?"
      //         var modal = ngDialog.openConfirm({
      //           template: '<p>' + errmsg + '</p>\
      //                       <div class="ngdialog-buttons">\
      //               <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">No</button>\
      //               <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
      //           </div>',
      //           plain: true
      //         });
      //         modal.then(function fullfilled(tdata) {
      //           if (tdata == 1) {
      //             $scope.postOnWall(message);
      //           }
      //         }, function rejected(data) {
      //           //MyService.ConsoleLog("rejected: ", data);
      //         });
      //         // alert("Facebook is taking too long to respond. Should we try again?");
      //       });
      //     }, {
      //       scope: 'public_profile,email,publish_actions',
      //       return_scopes: true
      //     });
      //   } else {
      //     if ($scope.userData && $scope.userData.facebookid) {
      //       if (response.authResponse.userID == $scope.userData.facebookid) {
      //         $scope.postOnWall(message);
      //       } else {
      //         $scope.DisplayFPostLoader = false;
      //         alert("Facebook user doesn't match with your account.");
      //       }
      //     } else {
      //       $scope.postOnWall(message);
      //     }
      //   }
      // });
    }

    $scope.checkSSR = function(){
      // MyService.ConsoleLog("check", $rootScope.OldCatforFB.fbImage);
      // var url = "https://api.votewise.net/resources/stop-and-frisk-2-(1-of-1)-1488802259580.jpg";
      var url = window.localStorage.getItem('fbImg');
      var catId = window.localStorage.getItem("cat_id");
      var subCat =window.localStorage.getItem("selCat_id");

      // $("head > meta[property='og:image']").attr('content', url);
      MyService.checkMetaWithSSR({imgUrl:url,
      catId:catId,
    subCatId:subCat}).then(function(APIResponse){
        MyService.ConsoleLog("Response",Response);
      },function(err){
        MyService.ConsoleLog("Error inside",err);
      });
    };
  }]);
