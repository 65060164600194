'use strict';
angular.module('myApp.advocateHome', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/advocateHome', {
        templateUrl: 'app/advocateHome/advocateHome.html',
        controller: 'advocateHomeCtrl'
    });
}])

.controller('advocateHomeCtrl', ['$scope','$rootScope','baseApiUrl', 'MyService', 'growl', function($scope, $rootScope, baseApiUrl, MyService, growl) {
    //MyService.ConsoleLog("DebateHome called");
    $scope.imgUrl = baseApiUrl + "/resources";
    $rootScope.welcome = false;
    $scope.statusGetEssayCat = 0;
    $scope.getCategories = function() {
        /*var request = apiCall.apiCall('GET', '/categories/list', {root: 1});
	  $http(
	    request
	  ).then(function successCallback(response) {
		//MyService.ConsoleLog("Got categories: ",response);
		if(response.data.success){
			$scope.categories = response.data.data;
		}
		else{
			//MyService.ConsoleLog("Something went wrong: ",response.data);
		}
	  }, function errorCallBack(response) {
	    //MyService.ConsoleLog("Error: ",response);
	  });*/
		$scope.categories = [];
		$scope.statusGetEssayCat = 1;
        //MyService.ConsoleLog("params----");
        
        MyService.getCategories({ root: 1, catTypes: "E" }, null).then(function(response) {
            if (response.data.success) {
                $scope.categories = response.data.data;
                //MyService.ConsoleLog($scope.categories);
                $scope.statusGetEssayCat = 2;
            } else {
                //MyService.ConsoleLog("Error: ", response.error);
                growl.error("Something went wrong: ");
                $scope.statusGetEssayCat = 3;
            }
        }, function() {
            growl.error("Something went wrong while fetching categories.");
            $scope.statusGetEssayCat = 3;
        });
    }
    $scope.getCategories();
}]);
