'use strict';

angular.module('myApp.otherPoliticians', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/otherPoliticians', {
      templateUrl: 'app/otherPoliticians/otherPoliticians.html',
      controller: 'otherPoliticiansCtrl'
    });
  }])
  .controller('otherPoliticiansCtrl', ['$scope', '$rootScope', 'MyService',
    '$http', '$filter', 'growl', '$q', '$cookies', 'apiCall', '$anchorScroll', '$location',
    function ($scope, $rootScope, MyService, $http, $filter, growl, $q, $cookies, apiCall, $anchorScroll, $location) {
      $rootScope.welcome = false;
      $scope.search = {
        pName: '',
        level: '',
        curState: '',
        county: '',
        city: '',
        chamber: '',
        party: '',
        isOffice: ''
      };
      $scope.showLoader = false;
      $scope.loader = false;
      $scope.allState = [];
      $scope.address = {
        state: "",
        upstate: "",
        files: ""
      };
      $scope.countyone = false;
      $scope.cityone = false;
      $scope.showChamber = false;

      /**
       * @description Function is use to show all state data.
       */
      $scope.getAllStates = function () {
        $scope.showLoader = true;
        $scope.allState.length = 0;

        if ($scope.search.level == 'federalCongressionalDistrict') {
          $scope.showChamber = true;

        } else if ($scope.search.level == 'statePostalCode') {
          $scope.showChamber = true;
        }
        // console.log("address.state", $scope.address.state);
        MyService.getAllStates().then(
          function (payload) {
            MyService.ConsoleLog("payload : ", payload);
            $scope.allState = payload;
            $scope.showLoader = false;
          },
          function (errorPayload) {
            growl.error("Something went wrong.");
            $scope.showLoader = false;
          }
        );
      };

      /**
       * End of getAllState
       */


      /**
       * @description Function is use to search politicians
       */

      $scope.searchPoliticians = function () {
        var newHash = 'compareContent';

        $scope.poliData = '';
        if (!$filter("isBlankString")($scope.search.pName) || !$filter("isBlankString")($scope.search.level) || !$filter("isBlankString")($scope.search.chamber) || !$filter("isBlankString")($scope.search.curState) || !$filter("isBlankString")($scope.search.party)) {
          $scope.loader = true;

          MyService.searchPoliticiansWith($scope.search).then(function (response) {
            MyService.ConsoleLog("response: ", response);
            if (response && response.length > 0) {

              $scope.poliData = response;
              if (!$cookies.get('params')) {
                $cookies.put('params', JSON.stringify($scope.search));
              } else {
                $cookies.remove('params');
              }
              if ($location.hash() !== newHash) {
                $location.hash('compareContent');
              } else {
                $anchorScroll();
              }
              $scope.loader = false;

              if ($scope.search.level == 'federalCongressionalDistrict') {
                $scope.showChamber = true;

              } else if ($scope.search.level == 'statePostalCode') {
                $scope.showChamber = true;
              }
            } else {
              $scope.loader = false;
              growl.error(response);

            }

          }, function (err) {
            console.log("Error", err);
            growl.error(err);
            $scope.loader = false;

          });
        } else {
          growl.error("Please enter politician name");
          $scope.loader = false;
        }
      };

      /**
       * End of searchPoliticians
       */

      $scope.getAllStates();
      MyService.ConsoleLog("$cookies.get()", $cookies.get('params'));
      if ($cookies.get('params')) {
        console.log("data", JSON.parse($cookies.get('params')));
        $scope.search = '';
        $scope.search = JSON.parse($cookies.get('params'));
        $scope.searchPoliticians();
        // $cookies.remove('params');
      }


      /**
       * @description Get  County within selected state
       */
      var getCountiesCanceler = $q.defer();
      $scope.getCounties = function () {
        // alert("first");
        // MyService.ConsoleLog("$scope.User.cur_state: ", $scope.User.cur_state)
        // MyService.ConsoleLog("old",$scope.oldaddress);
        $scope.counties = "";
        var statePostalCode = "";

        if ($scope.search.curState && $scope.search.curState.stateFips) {
          statePostalCode = $scope.search.curState.stateFips;
        }

        MyService.ConsoleLog("statePostalCode", statePostalCode);

        var request = apiCall.apiCall("POST", "/user/getAllCounties", {
          statePostalCode: statePostalCode,
          timeout: getCountiesCanceler.promise
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              MyService.ConsoleLog("response", response.data.data);
              $scope.counties = response.data.data;
              console.log("$scope.alllcounties>>", $scope.counties);
              // if (lbl == "new") {
              if ($scope.search.level == "placeName") {
                $scope.countyone = true;
                $scope.showLoader = false;
              }

              if ($scope.search.level == "Other") {
                $scope.countyone = true;
                $scope.showLoader = false;
              }

              if ($scope.search.level == "countyName") {
                $scope.countyone = true;
                $scope.showcur_office_nameDrop = true;
                $scope.showLoader = false;
              }
              $scope.address.county = $scope.counties;
              MyService.ConsoleLog("$scope.address.county >", $scope.address.county);
              // }
            } else {
              growl.error("Something went wrong.");
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
          }
        );
      };

      /**
       * @description Get Cities within county
       */
      $scope.getSubCounties = function () {
        $scope.arr = [];
        $scope.dis = false;

        // $scope.countyone = false;

        $scope.allsubcounty = "";
        $scope.places = "";

        MyService.ConsoleLog("$scope.state >>>", $scope.search.curState);
        MyService.ConsoleLog("$scope.County >>>", $scope.address.county);
        if ($scope.search.level == "placeName" || $scope.search.level == "Other") {
          $scope.showLoaderOne = true;
          // $scope.LoaderTwo = false;
        }


        MyService.getPlacesAndSubCounty($scope.search.curState.stateFips, $scope.address.county.countyFips, $scope.search.curState.state)
          .then(function (response) {
            MyService.ConsoleLog("response >>", response);

            var sub = response.countySubdivision.filter(function (val) {
              var cmat = val.city.substring(val.city.length - 3);
              if (cmat != 'CDP') {
                return val;

              }
            });

            $scope.allsubcounty = sub;

            var plFil = response.places.filter(function (item) {
              MyService.ConsoleLog('item', item);
              var smTxt = item.city.substring(item.city.length - 3);
              if (smTxt != 'CDP') {
                return item;
                MyService.ConsoleLog("smTxt >>", smTxt);

              }

            });
            MyService.ConsoleLog("plFil >>", plFil);
            $scope.finArr = $scope.allsubcounty.concat(plFil);
            MyService.ConsoleLog("$scope.arr", $scope.finArr);

            $scope.places = $scope.finArr;
            if ($scope.search.level == "placeName") {
              $scope.cityone = true;

            }
            $scope.showLoaderOne = false;

          }, function (error) {
            MyService.ConsoleLog("Error >>", error);
          });

      }

      /**
       * @description Reset all drop downs
       */
      $scope.resetDropDown = function () {
        $scope.cityone = false;
        $scope.countyone = false;
        $scope.poliData = '';
        // $scope.showChamber = false;
        $scope.places = '';
        $scope.address.county = '';
        $scope.getAllStates();
        if ($scope.search.level == 'federalCongressionalDistrict') {
          $scope.showChamber = true;

        } else if ($scope.search.level == 'statePostalCode') {
          $scope.showChamber = true;
        }
      };

      /**
       * @description Filters politicians data
       */
      $scope.filterItems = function () {
        return function (u) {
          // if (!u.name.includes('test') && !u.name.includes('Test') && !u.name.includes('jack')) {

          if ($scope.search.level == 'federalCongressionalDistrict' && !$scope.search.chamber) {
            if (u.office && u.office != '') {
              if (u.office == 'u.s. senate' || u.office == 'u.s. house') {
                u.voterScope = 'federalCongressionalDistrict';
                return u;
              }
            }
          } else if ($scope.search.level == 'federalCongressionalDistrict' && $scope.search.chamber == 'senate') {

            if (u.office && u.office != '') {
              if (u.winDetails && u.winDetails.length > 0) {
                if (u.winDetails[0].office == 'u.s. senate' || u.winDetails[0].office == 'U.S. Senate') {
                  u.voterScope = 'federalCongressionalDistrict';
                  return u;
                }
              } else if (u.office == 'u.s. senate') {
                u.voterScope = 'federalCongressionalDistrict';
                return u;
              }
            }
          } else if ($scope.search.level == 'federalCongressionalDistrict' && $scope.search.chamber == 'house') {

            if (u.office && u.office != '') {
              if (u.office == 'u.s. house') {
                u.voterScope = 'federalCongressionalDistrict';
                return u;
              } else if (u.winDetails && u.winDetails[0].office.toLowerCase() == 'u.s. house') {
                u.voterScope = 'federalCongressionalDistrict';
                return u;
              }
            }
          } else if ($scope.search.level == 'statePostalCode' && $scope.search.chamber == 'senate') {
            if (u.office && u.office != '') {
              if (u.office == 'state senate') {

                return u;
              }
            }
          } else if ($scope.search.level == 'statePostalCode' && $scope.search.chamber == 'house') {
            if (u.office && u.office != '') {
              if (u.office == 'state house') {

                return u;
              }
            }
          } else {
            return u;
          }
          // }
          // }
        };
      };

      /**
       * @description Reset All the fields of search.
       *
       */
      $scope.resetSearch = function () {
        $scope.search = {
          pName: '',
          level: '',
          curState: '',
          county: '',
          city: '',
          chamber: '',
          party: '',
          isOffice: ''
        };
        $scope.cityone = false;
        $scope.countyone = false;
        $scope.showChamber = false;

      };
    }
  ]);
