"use strict";
angular
  .module("myApp.compareEssayAns", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/compareEssayAns", {
        templateUrl: "app/compareEssayAns/compareEssayAns.html",
        controller: "compareEssayAnsCtrl",
      });
    },
  ])
  .controller("compareEssayAnsCtrl", [
    "$scope",
    "$http",
    "$location",
    "apiCall",
    "$routeParams",
    "$rootScope",
    "$q",
    "$timeout",
    "tokenService",
    "MyService",
    "$anchorScroll",
    "$cookies",
    "ngDialog",
    "growl",
    "$window",
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $rootScope,
      $q,
      $timeout,
      tokenService,
      MyService,
      $anchorScroll,
      $cookies,
      ngDialog,
      growl,
      $window
    ) {
      $scope.ansTypes = [];
      $scope.aType = "short";

      window.localStorage.setItem("isShowOldResult", true);
      $scope.loader = false;
      $scope.getEssayQuestions = function () {
        MyService.getFiveMinEssayQues().then(
          function (APIReponse) {
            var data = $scope.data.candidates.filter(function (val) {
              $scope.candidates.filter(function (item) {
                if (val._id == item._id) {
                  val = val;
                  val.total_answers = item.total_answers;
                  val.total_endorsements = item.total_endorsements;
                  val.total_reviews = item.total_reviews;
                  val.total_tagged = item.total_tagged;
                }
              });

              return val;
            });
            MyService.ConsoleLog("data check >> ", data);

            $scope.showCandidates = data;
            $scope.fiveMinQuestion = APIReponse;

            MyService.ConsoleLog(
              "$scope.fiveMinQuestion > ",
              $scope.fiveMinQuestion
            );
            $scope.loader = false;
          },
          function (err) {
            growl.error(err);
          }
        );
        $scope.getEssayAns("short");
      };

      $scope.getEssayAns = function (type) {
        $scope.showShort = true;
        $scope.showMedium = true;
        $scope.showLong = true;

        if (!type) {
          $scope.type = "short";
        } else {
          $scope.type = type;
        }
        $scope.ansTypes = [];
        $scope.aType = type;
      };

      $scope.isShowEssayAns = function (user, que) {
        var isData = $scope.essayAns[user._id].filter(function (ans) {
          if (ans.question == que._id) {
            if ($scope.type == "short" && ans.shortAns != "") {
              return ans;
            } else if ($scope.type == "medium" && ans.mediumAns != "") {
              return ans;
            } else if ($scope.type == "long" && ans.longAns != "") {
              return ans;
            }
          }
        });
        if (isData && isData.length > 0) {
          return true;
        } else {
          return false;
        }
      };

      $scope.getCandidate = function (val) {
        var params = {
          eDate: val,
          extradata: JSON.parse(window.localStorage.getItem("extraData")),
        };
        MyService.getCandidateListing(params).then(
          function (APIResponse) {
            MyService.ConsoleLog("APIResponse >> ", APIResponse);
            $scope.candidates = APIResponse.data.data;
            $scope.getEssayQuestions();
          },
          function (err) {
            MyService.ConsoleLog("get candi >> ", err);
          }
        );
      };

      $scope.goToPoliticianThread = function (officename, users, userData) {
        //console.log("goToPoliticianThread: ", users, " officename: ", officename, " userData: ", userData);
        $rootScope.politicianThreadData = {
          // office: officename,
          users: users,
          selected_user: userData,
        };
        window.localStorage.setItem(
          "politicianThreadData",
          JSON.stringify($rootScope.politicianThreadData)
        );
        $location.path("/politician-thread/" + userData._id);
      };

      $scope.compareRatings = function (users) {
        //MyService.ConsoleLog("users > ", users);
        $rootScope.selectedRatingUsers = [];
        angular.forEach(users, function (val, key) {
          //MyService.ConsoleLog("key123: ", key);
          //MyService.ConsoleLog(" val321: ", val);
          if (val.total_reviews > 0) {
            // alert("Here");
            console.log("valpoliticianIds >> ", val.politicianIds);
            if (!val.politicianIds) {
              var pids = [];
              pids.push(val._id);
              val["politicianIds"] = pids;
              console.log("val >>> ", val);
            }
            $rootScope.selectedRatingUsers.push(val);
          }
        });
        //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
        if ($rootScope.selectedRatingUsers.length <= 0) {
          growl.error("None of the users you selected have ratings.");
        } else {
          $location.path("/compareRatings");
        }
      };

      // $scope.compareEndorsement = function (users) {

      // };

      if (window.localStorage.getItem("compareAnsData")) {
        $scope.loader = true;
        $scope.data = JSON.parse(window.localStorage.getItem("compareAnsData"));
        MyService.ConsoleLog(" values >> ", $scope.data);
        $scope.essayAns = $scope.data.essayAnswers;
        if (window.localStorage.getItem("eleDate")) {
          MyService.ConsoleLog(
            "eleDate >> ",
            window.localStorage.getItem("eleDate")
          );
          $scope.getCandidate(window.localStorage.getItem("eleDate"));
        }
      }
    },
  ]);
