'use strict';
angular.module('myApp.whatAmILookingAt3', ['ngRoute'])

  .config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/whatAmILookingAt3', {
      templateUrl: 'app/whatAmILookingAt3/whatAmILookingAt3.html',
      controller: 'whatAmILookingAt3Ctrl'
    });
  }])
  .controller('whatAmILookingAt3Ctrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'tokenService', 'MyService', function($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, tokenService, MyService) {

  }]);
