'use strict';
angular.module('myApp.compare_politician_questions', ['ngRoute', 'ngDialog'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/compare-politician-questions', {
      templateUrl: 'app/compare-politician-questions/compare-politician-questions.html',
      controller: 'comparePoliticianQuestionsCtrl'
    });
  }])

  .controller('comparePoliticianQuestionsCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', 'tokenService', '$anchorScroll', '$timeout', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, tokenService, $anchorScroll, $timeout) {
    //MyService.ConsoleLog("** politician-compare ** :", $routeParams);
    $scope.FBCompareList = [];
    $scope.AnswerArray = [];
    $scope.AnswerArray[1] = "Very Strongly Agree";
    $scope.AnswerArray[2] = "Agree";
    $scope.AnswerArray[3] = "Somewhat Agree";
    $scope.AnswerArray[4] = "Somewhat Disagree";
    $scope.AnswerArray[5] = "Disagree";
    $scope.AnswerArray[6] = "Very Strongly Disgree";
    $scope.AnswerImpArray = [];
    $scope.AnswerImpArray[1] = "Somewhat Important";
    $scope.AnswerImpArray[2] = "Important";
    $scope.AnswerImpArray[3] = "Very Important";
    $scope.Math = window.Math;
    $rootScope.welcome = false;
    $scope.showAlert = false;
    window.localStorage.removeItem('qData');
    $scope.checkMyOrder = function (frnd) {
      //MyService.ConsoleLog($scope.ord.Option);
      switch ($scope.ord.Option) {
        case "BW":
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "W":
          return ($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
        case "BS":
          return -($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        case "S":
          return ($scope.FBunWeighted[frnd.id] / $scope.fbqlen[frnd.id]);
          break;
        default:
          return -($scope.fbcombinedWeight[frnd.id] / $scope.fbmultiplier[frnd.id]);
          break;
      }
    }
    $scope.status = 0;
    $scope.showSpecial = false;

    /** 
     * 
     * @description Get front runners records from locaStorage. 
     */


    $scope.frontRunners = JSON.parse(window.localStorage.getItem('frontRunners'));


    $scope.nextCat = function () {

      $scope.rootCatIndex = window.localStorage.getItem("RootCatIndex");
      MyService.ConsoleLog($scope.rootCatIndex);
      if ($scope.rootCatIndex < ($rootScope.categories.length - 1)) {
        $location.path("/question-compare/all/" + $rootScope.categories[$scope.rootCatIndex]._id);
      }

    }

    $scope.isObjectEmpty = function (card) {
      return Object.keys(card).length === 0;
    }

    $scope.CompareWithAllPolitcians = function () {
      $scope.status = 1;
      //MyService.ConsoleLog($scope.selection);
      //MyService.ConsoleLog("ComapreWithSelected");
      //MyService.ConsoleLog($rootScope.OldCatforFB._id);
      //MyService.ConsoleLog(window.localStorage.getItem("selectedCompareUsers"));
      var obj = JSON.parse(window.localStorage.getItem("caompareAll"));

      MyService.ConsoleLog("Object", obj);
      if (obj.compareUsersAll.length > 0 && obj.compareQuestionsAll.length > 0) {
        $scope.FBfreinds = JSON.parse(obj.compareUsersAll);
        var frdlist = JSON.stringify(obj.compareUsersAll);
        // MyService.ConsoleLog(frdlist);
        //MyService.ConsoleLog($routeParams.categoryId);
        MyService.CompareWithAllPolitcians({
          "frds": frdlist,
          "categoryIds": obj.compareQuestionsAll
        }).then(function (payload) {
          MyService.ConsoleLog("payload :", payload);
          $scope.myObject = payload.data.data.answeredQuestions;

          if ($scope.isObjectEmpty(payload.data.data.answeredQuestions)) {

            MyService.ConsoleLog(" Welcome to if");

            $scope.showAlert = true;

            // setTimeout(function() {

            //   $scope.showAlert = true;


            // }, 3000);

          }
          $scope.FBCompareList.length = 0;
          if (payload.data.success) {
            $scope.status = 0;
            // MyService.ConsoleLog(payload.data.data);
            $scope.FBCompareList = payload.data.data.qdata;
            if ($scope.FBCompareList.length > 0) {
              $scope.FBunWeighted = payload.data.data.unweightedMatch;
              $scope.fbcombinedWeight = payload.data.data.fbcombinedWeight;
              $scope.fbmultiplier = payload.data.data.fbmultiplier;
              $scope.answeredQuestions = payload.data.data.answeredQuestions;
              $scope.fbqlen = payload.data.data.qlen;
              $scope.AllUsers = payload.data.data.AllUsers;
              $scope.qData = payload.data.data.qdata;

              var allkeys = Object.keys($scope.answeredQuestions);
              // MyService.ConsoleLog("check now ", allkeys);
              $scope.isShowAsterix = {};
              $scope.frontRunners.filter(function (itms) {
                if (allkeys.indexOf(itms._id) > -1) {
                  $scope.isShowAsterix[itms._id] = true;
                }
              });


              window.localStorage.setItem('qData', JSON.stringify($scope.qData));
            } else {
              alert("Looks like your freinds not yet answered the questions.");
              //MyService.ConsoleLog($scope.FBCompareList);
            }
          } else {
            $scope.status = 0;
            alert("Something went wrong.");
          }
        }, function (errorpayload) {
          alert("Something went wrong.");
        })
      } else {
        alert("Please select at least one Friend to compare with");
      }
    }

    $scope.getOnePoliMatch = function (params) {
      MyService.ConsoleLog(" params", params);
      params.uId = tokenService.getUserId();
      // MyService.ConsoleLog('$rootScope.resultOfAns >> ', $rootScope.resultOfAns);
      MyService.getPoliMatch(params).then(function (result) {
        MyService.ConsoleLog("Results", result);
        // if (result && result.data.data && result.data.data.qdata) {
        $scope.questions = result.data.qdata;
        $scope.FBCompareList = result.data.qdata;

        if ($scope.FBCompareList && $scope.FBCompareList.length > 0) {
          $scope.showSpecial = true;

          $scope.FBunWeighted = result.data.unweightedMatch;
          $scope.fbcombinedWeight = result.data.fbcombinedWeight;
          $scope.fbmultiplier = result.data.fbmultiplier;
          $scope.answeredQuestions = result.data.answeredQuestions;
          $scope.fbqlen = result.data.qlen;
          $scope.AllUsers = result.data.AllUsers;
          $scope.poliName = result.data.poliName;
          $scope.poliInfo = result.data.poliInfo;
          // $scope.showcomparison = true;
          var allkeys = Object.keys($scope.answeredQuestions);
          // MyService.ConsoleLog("check now ", allkeys);
          $scope.isShowAsterix = {};
          $scope.frontRunners.filter(function (itms) {
            if (allkeys.indexOf(itms._id) > -1) {
              $scope.isShowAsterix[itms._id] = true;
            }
          });

          $scope.status = 0;
          MyService.ConsoleLog("$scope.AllUsers ", $scope.AllUsers);
          MyService.ConsoleLog(" $scope.FBunWeighted", $scope.FBunWeighted);
          window.localStorage.setItem('qData', JSON.stringify($scope.questions));
        }
        // } else {
        //   // growl.error(result.data.data.poliName + ' not answered the quiz yet');
        // }

      }, function (err) {
        if (err) {
          // $scope.Popup();
        }
        MyService.ConsoleLog("Error", err);
      });
    };
    if (!window.localStorage.getItem('isShowSpecialMatch')) {
      $scope.CompareWithAllPolitcians();
    } else {
      var para;
      if (window.localStorage.getItem('specialQueAns')) {
        para = JSON.parse(window.localStorage.getItem('specialQueAns'));
      }
      $scope.status = 1;
      $scope.getOnePoliMatch(para);
    }


    MyService.ConsoleLog("  $rootScope.catsId :", $rootScope.catsId);
    $scope.showDetails = function () {

      $location.path("question-compare-politician/" + $rootScope.catsId);
      // $rootScope.catsId = "";

    };
  }]);
