'use strict';
angular.module('myApp.questionCompare', ['ngRoute', 'ngDialog'])
  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/question-compare/:categoryId/:parentId?', {
      templateUrl: 'app/questionCompare/questionCompare.html',
      controller: 'questionCompareCtrl'
    });
  }]).controller('questionCompareCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$q', 'ngDialog', '$rootScope', 'growl', 'MyService', '$anchorScroll', '$timeout', 'tokenService', function ($scope, $http, $location, apiCall, $routeParams, $q, ngDialog, $rootScope, growl, MyService, $anchorScroll, $timeout, tokenService) {
    //MyService.ConsoleLog("** questionCompareCtrl ** :", $routeParams);
    //MyService.ConsoleLog("$rootScope.selectedCompareUsers: ", $rootScope.selectedCompareUsers);
    $rootScope.welcome = false;
    $scope.traverseBackgrounds = [];
    $scope.currBackgroundIndex = 0;
    $scope.UserId = tokenService.getUserId();
    $scope.status = {
      questions: 0, //1=loading, 2=loaded, 3=error
      category: 0
    };

    $scope.currentIndex = 0;

    var getCategoryCanceler = $q.defer();
    var prevFetchedCategoryId = null;

    $scope.getCategory = function (params) {
      try {
        prevFetchedCategoryId = $scope.category._id
      } catch (e) {}

      //MyService.ConsoleLog("prevFetchedCategoryId: ", prevFetchedCategoryId);
      //MyService.ConsoleLog("params.id: ", params.id);
      if (prevFetchedCategoryId != params.id) {
        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        getCategoryCanceler.resolve();
        getCategoryCanceler = $q.defer()
        $scope.status.category = 1;
        // alert(" category called");
        MyService.getCategories(params, getCategoryCanceler.promise).then(function (response) {
          //MyService.ConsoleLog("-----------------------------");
          //MyService.ConsoleLog("response: ", response);
          //MyService.ConsoleLog("-----------------------------");
          if (response) {
            if (response.data.success) {
              $scope.category = response.data.data;
              //MyService.ConsoleLog("category data: ", $scope.category);
              prevFetchedCategoryId = $scope.category._id;
              $scope.status.category = 2;
              //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
            } else {
              //MyService.ConsoleLog("Something went wrong: ", response.data);
              $scope.status.category = 3;
              //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
            }
          } else {
            $scope.status.category = 2;
            //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
          }
        }, function (err) {
          //MyService.ConsoleLog("Error: ", err);
          $scope.status.category = 3;
          //MyService.ConsoleLog("$scope.status: ", $scope.status.category);
        });
      }
    };

    $scope.rootCatIndex = 0;
    $scope.getRootCategoryIndex = function () {
      if ($routeParams.categoryId == 'all') {
        var len = $rootScope.categories.length;
        for (var i = 0; i < len; i++) {
          if ($routeParams.parentId == $rootScope.categories[i]._id) {
            $scope.rootCatIndex = i;
            MyService.ConsoleLog("rootCatIndex : ", $scope.rootCatIndex);
            break;
          }
        }
      }
    };

    var getLevelCategoryCanceler = $q.defer();

    function getLevelCategory(params) {
      getLevelCategoryCanceler.resolve();
      $scope.status.levlCat = 1;
      params.timeout = getLevelCategoryCanceler.promise;
      var request = apiCall.apiCall('GET', '/categories/list-layer', params);
      $http(
          request
        )
        .then(function successCallback(response) {
          MyService.ConsoleLog("Got categories: ", response);
          if (response.data.success) {
            $scope.traverseBackgrounds = response.data.data;
            if ($routeParams.selectedCatId) {
              angular.forEach(response.data.data, function (val, key) {
                if (val._id == $routeParams.selectedCatId) {
                  $scope.currBackgroundIndex = key;
                  $scope.category = val;
                }
              });
            } else if ($scope.traverseBackgrounds.length > 0) {
              $scope.category = $scope.traverseBackgrounds[0];
            }
            $scope.status.levlCat = 2;
          } else {
            //MyService.ConsoleLog("Something went wrong: ", response.data);
            $scope.status.levlCat = 3;
          }
        }, function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
          $scope.status.levlCat = 3;
        });
    }

    $scope.skipQuestionOne = function () {

      var skip = function () {
        var tempIndex = $scope.currentIndex + 1;

        if (tempIndex < $scope.questions.length) {
          $scope.currentIndex++;

          if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
            // //we are showing questions of most popular section
            $scope.getCategory({
              id: $scope.questions[$scope.currentIndex].categories.cid
            });
          }
        }
        $("html, body").animate({
          scrollTop: $("#DisplayQ").offset().top - 280
        }, "slow");
        //MyService.ConsoleLog("tempIndex = ", tempIndex, "$scope.questions.length ",$scope.questions.length);
        if ((tempIndex == $scope.questions.length) || $scope.questions.length == 0) {
          //MyService.ConsoleLog("end of questions switch background");
          var tempBackIdx = $scope.currBackgroundIndex + 1;
          if (tempBackIdx < $scope.traverseBackgrounds.length) {
            $scope.currBackgroundIndex++;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          } else {
            $scope.rootCatIndex++;
            // alert("Else");
            window.localStorage.setItem("RootCatIndex", $scope.rootCatIndex);
            window.localStorage.setItem("DisplayCategory", JSON.stringify($scope.category));
            //MyService.ConsoleLog("/politician_compare/" + $routeParams.categoryId + "/" + $routeParams.parentId);
            if ($scope.UserId && $scope.UserId != "") {
              $location.path("/politician_compare/" + $routeParams.categoryId + "/" + $routeParams.parentId);
            } else {
              // alert("no more answered questions");
              $location.path("/compare-subject/politician");
            }
          }
        }
      };

      skip();
    };

    $scope.prevQuestion = function () {
      //MyService.ConsoleLog("** prevQuestion **");
      // $timeout(function () {
      //   $location.hash('DisplayQ');
      //   $anchorScroll();
      // }, 1500);
      $("html, body").animate({
        scrollTop: $("#DisplayQ").offset().top - 280
      }, "slow");
      if ($scope.currentIndex > 0) {
        $scope.currentIndex--;
      } else if ($scope.currentIndex == 0) {
        if ($scope.currBackgroundIndex > 0) {
          $scope.currBackgroundIndex--;
          $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
        } else {
          //MyService.ConsoleLog("** end of background **");
          if ($scope.rootCatIndex > 0) {
            $scope.rootCatIndex--;

            if ($scope.UserId && $scope.UserId != "") {
              $location.path("/question-compare/all/" + $rootScope.categories[$scope.rootCatIndex]._id);
            } else {
              // alert("no more answered questions");
              $location.path("/compare-subject/politician");
            }
          }
        }
      }
    };

    $scope.compareChanged = function (compare_with) {
      MyService.ConsoleLog("compare_with :", compare_with);
      var catid = $routeParams.categoryId;
      if ($routeParams.categoryId && $routeParams.categoryId.indexOf("popular:") == 0) {
        var catid = $routeParams.categoryId.split("popular:")[1];
      }

      $location.path('/compare-select/' + compare_with);
    };

    $scope.getComapreClass = function (match, i) {
      MyService.ConsoleLog("match: ", match);
      /*if(match == 100)
          return 'ag-green';
      if(match == 0)
          return 'ag-red';
      if(match > 0 && match <100)
          return 'ag-dg'+i;*/
      if (match >= 100)
        return 'ag-dg1';
      if (match >= 80)
        return 'ag-dg2';
      if (match >= 60)
        return 'ag-dg3';
      if (match >= 40)
        return 'ag-dg4';
      if (match >= 20)
        return 'ag-dg5';
      if (match >= 0)
        return 'ag-dg6';

      return 'ag-dg';
    };

    var getCompareAnswersCanceler = $q.defer();
    $scope.getCompareAnswers = function (params) {

      MyService.ConsoleLog(" params for getCompareAnswers :", params);
      if (!params) return;
      getCompareAnswersCanceler.resolve();

      getCompareAnswersCanceler = $q.defer();
      $scope.questions = [];
      $scope.status.questions = 1;

      MyService.getCompareAns(params, getCompareAnswersCanceler.promise)
        .then(function successCallback(response) {
          //MyService.ConsoleLog("Got categories: ",response);
          if (response.data.success) {
            if (response.data.data.length > 0) {
              $scope.status.questions = 2;
              angular.forEach(response.data.data, function (val, key) {
                if (val.answers && val.answers.length != $rootScope.selectedCompareUsers.length) {
                  var check_author_exist = function (u) {
                    var len = val.answers.length;
                    for (var i = 0; i < len; i++) {
                      if (val.answers[i].author._id == u._id) {
                        return true;
                      }
                    }
                    return false;
                  };

                  angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                    if (!check_author_exist(u)) {
                      response.data.data[key].answers.push({
                        author: u
                      });
                    }
                  });
                } else if (!val.answers) {
                  response.data.data[key].answers = [];
                  angular.forEach($rootScope.selectedCompareUsers, function (u, i) {
                    response.data.data[key].answers.push({
                      author: u
                    });
                  });
                }
              });

              $scope.questions = response.data.data.sort(function (x, y) {
                if (x.categories[0].viewOrder < y.categories[0].viewOrder) {
                  return -1;
                }
                if (x.categories[0].viewOrder > y.categories[0].viewOrder) {
                  return 1;
                }
                return 0;
              });
              MyService.ConsoleLog("$scope.questions: ", $scope.questions);
            }
            // $location.path("question-compare/"+$routeParams.category_id+"/"+null);
          } else {
            $scope.status.questions = 3;
            //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
        }, function errorCallBack(response) {
          $scope.status.questions = 3;
          //MyService.ConsoleLog("Error: ", response);
        });
    };

    var current_url = $location.url();
    //MyService.ConsoleLog("$location.url: ", current_url);

    $scope.state = "question-compare";
    if (!$rootScope.selectedCompareUsers || $rootScope.selectedCompareUsers.length <= 0) {
      $location.path("/questionHome");
    } else {
      if ($routeParams.categoryId == 'all') {
        //MyService.ConsoleLog("compare all answers");
        var param = {
          id: $routeParams.parentId,
          questions_counter: 1
        };
        getLevelCategory(param);
        $scope.getRootCategoryIndex();
      } // traverse all questions
      else {
        //MyService.ConsoleLog("** question-compare **");
        //MyService.ConsoleLog("compareAnswerList: ", $rootScope.compareAnswerList);

        $scope.questions = $rootScope.compareAnswerList;
        $scope.getCategory({
          id: $routeParams.categoryId
        });
      }
    }


    $scope.getInfo = function (val) {
      MyService.ConsoleLog("val >>", val);
      if (val && val.comment && val.comment != '') {
        $scope.showComment = val.comment;
        $scope.showPopUp = ngDialog.open({
          template: 'showInfoPopUp',
          closeByNavigation: true,
          scope: $scope,
        });
      }
    };

    $scope.viewAnsComment = function (ans) {
      if (ans.comment) {
        ngDialog.open({
          template: 'app/popups/show_comment_txt.html',
          controller: 'OldAnswerViewerCtrl',
          data: {
            title: "Comment",
            content: ans.comment
          }
        });
      }
    };


    /**
     * show pop up with old answers
     */
    $scope.getOldAnswers = function (val) {
      MyService.ConsoleLog('dava', val);
      $scope.oldAns = val;
      $scope.showPopUp = ngDialog.open({
        template: 'showOldAnsPopUp',
        closeByNavigation: true,
        scope: $scope,
      });
    };

    $scope.$watch('category', function (newValue, oldValue) {
      //MyService.ConsoleLog("** category watch **");
      var current_url = $location.url();

      if (current_url.indexOf("question-tagged") > -1)
        return; //for tagged questions don't again fetch questions

      if (newValue) {
        //MyService.ConsoleLog("<<<<<<oldValue: ", oldValue);
        //MyService.ConsoleLog("<<<<<newValue: ", newValue);
        if (oldValue && oldValue._id == newValue._id) return;
        //MyService.ConsoleLog("scrollTop");
        $("html, body").animate({
          scrollTop: 0
        }, "slow");
        // MyService.ConsoleLog("window.localStorage.getItem('fiveQueIds')", window.localStorage.getItem('fiveQueIds'));
        if (!window.localStorage.getItem('fiveQueIds')) {
          $scope.currentIndex = 0;
        }

        if (current_url.indexOf("question-compare") > -1) {
          var temp_uids = [];
          angular.forEach($rootScope.selectedCompareUsers, function (val, key) {
            temp_uids.push(val._id);
          });

          if (!window.localStorage.getItem('fiveQueIds')) {
            MyService.ConsoleLog("**** getCompareAnswers ****");
            MyService.ConsoleLog("<<<<<<oldValue: ", oldValue);
            MyService.ConsoleLog("<<<<<newValue: ", newValue);
            $scope.getCompareAnswers({
              categoryIds: newValue._id,
              userIds: temp_uids.join(","),
              //"compare_with": $routeParams.user_type,
            });

          }



        }
      }
    });

    $scope.$watch('questions', function (newValue, oldValue) {
      //MyService.ConsoleLog("** questions watch **");
      try {
        //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }

        // $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("questions Index Change Ex: ", e)
      }
    });

    $scope.$watch('currentIndex', function (newValue, oldValue) {
      //MyService.ConsoleLog("** currentIndex watch **");
      try {
        //MyService.ConsoleLog("Current question: ", $scope.questions[$scope.currentIndex]);
        var tempCate = $scope.questions[$scope.currentIndex].categories;
        if (angular.isArray(tempCate)) {
          $scope.getCategory({
            id: tempCate[0].cid
          });
        } else {
          $scope.getCategory({
            id: tempCate.cid
          });
        }
        // $scope.initMyAnswer();
      } catch (e) {
        //MyService.ConsoleLog("Current Index Change Ex: ", e)
      }
    });
  }])
