'use strict';
angular.module('myApp.editBallotMeasures', ['ngRoute'])

  .config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/editBallotMeasures/:bId?', {
      templateUrl: 'app/editBallotMeasures/editBallotMeasures.html',
      controller: 'editBallotMeasuresCtrl'
    });
  }]).controller('editBallotMeasuresCtrl', ['$scope', '$http', '$location', 'apiCall', '$routeParams', '$rootScope', '$q', '$timeout', 'growl', 'tokenService', 'ngDialog', 'baseApiUrl', '$anchorScroll', 'MyService', '$window', function ($scope, $http, $location, apiCall, $routeParams, $rootScope, $q, $timeout, growl, tokenService, ngDialog, baseApiUrl, $anchorScroll, MyService, $window) {

    MyService.ConsoleLog("routeParams", $routeParams.bId);
    $scope.showBallot = "";
    /**
     * Get Details of ballot measures
     */
    $scope.getDetailsOfBallot = function () {
      MyService.getDetailsOfBallotMeasures({
        bId: $routeParams.bId
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        $scope.showBallot = response;
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Update ballot measures details
     */
    $scope.updateBallot = function (udata) {
      MyService.ConsoleLog("update", udata);

      MyService.updateBallotMeasures({
        bId: $routeParams.bId,
        upData: JSON.stringify(udata)
      }).then(function (response) {
        MyService.ConsoleLog("Response", response);
        if (response) {
          growl.success("Updated Successfully");
        }
      }, function (err) {
        MyService.ConsoleLog("Error", err);
      });
    };

    /**
     * Navigate back to the listing page
     */
    $scope.goBack = function () {
      $location.path('/uploadBallotMeasures');
    };


    $scope.getDetailsOfBallot();
  }]);
