'use strict';
angular
  .module('myApp.followersQuiz', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/followersQuiz/:quizName?/:orgId?/:isExist?', {
        templateUrl: 'app/followersQuiz/followersQuiz.html',
        controller: 'followersQuizCtrl',
      });
    },
  ]).controller('followersQuizCtrl', [
    '$scope',
    '$http',
    '$location',
    'apiCall',
    '$routeParams',
    '$q',
    'ngDialog',
    '$rootScope',
    'growl',
    'MyService',
    '$anchorScroll',
    '$timeout',
    'tokenService',
    '$cookies',
    'baseApiUrl',
    '$document',
    function (
      $scope,
      $http,
      $location,
      apiCall,
      $routeParams,
      $q,
      ngDialog,
      $rootScope,
      growl,
      MyService,
      $anchorScroll,
      $timeout,
      tokenService,
      $cookies,
      baseApiUrl,
      $document
    ) {
      $scope.qName = $routeParams.quizName;
      $scope.orgId = $routeParams.orgId;
      // $scope.isExist = true;
      MyService.ConsoleLog("new");

      $cookies.remove('isExist');
      $cookies.remove('orgId');
      $cookies.remove('qName');
      $scope.showCheck = true;
      if (window.localStorage.getItem('isOrg') == 'true') {
        $scope.showCheck = false;
      }

      /**
       * redirect to the login form
       */
      if (!tokenService.getUserId()) {
        $cookies.put('orgId', $scope.orgId);
        $cookies.put('qName', $scope.qName);
        $cookies.put('isExist', true);

        $location.path('/showPopUp');
      }


      $rootScope.welcome = true;
      $scope.currentIndex = 0;
      $scope.questions = [];
      $scope.oldQuestions = [];
      $scope.tempans = [];
      $scope.oldIndex = 0;
      $scope.showZipCode = false;
      $scope.isShare = true;
      $scope.showloader = false;
      $scope.UserId = tokenService.getUserId();
      window.localStorage.removeItem('followData');
      $scope.updata = {
        comment: '',
        reason: '',
      };
      // $scope.skipToRes = false;
      $('#skipMatch').attr('disabled', true);

      MyService.ConsoleLog(' user id :', $scope.UserId);
      if ($cookies.get('sessionId')) {
        $cookies.remove('sessionId');
      }
      $scope.fivefb = {
        title: 'Take a fun five minute quiz to find out who to vote for in your local elections',
        description: '',
        fbFiveImage: '',
      };

      $scope.getFiveFacebookPost = function (data) {
        MyService.getFiveFacebookPost().then(
          function (payload) {
            // MyService.ConsoleLog(payload)
            if (payload.data.success) {
              $scope.fivefb.title = payload.data.data.fbTitle;
              $scope.fivefb.description = payload.data.data.fbDescription;
              if (payload.data.data.fbImage) {
                $scope.fivefb.fbFiveImage = baseApiUrl + '/resources/' + payload.data.data.fbImage;
              }
            }
          },
          function (errorPayload) {}
        );
      };

      $scope.getFiveFacebookPost();

      if ($scope.currentIndex == 0) {
        if (window.localStorage.getItem('fiveMinMatch')) {
          // $scope.questions = JSON.parse(window.localStorage.getItem("fiveMinMatch"));
          $rootScope.fiveMatch = JSON.parse(window.localStorage.getItem('fiveMinMatch'));
          $timeout(function () {
            $scope.$apply();
          });
        } else {
          $rootScope.fiveMatch = [];
          $rootScope.fiveMatch.length = 0;
        }
      }
      $scope.initializeVariables = function () {
        $scope.importance = null;
        $scope.answer = null;
        $scope.updata.comment = '';
        $scope.updata.reason = '';
      };

      $scope.saveFiveMinAnswers = function (skip) {
        // MyService.ConsoleLog("saveFiveMinAnswers : ");
        MyService.ConsoleLog('saveFiveMinAnswers fiveMatch >>>: ', $rootScope.fiveMatch);
        // alert(skip);
        $scope.pstData = [];
        var len = $rootScope.fiveMatch.length;
        MyService.ConsoleLog('Len :: ', len);
        if (len > 0) {
          for (var i = 0, k = 1; i < len; i++) {
            if (skip != '') {
              $scope.answer = '';
              $scope.importance = '';
              $scope.updata.comment = '';
              if ($rootScope.fiveMatch[i]) {
                // console.log("Inside i ", i);
                // console.log("answer :", $rootScope.fiveMatch[i].answer);
                if ($rootScope.fiveMatch[i].answer && $rootScope.fiveMatch[i].answer != '') {
                  $scope.answer = $rootScope.fiveMatch[i].answer;
                  $scope.importance = $rootScope.fiveMatch[i].importance;
                  $scope.updata.comment = $rootScope.fiveMatch[i].comment;
                  var postData = {
                    questionId: $rootScope.fiveMatch[i]._id,
                    importance: $scope.importance,
                    answer: $scope.answer,
                    comment: $scope.updata.comment,
                    categories: $rootScope.fiveMatch[i]['categories'],
                    content: $rootScope.fiveMatch[i]['content'],
                    _id: $rootScope.fiveMatch[i]['_id'],
                  };
                  $rootScope.fiveMatch[i] = angular.copy(postData);
                  // console.log("fiveMatch[i] > ", $rootScope.fiveMatch[i]);
                  $scope.pstData.push(postData);
                } else {
                  var data = $scope.checkQuestion($rootScope.fiveMatch[i]._id);
                  if (data && data[0]) {
                    // MyService.ConsoleLog("getdata >>> ", data[0]);
                    if (data[0].answer != '') {
                      $scope.answer = data[0].answer;
                    }
                    if (data[0].importance != '') {
                      $scope.importance = data[0].importance;
                    }
                    if (data[0].comment != '') {
                      $scope.updata.comment = data[0].comment;
                    }
                  }
                  var postData1 = {
                    questionId: $rootScope.fiveMatch[i]._id,
                    importance: $scope.importance,
                    answer: $scope.answer,
                    comment: $scope.updata.comment,
                    categories: $rootScope.fiveMatch[i]['categories'],
                    content: $rootScope.fiveMatch[i]['content'],
                    _id: $rootScope.fiveMatch[i]['_id'],
                  };
                  $rootScope.fiveMatch[i] = angular.copy(postData1);
                  $scope.pstData.push(postData1);
                }
              }
            } else {
              var postData = $rootScope.fiveMatch[i];
              $scope.pstData.push(postData);
            }
            if (skip != '' && postData) {
              console.log('saveFiveMinAnswers postData >>>>>>> ', postData);

              MyService.postAnswer(postData).then(
                function (response) {
                  // MyService.ConsoleLog("saveFiveMinAnswers response >>>", response);
                  if (response.data.success) {
                    MyService.ConsoleLog('Answer updated successfully');
                    growl.success('Answer updated successfully');
                  } else {
                    MyService.ConsoleLog('Error: ' + response.data.error);
                    growl.error(response.data.error);
                  }
                  if (k == len) {
                    $rootScope.fiveMatch = angular.copy($scope.pstData);
                    window.localStorage.setItem('fiveMatch', JSON.stringify($rootScope.fiveMatch));
                    MyService.ConsoleLog(
                      '$rootScope.fiveMatch > ******** >>>',
                      $rootScope.fiveMatch
                    );
                    if (skip == 'RediectNew') {
                      $location.path('/fiveCompare/skip_to_result');
                    } else if (skip != 'skipNew') {
                      MyService.ConsoleLog('IFFFFFFFFFFFFFF');
                      $location.path('/fiveCompare');
                    }
                  }
                  k++;
                },
                function (err) {
                  MyService.ConsoleLog('Error: err');
                }
              );
            } else {
              $scope.updata.comment = '';

              if (k == len) {
                $rootScope.fiveMatch = angular.copy($scope.pstData);
                window.localStorage.setItem('fiveMatch', JSON.stringify($rootScope.fiveMatch));
                MyService.ConsoleLog('$rootScope.fiveMatch > ******** >>>', $rootScope.fiveMatch);
                if (skip == 'RediectNew') {
                  $location.path('/fiveCompare/skip_to_result');
                } else if (skip != 'skipNew') {
                  MyService.ConsoleLog('IFFFFFFFFFFFFFF');
                  $location.path('/fiveCompare');
                }
              }
              k++;
            }
          }
        }
      };

      /**
       * insert followers answer with flag
       */
      $scope.addFollowersAnswers = function () {
        var len = $rootScope.fiveMatch.length;
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            // $rootScope.fiveMatch[i].show = isShow;
            MyService.ConsoleLog($rootScope.fiveMatch[i]);
            MyService.postAnswer($rootScope.fiveMatch[i]).then(
              function (response) {
                MyService.ConsoleLog(response);
                if (response.data.success) {
                  MyService.ConsoleLog('Answer updated successfully');
                } else {
                  MyService.ConsoleLog('Error: ' + response.data.error);
                }
              },
              function (err) {
                MyService.ConsoleLog('Error: ');
              }
            );
          }
        }
      };

      $scope.fiveMinuteMatchDone = function () {
        $scope.showpopUp = false;
        if (!$scope.UserId || $scope.UserId == '') {
          MyService.ConsoleLog('$scope.Questions', $scope.questions);
          var qLen = $scope.questions.length;
          for (var t = 0; t < qLen; t++) {
            if (
              $scope.questions[t].my_answer &&
              $scope.questions[t].my_answer != '' &&
              $scope.questions[t].my_answer.answer != ''
            ) {
              // alert('got');
              MyService.ConsoleLog("is exist", $scope.isExist);
              if ($scope.isExist && $scope.isExist == 'true') {
                $location.path('/fiveminsignin');
              } else {
                $scope.showpopUp = true;
                $scope.showZipCode = true;
              }
            } else {
              if (!$scope.showpopUp && t == qLen - 1) {
                var message = 'You need to answer at least one question to see a match comparison';
                var modal = ngDialog.openConfirm({
                  template: '<p>' +
                    message +
                    '</p>\
                  <div class="ngdialog-buttons">\
                      <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Back to Questions</button>\
                  </div>',
                  plain: true,
                  closeByDocument: false,
                });
                modal.then(
                  function fullfilled(data) {
                    // MyService.ConsoleLog("fullfilled: ", data);
                    $scope.getFollowersQuiz();
                  },
                  function rejected(data) {
                    // MyService.ConsoleLog("rejected: ", data);
                    // $scope.getMatchQUestions();
                  }
                );
                t = qLen + 1;
              }
            }
          }
        } else {
          MyService.ConsoleLog('saveFiveMinAnswers fiveMatch >>>: ', $rootScope.fiveMatch);
          // alert('inside');
          /*  MyService.ConsoleLog("orgInf", $scope.orgInfo);
            var message = 'Do you want to share your quiz answers to the ' + '<b>' + $scope.orgInfo.title + '</b>';
            var modal = ngDialog.openConfirm({
              template: '<p>' +
                message +
                '</p>\
              <div class="ngdialog-buttons">\
                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Yes</button>\
                  <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="confirm(2)">No</button>\
              </div>',
              plain: true,
              closeByDocument: false,
            });
            modal.then(
              function fullfilled(data) {
                if (data == 1) {
                  MyService.ConsoleLog("Yes ");
                  $scope.AddFollowersAnswers('Y');
                  $location.path('/orgMatch/' + $scope.orgId);
                } else {
                  $scope.AddFollowersAnswers('N');
                  MyService.ConsoleLog("No");
                  $location.path('/orgMatch/' + $scope.orgId);

                }
              },
              function rejected(data) {
                MyService.ConsoleLog("rejected: ", data);
                // $scope.getMatchQUestions();
              }
            );*/
          $scope.addFollowersAnswers();
          if (window.localStorage.getItem('isOrg') == 'true') {
            var message = 'Congratulations, ' + $scope.orgInfo.title + '. You finished the quiz.  Now you can invite politicians and compare answers.';
            var modals = ngDialog.openConfirm({
              template: '<p>' +
                message +
                '</p>\
              <div class="ngdialog-buttons">\
                  <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Back to Quizzes</button>\
              </div>',
              plain: true,
              closeByDocument: false,
            });
            modals.then(
              function fullfilled(data) {
                MyService.ConsoleLog("fullfilled: ", data);
                $location.path('/allQuizzes');
              },
              function rejected(data) {
                // MyService.ConsoleLog("rejected: ", data);
                // $scope.getMatchQUestions();
              }
            );
          } else {
            $location.path('/orgMatch/' + $scope.orgId);
          }


          // $scope.saveFiveMinAnswers('');
        }
      };
      $scope.initializeVariables();
      $scope.newQuestion = function () {
        MyService.ConsoleLog('newQuestion' + $scope.currentIndex);
        // alert('three');
        var tempIndex = $scope.currentIndex;
        $scope.initializeVariables();

        $scope.answer = '';
        $scope.importance = '';
        $scope.updata.comment = '';
        MyService.ConsoleLog("tempIndex", tempIndex + "   quesLen" + $scope.questions.length);
        if (tempIndex < $scope.questions.length) {
          $scope.initializeVariables();

          if (window.localStorage.getItem('fiveMinMatch')) {
            $scope.getFiveMinData = [];
            $scope.getFiveMinData = JSON.parse(window.localStorage.getItem('fiveMinMatch'));
            MyService.ConsoleLog('Data : ', $scope.getFiveMinData);
            for (var i = 0; i < $scope.getFiveMinData.length; i++) {
              // MyService.ConsoleLog("for i : " + i + " currentIndex : " + $scope.currentIndex);
              if (i == $scope.currentIndex) {
                // MyService.ConsoleLog("index matched", $scope.getFiveMinData[i]);
                $scope.answer = $scope.getFiveMinData[i].answer;
                $scope.importance = $scope.getFiveMinData[i].importance;
                $scope.updata.comment = $scope.getFiveMinData[i].comment;
                $timeout(function () {
                  $scope.$apply();
                });
              }
            }
          } else {
            console.log('Else is called');
            $scope.answer = '';
            $scope.importance = '';
            $scope.updata.comment = '';
            console.log('importance > ', $scope.importance);
            MyService.ConsoleLog('got >> ', window.localStorage.getItem('fiveMatch'));

          }
          //$scope.currentIndex++;
        } else {
          // MyService.ConsoleLog("final question : " + $scope.questions.length);
          MyService.ConsoleLog('got >> ', window.localStorage.getItem('fiveMatch'));

          // alert('newques');
          $scope.fiveMinuteMatchDone();
        }
      };

      $scope.checkQuestion = function (val) {
        if ($scope.AllAnsweredQuestions && $scope.AllAnsweredQuestions.length > 0) {
          return $scope.AllAnsweredQuestions.filter(function (rdata) {
            // MyService.ConsoleLog("if", rdata.question + "==" + val);
            if (rdata.question == val) return rdata;
          });
        } else {
          return $rootScope.fiveMatch.filter(function (rdata) {
            // MyService.ConsoleLog("else", rdata.questionId + "<==>" + val);
            if (rdata.questionId == val) return rdata;
          });
        }
      };

      $scope.checkIfExists = function (val) {
        var obj = $scope.questions[$scope.currentIndex];
        if (obj) {
          var data;
          // MyService.ConsoleLog("checkIfExists >> ", obj);

          if (obj && obj.queId && obj.queId != '') {
            data = $scope.checkQuestion(obj.queId);
          } else {
            // alert('else');
            data = $scope.checkQuestion(obj._id);
            // MyService.ConsoleLog("inside else", data);

          }

          if (data && data[0]) {
            // MyService.ConsoleLog("checkIfExists data>> ", data[0]);
            // MyService.CongressionalDistrict("val", val);
            // $scope.comment = data[0].comment;
            if (data[0].answer == val) {
              // console.log("return True");
              $scope.dis = true;
              return true;
            }
          }
        }
        return false;
      };

      $scope.checkQuestionAnswered = function () {
        // console.log("scope.questions > ", $scope.questions);
        return $scope.questions.filter(function (rdata) {
          // MyService.ConsoleLog("== rdata >>>", rdata);
          var data = $scope.checkQuestion(rdata._id);
          // MyService.ConsoleLog("rdata.questionId  ", data);

          if (data && data.length > 0) return rdata;
        });
      };

      $scope.oneMore = function (valOne) {
        console.log('One more clicked', $scope.isShare);
        $scope.importance = valOne;
        // console.log("$scope.importance >> ",$scope.importance);
        $scope.submitAnswer();
      };

      $scope.checkImpExists = function (val) {
        // MyService.ConsoleLog('checkImpExists called :', $scope.importance);
        var obj = $scope.questions[$scope.currentIndex];
        if (obj) {
          // MyService.ConsoleLog('data', obj);
          var data;
          if (obj && obj.queId && obj.queId != '') {
            data = $scope.checkQuestion(obj.queId);
          } else {
            data = $scope.checkQuestion(obj._id);
          }
          if (data && data[0]) {
            if (data[0].importance == val) {
              return true;
            }
          } else {
            // alert('one');
            // MyService.ConsoleLog('compare them', $scope.importance + '   ' + val);
            if ($scope.importance == val) {
              $scope.importance = '';
            }
          }
        }
        return false;
      };

      $scope.$watch('currentIndex', function (newValue, oldValue) {
        try {
          MyService.ConsoleLog('Current currentIndex: ', $scope.currentIndex);
          var data = $scope.checkQuestion($scope.questions[$scope.currentIndex]._id);
          // console.log("data >> ", data);
          if (data && data[0]) {
            $scope.updata.comment = data[0].comment;
          }
        } catch (e) {
          //MyService.ConsoleLog("Current Index Change Ex: ", e)
        }
      });

      $scope.cachedQuestions = [];
      $scope.flagSkipAnsweredQuestion = false;
      $scope.skipAnswerChagne = function () {
        MyService.ConsoleLog('scope.flagSkipAnsweredQuestion: ', $scope.flagSkipAnsweredQuestion);
        MyService.ConsoleLog('$scope.questi', $scope.questions);
        $scope.temp = $scope.questions;

        $scope.currentIndex = 0;
        var questions = $scope.checkQuestionAnswered();
        $scope.dis = false;
        MyService.ConsoleLog('questions > ', questions);
        if (questions && questions.length > 0) {
          // alert('insise');
          if ($scope.flagSkipAnsweredQuestion) {
            $scope.cachedQuestions.length = 0;
            $scope.cachedQuestions = angular.copy($scope.questions);
            for (var i = 0; i < questions.length; i++) {
              var idx = $scope.questions.indexOf(questions[i]);
              if (idx > -1) {
                $scope.questions.splice(idx, 1);
              }
            }
            // console.log("questionslength > ", $scope.cachedQuestions.length + "==" + questions.length);
            MyService.ConsoleLog('$scope.cachedQuestions :', $scope.cachedQuestions);
            if ($scope.cachedQuestions.length == questions.length) {
              // console.log("questionslength Equal");
              if ($rootScope.fiveMatch.length == 0) {
                $rootScope.fiveMatch = angular.copy($scope.cachedQuestions);
                MyService.ConsoleLog(' $rootScope.fiveMatch one :', $rootScope.fiveMatch);
                var lents = $rootScope.fiveMatch.length;
                MyService.ConsoleLog(
                  'Len >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
                  lents
                );
                if (lents >= 5) {
                  // $scope.skipToRes = true;
                  $('#skipMatch').attr('disabled', false);
                }
                $scope.questions = angular.copy($scope.cachedQuestions);
                // $scope.answer = '';

              }
              // $scope.saveFiveMinAnswers('skip');
            } else {
              // alert("esle");
              $scope.updata.comment = '';
              $rootScope.fiveMatch = angular.copy(questions);
              var lent = $rootScope.fiveMatch.length;
              MyService.ConsoleLog('lent @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', lent);

              if (lent >= 5) {
                // $scope.skipToRes = true;
                $('#skipMatch').attr('disabled', false);
              }
              // $scope.saveFiveMinAnswers('skipNew');
            }
          } else {
            $scope.questions = angular.copy($scope.cachedQuestions);
          }
        } else {
          if (!$scope.flagSkipAnsweredQuestion) {
            MyService.ConsoleLog('INside');
            $scope.questions = angular.copy($scope.cachedQuestions);
            if ($scope.questions[$scope.currentIndex]) {
              MyService.ConsoleLog('ONe MORE');
              // console.log($scope.questions[$scope.currentIndex]._id);
              var data = $scope.checkQuestion($scope.questions[$scope.currentIndex]._id);
              // console.log("data >> ", data);
              if (data && data[0]) {
                $scope.updata.comment = data[0].comment;
              }
            } else {
              // $scope.flagSkipAnsweredQuestion = false;
              // alert("inside");
              MyService.ConsoleLog('$scope.temp', $scope.temp);
              $scope.questions = $scope.temp;
            }
          }
        }
      };

      // $scope.commenta=function()
      $scope.checkQuestions = function (rdata, val) {
        MyService.ConsoleLog('rdata :', rdata);
        return rdata.filter(function (rdata) {
          // MyService.ConsoleLog(rdata.question + "==" + val);
          if (rdata.questionId == val) return rdata;
        });
      };

      $scope.saveAnswersOneByOne = function (params) {
        MyService.ConsoleLog('post params >>>>>:', params);
        /**
         * Commented for pop up
         */
        /*if (params && params != '') {
          MyService.postAnswer(params).then(
            function (response) {
              document.getElementById('txtReason').value = '';

              if (response.data.success) {
                try {
                  if (params.id) {
                    $scope.questions[$scope.currentIndex]['my_answer'] = {
                      _id: postData.id,
                      created: new Date(),
                      comment: params.comment,
                      questionId: params.questionId,
                      answer: params.answer,
                      importance: params.importance,
                    };
                  } else {
                    $scope.questions[$scope.currentIndex]['my_answer'] = {
                      _id: response.data.data._id,
                      created: response.data.data.created,
                      questionId: response.data.data.question,
                      comment: response.data.data.comment,
                      answer: response.data.data.answer,
                      importance: response.data.data.importance,
                    };
                  }
                } catch (e) {
                  //MyService.ConsoleLog("Exceptoin: ", e);
                }
                if (response.data.data.question) {
                  $scope.AllAnsweredQuestions.push(response.data.data);
                }

                if (params.id) {
                  growl.success('Answer updated successfully', {
                    ttl: 5000,
                  });
                } else {
                  growl.success('Answered successfully', {
                    ttl: 5000,
                  });
                }
                $scope.currentIndex = $scope.currentIndex + 1;

                // MyService.ConsoleLog(window.localStorage.getItem("fiveMatch"));
                $scope.newQuestion();
              } else {
                growl.error('Error: ' + response.data.error);
              }
            },
            function (err) {
              // growl.error("Something went wrong.");
            }
          );
        } */
      };

      $scope.postAnswer = function (postData, val) {
        MyService.ConsoleLog('postData: ', postData);
        // MyService.ConsoleLog("Index <><><", $rootScope.fiveMatch.indexOf(postData));
        var ndata = $scope.checkQuestion(postData.questionId);
        MyService.ConsoleLog('ndata >', ndata);
        // MyService.ConsoleLog($rootScope.fiveMatch);

        if (ndata.length > 0) {
          var vdx = $scope.AllAnsweredQuestions.indexOf(ndata[0]);
          if (vdx > -1) {
            // console.log("VDX > ", vdx);
            $scope.AllAnsweredQuestions[vdx]['answer'] = postData.answer;
            $scope.AllAnsweredQuestions[vdx]['importance'] = postData.importance;
            $scope.AllAnsweredQuestions[vdx]['comment'] = postData.comment;
            // console.log("AS : ", $scope.AllAnsweredQuestions[vdx]);
          }
        }

        $scope.questions[$scope.currentIndex]['my_answer'] = {
          _id: postData._id,
          created: new Date(),
          id: postData.id,
          reason: postData.reason,
          comment: postData.comment,
          answer: postData.answer,
          importance: postData.importance,
        };

        var chkData = $scope.checkQuestions($rootScope.fiveMatch, postData.questionId);
        MyService.ConsoleLog('chkData<><><>', chkData);
        $scope.dis = false;

        postData.categories = $scope.questions[$scope.currentIndex]['categories'];
        postData.content = $scope.questions[$scope.currentIndex]['content'];
        postData._id = $scope.questions[$scope.currentIndex]['_id'];

        if (chkData && chkData.length == 0) {
          // alert("one");
          // MyService.ConsoleLog("postData1if<><><",postData);
          $rootScope.fiveMatch.push(postData);
          // MyService.ConsoleLog("Final Five match <><><><><> ", $rootScope.fiveMatch);
          window.localStorage.setItem('fiveMatch', JSON.stringify($rootScope.fiveMatch));
          if (val == 'submit' && val != 'skip') {
            // if ($scope.UserId && $scope.UserId != '') {
            //   if (postData.questionId && postData.questionId != '') {
            // alert('one');
            //     $scope.saveAnswersOneByOne(postData);
            //     postData = '';
            //   }
            // } else {
            $scope.currentIndex = $scope.currentIndex + 1;
            MyService.ConsoleLog('Else >> ', window.localStorage.getItem('fiveMatch'));
            $scope.importance = angular.copy('');
            growl.success('Answered successfully', {
              ttl: 1000,
            });
            $scope.newQuestion();
            // }
          }

          var fiveLen = $rootScope.fiveMatch.length;

          MyService.ConsoleLog('FiveMatch:  $scope.currentIndex ::: ', $scope.currentIndex);
          $scope.importance = angular.copy('');
          if (fiveLen >= 5) {
            // $scope.skipToRes = true;
            $('#skipMatch').attr('disabled', false);
          }
        } else {
          // alert("two");
          var idx = $rootScope.fiveMatch.indexOf(chkData[0]);

          $rootScope.fiveMatch[idx] = postData;
          $scope.importance = angular.copy('');
          window.localStorage.setItem('fiveMatch', JSON.stringify($rootScope.fiveMatch));
          if (val == 'submit' && val != 'skip') {
            // if ($scope.UserId && $scope.UserId != '') {
            //   if (
            //     (postData.reason && postData.reason != '') ||
            //     (postData.id && postData.id != '')
            //   ) {
            //     // alert('two');
            //     // $scope.saveAnswersOneByOne(postData);
            //     postData = '';
            //   }
            // } else {
            $scope.currentIndex = $scope.currentIndex + 1;
            $scope.importance = angular.copy('');
            MyService.ConsoleLog(
              'currentIndex <><><> ',
              window.localStorage.getItem('fiveMatch')
            );
            $scope.newQuestion();
            // }
          }
        }
      };

      $scope.submitAnswer = function () {
        MyService.ConsoleLog('$scope.comment <>>>>', $scope.updata);
        var answerId = '';
        var answerReason = '';
        var data = $scope.checkQuestion($scope.questions[$scope.currentIndex]._id);
        if (data && data[0]) {
          if (data[0] && data[0]._id) {
            answerId = data[0]._id;
          }
          if (data[0] && data[0]._id) {
            if ($scope.updata && $scope.updata.reason) {
              answerReason = $scope.updata.reason;
            }
          }
        }
        if ($scope.answer == null || $scope.answer == '') {
          var message = 'Please select answer first.';
          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                         <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                         </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              MyService.ConsoleLog('fullfilled: ', data);
            },
            function rejected(data) {
              MyService.ConsoleLog('rejected: ', data);
            }
          );
          $('html, body').animate({
              scrollTop: 0,
            },
            'slow'
          );
          return;
        }
        //MyService.ConsoleLog("**** ", $scope.importance);
        if ($scope.importance == null || $scope.importance == '') {
          var message =
            'You need to choose an importance level.  Should they fight hard for this can they use it to trade for other things you care about more?';
          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                         <div class="ngdialog-buttons">\
                             <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                         </div>',
            plain: true,
          });
          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
            }
          );
          $('html, body').animate({
              scrollTop: 0,
            },
            'slow'
          );
          return;
        }
        var qid;
        MyService.ConsoleLog('$scope', $scope.UserId);
        if ($scope.UserId && $scope.UserId) {
          qid = $scope.questions[$scope.currentIndex]._id;
        } else {
          qid = $scope.questions[$scope.currentIndex].queId;
        }
        var showMatch;
        if ($scope.isShare && $scope.isShare == true) {
          showMatch = 'Y';
        } else {
          // alert('else');
          showMatch = 'N';
        }
        var postData = {
          questionId: qid,
          importance: $scope.importance,
          answer: $scope.answer,
          comment: $scope.updata.comment,
          show: showMatch
        };

        if (answerId != '') {
          postData.id = answerId;
          MyService.ConsoleLog('answerId :', answerId);
          postData.reason = answerReason;
        }
        $('html, body').animate({
            scrollTop: $('#DisplayQ1').offset().top - 80,
          },
          'slow'
        );
        // console.log("postData > ", postData);
        $scope.postAnswer(postData, 'submit');
      };

      $rootScope.$on('question.answered', function (evt, data) {
        MyService.ConsoleLog('on question.answered: ', data);
        if ($scope.questions[$scope.currentIndex]._id == data.questionId) {
          $scope.initializeVariables();
          $scope.skipQuestion();
        }
      });

      /**
       * get Org Information
       */

      $scope.getOrgInfo = function () {
        MyService.getPress($scope.orgId).then(
          function (response) {
            MyService.ConsoleLog('Response', response);
            $scope.orgInfo = response.orgdata;
            $scope.orgUser = response.userData;
            $scope.getFollowersList();
          },
          function (err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };

      /**
       * get  followers count and get quizzes count
       */
      $scope.getFollowersList = function () {
        MyService.getFollowersList({
          orgId: $scope.orgId
        }).then(
          function (response) {
            MyService.ConsoleLog('response', response.data);
            $scope.followersCnt = response.data.followersCount;
            $rootScope.followersData = response.data.followersData;
            window.localStorage.setItem('followData', JSON.stringify(response.data.followersData));
            $scope.questionsCnt = response.data.quizzesCount;
            $rootScope.quizzesData = response.data.quizzesData;
            $scope.quizQuesHasAns = response.data.quesHasAnsCount;
          },
          function (err) {
            MyService.ConsoleLog('error', err);
          }
        );
      };

      // Quick link for show match

      $scope.skipToMatch = function () {
        if ($scope.UserId && $scope.UserId != '') {
          // alert("if");
          MyService.ConsoleLog('welcome to quick link :', $rootScope.fiveMatch);
          $location.path('/fiveCompare/skip_to_result');
          // window.localStorage.setItem("fiveMatch", JSON.stringify($rootScope.fiveMatch));
          // $scope.saveFiveMinAnswers("RedirectNew");
        } else {
          // alert("else");
          $scope.showZipCode = true;
        }
        // $rootScope.fiveMatch.length=0;
      };

      $scope.getFollowersQuiz = function () {
        // MyService.ConsoleLog("  $rootScope.fiveMatch :>", $rootScope.fiveMatch);
        $scope.showloader = true;
        var sobj = {};

        if ($scope.orgId && $scope.orgId != '') {
          MyService.ConsoleLog('<<<<< If >>');
          sobj.quizName = $scope.qName;
          sobj.orgId = $scope.orgId;
        } else {
          MyService.ConsoleLog('<<<<<<<<< Else >>>>>>>>>');
          sobj.queIds = JSON.parse(window.localStorage.getItem('queIds'));
        }
        MyService.getOrgQuizQuestions(sobj).then(
          function (payload) {
            MyService.ConsoleLog('getMatchQUestions', payload);
            if (payload.data.success && payload.data.success != '') {
              $scope.questions = payload.data.data;
              $scope.queLen = $scope.questions.length;

              MyService.ConsoleLog('This is length ::', $scope.queLen);

              $scope.flagSkipAnsweredQuestion = true;
              $scope.showloader = false;
              if ($scope.orgId && $scope.orgId != '') {
                $scope.getOrgInfo();
              }
              if ($scope.questions[$scope.currentIndex] && $scope.questions[$scope.currentIndex].categories.length > 0) {
                var paObj = {};

                paObj.id = $scope.questions[$scope.currentIndex].categories[0].cid;
                $scope.getCategory(paObj);
              }
              $scope.skipAnswerChagne();
            } else {
              alert('Something went wrong. Please try after some time');
              $location.path('/welcome');
            }
          },
          function (errpayload) {
            alert('Something went wrong. Please try after some time');
            $location.path('/welcome');
          }
        );
      };

      $scope.skipQuestion = function () {
        MyService.ConsoleLog('* skipQuestion *');
        var skip = function () {
          // MyService.ConsoleLog("skip");
          var paObj = {};
          paObj.id = $scope.questions[$scope.currentIndex].categories[0].cid;
          $scope.getCategory(paObj);
          var data = $scope.checkQuestion($scope.questions[$scope.currentIndex]._id);
          if (data && data[0]) {
            MyService.ConsoleLog('getMatchQUestions >>> ', data[0]);
            if (data[0].answer != '') {
              $scope.answer = data[0].answer;
            }
            if (data[0].importance != '') {
              $scope.importance = data[0].importance;
            }
            if (data[0].comment != '') {
              $scope.updata.comment = data[0].comment;
            }
          }
          // $scope.dis=false;
          // console.log("answer : ", $scope.answer);
          if ($scope.answer && $scope.answer != '') {
            var postData = {
              questionId: $scope.questions[$scope.currentIndex]._id,
              importance: $scope.importance,
              answer: $scope.answer,
              comment: $scope.updata.comment,
            };
            // console.log("postData >> ", postData);
            $scope.postAnswer(postData, 'skip');
          }

          // console.log($("#DisplayQ1").offset());
          $('html, body').animate({
              scrollTop: $('#DisplayQ1').offset().top - 80,
            },
            'slow'
          );

          var tempIndex = $scope.currentIndex + 1;
          if (tempIndex < $scope.questions.length) {
            $scope.initializeVariables();
            $scope.currentIndex++;
          } else {
            MyService.ConsoleLog('final question : ' + $scope.questions.length);
            $scope.fiveMinuteMatchDone();
          }
          /*
          $timeout(function() {
            $location.hash('DisplayQ');
            $anchorScroll();
          }, 100);
          */
        };

        MyService.ConsoleLog('skip answer<><><><<>', $scope.answer);
        if ($scope.answer != null) {
          var show_answer_modal = true;
          try {
            var my_answer = $scope.questions[$scope.currentIndex]['my_answer'];

            /*for (var key in my_answer) {
              if(my_answer[])
            }*/
            if (
              my_answer.importance == $scope.importance &&
              my_answer.answer == $scope.answer &&
              my_answer.comment == $scope.updata.comment
            )
              show_answer_modal = false;
          } catch (e) {
            //MyService.ConsoleLog("Exceptoin : ", e);
          }
          // MyService.ConsoleLog("show_answer_modal: ", show_answer_modal);
          if (show_answer_modal) {
            var message =
              'You just hit the SKIP button.  Are you sure you want to skip this one, or would you rather Submit your answer?';
            var modal = ngDialog.openConfirm({
              template: '<p>' +
                message +
                '</p>\
                <div class="ngdialog-buttons">\
                    <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">Skip</button>\
                    <button type="button" class="ngdialog-button ngdialog-button-primary" ng-click="confirm(1)">Submit</button>\
                </div>',
              plain: true,
              closeByDocument: false,
            });

            modal.then(
              function fullfilled(data) {
                //submit answer
                $scope.submitAnswer();
              },
              function rejected(data) {
                //skip the answer
                skip();
              }
            );
          } else {
            skip();
          }
        } else {
          skip();
        }
      };

      //  prevQuestions
      $scope.prevQuestion = function () {
        MyService.ConsoleLog('** prevQuestion **');

        if ($scope.currentIndex > 0) {
          // alert($scope.currentIndex);
          $('html, body').animate({
              scrollTop: $('#DisplayQ1').offset().top - 80,
            },
            'slow'
          );
          $scope.currentIndex--;
          // console.log("Current <><>,", $scope.currentIndex);
          $scope.updata.comment = '';
          // console.log("C digDeeper : ",$scope.digDeeperMatch);
          if ($scope.digDeeperMatch != true) {
            // console.log("digDeeper");
            // console.log(window.localStorage.getItem("fiveMatch"));
            if (window.localStorage.getItem('fiveMatch')) {
              MyService.ConsoleLog('here', JSON.parse(window.localStorage.getItem('fiveMatch')));
              MyService.ConsoleLog(' $scope.cachedQuestions', $scope.cachedQuestions);
              $scope.getFiveMinData = [];
              $scope.getFiveMinData.length = 0;
              if ($scope.flagSkipAnsweredQuestion) {
                console.log('IF <> ', $scope.flagSkipAnsweredQuestion);
                // if ($scope.cachedQuestions && $scope.cachedQuestions.length > 0) {
                $scope.getFiveMinData = $scope.cachedQuestions;
                // } else {
                //   $scope.getFiveMinData = JSON.parse(window.localStorage.getItem('fiveMatch'));
                // }
              } else {
                console.log('Else ');
                $scope.getFiveMinData = JSON.parse(window.localStorage.getItem('fiveMatch'));
              }

              // MyService.ConsoleLog('Data : ', $scope.getFiveMinData);
              for (var i = 0; i < $scope.getFiveMinData.length; i++) {
                MyService.ConsoleLog('for i : ' + i + ' currentIndex : ' + $scope.currentIndex);

                if (i == $scope.currentIndex) {
                  MyService.ConsoleLog('index matched', $scope.getFiveMinData[i]);
                  MyService.ConsoleLog('index matched', $scope.getFiveMinData[i].importance);

                  $scope.answer = $scope.getFiveMinData[i].answer;
                  $scope.importance = $scope.getFiveMinData[i].importance;

                  $scope.updata.comment = $scope.getFiveMinData[i].comment;
                  // $timeout(function() {
                  //   $scope.$apply();
                  // });
                }
              }
              MyService.ConsoleLog(' $scope.importance', $scope.importance);
            }
          }
        } else if ($scope.currentIndex == 0) {
          if ($scope.currBackgroundIndex > 0) {
            $scope.currBackgroundIndex--;
            $scope.category = $scope.traverseBackgrounds[$scope.currBackgroundIndex];
          }
        }
      };

      // Zip code
      $scope.oldaddress = {
        state: '',
        county: '',
        countySubdivision: '',
        place: '',
      };

      $scope.address = {
        state: '',
        county: '',
        countySubdivision: '',
        place: '',
      };
      $scope.showZipLoader = false;
      $scope.showAddressDropDown = false;

      $scope.AllAnsweredQuestions = [];
      $scope.getAllAnsweredQuestions = function (userId) {
        MyService.getAllAnsweredQuestions({
          authorId: userId,
          quizName: $scope.qName
        }).then(
          function (payload) {
            // MyService.ConsoleLog("getAllAnsweredQuestions >> ", payload)
            if (payload.data.success) {
              $scope.AllAnsweredQuestions = payload.data.data;
              $scope.getFollowersQuiz();
            }
          },
          function (errpayload) {
            MyService.ConsoleLog('getAllAnsweredQuestions errpayload >> ', errpayload);
          }
        );
      };

      $scope.getAllStates = function () {
        if ($scope.counties) {
          $scope.counties.length = 0;
        }
        if ($scope.places) {
          $scope.places.length = 0;
        }

        var request = apiCall.apiCall('GET', '/user/getAllStates', {
          country: '',
        });
        $http(request).then(
          function successCallback(response) {
            MyService.ConsoleLog('Got States: ', response);
            if (response.data.success) {
              $scope.states = response.data.data;
            } else {
              growl.error('Something went wrong.');
            }
          },
          function errorCallBack(response) {
            //MyService.ConsoleLog("Error: ", response);
            growl.error('Something went wrong.');
          }
        );
      };

      // if ($cookies.get('isAlreadyAnswer') == 'true') {
      //   // alert('dd');
      $scope.getAllAnsweredQuestions($scope.UserId);
      // } else {
      $scope.getAllStates();
      // $scope.getFollowersQuiz();
      // }

      $scope.checkState = function (rdata, val) {
        // MyService.ConsoleLog(rdata);
        return rdata.filter(function (rdata) {
          //MyService.ConsoleLog(rdata.statePostalCode + "==" + val);
          if (rdata.statePostalCode == val) return rdata;
        });
      };

      $scope.checkState = function (rdata, val) {
        // MyService.ConsoleLog("State :", rdata);
        // MyService.ConsoleLog("State val :", val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countyFips == obj.COUNTYFP && rsdata.stateFips == obj.STATEFP) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };

      $scope.checkCounty = function (rdata, val) {
        MyService.ConsoleLog('County :', rdata);
        MyService.ConsoleLog('County val :', val);
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countyFips == obj.COUNTYFP && rsdata.stateFips == obj.STATEFP) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checksubCounty = function (rdata, val) {
        var sdata = [];
        var len = rdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = rdata[i];
          var gdata = val.filter(function (obj) {
            if (rsdata.countySubdivision == obj.NAME) {
              // MyService.ConsoleLog(rdata[i]);
              return rdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(rdata[i]) == -1) {
              sdata.push(rdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      $scope.checkPlaces = function (prdata, val) {
        var sdata = [];
        var len = prdata.length;
        var j = 1;

        for (var i = 0; i < len; i++, j++) {
          var rsdata = prdata[i];
          var gdata = val.filter(function (obj) {
            // MyService.ConsoleLog(rsdata.place +"=="+ obj.NAME);
            if (rsdata.place == obj.NAME && !rsdata.countyFips) {
              // MyService.ConsoleLog(rdata[i]);
              return prdata[i];
            }
          });
          if (gdata.length > 0) {
            if (sdata.indexOf(prdata[i]) == -1) {
              sdata.push(prdata[i]);
            }
          }

          if (j == len) {
            // MyService.ConsoleLog("gdata > ", sdata);
            return sdata;
          }
        }
      };
      var getCountiesCanceler = $q.defer();

      $scope.allcounties = [];
      $scope.getCounties = function (lbl) {
        // MyService.ConsoleLog("county >", $scope.Location.state);
        // $scope.allcounties.length = 0;
        var statePostalCode = '';
        var state = '';
        if (lbl == 'new') {
          state = JSON.stringify($scope.oldaddress.state);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
        }
        if (!statePostalCode) {
          statePostalCode = $scope.stateFips;
          MyService.ConsoleLog('StatePostal Code in IF', statePostalCode);
        }
        // MyService.ConsoleLog(statePostalCode + "<><><><>>" + state);
        MyService.getAllCounties(statePostalCode, state).then(
          function (payload) {
            // MyService.ConsoleLog("sublocations Locals : ", payload);
            var allcounties = payload;
            if (lbl == 'new') {
              var county = $scope.checkCounty(allcounties, $scope.oldaddress.county);
              $scope.allcounties = county;
              // MyService.ConsoleLog("county<><", county);
              $scope.address.county = county[0];
            }
          },
          function (errorPayload) {
            growl.error('Something went wrong.');
          }
        );
      };
      $scope.MyPlaces = [];
      $scope.getSubCounties = function (lbl) {
        //MyService.ConsoleLog($scope.Location.state);
        //MyService.ConsoleLog($scope.Location.county);
        // var county = $scope.Location.county;

        $scope.MyPlaces.length = 0;
        var county = '';
        var statePostalCode = '';
        $scope.MyPlaces.length = 0;
        var state = '';
        var county = '';
        if (lbl == 'new') {
          state = JSON.stringify($scope.oldaddress.state);
          county = JSON.stringify($scope.oldaddress.county);
        } else {
          statePostalCode = $scope.Location.state.statePostalCode;
          state = $scope.Location.state;
          county = county.county;
        }
        // MyService.ConsoleLog(statePostalCode + "===" + county);
        MyService.getAllSubCountiesLocal(statePostalCode, state, county).then(
          function (payload) {
            // payload = JSON.parse(payload);
            $scope.MyPlaces = payload;
            // MyService.ConsoleLog("PlaCE :", payload);
            // MyService.ConsoleLog(payload.countySubdivision);
            var subcounties = payload.countySubdivision;
            var places = payload.places;
            // MyService.ConsoleLog("PlaCE :", payload);
            if (lbl == 'new') {
              // MyService.ConsoleLog("Places 1 > ",$scope.oldaddress.place);
              var subcounty = $scope.checksubCounty(
                subcounties,
                $scope.oldaddress.countySubdivision
              );
              var places = $scope.checkPlaces(places, $scope.oldaddress.place);
              // MyService.ConsoleLog("subcounty<><><", subcounty);
              // $scope.subcounties = subcounty;
              $scope.places = places;
              // console.log('***********', $scope.places);
              // $scope.Location.countySubdivision = subcounty[0];
              $scope.address.place = places[0];
            }
          },
          function (errorPayload) {
            growl.error('Something went wrong.');
          }
        );
      };

      var getLocalsCanceler = $q.defer();
      $scope.getLocals = function (lbl) {
        // MyService.ConsoleLog($scope.oldaddress);
        var state = {},
          county = {};

        if (lbl == 'cur') {
          state = $scope.User.cur_state;
          county = JSON.parse($scope.User.cur_county);
        } else if (lbl == 'new') {
          state.statePostalCode = $scope.oldaddress.state;
          county.county = $scope.oldaddress.county;
        } else {
          state = JSON.parse($scope.User.sel_state);
          county = JSON.parse($scope.User.sel_county);
        }

        //MyService.ConsoleLog("county : ", county);
        getLocalsCanceler.resolve();

        var request = apiCall.apiCall('GET', '/user/getAllSubCountiesLocal', {
          statePostalCode: state.statePostalCode,
          county: county.county,
          timeout: getLocalsCanceler.promise,
        });
        $http(request).then(
          function successCallback(response) {
            if (response.data.success) {
              if (response.data.data) {
                // MyService.ConsoleLog(response.data.data);
                $scope.places = response.data.data;

                if (lbl == 'new') {
                  var subcounty = $scope.checksubCounty(
                    $scope.places,
                    $scope.oldaddress.countySubdivision
                  );

                  var places = $scope.checkPlaces($scope.places, $scope.oldaddress.place);
                  // MyService.ConsoleLog("subcounty<><><", subcounty[0])
                  $scope.address.countySubdivision = subcounty[0];
                  $scope.address.place = places[0];
                }
              }
            } else {
              growl('Something went wrong.');
            }
          },
          function errorCallBack(response) {
            growl('Something went wrong.');
          }
        );
      };
      $scope.User = {
        ZipCode: '',
      };
      $scope.detectCode = function (event) {
        if (event.keyCode == 9 && event.key == 'Tab') {
          $scope.showAddressDropDowns($scope.User.ZipCode);
        }
        if (event.keyCode == 13 && event.key == 'Enter') {
          $scope.showAddressDropDowns($scope.User.ZipCode);
        }
      };

      $scope.checkLength = function (vdata, val) {
        MyService.ConsoleLog(' vdata :', vdata);
        // MyService.ConsoleLog(" val :",val);

        var data = vdata.map(function (sub) {
          var returnval = '';
          switch (val) {
            case 'federalCongrassionalDist':
              returnval = sub.CD115FP;
              break;
            case 'stateSenateDistrict':
              returnval = sub.SLDUST;
              break;
            case 'stateHouseOFRepresentativeDistrict':
              returnval = sub.NAMELSAD;
              break;
            case 'unifiedSchoolDistrictName':
              returnval = sub.NAME;
              break;
            case 'default':
              returnval = sub.NAME;
              break;
          }
          return returnval;
        });

        var ndata = $filter('unique')(data);
        if (ndata.length > 1) {
          return 'yellowBackground';
        } else {
          return '';
        }
      };

      // for autocomplete address

      var options = {
        componentRestrictions: {
          country: 'us',
        },
        types: ['geocode'],
      };

      $scope.detectAddress = function (ent) {
        var val = document.getElementById('address');
        // console.log($scope.User.Address);
        var autocomplete = new google.maps.places.Autocomplete(val, options);

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
          var place = autocomplete.getPlace();

          var lat = place.geometry.location.lat(),
            lng = place.geometry.location.lng();

          $scope.lati = lat.toFixed(6);
          $scope.lon = lng.toFixed(6);
          // console.log($scope.lati);
          // console.log($scope.lon);

          $scope.mailing_Address = place.formatted_address;
          // MyService.ConsoleLog("Main addd :", place.address_components);
          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {
              if (place.address_components[i].types[j] == 'administrative_area_level_1') {
                $scope.statePostalCode = place.address_components[i].short_name;
                // console.log($scope.statePostalCode);
              }
              if (place.address_components[i].types[j] == 'country') {
                $scope.countryname = place.address_components[i].long_name;
                // console.log($scope.countryname);
              }
              if (place.address_components[i].types[j] == 'administrative_area_level_2') {
                $scope.county_name = place.address_components[i].long_name;
                // console.log($scope.county_name);
              }
              if (place.address_components[i].types[j] == 'locality') {
                $scope.place_name = place.address_components[i].long_name;
                // console.log($scope.place_name);
              }
              if (place.address_components[i].types[j] == 'postal_code') {
                // document.getElementById('zip').value = place.address_components[i].long_name;
                if (
                  place.address_components[i].long_name &&
                  place.address_components[i].long_name != ''
                ) {
                  $scope.User.ZipCode = place.address_components[i].long_name;
                  // console.log($scope.User.ZipCode);
                }
                // console.log($scope.User.ZipCode);
              }
            }
          }

          console.log('$scope.User.ZipCode :', $scope.User.ZipCode);

          var loc = JSON.stringify({
            longitude: $scope.lon,
            latitude: $scope.lati,
          });
          // console.log(loc);
          // $rootScope.location = new Array;
          $rootScope.location = {
            longitude: $scope.lon,
            latitude: $scope.lati,
          };
          $scope.showAddressDropDowns('', loc);
        });
      };

      // End for autocomplete address

      $scope.mainAddress = '';

      $scope.showAddressDropDowns = function (zipcode, loc) {
        if (loc && loc != '') {
          MyService.ConsoleLog('showAddressDropDowns' + zipcode);
          MyService.ConsoleLog('MyLocation : >>>.' + loc);

          $scope.showZipLoader = true;
          MyService.getOrganizedZipData({
            candidate: true,
            loc: loc,
          }).then(
            function (payload) {
              MyService.ConsoleLog(' showAddressDropDowns ', payload);
              if (payload && payload != '') {
                // var finalData = payload.data;
                // MyService.ConsoleLog(payload[0].countySubdivision);
                // MyService.ConsoleLog(payload[0].county);
                // MyService.ConsoleLog(payload[0].place);
                var countySubdivisions = payload.places.filter(function (obj) {
                  if (obj.COUSUBFP && obj.COUSUBFP != '') {
                    // MyService.ConsoleLog("Inside cs");
                    return obj;
                  }
                });
                var places = payload.places.filter(function (obj) {
                  if (obj.PLACEFP && obj.PLACEFP != '') {
                    // MyService.ConsoleLog("obj > ", obj);
                    return obj;
                  }
                });
                MyService.ConsoleLog(' showAddressDropDowns <><><><><><><><><><><><><> ');

                $scope.mainAddress = payload;
                $scope.showZipLoader = false;
                $scope.showAddressDropDown = true;
                $scope.oldaddress.state = payload.state;
                $scope.oldaddress.county = payload.county;
                $scope.stateFips = payload.state[0].STATEFP;
                MyService.ConsoleLog('ALLLLLLLLLLL', $scope.stateFips);
                // MyService.ConsoleLog("countySubdivisions >> ", countySubdivisions);
                $scope.oldaddress.countySubdivision = countySubdivisions;
                // MyService.ConsoleLog("places >>> ",places);
                $scope.countyName = payload.county;
                $scope.placeName = payload.places;
                MyService.ConsoleLog('$scope.placename', $scope.placeName);
                MyService.ConsoleLog('$scope.countyName', $scope.countyName);
                $scope.oldaddress.place = places;
                $scope.getCounties('new');

                $scope.getSubCounties('new');

                // $scope.voterDistricts = payload;
                // MyService.ConsoleLog(payload.congressionalDistrict);
                $scope.federalCongrassionalDist = payload.congressionalDistrict;
                MyService.ConsoleLog('@@@@@@ drop :', $scope.federalCongrassionalDist);

                $scope.stateSenateDistrict = payload.stateSenateDistrict;
                $scope.stateHouseOFRepresentativeDistricts =
                  payload.stateHouseofRepresentativesDistrict;
                $scope.unifiedSchoolDistrictNames = payload.unifiedSchoolDistrict;
                //$scope.$apply();
                // MyService.ConsoleLog($scope.states);
                // MyService.ConsoleLog(payload[0].statePostalCode);
                var address = $scope.checkState($scope.states, payload.state);
                MyService.ConsoleLog('address : ', address[0]);

                $scope.address.state = address[0];
                // $scope.address.voterDistrict = $scope.voterDistricts[0];
                $scope.address.federalCongrassionalDist = $scope.federalCongrassionalDist[0];
                $scope.address.stateSenateDistrict = $scope.stateSenateDistrict[0];
                $scope.address.stateHouseOFRepresentativeDistrict =
                  $scope.stateHouseOFRepresentativeDistricts[0];
                $scope.address.unifiedSchoolDistrictName = $scope.unifiedSchoolDistrictNames[0];
                // MyService.ConsoleLog("address ; ", address);
                MyService.ConsoleLog(
                  '<><><><><<><><>>>>>>. zip code >>>>>>>>>>>>>>>>>',
                  $scope.User.ZipCode
                );
                $timeout(function () {
                  $rootScope.tempUserZip = $scope.User.ZipCode;
                  $rootScope.tempUserAddress = $scope.address;
                  MyService.ConsoleLog(
                    ' temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>',
                    $rootScope.tempUserAddress
                  );
                  window.localStorage.setItem('tempUserZip', $rootScope.tempUserZip);
                  window.localStorage.setItem(
                    'tempUserAddress',
                    JSON.stringify($rootScope.tempUserAddress)
                  );
                  // MyService.ConsoleLog("address : ", address);
                  // MyService.ConsoleLog("rootscope address : ", $rootScope.tempUserAddress);
                }, 100);
              }
            },
            function (errorPayload) {
              $scope.showZipLoader = false;
              growl.error(errorPayload);
            }
          );
        }
      };

      $scope.saveUserAnswers = function () {
        console.log('address>>>', $scope.address);
        $rootScope.tempUserAddress = $scope.address;
        MyService.ConsoleLog(' temp : >>>>>>>>>>>>>>>>>>>>>>>>>>>', $rootScope.tempUserAddress);
        window.localStorage.setItem('tempUserZip', $rootScope.tempUserZip);
        window.localStorage.setItem('tempUserAddress', JSON.stringify($rootScope.tempUserAddress));
        $location.path('/fiveSaveUser');
      };

      $scope.eraseUserAnswers = function () {
        MyService.ConsoleLog('eraseUserAnswers');
        MyService.ConsoleLog(window.localStorage.getItem('fiveMatch'));
        $location.path('/fiveCompare');
      };

      $scope.showDigDeeperLoader = false;

      $scope.goBack = function () {
        $scope.initializeVariables();
        $scope.questions = angular.copy($scope.oldQuestions);
        $scope.currentIndex = angular.copy($scope.oldIndex);
        $scope.oldQuestions.length = 0;
        $scope.oldIndex = 0;
      };

      var getCategoryCanceler = $q.defer();


      /**
       * Get category
       */

      var prevFetchedCategoryId = null;
      $scope.categoryCalled = false;
      $scope.status = {
        questions: 0, //1= loading, 2 = loaded, 3 = error
        category: 0
      };
      $scope.getCategory = function (params) {
        MyService.ConsoleLog("scrollTop pre :", prevFetchedCategoryId);
        MyService.ConsoleLog("scrollTop Two :", params.id);
        if (prevFetchedCategoryId != params.id) {
          MyService.ConsoleLog("scrollTop");
          $scope.categoryCalled = true;
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
          getCategoryCanceler.resolve();
          $scope.status.category = 1;
          params.timeout = getCategoryCanceler.promise;
          var request = apiCall.apiCall('GET', '/categories/list', params);
          $http(
              request
            )
            .then(function successCallback(response) {
              //MyService.ConsoleLog("Got categories: ",response);
              if (response.data.success) {
                $scope.category = response.data.data;
                //MyService.ConsoleLog("category data: ", $scope.category);
                prevFetchedCategoryId = $scope.category._id;
                $scope.status.category = 2;
              } else {
                //MyService.ConsoleLog("Something went wrong: ", response.data);
                $scope.status.category = 3;
              }
            }, function errorCallBack(response) {
              //MyService.ConsoleLog("Error: ", response);
              $scope.status.category = 3;
            });
        } else {

          $("html, body").animate({
            scrollTop: $("#DisplayQ1").offset().top - 80
          }, "slow");
          // $timeout(function() {
          //   MyService.ConsoleLog("called")
          //   $location.hash('DisplayQue');
          //   $anchorScroll();

          // }, 100);

        }
      };

      // $scope.oldAddress = "";
      $scope.checkZipAddress = function (val) {
        // $scope.oldAddress = angular.copy($scope.mainAddress);
        return $scope.mainAddress.filter(function (rdata) {
          // MyService.ConsoleLog("Space:", parseInt(val.CongressionalDistrict));
          if (val.CongressionalDistrict == '' || val.CongressionalDistrict == ' ') {
            // MyService.ConsoleLog("inside<><><><><>");

            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) == parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
              parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) == parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) == parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              // MyService.ConsoleLog("This is a match");
              return rdata;
            }
          } else {
            if (
              parseInt(rdata.FIPScounty) == parseInt(val.CountyFIPS) &&
              parseInt(rdata.FIPScountySub) == parseInt(val.CountySubdivisionCode) &&
              parseInt(rdata.federalCongrassionalDist) == parseInt(val.CongressionalDistrict) &&
              parseInt(rdata.stateHouseOFRepresentativeDistrict) ==
              parseInt(val.StateDistrictLower) &&
              parseInt(rdata.stateSenateDistrict) == parseInt(val.StateDistrictUpper) &&
              parseInt(rdata.unifiedSchoolDistrict) == parseInt(val.UnifiedSchoolDistrictCode)
            ) {
              // MyService.ConsoleLog("This is a match2");
              return rdata;
            }
          }
        });
      };

      $scope.setAddressNew = function () {
        // MyService.ConsoleLog($scope.result);
        var finalResult = $scope.checkZipAddress($scope.result);
        if (finalResult && finalResult.length > 0) {
          MyService.ConsoleLog('finalResult::::', finalResult);
          // $scope.mainAddress = angular.copy(finalResult);
          $scope.voterDistricts = finalResult;
          $scope.federalCongrassionalDist = finalResult;
          $scope.stateSenateDistrict = finalResult;
          $scope.stateHouseOFRepresentativeDistricts = finalResult;
          $scope.unifiedSchoolDistrictNames = finalResult;
          ngDialog.closeAll();
          // $scope.$apply();
        } else {
          alert(
            'Address not matching with our database. Please enter detail address and try again.'
          );
        }
      };

      var previousSelVal = {};
      $scope.setSelectedDropdown = function (listData, setfor, selectedUserAdrs) {
        switch (setfor) {
          case 'counties':
            previousSelVal.county = $scope.address.county;
            //MyService.ConsoleLog("selCountyNumber: ",previousSelVal.county);
            $scope.address.county = null;
            if (listData) {
              $scope.counties = listData;
              $scope.counties.unshift({
                number: null,
                name: 'none',
              });

              // if(!selectedUserAdrs) selectedUserAdrs.countyFips = previousSelVal.county.number;
              var selCountyNumber = null;
              try {
                $scope.address.county = $scope.counties[0];
                if (selectedUserAdrs) {
                  selCountyNumber = selectedUserAdrs.countyFips;
                }
                if (previousSelVal.county) {
                  selCountyNumber = previousSelVal.county.number;
                }

                if (selCountyNumber) {
                  //MyService.ConsoleLog("selCountyNumber: ",selCountyNumber);
                  angular.forEach($scope.counties, function (val, key) {
                    if (selCountyNumber == val.number) $scope.address.county = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case 'subCounties':
            previousSelVal.subcounty = $scope.address.subcounty;
            $scope.address.subcounty = null;
            if (listData) {
              $scope.subCounties = listData;
              $scope.subCounties.unshift({
                number: null,
                name: 'none',
              });
              var selSubCountyNo = null;
              try {
                $scope.address.subcounty = $scope.subCounties[0];
                if (selectedUserAdrs) {
                  selSubCountyNo = selectedUserAdrs.countySubdivisionFips;
                }
                if (previousSelVal.subcounty) {
                  selSubCountyNo = previousSelVal.subcounty.number;
                }
                if (selSubCountyNo) {
                  angular.forEach($scope.subCounties, function (val, key) {
                    //set selected state
                    // if(selectedUserAdrs.countySubdivisionFips == val.number)
                    if (selSubCountyNo == val.number) $scope.address.subcounty = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case 'places':
            previousSelVal.place = $scope.address.place;
            $scope.address.place = null;
            if (listData) {
              $scope.places = listData;
              $scope.places.unshift({
                number: null,
                name: 'none',
              });

              try {
                $scope.address.place = $scope.places[0];
                var selPlaceNo = null;
                if (selectedUserAdrs) {
                  selPlaceNo = selectedUserAdrs.placeFips;
                }
                if (previousSelVal.place) {
                  selPlaceNo = previousSelVal.place.number;
                }
                if (selPlaceNo) {
                  angular.forEach($scope.places, function (val, key) {
                    if (selPlaceNo == val.number) $scope.address.place = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case 'voterDistricts':
            if (listData) {
              previousSelVal.voterDistrict = $scope.address.voterDistrict;
              $scope.address.voterDistrict = null;
              $scope.voterDistricts = listData;
              $scope.voterDistricts.unshift({
                number: null,
                name: 'none',
              });

              var voterDistrictNo = null;
              try {
                $scope.address.voterDistrict = $scope.voterDistricts[0];
                if (selectedUserAdrs) {
                  voterDistrictNo = selectedUserAdrs.voterDistrictFips;
                }
                if (previousSelVal.voterDistrict) {
                  voterDistrictNo = previousSelVal.voterDistrict.number;
                }

                if (voterDistrictNo) {
                  angular.forEach($scope.voterDistricts, function (val, key) {
                    //set selected
                    if (voterDistrictNo == val.number) $scope.address.voterDistrict = val;
                  });
                }
              } catch (e) {
                //MyService.ConsoleLog("Exception: ",e);
              }
            }
            break;
          case 'federalCongrassionalDist':
            $scope.address.federalCongrassionalDist = null;
            if (listData) {
              $scope.federalCongrassionalDist = listData;
              $scope.federalCongrassionalDist.unshift('none');

              if (selectedUserAdrs) {
                $scope.address.federalCongrassionalDist = $scope.federalCongrassionalDist[0];
                angular.forEach($scope.federalCongrassionalDist, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.federalCongrassionalDist == val)
                    $scope.address.federalCongrassionalDist = val;
                });
              }
            }
            break;
          case 'stateSenateDistrict':
            $scope.address.stateSenateDistrict = null;
            if (listData) {
              $scope.stateSenateDistrict = listData;
              $scope.stateSenateDistrict.unshift('none');

              $scope.address.stateSenateDistrict = $scope.stateSenateDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.stateSenateDistrict, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.stateSenateDistrict == val)
                    $scope.address.stateSenateDistrict = val;
                });
              }
            }
            break;
          case 'stateHouseOFRepresentativeDistrict':
            $scope.address.stateLegislativeDistrict = null;
            if (listData) {
              $scope.stateLegislativeDistrict = listData;
              $scope.stateLegislativeDistrict.unshift('none');
              $scope.address.stateLegislativeDistrict = $scope.stateLegislativeDistrict[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.stateLegislativeDistrict, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.legislativeDistrict == val)
                    $scope.address.stateLegislativeDistrict = val;
                });
              }
            }
            break;
          case 'schoolDistricts':
            $scope.address.schoolDistrict = null;
            if (listData) {
              $scope.schoolDistricts = listData;
              $scope.schoolDistricts.unshift({
                number: null,
                name: 'none',
              });
              $scope.address.schoolDistricts = $scope.schoolDistricts[0];
              if (selectedUserAdrs) {
                angular.forEach($scope.schoolDistricts, function (val, key) {
                  //set selected state
                  if (selectedUserAdrs.schoolDistrictFips == val.number)
                    $scope.address.schoolDistrict = val;
                });
              }
            }
            break;
        }
      };

      $scope.confirmPopup = function () {
        MyService.ConsoleLog('address : ', $scope.address);
        var message =
          'Without your voice, your politician won’t know how to represent you. Before we show you your results, would you like to create a profile so we can save your answers and send them to your politicians anonymously?';
        var modal = ngDialog.openConfirm({
          template: '<p>' +
            message +
            '</p>\
                          <div class="ngdialog-buttons">\
                              <button type="button" class="compareBtn" ng-click="confirm(0)">Create and Save</button>\
                              <button type="button" class="eraseBtn" ng-click="confirm(1)">No. Erase when done</button>\
                          </div>',
          plain: true,
        });

        modal.then(
          function fullfilled(data) {
            MyService.ConsoleLog('fullfilled: ', data);
            if (data == 0) {
              $scope.saveUserAnswers();
            } else {
              $scope.eraseUserAnswers();
            }
          },
          function rejected(data) {
            MyService.ConsoleLog('rejected: ', data);
          }
        );

        return;
      };

      function keyupHandler(keyEvent) {
        MyService.ConsoleLog('key up');
        if (!$scope.showZipCode) {
          event.preventDefault();
          if (event.keyCode === 13) {
            //MyService.ConsoleLog('Current route name: ', $location.path());
            if ($location.path() == '/fiveMatch') {
              $scope.submitAnswer();
            }
          }
        }
      }
      document.addEventListener('keyup', keyupHandler);
      $scope.$on('$destroy', function () {
        MyService.ConsoleLog('event destroyed :');
        $document.unbind('keyup');
      });
    }
  ]);
