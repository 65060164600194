'use strict';
angular
  .module('myApp.pressHome', ['ngRoute'])

  .config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/presshome/:orgtype', {
        templateUrl: 'app/pressHome/pressHome.html',
        controller: 'pressHomeCtrl',
      });
      $routeProvider.when('/presshome/:orgtype/:cat_title/:catId?', {
        templateUrl: 'app/pressHome/pressHome.html',
        controller: 'pressHomeCtrl',
      });
    },
  ])

  .controller('pressHomeCtrl', [
    '$scope',
    '$rootScope',
    '$http',
    '$location',
    'apiCall',
    'MyService',
    'tokenService',
    '$q',
    '$routeParams',
    'ngDialog',
    function (
      $scope,
      $rootScope,
      $http,
      $location,
      apiCall,
      MyService,
      tokenService,
      $q,
      $routeParams,
      ngDialog
    ) {
      $scope.loginUser = tokenService.getUserId();
      $scope.catId = $routeParams.catId;
      MyService.ConsoleLog('$scope.catId', $scope.catId);
      $rootScope.cat_title = $routeParams.cat_title;
      $rootScope.welcome = false;
      $scope.totalUsers = 0;
      $scope.usersPerPage = 25;
      $scope.pagination = {
        current: 1,
      };
      $scope.ratingUp = null;
      $scope.ratingDown = null;
      $scope.ratingUpNew = null;
      $scope.ratingDownNew = null;
      $scope.scrollToTop = function () {
        MyService.ConsoleLog('scroll');
        window.scrollTo(0, 0);
        //$('html, body').animate({scrollTop:100},'25');
      };
      if (tokenService.getUserData()) {
        $scope.allData = JSON.parse(tokenService.getUserData());
        $scope.fData = $scope.allData.followlist;
      }

      $scope.cat_title = $routeParams.cat_title;

      $scope.orgtype = $routeParams.orgtype;
      $scope.press = {
        data: [],
      };
      $scope.locationIntrest = 'usa';
      $scope.sort = {
        recommend: 'All',
        factual: 'All',
        Rfactual: 'All',
        Rrecommend: 'All',
        Trecommend: 'All',
        Tfactual: 'All',
      };

      $scope.calculateAvg = function (arr) {
        var reclen = arr.length;
        var recsum = 0;
        for (var r = 0; r < reclen; r++) {
          recsum += parseInt(arr[r].vote, 10);
          if (r == reclen - 1) {
            if (recsum > 0) {
              var recavg = Math.round(recsum / reclen);
            } else {
              var recavg = 0;
            }
            return recavg;
          }
        }
        //return card.values.opt1 + card.values.opt2;
      };

      function getCount(arr, type) {
        var reclen = arr.length;
        var recsum = 0;
        for (var r = 0; r < reclen; r++) {
          if (arr[r].user.utype == type) {
            recsum += 1;
          }
          if (r == reclen - 1) {
            return recsum;
          }
        }
      }

      $scope.calculateAvgType = function (arr, type) {
        var reclen = arr.length;
        var recsum = 0;
        var finallen = 0;
        for (var r = 0; r < reclen; r++) {
          if (arr[r].user.utype == type) {
            recsum += parseInt(arr[r].vote, 10);
            finallen += 1;
          }
          if (r == reclen - 1) {
            if (recsum > 0) {
              var recavg = Math.round(recsum / finallen);
            } else {
              var recavg = 0;
            }
            return recavg;
          }
        }
        //return card.values.opt1 + card.values.opt2;
      };

      $scope.showPaperRate = 'All';
      $scope.showPaperRRate = 'All';

      var getAllCanceler = $q.defer();
      $scope.page = 0;
      $scope.maxRecordsPerPg = 30;
      $scope.statusGetAll = 0;
      $scope.press.data = [];
      $scope.totalLen = 1;
      $scope.myPagingFunction = function () {
        MyService.ConsoleLog('scoll called', $scope.statusGetAll);
        MyService.ConsoleLog('scoll called', $scope.totalLen);
        MyService.ConsoleLog('scoll called', $scope.press.data.length);

        if ($scope.statusGetAll != 1 && $scope.totalLen > $scope.press.data.length) {
          $scope.page++;
          $scope.pageChanged($scope.page);
        }
      };

      $scope.pageChanged = function (newPage) {
        $scope.getAll(newPage);
      };

      $scope.getAll = function (pageNumber) {
        var param = {
          type: $routeParams.orgtype,
        };
        if ($routeParams.cat_title) {
          //for advocate page we need category
          param.topic = $routeParams.cat_title;
        }
        MyService.ConsoleLog("$scope.locationIntrest", $scope.locationIntrest);
        switch ($scope.locationIntrest) {
          case 'International':
            param.location_of_interest = 'International';
            break;
          case 'usa':
            param.location_of_interest = 'usa';
            break;
          case 'state':
            param.location_of_interest = 'state';
            try {
              var userdata = tokenService.getUserData();
              userdata = JSON.parse(userdata);
              // if(userdata.address.state) param.state = userdata.address.state;
              if (userdata.address.statePostalCode)
                param.statePostalCode = userdata.address.statePostalCode;
            } catch (e) {
              //MyService.ConsoleLog("Exception: ", e);
            }
            break;
          case 'county':
            param.location_of_interest = 'county';
            try {
              var userdata = tokenService.getUserData();
              userdata = JSON.parse(userdata);
              // if(userdata.address.state) param.state = userdata.address.state;
              if (userdata.address.statePostalCode)
                param.statePostalCode = userdata.address.statePostalCode;
              if (userdata.address.county) param.county = userdata.address.county;
            } catch (e) {
              //MyService.ConsoleLog("Exception: ", e);
            }
            break;
          case 'local':
            param.location_of_interest = 'local';
            try {
              var userdata = tokenService.getUserData();
              userdata = JSON.parse(userdata);
              // if(userdata.address.state) param.state = userdata.address.state;
              if (userdata.address.statePostalCode)
                param.statePostalCode = userdata.address.statePostalCode;
              if (userdata.address.county) param.county = userdata.address.county;
              if (userdata.address.place) param.place = userdata.address.place;
            } catch (e) {
              //MyService.ConsoleLog("Exception: ", e);
            }
            break;
        }
        MyService.ConsoleLog('$scope.allData', $scope.allData);
        param.page = pageNumber;
        param.max_records = $scope.maxRecordsPerPg;
        if ($scope.allData && $scope.allData != '') {
          param.curId = $scope.allData.id;
        }
        param.catId = $scope.catId;
        //MyService.ConsoleLog("param: ", param);

        getAllCanceler.resolve();
        getAllCanceler = $q.defer();
        $scope.statusGetAll = 1;
        MyService.getAllOrganizationsPaginated(param).then(
          function (payload) {
            //MyService.getAllOrganizations(param).then(function(payload) {
            // $scope.press.data.length = 0;

            var finalData = payload.data;
            // MyService.ConsoleLog("finalData: ", finalData);
            var flen = finalData.length;
            for (var s = 0; s < flen; s++) {
              if (finalData[s].recommended && finalData[s].recommended.length > 0) {
                finalData[s].recavg = $scope.calculateAvg(finalData[s].recommended);
                finalData[s].recavgtotal = finalData[s].recommended.length;
                finalData[s].recavgvoters = $scope.calculateAvgType(
                  finalData[s].recommended,
                  'voter'
                );
                finalData[s].recavgvoterstotal = getCount(finalData[s].recommended.length, 'voter');
                finalData[s].recavgpolitician = $scope.calculateAvgType(
                  finalData[s].recommended,
                  'politician'
                );
                finalData[s].recavgpoliticiantotal = getCount(
                  finalData[s].recommended.length,
                  'politician'
                );
                finalData[s].recavgadvocate = $scope.calculateAvgType(
                  finalData[s].recommended,
                  'advocate'
                );
                finalData[s].recavgadvocatetotal = getCount(
                  finalData[s].recommended.length,
                  'advocate'
                );
                finalData[s].recavgpress = $scope.calculateAvgType(
                  finalData[s].recommended,
                  'press'
                );
                finalData[s].recavgpresstotal = getCount(finalData[s].recommended, 'press');
                MyService.ConsoleLog(
                  ' finalData[s].recavgvoterstotal',
                  finalData[s].recavgvoterstotal
                );
              } else {
                finalData[s].recavg = 0;
                finalData[s].recavgvoters = 0;
                finalData[s].recavgpolitician = 0;
                finalData[s].recavgadvocate = 0;
                finalData[s].recavgpress = 0;
                finalData[s].recavgtotal = 0;
                finalData[s].recavgvoterstotal = 0;
                finalData[s].recavgpoliticiantotal = 0;
                finalData[s].recavgadvocatetotal = 0;
                finalData[s].recavgpresstotal = 0;
              }
              if (finalData[s].factual && finalData[s].factual.length > 0) {
                finalData[s].factfavg = $scope.calculateAvg(finalData[s].factual);
                finalData[s].factfavgtotal = finalData[s].factual.length;
                finalData[s].factfavgvoters = $scope.calculateAvgType(
                  finalData[s].factual,
                  'voter'
                );
                finalData[s].factfavgvoterstotal = getCount(finalData[s].factual, 'voter');
                finalData[s].factfavgpolitician = $scope.calculateAvgType(
                  finalData[s].factual,
                  'politician'
                );
                finalData[s].factfavgpoliticiantotal = getCount(finalData[s].factual, 'politician');
                finalData[s].factfavgadvocate = $scope.calculateAvgType(
                  finalData[s].factual,
                  'advocate'
                );
                finalData[s].factfavgadvocatetotal = getCount(finalData[s].factual, 'advocate');
                finalData[s].factfavgpress = $scope.calculateAvgType(finalData[s].factual, 'press');
                finalData[s].factfavgpresstotal = getCount(finalData[s].factual, 'press');
              } else {
                finalData[s].factfavg = 0;
                finalData[s].factfavgvoters = 0;
                finalData[s].factfavgpolitician = 0;
                finalData[s].factfavgadvocate = 0;
                finalData[s].factfavgpress = 0;
                finalData[s].factfavgtotal = 0;

                finalData[s].factfavgvoterstotal = 0;
                finalData[s].factfavgpoliticiantotal = 0;
                finalData[s].factfavgadvocatetotal = 0;
                finalData[s].factfavgpresstotal = 0;
              }
              //MyService.ConsoleLog("finalData[s] :", finalData[s]);
              // $scope.press.data.push(finalData[s]);
            }

            MyService.ConsoleLog('payload: ', payload);
            $scope.press.data = $scope.press.data.concat(finalData);
            $scope.totalLen = payload.total;

            $scope.press.data.sort(function (a, b) {
              return b.match - a.match;
            });
            //MyService.ConsoleLog("finalData: ", $scope.press.data.length);
            //MyService.ConsoleLog("$scope.press.data: ", $scope.press.data);
            $scope.statusGetAll = 2;
          },
          function (err) {
            //MyService.ConsoleLog("got states error");
            $scope.statusGetAll = 3;
            $scope.page--;
            alert('Something went wrong');
          }
        );
      };
      // $scope.getAll();

      $scope.loadMore = function () {
        $scope.page++;
        $scope.getAll();
      };

      $scope.sorArr = function (val) {
        var sortOrder = '';
        if (val == 'match') {
          MyService.ConsoleLog("called");
          if ($scope.ratingUp == '' || $scope.ratingUp == 'DESC') {
            sortOrder = 'ASC';
            // $scope.reverseSort = "";
            // $scope.reverseSort = "match";
            // if (para == 'factual') {
            $scope.ratingUp = true;
            $scope.ratingDown = false;

            // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);

            $scope.press.data.sort(function (a, b) {

              if (a && a.match && b && b.match) {
                return a.match - b.match;
              }
            });
          } else {
            sortOrder = 'DESC';
            // $scope.reverseSort = "";
            // $scope.reverseSort = "match";
            $scope.ratingUp = false;
            $scope.ratingDown = true;

            // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);
            $scope.press.data.sort(function (a, b) {
              if (a && a.match && b && b.match) {
                return b.match - a.match;
              }
            });
          }
        } else {
          MyService.ConsoleLog("called");
          if ($scope.nameUp == '' || $scope.nameUp == 'DESC') {
            sortOrder = 'ASC';
            // $scope.reverseSort = "";
            // $scope.reverseSort = "match";
            // if (para == 'factual') {
            $scope.nameUp = true;
            $scope.nameDown = false;

            // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);

            $scope.press.data.sort(function (a, b) {

              if (a && a.title && b && b.title) {
                var x, y;
                x = a.title.toLowerCase();
                y = b.title.toLowerCase();

                if (x < y) {
                  return -1;
                }
                if (x > y) {
                  return 1;
                }
                return 0;
              }
            });
          } else {
            sortOrder = 'DESC';
            // $scope.reverseSort = "";
            // $scope.reverseSort = "match";
            $scope.nameUp = false;
            $scope.nameDown = true;

            // MyService.ConsoleLog("$scope.press.data", $scope.press.data.length);
            $scope.press.data.sort(function (a, b) {
              if (a && a.title && b && b.title) {

                var xs = a.title.toLowerCase();
                var ys = b.title.toLowerCase();
                if (xs > ys) {
                  return -1;
                }
                if (xs < ys) {
                  return 1;
                }
                return 0;
              }
            });
          }
        }
      };

      $scope.intrestChange = function () {
        //MyService.ConsoleLog("intrestChange: ", $scope.locationIntrest);
        $scope.page = 0;
        $scope.press.data = [];
        $scope.totalLen = 1;
        // $scope.getAll();
        $scope.myPagingFunction();
      };

      $scope.paperFilter = function (item) {
        return item.orgtype.toLowerCase() === 'paper' || item.orgtype.toLowerCase() === 'magazine';
      };

      $scope.tvFilter = function (item) {
        return item.orgtype.toLowerCase() === 'tv';
      };

      $scope.radioFilter = function (item) {
        return item.orgtype.toLowerCase() === 'radio';
      };

      $scope.checkIfChecked = function (item) {
        if (item.followerUsers.indexOf($scope.loginUser) > -1) {
          var idx = $scope.followArr.indexOf(item._id);
          if (idx == -1) {
            $scope.followArr.push(item._id);
          }
          return true;
        } else {
          return false;
        }
      };

      $scope.followArr = [];
      $scope.addFollow = function (item) {
        var id = item._id;
        var idx = $scope.followArr.indexOf(id);
        if (idx == -1) {
          $scope.followArr.push(id);
        }
        //MyService.ConsoleLog($scope.followArr);
      };

      $scope.setLocation = function (state) {
        //MyService.ConsoleLog(" setLocation ");
        // $scope.getAll(state);
      };

      $scope.updateFollow = function () {
        //MyService.ConsoleLog($scope.followArr);
        var token = apiCall.getToken();
        if (!token) {
          var message = 'Please login first.';

          var modal = ngDialog.openConfirm({
            template: '<p>' +
              message +
              '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true,
          });

          modal.then(
            function fullfilled(data) {
              //MyService.ConsoleLog("fullfilled: ", data);
              $location.path('signin');
            },
            function rejected(data) {
              //MyService.ConsoleLog("rejected: ", data);
              $location.path('signin');
            }
          );
        } else {
          if ($scope.followArr.length > 0) {
            var data = {};
            data.followlist = JSON.stringify($scope.followArr);
            data.userId = $scope.loginUser;
            //alert("This is not implemented yet");
            //MyService.ConsoleLog(data);
            MyService.addToMyFollow(data).then(
              function (payload) {
                // $scope.getAll();
                alert('Items added to your Follow List');
              },
              function (err) {
                alert('Something went wrong try again.');
              }
            );
          } else {
            alert('Please select atleast one record');
          }
        }
      };

      // $scope.getAll('International');
    },
  ]);
