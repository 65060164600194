'use strict';
angular
  .module('myApp.viewBillDetails', ['ngRoute'])

  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/viewBillDetails/:id?', {
        templateUrl: 'app/viewBillDetails/viewBillDetails.html',
        controller: 'viewBillDetailsCtrl'
      });
    }
  ])
  .controller('viewBillDetailsCtrl', [
    '$scope',
    'ngDialog',
    '$rootScope',
    'MyService',
    '$http',
    '$timeout',
    '$routeParams',
    '$q',
    'tokenService',
    'growl',
    'apiCall',


    function($scope, ngDialog, $rootScope, MyService, $http, $timeout, $routeParams, $q, tokenService, growl, apiCall) {


      console.log("$routeParams >", $routeParams.id);
      $scope.uId = $routeParams.id;
      $scope.activeDoc = true;
      $scope.activeAct = true;
      $scope.activeAction = true;

      $scope.activeVot = true;
      $scope.activeSpo = true;
      $scope.displayComments = true;
      $scope.ShowComments = false;
      $scope.loader = false;

      /*
       ** get Bill Details 
       */

      $scope.getDetails = function(val) {
        MyService.ConsoleLog('params >', val);
        MyService.getBillDetail({ id: val }).then(
          function(response) {
            MyService.ConsoleLog('got data >', response);
            $scope.details = response.data.data;
            $scope.showDetail = true;
            $scope.getRatingData();

          },
          function(err) {
            MyService.ConsoleLog('Error', err);
          }
        );
      };

      $scope.getDetails($scope.uId);


      /*
       ** Redirect to comment form
       */



      $scope.redirectToComment = function() {
        var token = apiCall.getToken();
        if (!token) {
          var message = "Please login first.";

          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true
          });

          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            $location.path('signin');
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            $location.path('signin');
          });
        }
        $scope.ShowComments = true;
        $scope.displayComments = false;

        //$location.path('forumReply/'+$routeParams.categoryId+'/'+$routeParams.subcatId+'/'+$routeParams.essayId);
      }


      /*
       ** Hide comment text input
       */
      $scope.goBack = function() {
        $scope.ShowComments = false;
        $scope.displayComments = true;
      }


      /*
       ** get Ratings 
       */

      $scope.rating = {
        "rate": ''
      }
      $scope.rateLegislation = function(option) {
        var token = apiCall.getToken();
        if (!token) {
          var message = "Please login first.";

          var modal = ngDialog.openConfirm({
            template: '<p>' + message + '</p>\
                        <div class="ngdialog-buttons">\
                            <button type="button" class="ngdialog-button ngdialog-button-secondary" ng-click="closeThisDialog(0)">OK</button>\
                        </div>',
            plain: true
          });

          modal.then(function fullfilled(data) {
            //MyService.ConsoleLog("fullfilled: ", data);
            $location.path('signin');
          }, function rejected(data) {
            //MyService.ConsoleLog("rejected: ", data);
            $location.path('signin');
          });
        } else {
          var data = {};
          data.userId = tokenService.getUserId();
          data.uName = tokenService.getUserName();
          data.uType = tokenService.getUserRole();
          data.legisId = $scope.details._id;
          data.createOn = new Date();

          //MyService.ConsoleLog(option);

          data.rating = $scope.rating.rate;
          MyService.ConsoleLog("rating  >>>", data);

          MyService.saveLegisRating(data).then(function(payload) {
            // alert('Thank you for rating the Legislation.');

            growl.success('Thank you for vote Legislation.');


          }, function(errorPayload) {
            alert('Something went wrong ');
            growl.Error('Something went wrong');
          });
        }
      }


      /*
       ** Get comments and save it to tha database  
       */

      $scope.SubmitComment = function(rec) {
        //MyService.ConsoleLog(data);

        var data = {};
        if (rec.content != "") {

          data.commentedBy = tokenService.getUserId();
          data.commentedByName = tokenService.getUserName();
          data.commentedByType = tokenService.getUserRole();
          data.comments = rec.content;
          data.legisId = $scope.details._id;
          data.createOn = new Date();
          //MyService.ConsoleLog(data);
          MyService.saveLegisComment(data).then(function(payload) {
            $scope.comments = {
              content: ''
            };
            $scope.getRatingData();
            $scope.ShowComments = false;
            $scope.displayComments = true;
            $scope.getRatingData();
            // $scope.getComments();
          }, function(errorPayload) {
            alert('Something went wrong ');
          });
        } else {
          alert("Please enter comments.");
        }
      }

      /*
       ** Fetch rating data from database  
       */

      $scope.getRatingData = function() {



        MyService.getLegisRate({ 'legisId': $scope.details._id, "uType": tokenService.getUserRole() }).then(function(data) {
          MyService.ConsoleLog("Dayta>>>", data);
          $scope.rating.rate = data.data.rating;
          $scope.comment = data.data.comment;
          $scope.disImg = true;

        }, function(err) {
          console.log("error >>", err);
        })
      }


      $scope.backTo = function() {
      window.localStorage.setItem('flag', true);

        window.history.back();
      }
    }
  ]);
